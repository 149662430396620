import styled from 'styled-components'
import { Activity } from 'models/activity'
import apiService from 'services/api'
import React, { useMemo } from 'react'
import { SalesStage } from 'models/sales_stages'
import { StagePill } from 'components/FbUI/StagePill'
import { formatTwoDecimal, formatUsdDecimal } from 'utils/formatting'
import { Editor } from '../../../Editor/Editor'

const getIdFromLastParentheses = (str: string) => {
  const matches = str.match(/\(([^)]+)\)/g)
  if (!matches) return null
  const lastMatch = matches.pop()
  if (!lastMatch) return null
  const id = lastMatch.replace(/\D/g, '')
  return id
}

const removeLastParentheses = (str: string) => {
  const matches = str.match(/\(([^)]+)\)/g)
  if (!matches) return str
  const lastMatch = matches.pop()
  if (!lastMatch) return str
  const formattedStr = str.replace(lastMatch, '')
  return formattedStr.trim()
}

const convertSnakeCaseToSentenceCase = (str: string) => {
  const formattedStr = str.split('_').join(' ')
  return formattedStr
}

const getCurrVal = (activity: Activity, key: string) => {
  let value = ''
  if (activity.changes[key]) {
    value = activity.changes[key].objects[1]
  }
  return value
}

const getPrevVal = (activity: Activity, key: string) => {
  let value = ''
  if (activity.changes[key]) {
    value = activity.changes[key].objects[0]
  }
  return value
}

const getChangeAction = (activity: Activity, key: string) => {
  if (activity.changes[key]) {
    if (activity.changes[key].type === 'text') {
      if (activity.changes[key].objects[0] === 'None') {
        return 'added'
      } else if (activity.changes[key].objects[1] === 'None') {
        return 'removed'
      } else {
        return 'changed'
      }
    } else {
      return activity.changes[key].operation
    }
  }
  return null
}

export default function CellEventFormatter(activity: Activity) {
  const eventMap: Record<string, any> = {
    contact: CellEventFormatterContact,
    note: CellEventFormatterContactCompanyNote,
    contactcompany: CellEventFormatterContactCompany,
    deal: CellEventFormatterDeal,
    product: CellEventFormatterProduct,
    savedview: CellEventFormatterSavedView,
  }
  const Formatter = eventMap[activity.content_type]
  if (Formatter) {
    return <Formatter {...activity} />
  }
  return null
}

const CellEventFormatterDeal = (activity: Activity) => {
  const api = apiService()
  const { data: salesStages } = api.useGetSalesStageOptions()
  const salesStagesMap = useMemo(() => {
    if (!salesStages) return {}
    return salesStages.reduce((acc: Record<string, SalesStage>, curr) => {
      if (!curr.original) return acc
      acc[curr.label] = curr.original
      return acc
    }, {})
  }, [salesStages])
  if (activity.action === 0) {
    const product = removeLastParentheses(getCurrVal(activity, 'product'))
    // If product not found, deal was created by the system
    if (!product)
      return (
        <>
          created new <GreenBold>deal</GreenBold>
        </>
      )
    return (
      <>
        created new <GreenBold>deal</GreenBold> for <b>{product}</b>
      </>
    )
  }
  if (activity.action === 1) {
    // Only has update event
    const changedKey = Object.keys(activity.changes)[0]
    const changeAction = getChangeAction(activity, changedKey)

    let changedValue: any = getCurrVal(activity, changedKey)

    let prevValue: any = getPrevVal(activity, changedKey)
    const changedKeyDisplay = convertSnakeCaseToSentenceCase(changedKey)

    if (changedKey === 'account_owner') {
      changedValue = removeLastParentheses(changedValue)
      prevValue = removeLastParentheses(prevValue)
    } else if (changedKey === 'sales_stage') {
      const fromStage = salesStagesMap[prevValue]
      const toStage = salesStagesMap[changedValue]
      if (toStage) {
        changedValue = (
          <PillContainer>
            <StagePill stageId={toStage.id} />
          </PillContainer>
        )
      }
      if (fromStage) {
        prevValue = (
          <PillContainer>
            <StagePill stageId={fromStage.id} />
          </PillContainer>
        )
      }
    }

    if (
      ['monthly_volume_override', 'monthly_revenue_override'].includes(
        changedKey
      )
    ) {
      const formatter =
        changedKey === 'monthly_volume_override'
          ? formatTwoDecimal
          : formatUsdDecimal
      if (prevValue) {
        prevValue = formatter(prevValue)
      }
      if (changedValue) {
        changedValue = formatter(changedValue)
      }
    }

    if (changeAction === 'changed') {
      return (
        <div>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> from{' '}
          <b>{prevValue}</b> to <b>{changedValue}</b>
        </div>
      )
    }
    if (changeAction === 'added') {
      return (
        <>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> to{' '}
          <b>{changedValue}</b>
        </>
      )
    }
    if (changeAction === 'removed') {
      return (
        <>
          removed <b>{prevValue}</b> from{' '}
          <GreenBold>{changedKeyDisplay}</GreenBold>
        </>
      )
    }
  }
  if (activity.action === 2) {
    const product = removeLastParentheses(getPrevVal(activity, 'product'))
    return (
      <>
        deleted <GreenBold>deal</GreenBold> for <b>{product}</b>
      </>
    )
  }
}

const CellEventFormatterContactCompanyNote = (activity: Activity) => {
  if (activity.action === 0) {
    const product = removeLastParentheses(
      getCurrVal(activity, 'contact_company')
    ).split(' - ')[0]
    // If product not found, deal was created by the system
    if (!product)
      return (
        <>
          created new <GreenBold>note</GreenBold>
        </>
      )
    return (
      <>
        created new <GreenBold>note</GreenBold> for <b>{product}</b>
      </>
    )
  }
  if (activity.action === 1) {
    // Only has update event
    const changedKey = Object.keys(activity.changes)[0]
    const changeAction = getChangeAction(activity, changedKey)
    const changedValue = getCurrVal(activity, changedKey)
    const prevValue = getPrevVal(activity, changedKey)
    const changedKeyDisplay = convertSnakeCaseToSentenceCase(changedKey)

    if (changeAction === 'changed' || changeAction === 'added') {
      return (
        <div className={'flex justify-center flex-col pt-3'}>
          <span>
            changed <GreenBold>Note</GreenBold> to:{' '}
          </span>
          <Editor
            value={changedValue}
            readOnly={true}
            disableToolbar={true}
            noBorder
          />
        </div>
      )
    }
    if (changeAction === 'removed') {
      return (
        <>
          removed <b>{prevValue}</b> from{' '}
          <GreenBold>{changedKeyDisplay}</GreenBold>
        </>
      )
    }
  }
  if (activity.action === 2) {
    const product = removeLastParentheses(
      getPrevVal(activity, 'contact_company')
    ).split(' - ')[0]
    if (!product)
      return (
        <>
          deleted <GreenBold>note</GreenBold>
        </>
      )
    return (
      <>
        deleted <GreenBold>note</GreenBold> for <b>{product}</b>
      </>
    )
  }
}

const CellEventFormatterSavedView = (activity: Activity) => {
  if (activity.action === 0) {
    // If product not found, deal was created by the system
    return (
      <>
        created new <GreenBold>saved filter</GreenBold>{' '}
        <b>&apos;{activity.object_repr}&apos;</b>
      </>
    )
  }
  if (activity.action === 2) {
    return (
      <>
        deleted <GreenBold>saved filter</GreenBold>{' '}
        <b>&apos;{activity.object_repr}&apos;</b>
      </>
    )
  }
}

const CellEventFormatterProduct = (activity: Activity) => {
  if (activity.action === 0) {
    const product = removeLastParentheses(getCurrVal(activity, 'name')).split(
      ' - '
    )[0]
    // If product not found, deal was created by the system
    if (!product)
      return (
        <>
          created new <GreenBold>product</GreenBold>
        </>
      )
    return (
      <>
        created new <GreenBold>product</GreenBold> <b>{product}</b>
      </>
    )
  }

  if (activity.action === 1) {
    // Only has update event
    const changedKey = Object.keys(activity.changes)[0]
    const changeAction = getChangeAction(activity, changedKey)
    const changedValue = getCurrVal(activity, changedKey)
    const prevValue = getPrevVal(activity, changedKey)
    const changedKeyDisplay = convertSnakeCaseToSentenceCase(changedKey)

    if (changedKey === 'estimated_sales_potential') {
      return (
        <div>
          changed <GreenBold>product input</GreenBold> for{' '}
          {activity.object_repr.split('(')[0]}
        </div>
      )
    }

    if (changeAction === 'changed') {
      return (
        <div>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> from{' '}
          <b>{prevValue}</b> to <b>{changedValue}</b>
        </div>
      )
    }
    if (changeAction === 'added') {
      return (
        <>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> to{' '}
          <b>{changedValue}</b>
        </>
      )
    }
    if (changeAction === 'removed') {
      return (
        <>
          removed <b>{prevValue}</b> from{' '}
          <GreenBold>{changedKeyDisplay}</GreenBold>
        </>
      )
    }
  }

  if (activity.action === 2) {
    const product = removeLastParentheses(getPrevVal(activity, 'name')).split(
      ' - '
    )[0]
    if (!product)
      return (
        <>
          deleted <GreenBold>product</GreenBold>
        </>
      )
    return (
      <>
        deleted <GreenBold>product</GreenBold> <b>{product}</b>
      </>
    )
  }
}

const CellEventFormatterContact = (activity: Activity) => {
  const api = apiService()
  const { data: tags } = api.useGetTagsOptions(false, 'contacts')
  const tagsMap = useMemo(() => {
    if (!tags) return {}
    return tags.reduce((acc: Record<string, string>, curr) => {
      acc[curr.value] = curr.label
      return acc
    }, {})
  }, [tags])
  if (activity.action === 0) {
    const name =
      getCurrVal(activity, 'first_name') +
      ' ' +
      getCurrVal(activity, 'last_name')
    const title = getCurrVal(activity, 'title')
    const display = name + (title ? ', ' + title : '')
    return (
      <>
        added contact <b>{display}</b>
      </>
    )
  } else if (activity.action === 1) {
    const changedKey = Object.keys(activity.changes)[0]
    const changeAction = getChangeAction(activity, changedKey)
    const changedValue = getCurrVal(activity, changedKey)
    const prevValue = getPrevVal(activity, changedKey)
    const changedKeyDisplay = convertSnakeCaseToSentenceCase(changedKey)

    const getTags = (tags: string[]) => {
      return tags
        .map((v) => {
          const id = getIdFromLastParentheses(v)
          if (!id) return v
          return tagsMap[id]
        })
        .join(', ')
    }

    if (changeAction === 'changed') {
      return (
        <div>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> from{' '}
          <b>{prevValue}</b> to <b>{changedValue}</b>
        </div>
      )
    }
    if (changeAction === 'added') {
      return (
        <>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> to{' '}
          <b>{changedValue}</b>
        </>
      )
    }
    if (changeAction === 'removed') {
      return (
        <>
          removed <b>{prevValue}</b> from{' '}
          <GreenBold>{changedKeyDisplay}</GreenBold>
        </>
      )
    }
    if (changeAction === 'add') {
      return (
        <>
          added new <GreenBold>tag</GreenBold>:{' '}
          {getTags(activity.changes.tags.objects)}
        </>
      )
    }
    if (changeAction === 'delete') {
      return (
        <>
          removed <GreenBold>tag</GreenBold>:{' '}
          {getTags(activity.changes.tags.objects)}
        </>
      )
    }
  } else if (activity.action === 2) {
    const name =
      getPrevVal(activity, 'first_name') +
      ' ' +
      getPrevVal(activity, 'last_name')
    const title = getPrevVal(activity, 'title')
    const display = name + (title ? ', ' + title : '')
    return (
      <>
        deleted contact <b>{display}</b>
      </>
    )
  }
}

const CellEventFormatterContactCompany = (activity: Activity) => {
  const api = apiService()
  const { data: tags } = api.useGetTagsOptions()
  const { data: distributors } = api.useGetCompanyDistributorsOptions()

  const tagsMap = useMemo(() => {
    if (!tags) return {}
    return tags.reduce((acc: Record<string, string>, curr) => {
      acc[curr.value] = curr.label
      return acc
    }, {})
  }, [tags])

  const distributorsMap = useMemo(() => {
    if (!distributors) return {}
    return distributors.reduce((acc: Record<string, string>, curr) => {
      acc[curr.value] = curr.label
      return acc
    }, {})
  }, [distributors])

  if (activity.action === 0) {
    return <>Created this company</>
  } else if (activity.action === 1) {
    const changedKey = Object.keys(activity.changes)[0]
    const changeAction = getChangeAction(activity, changedKey)
    const changedValue = getCurrVal(activity, changedKey)
    const prevValue = getPrevVal(activity, changedKey)
    const changedKeyDisplay = convertSnakeCaseToSentenceCase(changedKey)

    if (changeAction === 'changed') {
      return (
        <div>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> from{' '}
          <b>{prevValue}</b> to <b>{changedValue}</b>
        </div>
      )
    }
    if (changeAction === 'added') {
      return (
        <>
          changed <GreenBold>{changedKeyDisplay}</GreenBold> to{' '}
          <b>{changedValue}</b>
        </>
      )
    }
    if (changeAction === 'removed') {
      return (
        <>
          removed <b>{prevValue}</b> from{' '}
          <GreenBold>{changedKeyDisplay}</GreenBold>
        </>
      )
    }
    if (activity.changes.taglist) {
      const getTags = (tags: string[]) => {
        return tags
          .map((v) => {
            const id = getIdFromLastParentheses(v)
            if (!id) return v
            return tagsMap[id]
          })
          .join(', ')
      }
      if (changeAction === 'add') {
        return (
          <>
            added new <GreenBold>tag</GreenBold>:{' '}
            {getTags(activity.changes.taglist.objects)}
          </>
        )
      }
      if (changeAction === 'delete') {
        return (
          <>
            removed <GreenBold>tag</GreenBold>:{' '}
            {getTags(activity.changes.taglist.objects)}
          </>
        )
      }
    } else if (activity.changes.distributors) {
      const getDistributors = (tags: string[]) => {
        return tags
          .map((v) => {
            const id = getIdFromLastParentheses(v)
            if (!id) return v
            return distributorsMap[id]
          })
          .join(', ')
      }
      if (changeAction === 'add') {
        return (
          <>
            added new <GreenBold>distributor</GreenBold>:{' '}
            {getDistributors(activity.changes.distributors.objects)}
          </>
        )
      }
      if (changeAction === 'delete') {
        return (
          <>
            removed <GreenBold>distributor</GreenBold>:{' '}
            {getDistributors(activity.changes.distributors.objects)}
          </>
        )
      }
    }
  } else if (activity.action === 2) {
    return <>deleted this company</>
  }
}

export const GreenBold = styled.b`
  color: #087443;
`

export const PillContainer = styled.span`
  display: inline-flex;
  margin: 0 2px;
`
