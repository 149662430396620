import React, { createContext, ReactNode } from 'react'

const initialContext = {
  doorsTabSelected: false,
  chainsTabSelected: false,
  setRestaurantsView: () => {},
} as IOppportunitiesContext

export const OpportunitiesContext =
  createContext<IOppportunitiesContext>(initialContext)

export const OpportunitiesProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [restaurantsView, setRestaurantsView] = React.useState<
    'chain' | 'door'
  >('chain')
  const doorsTabSelected = restaurantsView === 'door'
  const chainsTabSelected = restaurantsView === 'chain'
  return (
    <OpportunitiesContext.Provider
      value={{
        doorsTabSelected,
        chainsTabSelected,
        setRestaurantsView,
      }}
    >
      {children}
    </OpportunitiesContext.Provider>
  )
}

export interface IOppportunitiesContext {
  doorsTabSelected: boolean
  chainsTabSelected: boolean
  setRestaurantsView: (view: 'chain' | 'door') => void
}

export const useOpportunitiesContext = () => {
  const context = React.useContext(OpportunitiesContext)
  if (!context) {
    throw new Error(
      'useOpportunitiesContext must be used within a OpportunitiesProvider'
    )
  }
  return context
}
