import React, { useEffect } from 'react'
import Checkbox from './Checkbox/checkbox'

export function IndeterminateCheckbox({
  indeterminate,
  wrapperClassName,
  ...rest
}: {
  indeterminate?: boolean
  wrapperClassName?: string
} & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)
  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return <Checkbox {...rest} ref={ref} wrapperClassName={wrapperClassName} />
}
