import styled from 'styled-components/macro'

export const TextPill = styled.div<{
  backgroundColor: string
  textColor: string
}>`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 3px 6px;
  border-radius: 10px;
  width: fit-content;
  max-width: 200px;
`
