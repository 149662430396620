import styled from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'

export default function Autocomplete({
  options,
  ...props
}: {
  options: string[]
} & React.ComponentProps<typeof Form.Control>) {
  const [inputValue, setInputValue] = useState<string>(
    (props.value || '') as string
  )
  const filteredOptions = useMemo(() => {
    return options
      .filter((option) =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      )
      .slice(0, 5)
  }, [inputValue])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [hoveredOptionIdx, setHoveredOptionIdx] = useState<number | null>(null)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    props.onChange?.({
      target: {
        value: inputValue,
      },
    } as any)
  }, [inputValue])

  useEffect(() => {
    setInputValue(props.value as string)
  }, [props.value])

  useEffect(() => {
    if (filteredOptions.length > 0 && inputValue.length > 0 && isFocused) {
      setIsMenuOpen(true)
    } else {
      setIsMenuOpen(false)
      setHoveredOptionIdx(null)
    }
  }, [filteredOptions, inputValue])

  const selectOptionIdx = (idx: number | null) => {
    if (idx !== null) {
      setInputValue(filteredOptions[idx])
    }
    setTimeout(() => {
      setIsMenuOpen(false)
    }, 100)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let hoverIdx = hoveredOptionIdx
    // Do nothing if menu is not open
    if (!isMenuOpen) {
      return
    }

    // Arrow down
    if (e.key === 'ArrowDown') {
      if (hoverIdx === null) {
        hoverIdx = -1
      }
      const nextIdx = hoverIdx! + 1
      if (nextIdx < filteredOptions.length) {
        setHoveredOptionIdx(nextIdx)
      } else {
        setHoveredOptionIdx(null)
      }
      e.preventDefault()
    }

    // Arrow up
    if (e.key === 'ArrowUp') {
      if (hoveredOptionIdx === null) {
        hoverIdx = filteredOptions.length
      }
      const nextIdx = hoverIdx! - 1
      if (nextIdx >= 0) {
        setHoveredOptionIdx(nextIdx)
      } else {
        setHoveredOptionIdx(null)
      }
      e.preventDefault()
    }

    if (e.key === 'Enter') {
      selectOptionIdx(hoveredOptionIdx)
      e.preventDefault()
    }
  }

  const preventEventOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isMenuOpen) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
      }
      if (e.key === 'Enter' && hoveredOptionIdx !== null) {
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    if (
      hoveredOptionIdx !== null &&
      hoveredOptionIdx > filteredOptions.length
    ) {
      setHoveredOptionIdx(null)
    }
  }, [filteredOptions, hoveredOptionIdx])

  const handleDoubleClick = () => {
    if (filteredOptions.length > 0) {
      setIsMenuOpen(true)
    }
  }

  return (
    <div onKeyUp={handleKeyUp} className="flex-1 d-flex position-relative">
      <Form.Control
        {...props}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyUp={preventEventOnInput}
        onKeyDown={preventEventOnInput}
        onDoubleClick={handleDoubleClick}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {isMenuOpen && (
        <MenuDropdown>
          {filteredOptions.map((option, index) => (
            <Option
              key={option}
              onClick={() => selectOptionIdx(index)}
              isHover={hoveredOptionIdx === index}
            >
              {option}
            </Option>
          ))}
        </MenuDropdown>
      )}
    </div>
  )
}

const MenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 50;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 0;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
`

const Option = styled.div<{ isHover: boolean }>`
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: ${({ isHover }) => (isHover ? '#f2f2f2' : 'transparent')};
  &:hover {
    background-color: #f2f2f2;
  }
`
