import styled from 'styled-components/macro'

export const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  user-select: none;
  margin-bottom: 8px;
`

export const Input = styled.textarea`
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 15px;
  line-height: 1;
  cursor: text;
  height: 103px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`
