import FbButton from 'components/FbUI/FbButton'
import { cn } from 'components/UI/cn'
import React from 'react'

export interface DataTableFilterButtonsProps {
  isActive: boolean
  open: boolean
  title: string
  activeValueLabel: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const FilterButtonComponent = React.forwardRef(
  (props: DataTableFilterButtonsProps, ref) => {
    return (
      <FbButton
        ref={ref}
        variant={'secondary'}
        type="button"
        className={cn(
          'flex-1 w-100 relative border-gray-300 text-[#344054] h-[40px] hover:text-[#344054]',
          {
            'bg-[#F9F9FB]': props.open,
            'border-[#5a755e]': props.isActive,
          }
        )}
        onClick={props.onClick}
      >
        <div
          className={cn('flex items-center w-full', {
            'pr-8': props.isActive,
          })}
        >
          <span
            className={
              'overflow-hidden whitespace-nowrap overflow-ellipsis shrink-0'
            }
          >
            {props.title}:&nbsp;
          </span>
          <b className={'overflow-hidden whitespace-nowrap overflow-ellipsis'}>
            {props.activeValueLabel}
          </b>
        </div>
      </FbButton>
    )
  }
)
FilterButtonComponent.displayName = 'FilterButton'

export const FilterButton = FilterButtonComponent
