import styled from 'styled-components/macro'
import { CSSProperties } from 'react'

interface TabsProps<T> {
  tabs: {
    key: T
    title: string | JSX.Element
  }[]
  selectedTab: T
  setSelectedTab: (tab: T) => void
  style?: CSSProperties
  tabStyle?: CSSProperties
  isSmall?: boolean
}

export function Tabs<T>({
  tabs,
  selectedTab,
  setSelectedTab,
  style,
  tabStyle,
  isSmall,
}: TabsProps<T>) {
  return (
    <TabContainer style={style}>
      {tabs.map(({ key, title }) => (
        <Tab
          key={String(key)}
          onClick={() => setSelectedTab(key as T)}
          isActive={selectedTab === key ? 1 : 0}
          isSmall={isSmall ? 1 : 0}
          style={tabStyle}
        >
          {title}
        </Tab>
      ))}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  gap: 25px;
`

const Tab = styled.div<{ isActive: number; isSmall: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${(props) => (props.isSmall === 1 ? '13px' : '14px')};
  font-style: normal;
  font-weight: ${(props) =>
    props.isActive === 1 ? (props.isSmall ? '400' : '500') : '300'};
  text-underline-offset: 12px;
  line-height: 20px;
  border-bottom: ${(props) => (props.isActive === 1 ? '2px solid' : 'none')};
  padding: 6px 12px;
  cursor: pointer;
  white-space: nowrap;

  // Disable text selection
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &.selected {
    border-bottom: 2px solid #3c5e19;
  }

  &:hover {
    border-bottom: 2px solid;
  }
`
