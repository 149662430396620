import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import {
  ContactCountRangeFilter,
  DaysSinceLastUpdateRangeFilter,
  NoteCountRangeFilter,
  RangeFilter,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { useCategorySubcategoryOptions } from 'hooks/useCategorySubcategoryFilterOptions'
import apiService from 'services/api'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from '../../Filters/FilterStore'

export function DealsFilterset(props: { filterIdentifier: FilterIdentifier }) {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: productsOptions } = api.useGetProductsOptions()
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)
  const { data: contactTagsOptions } = api.useGetTagsOptions(true, 'contacts')
  const dealsFilterStore = filterStoreRepo.getStore(props.filterIdentifier)
  const { categoriesOptions, subCategoriesOptions } =
    useCategorySubcategoryOptions({ filterStore: dealsFilterStore })

  return (
    <FilterModal store={dealsFilterStore} identifier={props.filterIdentifier}>
      <FilterGroup title={'Opportunity Details'}>
        <RangeFilter
          title={FILTER_TITLES[props.filterIdentifier].company_size}
          filterKey="company_size"
          store={dealsFilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[props.filterIdentifier].category}
          filterKey={'category'}
          filterStore={dealsFilterStore}
          options={categoriesOptions}
        />
        <SelectFilter
          title={FILTER_TITLES[props.filterIdentifier].sub_category}
          filterKey={'sub_category'}
          filterStore={dealsFilterStore}
          options={subCategoriesOptions}
        />
      </FilterGroup>
      <FilterGroup title="Account">
        <SelectFilter
          filterKey="product"
          title={FILTER_TITLES[props.filterIdentifier].product}
          options={productsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          filterKey="sales_stage"
          title={FILTER_TITLES[props.filterIdentifier].sales_stage}
          options={stageOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          filterKey="account_owner"
          title={FILTER_TITLES[props.filterIdentifier].account_owner}
          options={userOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          filterKey="tags"
          title={FILTER_TITLES[props.filterIdentifier].tags}
          options={tagsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          filterKey="contact_tags"
          title={FILTER_TITLES[props.filterIdentifier].contact_tags}
          options={contactTagsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          filterKey="distributors"
          title={FILTER_TITLES[props.filterIdentifier].distributors}
          options={distributorOptions as any}
          filterStore={dealsFilterStore}
        />
        <NoteCountRangeFilter store={dealsFilterStore} />
        <ContactCountRangeFilter store={dealsFilterStore} />
      </FilterGroup>
      <FilterGroup title="Predicted Values">
        <RangeFilter
          filterKey="predicted_volume"
          decimalScale={1}
          store={dealsFilterStore}
          title={FILTER_TITLES[props.filterIdentifier].predicted_volume}
        />
        <RangeFilter
          title={FILTER_TITLES[props.filterIdentifier].predicted_revenue}
          filterKey="predicted_revenue"
          store={dealsFilterStore}
        />
        <DateRangeFilter
          title={FILTER_TITLES[props.filterIdentifier].estimated_close_date}
          filterKey="estimated_close_date"
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Overrides">
        <RangeFilter
          title={FILTER_TITLES[props.filterIdentifier].monthly_volume_override}
          filterKey="monthly_volume_override"
          decimalScale={1}
          store={dealsFilterStore}
        />
        <RangeFilter
          title={FILTER_TITLES[props.filterIdentifier].monthly_revenue_override}
          filterKey="monthly_revenue_override"
          store={dealsFilterStore}
        />
        <DateRangeFilter
          title={FILTER_TITLES[props.filterIdentifier].close_date_override}
          filterKey="close_date_override"
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="User Activity">
        <DateRangeFilter
          title={FILTER_TITLES[props.filterIdentifier].modified}
          filterKey="modified"
          filterStore={dealsFilterStore}
        />
        <DateRangeFilter
          title={FILTER_TITLES[props.filterIdentifier].created}
          filterKey="created"
          filterStore={dealsFilterStore}
        />
        <RangeFilter
          filterKey="time_in_stage"
          decimalScale={1}
          store={dealsFilterStore}
          title={FILTER_TITLES[props.filterIdentifier].time_in_stage}
          minAllowNegative={false}
          maxAllowNegative={false}
        />
        <DaysSinceLastUpdateRangeFilter store={dealsFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
