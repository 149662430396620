import { useEffect, useRef, useState } from 'react'
import * as S from './NotificationButton.styles'
import { NotificationModal } from './NotificationModal'
import apiService from 'services/api'

export const NotificationButton = () => {
  const [show, setShow] = useState(false)
  const [stickRight, setStickRight] = useState(false)
  const api = apiService()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      if (rect.left + 320 + 20 > window.innerWidth) {
        setStickRight(true)
      } else {
        setStickRight(false)
      }
    }
  }, [ref.current])

  const { data } = api.useGetUnreadNotificationsCount()

  return (
    <div className="click-outside" ref={ref}>
      <div className="position-relative">
        <S.BellIcon onClick={() => setShow((prev) => prev === false)} />
        {!!data?.count && <S.Badge>{data?.count}</S.Badge>}
      </div>
      {show && (
        <NotificationModal
          onClickOutside={() => setShow(false)}
          clickOutsideClassName=".click-outside"
          stickToRight={stickRight}
        />
      )}
    </div>
  )
}
