import FbButton, { FbButtonVariants } from 'components/FbUI/FbButton'
import { BsCheck, BsChevronDown } from 'react-icons/bs'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../BaseDropdown/dropdown-base'
import React from 'react'
import { cn } from '../cn' // eslint-disable-next-line @typescript-eslint/no-explicit-any

export interface ISelectItem<T = any> {
  label: string
  icon?: React.JSX.Element
  value?: T
}

export interface ISelectProps<T> {
  items?: ISelectItem<T>[]
  selected?: T
  onSelect?: (item: T) => void
  error?: string
  nullSelectionLabel?: string
  buttonLabel?: string | React.JSX.Element
  buttonLabelClassName?: string
  buttonIcon?: React.JSX.Element
  buttonVariant?: FbButtonVariants
  menuLabel?: string
  disabled?: boolean
  defaultSelection?: T
  className?: string
  buttonClassName?: string
  disableChevronIcon?: boolean
}

export function Select<T>(props: ISelectProps<T>) {
  function handleSelect(item: ISelectItem) {
    if (item.value === props?.selected) {
      if (props.defaultSelection) {
        props.onSelect?.(props.defaultSelection)
        return
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.onSelect?.(undefined)
        return
      }
    }
    props.onSelect?.(item.value)
  }

  const itemSelected = props.items?.find(
    (item) => item.value === props.selected
  )

  const selectionLabel =
    itemSelected?.label ?? props.nullSelectionLabel ?? 'All'

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={props.disabled} asChild>
        <FbButton
          className={cn('flex-1 border-[#DEE2E6]', props.buttonClassName)}
          variant={props.buttonVariant}
        >
          <div
            className={cn(
              'flex items-center justify-between gap-2 flex-1 min-w-0'
            )}
          >
            <div
              className={'flex items-center justify-start w-[90%] max-w-[90%]'}
            >
              {props.buttonIcon}
              {props?.buttonLabel ? (
                <>
                  <b className={cn('truncate', props.buttonLabelClassName)}>
                    {props.buttonLabel}:&nbsp;
                  </b>
                </>
              ) : null}
              {selectionLabel && (
                <span
                  className={
                    'text-nowrap overflow-ellipsis overflow-hidden max-w-full'
                  }
                >
                  {selectionLabel}
                </span>
              )}
            </div>
            {props.disableChevronIcon ? null : (
              <div>
                <BsChevronDown />
              </div>
            )}
          </div>
        </FbButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={cn(
          'w-56',
          {
            'opacity-50': props.disabled,
            'border-red-400': props.error,
          },
          props.className
        )}
      >
        {props.menuLabel ? (
          <>
            <DropdownMenuLabel>{props.menuLabel}</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuGroup>
          {props.items?.map((item) => {
            return (
              <DropdownMenuItem
                onClick={() => handleSelect(item)}
                key={item.label}
              >
                <div
                  className={'flex items-center justify-between gap-2 w-full'}
                >
                  <div className={'flex items-center gap-2'}>
                    {item.icon}
                    {item.label}
                  </div>
                  <div>{props.selected === item.value && <BsCheck />}</div>
                </div>
              </DropdownMenuItem>
            )
          })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
