export const getTableQueryKey = (options: {
  tableKey: string

  filterParams: any
  page?: number

  products?: any
}) => [
  options.tableKey,
  JSON.stringify(options.filterParams),
  String(options.page ?? 1),
  String(options.products ?? -1), // -1 is no product filter
]
