import { useDataTableContext } from '../../components/DataTable'
import apiService from '../../services/api'
import lodash from 'lodash'
import confirm from '../../components/dialogConfirm'
import { CampaignAssignRequestCompanyType } from './campaigns_api'

interface CampaignUnassignerProps {
  accessorKey: string
  tableQueryKey: string[]
  requestParams: Record<string, any>
  campaignId: number
  data: any[]
}

export function useCampaignUnassigner(
  companyType: CampaignAssignRequestCompanyType,
  props: CampaignUnassignerProps
) {
  const api = apiService()

  const mutation = api.useUnassignToCampaignMutation(
    companyType,
    props.tableQueryKey,
    props.accessorKey
  )

  const {
    state: { rowSelection, isAllRowsSelected },
  } = useDataTableContext()

  async function handleSubmit() {
    const selectedRows = props.data?.filter((_, i) => {
      return Boolean(rowSelection?.[i])
    })

    const selectedChainProxyIds = selectedRows?.map((r: any) =>
      lodash.get(r, props.accessorKey)
    ) as number[]

    const confirmed = await confirm(
      `Confirm unassigning ${selectedChainProxyIds.length} companies from campaign?`,
      'Unassign companies from Campaign'
    )

    if (!confirmed) {
      return
    }

    await mutation.mutateAsync({
      campaign_id: props.campaignId,
      chain_proxy_ids: isAllRowsSelected ? undefined : selectedChainProxyIds,
      params: props.requestParams,
    })
  }

  return handleSubmit
}
