import { GreenBold } from 'components/Tables/ActivityTableV2/CellEvent/CellEventFormatter'

export const DefaultAddOrRemoveEvent = ({
  action,
  fieldName,
  values,
}: {
  action: string
  fieldName: string
  values: string | React.ReactNode
}) => {
  return (
    <>
      {action} new <GreenBold>{fieldName}</GreenBold>: <b>{values}</b>
    </>
  )
}
