import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import React, { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import apiService from 'services/api'
import { formatUsd } from 'utils/formatting'
import { TableSearch } from '../../Filters/TableSearch'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { SalesStageCell } from '../../FbUI/StagePill'
import DealsPipelineTableFilters from './DealsPipelineFilterset'
import { DealsPipeline } from 'models/deals_pipeline'
import moment from 'moment'
import styled from 'styled-components'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import {
  dealsPipelineFilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { BsDownload } from 'react-icons/bs'
import FbButton from 'components/FbUI/FbButton'
import PipelineExportModal from 'components/Modals/ExportModal/PipelineExportModal'
import { getCompanyUrlFromDealPipeline } from 'utils/getCompanyUrl'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'

interface DealsPipelineTableProps {
  tableKey: string
}

function DealsPipelineTableComponent(props: DealsPipelineTableProps) {
  const {
    state: { sorting },
    methods: { clearSelectedRows },
  } = useDataTableContext()
  const filtersAsParams = useFilterParams(dealsPipelineFilterStore)

  const PAGE_SIZE = 20

  const [pagination] = usePaginationURLParams(PAGE_SIZE)

  const api = apiService()
  const { preferences } = usePreferences()

  const tooltips: Record<string, any> = preferences?.tooltips || {}

  const [showExportModal, setShowExportModal] = useState(false)

  const filterParams = useMemo(() => {
    const params: Record<string, any> = filtersAsParams

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting, filtersAsParams])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterParams,
    page: pagination.pageIndex,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const fullParams = {
        ...filterParams,
        page: pagination.pageIndex + 1,
        has_stage: true,
      }

      return await api.getDealsPipeline(fullParams, signal)
    },
  })

  const columns = useMemo<ColumnDef<DealsPipeline, any>[]>(() => {
    return [
      {
        accessorKey: 'company_name',
        meta: {
          tooltip: tooltips?.['dealspipeline_company_name'],
        },
        header: 'Company',
        cell: (info) => {
          if (!info.getValue()) return '-'

          return (
            <Link
              to={getCompanyUrlFromDealPipeline(info.row.original)}
              target="_blank"
            >
              {info.getValue()}
            </Link>
          )
        },
        size: 200,
      },
      {
        accessorKey: 'count',
        meta: { tooltip: tooltips?.['dealspipeline_count'] },
        header: 'Deals',
        size: 100,
        cell: (info) => {
          return info.getValue()
        },
      },

      {
        accessorKey: 'closed',
        meta: { tooltip: tooltips?.['dealspipeline_closed'] },
        header: 'Deals Closed',
        size: 100,
        cell: (info) => {
          return info.getValue()
        },
      },

      {
        // Sorting is done by id
        id: 'sales_stage',
        accessorKey: 'sales_stages',
        meta: { tooltip: tooltips?.['dealspipeline_sales_stages'] },
        header: 'Sales Stages',
        cell: (info) => {
          const salesStageIds = info
            .getValue()
            ?.map((it: { sales_stage: { id: string } }) =>
              parseInt(it.sales_stage.id)
            )
          if (!salesStageIds) return '-'
          return <SalesStageCell salesStagesIds={salesStageIds} />
        },
      },
      {
        accessorKey: 'taro',
        meta: { tooltip: tooltips?.['dealspipeline_taro'] },
        header: 'Annual Revenue Opportunity',
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{formatUsd(info.getValue())}</div>
        },
      },
      {
        accessorKey: 'taro_closed',
        meta: { tooltip: tooltips?.['dealspipeline_taro_closed'] },
        header: 'Annual Revenue Opportunity (Closed)',
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{formatUsd(info.getValue())}</div>
        },
      },
      {
        accessorKey: 'last_modified',
        meta: { tooltip: tooltips?.['dealspipeline_last_modified'] },
        header: 'Last Update',
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{moment(info.getValue()).format('MMM DD, YYYY')}</div>
        },
      },
      {
        accessorKey: 'days_since_last_modified',
        meta: { tooltip: tooltips?.['dealspipeline_days_since_last_modified'] },
        header: 'Days Since Last Update',
        cell: (info) => {
          if (!info.getValue()) return '-'
          return info.getValue()
        },
      },
    ] satisfies ColumnDef<DealsPipeline, any>[]
  }, [data])

  if (isFetching || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <TableContainer>
        <h2 className="uppercase font-light text-2xl text-gray-700">
          Companies in Pipeline
        </h2>

        <hr className="border-gray-400 mb-6 mt-2" />

        <SearchContainer>
          <TableSearch
            filterStore={dealsPipelineFilterStore}
            searchPlaceholder="Search by chain name"
          />
          <DealsPipelineTableFilters />
        </SearchContainer>

        <div className="mb-2">
          <FilterChips
            identifier={'DEALS_PIPELINE_TABLE'}
            store={dealsPipelineFilterStore}
            showActive
          />
        </div>

        <PipelineExportModal
          count={data?.length ?? 0}
          show={showExportModal}
          _handleClose={() => setShowExportModal(false)}
          filters={filtersAsParams}
        />

        <DataTable
          tableKey={props.tableKey}
          loading={isFetching}
          data={data ?? []}
          columns={columns}
          sortableFields="all"
          virtualizeRows
          enableRowSelection={false}
          defaultSort={[{ id: 'last_modified' as any, desc: true }]}
          footerControls={
            <ExportButton
              variant="secondary"
              onClick={() => setShowExportModal(true)}
            >
              <BsDownload size={18} />
            </ExportButton>
          }
        />
      </TableContainer>
    </>
  )
}

export function DealsPipelineTable(props: DealsPipelineTableProps) {
  const { tableKey, ...rest } = props

  return (
    <ColumnsStoreProvider tableKey={tableKey}>
      <DataTableProvider tableKey={tableKey}>
        <DealsPipelineTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: 1000px;
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`

const ExportButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
