import styled, { css } from 'styled-components/macro'
import { BsBell } from 'react-icons/bs'

const SharedButton = css`
  cursor: pointer;
  color: white;
  font-size: 28px;
  height: 45px;
  width: 45px;
  padding: 10px;
  &:hover {
    background: rgb(44, 107, 78);
  }
`

export const BellIcon = styled(BsBell)`
  ${SharedButton}
`

export const Badge = styled.div`
  background: #fcbc05;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 2px;

  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
`
