import {
  FilterStore,
  RangeFilter,
  ValueFilter,
} from 'components/Filters/FilterStore'
import Checkbox from '../../Checkbox/checkbox'
import { useEffect } from 'react'
import styled from 'styled-components'

interface QuickFilterProps {
  title: string
  valueLabel: string
  filterKey: string
  filterStore: FilterStore
}

interface QuickRangeFilterProps extends QuickFilterProps {
  predefinedValue: RangeFilter
}

export function QuickRangeFilter({
  title,
  valueLabel,
  filterKey,
  predefinedValue,
  filterStore,
}: QuickRangeFilterProps) {
  const {
    pendingRangeFilters: pending,
    setPendingRangeFilters: setPending,
    clearPendingRangeFilter: clearPending,
    registerFilterTitle,
  } = filterStore()

  const isActiveValueEqual = (a: RangeFilter, b: RangeFilter) => {
    if (!a || !b) return false
    return a.max == b.max && a.min == b.min
  }

  const isChecked: boolean = isActiveValueEqual(
    pending[filterKey],
    predefinedValue
  )

  const removeFilter = () => {
    clearPending(filterKey)
  }

  const addFilter = () => {
    setPending(filterKey, predefinedValue.min, predefinedValue.max)
  }

  useEffect(() => {
    registerFilterTitle(filterKey, title)
  }, [])

  return (
    <QuickFilter
      label={title}
      valueLabel={valueLabel}
      isChecked={isChecked}
      addFilter={addFilter}
      removeFilter={removeFilter}
    />
  )
}

interface QuickValueFilterProps extends QuickFilterProps {
  predefinedValue: ValueFilter[]
}

export function QuickValueFilter({
  title,
  valueLabel,
  filterKey,
  predefinedValue,
  filterStore,
}: QuickValueFilterProps) {
  const {
    pendingValueFilters: pending,
    setPendingValueFilters: setPending,
    clearPendingValueFilter: clearPending,
    registerFilterTitle,
  } = filterStore()

  const isActiveValueEqual = (a: ValueFilter[], b: ValueFilter[]) => {
    if (!a || !b) return false
    return a.length === b.length && a.every((v, i) => v.value == b[i].value)
  }

  const isChecked: boolean = isActiveValueEqual(
    pending[filterKey],
    predefinedValue
  )

  const removeFilter = () => {
    clearPending(filterKey)
  }

  const addFilter = () => {
    setPending(filterKey, predefinedValue)
  }

  useEffect(() => {
    registerFilterTitle(filterKey, title)
  }, [])

  return (
    <QuickFilter
      label={title}
      valueLabel={valueLabel}
      isChecked={isChecked}
      addFilter={addFilter}
      removeFilter={removeFilter}
    ></QuickFilter>
  )
}

function QuickFilter({
  label,
  valueLabel,
  isChecked,
  addFilter,
  removeFilter,
}: {
  label: string
  valueLabel: string
  isChecked: boolean
  addFilter: () => void
  removeFilter: () => void
}) {
  const onChange = () => {
    if (isChecked) {
      removeFilter()
    } else {
      addFilter()
    }
  }

  return (
    <Container>
      <Checkbox id={label} checked={isChecked} onChange={onChange} />
      <label htmlFor={label} style={{ marginLeft: 8 }}>
        {label}: {valueLabel}
      </label>
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  :hover,
  & > *:hover {
    cursor: pointer;
  }
`
