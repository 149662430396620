import styled from 'styled-components/macro'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import to from 'await-to-js'
import { useForm } from 'react-hook-form'
import { TextInput } from 'components/FormUtils/TextInput'
import FbButton from 'components/FbUI/FbButton'
import { useAuth } from 'context/authentication/useAuth'
import { handleFormError } from 'utils/handleFormError'

const resendEmailFormShape = z.object({
  email: z.string().email(),
})

function Failed() {
  const { resendVerify } = useAuth()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(resendEmailFormShape),
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const [err] = await to(resendVerify(values.email))
      handleFormError(err, setError)
    },
    (errors) => console.warn(errors)
  )

  return (
    <div style={{ width: '400px', flexShrink: 0 }}>
      <Title>Email Verification Failed</Title>
      <p>Your email has failed to verify!</p>
      <p>
        <form onSubmit={onSubmit}>
          <TextInput
            className="mb-2"
            control={control}
            name="email"
            label="Email"
          />
          <div className="flex justify-end">
            <FbButton type="submit" loading={isSubmitting}>
              Resend Verification Email
            </FbButton>
          </div>
        </form>
      </p>
    </div>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

export default Failed
