import * as S from './CreateDealModal.styles'
import { Spinner } from 'react-bootstrap'
import React, { useEffect, useMemo } from 'react'
import { DealCreate } from 'models/deals'
import dayjs from 'dayjs'
import apiService from 'services/api'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { TextInput } from 'components/FormUtils/TextInput'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { toast } from 'react-toastify'
import { formatInteger, formatUsdDecimal } from '../../../utils/formatting'
import { Modal } from '../../UI/Modal/Modal'
import Select from '../../FormUtils/Select'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { paramsContainAccountFilters } from 'utils/filterUtils'

function getCreateDealFormSchema() {
  const dealSchema = {
    create: z.boolean(),
    product: z.number(),
    monthly_volume_override: z.number().min(0).optional().nullable(),
    monthly_revenue_override: z.number().min(0).optional().nullable(),
    close_date_override: z.string().optional().nullable(),
    account_owner: z.number().optional().nullable(),
    sales_stage: z.number().optional().nullable(),
  }

  const dealsFormSchema = z
    .object({
      deals: z.array(z.object(dealSchema)),
    })
    .superRefine((v, ctx) => {
      const dealsToCreate = v.deals.filter((deal) => deal.create)

      if (dealsToCreate.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one deal must be selected',
          path: ['custom'],
        })
      }

      v.deals.forEach((deal) => {
        if (!deal.create) return

        if (
          !deal.monthly_revenue_override &&
          !deal.monthly_volume_override &&
          !deal.close_date_override
        ) {
          return
        }

        if (
          !deal.monthly_volume_override ||
          !deal.monthly_revenue_override ||
          !deal.close_date_override
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'If any of the override fields are set, all must be set',
            path: ['custom'],
          })
        }
      })
    })

  return dealsFormSchema
}

export default function CreateDealModal({
  isUserCompany = false,
  show,
  handleClose,
  companyIds,
  chainsIds,
  onDealCreated,
  submitButtonText = 'Create Deal',
  cancelButtonText = 'Cancel',
  queryKey = [],
  origin,
  filterAndSortParams,
}: {
  isUserCompany?: boolean
  companyIds?: number[]
  chainsIds?: number[]
  handleClose: () => void
  onDealCreated?: () => void
  show: boolean
  submitButtonText?: string
  cancelButtonText?: string
  // These relate to optimistic UI updates
  queryKey?: string[]
  origin: 'companies' | 'chains' | 'create-company' | 'deals-section' | 'k12'

  filterAndSortParams?: Record<string, any>
}) {
  const queryClient = useQueryClient()
  const api = apiService()
  const { data: stageOptions } = api.useGetSalesStageOptions()
  const { data: userOptions } = api.useGetUserOptions()

  const dealsFormSchema = getCreateDealFormSchema()
  type DealsFormValues = z.infer<typeof dealsFormSchema>

  // A company can have predictions appear on the deal creation modal if:
  // 1. We're creating the deal for a single company.
  // 2. The company has a chain assotiacted with it, which means it has LTV data.
  const canHavePredictions =
    (companyIds?.length === 1 || chainsIds?.length === 1) && !isUserCompany

  const { data: predictions, isLoading: isLoadingPredictions } =
    api.useGetProductPredictions({
      companyId: companyIds?.[0],
      chainId: chainsIds?.[0],
      enabled: canHavePredictions,
    })

  const { data: products, isLoading: isLoadingProducts } =
    api.useProducts(canHavePredictions)
  const data = canHavePredictions ? predictions : products
  const isLoading =
    (isLoadingPredictions && canHavePredictions) ||
    (isLoadingProducts && !canHavePredictions)

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(dealsFormSchema),
    defaultValues: { deals: [] } as DealsFormValues,
    mode: 'onChange',
  })

  useEffect(() => {
    if (!predictions) return
    const deals = predictions.map((prediction) => {
      return {
        create: false,
        product: prediction.product.id,
        monthly_volume_override: prediction.deal?.monthly_volume_override,
        monthly_revenue_override: prediction.deal?.monthly_revenue_override,
        close_date_override: prediction.deal?.close_date_override
          ? dayjs(prediction.deal?.close_date_override).format('YYYY-MM-DD')
          : undefined,
      }
    })
    reset({ deals })
  }, [predictions])

  useEffect(() => {
    if (!products) return
    const deals = products.map((product) => {
      return {
        create: false,
        product: product.id,
        monthly_volume_override: undefined,
        monthly_revenue_override: undefined,
        close_date_override: undefined,
        account_owner: undefined,
        sales_stage: undefined,
      }
    })
    reset({ deals })
  }, [products])

  const disabledRowsIdx = useMemo(() => {
    const disabledRows: number[] = []
    predictions?.forEach((prediction, idx) => {
      if (prediction.deal) {
        disabledRows.push(idx)
      }
    })
    return disabledRows
  }, [predictions])

  const mutation = useMutation({
    mutationFn: async (data: {
      contact_company_ids?: number[]
      chain_ids?: number[]
      deals: Partial<DealCreate>[]
    }) => {
      return await api.dealsBulk(data)
    },
    onMutate: async (newDeals) => {
      await queryClient.cancelQueries({ queryKey: queryKey })

      let prev: any
      switch (origin) {
        case 'chains':
          {
            prev = queryClient.getQueryData(queryKey)

            queryClient.setQueryData(queryKey, (old: any) => {
              if (!newDeals.chain_ids) return old

              for (const chainId of newDeals.chain_ids) {
                const rowIdx = old?.results?.findIndex(
                  (row: any) => row.id === chainId
                )
                const row = old?.results?.[rowIdx]
                for (const deal of newDeals.deals) {
                  const existingDeal = row?.deals?.findIndex(
                    (d: any) => d.product === deal.product
                  )
                  if (row.deals) {
                    if (existingDeal != -1) {
                      row.deals[existingDeal] = deal
                    } else {
                      row.deals.push(deal)
                    }
                  } else {
                    row.deals = [deal]
                  }
                }

                row.deal_count = row.deals?.length
              }

              return old
            })
          }
          break
      }

      return { prev }
    },
    onError: (err, newTodo, context) => {
      if (!context) return
      queryClient.setQueryData(queryKey, context.prev)
      toast.error('Error creating deals')
    },
    onSuccess: () => {
      toast.success('Deals created')
    },
    onSettled: () => {
      onDealCreated?.()
      handleClose()
      reset()
      if (queryKey && paramsContainAccountFilters(filterAndSortParams)) {
        queryClient.invalidateQueries({ queryKey: queryKey })
      }
    },
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const dealsToCreate: Partial<DealCreate>[] = values.deals
        .filter((deal) => deal.create)
        .map((deal) => {
          return {
            product: deal.product,
            monthly_volume_override: deal.monthly_volume_override ?? undefined,
            monthly_revenue_override:
              deal.monthly_revenue_override ?? undefined,
            close_date_override: deal.close_date_override ?? undefined,
            sales_stage: deal.sales_stage ?? undefined,
            account_owner: deal.account_owner ?? undefined,
          }
        })

      await mutation.mutateAsync({
        deals: dealsToCreate,
        chain_ids: chainsIds,
        contact_company_ids: companyIds,
      })
    },
    (err) => console.warn(err)
  )

  return (
    <Modal
      open={show}
      onOpenChange={(open) => !open && handleClose()}
      size="2xl"
      onAccept={onSubmit}
      loading={isSubmitting}
      title={'Add Deals'}
      description={`Deals allow you to track specific revenue opportunities throughout your
        sales pipeline. Each deal is done on a per product basis. For deals with unmatched
        companies, you can provide your own volume, revenue, and close date estimates.`}
      acceptButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center overflow-hidden">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <S.TableHeader className="mb-2">Product Interests</S.TableHeader>
          <table className={'deals-modal-table'}>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                ></th>
                <th>Product</th>
                {canHavePredictions && (
                  <>
                    <th>Monthly Volume</th>
                    <th>Monthly Revenue</th>
                  </>
                )}

                <th>Monthly Volume (override)</th>
                <th>Monthly Revenue (override)</th>

                <th>Close Date (override)</th>
                <th>Sales Stage</th>
                <th>Account Owner</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div>
                        <Checkbox
                          control={control}
                          name={`deals.${index}.create`}
                          label=""
                          disabled={
                            disabledRowsIdx.includes(index) &&
                            !canHavePredictions
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        {canHavePredictions ? item.product.name : item.name}
                      </div>
                    </td>

                    {canHavePredictions && (
                      <>
                        <td>
                          <div>
                            {`${formatInteger(item.predicted_volume ?? 0)} lbs`}
                          </div>
                        </td>
                        <td>
                          <div>{formatUsdDecimal(item.predicted_revenue)}</div>
                        </td>
                      </>
                    )}

                    <td>
                      <div>
                        <TextInput
                          noMargin
                          control={control}
                          name={`deals.${index}.monthly_volume_override`}
                          type="number"
                          placeholder="### lbs."
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <TextInput
                          noMargin
                          control={control}
                          name={`deals.${index}.monthly_revenue_override`}
                          type="number"
                          placeholder="$"
                        />
                      </div>
                    </td>

                    <td>
                      <div>
                        <TextInput
                          noMargin
                          control={control}
                          name={`deals.${index}.close_date_override`}
                          type="date"
                        />
                      </div>
                    </td>
                    <td>
                      <Select
                        options={stageOptions}
                        control={control}
                        name={`deals.${index}.sales_stage`}
                      />
                    </td>
                    <td>
                      <Select
                        options={userOptions}
                        control={control}
                        name={`deals.${index}.account_owner`}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
      <span style={{ color: '#8c0c0c' }}>
        {/*// @ts-expect-error ignore */}
        {errors?.['custom']?.message}
      </span>
    </Modal>
  )
}
