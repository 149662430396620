import React, { ReactNode, useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { Form, Spinner } from 'react-bootstrap'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import FbButton from '../../FbUI/FbButton'

export interface Option {
  icon?: ReactNode
  label: string
  value: unknown
}

interface DropdownSingleProps {
  options: Option[]
  search: string
  handleOptionClick: (o: Option | undefined) => void
  setSearch: (search: string) => void
  enableSearch: boolean
  enableClear?: boolean
  isOpen: boolean
  loading?: boolean
  nullOption?: Option
  selectedOption?: Option
}

export function DropdownSingle({
  isOpen,
  loading,
  options,
  search,
  handleOptionClick,
  setSearch,
  enableSearch,
  enableClear,
  nullOption,
  selectedOption,
}: DropdownSingleProps) {
  return (
    <S.DropdownContainer isOpen={isOpen && !loading}>
      {enableSearch && (
        <Form.Control
          type="text"
          onChange={(e: any) => {
            setSearch(e.target.value)
          }}
          placeholder="Search"
        />
      )}
      {enableClear && (
        <FbButton
          onClick={() => handleOptionClick(undefined)}
          style={{
            marginTop: '10px',
            width: '100%',
          }}
        >
          Clear
        </FbButton>
      )}

      <S.OptionsScroll>
        <S.OptionsContainer>
          {options
            .filter((o) => o.label.toLowerCase().includes(search.toLowerCase()))
            .map((o) => {
              return (
                <S.Option
                  isSelected={o.value === selectedOption?.value}
                  key={o.label}
                  onClick={() => handleOptionClick(o)}
                >
                  {o.icon ?? null}
                  {o.label}
                </S.Option>
              )
            })}
        </S.OptionsContainer>
      </S.OptionsScroll>
      {nullOption && (
        <FbButton
          variant={'danger'}
          onClick={() => handleOptionClick(nullOption)}
          style={{
            marginTop: '10px',
            width: '100%',
          }}
        >
          {nullOption.label}
        </FbButton>
      )}
    </S.DropdownContainer>
  )
}

interface DropdownMultiProps {
  options: Option[]
  search: string
  handleOptionClick: (o: Option[] | undefined) => void
  setSearch: (search: string) => void
  enableSearch: boolean
  isOpen: boolean
  loading?: boolean
  nullOption?: Option
  selectedOption?: Option[]
  onClose?: () => void
}

export function DropdownMulti({
  isOpen,
  loading,
  options,
  search,
  handleOptionClick,
  setSearch,
  enableSearch,
  nullOption,
  selectedOption = [],
  onClose,
}: DropdownMultiProps) {
  const hasNullOption = selectedOption.some(
    (s) => s.value === nullOption?.value
  )

  function handleInternalChange(o: Option, isSelected: boolean) {
    let newSelected = [...selectedOption]

    if (nullOption && o === nullOption) {
      handleOptionClick([nullOption])

      onClose?.()
      return
    }

    if (hasNullOption) {
      newSelected = []
    }

    if (isSelected) {
      newSelected = newSelected.filter((s) => s.value !== o.value)
    } else {
      newSelected = [...newSelected, o]
    }

    handleOptionClick(newSelected)
  }

  return (
    <S.DropdownContainer isOpen={isOpen && !loading}>
      {enableSearch && (
        <Form.Control
          type="text"
          onChange={(e: any) => {
            setSearch(e.target.value)
          }}
          placeholder="Search"
        />
      )}

      <FbButton
        onClick={() => handleOptionClick(undefined)}
        style={{
          marginTop: '10px',
          width: '100%',
        }}
      >
        Clear
      </FbButton>

      <S.OptionsScroll>
        <S.OptionsContainer>
          {options
            .filter((o) => o.label.toLowerCase().includes(search.toLowerCase()))
            .map((o) => {
              const isSelected = selectedOption.some(
                (s) => s.value === o?.value
              )
              return (
                <S.Option
                  key={String(o.value)}
                  onClick={() => handleInternalChange(o, isSelected)}
                >
                  {isSelected ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                  {o.icon ?? null}
                  {o.label}
                </S.Option>
              )
            })}
        </S.OptionsContainer>
      </S.OptionsScroll>
      {nullOption && (
        <FbButton
          variant={'danger'}
          onClick={() => handleInternalChange(nullOption, hasNullOption)}
          style={{
            marginTop: '10px',
            width: '100%',
          }}
        >
          {nullOption.label}
        </FbButton>
      )}
    </S.DropdownContainer>
  )
}

type ConditionalDropdownProps =
  | {
      isMulti?: never
      onSelect: (option: Option | undefined) => void
      selected?: Option
    }
  | {
      isMulti: true
      onSelect: (option: Option[] | undefined) => void
      selected?: Option[]
    }

type DropdownProps = ConditionalDropdownProps & {
  title: string
  icon?: string
  options: Option[]
  loading?: boolean
  enableSearch?: boolean
  enableClear?: boolean
  nullOption?: Option
  closeOnSelect?: boolean
  customDisplayFunction?: (
    title: string,
    value: string | undefined
  ) => React.ReactNode
}

export function Dropdown({
  title,
  options,
  onSelect,
  loading,
  selected,
  enableSearch = true,
  enableClear = true,
  closeOnSelect = false,
  isMulti,
  nullOption,
  customDisplayFunction,
}: DropdownProps) {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const wrapperElementRef = useRef<HTMLDivElement>(null)

  // Listens for clicks outside the filter to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        open &&
        wrapperElementRef.current &&
        !wrapperElementRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  let label: string | undefined = undefined
  if (selected !== undefined) {
    if (Array.isArray(selected)) {
      if (selected.length > 3) label = '3+'
      else label = selected.map((s) => s.label).join(', ')
    } else {
      label = selected.label
    }
  }

  const displayFunction = (title: string) => {
    return (
      <span>
        {title} {label ? `(${label})` : null}
      </span>
    )
  }

  return (
    <S.Wrapper ref={wrapperElementRef}>
      <S.Button isOpen={open} onClick={() => setOpen(!open)}>
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : customDisplayFunction ? (
          customDisplayFunction(title, label)
        ) : (
          displayFunction(title)
        )}
      </S.Button>

      {!isMulti ? (
        <DropdownSingle
          isOpen={open}
          loading={loading}
          options={options}
          selectedOption={selected}
          search={search}
          handleOptionClick={(o) => {
            if (closeOnSelect) setOpen(false)
            onSelect(o)
          }}
          setSearch={setSearch}
          enableSearch={enableSearch}
          enableClear={enableClear}
          nullOption={nullOption}
        />
      ) : (
        <DropdownMulti
          isOpen={open}
          loading={loading}
          options={options}
          search={search}
          handleOptionClick={(o) => {
            if (closeOnSelect) setOpen(false)
            onSelect(o)
          }}
          selectedOption={selected}
          setSearch={setSearch}
          enableSearch={enableSearch}
          nullOption={nullOption}
          onClose={() => setOpen(false)}
        />
      )}
    </S.Wrapper>
  )
}
