import apiService from 'services/api'
import WarningBanner from './WarningBanner'
import { AsyncJobStatus, AsyncJobType } from 'models/asyncJobs'
import { useMemo } from 'react'

export function LTVProcessingBanner() {
  const api = apiService()
  const { data } = api.useGetAsyncJobs({
    job_type: AsyncJobType.FILL_LTV_TABLE,
    status: AsyncJobStatus.IN_PROGRESS,
  })
  const hasInProgressJob = useMemo(() => {
    return data ? data.count > 0 : false
  }, [data])

  if (!hasInProgressJob) {
    return null
  }

  return (
    <WarningBanner text="LTV is being processed. You might not see the latest data." />
  )
}
