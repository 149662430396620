import styled from 'styled-components/macro'

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StatsCard = styled.div`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding-top: 8px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: 125px;
  height: 74px;

  & h1 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    white-space: nowrap;
  }

  & span {
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
    color: #101828;
  }
`
