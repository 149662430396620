import Select from 'components/FormUtils/Select'
import { useForm } from 'react-hook-form'
import { Modal } from '../../../UI/Modal/Modal'
import React from 'react'

export interface Item<T> {
  label: string
  value: number
  original?: T
}

export interface SelectModalProps<T> {
  title: string | React.JSX.Element
  subtitle?: string | React.JSX.Element
  label: string
  placeholder: string
  items: Item<T>[]
  show?: boolean
  handleClose?: () => void
  onApply?: (item?: T) => Promise<void>
  isLoading?: boolean
}

export default function SelectModal<T>({
  title,
  subtitle,
  label,
  placeholder,
  items,
  show,
  handleClose,
  onApply,
  isLoading,
}: SelectModalProps<T>) {
  const { handleSubmit, control } = useForm<{ item?: Item<T>['value'] }>({
    defaultValues: { item: undefined },
    mode: 'onChange',
  })

  const onSubmit = handleSubmit(async ({ item }) => {
    const itemOriginal = items.find((i) => i.value === item)?.original
    await onApply?.(itemOriginal)
  })

  return (
    <Modal
      title={title}
      description={subtitle}
      onOpenChange={(open) => !open && handleClose?.()}
      open={show}
      onAccept={onSubmit}
      acceptButtonText="Apply"
      onCancel={handleClose}
      cancelButtonText="Cancel"
      loading={isLoading}
    >
      <Select
        name="item"
        control={control}
        options={items}
        label={label}
        placeholder={placeholder}
      />
    </Modal>
  )
}
