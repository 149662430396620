import axios from 'axios'
import { ErrorOption } from 'react-hook-form'

export function handleFormError(
  err: any,
  setError: (
    name: any,
    error: ErrorOption,
    options?: { shouldFocus: boolean } | undefined
  ) => void
) {
  console.warn(err)
  if (err) {
    if (axios.isAxiosError(err)) {
      const errors = err.response?.data as Record<string, string[]>
      Object.entries(errors).forEach(([key, value]) => {
        setError(key as any, {
          message: typeof value === 'string' ? value : value[0],
        })
      })
    }
  }
}
