export function hasIntersection(arr1: Array<any>, arr2: Array<any>) {
  const hashTable: Record<string, boolean> = {}

  // Populate the hash table with elements from arr1
  for (const item of arr1) {
    hashTable[item] = true
  }

  // Check if any element from arr2 exists in the hash table
  for (const item of arr2) {
    if (hashTable[item]) {
      return true // Intersection found
    }
  }

  return false // No intersection found
}
