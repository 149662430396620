import React from 'react'
import { ITooltip, Tooltip } from './tooltip'
import { MdHelp } from 'react-icons/md'

export function HelpTooltip(props: {
  content?: ITooltip['content']
  title?: ITooltip['title']
  link?: ITooltip['link']
}) {
  if (!props.content) return null
  return (
    <Tooltip {...props}>
      <MdHelp color={'rgba(209, 213, 219, 1)'} />
    </Tooltip>
  )
}
