import styled from 'styled-components/macro'

export const NotificationItem = styled.div<{
  isUnread?: boolean
  hasLink?: boolean
}>`
  display: flex;
  padding: 14px 12px 14px 10px;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;

  ${(props) => props.isUnread && `background: rgba(137, 190, 117, 0.10);`}

  ${(props) => props.hasLink && `cursor: pointer;`}

  &:hover {
    ${(props) =>
      props.isUnread
        ? `background: rgba(137, 190, 117, 0.20);`
        : `background: rgba(137, 190, 117, 0.05);`}
  }
`

export const NotificationLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

export const UnreadDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fcbc05;
`

export const NotificationRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
`

export const NotificationText = styled.div`
  color: #1e1919;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export const NotificationTime = styled.div`
  display: flex;
  width: 111px;
  height: 15px;
  flex-direction: column;
  justify-content: center;

  color: rgba(82, 74, 62, 0.82);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export const NotificationAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #087443;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`
