import { useEffect } from 'react'
import { NotificationItem } from './NotificationItem'
import * as S from './NotificationModal.styles'
import { AFbLink } from 'components/FbUI/FbLink/styles'
import apiService from 'services/api'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export type NotificationModalProps = {
  clickOutsideClassName: string
  onClickOutside: () => void
  stickToRight?: boolean
}

export const NotificationModal = ({
  clickOutsideClassName,
  onClickOutside,
  stickToRight = false,
}: NotificationModalProps) => {
  // Trigger onClickOutside when user clicks outside of the NotificationContainer
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (target.closest(clickOutsideClassName) === null) {
        onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickOutside])

  const api = apiService()

  const { data: unreadNotifications } = api.useGetNotifications({
    unread: true,
  })

  const { data: readNotifications } = api.useGetNotifications({
    unread: false,
  })

  const queryClient = useQueryClient()

  const handleMarkAllAsRead = () => {
    api
      .markAllNotificationsAsRead()
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] })
        queryClient.invalidateQueries({ queryKey: ['unread-notifications'] })
      })
      .catch(() => {
        toast.error('Failed to mark all notifications as read')
      })
  }

  return (
    <S.NotificationContainer stickRight={stickToRight}>
      <S.NotificationHeader>Notifications</S.NotificationHeader>
      {!unreadNotifications?.length && !readNotifications?.length && (
        <S.NotificationSubHeader>
          <div>
            👋 Hey there! 📬 You have no notifications right now. Enjoy some
            notification-free time!
          </div>
        </S.NotificationSubHeader>
      )}
      {!!unreadNotifications?.length && (
        <>
          <S.NotificationSubHeader>
            <div>Unread</div>
            <AFbLink href="#" onClick={handleMarkAllAsRead}>
              Mark all as read
            </AFbLink>
          </S.NotificationSubHeader>
          {unreadNotifications.map((notification) => (
            <NotificationItem
              isUnread
              key={notification.id}
              id={notification.id}
              fromUser={notification.from_user}
              notificationLink={notification.notification_link}
              message={notification.message}
              time={new Date(notification.created)}
              onClick={onClickOutside}
            />
          ))}
        </>
      )}
      {!!readNotifications?.length && (
        <>
          <S.NotificationSubHeader>
            <div>Read</div>
          </S.NotificationSubHeader>
          {readNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              id={notification.id}
              fromUser={notification.from_user}
              notificationLink={notification.notification_link}
              message={notification.message}
              time={new Date(notification.created)}
              onClick={onClickOutside}
            />
          ))}
        </>
      )}
    </S.NotificationContainer>
  )
}
