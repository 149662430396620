import { Product } from 'models/product'
import React, { useEffect, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelectedProductsStore } from 'stores/SelectedProductsStore/SelectedProductsStore'
import apiService from '../services/api'
import Checkbox from './Checkbox/checkbox'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { Modal } from './UI/Modal/Modal'
import { cn } from './UI/cn'

interface ChangeProductViewModalComponentProps {
  show: boolean
  handleClose: () => void
}

export function ChangeProductViewModal(
  props: ChangeProductViewModalComponentProps
) {
  const api = apiService()
  const { data, isLoading } = api.useProducts()
  const {
    selectedProducts: selectedProductsStore,
    setSelectedProducts: setSelectedProductsStore,
  } = useSelectedProductsStore()

  const [selectedProducts, setSelectedProducts] = React.useState<Product[]>(
    selectedProductsStore
  )

  const handleApply = () => {
    const allSelected = selectedProducts.length === data?.length
    if (allSelected) {
      setSelectedProductsStore([])
    } else {
      setSelectedProductsStore(selectedProducts)
    }
    props.handleClose()
  }

  const products = useMemo(() => {
    if (!data) return []
    return [...data]
  }, [data])

  useEffect(() => {
    // Start with all products selected if none are selected
    if (selectedProductsStore.length === 0 && products.length > 0) {
      setSelectedProducts(products)
    } else {
      setSelectedProducts(selectedProductsStore)
    }
  }, [products, selectedProductsStore])

  const toggleProduct = (product: Product) => {
    const isSelected = selectedProducts.some((p) => p.id === product.id)
    setSelectedProducts(
      isSelected
        ? selectedProducts.filter((p) => p.id !== product.id)
        : [
            ...selectedProducts
              .filter((p) => p.id !== product.id)
              .concat(product),
          ]
    )
  }

  return (
    <Modal
      title={'Active Products'}
      description={
        <>
          Find opportunities with revenue and volume estimates calculated for
          all or any of your products. Add or modify products{' '}
          <Link
            style={{
              color: '#087443',
            }}
            to={'/product'}
          >
            here
          </Link>
          .
        </>
      }
      onAccept={handleApply}
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      acceptButtonText={'Set Product'}
      blockAccept={selectedProducts.length === 0}
    >
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <div className="deals-modal-table-container">
          <table className="deals-modal-table">
            <thead>
              <tr>
                {/* Select all */}
                <th className="w-[31px] pt-1.5">
                  <IndeterminateCheckbox
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setSelectedProducts(products)
                      } else {
                        setSelectedProducts([])
                      }
                    }}
                    checked={selectedProducts.length === products.length}
                    indeterminate={
                      selectedProducts.length > 0 &&
                      selectedProducts.length < products.length
                    }
                  />
                </th>
                <th>Product</th>
                <th>Format</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p, i) => {
                return (
                  <tr
                    className={cn({
                      'w-[31px]': i === 0,
                    })}
                    key={p.id}
                  >
                    <td className="flex items-center justify-center">
                      <Checkbox
                        name="product"
                        value={p.id}
                        checked={selectedProducts.some((sp) => sp.id === p.id)}
                        onChange={(e) => {
                          e.stopPropagation()
                          toggleProduct(p)
                        }}
                      />
                    </td>
                    <td onClick={() => toggleProduct(p)}>{p.name ?? '-'}</td>
                    <td onClick={() => toggleProduct(p)}>{p.format ?? '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}
