import { useContext } from 'react'
import { useStore } from 'zustand'
import { ColumnStoreInternal } from './ColumnsStore'
import { ColumnStoreContext } from './ColumnsStoreProvider'

export function useColumnStoreContext<T>(
  selector: (state: ColumnStoreInternal) => T
): T {
  const store = useContext(ColumnStoreContext)
  if (!store)
    throw new Error(
      'useColumnStoreContext must be used within a ColumnsStoreProvider'
    )
  return useStore(store, selector)
}
