import { Form, InputGroup } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { ErrorLabel } from '../ErrorLabel'
import React from 'react'
import { BsX } from 'react-icons/bs'

export interface TextInputProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  label?: string
  placeholder?: string
  type?: string
  maxLength?: number
  optional?: boolean
  prefix?: string
  suffix?: string
  style?: React.CSSProperties
  sublabel?: string
  noMargin?: boolean
  disabled?: boolean
  className?: string
  helperText?: string
  onClear?: () => void
}

export function TextInput<T extends FieldValues>(props: TextInputProps<T>) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'number') {
      if (isNaN(e.target.valueAsNumber)) {
        onChange('')
      } else {
        onChange(e.target.valueAsNumber)
      }
    } else {
      onChange(e.target.value)
    }
  }

  return (
    <div className={`flex flex-col w-full ${props.noMargin ? 'mt-0' : 'mt-3'}`}>
      <div className={props.noMargin ? 'mt-0' : 'mb-1'}>
        {props.label && (
          <label
            htmlFor={props.name}
            className="text-base font-medium text-gray-800 select-none"
          >
            {props.label}
            {props.optional && (
              <span className="italic text-sm font-normal"> - optional</span>
            )}
          </label>
        )}
        {props.sublabel && (
          <label className="text-gray-600 text-sm font-normal leading-5">
            {props.sublabel}
          </label>
        )}
      </div>
      <div className="relative">
        {props.onClear && !!value && (
          <button
            className="z-10 absolute right-10 top-1 text-sm text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={() => props.onClear?.()}
          >
            <BsX size={24} />
          </button>
        )}

        <InputGroup>
          {props.prefix && <InputGroup.Text>{props.prefix}</InputGroup.Text>}
          <Form.Control
            id={props.name}
            onChange={handleOnChange}
            type={props.type}
            value={value ?? ''}
            ref={ref}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            style={props.style}
            disabled={props.disabled}
            className={`${props.className} ${
              error?.message ? 'input-error' : ''
            }`}
          />
          {props.suffix && <InputGroup.Text>{props.suffix}</InputGroup.Text>}
        </InputGroup>
      </div>
      {props.helperText && (
        <span className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.helperText}
        </span>
      )}
      {error?.message && <ErrorLabel message={error?.message} />}
    </div>
  )
}
