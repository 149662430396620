import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ColumnAssignment } from '../ColumnsStep'
import { ContactsImportResponse } from 'models/contacts'
import Papa from 'papaparse'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import to from 'await-to-js'

const ImportContactContext = createContext<ImportContactContextData | null>(
  null
)

export type MatchedCompany = {
  company_name: string
  matched_company?: {
    id?: number
    chain?: number
  }
}

export function ImportContactProvider({
  children,
  onStepChange,
  handleClose,
  onContactsImported,
}: {
  children: React.ReactNode
  onStepChange?: (step: number) => void
  handleClose: () => void
  onContactsImported?: () => void
}) {
  const api = apiService()
  const [step, setStep] = useState(0)
  const [file, setFile] = useState<File | null>(null)
  const [tags, setTags] = useState<number[]>([])
  const [parsedFile, setParsedFile] = useState<Papa.ParseResult<
    Record<string, string>
  > | null>(null)
  const [columnAssignment, setColumnAssignment] = useState<ColumnAssignment[]>(
    []
  )
  const [previewData, setPreviewData] = useState<ContactsImportResponse | null>(
    null
  )
  const [unmatchedCompanies, setUnmatchedCompanies] = useState<string[] | null>(
    null
  )
  const [isImporting, setIsImporting] = useState(false)
  const [matchedCompanies, setMatchedCompanies] = useState<MatchedCompany[]>([])

  useEffect(() => {
    if (onStepChange) onStepChange(step)
    if (step < 0) {
      handleClose()
    }
  }, [step])

  useEffect(() => {
    if (!file) return
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setParsedFile(results as Papa.ParseResult<Record<string, string>>)
      },
      error: (error) => {
        console.error(error)
      },
    })
  }, [file])

  const importContacts = async () => {
    setIsImporting(true)
    const [err, response] = await to(
      api.contactsImport(file!, columnAssignment, matchedCompanies, tags)
    )
    setIsImporting(false)
    if (err) {
      toast.error('Failed to update contacts')
      return
    }

    toast.success(`${response.success_count} contacts updated successfully`)
    onContactsImported?.()
    handleClose()
  }

  const nextStep = useCallback(
    (skip: number = 0) => {
      let newStep = step + 1 + skip
      if (newStep == 4) {
        if (previewData?.failed_count == 0) {
          newStep += 1
        }
      }
      setStep(newStep)
    },
    [step, unmatchedCompanies, unmatchedCompanies?.length, previewData]
  )

  const prevStep = () => {
    let newStep = step - 1
    if (newStep == 2) {
      if (!unmatchedCompanies || unmatchedCompanies.length == 0) {
        newStep -= 1
      }
    }
    if (newStep == 4) {
      if (previewData?.failed_count == 0) {
        newStep -= 1
      }
    }
    setStep(newStep)
  }

  return (
    <ImportContactContext.Provider
      value={{
        methods: {
          nextStep,
          prevStep,
          setFile,
          setParsedFile,
          setColumnAssignment,
          setPreviewData,
          setUnmatchedCompanies,
          handleClose,
          importContacts,
          setMatchedCompanies,
          setTags,
        },
        state: {
          step,
          file,
          parsedFile,
          columnAssignment,
          previewData,
          unmatchedCompanies,
          isImporting,
          tags,
        },
      }}
    >
      {children}
    </ImportContactContext.Provider>
  )
}

type ImportContactContextData = {
  methods: {
    nextStep: (skip?: number) => void
    prevStep: () => void
    setFile: Dispatch<SetStateAction<File | null>>
    setParsedFile: Dispatch<
      SetStateAction<Papa.ParseResult<Record<string, string>> | null>
    >
    setColumnAssignment: Dispatch<SetStateAction<ColumnAssignment[]>>
    setPreviewData: Dispatch<SetStateAction<ContactsImportResponse | null>>
    setUnmatchedCompanies: Dispatch<SetStateAction<string[] | null>>
    handleClose: () => void
    importContacts: () => Promise<void>
    setMatchedCompanies: Dispatch<SetStateAction<MatchedCompany[]>>
    setTags: Dispatch<SetStateAction<number[]>>
  }
  state: {
    step: number
    file: File | null
    parsedFile: Papa.ParseResult<Record<string, string>> | null
    columnAssignment: ColumnAssignment[]
    previewData: ContactsImportResponse | null
    unmatchedCompanies: string[] | null
    isImporting: boolean
    tags: number[]
  }
}

export function useImportContactContext() {
  const context = useContext(ImportContactContext) as ImportContactContextData
  if (!context) {
    throw new Error(
      'useImportContactContext must be used within a ImportContactProvider'
    )
  }
  return { ...context }
}
