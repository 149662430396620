export const menu_ingredients = [
  {
    label: 'Alternative Meat Brands',
    keywords:
      'Impossible Burger Impossible™ Burger Impossible™ Beef Impossible Beef Impossible™ Slider Impossible™ Sliders Impossible Slider Impossible Sliders Impossible™ Patty Impossible Patty Impossible Meat Impossible™ Meat Made with Impossible™ Made with Impossible ',
    value: 'Alt Meats',
  },
  {
    label: 'Vegetarian',
    keywords: 'Vegetarian',
    value: 'Vegetarian',
  },
  {
    label: 'Vegan',
    keywords: 'Vegan',
    value: 'Vegan',
  },
  {
    label: 'Plant-based',
    keywords: 'Plant-based plant based',
    value: 'Plant-based',
  },
  {
    label: 'All Vegetarian/Vegan/Plant-Based',
    keywords:
      "vegetarian vegan tofu seitan tempeh veg meatless chickenless chick'n impossible burger beyond burger beyond meat impossible patty beyond patty impossible foods veggie burger veggie option plant based plant-based no meat Impossible™ Burger Impossible™ Beef Impossible Beef Impossible™ Slider Impossible™ Sliders Impossible Slider Impossible Sliders Impossible™ Patty Impossible Meat Impossible™ Meat Made with Impossible™ Made with Impossible Impossible™ Patties Impossible Patties Impossible Pork Impossible™ Pork Impossible Chicken Impossible™ Chicken Impossible Nuggets Impossible™ Nugggets Impossible Tenders Impossible™ Tenders Impossible Sausage Impossible™ Sausage Impossible Meatballs Impossible™ Meatballs Impossible Chicken Tenders Impossible™ Chicken Tenders Beyond Beef Beyond Sliders Beyond Slider Made with Beyond Beyond Foods Beyond Sausage Beyond Chicken Beyond Nuggets Beyond Tenders Beyond Meatballs Beyond Breakfast Sausage Beyond Crumbles Beyond Jerky Beyond Steak Beyond Popcorn Beyond Popcorn Gardein MorningStar morning star",
    value: 'All Vegetarian/Vegan/Plant-Based',
  },
  {
    label: 'Sustainable',
    keywords:
      'Sustainable environmental sustainability climate-friendly pasture-raised fair trade climate change carbon neutral carbon-neutral',
    value: 'Sustainable',
  },
  {
    label: 'Organic',
    keywords: 'Organic',
    value: 'Organic',
  },
  {
    label: 'Healthy',
    keywords: 'Healthy nutritious wholesome',
    value: 'Healthy',
  },
  // {
  //   label: "Burger",
  //   keywords:
  //     "Burger cheeseburger hamburger burgers cheeseburgers hamburgers sliders slider beef patty beef patties patty melt",
  //   value: "Burger",
  // },
  // {
  //   label: "Chicken",
  //   keywords:
  //     "Chicken nuggets strips breast breasts wings tenders chickens wing nugget",
  //   value: "Chicken",
  // },
  // {
  //   label: "Steak",
  //   keywords:
  //     "Steak tri-tip filet mignon Tenderloin Ribeye steaks porterhouse T-bone new york strip rib-eye carne asada",
  //   value: "Steak",
  // },
  // {
  //   label: "Pork",
  //   keywords:
  //     "Pork bacon ham pancetta porks carnitas spareribs ribs jowl guanciale",
  //   value: "Pork",
  // },
  // {
  //   label: '"Pork" Only',
  //   keywords: "Pork",
  //   value: '"Pork" Only',
  // },
  // {
  //   label: "Bacon",
  //   keywords: "Bacon bacons pancetta",
  //   value: "Bacon",
  // },
  // {
  //   label: "Lamb",
  //   keywords: "Lamb sheep mutton",
  //   value: "Lamb",
  // },
  // {
  //   label: "Ham",
  //   keywords: "Ham canadian bacon Capicola Gammon Jamon bologna baloney",
  //   value: "Ham",
  // },
  // {
  //   label: "Turkey",
  //   keywords: "Turkey",
  //   value: "Turkey",
  // },
  // {
  //   label: "Sausage / Hot Dog",
  //   keywords:
  //     "Sausage hot dog bratwurst banger frankfurter liverwurst wienerwurst kielbasa wurst",
  //   value: "Sausage / Hot Dog",
  // },
  // {
  //   label: "Fish / Seafood",
  //   keywords:
  //     "Fish salmon tuna mackerel tilapia cod snapper herring haddock flounder trout catfish bass halibut swordfish branzino mahi-mahi poke calamari squid crab lobster octopus shrimp mussels scallops clam",
  //   value: "Fish / Seafood",
  // },
  // {
  //   label: "Sandwich",
  //   keywords:
  //     "Sandwich subway hoagie panini sub hero sandwiches subs paninis hoagies subways heros heroes",
  //   value: "Sandwich",
  // },
  // {
  //   label: "Cheese",
  //   keywords:
  //     "Cheese Emmental Cheddar Gouda Manchegy Jack Cotija Feta Mozzarella Mozz brie provolone fontina havarti queso cheeses",
  //   value: "Cheese",
  // },
  // {
  //   label: "Pizza",
  //   keywords: "pizza flatbread deep dish pizzetta pizzas flatbreads",
  //   value: "Pizza",
  // },
  // {
  //   label: "Salad",
  //   keywords: "salad",
  //   value: "Salad",
  // },
  // {
  //   label: "Coffee",
  //   keywords: "coffee latte espresso cappuccino coffees cold brew",
  //   value: "Coffee",
  // },
  // {
  //   label: "Tea",
  //   keywords:
  //     "tea teas matcha chai rooibos oolong earl grey english breakfast chamomile",
  //   value: "Tea",
  // },
  // {
  //   label: "Alcohol",
  //   keywords:
  //     "beer ale IPA lager pilsner beers ales wine chardonnay pinot merlot rosé cabernet syrah zinfandel sauvignon riesling wines cocktail gin vodka rum brandy vermouth whiskey sake bourbon",
  //   value: "Alcohol",
  // },
  // {
  //   label: "Beer",
  //   keywords: "beer ale IPA lager pilsner beers ales",
  //   value: "Beer",
  // },
  // {
  //   label: "Wine",
  //   keywords:
  //     "wine chardonnay pinot merlot rosé cabernet syrah zinfandel sauvignon riesling wines",
  //   value: "Wine",
  // },
  // {
  //   label: "Baked Goods",
  //   keywords:
  //     "bagel bagels bun buns biscuit biscuits bread breads cookie cookies cake cakes pie pies muffin muffins doughnut doughnuts donut donuts pastry pastries croissant croissants",
  //   value: "Baked Goods",
  // },
  // {
  //   label: "Juice / Smoothie",
  //   keywords: "juice smoothie juices smoothies",
  //   value: "Juice / Smoothie",
  // },
  // {
  //   label: "Ice Cream / Milkshake",
  //   keywords:
  //     "ice cream milkshake frappe milkshakes frappes ice creams gelato affogato sorbet frozen yogurt fro-yo froyo sherbet sherbert",
  //   value: "Ice Cream / Milkshake",
  // },
  // {
  //   label: "Pasta",
  //   keywords:
  //     "Pasta Farfalle Bucatini Fettuccine Fusilli Gnocchi Linguine Macaroni Orecchiette Penne Ravioli Spaghetti Tagliatelle Tortellini pastas mac rigatoni",
  //   value: "Pasta",
  // },
  // {
  //   label: "Taco",
  //   keywords: "taco tacos",
  //   value: "Taco",
  // },
  // {
  //   label: "Burrito",
  //   keywords: "burrito burrito",
  //   value: "Burrito",
  // },
  // {
  //   label: "Salmon",
  //   keywords: "salmon lox",
  //   value: "Salmon",
  // },
  // {
  //   label: "Sushi",
  //   keywords: "sushi nigiri california roll maki temaki onigiri kimbap",
  //   value: "Sushi",
  // },
  // {
  //   label: "Shrimp",
  //   keywords: "shrimp shrimps prawn prawns",
  //   value: "Shrimp",
  // },
  // {
  //   label: "Chicken Tenders/Nuggets",
  //   keywords:
  //     "chicken tender chicken tenders chicken nugget chicken nuggets chicken finger chicken fingers chicken strip chicken strips mcnugget mcnuggets breaded chicken chicken bite chicken bites popcorn chicken",
  //   value: "Chicken Tenders/Nuggets",
  // },
  // {
  //   label: "Cheddar",
  //   keywords: "cheddar cheddars Colby Red Leicester",
  //   value: "Cheddar",
  // },
  // {
  //   label: "Cream Cheese",
  //   keywords: "cream cheese schmear",
  //   value: "Cream Cheese",
  // },
  // {
  //   label: "Mozzarella",
  //   keywords: "Mozzarella Mozz Fior di Latte Burrata Ovoline",
  //   value: "Mozzarella",
  // },
  // {
  //   label: "American cheese",
  //   keywords: "American cheese kraft cheese American singles American slices",
  //   value: "American cheese",
  // },
  // {
  //   label: "Meatballs",
  //   keywords: "Meatball meatballs Kofta Polpette",
  //   value: "Meatballs",
  // },
  // {
  //   label: "Chicken sandwich",
  //   keywords:
  //     "Chicken sandwich Chicken sandwichesm chicken patty chicken patties McChicken chicken burger chicken burgers chicken slider chicken sliders chickwich",
  //   value: "Chicken sandwich",
  // },
  // {
  //   label: "Charcuterie",
  //   keywords: "Salami pate paté pâté prosciutto Charcuterie",
  //   value: "Charcuterie",
  // },
  // {
  //   label: "Pepperoni",
  //   keywords: "Pepperoni",
  //   value: "Pepperoni",
  // },
  // {
  //   label: "Leafy Greens",
  //   keywords:
  //     "Kale arugula lettuce frisee frisée cress endive escarole little gem mesclun spring mix radicchio romaine mizuna tatsoi mustard greens ",
  //   value: "Leafy Greens",
  // },
  // {
  //   label: "Kale",
  //   keywords: "Kale",
  //   value: "Kale",
  // },
  // {
  //   label: "Arugula",
  //   keywords: "Arugula rugula roquette rocket greens",
  //   value: "Arugula",
  // },
  // {
  //   label: "Lettuce",
  //   keywords: "Lettuce frisee frisée iceberg celtuce ",
  //   value: "Lettuce",
  // },
  // {
  //   label: "Bread",
  //   keywords:
  //     "bread breads baguette bun buns ciabatta brioche challah focaccia sourdough pumpernickle",
  //   value: "Bread",
  // },
  // {
  //   label: "Berries",
  //   keywords:
  //     "berry berries strawberry strawberries raspberry raspberries blueberry blueberries blackberry blackberries",
  //   value: "Berries",
  // },
  // {
  //   label: "Peanut Butter",
  //   keywords: "peanut butter",
  //   value: "Peanut Butter",
  // },
  // {
  //   label: "Microgreens / Sprouts",
  //   keywords: "microgreens microgreen sprouts",
  //   value: "Microgreens / Sprouts",
  // },
  // {
  //   label: "Breakfast Sausage / Sandwich",
  //   keywords: "breakfast sausage breakfast sandwich breakfast patty",
  //   value: "Breakfast Sausage / Sandwich",
  // },
]
