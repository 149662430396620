import React from 'react'
import * as S from './textarea-input.styles'

const TextAreaInputBase = React.forwardRef(
  (
    props: React.InputHTMLAttributes<HTMLTextAreaElement>,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    return <S.Input ref={ref} {...props} />
  }
)
TextAreaInputBase.displayName = 'TextAreaInputBase'

export default TextAreaInputBase
