import ReactSelect, { Props } from 'react-select'
import { cn } from '../UI/cn'
import { forwardRef } from 'react'

const ReactSelectWrapper = forwardRef<any, Props>((props, ref) => {
  return (
    <ReactSelect
      menuPortalTarget={document.body}
      {...props}
      styles={{
        ...props.styles,
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 100,
          pointerEvents: 'all',
        }),
        control: (provided, state) => ({
          // ...provided,
          ...props.styles?.control?.(provided, state),
          display: 'flex',
          height: '33.5px',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          ...props.styles?.placeholder?.(provided, state),
          color: undefined,
        }),
      }}
      classNames={{
        ...props.classNames,
        control: (provided) => {
          return cn(
            'form-control-select',
            {
              'form-control-select-focus': provided.isFocused,
              'opacity-50': props.isDisabled,
            },
            props.classNames?.control?.(provided) ?? ''
          )
        },
        placeholder: (provided) =>
          'form-control-select-placeholder' +
          (props.classNames?.placeholder?.(provided) ?? ''),
        option: (provided) =>
          `${provided.isFocused ? 'hover-menu-item' : ''} ${
            provided.isSelected ? 'selected-menu-item' : ''
          }` + (props.classNames?.option?.(provided) ?? ''),
      }}
      ref={ref}
    />
  )
})

ReactSelectWrapper.displayName = 'ReactSelectWrapper'

export default ReactSelectWrapper
