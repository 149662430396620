export type MISC_SETTINGS_TYPES =
  | 'show_temp_closed'
  | 'sales_forecast_target_line'
  | 'show_sales_forecast_line'

export const MISC_SETTINGS: Record<
  string,
  { title: string; description: string }
> = {
  show_temp_closed: {
    title: 'Show temporarily closed locations',
    description:
      'When turned on, locations that are identified as temporarily closed will be shown in the opportunity table. Restaurants may mark themselves as temporarily closed for many reasons: they may be under renovation, or they may actually be closed down. Turning off this setting can increase the quality and likelihood a restaurant is still active.',
  },
}
