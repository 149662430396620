import * as S from './RatingsCard.styles'
import React from 'react'
import { Restaurant } from '../../../models/restaurant'
import { formatInteger, formatTwoDecimal } from 'utils/formatting'

export function RatingsCard({ restaurant: _restaurant }: { restaurant: any }) {
  const restaurant = _restaurant as Restaurant
  // This is to get the total number of reviews for the restaurant while
  // while reviews_per_score is not the correct value for the chart
  const chartRestaurantTotal = Object.entries(
    restaurant.reviews_per_score || {}
  ).reduce((acc, curr) => {
    return acc + curr[1]
  }, 0)

  return (
    <S.RatingsWrapper>
      <S.Ratings>
        <div>
          <S.Label>Average Rating</S.Label>
          <S.RatingsValueRow>
            <S.StarIcon />
            <S.ValueBig>{formatTwoDecimal(restaurant.avg_rating)}</S.ValueBig>
          </S.RatingsValueRow>
        </div>
        <div>
          <S.Label>Total Reviews</S.Label>
          <S.ValueBig>{formatInteger(restaurant.reviews_count)}</S.ValueBig>
        </div>
      </S.Ratings>
      {restaurant.reviews_per_score
        ? Object.entries(restaurant.reviews_per_score)
            .reverse()
            .map((rating) => (
              <S.RatingsBarWrapper key={'reviews_' + rating[0]}>
                <S.RatingsBarText>{rating[0]}</S.RatingsBarText>
                <S.RatingsBar>
                  <S.RatingsBarFill
                    width={(rating[1] / chartRestaurantTotal) * 100}
                  />
                </S.RatingsBar>
              </S.RatingsBarWrapper>
            ))
        : null}
    </S.RatingsWrapper>
  )
}
