import { toast } from 'react-toastify'
import ContactModal, { ContactDefaultValues } from './ContactModal/ContactModal'
import apiService from '../../services/api'
import { ContactCompany } from '../../models/companies'

export default function NewContactModal({
  show,
  handleClose,
  onContactCreated,
  forCompany,
}: {
  show: boolean
  handleClose: () => void
  onContactCreated?: () => void
  forCompany?: ContactCompany
}) {
  const api = apiService()

  const handleSubmitSuccess = () => {
    toast.success('Contact created successfully')
    onContactCreated?.()
    handleClose()
  }

  const createContact = (values: ContactDefaultValues) => {
    return api.createContact({
      ...values,
      contact_company: {
        ...values.contact_company,
        domain: values.company_domain, // Move company_domain to company object so we override it on the backend if needed
      },
    })
  }

  return ContactModal({
    show,
    handleClose,
    onSubmitSuccess: handleSubmitSuccess,
    confirmButtonText: 'Create',
    submitApiAction: createContact,
    forCompany,
  })
}
