import { FilterCriteria, ValueFilter } from '../../FilterStore'
import { Tooltip } from '../../../Tooltip/tooltip'
import { useMemo } from 'react'
import { FilterChipCloseButton, FilterChipContainer } from './FilterChipStyles'

type FilterChipValue = {
  title?: string
  value: ValueFilter[]
  onClose?: () => void
  filterCriteria: FilterCriteria
}

export function FilterChipValue({
  onClose,
  value,
  title,
  filterCriteria,
}: FilterChipValue) {
  const isExclusive = filterCriteria === 'neither'
  const getFormattedValue = () => {
    if (value.length === 1) return value[0]?.label
    return `${value.length} selected`
  }

  const selectedString = useMemo(() => {
    const selected = value.map((v) => v.label)
    if (selected.length < 1) return ''
    return selected.join(', ')
  }, [value])

  return (
    <Tooltip delay={0} content={selectedString} placement="top">
      <FilterChipContainer isExclusive={isExclusive}>
        <span>{`${title}: ${getFormattedValue()}`}</span>
        {onClose && (
          <FilterChipCloseButton
            style={{
              color: isExclusive ? '#E44C4C' : '#087443',
            }}
            onClick={onClose}
          />
        )}
      </FilterChipContainer>
    </Tooltip>
  )
}
