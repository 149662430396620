import { useForm } from 'react-hook-form'
import React, { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { FilterStore } from '../FilterStore'
import {
  DropdownMenu,
  DropdownMenuContent,
} from 'components/UI/BaseDropdown/dropdown-base'
import { TextInput } from 'components/FormUtils/TextInput'
import { RadioInput } from 'components/FormUtils/RadioInput'
import FbButton from 'components/FbUI/FbButton'
import { FilterButton } from '../components/FilterButton'
import { FilterCloseButton } from '../components/FilterCloseButton'

interface ZipcodeFilterProps {
  filterStore: FilterStore
}

export function ZipcodeFilter({ filterStore }: ZipcodeFilterProps) {
  const { pendingZipCodeFilter, setPendingZipCodeFilter } = filterStore()

  const [open, setOpen] = useState(false)
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      zipcode: pendingZipCodeFilter[0],
      mileRadius: pendingZipCodeFilter[1],
    },
    resolver: zodResolver(
      z.object({
        zipcode: z.string().min(5).max(5).regex(/^\d+$/),
        mileRadius: z.number().int().min(1).max(200),
      })
    ),
  })

  const clearFilters = () => {
    reset({
      zipcode: '',
      mileRadius: 0,
    })
    setPendingZipCodeFilter('', 0)
  }

  const _handleSubmit = handleSubmit((data) => {
    setPendingZipCodeFilter(data.zipcode, data.mileRadius)
    setOpen(false)
  })

  const isZipcodeSet =
    pendingZipCodeFilter[0] !== '' && pendingZipCodeFilter[1] !== 0

  const label = !isZipcodeSet
    ? 'All'
    : `${pendingZipCodeFilter[0]} (${pendingZipCodeFilter[1]} miles)`

  return (
    <DropdownMenu open={open} onOpenChange={(open) => setOpen(open)}>
      <div className={'relative'}>
        <DropdownMenuTrigger asChild>
          <FilterButton
            open={open}
            isActive={isZipcodeSet}
            title={'Zipcode Radius'}
            activeValueLabel={label}
            onClick={() => setOpen(!open)}
          />
        </DropdownMenuTrigger>
        <FilterCloseButton
          isActive={isZipcodeSet}
          onClick={() => clearFilters()}
        />
      </div>
      <DropdownMenuContent>
        <TextInput
          control={control}
          name={'zipcode'}
          maxLength={5}
          placeholder={'Zipcode'}
        />
        <RadioInput
          options={[
            { label: '1 mile', value: 1 },
            { label: '5 miles', value: 5 },
            { label: '10 miles', value: 10 },
            { label: '15 miles', value: 15 },
            { label: '25 miles', value: 25 },
            { label: '50 miles', value: 50 },
            { label: '100 miles', value: 100 },
            { label: '200 miles', value: 200 },
          ]}
          formControl={control}
          fieldName={'mileRadius'}
        />
        <FbButton
          title="Apply"
          style={{ width: '100%' }}
          onClick={_handleSubmit}
        >
          Apply
        </FbButton>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
