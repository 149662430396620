import { CardSubtitleWrapper } from 'pages/app/door/door.styles'
import * as S from './AgeCard.styles'
import { AddressInfo } from 'models/restaurant'
import { Doughnut } from 'react-chartjs-2'

export function AgeCard({ addressInfo }: { addressInfo?: AddressInfo }) {
  if (!addressInfo) return null
  const ages = [
    {
      color: '#86DD71',
      name: '0-19',
      percentage:
        addressInfo?.age_5to9years_pop_pct +
        addressInfo?.age_15to19years_pop_pct,
    },
    {
      color: '#FFD582',
      name: '20-34',
      percentage:
        addressInfo?.age_20to24years_pop_pct +
        addressInfo?.age_25to34years_pop_pct,
    },
    {
      color: '#83E1FF',
      name: '35-64',
      percentage:
        addressInfo?.age_35to44years_pop_pct +
        addressInfo?.age_45to54years_pop_pct +
        addressInfo?.age_55to59years_pop_pct +
        addressInfo?.age_60to64years_pop_pct,
    },
    {
      color: '#E87676',
      name: '65+',
      percentage:
        addressInfo?.age_65to74years_pop_pct +
        addressInfo?.age_75to84years_pop_pct +
        addressInfo?.age_85yearsandover_pop_pct,
    },
  ]
  return (
    <>
      <S.CardTitle>Age</S.CardTitle>
      <S.AgesWrapper>
        <S.AgesChartWrapper>
          <Doughnut
            data={{
              datasets: [
                {
                  data: ages.map((v) => v.percentage * 100),
                  backgroundColor: ages.map((v) => v.color),
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: false,
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
            }}
          />
        </S.AgesChartWrapper>

        <S.AgesSubtitles>
          {ages.map((v) => {
            return (
              <CardSubtitleWrapper key={v.name}>
                <S.ColoredDot color={v.color} />
                <S.CardSubtitle>{v.name}</S.CardSubtitle>
                <S.CardSubtitlePercent>
                  - {Math.round(v.percentage * 100)}%
                </S.CardSubtitlePercent>
              </CardSubtitleWrapper>
            )
          })}
        </S.AgesSubtitles>
      </S.AgesWrapper>
    </>
  )
}
