import styled from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import ReactSelectWrapper from '../FormUtils/ReactSelectWrapper'
import FbButton from 'components/FbUI/FbButton'
import Autocomplete from 'components/FormUtils/Autocomplete'
import useCachedState from 'utils/useCachedState'
import { FilterStore, SearchFilterState } from 'components/Filters/FilterStore'
import { useAuth } from '../../context/authentication/useAuth'

interface IElastiSearchHistory {
  [key: string]: string[]
}

interface IOption {
  label: string
  searchKey: string
  searchPlaceholder?: string
}

export function TableSearchWithElastic({
  options,
  historyKey,
  filterStore,
}: {
  options: IOption[]
  historyKey: string
  filterStore: FilterStore
}) {
  const { user } = useAuth()
  const LOCAL_STORAGE_KEY_PREFIX = `5-${user?.id}-`
  const { search, setSearch } = filterStore()

  const selectOptions = useMemo(() => {
    return options.map((option) => ({
      label: option.label,
      value: option.searchKey,
      searchKey: option.searchKey,
      searchPlaceholder: option.searchPlaceholder,
    }))
  }, [options])

  const initialOption =
    selectOptions?.find((option) =>
      Object.keys(search).includes(option.searchKey)
    ) ?? options?.[0]

  const [localSearch, setLocalSearch] = useState<SearchFilterState>(search)
  const [selectedOption, setSelectedOption] = useState<IOption>(initialOption)
  const [searchHistory, setSearchHistory] =
    useCachedState<IElastiSearchHistory>(
      {},
      LOCAL_STORAGE_KEY_PREFIX + 'search-history-' + historyKey
    )

  useEffect(() => {
    // Reset local search when elastiSearch or selectedOption changes
    setLocalSearch(search)
  }, [search, selectedOption])

  const applySearch = () => {
    const localSearchCopy = { ...localSearch }

    // Add to search history
    Object.keys(localSearchCopy).map((key) => {
      // Skip empty values
      if (!localSearchCopy[key].value) {
        return
      }

      if (!searchHistory[key]) {
        searchHistory[key] = []
      }
      if (
        !searchHistory[key]
          .map((s) => s.toLowerCase())
          .includes(localSearchCopy[key].value.toLowerCase())
      ) {
        // Insert at the beginning
        searchHistory[key].unshift(localSearchCopy[key].value)
      }
      // Limit to last 15 searches
      if (searchHistory[key].length > 15) {
        searchHistory[key].splice(0, searchHistory[key].length - 15)
      }

      setSearchHistory((prev) => ({ ...prev, [key]: searchHistory[key] }))
    })

    for (const [key, value] of Object.entries(localSearchCopy)) {
      setSearch(key, value.value, value.label, value?.elastic)
    }
  }

  return (
    <div className="flex flex-1">
      <Dropdown
        options={selectOptions}
        value={selectedOption}
        isSearchable={false}
        onChange={(option) => {
          if (option) {
            setSelectedOption(option as IOption)
          }
        }}
        menuPosition="fixed"
        styles={{
          control: () => ({
            height: '100%',
            minWidth: 100,
            borderRadius: '6px 0 0 6px',
            cursor: 'pointer',
            borderRight: 'none',
          }),
          menuPortal(base) {
            return { ...base, zIndex: 9999 }
          },
          indicatorSeparator: () => ({
            display: 'none',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '2px 8px 2px 12px',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: '8px 8px 8px 0',
            color: '#343741',
            '&:hover': {
              color: '#272a31',
            },
          }),
        }}
      />
      <form
        className="flex justify-between w-full"
        onSubmit={(e) => {
          e.preventDefault()
          applySearch()
        }}
      >
        <Autocomplete
          type="text"
          options={searchHistory[selectedOption.searchKey] ?? []}
          value={localSearch[selectedOption.searchKey]?.value ?? ''}
          onChange={(e) =>
            setLocalSearch((prev) => ({
              ...prev,
              [selectedOption.searchKey]: {
                value: e.target.value.replaceAll(/[;:]/g, ''),
                label: selectedOption.label,
                elastic: true,
              },
            }))
          }
          placeholder={selectedOption.searchPlaceholder}
          className="form-control flex-1 min-w-[150px]"
          style={{ borderRadius: '0 6px 6px 0', flex: 1 }}
        />
        <FbButton
          className="-ml-1 h-full font-semibold rounded-tr-lg rounded-br-lg rounded-tl-none rounded-bl-none"
          onClick={applySearch}
        >
          Search
        </FbButton>
      </form>
    </div>
  )
}

export const SearchButton = styled(FbButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  font-size: 1rem;
  line-height: normal;
  border-radius: 0 6px 6px 0;
  font-weight: 600 !important;
`

export const Dropdown = styled(ReactSelectWrapper)`
  & > .form-control-select {
    background: #eef0f1;
    color: #343741 !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
  }
`
