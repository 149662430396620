import React from 'react'
import { Tabs } from '../../../components/FbUI/Tabs'
import styled from 'styled-components/macro'
import { Page } from 'components/FbUI/Page'
import useDocumentTitle from 'components/useDocumentTitle'
import { ActivityTable } from 'components/Tables/ActivityTable/ActivityTable'
import { useSimpleTabSelector } from 'utils/useSimpleTabSelector'

export function ActivityPage() {
  const [totalActivity, setTotalActivity] = React.useState<number | null>(null)
  const [totalMyActivity, setTotalMyActivity] = React.useState<number | null>(
    null
  )

  const [selectedTab, setSelectedTab] = useSimpleTabSelector<
    'all-activity' | 'my-activity'
  >('all-activity')

  useDocumentTitle(
    `${
      selectedTab === 'my-activity' ? 'My' : 'Team'
    } Activity - Activity - First Bite`
  )

  return (
    <Page>
      <Header>
        <TitleRow>
          <Title>Team Activity</Title>
        </TitleRow>

        <TabsAndStats>
          <Tabs
            tabs={[
              {
                key: 'all-activity',
                title: (
                  <div>
                    <b>Team activity</b>{' '}
                    {totalActivity ? `(${totalActivity})` : ''}
                  </div>
                ),
              },
              {
                key: 'my-activity',
                title: (
                  <div>
                    <b>My activity</b>{' '}
                    {totalMyActivity ? `(${totalMyActivity})` : ''}
                  </div>
                ),
              },
            ]}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabStyle={{ width: '180px' }}
          />
        </TabsAndStats>
      </Header>

      <TableContainer show={selectedTab === 'all-activity'}>
        {selectedTab === 'all-activity' && (
          <ActivityTable
            setTotalRowsCount={setTotalActivity}
            tableKey={'all-activity-table'}
          />
        )}
      </TableContainer>
      <TableContainer show={selectedTab === 'my-activity'}>
        {selectedTab === 'my-activity' && (
          <ActivityTable
            tableKey={'my-activity-table'}
            setTotalRowsCount={setTotalMyActivity}
            myActivityOnly
          />
        )}
      </TableContainer>
    </Page>
  )
}

const Header = styled.div`
  padding: 16px 25px 0 25px;
`

const TabsAndStats = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 100px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 12px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`

// table container height based on
// footer height + navbar height + table header height
const TableContainer = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
  height: calc(100vh - 260px);
`
