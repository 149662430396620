import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'

export default function UniversityExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportUniversities = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportUniversities(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Universities - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        ['name', 'Name'],
        ['address', 'Address'],
        ['city', 'City'],
        ['state', 'State'],
        ['zipcode', 'Zipcode'],
        ['domain', 'Domain'],
        ['carnegie_classification_size', 'Carnegie Classification Size'],
        ['carnegie_classification_year', 'Carnegie Classification Year'],
        [
          'carnegie_classification_student_composition',
          'Carnegie Classification Student Composition',
        ],
        ['total_enrollment', 'Total Enrollment'],
        ['total_men_enrollment', 'Total Men Enrollment'],
        ['total_women_enrollment', 'Total Women Enrollment'],
        ['dorm_capacity', 'Dorm Capacity'],
        ['google_place_url', 'Place URL'],
      ],
    },
    {
      title: 'Dining Services',
      elements: [
        ['meal_plan_provided', 'Meal Plan Provided'],
        ['food_management_company', 'Food Management Company'],
        ['food_management_company_source', 'Food Management Company Source'],
        ['food_management_company_parent', 'Parent Food Management Company'],
        ['estimated_meals_per_day', 'Estimated Meals per Day'],
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: [
        ['sales_stages', 'Sales Stages'],
        ['account_owner', 'Account Owner'],
        ['tags', 'Tags'],
        ['deals_count', 'Deals Count'],
        ['contact_count', 'Contact Count'],
        ['notes_count', 'Notes Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Universities Download"
      subTitle="Download up to 10,000 universities at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Universities"
      columns={columns}
      exportAPIAction={exportUniversities}
      {...props}
    />
  )
}
