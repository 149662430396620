import styled from 'styled-components/macro'
import { handleFormError } from '../../../utils/handleFormError'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { useForm } from 'react-hook-form'
import { useAuth } from 'context/authentication/useAuth'
import to from 'await-to-js'
import { useState } from 'react'
import { Text } from '../../../components/FbUI/Text'
import FbButton from '../../../components/FbUI/FbButton'

function ResetPassword() {
  const { resetPassword } = useAuth()
  const [successMsg, setSuccessMsg] = useState('')

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      non_field_errors: '',
    },
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const [err, res] = await to(resetPassword(values.email))
      handleFormError(err, setError)
      setSuccessMsg(res?.detail ?? '')
    },
    (errors) => console.warn(errors)
  )

  return (
    <Content>
      <Title>Reset Password</Title>
      {successMsg && <Text>{successMsg}</Text>}
      <TextInput control={control} name="email" label="Email" />

      <ButtonContainer>
        <FbButton type="submit" onClick={onSubmit} loading={isSubmitting}>
          Reset Password
        </FbButton>
      </ButtonContainer>
    </Content>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

const Content = styled.div`
  width: 400px;
  flexshrink: 0;

  @media (max-width: 400px) {
    width: 100%;
    margin: 0 16px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
`

export default ResetPassword
