import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const Label = styled.label`
  margin-top: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  user-select: none;
`

export const SubLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

export const Optional = styled.span`
  font-style: italic;
  font-size: 13px;
  font-weight: 400;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
`

export const Warning = styled.div`
  color: #b98900;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 4px;
  padding-left: 2px;

  & > svg {
    min-width: 20px;
  }
`
