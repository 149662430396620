import { Campaign } from './campaign'
import { ContactCompany, ContactCompanyCreateRequest } from './companies'
import { Tag } from './tags'

export interface ContactListResponse {
  results: ContactResponse[]
  count: number
}

export interface ContactResponse {
  id: number
  contact_company: ContactCompany
  first_name: string
  last_name: string
  title: string
  type: string
  notes: string
  email: string
  url: string
  linkedin_url: string
  extension?: string
  phone_number: string

  note?: any
  tags: Tag[] // contact labels
  consent_to_contact: boolean
  company_size?: number
  company_cuisine_50?: string
  created: string
  modified: string
  source?: number
  email_validity?: number
  requested_contact?: {
    id: number
    job: {
      campaign: Campaign
    }
  }
}

export interface ContactCreateRequest {
  contact_company: ContactCompanyCreateRequest
  category_id?: number
  sub_category_id?: number
  first_name: string
  last_name: string
  title?: string
  type?: number
  notes?: string
  tags?: number[]
  email: string
  url?: string
  linkedin_url?: string
  phone_number?: string
  consent_to_contact: boolean
  extension?: string
}

export interface FailedContact {
  first_name: string
  last_name: string
  company: string
  company_domain: string
  email: string
  phone_number: string
  url: string
  notes: string
  title: string
  type: string
  errors: {
    [key: string]: string[]
  }
}

export interface ContactsImportResponse {
  total_count: number
  failed_count: number
  success_count: number
  success_contacts: ContactResponse[]
  failed_contacts: FailedContact[]
  failed_contacts_csv: string
  unmatched_companies?: string[]
}

// EmailValidity
export enum EmailValidity {
  INVALID = 0,
  VALID = 1,
  CATCH_ALL = 3,
}

export const EmailValidityLabels: { [key in EmailValidity]: string } = {
  [EmailValidity.INVALID]: 'Invalid',
  [EmailValidity.VALID]: 'Valid',
  [EmailValidity.CATCH_ALL]: 'Catch All',
}

// ContactSource
export enum ContactSource {
  INTERNAL = 0,
  FIRSTBITE = 1,
}

export const ContactSourceLabels: { [key in ContactSource]: string } = {
  [ContactSource.INTERNAL]: 'Internal',
  [ContactSource.FIRSTBITE]: 'First Bite',
}
