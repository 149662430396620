export default function getStageColor(percentage: number) {
  const colors = {
    100: ['#00876c', '#FFFFFF'],
    90: ['#3d9a70', '#FFFFFF'],
    80: ['#64ad73', '#FFFFFF'],
    75: ['#89bf77', '#FFFFFF'],
    70: ['#afd17c', '#000000'],
    60: ['#d6e184', '#000000'],
    50: ['#fff18f', '#000000'],
    40: ['#fdd576', '#000000'],
    30: ['#fbb862', '#000000'],
    25: ['#f59b56', '#FFFFFF'],
    20: ['#ee7d4f', '#FFFFFF'],
    10: ['#e35e4e', '#FFFFFF'],
    0: ['#d43d51', '#FFFFFF'],
  }

  const keys = Object.keys(colors)
  const closestPercentage = keys.reduce(function (prev, curr) {
    return Math.abs(parseInt(curr) - percentage) <
      Math.abs(parseInt(prev) - percentage)
      ? curr
      : prev
  })

  return colors[closestPercentage as '100']
}
