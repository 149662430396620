import { createColumnHelper } from '@tanstack/react-table'
import { IColumnSort } from 'components/DataTable/types'
import { usePreferences } from 'context/preferences/PreferencesContext'
import React, { useMemo } from 'react'
import { ContactCompanyType } from '../../../models/contact_companies'
import { UniversityChainProxy } from '../../../models/universities'
import { formatInteger } from '../../../utils/formatting'
import { FbLink } from '../../FbUI/FbLink'
import {
  AccountOwnerCell,
  CampaignsCell,
  DistributorsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { Anchor } from '../../UI/Anchor'
import { LinkCell } from '../CommonTableCells/LinkCell'

const integerFormatterOrFallback = (
  value: number | null,
  fallback: string = '-'
) => (value ? formatInteger(value) : fallback)

export const useUniversitiesTableCols = ({
  sorting,
}: {
  sorting?: IColumnSort<object>[]
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<UniversityChainProxy>()
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'institution_name',
        header: () => null,
        meta: {
          headerTitle: 'Institution Name',
          headerGroup: 'Opportunity Attributes',
          isEditable: false,
          isFirstColumn: true,
        },
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          let link = ''
          if (info.row.original?.university) {
            link = `/university/${preferences?.company_type_prefix_map[ContactCompanyType.University]}${info.row.original?.university?.institution_id}`
          } else {
            link = `/company/${preferences?.company_type_prefix_map[ContactCompanyType.UserGenerated]}${info.row.original?.id}`
          }
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('university.address', {
        id: 'address',
        meta: {
          tooltip: tooltips?.['opps_full_address'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Address',
        size: 250,
      }),
      columnHelper.accessor('university.city', {
        id: 'city',
        meta: {
          tooltip: tooltips?.['opps_city'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'City',
        size: 150,
      }),
      columnHelper.accessor('university.state_abbr', {
        id: 'state_abbr',
        meta: {
          tooltip: tooltips?.['opps_state'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'State',
        size: 100,
      }),
      columnHelper.accessor('university.zipcode', {
        id: 'zipcode',
        meta: {
          tooltip: tooltips?.['opps_zipcode'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Zipcode',
        size: 100,
      }),
      columnHelper.accessor('university.domain', {
        id: 'domain',
        meta: {
          tooltip: tooltips?.['opps_domain'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Domain',
        size: 200,
        cell: (info) => {
          let domain = info.getValue()
          if (domain.startsWith('http://') || domain.startsWith('https://')) {
            domain = domain.replace('http://', '').replace('https://', '')
          }
          if (domain.endsWith('/')) {
            domain = domain.slice(0, -1)
          }
          return domain ? (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('university.dormitory_capacity', {
        id: 'dormitory_capacity',
        meta: {
          tooltip: tooltips?.['opps_dorm_capacity'],
          headerGroup: 'Classification and Enrollment',
        },
        header: 'Dormitory Capacity',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.meal_plan_provided', {
        id: 'meal_plan_provided',
        meta: {
          tooltip: tooltips?.['opps_mealplan'],
          headerGroup: 'Dining Services',
        },
        header: 'Meal Plan Provided',
        size: 150,
        cell: (info) => {
          const mealPlan = info.getValue()
          return mealPlan ? 'Yes' : 'No'
        },
      }),
      columnHelper.accessor('university.total_enrollment', {
        id: 'total_enrollment',
        meta: {
          tooltip: tooltips?.['opps_total_enrollment'],
          headerGroup: 'Classification and Enrollment',
        },
        header: 'Total Enrollment',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.total_men_enrollment', {
        id: 'total_men_enrollment',
        meta: {
          tooltip: tooltips?.['opps_M_enrollment'],
          headerGroup: 'Classification and Enrollment',
        },
        header: 'Total Men Enrollment',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.total_women_enrollment', {
        id: 'total_women_enrollment',
        meta: {
          tooltip: tooltips?.['opps_F_enrollment'],
          headerGroup: 'Classification and Enrollment',
        },
        header: 'Total Women Enrollment',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.food_management_company', {
        id: 'food_management_company',
        meta: {
          tooltip: tooltips?.['opps_FMC'],
          headerGroup: 'Dining Services',
        },
        header: 'Food Management Company',
        size: 200,
      }),
      columnHelper.accessor('university.food_management_company_source', {
        id: 'food_management_company_source',
        meta: {
          tooltip: tooltips?.['opps_FMC_source'],
          headerGroup: 'Dining Services',
        },
        header: 'Food Management Company Source',
        cell: (info) => {
          const src = info.getValue() ?? ''
          return src.charAt(0).toUpperCase() + src.slice(1)
        },
        size: 200,
      }),
      columnHelper.accessor('university.food_management_company_parent', {
        id: 'food_management_company_parent',
        meta: {
          tooltip: tooltips?.['opps_FMC_parent'],
          headerGroup: 'Dining Services',
        },
        header: 'Parent Food Management Company',
        size: 200,
      }),
      columnHelper.accessor('university.estimated_meals_per_day', {
        id: 'estimated_meals_per_day',
        meta: {
          tooltip: tooltips?.['opps_meals_count'],
          headerGroup: 'Dining Services',
        },
        header: 'Estimated Meals per Day',
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_size',
        {
          id: 'carnegie_classification_2021_size_setting_size',
          meta: {
            tooltip:
              tooltips?.['opps_carnegie_classification_2021_size_setting_size'],
            headerGroup: 'Classification and Enrollment',
          },
          header: 'Carnegie Classification Size',
          size: 150,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_year',
        {
          id: 'carnegie_classification_2021_size_setting_year',
          meta: {
            tooltip:
              tooltips?.['opps_carnegie_classification_2021_size_setting_year'],
            headerGroup: 'Classification and Enrollment',
          },
          header: 'Carnegie Classification Year',
          size: 150,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_student_composition',
        {
          id: 'carnegie_classification_2021_size_setting_student_composition',
          meta: {
            tooltip:
              tooltips?.[
                'opps_carnegie_classification_2021_size_setting_student_composition'
              ],
            headerGroup: 'Classification and Enrollment',
          },
          header: 'Carnegie Classification Student Composition',
          size: 300,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),
      columnHelper.accessor('campaigns', {
        header: 'Campaigns',
        meta: {
          headerGroup: 'Campaigns and Deals',
        },
        cell: (info) => {
          return <CampaignsCell campaigns={info.getValue()} />
        },
        size: 260,
      }),
      columnHelper.accessor('deal_count', {
        id: 'deal_count',
        meta: {
          tooltip: tooltips?.['opps_deals_count'],
          rightAlign: true,
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Deals',
        size: 110,
        cell: (info) => {
          return info.row.original.deals?.length || '-'
        },
      }),
      columnHelper.accessor('contact_count', {
        id: 'contact_count',
        meta: {
          tooltip: tooltips?.['opps_contact_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Contacts',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('note_count', {
        id: 'note_count',
        meta: {
          tooltip: tooltips?.['opps_note_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Notes',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('deals.sales_stage', {
        id: 'sales_stages',
        meta: {
          tooltip: tooltips?.['opps_sales_stages'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Sales Stages',
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.deals}
              sortAsc={
                sorting?.length && sorting[0].id === 'sales_stages'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      }),
      columnHelper.display({
        id: 'account_owner',
        meta: {
          tooltip: tooltips?.['opps_account_owners'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Account Owners',
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.deals
          return <AccountOwnerCell deals={deals} />
        },
      }),
      columnHelper.accessor('taglist', {
        id: 'taglist',
        meta: {
          tooltip: tooltips?.['opps_tags'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Tags',
        size: 260,
        cell: (info) => {
          const taglist = info.getValue()
          return <TaglistCell taglist={taglist} />
        },
      }),
      columnHelper.accessor('distributors', {
        header: 'Known Distributors',
        meta: { headerGroup: 'Campaigns and Deals' },
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell distributors={info.row.original?.distributors} />
          )
        },
      }),
      columnHelper.accessor('university.google_place_url', {
        header: 'Place URL',
        meta: {
          headerGroup: 'Opportunity Attributes',
        },
        size: 150,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
    ],
    [columnHelper, sorting]
  )
  return columns
}
