import styled from 'styled-components'
import { Link } from 'react-router-dom'

const sharedStyles = `
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-decoration-line: underline;
color: #087443;

&:active,
&:focus {
  color: #087443;
}

&:visited {
  color: #002414;
}

&:hover {
  color: #83b9a1;
}
`

export const FbLink = styled(Link)`
  ${sharedStyles}
`
export const AFbLink = styled.a`
  ${sharedStyles};
  cursor: pointer;
`

export const FakeLink = styled.span`
  ${sharedStyles};
  cursor: pointer;
`
