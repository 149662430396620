const _k12Grades = [
  { rank: 0, value: 'PK', label: 'Pre-Kindergarten' },
  { rank: 1, value: 'KG', label: 'Kindergarten' },
  { rank: 2, value: '01', label: '1st Grade' },
  { rank: 3, value: '02', label: '2nd Grade' },
  { rank: 4, value: '03', label: '3rd Grade' },
  { rank: 5, value: '04', label: '4th Grade' },
  { rank: 6, value: '05', label: '5th Grade' },
  { rank: 7, value: '06', label: '6th Grade' },
  { rank: 8, value: '07', label: '7th Grade' },
  { rank: 9, value: '08', label: '8th Grade' },
  { rank: 10, value: '09', label: '9th Grade (Freshman)' },
  { rank: 11, value: '10', label: '10th Grade (Sophomore)' },
  { rank: 12, value: '11', label: '11th Grade (Junior)' },
  { rank: 13, value: '12', label: '12th Grade (Senior)' },
  { rank: 14, value: '13', label: '13th Grade' },
  { rank: 15, value: 'AE', label: 'Adult Education' },
  // Not displaying these values in the UI
  // { rank: 0, value: 'M', label: 'Ungraded Middle School' },
  // { rank: 0, value: 'N', label: 'Ungraded Elementary' },
  // { rank: 0, value: 'UG', label: 'Ungraded' },
]

export const k12Grades = _k12Grades.map((o) => ({
  rank: o.rank,
  value: o.value,
  label: `${o.label} (${o.value})`,
}))
