import apiService from 'services/api'
import { CompanyFormShape, CompanyModal } from './CompanyModal'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import FbButton from '../../FbUI/FbButton'
import { FaPlus } from 'react-icons/fa'
import { IContactCompany } from '../../../models/contact_companies'

export function CreateDoorProxyModal(props: { chainProxy?: IContactCompany }) {
  const [show, setShow] = useState(false)
  const api = apiService()
  const { refetch } = api.useDoorProxiesOfChainProxy(props.chainProxy?.id)

  const apiAction = async (values: CompanyFormShape) => {
    const payload = {
      ...values,
      country: values.country || 'US',
      state: values.state || '',
      city: values.city || '',
      domain: values.domain?.toLowerCase() || '',
      name: values.name?.trim() || '',
      chain_proxy_id: props.chainProxy?.id,
    }
    const response = await api.createDoorProxy(payload)
    await refetch()
    toast.success('Door created successfully')
    return response
  }

  return (
    <>
      <FbButton onClick={() => setShow(true)}>
        <FaPlus size={14} /> Add Door
      </FbButton>
      <CompanyModal
        defaultValues={{
          domain: props.chainProxy?.domain ?? '',
        }}
        hideCategoryAndType
        hideKnownDistributorsAndTags
        show={show}
        setShow={setShow}
        apiAction={apiAction}
        title="New Door"
        description="Add a company to First Bite to track additional deals and opportunities."
        acceptButtonText="Create Door"
      />
    </>
  )
}
