import { useEffect } from 'react'

type PageWithTitleProps = {
  title: string
  children: JSX.Element
}

export function PageWithTitle({
  title,
  children,
}: PageWithTitleProps): JSX.Element {
  const oldTitle = document.title

  useEffect(() => {
    document.title = title

    return () => {
      document.title = oldTitle
    }
  }, [title])

  return children
}
