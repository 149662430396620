import clsx from 'clsx'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { MdDragIndicator } from 'react-icons/md'

export interface ColumnDragAndDropItemProps {
  label: string
  readOnly?: boolean
  onRemove?: () => void
}
export function ColumnDragAndDropItem({
  label,
  readOnly,
  onRemove,
}: ColumnDragAndDropItemProps) {
  return (
    <div
      className={clsx('px-3 my-1', readOnly ? 'bg-[#D5DCD9]' : 'bg-[#E8F0EC]')}
    >
      <div className="justify-between items-center flex py-3">
        <div className="flex items-center gap-2">
          {!readOnly && (
            <MdDragIndicator
              size={17}
              className="text-[#414942] hover:cursor-move"
            />
          )}
          <div>{label}</div>
        </div>
        {!readOnly && (
          <IoIosRemoveCircleOutline
            size={17}
            onClick={onRemove}
            className="cursor-pointer text-[#414942]"
          />
        )}
      </div>
    </div>
  )
}
