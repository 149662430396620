import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import apiService from 'services/api'
import { Restaurant } from '../../../models/restaurant'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import * as S from '../CommonTable.styles'

import { ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import { AxiosError } from 'axios'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import TableButtonProduct from 'components/Buttons/TableButtons/TableButtonProduct'
import { IColumnSort, IColumnVisibility } from 'components/DataTable/types'
import { FbLink } from 'components/FbUI/FbLink'
import {
  restaurantsFilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import DoorExportModal from 'components/Modals/ExportModal/DoorExportModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { PredictedTrafficBadge } from 'components/PredictedTrafficBadge/PredictedTrafficBadge'
import { DoorsFilterset } from 'components/Tables/DoorsTable/DoorsFilterset'
import { DOOR_SUMMARY_KEY } from 'constants/tableQueryKeys'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { menu_ingredients } from 'models/menu_ingredients'
import { BiFoodMenu } from 'react-icons/bi'
import { BsDownload, BsTags, BsTruck } from 'react-icons/bs'
import {
  bulkEditDistributorsParams,
  bulkEditTagsParams,
} from 'services/apiTypes'
import { notifyError } from 'services/toast'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { useSelectedProductIds } from 'stores/SelectedProductsStore/SelectedProductsStore'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import { paramsContainAccountFilters } from 'utils/filterUtils'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { PlacesSummary } from '../../../models/summaries'
import { useOpportunitiesContext } from '../../../pages/app/opportunities/opportunities-context'
import {
  formatInteger,
  formatPercentage,
  formatUsd,
} from '../../../utils/formatting'
import {
  AccountOwnerCell,
  DistributorsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { TableSearchWithElastic } from '../../Filters/TableSearchWithElastic'
import { DoorMenuModal } from '../../Modals/MenuModal/DoorMenuModal'
import { OverflownText } from '../../OverflownText'
import { Anchor } from '../../UI/Anchor'
import { Switch } from '../../UI/Switch/Switch'
import { ChangeProductViewModal } from '../../change-product-view-modal'
import { LinkCell } from '../CommonTableCells/LinkCell'

const sortableFields = [
  'restaurant_name',
  'chain',
  'total_ltv',
  'brand_ltv',
  'revenue_ltv',
  'pounds_per_year',
  'reviews_count',
  'expense_category',
  'chain_count',
  'sales_stage',
  'account_owner',
  'dmanamecbsa',
  'avg_rating',
  'cuisine_50',
  'country',
  'state',
  'city',
  'zipcode',
  'domain',
  'velocity_group',
  'deal_count',
  'contact_count',
  'taro',
  'tabo',
  'one_year_total_value',
  'distributors',
  'michelin_stars',
  'menu_size',
  'menu_matches',
  'sales_stages',
] as Array<keyof Restaurant>

interface IDoorsTableProps {
  chainFilter?: number
  setPlaceData?: React.Dispatch<React.SetStateAction<Restaurant | null>>
  setDoorsSummary?: React.Dispatch<React.SetStateAction<PlacesSummary>>
  isChainTable?: boolean
  hideTitle?: boolean
  hideTopBar?: boolean
  disableUrlPagination?: boolean
  tableKey: string
}

const PAGE_SIZE = 100

function DoorsTableComponent({
  hideTitle = true,
  hideTopBar = false,
  disableUrlPagination = false,
  ...props
}: IDoorsTableProps) {
  const tableContext = useDataTableContext()
  const queryClient = useQueryClient()
  const api = apiService()

  const { setRestaurantsView, chainsTabSelected, doorsTabSelected } =
    useOpportunitiesContext()
  const [menuModal, setMenuModal] = useState<
    | {
        doorId: number
        doorName?: string
        itemsIds?: number[]
        menuLength?: number
      }
    | undefined
  >(undefined)

  // TABLE CONTEXT (SORTING, ETC)
  const {
    state: { sorting, rowSelection, totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = tableContext

  const selectedProductsIds = useSelectedProductIds()

  const [pagination, setPagination] = disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)

  const [showProductSelection, setShowProductSelection] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)

  const filtersAsParams = useFilterParams(restaurantsFilterStore)

  // FILTER PARAMS (FOR API CALL)
  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.chainFilter) {
      params.chain = props.chainFilter
    }

    return params
  }, [filtersAsParams, props.chainFilter])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
    products: selectedProductsIds,
  })

  // API CALL
  const { data: places, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    enabled: doorsTabSelected || !!props.chainFilter, // Enable only when tab is selected or chain filter is present (chain page)
    queryFn: async ({ signal }) => {
      clearSelectedRows()
      let err, res

      if (selectedProductsIds.length === 0) {
        ;[err, res] = await to(
          api.getRestaurants(
            {
              ...filterAndSortParams,
              page: pagination.pageIndex + 1,
              limit: pagination.pageSize,
            },
            signal
          )
        )
      } else {
        ;[err, res] = await to(
          api.getRestaurantsLTVByProduct(
            {
              ...filterAndSortParams,
              page: pagination.pageIndex + 1,
              limit: pagination.pageSize,
              product_id: selectedProductsIds[0],
            },
            signal
          )
        )
      }

      if ((err as AxiosError)?.code === 'ERR_NETWORK') {
        notifyError('Sorry! Unable to complete this search, please try again.')
      }
      if (err) throw err

      if (props.setPlaceData) {
        const result = res.results.find(
          (d: any) => !!d.banner_img_url || !!d.small_img_url
        )

        const place = result || res.results[0]

        if (!place?.firstbite_id) {
          return {
            res: res.results,
          }
        }

        const placeResponse = await api.getPlace(place?.firstbite_id)
        props.setPlaceData(placeResponse)
      }

      return res.results
    },
  })

  useEffect(() => {
    clearSelectedRows()
  }, [places])

  // SUMMARY API CALL
  const {
    data: placesSummary = {
      brand_ltv: 0,
      revenue_ltv: 0,
      total_ltv: 0,
      chains_count: 0,
      places_count: 0,
    },
    isFetching: placesSummaryLoading,
  } = api.useGetPlacesSummary(
    filterParams,
    selectedProductsIds,
    undefined,
    !props.isChainTable
  )

  // ˆ PASS LTV RESULTS TO PARENT COMPONENT ˆ
  useEffect(() => {
    if (placesSummaryLoading) return
    props.setDoorsSummary?.({
      ...placesSummary,
      isLoading: placesSummaryLoading,
    })
    setTotalRowsInBackend(placesSummary.places_count ?? 0)
  }, [placesSummaryLoading, placesSummary])

  const selectedDoorIds = useMemo(() => {
    const accessor = 'firstbite_id'
    return (
      places
        ?.filter((_: never, i: number) => rowSelection[i])

        ?.map((r: any) => {
          return accessor.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []
    )
  }, [places, rowSelection])

  const columns = useRestaurantsTableColumns(
    setMenuModal,
    !!props.isChainTable,
    props.chainFilter,
    selectedProductsIds,
    sorting
  )

  const refetchDoorsSummary = useCallback(() => {
    if (paramsContainAccountFilters(filterAndSortParams)) {
      queryClient.refetchQueries({ queryKey: [DOOR_SUMMARY_KEY] })
    }
  }, [filterAndSortParams, queryClient])

  const apiTagsAction = useCallback(
    async (values: bulkEditTagsParams) => {
      const response = await api.doorsBulkEditTags(values)
      refetchDoorsSummary()
      return response
    },
    [api, refetchDoorsSummary]
  )

  const apiDistributorsAction = useCallback(
    async (values: bulkEditDistributorsParams) => {
      const response = await api.doorsBulkEditDistributors(values)
      refetchDoorsSummary()
      return response
    },
    [api, refetchDoorsSummary]
  )

  return (
    <TableContainer>
      {!hideTitle && (
        <Row className="mb-4">
          <Col>
            <S.CardTitle>
              Doors{' '}
              <span style={{ fontWeight: 100 }}>
                ({placesSummary?.places_count})
              </span>
            </S.CardTitle>
          </Col>
        </Row>
      )}
      {!hideTopBar && (
        <>
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearchWithElastic
                filterStore={restaurantsFilterStore}
                historyKey="doors"
                options={[
                  {
                    label: 'Menu',
                    searchKey: 'menu',
                    searchPlaceholder: 'Type menu item',
                  },
                  {
                    label: 'Name',
                    searchKey: 'restaurant_name',
                    searchPlaceholder: 'Type restaurant name',
                  },
                ]}
              />

              <Switch
                type={'checkbox'}
                checked={chainsTabSelected}
                onChange={(e) => {
                  if (e.target.checked) {
                    setRestaurantsView('chain')
                  } else {
                    setRestaurantsView('door')
                  }
                }}
                labelRight={chainsTabSelected ? 'Chain View' : 'Door View'}
                className={'min-w-[120px]'}
              />

              <TableButtonProduct
                selectedProducts={selectedProductsIds}
                onClick={() => setShowProductSelection(true)}
              />

              <DoorsFilterset />

              <ColumnSelectorRecipient tableKey={props.tableKey} />

              <TableButtonAction
                items={[
                  {
                    label: 'Manage Tags',
                    icon: <BsTags size={20} />,
                    callback: () => setShowAssignTags(true),
                  },
                  {
                    label: 'Add Known Distributors',
                    icon: <BsTruck size={20} />,
                    callback: () => setShowAssignDistributors(true),
                  },
                  {
                    label: 'Export Selected',
                    icon: <BsDownload size={20} />,
                    callback: () => setShowExportModal(true),
                  },
                ]}
                disabled={!totalSelectedRows}
                selectedRowsCount={totalSelectedRows ?? 0}
              />
            </div>

            <FilterChips
              identifier={'DOORS_TABLE'}
              clearAllButton
              showActive
              store={restaurantsFilterStore}
            />
          </S.SearchContainer>

          <TagAssignModal
            show={showAssignTags}
            handleClose={() => setShowAssignTags(false)}
            tableQueryKey={TABLE_QUERY_KEY}
            data={places ?? []}
            filterAndSortParams={filterAndSortParams}
            apiTagsAction={apiTagsAction}
            idAccessor="chain.id"
          />

          <DistributorAssignModal
            show={showAssignDistributors}
            handleClose={() => setShowAssignDistributors(false)}
            tableQueryKey={TABLE_QUERY_KEY}
            data={places ?? []}
            idAccessor="chain.id"
            filterAndSortParams={filterAndSortParams}
            apiDistributorsAction={apiDistributorsAction}
          />

          <DoorExportModal
            filters={filterAndSortParams}
            productSelected={selectedProductsIds[0]}
            show={showExportModal || !!showExportAllModal}
            _handleClose={() => {
              setShowExportModal(false)
              setShowExportAllModal?.(false)
            }}
            selectedIds={showExportModal ? selectedDoorIds : undefined}
            count={
              showExportModal
                ? selectedDoorIds?.length
                : placesSummary?.places_count
            }
          />
        </>
      )}

      <DataTable
        tableKey={props.tableKey}
        key={props.tableKey}
        data={places ?? []}
        loading={isFetching}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        enableRowSelection={!props.chainFilter}
        isPaginationEnabled={true}
        defaultSort={[{ id: 'chain_count', desc: true }]}
        paginationOptions={{
          pageCount: Math.ceil(placesSummary?.places_count / PAGE_SIZE),
          setPagination: setPagination,
          isPaginationLoading: placesSummaryLoading,
          pagination: pagination,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={'restaurants-bars'}
            onDownloadButtonClick={() =>
              chainsTabSelected
                ? setShowExportAllModal(true)
                : setShowExportModal(true)
            }
          />
        }
      />

      <ChangeProductViewModal
        show={showProductSelection}
        handleClose={() => setShowProductSelection(false)}
      />

      {menuModal?.doorId && (
        <DoorMenuModal
          filterStore={restaurantsFilterStore}
          show={!!menuModal}
          handleClose={() => setMenuModal(undefined)}
          doorId={menuModal.doorId!}
          doorName={menuModal.doorName!}
          menuLength={menuModal?.menuLength}
        />
      )}
    </TableContainer>
  )
}

function useRestaurantsTableColumns(
  setMenuModal: React.Dispatch<
    React.SetStateAction<
      | {
          doorId: number
          doorName?: string
          itemsIds?: number[]
          menuLength?: number
        }
      | undefined
    >
  >,
  isChainTable: boolean,
  chainFilter: number | undefined,
  selectedProducts: number[],
  sorting: IColumnSort<object>[] | undefined
) {
  const featureFlag = featureFlagService()
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columns = useMemo(() => {
    let titleCols = [
      {
        accessorKey: 'restaurant_name',
        meta: {
          isEditable: false,
          isFirstColumn: true,
          headerTitle: 'Restaurant Name',
          headerGroup: 'Opportunity Attributes',
        },
        header: () => null,
        cell: (info) => {
          if (!info.getValue()) return '-'

          return (
            <FbLink
              to={`/door/${info.row.original.firstbite_id}`}
              target="_blank"
            >
              {info.getValue()}
            </FbLink>
          )
        },
        size: 250,
      },
      {
        id: 'chain',
        meta: {
          tooltip: tooltips?.['opps_chain'],
          headerGroup: 'Opportunity Attributes',
        },
        accessorKey: 'chain.chain',
        header: 'Chain',
        accessorFn: (row) => row?.chain?.chain,
        size: 250,
        cell: (info) => {
          const name = info.row.original.chain?.chain
          const id = info.row.original.chain?.id
          if (!name) return '-'

          return (
            <FbLink
              to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${id}`}
              target="_blank"
            >
              {name}
            </FbLink>
          )
        },
      },
      {
        accessorKey: 'chain_count',
        meta: {
          tooltip: tooltips?.['opps_chain_count'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Chain Size',
        accessorFn: (row) => row?.chain?.count,
        size: 150,
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '1',
      },
      {
        accessorKey: 'cuisine_50',
        meta: {
          tooltip: tooltips?.['opps_cuisine_50'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Cuisine',
        size: 150,
        cell: (info) => info.getValue() || '-',
      },
      {
        id: 'country',
        meta: {
          tooltip: tooltips?.['opps_country'],
          headerGroup: 'Location Data',
        },
        accessorFn: (row) => row?.address_info?.country,
        header: 'Country',
        size: 100,
        cell: (info) => info.getValue() || '-',
      },
      {
        accessorKey: 'full_address',
        meta: {
          tooltip: tooltips?.['opps_full_address'],
          headerGroup: 'Location Data',
        },
        header: 'Address',
        size: 200,
        cell: (info) => info.getValue() || '-',
      },
      {
        id: 'city',
        meta: {
          tooltip: tooltips?.['opps_city'],
          headerGroup: 'Location Data',
        },
        accessorFn: (row) => row?.address_info?.city,
        header: 'City',
        size: 200,
        cell: (info) => info.getValue() || '-',
      },
      {
        id: 'state',
        meta: {
          tooltip: tooltips?.['opps_state'],
          headerGroup: 'Location Data',
        },
        accessorFn: (row) => row?.address_info?.state,
        header: 'State',
        size: 100,
        cell: (info) => info.getValue() || '-',
      },
      {
        id: 'zipcode',
        meta: {
          tooltip: tooltips?.['opps_zipcode'],
          headerGroup: 'Location Data',
        },
        accessorFn: (row) => row?.address_info?.zip,
        header: 'ZIP Code',
        size: 100,
        cell: (info) => info.getValue() || '-',
      },
      {
        accessorKey: 'total_ltv',
        meta: {
          tooltip: tooltips?.['opps_total_ltv'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: 'Lifetime Total Value',
        accessorFn: (row) => row?.ltv_data?.total_ltv,
        size: 200,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'brand_ltv',
        meta: {
          tooltip: tooltips?.['opps_brand_ltv'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        accessorFn: (row) => row?.ltv_data?.brand_ltv,
        header: 'Lifetime Brand Value',
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'revenue_ltv',
        meta: {
          tooltip: tooltips?.['opps_revenue_ltv'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: 'Lifetime Revenue Value',
        accessorFn: (row) => row?.ltv_data?.revenue_ltv,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },

      {
        accessorKey: 'one_year_total_value',
        meta: {
          tooltip: tooltips?.['opps_taro_plus_tabo'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Total Value',
        accessorFn: (row) => row?.ltv_data?.one_year_total_value,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'taro',
        meta: {
          tooltip: tooltips?.['opps_taro'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Revenue Value',
        accessorFn: (row) => row?.ltv_data?.taro,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'tabo',
        meta: {
          tooltip: tooltips?.['opps_tabo'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Brand Value',
        accessorFn: (row) => row?.ltv_data?.tabo,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'pounds_per_year',
        meta: {
          tooltip: tooltips?.['opps_pounds_per_year'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Volume (lbs)',
        accessorFn: (row) => row?.ltv_data?.pounds_per_year,
        size: 150,
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '-',
      },
    ] satisfies ColumnDef<Restaurant, any>[]

    if (!featureFlag.shouldShowDoorMenu) {
      titleCols = titleCols.filter((col) => col.id !== 'menu')
    }

    let cols = [
      {
        accessorKey: 'avg_rating',
        meta: {
          tooltip: tooltips?.['opps_avg_rating'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Avg rating',
        size: 150,
        cell: (info) => info.getValue()?.toFixed(2) || '-',
      },
      {
        accessorKey: 'reviews_count',
        meta: {
          tooltip: tooltips?.['opps_reviews_count'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Total Reviews',
        size: 150,
        cell: (info) => formatInteger(info.getValue()) || '-',
      },
      {
        accessorKey: 'expense_category',
        meta: {
          tooltip: tooltips?.['opps_expense_category'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Expense Cat.',
        size: 100,
        cell: (info) => {
          const value = info.getValue()
          if (value === 'nan' || !value) {
            return '-'
          } else {
            return value
          }
        },
      },
      {
        accessorKey: 'velocity_group',
        meta: {
          tooltip: tooltips?.['opps_velocity_group'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Predicted Traffic',
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      },
      {
        id: 'instagram_url',
        accessorKey: 'chain.instagram.url',
        meta: {
          tooltip: tooltips?.['opps_instagram_url'],
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Handle',
        cell: (info) => {
          let value = info.row.original?.chain?.instagram?.url
          if (!value) return '-'

          // Get handle from value, value is a url that can end on / or not
          if (value.endsWith('/')) {
            value = value.slice(0, -1)
          }
          const handle = '@' + value.split('/').pop()

          return (
            <Anchor href={'//' + value} target="_blank">
              {handle}
            </Anchor>
          )
        },
      },
      {
        id: 'instagram_followers',
        accessorKey: 'chain.instagram.followers',
        meta: {
          tooltip: tooltips?.['opps_instagram_followers'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Followers',
        cell: (info) => {
          const value = info.row.original.chain.instagram?.followers
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        id: 'instagram_following',
        accessorKey: 'chain.instagram.following',
        meta: {
          tooltip: tooltips?.['opps_instagram_following'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Following',
        cell: (info) => {
          const value = info.row.original.chain.instagram?.following
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        id: 'instagram_posts',
        accessorKey: 'chain.instagram.posts',
        meta: {
          tooltip: tooltips?.['opps_instagram_posts'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Posts',
        cell: (info) => {
          const value = info.row.original.chain.instagram?.posts
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        header: 'Featured On',
        meta: {
          tooltip: tooltips?.['opps_door_featured_on'],
          headerGroup: 'Reputation Data',
        },
        id: 'reputation_data',
        size: 200,
        cell: (info) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { michelin_id, eater_id, infatuation_id } = info?.row?.original
          const reputationPlatforms = [
            michelin_id && 'Michelin',
            eater_id && 'Eater',
            infatuation_id && 'Infatuation',
          ].filter(Boolean)

          if (reputationPlatforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {reputationPlatforms.map((platform, index) => (
                <Badge key={index} className="!bg-green-500">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        header: 'Michelin Stars',
        meta: {
          tooltip: tooltips?.['opps_door_michelin_stars'],
          headerGroup: 'Reputation Data',
        },
        id: 'michelin_stars',
        accessorKey: 'michelin_stars',
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (value === undefined || value === null) return '-'
          return value
        },
      },
      {
        header: 'Delivery Platforms',
        meta: {
          tooltip: tooltips?.['opps_Delivery'],
          headerGroup: 'Reputation Data',
        },
        id: 'delivery_platforms',
        size: 200,
        cell: (info) => {
          const { has_doordash_id, has_ubereats_id, has_grubhub_id } =
            // eslint-disable-next-line no-unsafe-optional-chaining
            info.row?.original
          const platforms = [
            has_doordash_id && 'Doordash',
            has_ubereats_id && 'Uber Eats',
            has_grubhub_id && 'Grubhub',
          ].filter(Boolean)

          if (platforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {platforms.map((platform, index) => (
                <Badge key={index} bg="info">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        accessorKey: 'domain',
        meta: {
          tooltip: tooltips?.['opps_domain'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Domain',
        size: 200,
        cell: (info) => {
          const val = info.getValue()
          return val ? (
            <Anchor href={'//' + val} target="_blank">
              {val}
            </Anchor>
          ) : (
            '-'
          )
        },
      },
      {
        accessorKey: 'website',
        meta: {
          tooltip: tooltips?.['opps_website'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Website',
        size: 100,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      },
      {
        accessorKey: 'phone',
        meta: {
          tooltip: tooltips?.['opps_phone'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Phone',
        size: 180,
        cell: (info) => info.getValue() || '-',
      },
      {
        id: 'menu',
        meta: {
          tooltip: tooltips?.['opps_menu'],
          headerGroup: 'Opportunity Attributes',
        },
        accessorKey: 'menu',
        size: 100,
        header: 'Menu',
        cell: (info) => {
          const items = info.row.original?.search_items?.map(
            (item) => item.item_id
          )

          const menuLength = info.row.original?.menu_size ?? 0

          if (!menuLength)
            return (
              <div className="ml-1">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Unavailable</Tooltip>}
                >
                  <div className="max-w-max">
                    <BiFoodMenu size={20} className="text-gray-400" />
                  </div>
                </OverlayTrigger>
              </div>
            )

          return (
            <div className="ml-1">
              <BiFoodMenu
                size={20}
                onClick={() =>
                  setMenuModal({
                    doorId: info.row.original.firstbite_id,
                    doorName: info.row.original.restaurant_name,
                    itemsIds: items,
                    menuLength,
                  })
                }
                className="cursor-pointer"
              />
            </div>
          )
        },
      },
      {
        id: 'menu_size',
        accessorKey: 'menu_size',
        meta: {
          tooltip: tooltips?.['opps_menu_size'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Size',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
        size: 100,
      },
      {
        id: 'menu_matches',
        accessorKey: 'match_count',
        meta: {
          tooltip: tooltips?.['opps_menu_matches'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Matches',
        size: 110,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        id: 'dma',
        meta: {
          tooltip: tooltips?.['opps_dma'],
          headerGroup: 'Location Data',
        },
        accessorFn: (row) => row?.address_info?.dmanamecbsa,
        header: 'DMA',
        size: 250,
        cell: (info) => {
          const value = info.getValue()
          if (value === 'NaN' || !value) {
            return '-'
          } else {
            return value
          }
        },
      },
      {
        accessorKey: 'menu_url',
        meta: {
          tooltip: tooltips?.['opps_menu_url'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu URL',
        size: 100,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      },
      {
        accessorKey: 'google_place_url',
        meta: {
          tooltip: tooltips?.['opps_google_place_url'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Place URL',
        size: 150,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      },
    ] satisfies ColumnDef<Restaurant, any>[]

    if (!featureFlag.enableInstagremData) {
      cols = cols.filter((col) => !col.id?.includes('instagram'))
    }

    if (!featureFlag.shouldShowReputationData) {
      cols = cols.filter(
        (col) => !['reputation_data', 'michelin_stars'].includes(col?.id ?? '')
      )
    }

    const extraCols = [
      {
        accessorKey: 'ingredients_matrix',
        meta: {
          tooltip: tooltips?.['opps_ingredients_matrix'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Characteristics',
        size: 500,
        cell: (info) => {
          const ingredients = info.row.original.ingredients_matrix
          const display: string[] = []

          if (!ingredients) return null

          const ingredientsValuesWhitelist = menu_ingredients.map(
            (item) => item.value
          )

          Object.entries(ingredients)
            .filter(([key]) => ingredientsValuesWhitelist.includes(key))
            .map(([key, value]) => {
              if (Number(value) > 0) display.push(key)
            })

          return <OverflownText>{display.join(', ')}</OverflownText>
        },
      },
      {
        accessorKey: 'note_count',
        meta: {
          tooltip: tooltips?.['opps_note_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Notes',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      },
      {
        accessorKey: 'contact_count',
        meta: {
          tooltip: tooltips?.['opps_contact_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Contacts',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      },
      {
        id: 'deal_count',
        meta: {
          tooltip: tooltips?.['opps_deals_count'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Deals',
        size: 110,
        cell: (info) => {
          return info.row.original.deal_count || '-'
        },
      },
      {
        id: 'sales_stages',
        meta: {
          tooltip: tooltips?.['opps_sales_stages'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Sales Stages',
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.deals}
              sortAsc={
                sorting?.length && sorting[0].id === 'sales_stages'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      },
      {
        id: 'account_owners',
        meta: {
          tooltip: tooltips?.['opps_account_owners'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Account Owners',
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.deals
          return <AccountOwnerCell deals={deals} />
        },
      },
      {
        accessorKey: 'tags',
        meta: {
          tooltip: tooltips?.['opps_tags'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Tags',
        size: 260,
        cell: (info) => {
          return <TaglistCell taglist={info.row.original?.taglist} />
        },
      },
      {
        accessorKey: 'distributors',
        meta: {
          tooltip: tooltips?.['opps_distributors'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Known Distributors',
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell distributors={info.row.original?.distributors} />
          )
        },
      },
      {
        accessorKey: 'address_info.income_medianhouseholdincome_hh_cnt',
        id: 'income_medianhouseholdincome_hh_cnt',
        meta: { tooltip: tooltips?.['opps_median_hhi'] },
        header: 'Median Household Income',
        cell: (info) => formatUsd(info.getValue()),
      },
      {
        accessorKey: 'address_info.pop_sqmi',
        id: 'pop_sqmi',
        meta: { tooltip: tooltips?.['opps_pop_density'] },
        header: 'Population Density',
        cell: (info) => formatInteger(info.getValue() ?? 0) + '/mi²',
      },
      {
        accessorKey: 'address_info.hh_gt100k',
        id: 'hh_gt100k',
        meta: { tooltip: tooltips?.['opps_hh_gt100k'] },
        header: 'High Household Income',
        cell: (info) => {
          const value =
            (info?.row?.original?.address_info?.income_100000to149999_hh_pct +
              info?.row?.original?.address_info?.income_150000to199999_hh_pct +
              info?.row?.original?.address_info?.income_200000ormore_hh_pct) *
            100
          return formatPercentage(value ?? 0) + '%'
        },
      },
      {
        accessorKey:
          'address_info.employment_unemployed_rate_county_level_only_pct',
        id: 'employment_unemployed_rate_county_level_only_pct',
        meta: { tooltip: tooltips?.['opps_unemployment_rate'] },
        header: 'Unemployment Rate',
        cell: (info) => formatInteger((info.getValue() ?? 0) * 100) + '%',
      },
    ] satisfies ColumnDef<Restaurant, any>[]

    if (isChainTable) {
      titleCols = titleCols.filter(
        (el) =>
          el.accessorKey !== 'cuisine_50' &&
          el.accessorKey !== 'chain.chain' &&
          el.accessorKey !== 'chain_count'
      )
      cols = cols.filter((el) => el.accessorKey !== 'expense_category')
    }

    if (!featureFlag.shouldShowMenuSize) {
      cols = cols.filter((col) => col.id !== 'menu_size')
    }

    if (chainFilter) {
      return [...titleCols, ...cols, ...extraCols]
    }

    return [...titleCols, ...cols]
  }, [
    selectedProducts,
    featureFlag.shouldShowMenuSize,
    featureFlag.enableInstagremData,
    featureFlag.shouldShowReputationData,
  ])

  return columns
}

export function DoorsTable(
  props: IDoorsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Predicted Values',
        'Reputation Data',
        'Location Data',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <DoorsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
