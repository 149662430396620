import styled from 'styled-components'

export const Card = styled.div`
  padding: 60px 80px;
  background-color: #f5f5f5;
  flex: 1;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  max-width: 560px;
  margin: 0 auto;

  padding: 0 80px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const Content = styled.div`
  padding: 32px;
`

export const Header = styled.div`
  padding: 33px 25px 0 25px;
`

export const Title = styled.h1`
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 20px;
`

export const Subtitle = styled.h2`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
`

export const Hr = styled.hr`
  margin-top: 13px;
  margin-bottom: 0px;
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #344054;
`

export const Legend = styled.p`
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`
