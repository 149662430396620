import { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { ErrorLabel } from '../ErrorLabel'
import * as S from './styles'

export function NumericInput<T extends FieldValues>({
  fieldName,
  label,
  control,
  decimalScale = 0,
  allowNegative = true,
}: {
  control: Control<T, any>
  fieldName: Path<T>
  label?: string
  placeholder?: string
  type?: string
  decimalScale?: number
  allowNegative?: boolean
}) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: fieldName, control })

  const [localValue, setLocalValue] = useState<number | undefined>(value)

  const _onChange = (values: NumberFormatValues) => {
    if (values.value === '') {
      setLocalValue(undefined)
      onChange(undefined)
    } else {
      const numericValue = values.floatValue
      setLocalValue(numericValue)
      onChange(numericValue)
    }
  }

  return (
    <S.Wrapper>
      <S.Label htmlFor={fieldName}>{label}</S.Label>
      <NumericFormat
        allowNegative={allowNegative}
        onValueChange={_onChange}
        thousandSeparator={true}
        allowedDecimalSeparators={decimalScale ? ['.'] : []}
        decimalScale={decimalScale}
        value={localValue !== undefined ? localValue : ''}
        customInput={CustomInput}
        className={`${error?.message ? 'input-error' : ''}`}
      />
      {error?.message && <ErrorLabel message={error?.message} />}
    </S.Wrapper>
  )
}

const CustomInput = (props: any) => {
  return <FormControl {...props} />
}
