import styled from 'styled-components/macro'
import { FbLink } from 'components/FbUI/FbLink'

function Verified() {
  return (
    <div style={{ width: '400px', flexShrink: 0 }}>
      <Title>Login</Title>
      <p>
        Your email has been verified! <FbLink to="/login">Click here</FbLink> to
        login.
      </p>
    </div>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

export default Verified
