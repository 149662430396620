import { useQueryClient } from '@tanstack/react-query'
import to from 'await-to-js'
import CreatableSelect from 'components/FormUtils/CreatableSelect'
import { SelectOption } from 'components/FormUtils/Select'
import { Control, FieldValues, Path, UseFormSetError } from 'react-hook-form'
import apiService from 'services/api'
import { handleFormError } from 'utils/handleFormError'

export function CreatableDistributorSelector<T extends FieldValues>({
  name,
  control,
  options,
  setError,
  label,
  placeholder,
  className,
  optional,
}: {
  name: Path<T>
  control: Control<T, unknown>
  options?: SelectOption[]
  setError: UseFormSetError<T>
  label?: string
  placeholder?: string
  className?: string
  optional?: boolean
}) {
  const api = apiService()
  const queryClient = useQueryClient()

  const onCreateOption = async (value: string) => {
    const [err, res] = await to(
      api.postDistributor({
        name: value,
      })
    )

    if (err) handleFormError(err, setError)
    else {
      // Invalidate tags query
      queryClient.invalidateQueries({ queryKey: ['distributors'] })
      return {
        label: res?.name,
        value: res?.id,
      }
    }

    return null
  }

  return (
    <CreatableSelect
      name={name}
      isMulti
      control={control}
      type="text"
      label={label}
      placeholder={placeholder}
      options={options}
      onCreateOption={onCreateOption}
      className={className}
      optional={optional}
    />
  )
}
