import * as S from './styles'
import { LinkProps } from 'react-router-dom'

export function FbLink(props: LinkProps) {
  const { to, ...rest } = props

  return (
    <S.FbLink to={to} {...rest}>
      {props.children}
    </S.FbLink>
  )
}
