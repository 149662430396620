export enum AsyncJobStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  TIMED_OUT = 'TIMED_OUT',
}

export enum AsyncJobType {
  FILL_LTV_TABLE = 'FILL_LTV_TABLE',
}

export interface AsyncJobListResponse {
  id: number
  job_type: AsyncJobType
  job_id: string
  status: AsyncJobStatus
  created: string
  modified: string
}
