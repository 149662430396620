import Tippy from '@tippyjs/react/headless' // different import path!
import styled from 'styled-components'

export interface ITooltip {
  children: JSX.Element
  title?: string
  content?: string
  link?: {
    text: string
    href: string
  }
  wrapTrigger?: boolean
  placement?: 'top' | 'bottom' | 'left' | 'right'
  delay?: number | [number | null, number | null] | undefined
}

export function Tooltip({
  children,
  title,
  content,
  link,
  wrapTrigger = true,
  placement = 'bottom',
  delay = [0, 1500],
}: ITooltip): JSX.Element {
  if (!content) return children
  return (
    <Tippy
      delay={delay}
      interactive={true}
      placement={placement}
      appendTo={document.getElementById('root') as HTMLElement}
      // animation="fade"
      onShow={(instance) => {
        const instanceIndex = instance.popper.id.split('-')[1]
        document.querySelectorAll('[id^="tippy-"]').forEach((el) => {
          const elIndex = el.id.split('-')[0]
          if (elIndex !== instanceIndex) {
            el.remove()
          }
        })
      }}
      render={() => (
        <TooltipBox>
          {title && <TooltipTitle>{title}</TooltipTitle>}
          {content && <TooltipContent>{content}</TooltipContent>}
          {link?.text && (
            <TooltipLink href={link.href} target="_blank" rel="noreferrer">
              {link.text}
            </TooltipLink>
          )}
        </TooltipBox>
      )}
    >
      {wrapTrigger ? <div>{children}</div> : children}
    </Tippy>
  )
}

const TooltipBox = styled.div`
  border-radius: 12px;
  z-index: 9999;
  background: #e8eeeb;
  box-shadow:
    0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  width: 280px;
  max-width: 280px;
`

const TooltipTitle = styled.span`
  margin-bottom: 4px;
  color: var(--m-3-sys-light-on-surface-variant, #49454f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
`
const TooltipContent = styled.span`
  color: var(--m-3-sys-light-on-surface-variant, #49454f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
`
const TooltipLink = styled.a`
  margin-top: 12px;
  color: #087443;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  text-decoration-line: underline;
  text-transform: capitalize;
  &:hover {
    color: #83b9a1;
  }
`
