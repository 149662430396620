import BaseStep from './BaseStep'
import { Col, Container, Row } from 'react-bootstrap'
import apiService from 'services/api'
import { useEffect } from 'react'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { useForm } from 'react-hook-form'

export function AddTagsStep() {
  const {
    methods: { prevStep, handleClose, importContacts, setTags },
    state: { previewData, file, isImporting, tags },
  } = useImportContactContext()

  if (!previewData || !file) return null

  const { success_count: totalContacts } = previewData

  const api = apiService()
  const { data: tagsOptions } = api.useGetTagsOptions(false, 'contacts')

  const { control, setError, watch } = useForm({
    defaultValues: {
      tags: tags,
    },
  })

  const _tags = watch('tags')

  useEffect(() => {
    setTags(_tags)
  }, [_tags])

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      onContinue={importContacts}
      title="Update New Contacts - Tag Contacts"
      backButtonText="Back"
      continueButtonText="Complete"
      isContinueLoading={isImporting}
    >
      <S.Content style={{ maxHeight: 500 }}>
        <S.ContentTitle>
          To help organize these contacts, you can add one or multiple tags by
          creating or assigning tags below.Note: these differ from company and
          deal tags, and are only assigned on the contact level.
          <Container className="mt-2">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                Add Contact Labels to {totalContacts} new contacts
                <CreatableTagSelector
                  name="tags"
                  control={control}
                  placeholder="Labels"
                  options={tagsOptions}
                  setError={setError}
                  tagParent="contacts"
                />
              </Col>
            </Row>
          </Container>
        </S.ContentTitle>
      </S.Content>
    </BaseStep>
  )
}
