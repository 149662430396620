import { create } from 'zustand'
import { Campaign } from '../../models/campaign'
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware'

interface CampaignsState {
  campaignsOpen: Campaign[]
  campaignShown: Campaign | undefined
  overviewShown: boolean

  openCampaign: (id: Campaign) => void
  closeCampaign: (id: number) => void
  showCampaign: (id: Campaign) => void
  showOverview: () => void
}

export const useCampaignsStore = create(
  persist<CampaignsState>(
    (set) => ({
      campaignsOpen: [],
      campaignShown: undefined,
      overviewShown: true,
      openCampaign: (by) =>
        set((state) => ({
          campaignsOpen: [
            ...state.campaignsOpen.filter((it) => it.id !== by.id),
            by,
          ],
          campaignShown: by,
          overviewShown: false,
        })),
      closeCampaign: (by) =>
        set((state) => {
          const newCampaignsOpen = state.campaignsOpen.filter(
            (c) => c.id !== by
          )
          return {
            campaignsOpen: newCampaignsOpen,
            campaignShown: newCampaignsOpen.length
              ? newCampaignsOpen[0]
              : undefined,
            overviewShown: !newCampaignsOpen.length,
          }
        }),
      showCampaign: (by) => {
        set(() => ({ campaignShown: by, overviewShown: false }))
      },
      showOverview: () => {
        set(() => ({
          overviewShown: true,
          campaignShown: undefined,
        }))
      },
    }),
    {
      name: 'campaignsStore',
      storage: createJSONStorage<CampaignsState>(() => persistentStorage),
    }
  )
)

const persistentStorage: StateStorage = {
  getItem: (key): string => {
    return JSON.parse(localStorage.getItem(key) as string)
  },
  setItem: (key, newValue): void => {
    localStorage.setItem(key, JSON.stringify(newValue))
  },
  removeItem: (key): void => {
    localStorage.removeItem(key)
  },
}
