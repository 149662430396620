import { FbLink } from 'components/FbUI/FbLink'
import * as S from './styles'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

export function ErrorText<T extends FieldValues>({
  name,
  control,
  resendVerification,
}: {
  control: Control<T, any>
  name: Path<T>
  resendVerification?: () => void
}) {
  const {
    fieldState: { error },
  } = useController({ name: name, control })

  if (error?.message === 'E-mail is not verified.') {
    return (
      <S.Wrapper>
        <S.ErrorText>
          {error?.message ?? ''}&nbsp;
          <FbLink onClick={resendVerification} to="#">
            Click here
          </FbLink>{' '}
          to resend verification email.
        </S.ErrorText>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <S.ErrorText>{error?.message ?? ''}&nbsp;</S.ErrorText>
    </S.Wrapper>
  )
}
