import { usePagination } from 'utils/usePagination'
import * as S from './styles'
import { ITablePaginationOptions } from '../types'
import { useDataTableContext } from '../DataTableContext'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import FbButton from 'components/FbUI/FbButton'

export interface ITablePaginationProps {
  paginationOptions: ITablePaginationOptions
}

export default function DataTablePagination({
  paginationOptions,
}: ITablePaginationProps) {
  const pageSize = 100
  const pageIndex = paginationOptions.pagination?.pageIndex ?? 0
  const currentPage = pageIndex + 1
  const canPreviousPage = pageIndex > 0
  const canNextPage = currentPage < paginationOptions.pageCount

  function nextPage() {
    paginationOptions.setPagination?.({
      pageIndex: pageIndex + 1,
      pageSize: pageSize,
    })
  }

  function prevPage() {
    paginationOptions.setPagination?.({
      pageIndex: pageIndex - 1,
      pageSize: pageSize,
    })
  }

  const paginationRange = usePagination({
    pageCount: paginationOptions.pageCount,
    siblingCount: 1,
    currentPage: currentPage,
  })

  const {
    methods: { clearSelectedRows },
  } = useDataTableContext()

  return (
    <S.DataTablePaginationContainer>
      <FbButton
        variant="secondary"
        onClick={() => {
          if (canPreviousPage) {
            clearSelectedRows()
            prevPage()
          }
        }}
        style={{
          visibility: canPreviousPage ? 'visible' : 'hidden',
        }}
        disabled={paginationOptions.isPaginationLoading}
      >
        <MdArrowBack />
        Previous
      </FbButton>
      <S.DataTablePaginationButtonsContainer>
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === '...') {
            return (
              <S.DataTablePaginationDots key={index}>
                <span>. . .</span>
              </S.DataTablePaginationDots>
            )
          }

          return (
            <S.PageButton
              isActive={pageNumber === currentPage}
              key={'page-' + pageNumber}
              onClick={() => {
                clearSelectedRows()
                paginationOptions.setPagination?.({
                  pageIndex: (pageNumber as number) - 1,
                  pageSize: pageSize,
                })
              }}
            >
              {pageNumber}
            </S.PageButton>
          )
        })}
      </S.DataTablePaginationButtonsContainer>
      <FbButton
        variant="secondary"
        onClick={() => {
          if (canNextPage) {
            clearSelectedRows()
            nextPage()
          }
        }}
        style={{
          visibility: canNextPage ? 'visible' : 'hidden',
        }}
        disabled={paginationOptions.isPaginationLoading}
      >
        Next
        <MdArrowForward />
      </FbButton>
    </S.DataTablePaginationContainer>
  )
}
