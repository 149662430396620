import { K12ChainProxy } from '../models/k12'
import { notifyError } from './toast'
import to from 'await-to-js'
import { clientInstance } from '../utils/http-client'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { PaginatedResponse } from '../models/paginated-response'
import qs from 'query-string'
import apiService, { baseAPICall } from './api'
import { bulkEditDistributorsParams, bulkEditTagsParams } from './apiTypes'

export async function getK12District(
  k12DistrictId?: number
): Promise<K12ChainProxy> {
  if (!k12DistrictId) {
    notifyError('No k12 district id provided')
    throw new Error('no k12 district id provided')
  }

  const url = `k12/districts/${k12DistrictId}/`
  const [err, res] = await to(clientInstance.get(url))

  if (err) {
    notifyError(err.message)
    throw err
  }

  return res.data
}

export const useGetK12District = (k12ChainId?: number) =>
  useQuery<K12ChainProxy, AxiosError>({
    queryKey: ['k12district', k12ChainId],
    queryFn: () => getK12District(k12ChainId),
    enabled: !!k12ChainId, // Only fetch when chainId is truthy
    retry: (failureCount, error: Error | AxiosError) => {
      // By defualt, useQuery performs 3 retries on network errors
      // No retry on 404 error; continue on other errors up to 3 times
      return axios.isAxiosError(error) && error?.response?.status === 404
        ? false
        : failureCount < 3
    },
  })

export async function getK12DistrictsList(params = {}) {
  return baseAPICall<PaginatedResponse<K12ChainProxy>>(() =>
    clientInstance.get(`/k12/districts/`, {
      params: {
        ...params,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export async function k12BulkEditTags(values: bulkEditTagsParams) {
  return apiService().bulkEditTags({
    type: 'k12',
    values,
  })
}

export async function k12BulkEditDistributors(
  values: bulkEditDistributorsParams
) {
  return apiService().bulkEditDistributors({
    type: 'k12',
    values,
  })
}

export async function exportK12Districts(
  params = {},
  selectedColumns: string[],
  exportType: 'csv' | 'xlsx',
  signal?: AbortSignal
) {
  return baseAPICall(() =>
    clientInstance.post(
      'k12/districts/export/',
      {
        selected_columns: selectedColumns,
        export_type: exportType,
      },
      {
        responseType: 'blob',
        signal,
        params,
        paramsSerializer: (p) => {
          return qs.stringify(p)
        },
      }
    )
  )
}

export async function getK12CountiesOptions() {
  return baseAPICall<{ value: string; label: string }[]>(() =>
    clientInstance.get(`/k12/districts/counties`)
  )
}

export const useK12CountiesOptions = () =>
  useQuery({
    queryKey: ['k12-counties'],
    queryFn: getK12CountiesOptions,
    refetchOnMount: false,
  })
