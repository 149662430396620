import styled from 'styled-components/macro'

export const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  user-select: none;
  margin-bottom: 5px;
`

export const Input = styled.input`
  all: unset;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  height: 35px;
  font-size: 15px;
  line-height: 1;
  color: #424242;
  background-color: #f5f5f5;
  box-shadow: 0 0 0 1px #d0d5dd;
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
  border: 1px solid transparent;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
