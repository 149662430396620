import { Spinner } from 'react-bootstrap'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import * as S from './styles'
import { useQuery } from '@tanstack/react-query'
import { BootstrapInput } from '../../../components/FormUtils/NumericInput'
import dialogConfirm from 'components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import { handleFormError } from '../../../utils/handleFormError'
import to from 'await-to-js'
import FbButton from '../../../components/FbUI/FbButton'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { Modal } from '../../../components/UI/Modal/Modal'
import { TextareaInput } from 'components/FormUtils/TextareaInput'
import { OverflownText } from 'components/OverflownText'
import { SalesStage } from 'models/sales_stages'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'

const defaultValuesStage = {
  stage: '',
  win_rate: 0,
  avg_days_to_close: 0,
  description: '',
}

export function SalesStageTable() {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedStageID, setSelectedStageID] = useState<number>()

  const {
    data: stageData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['stages'],
    queryFn: async () => {
      return await api.getSalesStages()
    },
  })

  const data = stageData?.sort(
    (a, b) => b.avg_days_to_close - a.avg_days_to_close
  )

  const {
    control: control,
    handleSubmit: handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm({
    defaultValues: defaultValuesStage,
    mode: 'onSubmit',
    // resolver: zodResolver(productShape),
  })

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset(defaultValuesStage)
    setSelectedStageID(undefined)
  }

  const onSubmitCreate = handleSubmit(async (values) => {
    const [err] = await to(
      api.postSalesStage({
        avg_days_to_close: values.avg_days_to_close,
        stage: values.stage,
        win_rate: values.win_rate,
        description: values.description,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()
    hideModal()
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    if (!selectedStageID) return

    const [err] = await to(
      api.putStage(selectedStageID, {
        avg_days_to_close: values.avg_days_to_close,
        stage: values.stage,
        win_rate: values.win_rate,
        description: values.description,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()

    hideModal()
  })

  async function handleRowDelete(index: number) {
    const id = data?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this stage?',
        'Delete Stage'
      )
    ) {
      await api.deleteStage(id)
      void refetch()
    }
  }

  function handleRowEdit(index: number) {
    setShowModal(true)
    setSelectedStageID(data?.[index]?.id)
    if (data) reset(data?.[index])
  }

  const columns = useMemo<ColumnDef<SalesStage, any>[]>(
    () => [
      {
        accessorKey: 'stage',
        header: 'Stage Name',
        size: 150,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          return <OverflownText maxLines={2}>{value}</OverflownText>
        },
      },
      {
        accessorKey: 'win_rate',
        header: 'Win Rate (%)',
        size: 100,
      },
      {
        accessorKey: 'avg_days_to_close',
        header: 'Avg Days to Close',
        size: 100,
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 100,
        meta: { rightAlign: true },
        cell: ({ row }) => {
          if (
            row.original.stage === 'Closed Won' ||
            row.original.stage === 'Closed Lost' ||
            row.original.stage === 'Churn'
          )
            return (
              <div
                className="flex justify-end w-full"
                style={{
                  fontSize: 11,
                  textTransform: 'uppercase',
                  color: '#71717A',
                }}
              >
                Required
              </div>
            )
          return (
            <ActionButtons
              className={'justify-end pr-0'}
              onDelete={() => handleRowDelete(row.index)}
              onEdit={() => handleRowEdit(row.index)}
            />
          )
        },
      },
    ],
    [data]
  )

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  const salesStageDescriptionText = `
    Customize your sales pipeline by creating stages and win rates that
    reflect your current process and performance. Note that a chain can
    only be in one stage at a time, and Closed Won, Closed Lost, and
    Churn are not editable. Changes to win rates will impact LTV
    calculations in restaurant and dashboard views.
  `

  return (
    <>
      <S.SettingsTableHeaderContainer
        style={{
          height: '95px',
        }}
      >
        <S.TableDescriptionText>
          <OverflownTextTooltip
            tooltipText={salesStageDescriptionText}
            maxLines={4}
            placement="bottom"
          >
            {salesStageDescriptionText}
          </OverflownTextTooltip>
        </S.TableDescriptionText>
        <FbButton
          style={{ width: 200, marginTop: 20, marginLeft: 20 }}
          onClick={() => openModal()}
        >
          Create Stage
        </FbButton>
      </S.SettingsTableHeaderContainer>
      <S.SettingsTableContainer
        style={{
          height: 'calc(100vh - 310px)',
        }}
      >
        <ClientDataTable
          stickyLastColumn={true}
          data={data}
          columns={columns}
        />
        <Modal
          open={showModal}
          title={selectedStageID ? 'Edit Sales Stage' : 'Create New Stage'}
          description={
            selectedStageID ? (
              <>
                Edit your sales stage to reflect your current process and
                performance.
                <br />
                &quot;Note that changes may change LTV and revenue estimates.
              </>
            ) : (
              <>
                Create a sales stage to reflect your current process and its
                performance.
                <br />
                Note that changes may change LTV and revenue estimates.
              </>
            )
          }
          onOpenChange={(open) => !open && hideModal()}
          loading={isSubmitting}
          blockAccept={!isValid}
          onAccept={selectedStageID ? onSubmitUpdate : onSubmitCreate}
          acceptButtonText={selectedStageID ? 'Update' : 'Create'}
        >
          <BootstrapInput
            name="stage"
            control={control}
            type="text"
            label="Stage Name"
            placeholder="Sales Stage"
          />
          <BootstrapInput
            name="win_rate"
            control={control}
            type="text"
            label="Win Rate (%)"
            placeholder="Win Rate"
            units={'%'}
          />
          <BootstrapInput
            name="avg_days_to_close"
            control={control}
            type="text"
            label="Average Days to Close"
            placeholder="Average Days to Close"
          />
          <TextareaInput
            name="description"
            control={control}
            type="textarea"
            label="Description"
            placeholder="Description"
          />
        </Modal>
      </S.SettingsTableContainer>
    </>
  )
}
