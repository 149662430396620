import styled from 'styled-components/macro'

export const NotificationContainer = styled.div<{ stickRight?: boolean }>`
  background: #fff;
  box-shadow:
    0px 1px 2px 0px rgba(30, 25, 25, 0.08),
    0px 2px 4px 0px rgba(30, 25, 25, 0.08),
    0px 4px 8px 0px rgba(30, 25, 25, 0.08),
    0px 8px 16px 0px rgba(30, 25, 25, 0.08),
    0px 8px 32px 0px rgba(30, 25, 25, 0.08);
  position: absolute;
  display: flex;
  width: 320px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 200;
  ${(props) => (props.stickRight ? 'right: 20px;' : '')}
`
export const NotificationHeader = styled.div`
  display: flex;
  padding: 12px 16px 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #0d5635;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`

export const NotificationSubHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 16px 4px 16px;
  justify-content: space-between;
  align-items: center;

  color: #1a1918;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  & > a {
    font-size: 10px;
  }
`
