export type FilterIdentifier =
  | 'CHAINS_TABLE'
  | 'DOORS_TABLE'
  | 'CONTACTS_TABLE'
  | 'DEALS_TABLE'
  | 'ACTIVITY_TABLE'
  | 'ACTIVITY_TABLE_V2'
  | 'COMPANIES_TABLE'
  | 'DEALS_PIPELINE_TABLE'
  | 'CNU_TABLE'
  | 'K12_TABLE'

export const SavedViewTableLabels: Record<FilterIdentifier, string> = {
  CHAINS_TABLE: 'Chains',
  DOORS_TABLE: 'Doors',
  CONTACTS_TABLE: 'Contacts',
  DEALS_TABLE: 'Deals',
  ACTIVITY_TABLE: 'Activity',
  ACTIVITY_TABLE_V2: 'Activity',
  DEALS_PIPELINE_TABLE: 'Deals Pipeline',
  CNU_TABLE: 'College & Universities',
  K12_TABLE: 'K12 Districts',
  COMPANIES_TABLE: 'Generic Company', // Wont be used
}

export interface SavedView {
  id: number
  name: string
  identifier: FilterIdentifier
  payload: Record<any, any>
  user: {
    first_name: string
    last_name: string
  }
}

export interface SavedViewCreate {
  name: string
  identifier: FilterIdentifier
  shared: boolean
  payload: Record<any, any>
}
