import { InfoCard } from 'components/UI/InfoCard'
import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import apiService from 'services/api'
import { formatInteger, formatUsd } from 'utils/formatting'
import { SalesForecastTargetLineModal } from '../Modals/SalesForecastTargetLineModal'
import { MultiSelect } from '../UI/Select/MultiSelect'
import { Select } from '../UI/Select/Select'

const horizonOptions = [
  {
    label: '12 Months',
    value: 12,
  },
  {
    label: '24 Months',
    value: 24,
  },
  {
    label: '36 Months',
    value: 36,
  },
]

const chartOptions = [
  {
    label: 'Revenue',
    value: 'forecasted_revenue',
  },
  {
    label: 'Volume',
    value: 'forecasted_volume',
  },
]

export function SalesForecast() {
  const api = apiService()

  const [product, setProduct] = React.useState<number[]>([])
  const [user, setUser] = React.useState<number[]>([])
  const [stage, setStage] = React.useState<number[]>([])
  const [subCatId, setSubCatId] = React.useState<number[]>([])

  const [horizon, setHorizon] = React.useState<number>(horizonOptions[0].value)
  const [chart, setChart] = React.useState<string>(chartOptions[0].value)
  const { data: companyCatAndSub, isLoading: isOppLoading } =
    api.useGetCompanyCategoriesAndSub()
  const { data: preferences } = api.useGetPreferences()
  const { data: products } = api.useGetProductsOptions()
  const { data: stages } = api.useGetSalesStageOptions()
  const { data: users } = api.useGetUserOptions()
  const { data: salesForecast, isLoading } = api.useGetDashboardSalesForecast(
    horizon,
    user,
    product,
    stage,
    subCatId
  )

  const subCatOptions = useMemo(() => {
    return (
      companyCatAndSub?.flatMap((cat) =>
        cat.sub_categories.map((subCat) => ({
          label: subCat.name,
          value: subCat.id,
        }))
      ) ?? []
    )
  }, [companyCatAndSub])

  const colors = useMemo(() => {
    const part = 75 / (horizon ?? 1)
    const _colors: string[] = []
    for (let i = 0; i < horizon; i++) {
      const opacity = (0.25 + (part * (i + 1)) / 100).toFixed(2)
      _colors.push(`rgba(6,81,47,${opacity})`)
    }
    return _colors
  }, [horizon])

  return (
    <div>
      <h2 className="uppercase font-light text-2xl text-gray-700">
        Rolling Sales Forecast
      </h2>

      <hr className="border-gray-400 mb-6 mt-2" />

      <div className="grid gap-3 mb-10 mt-2 grid-cols-3">
        <MultiSelect
          disabled={isOppLoading}
          onSelect={setSubCatId}
          selected={subCatId}
          buttonVariant={'secondary'}
          buttonLabel={'Opp. Type'}
          items={subCatOptions}
          selectAllBehavior={'selectNone'}
          enableSelectAll
        />
        <MultiSelect
          onSelect={setProduct}
          selected={product}
          buttonVariant={'secondary'}
          buttonLabel={'Product'}
          items={products}
          selectAllBehavior={'selectNone'}
          enableSelectAll
        />
        <MultiSelect
          onSelect={setUser}
          selected={user}
          buttonVariant={'secondary'}
          buttonLabel={'Account Owner'}
          items={users}
          selectAllBehavior={'selectNone'}
          enableSelectAll
        />
        <MultiSelect
          onSelect={setStage}
          selected={stage}
          buttonVariant={'secondary'}
          buttonLabel={'Sales Stage'}
          items={stages?.map((s) => ({
            ...s,
            icon: (
              <div
                className={`h-3 w-3 rounded-full flex-shrink-0`}
                style={{ backgroundColor: s.color }}
              />
            ),
          }))}
          selectAllBehavior={'selectNone'}
          enableSelectAll
        />
        <Select
          onSelect={setHorizon}
          defaultSelection={horizonOptions[0].value}
          selected={horizon}
          buttonVariant={'secondary'}
          buttonLabel={'Time Horizon'}
          items={horizonOptions}
        />
        <Select
          defaultSelection={chartOptions[0].value}
          onSelect={setChart}
          selected={chart}
          buttonVariant={'secondary'}
          buttonLabel={'Chart Type'}
          items={chartOptions}
        />
      </div>

      <div className="pr-2">
        {isLoading ? (
          <div
            className={
              'w-full backdrop-blur flex items-center justify-center h-[355px]'
            }
          >
            <Spinner color={'black'}></Spinner>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex-[8]" style={{ width: '100%' }}>
              {salesForecast && colors && (
                <BarChart
                  targetValue={
                    preferences?.misc_settings?.find(
                      (it) => it.key === 'show_sales_forecast_line'
                    )?.value
                      ? preferences?.misc_settings?.find(
                          (it) => it.key === 'sales_forecast_target_line'
                        )?.value
                      : undefined
                  }
                  values={salesForecast?.charts?.[chart].values}
                  labels={salesForecast?.charts?.[chart].labels}
                  colors={colors}
                  formatTootltipCurrency={chart === 'forecasted_revenue'}
                  formatYAxisCurrency={chart === 'forecasted_revenue'}
                />
              )}
            </div>
            <div className="flex-[2] flex lg:flex-col gap-2 flex-wrap">
              {salesForecast?.cards?.map((item, index) => (
                <InfoCard
                  key={index}
                  title={item.label}
                  value={item.value}
                  tooltip={item?.tooltip}
                />
              ))}
              <SalesForecastTargetLineModal />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function BarChart(props: {
  values: number[]
  labels: string[]
  colors: string[]
  formatTootltipCurrency?: boolean
  targetValue?: number
  formatYAxisCurrency?: boolean
}) {
  const max = roundChartPadding(
    Math.max(...props.values, props.targetValue ?? 0)
  )
  return (
    <Bar
      style={{ width: '100%', maxHeight: '355px' }}
      data={{
        labels: props.labels,
        datasets: [
          {
            data: props.values,
            backgroundColor: props.colors,
            borderRadius: 4,
            // barThickness: 50,
            borderColor: 'transparent',
            borderWidth: 2,
            datalabels: {
              display: false,
            },
          },
        ],
      }}
      options={{
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                display: !!props.targetValue,
                yMin: props.targetValue,
                yMax: props.targetValue,
                borderColor: '#E2E4E9',
                borderWidth: 3,
                borderDash: [6, 4],
              },
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#fff',
            titleColor: '#667085',
            titleFont: {
              weight: 'lighter',
              size: 14,
            },
            borderWidth: 1,
            borderColor: '#c2c2c2',
            bodyColor: '#18181B',
            bodyFont: {
              weight: 'bold',
              size: 14,
            },
            bodyAlign: 'center',
            displayColors: false,
            yAlign: 'bottom',

            callbacks: {
              label: function (context) {
                if (props.formatTootltipCurrency) {
                  return formatUsd(context.parsed.y)
                }
                return formatInteger(context.parsed.y) + ' lbs/month'
              },
            },
          },
        },
        layout: {},
        scales: {
          y: {
            suggestedMax: max,
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              callback: function (value) {
                const numValue =
                  typeof value === 'string' ? parseFloat(value) : value
                if (props.formatYAxisCurrency) {
                  return formatUsd(numValue)
                }
                return formatInteger(numValue)
              },
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              display: true,
            },
          },
        },
      }}
    />
  )
}

function roundChartPadding(number: number) {
  if (number < 1000000) {
    // Round to the nearest ten thousand
    return Math.round(number / 10000) * 10000
  } else {
    // Round to the nearest hundred thousand
    return Math.round(number / 100000) * 100000
  }
}
