import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'

export default function DoorExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportDoors = async ({
    filter,
    selectedColumns,
    selectedProductId,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    selectedProductId?: number
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    let res
    if (selectedProductId !== -1) {
      res = await api.downloadDoorsCsvByProduct(
        {
          ...filter,
          product_id: selectedProductId,
          firstbite_id: props.selectedIds,
        },
        selectedColumns,
        exportType,
        controller?.signal
      )
    } else {
      res = await api.downloadDoorsCsv(
        {
          ...filter,
          firstbite_id: props.selectedIds,
        },
        selectedColumns,
        exportType,
        controller?.signal
      )
    }

    downloadFile(
      res,
      `First Bite Export: Doors - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        ['restaurant_name', 'Door'],
        ['chain', 'Chain'],
        ['chain_count', 'Chain Size'],
        ['ingredients_matrix', 'Menu Characteristics'],
        ['menu_size', 'Menu Size'],
        ['match_count', 'Menu Matches'],
        ['menu_url', 'Menu URL'],
        ['cuisine_50', 'Cuisine'],
        ['velocity_group', 'Predicted Traffic'],
        ['expense_category', 'Expense Category'],
        ['dma', 'DMA'],
        ['full_address', 'Address'],
        ['city', 'City'],
        ['state', 'State'],
        ['zipcode', 'ZIP Code'],
        ['google_place_url', 'Place URL'],
        ['phone', 'Phone'],
        ['website', 'Website'],
        ['domain', 'Domain'],
      ],
    },
    {
      title: 'Reputation Insights',
      elements: [
        ['avg_rating', 'Avg. Rating'],
        ['reviews_count', 'Total Reviews'],
        ['instagram_url', 'Instagram Handle'],
        ['instagram_followers', 'Instagram Followers'],
        ['instagram_following', 'Instagram Following'],
        ['instagram_posts', 'Instagram Posts'],
        ['featured_on', 'Featured On'],
        ['michelin_stars', 'Michelin Stars'],
      ],
    },
    {
      title: 'Predicted Values and Deals ',
      elements: [
        ['total_ltv', 'Lifetime Total Value'],
        ['brand_ltv', 'Lifetime Brand Value'],
        ['revenue_ltv', 'Lifetime Revenue Value'],
        ['taro_plus_tabo', '1yr Total Value'],
        ['tabo', '1yr Brand Value'],
        ['taro', '1yr Revenue Value'],
        ['pounds_per_year', '1yr Volume (lbs)'],
        ['sales_stages', 'Sales Stages'],
        ['account_owners', 'Account Owners'],
        ['tags', 'Tags'],
        ['deal_count', 'Deals Count'],
        ['note_count', 'Notes Count'],
        ['contact_count', 'Contacts Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Restaurant Doors Download"
      subTitle="Download up to 10,000 doors at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Doors"
      columns={columns}
      exportAPIAction={exportDoors}
      {...props}
    />
  )
}
