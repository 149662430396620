import { useQuery } from '@tanstack/react-query'
import { CellContext, createColumnHelper } from '@tanstack/react-table'
import to from 'await-to-js'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import FbButton from 'components/FbUI/FbButton'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import * as S from 'components/Tables/CommonTable.styles'
import dialogConfirm from 'components/dialogConfirm'
import dayjs from 'dayjs'
import { ContactResponse } from 'models/contacts'
import { Deal } from 'models/deals'
import React, { useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  BsDownload,
  BsPencil,
  BsPerson,
  BsSignpost,
  BsTags,
  BsTruck,
} from 'react-icons/bs'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { default as s, default as styled } from 'styled-components'
import { usePreferences } from '../../../context/preferences/PreferencesContext'
import { IContactCompany } from '../../../models/contact_companies'
import { FilterIdentifier } from '../../../models/saved_view'
import {
  formatInteger,
  formatTwoDecimal,
  formatUsd,
  formatUsdDecimal,
} from '../../../utils/formatting'
import { getCompanyUrl } from '../../../utils/getCompanyUrl'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { usePaginationURLParams } from '../../../utils/usePaginationURLParams'
import { ActionButtons } from '../../Buttons/ActionButtons'
import TableButtonAction from '../../Buttons/TableButtons/TableButtonAction'
import { IColumnVisibility } from '../../DataTable/types'
import { EditDealModal } from '../../Deals/EditDealModal/EditDealModal'
import { FbLink } from '../../FbUI/FbLink'
import {
  AccountOwnerPill,
  ContactTagCell,
  DistributorsCell,
  StagePill,
  TaglistCell,
} from '../../FbUI/StagePill'
import { TableSearch } from '../../Filters/TableSearch'
import { FilterChips } from '../../Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from '../../Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from '../../Modals/AssignmentModal/TagAssignModal'
import DealExportModal from '../../Modals/ExportModal/DealExportModal'
import DealsOverrideModal from '../../Modals/FormActionModal/DealsOverrideModal'
import AccountOwnerSelectModal from '../../Modals/SelectModal/AccountOwnerSelectModal'
import SalesStageSelectModal from '../../Modals/SelectModal/SalesStageSelectModal'
import { DealsFilterset } from './DealsFilterset'
import ExpandedDealsRow from './ExpandedDealsRow'

const tooltip = <Tooltip id="tooltip">Download limited to 10,000 rows</Tooltip>
type SortableFields = (keyof ContactResponse | string)[]

export type DealsTableRef = {
  refetch: () => void
}

const sortableFields: SortableFields = [
  'id',
  'company',
  'product',
  'predicted_revenue',
  'predicted_volume',
  'monthly_volume_override',
  'monthly_revenue_override',
  'close_date_override',
  'sales_stage',
  'account_owner',
  'created',
  'modified',
  'last_datetime_stage_changed',
  'distributors',
  'expected_days_to_close',
  'note_count',
  'annual_revenue_opportunity',
  'category',
  'sub_category',
  'contact_count',
  'contact_tags',
  'days_since_last_modified',
  'company_size',
]

const PAGE_SIZE = 100

interface DealsTableProps {
  setTotalRowsCount?: (count: number) => void
  forCompany?: Partial<IContactCompany>
  forChainId?: number
  showCompanyColumn?: boolean
  enablePagination?: boolean
  disableUrlPagination?: boolean
  containerStyle?: React.CSSProperties
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: string
}

function DealsTableComponent(props: DealsTableProps) {
  const api = apiService()
  const { preferences } = usePreferences()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<Deal>()
  const {
    state: { sorting, totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [dealToEdit, setDealToEdit] = useState<Deal>()
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showSelectAccountOwner, setShowSelectAccountOwner] = useState(false)
  const [showSelectSalesStage, setShowSelectSalesStage] = useState(false)
  const [showOverrideModal, setShowOverrideModal] = useState(false)
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [pagination, setPagination] = props.disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)

  const IDENTIFIER = ('DEALS_TABLE' +
    props.filterIdentifierModifier) as FilterIdentifier
  const dealsFilterStore = filterStoreRepo.getStore(IDENTIFIER)

  const filtersAsParams = {
    ...props.baseFilters,
    ...useFilterParams(dealsFilterStore),
  }

  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.forCompany) {
      params['company'] = props.forCompany.id
    } else if (props.forChainId) {
      params['chain'] = props.forChainId
    }

    return params
  }, [filtersAsParams, props.forCompany, props.forChainId])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const res = await api.getDeals(
        {
          ...filterAndSortParams,
          page: pagination.pageIndex + 1,
        },
        signal
      )

      if (res?.count) {
        props.setTotalRowsCount?.(res.count)
        setTotalRowsInBackend(res.count)
      }

      return res
    },
  })

  // END DATA FETCHING

  async function handleDealDelete(dealId: number) {
    if (
      await dialogConfirm(
        <>
          Are you sure you want to delete this <b>deal</b>? This action cannot
          be undone.
        </>,
        'Delete Deal?',
        '',
        { confirmLabel: 'Delete' }
      )
    ) {
      const [err] = await to(api.deleteDeal(dealId))
      if (!err) {
        toast.success('Deal deleted successfully')
        void refetch()
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        id: 'expanded-row',
        header: '',
        cell: (info: CellContext<Deal, unknown>) => {
          const row = info.row.original
          return <ExpandedDealsRow dealId={row.id} product={row.product} />
        },
      },
      ...(props.showCompanyColumn
        ? [
            columnHelper.accessor('contact_company', {
              id: 'company',
              header: () => null,
              meta: {
                isEditable: false,
                isFirstColumn: props.showCompanyColumn,
                headerTitle: 'Company',
                headerGroup: 'Company Attributes',
              },
              cell: (info) => {
                const row = info.row.original

                return (
                  <CompanyContainer>
                    <FbLink
                      to={`${getCompanyUrl(row.contact_company)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.contact_company?.name}
                    </FbLink>
                  </CompanyContainer>
                )
              },
              size: 350,
            }),
          ]
        : []),
      columnHelper.accessor('product', {
        id: 'product',
        meta: {
          isEditable: props.showCompanyColumn,
          isFirstColumn: !props.showCompanyColumn,
          headerTitle: 'Product',
          headerGroup: 'Deal Attributes',
        },
        header: () => (props.showCompanyColumn ? 'Product' : ''),
        size: 250,

        cell: (info) => {
          const row = info.row.original
          return row.product?.name
        },
      }),
      columnHelper.accessor('company_size', {
        id: 'company_size',
        meta: {
          tooltip: tooltips?.['deals_chain_size'],
          headerGroup: 'Company Attributes',
        },
        header: 'Size',
        size: 100,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('contact_company.category', {
        id: 'category',
        header: 'Category',
        meta: { headerGroup: 'Company Attributes' },
        cell: (info) => {
          return (
            categories?.find(
              (a) => a.id === (info.getValue() as unknown as number)
            )?.name ?? '-'
          )
        },
      }),
      columnHelper.accessor('contact_company.sub_category', {
        id: 'sub_category',
        header: 'Type',
        meta: { headerGroup: 'Company Attributes' },
        cell: (info) => {
          const value = info.getValue()
          let name = '-'

          categories?.forEach((a) => {
            a.sub_categories.forEach((b) => {
              if (b.id === (value as unknown as number)) {
                name = b.name
              }
            })
          })

          return name
        },
      }),
      columnHelper.accessor('sales_stage', {
        id: 'sales_stage',
        meta: {
          tooltip: tooltips?.['deals_sales_stage'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Sales Stage',
        size: 250,
        cell: (info) => {
          if (!info.row?.original?.sales_stage) return <>-</>
          return <StagePill stageId={info.row.original?.sales_stage} />
        },
      }),
      columnHelper.accessor('last_datetime_stage_changed', {
        id: 'last_datetime_stage_changed',
        meta: {
          tooltip: tooltips?.['deals_last_datetime_stage_changed'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Days In Stage',
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${dayjs().diff(dayjs(value), 'day')} days`
        },
      }),
      columnHelper.accessor('expected_days_to_close', {
        meta: {
          tooltip: tooltips?.['deals_expected_days_to_close'],
          headerGroup: 'Deal Attributes',
        },
        size: 150,
        header: 'Expected Close Date',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${dayjs().add(value, 'day').format('MMM DD, YYYY')}`
        },
      }),
      columnHelper.accessor('close_date_override', {
        meta: {
          tooltip: tooltips?.['deals_close_date_override'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Close Date (override)',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return dayjs(value).format('MMM DD, YYYY')
        },
      }),
      columnHelper.accessor('account_owner', {
        meta: {
          tooltip: tooltips?.['deals_account_owner'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Account Owner',
        size: 250,
        cell: (info) => {
          return <AccountOwnerPill account_owner_id={info.getValue()} />
        },
      }),
      columnHelper.accessor('predicted_annual_total', {
        id: 'predicted_annual_total',
        meta: {
          tooltip: tooltips?.['deals_predicted_total'],
          headerGroup: 'Forecast',
        },
        header: '1yr Total Value',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('predicted_annual_brand_opp', {
        id: 'predicted_annual_brand_opp',
        meta: {
          tooltip: tooltips?.['predicted_annual_brand_opp'],
          headerGroup: 'Forecast',
        },
        header: '1yr Brand Value',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('annual_revenue_opportunity', {
        id: 'annual_revenue_opportunity',
        meta: {
          tooltip: tooltips?.['deals_annual_revenue_opportunity'],
          headerGroup: 'Forecast',
        },
        header: 'Annual Revenue Opportunity',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('predicted_annual_revenue', {
        id: 'predicted_annual_revenue',
        meta: {
          tooltip: tooltips?.['deals_predicted_annual_revenue'],
          headerGroup: 'Forecast',
        },
        header: '1yr Revenue Value',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('predicted_monthly_revenue', {
        id: 'predicted_monthly_revenue',
        meta: {
          tooltip: tooltips?.['deals_predicted_monthly_revenue'],
          headerGroup: 'Forecast',
        },
        header: 'Monthly Revenue',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsd(value)
        },
      }),
      columnHelper.accessor('monthly_revenue_override', {
        id: 'monthly_revenue_override',
        meta: {
          tooltip: tooltips?.['deals_monthly_revenue_override'],
          headerGroup: 'Forecast',
        },
        header: 'Monthly Revenue (override)',
        size: 185,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatUsdDecimal(value)
        },
      }),
      columnHelper.accessor('predicted_annual_volume', {
        id: 'predicted_annual_volume',
        meta: {
          tooltip: tooltips?.['deals_predicted_annual_volume'],
          headerGroup: 'Forecast',
        },
        header: '1yr Volume (lbs)',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatInteger(value ?? 0)} lbs`
        },
      }),
      columnHelper.accessor('predicted_monthly_volume', {
        id: 'predicted_monthly_volume',
        meta: {
          tooltip: tooltips?.['deals_predicted_monthly_volume'],
          headerGroup: 'Forecast',
        },
        header: 'Monthly Volume',
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatInteger(value ?? 0)} lbs`
        },
      }),
      columnHelper.accessor('monthly_volume_override', {
        id: 'monthly_volume_override',
        header: 'Monthly Volume (override)',
        meta: {
          tooltip: tooltips?.['deals_monthly_volume_override'],
          headerGroup: 'Forecast',
        },
        size: 175,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return `${formatTwoDecimal(value ?? 0)} lbs`
        },
      }),
      columnHelper.accessor('contact_company_tags', {
        meta: {
          tooltip: tooltips?.['deals_contact_company_tags'],
          headerGroup: 'Company Attributes',
        },
        header: 'Tags',
        size: 260,
        cell: (info) => {
          return (
            <TaglistCell taglist={info.row.original?.contact_company.taglist} />
          )
        },
      }),
      columnHelper.accessor('contact_tags', {
        meta: {
          tooltip: tooltips?.['deals_contact_tags'],
          headerGroup: 'Company Attributes',
        },
        header: 'Contact Labels',
        size: 260,
        cell: (info) => {
          return <ContactTagCell deals={[info.row.original]} />
        },
      }),
      columnHelper.accessor('contact_company.distributors', {
        meta: {
          tooltip: tooltips?.['deals_distributors'],
          headerGroup: 'Company Attributes',
        },
        header: 'Known Distributors',
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell
              distributors={info.getValue()}
              asc={
                sorting?.length && sorting[0].id === 'distributors'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      }),
      columnHelper.accessor('contact_company.note_count', {
        id: 'note_count',
        meta: {
          tooltip: tooltips?.['deals_note_count'],
          headerGroup: 'Company Attributes',
        },
        header: 'Note Count',
        size: 110,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('contact_company.contact_count', {
        id: 'contact_count',
        meta: {
          tooltip: tooltips?.['deals_contact_count'],
          headerGroup: 'Company Attributes',
        },
        header: 'Contact Count',
        size: 110,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return <>-</>
          return formatInteger(value)
        },
      }),
      columnHelper.accessor('contact_company.days_since_last_modified', {
        id: 'days_since_last_modified',
        meta: {
          tooltip: tooltips?.['dealspipeline_days_since_last_modified'],
          headerGroup: 'Company Attributes',
        },
        header: 'Days Since Last Update',
        cell: (info) => {
          return info.getValue()
        },
      }),
      columnHelper.accessor('modified', {
        meta: {
          tooltip: tooltips?.['deals_modified'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Last Modified',
        size: 150,
        cell: (info) => {
          return dayjs(info.getValue()).format('MM/DD/YYYY')
        },
      }),
      columnHelper.accessor('created', {
        meta: {
          tooltip: tooltips?.['deals_created'],
          headerGroup: 'Deal Attributes',
        },
        header: 'Date Created',
        size: 150,
        cell: (info) => dayjs(info.getValue()).format('MM/DD/YYYY'),
      }),
      {
        id: 'actions',
        header: 'Actions',
        size: 150,
        meta: {
          isEditable: false,
          headerDisableOrdering: true,
        },
        cell: (info: CellContext<Deal, unknown>) => {
          const deal = info.row.original
          const dealId = deal.id

          return (
            <ActionButtons
              className={'justify-start'}
              onDelete={() => handleDealDelete(dealId)}
              onEdit={() => setDealToEdit(deal)}
            />
          )
        },
      },
    ],
    [categories, preferences, props.showCompanyColumn]
  )

  return (
    <TableContainer
      key={props.tableKey + '-container'}
      style={props.containerStyle}
    >
      <S.SearchContainer>
        <S.SearchContainerFilters>
          <S.TableSearchContainer>
            <TableSearch
              filterStore={dealsFilterStore}
              searchPlaceholder="Search by name, company..."
            />
          </S.TableSearchContainer>
          <S.TableButtonsContainer>
            <DealsFilterset filterIdentifier={IDENTIFIER} />
            <ColumnSelectorRecipient tableKey={props.tableKey} />
            <TableButtonAction
              items={[
                {
                  label: 'Manage Tags',
                  icon: <BsTags size={20} />,
                  callback: () => setShowAssignTags(true),
                },
                {
                  label: 'Add Known Distributors',
                  icon: <BsTruck size={20} />,
                  callback: () => setShowAssignDistributors(true),
                },
                {
                  label: 'Assign Account Owner',
                  icon: <BsPerson size={20} />,
                  callback: () => setShowSelectAccountOwner(true),
                },
                {
                  label: 'Change Sales Stage',
                  icon: <BsSignpost size={20} />,
                  callback: () => setShowSelectSalesStage(true),
                },
                {
                  label: 'Manage Override',
                  icon: <BsPencil size={20} />,
                  callback: () => setShowOverrideModal(true),
                },
              ]}
              disabled={!totalSelectedRows}
              selectedRowsCount={totalSelectedRows ?? 0}
            />
          </S.TableButtonsContainer>
        </S.SearchContainerFilters>
        <FilterChips
          identifier={IDENTIFIER}
          store={dealsFilterStore}
          showActive
          clearAllButton
        />
      </S.SearchContainer>

      <DataTable
        key={props.tableKey}
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        enableRowSelection
        isPaginationEnabled={props.enablePagination}
        defaultSort={[{ id: 'annual_revenue_opportunity', desc: true }]}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        stickyLastColumn
        expandColAccessor={'expanded-row'}
        selectAllText="Select Deals"
        footerControls={
          <OverlayTrigger placement="top" overlay={tooltip}>
            <ExportButton
              variant="secondary"
              onClick={() => setShowExportModal(true)}
            >
              <BsDownload size={18} />
            </ExportButton>
          </OverlayTrigger>
        }
      />

      <EditDealModal
        onClose={() => setDealToEdit(undefined)}
        onDealEdited={() => refetch()}
        show={!!dealToEdit}
        deal={dealToEdit}
      />

      <DealExportModal
        count={data?.count ?? 0}
        show={showExportModal}
        _handleClose={() => setShowExportModal(false)}
        filters={filterAndSortParams}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        tableObjAccessor="contact_company.taglist"
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={api.dealsBulkEditTags}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        tableObjAccessor="contact_company.distributors"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={api.dealsBulkEditDistributors}
      />

      <AccountOwnerSelectModal
        show={showSelectAccountOwner}
        handleClose={() => setShowSelectAccountOwner(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />

      <SalesStageSelectModal
        show={showSelectSalesStage}
        handleClose={() => setShowSelectSalesStage(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />

      <DealsOverrideModal
        show={showOverrideModal}
        handleClose={() => setShowOverrideModal(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />
    </TableContainer>
  )
}

export function DealsTable(
  props: DealsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<unknown>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props
  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Company Attributes',
        'Deal Attributes',
        'Forecast',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <DealsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

// table container height based on
// footer height + navbar height + table header height
const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

const ExportButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
const CompanyContainer = s.div`
display: flex;
align-items: center;
`
