import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export function ConditionalTooltipWrapper({
  shouldWrap,
  tooltipText,
  children,
}: {
  shouldWrap?: boolean
  tooltipText?: string
  children: React.ReactElement
}) {
  if (shouldWrap) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    )
  } else {
    return <>{children}</>
  }
}
