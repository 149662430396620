import BaseStep from './BaseStep'
import { ContactsPreviewTable } from 'components/Tables/ContactsPreviewTable/ContactsPreviewTable'
import { useContactsTableColumns } from 'components/Tables/ContactsTable/useContactsTableColumns'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'

export function PreviewSuccessfulStep() {
  const {
    methods: { prevStep, nextStep, handleClose },
    state: { previewData, isImporting },
  } = useImportContactContext()

  if (!previewData) return null

  const totalContacts = previewData.success_count

  const totalFailed = previewData.failed_count

  const columns = useContactsTableColumns()

  const continueText = totalFailed > 0 ? 'Continue' : 'Complete'

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      onContinue={() => nextStep()}
      title="Update New Contacts - Review"
      backButtonText="Back"
      continueButtonText={continueText}
      isContinueLoading={isImporting}
    >
      <S.Content style={{ maxHeight: 500 }}>
        <S.ContentTitle>
          {totalContacts === 0
            ? 'No contacts are ready to update. See the issues in the next step or return to the previous step to change columns.'
            : `${totalContacts} contact${
                totalContacts === 1 ? ' is' : 's are'
              } ready to update.
              Return to the previous step to change columns, or click complete to finish updating. You may need to scroll
              horizontally to see all columns.`}
          {totalContacts > 50 && (
            <>
              <br />
              The following table contains the first 50 contacts that are ready
              to update.
            </>
          )}
        </S.ContentTitle>
        {totalContacts > 0 && (
          <ContactsPreviewTable
            data={previewData.success_contacts}
            columns={columns}
          />
        )}
      </S.Content>
    </BaseStep>
  )
}
