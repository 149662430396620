import styled from 'styled-components'
import { BsLink } from 'react-icons/bs'

export const LinkPill = styled.a`
  height: 22px;
  border: 1px solid #b9c0d4;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 2px 8px 2px 6px;
  gap: 5px;
  font-size: 12px;
  color: #5d6b98;
  text-decoration: none;
  &:hover {
    color: #5d6b98;
  }
`

export const LinkIcon = styled(BsLink)`
  font-size: 12px;
  color: #5d6b98;
`
