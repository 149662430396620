import { Spinner } from 'react-bootstrap'
import React, { useMemo, useState } from 'react'
import apiService from '../../../services/api'
import * as S from './styles'
import { ColumnDef } from '@tanstack/react-table'
import { DateCell } from '../../../components/Tables/CommonTableCells/DateCell'
import {
  FilterIdentifier,
  SavedView,
  SavedViewTableLabels,
} from '../../../models/saved_view'
import { FilterChipsComponent } from '../../../components/Filters/components/FilterChips/FilterChips'
import { ActionButtons } from '../../../components/Buttons/ActionButtons'
import { Modal } from '../../../components/UI/Modal/Modal'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'

export function SavedFiltersTable() {
  const api = apiService()

  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const { data, isLoading, refetch, isFetching } = api.useGetAllSavedFilters()

  const [filterIdToDelete, setFilterIdToDelete] = useState<number>()

  async function handleRowDelete() {
    if (!filterIdToDelete) return
    await api.deleteFilter(filterIdToDelete)
    setFilterIdToDelete(undefined)
    void refetch()
  }

  const columns = useMemo<ColumnDef<SavedView, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'filters',
        header: 'Filters',
        size: 500,
        cell: (info) => {
          const payload = info.row.original.payload
          return (
            <div className={'py-2'}>
              <FilterChipsComponent
                filterState={{
                  valueFilters: payload.valueFilters,
                  rangeFilters: payload.rangeFilters,
                  zipCodeFilter: payload.zipCodeFilter,
                  criteria: payload.criteria,
                  search: payload.search,
                }}
                identifier={info.row.original.identifier}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'identifier',
        header: 'Table',
        cell: (info) => {
          if (info.getValue().toString().startsWith('companies-')) {
            const id = parseInt(info.getValue().toString().split('-')[1])
            let name = ''
            if (categories) {
              loop: for (const c of categories) {
                for (const s of c.sub_categories) {
                  if (s.id === id) {
                    name = s.name
                    break loop
                  }
                }
              }
            }

            return name
          } else {
            return SavedViewTableLabels[info.getValue() as FilterIdentifier]
          }
        },
      },
      {
        accessorKey: 'shared',
        size: 100,
        header: 'Type',
        cell: (info) => (info.getValue() ? 'Shared' : 'Private'),
      },
      {
        id: 'user__first_name',
        header: 'Created By',
        cell: (info) => {
          return (
            info.row.original?.user?.first_name +
            ' ' +
            info.row.original?.user?.last_name
          )
        },
      },
      {
        accessorKey: 'created',
        header: 'Date Created',
        cell: DateCell,
      },
      {
        accessorKey: 'modified',
        header: 'Date Modified',
        cell: DateCell,
      },
      {
        id: 'edit',
        header: 'Actions',
        meta: { rightAlign: true },
        cell: ({ row }) => {
          return (
            <ActionButtons
              className={'justify-end pr-0'}
              onDelete={() => setFilterIdToDelete(row.original.id)}
            />
          )
        },
      },
    ],
    [data]
  )

  if (isLoading || !data || isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <S.SettingsTableContainer
        style={{
          height: 'calc(100vh - 235px)',
          marginTop: '20px',
        }}
      >
        <ClientDataTable
          stickyFirstColumn={true}
          stickyLastColumn={true}
          data={data || []}
          columns={columns}
        />
      </S.SettingsTableContainer>
      <Modal
        open={!!filterIdToDelete}
        title={'Delete Saved Filter'}
        onOpenChange={(open) => !open && setFilterIdToDelete(undefined)}
        onAccept={() => handleRowDelete()}
        acceptButtonText={'Delete'}
      >
        <span>Are you sure you want to delete this saved filter?</span>
      </Modal>
    </>
  )
}
