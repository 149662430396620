import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as S from './CompanyAttrsManages.styles'
import FbButton from '../FbUI/FbButton'
import dialogConfirm from 'components/dialogConfirm'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreatableDistributorSelector } from 'components/CreatableDistributorSelector'
import { NoteManager } from 'components/Notes/NoteManager'
import clsx from 'clsx'

const notesShape = z.object({
  taglist: z.any().optional().nullable(),
  distributors: z.any().optional().nullable(),
  note: z.string().optional().nullable(),
  attachments: z.any().optional().nullable(),
  previous_taglist: z.any().optional().nullable(),
  previous_distributors: z.any().optional().nullable(),
})
export type CompanyAttrsInput = z.infer<typeof notesShape>

export function CompanyAttributesManager({
  chainID,
  contactCompanyID,
  isDoorPage,
}: {
  chainID?: number
  contactCompanyID?: number
  isDoorPage?: boolean
}) {
  const queryClient = useQueryClient()
  const api = apiService()
  const { data: distributorsOptions } = api.useGetCompanyDistributorsOptions()
  const { data: tagsOptions } = api.useGetTagsOptions()
  const { data: chainData, refetch: refetchChain } = useQuery({
    queryKey: ['chain', chainID],
    queryFn: () => api.getChain(chainID),
    enabled: !!chainID,
  })
  const { data: contactCompanyData, refetch: refetchContactCompany } = useQuery(
    {
      queryKey: ['contactCompany', contactCompanyID],
      queryFn: () => api.getCompany(contactCompanyID!),
      enabled: !!contactCompanyID,
    }
  )

  const [previousDistributors, setPreviousDistributors] = useState<number[]>([])
  const [previousTaglist, setPreviousTaglist] = useState<number[]>([])

  const { control, handleSubmit, reset, setError, formState } =
    useForm<CompanyAttrsInput>({
      defaultValues: {
        taglist: undefined,
        distributors: undefined,
        note: '',
        attachments: [],
      },
      resolver: zodResolver(notesShape),
    })

  useEffect(() => {
    if (chainData || contactCompanyData) {
      const taglist = chainData?.taglist || contactCompanyData?.taglist
      const distributors =
        chainData?.distributors || contactCompanyData?.distributors

      const mappedTaglist = taglist?.map((t) => t.id) || []
      const mappedDistributors =
        distributors?.map((distributor) => distributor.id) || []

      reset({
        taglist: mappedTaglist,
        distributors: mappedDistributors,
        note: '',
        attachments: [],
      })

      setPreviousDistributors(mappedDistributors)
      setPreviousTaglist(mappedTaglist)
    }
  }, [chainData, contactCompanyData])

  const mutation = useMutation({
    mutationFn: async (values: CompanyAttrsInput) => {
      await api.bulkEditCompanyAttrs({
        chain_ids: chainID ? [chainID] : undefined,
        contact_company_ids: !chainID ? [contactCompanyID!] : undefined,
        taglist:
          Array.isArray(values.taglist) && values.taglist.length > 0
            ? values?.taglist
            : 'REMOVE',
        distributors:
          Array.isArray(values.distributors) && values.distributors.length > 0
            ? values.distributors
            : 'REMOVE',
        previous_distributors: previousDistributors,
        previous_taglist: previousTaglist,
      })

      if (values.note || values.attachments?.length) {
        await api.createNote(
          chainID,
          contactCompanyID,
          values.note!,
          values.attachments!
        )
      }
    },

    onSettled: async (data, err) => {
      if (chainID) {
        refetchChain()
      } else {
        refetchContactCompany()
      }
      queryClient.invalidateQueries({ queryKey: ['notes-table'] })
      if (err) {
        toast.error('Error updating.')
      } else {
        toast.success('Company updated.')
        reset({ ...formState.defaultValues, note: '', attachments: [] })
      }
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    if (chainID) {
      if (
        !isDoorPage ||
        (await dialogConfirm(
          'Changing information on a door will apply to all doors in a chain. Would you like to proceed?',
          'Heads Up'
        ))
      ) {
        mutation.mutate(values)
      }
    } else {
      mutation.mutate(values)
    }
  })

  return (
    <>
      <S.Wrapper>
        <CreatableTagSelector
          name="taglist"
          control={control}
          label="Tags"
          placeholder="Tags"
          options={tagsOptions}
          setError={setError}
        />
        <CreatableDistributorSelector
          name="distributors"
          control={control}
          label="Distributors"
          placeholder="Distributor(s)"
          options={distributorsOptions}
          setError={setError}
        />
        <NoteManager
          noteName="note"
          attachmentsName="attachments"
          control={control}
          label="New Note"
          placeholder="Start writing note here"
        />
      </S.Wrapper>
      <FbButton
        className={clsx('ml-auto', formState.isDirty ? 'block' : 'invisible')}
        loading={mutation.isPending}
        onClick={onSubmit}
      >
        Save
      </FbButton>
    </>
  )
}
