import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { AxiosError, AxiosInstance } from 'axios'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import {
  getClient,
  getToken,
  removeToken,
  setToken,
} from '../../utils/http-client'
import { User } from '../../models/user'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.')
}

const initialContext = {
  isAuthenticated: false,
  createUser: stub,
  login: stub,
  logout: stub,
  refreshToken: stub,
  getClient: stub,
  isCheckingAuth: true,
  isVerifyStep: false,
  resetPassword: stub,
  resendVerify: stub,
  resetPasswordConfirm: stub,
  user: undefined,
}

export const AuthContext = createContext<AuthContextInterface>(initialContext)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>()
  const isAuthenticated = !!user
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)
  const [isVerifyStep, setIsVerifyStep] = useState(false)

  async function login(email: string, password: string) {
    const client = getClient()

    try {
      const res = await client.post('auth/login/', {
        email: email,
        password: password,
      })
      setToken(res.data.key)
    } catch (error) {
      throw error as AxiosError
    }
  }

  async function resetPasswordConfirm(
    email: string,
    uid: string,
    token: string,
    new_password1: string,
    new_password2: string
  ) {
    const client = getClient()

    try {
      const res = await client.post(`password-reset-confirm/`, {
        email: email,
        new_password1,
        new_password2,
        uid: uid,
        token: token,
      })

      return res.data as {
        detail: string
      }
    } catch (error) {
      throw error as AxiosError
    }
  }

  async function resetPassword(email: string) {
    const client = getClient()

    try {
      const res = await client.post(`password-reset/`, {
        email: email,
      })

      return res.data as {
        detail: string
      }
    } catch (error) {
      throw error as AxiosError
    }
  }

  async function resendVerify(email: string) {
    const client = getClient()

    try {
      const res = await client.post('resend-verification/', {
        email: email,
      })

      if (res.status === 200) {
        toast('Verification email sent.', {
          type: 'success',
        })
      }

      return res.data
    } catch (error) {
      throw error as AxiosError
    }
  }

  async function logout() {
    removeToken()
    setUser(undefined)
  }

  async function createUser(
    email: string,
    first_name: string,
    last_name: string,
    password1: string,
    password2: string
  ) {
    const client = getClient()

    try {
      const resp = await client.post(
        'auth/registration/',
        { email, first_name, last_name, password1, password2 },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (resp) {
        setIsVerifyStep(true)
      }
    } catch (error) {
      throw error as AxiosError
    }
  }

  useEffect(() => {
    const tryAuth = async () => {
      const token = getToken()

      if (!token) {
        setIsCheckingAuth(false)
        return
      }

      const client = getClient({ reloadOnAuthError: false })

      const [err, currUser] = await to(client.get('me/'))

      setUser(currUser?.data)

      if (import.meta.env.MODE !== 'development') {
        if (!err && currUser.data) {
          Sentry.setUser({ email: currUser.data.email, id: currUser.data.id })
        } else {
          Sentry.setUser(null)
        }
      }

      setIsCheckingAuth(false)
    }

    void tryAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        login,
        createUser,
        getClient,
        logout,
        isCheckingAuth,
        isVerifyStep,
        resetPassword,
        resendVerify,
        resetPasswordConfirm,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export interface AuthContextInterface {
  isAuthenticated: boolean
  createUser: (
    email: string,
    first_name: string,
    last_name: string,
    password1: string,
    password2: string
  ) => Promise<void>
  login: (email: string, password: string) => Promise<void>
  resetPassword: (email: string) => Promise<{
    detail: string
  }>
  logout: () => Promise<void>
  getClient: () => AxiosInstance
  isCheckingAuth: boolean
  isVerifyStep: boolean
  resendVerify: (email: string) => Promise<void>
  resetPasswordConfirm: (
    email: string,
    uid: string,
    token: string,
    new_password1: string,
    new_password2: string
  ) => Promise<{
    detail: string
  }>
  user: User | undefined
}
