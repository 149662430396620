import { FilterIdentifier } from '../../models/saved_view'

const originalFilterTitles: Record<FilterIdentifier, Record<string, string>> = {
  CNU_TABLE: {
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    tags: 'Tags',
    deal_count: 'Deal Count',
    note_count: 'Note Count',
    contact_count: 'Contact Count',
    distributors: 'Known Distributors',
    dormitory_capacity: 'Dormitory Capacity',
    meal_plan_provided: 'Meal Plan Provided',
    estimated_meals_per_day: 'Estimated Meals Per Day',
    food_management_company: 'Food Mgmt Company',
    food_management_company_source: 'Food Mgmt Source',
    food_management_company_parent: 'Parent Food Mgmt Company',
    carnegie_classification_2021_size_setting_size: 'Carnegie Size',
    carnegie_classification_2021_size_setting_year: 'Carnegie Year',
    carnegie_classification_2021_size_setting_student_composition:
      'Student Composition',
    city: 'City',
    state_abbr: 'State',
    zipcode: 'Zipcode',
  },
  K12_TABLE: {
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    tags: 'Tags',
    deal_count: 'Deal Count',
    note_count: 'Note Count',
    contact_count: 'Contact Count',
    distributors: 'Known Distributors',
    city: 'City',
    state: 'State',
    zipcode: 'Zipcode',
    locale: 'Locale',
    county: 'County',
    gslo: 'Lowest Grade',
    gshi: 'Highest Grade',
    student_count: 'Students',
    teachers_count: 'Teachers',
    operational_schools: 'Operational Schools',
    revenue: 'Revenue',
    food_service_expenditures: 'Food Service Expenditures',
    free_lunch: 'Free Lunch',
    reduced_lunch: 'Reduced Lunch',
    total_supplemented_lunch: 'Total Supp Lunch',
    uncategorized_lunch: 'Uncategorized Supp Lunch',
    free_lunch_perc: 'Free Lunch (%)',
    reduced_lunch_perc: 'Reduced Lunch (%)',
    total_supplemented_lunch_perc: 'Total Supp Lunch (%)',
    uncategorized_lunch_perc: 'Uncategorized Supp Lunch (%)',
    estimated_meals_per_day: 'Estimated Meals Per Day',
  },
  DOORS_TABLE: {
    one_year_total_value: '1yr Total Value',
    chain_count: 'Chain Size',
    sales_stage: 'Stage',
    contact_count: 'Contact Count',
    ltv_total: 'Lifetime Values',
    brand_ltv: 'Lifetime Values',
    revenue_ltv: 'Lifetime Values',
    taro_plus_tabo: 'Predictive Annual Values',
    taro: 'Predictive Annual Values',
    tabo: 'Predictive Annual Values',
    cuisine_50: 'Cuisine 50',
    pounds_per_year: 'Pounds Per Year',
    menu_ingredients: 'Menu Characteristics',
    menu_size: 'Menu Size',
    menu_matches: 'Menu Matches',
    delivery_platforms: 'Delivery Platforms',
    expense_category: 'Expense Category',
    reputation_data: 'Featured On',
    michelin_stars: 'Michelin Stars',
    avg_rating: 'Avg Rating',
    review_count: 'Review Count',
    velocity_group: 'Predicted Traffic',
    instagram_followers: 'Instagram Followers',
    country: 'Country',
    state: 'State',
    dmanamecbsa: 'DMA Name',
    city: 'City',
    zipcode: 'Zipcode',
    account_owner: 'Account Owner',
    tags: 'Tags',
    distributors: 'Known Distributors',
    deal_count: 'Deal Count',
    median_household_income: 'Median Household Income',
    pop_density: 'Pop Density',
    unemployment_rate: 'Unemployment Rate',
    reviews_count: 'Review Count',
    total_ltv: 'Lifetime Total Value',
    note_count: 'Note Count',
    median_hhi: 'Median Household Income',
    days_since_last_modified: 'Days Since Last Update',
    hh_gt100k: 'High Household Income',
  },
  DEALS_TABLE: {
    company_size: 'Size',
    category: 'Category',
    sub_category: 'Type',
    product: 'Products',
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    tags: 'Tags',
    contact_tags: 'Contact Labels',
    distributors: 'Known Distributors',
    note_count: 'Note Count',
    contact_count: 'Contact Count',
    predicted_volume: 'Predicted Volume',
    predicted_revenue: 'Predicted Revenue',
    estimated_close_date: 'Estimated Close Date',
    monthly_volume_override: 'Monthly Volume (override)',
    monthly_revenue_override: 'Monthly Revenue (override)',
    close_date_override: 'Close Date (override)',
    modified: 'Last Modified',
    created: 'Date Created',
    time_in_stage: 'Days In Stage',
    days_since_last_update: 'Days Since Last Update',
  },
  DEALS_PIPELINE_TABLE: {
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    product: 'Product',
    count: 'Deals Count',
    closed: 'Closed Deals Count',
    taro: 'Annual Rev. Opp.',
    taro_closed: 'Closed Annual Rev. Opp.',
    last_modified: 'Last Update',
    days_since_last_modified: 'Days Since Last Update',
    category: 'Category',
    sub_category: 'Type',
  },
  CONTACTS_TABLE: {
    title: 'Title',
    contact_type: 'Type',
    contact_labels: 'Contact Labels',
    company_size: 'Company Size',
    company_cuisine_50: 'Cuisine',
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    company_tags: 'Company Tags',
    modified: 'Last Modified',
    added: 'Date Added',
  },
  COMPANIES_TABLE: {
    sales_stage: 'Sales Stage',
    account_owner: 'Account Owner',
    tags: 'Tags',
    deal_count: 'Deal Count',
    distributors: 'Known Distributors',
    country: 'Country',
    city: 'City',
    state: 'State',
  },
  CHAINS_TABLE: {
    one_year_total_value: '1yr Total Value',
    chain_count: 'Chain Size',
    sales_stage: 'Stage',
    contact_count: 'Contact Count',
    cuisine_50: 'Cuisine 50',
    menu_ingredients: 'Menu Characteristics',
    menu_length: 'Menu Size',
    menu_matches: 'Menu Matches',
    is_chain: '2+ Unit Chain',
    expense_category: 'Expense Category',
    reputation_data: 'Featured On',
    michelin_stars: 'Michelin Stars',
    rating: 'Rating',
    velocity_group: 'Predicted Traffic',
    account_owner: 'Account Owner',
    tags: 'Tags',
    distributors: 'Known Distributors',
    country: 'Country',
    state: 'State',
    city: 'City',
    zipcode: 'Zipcode',
    avg_rating: 'Average Rating',
    reviews_count: 'Review Count',
    instagram_followers: 'Instagram Followers',
    total_ltv: 'Lifetime Total Value',
    brand_ltv: 'Lifetime Brand Value',
    revenue_ltv: 'Lifetime Revenue Value',
    pounds_per_year: '1yr Volume (lbs)',
    taro: '1yr Revenue Value',
    tabo: '1yr Brand Value',
    deal_count: 'Deal Count',
    note_count: 'Note Count',
    median_hhi: 'Median Household Income',
    pop_density: 'Population Density',
    days_since_last_modified: 'Days Since Last Update',
    hh_gt100k: 'High Household Income',
    unemployment_rate: 'Unemployment Rate',
  },
  ACTIVITY_TABLE: {
    actor: 'Team Member',
    timestamp: 'Date Range',
    event_type: 'Event Type',
  },
  ACTIVITY_TABLE_V2: {
    user: 'Team Member',
    created_at: 'Date Range',
    event_type: 'Event Type',
  },
}

// Ugly hack, sorry
export const FILTER_TITLES = new Proxy(originalFilterTitles, {
  get(target, prop: string) {
    if (prop.startsWith('companies-')) {
      return target['COMPANIES_TABLE']
    }
    if (prop.startsWith('CHAINS_TABLE')) {
      return target['CHAINS_TABLE']
    }
    if (prop.startsWith('DEALS_TABLE')) {
      return target['DEALS_TABLE']
    }
    if (prop.startsWith('CNU_TABLE')) {
      return target['CNU_TABLE']
    }
    if (prop.startsWith('K12_TABLE')) {
      return target['K12_TABLE']
    }
    if (prop.startsWith('CONTACTS_TABLE')) {
      return target['CONTACTS_TABLE']
    }
    return target[prop as keyof typeof target]
  },
})
