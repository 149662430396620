import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'

export function K12DistrictExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportK12Districts = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportK12Districts(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: K12 Districts - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        ['lea_name', 'Name'],
        ['address', 'Address'],
        ['lcity', 'City'],
        ['lstate', 'State'],
        ['zipcode', 'Zipcode'],
        ['phone', 'Phone'],
        ['domain', 'Domain'],
        ['gslo', 'Lowest Grade'],
        ['gshi', 'Highest Grade'],
        ['student_count', 'Students'],
        ['teachers_count', 'Teachers'],
        ['operational_schools', 'Schools'],
        ['revenue', 'Revenue'],
        ['google_place_url', 'Place URL'],
      ],
    },
    {
      title: 'Dining Services',
      elements: [
        ['food_service_expenditures', 'Food Service Expenditures'],
        ['free_lunch', 'Free Lunch'],
        ['reduced_lunch', 'Reduced Lunch'],
        ['total_supplemented_lunch', 'Total Supplemented Lunch'],
        ['uncategorized_lunch', 'Total Supplemented Lunch'],
        ['free_lunch_perc', 'Free Lunch (%)'],
        ['reduced_lunch_perc', 'Reduced Lunch (%)'],
        ['total_supplemented_lunch_perc', 'Total Supplemented Lunch (%)'],
        ['uncategorized_lunch_perc', 'Uncategorized Supplemented Lunch (%)'],
        ['estimated_meals_per_day', 'Estimated Meals Per Day'],
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: [
        ['sales_stage', 'Sales Stages'],
        ['account_owner', 'Account Owner'],
        ['tags', 'Tags'],
        ['deals_count', 'Deals Count'],
        ['contact_count', 'Contact Count'],
        ['notes_count', 'Notes Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for K-12 Districts Download"
      subTitle="Download up to 10,000 K-12 Districts at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export K-12 Districts"
      columns={columns}
      exportAPIAction={exportK12Districts}
      {...props}
    />
  )
}
