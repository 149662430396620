import './CollapsibleSection.styles.css'
import { Block } from '../Block'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { cn } from '../cn'

export interface ICollapsibleSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  summary: React.JSX.Element
  forceClose?: boolean
}

export function CollapsibleSection(props: ICollapsibleSectionProps) {
  const SummaryContent = () => (
    <div className={'flex items-center gap-x-2'}>
      {!props.forceClose && (
        <div className={'flex items-center marker'}>
          <ChevronDownIcon />
        </div>
      )}
      <span className={'text-2xl font-normal text-gray-700 w-full'}>
        {props.summary}
      </span>
    </div>
  )
  return (
    <Block
      className={cn('collapsible-section max-h-[1000px]', props.className)}
    >
      {props.forceClose ? (
        <>
          <SummaryContent />
          <div className={'hidden'}>{props.children}</div>
        </>
      ) : (
        <details open={true}>
          <summary>
            <SummaryContent />
          </summary>
          <div className={'max-h-[600px] flex pb-1 pt-4'}>{props.children}</div>
        </details>
      )}
    </Block>
  )
}
