import { AFbLink } from 'components/FbUI/FbLink/styles'

interface Info {
  getValue: () => string | undefined
}

export const LinkCell = ({ info }: { info: Info }) => {
  return info.getValue() ? (
    <AFbLink href={info.getValue()} target="_blank">
      View
    </AFbLink>
  ) : (
    <>-</>
  )
}
