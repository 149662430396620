import { Text } from '../../../components/FbUI/Text'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { useAuth } from 'context/authentication/useAuth'
import { useForm } from 'react-hook-form'
import to from 'await-to-js'
import { handleFormError } from '../../../utils/handleFormError'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorText } from '../../../components/FormUtils/ErrorText'
import styled from 'styled-components/macro'
import FbButton from '../../../components/FbUI/FbButton'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { FbLink } from 'components/FbUI/FbLink'

const registerFormShape = z.object({
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  password1: z.string(),
  password2: z.string(),
  non_field_errors: z.string().optional(),
  terms: z.boolean().refine((v) => v, {
    message: 'You must agree to the terms and conditions',
  }),
})

function Register() {
  const { createUser, isVerifyStep } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password1: '',
      password2: '',
      non_field_errors: '',
      terms: false,
    },
    mode: 'onSubmit',
    resolver: zodResolver(registerFormShape),
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const [err] = await to(
        createUser(
          values.email,
          values.first_name,
          values.last_name,
          values.password1,
          values.password2
        )
      )
      handleFormError(err, setError)
    },
    (err) => console.warn(err)
  )

  return (
    <Form onSubmit={onSubmit}>
      {isVerifyStep && (
        <Text>
          Thanks for registering! Check your email for a verification email. We
          need to verify your email in order for you to login.
        </Text>
      )}

      <Title>Get started with First Bite</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: 12,
        }}
      >
        <TextInput control={control} name="first_name" label="First Name" />
        <TextInput control={control} name="last_name" label="Last Name" />
      </div>
      <Text>Join your team by using your work email.</Text>
      <TextInput control={control} name="email" label="Email" />
      <TextInput
        type="password"
        control={control}
        name="password1"
        label="Password"
      />
      <TextInput
        type="password"
        control={control}
        name="password2"
        label="Confirm Password"
      />
      <Checkbox control={control} name="terms">
        <Text style={{ color: '#475467' }}>
          By continuing, you agree to First Bite&apos;s{' '}
          <FbLink to="/terms-of-use">terms of use</FbLink> and{' '}
          <FbLink to="/privacy-policy">privacy policy</FbLink>
        </Text>
      </Checkbox>
      <ButtonContainer>
        <FbButton type="submit" onClick={onSubmit} loading={isSubmitting}>
          Create Account
        </FbButton>
      </ButtonContainer>
      <ErrorText control={control} name={'non_field_errors'} />
    </Form>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

const Form = styled.form`
  width: 400px;
  flexshrink: 0;

  @media (max-width: 400px) {
    width: 100%;
    margin: 0 16px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
`

export default Register
