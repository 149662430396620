import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  width: 100%;
`

export const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TopBarContainer = styled.div`
  height: 70px;
  padding: 0px 20px;
  width: 100%;
  background-color: black;
  align-items: center;
  justify-content: space-between;
  display: flex;
`

export const TopBarTitle = styled.div`
  color: white;
  text-align: center;
  font-size: 24px;
`
