import * as S from './styles'

import { Text } from '../../FbUI/Text'
import { FormControl, InputGroup } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useEffect, useState } from 'react'

export function BootstrapInput<T extends FieldValues>({
  name,
  label,
  control,
  type = 'text',
  units,
  ...props
}: {
  control: Control<T, any>
  name: Path<T>
  label?: string
  placeholder?: string
  type?: string
  units?: string
}) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ ...props, name: name, control })

  const [internalValue, setInternalValue] = useState('')
  useEffect(() => {
    setInternalValue(value?.toString() || '')
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      type === 'number'
        ? onlyNumericCharacters(event.target.value)
        : event.target.value
    //TODO: do not convert to Number() because it screws up everything
    onChange(newValue)
  }

  return (
    <div className={'mb-2'}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <InputGroup>
        {units === '$' ? <InputGroup.Text>$</InputGroup.Text> : null}
        <FormControl
          id={name}
          onChange={handleChange}
          type="text"
          value={internalValue}
        />
        {units === '%' ? <InputGroup.Text>%</InputGroup.Text> : null}
      </InputGroup>
      <Text>{error?.message ?? ''}</Text>
    </div>
  )
}

// create a function that takes a string and returns only numeric characters
function onlyNumericCharacters(value: string) {
  return value.replace(/[^0-9.]/g, '')
}
