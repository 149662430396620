/* eslint-disable @typescript-eslint/no-explicit-any */

import apiService from 'services/api'
import { AssignModal, AssignModalProps } from './BaseAssignModal'
import {
  bulkEditDistributorsParams,
  bulkEditMultiParams,
} from 'services/apiTypes'
import { useDataTableContext } from 'components/DataTable'
import { formatInteger } from 'utils/formatting'
import dayjs from 'dayjs'
import { CompanyDistributor } from '../../../models/companies'

export default function DistributorAssignModal({
  tableObjAccessor = 'distributors',
  apiDistributorsAction,
  ...props
}: {
  tableObjAccessor?: string
  apiDistributorsAction: (params: bulkEditDistributorsParams) => Promise<any>
} & Omit<
  AssignModalProps<CompanyDistributor, any>,
  | 'apiAction'
  | 'title'
  | 'subtitle'
  | 'pluralLabel'
  | 'singularLabel'
  | 'onConfirmAddNew'
  | 'items'
  | 'tableObjAccessor'
>) {
  const api = apiService()
  const { data: distributors } = api.useGetDistributors()
  const {
    state: { totalSelectedRows },
  } = useDataTableContext()

  const apiAction = async (params: bulkEditMultiParams) => {
    const { items_ids: itemsIds, ...rest } = params
    return await apiDistributorsAction({
      ...rest,
      distributor_ids: itemsIds,
    })
  }

  return (
    <AssignModal
      {...props}
      title="Assign Distributors"
      subtitle={
        <span>
          Choose distributors to add or remove from{' '}
          <b>{formatInteger(totalSelectedRows)}</b> selected rows.
        </span>
      }
      pluralLabel="Distributors"
      singularLabel="Distributor"
      items={distributors ?? []}
      tableObjAccessor={tableObjAccessor!}
      apiAction={apiAction}
      columns={[
        {
          accessorKey: 'name',
          header: 'Name',
        },
        {
          accessorKey: 'created',
          header: 'Date Created',
          cell: (info) => dayjs(info.getValue()).format('MMM DD YYYY'),
          meta: { rightAlign: true },
          size: 100,
        },
      ]}
    />
  )
}
