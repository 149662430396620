import styled from 'styled-components/macro'

export const LTVChart = styled.div`
  display: flex;
`
export const LTVChartSubtitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 40px;
`

export const ColoredDot = styled.div<{
  color: string
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
`

export const LTVInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ValueBig = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #101828;
`

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
`
