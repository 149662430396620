import { cn } from './cn'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Tooltip } from '../Tooltip/tooltip'

export interface IInfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  value?: string | number
  tooltip?: string
}

export function InfoCard(props: IInfoCardProps) {
  if (!props.value) return null
  return (
    <div
      className={cn(
        'p-3 bg-white rounded-xl border-gray-500 border',
        props.className
      )}
    >
      <div className="flex items-center gap-3 mb-2 text-gray-500">
        <h1 className="uppercase font-medium text-xs tracking-wider">
          {props.title}
        </h1>
        {props.tooltip && (
          <Tooltip content={props.tooltip}>
            <AiFillInfoCircle className="text-xs" />
          </Tooltip>
        )}
      </div>
      <span className="text-xl font-bold text-gray-900">{props.value}</span>
    </div>
  )
}
