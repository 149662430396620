import { Page } from 'components/FbUI/Page'
import * as S from './styles'
import { Spinner } from 'react-bootstrap'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { handleFormError } from 'utils/handleFormError'
import to from 'await-to-js'
import DealsSection from 'components/Deals/DealsSection/DealsSection'
import FbButton from '../../../components/FbUI/FbButton'
import confirm from '../../../components/dialogConfirm'
import useDocumentTitle from 'components/useDocumentTitle'
import ChainHeader from 'components/Cards/ChainHeaderCard'
import ContactsTableSection from '../../../components/Cards/ContactsTableSection'
import NotesSection from '../../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../../components/UI/Block'
import { EditCompanyModal } from 'components/Modals/CompanyModal/EditCompanyModal'
import { ContactCompanyType, IContactCompany } from 'models/contact_companies'
import { CreatableDistributorSelector } from 'components/CreatableDistributorSelector'
import axios from 'axios'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import { DoorProxySection } from '../../../components/Cards/DoorProxySection'
import { getIdWithPrefix } from 'utils/pathUtils'
import { usePreferences } from 'context/preferences/PreferencesContext'

const companyInfo = z.object({
  taglist: z.any().optional().nullable(),
  notes: z.string().optional().nullable(),
  distributors: z.array(z.number()).optional().nullable(),
  previous_taglist: z.any().optional().nullable(),
  previous_distributors: z.any().optional().nullable(),
})

export type CompanyInfo = z.infer<typeof companyInfo>

const serializeValues = (values: CompanyInfo) => {
  return {
    taglist:
      Array.isArray(values.taglist) && values.taglist.length > 0
        ? (values?.taglist as number[])
        : 'REMOVE',
    distributors: Array.isArray(values.distributors) ? values.distributors : [],
  } as const
}

export function CompanyPage() {
  const api = apiService()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [editCompany, setEditCompany] = useState<IContactCompany | null>(null)
  const [notFound, setNotFound] = useState(false)

  const [previousDistributors, setPreviousDistributors] = useState<number[]>([])
  const [previousTaglist, setPreviousTaglist] = useState<number[]>([])

  const params = useParams()
  const prefix = params?.company_id ? params.company_id.slice(0, 2) : undefined
  const { preferences } = usePreferences()

  // based on prefix, redirect to the correct page
  switch (prefix) {
    case preferences?.company_type_prefix_map[ContactCompanyType.Chain]:
      navigate(`/chain/${params?.company_id}`)
      break
    case preferences?.company_type_prefix_map[ContactCompanyType.University]:
      navigate(`/university/${params?.company_id}`)
      break
    case preferences?.company_type_prefix_map[ContactCompanyType.K12District]:
      navigate(`/k12/${params?.company_id}`)
      break
  }

  const companyID = getIdWithPrefix(
    params.company_id as string,
    preferences?.company_type_prefix_map[ContactCompanyType.UserGenerated]
  ) as number

  const {
    data: companyData,
    isLoading,
    isError,
    error,
  } = api.useGetCompany(companyID)

  useDocumentTitle(`${companyData?.name || 'Not Found'} (Company) - First Bite`)

  useEffect(() => {
    if (
      isError &&
      axios.isAxiosError(error) &&
      error?.response?.status === 404
    ) {
      setNotFound(true)
    }
  }, [error, isError])

  useEffect(() => {
    if (companyData?.chain) {
      // redirect to chain page
      navigate(
        `/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${companyData?.chain}`
      )
    }
    if (companyData?.university_id) {
      // redirect to university page
      navigate(
        `/university/${preferences?.company_type_prefix_map[ContactCompanyType.University]}${companyData?.university_id}`
      )
    }
    if (companyData?.k12district_id) {
      // redirect to university page
      navigate(
        `/k12/${preferences?.company_type_prefix_map[ContactCompanyType.K12District]}${companyData?.k12district_id}`
      )
    }
  }, [companyData])

  const { data: tagsOptions } = api.useGetTagsOptions()
  const { data: distributorsOptions } = api.useGetCompanyDistributorsOptions()

  const { control, handleSubmit, reset, setError, formState } = useForm({
    defaultValues: {
      taglist: undefined,
    } as CompanyInfo,
    resolver: zodResolver(companyInfo),
  })

  useEffect(() => {
    if (companyData) {
      const mappedTaglist = companyData.taglist?.map((t) => t.id) || []
      const mappedDistributors =
        companyData.distributors?.map((distributor) => distributor.id) || []

      reset({
        taglist: mappedTaglist,
        distributors: mappedDistributors,
      })

      setPreviousDistributors(mappedDistributors)
      setPreviousTaglist(mappedTaglist)
    }
  }, [companyData])

  const mutation = useMutation({
    mutationFn: async (values: CompanyInfo) => {
      const newValues = serializeValues(values)
      return await api.bulkEditCompanyAttrs({
        contact_company_ids: [companyID],
        taglist: newValues.taglist.length > 0 ? newValues.taglist : 'REMOVE',
        distributors:
          newValues.distributors.length > 0 ? newValues.distributors : 'REMOVE',
        previous_distributors: previousDistributors,
        previous_taglist: previousTaglist,
      })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['company', companyID!],
      })
    },
    onSettled: async (data, err) => {
      await queryClient.invalidateQueries({
        queryKey: ['company', companyID!],
      })

      if (err) {
        toast.error('Error updating company')
      } else {
        toast.success('Company updated')
      }
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    const [err] = await to(mutation.mutateAsync(values))
    if (err) {
      handleFormError(err, setError)
    }
  })

  const RightColumnHeader = useMemo(() => {
    return (
      <>
        <CreatableTagSelector
          name="taglist"
          control={control}
          label="Tags"
          placeholder="Tags"
          options={tagsOptions}
          setError={setError}
        />
        <CreatableDistributorSelector
          name="distributors"
          control={control}
          label="Distributors"
          placeholder="Distributor(s)"
          options={distributorsOptions}
          setError={setError}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {formState?.isDirty && (
            <S.SaveButton loading={formState?.isSubmitting} onClick={onSubmit}>
              Save
            </S.SaveButton>
          )}
        </div>
      </>
    )
  }, [
    companyData,
    formState,
    onSubmit,
    tagsOptions,
    distributorsOptions,
    control,
  ])

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  if (notFound) {
    return <NotFoundCard />
  }

  return (
    <Page className="gap-y-4" noContainer>
      <ChainHeader
        type="company"
        category={companyData?.category}
        subCategory={companyData?.sub_category}
        name={companyData?.name}
        breadcrumbs={[{ breadcrumb: companyData?.name }]}
        phone={companyData?.phone_number}
        extension={companyData?.extension}
        address={{
          url: companyData?.google_place_url,
          addressInfo: {
            fullAddress: companyData?.address,
            city: companyData?.city,
            state: companyData?.state,
            zipcode: companyData?.zipcode,
          },
        }}
        domainField={companyData?.domain}
        userCreated
        rightColumnHeader={RightColumnHeader}
        editDomain={() => {
          setEditCompany(companyData!)
        }}
      />

      {!!editCompany && (
        <EditCompanyModal
          show={!!editCompany}
          setShow={() => setEditCompany(null)}
          company={editCompany}
        />
      )}

      <DealsSection forCompany={companyData} />

      <NotesSection
        chainId={companyData?.chain}
        contactCompanyId={companyData?.id}
        pagination={false}
      />

      <ContactsTableSection data={companyData} />

      <DoorProxySection chainProxy={companyData} />

      <Block className={'flex items-center justify-center'}>
        <FbButton
          className="flex-shrink-0"
          variant="danger"
          onClick={async () => {
            if (
              await confirm(
                'Deleting this company will also delete any notes, tags, deals, or contacts you may have added. This action cannot be undone.',
                'Delete Company',
                undefined,
                {
                  buttonVariant: 'danger',
                  confirmLabel: 'Delete Company',
                }
              )
            ) {
              if (!companyData?.id) {
                return
              }
              const [err] = await to(api.deleteCompany(companyData.id!))
              if (err) {
                toast.error('Error deleting company')
                return
              }
              toast.success('Company deleted')
              navigate('/')
            }
          }}
        >
          Delete Company
        </FbButton>
      </Block>
    </Page>
  )
}
