import { Spinner } from 'react-bootstrap'
import { useMemo } from 'react'
import apiService from 'services/api'
import * as S from './styles'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'models/user'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'

export function UserTable() {
  const api = apiService()

  const { data, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      return await api.getUsers()
    },
  })

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 200,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
    ],
    [data]
  )

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <S.SettingsTableContainer
      style={{
        height: 'calc(100vh - 235px)',
        marginTop: '20px',
      }}
    >
      <ClientDataTable data={data} columns={columns} />
    </S.SettingsTableContainer>
  )
}
