import React, { useMemo } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { GroupBase, StylesConfig } from 'react-select'
import { ErrorLabel } from '../ErrorLabel'
import lodash from 'lodash'
import ReactSelectWrapper from '../ReactSelectWrapper'
import { cn } from '../../UI/cn'

export type SelectOption = {
  label: string | React.JSX.Element
  value: unknown
}

export interface ISelectProps<T extends FieldValues> {
  control: Control<T, any>
  name: Path<T>
  label?: string
  placeholder?: string
  type?: string
  options?: SelectOption[]
  isMulti?: boolean
  style?: React.CSSProperties

  reactSelectStyles?: StylesConfig<any, boolean, GroupBase<any>> | undefined
  className?: string
  disabled?: boolean
  menuPosition?: 'fixed' | 'absolute'
  optional?: boolean

  valueWhenCleared?: any
  filterOption?: (option: SelectOption, inputValue: string) => boolean
}

function Select<T extends FieldValues>(props: ISelectProps<T>) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  const compareObjects = (obj1: any, obj2: any) => {
    if (!obj1 || !obj2) return false
    return lodash.isEqual(obj1, obj2)
  }

  const _value = useMemo(() => {
    if (props.isMulti) {
      return value?.map((v: SelectOption) =>
        props?.options?.find((option: any) => option.value === v)
      )
    } else {
      return props?.options?.find((option: any) =>
        compareObjects(option.value, value)
      )
    }
  }, [value, props.options, props.isMulti])

  return (
    <div
      className={cn('flex flex-col mt-3', props.className)}
      style={props.style}
    >
      {props.label && (
        <label
          className="text-base font-medium text-gray-800 select-none mb-1"
          htmlFor={props.name}
        >
          {props.label}
          {props.optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}
        </label>
      )}

      <ReactSelectWrapper
        onChange={(newValue: any) => {
          if (newValue === null) {
            let clearedValue = null
            if (props.valueWhenCleared !== undefined) {
              clearedValue = props.valueWhenCleared
            }
            onChange(clearedValue)
            return
          }

          if (props.isMulti) {
            onChange(newValue?.map((v: any) => v.value))
          } else {
            onChange(newValue?.value)
          }
        }}
        placeholder={props.placeholder}
        isClearable={true}
        name={props.name}
        options={props.options}
        value={_value}
        defaultValue={props.isMulti ? [] : undefined}
        ref={ref}
        isMulti={props.isMulti}
        classNames={{
          control: () => `${error?.message ? 'input-error' : ''}`,
        }}
        menuPosition={props.menuPosition}
        isDisabled={props.disabled}
        filterOption={props.filterOption}
      />

      {error?.message && <ErrorLabel message={error.message} />}
    </div>
  )
}

export default Select
