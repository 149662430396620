import axios from 'axios'
import { DataTableProvider } from 'components/DataTable'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { LTVChartsCard } from '../../../components/Charts/LTVChartsCard/LTVChartsCard'
import { RatingsCard } from '../../../components/Charts/RatingsCard/RatingsCard'
import { CompanyAttributesManager } from '../../../components/CompanyAttrsManager/CompanyAttrsManager'
import * as S from './door.styles'
import { OpeningHoursTitle } from './door.styles'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AgeCard } from 'components/Cards/AgeCard'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import { EducationCard } from 'components/Cards/EducationCard'
import { RaceCard } from 'components/Cards/RaceCard'
import { ZipCodeDemographics } from 'components/Cards/ZipCodeDemographics'
import { FbLinkPill } from 'components/FbUI/FbLinkPill'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import useDocumentTitle from 'components/useDocumentTitle'
import { usePreferences } from 'context/preferences/PreferencesContext'
import mapboxgl from 'mapbox-gl'
import { ContactCompanyType } from 'models/contact_companies'
import { Spinner } from 'react-bootstrap'
import { DoorMenuSection } from '../../../components/Cards/DoorMenuSection'
import { Page } from '../../../components/FbUI/Page'
import NotesSection from '../../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../../components/UI/Block'

mapboxgl.accessToken =
  'pk.eyJ1IjoiY2NhYnJhbC1kZXYiLCJhIjoiY2xkdWxudHlyMDcybDN2b3o0OHU3d3o3ZiJ9.WNw9-EHladqP7BiEQYKuaw'

function _Door() {
  const mapContainer = useRef(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const api = apiService()
  const urlParams = useParams()
  const placeID = urlParams?.firstbite_id
  const { preferences } = usePreferences()

  const {
    data: placeData,
    isLoading,
    isError,
    error,
  } = api.useGetPlace(placeID)

  useDocumentTitle(
    `${placeData?.restaurant_name || 'Not Found'} (Door) - First Bite`
  )

  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (
      isError &&
      axios.isAxiosError(error) &&
      error?.response?.status === 404
    ) {
      setNotFound(true)
    }
  }, [error, isError])

  useEffect(() => {
    if (!mapContainer.current) return // wait for map container to be ready
    if (map.current || !placeData) return // initialize map only once
    const createdMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [placeData.longitude, placeData.latitude],
      zoom: 12,
    })
    map.current = createdMap as mapboxgl.Map
  }, [placeData, mapContainer])

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  if (notFound) {
    return <NotFoundCard />
  }

  return (
    <Page className="gap-y-4" noContainer>
      {placeData && (
        <>
          <ChainHeaderCard
            type="door"
            doorId={placeData?.firstbite_id}
            isLoading={isLoading}
            category={'Commercial'}
            subCategory={'Restaurant Door'}
            name={placeData?.restaurant_name}
            breadcrumbs={[
              {
                title: 'Chain',
                breadcrumb: placeData?.restaurant_name,
                url: `/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${placeData?.chain?.id}`,
              },
            ]}
            phone={placeData?.phone}
            address={{
              addressInfo: {
                fullAddress: placeData?.full_address,
              },
              url: placeData?.google_place_url,
            }}
            companyLogoUrl={placeData?.small_img_url}
            description={placeData?.about}
            domainField={placeData?.website}
            rightColumnHeader={
              <CompanyAttributesManager
                chainID={placeData?.chain?.id}
                contactCompanyID={undefined}
              />
            }
            leftFirstBiteCol={{
              chain: placeData?.chain.count,
              category: placeData?.expense_category,
              cuisine: placeData?.cuisine_50,
              reviews: placeData?.reviews_count,
              rating: placeData?.avg_rating,
            }}
          />

          {placeData && (
            <NotesSection
              contactCompanyId={undefined}
              chainId={placeData.chain.id}
              pagination={false}
            />
          )}

          <DoorMenuSection doorId={placeData.firstbite_id} />

          <Block>
            <S.Section>
              <S.SectionItem>
                <LTVChartsCard
                  ltv={{
                    brand_ltv: placeData.ltv_data.brand_ltv,
                    revenue_ltv: placeData.ltv_data.revenue_ltv,
                    total_ltv: placeData.ltv_data.total_ltv,
                  }}
                />
              </S.SectionItem>

              <S.VerticalDivider />

              {placeData?.address_info ? (
                <S.SectionItem>
                  <RatingsCard restaurant={placeData} />
                </S.SectionItem>
              ) : null}

              <S.VerticalDivider />

              {placeData?.address_info && (
                <ZipCodeDemographics addressInfo={placeData?.address_info} />
              )}
            </S.Section>
          </Block>

          {placeData?.address_info ? (
            <Block>
              <S.Section>
                <S.SectionItem style={{ flex: 0.5 }}>
                  <RaceCard addressInfo={placeData?.address_info} />
                </S.SectionItem>

                <S.VerticalDivider />

                <S.SectionItem>
                  <AgeCard addressInfo={placeData?.address_info} />
                </S.SectionItem>

                <S.VerticalDivider />

                <S.SectionItem style={{ flex: 2 }}>
                  <EducationCard addressInfo={placeData?.address_info} />
                </S.SectionItem>
              </S.Section>
            </Block>
          ) : null}

          <Block>
            <S.Section style={{ justifyContent: 'stretch' }}>
              <S.RestaurantInfo>
                <S.RowBetween>
                  <S.CardTitle>Restaurant Information</S.CardTitle>
                  <S.Row>
                    {placeData?.menu_url && (
                      <FbLinkPill url={placeData?.menu_url}>Menu</FbLinkPill>
                    )}
                    {placeData?.booking_url && (
                      <FbLinkPill url={placeData?.booking_url}>
                        Booking
                      </FbLinkPill>
                    )}
                  </S.Row>
                </S.RowBetween>

                {placeData?.open_hours && (
                  <>
                    <OpeningHoursTitle>Opening Hours</OpeningHoursTitle>

                    <S.OpenHoursGrid>
                      {Object.entries(placeData?.open_hours)?.map(
                        ([day, hours]) => {
                          return (
                            <S.OpeningHoursItem key={day}>
                              <span>{day}</span>
                              <span>{hours as string}</span>
                            </S.OpeningHoursItem>
                          )
                        }
                      )}
                    </S.OpenHoursGrid>
                  </>
                )}
              </S.RestaurantInfo>
              <S.VerticalDivider style={{ height: 'unset' }} />
              <S.Map>
                <div
                  style={{
                    width: 571,
                    maxHeight: 400,
                    marginBottom: 20,
                  }}
                  ref={mapContainer}
                  className="map-container"
                />
              </S.Map>
            </S.Section>
          </Block>
        </>
      )}
    </Page>
  )
}

export default function Door() {
  return (
    <DataTableProvider tableKey={'door-table'}>
      <_Door />
    </DataTableProvider>
  )
}
