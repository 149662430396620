import React, { useEffect, useMemo, useRef, useState } from 'react'
import FbButton, { FbButtonVariants } from 'components/FbUI/FbButton'
import { BsCheck, BsChevronDown } from 'react-icons/bs'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../BaseDropdown/dropdown-base'
import { cn } from '../cn'

export interface ISelectItem<T = any> {
  label: string
  icon?: React.JSX.Element
  value: T
}

export interface IMultiSelectProps<T> {
  items?: ISelectItem<T>[]
  selected: T[]
  onSelect: (items: T[]) => void
  error?: string
  buttonLabel?: string | React.JSX.Element
  buttonLabelClassName?: string
  buttonIcon?: React.JSX.Element
  buttonVariant?: FbButtonVariants
  menuLabel?: string
  disabled?: boolean
  className?: string
  buttonClassName?: string
  disableChevronIcon?: boolean
  enableSelectAll?: boolean
  selectAllLabel?: string
  selectAllBehavior?: 'toggle' | 'selectNone'
}

export function MultiSelect<T>(props: IMultiSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const [tempSelected, setTempSelected] = useState<T[]>(props.selected)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const allValues = useMemo(
    () => props.items?.map((item) => item.value) ?? [],
    [props.items]
  )

  const isAllSelected = useMemo(() => {
    if (props.selectAllBehavior === 'selectNone') {
      return tempSelected.length === 0
    } else {
      return (
        allValues.length > 0 &&
        allValues.every((value) => tempSelected.includes(value))
      )
    }
  }, [allValues, tempSelected])

  useEffect(() => {
    setTempSelected(props.selected)
  }, [props.selected])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        applySelection()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [tempSelected])

  const handleSelect = (item: ISelectItem<T>) => {
    setTempSelected((prev) =>
      prev.includes(item.value)
        ? prev.filter((i) => i !== item.value)
        : [...prev, item.value]
    )
  }

  const handleSelectAll = (e: any) => {
    e.preventDefault()

    if (props.selectAllBehavior === 'selectNone') {
      setTempSelected([])
    } else {
      // Default 'toggle' behavior
      setTempSelected(isAllSelected ? [] : allValues)
    }
  }

  const applySelection = () => {
    props.onSelect(tempSelected)
    setIsOpen(false)
  }

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger disabled={props.disabled} asChild>
        <FbButton
          className={cn('flex-1 border-[#DEE2E6]', props.buttonClassName)}
          variant={props.buttonVariant}
        >
          <div className="flex items-center justify-between gap-2 flex-1 min-w-0">
            <div className="flex items-center justify-start w-[90%] max-w-[90%]">
              {props.buttonIcon}
              {props.buttonLabel && (
                <b className={cn('truncate', props.buttonLabelClassName)}>
                  {props.buttonLabel}:&nbsp;
                </b>
              )}
              {props.enableSelectAll && isAllSelected ? (
                'All'
              ) : tempSelected.length > 0 ? (
                <>{tempSelected.length} selected</>
              ) : (
                <span className="text-gray-500">Select items</span>
              )}
            </div>
            {!props.disableChevronIcon && (
              <div>
                <BsChevronDown />
              </div>
            )}
          </div>
        </FbButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        ref={dropdownRef}
        className={cn(
          'w-56',
          {
            'opacity-50': props.disabled,
            'border-red-400': props.error,
          },
          props.className
        )}
      >
        {props.menuLabel && (
          <>
            <DropdownMenuLabel>{props.menuLabel}</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        )}
        <DropdownMenuGroup>
          {props.enableSelectAll && (
            <DropdownMenuItem onClick={handleSelectAll}>
              <div className="flex items-center justify-between gap-2 w-full">
                <div className="flex items-center gap-2">
                  {props.selectAllLabel || 'All'}
                </div>
                <div>{isAllSelected && <BsCheck />}</div>
              </div>
            </DropdownMenuItem>
          )}
          {props.items?.map((item) => (
            <DropdownMenuItem
              key={String(item.value)}
              onClick={(e) => {
                e.preventDefault()
                handleSelect(item)
              }}
            >
              <div className="flex items-center justify-between gap-2 w-full">
                <div className="flex items-center gap-2">
                  {item.icon}
                  {item.label}
                </div>
                <div>{tempSelected.includes(item.value) && <BsCheck />}</div>
              </div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
