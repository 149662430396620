import { BsSearch } from 'react-icons/bs'
import styled from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { FilterStore } from 'components/Filters/FilterStore'

export function TableSearch({
  searchPlaceholder,
  filterStore,
  className,
}: {
  searchPlaceholder: string
  filterStore: FilterStore
  className?: string
}) {
  const { search: searchFilter, setSearch } = filterStore()
  const search = searchFilter?.['search'] ?? { value: '', label: 'Search' }

  const [internalSearch, setInternalSearch] = useState(search)
  const [debouncedSearch] = useDebounce(internalSearch, 1000)

  useEffect(() => {
    if (internalSearch === search) return
    setSearch('search', internalSearch.value, 'Search')
  }, [debouncedSearch])

  function onChange(e: any) {
    setInternalSearch({ value: e.target.value as string, label: 'Search' })
  }

  return (
    <div style={{ position: 'relative' }} className={className}>
      <IconContainer>
        <BsSearch />
      </IconContainer>
      <Input
        type="text"
        value={internalSearch.value}
        onChange={onChange}
        placeholder={searchPlaceholder}
        className="form-control"
        style={{ width: '100%' }}
      />
    </div>
  )
}

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background: transparent;
  padding-left: 10px;
`

export const Input = styled.input`
  display: flex;
  align-items: center;
  padding: 8px 14px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  gap: 10px;
  width: 320px;
  padding-left: 30px;
  height: 34px;
`
