import { Spinner } from 'react-bootstrap'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import * as S from './styles'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { BootstrapInput } from '../../../components/FormUtils/NumericInput'
import dialogConfirm from 'components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import { handleFormError } from '../../../utils/handleFormError'
import to from 'await-to-js'
import FbButton from '../../../components/FbUI/FbButton'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { Modal } from '../../../components/UI/Modal/Modal'
import { TextareaInput } from 'components/FormUtils/TextareaInput'
import { OverflownText } from 'components/OverflownText'
import { CompanyDistributor } from 'models/companies'
import { toast } from 'react-toastify'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'

const defaultValuesDistributor = {
  name: '',
  description: '',
  url: '',
}

export function DistributorTable() {
  const api = apiService()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedDistributorID, setSelectedDistributorID] = useState<number>()

  const {
    data: distributorsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['distributors'],
    queryFn: async () => {
      return await api.getDistributors({
        params: {
          is_default: false,
        },
      })
    },
  })

  const data = distributorsData?.sort(
    // Sort by is_default first, then by name
    (a, b) =>
      (b.is_default ? 1 : -1) - (a.is_default ? 1 : -1) ||
      a.name.localeCompare(b.name)
  )

  const {
    control: control,
    handleSubmit: handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm({
    defaultValues: defaultValuesDistributor,
    mode: 'onSubmit',
  })

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset(defaultValuesDistributor)
    setSelectedDistributorID(undefined)
  }

  const onSubmitCreate = handleSubmit(async (values) => {
    const [err] = await to(
      api.postDistributor({
        name: values.name,
        description: values.description,
        url: values.url,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()
    queryClient.invalidateQueries({ queryKey: ['distributors'] })
    toast.success('Distributor created successfully')
    hideModal()
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    if (!selectedDistributorID) return

    const [err] = await to(
      api.updateDistributor(selectedDistributorID, {
        name: values.name,
        description: values.description,
        url: values.url,
      })
    )
    if (err) handleFormError(err, setError)

    await refetch()
    queryClient.invalidateQueries({ queryKey: ['distributors'] })
    toast.success('Distributor updated successfully')
    hideModal()
  })

  async function handleRowDelete(index: number) {
    const id = data?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this distributor?',
        'Delete Distributor'
      )
    ) {
      await api.deleteDistributor(id)
      void refetch()
      queryClient.invalidateQueries({ queryKey: ['distributors'] })
      toast.success('Distributor deleted successfully')
    }
  }

  function handleRowEdit(index: number) {
    setShowModal(true)
    setSelectedDistributorID(data?.[index]?.id)
    if (data) reset(data?.[index])
  }

  const columns = useMemo<ColumnDef<CompanyDistributor, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          return <OverflownText maxLines={2}>{value}</OverflownText>
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        meta: { rightAlign: true },
        cell: ({ row }) => {
          if (row.original.is_default)
            return (
              <div
                className="flex justify-end"
                style={{
                  fontSize: 11,
                  textTransform: 'uppercase',
                  color: '#71717A',
                }}
              >
                Default
              </div>
            )
          return (
            <ActionButtons
              className={'justify-end pr-0'}
              onDelete={() => handleRowDelete(row.index)}
              onEdit={() => handleRowEdit(row.index)}
            />
          )
        },
        size: 70,
      },
    ],
    [data]
  )

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <S.SettingsTableHeaderContainer
        style={{
          height: '60px',
        }}
      >
        <S.TableDescriptionText>
          You can create a new distributor or edit an existing one.
        </S.TableDescriptionText>
        <FbButton
          style={{ width: 200, marginTop: 8 }}
          onClick={() => openModal()}
        >
          Create Distributor
        </FbButton>
      </S.SettingsTableHeaderContainer>
      <S.SettingsTableContainer
        style={{
          height: 'calc(100vh - 275px)',
        }}
      >
        <ClientDataTable data={data} columns={columns} />
        <Modal
          open={showModal}
          title={
            selectedDistributorID
              ? 'Edit Distributor'
              : 'Create New Distributor'
          }
          description={
            selectedDistributorID ? (
              <>
                Edit your distributor to reflect your current process and
                performance.
              </>
            ) : (
              <>
                Create a distributor to reflect your current process and its
                performance.
              </>
            )
          }
          onOpenChange={(open) => !open && hideModal()}
          loading={isSubmitting}
          blockAccept={!isValid}
          onAccept={selectedDistributorID ? onSubmitUpdate : onSubmitCreate}
          acceptButtonText={selectedDistributorID ? 'Update' : 'Create'}
        >
          <BootstrapInput
            name="name"
            control={control}
            type="text"
            label="Name"
            placeholder="Distributor Name"
          />
          <BootstrapInput
            name="url"
            control={control}
            type="text"
            label="URL"
            placeholder="URL"
          />
          <TextareaInput
            name="description"
            control={control}
            type="textarea"
            label="Description"
            placeholder="Description"
          />
        </Modal>
      </S.SettingsTableContainer>
    </>
  )
}
