import { create } from 'zustand'

interface NavbarStoreState {
  lastOppVisited: string
  setLastOppVisited: (url: string) => void
  lastContactVisited: string
  setLastContactVisited: (url: string) => void
  lastActivityVisited: string
  setLastActivityVisited: (url: string) => void
  lastSettingsVisited: string
  setLastSettingsVisited: (url: string) => void
}

export const useNavbarStore = create<NavbarStoreState>()((set) => ({
  lastOppVisited: 'opportunities/',
  setLastOppVisited: (by) => set((state) => ({ ...state, lastOppVisited: by })),
  lastContactVisited: 'contacts/',
  setLastContactVisited: (by) =>
    set((state) => ({ ...state, lastContactVisited: by })),
  lastActivityVisited: 'activity/',
  setLastActivityVisited: (by) =>
    set((state) => ({ ...state, lastActivityVisited: by })),
  lastSettingsVisited: 'settings/',
  setLastSettingsVisited: (by) =>
    set((state) => ({ ...state, lastSettingsVisited: by })),
}))
