import { BsPencil } from 'react-icons/bs'
import TableButton from './TableButton'
import { Dropdown, IDropdownItem } from 'components/UI/Dropdown/Dropdown'

export default function TableButtonAction({
  selectedRowsCount,
  items,
}: {
  selectedRowsCount: number
  items?: IDropdownItem[]
} & Partial<React.ComponentProps<typeof TableButton>>) {
  return (
    <Dropdown
      buttonIcon={<BsPencil size={20} />}
      buttonLabel="Actions"
      buttonVariant="secondary"
      items={items ?? []}
      menuLabel={`Apply to all ${selectedRowsCount} row${
        selectedRowsCount > 1 ? 's' : ''
      }`}
      disabled={selectedRowsCount === 0}
    />
  )
}
