import { cn } from './cn'

export interface IBlockProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Block(props: IBlockProps) {
  return (
    <div className={cn('py-8 px-5 bg-white rounded', props.className)}>
      {props.children}
    </div>
  )
}
