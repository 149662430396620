import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

export function OverflownText({
  children,
  maxLines = 1,
  ...props
}: {
  children: React.ReactNode
  maxLines?: number
} & React.HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => {
      if (!ref.current) return
      const lineHeight = parseFloat(getComputedStyle(ref.current!).lineHeight)
      setIsOverflown(ref.current!.scrollHeight > lineHeight * maxLines)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect() // clean up
  }, [])

  return (
    <Wrapper>
      <TruncatedText
        isOverflown={isOverflown}
        ref={ref}
        {...props}
        maxLines={maxLines}
      >
        {children}
      </TruncatedText>
    </Wrapper>
  )
}

const TruncatedText = styled.div<{
  isOverflown: boolean
  maxLines?: number
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;

  ${({ maxLines }) =>
    maxLines &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}

  ${({ isOverflown }) =>
    isOverflown &&
    `
    &:hover {
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;
        white-space: normal;
        -ms-word-break: break-all;
        word-break: break-word;
        vertical-align: center;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;

        -webkit-line-clamp: initial;

        max-width: 100%;
        width: 100%;
        z-index: 1;

        &:before {
            background-color: #f7f7f7;
            border: 1px solid #ddd;
            content: "";
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 3px;
            position: absolute;
            z-index: -1; /* stack below truncated text */
        }
    }
    `}
`

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  &:hover {
    position: relative;
  }
`
