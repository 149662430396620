import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import apiService from 'services/api'
import s from 'styled-components'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdOutlineBarChart } from 'react-icons/md'
import { HiPencil } from 'react-icons/hi'
import { FaQuestion } from 'react-icons/fa'
import moment from 'moment'
import { DealHistory } from 'models/deals'
import { Product } from 'models/product'
import { StagePill } from 'components/FbUI/StagePill'
import { formatTwoDecimal, formatUsdDecimal } from 'utils/formatting'

const LoadingContainer = s.div`
    margin: 20px 0px;
    margin-left: calc(40vw + 5rem);
`

const ExpandedDealsContainer = s.div`
  padding: 24px 0px 24px 70px;
`

const DealContainer = s.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const RowIcon = s.span`
  width: 16px;
  height: 16px;
  color: #087443;
  margin-right: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`

const RowName = s.span`
  color: #171C26;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowAction = s.span`
  color: #464F60;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowType = s.span`
  color: #087443;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowDate = s.span`
  color: #687182;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const VerticalLine = s.div`
  width: 2px;
  height: 16px;
  background-color: #D5DBE5;
  margin: 8px 0px;
  margin-left: 8px;
`

const ResultContainer = s.span`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`

const PillResultContainer = s.span`
  margin: 0 5px;
`

const ResultToText = s.span`
  font-weight: 700;
  margin: 0 4px;
`

interface ExpandedRowProps {
  dealId: number
  product: Product
}

const ExpandedDealsRow = (props: ExpandedRowProps) => {
  const api = apiService()
  const { data: data, isLoading: loading } = api.useGetDealHistory({
    dealId: props.dealId,
  })
  const { data: stageOptions, isLoading: loadingSales } =
    api.useGetSalesStageOptions(true)

  const dataRow = useMemo(() => {
    const parsedData =
      data?.map((row: DealHistory) => {
        const newRow = { ...row }
        newRow.changes = JSON.parse(row.changes)
        return newRow
      }) || []

    const actionMap: Record<number, string> = {
      0: 'create',
      1: 'update',
      2: 'delete',
    }

    const newData = parsedData.map((row: DealHistory) => {
      const newRow = {} as any
      newRow.userName = row?.user?.first_name + ' ' + row?.user?.last_name
      newRow.date = row?.timestamp
      if (actionMap[row.action] === 'create') {
        newRow.action = 'create'
        newRow.type = props.product?.name + ' deal'
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('sales_stage' in row?.changes) {
        newRow.action = 'change'
        newRow.resultFrom = row?.changes.sales_stage[0]
        newRow.resultTo = row?.changes.sales_stage[1]
        newRow.type = 'sales stage'
        if (
          row?.changes.sales_stage[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('note' in row?.changes) {
        newRow.action = 'update'
        newRow.resultFrom = row?.changes.note[0]
        newRow.resultTo = row?.changes.note[1]
        newRow.type = 'note'
        if (
          row?.changes.note[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('account_owner' in row?.changes) {
        newRow.action = 'update'
        newRow.resultFrom = row?.changes.account_owner[0].replace(
          /\(.*?\)/g,
          ''
        )
        newRow.resultTo = row?.changes.account_owner[1].replace(/\(.*?\)/g, '')
        newRow.type = 'account owner'
        if (
          row?.changes.account_owner[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('monthly_volume_override' in row?.changes) {
        newRow.action = 'update'
        newRow.resultFrom = /[0-9]*\.?[0-9]+/.test(
          row?.changes.monthly_volume_override[0]
        )
          ? formatTwoDecimal(row?.changes.monthly_volume_override[0] ?? 0) +
            ' lbs'
          : row?.changes.monthly_volume_override[0]
        newRow.resultTo = /[0-9]*\.?[0-9]+/.test(
          row?.changes.monthly_volume_override[1]
        )
          ? formatTwoDecimal(row?.changes.monthly_volume_override[1] ?? 0) +
            ' lbs'
          : row?.changes.monthly_volume_override[1]
        newRow.type = 'monthly volume'
        if (
          row?.changes.monthly_volume_override[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('monthly_revenue_override' in row?.changes) {
        newRow.action = 'update'
        newRow.resultFrom = /[0-9]*\.?[0-9]+/.test(
          row?.changes.monthly_revenue_override[0]
        )
          ? formatUsdDecimal(row?.changes.monthly_revenue_override[0])
          : row?.changes.monthly_revenue_override[0]
        newRow.resultTo = /[0-9]*\.?[0-9]+/.test(
          row?.changes.monthly_revenue_override[1]
        )
          ? formatUsdDecimal(row?.changes.monthly_revenue_override[1])
          : row?.changes.monthly_revenue_override[1]
        newRow.type = 'monthly revenue'
        if (
          row?.changes.monthly_revenue_override[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
      } else if ('close_date_override' in row?.changes) {
        newRow.action = 'update'
        newRow.resultFrom = row?.changes.close_date_override[0]
        newRow.resultTo = row?.changes.close_date_override[1]
        newRow.type = 'close date'
        if (
          row?.changes.close_date_override[1] ===
          'This deal was created from a migrated chainnote'
        ) {
          newRow.userName = 'System'
        }
        if (moment(newRow.resultFrom).isValid()) {
          newRow.resultFrom = moment(newRow.resultFrom).format('MMM D, YYYY')
        }
        if (moment(newRow.resultTo).isValid()) {
          newRow.resultTo = moment(newRow.resultTo).format('MMM D, YYYY')
        }
      }
      return newRow
    })

    return newData.sort((a, b) => moment(b.date).diff(moment(a.date)))
  }, [data, loading])

  const returnIconRow = (row: any) => {
    switch (row?.action) {
      case 'create':
        return <AiOutlinePlus size={18} />
      case 'update':
        return <HiPencil size={18} />
      case 'change':
        return <MdOutlineBarChart size={18} />
      default:
        return <FaQuestion size={18} />
    }
  }

  const returnActionString = (row: any) => {
    switch (row?.action) {
      case 'create':
        return 'created'
      case 'update':
        return 'updated'
      case 'change':
        return 'changed'
      default:
        return ''
    }
  }

  const returnResultString = (row: any) => {
    let newResultFrom = row?.resultFrom
    let newResultTo = <ResultToText>{row?.resultTo}</ResultToText>

    if (row?.type === 'sales stage') {
      if (loadingSales) {
        newResultFrom = <ResultToText>{row?.resultFrom}</ResultToText>
        newResultTo = <ResultToText>{row?.resultTo}</ResultToText>
      } else {
        const stageFrom = stageOptions?.find(
          (stage) => stage?.original?.stage === row?.resultFrom
        )
        const stageTo = stageOptions?.find(
          (stage) => stage?.original?.stage === row?.resultTo
        )
        newResultFrom = (
          <PillResultContainer>
            <StagePill stageId={stageFrom?.value} />
          </PillResultContainer>
        )
        newResultTo = (
          <PillResultContainer>
            <StagePill stageId={stageTo?.value} />
          </PillResultContainer>
        )
      }
    }

    if (row?.resultTo === 'This deal was created from a migrated chainnote') {
      return
    } else if (row?.resultFrom && row?.resultTo) {
      return (
        <ResultContainer>
          from {newResultFrom} to {newResultTo}
        </ResultContainer>
      )
    } else if (!row?.resultFrom && row?.resultTo) {
      return <ResultContainer>to {newResultTo}</ResultContainer>
    } else {
      return
    }
  }

  if (loading)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )

  return (
    <ExpandedDealsContainer>
      {dataRow.map((row: any, index: number) => {
        return (
          <div key={index}>
            <DealContainer>
              <RowIcon>{returnIconRow(row)}</RowIcon>
              <RowName>
                {row?.userName ? row?.userName : '(Name not found)'}
              </RowName>
              <RowAction>{returnActionString(row)}</RowAction>
              <RowType>{row?.type ? row?.type : '(Type not found)'}</RowType>
              {returnResultString(row) && (
                <RowAction>{returnResultString(row)}</RowAction>
              )}
              <RowDate>
                {row?.date
                  ? 'on ' + moment(row?.date).format('MMM DD YYYY @ h:mm A')
                  : '(Date not found)'}
              </RowDate>
            </DealContainer>
            {index !== dataRow.length - 1 && <VerticalLine />}
          </div>
        )
      })}
    </ExpandedDealsContainer>
  )
}

export default ExpandedDealsRow
