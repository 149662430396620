import { MdClose } from 'react-icons/md'
import styled from 'styled-components/macro'

export const FilterChipContainer = styled.span<{
  isExclusive?: boolean
}>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #087443;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;

  border: 1px solid rgba(71, 84, 103, 0);
  background: rgba(8, 116, 67, 0.1);

  ${({ isExclusive }) =>
    isExclusive &&
    `
      background-color: rgba(228, 76, 76, 0.10);
      color: #E44C4C;
      `}
`

export const FilterChipCloseButton = styled(MdClose)<{
  isExclusive?: boolean
}>`
  margin-left: 8px;
  color: ${(props) => (props.isExclusive ? '#E44C4C' : '#087443')};
  font-size: 18px;
  cursor: pointer;
  line-height: 18px;
`
