import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
`

export const Button = styled.button<{
  isOpen: boolean
}>`
  color: #5a755e;
  display: flex;
  background-color: ${(props) => (props.isOpen ? '#D0D5DD' : 'white')};
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  width: 100%;
  margin-right: 4px;
  font-size: 12px;
  height: 40px;
  min-width: 200px;

  & span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;

    & strong {
      font-weight: 500;
    }
  }
`

export const DropdownContainer = styled.div<{
  isOpen: boolean
}>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  min-width: 100%;
  max-height: 400px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`

export const OptionsScroll = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
`

export const OptionsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Option = styled.div<{
  isSelected?: boolean
}>`
  width: 100%;

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;

  background-color: ${(props) => (props.isSelected ? '#D0D5DD' : 'unset')};

  &:hover {
    background-color: #cccbcb;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 8px 0;
`
