import { useEffect, useRef, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components/macro'

export function OverflownTextTooltip({
  children,
  maxLines = 1,
  tooltipText,
  placement = 'top',
  ...props
}: {
  children: React.ReactNode
  maxLines?: number
  tooltipText: string
  placement?: 'top' | 'right' | 'bottom' | 'left'
} & React.HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const checkOverflow = () => {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight)
      setIsOverflown(element.scrollHeight > lineHeight * maxLines)
    }

    checkOverflow()

    const resizeObserver = new ResizeObserver(checkOverflow)
    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [maxLines])

  const tooltip = (
    // Tag it as position fixed !important to override the tooltip's default position: absolute
    <TooltipFixed>{tooltipText}</TooltipFixed>
  )

  return (
    <Wrapper>
      <OverlayTrigger
        placement={placement}
        overlay={isOverflown ? tooltip : <></>}
      >
        <TruncatedText ref={ref} {...props} maxLines={maxLines}>
          {children}
        </TruncatedText>
      </OverlayTrigger>
    </Wrapper>
  )
}

const TooltipFixed = styled(Tooltip)`
  position: fixed !important;
`

const TruncatedText = styled.span<{
  maxLines?: number
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;

  ${({ maxLines }) =>
    maxLines &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
`
