import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSimpleTabSelector = <T extends number | string>(
  defaultValue: T,
  paramName: string = 'tab',
  paramsToKeep: string[] = [],
  paramPosition: number = 0
) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab: T = useMemo(() => {
    if (typeof defaultValue === 'number') {
      const tab = parseInt(searchParams.get(paramName) ?? '')
      if (!isNaN(tab)) {
        return tab as T
      }
    }
    return (searchParams.get(paramName) as T) ?? defaultValue
  }, [searchParams])

  const setSelectedTab = useCallback(
    (tab: T, { keepAllParams = false }: { keepAllParams?: boolean } = {}) => {
      setSearchParams((prev) => {
        let params: Record<string, string> = {}
        if (keepAllParams) {
          params = Object.fromEntries(prev.entries())
        } else {
          for (const param of paramsToKeep) {
            const paramVal = prev.get(param)
            if (paramVal) {
              params[param] = paramVal
            }
          }
        }
        params[paramName] = tab.toString()
        // Put paramName at paramPosition on ordered
        const ordered: Record<string, string> = {}
        const keys = Object.keys(params)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          if (i === paramPosition) {
            ordered[paramName] = params[paramName]
          }
          if (key !== paramName) {
            ordered[key] = params[key]
          }
        }

        return new URLSearchParams({ ...ordered })
      })
    },
    [searchParams, setSearchParams]
  )

  return [selectedTab, setSelectedTab] as const
}
