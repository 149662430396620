import styled from 'styled-components/macro'

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #f7f8f9;
  border-radius: 4px;
  padding: 8px 22px;
  border: 1px solid #e7eaee;
  border-bottom: none;

  margin-bottom: -10px;
  padding-bottom: 18px;
`

export const SearchContainerFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export const TableSearchContainer = styled.div`
  flex: 1 1 0%;
  max-width: 500px;
  width: 100%;
`

export const TableButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`

export const CardTitle = styled.div`
  color: #344054;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`
