import styled from 'styled-components/macro'
import { Link, useLocation } from 'react-router-dom'

const NavigationMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  margin-right: auto;
  gap: 5px;
`

const NavigationMenuItem = styled(Link)<{
  $isActive?: boolean
  $activeColor?: string
}>`
  width: 118px;
  height: 45px;
  font-weight: ${({ $isActive }) => ($isActive ? '500' : '300')};
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.05s ease-in-out;

  &:hover {
    background: rgb(44, 107, 78);
    text-decoration: none;
    color: #f2f4f7;
  }
`

export const NavMenu = ({
  items,
  options,
}: {
  items: {
    label: string
    to: string
    onClick?: () => void
  }[]
  options?: {
    activeColor?: string
  }
}) => {
  const location = useLocation()

  return (
    <NavigationMenu>
      {items.map(({ label, to, onClick }, i) => {
        const firstPathPart = location.pathname.split('/')[1] || ''
        return (
          <NavigationMenuItem
            $isActive={to.replace('/', '').startsWith(`${firstPathPart}`)}
            $activeColor={options?.activeColor || '#148552'}
            to={to}
            replace={false}
            onClick={onClick || (() => {})}
            key={i}
          >
            {label}
          </NavigationMenuItem>
        )
      })}
    </NavigationMenu>
  )
}
