import { CellEventFormatterProps } from '../../utils'
import { useMemo } from 'react'
import { DefaultAddOrRemoveEvent } from './DefaultAddOrRemoveEvent'
import apiService from 'services/api'
import { Tag } from 'models/tags'
import { CompanyDistributor } from 'models/companies'

export const CompanyAddOrRemoveEvent = ({ event }: CellEventFormatterProps) => {
  const actionType = event.action_type === 'ADD' ? 'added' : 'removed'
  if (event.field_name === 'taglist') {
    return <CompanyAddOrRemoveTagsEvent event={event} actionType={actionType} />
  }
  if (event.field_name === 'contact_tags') {
    return (
      <CompanyAddOrRemoveContactTagsEvent
        event={event}
        actionType={actionType}
      />
    )
  }
  if (event.field_name === 'distributors') {
    return (
      <CompanyAddOrRemoveDistributorsEvent
        event={event}
        actionType={actionType}
      />
    )
  }
}

const CompanyAddOrRemoveTagsEvent = ({
  event,
  actionType,
}: CellEventFormatterProps & { actionType: string }) => {
  const api = apiService()
  const { data: tags } = api.useGetTagsOptions()

  const tagsMap = useMemo(() => {
    if (!tags) return {}

    return tags.reduce((acc: Record<string, Tag>, curr) => {
      if (!curr.original) return acc
      acc[curr.original.id] = curr.original
      return acc
    }, {})
  }, [tags])

  const getTags = (tagsIds: number[]) => {
    if (!Object.keys(tagsMap).length) return ''

    const displayTagName = (tagId: number) =>
      tagsMap[tagId]?.tag ?? `[Tag deleted]`

    const tags = tagsIds.map(displayTagName)
    return tags.filter((tag) => tag !== '').join(', ')
  }

  return (
    <DefaultAddOrRemoveEvent
      action={actionType}
      fieldName="tags"
      values={getTags(event.value)}
    />
  )
}

const CompanyAddOrRemoveContactTagsEvent = ({
  event,
  actionType,
}: CellEventFormatterProps & { actionType: string }) => {
  const api = apiService()
  const { data: tags } = api.useGetTagsOptions(false, 'contacts')

  const tagsMap = useMemo(() => {
    if (!tags) return {}

    return tags.reduce((acc: Record<string, Tag>, curr) => {
      if (!curr.original) return acc
      acc[curr.original.id] = curr.original
      return acc
    }, {})
  }, [tags])

  const getTags = (tagsIds: number[]) => {
    if (!Object.keys(tagsMap).length) return ''

    const displayTagName = (tagId: number) =>
      tagsMap[tagId]?.tag ?? `[Label deleted]`

    const tags = tagsIds.map(displayTagName)
    return tags.filter((tag) => tag !== '').join(', ')
  }

  return (
    <DefaultAddOrRemoveEvent
      action={actionType}
      fieldName="contact labels"
      values={getTags(event.value)}
    />
  )
}

const CompanyAddOrRemoveDistributorsEvent = ({
  event,
  actionType,
}: CellEventFormatterProps & { actionType: string }) => {
  const api = apiService()
  const { data: distributors } = api.useGetCompanyDistributorsOptions()
  const distributorsMap = useMemo(() => {
    if (!distributors) return {}
    return distributors.reduce(
      (acc: Record<string, CompanyDistributor>, curr) => {
        acc[curr.original.id] = curr.original
        return acc
      },
      {}
    )
  }, [distributors])

  const getDistributors = (distributorsIds: number[]) => {
    if (!Object.keys(distributorsMap).length) return ''
    return distributorsIds
      .map((distributorId) => distributorsMap[distributorId]?.name)
      .join(', ')
  }

  return (
    <DefaultAddOrRemoveEvent
      action={actionType}
      fieldName="distributors"
      values={getDistributors(event.value)}
    />
  )
}
