import { DealsTable } from 'components/Tables/DealsTable/DealsTable'
import { useMemo, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { IContactCompany } from '../../../models/contact_companies'
import { queryClient } from '../../../services/queryClient'
import FbButton from '../../FbUI/FbButton'
import { CollapsibleSection } from '../../UI/CollapsibleSection/CollapsibleSection'
import CreateDealModal from '../CreateDealModal/CreateDealModal'

export default function DealsSection({
  forCompany,
  forChainId,
  pagination,
}: {
  forCompany?: Partial<IContactCompany>
  forChainId?: number
  pagination?: boolean
}) {
  const [showNewDeal, setShowNewDealModal] = useState(false)
  const [dealsCount, setDealsCount] = useState(0)

  const TABLE_KEY = `company-deals-table-${forCompany?.id}`

  const noChainSet = useMemo(() => {
    return (!!forCompany || !!forChainId) && !forCompany?.chain && !forChainId
  }, [forChainId, forCompany])

  return (
    <CollapsibleSection
      forceClose={dealsCount < 1}
      summary={
        <div className={'flex justify-between items-center w-full'}>
          <div>
            Deals <span className={'font-thin'}>({dealsCount})</span>
          </div>
          <FbButton onClick={() => setShowNewDealModal(true)}>
            <FaPlus size={14} /> Add Deals
          </FbButton>
        </div>
      }
    >
      {showNewDeal && (
        <CreateDealModal
          origin="deals-section"
          show={showNewDeal}
          isUserCompany={noChainSet}
          handleClose={() => setShowNewDealModal(false)}
          chainsIds={forChainId ? [forChainId] : undefined}
          companyIds={forCompany ? [forCompany.id!] : undefined}
          onDealCreated={() => {
            queryClient.refetchQueries({ queryKey: [TABLE_KEY] })
          }}
        />
      )}

      <DealsTable
        tableKey={TABLE_KEY}
        forChainId={forChainId}
        forCompany={forCompany}
        setTotalRowsCount={setDealsCount}
        enablePagination={pagination}
        disableUrlPagination
        showCompanyColumn={false}
      />
    </CollapsibleSection>
  )
}
