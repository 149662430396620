import { CompanyDistributor } from 'models/companies'
import { useMemo } from 'react'
import apiService from 'services/api'
import styled from 'styled-components/macro'
import { Campaign } from '../../models/campaign'
import { Deal } from '../../models/deals'
import { Tag } from '../../models/tags'
import { TAG_COLORS } from '../../utils/constants'
import getStageColor from '../../utils/getStageColor'
import { ConditionalTooltipWrapper } from './ConditionalTooltipWrapper'
import { TextPill } from './TextPill'

export function StagePill({ stageId }: { stageId?: number }) {
  const api = apiService()
  const { data } = api.useGetSalesStages()
  if (!stageId) return null
  const stage = data?.find((it) => it.id === stageId)

  if (!stage) return null
  const colors = getStageColor(stage?.win_rate)
  return (
    <TextPill
      style={{
        marginBottom: 2,
      }}
      textColor={colors[1]}
      backgroundColor={colors[0]}
    >
      {stage.stage}
    </TextPill>
  )
}

export function SaleStagesCellFromDeals({
  deals,
  sortAsc,
}: {
  deals?: Deal[] | null
  sortAsc?: boolean
}) {
  if (!deals) return <>-</>

  const stages = deals.reduce((prev, current) => {
    if (!prev.includes(current.sales_stage) && !!current.sales_stage) {
      prev.push(current.sales_stage)
    }
    return prev
  }, [] as number[])

  if (stages.length === 0) return <>-</>

  return <SalesStageCell salesStagesIds={stages} sortAsc={sortAsc} />
}

export function SalesStageCell({
  salesStagesIds,
  sortAsc,
}: {
  salesStagesIds: number[]
  sortAsc?: boolean
}) {
  const api = apiService()
  const { data } = api.useGetSalesStages()
  const salesStagesToShow = useMemo(() => {
    const stages = data?.filter((it) => salesStagesIds.includes(it.id))
    if (sortAsc === true) {
      return stages?.sort((a, b) => a.stage.localeCompare(b.stage)) || []
    } else if (sortAsc === false) {
      return stages?.sort((a, b) => b.stage.localeCompare(a.stage)) || []
    }
    return stages || []
  }, [data, salesStagesIds])

  const salesStagesNames = useMemo(
    () => salesStagesToShow?.map((it) => it.stage),
    [salesStagesToShow]
  )
  return (
    <ConditionalTooltipWrapper
      shouldWrap={salesStagesIds.length > 2}
      tooltipText={salesStagesNames?.join(', ')}
    >
      <PillContainer>
        {salesStagesToShow?.slice(0, 2).map((stage) => {
          return <StagePill key={stage.id} stageId={stage.id} />
        })}
        {salesStagesToShow.length > 2 ? (
          <span>+{salesStagesToShow.length - 2}</span>
        ) : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export function ContactTagPill({
  contact_tag_id,
}: {
  contact_tag_id?: number
}) {
  const api = apiService()
  const { data } = api.useGetTags('contacts')
  if (!contact_tag_id) return null
  const contact_tag = data?.find((it) => it.id === contact_tag_id)
  if (!contact_tag) return null
  return (
    <TextPill textColor={'#101828'} backgroundColor={'#F9F9FB'}>
      {contact_tag.tag}
    </TextPill>
  )
}

export function ContactTagCell({ deals }: { deals?: Deal[] | null }) {
  if (!deals) return <>-</>

  const tags = deals.reduce((prev, current) => {
    prev.push(...current.contact_tags)
    return prev
  }, [] as number[])

  if (tags.length === 0) return <>-</>

  return (
    <ConditionalTooltipWrapper shouldWrap={tags.length > 2}>
      <PillContainer>
        {tags
          ?.slice(0, 2)
          ?.map((id) => <ContactTagPill key={id} contact_tag_id={id} />)}
        {tags.length > 2 ? <span>+{tags.length - 2}</span> : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export function AccountOwnerPill({
  account_owner_id,
}: {
  account_owner_id?: number
}) {
  const api = apiService()
  const { data } = api.useGetUsers()
  if (!account_owner_id) return null
  const accOwner = data?.find((it) => it.id === account_owner_id)
  if (!accOwner) return null
  const name = `${accOwner.first_name} ${accOwner.last_name}`
  return (
    <TextPill textColor={'#101828'} backgroundColor={'#F9F9FB'}>
      {name}
    </TextPill>
  )
}

export function AccountOwnerCell({ deals }: { deals?: Deal[] | null }) {
  if (!deals) return <>-</>

  const accountOwners = deals.reduce((prev, current) => {
    if (!prev.includes(current.account_owner) && !!current.account_owner) {
      prev.push(current.account_owner)
    }
    return prev
  }, [] as number[])

  if (accountOwners.length === 0) return <>-</>

  const sortedAccountOwners = useMemo(() => {
    return accountOwners.sort((a, b) => a - b)
  }, [accountOwners])

  return (
    <ConditionalTooltipWrapper
      shouldWrap={sortedAccountOwners.length > 2}
      tooltipText={sortedAccountOwners.join(', ')}
    >
      <PillContainer>
        {sortedAccountOwners
          ?.slice(0, 2)
          ?.map((account_owner_id) => (
            <AccountOwnerPill
              key={account_owner_id}
              account_owner_id={account_owner_id}
            />
          ))}
        {sortedAccountOwners.length > 2 ? (
          <span>+{sortedAccountOwners.length - 2}</span>
        ) : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export function TaglistCell({
  taglist,
  asc = true,
}: {
  taglist?: Tag[] | null
  asc?: boolean
}) {
  if (!taglist || taglist.length === 0) return <>-</>

  const sortedTaglist = useMemo(() => {
    if (asc) return taglist.sort((a, b) => a.tag.localeCompare(b.tag))
    return taglist.sort((a, b) => b.tag.localeCompare(a.tag))
  }, [taglist])

  return (
    <ConditionalTooltipWrapper
      shouldWrap={sortedTaglist.length > 2}
      tooltipText={sortedTaglist.map((tag) => tag.tag).join(', ')}
    >
      <PillContainer>
        {sortedTaglist?.slice(0, 2)?.map((tag) => {
          const tagColor = tag.color
            ? TAG_COLORS.find((c) => c.background === tag.color)
            : TAG_COLORS[0]
          return (
            <TextPill
              key={tag.id}
              textColor={tagColor?.text || '#ffffff'}
              backgroundColor={tagColor?.background || '#7a7aff'}
            >
              {tag.tag}
            </TextPill>
          )
        })}
        {sortedTaglist.length > 2 ? (
          <span>+{sortedTaglist.length - 2}</span>
        ) : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export function DistributorsCell({
  distributors,
  asc,
}: {
  distributors?: CompanyDistributor[] | null
  asc?: boolean
}) {
  if (!distributors || distributors.length === 0) return <>-</>

  const sortedDistributors = useMemo(() => {
    return distributors.sort((a, b) => {
      if (asc) return a.name.localeCompare(b.name)
      return b.name.localeCompare(a.name)
    })
  }, [distributors, asc])

  return (
    <ConditionalTooltipWrapper
      shouldWrap={sortedDistributors.length > 2}
      tooltipText={sortedDistributors
        .map((distributor) => distributor.name)
        .join(', ')}
    >
      <PillContainer>
        {sortedDistributors?.slice(0, 2).map((distributor) => {
          return (
            <TextPill
              key={distributor.id}
              textColor={'#ffffff'}
              backgroundColor={'#7a7aff'}
            >
              {distributor.name}
            </TextPill>
          )
        })}
        {sortedDistributors.length > 2 ? (
          <span>+{sortedDistributors.length - 2}</span>
        ) : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export function CampaignsCell({
  campaigns,
  asc,
}: {
  campaigns?: Campaign[] | null
  asc?: boolean
}) {
  if (!campaigns || campaigns.length === 0) return <>-</>

  const sorted = useMemo(() => {
    return campaigns
      .filter((campaign) => campaign != null)
      .sort((a, b) => {
        if (asc) return a.name.localeCompare(b.name)
        return b.name.localeCompare(a.name)
      })
  }, [campaigns, asc])

  return (
    <ConditionalTooltipWrapper
      shouldWrap={sorted.length > 2}
      tooltipText={sorted.map((distributor) => distributor.name).join(', ')}
    >
      <PillContainer>
        {sorted?.slice(0, 2).map((it) => {
          const color = it.color
            ? TAG_COLORS.find((c) => c.background === it.color)
            : TAG_COLORS[0]
          return (
            <TextPill
              key={it.id}
              textColor={color?.text ?? '#ffffff'}
              backgroundColor={color?.background ?? '#64d07d'}
            >
              {it.name}
            </TextPill>
          )
        })}
        {sorted.length > 2 ? <span>+{sorted.length - 2}</span> : null}
      </PillContainer>
    </ConditionalTooltipWrapper>
  )
}

export const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
  padding: 4px;
`
