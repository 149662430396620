import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import type { FilterStore } from 'components/Filters/FilterStore'
import {
  DropdownMenu,
  DropdownMenuContent,
} from 'components/UI/BaseDropdown/dropdown-base'
import { TextInput } from 'components/FormUtils/TextInput/text-input'
import FbButton from 'components/FbUI/FbButton'
import { FilterButton } from '../components/FilterButton'
import { FilterCloseButton } from '../components/FilterCloseButton'

const zodObject = z
  .object({
    min: z.string().optional().nullable(),
    max: z.string().optional().nullable(),
  })
  .superRefine((v, ctx) => {
    if (v.min !== null && v.max !== null) {
      const min = new Date(v.min!)
      const max = new Date(v.max!)
      if (min > max) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Start date must be before end date',
          path: ['min'],
        })
      }
    }
  })

export function DateRangeFilter({
  title,
  filterKey,
  filterStore,
}: {
  title: string
  filterKey: string
  filterStore: FilterStore
}) {
  const [open, setOpen] = useState(false)
  const {
    pendingRangeFilters,
    setPendingRangeFilters,
    clearPendingRangeFilter,
    registerFilterTitle,
  } = filterStore()
  const current = pendingRangeFilters?.[filterKey!]

  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      min: current?.min || null,
      max: current?.max || null,
    },
    resolver: zodResolver(zodObject),
  })

  function saveFilters() {
    if (watch('min') !== null || watch('max') !== null) {
      setPendingRangeFilters(
        filterKey,
        watch('min') ?? undefined,
        watch('max') ?? undefined
      )
    }

    setOpen(false)
  }

  const clearFilters = () => {
    reset({
      min: null,
      max: null,
    })

    clearPendingRangeFilter(filterKey)
  }

  const _handleSubmit = handleSubmit(() => {
    saveFilters()
    setOpen(false)
  })

  const getLabel = (): string => {
    if (!isRangeSet) {
      return 'All'
    }

    // Safely handle undefined values by falling back to `null`
    const min = current?.min ?? null
    const max = current?.max ?? null

    if (min === null && max !== null) {
      return `≤${max}`
    }

    if (max === null && min !== null) {
      return `≥${min}`
    }

    return `${min} - ${max}`
  }

  const isRangeSet = current?.min != null || current?.max != null

  useEffect(() => {
    registerFilterTitle(filterKey, title)
  }, [])

  return (
    <DropdownMenu open={open} onOpenChange={(open) => setOpen(open)}>
      <div className={'relative'}>
        <DropdownMenuTrigger asChild>
          <FilterButton
            open={open}
            isActive={isRangeSet}
            title={title}
            activeValueLabel={getLabel()}
            onClick={() => setOpen(!open)}
          />
        </DropdownMenuTrigger>
        <FilterCloseButton
          isActive={isRangeSet}
          onClick={() => clearFilters()}
        />
      </div>

      <DropdownMenuContent>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
          className="mb-2"
        >
          <TextInput
            noMargin
            control={control}
            label="Start Date"
            name="min"
            type="date"
          />
          <TextInput
            noMargin
            control={control}
            label="End Date"
            name="max"
            type="date"
          />
        </div>

        <FbButton style={{ width: '100%' }} onClick={_handleSubmit}>
          Apply
        </FbButton>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
