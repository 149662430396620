import React, { useEffect, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import styled from 'styled-components'
import { getCompanyUrl } from '../../utils/getCompanyUrl'
import { FbLink } from '../FbUI/FbLink'

interface Company {
  id: number
  name: string
}

interface ExpandableCompaniesProps {
  companies: Company[]
  title: string
}

const MAX_LINES = 2

export const ExpandableCompanies: React.FC<ExpandableCompaniesProps> = ({
  companies,
  title,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const lineHeight = parseInt(
          getComputedStyle(containerRef.current).lineHeight
        )
        setIsOverflown(
          containerRef.current.scrollHeight > lineHeight * MAX_LINES
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [companies])

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <span className="font-bold">{title}</span>
        {isOverflown && (
          <ToggleButton
            onClick={handleToggle}
            className="ml-2 inline-flex items-center text-gray-600 focus:outline-none"
          >
            <span className="mr-1">{isExpanded ? 'Less' : 'More'}</span>
            {isExpanded ? <FaCaretUp size={14} /> : <FaCaretDown size={14} />}
          </ToggleButton>
        )}
      </TitleWrapper>
      <CompanyContainer ref={containerRef} isExpanded={isExpanded}>
        {companies.map((company, index) => (
          <React.Fragment key={company.id}>
            <FbLink
              to={`${getCompanyUrl(company)}`}
              target="_blank"
              rel="noreferrer"
            >
              {company.name}
            </FbLink>
            {index < companies.length - 1 && <span className="mx-1">,</span>}
          </React.Fragment>
        ))}
      </CompanyContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: start;
  align-content: center;
`

const CompanyContainer = styled.div<{ isExpanded: boolean }>`
  transition:
    max-height 0.7s ease-out,
    opacity 0.7s ease-out;
  overflow: hidden;
  opacity: 1;
  max-height: ${({ isExpanded }) =>
    isExpanded ? '1000px' : `${MAX_LINES * 1.5}em`};

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${MAX_LINES};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    opacity: 0.9;
  `}
`

const ToggleButton = styled.button`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`
