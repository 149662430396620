import { PaginationState } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const usePaginationURLParams = (
  PAGE_SIZE: number,
  paramName: string = 'page'
) => {
  const defaultPage = 0
  const [searchParams, setSearchParams] = useSearchParams()

  const pageIndex = useMemo(() => {
    let pageIndex = Number(searchParams.get(paramName))
    if (pageIndex > 0) {
      pageIndex -= 1
    }
    return pageIndex || defaultPage
  }, [searchParams])

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageIndex,
    pageSize: PAGE_SIZE,
  })

  useEffect(() => {
    if (pageIndex !== pagination.pageIndex) {
      setPagination((prev) => ({ ...prev, pageIndex }))
    }
  }, [pageIndex])

  useEffect(() => {
    if (pagination.pageIndex !== pageIndex) {
      setSearchParams((prev) => {
        if (pagination.pageIndex === defaultPage) {
          prev.delete(paramName)
          return prev
        }
        return new URLSearchParams({
          ...Object.fromEntries(prev),
          [paramName]: (pagination.pageIndex + 1).toString(),
        })
      })
    }
  }, [pagination.pageIndex])

  return [pagination, setPagination] as const
}
