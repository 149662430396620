import React from 'react'
import { FbLink } from 'components/FbUI/FbLink'
import * as S from './styles'

const NotFoundCard: React.FC = () => {
  const supportEmail = 'support@firstbite.io'

  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  const emailLink = () => (
    <FbLink to="#" onClick={handleEmailClick}>
      here
    </FbLink>
  )

  return (
    <S.Card>
      <S.Container>
        <S.Header>
          <S.Title>404</S.Title>
          <S.Hr />
        </S.Header>
        <S.Content>
          <S.Description>
            This page may have been moved, or is temporarily unavailable.
          </S.Description>
          <S.Legend>
            Let us know how you got here, and we&apos;ll look into this for you.
            Click {emailLink()} to send us an email.
          </S.Legend>
        </S.Content>
      </S.Container>
    </S.Card>
  )
}

export default NotFoundCard
