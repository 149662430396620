import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual'
import React, {
  CSSProperties,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Form, Spinner } from 'react-bootstrap'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components/macro'
import * as S from '../DataTable/DataTable/styles'
import { DownArrow, UpArrow } from '../DataTable/styles'
import { IndeterminateCheckbox } from '../IndeterminateCheckbox'
import { HelpTooltip } from '../Tooltip/HelpTooltip'
import { cn } from '../UI/cn'

export interface IClientDataTable<T> {
  columns: ColumnDef<T, any>[]
  data: T[]
  enableSorting?: boolean
  enableGlobalSearch?: boolean
  onTableBottomReached?: () => void
  stickyLastColumn?: boolean
  stickyFirstColumn?: boolean
  searchPlaceholder?: string
  enableRowSelection?: boolean
  onRowSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>
  selectedRows?: RowSelectionState
  height?: number | string
  style?: CSSProperties
  smallRows?: boolean
  footerContent?: React.ReactNode
  emptyContent?: React.ReactNode
  loading?: boolean
}

export function ClientDataTable<T>(props: IClientDataTable<T>) {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})

  const table = useReactTable({
    data: props.data ?? [],
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: props.enableSorting,
    enableGlobalFilter: props.enableGlobalSearch,
    onRowSelectionChange: props.onRowSelectionChange ?? setSelectedRows,
    state: {
      rowSelection: props.selectedRows ?? selectedRows,
    },
  })

  // Virtualization
  const tableContainerRef = useRef<HTMLTableElement>(null)
  const { rows: originalRows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: originalRows.length,
    getScrollElement: () => tableContainerRef.current || null,
    estimateSize: () => (props.smallRows ? 40 : 56),
    overscan: 2,
  })

  const virtualRows = virtualizer.getVirtualItems()

  const [before, after] = useMemo(() => {
    return virtualRows.length > 0
      ? [
          notUndefined(virtualRows[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(virtualRows[virtualRows.length - 1]).end,
        ]
      : [0, 0]
  }, [
    virtualRows,
    virtualizer.options.scrollMargin,
    virtualizer.getTotalSize(),
  ])

  function manageShadows(event?: React.UIEvent<HTMLDivElement, UIEvent>) {
    const {
      scrollHeight,
      scrollTop,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = event?.currentTarget ?? {
      scrollHeight: 0,
      scrollTop: 0,
      clientHeight: 0,
      scrollLeft: 1,
      scrollWidth: 0,
      clientWidth: 0,
    }
    //called once the user has scrolled 50px left
    if (scrollLeft > 0) {
      document
        .querySelectorAll('.shadowable-table-cell')
        .forEach((el) => el?.classList.add('shadow-table-cell'))
    } else {
      document
        .querySelectorAll('.shadowable-table-cell')
        .forEach((el) => el?.classList.remove('shadow-table-cell'))
    }

    if (scrollLeft !== scrollWidth - clientWidth) {
      document
        .querySelectorAll('.shadowable-table-cell-right')
        .forEach((el) => el?.classList.add('shadow-table-cell-right'))
    } else {
      document
        .querySelectorAll('.shadowable-table-cell-right')
        .forEach((el) => el?.classList.remove('shadow-table-cell-right'))
    }

    // called once the user has scrolled within 300px of the bottom of the table
    if (scrollHeight - scrollTop - clientHeight < 300) {
      if (props.onTableBottomReached) props.onTableBottomReached()
    }
  }

  useEffect(() => {
    manageShadows()
  }, [])

  // Bottom reached callback
  const handleScroll = (event?: React.UIEvent<HTMLDivElement, UIEvent>) => {
    manageShadows(event)
  }

  return (
    <div className={'flex flex-col w-full relative h-full'}>
      <LoadingOverlay show={props.loading}>
        <Spinner />
      </LoadingOverlay>

      {props.enableGlobalSearch && (
        <div className={'px-3 bg-gray-100 pt-3 pb-6 -mb-3 rounded'}>
          <Form.Control
            placeholder={props.searchPlaceholder ?? 'Search...'}
            onChange={(e) => table.setGlobalFilter(e.target.value)}
            value={table.getState().globalFilter}
          />
        </div>
      )}
      <div
        style={{
          border: '1px solid #eaecf0',
          borderRadius: 8,
          overflow: 'hidden',
          maxHeight: props.height || 480,
        }}
      >
        <SimpleBar
          style={{
            maxHeight: props.height || 480,
            ...props.style,
          }}
          autoHide={false}
          scrollableNodeProps={{
            ref: tableContainerRef,
            onScroll: handleScroll,
          }}
        >
          <S.Table>
            <S.THead>
              {table.getHeaderGroups().map((headerGroup) => {
                const filteredHeaderGroup = headerGroup.headers
                return (
                  <S.TRHead key={`headergroup_${headerGroup.id}`}>
                    {filteredHeaderGroup.map((header, headerIndx) => {
                      const sortingProps: Record<string, unknown> = {}

                      if (header.column.getCanSort()) {
                        sortingProps['onClick'] = () =>
                          header.column.toggleSorting()
                        sortingProps['style'] = { cursor: 'pointer' }
                      }

                      const tooltip = (header?.column.columnDef?.meta as any)
                        ?.tooltip

                      return (
                        <S.TH
                          className={cn({
                            'sticky-table-cell':
                              headerIndx === 0 && props.stickyFirstColumn,
                            'sticky-table-cell-right':
                              props.stickyLastColumn &&
                              headerIndx === headerGroup.headers.length - 1,
                            'shadowable-table-cell':
                              headerIndx === 0 && props.stickyFirstColumn,
                            'shadowable-table-cell-right':
                              props.stickyLastColumn &&
                              headerIndx === headerGroup.headers.length - 1,
                          })}
                          style={{
                            width: header.getSize(),
                            pointerEvents: props.data.length ? 'all' : 'none',
                          }}
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {!header.isPlaceholder && (
                            <div className={'flex items-center flex-nowrap'}>
                              {props.enableRowSelection && headerIndx === 0 && (
                                <IndeterminateCheckbox
                                  wrapperClassName={'mr-2'}
                                  {...{
                                    checked: table.getIsAllRowsSelected(),
                                    indeterminate:
                                      table.getIsSomeRowsSelected(),
                                    onChange:
                                      table.getToggleAllRowsSelectedHandler(),
                                  }}
                                />
                              )}

                              <S.HeadingText {...sortingProps}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </S.HeadingText>

                              {tooltip ? (
                                <div style={{ marginLeft: 10 }}>
                                  <HelpTooltip
                                    title={tooltip?.title}
                                    content={tooltip?.content}
                                    link={{
                                      text: tooltip?.link_text,
                                      href: tooltip?.link,
                                    }}
                                  />
                                </div>
                              ) : null}
                              <div {...sortingProps}>
                                {header.column.getCanSort() &&
                                  header.column.getIsSorted() &&
                                  (header.column.getIsSorted() === 'asc' ? (
                                    <DownArrow />
                                  ) : (
                                    <UpArrow />
                                  ))}
                              </div>
                            </div>
                          )}
                        </S.TH>
                      )
                    })}
                  </S.TRHead>
                )
              })}
            </S.THead>
            <S.TBody style={{ height: `${virtualizer.getTotalSize()}px` }}>
              {originalRows.length > 0 ? (
                <>
                  {before > 0 && (
                    <S.TR>
                      <S.TD style={{ height: `${before}px` }} />
                    </S.TR>
                  )}
                  {virtualRows.map((_row) => {
                    const row = originalRows[_row.index]
                    const cells = [...row.getVisibleCells()]
                    return (
                      <Fragment key={row.id}>
                        <S.TR
                          key={_row.key}
                          data-index={_row.index}
                          ref={virtualizer.measureElement}
                          style={{
                            height: `${props.smallRows ? 40 : 56}px`,
                          }}
                        >
                          {cells.map((cell, indx) => {
                            const meta = cell?.column.columnDef?.meta as any
                            const rightAlign = meta?.rightAlign
                            return (
                              <S.TD
                                className={cn({
                                  'sticky-table-cell':
                                    indx === 0 && props.stickyFirstColumn,
                                  'shadowable-table-cell':
                                    indx === 0 && props.stickyFirstColumn,
                                  'sticky-table-cell-right':
                                    props.stickyLastColumn &&
                                    indx === cells.length - 1,
                                  'shadowable-table-cell-right':
                                    props.stickyLastColumn &&
                                    indx === cells.length - 1,
                                  'text-right': rightAlign,
                                })}
                                isSelected={row.getIsSelected()}
                                key={cell.id}
                                style={{
                                  height: `${props.smallRows ? 40 : 56}px`,
                                }}
                              >
                                <div className={'flex items-center'}>
                                  {props.enableRowSelection && indx === 0 && (
                                    <IndeterminateCheckbox
                                      wrapperClassName={'mr-2'}
                                      {...{
                                        checked: row.getIsSelected(),
                                        disabled: !row.getCanSelect(),
                                        onChange:
                                          row.getToggleSelectedHandler(),
                                      }}
                                    />
                                  )}
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  ) || ' - '}
                                </div>
                              </S.TD>
                            )
                          })}
                        </S.TR>
                      </Fragment>
                    )
                  })}
                  {after > 0 && (
                    <S.TR>
                      <S.TD style={{ height: `${after}px` }} />
                    </S.TR>
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan={props.columns.length}>
                    <div
                      className={
                        'flex items-center justify-center w-full h-40 text-[15px] text-gray-400 select-none'
                      }
                    >
                      {props.emptyContent ?? 'No data to show.'}
                    </div>
                  </td>
                </tr>
              )}
            </S.TBody>
          </S.Table>
          {props?.footerContent && <S.TFooter>{props.footerContent}</S.TFooter>}
        </SimpleBar>
      </div>
    </div>
  )
}

const LoadingOverlay = styled.div<{
  show?: boolean
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 30;
`
