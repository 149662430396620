import { ZipCodeFilterState } from '../../FilterStore'
import { FilterChipCloseButton, FilterChipContainer } from './FilterChipStyles'

export type FilterZipRangeProps = {
  label: string
  active: ZipCodeFilterState
  onClose?: () => void
  isExclusive: boolean
}

export function FilterChipZipRange({
  label,
  active,
  onClose,
  isExclusive,
}: FilterZipRangeProps) {
  const getFormattedValue = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const zipcode = active?.[0]!
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const range = active?.[1]!
    return `${zipcode} (${range} mile${range > 1 ? 's' : ''})`
  }

  return (
    <FilterChipContainer isExclusive={isExclusive}>
      <span>{`${label}: ${getFormattedValue()}`}</span>
      {onClose && (
        <FilterChipCloseButton onClick={onClose} isExclusive={isExclusive} />
      )}
    </FilterChipContainer>
  )
}
