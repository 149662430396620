import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import apiService from '../../services/api'
import { NumericInput } from '../FormUtils/NumericInput'
import { Modal } from '../UI/Modal/Modal'
import React, { useState } from 'react'
import FbButton from '../FbUI/FbButton'
import { Switch } from '../UI/Switch/Switch'
import { BiChart } from 'react-icons/bi'

const formSchema = z.object({
  targetValue: z
    .number()
    .min(1, { message: 'Please enter a number greater than 1.' }),
  show: z.boolean(),
})

type FormSchema = z.infer<typeof formSchema>

export function SalesForecastTargetLineModal() {
  const [open, setOpen] = useState(false)
  const api = apiService()
  const { data: preferences } = api.useGetPreferences()
  const mutation = api.useChangeMiscSettings()
  const form = useForm<FormSchema>({
    defaultValues: {
      targetValue: parseFloat(
        preferences?.misc_settings.find(
          (it) => it.key === 'sales_forecast_target_line'
        )?.value
      ),
      show:
        preferences?.misc_settings.find(
          (it) => it.key === 'show_sales_forecast_line'
        )?.value ?? false,
    },
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const { handleSubmit, control, formState } = form

  const handleAccept = async (values: FormSchema) => {
    try {
      await mutation.mutateAsync({
        key: 'sales_forecast_target_line',
        value: values.targetValue,
      })

      await mutation.mutateAsync({
        key: 'show_sales_forecast_line',
        value: values.show,
      })

      setOpen(false)
    } catch (err) {
      toast.error(`Error Requesting Contacts: ${err}.`)
    }
  }

  return (
    <>
      <FbButton variant={'secondary'} onClick={() => setOpen(true)}>
        <BiChart /> Target Line
      </FbButton>
      <Modal
        open={open}
        onOpenChange={setOpen}
        title={'Add target line'}
        description={
          'Visually compare your sales forecast against a monthly revenue goal. You can change this value or choose to hide it at anytime.'
        }
        onAccept={handleSubmit(handleAccept)}
        acceptButtonText={'Apply'}
        blockAccept={!formState.isValid}
      >
        <NumericInput
          label={'Value'}
          control={control}
          fieldName={'targetValue'}
        />
        <Controller
          render={({ field }) => {
            return (
              <Switch
                type={'checkbox'}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked)
                }}
                labelRight={'Shown on graph'}
                className={'mt-3'}
              />
            )
          }}
          name={'show'}
          control={form.control}
        />
      </Modal>
    </>
  )
}
