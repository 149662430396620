import { Product } from 'models/product'
import { useMemo } from 'react'
import { create } from 'zustand'

interface SelectedProductState {
  selectedProducts: Product[]
}

interface SelectedProductActions {
  setSelectedProducts: (products: Product[]) => void
  toggleProduct: (product: Product) => void
}

export type SelectedProductStore = SelectedProductState & SelectedProductActions

export const useSelectedProductsStore = create<SelectedProductStore>()(
  (set) => ({
    selectedProducts: [],
    setSelectedProducts: (products) => set({ selectedProducts: products }),
    toggleProduct: (product) =>
      set((state) => {
        const isSelected = state.selectedProducts.some(
          (p) => p.id === product.id
        )
        return {
          selectedProducts: isSelected
            ? state.selectedProducts.filter((p) => p.id !== product.id)
            : [...state.selectedProducts, product],
        }
      }),
  })
)

export function useSelectedProductIds() {
  const selectedProducts = useSelectedProductsStore(
    (state) => state.selectedProducts
  )
  const selectedProductIds = useMemo(
    () => selectedProducts.map((p) => p.id),
    [selectedProducts]
  )
  return selectedProductIds
}
