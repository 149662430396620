import { Control } from 'react-hook-form'
import { Cuisine50Options } from 'models/cuisine_50'
import { TextInput } from 'components/FormUtils/TextInput'
import { Col, Row } from 'react-bootstrap'
import { Product } from '../../../models/product'

export function EstimatedSalesPotential({
  control,
}: {
  control: Control<Product['estimated_sales_potential']>
}) {
  return (
    <Row>
      <div>Estimated Lbs/Month of Typical Restaurant in Each Cuisine</div>
      {Cuisine50Options.map((cuisine) => {
        return (
          <Col key={cuisine.value} md={3}>
            <TextInput
              name={cuisine.value}
              control={control}
              type="number"
              label={cuisine.label}
              placeholder={cuisine.label}
            />
          </Col>
        )
      })}
    </Row>
  )
}
