import { CellEventFormatterProps, PillContainer } from '../../utils'
import { useMemo } from 'react'
import { DefaultUpdateEvent } from './DefaultUpdateEvent'
import apiService from 'services/api'
import { SalesStage } from 'models/sales_stages'
import { StagePill } from 'components/FbUI/StagePill'

export const DealUpdateEvent = ({ event }: CellEventFormatterProps) => {
  if (event.field_name === 'sales_stage_id') {
    return <DealUpdateSalesStageEvent event={event} />
  }
  if (event.field_name === 'account_owner_id') {
    return <DealUpdateAccountOwnerEvent event={event} />
  }

  return <DefaultUpdateEvent event={event} />
}

const DealUpdateSalesStageEvent = ({ event }: CellEventFormatterProps) => {
  const api = apiService()
  const { data: salesStages } = api.useGetSalesStageOptions()
  const salesStagesMap = useMemo(() => {
    if (!salesStages) return {}
    return salesStages.reduce((acc: Record<string, SalesStage>, curr) => {
      if (!curr.original) return acc
      acc[curr.original.id] = curr.original
      return acc
    }, {})
  }, [salesStages])

  const customFormatter = useMemo(() => {
    // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
    return (value: any) => {
      const stage = salesStagesMap[value]
      if (!stage) return undefined
      return (
        <PillContainer>
          <StagePill stageId={stage.id} />
        </PillContainer>
      )
    }
  }, [event.field_name, salesStagesMap])

  return (
    <DefaultUpdateEvent
      event={event}
      customFieldName="sales stage"
      customFormatter={customFormatter}
    />
  )
}

const DealUpdateAccountOwnerEvent = ({ event }: CellEventFormatterProps) => {
  const api = apiService()
  const { data: users } = api.useGetUserOptions()
  const usersMap = useMemo(() => {
    if (!users) return {}

    return users.reduce((acc: Record<string, any>, curr) => {
      if (!curr.original) return acc
      acc[curr.original.id] = curr.original
      return acc
    }, {})
  }, [users])

  const customFormatter = useMemo(() => {
    return (value: any) => {
      const user = usersMap[value]
      if (!user) return undefined
      return `${user.first_name} ${user.last_name}`
    }
  }, [event.field_name, usersMap])

  return (
    <DefaultUpdateEvent
      event={event}
      customFieldName="account owner"
      customFormatter={customFormatter}
    />
  )
}
