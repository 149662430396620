// Data Source: https://simplemaps.com/data/us-cities
export const US_CITIES = [
  { value: 'Aaronsburg_PA', label: 'Aaronsburg, PA' },
  { value: 'Abbeville_SC', label: 'Abbeville, SC' },
  { value: 'Abbeville_GA', label: 'Abbeville, GA' },
  { value: 'Abbeville_AL', label: 'Abbeville, AL' },
  { value: 'Abbeville_MS', label: 'Abbeville, MS' },
  { value: 'Abbeville_LA', label: 'Abbeville, LA' },
  { value: 'Abbot_ME', label: 'Abbot, ME' },
  { value: 'Abbotsford_WI', label: 'Abbotsford, WI' },
  { value: 'Abbott_TX', label: 'Abbott, TX' },
  { value: 'Abbottstown_PA', label: 'Abbottstown, PA' },
  { value: 'Abbyville_KS', label: 'Abbyville, KS' },
  { value: 'Abell_MD', label: 'Abell, MD' },
  { value: 'Abercrombie_ND', label: 'Abercrombie, ND' },
  { value: 'Aberdeen_MD', label: 'Aberdeen, MD' },
  { value: 'Aberdeen_NC', label: 'Aberdeen, NC' },
  { value: 'Aberdeen_MS', label: 'Aberdeen, MS' },
  { value: 'Aberdeen_OH', label: 'Aberdeen, OH' },
  { value: 'Aberdeen_SD', label: 'Aberdeen, SD' },
  { value: 'Aberdeen_ID', label: 'Aberdeen, ID' },
  { value: 'Aberdeen_WA', label: 'Aberdeen, WA' },
  { value: 'Aberdeen Proving Ground_MD', label: 'Aberdeen Proving Ground, MD' },
  { value: 'Abernathy_TX', label: 'Abernathy, TX' },
  { value: 'Abie_NE', label: 'Abie, NE' },
  { value: 'Abilene_KS', label: 'Abilene, KS' },
  { value: 'Abilene_TX', label: 'Abilene, TX' },
  { value: 'Abingdon_MD', label: 'Abingdon, MD' },
  { value: 'Abingdon_VA', label: 'Abingdon, VA' },
  { value: 'Abingdon_IL', label: 'Abingdon, IL' },
  { value: 'Abington_MA', label: 'Abington, MA' },
  { value: 'Abington_PA', label: 'Abington, PA' },
  { value: 'Abiquiu_NM', label: 'Abiquiu, NM' },
  { value: 'Abita Springs_LA', label: 'Abita Springs, LA' },
  { value: 'Abrams_WI', label: 'Abrams, WI' },
  { value: 'Absaraka_ND', label: 'Absaraka, ND' },
  { value: 'Absarokee_MT', label: 'Absarokee, MT' },
  { value: 'Absecon_NJ', label: 'Absecon, NJ' },
  { value: 'Acampo_CA', label: 'Acampo, CA' },
  { value: 'Accident_MD', label: 'Accident, MD' },
  { value: 'Accokeek_MD', label: 'Accokeek, MD' },
  { value: 'Accomac_VA', label: 'Accomac, VA' },
  { value: 'Accord_NY', label: 'Accord, NY' },
  { value: 'Accoville_WV', label: 'Accoville, WV' },
  { value: 'Ace_TX', label: 'Ace, TX' },
  { value: 'Achille_OK', label: 'Achille, OK' },
  { value: 'Ackerly_TX', label: 'Ackerly, TX' },
  { value: 'Ackerman_MS', label: 'Ackerman, MS' },
  { value: 'Ackley_IA', label: 'Ackley, IA' },
  { value: 'Ackworth_IA', label: 'Ackworth, IA' },
  { value: 'Acme_PA', label: 'Acme, PA' },
  { value: 'Acme_LA', label: 'Acme, LA' },
  { value: 'Acme_WA', label: 'Acme, WA' },
  { value: 'Acosta_PA', label: 'Acosta, PA' },
  { value: 'Acra_NY', label: 'Acra, NY' },
  { value: 'Acton_MA', label: 'Acton, MA' },
  { value: 'Acton_ME', label: 'Acton, ME' },
  { value: 'Acton_MT', label: 'Acton, MT' },
  { value: 'Acton_CA', label: 'Acton, CA' },
  { value: 'Acushnet_MA', label: 'Acushnet, MA' },
  { value: 'Acworth_NH', label: 'Acworth, NH' },
  { value: 'Acworth_GA', label: 'Acworth, GA' },
  { value: 'Ada_OH', label: 'Ada, OH' },
  { value: 'Ada_MI', label: 'Ada, MI' },
  { value: 'Ada_MN', label: 'Ada, MN' },
  { value: 'Ada_OK', label: 'Ada, OK' },
  { value: 'Adah_PA', label: 'Adah, PA' },
  { value: 'Adair_IA', label: 'Adair, IA' },
  { value: 'Adair_IL', label: 'Adair, IL' },
  { value: 'Adair_OK', label: 'Adair, OK' },
  { value: 'Adairsville_GA', label: 'Adairsville, GA' },
  { value: 'Adairville_KY', label: 'Adairville, KY' },
  { value: 'Adak_AK', label: 'Adak, AK' },
  { value: 'Adamant_VT', label: 'Adamant, VT' },
  { value: 'Adams_MA', label: 'Adams, MA' },
  { value: 'Adams_NY', label: 'Adams, NY' },
  { value: 'Adams_TN', label: 'Adams, TN' },
  { value: 'Adams_KY', label: 'Adams, KY' },
  { value: 'Adams_WI', label: 'Adams, WI' },
  { value: 'Adams_MN', label: 'Adams, MN' },
  { value: 'Adams_ND', label: 'Adams, ND' },
  { value: 'Adams_NE', label: 'Adams, NE' },
  { value: 'Adams_OK', label: 'Adams, OK' },
  { value: 'Adams_OR', label: 'Adams, OR' },
  { value: 'Adams Center_NY', label: 'Adams Center, NY' },
  { value: 'Adams Run_SC', label: 'Adams Run, SC' },
  { value: 'Adamsburg_PA', label: 'Adamsburg, PA' },
  { value: 'Adamstown_PA', label: 'Adamstown, PA' },
  { value: 'Adamstown_MD', label: 'Adamstown, MD' },
  { value: 'Adamsville_PA', label: 'Adamsville, PA' },
  { value: 'Adamsville_AL', label: 'Adamsville, AL' },
  { value: 'Adamsville_TN', label: 'Adamsville, TN' },
  { value: 'Adamsville_OH', label: 'Adamsville, OH' },
  { value: 'Addieville_IL', label: 'Addieville, IL' },
  { value: 'Addington_OK', label: 'Addington, OK' },
  { value: 'Addis_LA', label: 'Addis, LA' },
  { value: 'Addison_ME', label: 'Addison, ME' },
  { value: 'Addison_NY', label: 'Addison, NY' },
  { value: 'Addison_PA', label: 'Addison, PA' },
  { value: 'Addison_AL', label: 'Addison, AL' },
  { value: 'Addison_MI', label: 'Addison, MI' },
  { value: 'Addison_IL', label: 'Addison, IL' },
  { value: 'Addison_TX', label: 'Addison, TX' },
  { value: 'Addy_WA', label: 'Addy, WA' },
  { value: 'Addyston_OH', label: 'Addyston, OH' },
  { value: 'Adel_GA', label: 'Adel, GA' },
  { value: 'Adel_IA', label: 'Adel, IA' },
  { value: 'Adel_OR', label: 'Adel, OR' },
  { value: 'Adelanto_CA', label: 'Adelanto, CA' },
  { value: 'Adell_WI', label: 'Adell, WI' },
  { value: 'Adelphi_OH', label: 'Adelphi, OH' },
  { value: 'Adena_OH', label: 'Adena, OH' },
  { value: 'Adger_AL', label: 'Adger, AL' },
  { value: 'Adin_CA', label: 'Adin, CA' },
  { value: 'Adirondack_NY', label: 'Adirondack, NY' },
  { value: 'Adjuntas_PR', label: 'Adjuntas, PR' },
  { value: 'Adkins_TX', label: 'Adkins, TX' },
  { value: 'Admire_KS', label: 'Admire, KS' },
  { value: 'Adolphus_KY', label: 'Adolphus, KY' },
  { value: 'Adona_AR', label: 'Adona, AR' },
  { value: 'Adrian_PA', label: 'Adrian, PA' },
  { value: 'Adrian_WV', label: 'Adrian, WV' },
  { value: 'Adrian_GA', label: 'Adrian, GA' },
  { value: 'Adrian_MI', label: 'Adrian, MI' },
  { value: 'Adrian_MN', label: 'Adrian, MN' },
  { value: 'Adrian_MO', label: 'Adrian, MO' },
  { value: 'Adrian_TX', label: 'Adrian, TX' },
  { value: 'Adrian_OR', label: 'Adrian, OR' },
  { value: 'Advance_NC', label: 'Advance, NC' },
  { value: 'Advance_IN', label: 'Advance, IN' },
  { value: 'Advance_MO', label: 'Advance, MO' },
  { value: 'Advent_WV', label: 'Advent, WV' },
  { value: 'Afton_NY', label: 'Afton, NY' },
  { value: 'Afton_VA', label: 'Afton, VA' },
  { value: 'Afton_TN', label: 'Afton, TN' },
  { value: 'Afton_MI', label: 'Afton, MI' },
  { value: 'Afton_IA', label: 'Afton, IA' },
  { value: 'Afton_WI', label: 'Afton, WI' },
  { value: 'Afton_MN', label: 'Afton, MN' },
  { value: 'Afton_OK', label: 'Afton, OK' },
  { value: 'Afton_TX', label: 'Afton, TX' },
  { value: 'Afton_WY', label: 'Afton, WY' },
  { value: 'Agar_SD', label: 'Agar, SD' },
  { value: 'Agat_GU', label: 'Agat, GU' },
  { value: 'Agate_ND', label: 'Agate, ND' },
  { value: 'Agate_CO', label: 'Agate, CO' },
  { value: 'Agawam_MA', label: 'Agawam, MA' },
  { value: 'Agency_IA', label: 'Agency, IA' },
  { value: 'Agency_MO', label: 'Agency, MO' },
  { value: 'Agenda_KS', label: 'Agenda, KS' },
  { value: 'Ages Brookside_KY', label: 'Ages Brookside, KY' },
  { value: 'Agness_OR', label: 'Agness, OR' },
  { value: 'Agoura Hills_CA', label: 'Agoura Hills, CA' },
  { value: 'Agra_KS', label: 'Agra, KS' },
  { value: 'Agra_OK', label: 'Agra, OK' },
  { value: 'Agua Dulce_TX', label: 'Agua Dulce, TX' },
  { value: 'Aguada_PR', label: 'Aguada, PR' },
  { value: 'Aguadilla_PR', label: 'Aguadilla, PR' },
  { value: 'Aguanga_CA', label: 'Aguanga, CA' },
  { value: 'Aguas Buenas_PR', label: 'Aguas Buenas, PR' },
  { value: 'Aguila_AZ', label: 'Aguila, AZ' },
  { value: 'Aguilar_CO', label: 'Aguilar, CO' },
  { value: 'Aguirre_PR', label: 'Aguirre, PR' },
  { value: 'Ahmeek_MI', label: 'Ahmeek, MI' },
  { value: 'Ahoskie_NC', label: 'Ahoskie, NC' },
  { value: 'Ahsahka_ID', label: 'Ahsahka, ID' },
  { value: 'Ahwahnee_CA', label: 'Ahwahnee, CA' },
  { value: 'Aibonito_PR', label: 'Aibonito, PR' },
  { value: 'Aiea_HI', label: 'Aiea, HI' },
  { value: 'Aiken_SC', label: 'Aiken, SC' },
  { value: 'Ailey_GA', label: 'Ailey, GA' },
  { value: 'Aimwell_LA', label: 'Aimwell, LA' },
  { value: 'Ainsworth_IA', label: 'Ainsworth, IA' },
  { value: 'Ainsworth_NE', label: 'Ainsworth, NE' },
  { value: 'Airville_PA', label: 'Airville, PA' },
  { value: 'Airway Heights_WA', label: 'Airway Heights, WA' },
  { value: 'Aitkin_MN', label: 'Aitkin, MN' },
  { value: 'Ajo_AZ', label: 'Ajo, AZ' },
  { value: 'Akaska_SD', label: 'Akaska, SD' },
  { value: 'Akeley_MN', label: 'Akeley, MN' },
  { value: 'Akiachak_AK', label: 'Akiachak, AK' },
  { value: 'Akiak_AK', label: 'Akiak, AK' },
  { value: 'Akron_NY', label: 'Akron, NY' },
  { value: 'Akron_PA', label: 'Akron, PA' },
  { value: 'Akron_AL', label: 'Akron, AL' },
  { value: 'Akron_OH', label: 'Akron, OH' },
  { value: 'Akron_IN', label: 'Akron, IN' },
  { value: 'Akron_MI', label: 'Akron, MI' },
  { value: 'Akron_IA', label: 'Akron, IA' },
  { value: 'Akron_CO', label: 'Akron, CO' },
  { value: 'Akutan_AK', label: 'Akutan, AK' },
  { value: 'Alabaster_AL', label: 'Alabaster, AL' },
  { value: 'Alachua_FL', label: 'Alachua, FL' },
  { value: 'Aladdin_WY', label: 'Aladdin, WY' },
  { value: 'Alakanuk_AK', label: 'Alakanuk, AK' },
  { value: 'Alamance_NC', label: 'Alamance, NC' },
  { value: 'Alameda_CA', label: 'Alameda, CA' },
  { value: 'Alamo_GA', label: 'Alamo, GA' },
  { value: 'Alamo_TN', label: 'Alamo, TN' },
  { value: 'Alamo_IN', label: 'Alamo, IN' },
  { value: 'Alamo_ND', label: 'Alamo, ND' },
  { value: 'Alamo_TX', label: 'Alamo, TX' },
  { value: 'Alamo_NV', label: 'Alamo, NV' },
  { value: 'Alamo_CA', label: 'Alamo, CA' },
  { value: 'Alamogordo_NM', label: 'Alamogordo, NM' },
  { value: 'Alamosa_CO', label: 'Alamosa, CO' },
  { value: 'Alanreed_TX', label: 'Alanreed, TX' },
  { value: 'Alanson_MI', label: 'Alanson, MI' },
  { value: 'Alapaha_GA', label: 'Alapaha, GA' },
  { value: 'Alba_PA', label: 'Alba, PA' },
  { value: 'Alba_MI', label: 'Alba, MI' },
  { value: 'Alba_MO', label: 'Alba, MO' },
  { value: 'Alba_TX', label: 'Alba, TX' },
  { value: 'Albany_VT', label: 'Albany, VT' },
  { value: 'Albany_NY', label: 'Albany, NY' },
  { value: 'Albany_GA', label: 'Albany, GA' },
  { value: 'Albany_KY', label: 'Albany, KY' },
  { value: 'Albany_OH', label: 'Albany, OH' },
  { value: 'Albany_IN', label: 'Albany, IN' },
  { value: 'Albany_WI', label: 'Albany, WI' },
  { value: 'Albany_MN', label: 'Albany, MN' },
  { value: 'Albany_IL', label: 'Albany, IL' },
  { value: 'Albany_MO', label: 'Albany, MO' },
  { value: 'Albany_LA', label: 'Albany, LA' },
  { value: 'Albany_OK', label: 'Albany, OK' },
  { value: 'Albany_TX', label: 'Albany, TX' },
  { value: 'Albany_CA', label: 'Albany, CA' },
  { value: 'Albany_OR', label: 'Albany, OR' },
  { value: 'Albemarle_NC', label: 'Albemarle, NC' },
  { value: 'Albers_IL', label: 'Albers, IL' },
  { value: 'Albert_KS', label: 'Albert, KS' },
  { value: 'Albert_OK', label: 'Albert, OK' },
  { value: 'Albert City_IA', label: 'Albert City, IA' },
  { value: 'Albert Lea_MN', label: 'Albert Lea, MN' },
  { value: 'Alberta_VA', label: 'Alberta, VA' },
  { value: 'Alberta_AL', label: 'Alberta, AL' },
  { value: 'Alberta_MN', label: 'Alberta, MN' },
  { value: 'Alberton_MT', label: 'Alberton, MT' },
  { value: 'Albertson_NY', label: 'Albertson, NY' },
  { value: 'Albertson_NC', label: 'Albertson, NC' },
  { value: 'Albertville_AL', label: 'Albertville, AL' },
  { value: 'Albertville_MN', label: 'Albertville, MN' },
  { value: 'Albia_IA', label: 'Albia, IA' },
  { value: 'Albin_WY', label: 'Albin, WY' },
  { value: 'Albion_RI', label: 'Albion, RI' },
  { value: 'Albion_ME', label: 'Albion, ME' },
  { value: 'Albion_NY', label: 'Albion, NY' },
  { value: 'Albion_PA', label: 'Albion, PA' },
  { value: 'Albion_IN', label: 'Albion, IN' },
  { value: 'Albion_MI', label: 'Albion, MI' },
  { value: 'Albion_IA', label: 'Albion, IA' },
  { value: 'Albion_IL', label: 'Albion, IL' },
  { value: 'Albion_NE', label: 'Albion, NE' },
  { value: 'Albion_OK', label: 'Albion, OK' },
  { value: 'Albion_ID', label: 'Albion, ID' },
  { value: 'Albion_CA', label: 'Albion, CA' },
  { value: 'Albion_WA', label: 'Albion, WA' },
  { value: 'Alborn_MN', label: 'Alborn, MN' },
  { value: 'Albright_WV', label: 'Albright, WV' },
  { value: 'Albrightsville_PA', label: 'Albrightsville, PA' },
  { value: 'Albuquerque_NM', label: 'Albuquerque, NM' },
  { value: 'Alburgh_VT', label: 'Alburgh, VT' },
  { value: 'Alburnett_IA', label: 'Alburnett, IA' },
  { value: 'Alburtis_PA', label: 'Alburtis, PA' },
  { value: 'Alcalde_NM', label: 'Alcalde, NM' },
  { value: 'Alcester_SD', label: 'Alcester, SD' },
  { value: 'Alcoa_TN', label: 'Alcoa, TN' },
  { value: 'Alcolu_SC', label: 'Alcolu, SC' },
  { value: 'Alcova_WY', label: 'Alcova, WY' },
  { value: 'Alcove_NY', label: 'Alcove, NY' },
  { value: 'Alda_NE', label: 'Alda, NE' },
  { value: 'Alden_NY', label: 'Alden, NY' },
  { value: 'Alden_MI', label: 'Alden, MI' },
  { value: 'Alden_IA', label: 'Alden, IA' },
  { value: 'Alden_MN', label: 'Alden, MN' },
  { value: 'Alden_KS', label: 'Alden, KS' },
  { value: 'Alder_MT', label: 'Alder, MT' },
  { value: 'Alder Creek_NY', label: 'Alder Creek, NY' },
  { value: 'Alderpoint_CA', label: 'Alderpoint, CA' },
  { value: 'Alderson_WV', label: 'Alderson, WV' },
  { value: 'Alderson_OK', label: 'Alderson, OK' },
  { value: 'Aldie_VA', label: 'Aldie, VA' },
  { value: 'Aldrich_MN', label: 'Aldrich, MN' },
  { value: 'Aldrich_MO', label: 'Aldrich, MO' },
  { value: 'Aledo_IL', label: 'Aledo, IL' },
  { value: 'Aledo_TX', label: 'Aledo, TX' },
  { value: 'Aleknagik_AK', label: 'Aleknagik, AK' },
  { value: 'Aleppo_PA', label: 'Aleppo, PA' },
  { value: 'Alex_OK', label: 'Alex, OK' },
  { value: 'Alexander_NY', label: 'Alexander, NY' },
  { value: 'Alexander_NC', label: 'Alexander, NC' },
  { value: 'Alexander_IA', label: 'Alexander, IA' },
  { value: 'Alexander_ND', label: 'Alexander, ND' },
  { value: 'Alexander_IL', label: 'Alexander, IL' },
  { value: 'Alexander_KS', label: 'Alexander, KS' },
  { value: 'Alexander_AR', label: 'Alexander, AR' },
  { value: 'Alexander City_AL', label: 'Alexander City, AL' },
  { value: 'Alexandria_PA', label: 'Alexandria, PA' },
  { value: 'Alexandria_VA', label: 'Alexandria, VA' },
  { value: 'Alexandria_AL', label: 'Alexandria, AL' },
  { value: 'Alexandria_TN', label: 'Alexandria, TN' },
  { value: 'Alexandria_KY', label: 'Alexandria, KY' },
  { value: 'Alexandria_OH', label: 'Alexandria, OH' },
  { value: 'Alexandria_IN', label: 'Alexandria, IN' },
  { value: 'Alexandria_MN', label: 'Alexandria, MN' },
  { value: 'Alexandria_SD', label: 'Alexandria, SD' },
  { value: 'Alexandria_MO', label: 'Alexandria, MO' },
  { value: 'Alexandria_NE', label: 'Alexandria, NE' },
  { value: 'Alexandria_LA', label: 'Alexandria, LA' },
  { value: 'Alexandria Bay_NY', label: 'Alexandria Bay, NY' },
  { value: 'Alexis_NC', label: 'Alexis, NC' },
  { value: 'Alexis_IL', label: 'Alexis, IL' },
  { value: 'Alford_FL', label: 'Alford, FL' },
  { value: 'Alfred_ME', label: 'Alfred, ME' },
  { value: 'Alfred_NY', label: 'Alfred, NY' },
  { value: 'Alfred Station_NY', label: 'Alfred Station, NY' },
  { value: 'Alger_OH', label: 'Alger, OH' },
  { value: 'Alger_MI', label: 'Alger, MI' },
  { value: 'Algodones_NM', label: 'Algodones, NM' },
  { value: 'Algoma_WI', label: 'Algoma, WI' },
  { value: 'Algona_IA', label: 'Algona, IA' },
  { value: 'Algonac_MI', label: 'Algonac, MI' },
  { value: 'Algonquin_IL', label: 'Algonquin, IL' },
  { value: 'Alhambra_IL', label: 'Alhambra, IL' },
  { value: 'Alhambra_CA', label: 'Alhambra, CA' },
  { value: 'Alice_TX', label: 'Alice, TX' },
  { value: 'Aliceville_AL', label: 'Aliceville, AL' },
  { value: 'Alicia_AR', label: 'Alicia, AR' },
  { value: 'Aline_OK', label: 'Aline, OK' },
  { value: 'Aliquippa_PA', label: 'Aliquippa, PA' },
  { value: 'Aliso Viejo_CA', label: 'Aliso Viejo, CA' },
  { value: 'Alkol_WV', label: 'Alkol, WV' },
  { value: 'Allakaket_AK', label: 'Allakaket, AK' },
  { value: 'Allamuchy_NJ', label: 'Allamuchy, NJ' },
  { value: 'Allardt_TN', label: 'Allardt, TN' },
  { value: 'Alledonia_OH', label: 'Alledonia, OH' },
  { value: 'Alleene_AR', label: 'Alleene, AR' },
  { value: 'Allegan_MI', label: 'Allegan, MI' },
  { value: 'Allegany_NY', label: 'Allegany, NY' },
  { value: 'Alleghany_CA', label: 'Alleghany, CA' },
  { value: 'Alleman_IA', label: 'Alleman, IA' },
  { value: 'Allen_MD', label: 'Allen, MD' },
  { value: 'Allen_KY', label: 'Allen, KY' },
  { value: 'Allen_MI', label: 'Allen, MI' },
  { value: 'Allen_SD', label: 'Allen, SD' },
  { value: 'Allen_KS', label: 'Allen, KS' },
  { value: 'Allen_NE', label: 'Allen, NE' },
  { value: 'Allen_OK', label: 'Allen, OK' },
  { value: 'Allen_TX', label: 'Allen, TX' },
  { value: 'Allen Junction_WV', label: 'Allen Junction, WV' },
  { value: 'Allen Park_MI', label: 'Allen Park, MI' },
  { value: 'Allendale_NJ', label: 'Allendale, NJ' },
  { value: 'Allendale_SC', label: 'Allendale, SC' },
  { value: 'Allendale_MI', label: 'Allendale, MI' },
  { value: 'Allendale_IL', label: 'Allendale, IL' },
  { value: 'Allendale_MO', label: 'Allendale, MO' },
  { value: 'Allenhurst_NJ', label: 'Allenhurst, NJ' },
  { value: 'Allenhurst_GA', label: 'Allenhurst, GA' },
  { value: 'Allenport_PA', label: 'Allenport, PA' },
  { value: 'Allenspark_CO', label: 'Allenspark, CO' },
  { value: 'Allensville_PA', label: 'Allensville, PA' },
  { value: 'Allensville_KY', label: 'Allensville, KY' },
  { value: 'Allenton_MI', label: 'Allenton, MI' },
  { value: 'Allenton_WI', label: 'Allenton, WI' },
  { value: 'Allentown_NJ', label: 'Allentown, NJ' },
  { value: 'Allentown_NY', label: 'Allentown, NY' },
  { value: 'Allentown_PA', label: 'Allentown, PA' },
  { value: 'Allentown_GA', label: 'Allentown, GA' },
  { value: 'Allenwood_NJ', label: 'Allenwood, NJ' },
  { value: 'Allenwood_PA', label: 'Allenwood, PA' },
  { value: 'Allerton_IA', label: 'Allerton, IA' },
  { value: 'Allerton_IL', label: 'Allerton, IL' },
  { value: 'Alleyton_TX', label: 'Alleyton, TX' },
  { value: 'Allgood_AL', label: 'Allgood, AL' },
  { value: 'Alliance_NC', label: 'Alliance, NC' },
  { value: 'Alliance_OH', label: 'Alliance, OH' },
  { value: 'Alliance_NE', label: 'Alliance, NE' },
  { value: 'Alligator_MS', label: 'Alligator, MS' },
  { value: 'Allison_PA', label: 'Allison, PA' },
  { value: 'Allison_IA', label: 'Allison, IA' },
  { value: 'Allison_TX', label: 'Allison, TX' },
  { value: 'Allison Park_PA', label: 'Allison Park, PA' },
  { value: 'Allons_TN', label: 'Allons, TN' },
  { value: 'Allouez_MI', label: 'Allouez, MI' },
  { value: 'Alloway_NJ', label: 'Alloway, NJ' },
  { value: 'Alloy_WV', label: 'Alloy, WV' },
  { value: 'Allport_PA', label: 'Allport, PA' },
  { value: 'Allred_TN', label: 'Allred, TN' },
  { value: 'Allston_MA', label: 'Allston, MA' },
  { value: 'Allyn_WA', label: 'Allyn, WA' },
  { value: 'Alma_NY', label: 'Alma, NY' },
  { value: 'Alma_WV', label: 'Alma, WV' },
  { value: 'Alma_GA', label: 'Alma, GA' },
  { value: 'Alma_MI', label: 'Alma, MI' },
  { value: 'Alma_WI', label: 'Alma, WI' },
  { value: 'Alma_IL', label: 'Alma, IL' },
  { value: 'Alma_MO', label: 'Alma, MO' },
  { value: 'Alma_KS', label: 'Alma, KS' },
  { value: 'Alma_NE', label: 'Alma, NE' },
  { value: 'Alma_AR', label: 'Alma, AR' },
  { value: 'Alma_CO', label: 'Alma, CO' },
  { value: 'Alma Center_WI', label: 'Alma Center, WI' },
  { value: 'Almena_WI', label: 'Almena, WI' },
  { value: 'Almena_KS', label: 'Almena, KS' },
  { value: 'Almira_WA', label: 'Almira, WA' },
  { value: 'Almo_KY', label: 'Almo, KY' },
  { value: 'Almo_ID', label: 'Almo, ID' },
  { value: 'Almond_NY', label: 'Almond, NY' },
  { value: 'Almond_NC', label: 'Almond, NC' },
  { value: 'Almond_WI', label: 'Almond, WI' },
  { value: 'Almont_MI', label: 'Almont, MI' },
  { value: 'Almont_ND', label: 'Almont, ND' },
  { value: 'Almont_CO', label: 'Almont, CO' },
  { value: 'Almyra_AR', label: 'Almyra, AR' },
  { value: 'Alna_ME', label: 'Alna, ME' },
  { value: 'Alpaugh_CA', label: 'Alpaugh, CA' },
  { value: 'Alpena_MI', label: 'Alpena, MI' },
  { value: 'Alpena_SD', label: 'Alpena, SD' },
  { value: 'Alpena_AR', label: 'Alpena, AR' },
  { value: 'Alpha_KY', label: 'Alpha, KY' },
  { value: 'Alpha_OH', label: 'Alpha, OH' },
  { value: 'Alpha_MI', label: 'Alpha, MI' },
  { value: 'Alpha_MN', label: 'Alpha, MN' },
  { value: 'Alpha_IL', label: 'Alpha, IL' },
  { value: 'Alpharetta_GA', label: 'Alpharetta, GA' },
  { value: 'Alpine_NJ', label: 'Alpine, NJ' },
  { value: 'Alpine_NY', label: 'Alpine, NY' },
  { value: 'Alpine_AL', label: 'Alpine, AL' },
  { value: 'Alpine_TN', label: 'Alpine, TN' },
  { value: 'Alpine_TX', label: 'Alpine, TX' },
  { value: 'Alpine_WY', label: 'Alpine, WY' },
  { value: 'Alpine_UT', label: 'Alpine, UT' },
  { value: 'Alpine_AZ', label: 'Alpine, AZ' },
  { value: 'Alpine_CA', label: 'Alpine, CA' },
  { value: 'Alplaus_NY', label: 'Alplaus, NY' },
  { value: 'Alsea_OR', label: 'Alsea, OR' },
  { value: 'Alsen_ND', label: 'Alsen, ND' },
  { value: 'Alsey_IL', label: 'Alsey, IL' },
  { value: 'Alsip_IL', label: 'Alsip, IL' },
  { value: 'Alstead_NH', label: 'Alstead, NH' },
  { value: 'Alston_GA', label: 'Alston, GA' },
  { value: 'Alta_IA', label: 'Alta, IA' },
  { value: 'Alta_WY', label: 'Alta, WY' },
  { value: 'Alta_CA', label: 'Alta, CA' },
  { value: 'Alta Vista_IA', label: 'Alta Vista, IA' },
  { value: 'Alta Vista_KS', label: 'Alta Vista, KS' },
  { value: 'Altadena_CA', label: 'Altadena, CA' },
  { value: 'Altair_TX', label: 'Altair, TX' },
  { value: 'Altamahaw_NC', label: 'Altamahaw, NC' },
  { value: 'Altamont_NY', label: 'Altamont, NY' },
  { value: 'Altamont_TN', label: 'Altamont, TN' },
  { value: 'Altamont_IL', label: 'Altamont, IL' },
  { value: 'Altamont_MO', label: 'Altamont, MO' },
  { value: 'Altamont_KS', label: 'Altamont, KS' },
  { value: 'Altamont_UT', label: 'Altamont, UT' },
  { value: 'Altamonte Springs_FL', label: 'Altamonte Springs, FL' },
  { value: 'Altaville_CA', label: 'Altaville, CA' },
  { value: 'Altavista_VA', label: 'Altavista, VA' },
  { value: 'Altenburg_MO', label: 'Altenburg, MO' },
  { value: 'Altha_FL', label: 'Altha, FL' },
  { value: 'Altheimer_AR', label: 'Altheimer, AR' },
  { value: 'Altmar_NY', label: 'Altmar, NY' },
  { value: 'Alto_GA', label: 'Alto, GA' },
  { value: 'Alto_MI', label: 'Alto, MI' },
  { value: 'Alto_TX', label: 'Alto, TX' },
  { value: 'Alto_NM', label: 'Alto, NM' },
  { value: 'Alto Pass_IL', label: 'Alto Pass, IL' },
  { value: 'Alton_NH', label: 'Alton, NH' },
  { value: 'Alton_NY', label: 'Alton, NY' },
  { value: 'Alton_VA', label: 'Alton, VA' },
  { value: 'Alton_IA', label: 'Alton, IA' },
  { value: 'Alton_IL', label: 'Alton, IL' },
  { value: 'Alton_MO', label: 'Alton, MO' },
  { value: 'Alton_KS', label: 'Alton, KS' },
  { value: 'Alton_UT', label: 'Alton, UT' },
  { value: 'Alton Bay_NH', label: 'Alton Bay, NH' },
  { value: 'Altona_NY', label: 'Altona, NY' },
  { value: 'Altona_IL', label: 'Altona, IL' },
  { value: 'Altonah_UT', label: 'Altonah, UT' },
  { value: 'Altoona_PA', label: 'Altoona, PA' },
  { value: 'Altoona_FL', label: 'Altoona, FL' },
  { value: 'Altoona_AL', label: 'Altoona, AL' },
  { value: 'Altoona_IA', label: 'Altoona, IA' },
  { value: 'Altoona_WI', label: 'Altoona, WI' },
  { value: 'Altoona_KS', label: 'Altoona, KS' },
  { value: 'Altura_MN', label: 'Altura, MN' },
  { value: 'Alturas_CA', label: 'Alturas, CA' },
  { value: 'Altus_AR', label: 'Altus, AR' },
  { value: 'Altus_OK', label: 'Altus, OK' },
  { value: 'Altus Afb_OK', label: 'Altus Afb, OK' },
  { value: 'Alum Bank_PA', label: 'Alum Bank, PA' },
  { value: 'Alum Bridge_WV', label: 'Alum Bridge, WV' },
  { value: 'Alum Creek_WV', label: 'Alum Creek, WV' },
  { value: 'Alva_FL', label: 'Alva, FL' },
  { value: 'Alva_OK', label: 'Alva, OK' },
  { value: 'Alva_WY', label: 'Alva, WY' },
  { value: 'Alvada_OH', label: 'Alvada, OH' },
  { value: 'Alvarado_MN', label: 'Alvarado, MN' },
  { value: 'Alvarado_TX', label: 'Alvarado, TX' },
  { value: 'Alvaton_KY', label: 'Alvaton, KY' },
  { value: 'Alverda_PA', label: 'Alverda, PA' },
  { value: 'Alverton_PA', label: 'Alverton, PA' },
  { value: 'Alvin_IL', label: 'Alvin, IL' },
  { value: 'Alvin_TX', label: 'Alvin, TX' },
  { value: 'Alviso_CA', label: 'Alviso, CA' },
  { value: 'Alvo_NE', label: 'Alvo, NE' },
  { value: 'Alvord_IA', label: 'Alvord, IA' },
  { value: 'Alvord_TX', label: 'Alvord, TX' },
  { value: 'Alvordton_OH', label: 'Alvordton, OH' },
  { value: 'Alzada_MT', label: 'Alzada, MT' },
  { value: 'Ama_LA', label: 'Ama, LA' },
  { value: 'Amado_AZ', label: 'Amado, AZ' },
  { value: 'Amador City_CA', label: 'Amador City, CA' },
  { value: 'Amagansett_NY', label: 'Amagansett, NY' },
  { value: 'Amagon_AR', label: 'Amagon, AR' },
  { value: 'Amalia_NM', label: 'Amalia, NM' },
  { value: 'Amana_IA', label: 'Amana, IA' },
  { value: 'Amanda_OH', label: 'Amanda, OH' },
  { value: 'Amanda Park_WA', label: 'Amanda Park, WA' },
  { value: 'Amargosa Valley_NV', label: 'Amargosa Valley, NV' },
  { value: 'Amarillo_TX', label: 'Amarillo, TX' },
  { value: 'Amasa_MI', label: 'Amasa, MI' },
  { value: 'Amawalk_NY', label: 'Amawalk, NY' },
  { value: 'Amazonia_MO', label: 'Amazonia, MO' },
  { value: 'Amber_OK', label: 'Amber, OK' },
  { value: 'Amberg_WI', label: 'Amberg, WI' },
  { value: 'Amberson_PA', label: 'Amberson, PA' },
  { value: 'Ambia_IN', label: 'Ambia, IN' },
  { value: 'Ambler_PA', label: 'Ambler, PA' },
  { value: 'Ambler_AK', label: 'Ambler, AK' },
  { value: 'Amboy_IN', label: 'Amboy, IN' },
  { value: 'Amboy_MN', label: 'Amboy, MN' },
  { value: 'Amboy_IL', label: 'Amboy, IL' },
  { value: 'Amboy_CA', label: 'Amboy, CA' },
  { value: 'Amboy_WA', label: 'Amboy, WA' },
  { value: 'Ambridge_PA', label: 'Ambridge, PA' },
  { value: 'Ambrose_GA', label: 'Ambrose, GA' },
  { value: 'Ambrose_ND', label: 'Ambrose, ND' },
  { value: 'Amelia_OH', label: 'Amelia, OH' },
  { value: 'Amelia_NE', label: 'Amelia, NE' },
  { value: 'Amelia_LA', label: 'Amelia, LA' },
  { value: 'Amelia Court House_VA', label: 'Amelia Court House, VA' },
  { value: 'Amenia_NY', label: 'Amenia, NY' },
  { value: 'Amenia_ND', label: 'Amenia, ND' },
  { value: 'American Canyon_CA', label: 'American Canyon, CA' },
  { value: 'American Falls_ID', label: 'American Falls, ID' },
  { value: 'American Fork_UT', label: 'American Fork, UT' },
  { value: 'Americus_GA', label: 'Americus, GA' },
  { value: 'Americus_KS', label: 'Americus, KS' },
  { value: 'Amery_WI', label: 'Amery, WI' },
  { value: 'Ames_IA', label: 'Ames, IA' },
  { value: 'Ames_NE', label: 'Ames, NE' },
  { value: 'Ames_OK', label: 'Ames, OK' },
  { value: 'Amesbury_MA', label: 'Amesbury, MA' },
  { value: 'Amesville_OH', label: 'Amesville, OH' },
  { value: 'Amherst_MA', label: 'Amherst, MA' },
  { value: 'Amherst_NH', label: 'Amherst, NH' },
  { value: 'Amherst_VA', label: 'Amherst, VA' },
  { value: 'Amherst_OH', label: 'Amherst, OH' },
  { value: 'Amherst_WI', label: 'Amherst, WI' },
  { value: 'Amherst_SD', label: 'Amherst, SD' },
  { value: 'Amherst_NE', label: 'Amherst, NE' },
  { value: 'Amherst_TX', label: 'Amherst, TX' },
  { value: 'Amherst_CO', label: 'Amherst, CO' },
  { value: 'Amherst Junction_WI', label: 'Amherst Junction, WI' },
  { value: 'Amherstdale_WV', label: 'Amherstdale, WV' },
  { value: 'Amidon_ND', label: 'Amidon, ND' },
  { value: 'Amigo_WV', label: 'Amigo, WV' },
  { value: 'Amissville_VA', label: 'Amissville, VA' },
  { value: 'Amistad_NM', label: 'Amistad, NM' },
  { value: 'Amite_LA', label: 'Amite, LA' },
  { value: 'Amity_PA', label: 'Amity, PA' },
  { value: 'Amity_MO', label: 'Amity, MO' },
  { value: 'Amity_AR', label: 'Amity, AR' },
  { value: 'Amity_OR', label: 'Amity, OR' },
  { value: 'Amityville_NY', label: 'Amityville, NY' },
  { value: 'Amlin_OH', label: 'Amlin, OH' },
  { value: 'Amma_WV', label: 'Amma, WV' },
  { value: 'Amo_IN', label: 'Amo, IN' },
  { value: 'Amonate_VA', label: 'Amonate, VA' },
  { value: 'Amoret_MO', label: 'Amoret, MO' },
  { value: 'Amorita_OK', label: 'Amorita, OK' },
  { value: 'Amory_MS', label: 'Amory, MS' },
  { value: 'Amsterdam_NY', label: 'Amsterdam, NY' },
  { value: 'Amsterdam_OH', label: 'Amsterdam, OH' },
  { value: 'Amsterdam_MO', label: 'Amsterdam, MO' },
  { value: 'Amston_CT', label: 'Amston, CT' },
  { value: 'Anabel_MO', label: 'Anabel, MO' },
  { value: 'Anacoco_LA', label: 'Anacoco, LA' },
  { value: 'Anaconda_MT', label: 'Anaconda, MT' },
  { value: 'Anacortes_WA', label: 'Anacortes, WA' },
  { value: 'Anadarko_OK', label: 'Anadarko, OK' },
  { value: 'Anaheim_CA', label: 'Anaheim, CA' },
  { value: 'Anahola_HI', label: 'Anahola, HI' },
  { value: 'Anahuac_TX', label: 'Anahuac, TX' },
  { value: 'Anaktuvuk Pass_AK', label: 'Anaktuvuk Pass, AK' },
  { value: 'Anamoose_ND', label: 'Anamoose, ND' },
  { value: 'Anamosa_IA', label: 'Anamosa, IA' },
  { value: 'Anasco_PR', label: 'Anasco, PR' },
  { value: 'Anatone_WA', label: 'Anatone, WA' },
  { value: 'Anawalt_WV', label: 'Anawalt, WV' },
  { value: 'Anchor_IL', label: 'Anchor, IL' },
  { value: 'Anchor Point_AK', label: 'Anchor Point, AK' },
  { value: 'Anchorage_AK', label: 'Anchorage, AK' },
  { value: 'Ancona_IL', label: 'Ancona, IL' },
  { value: 'Ancram_NY', label: 'Ancram, NY' },
  { value: 'Ancramdale_NY', label: 'Ancramdale, NY' },
  { value: 'Andale_KS', label: 'Andale, KS' },
  { value: 'Andalusia_AL', label: 'Andalusia, AL' },
  { value: 'Andalusia_IL', label: 'Andalusia, IL' },
  { value: 'Anderson_SC', label: 'Anderson, SC' },
  { value: 'Anderson_AL', label: 'Anderson, AL' },
  { value: 'Anderson_IN', label: 'Anderson, IN' },
  { value: 'Anderson_MO', label: 'Anderson, MO' },
  { value: 'Anderson_TX', label: 'Anderson, TX' },
  { value: 'Anderson_CA', label: 'Anderson, CA' },
  { value: 'Anderson_AK', label: 'Anderson, AK' },
  { value: 'Anderson Island_WA', label: 'Anderson Island, WA' },
  { value: 'Andersonville_GA', label: 'Andersonville, GA' },
  { value: 'Andersonville_TN', label: 'Andersonville, TN' },
  { value: 'Andes_NY', label: 'Andes, NY' },
  { value: 'Andover_MA', label: 'Andover, MA' },
  { value: 'Andover_NH', label: 'Andover, NH' },
  { value: 'Andover_ME', label: 'Andover, ME' },
  { value: 'Andover_CT', label: 'Andover, CT' },
  { value: 'Andover_NJ', label: 'Andover, NJ' },
  { value: 'Andover_NY', label: 'Andover, NY' },
  { value: 'Andover_OH', label: 'Andover, OH' },
  { value: 'Andover_IA', label: 'Andover, IA' },
  { value: 'Andover_MN', label: 'Andover, MN' },
  { value: 'Andover_SD', label: 'Andover, SD' },
  { value: 'Andover_IL', label: 'Andover, IL' },
  { value: 'Andover_KS', label: 'Andover, KS' },
  { value: 'Andreas_PA', label: 'Andreas, PA' },
  { value: 'Andrew_IA', label: 'Andrew, IA' },
  { value: 'Andrews_NC', label: 'Andrews, NC' },
  { value: 'Andrews_SC', label: 'Andrews, SC' },
  { value: 'Andrews_IN', label: 'Andrews, IN' },
  { value: 'Andrews_TX', label: 'Andrews, TX' },
  { value: 'Andrews Air Force Base_MD', label: 'Andrews Air Force Base, MD' },
  { value: 'Aneta_ND', label: 'Aneta, ND' },
  { value: 'Angel Fire_NM', label: 'Angel Fire, NM' },
  { value: 'Angela_MT', label: 'Angela, MT' },
  { value: 'Angeles_PR', label: 'Angeles, PR' },
  { value: 'Angelica_NY', label: 'Angelica, NY' },
  { value: 'Angels Camp_CA', label: 'Angels Camp, CA' },
  { value: 'Angelus Oaks_CA', label: 'Angelus Oaks, CA' },
  { value: 'Angie_LA', label: 'Angie, LA' },
  { value: 'Angier_NC', label: 'Angier, NC' },
  { value: 'Angle Inlet_MN', label: 'Angle Inlet, MN' },
  { value: 'Angleton_TX', label: 'Angleton, TX' },
  { value: 'Angola_NY', label: 'Angola, NY' },
  { value: 'Angola_IN', label: 'Angola, IN' },
  { value: 'Angola_LA', label: 'Angola, LA' },
  { value: 'Angoon_AK', label: 'Angoon, AK' },
  { value: 'Angora_MN', label: 'Angora, MN' },
  { value: 'Angora_NE', label: 'Angora, NE' },
  { value: 'Anguilla_MS', label: 'Anguilla, MS' },
  { value: 'Angwin_CA', label: 'Angwin, CA' },
  { value: 'Aniak_AK', label: 'Aniak, AK' },
  { value: 'Animas_NM', label: 'Animas, NM' },
  { value: 'Anita_PA', label: 'Anita, PA' },
  { value: 'Anita_IA', label: 'Anita, IA' },
  { value: 'Aniwa_WI', label: 'Aniwa, WI' },
  { value: 'Ankeny_IA', label: 'Ankeny, IA' },
  { value: 'Anmoore_WV', label: 'Anmoore, WV' },
  { value: 'Ann Arbor_MI', label: 'Ann Arbor, MI' },
  { value: 'Anna_OH', label: 'Anna, OH' },
  { value: 'Anna_IL', label: 'Anna, IL' },
  { value: 'Anna_TX', label: 'Anna, TX' },
  { value: 'Anna Maria_FL', label: 'Anna Maria, FL' },
  { value: 'Annabella_UT', label: 'Annabella, UT' },
  { value: 'Annada_MO', label: 'Annada, MO' },
  { value: 'Annandale_NJ', label: 'Annandale, NJ' },
  { value: 'Annandale_VA', label: 'Annandale, VA' },
  { value: 'Annandale_MN', label: 'Annandale, MN' },
  { value: 'Annandale On Hudson_NY', label: 'Annandale On Hudson, NY' },
  { value: 'Annapolis_MD', label: 'Annapolis, MD' },
  { value: 'Annapolis_IL', label: 'Annapolis, IL' },
  { value: 'Annapolis_MO', label: 'Annapolis, MO' },
  { value: 'Annapolis_CA', label: 'Annapolis, CA' },
  { value: 'Annapolis Junction_MD', label: 'Annapolis Junction, MD' },
  { value: 'Annawan_IL', label: 'Annawan, IL' },
  { value: 'Anniston_AL', label: 'Anniston, AL' },
  { value: 'Anniston_MO', label: 'Anniston, MO' },
  { value: 'Annona_TX', label: 'Annona, TX' },
  { value: 'Annville_PA', label: 'Annville, PA' },
  { value: 'Annville_KY', label: 'Annville, KY' },
  { value: 'Anoka_MN', label: 'Anoka, MN' },
  { value: 'Anselmo_NE', label: 'Anselmo, NE' },
  { value: 'Ansley_NE', label: 'Ansley, NE' },
  { value: 'Anson_ME', label: 'Anson, ME' },
  { value: 'Anson_TX', label: 'Anson, TX' },
  { value: 'Ansonia_CT', label: 'Ansonia, CT' },
  { value: 'Ansonia_OH', label: 'Ansonia, OH' },
  { value: 'Ansonville_NC', label: 'Ansonville, NC' },
  { value: 'Ansted_WV', label: 'Ansted, WV' },
  { value: 'Antelope_MT', label: 'Antelope, MT' },
  { value: 'Antelope_CA', label: 'Antelope, CA' },
  { value: 'Antelope_OR', label: 'Antelope, OR' },
  { value: 'Antes Fort_PA', label: 'Antes Fort, PA' },
  { value: 'Anthon_IA', label: 'Anthon, IA' },
  { value: 'Anthony_FL', label: 'Anthony, FL' },
  { value: 'Anthony_KS', label: 'Anthony, KS' },
  { value: 'Anthony_TX', label: 'Anthony, TX' },
  { value: 'Anthony_NM', label: 'Anthony, NM' },
  { value: 'Antigo_WI', label: 'Antigo, WI' },
  { value: 'Antimony_UT', label: 'Antimony, UT' },
  { value: 'Antioch_TN', label: 'Antioch, TN' },
  { value: 'Antioch_IL', label: 'Antioch, IL' },
  { value: 'Antioch_CA', label: 'Antioch, CA' },
  { value: 'Antler_ND', label: 'Antler, ND' },
  { value: 'Antlers_OK', label: 'Antlers, OK' },
  { value: 'Antoine_AR', label: 'Antoine, AR' },
  { value: 'Anton_TX', label: 'Anton, TX' },
  { value: 'Anton_CO', label: 'Anton, CO' },
  { value: 'Anton Chico_NM', label: 'Anton Chico, NM' },
  { value: 'Antonito_CO', label: 'Antonito, CO' },
  { value: 'Antrim_NH', label: 'Antrim, NH' },
  { value: 'Antwerp_NY', label: 'Antwerp, NY' },
  { value: 'Antwerp_OH', label: 'Antwerp, OH' },
  { value: 'Anvik_AK', label: 'Anvik, AK' },
  { value: 'Anza_CA', label: 'Anza, CA' },
  { value: 'Apache_OK', label: 'Apache, OK' },
  { value: 'Apache Junction_AZ', label: 'Apache Junction, AZ' },
  { value: 'Apalachicola_FL', label: 'Apalachicola, FL' },
  { value: 'Apalachin_NY', label: 'Apalachin, NY' },
  { value: 'Apex_NC', label: 'Apex, NC' },
  { value: 'Apison_TN', label: 'Apison, TN' },
  { value: 'Aplington_IA', label: 'Aplington, IA' },
  { value: 'Apollo_PA', label: 'Apollo, PA' },
  { value: 'Apollo Beach_FL', label: 'Apollo Beach, FL' },
  { value: 'Apopka_FL', label: 'Apopka, FL' },
  { value: 'Appalachia_VA', label: 'Appalachia, VA' },
  { value: 'Apple Creek_OH', label: 'Apple Creek, OH' },
  { value: 'Apple Grove_WV', label: 'Apple Grove, WV' },
  { value: 'Apple River_IL', label: 'Apple River, IL' },
  { value: 'Apple Springs_TX', label: 'Apple Springs, TX' },
  { value: 'Apple Valley_CA', label: 'Apple Valley, CA' },
  { value: 'Applegate_MI', label: 'Applegate, MI' },
  { value: 'Applegate_CA', label: 'Applegate, CA' },
  { value: 'Appleton_NY', label: 'Appleton, NY' },
  { value: 'Appleton_WI', label: 'Appleton, WI' },
  { value: 'Appleton_MN', label: 'Appleton, MN' },
  { value: 'Appleton_WA', label: 'Appleton, WA' },
  { value: 'Appleton City_MO', label: 'Appleton City, MO' },
  { value: 'Appling_GA', label: 'Appling, GA' },
  { value: 'Appomattox_VA', label: 'Appomattox, VA' },
  { value: 'Aptos_CA', label: 'Aptos, CA' },
  { value: 'Apulia Station_NY', label: 'Apulia Station, NY' },
  { value: 'Aquasco_MD', label: 'Aquasco, MD' },
  { value: 'Aquashicola_PA', label: 'Aquashicola, PA' },
  { value: 'Aquebogue_NY', label: 'Aquebogue, NY' },
  { value: 'Aquilla_TX', label: 'Aquilla, TX' },
  { value: 'Arab_AL', label: 'Arab, AL' },
  { value: 'Arabi_GA', label: 'Arabi, GA' },
  { value: 'Arabi_LA', label: 'Arabi, LA' },
  { value: 'Aragon_GA', label: 'Aragon, GA' },
  { value: 'Aragon_NM', label: 'Aragon, NM' },
  { value: 'Aransas Pass_TX', label: 'Aransas Pass, TX' },
  { value: 'Arapaho_OK', label: 'Arapaho, OK' },
  { value: 'Arapahoe_NC', label: 'Arapahoe, NC' },
  { value: 'Arapahoe_NE', label: 'Arapahoe, NE' },
  { value: 'Arapahoe_CO', label: 'Arapahoe, CO' },
  { value: 'Arapahoe_WY', label: 'Arapahoe, WY' },
  { value: 'Ararat_VA', label: 'Ararat, VA' },
  { value: 'Ararat_NC', label: 'Ararat, NC' },
  { value: 'Arbela_MO', label: 'Arbela, MO' },
  { value: 'Arboles_CO', label: 'Arboles, CO' },
  { value: 'Arbon_ID', label: 'Arbon, ID' },
  { value: 'Arbovale_WV', label: 'Arbovale, WV' },
  { value: 'Arbuckle_CA', label: 'Arbuckle, CA' },
  { value: 'Arbyrd_MO', label: 'Arbyrd, MO' },
  { value: 'Arcade_NY', label: 'Arcade, NY' },
  { value: 'Arcadia_PA', label: 'Arcadia, PA' },
  { value: 'Arcadia_SC', label: 'Arcadia, SC' },
  { value: 'Arcadia_FL', label: 'Arcadia, FL' },
  { value: 'Arcadia_OH', label: 'Arcadia, OH' },
  { value: 'Arcadia_IN', label: 'Arcadia, IN' },
  { value: 'Arcadia_MI', label: 'Arcadia, MI' },
  { value: 'Arcadia_IA', label: 'Arcadia, IA' },
  { value: 'Arcadia_WI', label: 'Arcadia, WI' },
  { value: 'Arcadia_MO', label: 'Arcadia, MO' },
  { value: 'Arcadia_KS', label: 'Arcadia, KS' },
  { value: 'Arcadia_NE', label: 'Arcadia, NE' },
  { value: 'Arcadia_LA', label: 'Arcadia, LA' },
  { value: 'Arcadia_OK', label: 'Arcadia, OK' },
  { value: 'Arcadia_CA', label: 'Arcadia, CA' },
  { value: 'Arcanum_OH', label: 'Arcanum, OH' },
  { value: 'Arcata_CA', label: 'Arcata, CA' },
  { value: 'Arch Cape_OR', label: 'Arch Cape, OR' },
  { value: 'Archbald_PA', label: 'Archbald, PA' },
  { value: 'Archbold_OH', label: 'Archbold, OH' },
  { value: 'Archer_FL', label: 'Archer, FL' },
  { value: 'Archer_IA', label: 'Archer, IA' },
  { value: 'Archer_NE', label: 'Archer, NE' },
  { value: 'Archer City_TX', label: 'Archer City, TX' },
  { value: 'Archibald_LA', label: 'Archibald, LA' },
  { value: 'Archie_MO', label: 'Archie, MO' },
  { value: 'Arco_MN', label: 'Arco, MN' },
  { value: 'Arco_ID', label: 'Arco, ID' },
  { value: 'Arcola_MS', label: 'Arcola, MS' },
  { value: 'Arcola_IN', label: 'Arcola, IN' },
  { value: 'Arcola_IL', label: 'Arcola, IL' },
  { value: 'Arcola_MO', label: 'Arcola, MO' },
  { value: 'Arctic Village_AK', label: 'Arctic Village, AK' },
  { value: 'Ardara_PA', label: 'Ardara, PA' },
  { value: 'Arden_NY', label: 'Arden, NY' },
  { value: 'Arden_NC', label: 'Arden, NC' },
  { value: 'Ardenvoir_WA', label: 'Ardenvoir, WA' },
  { value: 'Ardmore_PA', label: 'Ardmore, PA' },
  { value: 'Ardmore_AL', label: 'Ardmore, AL' },
  { value: 'Ardmore_TN', label: 'Ardmore, TN' },
  { value: 'Ardmore_OK', label: 'Ardmore, OK' },
  { value: 'Ardsley_NY', label: 'Ardsley, NY' },
  { value: 'Ardsley On Hudson_NY', label: 'Ardsley On Hudson, NY' },
  { value: 'Arecibo_PR', label: 'Arecibo, PR' },
  { value: 'Aredale_IA', label: 'Aredale, IA' },
  { value: 'Arena_WI', label: 'Arena, WI' },
  { value: 'Arenas Valley_NM', label: 'Arenas Valley, NM' },
  { value: 'Arendtsville_PA', label: 'Arendtsville, PA' },
  { value: 'Arenzville_IL', label: 'Arenzville, IL' },
  { value: 'Argenta_IL', label: 'Argenta, IL' },
  { value: 'Argillite_KY', label: 'Argillite, KY' },
  { value: 'Argonia_KS', label: 'Argonia, KS' },
  { value: 'Argonne_WI', label: 'Argonne, WI' },
  { value: 'Argos_IN', label: 'Argos, IN' },
  { value: 'Argusville_ND', label: 'Argusville, ND' },
  { value: 'Argyle_NY', label: 'Argyle, NY' },
  { value: 'Argyle_GA', label: 'Argyle, GA' },
  { value: 'Argyle_MI', label: 'Argyle, MI' },
  { value: 'Argyle_IA', label: 'Argyle, IA' },
  { value: 'Argyle_WI', label: 'Argyle, WI' },
  { value: 'Argyle_MN', label: 'Argyle, MN' },
  { value: 'Argyle_MO', label: 'Argyle, MO' },
  { value: 'Argyle_TX', label: 'Argyle, TX' },
  { value: 'Ariel_WA', label: 'Ariel, WA' },
  { value: 'Arimo_ID', label: 'Arimo, ID' },
  { value: 'Arion_IA', label: 'Arion, IA' },
  { value: 'Aripeka_FL', label: 'Aripeka, FL' },
  { value: 'Arispe_IA', label: 'Arispe, IA' },
  { value: 'Aristes_PA', label: 'Aristes, PA' },
  { value: 'Ariton_AL', label: 'Ariton, AL' },
  { value: 'Arivaca_AZ', label: 'Arivaca, AZ' },
  { value: 'Arizona City_AZ', label: 'Arizona City, AZ' },
  { value: 'Arjay_KY', label: 'Arjay, KY' },
  { value: 'Arkadelphia_AR', label: 'Arkadelphia, AR' },
  { value: 'Arkansas City_KS', label: 'Arkansas City, KS' },
  { value: 'Arkansas City_AR', label: 'Arkansas City, AR' },
  { value: 'Arkansaw_WI', label: 'Arkansaw, WI' },
  { value: 'Arkdale_WI', label: 'Arkdale, WI' },
  { value: 'Arkoma_OK', label: 'Arkoma, OK' },
  { value: 'Arkport_NY', label: 'Arkport, NY' },
  { value: 'Arkville_NY', label: 'Arkville, NY' },
  { value: 'Arlee_MT', label: 'Arlee, MT' },
  { value: 'Arley_AL', label: 'Arley, AL' },
  { value: 'Arlington_MA', label: 'Arlington, MA' },
  { value: 'Arlington_VT', label: 'Arlington, VT' },
  { value: 'Arlington_VA', label: 'Arlington, VA' },
  { value: 'Arlington_AL', label: 'Arlington, AL' },
  { value: 'Arlington_TN', label: 'Arlington, TN' },
  { value: 'Arlington_GA', label: 'Arlington, GA' },
  { value: 'Arlington_KY', label: 'Arlington, KY' },
  { value: 'Arlington_OH', label: 'Arlington, OH' },
  { value: 'Arlington_IN', label: 'Arlington, IN' },
  { value: 'Arlington_IA', label: 'Arlington, IA' },
  { value: 'Arlington_WI', label: 'Arlington, WI' },
  { value: 'Arlington_MN', label: 'Arlington, MN' },
  { value: 'Arlington_SD', label: 'Arlington, SD' },
  { value: 'Arlington_IL', label: 'Arlington, IL' },
  { value: 'Arlington_KS', label: 'Arlington, KS' },
  { value: 'Arlington_NE', label: 'Arlington, NE' },
  { value: 'Arlington_TX', label: 'Arlington, TX' },
  { value: 'Arlington_CO', label: 'Arlington, CO' },
  { value: 'Arlington_AZ', label: 'Arlington, AZ' },
  { value: 'Arlington_OR', label: 'Arlington, OR' },
  { value: 'Arlington_WA', label: 'Arlington, WA' },
  { value: 'Arlington Heights_IL', label: 'Arlington Heights, IL' },
  { value: 'Arma_KS', label: 'Arma, KS' },
  { value: 'Armada_MI', label: 'Armada, MI' },
  { value: 'Armagh_PA', label: 'Armagh, PA' },
  { value: 'Armbrust_PA', label: 'Armbrust, PA' },
  { value: 'Armington_IL', label: 'Armington, IL' },
  { value: 'Arminto_WY', label: 'Arminto, WY' },
  { value: 'Armona_CA', label: 'Armona, CA' },
  { value: 'Armonk_NY', label: 'Armonk, NY' },
  { value: 'Armour_SD', label: 'Armour, SD' },
  { value: 'Armstrong_IA', label: 'Armstrong, IA' },
  { value: 'Armstrong_IL', label: 'Armstrong, IL' },
  { value: 'Armstrong_MO', label: 'Armstrong, MO' },
  { value: 'Armstrong_TX', label: 'Armstrong, TX' },
  { value: 'Armstrong Creek_WI', label: 'Armstrong Creek, WI' },
  { value: 'Armuchee_GA', label: 'Armuchee, GA' },
  { value: 'Arnaudville_LA', label: 'Arnaudville, LA' },
  { value: 'Arnegard_ND', label: 'Arnegard, ND' },
  { value: 'Arnett_WV', label: 'Arnett, WV' },
  { value: 'Arnett_OK', label: 'Arnett, OK' },
  { value: 'Arnold_MD', label: 'Arnold, MD' },
  { value: 'Arnold_MO', label: 'Arnold, MO' },
  { value: 'Arnold_KS', label: 'Arnold, KS' },
  { value: 'Arnold_NE', label: 'Arnold, NE' },
  { value: 'Arnold_CA', label: 'Arnold, CA' },
  { value: 'Arnolds Park_IA', label: 'Arnolds Park, IA' },
  { value: 'Arnoldsburg_WV', label: 'Arnoldsburg, WV' },
  { value: 'Arnoldsville_GA', label: 'Arnoldsville, GA' },
  { value: 'Arnot_PA', label: 'Arnot, PA' },
  { value: 'Arock_OR', label: 'Arock, OR' },
  { value: 'Aroda_VA', label: 'Aroda, VA' },
  { value: 'Aroma Park_IL', label: 'Aroma Park, IL' },
  { value: 'Aromas_CA', label: 'Aromas, CA' },
  { value: 'Arona_PA', label: 'Arona, PA' },
  { value: 'Arp_TX', label: 'Arp, TX' },
  { value: 'Arpin_WI', label: 'Arpin, WI' },
  { value: 'Arrey_NM', label: 'Arrey, NM' },
  { value: 'Arriba_CO', label: 'Arriba, CO' },
  { value: 'Arrington_VA', label: 'Arrington, VA' },
  { value: 'Arrington_TN', label: 'Arrington, TN' },
  { value: 'Arrow Rock_MO', label: 'Arrow Rock, MO' },
  { value: 'Arrowsmith_IL', label: 'Arrowsmith, IL' },
  { value: 'Arroyo_PR', label: 'Arroyo, PR' },
  { value: 'Arroyo Grande_CA', label: 'Arroyo Grande, CA' },
  { value: 'Arroyo Hondo_NM', label: 'Arroyo Hondo, NM' },
  { value: 'Arroyo Seco_NM', label: 'Arroyo Seco, NM' },
  { value: 'Art_TX', label: 'Art, TX' },
  { value: 'Artemas_PA', label: 'Artemas, PA' },
  { value: 'Artemus_KY', label: 'Artemus, KY' },
  { value: 'Artesia_MS', label: 'Artesia, MS' },
  { value: 'Artesia_NM', label: 'Artesia, NM' },
  { value: 'Artesia_CA', label: 'Artesia, CA' },
  { value: 'Artesia Wells_TX', label: 'Artesia Wells, TX' },
  { value: 'Artesian_SD', label: 'Artesian, SD' },
  { value: 'Arthur_IA', label: 'Arthur, IA' },
  { value: 'Arthur_ND', label: 'Arthur, ND' },
  { value: 'Arthur_IL', label: 'Arthur, IL' },
  { value: 'Arthur_NE', label: 'Arthur, NE' },
  { value: 'Arthur City_TX', label: 'Arthur City, TX' },
  { value: 'Arthurdale_WV', label: 'Arthurdale, WV' },
  { value: 'Artie_WV', label: 'Artie, WV' },
  { value: 'Artois_CA', label: 'Artois, CA' },
  { value: 'Arvada_CO', label: 'Arvada, CO' },
  { value: 'Arvada_WY', label: 'Arvada, WY' },
  { value: 'Arverne_NY', label: 'Arverne, NY' },
  { value: 'Arvilla_ND', label: 'Arvilla, ND' },
  { value: 'Arvin_CA', label: 'Arvin, CA' },
  { value: 'Arvonia_VA', label: 'Arvonia, VA' },
  { value: 'Ary_KY', label: 'Ary, KY' },
  { value: 'Asbury_NJ', label: 'Asbury, NJ' },
  { value: 'Asbury_WV', label: 'Asbury, WV' },
  { value: 'Asbury_MO', label: 'Asbury, MO' },
  { value: 'Asbury Park_NJ', label: 'Asbury Park, NJ' },
  { value: 'Ascutney_VT', label: 'Ascutney, VT' },
  { value: 'Ash_NC', label: 'Ash, NC' },
  { value: 'Ash Flat_AR', label: 'Ash Flat, AR' },
  { value: 'Ash Fork_AZ', label: 'Ash Fork, AZ' },
  { value: 'Ash Grove_MO', label: 'Ash Grove, MO' },
  { value: 'Ashaway_RI', label: 'Ashaway, RI' },
  { value: 'Ashburn_VA', label: 'Ashburn, VA' },
  { value: 'Ashburn_GA', label: 'Ashburn, GA' },
  { value: 'Ashburn_MO', label: 'Ashburn, MO' },
  { value: 'Ashburnham_MA', label: 'Ashburnham, MA' },
  { value: 'Ashby_MA', label: 'Ashby, MA' },
  { value: 'Ashby_MN', label: 'Ashby, MN' },
  { value: 'Ashby_NE', label: 'Ashby, NE' },
  { value: 'Ashcamp_KY', label: 'Ashcamp, KY' },
  { value: 'Ashdown_AR', label: 'Ashdown, AR' },
  { value: 'Asheboro_NC', label: 'Asheboro, NC' },
  { value: 'Asher_KY', label: 'Asher, KY' },
  { value: 'Asher_OK', label: 'Asher, OK' },
  { value: 'Asherton_TX', label: 'Asherton, TX' },
  { value: 'Asheville_NC', label: 'Asheville, NC' },
  { value: 'Ashfield_MA', label: 'Ashfield, MA' },
  { value: 'Ashfield_PA', label: 'Ashfield, PA' },
  { value: 'Ashford_CT', label: 'Ashford, CT' },
  { value: 'Ashford_WV', label: 'Ashford, WV' },
  { value: 'Ashford_AL', label: 'Ashford, AL' },
  { value: 'Ashford_WA', label: 'Ashford, WA' },
  { value: 'Ashippun_WI', label: 'Ashippun, WI' },
  { value: 'Ashkum_IL', label: 'Ashkum, IL' },
  { value: 'Ashland_MA', label: 'Ashland, MA' },
  { value: 'Ashland_NH', label: 'Ashland, NH' },
  { value: 'Ashland_ME', label: 'Ashland, ME' },
  { value: 'Ashland_NY', label: 'Ashland, NY' },
  { value: 'Ashland_PA', label: 'Ashland, PA' },
  { value: 'Ashland_VA', label: 'Ashland, VA' },
  { value: 'Ashland_AL', label: 'Ashland, AL' },
  { value: 'Ashland_MS', label: 'Ashland, MS' },
  { value: 'Ashland_KY', label: 'Ashland, KY' },
  { value: 'Ashland_OH', label: 'Ashland, OH' },
  { value: 'Ashland_WI', label: 'Ashland, WI' },
  { value: 'Ashland_MT', label: 'Ashland, MT' },
  { value: 'Ashland_IL', label: 'Ashland, IL' },
  { value: 'Ashland_MO', label: 'Ashland, MO' },
  { value: 'Ashland_KS', label: 'Ashland, KS' },
  { value: 'Ashland_NE', label: 'Ashland, NE' },
  { value: 'Ashland_LA', label: 'Ashland, LA' },
  { value: 'Ashland_OR', label: 'Ashland, OR' },
  { value: 'Ashland City_TN', label: 'Ashland City, TN' },
  { value: 'Ashley_OH', label: 'Ashley, OH' },
  { value: 'Ashley_IN', label: 'Ashley, IN' },
  { value: 'Ashley_MI', label: 'Ashley, MI' },
  { value: 'Ashley_ND', label: 'Ashley, ND' },
  { value: 'Ashley_IL', label: 'Ashley, IL' },
  { value: 'Ashley Falls_MA', label: 'Ashley Falls, MA' },
  { value: 'Ashmore_IL', label: 'Ashmore, IL' },
  { value: 'Ashtabula_OH', label: 'Ashtabula, OH' },
  { value: 'Ashton_MD', label: 'Ashton, MD' },
  { value: 'Ashton_WV', label: 'Ashton, WV' },
  { value: 'Ashton_IA', label: 'Ashton, IA' },
  { value: 'Ashton_SD', label: 'Ashton, SD' },
  { value: 'Ashton_IL', label: 'Ashton, IL' },
  { value: 'Ashton_NE', label: 'Ashton, NE' },
  { value: 'Ashton_ID', label: 'Ashton, ID' },
  { value: 'Ashuelot_NH', label: 'Ashuelot, NH' },
  { value: 'Ashville_NY', label: 'Ashville, NY' },
  { value: 'Ashville_PA', label: 'Ashville, PA' },
  { value: 'Ashville_AL', label: 'Ashville, AL' },
  { value: 'Ashville_OH', label: 'Ashville, OH' },
  { value: 'Ashwood_OR', label: 'Ashwood, OR' },
  { value: 'Askov_MN', label: 'Askov, MN' },
  { value: 'Asotin_WA', label: 'Asotin, WA' },
  { value: 'Aspen_CO', label: 'Aspen, CO' },
  { value: 'Aspermont_TX', label: 'Aspermont, TX' },
  { value: 'Aspers_PA', label: 'Aspers, PA' },
  { value: 'Aspinwall_IA', label: 'Aspinwall, IA' },
  { value: 'Assaria_KS', label: 'Assaria, KS' },
  { value: 'Assawoman_VA', label: 'Assawoman, VA' },
  { value: 'Assonet_MA', label: 'Assonet, MA' },
  { value: 'Assumption_IL', label: 'Assumption, IL' },
  { value: 'Astatula_FL', label: 'Astatula, FL' },
  { value: 'Aston_PA', label: 'Aston, PA' },
  { value: 'Astor_FL', label: 'Astor, FL' },
  { value: 'Astoria_NY', label: 'Astoria, NY' },
  { value: 'Astoria_SD', label: 'Astoria, SD' },
  { value: 'Astoria_IL', label: 'Astoria, IL' },
  { value: 'Astoria_OR', label: 'Astoria, OR' },
  { value: 'Atalissa_IA', label: 'Atalissa, IA' },
  { value: 'Atascadero_CA', label: 'Atascadero, CA' },
  { value: 'Atascosa_TX', label: 'Atascosa, TX' },
  { value: 'Atchison_KS', label: 'Atchison, KS' },
  { value: 'Atco_NJ', label: 'Atco, NJ' },
  { value: 'Atglen_PA', label: 'Atglen, PA' },
  { value: 'Athelstane_WI', label: 'Athelstane, WI' },
  { value: 'Athena_OR', label: 'Athena, OR' },
  { value: 'Athens_ME', label: 'Athens, ME' },
  { value: 'Athens_NY', label: 'Athens, NY' },
  { value: 'Athens_PA', label: 'Athens, PA' },
  { value: 'Athens_WV', label: 'Athens, WV' },
  { value: 'Athens_GA', label: 'Athens, GA' },
  { value: 'Athens_AL', label: 'Athens, AL' },
  { value: 'Athens_TN', label: 'Athens, TN' },
  { value: 'Athens_OH', label: 'Athens, OH' },
  { value: 'Athens_MI', label: 'Athens, MI' },
  { value: 'Athens_WI', label: 'Athens, WI' },
  { value: 'Athens_IL', label: 'Athens, IL' },
  { value: 'Athens_LA', label: 'Athens, LA' },
  { value: 'Athens_TX', label: 'Athens, TX' },
  { value: 'Atherton_CA', label: 'Atherton, CA' },
  { value: 'Athol_MA', label: 'Athol, MA' },
  { value: 'Athol_NY', label: 'Athol, NY' },
  { value: 'Athol_KS', label: 'Athol, KS' },
  { value: 'Athol_ID', label: 'Athol, ID' },
  { value: 'Atka_AK', label: 'Atka, AK' },
  { value: 'Atkins_VA', label: 'Atkins, VA' },
  { value: 'Atkins_IA', label: 'Atkins, IA' },
  { value: 'Atkins_AR', label: 'Atkins, AR' },
  { value: 'Atkinson_NH', label: 'Atkinson, NH' },
  { value: 'Atkinson_NC', label: 'Atkinson, NC' },
  { value: 'Atkinson_IL', label: 'Atkinson, IL' },
  { value: 'Atkinson_NE', label: 'Atkinson, NE' },
  { value: 'Atlanta_NY', label: 'Atlanta, NY' },
  { value: 'Atlanta_GA', label: 'Atlanta, GA' },
  { value: 'Atlanta_IN', label: 'Atlanta, IN' },
  { value: 'Atlanta_MI', label: 'Atlanta, MI' },
  { value: 'Atlanta_IL', label: 'Atlanta, IL' },
  { value: 'Atlanta_MO', label: 'Atlanta, MO' },
  { value: 'Atlanta_KS', label: 'Atlanta, KS' },
  { value: 'Atlanta_NE', label: 'Atlanta, NE' },
  { value: 'Atlanta_LA', label: 'Atlanta, LA' },
  { value: 'Atlanta_TX', label: 'Atlanta, TX' },
  { value: 'Atlantic_PA', label: 'Atlantic, PA' },
  { value: 'Atlantic_VA', label: 'Atlantic, VA' },
  { value: 'Atlantic_NC', label: 'Atlantic, NC' },
  { value: 'Atlantic_IA', label: 'Atlantic, IA' },
  { value: 'Atlantic Beach_NY', label: 'Atlantic Beach, NY' },
  { value: 'Atlantic Beach_NC', label: 'Atlantic Beach, NC' },
  { value: 'Atlantic Beach_FL', label: 'Atlantic Beach, FL' },
  { value: 'Atlantic City_NJ', label: 'Atlantic City, NJ' },
  { value: 'Atlantic Highlands_NJ', label: 'Atlantic Highlands, NJ' },
  { value: 'Atlantic Mine_MI', label: 'Atlantic Mine, MI' },
  { value: 'Atlas_MI', label: 'Atlas, MI' },
  { value: 'Atlasburg_PA', label: 'Atlasburg, PA' },
  { value: 'Atmore_AL', label: 'Atmore, AL' },
  { value: 'Atoka_TN', label: 'Atoka, TN' },
  { value: 'Atoka_OK', label: 'Atoka, OK' },
  { value: 'Atomic City_ID', label: 'Atomic City, ID' },
  { value: 'Atqasuk_AK', label: 'Atqasuk, AK' },
  { value: 'Attalla_AL', label: 'Attalla, AL' },
  { value: 'Attapulgus_GA', label: 'Attapulgus, GA' },
  { value: 'Attica_NY', label: 'Attica, NY' },
  { value: 'Attica_OH', label: 'Attica, OH' },
  { value: 'Attica_IN', label: 'Attica, IN' },
  { value: 'Attica_MI', label: 'Attica, MI' },
  { value: 'Attica_KS', label: 'Attica, KS' },
  { value: 'Attleboro_MA', label: 'Attleboro, MA' },
  { value: 'Attleboro Falls_MA', label: 'Attleboro Falls, MA' },
  { value: 'Atwater_OH', label: 'Atwater, OH' },
  { value: 'Atwater_MN', label: 'Atwater, MN' },
  { value: 'Atwater_CA', label: 'Atwater, CA' },
  { value: 'Atwood_TN', label: 'Atwood, TN' },
  { value: 'Atwood_IN', label: 'Atwood, IN' },
  { value: 'Atwood_IL', label: 'Atwood, IL' },
  { value: 'Atwood_KS', label: 'Atwood, KS' },
  { value: 'Atwood_OK', label: 'Atwood, OK' },
  { value: 'Atwood_CO', label: 'Atwood, CO' },
  { value: 'Au Gres_MI', label: 'Au Gres, MI' },
  { value: 'Au Sable Forks_NY', label: 'Au Sable Forks, NY' },
  { value: 'Au Train_MI', label: 'Au Train, MI' },
  { value: 'Auberry_CA', label: 'Auberry, CA' },
  { value: 'Aubrey_AR', label: 'Aubrey, AR' },
  { value: 'Aubrey_TX', label: 'Aubrey, TX' },
  { value: 'Auburn_MA', label: 'Auburn, MA' },
  { value: 'Auburn_NH', label: 'Auburn, NH' },
  { value: 'Auburn_ME', label: 'Auburn, ME' },
  { value: 'Auburn_NY', label: 'Auburn, NY' },
  { value: 'Auburn_PA', label: 'Auburn, PA' },
  { value: 'Auburn_WV', label: 'Auburn, WV' },
  { value: 'Auburn_GA', label: 'Auburn, GA' },
  { value: 'Auburn_AL', label: 'Auburn, AL' },
  { value: 'Auburn_KY', label: 'Auburn, KY' },
  { value: 'Auburn_IN', label: 'Auburn, IN' },
  { value: 'Auburn_MI', label: 'Auburn, MI' },
  { value: 'Auburn_IA', label: 'Auburn, IA' },
  { value: 'Auburn_IL', label: 'Auburn, IL' },
  { value: 'Auburn_KS', label: 'Auburn, KS' },
  { value: 'Auburn_NE', label: 'Auburn, NE' },
  { value: 'Auburn_WY', label: 'Auburn, WY' },
  { value: 'Auburn_CA', label: 'Auburn, CA' },
  { value: 'Auburn_WA', label: 'Auburn, WA' },
  { value: 'Auburn Hills_MI', label: 'Auburn Hills, MI' },
  { value: 'Auburn University_AL', label: 'Auburn University, AL' },
  { value: 'Auburndale_MA', label: 'Auburndale, MA' },
  { value: 'Auburndale_FL', label: 'Auburndale, FL' },
  { value: 'Auburndale_WI', label: 'Auburndale, WI' },
  { value: 'Auburntown_TN', label: 'Auburntown, TN' },
  { value: 'Audubon_NJ', label: 'Audubon, NJ' },
  { value: 'Audubon_IA', label: 'Audubon, IA' },
  { value: 'Audubon_MN', label: 'Audubon, MN' },
  { value: 'Augusta_ME', label: 'Augusta, ME' },
  { value: 'Augusta_NJ', label: 'Augusta, NJ' },
  { value: 'Augusta_WV', label: 'Augusta, WV' },
  { value: 'Augusta_GA', label: 'Augusta, GA' },
  { value: 'Augusta_KY', label: 'Augusta, KY' },
  { value: 'Augusta_OH', label: 'Augusta, OH' },
  { value: 'Augusta_MI', label: 'Augusta, MI' },
  { value: 'Augusta_WI', label: 'Augusta, WI' },
  { value: 'Augusta_MT', label: 'Augusta, MT' },
  { value: 'Augusta_IL', label: 'Augusta, IL' },
  { value: 'Augusta_MO', label: 'Augusta, MO' },
  { value: 'Augusta_KS', label: 'Augusta, KS' },
  { value: 'Augusta_AR', label: 'Augusta, AR' },
  { value: 'Augusta Springs_VA', label: 'Augusta Springs, VA' },
  { value: 'Aulander_NC', label: 'Aulander, NC' },
  { value: 'Ault_CO', label: 'Ault, CO' },
  { value: 'Aultman_PA', label: 'Aultman, PA' },
  { value: 'Aumsville_OR', label: 'Aumsville, OR' },
  { value: 'Aurelia_IA', label: 'Aurelia, IA' },
  { value: 'Aurora_ME', label: 'Aurora, ME' },
  { value: 'Aurora_NY', label: 'Aurora, NY' },
  { value: 'Aurora_WV', label: 'Aurora, WV' },
  { value: 'Aurora_NC', label: 'Aurora, NC' },
  { value: 'Aurora_OH', label: 'Aurora, OH' },
  { value: 'Aurora_IN', label: 'Aurora, IN' },
  { value: 'Aurora_IA', label: 'Aurora, IA' },
  { value: 'Aurora_MN', label: 'Aurora, MN' },
  { value: 'Aurora_SD', label: 'Aurora, SD' },
  { value: 'Aurora_IL', label: 'Aurora, IL' },
  { value: 'Aurora_MO', label: 'Aurora, MO' },
  { value: 'Aurora_KS', label: 'Aurora, KS' },
  { value: 'Aurora_NE', label: 'Aurora, NE' },
  { value: 'Aurora_CO', label: 'Aurora, CO' },
  { value: 'Aurora_UT', label: 'Aurora, UT' },
  { value: 'Aurora_OR', label: 'Aurora, OR' },
  { value: 'Austell_GA', label: 'Austell, GA' },
  { value: 'Austerlitz_NY', label: 'Austerlitz, NY' },
  { value: 'Austin_PA', label: 'Austin, PA' },
  { value: 'Austin_KY', label: 'Austin, KY' },
  { value: 'Austin_IN', label: 'Austin, IN' },
  { value: 'Austin_MN', label: 'Austin, MN' },
  { value: 'Austin_AR', label: 'Austin, AR' },
  { value: 'Austin_TX', label: 'Austin, TX' },
  { value: 'Austin_CO', label: 'Austin, CO' },
  { value: 'Austin_NV', label: 'Austin, NV' },
  { value: 'Austinburg_OH', label: 'Austinburg, OH' },
  { value: 'Austinville_VA', label: 'Austinville, VA' },
  { value: 'Austinville_IA', label: 'Austinville, IA' },
  { value: 'Austwell_TX', label: 'Austwell, TX' },
  { value: 'Autaugaville_AL', label: 'Autaugaville, AL' },
  { value: 'Autryville_NC', label: 'Autryville, NC' },
  { value: 'Auxier_KY', label: 'Auxier, KY' },
  { value: 'Auxvasse_MO', label: 'Auxvasse, MO' },
  { value: 'Ava_NY', label: 'Ava, NY' },
  { value: 'Ava_OH', label: 'Ava, OH' },
  { value: 'Ava_IL', label: 'Ava, IL' },
  { value: 'Ava_MO', label: 'Ava, MO' },
  { value: 'Avalon_NJ', label: 'Avalon, NJ' },
  { value: 'Avalon_WI', label: 'Avalon, WI' },
  { value: 'Avalon_TX', label: 'Avalon, TX' },
  { value: 'Avalon_CA', label: 'Avalon, CA' },
  { value: 'Avant_OK', label: 'Avant, OK' },
  { value: 'Avawam_KY', label: 'Avawam, KY' },
  { value: 'Avella_PA', label: 'Avella, PA' },
  { value: 'Avenal_CA', label: 'Avenal, CA' },
  { value: 'Avenel_NJ', label: 'Avenel, NJ' },
  { value: 'Avenue_MD', label: 'Avenue, MD' },
  { value: 'Avera_GA', label: 'Avera, GA' },
  { value: 'Averill_VT', label: 'Averill, VT' },
  { value: 'Averill Park_NY', label: 'Averill Park, NY' },
  { value: 'Avery_TX', label: 'Avery, TX' },
  { value: 'Avery_ID', label: 'Avery, ID' },
  { value: 'Avery_CA', label: 'Avery, CA' },
  { value: 'Avery Island_LA', label: 'Avery Island, LA' },
  { value: 'Avila Beach_CA', label: 'Avila Beach, CA' },
  { value: 'Avilla_IN', label: 'Avilla, IN' },
  { value: 'Avilla_MO', label: 'Avilla, MO' },
  { value: 'Avinger_TX', label: 'Avinger, TX' },
  { value: 'Avis_PA', label: 'Avis, PA' },
  { value: 'Aviston_IL', label: 'Aviston, IL' },
  { value: 'Avoca_NY', label: 'Avoca, NY' },
  { value: 'Avoca_IN', label: 'Avoca, IN' },
  { value: 'Avoca_MI', label: 'Avoca, MI' },
  { value: 'Avoca_IA', label: 'Avoca, IA' },
  { value: 'Avoca_WI', label: 'Avoca, WI' },
  { value: 'Avoca_MN', label: 'Avoca, MN' },
  { value: 'Avoca_NE', label: 'Avoca, NE' },
  { value: 'Avoca_TX', label: 'Avoca, TX' },
  { value: 'Avon_MA', label: 'Avon, MA' },
  { value: 'Avon_CT', label: 'Avon, CT' },
  { value: 'Avon_NY', label: 'Avon, NY' },
  { value: 'Avon_NC', label: 'Avon, NC' },
  { value: 'Avon_MS', label: 'Avon, MS' },
  { value: 'Avon_OH', label: 'Avon, OH' },
  { value: 'Avon_IN', label: 'Avon, IN' },
  { value: 'Avon_MN', label: 'Avon, MN' },
  { value: 'Avon_SD', label: 'Avon, SD' },
  { value: 'Avon_MT', label: 'Avon, MT' },
  { value: 'Avon_IL', label: 'Avon, IL' },
  { value: 'Avon_CO', label: 'Avon, CO' },
  { value: 'Avon By The Sea_NJ', label: 'Avon By The Sea, NJ' },
  { value: 'Avon Lake_OH', label: 'Avon Lake, OH' },
  { value: 'Avon Park_FL', label: 'Avon Park, FL' },
  { value: 'Avondale_PA', label: 'Avondale, PA' },
  { value: 'Avondale_WV', label: 'Avondale, WV' },
  { value: 'Avondale_CO', label: 'Avondale, CO' },
  { value: 'Avondale_AZ', label: 'Avondale, AZ' },
  { value: 'Avondale Estates_GA', label: 'Avondale Estates, GA' },
  { value: 'Avonmore_PA', label: 'Avonmore, PA' },
  { value: 'Awendaw_SC', label: 'Awendaw, SC' },
  { value: 'Axis_AL', label: 'Axis, AL' },
  { value: 'Axson_GA', label: 'Axson, GA' },
  { value: 'Axtell_KS', label: 'Axtell, KS' },
  { value: 'Axtell_NE', label: 'Axtell, NE' },
  { value: 'Axtell_TX', label: 'Axtell, TX' },
  { value: 'Axtell_UT', label: 'Axtell, UT' },
  { value: 'Axton_VA', label: 'Axton, VA' },
  { value: 'Ayden_NC', label: 'Ayden, NC' },
  { value: 'Aydlett_NC', label: 'Aydlett, NC' },
  { value: 'Ayer_MA', label: 'Ayer, MA' },
  { value: 'Aylett_VA', label: 'Aylett, VA' },
  { value: 'Aynor_SC', label: 'Aynor, SC' },
  { value: 'Ayr_ND', label: 'Ayr, ND' },
  { value: 'Ayr_NE', label: 'Ayr, NE' },
  { value: 'Ayrshire_IA', label: 'Ayrshire, IA' },
  { value: 'Azalea_OR', label: 'Azalea, OR' },
  { value: 'Azle_TX', label: 'Azle, TX' },
  { value: 'Aztec_NM', label: 'Aztec, NM' },
  { value: 'Azusa_CA', label: 'Azusa, CA' },
  { value: 'Babb_MT', label: 'Babb, MT' },
  { value: 'Babbitt_MN', label: 'Babbitt, MN' },
  { value: 'Babcock_WI', label: 'Babcock, WI' },
  { value: 'Babson Park_MA', label: 'Babson Park, MA' },
  { value: 'Babson Park_FL', label: 'Babson Park, FL' },
  { value: 'Babylon_NY', label: 'Babylon, NY' },
  { value: 'Backus_MN', label: 'Backus, MN' },
  { value: 'Bacliff_TX', label: 'Bacliff, TX' },
  { value: 'Baconton_GA', label: 'Baconton, GA' },
  { value: 'Bacova_VA', label: 'Bacova, VA' },
  { value: 'Bad Axe_MI', label: 'Bad Axe, MI' },
  { value: 'Baden_PA', label: 'Baden, PA' },
  { value: 'Badger_IA', label: 'Badger, IA' },
  { value: 'Badger_MN', label: 'Badger, MN' },
  { value: 'Badger_SD', label: 'Badger, SD' },
  { value: 'Badger_CA', label: 'Badger, CA' },
  { value: 'Badin_NC', label: 'Badin, NC' },
  { value: 'Bagdad_FL', label: 'Bagdad, FL' },
  { value: 'Bagdad_KY', label: 'Bagdad, KY' },
  { value: 'Bagdad_AZ', label: 'Bagdad, AZ' },
  { value: 'Baggs_WY', label: 'Baggs, WY' },
  { value: 'Bagley_IA', label: 'Bagley, IA' },
  { value: 'Bagley_WI', label: 'Bagley, WI' },
  { value: 'Bagley_MN', label: 'Bagley, MN' },
  { value: 'Bagwell_TX', label: 'Bagwell, TX' },
  { value: 'Bahama_NC', label: 'Bahama, NC' },
  { value: 'Bailey_NC', label: 'Bailey, NC' },
  { value: 'Bailey_MS', label: 'Bailey, MS' },
  { value: 'Bailey_MI', label: 'Bailey, MI' },
  { value: 'Bailey_TX', label: 'Bailey, TX' },
  { value: 'Bailey_CO', label: 'Bailey, CO' },
  { value: 'Bailey Island_ME', label: 'Bailey Island, ME' },
  { value: 'Baileys Harbor_WI', label: 'Baileys Harbor, WI' },
  { value: 'Baileyton_AL', label: 'Baileyton, AL' },
  { value: 'Baileyville_ME', label: 'Baileyville, ME' },
  { value: 'Baileyville_IL', label: 'Baileyville, IL' },
  { value: 'Baileyville_KS', label: 'Baileyville, KS' },
  { value: 'Bainbridge_NY', label: 'Bainbridge, NY' },
  { value: 'Bainbridge_PA', label: 'Bainbridge, PA' },
  { value: 'Bainbridge_GA', label: 'Bainbridge, GA' },
  { value: 'Bainbridge_OH', label: 'Bainbridge, OH' },
  { value: 'Bainbridge_IN', label: 'Bainbridge, IN' },
  { value: 'Bainbridge Island_WA', label: 'Bainbridge Island, WA' },
  { value: 'Bainville_MT', label: 'Bainville, MT' },
  { value: 'Baird_TX', label: 'Baird, TX' },
  { value: 'Bairdford_PA', label: 'Bairdford, PA' },
  { value: 'Bairoil_WY', label: 'Bairoil, WY' },
  { value: 'Baisden_WV', label: 'Baisden, WV' },
  { value: 'Bajadero_PR', label: 'Bajadero, PR' },
  { value: 'Baker_WV', label: 'Baker, WV' },
  { value: 'Baker_FL', label: 'Baker, FL' },
  { value: 'Baker_MT', label: 'Baker, MT' },
  { value: 'Baker_LA', label: 'Baker, LA' },
  { value: 'Baker_NV', label: 'Baker, NV' },
  { value: 'Baker_CA', label: 'Baker, CA' },
  { value: 'Baker City_OR', label: 'Baker City, OR' },
  { value: 'Bakers Mills_NY', label: 'Bakers Mills, NY' },
  { value: 'Bakersfield_VT', label: 'Bakersfield, VT' },
  { value: 'Bakersfield_MO', label: 'Bakersfield, MO' },
  { value: 'Bakersfield_CA', label: 'Bakersfield, CA' },
  { value: 'Bakerstown_PA', label: 'Bakerstown, PA' },
  { value: 'Bakersville_NC', label: 'Bakersville, NC' },
  { value: 'Bala Cynwyd_PA', label: 'Bala Cynwyd, PA' },
  { value: 'Balaton_MN', label: 'Balaton, MN' },
  { value: 'Balch Springs_TX', label: 'Balch Springs, TX' },
  { value: 'Bald Knob_AR', label: 'Bald Knob, AR' },
  { value: 'Baldwin_NY', label: 'Baldwin, NY' },
  { value: 'Baldwin_MD', label: 'Baldwin, MD' },
  { value: 'Baldwin_GA', label: 'Baldwin, GA' },
  { value: 'Baldwin_MI', label: 'Baldwin, MI' },
  { value: 'Baldwin_IA', label: 'Baldwin, IA' },
  { value: 'Baldwin_WI', label: 'Baldwin, WI' },
  { value: 'Baldwin_ND', label: 'Baldwin, ND' },
  { value: 'Baldwin_IL', label: 'Baldwin, IL' },
  { value: 'Baldwin_LA', label: 'Baldwin, LA' },
  { value: 'Baldwin City_KS', label: 'Baldwin City, KS' },
  { value: 'Baldwin Park_CA', label: 'Baldwin Park, CA' },
  { value: 'Baldwin Place_NY', label: 'Baldwin Place, NY' },
  { value: 'Baldwinsville_NY', label: 'Baldwinsville, NY' },
  { value: 'Baldwinville_MA', label: 'Baldwinville, MA' },
  { value: 'Baldwyn_MS', label: 'Baldwyn, MS' },
  { value: 'Balfour_ND', label: 'Balfour, ND' },
  { value: 'Balko_OK', label: 'Balko, OK' },
  { value: 'Ball_LA', label: 'Ball, LA' },
  { value: 'Ball Ground_GA', label: 'Ball Ground, GA' },
  { value: 'Ballantine_MT', label: 'Ballantine, MT' },
  { value: 'Ballard_WV', label: 'Ballard, WV' },
  { value: 'Ballico_CA', label: 'Ballico, CA' },
  { value: 'Ballinger_TX', label: 'Ballinger, TX' },
  { value: 'Ballston Lake_NY', label: 'Ballston Lake, NY' },
  { value: 'Ballston Spa_NY', label: 'Ballston Spa, NY' },
  { value: 'Ballwin_MO', label: 'Ballwin, MO' },
  { value: 'Bally_PA', label: 'Bally, PA' },
  { value: 'Balm_FL', label: 'Balm, FL' },
  { value: 'Balmorhea_TX', label: 'Balmorhea, TX' },
  { value: 'Balsam_NC', label: 'Balsam, NC' },
  { value: 'Balsam Grove_NC', label: 'Balsam Grove, NC' },
  { value: 'Balsam Lake_WI', label: 'Balsam Lake, WI' },
  { value: 'Balta_ND', label: 'Balta, ND' },
  { value: 'Baltic_CT', label: 'Baltic, CT' },
  { value: 'Baltic_OH', label: 'Baltic, OH' },
  { value: 'Baltic_SD', label: 'Baltic, SD' },
  { value: 'Baltimore_MD', label: 'Baltimore, MD' },
  { value: 'Baltimore_OH', label: 'Baltimore, OH' },
  { value: 'Bamberg_SC', label: 'Bamberg, SC' },
  { value: 'Banco_VA', label: 'Banco, VA' },
  { value: 'Bancroft_WV', label: 'Bancroft, WV' },
  { value: 'Bancroft_MI', label: 'Bancroft, MI' },
  { value: 'Bancroft_IA', label: 'Bancroft, IA' },
  { value: 'Bancroft_WI', label: 'Bancroft, WI' },
  { value: 'Bancroft_NE', label: 'Bancroft, NE' },
  { value: 'Bancroft_ID', label: 'Bancroft, ID' },
  { value: 'Bandana_KY', label: 'Bandana, KY' },
  { value: 'Bandera_TX', label: 'Bandera, TX' },
  { value: 'Bandon_OR', label: 'Bandon, OR' },
  { value: 'Bandy_VA', label: 'Bandy, VA' },
  { value: 'Bangor_ME', label: 'Bangor, ME' },
  { value: 'Bangor_PA', label: 'Bangor, PA' },
  { value: 'Bangor_MI', label: 'Bangor, MI' },
  { value: 'Bangor_WI', label: 'Bangor, WI' },
  { value: 'Bangor_CA', label: 'Bangor, CA' },
  { value: 'Bangs_TX', label: 'Bangs, TX' },
  { value: 'Banks_AL', label: 'Banks, AL' },
  { value: 'Banks_AR', label: 'Banks, AR' },
  { value: 'Banks_ID', label: 'Banks, ID' },
  { value: 'Banks_OR', label: 'Banks, OR' },
  { value: 'Bankston_AL', label: 'Bankston, AL' },
  { value: 'Banner_MS', label: 'Banner, MS' },
  { value: 'Banner_KY', label: 'Banner, KY' },
  { value: 'Banner_WY', label: 'Banner, WY' },
  { value: 'Banner Elk_NC', label: 'Banner Elk, NC' },
  { value: 'Banning_CA', label: 'Banning, CA' },
  { value: 'Bannister_MI', label: 'Bannister, MI' },
  { value: 'Bannock_OH', label: 'Bannock, OH' },
  { value: 'Banquete_TX', label: 'Banquete, TX' },
  { value: 'Bantam_CT', label: 'Bantam, CT' },
  { value: 'Bantry_ND', label: 'Bantry, ND' },
  { value: 'Bapchule_AZ', label: 'Bapchule, AZ' },
  { value: 'Bar Harbor_ME', label: 'Bar Harbor, ME' },
  { value: 'Baraboo_WI', label: 'Baraboo, WI' },
  { value: 'Baraga_MI', label: 'Baraga, MI' },
  { value: 'Barataria_LA', label: 'Barataria, LA' },
  { value: 'Barbeau_MI', label: 'Barbeau, MI' },
  { value: 'Barberton_OH', label: 'Barberton, OH' },
  { value: 'Barboursville_VA', label: 'Barboursville, VA' },
  { value: 'Barboursville_WV', label: 'Barboursville, WV' },
  { value: 'Barbourville_KY', label: 'Barbourville, KY' },
  { value: 'Barceloneta_PR', label: 'Barceloneta, PR' },
  { value: 'Barclay_MD', label: 'Barclay, MD' },
  { value: 'Barco_NC', label: 'Barco, NC' },
  { value: 'Bard_NM', label: 'Bard, NM' },
  { value: 'Bard_CA', label: 'Bard, CA' },
  { value: 'Bardolph_IL', label: 'Bardolph, IL' },
  { value: 'Bardstown_KY', label: 'Bardstown, KY' },
  { value: 'Bardwell_KY', label: 'Bardwell, KY' },
  { value: 'Bardwell_TX', label: 'Bardwell, TX' },
  { value: 'Bargersville_IN', label: 'Bargersville, IN' },
  { value: 'Barhamsville_VA', label: 'Barhamsville, VA' },
  { value: 'Baring_MO', label: 'Baring, MO' },
  { value: 'Baring_WA', label: 'Baring, WA' },
  { value: 'Bark River_MI', label: 'Bark River, MI' },
  { value: 'Barker_NY', label: 'Barker, NY' },
  { value: 'Barkhamsted_CT', label: 'Barkhamsted, CT' },
  { value: 'Barksdale_TX', label: 'Barksdale, TX' },
  { value: 'Barksdale Afb_LA', label: 'Barksdale Afb, LA' },
  { value: 'Barling_AR', label: 'Barling, AR' },
  { value: 'Barlow_KY', label: 'Barlow, KY' },
  { value: 'Barnard_VT', label: 'Barnard, VT' },
  { value: 'Barnard_SD', label: 'Barnard, SD' },
  { value: 'Barnard_MO', label: 'Barnard, MO' },
  { value: 'Barnard_KS', label: 'Barnard, KS' },
  { value: 'Barnardsville_NC', label: 'Barnardsville, NC' },
  { value: 'Barnegat_NJ', label: 'Barnegat, NJ' },
  { value: 'Barnegat Light_NJ', label: 'Barnegat Light, NJ' },
  { value: 'Barnes_KS', label: 'Barnes, KS' },
  { value: 'Barnes City_IA', label: 'Barnes City, IA' },
  { value: 'Barneston_NE', label: 'Barneston, NE' },
  { value: 'Barnesville_PA', label: 'Barnesville, PA' },
  { value: 'Barnesville_MD', label: 'Barnesville, MD' },
  { value: 'Barnesville_GA', label: 'Barnesville, GA' },
  { value: 'Barnesville_OH', label: 'Barnesville, OH' },
  { value: 'Barnesville_MN', label: 'Barnesville, MN' },
  { value: 'Barnet_VT', label: 'Barnet, VT' },
  { value: 'Barnett_MO', label: 'Barnett, MO' },
  { value: 'Barneveld_NY', label: 'Barneveld, NY' },
  { value: 'Barneveld_WI', label: 'Barneveld, WI' },
  { value: 'Barney_GA', label: 'Barney, GA' },
  { value: 'Barney_ND', label: 'Barney, ND' },
  { value: 'Barnhart_MO', label: 'Barnhart, MO' },
  { value: 'Barnhart_TX', label: 'Barnhart, TX' },
  { value: 'Barnhill_IL', label: 'Barnhill, IL' },
  { value: 'Barnsdall_OK', label: 'Barnsdall, OK' },
  { value: 'Barnstable_MA', label: 'Barnstable, MA' },
  { value: 'Barnstead_NH', label: 'Barnstead, NH' },
  { value: 'Barnum_IA', label: 'Barnum, IA' },
  { value: 'Barnum_MN', label: 'Barnum, MN' },
  { value: 'Barnwell_SC', label: 'Barnwell, SC' },
  { value: 'Baroda_MI', label: 'Baroda, MI' },
  { value: 'Barrackville_WV', label: 'Barrackville, WV' },
  { value: 'Barranquitas_PR', label: 'Barranquitas, PR' },
  { value: 'Barre_MA', label: 'Barre, MA' },
  { value: 'Barre_VT', label: 'Barre, VT' },
  { value: 'Barren Springs_VA', label: 'Barren Springs, VA' },
  { value: 'Barrett_MN', label: 'Barrett, MN' },
  { value: 'Barrigada_GU', label: 'Barrigada, GU' },
  { value: 'Barrington_RI', label: 'Barrington, RI' },
  { value: 'Barrington_NH', label: 'Barrington, NH' },
  { value: 'Barrington_NJ', label: 'Barrington, NJ' },
  { value: 'Barrington_IL', label: 'Barrington, IL' },
  { value: 'Barron_WI', label: 'Barron, WI' },
  { value: 'Barronett_WI', label: 'Barronett, WI' },
  { value: 'Barrow_AK', label: 'Barrow, AK' },
  { value: 'Barry_MN', label: 'Barry, MN' },
  { value: 'Barry_IL', label: 'Barry, IL' },
  { value: 'Barry_TX', label: 'Barry, TX' },
  { value: 'Barryton_MI', label: 'Barryton, MI' },
  { value: 'Barrytown_NY', label: 'Barrytown, NY' },
  { value: 'Barryville_NY', label: 'Barryville, NY' },
  { value: 'Barstow_IL', label: 'Barstow, IL' },
  { value: 'Barstow_TX', label: 'Barstow, TX' },
  { value: 'Barstow_CA', label: 'Barstow, CA' },
  { value: 'Bartelso_IL', label: 'Bartelso, IL' },
  { value: 'Bartlesville_OK', label: 'Bartlesville, OK' },
  { value: 'Bartlett_NH', label: 'Bartlett, NH' },
  { value: 'Bartlett_IL', label: 'Bartlett, IL' },
  { value: 'Bartlett_KS', label: 'Bartlett, KS' },
  { value: 'Bartlett_NE', label: 'Bartlett, NE' },
  { value: 'Bartlett_TX', label: 'Bartlett, TX' },
  { value: 'Bartley_WV', label: 'Bartley, WV' },
  { value: 'Bartley_NE', label: 'Bartley, NE' },
  { value: 'Barto_PA', label: 'Barto, PA' },
  { value: 'Barton_VT', label: 'Barton, VT' },
  { value: 'Barton_NY', label: 'Barton, NY' },
  { value: 'Barton_MD', label: 'Barton, MD' },
  { value: 'Barton_OH', label: 'Barton, OH' },
  { value: 'Barton City_MI', label: 'Barton City, MI' },
  { value: 'Bartonsville_PA', label: 'Bartonsville, PA' },
  { value: 'Bartow_WV', label: 'Bartow, WV' },
  { value: 'Bartow_GA', label: 'Bartow, GA' },
  { value: 'Bartow_FL', label: 'Bartow, FL' },
  { value: 'Barwick_GA', label: 'Barwick, GA' },
  { value: 'Basalt_CO', label: 'Basalt, CO' },
  { value: 'Basalt_ID', label: 'Basalt, ID' },
  { value: 'Basco_IL', label: 'Basco, IL' },
  { value: 'Bascom_FL', label: 'Bascom, FL' },
  { value: 'Bascom_OH', label: 'Bascom, OH' },
  { value: 'Basehor_KS', label: 'Basehor, KS' },
  { value: 'Basile_LA', label: 'Basile, LA' },
  { value: 'Basin_MT', label: 'Basin, MT' },
  { value: 'Basin_WY', label: 'Basin, WY' },
  { value: 'Baskerville_VA', label: 'Baskerville, VA' },
  { value: 'Baskin_LA', label: 'Baskin, LA' },
  { value: 'Basking Ridge_NJ', label: 'Basking Ridge, NJ' },
  { value: 'Basom_NY', label: 'Basom, NY' },
  { value: 'Bass Harbor_ME', label: 'Bass Harbor, ME' },
  { value: 'Bass Lake_CA', label: 'Bass Lake, CA' },
  { value: 'Bassett_VA', label: 'Bassett, VA' },
  { value: 'Bassett_NE', label: 'Bassett, NE' },
  { value: 'Bassett_AR', label: 'Bassett, AR' },
  { value: 'Bassfield_MS', label: 'Bassfield, MS' },
  { value: 'Bastian_VA', label: 'Bastian, VA' },
  { value: 'Bastrop_LA', label: 'Bastrop, LA' },
  { value: 'Bastrop_TX', label: 'Bastrop, TX' },
  { value: 'Basye_VA', label: 'Basye, VA' },
  { value: 'Bat Cave_NC', label: 'Bat Cave, NC' },
  { value: 'Batavia_NY', label: 'Batavia, NY' },
  { value: 'Batavia_OH', label: 'Batavia, OH' },
  { value: 'Batavia_IA', label: 'Batavia, IA' },
  { value: 'Batavia_IL', label: 'Batavia, IL' },
  { value: 'Batchelor_LA', label: 'Batchelor, LA' },
  { value: 'Batchtown_IL', label: 'Batchtown, IL' },
  { value: 'Bates_OR', label: 'Bates, OR' },
  { value: 'Bates City_MO', label: 'Bates City, MO' },
  { value: 'Batesburg_SC', label: 'Batesburg, SC' },
  { value: 'Batesland_SD', label: 'Batesland, SD' },
  { value: 'Batesville_MS', label: 'Batesville, MS' },
  { value: 'Batesville_IN', label: 'Batesville, IN' },
  { value: 'Batesville_AR', label: 'Batesville, AR' },
  { value: 'Batesville_TX', label: 'Batesville, TX' },
  { value: 'Bath_NH', label: 'Bath, NH' },
  { value: 'Bath_ME', label: 'Bath, ME' },
  { value: 'Bath_NY', label: 'Bath, NY' },
  { value: 'Bath_PA', label: 'Bath, PA' },
  { value: 'Bath_NC', label: 'Bath, NC' },
  { value: 'Bath_SC', label: 'Bath, SC' },
  { value: 'Bath_IN', label: 'Bath, IN' },
  { value: 'Bath_MI', label: 'Bath, MI' },
  { value: 'Bath_SD', label: 'Bath, SD' },
  { value: 'Bath_IL', label: 'Bath, IL' },
  { value: 'Bath Springs_TN', label: 'Bath Springs, TN' },
  { value: 'Bathgate_ND', label: 'Bathgate, ND' },
  { value: 'Baton Rouge_LA', label: 'Baton Rouge, LA' },
  { value: 'Batson_TX', label: 'Batson, TX' },
  { value: 'Battery Park_VA', label: 'Battery Park, VA' },
  { value: 'Battiest_OK', label: 'Battiest, OK' },
  { value: 'Battle Creek_MI', label: 'Battle Creek, MI' },
  { value: 'Battle Creek_IA', label: 'Battle Creek, IA' },
  { value: 'Battle Creek_NE', label: 'Battle Creek, NE' },
  { value: 'Battle Ground_IN', label: 'Battle Ground, IN' },
  { value: 'Battle Ground_WA', label: 'Battle Ground, WA' },
  { value: 'Battle Lake_MN', label: 'Battle Lake, MN' },
  { value: 'Battle Mountain_NV', label: 'Battle Mountain, NV' },
  { value: 'Battleboro_NC', label: 'Battleboro, NC' },
  { value: 'Battletown_KY', label: 'Battletown, KY' },
  { value: 'Baudette_MN', label: 'Baudette, MN' },
  { value: 'Bausman_PA', label: 'Bausman, PA' },
  { value: 'Bauxite_AR', label: 'Bauxite, AR' },
  { value: 'Baxley_GA', label: 'Baxley, GA' },
  { value: 'Baxter_WV', label: 'Baxter, WV' },
  { value: 'Baxter_TN', label: 'Baxter, TN' },
  { value: 'Baxter_KY', label: 'Baxter, KY' },
  { value: 'Baxter_IA', label: 'Baxter, IA' },
  { value: 'Baxter_MN', label: 'Baxter, MN' },
  { value: 'Baxter Springs_KS', label: 'Baxter Springs, KS' },
  { value: 'Bay_AR', label: 'Bay, AR' },
  { value: 'Bay Center_WA', label: 'Bay Center, WA' },
  { value: 'Bay City_MI', label: 'Bay City, MI' },
  { value: 'Bay City_WI', label: 'Bay City, WI' },
  { value: 'Bay City_TX', label: 'Bay City, TX' },
  { value: 'Bay City_OR', label: 'Bay City, OR' },
  { value: 'Bay Minette_AL', label: 'Bay Minette, AL' },
  { value: 'Bay Pines_FL', label: 'Bay Pines, FL' },
  { value: 'Bay Port_MI', label: 'Bay Port, MI' },
  { value: 'Bay St Louis_MS', label: 'Bay St Louis, MS' },
  { value: 'Bay Shore_NY', label: 'Bay Shore, NY' },
  { value: 'Bay Springs_MS', label: 'Bay Springs, MS' },
  { value: 'Bay Village_OH', label: 'Bay Village, OH' },
  { value: 'Bayamon_PR', label: 'Bayamon, PR' },
  { value: 'Bayard_WV', label: 'Bayard, WV' },
  { value: 'Bayard_IA', label: 'Bayard, IA' },
  { value: 'Bayard_NE', label: 'Bayard, NE' },
  { value: 'Bayard_NM', label: 'Bayard, NM' },
  { value: 'Bayboro_NC', label: 'Bayboro, NC' },
  { value: 'Bayfield_WI', label: 'Bayfield, WI' },
  { value: 'Bayfield_CO', label: 'Bayfield, CO' },
  { value: 'Baylis_IL', label: 'Baylis, IL' },
  { value: 'Bayonne_NJ', label: 'Bayonne, NJ' },
  { value: 'Bayou La Batre_AL', label: 'Bayou La Batre, AL' },
  { value: 'Bayport_NY', label: 'Bayport, NY' },
  { value: 'Bayport_MN', label: 'Bayport, MN' },
  { value: 'Bays_KY', label: 'Bays, KY' },
  { value: 'Bayside_NY', label: 'Bayside, NY' },
  { value: 'Bayside_TX', label: 'Bayside, TX' },
  { value: 'Bayside_CA', label: 'Bayside, CA' },
  { value: 'Baytown_TX', label: 'Baytown, TX' },
  { value: 'Bayview_ID', label: 'Bayview, ID' },
  { value: 'Bayville_NJ', label: 'Bayville, NJ' },
  { value: 'Bayville_NY', label: 'Bayville, NY' },
  { value: 'Bazine_KS', label: 'Bazine, KS' },
  { value: 'Beach_ND', label: 'Beach, ND' },
  { value: 'Beach City_OH', label: 'Beach City, OH' },
  { value: 'Beach Haven_NJ', label: 'Beach Haven, NJ' },
  { value: 'Beach Lake_PA', label: 'Beach Lake, PA' },
  { value: 'Beachwood_NJ', label: 'Beachwood, NJ' },
  { value: 'Beachwood_OH', label: 'Beachwood, OH' },
  { value: 'Beacon_NY', label: 'Beacon, NY' },
  { value: 'Beacon_IA', label: 'Beacon, IA' },
  { value: 'Beacon Falls_CT', label: 'Beacon Falls, CT' },
  { value: 'Beale Afb_CA', label: 'Beale Afb, CA' },
  { value: 'Bealeton_VA', label: 'Bealeton, VA' },
  { value: 'Beallsville_PA', label: 'Beallsville, PA' },
  { value: 'Beallsville_MD', label: 'Beallsville, MD' },
  { value: 'Beallsville_OH', label: 'Beallsville, OH' },
  { value: 'Beals_ME', label: 'Beals, ME' },
  { value: 'Beaman_IA', label: 'Beaman, IA' },
  { value: 'Bean Station_TN', label: 'Bean Station, TN' },
  { value: 'Bear_DE', label: 'Bear, DE' },
  { value: 'Bear Branch_KY', label: 'Bear Branch, KY' },
  { value: 'Bear Creek_PA', label: 'Bear Creek, PA' },
  { value: 'Bear Creek_NC', label: 'Bear Creek, NC' },
  { value: 'Bear Creek_AL', label: 'Bear Creek, AL' },
  { value: 'Bear Creek_WI', label: 'Bear Creek, WI' },
  { value: 'Bear Lake_PA', label: 'Bear Lake, PA' },
  { value: 'Bear Lake_MI', label: 'Bear Lake, MI' },
  { value: 'Bear Mountain_NY', label: 'Bear Mountain, NY' },
  { value: 'Bear River City_UT', label: 'Bear River City, UT' },
  { value: 'Bearcreek_MT', label: 'Bearcreek, MT' },
  { value: 'Bearden_AR', label: 'Bearden, AR' },
  { value: 'Beardsley_MN', label: 'Beardsley, MN' },
  { value: 'Beardstown_IL', label: 'Beardstown, IL' },
  { value: 'Bearsville_NY', label: 'Bearsville, NY' },
  { value: 'Beasley_TX', label: 'Beasley, TX' },
  { value: 'Beason_IL', label: 'Beason, IL' },
  { value: 'Beatrice_AL', label: 'Beatrice, AL' },
  { value: 'Beatrice_NE', label: 'Beatrice, NE' },
  { value: 'Beattie_KS', label: 'Beattie, KS' },
  { value: 'Beatty_NV', label: 'Beatty, NV' },
  { value: 'Beatty_OR', label: 'Beatty, OR' },
  { value: 'Beattyville_KY', label: 'Beattyville, KY' },
  { value: 'Beaufort_NC', label: 'Beaufort, NC' },
  { value: 'Beaufort_SC', label: 'Beaufort, SC' },
  { value: 'Beaufort_MO', label: 'Beaufort, MO' },
  { value: 'Beaumont_MS', label: 'Beaumont, MS' },
  { value: 'Beaumont_KY', label: 'Beaumont, KY' },
  { value: 'Beaumont_KS', label: 'Beaumont, KS' },
  { value: 'Beaumont_TX', label: 'Beaumont, TX' },
  { value: 'Beaumont_CA', label: 'Beaumont, CA' },
  { value: 'Beauty_KY', label: 'Beauty, KY' },
  { value: 'Beaver_PA', label: 'Beaver, PA' },
  { value: 'Beaver_WV', label: 'Beaver, WV' },
  { value: 'Beaver_KY', label: 'Beaver, KY' },
  { value: 'Beaver_OH', label: 'Beaver, OH' },
  { value: 'Beaver_IA', label: 'Beaver, IA' },
  { value: 'Beaver_OK', label: 'Beaver, OK' },
  { value: 'Beaver_UT', label: 'Beaver, UT' },
  { value: 'Beaver_OR', label: 'Beaver, OR' },
  { value: 'Beaver_WA', label: 'Beaver, WA' },
  { value: 'Beaver_AK', label: 'Beaver, AK' },
  { value: 'Beaver Bay_MN', label: 'Beaver Bay, MN' },
  { value: 'Beaver City_NE', label: 'Beaver City, NE' },
  { value: 'Beaver Creek_MN', label: 'Beaver Creek, MN' },
  { value: 'Beaver Crossing_NE', label: 'Beaver Crossing, NE' },
  { value: 'Beaver Dam_KY', label: 'Beaver Dam, KY' },
  { value: 'Beaver Dam_WI', label: 'Beaver Dam, WI' },
  { value: 'Beaver Dams_NY', label: 'Beaver Dams, NY' },
  { value: 'Beaver Falls_NY', label: 'Beaver Falls, NY' },
  { value: 'Beaver Falls_PA', label: 'Beaver Falls, PA' },
  { value: 'Beaver Island_MI', label: 'Beaver Island, MI' },
  { value: 'Beaver Meadows_PA', label: 'Beaver Meadows, PA' },
  { value: 'Beaver Springs_PA', label: 'Beaver Springs, PA' },
  { value: 'Beavercreek_OR', label: 'Beavercreek, OR' },
  { value: 'Beaverdale_PA', label: 'Beaverdale, PA' },
  { value: 'Beaverdam_VA', label: 'Beaverdam, VA' },
  { value: 'Beaverdam_OH', label: 'Beaverdam, OH' },
  { value: 'Beaverton_AL', label: 'Beaverton, AL' },
  { value: 'Beaverton_MI', label: 'Beaverton, MI' },
  { value: 'Beaverton_OR', label: 'Beaverton, OR' },
  { value: 'Beavertown_PA', label: 'Beavertown, PA' },
  { value: 'Beaverville_IL', label: 'Beaverville, IL' },
  { value: 'Beccaria_PA', label: 'Beccaria, PA' },
  { value: 'Bechtelsville_PA', label: 'Bechtelsville, PA' },
  { value: 'Beckemeyer_IL', label: 'Beckemeyer, IL' },
  { value: 'Becker_MN', label: 'Becker, MN' },
  { value: 'Becket_MA', label: 'Becket, MA' },
  { value: 'Beckley_WV', label: 'Beckley, WV' },
  { value: 'Beckville_TX', label: 'Beckville, TX' },
  { value: 'Beckwourth_CA', label: 'Beckwourth, CA' },
  { value: 'Bedford_MA', label: 'Bedford, MA' },
  { value: 'Bedford_NH', label: 'Bedford, NH' },
  { value: 'Bedford_NY', label: 'Bedford, NY' },
  { value: 'Bedford_PA', label: 'Bedford, PA' },
  { value: 'Bedford_VA', label: 'Bedford, VA' },
  { value: 'Bedford_KY', label: 'Bedford, KY' },
  { value: 'Bedford_OH', label: 'Bedford, OH' },
  { value: 'Bedford_IN', label: 'Bedford, IN' },
  { value: 'Bedford_IA', label: 'Bedford, IA' },
  { value: 'Bedford_TX', label: 'Bedford, TX' },
  { value: 'Bedford_WY', label: 'Bedford, WY' },
  { value: 'Bedford Hills_NY', label: 'Bedford Hills, NY' },
  { value: 'Bedias_TX', label: 'Bedias, TX' },
  { value: 'Bedminster_NJ', label: 'Bedminster, NJ' },
  { value: 'Bedrock_CO', label: 'Bedrock, CO' },
  { value: 'Bee_VA', label: 'Bee, VA' },
  { value: 'Bee_NE', label: 'Bee, NE' },
  { value: 'Bee Branch_AR', label: 'Bee Branch, AR' },
  { value: 'Bee Spring_KY', label: 'Bee Spring, KY' },
  { value: 'Beebe_AR', label: 'Beebe, AR' },
  { value: 'Beech Bluff_TN', label: 'Beech Bluff, TN' },
  { value: 'Beech Bottom_WV', label: 'Beech Bottom, WV' },
  { value: 'Beech Creek_PA', label: 'Beech Creek, PA' },
  { value: 'Beech Creek_KY', label: 'Beech Creek, KY' },
  { value: 'Beech Grove_KY', label: 'Beech Grove, KY' },
  { value: 'Beech Grove_IN', label: 'Beech Grove, IN' },
  { value: 'Beech Grove_AR', label: 'Beech Grove, AR' },
  { value: 'Beech Island_SC', label: 'Beech Island, SC' },
  { value: 'Beecher_IL', label: 'Beecher, IL' },
  { value: 'Beecher City_IL', label: 'Beecher City, IL' },
  { value: 'Beecher Falls_VT', label: 'Beecher Falls, VT' },
  { value: 'Beechgrove_TN', label: 'Beechgrove, TN' },
  { value: 'Beechmont_KY', label: 'Beechmont, KY' },
  { value: 'Beedeville_AR', label: 'Beedeville, AR' },
  { value: 'Beeler_KS', label: 'Beeler, KS' },
  { value: 'Beemer_NE', label: 'Beemer, NE' },
  { value: 'Beersheba Springs_TN', label: 'Beersheba Springs, TN' },
  { value: 'Beeson_WV', label: 'Beeson, WV' },
  { value: 'Beetown_WI', label: 'Beetown, WI' },
  { value: 'Beeville_TX', label: 'Beeville, TX' },
  { value: 'Beggs_OK', label: 'Beggs, OK' },
  { value: 'Beirne_AR', label: 'Beirne, AR' },
  { value: 'Bejou_MN', label: 'Bejou, MN' },
  { value: 'Bel Air_MD', label: 'Bel Air, MD' },
  { value: 'Bel Alton_MD', label: 'Bel Alton, MD' },
  { value: 'Belcamp_MD', label: 'Belcamp, MD' },
  { value: 'Belcher_KY', label: 'Belcher, KY' },
  { value: 'Belcher_LA', label: 'Belcher, LA' },
  { value: 'Belchertown_MA', label: 'Belchertown, MA' },
  { value: 'Belcourt_ND', label: 'Belcourt, ND' },
  { value: 'Belden_MS', label: 'Belden, MS' },
  { value: 'Belden_NE', label: 'Belden, NE' },
  { value: 'Belden_CA', label: 'Belden, CA' },
  { value: 'Beldenville_WI', label: 'Beldenville, WI' },
  { value: 'Belding_MI', label: 'Belding, MI' },
  { value: 'Belen_NM', label: 'Belen, NM' },
  { value: 'Belews Creek_NC', label: 'Belews Creek, NC' },
  { value: 'Belfair_WA', label: 'Belfair, WA' },
  { value: 'Belfast_ME', label: 'Belfast, ME' },
  { value: 'Belfast_NY', label: 'Belfast, NY' },
  { value: 'Belfast_TN', label: 'Belfast, TN' },
  { value: 'Belfield_ND', label: 'Belfield, ND' },
  { value: 'Belford_NJ', label: 'Belford, NJ' },
  { value: 'Belfry_KY', label: 'Belfry, KY' },
  { value: 'Belfry_MT', label: 'Belfry, MT' },
  { value: 'Belgium_WI', label: 'Belgium, WI' },
  { value: 'Belgrade_ME', label: 'Belgrade, ME' },
  { value: 'Belgrade_MN', label: 'Belgrade, MN' },
  { value: 'Belgrade_MT', label: 'Belgrade, MT' },
  { value: 'Belgrade_MO', label: 'Belgrade, MO' },
  { value: 'Belgrade_NE', label: 'Belgrade, NE' },
  { value: 'Belgrade Lakes_ME', label: 'Belgrade Lakes, ME' },
  { value: 'Belhaven_NC', label: 'Belhaven, NC' },
  { value: 'Belington_WV', label: 'Belington, WV' },
  { value: 'Belk_AL', label: 'Belk, AL' },
  { value: 'Belknap_IL', label: 'Belknap, IL' },
  { value: 'Bell_FL', label: 'Bell, FL' },
  { value: 'Bell Buckle_TN', label: 'Bell Buckle, TN' },
  { value: 'Bell City_MO', label: 'Bell City, MO' },
  { value: 'Bell City_LA', label: 'Bell City, LA' },
  { value: 'Bell Gardens_CA', label: 'Bell Gardens, CA' },
  { value: 'Bella Vista_AR', label: 'Bella Vista, AR' },
  { value: 'Bella Vista_CA', label: 'Bella Vista, CA' },
  { value: 'Bellaire_OH', label: 'Bellaire, OH' },
  { value: 'Bellaire_MI', label: 'Bellaire, MI' },
  { value: 'Bellaire_TX', label: 'Bellaire, TX' },
  { value: 'Bellamy_AL', label: 'Bellamy, AL' },
  { value: 'Bellbrook_OH', label: 'Bellbrook, OH' },
  { value: 'Belle_WV', label: 'Belle, WV' },
  { value: 'Belle_MO', label: 'Belle, MO' },
  { value: 'Belle Center_OH', label: 'Belle Center, OH' },
  { value: 'Belle Chasse_LA', label: 'Belle Chasse, LA' },
  { value: 'Belle Fourche_SD', label: 'Belle Fourche, SD' },
  { value: 'Belle Glade_FL', label: 'Belle Glade, FL' },
  { value: 'Belle Haven_VA', label: 'Belle Haven, VA' },
  { value: 'Belle Mead_NJ', label: 'Belle Mead, NJ' },
  { value: 'Belle Mina_AL', label: 'Belle Mina, AL' },
  { value: 'Belle Plaine_IA', label: 'Belle Plaine, IA' },
  { value: 'Belle Plaine_MN', label: 'Belle Plaine, MN' },
  { value: 'Belle Plaine_KS', label: 'Belle Plaine, KS' },
  { value: 'Belle Rive_IL', label: 'Belle Rive, IL' },
  { value: 'Belle Rose_LA', label: 'Belle Rose, LA' },
  { value: 'Belle Valley_OH', label: 'Belle Valley, OH' },
  { value: 'Belle Vernon_PA', label: 'Belle Vernon, PA' },
  { value: 'Belleair Beach_FL', label: 'Belleair Beach, FL' },
  { value: 'Bellefontaine_MS', label: 'Bellefontaine, MS' },
  { value: 'Bellefontaine_OH', label: 'Bellefontaine, OH' },
  { value: 'Bellefonte_PA', label: 'Bellefonte, PA' },
  { value: 'Bellemont_AZ', label: 'Bellemont, AZ' },
  { value: 'Bellerose_NY', label: 'Bellerose, NY' },
  { value: 'Belleview_FL', label: 'Belleview, FL' },
  { value: 'Belleview_MO', label: 'Belleview, MO' },
  { value: 'Belleville_NJ', label: 'Belleville, NJ' },
  { value: 'Belleville_NY', label: 'Belleville, NY' },
  { value: 'Belleville_PA', label: 'Belleville, PA' },
  { value: 'Belleville_WV', label: 'Belleville, WV' },
  { value: 'Belleville_MI', label: 'Belleville, MI' },
  { value: 'Belleville_WI', label: 'Belleville, WI' },
  { value: 'Belleville_IL', label: 'Belleville, IL' },
  { value: 'Belleville_KS', label: 'Belleville, KS' },
  { value: 'Belleville_AR', label: 'Belleville, AR' },
  { value: 'Bellevue_KY', label: 'Bellevue, KY' },
  { value: 'Bellevue_OH', label: 'Bellevue, OH' },
  { value: 'Bellevue_MI', label: 'Bellevue, MI' },
  { value: 'Bellevue_IA', label: 'Bellevue, IA' },
  { value: 'Bellevue_NE', label: 'Bellevue, NE' },
  { value: 'Bellevue_TX', label: 'Bellevue, TX' },
  { value: 'Bellevue_ID', label: 'Bellevue, ID' },
  { value: 'Bellevue_WA', label: 'Bellevue, WA' },
  { value: 'Bellflower_IL', label: 'Bellflower, IL' },
  { value: 'Bellflower_MO', label: 'Bellflower, MO' },
  { value: 'Bellflower_CA', label: 'Bellflower, CA' },
  { value: 'Bellingham_MA', label: 'Bellingham, MA' },
  { value: 'Bellingham_MN', label: 'Bellingham, MN' },
  { value: 'Bellingham_WA', label: 'Bellingham, WA' },
  { value: 'Bellmawr_NJ', label: 'Bellmawr, NJ' },
  { value: 'Bellmont_IL', label: 'Bellmont, IL' },
  { value: 'Bellmore_NY', label: 'Bellmore, NY' },
  { value: 'Bellona_NY', label: 'Bellona, NY' },
  { value: 'Bellows Falls_VT', label: 'Bellows Falls, VT' },
  { value: 'Bellport_NY', label: 'Bellport, NY' },
  { value: 'Bells_TN', label: 'Bells, TN' },
  { value: 'Bells_TX', label: 'Bells, TX' },
  { value: 'Bellville_GA', label: 'Bellville, GA' },
  { value: 'Bellville_OH', label: 'Bellville, OH' },
  { value: 'Bellville_TX', label: 'Bellville, TX' },
  { value: 'Bellvue_CO', label: 'Bellvue, CO' },
  { value: 'Bellwood_PA', label: 'Bellwood, PA' },
  { value: 'Bellwood_AL', label: 'Bellwood, AL' },
  { value: 'Bellwood_IL', label: 'Bellwood, IL' },
  { value: 'Bellwood_NE', label: 'Bellwood, NE' },
  { value: 'Belmar_NJ', label: 'Belmar, NJ' },
  { value: 'Belmond_IA', label: 'Belmond, IA' },
  { value: 'Belmont_MA', label: 'Belmont, MA' },
  { value: 'Belmont_NH', label: 'Belmont, NH' },
  { value: 'Belmont_VT', label: 'Belmont, VT' },
  { value: 'Belmont_NY', label: 'Belmont, NY' },
  { value: 'Belmont_WV', label: 'Belmont, WV' },
  { value: 'Belmont_NC', label: 'Belmont, NC' },
  { value: 'Belmont_MS', label: 'Belmont, MS' },
  { value: 'Belmont_OH', label: 'Belmont, OH' },
  { value: 'Belmont_MI', label: 'Belmont, MI' },
  { value: 'Belmont_WI', label: 'Belmont, WI' },
  { value: 'Belmont_LA', label: 'Belmont, LA' },
  { value: 'Belmont_CA', label: 'Belmont, CA' },
  { value: 'Belmore_OH', label: 'Belmore, OH' },
  { value: 'Beloit_OH', label: 'Beloit, OH' },
  { value: 'Beloit_WI', label: 'Beloit, WI' },
  { value: 'Beloit_KS', label: 'Beloit, KS' },
  { value: 'Belpre_OH', label: 'Belpre, OH' },
  { value: 'Belpre_KS', label: 'Belpre, KS' },
  { value: 'Belsano_PA', label: 'Belsano, PA' },
  { value: 'Belspring_VA', label: 'Belspring, VA' },
  { value: 'Belt_MT', label: 'Belt, MT' },
  { value: 'Belton_SC', label: 'Belton, SC' },
  { value: 'Belton_KY', label: 'Belton, KY' },
  { value: 'Belton_MO', label: 'Belton, MO' },
  { value: 'Belton_TX', label: 'Belton, TX' },
  { value: 'Beltrami_MN', label: 'Beltrami, MN' },
  { value: 'Beltsville_MD', label: 'Beltsville, MD' },
  { value: 'Belva_WV', label: 'Belva, WV' },
  { value: 'Belvedere Tiburon_CA', label: 'Belvedere Tiburon, CA' },
  { value: 'Belvidere_NJ', label: 'Belvidere, NJ' },
  { value: 'Belvidere_NC', label: 'Belvidere, NC' },
  { value: 'Belvidere_TN', label: 'Belvidere, TN' },
  { value: 'Belvidere_SD', label: 'Belvidere, SD' },
  { value: 'Belvidere_IL', label: 'Belvidere, IL' },
  { value: 'Belvidere_NE', label: 'Belvidere, NE' },
  { value: 'Belvidere Center_VT', label: 'Belvidere Center, VT' },
  { value: 'Belview_MN', label: 'Belview, MN' },
  { value: 'Belvue_KS', label: 'Belvue, KS' },
  { value: 'Belzoni_MS', label: 'Belzoni, MS' },
  { value: 'Bement_IL', label: 'Bement, IL' },
  { value: 'Bemidji_MN', label: 'Bemidji, MN' },
  { value: 'Bemus Point_NY', label: 'Bemus Point, NY' },
  { value: 'Ben Bolt_TX', label: 'Ben Bolt, TX' },
  { value: 'Ben Franklin_TX', label: 'Ben Franklin, TX' },
  { value: 'Ben Lomond_AR', label: 'Ben Lomond, AR' },
  { value: 'Ben Lomond_CA', label: 'Ben Lomond, CA' },
  { value: 'Ben Wheeler_TX', label: 'Ben Wheeler, TX' },
  { value: 'Bena_MN', label: 'Bena, MN' },
  { value: 'Benavides_TX', label: 'Benavides, TX' },
  { value: 'Bend_TX', label: 'Bend, TX' },
  { value: 'Bend_OR', label: 'Bend, OR' },
  { value: 'Bendena_KS', label: 'Bendena, KS' },
  { value: 'Bendersville_PA', label: 'Bendersville, PA' },
  { value: 'Benedict_MD', label: 'Benedict, MD' },
  { value: 'Benedict_ND', label: 'Benedict, ND' },
  { value: 'Benedict_KS', label: 'Benedict, KS' },
  { value: 'Benedict_NE', label: 'Benedict, NE' },
  { value: 'Benedicta_ME', label: 'Benedicta, ME' },
  { value: 'Benezett_PA', label: 'Benezett, PA' },
  { value: 'Benge_WA', label: 'Benge, WA' },
  { value: 'Benham_KY', label: 'Benham, KY' },
  { value: 'Benicia_CA', label: 'Benicia, CA' },
  { value: 'Benjamin_TX', label: 'Benjamin, TX' },
  { value: 'Benkelman_NE', label: 'Benkelman, NE' },
  { value: 'Benld_IL', label: 'Benld, IL' },
  { value: 'Bennet_NE', label: 'Bennet, NE' },
  { value: 'Bennett_NC', label: 'Bennett, NC' },
  { value: 'Bennett_IA', label: 'Bennett, IA' },
  { value: 'Bennett_CO', label: 'Bennett, CO' },
  { value: 'Bennettsville_SC', label: 'Bennettsville, SC' },
  { value: 'Bennington_NH', label: 'Bennington, NH' },
  { value: 'Bennington_VT', label: 'Bennington, VT' },
  { value: 'Bennington_IN', label: 'Bennington, IN' },
  { value: 'Bennington_KS', label: 'Bennington, KS' },
  { value: 'Bennington_NE', label: 'Bennington, NE' },
  { value: 'Bennington_OK', label: 'Bennington, OK' },
  { value: 'Benoit_MS', label: 'Benoit, MS' },
  { value: 'Bensalem_PA', label: 'Bensalem, PA' },
  { value: 'Bensenville_IL', label: 'Bensenville, IL' },
  { value: 'Benson_VT', label: 'Benson, VT' },
  { value: 'Benson_NC', label: 'Benson, NC' },
  { value: 'Benson_MN', label: 'Benson, MN' },
  { value: 'Benson_IL', label: 'Benson, IL' },
  { value: 'Benson_AZ', label: 'Benson, AZ' },
  { value: 'Bent_NM', label: 'Bent, NM' },
  { value: 'Bent Mountain_VA', label: 'Bent Mountain, VA' },
  { value: 'Bentley_MI', label: 'Bentley, MI' },
  { value: 'Bentley_KS', label: 'Bentley, KS' },
  { value: 'Bentley_LA', label: 'Bentley, LA' },
  { value: 'Bentleyville_PA', label: 'Bentleyville, PA' },
  { value: 'Benton_PA', label: 'Benton, PA' },
  { value: 'Benton_TN', label: 'Benton, TN' },
  { value: 'Benton_MS', label: 'Benton, MS' },
  { value: 'Benton_KY', label: 'Benton, KY' },
  { value: 'Benton_IA', label: 'Benton, IA' },
  { value: 'Benton_WI', label: 'Benton, WI' },
  { value: 'Benton_IL', label: 'Benton, IL' },
  { value: 'Benton_MO', label: 'Benton, MO' },
  { value: 'Benton_KS', label: 'Benton, KS' },
  { value: 'Benton_LA', label: 'Benton, LA' },
  { value: 'Benton_AR', label: 'Benton, AR' },
  { value: 'Benton_CA', label: 'Benton, CA' },
  { value: 'Benton City_MO', label: 'Benton City, MO' },
  { value: 'Benton City_WA', label: 'Benton City, WA' },
  { value: 'Benton Harbor_MI', label: 'Benton Harbor, MI' },
  { value: 'Benton Ridge_OH', label: 'Benton Ridge, OH' },
  { value: 'Bentonia_MS', label: 'Bentonia, MS' },
  { value: 'Bentonville_VA', label: 'Bentonville, VA' },
  { value: 'Bentonville_OH', label: 'Bentonville, OH' },
  { value: 'Bentonville_AR', label: 'Bentonville, AR' },
  { value: 'Benwood_WV', label: 'Benwood, WV' },
  { value: 'Benzonia_MI', label: 'Benzonia, MI' },
  { value: 'Berclair_TX', label: 'Berclair, TX' },
  { value: 'Berea_WV', label: 'Berea, WV' },
  { value: 'Berea_KY', label: 'Berea, KY' },
  { value: 'Berea_OH', label: 'Berea, OH' },
  { value: 'Beresford_SD', label: 'Beresford, SD' },
  { value: 'Bergen_NY', label: 'Bergen, NY' },
  { value: 'Bergenfield_NJ', label: 'Bergenfield, NJ' },
  { value: 'Berger_MO', label: 'Berger, MO' },
  { value: 'Bergheim_TX', label: 'Bergheim, TX' },
  { value: 'Bergholz_OH', label: 'Bergholz, OH' },
  { value: 'Bergland_MI', label: 'Bergland, MI' },
  { value: 'Bergman_AR', label: 'Bergman, AR' },
  { value: 'Bergoo_WV', label: 'Bergoo, WV' },
  { value: 'Bergton_VA', label: 'Bergton, VA' },
  { value: 'Berino_NM', label: 'Berino, NM' },
  { value: 'Berkeley_IL', label: 'Berkeley, IL' },
  { value: 'Berkeley_CA', label: 'Berkeley, CA' },
  { value: 'Berkeley Heights_NJ', label: 'Berkeley Heights, NJ' },
  { value: 'Berkeley Springs_WV', label: 'Berkeley Springs, WV' },
  { value: 'Berkey_OH', label: 'Berkey, OH' },
  { value: 'Berkley_MA', label: 'Berkley, MA' },
  { value: 'Berkley_MI', label: 'Berkley, MI' },
  { value: 'Berkshire_MA', label: 'Berkshire, MA' },
  { value: 'Berkshire_NY', label: 'Berkshire, NY' },
  { value: 'Berlin_MA', label: 'Berlin, MA' },
  { value: 'Berlin_NH', label: 'Berlin, NH' },
  { value: 'Berlin_CT', label: 'Berlin, CT' },
  { value: 'Berlin_NJ', label: 'Berlin, NJ' },
  { value: 'Berlin_NY', label: 'Berlin, NY' },
  { value: 'Berlin_PA', label: 'Berlin, PA' },
  { value: 'Berlin_MD', label: 'Berlin, MD' },
  { value: 'Berlin_GA', label: 'Berlin, GA' },
  { value: 'Berlin_OH', label: 'Berlin, OH' },
  { value: 'Berlin_WI', label: 'Berlin, WI' },
  { value: 'Berlin_ND', label: 'Berlin, ND' },
  { value: 'Berlin Center_OH', label: 'Berlin Center, OH' },
  { value: 'Berlin Heights_OH', label: 'Berlin Heights, OH' },
  { value: 'Bern_KS', label: 'Bern, KS' },
  { value: 'Bern_ID', label: 'Bern, ID' },
  { value: 'Bernalillo_NM', label: 'Bernalillo, NM' },
  { value: 'Bernard_ME', label: 'Bernard, ME' },
  { value: 'Bernard_IA', label: 'Bernard, IA' },
  { value: 'Bernardston_MA', label: 'Bernardston, MA' },
  { value: 'Bernardsville_NJ', label: 'Bernardsville, NJ' },
  { value: 'Berne_NY', label: 'Berne, NY' },
  { value: 'Berne_IN', label: 'Berne, IN' },
  { value: 'Bernhards Bay_NY', label: 'Bernhards Bay, NY' },
  { value: 'Bernice_LA', label: 'Bernice, LA' },
  { value: 'Bernie_MO', label: 'Bernie, MO' },
  { value: 'Bernville_PA', label: 'Bernville, PA' },
  { value: 'Berrien Center_MI', label: 'Berrien Center, MI' },
  { value: 'Berrien Springs_MI', label: 'Berrien Springs, MI' },
  { value: 'Berry_AL', label: 'Berry, AL' },
  { value: 'Berry_KY', label: 'Berry, KY' },
  { value: 'Berry Creek_CA', label: 'Berry Creek, CA' },
  { value: 'Berrysburg_PA', label: 'Berrysburg, PA' },
  { value: 'Berryton_KS', label: 'Berryton, KS' },
  { value: 'Berryville_VA', label: 'Berryville, VA' },
  { value: 'Berryville_AR', label: 'Berryville, AR' },
  { value: 'Bertha_MN', label: 'Bertha, MN' },
  { value: 'Berthold_ND', label: 'Berthold, ND' },
  { value: 'Berthoud_CO', label: 'Berthoud, CO' },
  { value: 'Bertram_TX', label: 'Bertram, TX' },
  { value: 'Bertrand_MO', label: 'Bertrand, MO' },
  { value: 'Bertrand_NE', label: 'Bertrand, NE' },
  { value: 'Berwick_ME', label: 'Berwick, ME' },
  { value: 'Berwick_PA', label: 'Berwick, PA' },
  { value: 'Berwick_IA', label: 'Berwick, IA' },
  { value: 'Berwick_IL', label: 'Berwick, IL' },
  { value: 'Berwick_LA', label: 'Berwick, LA' },
  { value: 'Berwind_WV', label: 'Berwind, WV' },
  { value: 'Berwyn_PA', label: 'Berwyn, PA' },
  { value: 'Berwyn_IL', label: 'Berwyn, IL' },
  { value: 'Beryl_UT', label: 'Beryl, UT' },
  { value: 'Bessemer_PA', label: 'Bessemer, PA' },
  { value: 'Bessemer_AL', label: 'Bessemer, AL' },
  { value: 'Bessemer_MI', label: 'Bessemer, MI' },
  { value: 'Bessemer City_NC', label: 'Bessemer City, NC' },
  { value: 'Bessie_OK', label: 'Bessie, OK' },
  { value: 'Bethalto_IL', label: 'Bethalto, IL' },
  { value: 'Bethany_CT', label: 'Bethany, CT' },
  { value: 'Bethany_WV', label: 'Bethany, WV' },
  { value: 'Bethany_IL', label: 'Bethany, IL' },
  { value: 'Bethany_MO', label: 'Bethany, MO' },
  { value: 'Bethany_LA', label: 'Bethany, LA' },
  { value: 'Bethany_OK', label: 'Bethany, OK' },
  { value: 'Bethany Beach_DE', label: 'Bethany Beach, DE' },
  { value: 'Bethel_ME', label: 'Bethel, ME' },
  { value: 'Bethel_VT', label: 'Bethel, VT' },
  { value: 'Bethel_CT', label: 'Bethel, CT' },
  { value: 'Bethel_NY', label: 'Bethel, NY' },
  { value: 'Bethel_PA', label: 'Bethel, PA' },
  { value: 'Bethel_DE', label: 'Bethel, DE' },
  { value: 'Bethel_NC', label: 'Bethel, NC' },
  { value: 'Bethel_OH', label: 'Bethel, OH' },
  { value: 'Bethel_MN', label: 'Bethel, MN' },
  { value: 'Bethel_MO', label: 'Bethel, MO' },
  { value: 'Bethel_OK', label: 'Bethel, OK' },
  { value: 'Bethel_AK', label: 'Bethel, AK' },
  { value: 'Bethel Island_CA', label: 'Bethel Island, CA' },
  { value: 'Bethel Park_PA', label: 'Bethel Park, PA' },
  { value: 'Bethel Springs_TN', label: 'Bethel Springs, TN' },
  { value: 'Bethelridge_KY', label: 'Bethelridge, KY' },
  { value: 'Bethesda_MD', label: 'Bethesda, MD' },
  { value: 'Bethesda_OH', label: 'Bethesda, OH' },
  { value: 'Bethlehem_NH', label: 'Bethlehem, NH' },
  { value: 'Bethlehem_CT', label: 'Bethlehem, CT' },
  { value: 'Bethlehem_PA', label: 'Bethlehem, PA' },
  { value: 'Bethlehem_GA', label: 'Bethlehem, GA' },
  { value: 'Bethlehem_KY', label: 'Bethlehem, KY' },
  { value: 'Bethlehem_IN', label: 'Bethlehem, IN' },
  { value: 'Bethpage_NY', label: 'Bethpage, NY' },
  { value: 'Bethpage_TN', label: 'Bethpage, TN' },
  { value: 'Bethune_SC', label: 'Bethune, SC' },
  { value: 'Bethune_CO', label: 'Bethune, CO' },
  { value: 'Betsy Layne_KY', label: 'Betsy Layne, KY' },
  { value: 'Bettendorf_IA', label: 'Bettendorf, IA' },
  { value: 'Betterton_MD', label: 'Betterton, MD' },
  { value: 'Bettles Field_AK', label: 'Bettles Field, AK' },
  { value: 'Bettsville_OH', label: 'Bettsville, OH' },
  { value: 'Beulah_MS', label: 'Beulah, MS' },
  { value: 'Beulah_MI', label: 'Beulah, MI' },
  { value: 'Beulah_ND', label: 'Beulah, ND' },
  { value: 'Beulah_MO', label: 'Beulah, MO' },
  { value: 'Beulah_CO', label: 'Beulah, CO' },
  { value: 'Beulah_WY', label: 'Beulah, WY' },
  { value: 'Beulaville_NC', label: 'Beulaville, NC' },
  { value: 'Beverly_MA', label: 'Beverly, MA' },
  { value: 'Beverly_NJ', label: 'Beverly, NJ' },
  { value: 'Beverly_WV', label: 'Beverly, WV' },
  { value: 'Beverly_KY', label: 'Beverly, KY' },
  { value: 'Beverly_OH', label: 'Beverly, OH' },
  { value: 'Beverly_KS', label: 'Beverly, KS' },
  { value: 'Beverly_WA', label: 'Beverly, WA' },
  { value: 'Beverly Hills_FL', label: 'Beverly Hills, FL' },
  { value: 'Beverly Hills_CA', label: 'Beverly Hills, CA' },
  { value: 'Beverly Shores_IN', label: 'Beverly Shores, IN' },
  { value: 'Bevier_MO', label: 'Bevier, MO' },
  { value: 'Bevington_IA', label: 'Bevington, IA' },
  { value: 'Bevinsville_KY', label: 'Bevinsville, KY' },
  { value: 'Bexar_AR', label: 'Bexar, AR' },
  { value: 'Beyer_PA', label: 'Beyer, PA' },
  { value: 'Bickleton_WA', label: 'Bickleton, WA' },
  { value: 'Bickmore_WV', label: 'Bickmore, WV' },
  { value: 'Bicknell_IN', label: 'Bicknell, IN' },
  { value: 'Bicknell_UT', label: 'Bicknell, UT' },
  { value: 'Biddeford_ME', label: 'Biddeford, ME' },
  { value: 'Biddeford Pool_ME', label: 'Biddeford Pool, ME' },
  { value: 'Biddle_MT', label: 'Biddle, MT' },
  { value: 'Bidwell_OH', label: 'Bidwell, OH' },
  { value: 'Bieber_CA', label: 'Bieber, CA' },
  { value: 'Bienville_LA', label: 'Bienville, LA' },
  { value: 'Big Arm_MT', label: 'Big Arm, MT' },
  { value: 'Big Bar_CA', label: 'Big Bar, CA' },
  { value: 'Big Bay_MI', label: 'Big Bay, MI' },
  { value: 'Big Bear City_CA', label: 'Big Bear City, CA' },
  { value: 'Big Bear Lake_CA', label: 'Big Bear Lake, CA' },
  { value: 'Big Bend_WV', label: 'Big Bend, WV' },
  { value: 'Big Bend_WI', label: 'Big Bend, WI' },
  { value: 'Big Bend_CA', label: 'Big Bend, CA' },
  { value: 'Big Bend National Park_TX', label: 'Big Bend National Park, TX' },
  { value: 'Big Cabin_OK', label: 'Big Cabin, OK' },
  { value: 'Big Clifty_KY', label: 'Big Clifty, KY' },
  { value: 'Big Cove Tannery_PA', label: 'Big Cove Tannery, PA' },
  { value: 'Big Creek_WV', label: 'Big Creek, WV' },
  { value: 'Big Creek_MS', label: 'Big Creek, MS' },
  { value: 'Big Creek_KY', label: 'Big Creek, KY' },
  { value: 'Big Creek_CA', label: 'Big Creek, CA' },
  { value: 'Big Falls_WI', label: 'Big Falls, WI' },
  { value: 'Big Falls_MN', label: 'Big Falls, MN' },
  { value: 'Big Flat_AR', label: 'Big Flat, AR' },
  { value: 'Big Flats_NY', label: 'Big Flats, NY' },
  { value: 'Big Horn_WY', label: 'Big Horn, WY' },
  { value: 'Big Indian_NY', label: 'Big Indian, NY' },
  { value: 'Big Island_VA', label: 'Big Island, VA' },
  { value: 'Big Lake_MN', label: 'Big Lake, MN' },
  { value: 'Big Lake_TX', label: 'Big Lake, TX' },
  { value: 'Big Lake_AK', label: 'Big Lake, AK' },
  { value: 'Big Laurel_KY', label: 'Big Laurel, KY' },
  { value: 'Big Oak Flat_CA', label: 'Big Oak Flat, CA' },
  { value: 'Big Pine_CA', label: 'Big Pine, CA' },
  { value: 'Big Pine Key_FL', label: 'Big Pine Key, FL' },
  { value: 'Big Piney_WY', label: 'Big Piney, WY' },
  { value: 'Big Pool_MD', label: 'Big Pool, MD' },
  { value: 'Big Prairie_OH', label: 'Big Prairie, OH' },
  { value: 'Big Rapids_MI', label: 'Big Rapids, MI' },
  { value: 'Big Rock_VA', label: 'Big Rock, VA' },
  { value: 'Big Rock_TN', label: 'Big Rock, TN' },
  { value: 'Big Rock_IL', label: 'Big Rock, IL' },
  { value: 'Big Run_PA', label: 'Big Run, PA' },
  { value: 'Big Sandy_WV', label: 'Big Sandy, WV' },
  { value: 'Big Sandy_TN', label: 'Big Sandy, TN' },
  { value: 'Big Sandy_MT', label: 'Big Sandy, MT' },
  { value: 'Big Sandy_TX', label: 'Big Sandy, TX' },
  { value: 'Big Sky_MT', label: 'Big Sky, MT' },
  { value: 'Big Spring_TX', label: 'Big Spring, TX' },
  { value: 'Big Springs_WV', label: 'Big Springs, WV' },
  { value: 'Big Springs_NE', label: 'Big Springs, NE' },
  { value: 'Big Stone City_SD', label: 'Big Stone City, SD' },
  { value: 'Big Stone Gap_VA', label: 'Big Stone Gap, VA' },
  { value: 'Big Sur_CA', label: 'Big Sur, CA' },
  { value: 'Big Timber_MT', label: 'Big Timber, MT' },
  { value: 'Big Wells_TX', label: 'Big Wells, TX' },
  { value: 'Bigelow_MN', label: 'Bigelow, MN' },
  { value: 'Bigelow_AR', label: 'Bigelow, AR' },
  { value: 'Bigfoot_TX', label: 'Bigfoot, TX' },
  { value: 'Bigfork_MN', label: 'Bigfork, MN' },
  { value: 'Bigfork_MT', label: 'Bigfork, MT' },
  { value: 'Biggers_AR', label: 'Biggers, AR' },
  { value: 'Biggs_CA', label: 'Biggs, CA' },
  { value: 'Biggsville_IL', label: 'Biggsville, IL' },
  { value: 'Bighorn_MT', label: 'Bighorn, MT' },
  { value: 'Bigler_PA', label: 'Bigler, PA' },
  { value: 'Biglerville_PA', label: 'Biglerville, PA' },
  { value: 'Billerica_MA', label: 'Billerica, MA' },
  { value: 'Billings_MT', label: 'Billings, MT' },
  { value: 'Billings_MO', label: 'Billings, MO' },
  { value: 'Billings_OK', label: 'Billings, OK' },
  { value: 'Billingsley_AL', label: 'Billingsley, AL' },
  { value: 'Biloxi_MS', label: 'Biloxi, MS' },
  { value: 'Bim_WV', label: 'Bim, WV' },
  { value: 'Bimble_KY', label: 'Bimble, KY' },
  { value: 'Binford_ND', label: 'Binford, ND' },
  { value: 'Bingen_WA', label: 'Bingen, WA' },
  { value: 'Binger_OK', label: 'Binger, OK' },
  { value: 'Bingham_ME', label: 'Bingham, ME' },
  { value: 'Bingham_IL', label: 'Bingham, IL' },
  { value: 'Bingham_NE', label: 'Bingham, NE' },
  { value: 'Bingham Canyon_UT', label: 'Bingham Canyon, UT' },
  { value: 'Bingham Lake_MN', label: 'Bingham Lake, MN' },
  { value: 'Binghamton_NY', label: 'Binghamton, NY' },
  { value: 'Biola_CA', label: 'Biola, CA' },
  { value: 'Birch Harbor_ME', label: 'Birch Harbor, ME' },
  { value: 'Birch River_WV', label: 'Birch River, WV' },
  { value: 'Birch Run_MI', label: 'Birch Run, MI' },
  { value: 'Birch Tree_MO', label: 'Birch Tree, MO' },
  { value: 'Birchdale_MN', label: 'Birchdale, MN' },
  { value: 'Birchleaf_VA', label: 'Birchleaf, VA' },
  { value: 'Birchwood_TN', label: 'Birchwood, TN' },
  { value: 'Birchwood_WI', label: 'Birchwood, WI' },
  { value: 'Bird City_KS', label: 'Bird City, KS' },
  { value: 'Bird In Hand_PA', label: 'Bird In Hand, PA' },
  { value: 'Bird Island_MN', label: 'Bird Island, MN' },
  { value: 'Birds Landing_CA', label: 'Birds Landing, CA' },
  { value: 'Birdsboro_PA', label: 'Birdsboro, PA' },
  { value: 'Birdseye_IN', label: 'Birdseye, IN' },
  { value: 'Birdsnest_VA', label: 'Birdsnest, VA' },
  { value: 'Birmingham_NJ', label: 'Birmingham, NJ' },
  { value: 'Birmingham_AL', label: 'Birmingham, AL' },
  { value: 'Birmingham_MI', label: 'Birmingham, MI' },
  { value: 'Birmingham_IA', label: 'Birmingham, IA' },
  { value: 'Birnamwood_WI', label: 'Birnamwood, WI' },
  { value: 'Birney_MT', label: 'Birney, MT' },
  { value: 'Bisbee_ND', label: 'Bisbee, ND' },
  { value: 'Bisbee_AZ', label: 'Bisbee, AZ' },
  { value: 'Biscoe_NC', label: 'Biscoe, NC' },
  { value: 'Biscoe_AR', label: 'Biscoe, AR' },
  { value: 'Bishop_VA', label: 'Bishop, VA' },
  { value: 'Bishop_GA', label: 'Bishop, GA' },
  { value: 'Bishop_TX', label: 'Bishop, TX' },
  { value: 'Bishop_CA', label: 'Bishop, CA' },
  { value: 'Bishop Hill_IL', label: 'Bishop Hill, IL' },
  { value: 'Bishopville_MD', label: 'Bishopville, MD' },
  { value: 'Bishopville_SC', label: 'Bishopville, SC' },
  { value: 'Bismarck_ND', label: 'Bismarck, ND' },
  { value: 'Bismarck_IL', label: 'Bismarck, IL' },
  { value: 'Bismarck_MO', label: 'Bismarck, MO' },
  { value: 'Bismarck_AR', label: 'Bismarck, AR' },
  { value: 'Bison_SD', label: 'Bison, SD' },
  { value: 'Bison_KS', label: 'Bison, KS' },
  { value: 'Bison_OK', label: 'Bison, OK' },
  { value: 'Bitely_MI', label: 'Bitely, MI' },
  { value: 'Bivalve_MD', label: 'Bivalve, MD' },
  { value: 'Bivins_TX', label: 'Bivins, TX' },
  { value: 'Biwabik_MN', label: 'Biwabik, MN' },
  { value: 'Bixby_MO', label: 'Bixby, MO' },
  { value: 'Bixby_OK', label: 'Bixby, OK' },
  { value: 'Blachly_OR', label: 'Blachly, OR' },
  { value: 'Black_AL', label: 'Black, AL' },
  { value: 'Black_MO', label: 'Black, MO' },
  { value: 'Black Canyon City_AZ', label: 'Black Canyon City, AZ' },
  { value: 'Black Creek_NY', label: 'Black Creek, NY' },
  { value: 'Black Creek_NC', label: 'Black Creek, NC' },
  { value: 'Black Creek_WI', label: 'Black Creek, WI' },
  { value: 'Black Diamond_WA', label: 'Black Diamond, WA' },
  { value: 'Black Eagle_MT', label: 'Black Eagle, MT' },
  { value: 'Black Earth_WI', label: 'Black Earth, WI' },
  { value: 'Black Hawk_SD', label: 'Black Hawk, SD' },
  { value: 'Black Hawk_CO', label: 'Black Hawk, CO' },
  { value: 'Black Lick_PA', label: 'Black Lick, PA' },
  { value: 'Black Mountain_NC', label: 'Black Mountain, NC' },
  { value: 'Black Oak_AR', label: 'Black Oak, AR' },
  { value: 'Black River_NY', label: 'Black River, NY' },
  { value: 'Black River_MI', label: 'Black River, MI' },
  { value: 'Black River Falls_WI', label: 'Black River Falls, WI' },
  { value: 'Black Rock_AR', label: 'Black Rock, AR' },
  { value: 'Blackburn_MO', label: 'Blackburn, MO' },
  { value: 'Blackduck_MN', label: 'Blackduck, MN' },
  { value: 'Blackey_KY', label: 'Blackey, KY' },
  { value: 'Blackfoot_ID', label: 'Blackfoot, ID' },
  { value: 'Blacklick_OH', label: 'Blacklick, OH' },
  { value: 'Blacksburg_VA', label: 'Blacksburg, VA' },
  { value: 'Blacksburg_SC', label: 'Blacksburg, SC' },
  { value: 'Blackshear_GA', label: 'Blackshear, GA' },
  { value: 'Blackstock_SC', label: 'Blackstock, SC' },
  { value: 'Blackstone_MA', label: 'Blackstone, MA' },
  { value: 'Blackstone_VA', label: 'Blackstone, VA' },
  { value: 'Blackstone_IL', label: 'Blackstone, IL' },
  { value: 'Blacksville_WV', label: 'Blacksville, WV' },
  { value: 'Blackville_SC', label: 'Blackville, SC' },
  { value: 'Blackwater_VA', label: 'Blackwater, VA' },
  { value: 'Blackwater_MO', label: 'Blackwater, MO' },
  { value: 'Blackwell_MO', label: 'Blackwell, MO' },
  { value: 'Blackwell_OK', label: 'Blackwell, OK' },
  { value: 'Blackwell_TX', label: 'Blackwell, TX' },
  { value: 'Blackwood_NJ', label: 'Blackwood, NJ' },
  { value: 'Bladen_NE', label: 'Bladen, NE' },
  { value: 'Bladenboro_NC', label: 'Bladenboro, NC' },
  { value: 'Bladensburg_MD', label: 'Bladensburg, MD' },
  { value: 'Bladensburg_OH', label: 'Bladensburg, OH' },
  { value: 'Blain_PA', label: 'Blain, PA' },
  { value: 'Blaine_ME', label: 'Blaine, ME' },
  { value: 'Blaine_TN', label: 'Blaine, TN' },
  { value: 'Blaine_KY', label: 'Blaine, KY' },
  { value: 'Blaine_OH', label: 'Blaine, OH' },
  { value: 'Blaine_WA', label: 'Blaine, WA' },
  { value: 'Blair_WV', label: 'Blair, WV' },
  { value: 'Blair_SC', label: 'Blair, SC' },
  { value: 'Blair_WI', label: 'Blair, WI' },
  { value: 'Blair_NE', label: 'Blair, NE' },
  { value: 'Blair_OK', label: 'Blair, OK' },
  { value: 'Blairs_VA', label: 'Blairs, VA' },
  { value: 'Blairs Mills_PA', label: 'Blairs Mills, PA' },
  { value: 'Blairsburg_IA', label: 'Blairsburg, IA' },
  { value: 'Blairsden Graeagle_CA', label: 'Blairsden Graeagle, CA' },
  { value: 'Blairstown_NJ', label: 'Blairstown, NJ' },
  { value: 'Blairstown_IA', label: 'Blairstown, IA' },
  { value: 'Blairstown_MO', label: 'Blairstown, MO' },
  { value: 'Blairsville_PA', label: 'Blairsville, PA' },
  { value: 'Blairsville_GA', label: 'Blairsville, GA' },
  { value: 'Blakely_GA', label: 'Blakely, GA' },
  { value: 'Blakely Island_WA', label: 'Blakely Island, WA' },
  { value: 'Blakesburg_IA', label: 'Blakesburg, IA' },
  { value: 'Blakeslee_PA', label: 'Blakeslee, PA' },
  { value: 'Blakeslee_OH', label: 'Blakeslee, OH' },
  { value: 'Blanca_CO', label: 'Blanca, CO' },
  { value: 'Blanch_NC', label: 'Blanch, NC' },
  { value: 'Blanchard_PA', label: 'Blanchard, PA' },
  { value: 'Blanchard_MI', label: 'Blanchard, MI' },
  { value: 'Blanchard_IA', label: 'Blanchard, IA' },
  { value: 'Blanchard_ND', label: 'Blanchard, ND' },
  { value: 'Blanchard_LA', label: 'Blanchard, LA' },
  { value: 'Blanchard_OK', label: 'Blanchard, OK' },
  { value: 'Blanchard_ID', label: 'Blanchard, ID' },
  { value: 'Blanchardville_WI', label: 'Blanchardville, WI' },
  { value: 'Blanchester_OH', label: 'Blanchester, OH' },
  { value: 'Blanco_OK', label: 'Blanco, OK' },
  { value: 'Blanco_TX', label: 'Blanco, TX' },
  { value: 'Blanco_NM', label: 'Blanco, NM' },
  { value: 'Bland_VA', label: 'Bland, VA' },
  { value: 'Bland_MO', label: 'Bland, MO' },
  { value: 'Blandburg_PA', label: 'Blandburg, PA' },
  { value: 'Blandford_MA', label: 'Blandford, MA' },
  { value: 'Blanding_UT', label: 'Blanding, UT' },
  { value: 'Blandinsville_IL', label: 'Blandinsville, IL' },
  { value: 'Blandon_PA', label: 'Blandon, PA' },
  { value: 'Blanford_IN', label: 'Blanford, IN' },
  { value: 'Blanket_TX', label: 'Blanket, TX' },
  { value: 'Blauvelt_NY', label: 'Blauvelt, NY' },
  { value: 'Bledsoe_KY', label: 'Bledsoe, KY' },
  { value: 'Bledsoe_TX', label: 'Bledsoe, TX' },
  { value: 'Bleiblerville_TX', label: 'Bleiblerville, TX' },
  { value: 'Blencoe_IA', label: 'Blencoe, IA' },
  { value: 'Blenheim_SC', label: 'Blenheim, SC' },
  { value: 'Blessing_TX', label: 'Blessing, TX' },
  { value: 'Blevins_AR', label: 'Blevins, AR' },
  { value: 'Bliss_NY', label: 'Bliss, NY' },
  { value: 'Bliss_ID', label: 'Bliss, ID' },
  { value: 'Blissfield_OH', label: 'Blissfield, OH' },
  { value: 'Blissfield_MI', label: 'Blissfield, MI' },
  { value: 'Block Island_RI', label: 'Block Island, RI' },
  { value: 'Blocksburg_CA', label: 'Blocksburg, CA' },
  { value: 'Blockton_IA', label: 'Blockton, IA' },
  { value: 'Blodgett_MO', label: 'Blodgett, MO' },
  { value: 'Blodgett_OR', label: 'Blodgett, OR' },
  { value: 'Blodgett Mills_NY', label: 'Blodgett Mills, NY' },
  { value: 'Blomkest_MN', label: 'Blomkest, MN' },
  { value: 'Bloomburg_TX', label: 'Bloomburg, TX' },
  { value: 'Bloomdale_OH', label: 'Bloomdale, OH' },
  { value: 'Bloomer_WI', label: 'Bloomer, WI' },
  { value: 'Bloomery_WV', label: 'Bloomery, WV' },
  { value: 'Bloomfield_CT', label: 'Bloomfield, CT' },
  { value: 'Bloomfield_NJ', label: 'Bloomfield, NJ' },
  { value: 'Bloomfield_NY', label: 'Bloomfield, NY' },
  { value: 'Bloomfield_KY', label: 'Bloomfield, KY' },
  { value: 'Bloomfield_IN', label: 'Bloomfield, IN' },
  { value: 'Bloomfield_IA', label: 'Bloomfield, IA' },
  { value: 'Bloomfield_MT', label: 'Bloomfield, MT' },
  { value: 'Bloomfield_MO', label: 'Bloomfield, MO' },
  { value: 'Bloomfield_NE', label: 'Bloomfield, NE' },
  { value: 'Bloomfield_NM', label: 'Bloomfield, NM' },
  { value: 'Bloomfield Hills_MI', label: 'Bloomfield Hills, MI' },
  { value: 'Blooming Grove_NY', label: 'Blooming Grove, NY' },
  { value: 'Blooming Grove_TX', label: 'Blooming Grove, TX' },
  { value: 'Blooming Prairie_MN', label: 'Blooming Prairie, MN' },
  { value: 'Bloomingburg_NY', label: 'Bloomingburg, NY' },
  { value: 'Bloomingburg_OH', label: 'Bloomingburg, OH' },
  { value: 'Bloomingdale_NJ', label: 'Bloomingdale, NJ' },
  { value: 'Bloomingdale_NY', label: 'Bloomingdale, NY' },
  { value: 'Bloomingdale_GA', label: 'Bloomingdale, GA' },
  { value: 'Bloomingdale_OH', label: 'Bloomingdale, OH' },
  { value: 'Bloomingdale_IN', label: 'Bloomingdale, IN' },
  { value: 'Bloomingdale_MI', label: 'Bloomingdale, MI' },
  { value: 'Bloomingdale_IL', label: 'Bloomingdale, IL' },
  { value: 'Bloomingrose_WV', label: 'Bloomingrose, WV' },
  { value: 'Bloomington_NY', label: 'Bloomington, NY' },
  { value: 'Bloomington_MD', label: 'Bloomington, MD' },
  { value: 'Bloomington_IN', label: 'Bloomington, IN' },
  { value: 'Bloomington_WI', label: 'Bloomington, WI' },
  { value: 'Bloomington_IL', label: 'Bloomington, IL' },
  { value: 'Bloomington_NE', label: 'Bloomington, NE' },
  { value: 'Bloomington_TX', label: 'Bloomington, TX' },
  { value: 'Bloomington_ID', label: 'Bloomington, ID' },
  { value: 'Bloomington_CA', label: 'Bloomington, CA' },
  { value: 'Bloomington Springs_TN', label: 'Bloomington Springs, TN' },
  { value: 'Bloomsburg_PA', label: 'Bloomsburg, PA' },
  { value: 'Bloomsbury_NJ', label: 'Bloomsbury, NJ' },
  { value: 'Bloomsdale_MO', label: 'Bloomsdale, MO' },
  { value: 'Bloomville_NY', label: 'Bloomville, NY' },
  { value: 'Bloomville_OH', label: 'Bloomville, OH' },
  { value: 'Blossburg_PA', label: 'Blossburg, PA' },
  { value: 'Blossom_TX', label: 'Blossom, TX' },
  { value: 'Blossvale_NY', label: 'Blossvale, NY' },
  { value: 'Blount_WV', label: 'Blount, WV' },
  { value: 'Blounts Creek_NC', label: 'Blounts Creek, NC' },
  { value: 'Blountstown_FL', label: 'Blountstown, FL' },
  { value: 'Blountsville_AL', label: 'Blountsville, AL' },
  { value: 'Blountville_TN', label: 'Blountville, TN' },
  { value: 'Blowing Rock_NC', label: 'Blowing Rock, NC' },
  { value: 'Bloxom_VA', label: 'Bloxom, VA' },
  { value: 'Blue_AZ', label: 'Blue, AZ' },
  { value: 'Blue Ball_PA', label: 'Blue Ball, PA' },
  { value: 'Blue Bell_PA', label: 'Blue Bell, PA' },
  { value: 'Blue Creek_OH', label: 'Blue Creek, OH' },
  { value: 'Blue Diamond_NV', label: 'Blue Diamond, NV' },
  { value: 'Blue Earth_MN', label: 'Blue Earth, MN' },
  { value: 'Blue Eye_MO', label: 'Blue Eye, MO' },
  { value: 'Blue Gap_AZ', label: 'Blue Gap, AZ' },
  { value: 'Blue Grass_VA', label: 'Blue Grass, VA' },
  { value: 'Blue Grass_IA', label: 'Blue Grass, IA' },
  { value: 'Blue Hill_ME', label: 'Blue Hill, ME' },
  { value: 'Blue Hill_NE', label: 'Blue Hill, NE' },
  { value: 'Blue Island_IL', label: 'Blue Island, IL' },
  { value: 'Blue Jay_CA', label: 'Blue Jay, CA' },
  { value: 'Blue Lake_CA', label: 'Blue Lake, CA' },
  { value: 'Blue Mound_IL', label: 'Blue Mound, IL' },
  { value: 'Blue Mound_KS', label: 'Blue Mound, KS' },
  { value: 'Blue Mounds_WI', label: 'Blue Mounds, WI' },
  { value: 'Blue Mountain_MS', label: 'Blue Mountain, MS' },
  { value: 'Blue Mountain_AR', label: 'Blue Mountain, AR' },
  { value: 'Blue Mountain Lake_NY', label: 'Blue Mountain Lake, NY' },
  { value: 'Blue Point_NY', label: 'Blue Point, NY' },
  { value: 'Blue Rapids_KS', label: 'Blue Rapids, KS' },
  { value: 'Blue Ridge_VA', label: 'Blue Ridge, VA' },
  { value: 'Blue Ridge_GA', label: 'Blue Ridge, GA' },
  { value: 'Blue Ridge_TX', label: 'Blue Ridge, TX' },
  { value: 'Blue Ridge Summit_PA', label: 'Blue Ridge Summit, PA' },
  { value: 'Blue River_KY', label: 'Blue River, KY' },
  { value: 'Blue River_WI', label: 'Blue River, WI' },
  { value: 'Blue River_OR', label: 'Blue River, OR' },
  { value: 'Blue Rock_OH', label: 'Blue Rock, OH' },
  { value: 'Blue Springs_MS', label: 'Blue Springs, MS' },
  { value: 'Blue Springs_MO', label: 'Blue Springs, MO' },
  { value: 'Blue Springs_NE', label: 'Blue Springs, NE' },
  { value: 'Bluebell_UT', label: 'Bluebell, UT' },
  { value: 'Bluefield_VA', label: 'Bluefield, VA' },
  { value: 'Bluefield_WV', label: 'Bluefield, WV' },
  { value: 'Bluejacket_OK', label: 'Bluejacket, OK' },
  { value: 'Bluemont_VA', label: 'Bluemont, VA' },
  { value: 'Bluewater_NM', label: 'Bluewater, NM' },
  { value: 'Bluff_UT', label: 'Bluff, UT' },
  { value: 'Bluff City_TN', label: 'Bluff City, TN' },
  { value: 'Bluff City_KS', label: 'Bluff City, KS' },
  { value: 'Bluff City_AR', label: 'Bluff City, AR' },
  { value: 'Bluff Dale_TX', label: 'Bluff Dale, TX' },
  { value: 'Bluff Springs_IL', label: 'Bluff Springs, IL' },
  { value: 'Bluffs_IL', label: 'Bluffs, IL' },
  { value: 'Bluffton_SC', label: 'Bluffton, SC' },
  { value: 'Bluffton_GA', label: 'Bluffton, GA' },
  { value: 'Bluffton_OH', label: 'Bluffton, OH' },
  { value: 'Bluffton_IN', label: 'Bluffton, IN' },
  { value: 'Bluffton_MN', label: 'Bluffton, MN' },
  { value: 'Bluffton_AR', label: 'Bluffton, AR' },
  { value: 'Bluffton_TX', label: 'Bluffton, TX' },
  { value: 'Bluford_IL', label: 'Bluford, IL' },
  { value: 'Blum_TX', label: 'Blum, TX' },
  { value: 'Blunt_SD', label: 'Blunt, SD' },
  { value: 'Bly_OR', label: 'Bly, OR' },
  { value: 'Blythe_GA', label: 'Blythe, GA' },
  { value: 'Blythe_CA', label: 'Blythe, CA' },
  { value: 'Blythedale_MO', label: 'Blythedale, MO' },
  { value: 'Blytheville_AR', label: 'Blytheville, AR' },
  { value: 'Blythewood_SC', label: 'Blythewood, SC' },
  { value: 'Boalsburg_PA', label: 'Boalsburg, PA' },
  { value: 'Boardman_OR', label: 'Boardman, OR' },
  { value: 'Boaz_AL', label: 'Boaz, AL' },
  { value: 'Boaz_KY', label: 'Boaz, KY' },
  { value: 'Bob White_WV', label: 'Bob White, WV' },
  { value: 'Bobtown_PA', label: 'Bobtown, PA' },
  { value: 'Boca Grande_FL', label: 'Boca Grande, FL' },
  { value: 'Boca Raton_FL', label: 'Boca Raton, FL' },
  { value: 'Bock_MN', label: 'Bock, MN' },
  { value: 'Bode_IA', label: 'Bode, IA' },
  { value: 'Bodega_CA', label: 'Bodega, CA' },
  { value: 'Bodega Bay_CA', label: 'Bodega Bay, CA' },
  { value: 'Bodfish_CA', label: 'Bodfish, CA' },
  { value: 'Boelus_NE', label: 'Boelus, NE' },
  { value: 'Boerne_TX', label: 'Boerne, TX' },
  { value: 'Bogalusa_LA', label: 'Bogalusa, LA' },
  { value: 'Bogard_MO', label: 'Bogard, MO' },
  { value: 'Bogart_GA', label: 'Bogart, GA' },
  { value: 'Bogata_TX', label: 'Bogata, TX' },
  { value: 'Boggstown_IN', label: 'Boggstown, IN' },
  { value: 'Bogota_NJ', label: 'Bogota, NJ' },
  { value: 'Bogota_TN', label: 'Bogota, TN' },
  { value: 'Bogue_KS', label: 'Bogue, KS' },
  { value: 'Bogue Chitto_MS', label: 'Bogue Chitto, MS' },
  { value: 'Bohannon_VA', label: 'Bohannon, VA' },
  { value: 'Bohemia_NY', label: 'Bohemia, NY' },
  { value: 'Boiceville_NY', label: 'Boiceville, NY' },
  { value: 'Boiling Springs_PA', label: 'Boiling Springs, PA' },
  { value: 'Boiling Springs_NC', label: 'Boiling Springs, NC' },
  { value: 'Boiling Springs_SC', label: 'Boiling Springs, SC' },
  { value: 'Bois D Arc_MO', label: 'Bois D Arc, MO' },
  { value: 'Boise_ID', label: 'Boise, ID' },
  { value: 'Boise City_OK', label: 'Boise City, OK' },
  { value: 'Boissevain_VA', label: 'Boissevain, VA' },
  { value: 'Bokchito_OK', label: 'Bokchito, OK' },
  { value: 'Bokeelia_FL', label: 'Bokeelia, FL' },
  { value: 'Bokoshe_OK', label: 'Bokoshe, OK' },
  { value: 'Bolckow_MO', label: 'Bolckow, MO' },
  { value: 'Boles_AR', label: 'Boles, AR' },
  { value: 'Boley_OK', label: 'Boley, OK' },
  { value: 'Boligee_AL', label: 'Boligee, AL' },
  { value: 'Bolinas_CA', label: 'Bolinas, CA' },
  { value: 'Boling_TX', label: 'Boling, TX' },
  { value: 'Bolingbroke_GA', label: 'Bolingbroke, GA' },
  { value: 'Bolingbrook_IL', label: 'Bolingbrook, IL' },
  { value: 'Bolivar_NY', label: 'Bolivar, NY' },
  { value: 'Bolivar_PA', label: 'Bolivar, PA' },
  { value: 'Bolivar_TN', label: 'Bolivar, TN' },
  { value: 'Bolivar_OH', label: 'Bolivar, OH' },
  { value: 'Bolivar_MO', label: 'Bolivar, MO' },
  { value: 'Bolivia_NC', label: 'Bolivia, NC' },
  { value: 'Bolt_WV', label: 'Bolt, WV' },
  { value: 'Bolton_MA', label: 'Bolton, MA' },
  { value: 'Bolton_CT', label: 'Bolton, CT' },
  { value: 'Bolton_NC', label: 'Bolton, NC' },
  { value: 'Bolton_MS', label: 'Bolton, MS' },
  { value: 'Bolton Landing_NY', label: 'Bolton Landing, NY' },
  { value: 'Bombay_NY', label: 'Bombay, NY' },
  { value: 'Bomont_WV', label: 'Bomont, WV' },
  { value: 'Bomoseen_VT', label: 'Bomoseen, VT' },
  { value: 'Bon Air_AL', label: 'Bon Air, AL' },
  { value: 'Bon Aqua_TN', label: 'Bon Aqua, TN' },
  { value: 'Bon Secour_AL', label: 'Bon Secour, AL' },
  { value: 'Bon Wier_TX', label: 'Bon Wier, TX' },
  { value: 'Bonaire_GA', label: 'Bonaire, GA' },
  { value: 'Bonanza_UT', label: 'Bonanza, UT' },
  { value: 'Bonanza_OR', label: 'Bonanza, OR' },
  { value: 'Bonaparte_IA', label: 'Bonaparte, IA' },
  { value: 'Boncarbo_CO', label: 'Boncarbo, CO' },
  { value: 'Bond_CO', label: 'Bond, CO' },
  { value: 'Bondsville_MA', label: 'Bondsville, MA' },
  { value: 'Bonduel_WI', label: 'Bonduel, WI' },
  { value: 'Bondurant_IA', label: 'Bondurant, IA' },
  { value: 'Bondurant_WY', label: 'Bondurant, WY' },
  { value: 'Bondville_VT', label: 'Bondville, VT' },
  { value: 'Bondville_IL', label: 'Bondville, IL' },
  { value: 'Bone Gap_IL', label: 'Bone Gap, IL' },
  { value: 'Bonesteel_SD', label: 'Bonesteel, SD' },
  { value: 'Bonfield_IL', label: 'Bonfield, IL' },
  { value: 'Bonham_TX', label: 'Bonham, TX' },
  { value: 'Bonifay_FL', label: 'Bonifay, FL' },
  { value: 'Bonita_LA', label: 'Bonita, LA' },
  { value: 'Bonita_CA', label: 'Bonita, CA' },
  { value: 'Bonita Springs_FL', label: 'Bonita Springs, FL' },
  { value: 'Bonlee_NC', label: 'Bonlee, NC' },
  { value: 'Bonne Terre_MO', label: 'Bonne Terre, MO' },
  { value: 'Bonneau_SC', label: 'Bonneau, SC' },
  { value: 'Bonner_MT', label: 'Bonner, MT' },
  { value: 'Bonner Springs_KS', label: 'Bonner Springs, KS' },
  { value: 'Bonnerdale_AR', label: 'Bonnerdale, AR' },
  { value: 'Bonners Ferry_ID', label: 'Bonners Ferry, ID' },
  { value: 'Bonney Lake_WA', label: 'Bonney Lake, WA' },
  { value: 'Bonnie_IL', label: 'Bonnie, IL' },
  { value: 'Bonnieville_KY', label: 'Bonnieville, KY' },
  { value: 'Bonnots Mill_MO', label: 'Bonnots Mill, MO' },
  { value: 'Bonnyman_KY', label: 'Bonnyman, KY' },
  { value: 'Bono_AR', label: 'Bono, AR' },
  { value: 'Bonsall_CA', label: 'Bonsall, CA' },
  { value: 'Boody_IL', label: 'Boody, IL' },
  { value: 'Booker_TX', label: 'Booker, TX' },
  { value: 'Boomer_WV', label: 'Boomer, WV' },
  { value: 'Boomer_NC', label: 'Boomer, NC' },
  { value: 'Boon_MI', label: 'Boon, MI' },
  { value: 'Boone_NC', label: 'Boone, NC' },
  { value: 'Boone_IA', label: 'Boone, IA' },
  { value: 'Boone_CO', label: 'Boone, CO' },
  { value: 'Boone Grove_IN', label: 'Boone Grove, IN' },
  { value: 'Boones Mill_VA', label: 'Boones Mill, VA' },
  { value: 'Booneville_MS', label: 'Booneville, MS' },
  { value: 'Booneville_KY', label: 'Booneville, KY' },
  { value: 'Booneville_IA', label: 'Booneville, IA' },
  { value: 'Booneville_AR', label: 'Booneville, AR' },
  { value: 'Boons Camp_KY', label: 'Boons Camp, KY' },
  { value: 'Boonsboro_MD', label: 'Boonsboro, MD' },
  { value: 'Boonton_NJ', label: 'Boonton, NJ' },
  { value: 'Boonville_NY', label: 'Boonville, NY' },
  { value: 'Boonville_NC', label: 'Boonville, NC' },
  { value: 'Boonville_IN', label: 'Boonville, IN' },
  { value: 'Boonville_MO', label: 'Boonville, MO' },
  { value: 'Boonville_CA', label: 'Boonville, CA' },
  { value: 'Booth_AL', label: 'Booth, AL' },
  { value: 'Boothbay_ME', label: 'Boothbay, ME' },
  { value: 'Boothbay Harbor_ME', label: 'Boothbay Harbor, ME' },
  { value: 'Boothville_LA', label: 'Boothville, LA' },
  { value: 'Boqueron_PR', label: 'Boqueron, PR' },
  { value: 'Bordelonville_LA', label: 'Bordelonville, LA' },
  { value: 'Borden_IN', label: 'Borden, IN' },
  { value: 'Bordentown_NJ', label: 'Bordentown, NJ' },
  { value: 'Borger_TX', label: 'Borger, TX' },
  { value: 'Boring_OR', label: 'Boring, OR' },
  { value: 'Boron_CA', label: 'Boron, CA' },
  { value: 'Borrego Springs_CA', label: 'Borrego Springs, CA' },
  { value: 'Borup_MN', label: 'Borup, MN' },
  { value: 'Boscobel_WI', label: 'Boscobel, WI' },
  { value: 'Bosler_WY', label: 'Bosler, WY' },
  { value: 'Bosque_NM', label: 'Bosque, NM' },
  { value: 'Bosque Farms_NM', label: 'Bosque Farms, NM' },
  { value: 'Boss_MO', label: 'Boss, MO' },
  { value: 'Bossier City_LA', label: 'Bossier City, LA' },
  { value: 'Bostic_NC', label: 'Bostic, NC' },
  { value: 'Boston_MA', label: 'Boston, MA' },
  { value: 'Boston_NY', label: 'Boston, NY' },
  { value: 'Boston_VA', label: 'Boston, VA' },
  { value: 'Boston_GA', label: 'Boston, GA' },
  { value: 'Boston_KY', label: 'Boston, KY' },
  { value: 'Boston_IN', label: 'Boston, IN' },
  { value: 'Bostwick_GA', label: 'Bostwick, GA' },
  { value: 'Boswell_PA', label: 'Boswell, PA' },
  { value: 'Boswell_IN', label: 'Boswell, IN' },
  { value: 'Boswell_OK', label: 'Boswell, OK' },
  { value: 'Bosworth_MO', label: 'Bosworth, MO' },
  { value: 'Bothell_WA', label: 'Bothell, WA' },
  { value: 'Botkins_OH', label: 'Botkins, OH' },
  { value: 'Bottineau_ND', label: 'Bottineau, ND' },
  { value: 'Bouckville_NY', label: 'Bouckville, NY' },
  { value: 'Boulder_MT', label: 'Boulder, MT' },
  { value: 'Boulder_CO', label: 'Boulder, CO' },
  { value: 'Boulder_WY', label: 'Boulder, WY' },
  { value: 'Boulder_UT', label: 'Boulder, UT' },
  { value: 'Boulder City_NV', label: 'Boulder City, NV' },
  { value: 'Boulder Creek_CA', label: 'Boulder Creek, CA' },
  { value: 'Boulder Junction_WI', label: 'Boulder Junction, WI' },
  { value: 'Boulevard_CA', label: 'Boulevard, CA' },
  { value: 'Bound Brook_NJ', label: 'Bound Brook, NJ' },
  { value: 'Bountiful_UT', label: 'Bountiful, UT' },
  { value: 'Bourbon_IN', label: 'Bourbon, IN' },
  { value: 'Bourbon_MO', label: 'Bourbon, MO' },
  { value: 'Bourbonnais_IL', label: 'Bourbonnais, IL' },
  { value: 'Bourg_LA', label: 'Bourg, LA' },
  { value: 'Bourneville_OH', label: 'Bourneville, OH' },
  { value: 'Bouse_AZ', label: 'Bouse, AZ' },
  { value: 'Bouton_IA', label: 'Bouton, IA' },
  { value: 'Boutte_LA', label: 'Boutte, LA' },
  { value: 'Bovard_PA', label: 'Bovard, PA' },
  { value: 'Bovey_MN', label: 'Bovey, MN' },
  { value: 'Bovill_ID', label: 'Bovill, ID' },
  { value: 'Bovina_TX', label: 'Bovina, TX' },
  { value: 'Bovina Center_NY', label: 'Bovina Center, NY' },
  { value: 'Bow_NH', label: 'Bow, NH' },
  { value: 'Bow_WA', label: 'Bow, WA' },
  { value: 'Bowbells_ND', label: 'Bowbells, ND' },
  { value: 'Bowden_WV', label: 'Bowden, WV' },
  { value: 'Bowdle_SD', label: 'Bowdle, SD' },
  { value: 'Bowdoin_ME', label: 'Bowdoin, ME' },
  { value: 'Bowdoinham_ME', label: 'Bowdoinham, ME' },
  { value: 'Bowdon_GA', label: 'Bowdon, GA' },
  { value: 'Bowdon_ND', label: 'Bowdon, ND' },
  { value: 'Bowen_IL', label: 'Bowen, IL' },
  { value: 'Bowers_PA', label: 'Bowers, PA' },
  { value: 'Bowerston_OH', label: 'Bowerston, OH' },
  { value: 'Bowersville_GA', label: 'Bowersville, GA' },
  { value: 'Bowersville_OH', label: 'Bowersville, OH' },
  { value: 'Bowie_MD', label: 'Bowie, MD' },
  { value: 'Bowie_TX', label: 'Bowie, TX' },
  { value: 'Bowie_AZ', label: 'Bowie, AZ' },
  { value: 'Bowlegs_OK', label: 'Bowlegs, OK' },
  { value: 'Bowler_WI', label: 'Bowler, WI' },
  { value: 'Bowling Green_VA', label: 'Bowling Green, VA' },
  { value: 'Bowling Green_FL', label: 'Bowling Green, FL' },
  { value: 'Bowling Green_KY', label: 'Bowling Green, KY' },
  { value: 'Bowling Green_OH', label: 'Bowling Green, OH' },
  { value: 'Bowling Green_IN', label: 'Bowling Green, IN' },
  { value: 'Bowling Green_MO', label: 'Bowling Green, MO' },
  { value: 'Bowlus_MN', label: 'Bowlus, MN' },
  { value: 'Bowman_SC', label: 'Bowman, SC' },
  { value: 'Bowman_GA', label: 'Bowman, GA' },
  { value: 'Bowman_ND', label: 'Bowman, ND' },
  { value: 'Bowmanstown_PA', label: 'Bowmanstown, PA' },
  { value: 'Bowmansville_NY', label: 'Bowmansville, NY' },
  { value: 'Bowmansville_PA', label: 'Bowmansville, PA' },
  { value: 'Box Elder_SD', label: 'Box Elder, SD' },
  { value: 'Box Elder_MT', label: 'Box Elder, MT' },
  { value: 'Box Springs_GA', label: 'Box Springs, GA' },
  { value: 'Boxborough_MA', label: 'Boxborough, MA' },
  { value: 'Boxford_MA', label: 'Boxford, MA' },
  { value: 'Boxholm_IA', label: 'Boxholm, IA' },
  { value: 'Boyce_VA', label: 'Boyce, VA' },
  { value: 'Boyce_LA', label: 'Boyce, LA' },
  { value: 'Boyceville_WI', label: 'Boyceville, WI' },
  { value: 'Boyd_WI', label: 'Boyd, WI' },
  { value: 'Boyd_MN', label: 'Boyd, MN' },
  { value: 'Boyd_MT', label: 'Boyd, MT' },
  { value: 'Boyd_TX', label: 'Boyd, TX' },
  { value: 'Boyden_IA', label: 'Boyden, IA' },
  { value: 'Boyds_MD', label: 'Boyds, MD' },
  { value: 'Boydton_VA', label: 'Boydton, VA' },
  { value: 'Boyers_PA', label: 'Boyers, PA' },
  { value: 'Boyertown_PA', label: 'Boyertown, PA' },
  { value: 'Boyes_MT', label: 'Boyes, MT' },
  { value: 'Boykin_AL', label: 'Boykin, AL' },
  { value: 'Boykins_VA', label: 'Boykins, VA' },
  { value: 'Boyle_MS', label: 'Boyle, MS' },
  { value: 'Boylston_MA', label: 'Boylston, MA' },
  { value: 'Boyne City_MI', label: 'Boyne City, MI' },
  { value: 'Boyne Falls_MI', label: 'Boyne Falls, MI' },
  { value: 'Boynton_PA', label: 'Boynton, PA' },
  { value: 'Boynton_OK', label: 'Boynton, OK' },
  { value: 'Boynton Beach_FL', label: 'Boynton Beach, FL' },
  { value: 'Boys Ranch_TX', label: 'Boys Ranch, TX' },
  { value: 'Boys Town_NE', label: 'Boys Town, NE' },
  { value: 'Bozeman_MT', label: 'Bozeman, MT' },
  { value: 'Bozman_MD', label: 'Bozman, MD' },
  { value: 'Bozrah_CT', label: 'Bozrah, CT' },
  { value: 'Braceville_IL', label: 'Braceville, IL' },
  { value: 'Bracey_VA', label: 'Bracey, VA' },
  { value: 'Brackenridge_PA', label: 'Brackenridge, PA' },
  { value: 'Brackettville_TX', label: 'Brackettville, TX' },
  { value: 'Brackney_PA', label: 'Brackney, PA' },
  { value: 'Braddock_PA', label: 'Braddock, PA' },
  { value: 'Braddock_ND', label: 'Braddock, ND' },
  { value: 'Braddock Heights_MD', label: 'Braddock Heights, MD' },
  { value: 'Braddyville_IA', label: 'Braddyville, IA' },
  { value: 'Bradenton_FL', label: 'Bradenton, FL' },
  { value: 'Bradenton Beach_FL', label: 'Bradenton Beach, FL' },
  { value: 'Bradenville_PA', label: 'Bradenville, PA' },
  { value: 'Bradford_RI', label: 'Bradford, RI' },
  { value: 'Bradford_NH', label: 'Bradford, NH' },
  { value: 'Bradford_ME', label: 'Bradford, ME' },
  { value: 'Bradford_VT', label: 'Bradford, VT' },
  { value: 'Bradford_NY', label: 'Bradford, NY' },
  { value: 'Bradford_PA', label: 'Bradford, PA' },
  { value: 'Bradford_TN', label: 'Bradford, TN' },
  { value: 'Bradford_OH', label: 'Bradford, OH' },
  { value: 'Bradford_IA', label: 'Bradford, IA' },
  { value: 'Bradford_IL', label: 'Bradford, IL' },
  { value: 'Bradford_AR', label: 'Bradford, AR' },
  { value: 'Bradfordsville_KY', label: 'Bradfordsville, KY' },
  { value: 'Bradfordwoods_PA', label: 'Bradfordwoods, PA' },
  { value: 'Bradgate_IA', label: 'Bradgate, IA' },
  { value: 'Bradley_ME', label: 'Bradley, ME' },
  { value: 'Bradley_WV', label: 'Bradley, WV' },
  { value: 'Bradley_SC', label: 'Bradley, SC' },
  { value: 'Bradley_FL', label: 'Bradley, FL' },
  { value: 'Bradley_SD', label: 'Bradley, SD' },
  { value: 'Bradley_IL', label: 'Bradley, IL' },
  { value: 'Bradley_AR', label: 'Bradley, AR' },
  { value: 'Bradley_OK', label: 'Bradley, OK' },
  { value: 'Bradley_CA', label: 'Bradley, CA' },
  { value: 'Bradley Beach_NJ', label: 'Bradley Beach, NJ' },
  { value: 'Bradleyville_MO', label: 'Bradleyville, MO' },
  { value: 'Bradner_OH', label: 'Bradner, OH' },
  { value: 'Bradshaw_WV', label: 'Bradshaw, WV' },
  { value: 'Bradshaw_NE', label: 'Bradshaw, NE' },
  { value: 'Brady_MT', label: 'Brady, MT' },
  { value: 'Brady_NE', label: 'Brady, NE' },
  { value: 'Brady_TX', label: 'Brady, TX' },
  { value: 'Bradyville_TN', label: 'Bradyville, TN' },
  { value: 'Bragg City_MO', label: 'Bragg City, MO' },
  { value: 'Braggadocio_MO', label: 'Braggadocio, MO' },
  { value: 'Braggs_OK', label: 'Braggs, OK' },
  { value: 'Braham_MN', label: 'Braham, MN' },
  { value: 'Braidwood_IL', label: 'Braidwood, IL' },
  { value: 'Brainard_NY', label: 'Brainard, NY' },
  { value: 'Brainard_NE', label: 'Brainard, NE' },
  { value: 'Brainardsville_NY', label: 'Brainardsville, NY' },
  { value: 'Brainerd_MN', label: 'Brainerd, MN' },
  { value: 'Braintree_MA', label: 'Braintree, MA' },
  { value: 'Braithwaite_LA', label: 'Braithwaite, LA' },
  { value: 'Braman_OK', label: 'Braman, OK' },
  { value: 'Bramwell_WV', label: 'Bramwell, WV' },
  { value: 'Branch_MI', label: 'Branch, MI' },
  { value: 'Branch_LA', label: 'Branch, LA' },
  { value: 'Branch_AR', label: 'Branch, AR' },
  { value: 'Branchdale_PA', label: 'Branchdale, PA' },
  { value: 'Branchland_WV', label: 'Branchland, WV' },
  { value: 'Branchport_NY', label: 'Branchport, NY' },
  { value: 'Branchville_NJ', label: 'Branchville, NJ' },
  { value: 'Branchville_VA', label: 'Branchville, VA' },
  { value: 'Branchville_SC', label: 'Branchville, SC' },
  { value: 'Branchville_IN', label: 'Branchville, IN' },
  { value: 'Brandamore_PA', label: 'Brandamore, PA' },
  { value: 'Brandeis_CA', label: 'Brandeis, CA' },
  { value: 'Brandenburg_KY', label: 'Brandenburg, KY' },
  { value: 'Brandon_VT', label: 'Brandon, VT' },
  { value: 'Brandon_FL', label: 'Brandon, FL' },
  { value: 'Brandon_MS', label: 'Brandon, MS' },
  { value: 'Brandon_IA', label: 'Brandon, IA' },
  { value: 'Brandon_WI', label: 'Brandon, WI' },
  { value: 'Brandon_MN', label: 'Brandon, MN' },
  { value: 'Brandon_SD', label: 'Brandon, SD' },
  { value: 'Brandon_TX', label: 'Brandon, TX' },
  { value: 'Brandsville_MO', label: 'Brandsville, MO' },
  { value: 'Brandt_SD', label: 'Brandt, SD' },
  { value: 'Brandy Station_VA', label: 'Brandy Station, VA' },
  { value: 'Brandywine_MD', label: 'Brandywine, MD' },
  { value: 'Brandywine_WV', label: 'Brandywine, WV' },
  { value: 'Branford_CT', label: 'Branford, CT' },
  { value: 'Branford_FL', label: 'Branford, FL' },
  { value: 'Branscomb_CA', label: 'Branscomb, CA' },
  { value: 'Branson_MO', label: 'Branson, MO' },
  { value: 'Branson_CO', label: 'Branson, CO' },
  { value: 'Brant_NY', label: 'Brant, NY' },
  { value: 'Brant_MI', label: 'Brant, MI' },
  { value: 'Brant Lake_NY', label: 'Brant Lake, NY' },
  { value: 'Brantingham_NY', label: 'Brantingham, NY' },
  { value: 'Brantley_AL', label: 'Brantley, AL' },
  { value: 'Brantwood_WI', label: 'Brantwood, WI' },
  { value: 'Braselton_GA', label: 'Braselton, GA' },
  { value: 'Brashear_MO', label: 'Brashear, MO' },
  { value: 'Brashear_TX', label: 'Brashear, TX' },
  { value: 'Brasher Falls_NY', label: 'Brasher Falls, NY' },
  { value: 'Brasstown_NC', label: 'Brasstown, NC' },
  { value: 'Brattleboro_VT', label: 'Brattleboro, VT' },
  { value: 'Brave_PA', label: 'Brave, PA' },
  { value: 'Brawley_CA', label: 'Brawley, CA' },
  { value: 'Braxton_MS', label: 'Braxton, MS' },
  { value: 'Braymer_MO', label: 'Braymer, MO' },
  { value: 'Brayton_IA', label: 'Brayton, IA' },
  { value: 'Brazil_IN', label: 'Brazil, IN' },
  { value: 'Brazoria_TX', label: 'Brazoria, TX' },
  { value: 'Brea_CA', label: 'Brea, CA' },
  { value: 'Breaks_VA', label: 'Breaks, VA' },
  { value: 'Breaux Bridge_LA', label: 'Breaux Bridge, LA' },
  { value: 'Breckenridge_MI', label: 'Breckenridge, MI' },
  { value: 'Breckenridge_MN', label: 'Breckenridge, MN' },
  { value: 'Breckenridge_MO', label: 'Breckenridge, MO' },
  { value: 'Breckenridge_TX', label: 'Breckenridge, TX' },
  { value: 'Breckenridge_CO', label: 'Breckenridge, CO' },
  { value: 'Brecksville_OH', label: 'Brecksville, OH' },
  { value: 'Breda_IA', label: 'Breda, IA' },
  { value: 'Breeden_WV', label: 'Breeden, WV' },
  { value: 'Breeding_KY', label: 'Breeding, KY' },
  { value: 'Breedsville_MI', label: 'Breedsville, MI' },
  { value: 'Breese_IL', label: 'Breese, IL' },
  { value: 'Breesport_NY', label: 'Breesport, NY' },
  { value: 'Breezewood_PA', label: 'Breezewood, PA' },
  { value: 'Breezy Point_NY', label: 'Breezy Point, NY' },
  { value: 'Breinigsville_PA', label: 'Breinigsville, PA' },
  { value: 'Bremen_ME', label: 'Bremen, ME' },
  { value: 'Bremen_GA', label: 'Bremen, GA' },
  { value: 'Bremen_AL', label: 'Bremen, AL' },
  { value: 'Bremen_KY', label: 'Bremen, KY' },
  { value: 'Bremen_OH', label: 'Bremen, OH' },
  { value: 'Bremen_IN', label: 'Bremen, IN' },
  { value: 'Bremen_KS', label: 'Bremen, KS' },
  { value: 'Bremerton_WA', label: 'Bremerton, WA' },
  { value: 'Bremo Bluff_VA', label: 'Bremo Bluff, VA' },
  { value: 'Bremond_TX', label: 'Bremond, TX' },
  { value: 'Brenham_TX', label: 'Brenham, TX' },
  { value: 'Brent_AL', label: 'Brent, AL' },
  { value: 'Brentford_SD', label: 'Brentford, SD' },
  { value: 'Brenton_WV', label: 'Brenton, WV' },
  { value: 'Brentwood_NY', label: 'Brentwood, NY' },
  { value: 'Brentwood_MD', label: 'Brentwood, MD' },
  { value: 'Brentwood_TN', label: 'Brentwood, TN' },
  { value: 'Brentwood_CA', label: 'Brentwood, CA' },
  { value: 'Brethren_MI', label: 'Brethren, MI' },
  { value: 'Bretton Woods_NH', label: 'Bretton Woods, NH' },
  { value: 'Bretz_WV', label: 'Bretz, WV' },
  { value: 'Brevard_NC', label: 'Brevard, NC' },
  { value: 'Brevig Mission_AK', label: 'Brevig Mission, AK' },
  { value: 'Brewer_ME', label: 'Brewer, ME' },
  { value: 'Brewerton_NY', label: 'Brewerton, NY' },
  { value: 'Brewster_MA', label: 'Brewster, MA' },
  { value: 'Brewster_NY', label: 'Brewster, NY' },
  { value: 'Brewster_OH', label: 'Brewster, OH' },
  { value: 'Brewster_MN', label: 'Brewster, MN' },
  { value: 'Brewster_KS', label: 'Brewster, KS' },
  { value: 'Brewster_NE', label: 'Brewster, NE' },
  { value: 'Brewster_WA', label: 'Brewster, WA' },
  { value: 'Brewton_AL', label: 'Brewton, AL' },
  { value: 'Brian Head_UT', label: 'Brian Head, UT' },
  { value: 'Briarcliff Manor_NY', label: 'Briarcliff Manor, NY' },
  { value: 'Brice_OH', label: 'Brice, OH' },
  { value: 'Bricelyn_MN', label: 'Bricelyn, MN' },
  { value: 'Briceville_TN', label: 'Briceville, TN' },
  { value: 'Brick_NJ', label: 'Brick, NJ' },
  { value: 'Brickeys_AR', label: 'Brickeys, AR' },
  { value: 'Bridge City_TX', label: 'Bridge City, TX' },
  { value: 'Bridgehampton_NY', label: 'Bridgehampton, NY' },
  { value: 'Bridgeport_CT', label: 'Bridgeport, CT' },
  { value: 'Bridgeport_NJ', label: 'Bridgeport, NJ' },
  { value: 'Bridgeport_NY', label: 'Bridgeport, NY' },
  { value: 'Bridgeport_PA', label: 'Bridgeport, PA' },
  { value: 'Bridgeport_WV', label: 'Bridgeport, WV' },
  { value: 'Bridgeport_AL', label: 'Bridgeport, AL' },
  { value: 'Bridgeport_OH', label: 'Bridgeport, OH' },
  { value: 'Bridgeport_MI', label: 'Bridgeport, MI' },
  { value: 'Bridgeport_IL', label: 'Bridgeport, IL' },
  { value: 'Bridgeport_NE', label: 'Bridgeport, NE' },
  { value: 'Bridgeport_TX', label: 'Bridgeport, TX' },
  { value: 'Bridgeport_CA', label: 'Bridgeport, CA' },
  { value: 'Bridgeport_OR', label: 'Bridgeport, OR' },
  { value: 'Bridgeport_WA', label: 'Bridgeport, WA' },
  { value: 'Bridger_MT', label: 'Bridger, MT' },
  { value: 'Bridgeton_NJ', label: 'Bridgeton, NJ' },
  { value: 'Bridgeton_NC', label: 'Bridgeton, NC' },
  { value: 'Bridgeton_IN', label: 'Bridgeton, IN' },
  { value: 'Bridgeton_MO', label: 'Bridgeton, MO' },
  { value: 'Bridgeview_IL', label: 'Bridgeview, IL' },
  { value: 'Bridgeville_PA', label: 'Bridgeville, PA' },
  { value: 'Bridgeville_DE', label: 'Bridgeville, DE' },
  { value: 'Bridgeville_CA', label: 'Bridgeville, CA' },
  { value: 'Bridgewater_MA', label: 'Bridgewater, MA' },
  { value: 'Bridgewater_ME', label: 'Bridgewater, ME' },
  { value: 'Bridgewater_VT', label: 'Bridgewater, VT' },
  { value: 'Bridgewater_CT', label: 'Bridgewater, CT' },
  { value: 'Bridgewater_NJ', label: 'Bridgewater, NJ' },
  { value: 'Bridgewater_NY', label: 'Bridgewater, NY' },
  { value: 'Bridgewater_VA', label: 'Bridgewater, VA' },
  { value: 'Bridgewater_IA', label: 'Bridgewater, IA' },
  { value: 'Bridgewater_SD', label: 'Bridgewater, SD' },
  { value: 'Bridgewater Corners_VT', label: 'Bridgewater Corners, VT' },
  { value: 'Bridgman_MI', label: 'Bridgman, MI' },
  { value: 'Bridgton_ME', label: 'Bridgton, ME' },
  { value: 'Bridport_VT', label: 'Bridport, VT' },
  { value: 'Brielle_NJ', label: 'Brielle, NJ' },
  { value: 'Brier Hill_NY', label: 'Brier Hill, NY' },
  { value: 'Brierfield_AL', label: 'Brierfield, AL' },
  { value: 'Brigantine_NJ', label: 'Brigantine, NJ' },
  { value: 'Briggs_TX', label: 'Briggs, TX' },
  { value: 'Briggsdale_CO', label: 'Briggsdale, CO' },
  { value: 'Briggsville_WI', label: 'Briggsville, WI' },
  { value: 'Briggsville_AR', label: 'Briggsville, AR' },
  { value: 'Brigham City_UT', label: 'Brigham City, UT' },
  { value: 'Brighton_MA', label: 'Brighton, MA' },
  { value: 'Brighton_TN', label: 'Brighton, TN' },
  { value: 'Brighton_MI', label: 'Brighton, MI' },
  { value: 'Brighton_IA', label: 'Brighton, IA' },
  { value: 'Brighton_IL', label: 'Brighton, IL' },
  { value: 'Brighton_MO', label: 'Brighton, MO' },
  { value: 'Brighton_CO', label: 'Brighton, CO' },
  { value: 'Brightwaters_NY', label: 'Brightwaters, NY' },
  { value: 'Brightwood_VA', label: 'Brightwood, VA' },
  { value: 'Brightwood_OR', label: 'Brightwood, OR' },
  { value: 'Brilliant_AL', label: 'Brilliant, AL' },
  { value: 'Brilliant_OH', label: 'Brilliant, OH' },
  { value: 'Brillion_WI', label: 'Brillion, WI' },
  { value: 'Brimfield_MA', label: 'Brimfield, MA' },
  { value: 'Brimfield_IL', label: 'Brimfield, IL' },
  { value: 'Brimhall_NM', label: 'Brimhall, NM' },
  { value: 'Brimley_MI', label: 'Brimley, MI' },
  { value: 'Brimson_MN', label: 'Brimson, MN' },
  { value: 'Bringhurst_IN', label: 'Bringhurst, IN' },
  { value: 'Brinkhaven_OH', label: 'Brinkhaven, OH' },
  { value: 'Brinkley_AR', label: 'Brinkley, AR' },
  { value: 'Brinklow_MD', label: 'Brinklow, MD' },
  { value: 'Brinktown_MO', label: 'Brinktown, MO' },
  { value: 'Brinnon_WA', label: 'Brinnon, WA' },
  { value: 'Brinson_GA', label: 'Brinson, GA' },
  { value: 'Brisbane_CA', label: 'Brisbane, CA' },
  { value: 'Brisbin_PA', label: 'Brisbin, PA' },
  { value: 'Briscoe_TX', label: 'Briscoe, TX' },
  { value: 'Bristol_RI', label: 'Bristol, RI' },
  { value: 'Bristol_NH', label: 'Bristol, NH' },
  { value: 'Bristol_ME', label: 'Bristol, ME' },
  { value: 'Bristol_VT', label: 'Bristol, VT' },
  { value: 'Bristol_CT', label: 'Bristol, CT' },
  { value: 'Bristol_PA', label: 'Bristol, PA' },
  { value: 'Bristol_VA', label: 'Bristol, VA' },
  { value: 'Bristol_GA', label: 'Bristol, GA' },
  { value: 'Bristol_FL', label: 'Bristol, FL' },
  { value: 'Bristol_TN', label: 'Bristol, TN' },
  { value: 'Bristol_IN', label: 'Bristol, IN' },
  { value: 'Bristol_WI', label: 'Bristol, WI' },
  { value: 'Bristol_SD', label: 'Bristol, SD' },
  { value: 'Bristol_IL', label: 'Bristol, IL' },
  { value: 'Bristolville_OH', label: 'Bristolville, OH' },
  { value: 'Bristow_VA', label: 'Bristow, VA' },
  { value: 'Bristow_IN', label: 'Bristow, IN' },
  { value: 'Bristow_IA', label: 'Bristow, IA' },
  { value: 'Bristow_NE', label: 'Bristow, NE' },
  { value: 'Bristow_OK', label: 'Bristow, OK' },
  { value: 'Britt_IA', label: 'Britt, IA' },
  { value: 'Britt_MN', label: 'Britt, MN' },
  { value: 'Britton_MI', label: 'Britton, MI' },
  { value: 'Britton_SD', label: 'Britton, SD' },
  { value: 'Brixey_MO', label: 'Brixey, MO' },
  { value: 'Broad Brook_CT', label: 'Broad Brook, CT' },
  { value: 'Broad Run_VA', label: 'Broad Run, VA' },
  { value: 'Broad Top_PA', label: 'Broad Top, PA' },
  { value: 'Broadalbin_NY', label: 'Broadalbin, NY' },
  { value: 'Broadbent_OR', label: 'Broadbent, OR' },
  { value: 'Broaddus_TX', label: 'Broaddus, TX' },
  { value: 'Broadford_VA', label: 'Broadford, VA' },
  { value: 'Broadlands_IL', label: 'Broadlands, IL' },
  { value: 'Broadus_MT', label: 'Broadus, MT' },
  { value: 'Broadview_MT', label: 'Broadview, MT' },
  { value: 'Broadview_IL', label: 'Broadview, IL' },
  { value: 'Broadview_NM', label: 'Broadview, NM' },
  { value: 'Broadview Heights_OH', label: 'Broadview Heights, OH' },
  { value: 'Broadwater_NE', label: 'Broadwater, NE' },
  { value: 'Broadway_VA', label: 'Broadway, VA' },
  { value: 'Broadway_NC', label: 'Broadway, NC' },
  { value: 'Broadway_OH', label: 'Broadway, OH' },
  { value: 'Brock_NE', label: 'Brock, NE' },
  { value: 'Brocket_ND', label: 'Brocket, ND' },
  { value: 'Brockport_NY', label: 'Brockport, NY' },
  { value: 'Brockport_PA', label: 'Brockport, PA' },
  { value: 'Brockton_MA', label: 'Brockton, MA' },
  { value: 'Brockton_PA', label: 'Brockton, PA' },
  { value: 'Brockton_MT', label: 'Brockton, MT' },
  { value: 'Brockway_PA', label: 'Brockway, PA' },
  { value: 'Brockway_MT', label: 'Brockway, MT' },
  { value: 'Brockwell_AR', label: 'Brockwell, AR' },
  { value: 'Brocton_NY', label: 'Brocton, NY' },
  { value: 'Brocton_IL', label: 'Brocton, IL' },
  { value: 'Brodhead_KY', label: 'Brodhead, KY' },
  { value: 'Brodhead_WI', label: 'Brodhead, WI' },
  { value: 'Brodheadsville_PA', label: 'Brodheadsville, PA' },
  { value: 'Brodnax_VA', label: 'Brodnax, VA' },
  { value: 'Brogan_OR', label: 'Brogan, OR' },
  { value: 'Brogue_PA', label: 'Brogue, PA' },
  { value: 'Brohard_WV', label: 'Brohard, WV' },
  { value: 'Brohman_MI', label: 'Brohman, MI' },
  { value: 'Brokaw_WI', label: 'Brokaw, WI' },
  { value: 'Broken Arrow_OK', label: 'Broken Arrow, OK' },
  { value: 'Broken Bow_NE', label: 'Broken Bow, NE' },
  { value: 'Broken Bow_OK', label: 'Broken Bow, OK' },
  { value: 'Bromide_OK', label: 'Bromide, OK' },
  { value: 'Bronaugh_MO', label: 'Bronaugh, MO' },
  { value: 'Bronson_FL', label: 'Bronson, FL' },
  { value: 'Bronson_MI', label: 'Bronson, MI' },
  { value: 'Bronson_IA', label: 'Bronson, IA' },
  { value: 'Bronson_KS', label: 'Bronson, KS' },
  { value: 'Bronson_TX', label: 'Bronson, TX' },
  { value: 'Bronston_KY', label: 'Bronston, KY' },
  { value: 'Bronte_TX', label: 'Bronte, TX' },
  { value: 'Bronwood_GA', label: 'Bronwood, GA' },
  { value: 'Bronx_NY', label: 'Bronx, NY' },
  { value: 'Bronxville_NY', label: 'Bronxville, NY' },
  { value: 'Brook_IN', label: 'Brook, IN' },
  { value: 'Brook Park_MN', label: 'Brook Park, MN' },
  { value: 'Brookdale_CA', label: 'Brookdale, CA' },
  { value: 'Brookeland_TX', label: 'Brookeland, TX' },
  { value: 'Brooker_FL', label: 'Brooker, FL' },
  { value: 'Brookesmith_TX', label: 'Brookesmith, TX' },
  { value: 'Brookeville_MD', label: 'Brookeville, MD' },
  { value: 'Brookfield_MA', label: 'Brookfield, MA' },
  { value: 'Brookfield_VT', label: 'Brookfield, VT' },
  { value: 'Brookfield_CT', label: 'Brookfield, CT' },
  { value: 'Brookfield_NY', label: 'Brookfield, NY' },
  { value: 'Brookfield_GA', label: 'Brookfield, GA' },
  { value: 'Brookfield_OH', label: 'Brookfield, OH' },
  { value: 'Brookfield_WI', label: 'Brookfield, WI' },
  { value: 'Brookfield_IL', label: 'Brookfield, IL' },
  { value: 'Brookfield_MO', label: 'Brookfield, MO' },
  { value: 'Brookhaven_NY', label: 'Brookhaven, NY' },
  { value: 'Brookhaven_PA', label: 'Brookhaven, PA' },
  { value: 'Brookhaven_MS', label: 'Brookhaven, MS' },
  { value: 'Brookings_SD', label: 'Brookings, SD' },
  { value: 'Brookings_OR', label: 'Brookings, OR' },
  { value: 'Brookland_AR', label: 'Brookland, AR' },
  { value: 'Brooklet_GA', label: 'Brooklet, GA' },
  { value: 'Brooklin_ME', label: 'Brooklin, ME' },
  { value: 'Brookline_MA', label: 'Brookline, MA' },
  { value: 'Brookline_NH', label: 'Brookline, NH' },
  { value: 'Brookline_MO', label: 'Brookline, MO' },
  { value: 'Brooklyn_CT', label: 'Brooklyn, CT' },
  { value: 'Brooklyn_NY', label: 'Brooklyn, NY' },
  { value: 'Brooklyn_MD', label: 'Brooklyn, MD' },
  { value: 'Brooklyn_MS', label: 'Brooklyn, MS' },
  { value: 'Brooklyn_IN', label: 'Brooklyn, IN' },
  { value: 'Brooklyn_MI', label: 'Brooklyn, MI' },
  { value: 'Brooklyn_IA', label: 'Brooklyn, IA' },
  { value: 'Brooklyn_WI', label: 'Brooklyn, WI' },
  { value: 'Brookneal_VA', label: 'Brookneal, VA' },
  { value: 'Brookpark_OH', label: 'Brookpark, OH' },
  { value: 'Brookport_IL', label: 'Brookport, IL' },
  { value: 'Brooks_ME', label: 'Brooks, ME' },
  { value: 'Brooks_GA', label: 'Brooks, GA' },
  { value: 'Brooks_KY', label: 'Brooks, KY' },
  { value: 'Brooks_MN', label: 'Brooks, MN' },
  { value: 'Brooks_CA', label: 'Brooks, CA' },
  { value: 'Brookshire_TX', label: 'Brookshire, TX' },
  { value: 'Brookside_NJ', label: 'Brookside, NJ' },
  { value: 'Brookside_AL', label: 'Brookside, AL' },
  { value: 'Brookston_IN', label: 'Brookston, IN' },
  { value: 'Brookston_MN', label: 'Brookston, MN' },
  { value: 'Brookston_TX', label: 'Brookston, TX' },
  { value: 'Brooksville_ME', label: 'Brooksville, ME' },
  { value: 'Brooksville_FL', label: 'Brooksville, FL' },
  { value: 'Brooksville_MS', label: 'Brooksville, MS' },
  { value: 'Brooksville_KY', label: 'Brooksville, KY' },
  { value: 'Brookton_ME', label: 'Brookton, ME' },
  { value: 'Brooktondale_NY', label: 'Brooktondale, NY' },
  { value: 'Brookville_PA', label: 'Brookville, PA' },
  { value: 'Brookville_OH', label: 'Brookville, OH' },
  { value: 'Brookville_IN', label: 'Brookville, IN' },
  { value: 'Brookville_KS', label: 'Brookville, KS' },
  { value: 'Brookwood_AL', label: 'Brookwood, AL' },
  { value: 'Broomall_PA', label: 'Broomall, PA' },
  { value: 'Broomes Island_MD', label: 'Broomes Island, MD' },
  { value: 'Broomfield_CO', label: 'Broomfield, CO' },
  { value: 'Brooten_MN', label: 'Brooten, MN' },
  { value: 'Broseley_MO', label: 'Broseley, MO' },
  { value: 'Brothers_OR', label: 'Brothers, OR' },
  { value: 'Broughton_IL', label: 'Broughton, IL' },
  { value: 'Broussard_LA', label: 'Broussard, LA' },
  { value: 'Browder_KY', label: 'Browder, KY' },
  { value: 'Browerville_MN', label: 'Browerville, MN' },
  { value: 'Brown City_MI', label: 'Brown City, MI' },
  { value: 'Brownell_KS', label: 'Brownell, KS' },
  { value: 'Brownfield_ME', label: 'Brownfield, ME' },
  { value: 'Brownfield_PA', label: 'Brownfield, PA' },
  { value: 'Brownfield_TX', label: 'Brownfield, TX' },
  { value: 'Browning_MT', label: 'Browning, MT' },
  { value: 'Browning_IL', label: 'Browning, IL' },
  { value: 'Browning_MO', label: 'Browning, MO' },
  { value: 'Browns_IL', label: 'Browns, IL' },
  { value: 'Browns Mills_NJ', label: 'Browns Mills, NJ' },
  { value: 'Browns Summit_NC', label: 'Browns Summit, NC' },
  { value: 'Browns Valley_MN', label: 'Browns Valley, MN' },
  { value: 'Browns Valley_CA', label: 'Browns Valley, CA' },
  { value: 'Brownsboro_AL', label: 'Brownsboro, AL' },
  { value: 'Brownsboro_TX', label: 'Brownsboro, TX' },
  { value: 'Brownsburg_VA', label: 'Brownsburg, VA' },
  { value: 'Brownsburg_IN', label: 'Brownsburg, IN' },
  { value: 'Brownsdale_MN', label: 'Brownsdale, MN' },
  { value: 'Brownstown_PA', label: 'Brownstown, PA' },
  { value: 'Brownstown_IN', label: 'Brownstown, IN' },
  { value: 'Brownstown_IL', label: 'Brownstown, IL' },
  { value: 'Brownsville_VT', label: 'Brownsville, VT' },
  { value: 'Brownsville_PA', label: 'Brownsville, PA' },
  { value: 'Brownsville_MD', label: 'Brownsville, MD' },
  { value: 'Brownsville_TN', label: 'Brownsville, TN' },
  { value: 'Brownsville_KY', label: 'Brownsville, KY' },
  { value: 'Brownsville_OH', label: 'Brownsville, OH' },
  { value: 'Brownsville_IN', label: 'Brownsville, IN' },
  { value: 'Brownsville_WI', label: 'Brownsville, WI' },
  { value: 'Brownsville_MN', label: 'Brownsville, MN' },
  { value: 'Brownsville_TX', label: 'Brownsville, TX' },
  { value: 'Brownsville_CA', label: 'Brownsville, CA' },
  { value: 'Brownsville_OR', label: 'Brownsville, OR' },
  { value: 'Brownton_MN', label: 'Brownton, MN' },
  { value: 'Browntown_WI', label: 'Browntown, WI' },
  { value: 'Brownville_ME', label: 'Brownville, ME' },
  { value: 'Brownville_NY', label: 'Brownville, NY' },
  { value: 'Brownville_NE', label: 'Brownville, NE' },
  { value: 'Brownville Junction_ME', label: 'Brownville Junction, ME' },
  { value: 'Brownwood_MO', label: 'Brownwood, MO' },
  { value: 'Brownwood_TX', label: 'Brownwood, TX' },
  { value: 'Broxton_GA', label: 'Broxton, GA' },
  { value: 'Bruce_MS', label: 'Bruce, MS' },
  { value: 'Bruce_WI', label: 'Bruce, WI' },
  { value: 'Bruce_SD', label: 'Bruce, SD' },
  { value: 'Bruce Crossing_MI', label: 'Bruce Crossing, MI' },
  { value: 'Bruceton_TN', label: 'Bruceton, TN' },
  { value: 'Bruceton Mills_WV', label: 'Bruceton Mills, WV' },
  { value: 'Bruceville_IN', label: 'Bruceville, IN' },
  { value: 'Bruceville_TX', label: 'Bruceville, TX' },
  { value: 'Bruin_PA', label: 'Bruin, PA' },
  { value: 'Bruington_VA', label: 'Bruington, VA' },
  { value: 'Brule_WI', label: 'Brule, WI' },
  { value: 'Brule_NE', label: 'Brule, NE' },
  { value: 'Brumley_MO', label: 'Brumley, MO' },
  { value: 'Brundidge_AL', label: 'Brundidge, AL' },
  { value: 'Bruneau_ID', label: 'Bruneau, ID' },
  { value: 'Bruner_MO', label: 'Bruner, MO' },
  { value: 'Bruni_TX', label: 'Bruni, TX' },
  { value: 'Bruning_NE', label: 'Bruning, NE' },
  { value: 'Bruno_WV', label: 'Bruno, WV' },
  { value: 'Bruno_MN', label: 'Bruno, MN' },
  { value: 'Bruno_NE', label: 'Bruno, NE' },
  { value: 'Brunson_SC', label: 'Brunson, SC' },
  { value: 'Brunsville_IA', label: 'Brunsville, IA' },
  { value: 'Brunswick_ME', label: 'Brunswick, ME' },
  { value: 'Brunswick_MD', label: 'Brunswick, MD' },
  { value: 'Brunswick_NC', label: 'Brunswick, NC' },
  { value: 'Brunswick_GA', label: 'Brunswick, GA' },
  { value: 'Brunswick_OH', label: 'Brunswick, OH' },
  { value: 'Brunswick_MO', label: 'Brunswick, MO' },
  { value: 'Brunswick_NE', label: 'Brunswick, NE' },
  { value: 'Brusett_MT', label: 'Brusett, MT' },
  { value: 'Brush_CO', label: 'Brush, CO' },
  { value: 'Brush Creek_TN', label: 'Brush Creek, TN' },
  { value: 'Brush Prairie_WA', label: 'Brush Prairie, WA' },
  { value: 'Brush Valley_PA', label: 'Brush Valley, PA' },
  { value: 'Brushton_NY', label: 'Brushton, NY' },
  { value: 'Brusly_LA', label: 'Brusly, LA' },
  { value: 'Brussels_WI', label: 'Brussels, WI' },
  { value: 'Brussels_IL', label: 'Brussels, IL' },
  { value: 'Brutus_MI', label: 'Brutus, MI' },
  { value: 'Bryan_OH', label: 'Bryan, OH' },
  { value: 'Bryan_TX', label: 'Bryan, TX' },
  { value: 'Bryans Road_MD', label: 'Bryans Road, MD' },
  { value: 'Bryant_AL', label: 'Bryant, AL' },
  { value: 'Bryant_IN', label: 'Bryant, IN' },
  { value: 'Bryant_IA', label: 'Bryant, IA' },
  { value: 'Bryant_WI', label: 'Bryant, WI' },
  { value: 'Bryant_SD', label: 'Bryant, SD' },
  { value: 'Bryant_IL', label: 'Bryant, IL' },
  { value: 'Bryant_AR', label: 'Bryant, AR' },
  { value: 'Bryant Pond_ME', label: 'Bryant Pond, ME' },
  { value: 'Bryantown_MD', label: 'Bryantown, MD' },
  { value: 'Bryants Store_KY', label: 'Bryants Store, KY' },
  { value: 'Bryce_UT', label: 'Bryce, UT' },
  { value: 'Bryceville_FL', label: 'Bryceville, FL' },
  { value: 'Bryn Athyn_PA', label: 'Bryn Athyn, PA' },
  { value: 'Bryn Mawr_PA', label: 'Bryn Mawr, PA' },
  { value: 'Bryson_TX', label: 'Bryson, TX' },
  { value: 'Bryson City_NC', label: 'Bryson City, NC' },
  { value: 'Buchanan_NY', label: 'Buchanan, NY' },
  { value: 'Buchanan_VA', label: 'Buchanan, VA' },
  { value: 'Buchanan_GA', label: 'Buchanan, GA' },
  { value: 'Buchanan_TN', label: 'Buchanan, TN' },
  { value: 'Buchanan_MI', label: 'Buchanan, MI' },
  { value: 'Buchanan_ND', label: 'Buchanan, ND' },
  { value: 'Buchanan Dam_TX', label: 'Buchanan Dam, TX' },
  { value: 'Buchtel_OH', label: 'Buchtel, OH' },
  { value: 'Buck Creek_IN', label: 'Buck Creek, IN' },
  { value: 'Buck Hill Falls_PA', label: 'Buck Hill Falls, PA' },
  { value: 'Buckatunna_MS', label: 'Buckatunna, MS' },
  { value: 'Buckeye_WV', label: 'Buckeye, WV' },
  { value: 'Buckeye_AZ', label: 'Buckeye, AZ' },
  { value: 'Buckeye Lake_OH', label: 'Buckeye Lake, OH' },
  { value: 'Buckeystown_MD', label: 'Buckeystown, MD' },
  { value: 'Buckfield_ME', label: 'Buckfield, ME' },
  { value: 'Buckhannon_WV', label: 'Buckhannon, WV' },
  { value: 'Buckhead_GA', label: 'Buckhead, GA' },
  { value: 'Buckholts_TX', label: 'Buckholts, TX' },
  { value: 'Buckhorn_KY', label: 'Buckhorn, KY' },
  { value: 'Buckhorn_NM', label: 'Buckhorn, NM' },
  { value: 'Buckingham_PA', label: 'Buckingham, PA' },
  { value: 'Buckingham_VA', label: 'Buckingham, VA' },
  { value: 'Buckingham_IA', label: 'Buckingham, IA' },
  { value: 'Buckingham_IL', label: 'Buckingham, IL' },
  { value: 'Buckland_MA', label: 'Buckland, MA' },
  { value: 'Buckland_OH', label: 'Buckland, OH' },
  { value: 'Buckland_AK', label: 'Buckland, AK' },
  { value: 'Buckley_MI', label: 'Buckley, MI' },
  { value: 'Buckley_IL', label: 'Buckley, IL' },
  { value: 'Buckley_WA', label: 'Buckley, WA' },
  { value: 'Bucklin_MO', label: 'Bucklin, MO' },
  { value: 'Bucklin_KS', label: 'Bucklin, KS' },
  { value: 'Buckner_KY', label: 'Buckner, KY' },
  { value: 'Buckner_IL', label: 'Buckner, IL' },
  { value: 'Buckner_MO', label: 'Buckner, MO' },
  { value: 'Buckner_AR', label: 'Buckner, AR' },
  { value: 'Bucks_AL', label: 'Bucks, AL' },
  { value: 'Bucksport_ME', label: 'Bucksport, ME' },
  { value: 'Bucoda_WA', label: 'Bucoda, WA' },
  { value: 'Bucyrus_OH', label: 'Bucyrus, OH' },
  { value: 'Bucyrus_MO', label: 'Bucyrus, MO' },
  { value: 'Bucyrus_KS', label: 'Bucyrus, KS' },
  { value: 'Bud_WV', label: 'Bud, WV' },
  { value: 'Buda_IL', label: 'Buda, IL' },
  { value: 'Buda_TX', label: 'Buda, TX' },
  { value: 'Budd Lake_NJ', label: 'Budd Lake, NJ' },
  { value: 'Bude_MS', label: 'Bude, MS' },
  { value: 'Buellton_CA', label: 'Buellton, CA' },
  { value: 'Buena_NJ', label: 'Buena, NJ' },
  { value: 'Buena_WA', label: 'Buena, WA' },
  { value: 'Buena Park_CA', label: 'Buena Park, CA' },
  { value: 'Buena Vista_PA', label: 'Buena Vista, PA' },
  { value: 'Buena Vista_VA', label: 'Buena Vista, VA' },
  { value: 'Buena Vista_GA', label: 'Buena Vista, GA' },
  { value: 'Buena Vista_TN', label: 'Buena Vista, TN' },
  { value: 'Buena Vista_CO', label: 'Buena Vista, CO' },
  { value: 'Buena Vista_NM', label: 'Buena Vista, NM' },
  { value: 'Buffalo_NY', label: 'Buffalo, NY' },
  { value: 'Buffalo_WV', label: 'Buffalo, WV' },
  { value: 'Buffalo_SC', label: 'Buffalo, SC' },
  { value: 'Buffalo_KY', label: 'Buffalo, KY' },
  { value: 'Buffalo_OH', label: 'Buffalo, OH' },
  { value: 'Buffalo_IN', label: 'Buffalo, IN' },
  { value: 'Buffalo_IA', label: 'Buffalo, IA' },
  { value: 'Buffalo_MN', label: 'Buffalo, MN' },
  { value: 'Buffalo_SD', label: 'Buffalo, SD' },
  { value: 'Buffalo_ND', label: 'Buffalo, ND' },
  { value: 'Buffalo_MT', label: 'Buffalo, MT' },
  { value: 'Buffalo_IL', label: 'Buffalo, IL' },
  { value: 'Buffalo_MO', label: 'Buffalo, MO' },
  { value: 'Buffalo_KS', label: 'Buffalo, KS' },
  { value: 'Buffalo_OK', label: 'Buffalo, OK' },
  { value: 'Buffalo_TX', label: 'Buffalo, TX' },
  { value: 'Buffalo_WY', label: 'Buffalo, WY' },
  { value: 'Buffalo Center_IA', label: 'Buffalo Center, IA' },
  { value: 'Buffalo Creek_CO', label: 'Buffalo Creek, CO' },
  { value: 'Buffalo Gap_SD', label: 'Buffalo Gap, SD' },
  { value: 'Buffalo Gap_TX', label: 'Buffalo Gap, TX' },
  { value: 'Buffalo Grove_IL', label: 'Buffalo Grove, IL' },
  { value: 'Buffalo Junction_VA', label: 'Buffalo Junction, VA' },
  { value: 'Buffalo Lake_MN', label: 'Buffalo Lake, MN' },
  { value: 'Buffalo Mills_PA', label: 'Buffalo Mills, PA' },
  { value: 'Buffalo Prairie_IL', label: 'Buffalo Prairie, IL' },
  { value: 'Buffalo Valley_TN', label: 'Buffalo Valley, TN' },
  { value: 'Buford_GA', label: 'Buford, GA' },
  { value: 'Buford_WY', label: 'Buford, WY' },
  { value: 'Buhl_AL', label: 'Buhl, AL' },
  { value: 'Buhl_MN', label: 'Buhl, MN' },
  { value: 'Buhl_ID', label: 'Buhl, ID' },
  { value: 'Buhler_KS', label: 'Buhler, KS' },
  { value: 'Buies Creek_NC', label: 'Buies Creek, NC' },
  { value: 'Bulan_KY', label: 'Bulan, KY' },
  { value: 'Bulger_PA', label: 'Bulger, PA' },
  { value: 'Bull Shoals_AR', label: 'Bull Shoals, AR' },
  { value: 'Bullard_TX', label: 'Bullard, TX' },
  { value: 'Bullhead_SD', label: 'Bullhead, SD' },
  { value: 'Bullhead City_AZ', label: 'Bullhead City, AZ' },
  { value: 'Bullock_NC', label: 'Bullock, NC' },
  { value: 'Bulls Gap_TN', label: 'Bulls Gap, TN' },
  { value: 'Bullville_NY', label: 'Bullville, NY' },
  { value: 'Bulpitt_IL', label: 'Bulpitt, IL' },
  { value: 'Bulverde_TX', label: 'Bulverde, TX' },
  { value: 'Bumpass_VA', label: 'Bumpass, VA' },
  { value: 'Bumpus Mills_TN', label: 'Bumpus Mills, TN' },
  { value: 'Buna_TX', label: 'Buna, TX' },
  { value: 'Bunceton_MO', label: 'Bunceton, MO' },
  { value: 'Bunch_OK', label: 'Bunch, OK' },
  { value: 'Buncombe_IL', label: 'Buncombe, IL' },
  { value: 'Bunker_MO', label: 'Bunker, MO' },
  { value: 'Bunker Hill_WV', label: 'Bunker Hill, WV' },
  { value: 'Bunker Hill_IN', label: 'Bunker Hill, IN' },
  { value: 'Bunker Hill_IL', label: 'Bunker Hill, IL' },
  { value: 'Bunker Hill_KS', label: 'Bunker Hill, KS' },
  { value: 'Bunkerville_NV', label: 'Bunkerville, NV' },
  { value: 'Bunkie_LA', label: 'Bunkie, LA' },
  { value: 'Bunn_NC', label: 'Bunn, NC' },
  { value: 'Bunnell_FL', label: 'Bunnell, FL' },
  { value: 'Bunnlevel_NC', label: 'Bunnlevel, NC' },
  { value: 'Bunola_PA', label: 'Bunola, PA' },
  { value: 'Buras_LA', label: 'Buras, LA' },
  { value: 'Burbank_OH', label: 'Burbank, OH' },
  { value: 'Burbank_SD', label: 'Burbank, SD' },
  { value: 'Burbank_IL', label: 'Burbank, IL' },
  { value: 'Burbank_OK', label: 'Burbank, OK' },
  { value: 'Burbank_CA', label: 'Burbank, CA' },
  { value: 'Burbank_WA', label: 'Burbank, WA' },
  { value: 'Burchard_NE', label: 'Burchard, NE' },
  { value: 'Burden_KS', label: 'Burden, KS' },
  { value: 'Burdett_NY', label: 'Burdett, NY' },
  { value: 'Burdett_KS', label: 'Burdett, KS' },
  { value: 'Burdick_KS', label: 'Burdick, KS' },
  { value: 'Burdine_KY', label: 'Burdine, KY' },
  { value: 'Bureau_IL', label: 'Bureau, IL' },
  { value: 'Burfordville_MO', label: 'Burfordville, MO' },
  { value: 'Burgaw_NC', label: 'Burgaw, NC' },
  { value: 'Burgess_VA', label: 'Burgess, VA' },
  { value: 'Burgettstown_PA', label: 'Burgettstown, PA' },
  { value: 'Burghill_OH', label: 'Burghill, OH' },
  { value: 'Burgin_KY', label: 'Burgin, KY' },
  { value: 'Burgoon_OH', label: 'Burgoon, OH' },
  { value: 'Burkburnett_TX', label: 'Burkburnett, TX' },
  { value: 'Burke_NY', label: 'Burke, NY' },
  { value: 'Burke_VA', label: 'Burke, VA' },
  { value: 'Burke_SD', label: 'Burke, SD' },
  { value: 'Burkesville_KY', label: 'Burkesville, KY' },
  { value: 'Burket_IN', label: 'Burket, IN' },
  { value: 'Burkett_TX', label: 'Burkett, TX' },
  { value: 'Burkettsville_OH', label: 'Burkettsville, OH' },
  { value: 'Burkeville_VA', label: 'Burkeville, VA' },
  { value: 'Burkeville_TX', label: 'Burkeville, TX' },
  { value: 'Burkittsville_MD', label: 'Burkittsville, MD' },
  { value: 'Burleson_TX', label: 'Burleson, TX' },
  { value: 'Burley_ID', label: 'Burley, ID' },
  { value: 'Burlingame_KS', label: 'Burlingame, KS' },
  { value: 'Burlingame_CA', label: 'Burlingame, CA' },
  { value: 'Burlingham_NY', label: 'Burlingham, NY' },
  { value: 'Burlington_MA', label: 'Burlington, MA' },
  { value: 'Burlington_ME', label: 'Burlington, ME' },
  { value: 'Burlington_VT', label: 'Burlington, VT' },
  { value: 'Burlington_CT', label: 'Burlington, CT' },
  { value: 'Burlington_NJ', label: 'Burlington, NJ' },
  { value: 'Burlington_PA', label: 'Burlington, PA' },
  { value: 'Burlington_WV', label: 'Burlington, WV' },
  { value: 'Burlington_NC', label: 'Burlington, NC' },
  { value: 'Burlington_KY', label: 'Burlington, KY' },
  { value: 'Burlington_IN', label: 'Burlington, IN' },
  { value: 'Burlington_MI', label: 'Burlington, MI' },
  { value: 'Burlington_IA', label: 'Burlington, IA' },
  { value: 'Burlington_WI', label: 'Burlington, WI' },
  { value: 'Burlington_ND', label: 'Burlington, ND' },
  { value: 'Burlington_IL', label: 'Burlington, IL' },
  { value: 'Burlington_KS', label: 'Burlington, KS' },
  { value: 'Burlington_OK', label: 'Burlington, OK' },
  { value: 'Burlington_TX', label: 'Burlington, TX' },
  { value: 'Burlington_CO', label: 'Burlington, CO' },
  { value: 'Burlington_WY', label: 'Burlington, WY' },
  { value: 'Burlington_WA', label: 'Burlington, WA' },
  { value: 'Burlington Flats_NY', label: 'Burlington Flats, NY' },
  { value: 'Burlington Junction_MO', label: 'Burlington Junction, MO' },
  { value: 'Burlison_TN', label: 'Burlison, TN' },
  { value: 'Burna_KY', label: 'Burna, KY' },
  { value: 'Burnet_TX', label: 'Burnet, TX' },
  { value: 'Burnett_WI', label: 'Burnett, WI' },
  { value: 'Burnettsville_IN', label: 'Burnettsville, IN' },
  { value: 'Burney_CA', label: 'Burney, CA' },
  { value: 'Burneyville_OK', label: 'Burneyville, OK' },
  { value: 'Burnham_ME', label: 'Burnham, ME' },
  { value: 'Burnham_PA', label: 'Burnham, PA' },
  { value: 'Burns_TN', label: 'Burns, TN' },
  { value: 'Burns_KS', label: 'Burns, KS' },
  { value: 'Burns_CO', label: 'Burns, CO' },
  { value: 'Burns_WY', label: 'Burns, WY' },
  { value: 'Burns_OR', label: 'Burns, OR' },
  { value: 'Burns Flat_OK', label: 'Burns Flat, OK' },
  { value: 'Burnside_PA', label: 'Burnside, PA' },
  { value: 'Burnside_KY', label: 'Burnside, KY' },
  { value: 'Burnside_IA', label: 'Burnside, IA' },
  { value: 'Burnsville_WV', label: 'Burnsville, WV' },
  { value: 'Burnsville_NC', label: 'Burnsville, NC' },
  { value: 'Burnsville_MS', label: 'Burnsville, MS' },
  { value: 'Burnsville_MN', label: 'Burnsville, MN' },
  { value: 'Burnt Cabins_PA', label: 'Burnt Cabins, PA' },
  { value: 'Burnt Hills_NY', label: 'Burnt Hills, NY' },
  { value: 'Burnt Prairie_IL', label: 'Burnt Prairie, IL' },
  { value: 'Burnt Ranch_CA', label: 'Burnt Ranch, CA' },
  { value: 'Burr_NE', label: 'Burr, NE' },
  { value: 'Burr Hill_VA', label: 'Burr Hill, VA' },
  { value: 'Burr Oak_MI', label: 'Burr Oak, MI' },
  { value: 'Burr Oak_KS', label: 'Burr Oak, KS' },
  { value: 'Burrton_KS', label: 'Burrton, KS' },
  { value: 'Burson_CA', label: 'Burson, CA' },
  { value: 'Burt_NY', label: 'Burt, NY' },
  { value: 'Burt_MI', label: 'Burt, MI' },
  { value: 'Burt_IA', label: 'Burt, IA' },
  { value: 'Burt Lake_MI', label: 'Burt Lake, MI' },
  { value: 'Burton_WV', label: 'Burton, WV' },
  { value: 'Burton_OH', label: 'Burton, OH' },
  { value: 'Burton_MI', label: 'Burton, MI' },
  { value: 'Burton_TX', label: 'Burton, TX' },
  { value: 'Burtonsville_MD', label: 'Burtonsville, MD' },
  { value: 'Burtrum_MN', label: 'Burtrum, MN' },
  { value: 'Burwell_NE', label: 'Burwell, NE' },
  { value: 'Busby_MT', label: 'Busby, MT' },
  { value: 'Bush_LA', label: 'Bush, LA' },
  { value: 'Bushkill_PA', label: 'Bushkill, PA' },
  { value: 'Bushland_TX', label: 'Bushland, TX' },
  { value: 'Bushnell_FL', label: 'Bushnell, FL' },
  { value: 'Bushnell_IL', label: 'Bushnell, IL' },
  { value: 'Bushnell_NE', label: 'Bushnell, NE' },
  { value: 'Bushton_KS', label: 'Bushton, KS' },
  { value: 'Bushwood_MD', label: 'Bushwood, MD' },
  { value: 'Buskirk_NY', label: 'Buskirk, NY' },
  { value: 'Bussey_IA', label: 'Bussey, IA' },
  { value: 'Busy_KY', label: 'Busy, KY' },
  { value: 'Butler_NJ', label: 'Butler, NJ' },
  { value: 'Butler_PA', label: 'Butler, PA' },
  { value: 'Butler_MD', label: 'Butler, MD' },
  { value: 'Butler_GA', label: 'Butler, GA' },
  { value: 'Butler_AL', label: 'Butler, AL' },
  { value: 'Butler_TN', label: 'Butler, TN' },
  { value: 'Butler_KY', label: 'Butler, KY' },
  { value: 'Butler_OH', label: 'Butler, OH' },
  { value: 'Butler_IN', label: 'Butler, IN' },
  { value: 'Butler_WI', label: 'Butler, WI' },
  { value: 'Butler_IL', label: 'Butler, IL' },
  { value: 'Butler_MO', label: 'Butler, MO' },
  { value: 'Butler_OK', label: 'Butler, OK' },
  { value: 'Butlerville_IN', label: 'Butlerville, IN' },
  { value: 'Butner_NC', label: 'Butner, NC' },
  { value: 'Butte_ND', label: 'Butte, ND' },
  { value: 'Butte_MT', label: 'Butte, MT' },
  { value: 'Butte_NE', label: 'Butte, NE' },
  { value: 'Butte City_CA', label: 'Butte City, CA' },
  { value: 'Butte Des Morts_WI', label: 'Butte Des Morts, WI' },
  { value: 'Butte Falls_OR', label: 'Butte Falls, OR' },
  { value: 'Butterfield_MN', label: 'Butterfield, MN' },
  { value: 'Butterfield_MO', label: 'Butterfield, MO' },
  { value: 'Butternut_WI', label: 'Butternut, WI' },
  { value: 'Buttonwillow_CA', label: 'Buttonwillow, CA' },
  { value: 'Buxton_ME', label: 'Buxton, ME' },
  { value: 'Buxton_NC', label: 'Buxton, NC' },
  { value: 'Buxton_ND', label: 'Buxton, ND' },
  { value: 'Buxton_OR', label: 'Buxton, OR' },
  { value: 'Buzzards Bay_MA', label: 'Buzzards Bay, MA' },
  { value: 'Byars_OK', label: 'Byars, OK' },
  { value: 'Bybee_TN', label: 'Bybee, TN' },
  { value: 'Byers_KS', label: 'Byers, KS' },
  { value: 'Byers_TX', label: 'Byers, TX' },
  { value: 'Byers_CO', label: 'Byers, CO' },
  { value: 'Byesville_OH', label: 'Byesville, OH' },
  { value: 'Byfield_MA', label: 'Byfield, MA' },
  { value: 'Byhalia_MS', label: 'Byhalia, MS' },
  { value: 'Bylas_AZ', label: 'Bylas, AZ' },
  { value: 'Bynum_MT', label: 'Bynum, MT' },
  { value: 'Bynum_TX', label: 'Bynum, TX' },
  { value: 'Bypro_KY', label: 'Bypro, KY' },
  { value: 'Byram_MS', label: 'Byram, MS' },
  { value: 'Byrdstown_TN', label: 'Byrdstown, TN' },
  { value: 'Byrnedale_PA', label: 'Byrnedale, PA' },
  { value: 'Byromville_GA', label: 'Byromville, GA' },
  { value: 'Byron_NY', label: 'Byron, NY' },
  { value: 'Byron_GA', label: 'Byron, GA' },
  { value: 'Byron_MI', label: 'Byron, MI' },
  { value: 'Byron_MN', label: 'Byron, MN' },
  { value: 'Byron_IL', label: 'Byron, IL' },
  { value: 'Byron_NE', label: 'Byron, NE' },
  { value: 'Byron_WY', label: 'Byron, WY' },
  { value: 'Byron_CA', label: 'Byron, CA' },
  { value: 'Byron Center_MI', label: 'Byron Center, MI' },
  { value: 'Caballo_NM', label: 'Caballo, NM' },
  { value: 'Cabazon_CA', label: 'Cabazon, CA' },
  { value: 'Cabery_IL', label: 'Cabery, IL' },
  { value: 'Cabin Creek_WV', label: 'Cabin Creek, WV' },
  { value: 'Cabin John_MD', label: 'Cabin John, MD' },
  { value: 'Cabins_WV', label: 'Cabins, WV' },
  { value: 'Cable_OH', label: 'Cable, OH' },
  { value: 'Cable_WI', label: 'Cable, WI' },
  { value: 'Cabo Rojo_PR', label: 'Cabo Rojo, PR' },
  { value: 'Cabool_MO', label: 'Cabool, MO' },
  { value: 'Cabot_VT', label: 'Cabot, VT' },
  { value: 'Cabot_PA', label: 'Cabot, PA' },
  { value: 'Cabot_AR', label: 'Cabot, AR' },
  { value: 'Cache_OK', label: 'Cache, OK' },
  { value: 'Cache Junction_UT', label: 'Cache Junction, UT' },
  { value: 'Cactus_TX', label: 'Cactus, TX' },
  { value: 'Caddo_OK', label: 'Caddo, OK' },
  { value: 'Caddo_TX', label: 'Caddo, TX' },
  { value: 'Caddo Gap_AR', label: 'Caddo Gap, AR' },
  { value: 'Caddo Mills_TX', label: 'Caddo Mills, TX' },
  { value: 'Cade_LA', label: 'Cade, LA' },
  { value: 'Cades_SC', label: 'Cades, SC' },
  { value: 'Cadet_MO', label: 'Cadet, MO' },
  { value: 'Cadillac_MI', label: 'Cadillac, MI' },
  { value: 'Cadiz_KY', label: 'Cadiz, KY' },
  { value: 'Cadiz_OH', label: 'Cadiz, OH' },
  { value: 'Cadogan_PA', label: 'Cadogan, PA' },
  { value: 'Cadott_WI', label: 'Cadott, WI' },
  { value: 'Cadwell_GA', label: 'Cadwell, GA' },
  { value: 'Cadyville_NY', label: 'Cadyville, NY' },
  { value: 'Caguas_PR', label: 'Caguas, PR' },
  { value: 'Cahone_CO', label: 'Cahone, CO' },
  { value: 'Cainsville_MO', label: 'Cainsville, MO' },
  { value: 'Cairnbrook_PA', label: 'Cairnbrook, PA' },
  { value: 'Cairo_NY', label: 'Cairo, NY' },
  { value: 'Cairo_WV', label: 'Cairo, WV' },
  { value: 'Cairo_GA', label: 'Cairo, GA' },
  { value: 'Cairo_OH', label: 'Cairo, OH' },
  { value: 'Cairo_IL', label: 'Cairo, IL' },
  { value: 'Cairo_MO', label: 'Cairo, MO' },
  { value: 'Cairo_NE', label: 'Cairo, NE' },
  { value: 'Cal Nev Ari_NV', label: 'Cal Nev Ari, NV' },
  { value: 'Calabasas_CA', label: 'Calabasas, CA' },
  { value: 'Calabash_NC', label: 'Calabash, NC' },
  { value: 'Calais_ME', label: 'Calais, ME' },
  { value: 'Calais_VT', label: 'Calais, VT' },
  { value: 'Calamus_IA', label: 'Calamus, IA' },
  { value: 'Calcium_NY', label: 'Calcium, NY' },
  { value: 'Calder_ID', label: 'Calder, ID' },
  { value: 'Caldwell_NJ', label: 'Caldwell, NJ' },
  { value: 'Caldwell_WV', label: 'Caldwell, WV' },
  { value: 'Caldwell_OH', label: 'Caldwell, OH' },
  { value: 'Caldwell_KS', label: 'Caldwell, KS' },
  { value: 'Caldwell_AR', label: 'Caldwell, AR' },
  { value: 'Caldwell_TX', label: 'Caldwell, TX' },
  { value: 'Caldwell_ID', label: 'Caldwell, ID' },
  { value: 'Caledonia_NY', label: 'Caledonia, NY' },
  { value: 'Caledonia_MS', label: 'Caledonia, MS' },
  { value: 'Caledonia_OH', label: 'Caledonia, OH' },
  { value: 'Caledonia_MI', label: 'Caledonia, MI' },
  { value: 'Caledonia_WI', label: 'Caledonia, WI' },
  { value: 'Caledonia_MN', label: 'Caledonia, MN' },
  { value: 'Caledonia_ND', label: 'Caledonia, ND' },
  { value: 'Caledonia_IL', label: 'Caledonia, IL' },
  { value: 'Caledonia_MO', label: 'Caledonia, MO' },
  { value: 'Calera_AL', label: 'Calera, AL' },
  { value: 'Calera_OK', label: 'Calera, OK' },
  { value: 'Calexico_CA', label: 'Calexico, CA' },
  { value: 'Calhan_CO', label: 'Calhan, CO' },
  { value: 'Calhoun_GA', label: 'Calhoun, GA' },
  { value: 'Calhoun_TN', label: 'Calhoun, TN' },
  { value: 'Calhoun_KY', label: 'Calhoun, KY' },
  { value: 'Calhoun_IL', label: 'Calhoun, IL' },
  { value: 'Calhoun_MO', label: 'Calhoun, MO' },
  { value: 'Calhoun_LA', label: 'Calhoun, LA' },
  { value: 'Calhoun City_MS', label: 'Calhoun City, MS' },
  { value: 'Calhoun Falls_SC', label: 'Calhoun Falls, SC' },
  { value: 'Calico Rock_AR', label: 'Calico Rock, AR' },
  { value: 'Caliente_NV', label: 'Caliente, NV' },
  { value: 'Caliente_CA', label: 'Caliente, CA' },
  { value: 'Califon_NJ', label: 'Califon, NJ' },
  { value: 'California_PA', label: 'California, PA' },
  { value: 'California_MD', label: 'California, MD' },
  { value: 'California_KY', label: 'California, KY' },
  { value: 'California_MO', label: 'California, MO' },
  { value: 'California City_CA', label: 'California City, CA' },
  { value: 'California Hot Springs_CA', label: 'California Hot Springs, CA' },
  { value: 'Calimesa_CA', label: 'Calimesa, CA' },
  { value: 'Calion_AR', label: 'Calion, AR' },
  { value: 'Calipatria_CA', label: 'Calipatria, CA' },
  { value: 'Calistoga_CA', label: 'Calistoga, CA' },
  { value: 'Call_TX', label: 'Call, TX' },
  { value: 'Callahan_FL', label: 'Callahan, FL' },
  { value: 'Callahan_CA', label: 'Callahan, CA' },
  { value: 'Callands_VA', label: 'Callands, VA' },
  { value: 'Callao_VA', label: 'Callao, VA' },
  { value: 'Callao_MO', label: 'Callao, MO' },
  { value: 'Callaway_MD', label: 'Callaway, MD' },
  { value: 'Callaway_VA', label: 'Callaway, VA' },
  { value: 'Callaway_MN', label: 'Callaway, MN' },
  { value: 'Callaway_NE', label: 'Callaway, NE' },
  { value: 'Callender_IA', label: 'Callender, IA' },
  { value: 'Callensburg_PA', label: 'Callensburg, PA' },
  { value: 'Callery_PA', label: 'Callery, PA' },
  { value: 'Callicoon_NY', label: 'Callicoon, NY' },
  { value: 'Callicoon Center_NY', label: 'Callicoon Center, NY' },
  { value: 'Calliham_TX', label: 'Calliham, TX' },
  { value: 'Calmar_IA', label: 'Calmar, IA' },
  { value: 'Calpine_CA', label: 'Calpine, CA' },
  { value: 'Calumet_MI', label: 'Calumet, MI' },
  { value: 'Calumet_IA', label: 'Calumet, IA' },
  { value: 'Calumet_MN', label: 'Calumet, MN' },
  { value: 'Calumet_OK', label: 'Calumet, OK' },
  { value: 'Calumet City_IL', label: 'Calumet City, IL' },
  { value: 'Calvert_AL', label: 'Calvert, AL' },
  { value: 'Calvert_TX', label: 'Calvert, TX' },
  { value: 'Calvert City_KY', label: 'Calvert City, KY' },
  { value: 'Calverton_NY', label: 'Calverton, NY' },
  { value: 'Calvin_PA', label: 'Calvin, PA' },
  { value: 'Calvin_WV', label: 'Calvin, WV' },
  { value: 'Calvin_KY', label: 'Calvin, KY' },
  { value: 'Calvin_ND', label: 'Calvin, ND' },
  { value: 'Calvin_LA', label: 'Calvin, LA' },
  { value: 'Calvin_OK', label: 'Calvin, OK' },
  { value: 'Calypso_NC', label: 'Calypso, NC' },
  { value: 'Camak_GA', label: 'Camak, GA' },
  { value: 'Camanche_IA', label: 'Camanche, IA' },
  { value: 'Camano Island_WA', label: 'Camano Island, WA' },
  { value: 'Camargo_IL', label: 'Camargo, IL' },
  { value: 'Camargo_OK', label: 'Camargo, OK' },
  { value: 'Camarillo_CA', label: 'Camarillo, CA' },
  { value: 'Camas_WA', label: 'Camas, WA' },
  { value: 'Camas Valley_OR', label: 'Camas Valley, OR' },
  { value: 'Cambria_WI', label: 'Cambria, WI' },
  { value: 'Cambria_IL', label: 'Cambria, IL' },
  { value: 'Cambria_CA', label: 'Cambria, CA' },
  { value: 'Cambria Heights_NY', label: 'Cambria Heights, NY' },
  { value: 'Cambridge_MA', label: 'Cambridge, MA' },
  { value: 'Cambridge_ME', label: 'Cambridge, ME' },
  { value: 'Cambridge_VT', label: 'Cambridge, VT' },
  { value: 'Cambridge_NY', label: 'Cambridge, NY' },
  { value: 'Cambridge_MD', label: 'Cambridge, MD' },
  { value: 'Cambridge_OH', label: 'Cambridge, OH' },
  { value: 'Cambridge_IA', label: 'Cambridge, IA' },
  { value: 'Cambridge_WI', label: 'Cambridge, WI' },
  { value: 'Cambridge_MN', label: 'Cambridge, MN' },
  { value: 'Cambridge_IL', label: 'Cambridge, IL' },
  { value: 'Cambridge_KS', label: 'Cambridge, KS' },
  { value: 'Cambridge_NE', label: 'Cambridge, NE' },
  { value: 'Cambridge_ID', label: 'Cambridge, ID' },
  { value: 'Cambridge City_IN', label: 'Cambridge City, IN' },
  { value: 'Cambridge Springs_PA', label: 'Cambridge Springs, PA' },
  { value: 'Cambridgeport_VT', label: 'Cambridgeport, VT' },
  { value: 'Camby_IN', label: 'Camby, IN' },
  { value: 'Camden_ME', label: 'Camden, ME' },
  { value: 'Camden_NJ', label: 'Camden, NJ' },
  { value: 'Camden_NY', label: 'Camden, NY' },
  { value: 'Camden_WV', label: 'Camden, WV' },
  { value: 'Camden_NC', label: 'Camden, NC' },
  { value: 'Camden_SC', label: 'Camden, SC' },
  { value: 'Camden_AL', label: 'Camden, AL' },
  { value: 'Camden_TN', label: 'Camden, TN' },
  { value: 'Camden_MS', label: 'Camden, MS' },
  { value: 'Camden_OH', label: 'Camden, OH' },
  { value: 'Camden_IN', label: 'Camden, IN' },
  { value: 'Camden_MI', label: 'Camden, MI' },
  { value: 'Camden_IL', label: 'Camden, IL' },
  { value: 'Camden_MO', label: 'Camden, MO' },
  { value: 'Camden_AR', label: 'Camden, AR' },
  { value: 'Camden_TX', label: 'Camden, TX' },
  { value: 'Camden On Gauley_WV', label: 'Camden On Gauley, WV' },
  { value: 'Camden Point_MO', label: 'Camden Point, MO' },
  { value: 'Camden Wyoming_DE', label: 'Camden Wyoming, DE' },
  { value: 'Camdenton_MO', label: 'Camdenton, MO' },
  { value: 'Cameron_NY', label: 'Cameron, NY' },
  { value: 'Cameron_WV', label: 'Cameron, WV' },
  { value: 'Cameron_NC', label: 'Cameron, NC' },
  { value: 'Cameron_SC', label: 'Cameron, SC' },
  { value: 'Cameron_OH', label: 'Cameron, OH' },
  { value: 'Cameron_WI', label: 'Cameron, WI' },
  { value: 'Cameron_MT', label: 'Cameron, MT' },
  { value: 'Cameron_IL', label: 'Cameron, IL' },
  { value: 'Cameron_MO', label: 'Cameron, MO' },
  { value: 'Cameron_LA', label: 'Cameron, LA' },
  { value: 'Cameron_OK', label: 'Cameron, OK' },
  { value: 'Cameron_TX', label: 'Cameron, TX' },
  { value: 'Cameron_AZ', label: 'Cameron, AZ' },
  { value: 'Cameron Mills_NY', label: 'Cameron Mills, NY' },
  { value: 'Camilla_GA', label: 'Camilla, GA' },
  { value: 'Camillus_NY', label: 'Camillus, NY' },
  { value: 'Camino_CA', label: 'Camino, CA' },
  { value: 'Cammal_PA', label: 'Cammal, PA' },
  { value: 'Camp_AR', label: 'Camp, AR' },
  { value: 'Camp Creek_WV', label: 'Camp Creek, WV' },
  { value: 'Camp Crook_SD', label: 'Camp Crook, SD' },
  { value: 'Camp Dennison_OH', label: 'Camp Dennison, OH' },
  { value: 'Camp Douglas_WI', label: 'Camp Douglas, WI' },
  { value: 'Camp Grove_IL', label: 'Camp Grove, IL' },
  { value: 'Camp Hill_PA', label: 'Camp Hill, PA' },
  { value: 'Camp Hill_AL', label: 'Camp Hill, AL' },
  { value: 'Camp Lejeune_NC', label: 'Camp Lejeune, NC' },
  { value: 'Camp Meeker_CA', label: 'Camp Meeker, CA' },
  { value: 'Camp Murray_WA', label: 'Camp Murray, WA' },
  { value: 'Camp Nelson_CA', label: 'Camp Nelson, CA' },
  { value: 'Camp Pendleton_CA', label: 'Camp Pendleton, CA' },
  { value: 'Camp Point_IL', label: 'Camp Point, IL' },
  { value: 'Camp Sherman_OR', label: 'Camp Sherman, OR' },
  { value: 'Camp Verde_AZ', label: 'Camp Verde, AZ' },
  { value: 'Camp Wood_TX', label: 'Camp Wood, TX' },
  { value: 'Campaign_TN', label: 'Campaign, TN' },
  { value: 'Campbell_NY', label: 'Campbell, NY' },
  { value: 'Campbell_AL', label: 'Campbell, AL' },
  { value: 'Campbell_OH', label: 'Campbell, OH' },
  { value: 'Campbell_MN', label: 'Campbell, MN' },
  { value: 'Campbell_MO', label: 'Campbell, MO' },
  { value: 'Campbell_NE', label: 'Campbell, NE' },
  { value: 'Campbell_TX', label: 'Campbell, TX' },
  { value: 'Campbell_CA', label: 'Campbell, CA' },
  { value: 'Campbell Hall_NY', label: 'Campbell Hall, NY' },
  { value: 'Campbell Hill_IL', label: 'Campbell Hill, IL' },
  { value: 'Campbellsburg_KY', label: 'Campbellsburg, KY' },
  { value: 'Campbellsburg_IN', label: 'Campbellsburg, IN' },
  { value: 'Campbellsport_WI', label: 'Campbellsport, WI' },
  { value: 'Campbellsville_KY', label: 'Campbellsville, KY' },
  { value: 'Campbellton_FL', label: 'Campbellton, FL' },
  { value: 'Campbellton_TX', label: 'Campbellton, TX' },
  { value: 'Campbelltown_PA', label: 'Campbelltown, PA' },
  { value: 'Campo_CO', label: 'Campo, CO' },
  { value: 'Campo_CA', label: 'Campo, CA' },
  { value: 'Campo Seco_CA', label: 'Campo Seco, CA' },
  { value: 'Campobello_SC', label: 'Campobello, SC' },
  { value: 'Campti_LA', label: 'Campti, LA' },
  { value: 'Campton_NH', label: 'Campton, NH' },
  { value: 'Campton_KY', label: 'Campton, KY' },
  { value: 'Camptonville_CA', label: 'Camptonville, CA' },
  { value: 'Campus_IL', label: 'Campus, IL' },
  { value: 'Camuy_PR', label: 'Camuy, PR' },
  { value: 'Cana_VA', label: 'Cana, VA' },
  { value: 'Canaan_NH', label: 'Canaan, NH' },
  { value: 'Canaan_ME', label: 'Canaan, ME' },
  { value: 'Canaan_VT', label: 'Canaan, VT' },
  { value: 'Canaan_CT', label: 'Canaan, CT' },
  { value: 'Canaan_NY', label: 'Canaan, NY' },
  { value: 'Canaan_IN', label: 'Canaan, IN' },
  { value: 'Canada_KY', label: 'Canada, KY' },
  { value: 'Canadensis_PA', label: 'Canadensis, PA' },
  { value: 'Canadian_OK', label: 'Canadian, OK' },
  { value: 'Canadian_TX', label: 'Canadian, TX' },
  { value: 'Canajoharie_NY', label: 'Canajoharie, NY' },
  { value: 'Canal Fulton_OH', label: 'Canal Fulton, OH' },
  { value: 'Canal Point_FL', label: 'Canal Point, FL' },
  { value: 'Canal Winchester_OH', label: 'Canal Winchester, OH' },
  { value: 'Canalou_MO', label: 'Canalou, MO' },
  { value: 'Canandaigua_NY', label: 'Canandaigua, NY' },
  { value: 'Canaseraga_NY', label: 'Canaseraga, NY' },
  { value: 'Canastota_NY', label: 'Canastota, NY' },
  { value: 'Canby_MN', label: 'Canby, MN' },
  { value: 'Canby_CA', label: 'Canby, CA' },
  { value: 'Canby_OR', label: 'Canby, OR' },
  { value: 'Candia_NH', label: 'Candia, NH' },
  { value: 'Candler_NC', label: 'Candler, NC' },
  { value: 'Cando_ND', label: 'Cando, ND' },
  { value: 'Candor_NY', label: 'Candor, NY' },
  { value: 'Candor_NC', label: 'Candor, NC' },
  { value: 'Caneadea_NY', label: 'Caneadea, NY' },
  { value: 'Canehill_AR', label: 'Canehill, AR' },
  { value: 'Caney_KS', label: 'Caney, KS' },
  { value: 'Caney_OK', label: 'Caney, OK' },
  { value: 'Caneyville_KY', label: 'Caneyville, KY' },
  { value: 'Canfield_OH', label: 'Canfield, OH' },
  { value: 'Canisteo_NY', label: 'Canisteo, NY' },
  { value: 'Canistota_SD', label: 'Canistota, SD' },
  { value: 'Canjilon_NM', label: 'Canjilon, NM' },
  { value: 'Canmer_KY', label: 'Canmer, KY' },
  { value: 'Cannel City_KY', label: 'Cannel City, KY' },
  { value: 'Cannelburg_IN', label: 'Cannelburg, IN' },
  { value: 'Cannelton_WV', label: 'Cannelton, WV' },
  { value: 'Cannelton_IN', label: 'Cannelton, IN' },
  { value: 'Cannon_KY', label: 'Cannon, KY' },
  { value: 'Cannon Afb_NM', label: 'Cannon Afb, NM' },
  { value: 'Cannon Ball_ND', label: 'Cannon Ball, ND' },
  { value: 'Cannon Beach_OR', label: 'Cannon Beach, OR' },
  { value: 'Cannon Falls_MN', label: 'Cannon Falls, MN' },
  { value: 'Cannonville_UT', label: 'Cannonville, UT' },
  { value: 'Canoga Park_CA', label: 'Canoga Park, CA' },
  { value: 'Canon_GA', label: 'Canon, GA' },
  { value: 'Canon City_CO', label: 'Canon City, CO' },
  { value: 'Canones_NM', label: 'Canones, NM' },
  { value: 'Canonsburg_PA', label: 'Canonsburg, PA' },
  { value: 'Canova_SD', label: 'Canova, SD' },
  { value: 'Canovanas_PR', label: 'Canovanas, PR' },
  { value: 'Canterbury_NH', label: 'Canterbury, NH' },
  { value: 'Canterbury_CT', label: 'Canterbury, CT' },
  { value: 'Cantil_CA', label: 'Cantil, CA' },
  { value: 'Canton_MA', label: 'Canton, MA' },
  { value: 'Canton_ME', label: 'Canton, ME' },
  { value: 'Canton_CT', label: 'Canton, CT' },
  { value: 'Canton_NY', label: 'Canton, NY' },
  { value: 'Canton_PA', label: 'Canton, PA' },
  { value: 'Canton_NC', label: 'Canton, NC' },
  { value: 'Canton_GA', label: 'Canton, GA' },
  { value: 'Canton_MS', label: 'Canton, MS' },
  { value: 'Canton_OH', label: 'Canton, OH' },
  { value: 'Canton_MI', label: 'Canton, MI' },
  { value: 'Canton_MN', label: 'Canton, MN' },
  { value: 'Canton_SD', label: 'Canton, SD' },
  { value: 'Canton_IL', label: 'Canton, IL' },
  { value: 'Canton_MO', label: 'Canton, MO' },
  { value: 'Canton_KS', label: 'Canton, KS' },
  { value: 'Canton_OK', label: 'Canton, OK' },
  { value: 'Canton_TX', label: 'Canton, TX' },
  { value: 'Canton Center_CT', label: 'Canton Center, CT' },
  { value: 'Cantonment_FL', label: 'Cantonment, FL' },
  { value: 'Cantrall_IL', label: 'Cantrall, IL' },
  { value: 'Cantril_IA', label: 'Cantril, IA' },
  { value: 'Cantua Creek_CA', label: 'Cantua Creek, CA' },
  { value: 'Cantwell_AK', label: 'Cantwell, AK' },
  { value: 'Canute_OK', label: 'Canute, OK' },
  { value: 'Canutillo_TX', label: 'Canutillo, TX' },
  { value: 'Canvas_WV', label: 'Canvas, WV' },
  { value: 'Canyon_MN', label: 'Canyon, MN' },
  { value: 'Canyon_TX', label: 'Canyon, TX' },
  { value: 'Canyon_CA', label: 'Canyon, CA' },
  { value: 'Canyon City_OR', label: 'Canyon City, OR' },
  { value: 'Canyon Country_CA', label: 'Canyon Country, CA' },
  { value: 'Canyon Creek_MT', label: 'Canyon Creek, MT' },
  { value: 'Canyon Dam_CA', label: 'Canyon Dam, CA' },
  { value: 'Canyon Lake_TX', label: 'Canyon Lake, TX' },
  { value: 'Canyonville_OR', label: 'Canyonville, OR' },
  { value: 'Capac_MI', label: 'Capac, MI' },
  { value: 'Capay_CA', label: 'Capay, CA' },
  { value: 'Cape Canaveral_FL', label: 'Cape Canaveral, FL' },
  { value: 'Cape Charles_VA', label: 'Cape Charles, VA' },
  { value: 'Cape Coral_FL', label: 'Cape Coral, FL' },
  { value: 'Cape Elizabeth_ME', label: 'Cape Elizabeth, ME' },
  { value: 'Cape Fair_MO', label: 'Cape Fair, MO' },
  { value: 'Cape Girardeau_MO', label: 'Cape Girardeau, MO' },
  { value: 'Cape May_NJ', label: 'Cape May, NJ' },
  { value: 'Cape May Court House_NJ', label: 'Cape May Court House, NJ' },
  { value: 'Cape May Point_NJ', label: 'Cape May Point, NJ' },
  { value: 'Cape Neddick_ME', label: 'Cape Neddick, ME' },
  { value: 'Cape Vincent_NY', label: 'Cape Vincent, NY' },
  { value: 'Capistrano Beach_CA', label: 'Capistrano Beach, CA' },
  { value: 'Capitan_NM', label: 'Capitan, NM' },
  { value: 'Capitol_MT', label: 'Capitol, MT' },
  { value: 'Capitol Heights_MD', label: 'Capitol Heights, MD' },
  { value: 'Capitola_CA', label: 'Capitola, CA' },
  { value: 'Capon Bridge_WV', label: 'Capon Bridge, WV' },
  { value: 'Capon Springs_WV', label: 'Capon Springs, WV' },
  { value: 'Capron_VA', label: 'Capron, VA' },
  { value: 'Capron_IL', label: 'Capron, IL' },
  { value: 'Captain Cook_HI', label: 'Captain Cook, HI' },
  { value: 'Captiva_FL', label: 'Captiva, FL' },
  { value: 'Capulin_CO', label: 'Capulin, CO' },
  { value: 'Capulin_NM', label: 'Capulin, NM' },
  { value: 'Caputa_SD', label: 'Caputa, SD' },
  { value: 'Caratunk_ME', label: 'Caratunk, ME' },
  { value: 'Caraway_AR', label: 'Caraway, AR' },
  { value: 'Carbon_IN', label: 'Carbon, IN' },
  { value: 'Carbon_IA', label: 'Carbon, IA' },
  { value: 'Carbon_TX', label: 'Carbon, TX' },
  { value: 'Carbon Cliff_IL', label: 'Carbon Cliff, IL' },
  { value: 'Carbon Hill_AL', label: 'Carbon Hill, AL' },
  { value: 'Carbon Hill_OH', label: 'Carbon Hill, OH' },
  { value: 'Carbonado_WA', label: 'Carbonado, WA' },
  { value: 'Carbondale_PA', label: 'Carbondale, PA' },
  { value: 'Carbondale_IL', label: 'Carbondale, IL' },
  { value: 'Carbondale_KS', label: 'Carbondale, KS' },
  { value: 'Carbondale_CO', label: 'Carbondale, CO' },
  { value: 'Cardale_PA', label: 'Cardale, PA' },
  { value: 'Cardiff By The Sea_CA', label: 'Cardiff By The Sea, CA' },
  { value: 'Cardinal_VA', label: 'Cardinal, VA' },
  { value: 'Cardington_OH', label: 'Cardington, OH' },
  { value: 'Cardwell_MT', label: 'Cardwell, MT' },
  { value: 'Cardwell_MO', label: 'Cardwell, MO' },
  { value: 'Carefree_AZ', label: 'Carefree, AZ' },
  { value: 'Carencro_LA', label: 'Carencro, LA' },
  { value: 'Caret_VA', label: 'Caret, VA' },
  { value: 'Carey_OH', label: 'Carey, OH' },
  { value: 'Carey_ID', label: 'Carey, ID' },
  { value: 'Careywood_ID', label: 'Careywood, ID' },
  { value: 'Caribou_ME', label: 'Caribou, ME' },
  { value: 'Carl Junction_MO', label: 'Carl Junction, MO' },
  { value: 'Carle Place_NY', label: 'Carle Place, NY' },
  { value: 'Carleton_MI', label: 'Carleton, MI' },
  { value: 'Carleton_NE', label: 'Carleton, NE' },
  { value: 'Carlin_NV', label: 'Carlin, NV' },
  { value: 'Carlinville_IL', label: 'Carlinville, IL' },
  { value: 'Carlisle_MA', label: 'Carlisle, MA' },
  { value: 'Carlisle_NY', label: 'Carlisle, NY' },
  { value: 'Carlisle_PA', label: 'Carlisle, PA' },
  { value: 'Carlisle_SC', label: 'Carlisle, SC' },
  { value: 'Carlisle_KY', label: 'Carlisle, KY' },
  { value: 'Carlisle_IN', label: 'Carlisle, IN' },
  { value: 'Carlisle_IA', label: 'Carlisle, IA' },
  { value: 'Carlisle_AR', label: 'Carlisle, AR' },
  { value: 'Carlock_IL', label: 'Carlock, IL' },
  { value: 'Carlos_MN', label: 'Carlos, MN' },
  { value: 'Carlotta_CA', label: 'Carlotta, CA' },
  { value: 'Carlsbad_TX', label: 'Carlsbad, TX' },
  { value: 'Carlsbad_NM', label: 'Carlsbad, NM' },
  { value: 'Carlsbad_CA', label: 'Carlsbad, CA' },
  { value: 'Carlstadt_NJ', label: 'Carlstadt, NJ' },
  { value: 'Carlton_PA', label: 'Carlton, PA' },
  { value: 'Carlton_GA', label: 'Carlton, GA' },
  { value: 'Carlton_MN', label: 'Carlton, MN' },
  { value: 'Carlton_TX', label: 'Carlton, TX' },
  { value: 'Carlton_OR', label: 'Carlton, OR' },
  { value: 'Carlton_WA', label: 'Carlton, WA' },
  { value: 'Carlyle_IL', label: 'Carlyle, IL' },
  { value: 'Carman_IL', label: 'Carman, IL' },
  { value: 'Carmel_ME', label: 'Carmel, ME' },
  { value: 'Carmel_NY', label: 'Carmel, NY' },
  { value: 'Carmel_IN', label: 'Carmel, IN' },
  { value: 'Carmel_CA', label: 'Carmel, CA' },
  { value: 'Carmel By The Sea_CA', label: 'Carmel By The Sea, CA' },
  { value: 'Carmel Valley_CA', label: 'Carmel Valley, CA' },
  { value: 'Carmen_OK', label: 'Carmen, OK' },
  { value: 'Carmen_ID', label: 'Carmen, ID' },
  { value: 'Carmi_IL', label: 'Carmi, IL' },
  { value: 'Carmichael_CA', label: 'Carmichael, CA' },
  { value: 'Carmichaels_PA', label: 'Carmichaels, PA' },
  { value: 'Carmine_TX', label: 'Carmine, TX' },
  { value: 'Carnation_WA', label: 'Carnation, WA' },
  { value: 'Carnegie_PA', label: 'Carnegie, PA' },
  { value: 'Carnegie_OK', label: 'Carnegie, OK' },
  { value: 'Carnelian Bay_CA', label: 'Carnelian Bay, CA' },
  { value: 'Carnesville_GA', label: 'Carnesville, GA' },
  { value: 'Carney_MI', label: 'Carney, MI' },
  { value: 'Carney_OK', label: 'Carney, OK' },
  { value: 'Caro_MI', label: 'Caro, MI' },
  { value: 'Caroga Lake_NY', label: 'Caroga Lake, NY' },
  { value: 'Carol Stream_IL', label: 'Carol Stream, IL' },
  { value: 'Caroleen_NC', label: 'Caroleen, NC' },
  { value: 'Carolina_PR', label: 'Carolina, PR' },
  { value: 'Carolina_RI', label: 'Carolina, RI' },
  { value: 'Carolina_WV', label: 'Carolina, WV' },
  { value: 'Carolina Beach_NC', label: 'Carolina Beach, NC' },
  { value: 'Caroline_WI', label: 'Caroline, WI' },
  { value: 'Carp Lake_MI', label: 'Carp Lake, MI' },
  { value: 'Carpenter_IA', label: 'Carpenter, IA' },
  { value: 'Carpenter_SD', label: 'Carpenter, SD' },
  { value: 'Carpenter_WY', label: 'Carpenter, WY' },
  { value: 'Carpentersville_IL', label: 'Carpentersville, IL' },
  { value: 'Carpinteria_CA', label: 'Carpinteria, CA' },
  { value: 'Carpio_ND', label: 'Carpio, ND' },
  { value: 'Carr_CO', label: 'Carr, CO' },
  { value: 'Carrabelle_FL', label: 'Carrabelle, FL' },
  { value: 'Carrboro_NC', label: 'Carrboro, NC' },
  { value: 'Carrie_KY', label: 'Carrie, KY' },
  { value: 'Carrier_OK', label: 'Carrier, OK' },
  { value: 'Carrier Mills_IL', label: 'Carrier Mills, IL' },
  { value: 'Carriere_MS', label: 'Carriere, MS' },
  { value: 'Carrington_ND', label: 'Carrington, ND' },
  { value: 'Carrizo Springs_TX', label: 'Carrizo Springs, TX' },
  { value: 'Carrizozo_NM', label: 'Carrizozo, NM' },
  { value: 'Carroll_OH', label: 'Carroll, OH' },
  { value: 'Carroll_IA', label: 'Carroll, IA' },
  { value: 'Carroll_NE', label: 'Carroll, NE' },
  { value: 'Carrolls_WA', label: 'Carrolls, WA' },
  { value: 'Carrollton_VA', label: 'Carrollton, VA' },
  { value: 'Carrollton_GA', label: 'Carrollton, GA' },
  { value: 'Carrollton_AL', label: 'Carrollton, AL' },
  { value: 'Carrollton_MS', label: 'Carrollton, MS' },
  { value: 'Carrollton_KY', label: 'Carrollton, KY' },
  { value: 'Carrollton_OH', label: 'Carrollton, OH' },
  { value: 'Carrollton_MI', label: 'Carrollton, MI' },
  { value: 'Carrollton_IL', label: 'Carrollton, IL' },
  { value: 'Carrollton_MO', label: 'Carrollton, MO' },
  { value: 'Carrollton_TX', label: 'Carrollton, TX' },
  { value: 'Carrolltown_PA', label: 'Carrolltown, PA' },
  { value: 'Carrsville_VA', label: 'Carrsville, VA' },
  { value: 'Carson_VA', label: 'Carson, VA' },
  { value: 'Carson_MS', label: 'Carson, MS' },
  { value: 'Carson_IA', label: 'Carson, IA' },
  { value: 'Carson_ND', label: 'Carson, ND' },
  { value: 'Carson_NM', label: 'Carson, NM' },
  { value: 'Carson_CA', label: 'Carson, CA' },
  { value: 'Carson_WA', label: 'Carson, WA' },
  { value: 'Carson City_MI', label: 'Carson City, MI' },
  { value: 'Carson City_NV', label: 'Carson City, NV' },
  { value: 'Carsonville_MI', label: 'Carsonville, MI' },
  { value: 'Carter_MT', label: 'Carter, MT' },
  { value: 'Carter_OK', label: 'Carter, OK' },
  { value: 'Carter Lake_IA', label: 'Carter Lake, IA' },
  { value: 'Carteret_NJ', label: 'Carteret, NJ' },
  { value: 'Cartersville_VA', label: 'Cartersville, VA' },
  { value: 'Cartersville_GA', label: 'Cartersville, GA' },
  { value: 'Carterville_IL', label: 'Carterville, IL' },
  { value: 'Carterville_MO', label: 'Carterville, MO' },
  { value: 'Carthage_NY', label: 'Carthage, NY' },
  { value: 'Carthage_NC', label: 'Carthage, NC' },
  { value: 'Carthage_TN', label: 'Carthage, TN' },
  { value: 'Carthage_MS', label: 'Carthage, MS' },
  { value: 'Carthage_IN', label: 'Carthage, IN' },
  { value: 'Carthage_SD', label: 'Carthage, SD' },
  { value: 'Carthage_IL', label: 'Carthage, IL' },
  { value: 'Carthage_MO', label: 'Carthage, MO' },
  { value: 'Carthage_AR', label: 'Carthage, AR' },
  { value: 'Carthage_TX', label: 'Carthage, TX' },
  { value: 'Cartwright_ND', label: 'Cartwright, ND' },
  { value: 'Cartwright_OK', label: 'Cartwright, OK' },
  { value: 'Caruthers_CA', label: 'Caruthers, CA' },
  { value: 'Caruthersville_MO', label: 'Caruthersville, MO' },
  { value: 'Carver_MA', label: 'Carver, MA' },
  { value: 'Carver_MN', label: 'Carver, MN' },
  { value: 'Carversville_PA', label: 'Carversville, PA' },
  { value: 'Carville_LA', label: 'Carville, LA' },
  { value: 'Cary_NC', label: 'Cary, NC' },
  { value: 'Cary_MS', label: 'Cary, MS' },
  { value: 'Cary_IL', label: 'Cary, IL' },
  { value: 'Caryville_FL', label: 'Caryville, FL' },
  { value: 'Caryville_TN', label: 'Caryville, TN' },
  { value: 'Casa_AR', label: 'Casa, AR' },
  { value: 'Casa Blanca_NM', label: 'Casa Blanca, NM' },
  { value: 'Casa Grande_AZ', label: 'Casa Grande, AZ' },
  { value: 'Casar_NC', label: 'Casar, NC' },
  { value: 'Cascade_MD', label: 'Cascade, MD' },
  { value: 'Cascade_VA', label: 'Cascade, VA' },
  { value: 'Cascade_IA', label: 'Cascade, IA' },
  { value: 'Cascade_WI', label: 'Cascade, WI' },
  { value: 'Cascade_MT', label: 'Cascade, MT' },
  { value: 'Cascade_CO', label: 'Cascade, CO' },
  { value: 'Cascade_ID', label: 'Cascade, ID' },
  { value: 'Cascade Locks_OR', label: 'Cascade Locks, OR' },
  { value: 'Cascadia_OR', label: 'Cascadia, OR' },
  { value: 'Cascilla_MS', label: 'Cascilla, MS' },
  { value: 'Casco_ME', label: 'Casco, ME' },
  { value: 'Casco_MI', label: 'Casco, MI' },
  { value: 'Casco_WI', label: 'Casco, WI' },
  { value: 'Caseville_MI', label: 'Caseville, MI' },
  { value: 'Casey_IA', label: 'Casey, IA' },
  { value: 'Casey_IL', label: 'Casey, IL' },
  { value: 'Caseyville_IL', label: 'Caseyville, IL' },
  { value: 'Cash_AR', label: 'Cash, AR' },
  { value: 'Cashiers_NC', label: 'Cashiers, NC' },
  { value: 'Cashion_OK', label: 'Cashion, OK' },
  { value: 'Cashion_AZ', label: 'Cashion, AZ' },
  { value: 'Cashmere_WA', label: 'Cashmere, WA' },
  { value: 'Cashton_WI', label: 'Cashton, WI' },
  { value: 'Casmalia_CA', label: 'Casmalia, CA' },
  { value: 'Casnovia_MI', label: 'Casnovia, MI' },
  { value: 'Caspar_CA', label: 'Caspar, CA' },
  { value: 'Casper_WY', label: 'Casper, WY' },
  { value: 'Caspian_MI', label: 'Caspian, MI' },
  { value: 'Cass_WV', label: 'Cass, WV' },
  { value: 'Cass City_MI', label: 'Cass City, MI' },
  { value: 'Cass Lake_MN', label: 'Cass Lake, MN' },
  { value: 'Cassadaga_NY', label: 'Cassadaga, NY' },
  { value: 'Cassandra_PA', label: 'Cassandra, PA' },
  { value: 'Cassatt_SC', label: 'Cassatt, SC' },
  { value: 'Casscoe_AR', label: 'Casscoe, AR' },
  { value: 'Cassel_CA', label: 'Cassel, CA' },
  { value: 'Casselberry_FL', label: 'Casselberry, FL' },
  { value: 'Casselton_ND', label: 'Casselton, ND' },
  { value: 'Cassoday_KS', label: 'Cassoday, KS' },
  { value: 'Cassopolis_MI', label: 'Cassopolis, MI' },
  { value: 'Casstown_OH', label: 'Casstown, OH' },
  { value: 'Cassville_NY', label: 'Cassville, NY' },
  { value: 'Cassville_PA', label: 'Cassville, PA' },
  { value: 'Cassville_WI', label: 'Cassville, WI' },
  { value: 'Cassville_MO', label: 'Cassville, MO' },
  { value: 'Castaic_CA', label: 'Castaic, CA' },
  { value: 'Castalia_NC', label: 'Castalia, NC' },
  { value: 'Castalia_OH', label: 'Castalia, OH' },
  { value: 'Castalia_IA', label: 'Castalia, IA' },
  { value: 'Castalian Springs_TN', label: 'Castalian Springs, TN' },
  { value: 'Castana_IA', label: 'Castana, IA' },
  { value: 'Castaner_PR', label: 'Castaner, PR' },
  { value: 'Castell_TX', label: 'Castell, TX' },
  { value: 'Castella_CA', label: 'Castella, CA' },
  { value: 'Castile_NY', label: 'Castile, NY' },
  { value: 'Castine_ME', label: 'Castine, ME' },
  { value: 'Castle_OK', label: 'Castle, OK' },
  { value: 'Castle Creek_NY', label: 'Castle Creek, NY' },
  { value: 'Castle Dale_UT', label: 'Castle Dale, UT' },
  { value: 'Castle Hayne_NC', label: 'Castle Hayne, NC' },
  { value: 'Castle Rock_CO', label: 'Castle Rock, CO' },
  { value: 'Castle Rock_WA', label: 'Castle Rock, WA' },
  { value: 'Castleberry_AL', label: 'Castleberry, AL' },
  { value: 'Castleford_ID', label: 'Castleford, ID' },
  { value: 'Castleton_VT', label: 'Castleton, VT' },
  { value: 'Castleton_VA', label: 'Castleton, VA' },
  { value: 'Castleton_IL', label: 'Castleton, IL' },
  { value: 'Castleton On Hudson_NY', label: 'Castleton On Hudson, NY' },
  { value: 'Castlewood_VA', label: 'Castlewood, VA' },
  { value: 'Castlewood_SD', label: 'Castlewood, SD' },
  { value: 'Castor_LA', label: 'Castor, LA' },
  { value: 'Castorland_NY', label: 'Castorland, NY' },
  { value: 'Castro Valley_CA', label: 'Castro Valley, CA' },
  { value: 'Castroville_TX', label: 'Castroville, TX' },
  { value: 'Castroville_CA', label: 'Castroville, CA' },
  { value: 'Cat Spring_TX', label: 'Cat Spring, TX' },
  { value: 'Cataldo_ID', label: 'Cataldo, ID' },
  { value: 'Catano_PR', label: 'Catano, PR' },
  { value: 'Catarina_TX', label: 'Catarina, TX' },
  { value: 'Catasauqua_PA', label: 'Catasauqua, PA' },
  { value: 'Cataula_GA', label: 'Cataula, GA' },
  { value: 'Cataumet_MA', label: 'Cataumet, MA' },
  { value: 'Catawba_VA', label: 'Catawba, VA' },
  { value: 'Catawba_NC', label: 'Catawba, NC' },
  { value: 'Catawba_SC', label: 'Catawba, SC' },
  { value: 'Catawba_OH', label: 'Catawba, OH' },
  { value: 'Catawba_WI', label: 'Catawba, WI' },
  { value: 'Catawissa_PA', label: 'Catawissa, PA' },
  { value: 'Catawissa_MO', label: 'Catawissa, MO' },
  { value: 'Catharine_KS', label: 'Catharine, KS' },
  { value: 'Catharpin_VA', label: 'Catharpin, VA' },
  { value: 'Cathay_ND', label: 'Cathay, ND' },
  { value: 'Cathedral City_CA', label: 'Cathedral City, CA' },
  { value: 'Catherine_AL', label: 'Catherine, AL' },
  { value: 'Catheys Valley_CA', label: 'Catheys Valley, CA' },
  { value: 'Cathlamet_WA', label: 'Cathlamet, WA' },
  { value: 'Catlett_VA', label: 'Catlett, VA' },
  { value: 'Catlettsburg_KY', label: 'Catlettsburg, KY' },
  { value: 'Catlin_IL', label: 'Catlin, IL' },
  { value: 'Cato_NY', label: 'Cato, NY' },
  { value: 'Catonsville_MD', label: 'Catonsville, MD' },
  { value: 'Catoosa_OK', label: 'Catoosa, OK' },
  { value: 'Catron_MO', label: 'Catron, MO' },
  { value: 'Catskill_NY', label: 'Catskill, NY' },
  { value: 'Cattaraugus_NY', label: 'Cattaraugus, NY' },
  { value: 'Caulfield_MO', label: 'Caulfield, MO' },
  { value: 'Causey_NM', label: 'Causey, NM' },
  { value: 'Cavalier_ND', label: 'Cavalier, ND' },
  { value: 'Cave City_KY', label: 'Cave City, KY' },
  { value: 'Cave City_AR', label: 'Cave City, AR' },
  { value: 'Cave Creek_AZ', label: 'Cave Creek, AZ' },
  { value: 'Cave In Rock_IL', label: 'Cave In Rock, IL' },
  { value: 'Cave Junction_OR', label: 'Cave Junction, OR' },
  { value: 'Cave Spring_GA', label: 'Cave Spring, GA' },
  { value: 'Cave Springs_AR', label: 'Cave Springs, AR' },
  { value: 'Cavendish_VT', label: 'Cavendish, VT' },
  { value: 'Cavour_SD', label: 'Cavour, SD' },
  { value: 'Cawker City_KS', label: 'Cawker City, KS' },
  { value: 'Cawood_KY', label: 'Cawood, KY' },
  { value: 'Cayce_SC', label: 'Cayce, SC' },
  { value: 'Cayey_PR', label: 'Cayey, PR' },
  { value: 'Cayucos_CA', label: 'Cayucos, CA' },
  { value: 'Cayuga_NY', label: 'Cayuga, NY' },
  { value: 'Cayuga_IN', label: 'Cayuga, IN' },
  { value: 'Cayuga_ND', label: 'Cayuga, ND' },
  { value: 'Cayuta_NY', label: 'Cayuta, NY' },
  { value: 'Cazadero_CA', label: 'Cazadero, CA' },
  { value: 'Cazenovia_NY', label: 'Cazenovia, NY' },
  { value: 'Cazenovia_WI', label: 'Cazenovia, WI' },
  { value: 'Cebolla_NM', label: 'Cebolla, NM' },
  { value: 'Cecil_PA', label: 'Cecil, PA' },
  { value: 'Cecil_GA', label: 'Cecil, GA' },
  { value: 'Cecil_AL', label: 'Cecil, AL' },
  { value: 'Cecil_OH', label: 'Cecil, OH' },
  { value: 'Cecil_WI', label: 'Cecil, WI' },
  { value: 'Cecil_AR', label: 'Cecil, AR' },
  { value: 'Cecilia_KY', label: 'Cecilia, KY' },
  { value: 'Cecilton_MD', label: 'Cecilton, MD' },
  { value: 'Cedar_MI', label: 'Cedar, MI' },
  { value: 'Cedar_IA', label: 'Cedar, IA' },
  { value: 'Cedar_MN', label: 'Cedar, MN' },
  { value: 'Cedar_KS', label: 'Cedar, KS' },
  { value: 'Cedar Bluff_VA', label: 'Cedar Bluff, VA' },
  { value: 'Cedar Bluff_AL', label: 'Cedar Bluff, AL' },
  { value: 'Cedar Bluffs_NE', label: 'Cedar Bluffs, NE' },
  { value: 'Cedar City_UT', label: 'Cedar City, UT' },
  { value: 'Cedar Creek_NE', label: 'Cedar Creek, NE' },
  { value: 'Cedar Creek_TX', label: 'Cedar Creek, TX' },
  { value: 'Cedar Crest_NM', label: 'Cedar Crest, NM' },
  { value: 'Cedar Falls_IA', label: 'Cedar Falls, IA' },
  { value: 'Cedar Glen_CA', label: 'Cedar Glen, CA' },
  { value: 'Cedar Grove_NJ', label: 'Cedar Grove, NJ' },
  { value: 'Cedar Grove_WV', label: 'Cedar Grove, WV' },
  { value: 'Cedar Grove_NC', label: 'Cedar Grove, NC' },
  { value: 'Cedar Grove_TN', label: 'Cedar Grove, TN' },
  { value: 'Cedar Grove_IN', label: 'Cedar Grove, IN' },
  { value: 'Cedar Grove_WI', label: 'Cedar Grove, WI' },
  { value: 'Cedar Hill_TN', label: 'Cedar Hill, TN' },
  { value: 'Cedar Hill_MO', label: 'Cedar Hill, MO' },
  { value: 'Cedar Hill_TX', label: 'Cedar Hill, TX' },
  { value: 'Cedar Island_NC', label: 'Cedar Island, NC' },
  { value: 'Cedar Key_FL', label: 'Cedar Key, FL' },
  { value: 'Cedar Knolls_NJ', label: 'Cedar Knolls, NJ' },
  { value: 'Cedar Lake_IN', label: 'Cedar Lake, IN' },
  { value: 'Cedar Lane_TX', label: 'Cedar Lane, TX' },
  { value: 'Cedar Mountain_NC', label: 'Cedar Mountain, NC' },
  { value: 'Cedar Park_TX', label: 'Cedar Park, TX' },
  { value: 'Cedar Point_IL', label: 'Cedar Point, IL' },
  { value: 'Cedar Point_KS', label: 'Cedar Point, KS' },
  { value: 'Cedar Rapids_IA', label: 'Cedar Rapids, IA' },
  { value: 'Cedar Rapids_NE', label: 'Cedar Rapids, NE' },
  { value: 'Cedar Run_PA', label: 'Cedar Run, PA' },
  { value: 'Cedar Springs_GA', label: 'Cedar Springs, GA' },
  { value: 'Cedar Springs_MI', label: 'Cedar Springs, MI' },
  { value: 'Cedar Vale_KS', label: 'Cedar Vale, KS' },
  { value: 'Cedar Valley_UT', label: 'Cedar Valley, UT' },
  { value: 'Cedarbluff_MS', label: 'Cedarbluff, MS' },
  { value: 'Cedarburg_WI', label: 'Cedarburg, WI' },
  { value: 'Cedarcreek_MO', label: 'Cedarcreek, MO' },
  { value: 'Cedaredge_CO', label: 'Cedaredge, CO' },
  { value: 'Cedarhurst_NY', label: 'Cedarhurst, NY' },
  { value: 'Cedarpines Park_CA', label: 'Cedarpines Park, CA' },
  { value: 'Cedartown_GA', label: 'Cedartown, GA' },
  { value: 'Cedarville_NJ', label: 'Cedarville, NJ' },
  { value: 'Cedarville_WV', label: 'Cedarville, WV' },
  { value: 'Cedarville_OH', label: 'Cedarville, OH' },
  { value: 'Cedarville_MI', label: 'Cedarville, MI' },
  { value: 'Cedarville_IL', label: 'Cedarville, IL' },
  { value: 'Cedarville_AR', label: 'Cedarville, AR' },
  { value: 'Cedarville_CA', label: 'Cedarville, CA' },
  { value: 'Cee Vee_TX', label: 'Cee Vee, TX' },
  { value: 'Ceiba_PR', label: 'Ceiba, PR' },
  { value: 'Celeste_TX', label: 'Celeste, TX' },
  { value: 'Celestine_IN', label: 'Celestine, IN' },
  { value: 'Celina_TN', label: 'Celina, TN' },
  { value: 'Celina_OH', label: 'Celina, OH' },
  { value: 'Celina_TX', label: 'Celina, TX' },
  { value: 'Celoron_NY', label: 'Celoron, NY' },
  { value: 'Cement_OK', label: 'Cement, OK' },
  { value: 'Cement City_MI', label: 'Cement City, MI' },
  { value: 'Centenary_SC', label: 'Centenary, SC' },
  { value: 'Centennial_WY', label: 'Centennial, WY' },
  { value: 'Center_KY', label: 'Center, KY' },
  { value: 'Center_ND', label: 'Center, ND' },
  { value: 'Center_MO', label: 'Center, MO' },
  { value: 'Center_NE', label: 'Center, NE' },
  { value: 'Center_TX', label: 'Center, TX' },
  { value: 'Center_CO', label: 'Center, CO' },
  { value: 'Center Barnstead_NH', label: 'Center Barnstead, NH' },
  { value: 'Center City_MN', label: 'Center City, MN' },
  { value: 'Center Conway_NH', label: 'Center Conway, NH' },
  { value: 'Center Cross_VA', label: 'Center Cross, VA' },
  { value: 'Center Harbor_NH', label: 'Center Harbor, NH' },
  { value: 'Center Hill_FL', label: 'Center Hill, FL' },
  { value: 'Center Junction_IA', label: 'Center Junction, IA' },
  { value: 'Center Line_MI', label: 'Center Line, MI' },
  { value: 'Center Moriches_NY', label: 'Center Moriches, NY' },
  { value: 'Center Ossipee_NH', label: 'Center Ossipee, NH' },
  { value: 'Center Point_WV', label: 'Center Point, WV' },
  { value: 'Center Point_IA', label: 'Center Point, IA' },
  { value: 'Center Point_LA', label: 'Center Point, LA' },
  { value: 'Center Point_TX', label: 'Center Point, TX' },
  { value: 'Center Ridge_AR', label: 'Center Ridge, AR' },
  { value: 'Center Rutland_VT', label: 'Center Rutland, VT' },
  { value: 'Center Sandwich_NH', label: 'Center Sandwich, NH' },
  { value: 'Center Tuftonboro_NH', label: 'Center Tuftonboro, NH' },
  { value: 'Center Valley_PA', label: 'Center Valley, PA' },
  { value: 'Centerbrook_CT', label: 'Centerbrook, CT' },
  { value: 'Centerburg_OH', label: 'Centerburg, OH' },
  { value: 'Centereach_NY', label: 'Centereach, NY' },
  { value: 'Centerfield_UT', label: 'Centerfield, UT' },
  { value: 'Centerpoint_IN', label: 'Centerpoint, IN' },
  { value: 'Centerport_NY', label: 'Centerport, NY' },
  { value: 'Centerport_PA', label: 'Centerport, PA' },
  { value: 'Centerton_AR', label: 'Centerton, AR' },
  { value: 'Centertown_KY', label: 'Centertown, KY' },
  { value: 'Centertown_MO', label: 'Centertown, MO' },
  { value: 'Centerview_MO', label: 'Centerview, MO' },
  { value: 'Centerville_MA', label: 'Centerville, MA' },
  { value: 'Centerville_NY', label: 'Centerville, NY' },
  { value: 'Centerville_PA', label: 'Centerville, PA' },
  { value: 'Centerville_GA', label: 'Centerville, GA' },
  { value: 'Centerville_TN', label: 'Centerville, TN' },
  { value: 'Centerville_IN', label: 'Centerville, IN' },
  { value: 'Centerville_IA', label: 'Centerville, IA' },
  { value: 'Centerville_SD', label: 'Centerville, SD' },
  { value: 'Centerville_MO', label: 'Centerville, MO' },
  { value: 'Centerville_KS', label: 'Centerville, KS' },
  { value: 'Centerville_LA', label: 'Centerville, LA' },
  { value: 'Centerville_AR', label: 'Centerville, AR' },
  { value: 'Centerville_TX', label: 'Centerville, TX' },
  { value: 'Centerville_UT', label: 'Centerville, UT' },
  { value: 'Centerville_WA', label: 'Centerville, WA' },
  { value: 'Centrahoma_OK', label: 'Centrahoma, OK' },
  { value: 'Central_SC', label: 'Central, SC' },
  { value: 'Central_IN', label: 'Central, IN' },
  { value: 'Central_UT', label: 'Central, UT' },
  { value: 'Central_AZ', label: 'Central, AZ' },
  { value: 'Central_AK', label: 'Central, AK' },
  { value: 'Central Bridge_NY', label: 'Central Bridge, NY' },
  { value: 'Central City_PA', label: 'Central City, PA' },
  { value: 'Central City_KY', label: 'Central City, KY' },
  { value: 'Central City_IA', label: 'Central City, IA' },
  { value: 'Central City_NE', label: 'Central City, NE' },
  { value: 'Central City_CO', label: 'Central City, CO' },
  { value: 'Central Falls_RI', label: 'Central Falls, RI' },
  { value: 'Central Islip_NY', label: 'Central Islip, NY' },
  { value: 'Central Lake_MI', label: 'Central Lake, MI' },
  { value: 'Central Point_OR', label: 'Central Point, OR' },
  { value: 'Central Square_NY', label: 'Central Square, NY' },
  { value: 'Central Valley_NY', label: 'Central Valley, NY' },
  { value: 'Central Village_CT', label: 'Central Village, CT' },
  { value: 'Centralia_IL', label: 'Centralia, IL' },
  { value: 'Centralia_MO', label: 'Centralia, MO' },
  { value: 'Centralia_KS', label: 'Centralia, KS' },
  { value: 'Centralia_WA', label: 'Centralia, WA' },
  { value: 'Centre_AL', label: 'Centre, AL' },
  { value: 'Centre Hall_PA', label: 'Centre Hall, PA' },
  { value: 'Centreville_VA', label: 'Centreville, VA' },
  { value: 'Centreville_MD', label: 'Centreville, MD' },
  { value: 'Centreville_AL', label: 'Centreville, AL' },
  { value: 'Centreville_MS', label: 'Centreville, MS' },
  { value: 'Centreville_MI', label: 'Centreville, MI' },
  { value: 'Centuria_WI', label: 'Centuria, WI' },
  { value: 'Century_FL', label: 'Century, FL' },
  { value: 'Ceredo_WV', label: 'Ceredo, WV' },
  { value: 'Ceres_NY', label: 'Ceres, NY' },
  { value: 'Ceres_VA', label: 'Ceres, VA' },
  { value: 'Ceres_CA', label: 'Ceres, CA' },
  { value: 'Ceresco_MI', label: 'Ceresco, MI' },
  { value: 'Ceresco_NE', label: 'Ceresco, NE' },
  { value: 'Cerrillos_NM', label: 'Cerrillos, NM' },
  { value: 'Cerritos_CA', label: 'Cerritos, CA' },
  { value: 'Cerro_NM', label: 'Cerro, NM' },
  { value: 'Cerro Gordo_NC', label: 'Cerro Gordo, NC' },
  { value: 'Cerro Gordo_IL', label: 'Cerro Gordo, IL' },
  { value: 'Cerulean_KY', label: 'Cerulean, KY' },
  { value: 'Ceylon_MN', label: 'Ceylon, MN' },
  { value: 'Chacon_NM', label: 'Chacon, NM' },
  { value: 'Chadbourn_NC', label: 'Chadbourn, NC' },
  { value: 'Chadds Ford_PA', label: 'Chadds Ford, PA' },
  { value: 'Chadron_NE', label: 'Chadron, NE' },
  { value: 'Chadwick_IL', label: 'Chadwick, IL' },
  { value: 'Chadwick_MO', label: 'Chadwick, MO' },
  { value: 'Chadwicks_NY', label: 'Chadwicks, NY' },
  { value: 'Chaffee_NY', label: 'Chaffee, NY' },
  { value: 'Chaffee_MO', label: 'Chaffee, MO' },
  { value: 'Chagrin Falls_OH', label: 'Chagrin Falls, OH' },
  { value: 'Chalfont_PA', label: 'Chalfont, PA' },
  { value: 'Chalk Hill_PA', label: 'Chalk Hill, PA' },
  { value: 'Chalkyitsik_AK', label: 'Chalkyitsik, AK' },
  { value: 'Challenge_CA', label: 'Challenge, CA' },
  { value: 'Challis_ID', label: 'Challis, ID' },
  { value: 'Chalmers_IN', label: 'Chalmers, IN' },
  { value: 'Chalmette_LA', label: 'Chalmette, LA' },
  { value: 'Chama_CO', label: 'Chama, CO' },
  { value: 'Chama_NM', label: 'Chama, NM' },
  { value: 'Chamberino_NM', label: 'Chamberino, NM' },
  { value: 'Chamberlain_ME', label: 'Chamberlain, ME' },
  { value: 'Chamberlain_SD', label: 'Chamberlain, SD' },
  { value: 'Chambers_NE', label: 'Chambers, NE' },
  { value: 'Chambers_AZ', label: 'Chambers, AZ' },
  { value: 'Chambersburg_PA', label: 'Chambersburg, PA' },
  { value: 'Chambersburg_IL', label: 'Chambersburg, IL' },
  { value: 'Chambersville_PA', label: 'Chambersville, PA' },
  { value: 'Chamisal_NM', label: 'Chamisal, NM' },
  { value: 'Chamois_MO', label: 'Chamois, MO' },
  { value: 'Champaign_IL', label: 'Champaign, IL' },
  { value: 'Champion_PA', label: 'Champion, PA' },
  { value: 'Champion_MI', label: 'Champion, MI' },
  { value: 'Champion_NE', label: 'Champion, NE' },
  { value: 'Champlain_NY', label: 'Champlain, NY' },
  { value: 'Champlain_VA', label: 'Champlain, VA' },
  { value: 'Champlin_MN', label: 'Champlin, MN' },
  { value: 'Chana_IL', label: 'Chana, IL' },
  { value: 'Chancellor_AL', label: 'Chancellor, AL' },
  { value: 'Chancellor_SD', label: 'Chancellor, SD' },
  { value: 'Chandler_IN', label: 'Chandler, IN' },
  { value: 'Chandler_MN', label: 'Chandler, MN' },
  { value: 'Chandler_OK', label: 'Chandler, OK' },
  { value: 'Chandler_TX', label: 'Chandler, TX' },
  { value: 'Chandler_AZ', label: 'Chandler, AZ' },
  { value: 'Chandlers Valley_PA', label: 'Chandlers Valley, PA' },
  { value: 'Chandlersville_OH', label: 'Chandlersville, OH' },
  { value: 'Chandlerville_IL', label: 'Chandlerville, IL' },
  { value: 'Changewater_NJ', label: 'Changewater, NJ' },
  { value: 'Chanhassen_MN', label: 'Chanhassen, MN' },
  { value: 'Channahon_IL', label: 'Channahon, IL' },
  { value: 'Channelview_TX', label: 'Channelview, TX' },
  { value: 'Channing_MI', label: 'Channing, MI' },
  { value: 'Channing_TX', label: 'Channing, TX' },
  { value: 'Chantilly_VA', label: 'Chantilly, VA' },
  { value: 'Chanute_KS', label: 'Chanute, KS' },
  { value: 'Chaparral_NM', label: 'Chaparral, NM' },
  { value: 'Chapel Hill_NC', label: 'Chapel Hill, NC' },
  { value: 'Chapel Hill_TN', label: 'Chapel Hill, TN' },
  { value: 'Chapin_SC', label: 'Chapin, SC' },
  { value: 'Chapin_IA', label: 'Chapin, IA' },
  { value: 'Chapin_IL', label: 'Chapin, IL' },
  { value: 'Chaplin_CT', label: 'Chaplin, CT' },
  { value: 'Chaplin_KY', label: 'Chaplin, KY' },
  { value: 'Chapman_KS', label: 'Chapman, KS' },
  { value: 'Chapman_NE', label: 'Chapman, NE' },
  { value: 'Chapman Ranch_TX', label: 'Chapman Ranch, TX' },
  { value: 'Chapmansboro_TN', label: 'Chapmansboro, TN' },
  { value: 'Chapmanville_WV', label: 'Chapmanville, WV' },
  { value: 'Chappaqua_NY', label: 'Chappaqua, NY' },
  { value: 'Chappell_KY', label: 'Chappell, KY' },
  { value: 'Chappell_NE', label: 'Chappell, NE' },
  { value: 'Chappell Hill_TX', label: 'Chappell Hill, TX' },
  { value: 'Chappells_SC', label: 'Chappells, SC' },
  { value: 'Chaptico_MD', label: 'Chaptico, MD' },
  { value: 'Chardon_OH', label: 'Chardon, OH' },
  { value: 'Charenton_LA', label: 'Charenton, LA' },
  { value: 'Chariton_IA', label: 'Chariton, IA' },
  { value: 'Charlemont_MA', label: 'Charlemont, MA' },
  { value: 'Charleroi_PA', label: 'Charleroi, PA' },
  { value: 'Charles City_VA', label: 'Charles City, VA' },
  { value: 'Charles City_IA', label: 'Charles City, IA' },
  { value: 'Charles Town_WV', label: 'Charles Town, WV' },
  { value: 'Charleston_ME', label: 'Charleston, ME' },
  { value: 'Charleston_WV', label: 'Charleston, WV' },
  { value: 'Charleston_SC', label: 'Charleston, SC' },
  { value: 'Charleston_TN', label: 'Charleston, TN' },
  { value: 'Charleston_MS', label: 'Charleston, MS' },
  { value: 'Charleston_IL', label: 'Charleston, IL' },
  { value: 'Charleston_MO', label: 'Charleston, MO' },
  { value: 'Charleston_AR', label: 'Charleston, AR' },
  { value: 'Charleston Afb_SC', label: 'Charleston Afb, SC' },
  { value: 'Charlestown_MA', label: 'Charlestown, MA' },
  { value: 'Charlestown_RI', label: 'Charlestown, RI' },
  { value: 'Charlestown_NH', label: 'Charlestown, NH' },
  { value: 'Charlestown_MD', label: 'Charlestown, MD' },
  { value: 'Charlestown_IN', label: 'Charlestown, IN' },
  { value: 'Charlevoix_MI', label: 'Charlevoix, MI' },
  { value: 'Charlo_MT', label: 'Charlo, MT' },
  { value: 'Charlotte_VT', label: 'Charlotte, VT' },
  { value: 'Charlotte_NC', label: 'Charlotte, NC' },
  { value: 'Charlotte_TN', label: 'Charlotte, TN' },
  { value: 'Charlotte_MI', label: 'Charlotte, MI' },
  { value: 'Charlotte_IA', label: 'Charlotte, IA' },
  { value: 'Charlotte_AR', label: 'Charlotte, AR' },
  { value: 'Charlotte_TX', label: 'Charlotte, TX' },
  { value: 'Charlotte Court House_VA', label: 'Charlotte Court House, VA' },
  { value: 'Charlotte Hall_MD', label: 'Charlotte Hall, MD' },
  { value: 'Charlottesville_VA', label: 'Charlottesville, VA' },
  { value: 'Charlottesville_IN', label: 'Charlottesville, IN' },
  { value: 'Charlotteville_NY', label: 'Charlotteville, NY' },
  { value: 'Charlton_MA', label: 'Charlton, MA' },
  { value: 'Charlton Heights_WV', label: 'Charlton Heights, WV' },
  { value: 'Charmco_WV', label: 'Charmco, WV' },
  { value: 'Charter Oak_IA', label: 'Charter Oak, IA' },
  { value: 'Chase_MI', label: 'Chase, MI' },
  { value: 'Chase_KS', label: 'Chase, KS' },
  { value: 'Chase City_VA', label: 'Chase City, VA' },
  { value: 'Chase Mills_NY', label: 'Chase Mills, NY' },
  { value: 'Chaseburg_WI', label: 'Chaseburg, WI' },
  { value: 'Chaseley_ND', label: 'Chaseley, ND' },
  { value: 'Chaska_MN', label: 'Chaska, MN' },
  { value: 'Chassell_MI', label: 'Chassell, MI' },
  { value: 'Chataignier_LA', label: 'Chataignier, LA' },
  { value: 'Chateaugay_NY', label: 'Chateaugay, NY' },
  { value: 'Chatfield_OH', label: 'Chatfield, OH' },
  { value: 'Chatfield_MN', label: 'Chatfield, MN' },
  { value: 'Chatfield_TX', label: 'Chatfield, TX' },
  { value: 'Chatham_MA', label: 'Chatham, MA' },
  { value: 'Chatham_NJ', label: 'Chatham, NJ' },
  { value: 'Chatham_NY', label: 'Chatham, NY' },
  { value: 'Chatham_VA', label: 'Chatham, VA' },
  { value: 'Chatham_MS', label: 'Chatham, MS' },
  { value: 'Chatham_MI', label: 'Chatham, MI' },
  { value: 'Chatham_IL', label: 'Chatham, IL' },
  { value: 'Chatham_LA', label: 'Chatham, LA' },
  { value: 'Chatom_AL', label: 'Chatom, AL' },
  { value: 'Chatsworth_NJ', label: 'Chatsworth, NJ' },
  { value: 'Chatsworth_GA', label: 'Chatsworth, GA' },
  { value: 'Chatsworth_IA', label: 'Chatsworth, IA' },
  { value: 'Chatsworth_IL', label: 'Chatsworth, IL' },
  { value: 'Chatsworth_CA', label: 'Chatsworth, CA' },
  { value: 'Chattahoochee_FL', label: 'Chattahoochee, FL' },
  { value: 'Chattanooga_TN', label: 'Chattanooga, TN' },
  { value: 'Chattanooga_OK', label: 'Chattanooga, OK' },
  { value: 'Chattaroy_WV', label: 'Chattaroy, WV' },
  { value: 'Chattaroy_WA', label: 'Chattaroy, WA' },
  { value: 'Chaumont_NY', label: 'Chaumont, NY' },
  { value: 'Chauncey_WV', label: 'Chauncey, WV' },
  { value: 'Chauncey_GA', label: 'Chauncey, GA' },
  { value: 'Chauncey_OH', label: 'Chauncey, OH' },
  { value: 'Chautauqua_NY', label: 'Chautauqua, NY' },
  { value: 'Chautauqua_KS', label: 'Chautauqua, KS' },
  { value: 'Chauvin_LA', label: 'Chauvin, LA' },
  { value: 'Chavies_KY', label: 'Chavies, KY' },
  { value: 'Chazy_NY', label: 'Chazy, NY' },
  { value: 'Chebanse_IL', label: 'Chebanse, IL' },
  { value: 'Chebeague Island_ME', label: 'Chebeague Island, ME' },
  { value: 'Cheboygan_MI', label: 'Cheboygan, MI' },
  { value: 'Check_VA', label: 'Check, VA' },
  { value: 'Checotah_OK', label: 'Checotah, OK' },
  { value: 'Chefornak_AK', label: 'Chefornak, AK' },
  { value: 'Chehalis_WA', label: 'Chehalis, WA' },
  { value: 'Chelan_WA', label: 'Chelan, WA' },
  { value: 'Chelan Falls_WA', label: 'Chelan Falls, WA' },
  { value: 'Chelmsford_MA', label: 'Chelmsford, MA' },
  { value: 'Chelsea_MA', label: 'Chelsea, MA' },
  { value: 'Chelsea_VT', label: 'Chelsea, VT' },
  { value: 'Chelsea_NY', label: 'Chelsea, NY' },
  { value: 'Chelsea_AL', label: 'Chelsea, AL' },
  { value: 'Chelsea_MI', label: 'Chelsea, MI' },
  { value: 'Chelsea_IA', label: 'Chelsea, IA' },
  { value: 'Chelsea_OK', label: 'Chelsea, OK' },
  { value: 'Cheltenham_PA', label: 'Cheltenham, PA' },
  { value: 'Cheltenham_MD', label: 'Cheltenham, MD' },
  { value: 'Chemult_OR', label: 'Chemult, OR' },
  { value: 'Chemung_NY', label: 'Chemung, NY' },
  { value: 'Chenango Forks_NY', label: 'Chenango Forks, NY' },
  { value: 'Cheney_KS', label: 'Cheney, KS' },
  { value: 'Cheney_WA', label: 'Cheney, WA' },
  { value: 'Cheneyville_LA', label: 'Cheneyville, LA' },
  { value: 'Chenoa_IL', label: 'Chenoa, IL' },
  { value: 'Chepachet_RI', label: 'Chepachet, RI' },
  { value: 'Cheraw_SC', label: 'Cheraw, SC' },
  { value: 'Cheraw_CO', label: 'Cheraw, CO' },
  { value: 'Cheriton_VA', label: 'Cheriton, VA' },
  { value: 'Cherokee_NC', label: 'Cherokee, NC' },
  { value: 'Cherokee_AL', label: 'Cherokee, AL' },
  { value: 'Cherokee_IA', label: 'Cherokee, IA' },
  { value: 'Cherokee_KS', label: 'Cherokee, KS' },
  { value: 'Cherokee_OK', label: 'Cherokee, OK' },
  { value: 'Cherokee_TX', label: 'Cherokee, TX' },
  { value: 'Cherokee Village_AR', label: 'Cherokee Village, AR' },
  { value: 'Cherry_IL', label: 'Cherry, IL' },
  { value: 'Cherry Creek_NY', label: 'Cherry Creek, NY' },
  { value: 'Cherry Creek_SD', label: 'Cherry Creek, SD' },
  { value: 'Cherry Fork_OH', label: 'Cherry Fork, OH' },
  { value: 'Cherry Hill_NJ', label: 'Cherry Hill, NJ' },
  { value: 'Cherry Log_GA', label: 'Cherry Log, GA' },
  { value: 'Cherry Plain_NY', label: 'Cherry Plain, NY' },
  { value: 'Cherry Point_NC', label: 'Cherry Point, NC' },
  { value: 'Cherry Tree_PA', label: 'Cherry Tree, PA' },
  { value: 'Cherry Valley_MA', label: 'Cherry Valley, MA' },
  { value: 'Cherry Valley_NY', label: 'Cherry Valley, NY' },
  { value: 'Cherry Valley_IL', label: 'Cherry Valley, IL' },
  { value: 'Cherry Valley_AR', label: 'Cherry Valley, AR' },
  { value: 'Cherryfield_ME', label: 'Cherryfield, ME' },
  { value: 'Cherryvale_KS', label: 'Cherryvale, KS' },
  { value: 'Cherryville_PA', label: 'Cherryville, PA' },
  { value: 'Cherryville_NC', label: 'Cherryville, NC' },
  { value: 'Cherryville_MO', label: 'Cherryville, MO' },
  { value: 'Chesaning_MI', label: 'Chesaning, MI' },
  { value: 'Chesapeake_VA', label: 'Chesapeake, VA' },
  { value: 'Chesapeake_OH', label: 'Chesapeake, OH' },
  { value: 'Chesapeake Beach_MD', label: 'Chesapeake Beach, MD' },
  { value: 'Chesapeake City_MD', label: 'Chesapeake City, MD' },
  { value: 'Cheshire_MA', label: 'Cheshire, MA' },
  { value: 'Cheshire_CT', label: 'Cheshire, CT' },
  { value: 'Cheshire_OH', label: 'Cheshire, OH' },
  { value: 'Cheshire_OR', label: 'Cheshire, OR' },
  { value: 'Chesnee_SC', label: 'Chesnee, SC' },
  { value: 'Chest Springs_PA', label: 'Chest Springs, PA' },
  { value: 'Chester_MA', label: 'Chester, MA' },
  { value: 'Chester_NH', label: 'Chester, NH' },
  { value: 'Chester_VT', label: 'Chester, VT' },
  { value: 'Chester_CT', label: 'Chester, CT' },
  { value: 'Chester_NJ', label: 'Chester, NJ' },
  { value: 'Chester_NY', label: 'Chester, NY' },
  { value: 'Chester_PA', label: 'Chester, PA' },
  { value: 'Chester_MD', label: 'Chester, MD' },
  { value: 'Chester_VA', label: 'Chester, VA' },
  { value: 'Chester_WV', label: 'Chester, WV' },
  { value: 'Chester_SC', label: 'Chester, SC' },
  { value: 'Chester_GA', label: 'Chester, GA' },
  { value: 'Chester_OH', label: 'Chester, OH' },
  { value: 'Chester_IA', label: 'Chester, IA' },
  { value: 'Chester_SD', label: 'Chester, SD' },
  { value: 'Chester_MT', label: 'Chester, MT' },
  { value: 'Chester_IL', label: 'Chester, IL' },
  { value: 'Chester_NE', label: 'Chester, NE' },
  { value: 'Chester_AR', label: 'Chester, AR' },
  { value: 'Chester_OK', label: 'Chester, OK' },
  { value: 'Chester_TX', label: 'Chester, TX' },
  { value: 'Chester_ID', label: 'Chester, ID' },
  { value: 'Chester_UT', label: 'Chester, UT' },
  { value: 'Chester_CA', label: 'Chester, CA' },
  { value: 'Chester Gap_VA', label: 'Chester Gap, VA' },
  { value: 'Chester Heights_PA', label: 'Chester Heights, PA' },
  { value: 'Chester Springs_PA', label: 'Chester Springs, PA' },
  { value: 'Chesterfield_MA', label: 'Chesterfield, MA' },
  { value: 'Chesterfield_NH', label: 'Chesterfield, NH' },
  { value: 'Chesterfield_NJ', label: 'Chesterfield, NJ' },
  { value: 'Chesterfield_VA', label: 'Chesterfield, VA' },
  { value: 'Chesterfield_SC', label: 'Chesterfield, SC' },
  { value: 'Chesterfield_IL', label: 'Chesterfield, IL' },
  { value: 'Chesterfield_MO', label: 'Chesterfield, MO' },
  { value: 'Chesterhill_OH', label: 'Chesterhill, OH' },
  { value: 'Chesterland_OH', label: 'Chesterland, OH' },
  { value: 'Chesterton_IN', label: 'Chesterton, IN' },
  { value: 'Chestertown_NY', label: 'Chestertown, NY' },
  { value: 'Chestertown_MD', label: 'Chestertown, MD' },
  { value: 'Chesterville_OH', label: 'Chesterville, OH' },
  { value: 'Chestnut_IL', label: 'Chestnut, IL' },
  { value: 'Chestnut Hill_MA', label: 'Chestnut Hill, MA' },
  { value: 'Chestnut Mound_TN', label: 'Chestnut Mound, TN' },
  { value: 'Chestnut Ridge_PA', label: 'Chestnut Ridge, PA' },
  { value: 'Chestnutridge_MO', label: 'Chestnutridge, MO' },
  { value: 'Cheswick_PA', label: 'Cheswick, PA' },
  { value: 'Cheswold_DE', label: 'Cheswold, DE' },
  { value: 'Chetek_WI', label: 'Chetek, WI' },
  { value: 'Chetopa_KS', label: 'Chetopa, KS' },
  { value: 'Chevak_AK', label: 'Chevak, AK' },
  { value: 'Chevy Chase_MD', label: 'Chevy Chase, MD' },
  { value: 'Chewelah_WA', label: 'Chewelah, WA' },
  { value: 'Cheyenne_OK', label: 'Cheyenne, OK' },
  { value: 'Cheyenne_WY', label: 'Cheyenne, WY' },
  { value: 'Cheyenne Wells_CO', label: 'Cheyenne Wells, CO' },
  { value: 'Cheyney_PA', label: 'Cheyney, PA' },
  { value: 'Chicago_IL', label: 'Chicago, IL' },
  { value: 'Chicago Heights_IL', label: 'Chicago Heights, IL' },
  { value: 'Chicago Ridge_IL', label: 'Chicago Ridge, IL' },
  { value: 'Chichester_NH', label: 'Chichester, NH' },
  { value: 'Chichester_NY', label: 'Chichester, NY' },
  { value: 'Chickamauga_GA', label: 'Chickamauga, GA' },
  { value: 'Chickasaw_OH', label: 'Chickasaw, OH' },
  { value: 'Chickasha_OK', label: 'Chickasha, OK' },
  { value: 'Chicken_AK', label: 'Chicken, AK' },
  { value: 'Chico_TX', label: 'Chico, TX' },
  { value: 'Chico_CA', label: 'Chico, CA' },
  { value: 'Chicopee_MA', label: 'Chicopee, MA' },
  { value: 'Chicora_PA', label: 'Chicora, PA' },
  { value: 'Chidester_AR', label: 'Chidester, AR' },
  { value: 'Chiefland_FL', label: 'Chiefland, FL' },
  { value: 'Chignik_AK', label: 'Chignik, AK' },
  { value: 'Chignik Lagoon_AK', label: 'Chignik Lagoon, AK' },
  { value: 'Chignik Lake_AK', label: 'Chignik Lake, AK' },
  { value: 'Chilcoot_CA', label: 'Chilcoot, CA' },
  { value: 'Childersburg_AL', label: 'Childersburg, AL' },
  { value: 'Childress_TX', label: 'Childress, TX' },
  { value: 'Childwold_NY', label: 'Childwold, NY' },
  { value: 'Chilhowee_MO', label: 'Chilhowee, MO' },
  { value: 'Chilhowie_VA', label: 'Chilhowie, VA' },
  { value: 'Chili_WI', label: 'Chili, WI' },
  { value: 'Chillicothe_OH', label: 'Chillicothe, OH' },
  { value: 'Chillicothe_IA', label: 'Chillicothe, IA' },
  { value: 'Chillicothe_IL', label: 'Chillicothe, IL' },
  { value: 'Chillicothe_MO', label: 'Chillicothe, MO' },
  { value: 'Chillicothe_TX', label: 'Chillicothe, TX' },
  { value: 'Chilmark_MA', label: 'Chilmark, MA' },
  { value: 'Chilo_OH', label: 'Chilo, OH' },
  { value: 'Chiloquin_OR', label: 'Chiloquin, OR' },
  { value: 'Chilton_WI', label: 'Chilton, WI' },
  { value: 'Chilton_TX', label: 'Chilton, TX' },
  { value: 'Chimacum_WA', label: 'Chimacum, WA' },
  { value: 'Chimayo_NM', label: 'Chimayo, NM' },
  { value: 'Chimney Rock_NC', label: 'Chimney Rock, NC' },
  { value: 'China_TX', label: 'China, TX' },
  { value: 'China Grove_NC', label: 'China Grove, NC' },
  { value: 'China Spring_TX', label: 'China Spring, TX' },
  { value: 'China Village_ME', label: 'China Village, ME' },
  { value: 'Chincoteague Island_VA', label: 'Chincoteague Island, VA' },
  { value: 'Chinese Camp_CA', label: 'Chinese Camp, CA' },
  { value: 'Chinle_AZ', label: 'Chinle, AZ' },
  { value: 'Chino_CA', label: 'Chino, CA' },
  { value: 'Chino Hills_CA', label: 'Chino Hills, CA' },
  { value: 'Chino Valley_AZ', label: 'Chino Valley, AZ' },
  { value: 'Chinook_MT', label: 'Chinook, MT' },
  { value: 'Chinook_WA', label: 'Chinook, WA' },
  { value: 'Chinquapin_NC', label: 'Chinquapin, NC' },
  { value: 'Chipley_FL', label: 'Chipley, FL' },
  { value: 'Chippewa Bay_NY', label: 'Chippewa Bay, NY' },
  { value: 'Chippewa Falls_WI', label: 'Chippewa Falls, WI' },
  { value: 'Chippewa Lake_OH', label: 'Chippewa Lake, OH' },
  { value: 'Chippewa Lake_MI', label: 'Chippewa Lake, MI' },
  { value: 'Chireno_TX', label: 'Chireno, TX' },
  { value: 'Chisago City_MN', label: 'Chisago City, MN' },
  { value: 'Chisholm_MN', label: 'Chisholm, MN' },
  { value: 'Chitina_AK', label: 'Chitina, AK' },
  { value: 'Chittenango_NY', label: 'Chittenango, NY' },
  { value: 'Chittenden_VT', label: 'Chittenden, VT' },
  { value: 'Chloe_WV', label: 'Chloe, WV' },
  { value: 'Chloride_AZ', label: 'Chloride, AZ' },
  { value: 'Chocorua_NH', label: 'Chocorua, NH' },
  { value: 'Chocowinity_NC', label: 'Chocowinity, NC' },
  { value: 'Choctaw_AR', label: 'Choctaw, AR' },
  { value: 'Choctaw_OK', label: 'Choctaw, OK' },
  { value: 'Chokio_MN', label: 'Chokio, MN' },
  { value: 'Chokoloskee_FL', label: 'Chokoloskee, FL' },
  { value: 'Choteau_MT', label: 'Choteau, MT' },
  { value: 'Choudrant_LA', label: 'Choudrant, LA' },
  { value: 'Chouteau_OK', label: 'Chouteau, OK' },
  { value: 'Chowchilla_CA', label: 'Chowchilla, CA' },
  { value: 'Chrisman_IL', label: 'Chrisman, IL' },
  { value: 'Chrisney_IN', label: 'Chrisney, IN' },
  { value: 'Christiana_PA', label: 'Christiana, PA' },
  { value: 'Christiana_TN', label: 'Christiana, TN' },
  { value: 'Christiansburg_VA', label: 'Christiansburg, VA' },
  { value: 'Christiansburg_OH', label: 'Christiansburg, OH' },
  { value: 'Christiansted_VI', label: 'Christiansted, VI' },
  { value: 'Christine_ND', label: 'Christine, ND' },
  { value: 'Christine_TX', label: 'Christine, TX' },
  { value: 'Christmas_FL', label: 'Christmas, FL' },
  { value: 'Christmas Valley_OR', label: 'Christmas Valley, OR' },
  { value: 'Christopher_IL', label: 'Christopher, IL' },
  { value: 'Christoval_TX', label: 'Christoval, TX' },
  { value: 'Chromo_CO', label: 'Chromo, CO' },
  { value: 'Chualar_CA', label: 'Chualar, CA' },
  { value: 'Chuckey_TN', label: 'Chuckey, TN' },
  { value: 'Chugiak_AK', label: 'Chugiak, AK' },
  { value: 'Chugwater_WY', label: 'Chugwater, WY' },
  { value: 'Chula_GA', label: 'Chula, GA' },
  { value: 'Chula_MO', label: 'Chula, MO' },
  { value: 'Chula Vista_CA', label: 'Chula Vista, CA' },
  { value: 'Chunchula_AL', label: 'Chunchula, AL' },
  { value: 'Chunky_MS', label: 'Chunky, MS' },
  { value: 'Church Creek_MD', label: 'Church Creek, MD' },
  { value: 'Church Hill_MD', label: 'Church Hill, MD' },
  { value: 'Church Hill_TN', label: 'Church Hill, TN' },
  { value: 'Church Point_LA', label: 'Church Point, LA' },
  { value: 'Church Road_VA', label: 'Church Road, VA' },
  { value: 'Church Rock_NM', label: 'Church Rock, NM' },
  { value: 'Church View_VA', label: 'Church View, VA' },
  { value: 'Churchs Ferry_ND', label: 'Churchs Ferry, ND' },
  { value: 'Churchton_MD', label: 'Churchton, MD' },
  { value: 'Churchville_NY', label: 'Churchville, NY' },
  { value: 'Churchville_MD', label: 'Churchville, MD' },
  { value: 'Churchville_VA', label: 'Churchville, VA' },
  { value: 'Churdan_IA', label: 'Churdan, IA' },
  { value: 'Churubusco_NY', label: 'Churubusco, NY' },
  { value: 'Churubusco_IN', label: 'Churubusco, IN' },
  { value: 'Ciales_PR', label: 'Ciales, PR' },
  { value: 'Cibecue_AZ', label: 'Cibecue, AZ' },
  { value: 'Cibola_AZ', label: 'Cibola, AZ' },
  { value: 'Cibolo_TX', label: 'Cibolo, TX' },
  { value: 'Cicero_NY', label: 'Cicero, NY' },
  { value: 'Cicero_IN', label: 'Cicero, IN' },
  { value: 'Cicero_IL', label: 'Cicero, IL' },
  { value: 'Cidra_PR', label: 'Cidra, PR' },
  { value: 'Cimarron_KS', label: 'Cimarron, KS' },
  { value: 'Cimarron_CO', label: 'Cimarron, CO' },
  { value: 'Cimarron_NM', label: 'Cimarron, NM' },
  { value: 'Cincinnati_OH', label: 'Cincinnati, OH' },
  { value: 'Cincinnati_IA', label: 'Cincinnati, IA' },
  { value: 'Cincinnatus_NY', label: 'Cincinnatus, NY' },
  { value: 'Cinebar_WA', label: 'Cinebar, WA' },
  { value: 'Circle_MT', label: 'Circle, MT' },
  { value: 'Circle_AK', label: 'Circle, AK' },
  { value: 'Circle Pines_MN', label: 'Circle Pines, MN' },
  { value: 'Circleville_NY', label: 'Circleville, NY' },
  { value: 'Circleville_WV', label: 'Circleville, WV' },
  { value: 'Circleville_OH', label: 'Circleville, OH' },
  { value: 'Circleville_KS', label: 'Circleville, KS' },
  { value: 'Circleville_UT', label: 'Circleville, UT' },
  { value: 'Cisco_GA', label: 'Cisco, GA' },
  { value: 'Cisco_IL', label: 'Cisco, IL' },
  { value: 'Cisco_TX', label: 'Cisco, TX' },
  { value: 'Cisco_UT', label: 'Cisco, UT' },
  { value: 'Cisne_IL', label: 'Cisne, IL' },
  { value: 'Cissna Park_IL', label: 'Cissna Park, IL' },
  { value: 'Citra_FL', label: 'Citra, FL' },
  { value: 'Citronelle_AL', label: 'Citronelle, AL' },
  { value: 'Citrus Heights_CA', label: 'Citrus Heights, CA' },
  { value: 'Clackamas_OR', label: 'Clackamas, OR' },
  { value: 'Claflin_KS', label: 'Claflin, KS' },
  { value: 'Claiborne_MD', label: 'Claiborne, MD' },
  { value: 'Claire City_SD', label: 'Claire City, SD' },
  { value: 'Clairfield_TN', label: 'Clairfield, TN' },
  { value: 'Clairton_PA', label: 'Clairton, PA' },
  { value: 'Clallam Bay_WA', label: 'Clallam Bay, WA' },
  { value: 'Clam Gulch_AK', label: 'Clam Gulch, AK' },
  { value: 'Clam Lake_WI', label: 'Clam Lake, WI' },
  { value: 'Clancy_MT', label: 'Clancy, MT' },
  { value: 'Clanton_AL', label: 'Clanton, AL' },
  { value: 'Clara_MS', label: 'Clara, MS' },
  { value: 'Clara City_MN', label: 'Clara City, MN' },
  { value: 'Clare_MI', label: 'Clare, MI' },
  { value: 'Clare_IA', label: 'Clare, IA' },
  { value: 'Clare_IL', label: 'Clare, IL' },
  { value: 'Claremont_NH', label: 'Claremont, NH' },
  { value: 'Claremont_VA', label: 'Claremont, VA' },
  { value: 'Claremont_NC', label: 'Claremont, NC' },
  { value: 'Claremont_MN', label: 'Claremont, MN' },
  { value: 'Claremont_SD', label: 'Claremont, SD' },
  { value: 'Claremont_IL', label: 'Claremont, IL' },
  { value: 'Claremont_CA', label: 'Claremont, CA' },
  { value: 'Claremore_OK', label: 'Claremore, OK' },
  { value: 'Clarence_NY', label: 'Clarence, NY' },
  { value: 'Clarence_PA', label: 'Clarence, PA' },
  { value: 'Clarence_IA', label: 'Clarence, IA' },
  { value: 'Clarence_MO', label: 'Clarence, MO' },
  { value: 'Clarence_LA', label: 'Clarence, LA' },
  { value: 'Clarence Center_NY', label: 'Clarence Center, NY' },
  { value: 'Clarendon_PA', label: 'Clarendon, PA' },
  { value: 'Clarendon_NC', label: 'Clarendon, NC' },
  { value: 'Clarendon_AR', label: 'Clarendon, AR' },
  { value: 'Clarendon_TX', label: 'Clarendon, TX' },
  { value: 'Clarendon Hills_IL', label: 'Clarendon Hills, IL' },
  { value: 'Claridge_PA', label: 'Claridge, PA' },
  { value: 'Clarinda_IA', label: 'Clarinda, IA' },
  { value: 'Clarington_PA', label: 'Clarington, PA' },
  { value: 'Clarington_OH', label: 'Clarington, OH' },
  { value: 'Clarion_PA', label: 'Clarion, PA' },
  { value: 'Clarion_IA', label: 'Clarion, IA' },
  { value: 'Clarissa_MN', label: 'Clarissa, MN' },
  { value: 'Clarita_OK', label: 'Clarita, OK' },
  { value: 'Clark_NJ', label: 'Clark, NJ' },
  { value: 'Clark_PA', label: 'Clark, PA' },
  { value: 'Clark_SD', label: 'Clark, SD' },
  { value: 'Clark_MO', label: 'Clark, MO' },
  { value: 'Clark_CO', label: 'Clark, CO' },
  { value: 'Clark Fork_ID', label: 'Clark Fork, ID' },
  { value: 'Clark Mills_NY', label: 'Clark Mills, NY' },
  { value: 'Clarkdale_GA', label: 'Clarkdale, GA' },
  { value: 'Clarkdale_AZ', label: 'Clarkdale, AZ' },
  { value: 'Clarkedale_AR', label: 'Clarkedale, AR' },
  { value: 'Clarkesville_GA', label: 'Clarkesville, GA' },
  { value: 'Clarkfield_MN', label: 'Clarkfield, MN' },
  { value: 'Clarkia_ID', label: 'Clarkia, ID' },
  { value: 'Clarklake_MI', label: 'Clarklake, MI' },
  { value: 'Clarkrange_TN', label: 'Clarkrange, TN' },
  { value: 'Clarkridge_AR', label: 'Clarkridge, AR' },
  { value: 'Clarks_NE', label: 'Clarks, NE' },
  { value: 'Clarks_LA', label: 'Clarks, LA' },
  { value: 'Clarks Grove_MN', label: 'Clarks Grove, MN' },
  { value: 'Clarks Hill_SC', label: 'Clarks Hill, SC' },
  { value: 'Clarks Hill_IN', label: 'Clarks Hill, IN' },
  { value: 'Clarks Mills_PA', label: 'Clarks Mills, PA' },
  { value: 'Clarks Point_AK', label: 'Clarks Point, AK' },
  { value: 'Clarks Summit_PA', label: 'Clarks Summit, PA' },
  { value: 'Clarksboro_NJ', label: 'Clarksboro, NJ' },
  { value: 'Clarksburg_PA', label: 'Clarksburg, PA' },
  { value: 'Clarksburg_MD', label: 'Clarksburg, MD' },
  { value: 'Clarksburg_WV', label: 'Clarksburg, WV' },
  { value: 'Clarksburg_OH', label: 'Clarksburg, OH' },
  { value: 'Clarksburg_IN', label: 'Clarksburg, IN' },
  { value: 'Clarksburg_MO', label: 'Clarksburg, MO' },
  { value: 'Clarksburg_CA', label: 'Clarksburg, CA' },
  { value: 'Clarksdale_MS', label: 'Clarksdale, MS' },
  { value: 'Clarksdale_MO', label: 'Clarksdale, MO' },
  { value: 'Clarkson_KY', label: 'Clarkson, KY' },
  { value: 'Clarkson_NE', label: 'Clarkson, NE' },
  { value: 'Clarkston_GA', label: 'Clarkston, GA' },
  { value: 'Clarkston_MI', label: 'Clarkston, MI' },
  { value: 'Clarkston_UT', label: 'Clarkston, UT' },
  { value: 'Clarkston_WA', label: 'Clarkston, WA' },
  { value: 'Clarksville_NY', label: 'Clarksville, NY' },
  { value: 'Clarksville_PA', label: 'Clarksville, PA' },
  { value: 'Clarksville_MD', label: 'Clarksville, MD' },
  { value: 'Clarksville_VA', label: 'Clarksville, VA' },
  { value: 'Clarksville_FL', label: 'Clarksville, FL' },
  { value: 'Clarksville_TN', label: 'Clarksville, TN' },
  { value: 'Clarksville_OH', label: 'Clarksville, OH' },
  { value: 'Clarksville_IN', label: 'Clarksville, IN' },
  { value: 'Clarksville_MI', label: 'Clarksville, MI' },
  { value: 'Clarksville_IA', label: 'Clarksville, IA' },
  { value: 'Clarksville_MO', label: 'Clarksville, MO' },
  { value: 'Clarksville_AR', label: 'Clarksville, AR' },
  { value: 'Clarksville_TX', label: 'Clarksville, TX' },
  { value: 'Clarkton_NC', label: 'Clarkton, NC' },
  { value: 'Clarkton_MO', label: 'Clarkton, MO' },
  { value: 'Claryville_NY', label: 'Claryville, NY' },
  { value: 'Clatonia_NE', label: 'Clatonia, NE' },
  { value: 'Clatskanie_OR', label: 'Clatskanie, OR' },
  { value: 'Claude_TX', label: 'Claude, TX' },
  { value: 'Claudville_VA', label: 'Claudville, VA' },
  { value: 'Claunch_NM', label: 'Claunch, NM' },
  { value: 'Claverack_NY', label: 'Claverack, NY' },
  { value: 'Clawson_MI', label: 'Clawson, MI' },
  { value: 'Clawson_UT', label: 'Clawson, UT' },
  { value: 'Claxton_GA', label: 'Claxton, GA' },
  { value: 'Clay_NY', label: 'Clay, NY' },
  { value: 'Clay_WV', label: 'Clay, WV' },
  { value: 'Clay_KY', label: 'Clay, KY' },
  { value: 'Clay Center_OH', label: 'Clay Center, OH' },
  { value: 'Clay Center_KS', label: 'Clay Center, KS' },
  { value: 'Clay Center_NE', label: 'Clay Center, NE' },
  { value: 'Clay City_KY', label: 'Clay City, KY' },
  { value: 'Clay City_IN', label: 'Clay City, IN' },
  { value: 'Clay City_IL', label: 'Clay City, IL' },
  { value: 'Clay Springs_AZ', label: 'Clay Springs, AZ' },
  { value: 'Clayhole_KY', label: 'Clayhole, KY' },
  { value: 'Claymont_DE', label: 'Claymont, DE' },
  { value: 'Claypool_IN', label: 'Claypool, IN' },
  { value: 'Claypool_AZ', label: 'Claypool, AZ' },
  { value: 'Claysburg_PA', label: 'Claysburg, PA' },
  { value: 'Claysville_PA', label: 'Claysville, PA' },
  { value: 'Clayton_NJ', label: 'Clayton, NJ' },
  { value: 'Clayton_NY', label: 'Clayton, NY' },
  { value: 'Clayton_DE', label: 'Clayton, DE' },
  { value: 'Clayton_NC', label: 'Clayton, NC' },
  { value: 'Clayton_GA', label: 'Clayton, GA' },
  { value: 'Clayton_AL', label: 'Clayton, AL' },
  { value: 'Clayton_OH', label: 'Clayton, OH' },
  { value: 'Clayton_IN', label: 'Clayton, IN' },
  { value: 'Clayton_MI', label: 'Clayton, MI' },
  { value: 'Clayton_WI', label: 'Clayton, WI' },
  { value: 'Clayton_IL', label: 'Clayton, IL' },
  { value: 'Clayton_KS', label: 'Clayton, KS' },
  { value: 'Clayton_LA', label: 'Clayton, LA' },
  { value: 'Clayton_OK', label: 'Clayton, OK' },
  { value: 'Clayton_ID', label: 'Clayton, ID' },
  { value: 'Clayton_NM', label: 'Clayton, NM' },
  { value: 'Clayton_CA', label: 'Clayton, CA' },
  { value: 'Clayton_WA', label: 'Clayton, WA' },
  { value: 'Clayton Lake_ME', label: 'Clayton Lake, ME' },
  { value: 'Claytonville_IL', label: 'Claytonville, IL' },
  { value: 'Clayville_RI', label: 'Clayville, RI' },
  { value: 'Clayville_NY', label: 'Clayville, NY' },
  { value: 'Cle Elum_WA', label: 'Cle Elum, WA' },
  { value: 'Clear_AK', label: 'Clear, AK' },
  { value: 'Clear Brook_VA', label: 'Clear Brook, VA' },
  { value: 'Clear Creek_WV', label: 'Clear Creek, WV' },
  { value: 'Clear Fork_WV', label: 'Clear Fork, WV' },
  { value: 'Clear Lake_IA', label: 'Clear Lake, IA' },
  { value: 'Clear Lake_WI', label: 'Clear Lake, WI' },
  { value: 'Clear Lake_MN', label: 'Clear Lake, MN' },
  { value: 'Clear Lake_SD', label: 'Clear Lake, SD' },
  { value: 'Clear Spring_MD', label: 'Clear Spring, MD' },
  { value: 'Clearbrook_MN', label: 'Clearbrook, MN' },
  { value: 'Clearfield_PA', label: 'Clearfield, PA' },
  { value: 'Clearfield_KY', label: 'Clearfield, KY' },
  { value: 'Clearfield_IA', label: 'Clearfield, IA' },
  { value: 'Clearfield_UT', label: 'Clearfield, UT' },
  { value: 'Clearlake_CA', label: 'Clearlake, CA' },
  { value: 'Clearlake_WA', label: 'Clearlake, WA' },
  { value: 'Clearlake Oaks_CA', label: 'Clearlake Oaks, CA' },
  { value: 'Clearmont_MO', label: 'Clearmont, MO' },
  { value: 'Clearmont_WY', label: 'Clearmont, WY' },
  { value: 'Clearville_PA', label: 'Clearville, PA' },
  { value: 'Clearwater_FL', label: 'Clearwater, FL' },
  { value: 'Clearwater_MN', label: 'Clearwater, MN' },
  { value: 'Clearwater_KS', label: 'Clearwater, KS' },
  { value: 'Clearwater_NE', label: 'Clearwater, NE' },
  { value: 'Clearwater Beach_FL', label: 'Clearwater Beach, FL' },
  { value: 'Cleaton_KY', label: 'Cleaton, KY' },
  { value: 'Cleburne_TX', label: 'Cleburne, TX' },
  { value: 'Cleghorn_IA', label: 'Cleghorn, IA' },
  { value: 'Clementon_NJ', label: 'Clementon, NJ' },
  { value: 'Clements_MD', label: 'Clements, MD' },
  { value: 'Clements_MN', label: 'Clements, MN' },
  { value: 'Clements_CA', label: 'Clements, CA' },
  { value: 'Clemmons_NC', label: 'Clemmons, NC' },
  { value: 'Clemons_NY', label: 'Clemons, NY' },
  { value: 'Clemons_IA', label: 'Clemons, IA' },
  { value: 'Clemson_SC', label: 'Clemson, SC' },
  { value: 'Clendenin_WV', label: 'Clendenin, WV' },
  { value: 'Cleo Springs_OK', label: 'Cleo Springs, OK' },
  { value: 'Clermont_GA', label: 'Clermont, GA' },
  { value: 'Clermont_FL', label: 'Clermont, FL' },
  { value: 'Clermont_KY', label: 'Clermont, KY' },
  { value: 'Clermont_IA', label: 'Clermont, IA' },
  { value: 'Cleveland_NY', label: 'Cleveland, NY' },
  { value: 'Cleveland_VA', label: 'Cleveland, VA' },
  { value: 'Cleveland_WV', label: 'Cleveland, WV' },
  { value: 'Cleveland_NC', label: 'Cleveland, NC' },
  { value: 'Cleveland_SC', label: 'Cleveland, SC' },
  { value: 'Cleveland_GA', label: 'Cleveland, GA' },
  { value: 'Cleveland_AL', label: 'Cleveland, AL' },
  { value: 'Cleveland_TN', label: 'Cleveland, TN' },
  { value: 'Cleveland_MS', label: 'Cleveland, MS' },
  { value: 'Cleveland_OH', label: 'Cleveland, OH' },
  { value: 'Cleveland_WI', label: 'Cleveland, WI' },
  { value: 'Cleveland_MN', label: 'Cleveland, MN' },
  { value: 'Cleveland_ND', label: 'Cleveland, ND' },
  { value: 'Cleveland_MO', label: 'Cleveland, MO' },
  { value: 'Cleveland_AR', label: 'Cleveland, AR' },
  { value: 'Cleveland_OK', label: 'Cleveland, OK' },
  { value: 'Cleveland_TX', label: 'Cleveland, TX' },
  { value: 'Cleveland_UT', label: 'Cleveland, UT' },
  { value: 'Cleveland_NM', label: 'Cleveland, NM' },
  { value: 'Clever_MO', label: 'Clever, MO' },
  { value: 'Cleverdale_NY', label: 'Cleverdale, NY' },
  { value: 'Cleves_OH', label: 'Cleves, OH' },
  { value: 'Clewiston_FL', label: 'Clewiston, FL' },
  { value: 'Cliff_NM', label: 'Cliff, NM' },
  { value: 'Cliff Island_ME', label: 'Cliff Island, ME' },
  { value: 'Clifford_PA', label: 'Clifford, PA' },
  { value: 'Clifford_IN', label: 'Clifford, IN' },
  { value: 'Clifford_MI', label: 'Clifford, MI' },
  { value: 'Clifford_ND', label: 'Clifford, ND' },
  { value: 'Cliffside_NC', label: 'Cliffside, NC' },
  { value: 'Cliffside Park_NJ', label: 'Cliffside Park, NJ' },
  { value: 'Cliffwood_NJ', label: 'Cliffwood, NJ' },
  { value: 'Clifton_NJ', label: 'Clifton, NJ' },
  { value: 'Clifton_VA', label: 'Clifton, VA' },
  { value: 'Clifton_SC', label: 'Clifton, SC' },
  { value: 'Clifton_TN', label: 'Clifton, TN' },
  { value: 'Clifton_OH', label: 'Clifton, OH' },
  { value: 'Clifton_IL', label: 'Clifton, IL' },
  { value: 'Clifton_KS', label: 'Clifton, KS' },
  { value: 'Clifton_TX', label: 'Clifton, TX' },
  { value: 'Clifton_CO', label: 'Clifton, CO' },
  { value: 'Clifton_ID', label: 'Clifton, ID' },
  { value: 'Clifton_AZ', label: 'Clifton, AZ' },
  { value: 'Clifton Forge_VA', label: 'Clifton Forge, VA' },
  { value: 'Clifton Heights_PA', label: 'Clifton Heights, PA' },
  { value: 'Clifton Hill_MO', label: 'Clifton Hill, MO' },
  { value: 'Clifton Park_NY', label: 'Clifton Park, NY' },
  { value: 'Clifton Springs_NY', label: 'Clifton Springs, NY' },
  { value: 'Climax_NY', label: 'Climax, NY' },
  { value: 'Climax_NC', label: 'Climax, NC' },
  { value: 'Climax_GA', label: 'Climax, GA' },
  { value: 'Climax_MI', label: 'Climax, MI' },
  { value: 'Climax_MN', label: 'Climax, MN' },
  { value: 'Climax Springs_MO', label: 'Climax Springs, MO' },
  { value: 'Climbing Hill_IA', label: 'Climbing Hill, IA' },
  { value: 'Clinchco_VA', label: 'Clinchco, VA' },
  { value: 'Clines Corners_NM', label: 'Clines Corners, NM' },
  { value: 'Clint_TX', label: 'Clint, TX' },
  { value: 'Clinton_MA', label: 'Clinton, MA' },
  { value: 'Clinton_ME', label: 'Clinton, ME' },
  { value: 'Clinton_CT', label: 'Clinton, CT' },
  { value: 'Clinton_NJ', label: 'Clinton, NJ' },
  { value: 'Clinton_NY', label: 'Clinton, NY' },
  { value: 'Clinton_PA', label: 'Clinton, PA' },
  { value: 'Clinton_MD', label: 'Clinton, MD' },
  { value: 'Clinton_NC', label: 'Clinton, NC' },
  { value: 'Clinton_SC', label: 'Clinton, SC' },
  { value: 'Clinton_TN', label: 'Clinton, TN' },
  { value: 'Clinton_MS', label: 'Clinton, MS' },
  { value: 'Clinton_KY', label: 'Clinton, KY' },
  { value: 'Clinton_OH', label: 'Clinton, OH' },
  { value: 'Clinton_IN', label: 'Clinton, IN' },
  { value: 'Clinton_MI', label: 'Clinton, MI' },
  { value: 'Clinton_IA', label: 'Clinton, IA' },
  { value: 'Clinton_WI', label: 'Clinton, WI' },
  { value: 'Clinton_MN', label: 'Clinton, MN' },
  { value: 'Clinton_MT', label: 'Clinton, MT' },
  { value: 'Clinton_IL', label: 'Clinton, IL' },
  { value: 'Clinton_MO', label: 'Clinton, MO' },
  { value: 'Clinton_LA', label: 'Clinton, LA' },
  { value: 'Clinton_AR', label: 'Clinton, AR' },
  { value: 'Clinton_OK', label: 'Clinton, OK' },
  { value: 'Clinton_WA', label: 'Clinton, WA' },
  { value: 'Clinton Corners_NY', label: 'Clinton Corners, NY' },
  { value: 'Clinton Township_MI', label: 'Clinton Township, MI' },
  { value: 'Clintondale_NY', label: 'Clintondale, NY' },
  { value: 'Clintonville_PA', label: 'Clintonville, PA' },
  { value: 'Clintonville_WI', label: 'Clintonville, WI' },
  { value: 'Clintwood_VA', label: 'Clintwood, VA' },
  { value: 'Clio_SC', label: 'Clio, SC' },
  { value: 'Clio_AL', label: 'Clio, AL' },
  { value: 'Clio_MI', label: 'Clio, MI' },
  { value: 'Clio_IA', label: 'Clio, IA' },
  { value: 'Clio_CA', label: 'Clio, CA' },
  { value: 'Clipper Mills_CA', label: 'Clipper Mills, CA' },
  { value: 'Clitherall_MN', label: 'Clitherall, MN' },
  { value: 'Clive_IA', label: 'Clive, IA' },
  { value: 'Clontarf_MN', label: 'Clontarf, MN' },
  { value: 'Clopton_AL', label: 'Clopton, AL' },
  { value: 'Cloquet_MN', label: 'Cloquet, MN' },
  { value: 'Closplint_KY', label: 'Closplint, KY' },
  { value: 'Closter_NJ', label: 'Closter, NJ' },
  { value: 'Clothier_WV', label: 'Clothier, WV' },
  { value: 'Cloudcroft_NM', label: 'Cloudcroft, NM' },
  { value: 'Cloutierville_LA', label: 'Cloutierville, LA' },
  { value: 'Clover_VA', label: 'Clover, VA' },
  { value: 'Clover_SC', label: 'Clover, SC' },
  { value: 'Cloverdale_VA', label: 'Cloverdale, VA' },
  { value: 'Cloverdale_OH', label: 'Cloverdale, OH' },
  { value: 'Cloverdale_IN', label: 'Cloverdale, IN' },
  { value: 'Cloverdale_CA', label: 'Cloverdale, CA' },
  { value: 'Cloverdale_OR', label: 'Cloverdale, OR' },
  { value: 'Cloverport_KY', label: 'Cloverport, KY' },
  { value: 'Clovis_NM', label: 'Clovis, NM' },
  { value: 'Clovis_CA', label: 'Clovis, CA' },
  { value: 'Clubb_MO', label: 'Clubb, MO' },
  { value: 'Clune_PA', label: 'Clune, PA' },
  { value: 'Clute_TX', label: 'Clute, TX' },
  { value: 'Clutier_IA', label: 'Clutier, IA' },
  { value: 'Clyde_NY', label: 'Clyde, NY' },
  { value: 'Clyde_NC', label: 'Clyde, NC' },
  { value: 'Clyde_OH', label: 'Clyde, OH' },
  { value: 'Clyde_MO', label: 'Clyde, MO' },
  { value: 'Clyde_KS', label: 'Clyde, KS' },
  { value: 'Clyde_TX', label: 'Clyde, TX' },
  { value: 'Clyde Park_MT', label: 'Clyde Park, MT' },
  { value: 'Clyman_WI', label: 'Clyman, WI' },
  { value: 'Clymer_NY', label: 'Clymer, NY' },
  { value: 'Clymer_PA', label: 'Clymer, PA' },
  { value: 'Clyo_GA', label: 'Clyo, GA' },
  { value: 'Coachella_CA', label: 'Coachella, CA' },
  { value: 'Coahoma_MS', label: 'Coahoma, MS' },
  { value: 'Coahoma_TX', label: 'Coahoma, TX' },
  { value: 'Coal Center_PA', label: 'Coal Center, PA' },
  { value: 'Coal City_WV', label: 'Coal City, WV' },
  { value: 'Coal City_IN', label: 'Coal City, IN' },
  { value: 'Coal City_IL', label: 'Coal City, IL' },
  { value: 'Coal Creek_CO', label: 'Coal Creek, CO' },
  { value: 'Coal Hill_AR', label: 'Coal Hill, AR' },
  { value: 'Coal Mountain_WV', label: 'Coal Mountain, WV' },
  { value: 'Coal Run_OH', label: 'Coal Run, OH' },
  { value: 'Coal Township_PA', label: 'Coal Township, PA' },
  { value: 'Coal Valley_IL', label: 'Coal Valley, IL' },
  { value: 'Coaldale_PA', label: 'Coaldale, PA' },
  { value: 'Coaldale_CO', label: 'Coaldale, CO' },
  { value: 'Coalfield_TN', label: 'Coalfield, TN' },
  { value: 'Coalgate_OK', label: 'Coalgate, OK' },
  { value: 'Coalgood_KY', label: 'Coalgood, KY' },
  { value: 'Coalinga_CA', label: 'Coalinga, CA' },
  { value: 'Coalmont_TN', label: 'Coalmont, TN' },
  { value: 'Coalmont_IN', label: 'Coalmont, IN' },
  { value: 'Coalmont_CO', label: 'Coalmont, CO' },
  { value: 'Coalport_PA', label: 'Coalport, PA' },
  { value: 'Coalton_WV', label: 'Coalton, WV' },
  { value: 'Coalton_OH', label: 'Coalton, OH' },
  { value: 'Coalville_UT', label: 'Coalville, UT' },
  { value: 'Coamo_PR', label: 'Coamo, PR' },
  { value: 'Coarsegold_CA', label: 'Coarsegold, CA' },
  { value: 'Coatesville_PA', label: 'Coatesville, PA' },
  { value: 'Coatesville_IN', label: 'Coatesville, IN' },
  { value: 'Coats_NC', label: 'Coats, NC' },
  { value: 'Coats_KS', label: 'Coats, KS' },
  { value: 'Coatsburg_IL', label: 'Coatsburg, IL' },
  { value: 'Coatsville_MO', label: 'Coatsville, MO' },
  { value: 'Cobalt_CT', label: 'Cobalt, CT' },
  { value: 'Cobb_GA', label: 'Cobb, GA' },
  { value: 'Cobb_WI', label: 'Cobb, WI' },
  { value: 'Cobb_CA', label: 'Cobb, CA' },
  { value: 'Cobb Island_MD', label: 'Cobb Island, MD' },
  { value: 'Cobbs Creek_VA', label: 'Cobbs Creek, VA' },
  { value: 'Cobbtown_GA', label: 'Cobbtown, GA' },
  { value: 'Cobden_IL', label: 'Cobden, IL' },
  { value: 'Cobleskill_NY', label: 'Cobleskill, NY' },
  { value: 'Coburn_PA', label: 'Coburn, PA' },
  { value: 'Cochecton_NY', label: 'Cochecton, NY' },
  { value: 'Cochise_AZ', label: 'Cochise, AZ' },
  { value: 'Cochiti Lake_NM', label: 'Cochiti Lake, NM' },
  { value: 'Cochiti Pueblo_NM', label: 'Cochiti Pueblo, NM' },
  { value: 'Cochran_GA', label: 'Cochran, GA' },
  { value: 'Cochrane_WI', label: 'Cochrane, WI' },
  { value: 'Cochranton_PA', label: 'Cochranton, PA' },
  { value: 'Cochranville_PA', label: 'Cochranville, PA' },
  { value: 'Cockeysville_MD', label: 'Cockeysville, MD' },
  { value: 'Cocoa_FL', label: 'Cocoa, FL' },
  { value: 'Cocoa Beach_FL', label: 'Cocoa Beach, FL' },
  { value: 'Cocolalla_ID', label: 'Cocolalla, ID' },
  { value: 'Cocolamus_PA', label: 'Cocolamus, PA' },
  { value: 'Coden_AL', label: 'Coden, AL' },
  { value: 'Codorus_PA', label: 'Codorus, PA' },
  { value: 'Cody_NE', label: 'Cody, NE' },
  { value: 'Cody_WY', label: 'Cody, WY' },
  { value: 'Coeburn_VA', label: 'Coeburn, VA' },
  { value: 'Coello_IL', label: 'Coello, IL' },
  { value: 'Coeur D Alene_ID', label: 'Coeur D Alene, ID' },
  { value: 'Coeymans_NY', label: 'Coeymans, NY' },
  { value: 'Coeymans Hollow_NY', label: 'Coeymans Hollow, NY' },
  { value: 'Coffee Creek_MT', label: 'Coffee Creek, MT' },
  { value: 'Coffee Springs_AL', label: 'Coffee Springs, AL' },
  { value: 'Coffeen_IL', label: 'Coffeen, IL' },
  { value: 'Coffeeville_AL', label: 'Coffeeville, AL' },
  { value: 'Coffeeville_MS', label: 'Coffeeville, MS' },
  { value: 'Coffey_MO', label: 'Coffey, MO' },
  { value: 'Coffeyville_KS', label: 'Coffeyville, KS' },
  { value: 'Coffman Cove_AK', label: 'Coffman Cove, AK' },
  { value: 'Cofield_NC', label: 'Cofield, NC' },
  { value: 'Cogan Station_PA', label: 'Cogan Station, PA' },
  { value: 'Coggon_IA', label: 'Coggon, IA' },
  { value: 'Cogswell_ND', label: 'Cogswell, ND' },
  { value: 'Cohagen_MT', label: 'Cohagen, MT' },
  { value: 'Cohasset_MA', label: 'Cohasset, MA' },
  { value: 'Cohasset_MN', label: 'Cohasset, MN' },
  { value: 'Cohoctah_MI', label: 'Cohoctah, MI' },
  { value: 'Cohocton_NY', label: 'Cohocton, NY' },
  { value: 'Cohoes_NY', label: 'Cohoes, NY' },
  { value: 'Cohutta_GA', label: 'Cohutta, GA' },
  { value: 'Coila_MS', label: 'Coila, MS' },
  { value: 'Coin_IA', label: 'Coin, IA' },
  { value: 'Coinjock_NC', label: 'Coinjock, NC' },
  { value: 'Cokato_MN', label: 'Cokato, MN' },
  { value: 'Cokeburg_PA', label: 'Cokeburg, PA' },
  { value: 'Coker_AL', label: 'Coker, AL' },
  { value: 'Cokeville_WY', label: 'Cokeville, WY' },
  { value: 'Colbert_GA', label: 'Colbert, GA' },
  { value: 'Colbert_OK', label: 'Colbert, OK' },
  { value: 'Colbert_WA', label: 'Colbert, WA' },
  { value: 'Colby_WI', label: 'Colby, WI' },
  { value: 'Colby_KS', label: 'Colby, KS' },
  { value: 'Colchester_VT', label: 'Colchester, VT' },
  { value: 'Colchester_CT', label: 'Colchester, CT' },
  { value: 'Colchester_IL', label: 'Colchester, IL' },
  { value: 'Colcord_WV', label: 'Colcord, WV' },
  { value: 'Colcord_OK', label: 'Colcord, OK' },
  { value: 'Cold Bay_AK', label: 'Cold Bay, AK' },
  { value: 'Cold Brook_NY', label: 'Cold Brook, NY' },
  { value: 'Cold Spring_NY', label: 'Cold Spring, NY' },
  { value: 'Cold Spring_MN', label: 'Cold Spring, MN' },
  { value: 'Cold Spring Harbor_NY', label: 'Cold Spring Harbor, NY' },
  { value: 'Colden_NY', label: 'Colden, NY' },
  { value: 'Coldiron_KY', label: 'Coldiron, KY' },
  { value: 'Coldspring_TX', label: 'Coldspring, TX' },
  { value: 'Coldwater_MS', label: 'Coldwater, MS' },
  { value: 'Coldwater_OH', label: 'Coldwater, OH' },
  { value: 'Coldwater_MI', label: 'Coldwater, MI' },
  { value: 'Coldwater_KS', label: 'Coldwater, KS' },
  { value: 'Cole Camp_MO', label: 'Cole Camp, MO' },
  { value: 'Colebrook_NH', label: 'Colebrook, NH' },
  { value: 'Colebrook_CT', label: 'Colebrook, CT' },
  { value: 'Coleharbor_ND', label: 'Coleharbor, ND' },
  { value: 'Coleman_FL', label: 'Coleman, FL' },
  { value: 'Coleman_GA', label: 'Coleman, GA' },
  { value: 'Coleman_MI', label: 'Coleman, MI' },
  { value: 'Coleman_WI', label: 'Coleman, WI' },
  { value: 'Coleman_OK', label: 'Coleman, OK' },
  { value: 'Coleman_TX', label: 'Coleman, TX' },
  { value: 'Coleman Falls_VA', label: 'Coleman Falls, VA' },
  { value: 'Colerain_NC', label: 'Colerain, NC' },
  { value: 'Coleraine_MN', label: 'Coleraine, MN' },
  { value: 'Coleridge_NE', label: 'Coleridge, NE' },
  { value: 'Colesburg_IA', label: 'Colesburg, IA' },
  { value: 'Coleville_CA', label: 'Coleville, CA' },
  { value: 'Colfax_NC', label: 'Colfax, NC' },
  { value: 'Colfax_IN', label: 'Colfax, IN' },
  { value: 'Colfax_IA', label: 'Colfax, IA' },
  { value: 'Colfax_WI', label: 'Colfax, WI' },
  { value: 'Colfax_ND', label: 'Colfax, ND' },
  { value: 'Colfax_IL', label: 'Colfax, IL' },
  { value: 'Colfax_LA', label: 'Colfax, LA' },
  { value: 'Colfax_CA', label: 'Colfax, CA' },
  { value: 'Colfax_WA', label: 'Colfax, WA' },
  { value: 'Colgate_WI', label: 'Colgate, WI' },
  { value: 'Collbran_CO', label: 'Collbran, CO' },
  { value: 'College Corner_OH', label: 'College Corner, OH' },
  { value: 'College Grove_TN', label: 'College Grove, TN' },
  { value: 'College Park_MD', label: 'College Park, MD' },
  { value: 'College Place_WA', label: 'College Place, WA' },
  { value: 'College Point_NY', label: 'College Point, NY' },
  { value: 'College Springs_IA', label: 'College Springs, IA' },
  { value: 'College Station_AR', label: 'College Station, AR' },
  { value: 'College Station_TX', label: 'College Station, TX' },
  { value: 'Collegedale_TN', label: 'Collegedale, TN' },
  { value: 'Collegeport_TX', label: 'Collegeport, TX' },
  { value: 'Collegeville_PA', label: 'Collegeville, PA' },
  { value: 'Collegeville_MN', label: 'Collegeville, MN' },
  { value: 'Collettsville_NC', label: 'Collettsville, NC' },
  { value: 'Colleyville_TX', label: 'Colleyville, TX' },
  { value: 'Colliers_WV', label: 'Colliers, WV' },
  { value: 'Colliersville_NY', label: 'Colliersville, NY' },
  { value: 'Collierville_TN', label: 'Collierville, TN' },
  { value: 'Collingswood_NJ', label: 'Collingswood, NJ' },
  { value: 'Collins_NY', label: 'Collins, NY' },
  { value: 'Collins_GA', label: 'Collins, GA' },
  { value: 'Collins_MS', label: 'Collins, MS' },
  { value: 'Collins_OH', label: 'Collins, OH' },
  { value: 'Collins_IA', label: 'Collins, IA' },
  { value: 'Collins_WI', label: 'Collins, WI' },
  { value: 'Collins_MO', label: 'Collins, MO' },
  { value: 'Collins Center_NY', label: 'Collins Center, NY' },
  { value: 'Collinston_LA', label: 'Collinston, LA' },
  { value: 'Collinston_UT', label: 'Collinston, UT' },
  { value: 'Collinsville_CT', label: 'Collinsville, CT' },
  { value: 'Collinsville_VA', label: 'Collinsville, VA' },
  { value: 'Collinsville_AL', label: 'Collinsville, AL' },
  { value: 'Collinsville_MS', label: 'Collinsville, MS' },
  { value: 'Collinsville_IL', label: 'Collinsville, IL' },
  { value: 'Collinsville_OK', label: 'Collinsville, OK' },
  { value: 'Collinsville_TX', label: 'Collinsville, TX' },
  { value: 'Collinwood_TN', label: 'Collinwood, TN' },
  { value: 'Collison_IL', label: 'Collison, IL' },
  { value: 'Collyer_KS', label: 'Collyer, KS' },
  { value: 'Colman_SD', label: 'Colman, SD' },
  { value: 'Colmar_PA', label: 'Colmar, PA' },
  { value: 'Colmesneil_TX', label: 'Colmesneil, TX' },
  { value: 'Colo_IA', label: 'Colo, IA' },
  { value: 'Cologne_MN', label: 'Cologne, MN' },
  { value: 'Coloma_MI', label: 'Coloma, MI' },
  { value: 'Coloma_WI', label: 'Coloma, WI' },
  { value: 'Coloma_CA', label: 'Coloma, CA' },
  { value: 'Colome_SD', label: 'Colome, SD' },
  { value: 'Colon_MI', label: 'Colon, MI' },
  { value: 'Colon_NE', label: 'Colon, NE' },
  { value: 'Colona_IL', label: 'Colona, IL' },
  { value: 'Colonia_NJ', label: 'Colonia, NJ' },
  { value: 'Colonial Beach_VA', label: 'Colonial Beach, VA' },
  { value: 'Colonial Heights_VA', label: 'Colonial Heights, VA' },
  { value: 'Colony_KS', label: 'Colony, KS' },
  { value: 'Colony_OK', label: 'Colony, OK' },
  { value: 'Colora_MD', label: 'Colora, MD' },
  { value: 'Colorado City_TX', label: 'Colorado City, TX' },
  { value: 'Colorado City_CO', label: 'Colorado City, CO' },
  { value: 'Colorado City_AZ', label: 'Colorado City, AZ' },
  { value: 'Colorado Springs_CO', label: 'Colorado Springs, CO' },
  { value: 'Colp_IL', label: 'Colp, IL' },
  { value: 'Colquitt_GA', label: 'Colquitt, GA' },
  { value: 'Colrain_MA', label: 'Colrain, MA' },
  { value: 'Colstrip_MT', label: 'Colstrip, MT' },
  { value: 'Colt_AR', label: 'Colt, AR' },
  { value: 'Colton_NY', label: 'Colton, NY' },
  { value: 'Colton_OH', label: 'Colton, OH' },
  { value: 'Colton_SD', label: 'Colton, SD' },
  { value: 'Colton_CA', label: 'Colton, CA' },
  { value: 'Colton_OR', label: 'Colton, OR' },
  { value: 'Colton_WA', label: 'Colton, WA' },
  { value: 'Coltons Point_MD', label: 'Coltons Point, MD' },
  { value: 'Colts Neck_NJ', label: 'Colts Neck, NJ' },
  { value: 'Columbia_CT', label: 'Columbia, CT' },
  { value: 'Columbia_NJ', label: 'Columbia, NJ' },
  { value: 'Columbia_PA', label: 'Columbia, PA' },
  { value: 'Columbia_MD', label: 'Columbia, MD' },
  { value: 'Columbia_VA', label: 'Columbia, VA' },
  { value: 'Columbia_NC', label: 'Columbia, NC' },
  { value: 'Columbia_SC', label: 'Columbia, SC' },
  { value: 'Columbia_AL', label: 'Columbia, AL' },
  { value: 'Columbia_TN', label: 'Columbia, TN' },
  { value: 'Columbia_MS', label: 'Columbia, MS' },
  { value: 'Columbia_KY', label: 'Columbia, KY' },
  { value: 'Columbia_IA', label: 'Columbia, IA' },
  { value: 'Columbia_SD', label: 'Columbia, SD' },
  { value: 'Columbia_IL', label: 'Columbia, IL' },
  { value: 'Columbia_MO', label: 'Columbia, MO' },
  { value: 'Columbia_LA', label: 'Columbia, LA' },
  { value: 'Columbia_CA', label: 'Columbia, CA' },
  { value: 'Columbia City_IN', label: 'Columbia City, IN' },
  { value: 'Columbia City_OR', label: 'Columbia City, OR' },
  { value: 'Columbia Cross Roads_PA', label: 'Columbia Cross Roads, PA' },
  { value: 'Columbia Falls_ME', label: 'Columbia Falls, ME' },
  { value: 'Columbia Falls_MT', label: 'Columbia Falls, MT' },
  { value: 'Columbia Station_OH', label: 'Columbia Station, OH' },
  { value: 'Columbiana_AL', label: 'Columbiana, AL' },
  { value: 'Columbiana_OH', label: 'Columbiana, OH' },
  { value: 'Columbiaville_MI', label: 'Columbiaville, MI' },
  { value: 'Columbus_NJ', label: 'Columbus, NJ' },
  { value: 'Columbus_PA', label: 'Columbus, PA' },
  { value: 'Columbus_NC', label: 'Columbus, NC' },
  { value: 'Columbus_GA', label: 'Columbus, GA' },
  { value: 'Columbus_MS', label: 'Columbus, MS' },
  { value: 'Columbus_KY', label: 'Columbus, KY' },
  { value: 'Columbus_OH', label: 'Columbus, OH' },
  { value: 'Columbus_IN', label: 'Columbus, IN' },
  { value: 'Columbus_MI', label: 'Columbus, MI' },
  { value: 'Columbus_WI', label: 'Columbus, WI' },
  { value: 'Columbus_ND', label: 'Columbus, ND' },
  { value: 'Columbus_MT', label: 'Columbus, MT' },
  { value: 'Columbus_KS', label: 'Columbus, KS' },
  { value: 'Columbus_NE', label: 'Columbus, NE' },
  { value: 'Columbus_AR', label: 'Columbus, AR' },
  { value: 'Columbus_TX', label: 'Columbus, TX' },
  { value: 'Columbus_NM', label: 'Columbus, NM' },
  { value: 'Columbus City_IA', label: 'Columbus City, IA' },
  { value: 'Columbus Grove_OH', label: 'Columbus Grove, OH' },
  { value: 'Columbus Junction_IA', label: 'Columbus Junction, IA' },
  { value: 'Colusa_CA', label: 'Colusa, CA' },
  { value: 'Colver_PA', label: 'Colver, PA' },
  { value: 'Colville_WA', label: 'Colville, WA' },
  { value: 'Colwell_IA', label: 'Colwell, IA' },
  { value: 'Colwich_KS', label: 'Colwich, KS' },
  { value: 'Comanche_OK', label: 'Comanche, OK' },
  { value: 'Comanche_TX', label: 'Comanche, TX' },
  { value: 'Combes_TX', label: 'Combes, TX' },
  { value: 'Combined Locks_WI', label: 'Combined Locks, WI' },
  { value: 'Combs_KY', label: 'Combs, KY' },
  { value: 'Combs_AR', label: 'Combs, AR' },
  { value: 'Comer_GA', label: 'Comer, GA' },
  { value: 'Comerio_PR', label: 'Comerio, PR' },
  { value: 'Comfort_WV', label: 'Comfort, WV' },
  { value: 'Comfort_TX', label: 'Comfort, TX' },
  { value: 'Comfrey_MN', label: 'Comfrey, MN' },
  { value: 'Comins_MI', label: 'Comins, MI' },
  { value: 'Commack_NY', label: 'Commack, NY' },
  { value: 'Commerce_GA', label: 'Commerce, GA' },
  { value: 'Commerce_MO', label: 'Commerce, MO' },
  { value: 'Commerce_OK', label: 'Commerce, OK' },
  { value: 'Commerce_TX', label: 'Commerce, TX' },
  { value: 'Commerce City_CO', label: 'Commerce City, CO' },
  { value: 'Commerce Township_MI', label: 'Commerce Township, MI' },
  { value: 'Commercial Point_OH', label: 'Commercial Point, OH' },
  { value: 'Commiskey_IN', label: 'Commiskey, IN' },
  { value: 'Commodore_PA', label: 'Commodore, PA' },
  { value: 'Como_NC', label: 'Como, NC' },
  { value: 'Como_MS', label: 'Como, MS' },
  { value: 'Como_TX', label: 'Como, TX' },
  { value: 'Como_CO', label: 'Como, CO' },
  { value: 'Comptche_CA', label: 'Comptche, CA' },
  { value: 'Compton_IL', label: 'Compton, IL' },
  { value: 'Compton_AR', label: 'Compton, AR' },
  { value: 'Compton_CA', label: 'Compton, CA' },
  { value: 'Comstock_NY', label: 'Comstock, NY' },
  { value: 'Comstock_WI', label: 'Comstock, WI' },
  { value: 'Comstock_MN', label: 'Comstock, MN' },
  { value: 'Comstock_NE', label: 'Comstock, NE' },
  { value: 'Comstock_TX', label: 'Comstock, TX' },
  { value: 'Comstock Park_MI', label: 'Comstock Park, MI' },
  { value: 'Conasauga_TN', label: 'Conasauga, TN' },
  { value: 'Concan_TX', label: 'Concan, TX' },
  { value: 'Concepcion_TX', label: 'Concepcion, TX' },
  { value: 'Conception_MO', label: 'Conception, MO' },
  { value: 'Conception Junction_MO', label: 'Conception Junction, MO' },
  { value: 'Conchas Dam_NM', label: 'Conchas Dam, NM' },
  { value: 'Concho_OK', label: 'Concho, OK' },
  { value: 'Concho_AZ', label: 'Concho, AZ' },
  { value: 'Conconully_WA', label: 'Conconully, WA' },
  { value: 'Concord_MA', label: 'Concord, MA' },
  { value: 'Concord_NH', label: 'Concord, NH' },
  { value: 'Concord_VT', label: 'Concord, VT' },
  { value: 'Concord_PA', label: 'Concord, PA' },
  { value: 'Concord_VA', label: 'Concord, VA' },
  { value: 'Concord_NC', label: 'Concord, NC' },
  { value: 'Concord_GA', label: 'Concord, GA' },
  { value: 'Concord_MI', label: 'Concord, MI' },
  { value: 'Concord_IL', label: 'Concord, IL' },
  { value: 'Concord_NE', label: 'Concord, NE' },
  { value: 'Concord_AR', label: 'Concord, AR' },
  { value: 'Concord_CA', label: 'Concord, CA' },
  { value: 'Concordia_MO', label: 'Concordia, MO' },
  { value: 'Concordia_KS', label: 'Concordia, KS' },
  { value: 'Concrete_WA', label: 'Concrete, WA' },
  { value: 'Conde_SD', label: 'Conde, SD' },
  { value: 'Condon_MT', label: 'Condon, MT' },
  { value: 'Condon_OR', label: 'Condon, OR' },
  { value: 'Conehatta_MS', label: 'Conehatta, MS' },
  { value: 'Conejos_CO', label: 'Conejos, CO' },
  { value: 'Conestoga_PA', label: 'Conestoga, PA' },
  { value: 'Conesus_NY', label: 'Conesus, NY' },
  { value: 'Conesville_OH', label: 'Conesville, OH' },
  { value: 'Conesville_IA', label: 'Conesville, IA' },
  { value: 'Conetoe_NC', label: 'Conetoe, NC' },
  { value: 'Conewango Valley_NY', label: 'Conewango Valley, NY' },
  { value: 'Confluence_PA', label: 'Confluence, PA' },
  { value: 'Conger_MN', label: 'Conger, MN' },
  { value: 'Congers_NY', label: 'Congers, NY' },
  { value: 'Congerville_IL', label: 'Congerville, IL' },
  { value: 'Congress_AZ', label: 'Congress, AZ' },
  { value: 'Conifer_CO', label: 'Conifer, CO' },
  { value: 'Conklin_NY', label: 'Conklin, NY' },
  { value: 'Conklin_MI', label: 'Conklin, MI' },
  { value: 'Conley_GA', label: 'Conley, GA' },
  { value: 'Conneaut_OH', label: 'Conneaut, OH' },
  { value: 'Conneaut Lake_PA', label: 'Conneaut Lake, PA' },
  { value: 'Conneautville_PA', label: 'Conneautville, PA' },
  { value: 'Connell_WA', label: 'Connell, WA' },
  { value: 'Connellsville_PA', label: 'Connellsville, PA' },
  { value: 'Connelly_NY', label: 'Connelly, NY' },
  { value: 'Connelly Springs_NC', label: 'Connelly Springs, NC' },
  { value: 'Conner_MT', label: 'Conner, MT' },
  { value: 'Connersville_IN', label: 'Connersville, IN' },
  { value: 'Connerville_OK', label: 'Connerville, OK' },
  { value: 'Connoquenessing_PA', label: 'Connoquenessing, PA' },
  { value: 'Conover_NC', label: 'Conover, NC' },
  { value: 'Conover_OH', label: 'Conover, OH' },
  { value: 'Conover_WI', label: 'Conover, WI' },
  { value: 'Conowingo_MD', label: 'Conowingo, MD' },
  { value: 'Conrad_IA', label: 'Conrad, IA' },
  { value: 'Conrad_MT', label: 'Conrad, MT' },
  { value: 'Conrath_WI', label: 'Conrath, WI' },
  { value: 'Conroe_TX', label: 'Conroe, TX' },
  { value: 'Conroy_IA', label: 'Conroy, IA' },
  { value: 'Conshohocken_PA', label: 'Conshohocken, PA' },
  { value: 'Constable_NY', label: 'Constable, NY' },
  { value: 'Constableville_NY', label: 'Constableville, NY' },
  { value: 'Constantia_NY', label: 'Constantia, NY' },
  { value: 'Constantine_MI', label: 'Constantine, MI' },
  { value: 'Continental_OH', label: 'Continental, OH' },
  { value: 'Continental Divide_NM', label: 'Continental Divide, NM' },
  { value: 'Contoocook_NH', label: 'Contoocook, NH' },
  { value: 'Convent_LA', label: 'Convent, LA' },
  { value: 'Convent Station_NJ', label: 'Convent Station, NJ' },
  { value: 'Converse_SC', label: 'Converse, SC' },
  { value: 'Converse_IN', label: 'Converse, IN' },
  { value: 'Converse_LA', label: 'Converse, LA' },
  { value: 'Converse_TX', label: 'Converse, TX' },
  { value: 'Convoy_OH', label: 'Convoy, OH' },
  { value: 'Conway_MA', label: 'Conway, MA' },
  { value: 'Conway_NH', label: 'Conway, NH' },
  { value: 'Conway_PA', label: 'Conway, PA' },
  { value: 'Conway_NC', label: 'Conway, NC' },
  { value: 'Conway_SC', label: 'Conway, SC' },
  { value: 'Conway_MI', label: 'Conway, MI' },
  { value: 'Conway_MO', label: 'Conway, MO' },
  { value: 'Conway_AR', label: 'Conway, AR' },
  { value: 'Conway_WA', label: 'Conway, WA' },
  { value: 'Conway Springs_KS', label: 'Conway Springs, KS' },
  { value: 'Conyers_GA', label: 'Conyers, GA' },
  { value: 'Conyngham_PA', label: 'Conyngham, PA' },
  { value: 'Cook_MN', label: 'Cook, MN' },
  { value: 'Cook_NE', label: 'Cook, NE' },
  { value: 'Cook Springs_AL', label: 'Cook Springs, AL' },
  { value: 'Cook Sta_MO', label: 'Cook Sta, MO' },
  { value: 'Cooke City_MT', label: 'Cooke City, MT' },
  { value: 'Cookeville_TN', label: 'Cookeville, TN' },
  { value: 'Cooks_MI', label: 'Cooks, MI' },
  { value: 'Cooksburg_PA', label: 'Cooksburg, PA' },
  { value: 'Cookson_OK', label: 'Cookson, OK' },
  { value: 'Cookstown_NJ', label: 'Cookstown, NJ' },
  { value: 'Cooksville_MD', label: 'Cooksville, MD' },
  { value: 'Cooksville_IL', label: 'Cooksville, IL' },
  { value: 'Cookville_TX', label: 'Cookville, TX' },
  { value: 'Cool_CA', label: 'Cool, CA' },
  { value: 'Cool Ridge_WV', label: 'Cool Ridge, WV' },
  { value: 'Cooleemee_NC', label: 'Cooleemee, NC' },
  { value: 'Coolidge_GA', label: 'Coolidge, GA' },
  { value: 'Coolidge_KS', label: 'Coolidge, KS' },
  { value: 'Coolidge_TX', label: 'Coolidge, TX' },
  { value: 'Coolidge_AZ', label: 'Coolidge, AZ' },
  { value: 'Coolin_ID', label: 'Coolin, ID' },
  { value: 'Coolspring_PA', label: 'Coolspring, PA' },
  { value: 'Coolville_OH', label: 'Coolville, OH' },
  { value: 'Coon Rapids_IA', label: 'Coon Rapids, IA' },
  { value: 'Coon Valley_WI', label: 'Coon Valley, WI' },
  { value: 'Cooper_TX', label: 'Cooper, TX' },
  { value: 'Cooper Landing_AK', label: 'Cooper Landing, AK' },
  { value: 'Coopers Mills_ME', label: 'Coopers Mills, ME' },
  { value: 'Coopers Plains_NY', label: 'Coopers Plains, NY' },
  { value: 'Coopersburg_PA', label: 'Coopersburg, PA' },
  { value: 'Cooperstown_NY', label: 'Cooperstown, NY' },
  { value: 'Cooperstown_PA', label: 'Cooperstown, PA' },
  { value: 'Cooperstown_ND', label: 'Cooperstown, ND' },
  { value: 'Coopersville_MI', label: 'Coopersville, MI' },
  { value: 'Coos Bay_OR', label: 'Coos Bay, OR' },
  { value: 'Coosada_AL', label: 'Coosada, AL' },
  { value: 'Coosawhatchie_SC', label: 'Coosawhatchie, SC' },
  { value: 'Cooter_MO', label: 'Cooter, MO' },
  { value: 'Copake_NY', label: 'Copake, NY' },
  { value: 'Copake Falls_NY', label: 'Copake Falls, NY' },
  { value: 'Copalis Beach_WA', label: 'Copalis Beach, WA' },
  { value: 'Copalis Crossing_WA', label: 'Copalis Crossing, WA' },
  { value: 'Copan_OK', label: 'Copan, OK' },
  { value: 'Cope_SC', label: 'Cope, SC' },
  { value: 'Cope_CO', label: 'Cope, CO' },
  { value: 'Copeland_FL', label: 'Copeland, FL' },
  { value: 'Copeland_KS', label: 'Copeland, KS' },
  { value: 'Copemish_MI', label: 'Copemish, MI' },
  { value: 'Copen_WV', label: 'Copen, WV' },
  { value: 'Copenhagen_NY', label: 'Copenhagen, NY' },
  { value: 'Copiague_NY', label: 'Copiague, NY' },
  { value: 'Coplay_PA', label: 'Coplay, PA' },
  { value: 'Coppell_TX', label: 'Coppell, TX' },
  { value: 'Copper Center_AK', label: 'Copper Center, AK' },
  { value: 'Copper City_MI', label: 'Copper City, MI' },
  { value: 'Copper Harbor_MI', label: 'Copper Harbor, MI' },
  { value: 'Copper Hill_VA', label: 'Copper Hill, VA' },
  { value: 'Copperas Cove_TX', label: 'Copperas Cove, TX' },
  { value: 'Copperhill_TN', label: 'Copperhill, TN' },
  { value: 'Copperopolis_CA', label: 'Copperopolis, CA' },
  { value: 'Coquille_OR', label: 'Coquille, OR' },
  { value: 'Cora_WY', label: 'Cora, WY' },
  { value: 'Coral_PA', label: 'Coral, PA' },
  { value: 'Coral_MI', label: 'Coral, MI' },
  { value: 'Coral Springs_FL', label: 'Coral Springs, FL' },
  { value: 'Coralville_IA', label: 'Coralville, IA' },
  { value: 'Coram_NY', label: 'Coram, NY' },
  { value: 'Coram_MT', label: 'Coram, MT' },
  { value: 'Coraopolis_PA', label: 'Coraopolis, PA' },
  { value: 'Corapeake_NC', label: 'Corapeake, NC' },
  { value: 'Corbett_OR', label: 'Corbett, OR' },
  { value: 'Corbin_KY', label: 'Corbin, KY' },
  { value: 'Corcoran_CA', label: 'Corcoran, CA' },
  { value: 'Cord_AR', label: 'Cord, AR' },
  { value: 'Cordele_GA', label: 'Cordele, GA' },
  { value: 'Cordell_OK', label: 'Cordell, OK' },
  { value: 'Corder_MO', label: 'Corder, MO' },
  { value: 'Cordesville_SC', label: 'Cordesville, SC' },
  { value: 'Cordova_MD', label: 'Cordova, MD' },
  { value: 'Cordova_NC', label: 'Cordova, NC' },
  { value: 'Cordova_SC', label: 'Cordova, SC' },
  { value: 'Cordova_AL', label: 'Cordova, AL' },
  { value: 'Cordova_TN', label: 'Cordova, TN' },
  { value: 'Cordova_IL', label: 'Cordova, IL' },
  { value: 'Cordova_NE', label: 'Cordova, NE' },
  { value: 'Cordova_NM', label: 'Cordova, NM' },
  { value: 'Cordova_AK', label: 'Cordova, AK' },
  { value: 'Corea_ME', label: 'Corea, ME' },
  { value: 'Corfu_NY', label: 'Corfu, NY' },
  { value: 'Corinna_ME', label: 'Corinna, ME' },
  { value: 'Corinne_WV', label: 'Corinne, WV' },
  { value: 'Corinne_UT', label: 'Corinne, UT' },
  { value: 'Corinth_ME', label: 'Corinth, ME' },
  { value: 'Corinth_VT', label: 'Corinth, VT' },
  { value: 'Corinth_NY', label: 'Corinth, NY' },
  { value: 'Corinth_MS', label: 'Corinth, MS' },
  { value: 'Corinth_KY', label: 'Corinth, KY' },
  { value: 'Corn_OK', label: 'Corn, OK' },
  { value: 'Cornelia_GA', label: 'Cornelia, GA' },
  { value: 'Cornelius_NC', label: 'Cornelius, NC' },
  { value: 'Cornelius_OR', label: 'Cornelius, OR' },
  { value: 'Cornell_MI', label: 'Cornell, MI' },
  { value: 'Cornell_WI', label: 'Cornell, WI' },
  { value: 'Cornell_IL', label: 'Cornell, IL' },
  { value: 'Cornersville_TN', label: 'Cornersville, TN' },
  { value: 'Cornettsville_KY', label: 'Cornettsville, KY' },
  { value: 'Corning_NY', label: 'Corning, NY' },
  { value: 'Corning_OH', label: 'Corning, OH' },
  { value: 'Corning_IA', label: 'Corning, IA' },
  { value: 'Corning_KS', label: 'Corning, KS' },
  { value: 'Corning_AR', label: 'Corning, AR' },
  { value: 'Corning_CA', label: 'Corning, CA' },
  { value: 'Cornish_NH', label: 'Cornish, NH' },
  { value: 'Cornish_ME', label: 'Cornish, ME' },
  { value: 'Cornish_UT', label: 'Cornish, UT' },
  { value: 'Cornland_IL', label: 'Cornland, IL' },
  { value: 'Cornucopia_WI', label: 'Cornucopia, WI' },
  { value: 'Cornville_AZ', label: 'Cornville, AZ' },
  { value: 'Cornwall_CT', label: 'Cornwall, CT' },
  { value: 'Cornwall_NY', label: 'Cornwall, NY' },
  { value: 'Cornwall_PA', label: 'Cornwall, PA' },
  { value: 'Cornwall Bridge_CT', label: 'Cornwall Bridge, CT' },
  { value: 'Cornwall On Hudson_NY', label: 'Cornwall On Hudson, NY' },
  { value: 'Cornwallville_NY', label: 'Cornwallville, NY' },
  { value: 'Corolla_NC', label: 'Corolla, NC' },
  { value: 'Corona_NY', label: 'Corona, NY' },
  { value: 'Corona_SD', label: 'Corona, SD' },
  { value: 'Corona_NM', label: 'Corona, NM' },
  { value: 'Corona_CA', label: 'Corona, CA' },
  { value: 'Corona Del Mar_CA', label: 'Corona Del Mar, CA' },
  { value: 'Coronado_CA', label: 'Coronado, CA' },
  { value: 'Corozal_PR', label: 'Corozal, PR' },
  { value: 'Corpus Christi_TX', label: 'Corpus Christi, TX' },
  { value: 'Corral_ID', label: 'Corral, ID' },
  { value: 'Corrales_NM', label: 'Corrales, NM' },
  { value: 'Correctionville_IA', label: 'Correctionville, IA' },
  { value: 'Correll_MN', label: 'Correll, MN' },
  { value: 'Corrigan_TX', label: 'Corrigan, TX' },
  { value: 'Corriganville_MD', label: 'Corriganville, MD' },
  { value: 'Corry_PA', label: 'Corry, PA' },
  { value: 'Corryton_TN', label: 'Corryton, TN' },
  { value: 'Corsica_PA', label: 'Corsica, PA' },
  { value: 'Corsica_SD', label: 'Corsica, SD' },
  { value: 'Corsicana_TX', label: 'Corsicana, TX' },
  { value: 'Corte Madera_CA', label: 'Corte Madera, CA' },
  { value: 'Cortez_FL', label: 'Cortez, FL' },
  { value: 'Cortez_CO', label: 'Cortez, CO' },
  { value: 'Cortland_NY', label: 'Cortland, NY' },
  { value: 'Cortland_OH', label: 'Cortland, OH' },
  { value: 'Cortland_IL', label: 'Cortland, IL' },
  { value: 'Cortland_NE', label: 'Cortland, NE' },
  { value: 'Cortlandt Manor_NY', label: 'Cortlandt Manor, NY' },
  { value: 'Corunna_IN', label: 'Corunna, IN' },
  { value: 'Corunna_MI', label: 'Corunna, MI' },
  { value: 'Corvallis_MT', label: 'Corvallis, MT' },
  { value: 'Corvallis_OR', label: 'Corvallis, OR' },
  { value: 'Corwith_IA', label: 'Corwith, IA' },
  { value: 'Cory_IN', label: 'Cory, IN' },
  { value: 'Corydon_KY', label: 'Corydon, KY' },
  { value: 'Corydon_IN', label: 'Corydon, IN' },
  { value: 'Corydon_IA', label: 'Corydon, IA' },
  { value: 'Cos Cob_CT', label: 'Cos Cob, CT' },
  { value: 'Cosby_TN', label: 'Cosby, TN' },
  { value: 'Cosby_MO', label: 'Cosby, MO' },
  { value: 'Coshocton_OH', label: 'Coshocton, OH' },
  { value: 'Cosmopolis_WA', label: 'Cosmopolis, WA' },
  { value: 'Cosmos_MN', label: 'Cosmos, MN' },
  { value: 'Cossayuna_NY', label: 'Cossayuna, NY' },
  { value: 'Cost_TX', label: 'Cost, TX' },
  { value: 'Costa_WV', label: 'Costa, WV' },
  { value: 'Costa Mesa_CA', label: 'Costa Mesa, CA' },
  { value: 'Costilla_NM', label: 'Costilla, NM' },
  { value: 'Cotati_CA', label: 'Cotati, CA' },
  { value: 'Coto Laurel_PR', label: 'Coto Laurel, PR' },
  { value: 'Cotopaxi_CO', label: 'Cotopaxi, CO' },
  { value: 'Cottage Grove_TN', label: 'Cottage Grove, TN' },
  { value: 'Cottage Grove_WI', label: 'Cottage Grove, WI' },
  { value: 'Cottage Grove_MN', label: 'Cottage Grove, MN' },
  { value: 'Cottage Grove_OR', label: 'Cottage Grove, OR' },
  { value: 'Cottage Hills_IL', label: 'Cottage Hills, IL' },
  { value: 'Cottageville_WV', label: 'Cottageville, WV' },
  { value: 'Cottageville_SC', label: 'Cottageville, SC' },
  { value: 'Cottekill_NY', label: 'Cottekill, NY' },
  { value: 'Cotter_AR', label: 'Cotter, AR' },
  { value: 'Cotton_MN', label: 'Cotton, MN' },
  { value: 'Cotton Center_TX', label: 'Cotton Center, TX' },
  { value: 'Cotton Plant_AR', label: 'Cotton Plant, AR' },
  { value: 'Cotton Valley_LA', label: 'Cotton Valley, LA' },
  { value: 'Cottondale_FL', label: 'Cottondale, FL' },
  { value: 'Cottondale_AL', label: 'Cottondale, AL' },
  { value: 'Cottonport_LA', label: 'Cottonport, LA' },
  { value: 'Cottonton_AL', label: 'Cottonton, AL' },
  { value: 'Cottontown_TN', label: 'Cottontown, TN' },
  { value: 'Cottonwood_AL', label: 'Cottonwood, AL' },
  { value: 'Cottonwood_MN', label: 'Cottonwood, MN' },
  { value: 'Cottonwood_ID', label: 'Cottonwood, ID' },
  { value: 'Cottonwood_AZ', label: 'Cottonwood, AZ' },
  { value: 'Cottonwood_CA', label: 'Cottonwood, CA' },
  { value: 'Cottonwood Falls_KS', label: 'Cottonwood Falls, KS' },
  { value: 'Cotuit_MA', label: 'Cotuit, MA' },
  { value: 'Cotulla_TX', label: 'Cotulla, TX' },
  { value: 'Couch_MO', label: 'Couch, MO' },
  { value: 'Couderay_WI', label: 'Couderay, WI' },
  { value: 'Coudersport_PA', label: 'Coudersport, PA' },
  { value: 'Cougar_WA', label: 'Cougar, WA' },
  { value: 'Coulee City_WA', label: 'Coulee City, WA' },
  { value: 'Coulee Dam_WA', label: 'Coulee Dam, WA' },
  { value: 'Coulter_IA', label: 'Coulter, IA' },
  { value: 'Coulters_PA', label: 'Coulters, PA' },
  { value: 'Coulterville_IL', label: 'Coulterville, IL' },
  { value: 'Coulterville_CA', label: 'Coulterville, CA' },
  { value: 'Counce_TN', label: 'Counce, TN' },
  { value: 'Council_NC', label: 'Council, NC' },
  { value: 'Council_ID', label: 'Council, ID' },
  { value: 'Council Bluffs_IA', label: 'Council Bluffs, IA' },
  { value: 'Council Grove_KS', label: 'Council Grove, KS' },
  { value: 'Council Hill_OK', label: 'Council Hill, OK' },
  { value: 'Counselor_NM', label: 'Counselor, NM' },
  { value: 'Country Club Hills_IL', label: 'Country Club Hills, IL' },
  { value: 'Countyline_OK', label: 'Countyline, OK' },
  { value: 'Coupeville_WA', label: 'Coupeville, WA' },
  { value: 'Coupland_TX', label: 'Coupland, TX' },
  { value: 'Courtenay_ND', label: 'Courtenay, ND' },
  { value: 'Courtland_VA', label: 'Courtland, VA' },
  { value: 'Courtland_AL', label: 'Courtland, AL' },
  { value: 'Courtland_MS', label: 'Courtland, MS' },
  { value: 'Courtland_MN', label: 'Courtland, MN' },
  { value: 'Courtland_KS', label: 'Courtland, KS' },
  { value: 'Courtland_CA', label: 'Courtland, CA' },
  { value: 'Coushatta_LA', label: 'Coushatta, LA' },
  { value: 'Cove_AR', label: 'Cove, AR' },
  { value: 'Cove_OR', label: 'Cove, OR' },
  { value: 'Cove City_NC', label: 'Cove City, NC' },
  { value: 'Covel_WV', label: 'Covel, WV' },
  { value: 'Covelo_CA', label: 'Covelo, CA' },
  { value: 'Coventry_RI', label: 'Coventry, RI' },
  { value: 'Coventry_VT', label: 'Coventry, VT' },
  { value: 'Coventry_CT', label: 'Coventry, CT' },
  { value: 'Covert_MI', label: 'Covert, MI' },
  { value: 'Covesville_VA', label: 'Covesville, VA' },
  { value: 'Covina_CA', label: 'Covina, CA' },
  { value: 'Covington_PA', label: 'Covington, PA' },
  { value: 'Covington_VA', label: 'Covington, VA' },
  { value: 'Covington_GA', label: 'Covington, GA' },
  { value: 'Covington_TN', label: 'Covington, TN' },
  { value: 'Covington_KY', label: 'Covington, KY' },
  { value: 'Covington_OH', label: 'Covington, OH' },
  { value: 'Covington_IN', label: 'Covington, IN' },
  { value: 'Covington_MI', label: 'Covington, MI' },
  { value: 'Covington_LA', label: 'Covington, LA' },
  { value: 'Covington_OK', label: 'Covington, OK' },
  { value: 'Covington_TX', label: 'Covington, TX' },
  { value: 'Cowan_TN', label: 'Cowan, TN' },
  { value: 'Cowansville_PA', label: 'Cowansville, PA' },
  { value: 'Coward_SC', label: 'Coward, SC' },
  { value: 'Cowarts_AL', label: 'Cowarts, AL' },
  { value: 'Cowden_IL', label: 'Cowden, IL' },
  { value: 'Cowdrey_CO', label: 'Cowdrey, CO' },
  { value: 'Cowen_WV', label: 'Cowen, WV' },
  { value: 'Coweta_OK', label: 'Coweta, OK' },
  { value: 'Cowgill_MO', label: 'Cowgill, MO' },
  { value: 'Cowiche_WA', label: 'Cowiche, WA' },
  { value: 'Cowlesville_NY', label: 'Cowlesville, NY' },
  { value: 'Cowley_WY', label: 'Cowley, WY' },
  { value: 'Cowpens_SC', label: 'Cowpens, SC' },
  { value: 'Coxs Creek_KY', label: 'Coxs Creek, KY' },
  { value: 'Coxs Mills_WV', label: 'Coxs Mills, WV' },
  { value: 'Coxsackie_NY', label: 'Coxsackie, NY' },
  { value: 'Coy_AL', label: 'Coy, AL' },
  { value: 'Coyanosa_TX', label: 'Coyanosa, TX' },
  { value: 'Coyle_OK', label: 'Coyle, OK' },
  { value: 'Coyote_NM', label: 'Coyote, NM' },
  { value: 'Coyote_CA', label: 'Coyote, CA' },
  { value: 'Cozad_NE', label: 'Cozad, NE' },
  { value: 'Crab Orchard_WV', label: 'Crab Orchard, WV' },
  { value: 'Crab Orchard_TN', label: 'Crab Orchard, TN' },
  { value: 'Crab Orchard_KY', label: 'Crab Orchard, KY' },
  { value: 'Crab Orchard_NE', label: 'Crab Orchard, NE' },
  { value: 'Crabtree_PA', label: 'Crabtree, PA' },
  { value: 'Crabtree_OR', label: 'Crabtree, OR' },
  { value: 'Craftsbury_VT', label: 'Craftsbury, VT' },
  { value: 'Craftsbury Common_VT', label: 'Craftsbury Common, VT' },
  { value: 'Cragford_AL', label: 'Cragford, AL' },
  { value: 'Cragsmoor_NY', label: 'Cragsmoor, NY' },
  { value: 'Craig_MO', label: 'Craig, MO' },
  { value: 'Craig_NE', label: 'Craig, NE' },
  { value: 'Craig_CO', label: 'Craig, CO' },
  { value: 'Craig_AK', label: 'Craig, AK' },
  { value: 'Craigmont_ID', label: 'Craigmont, ID' },
  { value: 'Craigsville_VA', label: 'Craigsville, VA' },
  { value: 'Craigsville_WV', label: 'Craigsville, WV' },
  { value: 'Craigville_IN', label: 'Craigville, IN' },
  { value: 'Cramerton_NC', label: 'Cramerton, NC' },
  { value: 'Cranberry_PA', label: 'Cranberry, PA' },
  { value: 'Cranberry Isles_ME', label: 'Cranberry Isles, ME' },
  { value: 'Cranberry Lake_NY', label: 'Cranberry Lake, NY' },
  { value: 'Cranberry Township_PA', label: 'Cranberry Township, PA' },
  { value: 'Cranbury_NJ', label: 'Cranbury, NJ' },
  { value: 'Crandall_GA', label: 'Crandall, GA' },
  { value: 'Crandall_IN', label: 'Crandall, IN' },
  { value: 'Crandall_TX', label: 'Crandall, TX' },
  { value: 'Crandon_WI', label: 'Crandon, WI' },
  { value: 'Crane_IN', label: 'Crane, IN' },
  { value: 'Crane_MT', label: 'Crane, MT' },
  { value: 'Crane_MO', label: 'Crane, MO' },
  { value: 'Crane_TX', label: 'Crane, TX' },
  { value: 'Crane_OR', label: 'Crane, OR' },
  { value: 'Crane Hill_AL', label: 'Crane Hill, AL' },
  { value: 'Crane Lake_MN', label: 'Crane Lake, MN' },
  { value: 'Cranesville_PA', label: 'Cranesville, PA' },
  { value: 'Cranfills Gap_TX', label: 'Cranfills Gap, TX' },
  { value: 'Cranford_NJ', label: 'Cranford, NJ' },
  { value: 'Cranks_KY', label: 'Cranks, KY' },
  { value: 'Cranston_RI', label: 'Cranston, RI' },
  { value: 'Crapo_MD', label: 'Crapo, MD' },
  { value: 'Crary_ND', label: 'Crary, ND' },
  { value: 'Craryville_NY', label: 'Craryville, NY' },
  { value: 'Crater Lake_OR', label: 'Crater Lake, OR' },
  { value: 'Crawford_WV', label: 'Crawford, WV' },
  { value: 'Crawford_GA', label: 'Crawford, GA' },
  { value: 'Crawford_TN', label: 'Crawford, TN' },
  { value: 'Crawford_MS', label: 'Crawford, MS' },
  { value: 'Crawford_NE', label: 'Crawford, NE' },
  { value: 'Crawford_OK', label: 'Crawford, OK' },
  { value: 'Crawford_TX', label: 'Crawford, TX' },
  { value: 'Crawford_CO', label: 'Crawford, CO' },
  { value: 'Crawfordsville_IN', label: 'Crawfordsville, IN' },
  { value: 'Crawfordsville_IA', label: 'Crawfordsville, IA' },
  { value: 'Crawfordsville_AR', label: 'Crawfordsville, AR' },
  { value: 'Crawfordsville_OR', label: 'Crawfordsville, OR' },
  { value: 'Crawfordville_GA', label: 'Crawfordville, GA' },
  { value: 'Crawfordville_FL', label: 'Crawfordville, FL' },
  { value: 'Crawley_WV', label: 'Crawley, WV' },
  { value: 'Creal Springs_IL', label: 'Creal Springs, IL' },
  { value: 'Cream Ridge_NJ', label: 'Cream Ridge, NJ' },
  { value: 'Creede_CO', label: 'Creede, CO' },
  { value: 'Creedmoor_NC', label: 'Creedmoor, NC' },
  { value: 'Creekside_PA', label: 'Creekside, PA' },
  { value: 'Creighton_PA', label: 'Creighton, PA' },
  { value: 'Creighton_MO', label: 'Creighton, MO' },
  { value: 'Creighton_NE', label: 'Creighton, NE' },
  { value: 'Crenshaw_MS', label: 'Crenshaw, MS' },
  { value: 'Creola_AL', label: 'Creola, AL' },
  { value: 'Creola_OH', label: 'Creola, OH' },
  { value: 'Creole_LA', label: 'Creole, LA' },
  { value: 'Cresbard_SD', label: 'Cresbard, SD' },
  { value: 'Crescent_PA', label: 'Crescent, PA' },
  { value: 'Crescent_IA', label: 'Crescent, IA' },
  { value: 'Crescent_OK', label: 'Crescent, OK' },
  { value: 'Crescent_OR', label: 'Crescent, OR' },
  { value: 'Crescent City_FL', label: 'Crescent City, FL' },
  { value: 'Crescent City_IL', label: 'Crescent City, IL' },
  { value: 'Crescent City_CA', label: 'Crescent City, CA' },
  { value: 'Crescent Mills_CA', label: 'Crescent Mills, CA' },
  { value: 'Crescent Valley_NV', label: 'Crescent Valley, NV' },
  { value: 'Cresco_PA', label: 'Cresco, PA' },
  { value: 'Cresco_IA', label: 'Cresco, IA' },
  { value: 'Cressey_CA', label: 'Cressey, CA' },
  { value: 'Cresskill_NJ', label: 'Cresskill, NJ' },
  { value: 'Cresson_PA', label: 'Cresson, PA' },
  { value: 'Cresson_TX', label: 'Cresson, TX' },
  { value: 'Cressona_PA', label: 'Cressona, PA' },
  { value: 'Crest Hill_IL', label: 'Crest Hill, IL' },
  { value: 'Crested Butte_CO', label: 'Crested Butte, CO' },
  { value: 'Crestline_OH', label: 'Crestline, OH' },
  { value: 'Crestline_CA', label: 'Crestline, CA' },
  { value: 'Creston_WV', label: 'Creston, WV' },
  { value: 'Creston_NC', label: 'Creston, NC' },
  { value: 'Creston_OH', label: 'Creston, OH' },
  { value: 'Creston_IA', label: 'Creston, IA' },
  { value: 'Creston_IL', label: 'Creston, IL' },
  { value: 'Creston_NE', label: 'Creston, NE' },
  { value: 'Creston_CA', label: 'Creston, CA' },
  { value: 'Creston_WA', label: 'Creston, WA' },
  { value: 'Crestone_CO', label: 'Crestone, CO' },
  { value: 'Crestview_FL', label: 'Crestview, FL' },
  { value: 'Crestwood_KY', label: 'Crestwood, KY' },
  { value: 'Crestwood_IL', label: 'Crestwood, IL' },
  { value: 'Creswell_NC', label: 'Creswell, NC' },
  { value: 'Creswell_OR', label: 'Creswell, OR' },
  { value: 'Crete_IL', label: 'Crete, IL' },
  { value: 'Crete_NE', label: 'Crete, NE' },
  { value: 'Creve Coeur_IL', label: 'Creve Coeur, IL' },
  { value: 'Crewe_VA', label: 'Crewe, VA' },
  { value: 'Criders_VA', label: 'Criders, VA' },
  { value: 'Crimora_VA', label: 'Crimora, VA' },
  { value: 'Cripple Creek_VA', label: 'Cripple Creek, VA' },
  { value: 'Cripple Creek_CO', label: 'Cripple Creek, CO' },
  { value: 'Crisfield_MD', label: 'Crisfield, MD' },
  { value: 'Crittenden_KY', label: 'Crittenden, KY' },
  { value: 'Critz_VA', label: 'Critz, VA' },
  { value: 'Crivitz_WI', label: 'Crivitz, WI' },
  { value: 'Crocheron_MD', label: 'Crocheron, MD' },
  { value: 'Crocker_MO', label: 'Crocker, MO' },
  { value: 'Crockett_VA', label: 'Crockett, VA' },
  { value: 'Crockett_TX', label: 'Crockett, TX' },
  { value: 'Crockett_CA', label: 'Crockett, CA' },
  { value: 'Crockett Mills_TN', label: 'Crockett Mills, TN' },
  { value: 'Crocketts Bluff_AR', label: 'Crocketts Bluff, AR' },
  { value: 'Crofton_MD', label: 'Crofton, MD' },
  { value: 'Crofton_KY', label: 'Crofton, KY' },
  { value: 'Crofton_NE', label: 'Crofton, NE' },
  { value: 'Croghan_NY', label: 'Croghan, NY' },
  { value: 'Cromona_KY', label: 'Cromona, KY' },
  { value: 'Crompond_NY', label: 'Crompond, NY' },
  { value: 'Cromwell_CT', label: 'Cromwell, CT' },
  { value: 'Cromwell_KY', label: 'Cromwell, KY' },
  { value: 'Cromwell_IN', label: 'Cromwell, IN' },
  { value: 'Cromwell_IA', label: 'Cromwell, IA' },
  { value: 'Cromwell_MN', label: 'Cromwell, MN' },
  { value: 'Cromwell_OK', label: 'Cromwell, OK' },
  { value: 'Crook_CO', label: 'Crook, CO' },
  { value: 'Crooked Creek_AK', label: 'Crooked Creek, AK' },
  { value: 'Crooks_SD', label: 'Crooks, SD' },
  { value: 'Crookston_MN', label: 'Crookston, MN' },
  { value: 'Crookston_NE', label: 'Crookston, NE' },
  { value: 'Crooksville_OH', label: 'Crooksville, OH' },
  { value: 'Cropsey_IL', label: 'Cropsey, IL' },
  { value: 'Cropseyville_NY', label: 'Cropseyville, NY' },
  { value: 'Cropwell_AL', label: 'Cropwell, AL' },
  { value: 'Crosby_PA', label: 'Crosby, PA' },
  { value: 'Crosby_MS', label: 'Crosby, MS' },
  { value: 'Crosby_MN', label: 'Crosby, MN' },
  { value: 'Crosby_ND', label: 'Crosby, ND' },
  { value: 'Crosby_TX', label: 'Crosby, TX' },
  { value: 'Crosbyton_TX', label: 'Crosbyton, TX' },
  { value: 'Cross_SC', label: 'Cross, SC' },
  { value: 'Cross Anchor_SC', label: 'Cross Anchor, SC' },
  { value: 'Cross City_FL', label: 'Cross City, FL' },
  { value: 'Cross Fork_PA', label: 'Cross Fork, PA' },
  { value: 'Cross Hill_SC', label: 'Cross Hill, SC' },
  { value: 'Cross Junction_VA', label: 'Cross Junction, VA' },
  { value: 'Cross Plains_TN', label: 'Cross Plains, TN' },
  { value: 'Cross Plains_IN', label: 'Cross Plains, IN' },
  { value: 'Cross Plains_WI', label: 'Cross Plains, WI' },
  { value: 'Cross Plains_TX', label: 'Cross Plains, TX' },
  { value: 'Cross River_NY', label: 'Cross River, NY' },
  { value: 'Cross Timbers_MO', label: 'Cross Timbers, MO' },
  { value: 'Crossett_AR', label: 'Crossett, AR' },
  { value: 'Crosslake_MN', label: 'Crosslake, MN' },
  { value: 'Crossnore_NC', label: 'Crossnore, NC' },
  { value: 'Crossroads_NM', label: 'Crossroads, NM' },
  { value: 'Crossville_AL', label: 'Crossville, AL' },
  { value: 'Crossville_TN', label: 'Crossville, TN' },
  { value: 'Crossville_IL', label: 'Crossville, IL' },
  { value: 'Croswell_MI', label: 'Croswell, MI' },
  { value: 'Crothersville_IN', label: 'Crothersville, IN' },
  { value: 'Croton_OH', label: 'Croton, OH' },
  { value: 'Croton Falls_NY', label: 'Croton Falls, NY' },
  { value: 'Croton On Hudson_NY', label: 'Croton On Hudson, NY' },
  { value: 'Crouse_NC', label: 'Crouse, NC' },
  { value: 'Crow Agency_MT', label: 'Crow Agency, MT' },
  { value: 'Crowder_MS', label: 'Crowder, MS' },
  { value: 'Crowder_OK', label: 'Crowder, OK' },
  { value: 'Crowell_TX', label: 'Crowell, TX' },
  { value: 'Crowheart_WY', label: 'Crowheart, WY' },
  { value: 'Crowley_LA', label: 'Crowley, LA' },
  { value: 'Crowley_TX', label: 'Crowley, TX' },
  { value: 'Crowley_CO', label: 'Crowley, CO' },
  { value: 'Crown_PA', label: 'Crown, PA' },
  { value: 'Crown City_OH', label: 'Crown City, OH' },
  { value: 'Crown King_AZ', label: 'Crown King, AZ' },
  { value: 'Crown Point_NY', label: 'Crown Point, NY' },
  { value: 'Crown Point_IN', label: 'Crown Point, IN' },
  { value: 'Crownpoint_NM', label: 'Crownpoint, NM' },
  { value: 'Crownsville_MD', label: 'Crownsville, MD' },
  { value: 'Crows Landing_CA', label: 'Crows Landing, CA' },
  { value: 'Crowville_LA', label: 'Crowville, LA' },
  { value: 'Croydon_PA', label: 'Croydon, PA' },
  { value: 'Croydon_UT', label: 'Croydon, UT' },
  { value: 'Crozet_VA', label: 'Crozet, VA' },
  { value: 'Crozier_VA', label: 'Crozier, VA' },
  { value: 'Crucible_PA', label: 'Crucible, PA' },
  { value: 'Cruger_MS', label: 'Cruger, MS' },
  { value: 'Crum_WV', label: 'Crum, WV' },
  { value: 'Crum Lynne_PA', label: 'Crum Lynne, PA' },
  { value: 'Crump_TN', label: 'Crump, TN' },
  { value: 'Crumpler_NC', label: 'Crumpler, NC' },
  { value: 'Crumpton_MD', label: 'Crumpton, MD' },
  { value: 'Crumrod_AR', label: 'Crumrod, AR' },
  { value: 'Crystal_MI', label: 'Crystal, MI' },
  { value: 'Crystal_ND', label: 'Crystal, ND' },
  { value: 'Crystal Bay_NV', label: 'Crystal Bay, NV' },
  { value: 'Crystal Beach_FL', label: 'Crystal Beach, FL' },
  { value: 'Crystal City_MO', label: 'Crystal City, MO' },
  { value: 'Crystal City_TX', label: 'Crystal City, TX' },
  { value: 'Crystal Falls_MI', label: 'Crystal Falls, MI' },
  { value: 'Crystal Hill_VA', label: 'Crystal Hill, VA' },
  { value: 'Crystal Lake_IA', label: 'Crystal Lake, IA' },
  { value: 'Crystal Lake_IL', label: 'Crystal Lake, IL' },
  { value: 'Crystal River_FL', label: 'Crystal River, FL' },
  { value: 'Crystal Spring_PA', label: 'Crystal Spring, PA' },
  { value: 'Crystal Springs_MS', label: 'Crystal Springs, MS' },
  { value: 'Cub Run_KY', label: 'Cub Run, KY' },
  { value: 'Cuba_NY', label: 'Cuba, NY' },
  { value: 'Cuba_AL', label: 'Cuba, AL' },
  { value: 'Cuba_IL', label: 'Cuba, IL' },
  { value: 'Cuba_MO', label: 'Cuba, MO' },
  { value: 'Cuba_KS', label: 'Cuba, KS' },
  { value: 'Cuba_NM', label: 'Cuba, NM' },
  { value: 'Cuba City_WI', label: 'Cuba City, WI' },
  { value: 'Cubero_NM', label: 'Cubero, NM' },
  { value: 'Cucumber_WV', label: 'Cucumber, WV' },
  { value: 'Cudahy_WI', label: 'Cudahy, WI' },
  { value: 'Cuddebackville_NY', label: 'Cuddebackville, NY' },
  { value: 'Cuddy_PA', label: 'Cuddy, PA' },
  { value: 'Cuero_TX', label: 'Cuero, TX' },
  { value: 'Cuervo_NM', label: 'Cuervo, NM' },
  { value: 'Culbertson_MT', label: 'Culbertson, MT' },
  { value: 'Culbertson_NE', label: 'Culbertson, NE' },
  { value: 'Culdesac_ID', label: 'Culdesac, ID' },
  { value: 'Culebra_PR', label: 'Culebra, PR' },
  { value: 'Cullen_VA', label: 'Cullen, VA' },
  { value: 'Cullen_LA', label: 'Cullen, LA' },
  { value: 'Culleoka_TN', label: 'Culleoka, TN' },
  { value: 'Cullman_AL', label: 'Cullman, AL' },
  { value: 'Culloden_WV', label: 'Culloden, WV' },
  { value: 'Culloden_GA', label: 'Culloden, GA' },
  { value: 'Cullom_IL', label: 'Cullom, IL' },
  { value: 'Cullowhee_NC', label: 'Cullowhee, NC' },
  { value: 'Culpeper_VA', label: 'Culpeper, VA' },
  { value: 'Culver_IN', label: 'Culver, IN' },
  { value: 'Culver_OR', label: 'Culver, OR' },
  { value: 'Culver City_CA', label: 'Culver City, CA' },
  { value: 'Cumberland_RI', label: 'Cumberland, RI' },
  { value: 'Cumberland_MD', label: 'Cumberland, MD' },
  { value: 'Cumberland_VA', label: 'Cumberland, VA' },
  { value: 'Cumberland_NC', label: 'Cumberland, NC' },
  { value: 'Cumberland_KY', label: 'Cumberland, KY' },
  { value: 'Cumberland_OH', label: 'Cumberland, OH' },
  { value: 'Cumberland_IA', label: 'Cumberland, IA' },
  { value: 'Cumberland_WI', label: 'Cumberland, WI' },
  { value: 'Cumberland Center_ME', label: 'Cumberland Center, ME' },
  { value: 'Cumberland City_TN', label: 'Cumberland City, TN' },
  { value: 'Cumberland Foreside_ME', label: 'Cumberland Foreside, ME' },
  { value: 'Cumberland Furnace_TN', label: 'Cumberland Furnace, TN' },
  { value: 'Cumberland Gap_TN', label: 'Cumberland Gap, TN' },
  { value: 'Cumbola_PA', label: 'Cumbola, PA' },
  { value: 'Cumby_TX', label: 'Cumby, TX' },
  { value: 'Cummaquid_MA', label: 'Cummaquid, MA' },
  { value: 'Cumming_GA', label: 'Cumming, GA' },
  { value: 'Cumming_IA', label: 'Cumming, IA' },
  { value: 'Cummings_ND', label: 'Cummings, ND' },
  { value: 'Cummings_KS', label: 'Cummings, KS' },
  { value: 'Cummington_MA', label: 'Cummington, MA' },
  { value: 'Cuney_TX', label: 'Cuney, TX' },
  { value: 'Cunningham_TN', label: 'Cunningham, TN' },
  { value: 'Cunningham_KY', label: 'Cunningham, KY' },
  { value: 'Cunningham_KS', label: 'Cunningham, KS' },
  { value: 'Cunningham_TX', label: 'Cunningham, TX' },
  { value: 'Cupertino_CA', label: 'Cupertino, CA' },
  { value: 'Curlew_IA', label: 'Curlew, IA' },
  { value: 'Curlew_WA', label: 'Curlew, WA' },
  { value: 'Curran_MI', label: 'Curran, MI' },
  { value: 'Currie_NC', label: 'Currie, NC' },
  { value: 'Currie_MN', label: 'Currie, MN' },
  { value: 'Currituck_NC', label: 'Currituck, NC' },
  { value: 'Curryville_PA', label: 'Curryville, PA' },
  { value: 'Curryville_MO', label: 'Curryville, MO' },
  { value: 'Curtice_OH', label: 'Curtice, OH' },
  { value: 'Curtis_MI', label: 'Curtis, MI' },
  { value: 'Curtis_NE', label: 'Curtis, NE' },
  { value: 'Curtis_AR', label: 'Curtis, AR' },
  { value: 'Curtis_WA', label: 'Curtis, WA' },
  { value: 'Curtis Bay_MD', label: 'Curtis Bay, MD' },
  { value: 'Curtiss_WI', label: 'Curtiss, WI' },
  { value: 'Curwensville_PA', label: 'Curwensville, PA' },
  { value: 'Cushing_ME', label: 'Cushing, ME' },
  { value: 'Cushing_IA', label: 'Cushing, IA' },
  { value: 'Cushing_WI', label: 'Cushing, WI' },
  { value: 'Cushing_MN', label: 'Cushing, MN' },
  { value: 'Cushing_OK', label: 'Cushing, OK' },
  { value: 'Cushing_TX', label: 'Cushing, TX' },
  { value: 'Cushman_AR', label: 'Cushman, AR' },
  { value: 'Cusick_WA', label: 'Cusick, WA' },
  { value: 'Cusseta_GA', label: 'Cusseta, GA' },
  { value: 'Cusseta_AL', label: 'Cusseta, AL' },
  { value: 'Custar_OH', label: 'Custar, OH' },
  { value: 'Custer_KY', label: 'Custer, KY' },
  { value: 'Custer_MI', label: 'Custer, MI' },
  { value: 'Custer_WI', label: 'Custer, WI' },
  { value: 'Custer_SD', label: 'Custer, SD' },
  { value: 'Custer_MT', label: 'Custer, MT' },
  { value: 'Custer_WA', label: 'Custer, WA' },
  { value: 'Custer City_PA', label: 'Custer City, PA' },
  { value: 'Custer City_OK', label: 'Custer City, OK' },
  { value: 'Cut Bank_MT', label: 'Cut Bank, MT' },
  { value: 'Cut Off_LA', label: 'Cut Off, LA' },
  { value: 'Cutchogue_NY', label: 'Cutchogue, NY' },
  { value: 'Cuthbert_GA', label: 'Cuthbert, GA' },
  { value: 'Cutler_ME', label: 'Cutler, ME' },
  { value: 'Cutler_OH', label: 'Cutler, OH' },
  { value: 'Cutler_IN', label: 'Cutler, IN' },
  { value: 'Cutler_IL', label: 'Cutler, IL' },
  { value: 'Cutler_CA', label: 'Cutler, CA' },
  { value: 'Cuttingsville_VT', label: 'Cuttingsville, VT' },
  { value: 'Cuttyhunk_MA', label: 'Cuttyhunk, MA' },
  { value: 'Cuyahoga Falls_OH', label: 'Cuyahoga Falls, OH' },
  { value: 'Cyclone_PA', label: 'Cyclone, PA' },
  { value: 'Cyclone_WV', label: 'Cyclone, WV' },
  { value: 'Cygnet_OH', label: 'Cygnet, OH' },
  { value: 'Cylinder_IA', label: 'Cylinder, IA' },
  { value: 'Cynthiana_KY', label: 'Cynthiana, KY' },
  { value: 'Cynthiana_OH', label: 'Cynthiana, OH' },
  { value: 'Cynthiana_IN', label: 'Cynthiana, IN' },
  { value: 'Cypress_FL', label: 'Cypress, FL' },
  { value: 'Cypress_IL', label: 'Cypress, IL' },
  { value: 'Cypress_TX', label: 'Cypress, TX' },
  { value: 'Cypress_CA', label: 'Cypress, CA' },
  { value: 'Cypress Inn_TN', label: 'Cypress Inn, TN' },
  { value: 'Cyril_OK', label: 'Cyril, OK' },
  { value: 'Cyrus_MN', label: 'Cyrus, MN' },
  { value: 'D Hanis_TX', label: 'D Hanis, TX' },
  { value: 'D Lo_MS', label: 'D Lo, MS' },
  { value: 'Dacoma_OK', label: 'Dacoma, OK' },
  { value: 'Dacono_CO', label: 'Dacono, CO' },
  { value: 'Dacula_GA', label: 'Dacula, GA' },
  { value: 'Dade City_FL', label: 'Dade City, FL' },
  { value: 'Dadeville_AL', label: 'Dadeville, AL' },
  { value: 'Dadeville_MO', label: 'Dadeville, MO' },
  { value: 'Dafter_MI', label: 'Dafter, MI' },
  { value: 'Daggett_MI', label: 'Daggett, MI' },
  { value: 'Daggett_CA', label: 'Daggett, CA' },
  { value: 'Dagmar_MT', label: 'Dagmar, MT' },
  { value: 'Dagsboro_DE', label: 'Dagsboro, DE' },
  { value: 'Dagus Mines_PA', label: 'Dagus Mines, PA' },
  { value: 'Dahinda_IL', label: 'Dahinda, IL' },
  { value: 'Dahlen_ND', label: 'Dahlen, ND' },
  { value: 'Dahlgren_VA', label: 'Dahlgren, VA' },
  { value: 'Dahlgren_IL', label: 'Dahlgren, IL' },
  { value: 'Dahlonega_GA', label: 'Dahlonega, GA' },
  { value: 'Dailey_WV', label: 'Dailey, WV' },
  { value: 'Daingerfield_TX', label: 'Daingerfield, TX' },
  { value: 'Dairy_OR', label: 'Dairy, OR' },
  { value: 'Daisetta_TX', label: 'Daisetta, TX' },
  { value: 'Daisy_GA', label: 'Daisy, GA' },
  { value: 'Daisy_MO', label: 'Daisy, MO' },
  { value: 'Daisy_OK', label: 'Daisy, OK' },
  { value: 'Daisytown_PA', label: 'Daisytown, PA' },
  { value: 'Dakota_MN', label: 'Dakota, MN' },
  { value: 'Dakota_IL', label: 'Dakota, IL' },
  { value: 'Dakota City_IA', label: 'Dakota City, IA' },
  { value: 'Dakota City_NE', label: 'Dakota City, NE' },
  { value: 'Dalbo_MN', label: 'Dalbo, MN' },
  { value: 'Dale_NY', label: 'Dale, NY' },
  { value: 'Dale_IN', label: 'Dale, IN' },
  { value: 'Dale_WI', label: 'Dale, WI' },
  { value: 'Dale_IL', label: 'Dale, IL' },
  { value: 'Dale_TX', label: 'Dale, TX' },
  { value: 'Daleville_VA', label: 'Daleville, VA' },
  { value: 'Daleville_AL', label: 'Daleville, AL' },
  { value: 'Daleville_MS', label: 'Daleville, MS' },
  { value: 'Daleville_IN', label: 'Daleville, IN' },
  { value: 'Dalhart_TX', label: 'Dalhart, TX' },
  { value: 'Dallas_PA', label: 'Dallas, PA' },
  { value: 'Dallas_WV', label: 'Dallas, WV' },
  { value: 'Dallas_NC', label: 'Dallas, NC' },
  { value: 'Dallas_GA', label: 'Dallas, GA' },
  { value: 'Dallas_WI', label: 'Dallas, WI' },
  { value: 'Dallas_SD', label: 'Dallas, SD' },
  { value: 'Dallas_TX', label: 'Dallas, TX' },
  { value: 'Dallas_OR', label: 'Dallas, OR' },
  { value: 'Dallas Center_IA', label: 'Dallas Center, IA' },
  { value: 'Dallas City_IL', label: 'Dallas City, IL' },
  { value: 'Dallastown_PA', label: 'Dallastown, PA' },
  { value: 'Dallesport_WA', label: 'Dallesport, WA' },
  { value: 'Dalmatia_PA', label: 'Dalmatia, PA' },
  { value: 'Dalton_MA', label: 'Dalton, MA' },
  { value: 'Dalton_NY', label: 'Dalton, NY' },
  { value: 'Dalton_PA', label: 'Dalton, PA' },
  { value: 'Dalton_GA', label: 'Dalton, GA' },
  { value: 'Dalton_OH', label: 'Dalton, OH' },
  { value: 'Dalton_WI', label: 'Dalton, WI' },
  { value: 'Dalton_MN', label: 'Dalton, MN' },
  { value: 'Dalton_MO', label: 'Dalton, MO' },
  { value: 'Dalton_NE', label: 'Dalton, NE' },
  { value: 'Dalton City_IL', label: 'Dalton City, IL' },
  { value: 'Daly City_CA', label: 'Daly City, CA' },
  { value: 'Dalzell_SC', label: 'Dalzell, SC' },
  { value: 'Dalzell_IL', label: 'Dalzell, IL' },
  { value: 'Damar_KS', label: 'Damar, KS' },
  { value: 'Damariscotta_ME', label: 'Damariscotta, ME' },
  { value: 'Damascus_PA', label: 'Damascus, PA' },
  { value: 'Damascus_MD', label: 'Damascus, MD' },
  { value: 'Damascus_VA', label: 'Damascus, VA' },
  { value: 'Damascus_GA', label: 'Damascus, GA' },
  { value: 'Damascus_OH', label: 'Damascus, OH' },
  { value: 'Damascus_AR', label: 'Damascus, AR' },
  { value: 'Damascus_OR', label: 'Damascus, OR' },
  { value: 'Dameron_MD', label: 'Dameron, MD' },
  { value: 'Dammeron Valley_UT', label: 'Dammeron Valley, UT' },
  { value: 'Damon_TX', label: 'Damon, TX' },
  { value: 'Dana_KY', label: 'Dana, KY' },
  { value: 'Dana_IN', label: 'Dana, IN' },
  { value: 'Dana_IA', label: 'Dana, IA' },
  { value: 'Dana_IL', label: 'Dana, IL' },
  { value: 'Dana Point_CA', label: 'Dana Point, CA' },
  { value: 'Danbury_NH', label: 'Danbury, NH' },
  { value: 'Danbury_CT', label: 'Danbury, CT' },
  { value: 'Danbury_NC', label: 'Danbury, NC' },
  { value: 'Danbury_IA', label: 'Danbury, IA' },
  { value: 'Danbury_WI', label: 'Danbury, WI' },
  { value: 'Danbury_NE', label: 'Danbury, NE' },
  { value: 'Danbury_TX', label: 'Danbury, TX' },
  { value: 'Danby_VT', label: 'Danby, VT' },
  { value: 'Danciger_TX', label: 'Danciger, TX' },
  { value: 'Dandridge_TN', label: 'Dandridge, TN' },
  { value: 'Dane_WI', label: 'Dane, WI' },
  { value: 'Danese_WV', label: 'Danese, WV' },
  { value: 'Danevang_TX', label: 'Danevang, TX' },
  { value: 'Danforth_ME', label: 'Danforth, ME' },
  { value: 'Danforth_IL', label: 'Danforth, IL' },
  { value: 'Dania_FL', label: 'Dania, FL' },
  { value: 'Daniel_WY', label: 'Daniel, WY' },
  { value: 'Daniels_WV', label: 'Daniels, WV' },
  { value: 'Danielson_CT', label: 'Danielson, CT' },
  { value: 'Danielsville_PA', label: 'Danielsville, PA' },
  { value: 'Danielsville_GA', label: 'Danielsville, GA' },
  { value: 'Dannebrog_NE', label: 'Dannebrog, NE' },
  { value: 'Dannemora_NY', label: 'Dannemora, NY' },
  { value: 'Dansville_NY', label: 'Dansville, NY' },
  { value: 'Dansville_MI', label: 'Dansville, MI' },
  { value: 'Dante_VA', label: 'Dante, VA' },
  { value: 'Dante_SD', label: 'Dante, SD' },
  { value: 'Danube_MN', label: 'Danube, MN' },
  { value: 'Danvers_MA', label: 'Danvers, MA' },
  { value: 'Danvers_MN', label: 'Danvers, MN' },
  { value: 'Danvers_IL', label: 'Danvers, IL' },
  { value: 'Danville_NH', label: 'Danville, NH' },
  { value: 'Danville_VT', label: 'Danville, VT' },
  { value: 'Danville_PA', label: 'Danville, PA' },
  { value: 'Danville_VA', label: 'Danville, VA' },
  { value: 'Danville_WV', label: 'Danville, WV' },
  { value: 'Danville_GA', label: 'Danville, GA' },
  { value: 'Danville_AL', label: 'Danville, AL' },
  { value: 'Danville_KY', label: 'Danville, KY' },
  { value: 'Danville_OH', label: 'Danville, OH' },
  { value: 'Danville_IN', label: 'Danville, IN' },
  { value: 'Danville_IA', label: 'Danville, IA' },
  { value: 'Danville_IL', label: 'Danville, IL' },
  { value: 'Danville_KS', label: 'Danville, KS' },
  { value: 'Danville_AR', label: 'Danville, AR' },
  { value: 'Danville_CA', label: 'Danville, CA' },
  { value: 'Danville_WA', label: 'Danville, WA' },
  { value: 'Daphne_AL', label: 'Daphne, AL' },
  { value: 'Darby_PA', label: 'Darby, PA' },
  { value: 'Darby_MT', label: 'Darby, MT' },
  { value: 'Dardanelle_AR', label: 'Dardanelle, AR' },
  { value: 'Darden_TN', label: 'Darden, TN' },
  { value: 'Darfur_MN', label: 'Darfur, MN' },
  { value: 'Darien_CT', label: 'Darien, CT' },
  { value: 'Darien_GA', label: 'Darien, GA' },
  { value: 'Darien_WI', label: 'Darien, WI' },
  { value: 'Darien_IL', label: 'Darien, IL' },
  { value: 'Darien Center_NY', label: 'Darien Center, NY' },
  { value: 'Darling_MS', label: 'Darling, MS' },
  { value: 'Darlington_PA', label: 'Darlington, PA' },
  { value: 'Darlington_MD', label: 'Darlington, MD' },
  { value: 'Darlington_SC', label: 'Darlington, SC' },
  { value: 'Darlington_IN', label: 'Darlington, IN' },
  { value: 'Darlington_WI', label: 'Darlington, WI' },
  { value: 'Darlington_MO', label: 'Darlington, MO' },
  { value: 'Darragh_PA', label: 'Darragh, PA' },
  { value: 'Darrington_WA', label: 'Darrington, WA' },
  { value: 'Darrouzett_TX', label: 'Darrouzett, TX' },
  { value: 'Darrow_LA', label: 'Darrow, LA' },
  { value: 'Darwin_MN', label: 'Darwin, MN' },
  { value: 'Darwin_CA', label: 'Darwin, CA' },
  { value: 'Dassel_MN', label: 'Dassel, MN' },
  { value: 'Dateland_AZ', label: 'Dateland, AZ' },
  { value: 'Datil_NM', label: 'Datil, NM' },
  { value: 'Datto_AR', label: 'Datto, AR' },
  { value: 'Daufuskie Island_SC', label: 'Daufuskie Island, SC' },
  { value: 'Dauphin_PA', label: 'Dauphin, PA' },
  { value: 'Dauphin Island_AL', label: 'Dauphin Island, AL' },
  { value: 'Davenport_NY', label: 'Davenport, NY' },
  { value: 'Davenport_VA', label: 'Davenport, VA' },
  { value: 'Davenport_FL', label: 'Davenport, FL' },
  { value: 'Davenport_IA', label: 'Davenport, IA' },
  { value: 'Davenport_ND', label: 'Davenport, ND' },
  { value: 'Davenport_NE', label: 'Davenport, NE' },
  { value: 'Davenport_OK', label: 'Davenport, OK' },
  { value: 'Davenport_CA', label: 'Davenport, CA' },
  { value: 'Davenport_WA', label: 'Davenport, WA' },
  { value: 'Davenport Center_NY', label: 'Davenport Center, NY' },
  { value: 'Davey_NE', label: 'Davey, NE' },
  { value: 'David_KY', label: 'David, KY' },
  { value: 'David City_NE', label: 'David City, NE' },
  { value: 'Davidson_NC', label: 'Davidson, NC' },
  { value: 'Davidson_OK', label: 'Davidson, OK' },
  { value: 'Davidsonville_MD', label: 'Davidsonville, MD' },
  { value: 'Davidsville_PA', label: 'Davidsville, PA' },
  { value: 'Davilla_TX', label: 'Davilla, TX' },
  { value: 'Davin_WV', label: 'Davin, WV' },
  { value: 'Davis_WV', label: 'Davis, WV' },
  { value: 'Davis_NC', label: 'Davis, NC' },
  { value: 'Davis_SD', label: 'Davis, SD' },
  { value: 'Davis_IL', label: 'Davis, IL' },
  { value: 'Davis_OK', label: 'Davis, OK' },
  { value: 'Davis_CA', label: 'Davis, CA' },
  { value: 'Davis City_IA', label: 'Davis City, IA' },
  { value: 'Davis Creek_CA', label: 'Davis Creek, CA' },
  { value: 'Davis Junction_IL', label: 'Davis Junction, IL' },
  { value: 'Davisboro_GA', label: 'Davisboro, GA' },
  { value: 'Davisburg_MI', label: 'Davisburg, MI' },
  { value: 'Davison_MI', label: 'Davison, MI' },
  { value: 'Daviston_AL', label: 'Daviston, AL' },
  { value: 'Davisville_WV', label: 'Davisville, WV' },
  { value: 'Davisville_MO', label: 'Davisville, MO' },
  { value: 'Davy_WV', label: 'Davy, WV' },
  { value: 'Dawes_WV', label: 'Dawes, WV' },
  { value: 'Dawn_MO', label: 'Dawn, MO' },
  { value: 'Dawn_TX', label: 'Dawn, TX' },
  { value: 'Dawson_PA', label: 'Dawson, PA' },
  { value: 'Dawson_AL', label: 'Dawson, AL' },
  { value: 'Dawson_GA', label: 'Dawson, GA' },
  { value: 'Dawson_IA', label: 'Dawson, IA' },
  { value: 'Dawson_MN', label: 'Dawson, MN' },
  { value: 'Dawson_ND', label: 'Dawson, ND' },
  { value: 'Dawson_IL', label: 'Dawson, IL' },
  { value: 'Dawson_NE', label: 'Dawson, NE' },
  { value: 'Dawson_TX', label: 'Dawson, TX' },
  { value: 'Dawson Springs_KY', label: 'Dawson Springs, KY' },
  { value: 'Dawsonville_GA', label: 'Dawsonville, GA' },
  { value: 'Day_FL', label: 'Day, FL' },
  { value: 'Dayhoit_KY', label: 'Dayhoit, KY' },
  { value: 'Daykin_NE', label: 'Daykin, NE' },
  { value: 'Days Creek_OR', label: 'Days Creek, OR' },
  { value: 'Dayton_NJ', label: 'Dayton, NJ' },
  { value: 'Dayton_NY', label: 'Dayton, NY' },
  { value: 'Dayton_PA', label: 'Dayton, PA' },
  { value: 'Dayton_MD', label: 'Dayton, MD' },
  { value: 'Dayton_VA', label: 'Dayton, VA' },
  { value: 'Dayton_TN', label: 'Dayton, TN' },
  { value: 'Dayton_KY', label: 'Dayton, KY' },
  { value: 'Dayton_OH', label: 'Dayton, OH' },
  { value: 'Dayton_IN', label: 'Dayton, IN' },
  { value: 'Dayton_IA', label: 'Dayton, IA' },
  { value: 'Dayton_MN', label: 'Dayton, MN' },
  { value: 'Dayton_MT', label: 'Dayton, MT' },
  { value: 'Dayton_TX', label: 'Dayton, TX' },
  { value: 'Dayton_WY', label: 'Dayton, WY' },
  { value: 'Dayton_ID', label: 'Dayton, ID' },
  { value: 'Dayton_NV', label: 'Dayton, NV' },
  { value: 'Dayton_OR', label: 'Dayton, OR' },
  { value: 'Dayton_WA', label: 'Dayton, WA' },
  { value: 'Daytona Beach_FL', label: 'Daytona Beach, FL' },
  { value: 'Dayville_CT', label: 'Dayville, CT' },
  { value: 'Dayville_OR', label: 'Dayville, OR' },
  { value: 'Dazey_ND', label: 'Dazey, ND' },
  { value: 'De Beque_CO', label: 'De Beque, CO' },
  { value: 'De Berry_TX', label: 'De Berry, TX' },
  { value: 'De Borgia_MT', label: 'De Borgia, MT' },
  { value: 'De Graff_OH', label: 'De Graff, OH' },
  { value: 'De Kalb_MS', label: 'De Kalb, MS' },
  { value: 'De Kalb_MO', label: 'De Kalb, MO' },
  { value: 'De Kalb_TX', label: 'De Kalb, TX' },
  { value: 'De Kalb Junction_NY', label: 'De Kalb Junction, NY' },
  { value: 'De Lancey_PA', label: 'De Lancey, PA' },
  { value: 'De Land_IL', label: 'De Land, IL' },
  { value: 'De Leon_TX', label: 'De Leon, TX' },
  { value: 'De Leon Springs_FL', label: 'De Leon Springs, FL' },
  { value: 'De Mossville_KY', label: 'De Mossville, KY' },
  { value: 'De Pere_WI', label: 'De Pere, WI' },
  { value: 'De Peyster_NY', label: 'De Peyster, NY' },
  { value: 'De Queen_AR', label: 'De Queen, AR' },
  { value: 'De Ruyter_NY', label: 'De Ruyter, NY' },
  { value: 'De Smet_SD', label: 'De Smet, SD' },
  { value: 'De Soto_GA', label: 'De Soto, GA' },
  { value: 'De Soto_IA', label: 'De Soto, IA' },
  { value: 'De Soto_WI', label: 'De Soto, WI' },
  { value: 'De Soto_IL', label: 'De Soto, IL' },
  { value: 'De Soto_MO', label: 'De Soto, MO' },
  { value: 'De Soto_KS', label: 'De Soto, KS' },
  { value: 'De Tour Village_MI', label: 'De Tour Village, MI' },
  { value: 'De Valls Bluff_AR', label: 'De Valls Bluff, AR' },
  { value: 'De Witt_IA', label: 'De Witt, IA' },
  { value: 'De Witt_MO', label: 'De Witt, MO' },
  { value: 'De Witt_NE', label: 'De Witt, NE' },
  { value: 'De Witt_AR', label: 'De Witt, AR' },
  { value: 'Deadwood_SD', label: 'Deadwood, SD' },
  { value: 'Deadwood_OR', label: 'Deadwood, OR' },
  { value: 'Deal_NJ', label: 'Deal, NJ' },
  { value: 'Deal Island_MD', label: 'Deal Island, MD' },
  { value: 'Deale_MD', label: 'Deale, MD' },
  { value: 'Deane_KY', label: 'Deane, KY' },
  { value: 'Deansboro_NY', label: 'Deansboro, NY' },
  { value: 'Deanville_TX', label: 'Deanville, TX' },
  { value: 'Dearborn_MI', label: 'Dearborn, MI' },
  { value: 'Dearborn_MO', label: 'Dearborn, MO' },
  { value: 'Dearborn Heights_MI', label: 'Dearborn Heights, MI' },
  { value: 'Dearing_GA', label: 'Dearing, GA' },
  { value: 'Dearing_KS', label: 'Dearing, KS' },
  { value: 'Deary_ID', label: 'Deary, ID' },
  { value: 'Death Valley_CA', label: 'Death Valley, CA' },
  { value: 'Deatsville_AL', label: 'Deatsville, AL' },
  { value: 'Deaver_WY', label: 'Deaver, WY' },
  { value: 'Debary_FL', label: 'Debary, FL' },
  { value: 'Debord_KY', label: 'Debord, KY' },
  { value: 'Decatur_GA', label: 'Decatur, GA' },
  { value: 'Decatur_AL', label: 'Decatur, AL' },
  { value: 'Decatur_TN', label: 'Decatur, TN' },
  { value: 'Decatur_MS', label: 'Decatur, MS' },
  { value: 'Decatur_OH', label: 'Decatur, OH' },
  { value: 'Decatur_IN', label: 'Decatur, IN' },
  { value: 'Decatur_MI', label: 'Decatur, MI' },
  { value: 'Decatur_IA', label: 'Decatur, IA' },
  { value: 'Decatur_IL', label: 'Decatur, IL' },
  { value: 'Decatur_NE', label: 'Decatur, NE' },
  { value: 'Decatur_AR', label: 'Decatur, AR' },
  { value: 'Decatur_TX', label: 'Decatur, TX' },
  { value: 'Decaturville_TN', label: 'Decaturville, TN' },
  { value: 'Decherd_TN', label: 'Decherd, TN' },
  { value: 'Decker_IN', label: 'Decker, IN' },
  { value: 'Decker_MI', label: 'Decker, MI' },
  { value: 'Decker_MT', label: 'Decker, MT' },
  { value: 'Deckerville_MI', label: 'Deckerville, MI' },
  { value: 'Declo_ID', label: 'Declo, ID' },
  { value: 'Decorah_IA', label: 'Decorah, IA' },
  { value: 'Dedham_MA', label: 'Dedham, MA' },
  { value: 'Dedham_IA', label: 'Dedham, IA' },
  { value: 'Deep Gap_NC', label: 'Deep Gap, NC' },
  { value: 'Deep River_CT', label: 'Deep River, CT' },
  { value: 'Deep River_IA', label: 'Deep River, IA' },
  { value: 'Deep Run_NC', label: 'Deep Run, NC' },
  { value: 'Deep Water_WV', label: 'Deep Water, WV' },
  { value: 'Deepwater_NJ', label: 'Deepwater, NJ' },
  { value: 'Deepwater_MO', label: 'Deepwater, MO' },
  { value: 'Deer_AR', label: 'Deer, AR' },
  { value: 'Deer Creek_MN', label: 'Deer Creek, MN' },
  { value: 'Deer Creek_IL', label: 'Deer Creek, IL' },
  { value: 'Deer Creek_OK', label: 'Deer Creek, OK' },
  { value: 'Deer Grove_IL', label: 'Deer Grove, IL' },
  { value: 'Deer Harbor_WA', label: 'Deer Harbor, WA' },
  { value: 'Deer Island_OR', label: 'Deer Island, OR' },
  { value: 'Deer Isle_ME', label: 'Deer Isle, ME' },
  { value: 'Deer Lodge_TN', label: 'Deer Lodge, TN' },
  { value: 'Deer Lodge_MT', label: 'Deer Lodge, MT' },
  { value: 'Deer Park_NY', label: 'Deer Park, NY' },
  { value: 'Deer Park_AL', label: 'Deer Park, AL' },
  { value: 'Deer Park_WI', label: 'Deer Park, WI' },
  { value: 'Deer Park_TX', label: 'Deer Park, TX' },
  { value: 'Deer Park_CA', label: 'Deer Park, CA' },
  { value: 'Deer Park_WA', label: 'Deer Park, WA' },
  { value: 'Deer River_MN', label: 'Deer River, MN' },
  { value: 'Deer Trail_CO', label: 'Deer Trail, CO' },
  { value: 'Deerbrook_WI', label: 'Deerbrook, WI' },
  { value: 'Deerfield_MA', label: 'Deerfield, MA' },
  { value: 'Deerfield_NH', label: 'Deerfield, NH' },
  { value: 'Deerfield_VA', label: 'Deerfield, VA' },
  { value: 'Deerfield_OH', label: 'Deerfield, OH' },
  { value: 'Deerfield_MI', label: 'Deerfield, MI' },
  { value: 'Deerfield_WI', label: 'Deerfield, WI' },
  { value: 'Deerfield_IL', label: 'Deerfield, IL' },
  { value: 'Deerfield_MO', label: 'Deerfield, MO' },
  { value: 'Deerfield_KS', label: 'Deerfield, KS' },
  { value: 'Deerfield Beach_FL', label: 'Deerfield Beach, FL' },
  { value: 'Deering_ND', label: 'Deering, ND' },
  { value: 'Deering_MO', label: 'Deering, MO' },
  { value: 'Deering_AK', label: 'Deering, AK' },
  { value: 'Deersville_OH', label: 'Deersville, OH' },
  { value: 'Deerton_MI', label: 'Deerton, MI' },
  { value: 'Deerwood_MN', label: 'Deerwood, MN' },
  { value: 'Deeth_NV', label: 'Deeth, NV' },
  { value: 'Deferiet_NY', label: 'Deferiet, NY' },
  { value: 'Defiance_PA', label: 'Defiance, PA' },
  { value: 'Defiance_OH', label: 'Defiance, OH' },
  { value: 'Defiance_IA', label: 'Defiance, IA' },
  { value: 'Defiance_MO', label: 'Defiance, MO' },
  { value: 'Deford_MI', label: 'Deford, MI' },
  { value: 'Deforest_WI', label: 'Deforest, WI' },
  { value: 'Defuniak Springs_FL', label: 'Defuniak Springs, FL' },
  { value: 'Dekalb_IL', label: 'Dekalb, IL' },
  { value: 'Del Mar_CA', label: 'Del Mar, CA' },
  { value: 'Del Norte_CO', label: 'Del Norte, CO' },
  { value: 'Del Rey_CA', label: 'Del Rey, CA' },
  { value: 'Del Rio_TN', label: 'Del Rio, TN' },
  { value: 'Del Rio_TX', label: 'Del Rio, TX' },
  { value: 'Del Valle_TX', label: 'Del Valle, TX' },
  { value: 'Delafield_WI', label: 'Delafield, WI' },
  { value: 'Delancey_NY', label: 'Delancey, NY' },
  { value: 'Deland_FL', label: 'Deland, FL' },
  { value: 'Delano_PA', label: 'Delano, PA' },
  { value: 'Delano_TN', label: 'Delano, TN' },
  { value: 'Delano_MN', label: 'Delano, MN' },
  { value: 'Delano_CA', label: 'Delano, CA' },
  { value: 'Delanson_NY', label: 'Delanson, NY' },
  { value: 'Delaplaine_AR', label: 'Delaplaine, AR' },
  { value: 'Delaplane_VA', label: 'Delaplane, VA' },
  { value: 'Delavan_WI', label: 'Delavan, WI' },
  { value: 'Delavan_MN', label: 'Delavan, MN' },
  { value: 'Delavan_IL', label: 'Delavan, IL' },
  { value: 'Delaware_NJ', label: 'Delaware, NJ' },
  { value: 'Delaware_OH', label: 'Delaware, OH' },
  { value: 'Delaware_IA', label: 'Delaware, IA' },
  { value: 'Delaware_AR', label: 'Delaware, AR' },
  { value: 'Delaware_OK', label: 'Delaware, OK' },
  { value: 'Delaware City_DE', label: 'Delaware City, DE' },
  { value: 'Delaware Water Gap_PA', label: 'Delaware Water Gap, PA' },
  { value: 'Delbarton_WV', label: 'Delbarton, WV' },
  { value: 'Delcambre_LA', label: 'Delcambre, LA' },
  { value: 'Delco_NC', label: 'Delco, NC' },
  { value: 'Delevan_NY', label: 'Delevan, NY' },
  { value: 'Delhi_NY', label: 'Delhi, NY' },
  { value: 'Delhi_IA', label: 'Delhi, IA' },
  { value: 'Delhi_LA', label: 'Delhi, LA' },
  { value: 'Delhi_CA', label: 'Delhi, CA' },
  { value: 'Delia_KS', label: 'Delia, KS' },
  { value: 'Delight_AR', label: 'Delight, AR' },
  { value: 'Dell_MT', label: 'Dell, MT' },
  { value: 'Dell_AR', label: 'Dell, AR' },
  { value: 'Dell City_TX', label: 'Dell City, TX' },
  { value: 'Dell Rapids_SD', label: 'Dell Rapids, SD' },
  { value: 'Dellrose_TN', label: 'Dellrose, TN' },
  { value: 'Dellroy_OH', label: 'Dellroy, OH' },
  { value: 'Delmar_NY', label: 'Delmar, NY' },
  { value: 'Delmar_DE', label: 'Delmar, DE' },
  { value: 'Delmar_MD', label: 'Delmar, MD' },
  { value: 'Delmar_IA', label: 'Delmar, IA' },
  { value: 'Delmita_TX', label: 'Delmita, TX' },
  { value: 'Delmont_NJ', label: 'Delmont, NJ' },
  { value: 'Delmont_PA', label: 'Delmont, PA' },
  { value: 'Delmont_SD', label: 'Delmont, SD' },
  { value: 'Deloit_IA', label: 'Deloit, IA' },
  { value: 'Delong_IN', label: 'Delong, IN' },
  { value: 'Delphi_IN', label: 'Delphi, IN' },
  { value: 'Delphi Falls_NY', label: 'Delphi Falls, NY' },
  { value: 'Delphia_KY', label: 'Delphia, KY' },
  { value: 'Delphos_OH', label: 'Delphos, OH' },
  { value: 'Delphos_KS', label: 'Delphos, KS' },
  { value: 'Delray_WV', label: 'Delray, WV' },
  { value: 'Delray Beach_FL', label: 'Delray Beach, FL' },
  { value: 'Delta_PA', label: 'Delta, PA' },
  { value: 'Delta_AL', label: 'Delta, AL' },
  { value: 'Delta_OH', label: 'Delta, OH' },
  { value: 'Delta_IA', label: 'Delta, IA' },
  { value: 'Delta_MO', label: 'Delta, MO' },
  { value: 'Delta_LA', label: 'Delta, LA' },
  { value: 'Delta_CO', label: 'Delta, CO' },
  { value: 'Delta_UT', label: 'Delta, UT' },
  { value: 'Delta City_MS', label: 'Delta City, MS' },
  { value: 'Delta Junction_AK', label: 'Delta Junction, AK' },
  { value: 'Deltaville_VA', label: 'Deltaville, VA' },
  { value: 'Delton_MI', label: 'Delton, MI' },
  { value: 'Deltona_FL', label: 'Deltona, FL' },
  { value: 'Dema_KY', label: 'Dema, KY' },
  { value: 'Demarest_NJ', label: 'Demarest, NJ' },
  { value: 'Deming_NM', label: 'Deming, NM' },
  { value: 'Deming_WA', label: 'Deming, WA' },
  { value: 'Demopolis_AL', label: 'Demopolis, AL' },
  { value: 'Demorest_GA', label: 'Demorest, GA' },
  { value: 'Demotte_IN', label: 'Demotte, IN' },
  { value: 'Denair_CA', label: 'Denair, CA' },
  { value: 'Denali National Park_AK', label: 'Denali National Park, AK' },
  { value: 'Denbo_PA', label: 'Denbo, PA' },
  { value: 'Dendron_VA', label: 'Dendron, VA' },
  { value: 'Denham Springs_LA', label: 'Denham Springs, LA' },
  { value: 'Denhoff_ND', label: 'Denhoff, ND' },
  { value: 'Denio_NV', label: 'Denio, NV' },
  { value: 'Denison_IA', label: 'Denison, IA' },
  { value: 'Denison_KS', label: 'Denison, KS' },
  { value: 'Denison_TX', label: 'Denison, TX' },
  { value: 'Denmark_ME', label: 'Denmark, ME' },
  { value: 'Denmark_SC', label: 'Denmark, SC' },
  { value: 'Denmark_TN', label: 'Denmark, TN' },
  { value: 'Denmark_IA', label: 'Denmark, IA' },
  { value: 'Denmark_WI', label: 'Denmark, WI' },
  { value: 'Dennard_AR', label: 'Dennard, AR' },
  { value: 'Dennehotso_AZ', label: 'Dennehotso, AZ' },
  { value: 'Dennis_MA', label: 'Dennis, MA' },
  { value: 'Dennis_MS', label: 'Dennis, MS' },
  { value: 'Dennis_KS', label: 'Dennis, KS' },
  { value: 'Dennis_TX', label: 'Dennis, TX' },
  { value: 'Dennis Port_MA', label: 'Dennis Port, MA' },
  { value: 'Dennison_OH', label: 'Dennison, OH' },
  { value: 'Dennison_MN', label: 'Dennison, MN' },
  { value: 'Dennison_IL', label: 'Dennison, IL' },
  { value: 'Denniston_KY', label: 'Denniston, KY' },
  { value: 'Dennysville_ME', label: 'Dennysville, ME' },
  { value: 'Dent_MN', label: 'Dent, MN' },
  { value: 'Denton_MD', label: 'Denton, MD' },
  { value: 'Denton_NC', label: 'Denton, NC' },
  { value: 'Denton_GA', label: 'Denton, GA' },
  { value: 'Denton_KY', label: 'Denton, KY' },
  { value: 'Denton_MT', label: 'Denton, MT' },
  { value: 'Denton_KS', label: 'Denton, KS' },
  { value: 'Denton_NE', label: 'Denton, NE' },
  { value: 'Denton_TX', label: 'Denton, TX' },
  { value: 'Denver_NY', label: 'Denver, NY' },
  { value: 'Denver_PA', label: 'Denver, PA' },
  { value: 'Denver_NC', label: 'Denver, NC' },
  { value: 'Denver_IN', label: 'Denver, IN' },
  { value: 'Denver_IA', label: 'Denver, IA' },
  { value: 'Denver_MO', label: 'Denver, MO' },
  { value: 'Denver_CO', label: 'Denver, CO' },
  { value: 'Denver City_TX', label: 'Denver City, TX' },
  { value: 'Denville_NJ', label: 'Denville, NJ' },
  { value: 'Depauville_NY', label: 'Depauville, NY' },
  { value: 'Depauw_IN', label: 'Depauw, IN' },
  { value: 'Depew_NY', label: 'Depew, NY' },
  { value: 'Depew_OK', label: 'Depew, OK' },
  { value: 'Depoe Bay_OR', label: 'Depoe Bay, OR' },
  { value: 'Deport_TX', label: 'Deport, TX' },
  { value: 'Deposit_NY', label: 'Deposit, NY' },
  { value: 'Depue_IL', label: 'Depue, IL' },
  { value: 'Deputy_IN', label: 'Deputy, IN' },
  { value: 'Dequincy_LA', label: 'Dequincy, LA' },
  { value: 'Derby_VT', label: 'Derby, VT' },
  { value: 'Derby_CT', label: 'Derby, CT' },
  { value: 'Derby_NY', label: 'Derby, NY' },
  { value: 'Derby_OH', label: 'Derby, OH' },
  { value: 'Derby_IN', label: 'Derby, IN' },
  { value: 'Derby_IA', label: 'Derby, IA' },
  { value: 'Derby_KS', label: 'Derby, KS' },
  { value: 'Derby Line_VT', label: 'Derby Line, VT' },
  { value: 'Deridder_LA', label: 'Deridder, LA' },
  { value: 'Derma_MS', label: 'Derma, MS' },
  { value: 'Dermott_AR', label: 'Dermott, AR' },
  { value: 'Derrick City_PA', label: 'Derrick City, PA' },
  { value: 'Derry_NH', label: 'Derry, NH' },
  { value: 'Derry_PA', label: 'Derry, PA' },
  { value: 'Derry_NM', label: 'Derry, NM' },
  { value: 'Derwent_OH', label: 'Derwent, OH' },
  { value: 'Derwood_MD', label: 'Derwood, MD' },
  { value: 'Des Allemands_LA', label: 'Des Allemands, LA' },
  { value: 'Des Arc_MO', label: 'Des Arc, MO' },
  { value: 'Des Arc_AR', label: 'Des Arc, AR' },
  { value: 'Des Lacs_ND', label: 'Des Lacs, ND' },
  { value: 'Des Moines_IA', label: 'Des Moines, IA' },
  { value: 'Des Moines_NM', label: 'Des Moines, NM' },
  { value: 'Des Plaines_IL', label: 'Des Plaines, IL' },
  { value: 'Descanso_CA', label: 'Descanso, CA' },
  { value: 'Desdemona_TX', label: 'Desdemona, TX' },
  { value: 'Desert Center_CA', label: 'Desert Center, CA' },
  { value: 'Desert Hot Springs_CA', label: 'Desert Hot Springs, CA' },
  { value: 'Desha_AR', label: 'Desha, AR' },
  { value: 'Deshler_OH', label: 'Deshler, OH' },
  { value: 'Deshler_NE', label: 'Deshler, NE' },
  { value: 'Desmet_ID', label: 'Desmet, ID' },
  { value: 'Desoto_TX', label: 'Desoto, TX' },
  { value: 'Destin_FL', label: 'Destin, FL' },
  { value: 'Destrehan_LA', label: 'Destrehan, LA' },
  { value: 'Detroit_ME', label: 'Detroit, ME' },
  { value: 'Detroit_AL', label: 'Detroit, AL' },
  { value: 'Detroit_MI', label: 'Detroit, MI' },
  { value: 'Detroit_TX', label: 'Detroit, TX' },
  { value: 'Detroit_OR', label: 'Detroit, OR' },
  { value: 'Detroit Lakes_MN', label: 'Detroit Lakes, MN' },
  { value: 'Devens_MA', label: 'Devens, MA' },
  { value: 'Devers_TX', label: 'Devers, TX' },
  { value: 'Deville_LA', label: 'Deville, LA' },
  { value: 'Devils Elbow_MO', label: 'Devils Elbow, MO' },
  { value: 'Devils Lake_ND', label: 'Devils Lake, ND' },
  { value: 'Devils Tower_WY', label: 'Devils Tower, WY' },
  { value: 'Devine_TX', label: 'Devine, TX' },
  { value: 'Devol_OK', label: 'Devol, OK' },
  { value: 'Devon_PA', label: 'Devon, PA' },
  { value: 'Dewar_IA', label: 'Dewar, IA' },
  { value: 'Dewar_OK', label: 'Dewar, OK' },
  { value: 'Dewart_PA', label: 'Dewart, PA' },
  { value: 'Deweese_NE', label: 'Deweese, NE' },
  { value: 'Dewey_IL', label: 'Dewey, IL' },
  { value: 'Dewey_OK', label: 'Dewey, OK' },
  { value: 'Dewey_AZ', label: 'Dewey, AZ' },
  { value: 'Deweyville_TX', label: 'Deweyville, TX' },
  { value: 'Deweyville_UT', label: 'Deweyville, UT' },
  { value: 'Dewitt_VA', label: 'Dewitt, VA' },
  { value: 'Dewitt_MI', label: 'Dewitt, MI' },
  { value: 'Dewitt_IL', label: 'Dewitt, IL' },
  { value: 'Dewittville_NY', label: 'Dewittville, NY' },
  { value: 'Dewy Rose_GA', label: 'Dewy Rose, GA' },
  { value: 'Dexter_ME', label: 'Dexter, ME' },
  { value: 'Dexter_NY', label: 'Dexter, NY' },
  { value: 'Dexter_GA', label: 'Dexter, GA' },
  { value: 'Dexter_KY', label: 'Dexter, KY' },
  { value: 'Dexter_MI', label: 'Dexter, MI' },
  { value: 'Dexter_IA', label: 'Dexter, IA' },
  { value: 'Dexter_MN', label: 'Dexter, MN' },
  { value: 'Dexter_MO', label: 'Dexter, MO' },
  { value: 'Dexter_KS', label: 'Dexter, KS' },
  { value: 'Dexter_NM', label: 'Dexter, NM' },
  { value: 'Dexter_OR', label: 'Dexter, OR' },
  { value: 'Dexter City_OH', label: 'Dexter City, OH' },
  { value: 'Diablo_CA', label: 'Diablo, CA' },
  { value: 'Diagonal_IA', label: 'Diagonal, IA' },
  { value: 'Diamond_OH', label: 'Diamond, OH' },
  { value: 'Diamond_MO', label: 'Diamond, MO' },
  { value: 'Diamond_OR', label: 'Diamond, OR' },
  { value: 'Diamond Bar_CA', label: 'Diamond Bar, CA' },
  { value: 'Diamond City_AR', label: 'Diamond City, AR' },
  { value: 'Diamond Point_NY', label: 'Diamond Point, NY' },
  { value: 'Diamond Springs_CA', label: 'Diamond Springs, CA' },
  { value: 'Diamondhead_MS', label: 'Diamondhead, MS' },
  { value: 'Diamondville_WY', label: 'Diamondville, WY' },
  { value: 'Diana_WV', label: 'Diana, WV' },
  { value: 'Diana_TX', label: 'Diana, TX' },
  { value: 'Dibble_OK', label: 'Dibble, OK' },
  { value: 'Diberville_MS', label: 'Diberville, MS' },
  { value: 'Diboll_TX', label: 'Diboll, TX' },
  { value: 'Dickens_IA', label: 'Dickens, IA' },
  { value: 'Dickens_NE', label: 'Dickens, NE' },
  { value: 'Dickens_TX', label: 'Dickens, TX' },
  { value: 'Dickerson_MD', label: 'Dickerson, MD' },
  { value: 'Dickerson Run_PA', label: 'Dickerson Run, PA' },
  { value: 'Dickey_ND', label: 'Dickey, ND' },
  { value: 'Dickeyville_WI', label: 'Dickeyville, WI' },
  { value: 'Dickinson_AL', label: 'Dickinson, AL' },
  { value: 'Dickinson_ND', label: 'Dickinson, ND' },
  { value: 'Dickinson_TX', label: 'Dickinson, TX' },
  { value: 'Dickinson Center_NY', label: 'Dickinson Center, NY' },
  { value: 'Dickson_TN', label: 'Dickson, TN' },
  { value: 'Dierks_AR', label: 'Dierks, AR' },
  { value: 'Dieterich_IL', label: 'Dieterich, IL' },
  { value: 'Dietrich_ID', label: 'Dietrich, ID' },
  { value: 'Diggs_VA', label: 'Diggs, VA' },
  { value: 'Dighton_MA', label: 'Dighton, MA' },
  { value: 'Dighton_KS', label: 'Dighton, KS' },
  { value: 'Dike_IA', label: 'Dike, IA' },
  { value: 'Dike_TX', label: 'Dike, TX' },
  { value: 'Dill City_OK', label: 'Dill City, OK' },
  { value: 'Dillard_GA', label: 'Dillard, GA' },
  { value: 'Dillard_OR', label: 'Dillard, OR' },
  { value: 'Dille_WV', label: 'Dille, WV' },
  { value: 'Diller_NE', label: 'Diller, NE' },
  { value: 'Dilley_TX', label: 'Dilley, TX' },
  { value: 'Dilliner_PA', label: 'Dilliner, PA' },
  { value: 'Dillingham_AK', label: 'Dillingham, AK' },
  { value: 'Dillon_SC', label: 'Dillon, SC' },
  { value: 'Dillon_MT', label: 'Dillon, MT' },
  { value: 'Dillon_CO', label: 'Dillon, CO' },
  { value: 'Dillon Beach_CA', label: 'Dillon Beach, CA' },
  { value: 'Dillonvale_OH', label: 'Dillonvale, OH' },
  { value: 'Dillsboro_NC', label: 'Dillsboro, NC' },
  { value: 'Dillsboro_IN', label: 'Dillsboro, IN' },
  { value: 'Dillsburg_PA', label: 'Dillsburg, PA' },
  { value: 'Dilltown_PA', label: 'Dilltown, PA' },
  { value: 'Dillwyn_VA', label: 'Dillwyn, VA' },
  { value: 'Dilworth_MN', label: 'Dilworth, MN' },
  { value: 'Dime Box_TX', label: 'Dime Box, TX' },
  { value: 'Dimmitt_TX', label: 'Dimmitt, TX' },
  { value: 'Dimock_PA', label: 'Dimock, PA' },
  { value: 'Dimock_SD', label: 'Dimock, SD' },
  { value: 'Dimondale_MI', label: 'Dimondale, MI' },
  { value: 'Dingess_WV', label: 'Dingess, WV' },
  { value: 'Dingle_ID', label: 'Dingle, ID' },
  { value: 'Dingmans Ferry_PA', label: 'Dingmans Ferry, PA' },
  { value: 'Dinosaur_CO', label: 'Dinosaur, CO' },
  { value: 'Dinuba_CA', label: 'Dinuba, CA' },
  { value: 'Dinwiddie_VA', label: 'Dinwiddie, VA' },
  { value: 'Discovery Bay_CA', label: 'Discovery Bay, CA' },
  { value: 'Disney_OK', label: 'Disney, OK' },
  { value: 'Disputanta_VA', label: 'Disputanta, VA' },
  { value: 'Distant_PA', label: 'Distant, PA' },
  { value: 'District Heights_MD', label: 'District Heights, MD' },
  { value: 'Dittmer_MO', label: 'Dittmer, MO' },
  { value: 'Divernon_IL', label: 'Divernon, IL' },
  { value: 'Divide_MT', label: 'Divide, MT' },
  { value: 'Divide_CO', label: 'Divide, CO' },
  { value: 'Dix_IL', label: 'Dix, IL' },
  { value: 'Dix_NE', label: 'Dix, NE' },
  { value: 'Dixfield_ME', label: 'Dixfield, ME' },
  { value: 'Dixie_WV', label: 'Dixie, WV' },
  { value: 'Dixie_GA', label: 'Dixie, GA' },
  { value: 'Dixie_WA', label: 'Dixie, WA' },
  { value: 'Dixmont_ME', label: 'Dixmont, ME' },
  { value: 'Dixon_KY', label: 'Dixon, KY' },
  { value: 'Dixon_IA', label: 'Dixon, IA' },
  { value: 'Dixon_MT', label: 'Dixon, MT' },
  { value: 'Dixon_IL', label: 'Dixon, IL' },
  { value: 'Dixon_MO', label: 'Dixon, MO' },
  { value: 'Dixon_NE', label: 'Dixon, NE' },
  { value: 'Dixon_WY', label: 'Dixon, WY' },
  { value: 'Dixon_NM', label: 'Dixon, NM' },
  { value: 'Dixon_CA', label: 'Dixon, CA' },
  { value: 'Dixon Springs_TN', label: 'Dixon Springs, TN' },
  { value: 'Dixons Mills_AL', label: 'Dixons Mills, AL' },
  { value: 'Dixonville_PA', label: 'Dixonville, PA' },
  { value: 'Dobbins_CA', label: 'Dobbins, CA' },
  { value: 'Dobbs Ferry_NY', label: 'Dobbs Ferry, NY' },
  { value: 'Dobson_NC', label: 'Dobson, NC' },
  { value: 'Docena_AL', label: 'Docena, AL' },
  { value: 'Dodd City_TX', label: 'Dodd City, TX' },
  { value: 'Doddridge_AR', label: 'Doddridge, AR' },
  { value: 'Doddsville_MS', label: 'Doddsville, MS' },
  { value: 'Dodge_WI', label: 'Dodge, WI' },
  { value: 'Dodge_ND', label: 'Dodge, ND' },
  { value: 'Dodge_NE', label: 'Dodge, NE' },
  { value: 'Dodge_TX', label: 'Dodge, TX' },
  { value: 'Dodge Center_MN', label: 'Dodge Center, MN' },
  { value: 'Dodge City_KS', label: 'Dodge City, KS' },
  { value: 'Dodgeville_MI', label: 'Dodgeville, MI' },
  { value: 'Dodgeville_WI', label: 'Dodgeville, WI' },
  { value: 'Dodson_MT', label: 'Dodson, MT' },
  { value: 'Dodson_LA', label: 'Dodson, LA' },
  { value: 'Dodson_TX', label: 'Dodson, TX' },
  { value: 'Doe Hill_VA', label: 'Doe Hill, VA' },
  { value: 'Doe Run_MO', label: 'Doe Run, MO' },
  { value: 'Doerun_GA', label: 'Doerun, GA' },
  { value: 'Dola_OH', label: 'Dola, OH' },
  { value: 'Dolan Springs_AZ', label: 'Dolan Springs, AZ' },
  { value: 'Doland_SD', label: 'Doland, SD' },
  { value: 'Dolgeville_NY', label: 'Dolgeville, NY' },
  { value: 'Dollar Bay_MI', label: 'Dollar Bay, MI' },
  { value: 'Dolliver_IA', label: 'Dolliver, IA' },
  { value: 'Dolomite_AL', label: 'Dolomite, AL' },
  { value: 'Dolores_CO', label: 'Dolores, CO' },
  { value: 'Dolph_AR', label: 'Dolph, AR' },
  { value: 'Dolphin_VA', label: 'Dolphin, VA' },
  { value: 'Dolton_IL', label: 'Dolton, IL' },
  { value: 'Donahue_IA', label: 'Donahue, IA' },
  { value: 'Donald_OR', label: 'Donald, OR' },
  { value: 'Donalds_SC', label: 'Donalds, SC' },
  { value: 'Donaldson_IN', label: 'Donaldson, IN' },
  { value: 'Donaldson_MN', label: 'Donaldson, MN' },
  { value: 'Donaldson_AR', label: 'Donaldson, AR' },
  { value: 'Donaldsonville_LA', label: 'Donaldsonville, LA' },
  { value: 'Donalsonville_GA', label: 'Donalsonville, GA' },
  { value: 'Donegal_PA', label: 'Donegal, PA' },
  { value: 'Dongola_IL', label: 'Dongola, IL' },
  { value: 'Donie_TX', label: 'Donie, TX' },
  { value: 'Doniphan_MO', label: 'Doniphan, MO' },
  { value: 'Doniphan_NE', label: 'Doniphan, NE' },
  { value: 'Donna_TX', label: 'Donna, TX' },
  { value: 'Donnellson_IA', label: 'Donnellson, IA' },
  { value: 'Donnellson_IL', label: 'Donnellson, IL' },
  { value: 'Donnelly_MN', label: 'Donnelly, MN' },
  { value: 'Donnelly_ID', label: 'Donnelly, ID' },
  { value: 'Donnelsville_OH', label: 'Donnelsville, OH' },
  { value: 'Donner_LA', label: 'Donner, LA' },
  { value: 'Donnybrook_ND', label: 'Donnybrook, ND' },
  { value: 'Donora_PA', label: 'Donora, PA' },
  { value: 'Donovan_IL', label: 'Donovan, IL' },
  { value: 'Doole_TX', label: 'Doole, TX' },
  { value: 'Doon_IA', label: 'Doon, IA' },
  { value: 'Dora_AL', label: 'Dora, AL' },
  { value: 'Dora_MO', label: 'Dora, MO' },
  { value: 'Dora_NM', label: 'Dora, NM' },
  { value: 'Dorado_PR', label: 'Dorado, PR' },
  { value: 'Doran_VA', label: 'Doran, VA' },
  { value: 'Dorchester_MA', label: 'Dorchester, MA' },
  { value: 'Dorchester_NJ', label: 'Dorchester, NJ' },
  { value: 'Dorchester_SC', label: 'Dorchester, SC' },
  { value: 'Dorchester_IA', label: 'Dorchester, IA' },
  { value: 'Dorchester_WI', label: 'Dorchester, WI' },
  { value: 'Dorchester_NE', label: 'Dorchester, NE' },
  { value: 'Dorchester Center_MA', label: 'Dorchester Center, MA' },
  { value: 'Dorena_OR', label: 'Dorena, OR' },
  { value: 'Dornsife_PA', label: 'Dornsife, PA' },
  { value: 'Dorothy_NJ', label: 'Dorothy, NJ' },
  { value: 'Dorothy_WV', label: 'Dorothy, WV' },
  { value: 'Dorr_MI', label: 'Dorr, MI' },
  { value: 'Dorrance_KS', label: 'Dorrance, KS' },
  { value: 'Dorris_CA', label: 'Dorris, CA' },
  { value: 'Dorset_VT', label: 'Dorset, VT' },
  { value: 'Dorset_OH', label: 'Dorset, OH' },
  { value: 'Dorsey_IL', label: 'Dorsey, IL' },
  { value: 'Dos Palos_CA', label: 'Dos Palos, CA' },
  { value: 'Dos Rios_CA', label: 'Dos Rios, CA' },
  { value: 'Doss_TX', label: 'Doss, TX' },
  { value: 'Doswell_VA', label: 'Doswell, VA' },
  { value: 'Dothan_AL', label: 'Dothan, AL' },
  { value: 'Doty_WA', label: 'Doty, WA' },
  { value: 'Double Springs_AL', label: 'Double Springs, AL' },
  { value: 'Doucette_TX', label: 'Doucette, TX' },
  { value: 'Douds_IA', label: 'Douds, IA' },
  { value: 'Dougherty_IA', label: 'Dougherty, IA' },
  { value: 'Dougherty_OK', label: 'Dougherty, OK' },
  { value: 'Dougherty_TX', label: 'Dougherty, TX' },
  { value: 'Douglas_MA', label: 'Douglas, MA' },
  { value: 'Douglas_GA', label: 'Douglas, GA' },
  { value: 'Douglas_MI', label: 'Douglas, MI' },
  { value: 'Douglas_ND', label: 'Douglas, ND' },
  { value: 'Douglas_NE', label: 'Douglas, NE' },
  { value: 'Douglas_OK', label: 'Douglas, OK' },
  { value: 'Douglas_WY', label: 'Douglas, WY' },
  { value: 'Douglas_AZ', label: 'Douglas, AZ' },
  { value: 'Douglas_AK', label: 'Douglas, AK' },
  { value: 'Douglas City_CA', label: 'Douglas City, CA' },
  { value: 'Douglass_KS', label: 'Douglass, KS' },
  { value: 'Douglass_TX', label: 'Douglass, TX' },
  { value: 'Douglassville_PA', label: 'Douglassville, PA' },
  { value: 'Douglassville_TX', label: 'Douglassville, TX' },
  { value: 'Douglasville_GA', label: 'Douglasville, GA' },
  { value: 'Dousman_WI', label: 'Dousman, WI' },
  { value: 'Dove Creek_CO', label: 'Dove Creek, CO' },
  { value: 'Dover_MA', label: 'Dover, MA' },
  { value: 'Dover_NH', label: 'Dover, NH' },
  { value: 'Dover_NJ', label: 'Dover, NJ' },
  { value: 'Dover_PA', label: 'Dover, PA' },
  { value: 'Dover_DE', label: 'Dover, DE' },
  { value: 'Dover_NC', label: 'Dover, NC' },
  { value: 'Dover_FL', label: 'Dover, FL' },
  { value: 'Dover_TN', label: 'Dover, TN' },
  { value: 'Dover_KY', label: 'Dover, KY' },
  { value: 'Dover_OH', label: 'Dover, OH' },
  { value: 'Dover_MN', label: 'Dover, MN' },
  { value: 'Dover_IL', label: 'Dover, IL' },
  { value: 'Dover_MO', label: 'Dover, MO' },
  { value: 'Dover_AR', label: 'Dover, AR' },
  { value: 'Dover_OK', label: 'Dover, OK' },
  { value: 'Dover_ID', label: 'Dover, ID' },
  { value: 'Dover Afb_DE', label: 'Dover Afb, DE' },
  { value: 'Dover Foxcroft_ME', label: 'Dover Foxcroft, ME' },
  { value: 'Dover Plains_NY', label: 'Dover Plains, NY' },
  { value: 'Dovray_MN', label: 'Dovray, MN' },
  { value: 'Dow_IL', label: 'Dow, IL' },
  { value: 'Dow City_IA', label: 'Dow City, IA' },
  { value: 'Dowagiac_MI', label: 'Dowagiac, MI' },
  { value: 'Dowell_MD', label: 'Dowell, MD' },
  { value: 'Dowell_IL', label: 'Dowell, IL' },
  { value: 'Dowelltown_TN', label: 'Dowelltown, TN' },
  { value: 'Dowling_MI', label: 'Dowling, MI' },
  { value: 'Downers Grove_IL', label: 'Downers Grove, IL' },
  { value: 'Downey_ID', label: 'Downey, ID' },
  { value: 'Downey_CA', label: 'Downey, CA' },
  { value: 'Downieville_CA', label: 'Downieville, CA' },
  { value: 'Downing_WI', label: 'Downing, WI' },
  { value: 'Downing_MO', label: 'Downing, MO' },
  { value: 'Downingtown_PA', label: 'Downingtown, PA' },
  { value: 'Downs_IL', label: 'Downs, IL' },
  { value: 'Downs_KS', label: 'Downs, KS' },
  { value: 'Downsville_NY', label: 'Downsville, NY' },
  { value: 'Downsville_LA', label: 'Downsville, LA' },
  { value: 'Dows_IA', label: 'Dows, IA' },
  { value: 'Doyle_TN', label: 'Doyle, TN' },
  { value: 'Doyle_CA', label: 'Doyle, CA' },
  { value: 'Doylesburg_PA', label: 'Doylesburg, PA' },
  { value: 'Doylestown_PA', label: 'Doylestown, PA' },
  { value: 'Doylestown_OH', label: 'Doylestown, OH' },
  { value: 'Doylestown_WI', label: 'Doylestown, WI' },
  { value: 'Doyline_LA', label: 'Doyline, LA' },
  { value: 'Dozier_AL', label: 'Dozier, AL' },
  { value: 'Dracut_MA', label: 'Dracut, MA' },
  { value: 'Dragoon_AZ', label: 'Dragoon, AZ' },
  { value: 'Drain_OR', label: 'Drain, OR' },
  { value: 'Drake_ND', label: 'Drake, ND' },
  { value: 'Drake_CO', label: 'Drake, CO' },
  { value: 'Drakes Branch_VA', label: 'Drakes Branch, VA' },
  { value: 'Drakesboro_KY', label: 'Drakesboro, KY' },
  { value: 'Drakesville_IA', label: 'Drakesville, IA' },
  { value: 'Draper_VA', label: 'Draper, VA' },
  { value: 'Draper_SD', label: 'Draper, SD' },
  { value: 'Draper_UT', label: 'Draper, UT' },
  { value: 'Drasco_AR', label: 'Drasco, AR' },
  { value: 'Dravosburg_PA', label: 'Dravosburg, PA' },
  { value: 'Drayden_MD', label: 'Drayden, MD' },
  { value: 'Drayton_SC', label: 'Drayton, SC' },
  { value: 'Drayton_ND', label: 'Drayton, ND' },
  { value: 'Drennen_WV', label: 'Drennen, WV' },
  { value: 'Dresden_ME', label: 'Dresden, ME' },
  { value: 'Dresden_NY', label: 'Dresden, NY' },
  { value: 'Dresden_TN', label: 'Dresden, TN' },
  { value: 'Dresden_OH', label: 'Dresden, OH' },
  { value: 'Dresden_KS', label: 'Dresden, KS' },
  { value: 'Dresher_PA', label: 'Dresher, PA' },
  { value: 'Dresser_WI', label: 'Dresser, WI' },
  { value: 'Drew_MS', label: 'Drew, MS' },
  { value: 'Drewryville_VA', label: 'Drewryville, VA' },
  { value: 'Drewsey_OR', label: 'Drewsey, OR' },
  { value: 'Drewsville_NH', label: 'Drewsville, NH' },
  { value: 'Drexel_NC', label: 'Drexel, NC' },
  { value: 'Drexel_MO', label: 'Drexel, MO' },
  { value: 'Drexel Hill_PA', label: 'Drexel Hill, PA' },
  { value: 'Drift_KY', label: 'Drift, KY' },
  { value: 'Drifting_PA', label: 'Drifting, PA' },
  { value: 'Drifton_PA', label: 'Drifton, PA' },
  { value: 'Driftwood_PA', label: 'Driftwood, PA' },
  { value: 'Driftwood_TX', label: 'Driftwood, TX' },
  { value: 'Driggs_ID', label: 'Driggs, ID' },
  { value: 'Dripping Springs_TX', label: 'Dripping Springs, TX' },
  { value: 'Driscoll_ND', label: 'Driscoll, ND' },
  { value: 'Driscoll_TX', label: 'Driscoll, TX' },
  { value: 'Driver_AR', label: 'Driver, AR' },
  { value: 'Drummond_WI', label: 'Drummond, WI' },
  { value: 'Drummond_MT', label: 'Drummond, MT' },
  { value: 'Drummond_OK', label: 'Drummond, OK' },
  { value: 'Drummond Island_MI', label: 'Drummond Island, MI' },
  { value: 'Drummonds_TN', label: 'Drummonds, TN' },
  { value: 'Drumore_PA', label: 'Drumore, PA' },
  { value: 'Drumright_OK', label: 'Drumright, OK' },
  { value: 'Drums_PA', label: 'Drums, PA' },
  { value: 'Drury_MA', label: 'Drury, MA' },
  { value: 'Drury_MO', label: 'Drury, MO' },
  { value: 'Dry Branch_GA', label: 'Dry Branch, GA' },
  { value: 'Dry Creek_WV', label: 'Dry Creek, WV' },
  { value: 'Dry Creek_LA', label: 'Dry Creek, LA' },
  { value: 'Dry Fork_VA', label: 'Dry Fork, VA' },
  { value: 'Dry Prong_LA', label: 'Dry Prong, LA' },
  { value: 'Dry Ridge_KY', label: 'Dry Ridge, KY' },
  { value: 'Dry Run_PA', label: 'Dry Run, PA' },
  { value: 'Drybranch_WV', label: 'Drybranch, WV' },
  { value: 'Dryden_NY', label: 'Dryden, NY' },
  { value: 'Dryden_VA', label: 'Dryden, VA' },
  { value: 'Dryden_MI', label: 'Dryden, MI' },
  { value: 'Dryden_TX', label: 'Dryden, TX' },
  { value: 'Dryden_WA', label: 'Dryden, WA' },
  { value: 'Dryfork_WV', label: 'Dryfork, WV' },
  { value: 'Drytown_CA', label: 'Drytown, CA' },
  { value: 'Du Bois_PA', label: 'Du Bois, PA' },
  { value: 'Du Bois_IL', label: 'Du Bois, IL' },
  { value: 'Du Bois_NE', label: 'Du Bois, NE' },
  { value: 'Du Pont_GA', label: 'Du Pont, GA' },
  { value: 'Du Quoin_IL', label: 'Du Quoin, IL' },
  { value: 'Duanesburg_NY', label: 'Duanesburg, NY' },
  { value: 'Duarte_CA', label: 'Duarte, CA' },
  { value: 'Dubach_LA', label: 'Dubach, LA' },
  { value: 'Dubberly_LA', label: 'Dubberly, LA' },
  { value: 'Dublin_NH', label: 'Dublin, NH' },
  { value: 'Dublin_PA', label: 'Dublin, PA' },
  { value: 'Dublin_VA', label: 'Dublin, VA' },
  { value: 'Dublin_NC', label: 'Dublin, NC' },
  { value: 'Dublin_GA', label: 'Dublin, GA' },
  { value: 'Dublin_OH', label: 'Dublin, OH' },
  { value: 'Dublin_IN', label: 'Dublin, IN' },
  { value: 'Dublin_TX', label: 'Dublin, TX' },
  { value: 'Dublin_CA', label: 'Dublin, CA' },
  { value: 'Dubois_IN', label: 'Dubois, IN' },
  { value: 'Dubois_WY', label: 'Dubois, WY' },
  { value: 'Dubois_ID', label: 'Dubois, ID' },
  { value: 'Dubuque_IA', label: 'Dubuque, IA' },
  { value: 'Duchesne_UT', label: 'Duchesne, UT' },
  { value: 'Duck_WV', label: 'Duck, WV' },
  { value: 'Duck Creek Village_UT', label: 'Duck Creek Village, UT' },
  { value: 'Duck Hill_MS', label: 'Duck Hill, MS' },
  { value: 'Duck River_TN', label: 'Duck River, TN' },
  { value: 'Ducktown_TN', label: 'Ducktown, TN' },
  { value: 'Duckwater_NV', label: 'Duckwater, NV' },
  { value: 'Ducor_CA', label: 'Ducor, CA' },
  { value: 'Dudley_MA', label: 'Dudley, MA' },
  { value: 'Dudley_PA', label: 'Dudley, PA' },
  { value: 'Dudley_NC', label: 'Dudley, NC' },
  { value: 'Dudley_GA', label: 'Dudley, GA' },
  { value: 'Dudley_MO', label: 'Dudley, MO' },
  { value: 'Due West_SC', label: 'Due West, SC' },
  { value: 'Duenweg_MO', label: 'Duenweg, MO' },
  { value: 'Duff_TN', label: 'Duff, TN' },
  { value: 'Duffield_VA', label: 'Duffield, VA' },
  { value: 'Dufur_OR', label: 'Dufur, OR' },
  { value: 'Dugger_IN', label: 'Dugger, IN' },
  { value: 'Dugspur_VA', label: 'Dugspur, VA' },
  { value: 'Dugway_UT', label: 'Dugway, UT' },
  { value: 'Duke_MO', label: 'Duke, MO' },
  { value: 'Duke_OK', label: 'Duke, OK' },
  { value: 'Duke Center_PA', label: 'Duke Center, PA' },
  { value: 'Dukedom_TN', label: 'Dukedom, TN' },
  { value: 'Dulac_LA', label: 'Dulac, LA' },
  { value: 'Dulce_NM', label: 'Dulce, NM' },
  { value: 'Duluth_GA', label: 'Duluth, GA' },
  { value: 'Duluth_MN', label: 'Duluth, MN' },
  { value: 'Dulzura_CA', label: 'Dulzura, CA' },
  { value: 'Dumas_MS', label: 'Dumas, MS' },
  { value: 'Dumas_AR', label: 'Dumas, AR' },
  { value: 'Dumas_TX', label: 'Dumas, TX' },
  { value: 'Dumfries_VA', label: 'Dumfries, VA' },
  { value: 'Dumont_NJ', label: 'Dumont, NJ' },
  { value: 'Dumont_IA', label: 'Dumont, IA' },
  { value: 'Dumont_MN', label: 'Dumont, MN' },
  { value: 'Dumont_CO', label: 'Dumont, CO' },
  { value: 'Dunbar_PA', label: 'Dunbar, PA' },
  { value: 'Dunbar_WV', label: 'Dunbar, WV' },
  { value: 'Dunbar_WI', label: 'Dunbar, WI' },
  { value: 'Dunbar_NE', label: 'Dunbar, NE' },
  { value: 'Dunbarton_NH', label: 'Dunbarton, NH' },
  { value: 'Dunbridge_OH', label: 'Dunbridge, OH' },
  { value: 'Duncan_SC', label: 'Duncan, SC' },
  { value: 'Duncan_MS', label: 'Duncan, MS' },
  { value: 'Duncan_NE', label: 'Duncan, NE' },
  { value: 'Duncan_OK', label: 'Duncan, OK' },
  { value: 'Duncan_AZ', label: 'Duncan, AZ' },
  { value: 'Duncan Falls_OH', label: 'Duncan Falls, OH' },
  { value: 'Duncannon_PA', label: 'Duncannon, PA' },
  { value: 'Duncans Mills_CA', label: 'Duncans Mills, CA' },
  { value: 'Duncansville_PA', label: 'Duncansville, PA' },
  { value: 'Duncanville_AL', label: 'Duncanville, AL' },
  { value: 'Duncanville_TX', label: 'Duncanville, TX' },
  { value: 'Duncombe_IA', label: 'Duncombe, IA' },
  { value: 'Dundalk_MD', label: 'Dundalk, MD' },
  { value: 'Dundas_VA', label: 'Dundas, VA' },
  { value: 'Dundas_MN', label: 'Dundas, MN' },
  { value: 'Dundas_IL', label: 'Dundas, IL' },
  { value: 'Dundee_NY', label: 'Dundee, NY' },
  { value: 'Dundee_FL', label: 'Dundee, FL' },
  { value: 'Dundee_MS', label: 'Dundee, MS' },
  { value: 'Dundee_KY', label: 'Dundee, KY' },
  { value: 'Dundee_OH', label: 'Dundee, OH' },
  { value: 'Dundee_MI', label: 'Dundee, MI' },
  { value: 'Dundee_IA', label: 'Dundee, IA' },
  { value: 'Dundee_IL', label: 'Dundee, IL' },
  { value: 'Dundee_OR', label: 'Dundee, OR' },
  { value: 'Dunedin_FL', label: 'Dunedin, FL' },
  { value: 'Dunellen_NJ', label: 'Dunellen, NJ' },
  { value: 'Dunfermline_IL', label: 'Dunfermline, IL' },
  { value: 'Dungannon_VA', label: 'Dungannon, VA' },
  { value: 'Dunkerton_IA', label: 'Dunkerton, IA' },
  { value: 'Dunkirk_NY', label: 'Dunkirk, NY' },
  { value: 'Dunkirk_MD', label: 'Dunkirk, MD' },
  { value: 'Dunkirk_OH', label: 'Dunkirk, OH' },
  { value: 'Dunkirk_IN', label: 'Dunkirk, IN' },
  { value: 'Dunlap_TN', label: 'Dunlap, TN' },
  { value: 'Dunlap_IA', label: 'Dunlap, IA' },
  { value: 'Dunlap_IL', label: 'Dunlap, IL' },
  { value: 'Dunlap_CA', label: 'Dunlap, CA' },
  { value: 'Dunlevy_PA', label: 'Dunlevy, PA' },
  { value: 'Dunlo_PA', label: 'Dunlo, PA' },
  { value: 'Dunlow_WV', label: 'Dunlow, WV' },
  { value: 'Dunmor_KY', label: 'Dunmor, KY' },
  { value: 'Dunmore_WV', label: 'Dunmore, WV' },
  { value: 'Dunn_NC', label: 'Dunn, NC' },
  { value: 'Dunn Center_ND', label: 'Dunn Center, ND' },
  { value: 'Dunn Loring_VA', label: 'Dunn Loring, VA' },
  { value: 'Dunnegan_MO', label: 'Dunnegan, MO' },
  { value: 'Dunnell_MN', label: 'Dunnell, MN' },
  { value: 'Dunnellon_FL', label: 'Dunnellon, FL' },
  { value: 'Dunnigan_CA', label: 'Dunnigan, CA' },
  { value: 'Dunning_NE', label: 'Dunning, NE' },
  { value: 'Dunnsville_VA', label: 'Dunnsville, VA' },
  { value: 'Dunnville_KY', label: 'Dunnville, KY' },
  { value: 'Dunreith_IN', label: 'Dunreith, IN' },
  { value: 'Dunseith_ND', label: 'Dunseith, ND' },
  { value: 'Dunsmuir_CA', label: 'Dunsmuir, CA' },
  { value: 'Dunstable_MA', label: 'Dunstable, MA' },
  { value: 'Dupo_IL', label: 'Dupo, IL' },
  { value: 'Dupont_IN', label: 'Dupont, IN' },
  { value: 'Dupont_CO', label: 'Dupont, CO' },
  { value: 'Dupont_WA', label: 'Dupont, WA' },
  { value: 'Dupree_SD', label: 'Dupree, SD' },
  { value: 'Dupuyer_MT', label: 'Dupuyer, MT' },
  { value: 'Duquesne_PA', label: 'Duquesne, PA' },
  { value: 'Durand_MI', label: 'Durand, MI' },
  { value: 'Durand_WI', label: 'Durand, WI' },
  { value: 'Durand_IL', label: 'Durand, IL' },
  { value: 'Durango_IA', label: 'Durango, IA' },
  { value: 'Durango_CO', label: 'Durango, CO' },
  { value: 'Durant_FL', label: 'Durant, FL' },
  { value: 'Durant_MS', label: 'Durant, MS' },
  { value: 'Durant_IA', label: 'Durant, IA' },
  { value: 'Durant_OK', label: 'Durant, OK' },
  { value: 'Durbin_WV', label: 'Durbin, WV' },
  { value: 'Durham_NH', label: 'Durham, NH' },
  { value: 'Durham_ME', label: 'Durham, ME' },
  { value: 'Durham_CT', label: 'Durham, CT' },
  { value: 'Durham_NY', label: 'Durham, NY' },
  { value: 'Durham_NC', label: 'Durham, NC' },
  { value: 'Durham_MO', label: 'Durham, MO' },
  { value: 'Durham_KS', label: 'Durham, KS' },
  { value: 'Durham_OK', label: 'Durham, OK' },
  { value: 'Durham_CA', label: 'Durham, CA' },
  { value: 'Durhamville_NY', label: 'Durhamville, NY' },
  { value: 'Durkee_OR', label: 'Durkee, OR' },
  { value: 'Duryea_PA', label: 'Duryea, PA' },
  { value: 'Dushore_PA', label: 'Dushore, PA' },
  { value: 'Duson_LA', label: 'Duson, LA' },
  { value: 'Dustin_OK', label: 'Dustin, OK' },
  { value: 'Dutch Flat_CA', label: 'Dutch Flat, CA' },
  { value: 'Dutch Harbor_AK', label: 'Dutch Harbor, AK' },
  { value: 'Dutch John_UT', label: 'Dutch John, UT' },
  { value: 'Dutchtown_MO', label: 'Dutchtown, MO' },
  { value: 'Dutton_VA', label: 'Dutton, VA' },
  { value: 'Dutton_AL', label: 'Dutton, AL' },
  { value: 'Dutton_MT', label: 'Dutton, MT' },
  { value: 'Duvall_WA', label: 'Duvall, WA' },
  { value: 'Duxbury_MA', label: 'Duxbury, MA' },
  { value: 'Dwale_KY', label: 'Dwale, KY' },
  { value: 'Dwarf_KY', label: 'Dwarf, KY' },
  { value: 'Dwight_IL', label: 'Dwight, IL' },
  { value: 'Dwight_KS', label: 'Dwight, KS' },
  { value: 'Dwight_NE', label: 'Dwight, NE' },
  { value: 'Dycusburg_KY', label: 'Dycusburg, KY' },
  { value: 'Dyer_TN', label: 'Dyer, TN' },
  { value: 'Dyer_IN', label: 'Dyer, IN' },
  { value: 'Dyer_AR', label: 'Dyer, AR' },
  { value: 'Dyer_NV', label: 'Dyer, NV' },
  { value: 'Dyersburg_TN', label: 'Dyersburg, TN' },
  { value: 'Dyersville_IA', label: 'Dyersville, IA' },
  { value: 'Dyess_AR', label: 'Dyess, AR' },
  { value: 'Dyess Afb_TX', label: 'Dyess Afb, TX' },
  { value: 'Dyke_VA', label: 'Dyke, VA' },
  { value: 'Dysart_PA', label: 'Dysart, PA' },
  { value: 'Dysart_IA', label: 'Dysart, IA' },
  { value: 'Eads_TN', label: 'Eads, TN' },
  { value: 'Eads_CO', label: 'Eads, CO' },
  { value: 'Eagan_TN', label: 'Eagan, TN' },
  { value: 'Eagar_AZ', label: 'Eagar, AZ' },
  { value: 'Eagarville_IL', label: 'Eagarville, IL' },
  { value: 'Eagle_MI', label: 'Eagle, MI' },
  { value: 'Eagle_WI', label: 'Eagle, WI' },
  { value: 'Eagle_NE', label: 'Eagle, NE' },
  { value: 'Eagle_CO', label: 'Eagle, CO' },
  { value: 'Eagle_ID', label: 'Eagle, ID' },
  { value: 'Eagle_AK', label: 'Eagle, AK' },
  { value: 'Eagle Bay_NY', label: 'Eagle Bay, NY' },
  { value: 'Eagle Bend_MN', label: 'Eagle Bend, MN' },
  { value: 'Eagle Bridge_NY', label: 'Eagle Bridge, NY' },
  { value: 'Eagle Butte_SD', label: 'Eagle Butte, SD' },
  { value: 'Eagle Creek_OR', label: 'Eagle Creek, OR' },
  { value: 'Eagle Grove_IA', label: 'Eagle Grove, IA' },
  { value: 'Eagle Lake_ME', label: 'Eagle Lake, ME' },
  { value: 'Eagle Lake_FL', label: 'Eagle Lake, FL' },
  { value: 'Eagle Lake_MN', label: 'Eagle Lake, MN' },
  { value: 'Eagle Lake_TX', label: 'Eagle Lake, TX' },
  { value: 'Eagle Mountain_UT', label: 'Eagle Mountain, UT' },
  { value: 'Eagle Nest_NM', label: 'Eagle Nest, NM' },
  { value: 'Eagle Pass_TX', label: 'Eagle Pass, TX' },
  { value: 'Eagle Point_OR', label: 'Eagle Point, OR' },
  { value: 'Eagle River_WI', label: 'Eagle River, WI' },
  { value: 'Eagle River_AK', label: 'Eagle River, AK' },
  { value: 'Eagle Rock_VA', label: 'Eagle Rock, VA' },
  { value: 'Eagle Rock_MO', label: 'Eagle Rock, MO' },
  { value: 'Eagle Springs_NC', label: 'Eagle Springs, NC' },
  { value: 'Eagles Mere_PA', label: 'Eagles Mere, PA' },
  { value: 'Eagletown_OK', label: 'Eagletown, OK' },
  { value: 'Eagleville_TN', label: 'Eagleville, TN' },
  { value: 'Eagleville_MO', label: 'Eagleville, MO' },
  { value: 'Eagleville_CA', label: 'Eagleville, CA' },
  { value: 'Eakly_OK', label: 'Eakly, OK' },
  { value: 'Earl Park_IN', label: 'Earl Park, IN' },
  { value: 'Earle_AR', label: 'Earle, AR' },
  { value: 'Earleton_FL', label: 'Earleton, FL' },
  { value: 'Earleville_MD', label: 'Earleville, MD' },
  { value: 'Earlham_IA', label: 'Earlham, IA' },
  { value: 'Earlimart_CA', label: 'Earlimart, CA' },
  { value: 'Earling_IA', label: 'Earling, IA' },
  { value: 'Earlington_KY', label: 'Earlington, KY' },
  { value: 'Earlsboro_OK', label: 'Earlsboro, OK' },
  { value: 'Earlton_NY', label: 'Earlton, NY' },
  { value: 'Earlville_NY', label: 'Earlville, NY' },
  { value: 'Earlville_IA', label: 'Earlville, IA' },
  { value: 'Earlville_IL', label: 'Earlville, IL' },
  { value: 'Early_IA', label: 'Early, IA' },
  { value: 'Early_TX', label: 'Early, TX' },
  { value: 'Early Branch_SC', label: 'Early Branch, SC' },
  { value: 'Earlysville_VA', label: 'Earlysville, VA' },
  { value: 'Earp_CA', label: 'Earp, CA' },
  { value: 'Earth_TX', label: 'Earth, TX' },
  { value: 'Earth City_MO', label: 'Earth City, MO' },
  { value: 'Easley_SC', label: 'Easley, SC' },
  { value: 'East Alton_IL', label: 'East Alton, IL' },
  { value: 'East Amherst_NY', label: 'East Amherst, NY' },
  { value: 'East Andover_NH', label: 'East Andover, NH' },
  { value: 'East Andover_ME', label: 'East Andover, ME' },
  { value: 'East Arlington_VT', label: 'East Arlington, VT' },
  { value: 'East Aurora_NY', label: 'East Aurora, NY' },
  { value: 'East Baldwin_ME', label: 'East Baldwin, ME' },
  { value: 'East Bank_WV', label: 'East Bank, WV' },
  { value: 'East Barre_VT', label: 'East Barre, VT' },
  { value: 'East Bend_NC', label: 'East Bend, NC' },
  { value: 'East Berkshire_VT', label: 'East Berkshire, VT' },
  { value: 'East Berlin_CT', label: 'East Berlin, CT' },
  { value: 'East Berlin_PA', label: 'East Berlin, PA' },
  { value: 'East Bernard_TX', label: 'East Bernard, TX' },
  { value: 'East Berne_NY', label: 'East Berne, NY' },
  { value: 'East Bernstadt_KY', label: 'East Bernstadt, KY' },
  { value: 'East Bethany_NY', label: 'East Bethany, NY' },
  { value: 'East Boothbay_ME', label: 'East Boothbay, ME' },
  { value: 'East Boston_MA', label: 'East Boston, MA' },
  { value: 'East Brady_PA', label: 'East Brady, PA' },
  { value: 'East Branch_NY', label: 'East Branch, NY' },
  { value: 'East Bridgewater_MA', label: 'East Bridgewater, MA' },
  { value: 'East Brookfield_MA', label: 'East Brookfield, MA' },
  { value: 'East Brunswick_NJ', label: 'East Brunswick, NJ' },
  { value: 'East Burke_VT', label: 'East Burke, VT' },
  { value: 'East Butler_PA', label: 'East Butler, PA' },
  { value: 'East Calais_VT', label: 'East Calais, VT' },
  { value: 'East Canaan_CT', label: 'East Canaan, CT' },
  { value: 'East Canton_OH', label: 'East Canton, OH' },
  { value: 'East Carbon_UT', label: 'East Carbon, UT' },
  { value: 'East Carondelet_IL', label: 'East Carondelet, IL' },
  { value: 'East Charleston_VT', label: 'East Charleston, VT' },
  { value: 'East Chatham_NY', label: 'East Chatham, NY' },
  { value: 'East Chicago_IN', label: 'East Chicago, IN' },
  { value: 'East China_MI', label: 'East China, MI' },
  { value: 'East Concord_NY', label: 'East Concord, NY' },
  { value: 'East Corinth_VT', label: 'East Corinth, VT' },
  { value: 'East Dennis_MA', label: 'East Dennis, MA' },
  { value: 'East Dixfield_ME', label: 'East Dixfield, ME' },
  { value: 'East Dorset_VT', label: 'East Dorset, VT' },
  { value: 'East Dover_VT', label: 'East Dover, VT' },
  { value: 'East Dublin_GA', label: 'East Dublin, GA' },
  { value: 'East Dubuque_IL', label: 'East Dubuque, IL' },
  { value: 'East Durham_NY', label: 'East Durham, NY' },
  { value: 'East Earl_PA', label: 'East Earl, PA' },
  { value: 'East Ellijay_GA', label: 'East Ellijay, GA' },
  { value: 'East Elmhurst_NY', label: 'East Elmhurst, NY' },
  { value: 'East Fairfield_VT', label: 'East Fairfield, VT' },
  { value: 'East Falmouth_MA', label: 'East Falmouth, MA' },
  { value: 'East Flat Rock_NC', label: 'East Flat Rock, NC' },
  { value: 'East Freedom_PA', label: 'East Freedom, PA' },
  { value: 'East Freetown_MA', label: 'East Freetown, MA' },
  { value: 'East Fultonham_OH', label: 'East Fultonham, OH' },
  { value: 'East Galesburg_IL', label: 'East Galesburg, IL' },
  { value: 'East Glacier Park_MT', label: 'East Glacier Park, MT' },
  { value: 'East Granby_CT', label: 'East Granby, CT' },
  { value: 'East Grand Forks_MN', label: 'East Grand Forks, MN' },
  { value: 'East Greenbush_NY', label: 'East Greenbush, NY' },
  { value: 'East Greenville_PA', label: 'East Greenville, PA' },
  { value: 'East Greenwich_RI', label: 'East Greenwich, RI' },
  { value: 'East Haddam_CT', label: 'East Haddam, CT' },
  { value: 'East Hampstead_NH', label: 'East Hampstead, NH' },
  { value: 'East Hampton_CT', label: 'East Hampton, CT' },
  { value: 'East Hampton_NY', label: 'East Hampton, NY' },
  { value: 'East Hanover_NJ', label: 'East Hanover, NJ' },
  { value: 'East Hardwick_VT', label: 'East Hardwick, VT' },
  { value: 'East Hartford_CT', label: 'East Hartford, CT' },
  { value: 'East Hartland_CT', label: 'East Hartland, CT' },
  { value: 'East Haven_VT', label: 'East Haven, VT' },
  { value: 'East Haven_CT', label: 'East Haven, CT' },
  { value: 'East Helena_MT', label: 'East Helena, MT' },
  { value: 'East Hickory_PA', label: 'East Hickory, PA' },
  { value: 'East Islip_NY', label: 'East Islip, NY' },
  { value: 'East Jewett_NY', label: 'East Jewett, NY' },
  { value: 'East Jordan_MI', label: 'East Jordan, MI' },
  { value: 'East Killingly_CT', label: 'East Killingly, CT' },
  { value: 'East Kingston_NH', label: 'East Kingston, NH' },
  { value: 'East Lansing_MI', label: 'East Lansing, MI' },
  { value: 'East Leroy_MI', label: 'East Leroy, MI' },
  { value: 'East Liberty_OH', label: 'East Liberty, OH' },
  { value: 'East Liverpool_OH', label: 'East Liverpool, OH' },
  { value: 'East Longmeadow_MA', label: 'East Longmeadow, MA' },
  { value: 'East Lyme_CT', label: 'East Lyme, CT' },
  { value: 'East Lynn_WV', label: 'East Lynn, WV' },
  { value: 'East Lynn_IL', label: 'East Lynn, IL' },
  { value: 'East Lynne_MO', label: 'East Lynne, MO' },
  { value: 'East Machias_ME', label: 'East Machias, ME' },
  { value: 'East Marion_NY', label: 'East Marion, NY' },
  { value: 'East McKeesport_PA', label: 'East McKeesport, PA' },
  { value: 'East Meadow_NY', label: 'East Meadow, NY' },
  { value: 'East Meredith_NY', label: 'East Meredith, NY' },
  { value: 'East Millinocket_ME', label: 'East Millinocket, ME' },
  { value: 'East Millsboro_PA', label: 'East Millsboro, PA' },
  { value: 'East Moline_IL', label: 'East Moline, IL' },
  { value: 'East Montpelier_VT', label: 'East Montpelier, VT' },
  { value: 'East Moriches_NY', label: 'East Moriches, NY' },
  { value: 'East Nassau_NY', label: 'East Nassau, NY' },
  { value: 'East New Market_MD', label: 'East New Market, MD' },
  { value: 'East Northport_NY', label: 'East Northport, NY' },
  { value: 'East Norwich_NY', label: 'East Norwich, NY' },
  { value: 'East Orange_NJ', label: 'East Orange, NJ' },
  { value: 'East Orland_ME', label: 'East Orland, ME' },
  { value: 'East Orleans_MA', label: 'East Orleans, MA' },
  { value: 'East Otis_MA', label: 'East Otis, MA' },
  { value: 'East Otto_NY', label: 'East Otto, NY' },
  { value: 'East Palatka_FL', label: 'East Palatka, FL' },
  { value: 'East Palestine_OH', label: 'East Palestine, OH' },
  { value: 'East Pembroke_NY', label: 'East Pembroke, NY' },
  { value: 'East Peoria_IL', label: 'East Peoria, IL' },
  { value: 'East Petersburg_PA', label: 'East Petersburg, PA' },
  { value: 'East Pittsburgh_PA', label: 'East Pittsburgh, PA' },
  { value: 'East Point_KY', label: 'East Point, KY' },
  { value: 'East Prairie_MO', label: 'East Prairie, MO' },
  { value: 'East Prospect_PA', label: 'East Prospect, PA' },
  { value: 'East Providence_RI', label: 'East Providence, RI' },
  { value: 'East Quogue_NY', label: 'East Quogue, NY' },
  { value: 'East Randolph_VT', label: 'East Randolph, VT' },
  { value: 'East Rochester_NY', label: 'East Rochester, NY' },
  { value: 'East Rochester_OH', label: 'East Rochester, OH' },
  { value: 'East Rockaway_NY', label: 'East Rockaway, NY' },
  { value: 'East Rutherford_NJ', label: 'East Rutherford, NJ' },
  { value: 'East Ryegate_VT', label: 'East Ryegate, VT' },
  { value: 'East St Louis_IL', label: 'East St Louis, IL' },
  { value: 'East Sandwich_MA', label: 'East Sandwich, MA' },
  { value: 'East Schodack_NY', label: 'East Schodack, NY' },
  { value: 'East Setauket_NY', label: 'East Setauket, NY' },
  { value: 'East Smethport_PA', label: 'East Smethport, PA' },
  { value: 'East Smithfield_PA', label: 'East Smithfield, PA' },
  { value: 'East Sparta_OH', label: 'East Sparta, OH' },
  { value: 'East Spencer_NC', label: 'East Spencer, NC' },
  { value: 'East Springfield_NY', label: 'East Springfield, NY' },
  { value: 'East Springfield_PA', label: 'East Springfield, PA' },
  { value: 'East Springfield_OH', label: 'East Springfield, OH' },
  { value: 'East Stone Gap_VA', label: 'East Stone Gap, VA' },
  { value: 'East Stroudsburg_PA', label: 'East Stroudsburg, PA' },
  { value: 'East Syracuse_NY', label: 'East Syracuse, NY' },
  { value: 'East Taunton_MA', label: 'East Taunton, MA' },
  { value: 'East Tawas_MI', label: 'East Tawas, MI' },
  { value: 'East Templeton_MA', label: 'East Templeton, MA' },
  { value: 'East Texas_PA', label: 'East Texas, PA' },
  { value: 'East Thetford_VT', label: 'East Thetford, VT' },
  { value: 'East Troy_WI', label: 'East Troy, WI' },
  { value: 'East Vandergrift_PA', label: 'East Vandergrift, PA' },
  { value: 'East Wakefield_NH', label: 'East Wakefield, NH' },
  { value: 'East Wallingford_VT', label: 'East Wallingford, VT' },
  { value: 'East Walpole_MA', label: 'East Walpole, MA' },
  { value: 'East Wareham_MA', label: 'East Wareham, MA' },
  { value: 'East Waterboro_ME', label: 'East Waterboro, ME' },
  { value: 'East Waterford_PA', label: 'East Waterford, PA' },
  { value: 'East Wenatchee_WA', label: 'East Wenatchee, WA' },
  { value: 'East Weymouth_MA', label: 'East Weymouth, MA' },
  { value: 'East Wilton_ME', label: 'East Wilton, ME' },
  { value: 'East Windsor_CT', label: 'East Windsor, CT' },
  { value: 'East Worcester_NY', label: 'East Worcester, NY' },
  { value: 'Eastaboga_AL', label: 'Eastaboga, AL' },
  { value: 'Eastanollee_GA', label: 'Eastanollee, GA' },
  { value: 'Eastchester_NY', label: 'Eastchester, NY' },
  { value: 'Eastern_KY', label: 'Eastern, KY' },
  { value: 'Eastford_CT', label: 'Eastford, CT' },
  { value: 'Eastham_MA', label: 'Eastham, MA' },
  { value: 'Easthampton_MA', label: 'Easthampton, MA' },
  { value: 'Eastlake_OH', label: 'Eastlake, OH' },
  { value: 'Eastlake_MI', label: 'Eastlake, MI' },
  { value: 'Eastlake Weir_FL', label: 'Eastlake Weir, FL' },
  { value: 'Eastland_TX', label: 'Eastland, TX' },
  { value: 'Eastman_GA', label: 'Eastman, GA' },
  { value: 'Eastman_WI', label: 'Eastman, WI' },
  { value: 'Easton_ME', label: 'Easton, ME' },
  { value: 'Easton_CT', label: 'Easton, CT' },
  { value: 'Easton_PA', label: 'Easton, PA' },
  { value: 'Easton_MD', label: 'Easton, MD' },
  { value: 'Easton_MN', label: 'Easton, MN' },
  { value: 'Easton_IL', label: 'Easton, IL' },
  { value: 'Easton_MO', label: 'Easton, MO' },
  { value: 'Easton_KS', label: 'Easton, KS' },
  { value: 'Easton_TX', label: 'Easton, TX' },
  { value: 'Easton_WA', label: 'Easton, WA' },
  { value: 'Eastover_SC', label: 'Eastover, SC' },
  { value: 'Eastpoint_FL', label: 'Eastpoint, FL' },
  { value: 'Eastpointe_MI', label: 'Eastpointe, MI' },
  { value: 'Eastport_ME', label: 'Eastport, ME' },
  { value: 'Eastport_NY', label: 'Eastport, NY' },
  { value: 'Eastport_MI', label: 'Eastport, MI' },
  { value: 'Eastport_ID', label: 'Eastport, ID' },
  { value: 'Eastsound_WA', label: 'Eastsound, WA' },
  { value: 'Eastview_KY', label: 'Eastview, KY' },
  { value: 'Eastville_VA', label: 'Eastville, VA' },
  { value: 'Eaton_NY', label: 'Eaton, NY' },
  { value: 'Eaton_OH', label: 'Eaton, OH' },
  { value: 'Eaton_IN', label: 'Eaton, IN' },
  { value: 'Eaton_CO', label: 'Eaton, CO' },
  { value: 'Eaton Center_NH', label: 'Eaton Center, NH' },
  { value: 'Eaton Park_FL', label: 'Eaton Park, FL' },
  { value: 'Eaton Rapids_MI', label: 'Eaton Rapids, MI' },
  { value: 'Eatonton_GA', label: 'Eatonton, GA' },
  { value: 'Eatontown_NJ', label: 'Eatontown, NJ' },
  { value: 'Eatonville_WA', label: 'Eatonville, WA' },
  { value: 'Eau Claire_PA', label: 'Eau Claire, PA' },
  { value: 'Eau Claire_MI', label: 'Eau Claire, MI' },
  { value: 'Eau Claire_WI', label: 'Eau Claire, WI' },
  { value: 'Eau Galle_WI', label: 'Eau Galle, WI' },
  { value: 'Eben Junction_MI', label: 'Eben Junction, MI' },
  { value: 'Ebensburg_PA', label: 'Ebensburg, PA' },
  { value: 'Ebervale_PA', label: 'Ebervale, PA' },
  { value: 'Ebony_VA', label: 'Ebony, VA' },
  { value: 'Ebro_FL', label: 'Ebro, FL' },
  { value: 'Eccles_WV', label: 'Eccles, WV' },
  { value: 'Echo_MN', label: 'Echo, MN' },
  { value: 'Echo_LA', label: 'Echo, LA' },
  { value: 'Echo_UT', label: 'Echo, UT' },
  { value: 'Echo_OR', label: 'Echo, OR' },
  { value: 'Echo Lake_CA', label: 'Echo Lake, CA' },
  { value: 'Echola_AL', label: 'Echola, AL' },
  { value: 'Eckerman_MI', label: 'Eckerman, MI' },
  { value: 'Eckert_CO', label: 'Eckert, CO' },
  { value: 'Eckerty_IN', label: 'Eckerty, IN' },
  { value: 'Eckley_CO', label: 'Eckley, CO' },
  { value: 'Eclectic_AL', label: 'Eclectic, AL' },
  { value: 'Ecleto_TX', label: 'Ecleto, TX' },
  { value: 'Economy_IN', label: 'Economy, IN' },
  { value: 'Ecorse_MI', label: 'Ecorse, MI' },
  { value: 'Ecru_MS', label: 'Ecru, MS' },
  { value: 'Ector_TX', label: 'Ector, TX' },
  { value: 'Edcouch_TX', label: 'Edcouch, TX' },
  { value: 'Eddington_ME', label: 'Eddington, ME' },
  { value: 'Eddy_TX', label: 'Eddy, TX' },
  { value: 'Eddyville_KY', label: 'Eddyville, KY' },
  { value: 'Eddyville_IA', label: 'Eddyville, IA' },
  { value: 'Eddyville_IL', label: 'Eddyville, IL' },
  { value: 'Eddyville_NE', label: 'Eddyville, NE' },
  { value: 'Eddyville_OR', label: 'Eddyville, OR' },
  { value: 'Edelstein_IL', label: 'Edelstein, IL' },
  { value: 'Eden_VT', label: 'Eden, VT' },
  { value: 'Eden_NY', label: 'Eden, NY' },
  { value: 'Eden_MD', label: 'Eden, MD' },
  { value: 'Eden_NC', label: 'Eden, NC' },
  { value: 'Eden_GA', label: 'Eden, GA' },
  { value: 'Eden_WI', label: 'Eden, WI' },
  { value: 'Eden_SD', label: 'Eden, SD' },
  { value: 'Eden_TX', label: 'Eden, TX' },
  { value: 'Eden_ID', label: 'Eden, ID' },
  { value: 'Eden_UT', label: 'Eden, UT' },
  { value: 'Eden_AZ', label: 'Eden, AZ' },
  { value: 'Eden Mills_VT', label: 'Eden Mills, VT' },
  { value: 'Eden Prairie_MN', label: 'Eden Prairie, MN' },
  { value: 'Eden Valley_MN', label: 'Eden Valley, MN' },
  { value: 'Edenton_NC', label: 'Edenton, NC' },
  { value: 'Edenville_MI', label: 'Edenville, MI' },
  { value: 'Edgar_WI', label: 'Edgar, WI' },
  { value: 'Edgar_MT', label: 'Edgar, MT' },
  { value: 'Edgar_NE', label: 'Edgar, NE' },
  { value: 'Edgar Springs_MO', label: 'Edgar Springs, MO' },
  { value: 'Edgard_LA', label: 'Edgard, LA' },
  { value: 'Edgarton_WV', label: 'Edgarton, WV' },
  { value: 'Edgartown_MA', label: 'Edgartown, MA' },
  { value: 'Edgecomb_ME', label: 'Edgecomb, ME' },
  { value: 'Edgefield_SC', label: 'Edgefield, SC' },
  { value: 'Edgeley_ND', label: 'Edgeley, ND' },
  { value: 'Edgemont_SD', label: 'Edgemont, SD' },
  { value: 'Edgemont_AR', label: 'Edgemont, AR' },
  { value: 'Edgemoor_SC', label: 'Edgemoor, SC' },
  { value: 'Edgerton_OH', label: 'Edgerton, OH' },
  { value: 'Edgerton_WI', label: 'Edgerton, WI' },
  { value: 'Edgerton_MN', label: 'Edgerton, MN' },
  { value: 'Edgerton_MO', label: 'Edgerton, MO' },
  { value: 'Edgerton_KS', label: 'Edgerton, KS' },
  { value: 'Edgerton_WY', label: 'Edgerton, WY' },
  { value: 'Edgewater_NJ', label: 'Edgewater, NJ' },
  { value: 'Edgewater_MD', label: 'Edgewater, MD' },
  { value: 'Edgewater_FL', label: 'Edgewater, FL' },
  { value: 'Edgewood_MD', label: 'Edgewood, MD' },
  { value: 'Edgewood_IA', label: 'Edgewood, IA' },
  { value: 'Edgewood_IL', label: 'Edgewood, IL' },
  { value: 'Edgewood_TX', label: 'Edgewood, TX' },
  { value: 'Edgewood_NM', label: 'Edgewood, NM' },
  { value: 'Edina_MO', label: 'Edina, MO' },
  { value: 'Edinboro_PA', label: 'Edinboro, PA' },
  { value: 'Edinburg_PA', label: 'Edinburg, PA' },
  { value: 'Edinburg_VA', label: 'Edinburg, VA' },
  { value: 'Edinburg_ND', label: 'Edinburg, ND' },
  { value: 'Edinburg_IL', label: 'Edinburg, IL' },
  { value: 'Edinburg_TX', label: 'Edinburg, TX' },
  { value: 'Edinburgh_IN', label: 'Edinburgh, IN' },
  { value: 'Edison_NJ', label: 'Edison, NJ' },
  { value: 'Edison_GA', label: 'Edison, GA' },
  { value: 'Edison_OH', label: 'Edison, OH' },
  { value: 'Edison_NE', label: 'Edison, NE' },
  { value: 'Edison_CA', label: 'Edison, CA' },
  { value: 'Edisto Island_SC', label: 'Edisto Island, SC' },
  { value: 'Edmeston_NY', label: 'Edmeston, NY' },
  { value: 'Edmond_WV', label: 'Edmond, WV' },
  { value: 'Edmond_OK', label: 'Edmond, OK' },
  { value: 'Edmonds_WA', label: 'Edmonds, WA' },
  { value: 'Edmondson_AR', label: 'Edmondson, AR' },
  { value: 'Edmonson_TX', label: 'Edmonson, TX' },
  { value: 'Edmonton_KY', label: 'Edmonton, KY' },
  { value: 'Edmore_MI', label: 'Edmore, MI' },
  { value: 'Edmore_ND', label: 'Edmore, ND' },
  { value: 'Edna_KS', label: 'Edna, KS' },
  { value: 'Edna_TX', label: 'Edna, TX' },
  { value: 'Edon_OH', label: 'Edon, OH' },
  { value: 'Edroy_TX', label: 'Edroy, TX' },
  { value: 'Edson_KS', label: 'Edson, KS' },
  { value: 'Edwall_WA', label: 'Edwall, WA' },
  { value: 'Edward_NC', label: 'Edward, NC' },
  { value: 'Edwards_NY', label: 'Edwards, NY' },
  { value: 'Edwards_MS', label: 'Edwards, MS' },
  { value: 'Edwards_IL', label: 'Edwards, IL' },
  { value: 'Edwards_MO', label: 'Edwards, MO' },
  { value: 'Edwards_CO', label: 'Edwards, CO' },
  { value: 'Edwards_CA', label: 'Edwards, CA' },
  { value: 'Edwardsburg_MI', label: 'Edwardsburg, MI' },
  { value: 'Edwardsport_IN', label: 'Edwardsport, IN' },
  { value: 'Edwardsville_IL', label: 'Edwardsville, IL' },
  { value: 'Eek_AK', label: 'Eek, AK' },
  { value: 'Effie_MN', label: 'Effie, MN' },
  { value: 'Effie_LA', label: 'Effie, LA' },
  { value: 'Effingham_NH', label: 'Effingham, NH' },
  { value: 'Effingham_SC', label: 'Effingham, SC' },
  { value: 'Effingham_IL', label: 'Effingham, IL' },
  { value: 'Effingham_KS', label: 'Effingham, KS' },
  { value: 'Effort_PA', label: 'Effort, PA' },
  { value: 'Efland_NC', label: 'Efland, NC' },
  { value: 'Egan_SD', label: 'Egan, SD' },
  { value: 'Egan_LA', label: 'Egan, LA' },
  { value: 'Egegik_AK', label: 'Egegik, AK' },
  { value: 'Egeland_ND', label: 'Egeland, ND' },
  { value: 'Egg Harbor_WI', label: 'Egg Harbor, WI' },
  { value: 'Egg Harbor City_NJ', label: 'Egg Harbor City, NJ' },
  { value: 'Egg Harbor Township_NJ', label: 'Egg Harbor Township, NJ' },
  { value: 'Eggleston_VA', label: 'Eggleston, VA' },
  { value: 'Eglin Afb_FL', label: 'Eglin Afb, FL' },
  { value: 'Eglon_WV', label: 'Eglon, WV' },
  { value: 'Egnar_CO', label: 'Egnar, CO' },
  { value: 'Egypt_AR', label: 'Egypt, AR' },
  { value: 'Egypt_TX', label: 'Egypt, TX' },
  { value: 'Ehrenberg_AZ', label: 'Ehrenberg, AZ' },
  { value: 'Ehrhardt_SC', label: 'Ehrhardt, SC' },
  { value: 'Eidson_TN', label: 'Eidson, TN' },
  { value: 'Eielson Afb_AK', label: 'Eielson Afb, AK' },
  { value: 'Eight Mile_AL', label: 'Eight Mile, AL' },
  { value: 'Eighty Eight_KY', label: 'Eighty Eight, KY' },
  { value: 'Eighty Four_PA', label: 'Eighty Four, PA' },
  { value: 'Eitzen_MN', label: 'Eitzen, MN' },
  { value: 'Ekalaka_MT', label: 'Ekalaka, MT' },
  { value: 'Ekron_KY', label: 'Ekron, KY' },
  { value: 'Ekwok_AK', label: 'Ekwok, AK' },
  { value: 'El Cajon_CA', label: 'El Cajon, CA' },
  { value: 'El Campo_TX', label: 'El Campo, TX' },
  { value: 'El Centro_CA', label: 'El Centro, CA' },
  { value: 'El Cerrito_CA', label: 'El Cerrito, CA' },
  { value: 'El Dorado_KS', label: 'El Dorado, KS' },
  { value: 'El Dorado_AR', label: 'El Dorado, AR' },
  { value: 'El Dorado_CA', label: 'El Dorado, CA' },
  { value: 'El Dorado Hills_CA', label: 'El Dorado Hills, CA' },
  { value: 'El Dorado Springs_MO', label: 'El Dorado Springs, MO' },
  { value: 'El Granada_CA', label: 'El Granada, CA' },
  { value: 'El Indio_TX', label: 'El Indio, TX' },
  { value: 'El Mirage_AZ', label: 'El Mirage, AZ' },
  { value: 'El Monte_CA', label: 'El Monte, CA' },
  { value: 'El Nido_CA', label: 'El Nido, CA' },
  { value: 'El Paso_IL', label: 'El Paso, IL' },
  { value: 'El Paso_AR', label: 'El Paso, AR' },
  { value: 'El Paso_TX', label: 'El Paso, TX' },
  { value: 'El Portal_CA', label: 'El Portal, CA' },
  { value: 'El Prado_NM', label: 'El Prado, NM' },
  { value: 'El Reno_OK', label: 'El Reno, OK' },
  { value: 'El Rito_NM', label: 'El Rito, NM' },
  { value: 'El Segundo_CA', label: 'El Segundo, CA' },
  { value: 'El Sobrante_CA', label: 'El Sobrante, CA' },
  { value: 'Elaine_AR', label: 'Elaine, AR' },
  { value: 'Eland_WI', label: 'Eland, WI' },
  { value: 'Elba_NY', label: 'Elba, NY' },
  { value: 'Elba_AL', label: 'Elba, AL' },
  { value: 'Elba_NE', label: 'Elba, NE' },
  { value: 'Elbe_WA', label: 'Elbe, WA' },
  { value: 'Elberfeld_IN', label: 'Elberfeld, IN' },
  { value: 'Elberon_VA', label: 'Elberon, VA' },
  { value: 'Elberon_IA', label: 'Elberon, IA' },
  { value: 'Elbert_WV', label: 'Elbert, WV' },
  { value: 'Elbert_CO', label: 'Elbert, CO' },
  { value: 'Elberta_AL', label: 'Elberta, AL' },
  { value: 'Elberta_MI', label: 'Elberta, MI' },
  { value: 'Elberta_UT', label: 'Elberta, UT' },
  { value: 'Elberton_GA', label: 'Elberton, GA' },
  { value: 'Elbing_KS', label: 'Elbing, KS' },
  { value: 'Elbow Lake_MN', label: 'Elbow Lake, MN' },
  { value: 'Elbridge_NY', label: 'Elbridge, NY' },
  { value: 'Elburn_IL', label: 'Elburn, IL' },
  { value: 'Elcho_WI', label: 'Elcho, WI' },
  { value: 'Elco_PA', label: 'Elco, PA' },
  { value: 'Eldena_IL', label: 'Eldena, IL' },
  { value: 'Elderton_PA', label: 'Elderton, PA' },
  { value: 'Eldon_IA', label: 'Eldon, IA' },
  { value: 'Eldon_MO', label: 'Eldon, MO' },
  { value: 'Eldora_IA', label: 'Eldora, IA' },
  { value: 'Eldorado_OH', label: 'Eldorado, OH' },
  { value: 'Eldorado_WI', label: 'Eldorado, WI' },
  { value: 'Eldorado_IL', label: 'Eldorado, IL' },
  { value: 'Eldorado_OK', label: 'Eldorado, OK' },
  { value: 'Eldorado_TX', label: 'Eldorado, TX' },
  { value: 'Eldorado Springs_CO', label: 'Eldorado Springs, CO' },
  { value: 'Eldred_NY', label: 'Eldred, NY' },
  { value: 'Eldred_PA', label: 'Eldred, PA' },
  { value: 'Eldred_IL', label: 'Eldred, IL' },
  { value: 'Eldridge_AL', label: 'Eldridge, AL' },
  { value: 'Eldridge_IA', label: 'Eldridge, IA' },
  { value: 'Eldridge_MO', label: 'Eldridge, MO' },
  { value: 'Eldridge_CA', label: 'Eldridge, CA' },
  { value: 'Eleanor_WV', label: 'Eleanor, WV' },
  { value: 'Electra_TX', label: 'Electra, TX' },
  { value: 'Electric City_WA', label: 'Electric City, WA' },
  { value: 'Eleele_HI', label: 'Eleele, HI' },
  { value: 'Elephant Butte_NM', label: 'Elephant Butte, NM' },
  { value: 'Eleroy_IL', label: 'Eleroy, IL' },
  { value: 'Eleva_WI', label: 'Eleva, WI' },
  { value: 'Elfin Cove_AK', label: 'Elfin Cove, AK' },
  { value: 'Elfrida_AZ', label: 'Elfrida, AZ' },
  { value: 'Elgin_SC', label: 'Elgin, SC' },
  { value: 'Elgin_TN', label: 'Elgin, TN' },
  { value: 'Elgin_OH', label: 'Elgin, OH' },
  { value: 'Elgin_IA', label: 'Elgin, IA' },
  { value: 'Elgin_MN', label: 'Elgin, MN' },
  { value: 'Elgin_ND', label: 'Elgin, ND' },
  { value: 'Elgin_IL', label: 'Elgin, IL' },
  { value: 'Elgin_NE', label: 'Elgin, NE' },
  { value: 'Elgin_OK', label: 'Elgin, OK' },
  { value: 'Elgin_TX', label: 'Elgin, TX' },
  { value: 'Elgin_AZ', label: 'Elgin, AZ' },
  { value: 'Elgin_OR', label: 'Elgin, OR' },
  { value: 'Elida_NM', label: 'Elida, NM' },
  { value: 'Elim_AK', label: 'Elim, AK' },
  { value: 'Eliot_ME', label: 'Eliot, ME' },
  { value: 'Elizabeth_NJ', label: 'Elizabeth, NJ' },
  { value: 'Elizabeth_PA', label: 'Elizabeth, PA' },
  { value: 'Elizabeth_WV', label: 'Elizabeth, WV' },
  { value: 'Elizabeth_IN', label: 'Elizabeth, IN' },
  { value: 'Elizabeth_MN', label: 'Elizabeth, MN' },
  { value: 'Elizabeth_IL', label: 'Elizabeth, IL' },
  { value: 'Elizabeth_LA', label: 'Elizabeth, LA' },
  { value: 'Elizabeth_AR', label: 'Elizabeth, AR' },
  { value: 'Elizabeth_CO', label: 'Elizabeth, CO' },
  { value: 'Elizabeth City_NC', label: 'Elizabeth City, NC' },
  { value: 'Elizabethport_NJ', label: 'Elizabethport, NJ' },
  { value: 'Elizabethton_TN', label: 'Elizabethton, TN' },
  { value: 'Elizabethtown_NY', label: 'Elizabethtown, NY' },
  { value: 'Elizabethtown_PA', label: 'Elizabethtown, PA' },
  { value: 'Elizabethtown_NC', label: 'Elizabethtown, NC' },
  { value: 'Elizabethtown_KY', label: 'Elizabethtown, KY' },
  { value: 'Elizabethtown_IN', label: 'Elizabethtown, IN' },
  { value: 'Elizabethtown_IL', label: 'Elizabethtown, IL' },
  { value: 'Elizabethville_PA', label: 'Elizabethville, PA' },
  { value: 'Elizaville_NY', label: 'Elizaville, NY' },
  { value: 'Elk_CA', label: 'Elk, CA' },
  { value: 'Elk_WA', label: 'Elk, WA' },
  { value: 'Elk City_KS', label: 'Elk City, KS' },
  { value: 'Elk City_OK', label: 'Elk City, OK' },
  { value: 'Elk City_ID', label: 'Elk City, ID' },
  { value: 'Elk Creek_VA', label: 'Elk Creek, VA' },
  { value: 'Elk Creek_MO', label: 'Elk Creek, MO' },
  { value: 'Elk Creek_NE', label: 'Elk Creek, NE' },
  { value: 'Elk Creek_CA', label: 'Elk Creek, CA' },
  { value: 'Elk Falls_KS', label: 'Elk Falls, KS' },
  { value: 'Elk Garden_WV', label: 'Elk Garden, WV' },
  { value: 'Elk Grove_CA', label: 'Elk Grove, CA' },
  { value: 'Elk Grove Village_IL', label: 'Elk Grove Village, IL' },
  { value: 'Elk Horn_KY', label: 'Elk Horn, KY' },
  { value: 'Elk Horn_IA', label: 'Elk Horn, IA' },
  { value: 'Elk Mills_MD', label: 'Elk Mills, MD' },
  { value: 'Elk Mound_WI', label: 'Elk Mound, WI' },
  { value: 'Elk Mountain_WY', label: 'Elk Mountain, WY' },
  { value: 'Elk Park_NC', label: 'Elk Park, NC' },
  { value: 'Elk Point_SD', label: 'Elk Point, SD' },
  { value: 'Elk Rapids_MI', label: 'Elk Rapids, MI' },
  { value: 'Elk River_MN', label: 'Elk River, MN' },
  { value: 'Elk River_ID', label: 'Elk River, ID' },
  { value: 'Elka Park_NY', label: 'Elka Park, NY' },
  { value: 'Elkader_IA', label: 'Elkader, IA' },
  { value: 'Elkfork_KY', label: 'Elkfork, KY' },
  { value: 'Elkhart_IN', label: 'Elkhart, IN' },
  { value: 'Elkhart_IA', label: 'Elkhart, IA' },
  { value: 'Elkhart_IL', label: 'Elkhart, IL' },
  { value: 'Elkhart_KS', label: 'Elkhart, KS' },
  { value: 'Elkhart_TX', label: 'Elkhart, TX' },
  { value: 'Elkhart Lake_WI', label: 'Elkhart Lake, WI' },
  { value: 'Elkhorn_WV', label: 'Elkhorn, WV' },
  { value: 'Elkhorn_WI', label: 'Elkhorn, WI' },
  { value: 'Elkhorn_NE', label: 'Elkhorn, NE' },
  { value: 'Elkhorn City_KY', label: 'Elkhorn City, KY' },
  { value: 'Elkin_NC', label: 'Elkin, NC' },
  { value: 'Elkins_NH', label: 'Elkins, NH' },
  { value: 'Elkins_WV', label: 'Elkins, WV' },
  { value: 'Elkins_AR', label: 'Elkins, AR' },
  { value: 'Elkins Park_PA', label: 'Elkins Park, PA' },
  { value: 'Elkland_PA', label: 'Elkland, PA' },
  { value: 'Elkland_MO', label: 'Elkland, MO' },
  { value: 'Elkmont_AL', label: 'Elkmont, AL' },
  { value: 'Elko_SC', label: 'Elko, SC' },
  { value: 'Elko_GA', label: 'Elko, GA' },
  { value: 'Elko_NV', label: 'Elko, NV' },
  { value: 'Elko New Market_MN', label: 'Elko New Market, MN' },
  { value: 'Elkport_IA', label: 'Elkport, IA' },
  { value: 'Elkridge_MD', label: 'Elkridge, MD' },
  { value: 'Elkton_MD', label: 'Elkton, MD' },
  { value: 'Elkton_VA', label: 'Elkton, VA' },
  { value: 'Elkton_FL', label: 'Elkton, FL' },
  { value: 'Elkton_TN', label: 'Elkton, TN' },
  { value: 'Elkton_KY', label: 'Elkton, KY' },
  { value: 'Elkton_OH', label: 'Elkton, OH' },
  { value: 'Elkton_MI', label: 'Elkton, MI' },
  { value: 'Elkton_MN', label: 'Elkton, MN' },
  { value: 'Elkton_SD', label: 'Elkton, SD' },
  { value: 'Elkton_OR', label: 'Elkton, OR' },
  { value: 'Elkview_WV', label: 'Elkview, WV' },
  { value: 'Elkville_IL', label: 'Elkville, IL' },
  { value: 'Elkwood_VA', label: 'Elkwood, VA' },
  { value: 'Ellabell_GA', label: 'Ellabell, GA' },
  { value: 'Ellamore_WV', label: 'Ellamore, WV' },
  { value: 'Ellaville_GA', label: 'Ellaville, GA' },
  { value: 'Ellenboro_WV', label: 'Ellenboro, WV' },
  { value: 'Ellenboro_NC', label: 'Ellenboro, NC' },
  { value: 'Ellenburg_NY', label: 'Ellenburg, NY' },
  { value: 'Ellenburg Center_NY', label: 'Ellenburg Center, NY' },
  { value: 'Ellenburg Depot_NY', label: 'Ellenburg Depot, NY' },
  { value: 'Ellendale_DE', label: 'Ellendale, DE' },
  { value: 'Ellendale_TN', label: 'Ellendale, TN' },
  { value: 'Ellendale_MN', label: 'Ellendale, MN' },
  { value: 'Ellendale_ND', label: 'Ellendale, ND' },
  { value: 'Ellensburg_WA', label: 'Ellensburg, WA' },
  { value: 'Ellenton_GA', label: 'Ellenton, GA' },
  { value: 'Ellenton_FL', label: 'Ellenton, FL' },
  { value: 'Ellenville_NY', label: 'Ellenville, NY' },
  { value: 'Ellenwood_GA', label: 'Ellenwood, GA' },
  { value: 'Ellerbe_NC', label: 'Ellerbe, NC' },
  { value: 'Ellerslie_MD', label: 'Ellerslie, MD' },
  { value: 'Ellerslie_GA', label: 'Ellerslie, GA' },
  { value: 'Ellery_IL', label: 'Ellery, IL' },
  { value: 'Ellettsville_IN', label: 'Ellettsville, IN' },
  { value: 'Ellicott City_MD', label: 'Ellicott City, MD' },
  { value: 'Ellicottville_NY', label: 'Ellicottville, NY' },
  { value: 'Ellijay_GA', label: 'Ellijay, GA' },
  { value: 'Ellinger_TX', label: 'Ellinger, TX' },
  { value: 'Ellington_CT', label: 'Ellington, CT' },
  { value: 'Ellington_NY', label: 'Ellington, NY' },
  { value: 'Ellington_MO', label: 'Ellington, MO' },
  { value: 'Ellinwood_KS', label: 'Ellinwood, KS' },
  { value: 'Elliott_SC', label: 'Elliott, SC' },
  { value: 'Elliott_IA', label: 'Elliott, IA' },
  { value: 'Elliott_IL', label: 'Elliott, IL' },
  { value: 'Elliottsburg_PA', label: 'Elliottsburg, PA' },
  { value: 'Ellis_KS', label: 'Ellis, KS' },
  { value: 'Ellis_ID', label: 'Ellis, ID' },
  { value: 'Ellis Grove_IL', label: 'Ellis Grove, IL' },
  { value: 'Ellisburg_NY', label: 'Ellisburg, NY' },
  { value: 'Ellison Bay_WI', label: 'Ellison Bay, WI' },
  { value: 'Elliston_VA', label: 'Elliston, VA' },
  { value: 'Elliston_MT', label: 'Elliston, MT' },
  { value: 'Ellisville_MS', label: 'Ellisville, MS' },
  { value: 'Ellisville_IL', label: 'Ellisville, IL' },
  { value: 'Elloree_SC', label: 'Elloree, SC' },
  { value: 'Ellsinore_MO', label: 'Ellsinore, MO' },
  { value: 'Ellston_IA', label: 'Ellston, IA' },
  { value: 'Ellsworth_ME', label: 'Ellsworth, ME' },
  { value: 'Ellsworth_PA', label: 'Ellsworth, PA' },
  { value: 'Ellsworth_MI', label: 'Ellsworth, MI' },
  { value: 'Ellsworth_IA', label: 'Ellsworth, IA' },
  { value: 'Ellsworth_WI', label: 'Ellsworth, WI' },
  { value: 'Ellsworth_MN', label: 'Ellsworth, MN' },
  { value: 'Ellsworth_IL', label: 'Ellsworth, IL' },
  { value: 'Ellsworth_KS', label: 'Ellsworth, KS' },
  { value: 'Ellsworth_NE', label: 'Ellsworth, NE' },
  { value: 'Ellsworth Afb_SD', label: 'Ellsworth Afb, SD' },
  { value: 'Ellwood City_PA', label: 'Ellwood City, PA' },
  { value: 'Elm City_NC', label: 'Elm City, NC' },
  { value: 'Elm Creek_NE', label: 'Elm Creek, NE' },
  { value: 'Elm Grove_WI', label: 'Elm Grove, WI' },
  { value: 'Elm Grove_LA', label: 'Elm Grove, LA' },
  { value: 'Elm Mott_TX', label: 'Elm Mott, TX' },
  { value: 'Elma_NY', label: 'Elma, NY' },
  { value: 'Elma_IA', label: 'Elma, IA' },
  { value: 'Elma_WA', label: 'Elma, WA' },
  { value: 'Elmaton_TX', label: 'Elmaton, TX' },
  { value: 'Elmdale_KS', label: 'Elmdale, KS' },
  { value: 'Elmendorf_TX', label: 'Elmendorf, TX' },
  { value: 'Elmer_NJ', label: 'Elmer, NJ' },
  { value: 'Elmer_MO', label: 'Elmer, MO' },
  { value: 'Elmer_LA', label: 'Elmer, LA' },
  { value: 'Elmer_OK', label: 'Elmer, OK' },
  { value: 'Elmer City_WA', label: 'Elmer City, WA' },
  { value: 'Elmhurst_NY', label: 'Elmhurst, NY' },
  { value: 'Elmhurst_IL', label: 'Elmhurst, IL' },
  { value: 'Elmira_NY', label: 'Elmira, NY' },
  { value: 'Elmira_MI', label: 'Elmira, MI' },
  { value: 'Elmira_CA', label: 'Elmira, CA' },
  { value: 'Elmira_OR', label: 'Elmira, OR' },
  { value: 'Elmo_MT', label: 'Elmo, MT' },
  { value: 'Elmo_MO', label: 'Elmo, MO' },
  { value: 'Elmo_UT', label: 'Elmo, UT' },
  { value: 'Elmont_NY', label: 'Elmont, NY' },
  { value: 'Elmora_PA', label: 'Elmora, PA' },
  { value: 'Elmore_AL', label: 'Elmore, AL' },
  { value: 'Elmore_OH', label: 'Elmore, OH' },
  { value: 'Elmore_MN', label: 'Elmore, MN' },
  { value: 'Elmore City_OK', label: 'Elmore City, OK' },
  { value: 'Elmsford_NY', label: 'Elmsford, NY' },
  { value: 'Elmwood_TN', label: 'Elmwood, TN' },
  { value: 'Elmwood_WI', label: 'Elmwood, WI' },
  { value: 'Elmwood_IL', label: 'Elmwood, IL' },
  { value: 'Elmwood_NE', label: 'Elmwood, NE' },
  { value: 'Elmwood Park_NJ', label: 'Elmwood Park, NJ' },
  { value: 'Elmwood Park_IL', label: 'Elmwood Park, IL' },
  { value: 'Elnora_IN', label: 'Elnora, IN' },
  { value: 'Elon_NC', label: 'Elon, NC' },
  { value: 'Elora_TN', label: 'Elora, TN' },
  { value: 'Eloy_AZ', label: 'Eloy, AZ' },
  { value: 'Elrama_PA', label: 'Elrama, PA' },
  { value: 'Elrod_AL', label: 'Elrod, AL' },
  { value: 'Elrosa_MN', label: 'Elrosa, MN' },
  { value: 'Elroy_WI', label: 'Elroy, WI' },
  { value: 'Elsa_TX', label: 'Elsa, TX' },
  { value: 'Elsah_IL', label: 'Elsah, IL' },
  { value: 'Elsberry_MO', label: 'Elsberry, MO' },
  { value: 'Elsie_MI', label: 'Elsie, MI' },
  { value: 'Elsie_NE', label: 'Elsie, NE' },
  { value: 'Elsinore_UT', label: 'Elsinore, UT' },
  { value: 'Elsmere_NE', label: 'Elsmere, NE' },
  { value: 'Elsmore_KS', label: 'Elsmore, KS' },
  { value: 'Elton_PA', label: 'Elton, PA' },
  { value: 'Elton_WI', label: 'Elton, WI' },
  { value: 'Elton_LA', label: 'Elton, LA' },
  { value: 'Eltopia_WA', label: 'Eltopia, WA' },
  { value: 'Elvaston_IL', label: 'Elvaston, IL' },
  { value: 'Elverson_PA', label: 'Elverson, PA' },
  { value: 'Elverta_CA', label: 'Elverta, CA' },
  { value: 'Elwell_MI', label: 'Elwell, MI' },
  { value: 'Elwin_IL', label: 'Elwin, IL' },
  { value: 'Elwood_NJ', label: 'Elwood, NJ' },
  { value: 'Elwood_IN', label: 'Elwood, IN' },
  { value: 'Elwood_IL', label: 'Elwood, IL' },
  { value: 'Elwood_KS', label: 'Elwood, KS' },
  { value: 'Elwood_NE', label: 'Elwood, NE' },
  { value: 'Ely_IA', label: 'Ely, IA' },
  { value: 'Ely_MN', label: 'Ely, MN' },
  { value: 'Ely_NV', label: 'Ely, NV' },
  { value: 'Elyria_OH', label: 'Elyria, OH' },
  { value: 'Elyria_NE', label: 'Elyria, NE' },
  { value: 'Elysburg_PA', label: 'Elysburg, PA' },
  { value: 'Elysian_MN', label: 'Elysian, MN' },
  { value: 'Elysian Fields_TX', label: 'Elysian Fields, TX' },
  { value: 'Embarrass_WI', label: 'Embarrass, WI' },
  { value: 'Embarrass_MN', label: 'Embarrass, MN' },
  { value: 'Emblem_WY', label: 'Emblem, WY' },
  { value: 'Embudo_NM', label: 'Embudo, NM' },
  { value: 'Emden_IL', label: 'Emden, IL' },
  { value: 'Emden_MO', label: 'Emden, MO' },
  { value: 'Emeigh_PA', label: 'Emeigh, PA' },
  { value: 'Emelle_AL', label: 'Emelle, AL' },
  { value: 'Emerado_ND', label: 'Emerado, ND' },
  { value: 'Emerald Isle_NC', label: 'Emerald Isle, NC' },
  { value: 'Emerson_NJ', label: 'Emerson, NJ' },
  { value: 'Emerson_GA', label: 'Emerson, GA' },
  { value: 'Emerson_KY', label: 'Emerson, KY' },
  { value: 'Emerson_IA', label: 'Emerson, IA' },
  { value: 'Emerson_NE', label: 'Emerson, NE' },
  { value: 'Emerson_AR', label: 'Emerson, AR' },
  { value: 'Emery_SD', label: 'Emery, SD' },
  { value: 'Emery_UT', label: 'Emery, UT' },
  { value: 'Emeryville_CA', label: 'Emeryville, CA' },
  { value: 'Emigrant_MT', label: 'Emigrant, MT' },
  { value: 'Emigrant Gap_CA', label: 'Emigrant Gap, CA' },
  { value: 'Emigsville_PA', label: 'Emigsville, PA' },
  { value: 'Emily_MN', label: 'Emily, MN' },
  { value: 'Eminence_KY', label: 'Eminence, KY' },
  { value: 'Eminence_IN', label: 'Eminence, IN' },
  { value: 'Eminence_MO', label: 'Eminence, MO' },
  { value: 'Emington_IL', label: 'Emington, IL' },
  { value: 'Emlenton_PA', label: 'Emlenton, PA' },
  { value: 'Emma_MO', label: 'Emma, MO' },
  { value: 'Emmalena_KY', label: 'Emmalena, KY' },
  { value: 'Emmaus_PA', label: 'Emmaus, PA' },
  { value: 'Emmet_NE', label: 'Emmet, NE' },
  { value: 'Emmet_AR', label: 'Emmet, AR' },
  { value: 'Emmetsburg_IA', label: 'Emmetsburg, IA' },
  { value: 'Emmett_MI', label: 'Emmett, MI' },
  { value: 'Emmett_KS', label: 'Emmett, KS' },
  { value: 'Emmett_ID', label: 'Emmett, ID' },
  { value: 'Emmitsburg_MD', label: 'Emmitsburg, MD' },
  { value: 'Emmonak_AK', label: 'Emmonak, AK' },
  { value: 'Emmons_MN', label: 'Emmons, MN' },
  { value: 'Emory_VA', label: 'Emory, VA' },
  { value: 'Emory_TX', label: 'Emory, TX' },
  { value: 'Empire_AL', label: 'Empire, AL' },
  { value: 'Empire_OH', label: 'Empire, OH' },
  { value: 'Empire_MI', label: 'Empire, MI' },
  { value: 'Empire_LA', label: 'Empire, LA' },
  { value: 'Empire_CO', label: 'Empire, CO' },
  { value: 'Empire_NV', label: 'Empire, NV' },
  { value: 'Empire_CA', label: 'Empire, CA' },
  { value: 'Emporia_VA', label: 'Emporia, VA' },
  { value: 'Emporia_KS', label: 'Emporia, KS' },
  { value: 'Emporium_PA', label: 'Emporium, PA' },
  { value: 'Encampment_WY', label: 'Encampment, WY' },
  { value: 'Encinal_TX', label: 'Encinal, TX' },
  { value: 'Encinitas_CA', label: 'Encinitas, CA' },
  { value: 'Encino_TX', label: 'Encino, TX' },
  { value: 'Encino_NM', label: 'Encino, NM' },
  { value: 'Encino_CA', label: 'Encino, CA' },
  { value: 'Endeavor_PA', label: 'Endeavor, PA' },
  { value: 'Endeavor_WI', label: 'Endeavor, WI' },
  { value: 'Enderlin_ND', label: 'Enderlin, ND' },
  { value: 'Enders_NE', label: 'Enders, NE' },
  { value: 'Endicott_NY', label: 'Endicott, NY' },
  { value: 'Endicott_NE', label: 'Endicott, NE' },
  { value: 'Endicott_WA', label: 'Endicott, WA' },
  { value: 'Energy_IL', label: 'Energy, IL' },
  { value: 'Energy_TX', label: 'Energy, TX' },
  { value: 'Enfield_NH', label: 'Enfield, NH' },
  { value: 'Enfield_CT', label: 'Enfield, CT' },
  { value: 'Enfield_NC', label: 'Enfield, NC' },
  { value: 'Enfield_IL', label: 'Enfield, IL' },
  { value: 'Engadine_MI', label: 'Engadine, MI' },
  { value: 'Engelhard_NC', label: 'Engelhard, NC' },
  { value: 'England_AR', label: 'England, AR' },
  { value: 'Englewood_NJ', label: 'Englewood, NJ' },
  { value: 'Englewood_FL', label: 'Englewood, FL' },
  { value: 'Englewood_TN', label: 'Englewood, TN' },
  { value: 'Englewood_OH', label: 'Englewood, OH' },
  { value: 'Englewood_KS', label: 'Englewood, KS' },
  { value: 'Englewood_CO', label: 'Englewood, CO' },
  { value: 'Englewood Cliffs_NJ', label: 'Englewood Cliffs, NJ' },
  { value: 'English_IN', label: 'English, IN' },
  { value: 'Englishtown_NJ', label: 'Englishtown, NJ' },
  { value: 'Enid_MS', label: 'Enid, MS' },
  { value: 'Enid_OK', label: 'Enid, OK' },
  { value: 'Enigma_GA', label: 'Enigma, GA' },
  { value: 'Enloe_TX', label: 'Enloe, TX' },
  { value: 'Ennice_NC', label: 'Ennice, NC' },
  { value: 'Enning_SD', label: 'Enning, SD' },
  { value: 'Ennis_MT', label: 'Ennis, MT' },
  { value: 'Ennis_TX', label: 'Ennis, TX' },
  { value: 'Enochs_TX', label: 'Enochs, TX' },
  { value: 'Enola_PA', label: 'Enola, PA' },
  { value: 'Enola_AR', label: 'Enola, AR' },
  { value: 'Enon_OH', label: 'Enon, OH' },
  { value: 'Enon Valley_PA', label: 'Enon Valley, PA' },
  { value: 'Enoree_SC', label: 'Enoree, SC' },
  { value: 'Enosburg Falls_VT', label: 'Enosburg Falls, VT' },
  { value: 'Ensenada_PR', label: 'Ensenada, PR' },
  { value: 'Ensign_KS', label: 'Ensign, KS' },
  { value: 'Enterprise_WV', label: 'Enterprise, WV' },
  { value: 'Enterprise_AL', label: 'Enterprise, AL' },
  { value: 'Enterprise_MS', label: 'Enterprise, MS' },
  { value: 'Enterprise_KS', label: 'Enterprise, KS' },
  { value: 'Enterprise_LA', label: 'Enterprise, LA' },
  { value: 'Enterprise_UT', label: 'Enterprise, UT' },
  { value: 'Enterprise_OR', label: 'Enterprise, OR' },
  { value: 'Entiat_WA', label: 'Entiat, WA' },
  { value: 'Entriken_PA', label: 'Entriken, PA' },
  { value: 'Enumclaw_WA', label: 'Enumclaw, WA' },
  { value: 'Enville_TN', label: 'Enville, TN' },
  { value: 'Eola_IL', label: 'Eola, IL' },
  { value: 'Eola_TX', label: 'Eola, TX' },
  { value: 'Eolia_KY', label: 'Eolia, KY' },
  { value: 'Eolia_MO', label: 'Eolia, MO' },
  { value: 'Epes_AL', label: 'Epes, AL' },
  { value: 'Ephraim_WI', label: 'Ephraim, WI' },
  { value: 'Ephraim_UT', label: 'Ephraim, UT' },
  { value: 'Ephrata_PA', label: 'Ephrata, PA' },
  { value: 'Ephrata_WA', label: 'Ephrata, WA' },
  { value: 'Epping_NH', label: 'Epping, NH' },
  { value: 'Epping_ND', label: 'Epping, ND' },
  { value: 'Epps_LA', label: 'Epps, LA' },
  { value: 'Epsom_NH', label: 'Epsom, NH' },
  { value: 'Epworth_GA', label: 'Epworth, GA' },
  { value: 'Epworth_IA', label: 'Epworth, IA' },
  { value: 'Equality_AL', label: 'Equality, AL' },
  { value: 'Equality_IL', label: 'Equality, IL' },
  { value: 'Equinunk_PA', label: 'Equinunk, PA' },
  { value: 'Era_TX', label: 'Era, TX' },
  { value: 'Erath_LA', label: 'Erath, LA' },
  { value: 'Erbacon_WV', label: 'Erbacon, WV' },
  { value: 'Erhard_MN', label: 'Erhard, MN' },
  { value: 'Erick_OK', label: 'Erick, OK' },
  { value: 'Ericson_NE', label: 'Ericson, NE' },
  { value: 'Erie_PA', label: 'Erie, PA' },
  { value: 'Erie_MI', label: 'Erie, MI' },
  { value: 'Erie_ND', label: 'Erie, ND' },
  { value: 'Erie_IL', label: 'Erie, IL' },
  { value: 'Erie_KS', label: 'Erie, KS' },
  { value: 'Erie_CO', label: 'Erie, CO' },
  { value: 'Erieville_NY', label: 'Erieville, NY' },
  { value: 'Erin_NY', label: 'Erin, NY' },
  { value: 'Erin_TN', label: 'Erin, TN' },
  { value: 'Erlanger_KY', label: 'Erlanger, KY' },
  { value: 'Ermine_KY', label: 'Ermine, KY' },
  { value: 'Ernest_PA', label: 'Ernest, PA' },
  { value: 'Ernul_NC', label: 'Ernul, NC' },
  { value: 'Eros_LA', label: 'Eros, LA' },
  { value: 'Errol_NH', label: 'Errol, NH' },
  { value: 'Erskine_MN', label: 'Erskine, MN' },
  { value: 'Erving_MA', label: 'Erving, MA' },
  { value: 'Erwin_NC', label: 'Erwin, NC' },
  { value: 'Erwin_TN', label: 'Erwin, TN' },
  { value: 'Erwin_SD', label: 'Erwin, SD' },
  { value: 'Erwinna_PA', label: 'Erwinna, PA' },
  { value: 'Erwinville_LA', label: 'Erwinville, LA' },
  { value: 'Esbon_KS', label: 'Esbon, KS' },
  { value: 'Escalante_UT', label: 'Escalante, UT' },
  { value: 'Escalon_CA', label: 'Escalon, CA' },
  { value: 'Escanaba_MI', label: 'Escanaba, MI' },
  { value: 'Escondido_CA', label: 'Escondido, CA' },
  { value: 'Eskdale_WV', label: 'Eskdale, WV' },
  { value: 'Esko_MN', label: 'Esko, MN' },
  { value: 'Eskridge_KS', label: 'Eskridge, KS' },
  { value: 'Esmond_ND', label: 'Esmond, ND' },
  { value: 'Esmond_IL', label: 'Esmond, IL' },
  { value: 'Esmont_VA', label: 'Esmont, VA' },
  { value: 'Esopus_NY', label: 'Esopus, NY' },
  { value: 'Espanola_NM', label: 'Espanola, NM' },
  { value: 'Esparto_CA', label: 'Esparto, CA' },
  { value: 'Esperance_NY', label: 'Esperance, NY' },
  { value: 'Essex_MA', label: 'Essex, MA' },
  { value: 'Essex_CT', label: 'Essex, CT' },
  { value: 'Essex_NY', label: 'Essex, NY' },
  { value: 'Essex_MD', label: 'Essex, MD' },
  { value: 'Essex_IA', label: 'Essex, IA' },
  { value: 'Essex_MT', label: 'Essex, MT' },
  { value: 'Essex_IL', label: 'Essex, IL' },
  { value: 'Essex_MO', label: 'Essex, MO' },
  { value: 'Essex_CA', label: 'Essex, CA' },
  { value: 'Essex Fells_NJ', label: 'Essex Fells, NJ' },
  { value: 'Essex Junction_VT', label: 'Essex Junction, VT' },
  { value: 'Essexville_MI', label: 'Essexville, MI' },
  { value: 'Essie_KY', label: 'Essie, KY' },
  { value: 'Essig_MN', label: 'Essig, MN' },
  { value: 'Essington_PA', label: 'Essington, PA' },
  { value: 'Estacada_OR', label: 'Estacada, OR' },
  { value: 'Estancia_NM', label: 'Estancia, NM' },
  { value: 'Estell Manor_NJ', label: 'Estell Manor, NJ' },
  { value: 'Estelline_SD', label: 'Estelline, SD' },
  { value: 'Estelline_TX', label: 'Estelline, TX' },
  { value: 'Ester_AK', label: 'Ester, AK' },
  { value: 'Estero_FL', label: 'Estero, FL' },
  { value: 'Estes Park_CO', label: 'Estes Park, CO' },
  { value: 'Estherville_IA', label: 'Estherville, IA' },
  { value: 'Estherwood_LA', label: 'Estherwood, LA' },
  { value: 'Estill_SC', label: 'Estill, SC' },
  { value: 'Estill Springs_TN', label: 'Estill Springs, TN' },
  { value: 'Estillfork_AL', label: 'Estillfork, AL' },
  { value: 'Ethan_SD', label: 'Ethan, SD' },
  { value: 'Ethel_WV', label: 'Ethel, WV' },
  { value: 'Ethel_MS', label: 'Ethel, MS' },
  { value: 'Ethel_MO', label: 'Ethel, MO' },
  { value: 'Ethel_LA', label: 'Ethel, LA' },
  { value: 'Ethel_AR', label: 'Ethel, AR' },
  { value: 'Ethel_WA', label: 'Ethel, WA' },
  { value: 'Ethelsville_AL', label: 'Ethelsville, AL' },
  { value: 'Ether_NC', label: 'Ether, NC' },
  { value: 'Ethridge_TN', label: 'Ethridge, TN' },
  { value: 'Ethridge_MT', label: 'Ethridge, MT' },
  { value: 'Etlan_VA', label: 'Etlan, VA' },
  { value: 'Etna_NH', label: 'Etna, NH' },
  { value: 'Etna_ME', label: 'Etna, ME' },
  { value: 'Etna_NY', label: 'Etna, NY' },
  { value: 'Etna_OH', label: 'Etna, OH' },
  { value: 'Etna_WY', label: 'Etna, WY' },
  { value: 'Etna_CA', label: 'Etna, CA' },
  { value: 'Etna Green_IN', label: 'Etna Green, IN' },
  { value: 'Etoile_KY', label: 'Etoile, KY' },
  { value: 'Etoile_TX', label: 'Etoile, TX' },
  { value: 'Eton_GA', label: 'Eton, GA' },
  { value: 'Etowah_NC', label: 'Etowah, NC' },
  { value: 'Etowah_TN', label: 'Etowah, TN' },
  { value: 'Etowah_AR', label: 'Etowah, AR' },
  { value: 'Etta_MS', label: 'Etta, MS' },
  { value: 'Etters_PA', label: 'Etters, PA' },
  { value: 'Ettrick_WI', label: 'Ettrick, WI' },
  { value: 'Eubank_KY', label: 'Eubank, KY' },
  { value: 'Eucha_OK', label: 'Eucha, OK' },
  { value: 'Euclid_OH', label: 'Euclid, OH' },
  { value: 'Euclid_MN', label: 'Euclid, MN' },
  { value: 'Eudora_KS', label: 'Eudora, KS' },
  { value: 'Eudora_AR', label: 'Eudora, AR' },
  { value: 'Eufaula_AL', label: 'Eufaula, AL' },
  { value: 'Eufaula_OK', label: 'Eufaula, OK' },
  { value: 'Eugene_MO', label: 'Eugene, MO' },
  { value: 'Eugene_OR', label: 'Eugene, OR' },
  { value: 'Euless_TX', label: 'Euless, TX' },
  { value: 'Eunice_MO', label: 'Eunice, MO' },
  { value: 'Eunice_LA', label: 'Eunice, LA' },
  { value: 'Eunice_NM', label: 'Eunice, NM' },
  { value: 'Eupora_MS', label: 'Eupora, MS' },
  { value: 'Eure_NC', label: 'Eure, NC' },
  { value: 'Eureka_SD', label: 'Eureka, SD' },
  { value: 'Eureka_MT', label: 'Eureka, MT' },
  { value: 'Eureka_IL', label: 'Eureka, IL' },
  { value: 'Eureka_MO', label: 'Eureka, MO' },
  { value: 'Eureka_KS', label: 'Eureka, KS' },
  { value: 'Eureka_UT', label: 'Eureka, UT' },
  { value: 'Eureka_NV', label: 'Eureka, NV' },
  { value: 'Eureka_CA', label: 'Eureka, CA' },
  { value: 'Eureka Springs_AR', label: 'Eureka Springs, AR' },
  { value: 'Eustace_TX', label: 'Eustace, TX' },
  { value: 'Eustis_ME', label: 'Eustis, ME' },
  { value: 'Eustis_FL', label: 'Eustis, FL' },
  { value: 'Eustis_NE', label: 'Eustis, NE' },
  { value: 'Eutaw_AL', label: 'Eutaw, AL' },
  { value: 'Eutawville_SC', label: 'Eutawville, SC' },
  { value: 'Eva_AL', label: 'Eva, AL' },
  { value: 'Eva_TN', label: 'Eva, TN' },
  { value: 'Evadale_TX', label: 'Evadale, TX' },
  { value: 'Evangeline_LA', label: 'Evangeline, LA' },
  { value: 'Evans_WV', label: 'Evans, WV' },
  { value: 'Evans_GA', label: 'Evans, GA' },
  { value: 'Evans_LA', label: 'Evans, LA' },
  { value: 'Evans_CO', label: 'Evans, CO' },
  { value: 'Evans_WA', label: 'Evans, WA' },
  { value: 'Evans City_PA', label: 'Evans City, PA' },
  { value: 'Evans Mills_NY', label: 'Evans Mills, NY' },
  { value: 'Evansdale_IA', label: 'Evansdale, IA' },
  { value: 'Evansport_OH', label: 'Evansport, OH' },
  { value: 'Evanston_IN', label: 'Evanston, IN' },
  { value: 'Evanston_IL', label: 'Evanston, IL' },
  { value: 'Evanston_WY', label: 'Evanston, WY' },
  { value: 'Evansville_IN', label: 'Evansville, IN' },
  { value: 'Evansville_WI', label: 'Evansville, WI' },
  { value: 'Evansville_MN', label: 'Evansville, MN' },
  { value: 'Evansville_IL', label: 'Evansville, IL' },
  { value: 'Evansville_AR', label: 'Evansville, AR' },
  { value: 'Evansville_WY', label: 'Evansville, WY' },
  { value: 'Evant_TX', label: 'Evant, TX' },
  { value: 'Evart_MI', label: 'Evart, MI' },
  { value: 'Evarts_KY', label: 'Evarts, KY' },
  { value: 'Eveleth_MN', label: 'Eveleth, MN' },
  { value: 'Evening Shade_AR', label: 'Evening Shade, AR' },
  { value: 'Evensville_TN', label: 'Evensville, TN' },
  { value: 'Everest_KS', label: 'Everest, KS' },
  { value: 'Everett_MA', label: 'Everett, MA' },
  { value: 'Everett_PA', label: 'Everett, PA' },
  { value: 'Everett_WA', label: 'Everett, WA' },
  { value: 'Everetts_NC', label: 'Everetts, NC' },
  { value: 'Everglades City_FL', label: 'Everglades City, FL' },
  { value: 'Evergreen_NC', label: 'Evergreen, NC' },
  { value: 'Evergreen_AL', label: 'Evergreen, AL' },
  { value: 'Evergreen_LA', label: 'Evergreen, LA' },
  { value: 'Evergreen_CO', label: 'Evergreen, CO' },
  { value: 'Evergreen Park_IL', label: 'Evergreen Park, IL' },
  { value: 'Everly_IA', label: 'Everly, IA' },
  { value: 'Everson_PA', label: 'Everson, PA' },
  { value: 'Everson_WA', label: 'Everson, WA' },
  { value: 'Everton_MO', label: 'Everton, MO' },
  { value: 'Everton_AR', label: 'Everton, AR' },
  { value: 'Evington_VA', label: 'Evington, VA' },
  { value: 'Ewa Beach_HI', label: 'Ewa Beach, HI' },
  { value: 'Ewell_MD', label: 'Ewell, MD' },
  { value: 'Ewen_MI', label: 'Ewen, MI' },
  { value: 'Ewing_VA', label: 'Ewing, VA' },
  { value: 'Ewing_KY', label: 'Ewing, KY' },
  { value: 'Ewing_IL', label: 'Ewing, IL' },
  { value: 'Ewing_MO', label: 'Ewing, MO' },
  { value: 'Ewing_NE', label: 'Ewing, NE' },
  { value: 'Excel_AL', label: 'Excel, AL' },
  { value: 'Excello_MO', label: 'Excello, MO' },
  { value: 'Excelsior_MN', label: 'Excelsior, MN' },
  { value: 'Excelsior Springs_MO', label: 'Excelsior Springs, MO' },
  { value: 'Exchange_WV', label: 'Exchange, WV' },
  { value: 'Exeland_WI', label: 'Exeland, WI' },
  { value: 'Exeter_RI', label: 'Exeter, RI' },
  { value: 'Exeter_NH', label: 'Exeter, NH' },
  { value: 'Exeter_ME', label: 'Exeter, ME' },
  { value: 'Exeter_MO', label: 'Exeter, MO' },
  { value: 'Exeter_NE', label: 'Exeter, NE' },
  { value: 'Exeter_CA', label: 'Exeter, CA' },
  { value: 'Exira_IA', label: 'Exira, IA' },
  { value: 'Exline_IA', label: 'Exline, IA' },
  { value: 'Exmore_VA', label: 'Exmore, VA' },
  { value: 'Export_PA', label: 'Export, PA' },
  { value: 'Exton_PA', label: 'Exton, PA' },
  { value: 'Eyota_MN', label: 'Eyota, MN' },
  { value: 'Ezel_KY', label: 'Ezel, KY' },
  { value: 'Fabens_TX', label: 'Fabens, TX' },
  { value: 'Faber_VA', label: 'Faber, VA' },
  { value: 'Fabius_NY', label: 'Fabius, NY' },
  { value: 'Fackler_AL', label: 'Fackler, AL' },
  { value: 'Factoryville_PA', label: 'Factoryville, PA' },
  { value: 'Fair Bluff_NC', label: 'Fair Bluff, NC' },
  { value: 'Fair Grove_MO', label: 'Fair Grove, MO' },
  { value: 'Fair Haven_VT', label: 'Fair Haven, VT' },
  { value: 'Fair Haven_NJ', label: 'Fair Haven, NJ' },
  { value: 'Fair Haven_MI', label: 'Fair Haven, MI' },
  { value: 'Fair Lawn_NJ', label: 'Fair Lawn, NJ' },
  { value: 'Fair Oaks_IN', label: 'Fair Oaks, IN' },
  { value: 'Fair Oaks_CA', label: 'Fair Oaks, CA' },
  { value: 'Fair Play_SC', label: 'Fair Play, SC' },
  { value: 'Fair Play_MO', label: 'Fair Play, MO' },
  { value: 'Fairbank_PA', label: 'Fairbank, PA' },
  { value: 'Fairbank_IA', label: 'Fairbank, IA' },
  { value: 'Fairbanks_IN', label: 'Fairbanks, IN' },
  { value: 'Fairbanks_AK', label: 'Fairbanks, AK' },
  { value: 'Fairborn_OH', label: 'Fairborn, OH' },
  { value: 'Fairburn_GA', label: 'Fairburn, GA' },
  { value: 'Fairburn_SD', label: 'Fairburn, SD' },
  { value: 'Fairbury_IL', label: 'Fairbury, IL' },
  { value: 'Fairbury_NE', label: 'Fairbury, NE' },
  { value: 'Fairchance_PA', label: 'Fairchance, PA' },
  { value: 'Fairchild_WI', label: 'Fairchild, WI' },
  {
    value: 'Fairchild Air Force Base_WA',
    label: 'Fairchild Air Force Base, WA',
  },
  { value: 'Fairdale_WV', label: 'Fairdale, WV' },
  { value: 'Fairdale_KY', label: 'Fairdale, KY' },
  { value: 'Fairdale_ND', label: 'Fairdale, ND' },
  { value: 'Fairdealing_MO', label: 'Fairdealing, MO' },
  { value: 'Fairfax_VT', label: 'Fairfax, VT' },
  { value: 'Fairfax_VA', label: 'Fairfax, VA' },
  { value: 'Fairfax_SC', label: 'Fairfax, SC' },
  { value: 'Fairfax_IA', label: 'Fairfax, IA' },
  { value: 'Fairfax_MN', label: 'Fairfax, MN' },
  { value: 'Fairfax_SD', label: 'Fairfax, SD' },
  { value: 'Fairfax_MO', label: 'Fairfax, MO' },
  { value: 'Fairfax_OK', label: 'Fairfax, OK' },
  { value: 'Fairfax_CA', label: 'Fairfax, CA' },
  { value: 'Fairfax Station_VA', label: 'Fairfax Station, VA' },
  { value: 'Fairfield_ME', label: 'Fairfield, ME' },
  { value: 'Fairfield_VT', label: 'Fairfield, VT' },
  { value: 'Fairfield_CT', label: 'Fairfield, CT' },
  { value: 'Fairfield_NJ', label: 'Fairfield, NJ' },
  { value: 'Fairfield_PA', label: 'Fairfield, PA' },
  { value: 'Fairfield_VA', label: 'Fairfield, VA' },
  { value: 'Fairfield_NC', label: 'Fairfield, NC' },
  { value: 'Fairfield_FL', label: 'Fairfield, FL' },
  { value: 'Fairfield_AL', label: 'Fairfield, AL' },
  { value: 'Fairfield_KY', label: 'Fairfield, KY' },
  { value: 'Fairfield_OH', label: 'Fairfield, OH' },
  { value: 'Fairfield_IA', label: 'Fairfield, IA' },
  { value: 'Fairfield_ND', label: 'Fairfield, ND' },
  { value: 'Fairfield_MT', label: 'Fairfield, MT' },
  { value: 'Fairfield_IL', label: 'Fairfield, IL' },
  { value: 'Fairfield_NE', label: 'Fairfield, NE' },
  { value: 'Fairfield_TX', label: 'Fairfield, TX' },
  { value: 'Fairfield_ID', label: 'Fairfield, ID' },
  { value: 'Fairfield_CA', label: 'Fairfield, CA' },
  { value: 'Fairfield_WA', label: 'Fairfield, WA' },
  { value: 'Fairfield Bay_AR', label: 'Fairfield Bay, AR' },
  { value: 'Fairgrove_MI', label: 'Fairgrove, MI' },
  { value: 'Fairhaven_MA', label: 'Fairhaven, MA' },
  { value: 'Fairhope_PA', label: 'Fairhope, PA' },
  { value: 'Fairhope_AL', label: 'Fairhope, AL' },
  { value: 'Fairland_IN', label: 'Fairland, IN' },
  { value: 'Fairland_OK', label: 'Fairland, OK' },
  { value: 'Fairlee_VT', label: 'Fairlee, VT' },
  { value: 'Fairless Hills_PA', label: 'Fairless Hills, PA' },
  { value: 'Fairmont_WV', label: 'Fairmont, WV' },
  { value: 'Fairmont_NC', label: 'Fairmont, NC' },
  { value: 'Fairmont_MN', label: 'Fairmont, MN' },
  { value: 'Fairmont_NE', label: 'Fairmont, NE' },
  { value: 'Fairmont_OK', label: 'Fairmont, OK' },
  { value: 'Fairmount_GA', label: 'Fairmount, GA' },
  { value: 'Fairmount_IN', label: 'Fairmount, IN' },
  { value: 'Fairmount_ND', label: 'Fairmount, ND' },
  { value: 'Fairmount_IL', label: 'Fairmount, IL' },
  { value: 'Fairmount City_PA', label: 'Fairmount City, PA' },
  { value: 'Fairplay_MD', label: 'Fairplay, MD' },
  { value: 'Fairplay_CO', label: 'Fairplay, CO' },
  { value: 'Fairport_NY', label: 'Fairport, NY' },
  { value: 'Fairton_NJ', label: 'Fairton, NJ' },
  { value: 'Fairview_NJ', label: 'Fairview, NJ' },
  { value: 'Fairview_PA', label: 'Fairview, PA' },
  { value: 'Fairview_WV', label: 'Fairview, WV' },
  { value: 'Fairview_NC', label: 'Fairview, NC' },
  { value: 'Fairview_TN', label: 'Fairview, TN' },
  { value: 'Fairview_OH', label: 'Fairview, OH' },
  { value: 'Fairview_MI', label: 'Fairview, MI' },
  { value: 'Fairview_SD', label: 'Fairview, SD' },
  { value: 'Fairview_MT', label: 'Fairview, MT' },
  { value: 'Fairview_IL', label: 'Fairview, IL' },
  { value: 'Fairview_MO', label: 'Fairview, MO' },
  { value: 'Fairview_KS', label: 'Fairview, KS' },
  { value: 'Fairview_OK', label: 'Fairview, OK' },
  { value: 'Fairview_WY', label: 'Fairview, WY' },
  { value: 'Fairview_UT', label: 'Fairview, UT' },
  { value: 'Fairview_OR', label: 'Fairview, OR' },
  { value: 'Fairview Heights_IL', label: 'Fairview Heights, IL' },
  { value: 'Fairwater_WI', label: 'Fairwater, WI' },
  { value: 'Faison_NC', label: 'Faison, NC' },
  { value: 'Faith_NC', label: 'Faith, NC' },
  { value: 'Faith_SD', label: 'Faith, SD' },
  { value: 'Fajardo_PR', label: 'Fajardo, PR' },
  { value: 'Falcon_NC', label: 'Falcon, NC' },
  { value: 'Falcon_MO', label: 'Falcon, MO' },
  { value: 'Falcon Heights_TX', label: 'Falcon Heights, TX' },
  { value: 'Falconer_NY', label: 'Falconer, NY' },
  { value: 'Falfurrias_TX', label: 'Falfurrias, TX' },
  { value: 'Falkland_NC', label: 'Falkland, NC' },
  { value: 'Falkner_MS', label: 'Falkner, MS' },
  { value: 'Falkville_AL', label: 'Falkville, AL' },
  { value: 'Fall Branch_TN', label: 'Fall Branch, TN' },
  { value: 'Fall City_WA', label: 'Fall City, WA' },
  { value: 'Fall Creek_WI', label: 'Fall Creek, WI' },
  { value: 'Fall Creek_OR', label: 'Fall Creek, OR' },
  { value: 'Fall River_MA', label: 'Fall River, MA' },
  { value: 'Fall River_WI', label: 'Fall River, WI' },
  { value: 'Fall River_KS', label: 'Fall River, KS' },
  { value: 'Fall River Mills_CA', label: 'Fall River Mills, CA' },
  { value: 'Fallbrook_CA', label: 'Fallbrook, CA' },
  { value: 'Fallentimber_PA', label: 'Fallentimber, PA' },
  { value: 'Falling Waters_WV', label: 'Falling Waters, WV' },
  { value: 'Fallon_MT', label: 'Fallon, MT' },
  { value: 'Fallon_NV', label: 'Fallon, NV' },
  { value: 'Falls_PA', label: 'Falls, PA' },
  { value: 'Falls Church_VA', label: 'Falls Church, VA' },
  { value: 'Falls City_NE', label: 'Falls City, NE' },
  { value: 'Falls City_TX', label: 'Falls City, TX' },
  { value: 'Falls City_OR', label: 'Falls City, OR' },
  { value: 'Falls Creek_PA', label: 'Falls Creek, PA' },
  { value: 'Falls Mills_VA', label: 'Falls Mills, VA' },
  { value: 'Falls Of Rough_KY', label: 'Falls Of Rough, KY' },
  { value: 'Falls Village_CT', label: 'Falls Village, CT' },
  { value: 'Fallsburg_NY', label: 'Fallsburg, NY' },
  { value: 'Fallston_MD', label: 'Fallston, MD' },
  { value: 'Fallston_NC', label: 'Fallston, NC' },
  { value: 'Falmouth_MA', label: 'Falmouth, MA' },
  { value: 'Falmouth_ME', label: 'Falmouth, ME' },
  { value: 'Falmouth_KY', label: 'Falmouth, KY' },
  { value: 'Falmouth_IN', label: 'Falmouth, IN' },
  { value: 'Falmouth_MI', label: 'Falmouth, MI' },
  { value: 'False Pass_AK', label: 'False Pass, AK' },
  { value: 'Falun_KS', label: 'Falun, KS' },
  { value: 'Fancy Farm_KY', label: 'Fancy Farm, KY' },
  { value: 'Fancy Gap_VA', label: 'Fancy Gap, VA' },
  { value: 'Fannettsburg_PA', label: 'Fannettsburg, PA' },
  { value: 'Fannin_TX', label: 'Fannin, TX' },
  { value: 'Fanrock_WV', label: 'Fanrock, WV' },
  { value: 'Fanshawe_OK', label: 'Fanshawe, OK' },
  { value: 'Fanwood_NJ', label: 'Fanwood, NJ' },
  { value: 'Far Hills_NJ', label: 'Far Hills, NJ' },
  { value: 'Far Rockaway_NY', label: 'Far Rockaway, NY' },
  { value: 'Farber_MO', label: 'Farber, MO' },
  { value: 'Fargo_GA', label: 'Fargo, GA' },
  { value: 'Fargo_ND', label: 'Fargo, ND' },
  { value: 'Fargo_OK', label: 'Fargo, OK' },
  { value: 'Faribault_MN', label: 'Faribault, MN' },
  { value: 'Farina_IL', label: 'Farina, IL' },
  { value: 'Farley_IA', label: 'Farley, IA' },
  { value: 'Farley_MO', label: 'Farley, MO' },
  { value: 'Farlington_KS', label: 'Farlington, KS' },
  { value: 'Farmdale_OH', label: 'Farmdale, OH' },
  { value: 'Farmer_OH', label: 'Farmer, OH' },
  { value: 'Farmer City_IL', label: 'Farmer City, IL' },
  { value: 'Farmersburg_IN', label: 'Farmersburg, IN' },
  { value: 'Farmersburg_IA', label: 'Farmersburg, IA' },
  { value: 'Farmersville_OH', label: 'Farmersville, OH' },
  { value: 'Farmersville_IL', label: 'Farmersville, IL' },
  { value: 'Farmersville_TX', label: 'Farmersville, TX' },
  { value: 'Farmersville_CA', label: 'Farmersville, CA' },
  { value: 'Farmersville Station_NY', label: 'Farmersville Station, NY' },
  { value: 'Farmerville_LA', label: 'Farmerville, LA' },
  { value: 'Farmingdale_ME', label: 'Farmingdale, ME' },
  { value: 'Farmingdale_NJ', label: 'Farmingdale, NJ' },
  { value: 'Farmingdale_NY', label: 'Farmingdale, NY' },
  { value: 'Farmington_NH', label: 'Farmington, NH' },
  { value: 'Farmington_ME', label: 'Farmington, ME' },
  { value: 'Farmington_CT', label: 'Farmington, CT' },
  { value: 'Farmington_NY', label: 'Farmington, NY' },
  { value: 'Farmington_PA', label: 'Farmington, PA' },
  { value: 'Farmington_WV', label: 'Farmington, WV' },
  { value: 'Farmington_KY', label: 'Farmington, KY' },
  { value: 'Farmington_MI', label: 'Farmington, MI' },
  { value: 'Farmington_IA', label: 'Farmington, IA' },
  { value: 'Farmington_MN', label: 'Farmington, MN' },
  { value: 'Farmington_IL', label: 'Farmington, IL' },
  { value: 'Farmington_MO', label: 'Farmington, MO' },
  { value: 'Farmington_AR', label: 'Farmington, AR' },
  { value: 'Farmington_UT', label: 'Farmington, UT' },
  { value: 'Farmington_NM', label: 'Farmington, NM' },
  { value: 'Farmington_CA', label: 'Farmington, CA' },
  { value: 'Farmington_WA', label: 'Farmington, WA' },
  { value: 'Farmington Falls_ME', label: 'Farmington Falls, ME' },
  { value: 'Farmingville_NY', label: 'Farmingville, NY' },
  { value: 'Farmland_IN', label: 'Farmland, IN' },
  { value: 'Farmville_VA', label: 'Farmville, VA' },
  { value: 'Farmville_NC', label: 'Farmville, NC' },
  { value: 'Farnam_NE', label: 'Farnam, NE' },
  { value: 'Farner_TN', label: 'Farner, TN' },
  { value: 'Farnham_NY', label: 'Farnham, NY' },
  { value: 'Farnham_VA', label: 'Farnham, VA' },
  { value: 'Farnhamville_IA', label: 'Farnhamville, IA' },
  { value: 'Farnsworth_TX', label: 'Farnsworth, TX' },
  { value: 'Farragut_TN', label: 'Farragut, TN' },
  { value: 'Farragut_IA', label: 'Farragut, IA' },
  { value: 'Farrar_MO', label: 'Farrar, MO' },
  { value: 'Farrell_PA', label: 'Farrell, PA' },
  { value: 'Farson_WY', label: 'Farson, WY' },
  { value: 'Farwell_MI', label: 'Farwell, MI' },
  { value: 'Farwell_MN', label: 'Farwell, MN' },
  { value: 'Farwell_NE', label: 'Farwell, NE' },
  { value: 'Farwell_TX', label: 'Farwell, TX' },
  { value: 'Fate_TX', label: 'Fate, TX' },
  { value: 'Faucett_MO', label: 'Faucett, MO' },
  { value: 'Faulkner_MD', label: 'Faulkner, MD' },
  { value: 'Faulkton_SD', label: 'Faulkton, SD' },
  { value: 'Faunsdale_AL', label: 'Faunsdale, AL' },
  { value: 'Fawn Grove_PA', label: 'Fawn Grove, PA' },
  { value: 'Fawnskin_CA', label: 'Fawnskin, CA' },
  { value: 'Faxon_OK', label: 'Faxon, OK' },
  { value: 'Fay_OK', label: 'Fay, OK' },
  { value: 'Fayette_NY', label: 'Fayette, NY' },
  { value: 'Fayette_AL', label: 'Fayette, AL' },
  { value: 'Fayette_MS', label: 'Fayette, MS' },
  { value: 'Fayette_OH', label: 'Fayette, OH' },
  { value: 'Fayette_IA', label: 'Fayette, IA' },
  { value: 'Fayette_MO', label: 'Fayette, MO' },
  { value: 'Fayette_UT', label: 'Fayette, UT' },
  { value: 'Fayette City_PA', label: 'Fayette City, PA' },
  { value: 'Fayetteville_NY', label: 'Fayetteville, NY' },
  { value: 'Fayetteville_PA', label: 'Fayetteville, PA' },
  { value: 'Fayetteville_WV', label: 'Fayetteville, WV' },
  { value: 'Fayetteville_NC', label: 'Fayetteville, NC' },
  { value: 'Fayetteville_GA', label: 'Fayetteville, GA' },
  { value: 'Fayetteville_TN', label: 'Fayetteville, TN' },
  { value: 'Fayetteville_OH', label: 'Fayetteville, OH' },
  { value: 'Fayetteville_AR', label: 'Fayetteville, AR' },
  { value: 'Fayetteville_TX', label: 'Fayetteville, TX' },
  { value: 'Fayville_MA', label: 'Fayville, MA' },
  { value: 'Faywood_NM', label: 'Faywood, NM' },
  { value: 'Fe Warren Afb_WY', label: 'Fe Warren Afb, WY' },
  { value: 'Feasterville Trevose_PA', label: 'Feasterville Trevose, PA' },
  { value: 'Federal Dam_MN', label: 'Federal Dam, MN' },
  { value: 'Federal Way_WA', label: 'Federal Way, WA' },
  { value: 'Federalsburg_MD', label: 'Federalsburg, MD' },
  { value: 'Fedora_SD', label: 'Fedora, SD' },
  { value: 'Fedscreek_KY', label: 'Fedscreek, KY' },
  { value: 'Feeding Hills_MA', label: 'Feeding Hills, MA' },
  { value: 'Feesburg_OH', label: 'Feesburg, OH' },
  { value: 'Felch_MI', label: 'Felch, MI' },
  { value: 'Felda_FL', label: 'Felda, FL' },
  { value: 'Felicity_OH', label: 'Felicity, OH' },
  { value: 'Fellows_CA', label: 'Fellows, CA' },
  { value: 'Fellsmere_FL', label: 'Fellsmere, FL' },
  { value: 'Felt_OK', label: 'Felt, OK' },
  { value: 'Felt_ID', label: 'Felt, ID' },
  { value: 'Felton_PA', label: 'Felton, PA' },
  { value: 'Felton_DE', label: 'Felton, DE' },
  { value: 'Felton_MN', label: 'Felton, MN' },
  { value: 'Felton_CA', label: 'Felton, CA' },
  { value: 'Felts Mills_NY', label: 'Felts Mills, NY' },
  { value: 'Fence_WI', label: 'Fence, WI' },
  { value: 'Fence Lake_NM', label: 'Fence Lake, NM' },
  { value: 'Fenelton_PA', label: 'Fenelton, PA' },
  { value: 'Fennimore_WI', label: 'Fennimore, WI' },
  { value: 'Fennville_MI', label: 'Fennville, MI' },
  { value: 'Fenton_MI', label: 'Fenton, MI' },
  { value: 'Fenton_IA', label: 'Fenton, IA' },
  { value: 'Fenton_IL', label: 'Fenton, IL' },
  { value: 'Fenton_MO', label: 'Fenton, MO' },
  { value: 'Fenton_LA', label: 'Fenton, LA' },
  { value: 'Fentress_TX', label: 'Fentress, TX' },
  { value: 'Fenwick_WV', label: 'Fenwick, WV' },
  { value: 'Fenwick_MI', label: 'Fenwick, MI' },
  { value: 'Fenwick Island_DE', label: 'Fenwick Island, DE' },
  { value: 'Ferdinand_IN', label: 'Ferdinand, IN' },
  { value: 'Ferdinand_ID', label: 'Ferdinand, ID' },
  { value: 'Fergus Falls_MN', label: 'Fergus Falls, MN' },
  { value: 'Ferguson_NC', label: 'Ferguson, NC' },
  { value: 'Ferguson_KY', label: 'Ferguson, KY' },
  { value: 'Ferguson_IA', label: 'Ferguson, IA' },
  { value: 'Fernandina Beach_FL', label: 'Fernandina Beach, FL' },
  { value: 'Ferndale_NY', label: 'Ferndale, NY' },
  { value: 'Ferndale_PA', label: 'Ferndale, PA' },
  { value: 'Ferndale_FL', label: 'Ferndale, FL' },
  { value: 'Ferndale_MI', label: 'Ferndale, MI' },
  { value: 'Ferndale_CA', label: 'Ferndale, CA' },
  { value: 'Ferndale_WA', label: 'Ferndale, WA' },
  { value: 'Ferney_SD', label: 'Ferney, SD' },
  { value: 'Fernley_NV', label: 'Fernley, NV' },
  { value: 'Fernwood_MS', label: 'Fernwood, MS' },
  { value: 'Fernwood_ID', label: 'Fernwood, ID' },
  { value: 'Ferriday_LA', label: 'Ferriday, LA' },
  { value: 'Ferris_IL', label: 'Ferris, IL' },
  { value: 'Ferris_TX', label: 'Ferris, TX' },
  { value: 'Ferrisburgh_VT', label: 'Ferrisburgh, VT' },
  { value: 'Ferron_UT', label: 'Ferron, UT' },
  { value: 'Ferrum_VA', label: 'Ferrum, VA' },
  { value: 'Ferrysburg_MI', label: 'Ferrysburg, MI' },
  { value: 'Ferryville_WI', label: 'Ferryville, WI' },
  { value: 'Fertile_IA', label: 'Fertile, IA' },
  { value: 'Fertile_MN', label: 'Fertile, MN' },
  { value: 'Fessenden_ND', label: 'Fessenden, ND' },
  { value: 'Festus_MO', label: 'Festus, MO' },
  { value: 'Feura Bush_NY', label: 'Feura Bush, NY' },
  { value: 'Fiatt_IL', label: 'Fiatt, IL' },
  { value: 'Fiddletown_CA', label: 'Fiddletown, CA' },
  { value: 'Fidelity_IL', label: 'Fidelity, IL' },
  { value: 'Fieldale_VA', label: 'Fieldale, VA' },
  { value: 'Fielding_UT', label: 'Fielding, UT' },
  { value: 'Fieldon_IL', label: 'Fieldon, IL' },
  { value: 'Fields_OR', label: 'Fields, OR' },
  { value: 'Fields Landing_CA', label: 'Fields Landing, CA' },
  { value: 'Fieldton_TX', label: 'Fieldton, TX' },
  { value: 'Fife_WA', label: 'Fife, WA' },
  { value: 'Fife Lake_MI', label: 'Fife Lake, MI' },
  { value: 'Fifield_WI', label: 'Fifield, WI' },
  { value: 'Fifty Lakes_MN', label: 'Fifty Lakes, MN' },
  { value: 'Fifty Six_AR', label: 'Fifty Six, AR' },
  { value: 'Filer_ID', label: 'Filer, ID' },
  { value: 'Filer City_MI', label: 'Filer City, MI' },
  { value: 'Filion_MI', label: 'Filion, MI' },
  { value: 'Filley_NE', label: 'Filley, NE' },
  { value: 'Fillmore_NY', label: 'Fillmore, NY' },
  { value: 'Fillmore_IN', label: 'Fillmore, IN' },
  { value: 'Fillmore_IL', label: 'Fillmore, IL' },
  { value: 'Fillmore_MO', label: 'Fillmore, MO' },
  { value: 'Fillmore_UT', label: 'Fillmore, UT' },
  { value: 'Fillmore_CA', label: 'Fillmore, CA' },
  { value: 'Fincastle_VA', label: 'Fincastle, VA' },
  { value: 'Finchville_KY', label: 'Finchville, KY' },
  { value: 'Findlay_OH', label: 'Findlay, OH' },
  { value: 'Findlay_IL', label: 'Findlay, IL' },
  { value: 'Findley Lake_NY', label: 'Findley Lake, NY' },
  { value: 'Fine_NY', label: 'Fine, NY' },
  { value: 'Fingal_ND', label: 'Fingal, ND' },
  { value: 'Finger_TN', label: 'Finger, TN' },
  { value: 'Fingerville_SC', label: 'Fingerville, SC' },
  { value: 'Finksburg_MD', label: 'Finksburg, MD' },
  { value: 'Finland_MN', label: 'Finland, MN' },
  { value: 'Finlayson_MN', label: 'Finlayson, MN' },
  { value: 'Finley_TN', label: 'Finley, TN' },
  { value: 'Finley_ND', label: 'Finley, ND' },
  { value: 'Finley_OK', label: 'Finley, OK' },
  { value: 'Finley_CA', label: 'Finley, CA' },
  { value: 'Finleyville_PA', label: 'Finleyville, PA' },
  { value: 'Firebaugh_CA', label: 'Firebaugh, CA' },
  { value: 'Firestone_CO', label: 'Firestone, CO' },
  { value: 'Firth_NE', label: 'Firth, NE' },
  { value: 'Firth_ID', label: 'Firth, ID' },
  { value: 'Fischer_TX', label: 'Fischer, TX' },
  { value: 'Fish Camp_CA', label: 'Fish Camp, CA' },
  { value: 'Fish Creek_WI', label: 'Fish Creek, WI' },
  { value: 'Fish Haven_ID', label: 'Fish Haven, ID' },
  { value: 'Fisher_WV', label: 'Fisher, WV' },
  { value: 'Fisher_MN', label: 'Fisher, MN' },
  { value: 'Fisher_IL', label: 'Fisher, IL' },
  { value: 'Fisher_LA', label: 'Fisher, LA' },
  { value: 'Fisher_AR', label: 'Fisher, AR' },
  { value: 'Fishers_NY', label: 'Fishers, NY' },
  { value: 'Fishers_IN', label: 'Fishers, IN' },
  { value: 'Fishers Island_NY', label: 'Fishers Island, NY' },
  { value: 'Fishers Landing_NY', label: 'Fishers Landing, NY' },
  { value: 'Fishersville_VA', label: 'Fishersville, VA' },
  { value: 'Fishertown_PA', label: 'Fishertown, PA' },
  { value: 'Fisherville_KY', label: 'Fisherville, KY' },
  { value: 'Fishing Creek_MD', label: 'Fishing Creek, MD' },
  { value: 'Fishkill_NY', label: 'Fishkill, NY' },
  { value: 'Fishs Eddy_NY', label: 'Fishs Eddy, NY' },
  { value: 'Fishtail_MT', label: 'Fishtail, MT' },
  { value: 'Fisk_MO', label: 'Fisk, MO' },
  { value: 'Fiskdale_MA', label: 'Fiskdale, MA' },
  { value: 'Fiskeville_RI', label: 'Fiskeville, RI' },
  { value: 'Fitchburg_MA', label: 'Fitchburg, MA' },
  { value: 'Fithian_IL', label: 'Fithian, IL' },
  { value: 'Fittstown_OK', label: 'Fittstown, OK' },
  { value: 'Fitzgerald_GA', label: 'Fitzgerald, GA' },
  { value: 'Fitzhugh_OK', label: 'Fitzhugh, OK' },
  { value: 'Fitzpatrick_AL', label: 'Fitzpatrick, AL' },
  { value: 'Fitzwilliam_NH', label: 'Fitzwilliam, NH' },
  { value: 'Five Points_AL', label: 'Five Points, AL' },
  { value: 'Five Points_TN', label: 'Five Points, TN' },
  { value: 'Five Points_CA', label: 'Five Points, CA' },
  { value: 'Flag Pond_TN', label: 'Flag Pond, TN' },
  { value: 'Flagler_CO', label: 'Flagler, CO' },
  { value: 'Flagler Beach_FL', label: 'Flagler Beach, FL' },
  { value: 'Flagstaff_AZ', label: 'Flagstaff, AZ' },
  { value: 'Flagtown_NJ', label: 'Flagtown, NJ' },
  { value: 'Flanagan_IL', label: 'Flanagan, IL' },
  { value: 'Flanders_NJ', label: 'Flanders, NJ' },
  { value: 'Flandreau_SD', label: 'Flandreau, SD' },
  { value: 'Flasher_ND', label: 'Flasher, ND' },
  { value: 'Flat Lick_KY', label: 'Flat Lick, KY' },
  { value: 'Flat Rock_NC', label: 'Flat Rock, NC' },
  { value: 'Flat Rock_AL', label: 'Flat Rock, AL' },
  { value: 'Flat Rock_OH', label: 'Flat Rock, OH' },
  { value: 'Flat Rock_IN', label: 'Flat Rock, IN' },
  { value: 'Flat Rock_MI', label: 'Flat Rock, MI' },
  { value: 'Flat Rock_IL', label: 'Flat Rock, IL' },
  { value: 'Flat Top_WV', label: 'Flat Top, WV' },
  { value: 'Flatgap_KY', label: 'Flatgap, KY' },
  { value: 'Flatonia_TX', label: 'Flatonia, TX' },
  { value: 'Flatwoods_WV', label: 'Flatwoods, WV' },
  { value: 'Flatwoods_KY', label: 'Flatwoods, KY' },
  { value: 'Flatwoods_LA', label: 'Flatwoods, LA' },
  { value: 'Flaxton_ND', label: 'Flaxton, ND' },
  { value: 'Flaxville_MT', label: 'Flaxville, MT' },
  { value: 'Fleetville_PA', label: 'Fleetville, PA' },
  { value: 'Fleetwood_PA', label: 'Fleetwood, PA' },
  { value: 'Fleetwood_NC', label: 'Fleetwood, NC' },
  { value: 'Fleischmanns_NY', label: 'Fleischmanns, NY' },
  { value: 'Fleming_PA', label: 'Fleming, PA' },
  { value: 'Fleming_GA', label: 'Fleming, GA' },
  { value: 'Fleming_OH', label: 'Fleming, OH' },
  { value: 'Fleming_CO', label: 'Fleming, CO' },
  { value: 'Fleming Island_FL', label: 'Fleming Island, FL' },
  { value: 'Flemingsburg_KY', label: 'Flemingsburg, KY' },
  { value: 'Flemington_NJ', label: 'Flemington, NJ' },
  { value: 'Flemington_WV', label: 'Flemington, WV' },
  { value: 'Flemington_MO', label: 'Flemington, MO' },
  { value: 'Flensburg_MN', label: 'Flensburg, MN' },
  { value: 'Fletcher_NC', label: 'Fletcher, NC' },
  { value: 'Fletcher_OH', label: 'Fletcher, OH' },
  { value: 'Fletcher_MO', label: 'Fletcher, MO' },
  { value: 'Fletcher_OK', label: 'Fletcher, OK' },
  { value: 'Flint_MI', label: 'Flint, MI' },
  { value: 'Flint_TX', label: 'Flint, TX' },
  { value: 'Flint Hill_VA', label: 'Flint Hill, VA' },
  { value: 'Flinton_PA', label: 'Flinton, PA' },
  { value: 'Flintstone_MD', label: 'Flintstone, MD' },
  { value: 'Flintstone_GA', label: 'Flintstone, GA' },
  { value: 'Flintville_TN', label: 'Flintville, TN' },
  { value: 'Flippin_AR', label: 'Flippin, AR' },
  { value: 'Flom_MN', label: 'Flom, MN' },
  { value: 'Flomaton_AL', label: 'Flomaton, AL' },
  { value: 'Flomot_TX', label: 'Flomot, TX' },
  { value: 'Floodwood_MN', label: 'Floodwood, MN' },
  { value: 'Flora_MS', label: 'Flora, MS' },
  { value: 'Flora_IN', label: 'Flora, IN' },
  { value: 'Flora_IL', label: 'Flora, IL' },
  { value: 'Flora_LA', label: 'Flora, LA' },
  { value: 'Flora Vista_NM', label: 'Flora Vista, NM' },
  { value: 'Florahome_FL', label: 'Florahome, FL' },
  { value: 'Floral_AR', label: 'Floral, AR' },
  { value: 'Floral City_FL', label: 'Floral City, FL' },
  { value: 'Floral Park_NY', label: 'Floral Park, NY' },
  { value: 'Florala_AL', label: 'Florala, AL' },
  { value: 'Florence_MA', label: 'Florence, MA' },
  { value: 'Florence_VT', label: 'Florence, VT' },
  { value: 'Florence_NJ', label: 'Florence, NJ' },
  { value: 'Florence_SC', label: 'Florence, SC' },
  { value: 'Florence_AL', label: 'Florence, AL' },
  { value: 'Florence_MS', label: 'Florence, MS' },
  { value: 'Florence_KY', label: 'Florence, KY' },
  { value: 'Florence_IN', label: 'Florence, IN' },
  { value: 'Florence_WI', label: 'Florence, WI' },
  { value: 'Florence_SD', label: 'Florence, SD' },
  { value: 'Florence_MT', label: 'Florence, MT' },
  { value: 'Florence_MO', label: 'Florence, MO' },
  { value: 'Florence_KS', label: 'Florence, KS' },
  { value: 'Florence_TX', label: 'Florence, TX' },
  { value: 'Florence_CO', label: 'Florence, CO' },
  { value: 'Florence_AZ', label: 'Florence, AZ' },
  { value: 'Florence_OR', label: 'Florence, OR' },
  { value: 'Floresville_TX', label: 'Floresville, TX' },
  { value: 'Florham Park_NJ', label: 'Florham Park, NJ' },
  { value: 'Florida_PR', label: 'Florida, PR' },
  { value: 'Florida_NY', label: 'Florida, NY' },
  { value: 'Florien_LA', label: 'Florien, LA' },
  { value: 'Floris_IA', label: 'Floris, IA' },
  { value: 'Florissant_MO', label: 'Florissant, MO' },
  { value: 'Florissant_CO', label: 'Florissant, CO' },
  { value: 'Floriston_CA', label: 'Floriston, CA' },
  { value: 'Flossmoor_IL', label: 'Flossmoor, IL' },
  { value: 'Flournoy_CA', label: 'Flournoy, CA' },
  { value: 'Flourtown_PA', label: 'Flourtown, PA' },
  { value: 'Flovilla_GA', label: 'Flovilla, GA' },
  { value: 'Flower Mound_TX', label: 'Flower Mound, TX' },
  { value: 'Floweree_MT', label: 'Floweree, MT' },
  { value: 'Flowery Branch_GA', label: 'Flowery Branch, GA' },
  { value: 'Flowood_MS', label: 'Flowood, MS' },
  { value: 'Floyd_VA', label: 'Floyd, VA' },
  { value: 'Floyd_IA', label: 'Floyd, IA' },
  { value: 'Floyd_NM', label: 'Floyd, NM' },
  { value: 'Floydada_TX', label: 'Floydada, TX' },
  { value: 'Floyds Knobs_IN', label: 'Floyds Knobs, IN' },
  { value: 'Fluker_LA', label: 'Fluker, LA' },
  { value: 'Flushing_NY', label: 'Flushing, NY' },
  { value: 'Flushing_OH', label: 'Flushing, OH' },
  { value: 'Flushing_MI', label: 'Flushing, MI' },
  { value: 'Fluvanna_TX', label: 'Fluvanna, TX' },
  { value: 'Fly Creek_NY', label: 'Fly Creek, NY' },
  { value: 'Flynn_TX', label: 'Flynn, TX' },
  { value: 'Fogelsville_PA', label: 'Fogelsville, PA' },
  { value: 'Folcroft_PA', label: 'Folcroft, PA' },
  { value: 'Foley_AL', label: 'Foley, AL' },
  { value: 'Foley_MN', label: 'Foley, MN' },
  { value: 'Foley_MO', label: 'Foley, MO' },
  { value: 'Folkston_GA', label: 'Folkston, GA' },
  { value: 'Follansbee_WV', label: 'Follansbee, WV' },
  { value: 'Follett_TX', label: 'Follett, TX' },
  { value: 'Folly Beach_SC', label: 'Folly Beach, SC' },
  { value: 'Folsom_PA', label: 'Folsom, PA' },
  { value: 'Folsom_WV', label: 'Folsom, WV' },
  { value: 'Folsom_LA', label: 'Folsom, LA' },
  { value: 'Folsom_NM', label: 'Folsom, NM' },
  { value: 'Folsom_CA', label: 'Folsom, CA' },
  { value: 'Fombell_PA', label: 'Fombell, PA' },
  { value: 'Fond Du Lac_WI', label: 'Fond Du Lac, WI' },
  { value: 'Fonda_NY', label: 'Fonda, NY' },
  { value: 'Fonda_IA', label: 'Fonda, IA' },
  { value: 'Fontana_WI', label: 'Fontana, WI' },
  { value: 'Fontana_KS', label: 'Fontana, KS' },
  { value: 'Fontana_CA', label: 'Fontana, CA' },
  { value: 'Fontana Dam_NC', label: 'Fontana Dam, NC' },
  { value: 'Fontanelle_IA', label: 'Fontanelle, IA' },
  { value: 'Fontanet_IN', label: 'Fontanet, IN' },
  { value: 'Foosland_IL', label: 'Foosland, IL' },
  { value: 'Foothill Ranch_CA', label: 'Foothill Ranch, CA' },
  { value: 'Footville_WI', label: 'Footville, WI' },
  { value: 'Forbes_MN', label: 'Forbes, MN' },
  { value: 'Forbes_ND', label: 'Forbes, ND' },
  { value: 'Forbes Road_PA', label: 'Forbes Road, PA' },
  { value: 'Forbestown_CA', label: 'Forbestown, CA' },
  { value: 'Force_PA', label: 'Force, PA' },
  { value: 'Ford_VA', label: 'Ford, VA' },
  { value: 'Ford_KS', label: 'Ford, KS' },
  { value: 'Ford_WA', label: 'Ford, WA' },
  { value: 'Ford City_PA', label: 'Ford City, PA' },
  { value: 'Ford Cliff_PA', label: 'Ford Cliff, PA' },
  { value: 'Fordland_MO', label: 'Fordland, MO' },
  { value: 'Fordoche_LA', label: 'Fordoche, LA' },
  { value: 'Fords_NJ', label: 'Fords, NJ' },
  { value: 'Fords Branch_KY', label: 'Fords Branch, KY' },
  { value: 'Fordsville_KY', label: 'Fordsville, KY' },
  { value: 'Fordville_ND', label: 'Fordville, ND' },
  { value: 'Fordyce_NE', label: 'Fordyce, NE' },
  { value: 'Fordyce_AR', label: 'Fordyce, AR' },
  { value: 'Foreman_AR', label: 'Foreman, AR' },
  { value: 'Forest_VA', label: 'Forest, VA' },
  { value: 'Forest_MS', label: 'Forest, MS' },
  { value: 'Forest_OH', label: 'Forest, OH' },
  { value: 'Forest_IN', label: 'Forest, IN' },
  { value: 'Forest_LA', label: 'Forest, LA' },
  { value: 'Forest City_PA', label: 'Forest City, PA' },
  { value: 'Forest City_NC', label: 'Forest City, NC' },
  { value: 'Forest City_IA', label: 'Forest City, IA' },
  { value: 'Forest City_IL', label: 'Forest City, IL' },
  { value: 'Forest City_MO', label: 'Forest City, MO' },
  { value: 'Forest Falls_CA', label: 'Forest Falls, CA' },
  { value: 'Forest Grove_MT', label: 'Forest Grove, MT' },
  { value: 'Forest Grove_OR', label: 'Forest Grove, OR' },
  { value: 'Forest Hill_MD', label: 'Forest Hill, MD' },
  { value: 'Forest Hill_WV', label: 'Forest Hill, WV' },
  { value: 'Forest Hill_LA', label: 'Forest Hill, LA' },
  { value: 'Forest Hills_NY', label: 'Forest Hills, NY' },
  { value: 'Forest Hills_KY', label: 'Forest Hills, KY' },
  { value: 'Forest Home_AL', label: 'Forest Home, AL' },
  { value: 'Forest Junction_WI', label: 'Forest Junction, WI' },
  { value: 'Forest Knolls_CA', label: 'Forest Knolls, CA' },
  { value: 'Forest Lake_MN', label: 'Forest Lake, MN' },
  { value: 'Forest Lakes_AZ', label: 'Forest Lakes, AZ' },
  { value: 'Forest Park_GA', label: 'Forest Park, GA' },
  { value: 'Forest Park_IL', label: 'Forest Park, IL' },
  { value: 'Forest Ranch_CA', label: 'Forest Ranch, CA' },
  { value: 'Forest River_ND', label: 'Forest River, ND' },
  { value: 'Forestburg_TX', label: 'Forestburg, TX' },
  { value: 'Forestburgh_NY', label: 'Forestburgh, NY' },
  { value: 'Forestdale_MA', label: 'Forestdale, MA' },
  { value: 'Forestdale_RI', label: 'Forestdale, RI' },
  { value: 'Foresthill_CA', label: 'Foresthill, CA' },
  { value: 'Foreston_MN', label: 'Foreston, MN' },
  { value: 'Forestport_NY', label: 'Forestport, NY' },
  { value: 'Forestville_NY', label: 'Forestville, NY' },
  { value: 'Forestville_PA', label: 'Forestville, PA' },
  { value: 'Forestville_MI', label: 'Forestville, MI' },
  { value: 'Forestville_WI', label: 'Forestville, WI' },
  { value: 'Forestville_CA', label: 'Forestville, CA' },
  { value: 'Forgan_OK', label: 'Forgan, OK' },
  { value: 'Foristell_MO', label: 'Foristell, MO' },
  { value: 'Fork_MD', label: 'Fork, MD' },
  { value: 'Fork_SC', label: 'Fork, SC' },
  { value: 'Fork Union_VA', label: 'Fork Union, VA' },
  { value: 'Forked River_NJ', label: 'Forked River, NJ' },
  { value: 'Forkland_AL', label: 'Forkland, AL' },
  { value: 'Forks_WA', label: 'Forks, WA' },
  { value: 'Forks Of Salmon_CA', label: 'Forks Of Salmon, CA' },
  { value: 'Forksville_PA', label: 'Forksville, PA' },
  { value: 'Forman_ND', label: 'Forman, ND' },
  { value: 'Formoso_KS', label: 'Formoso, KS' },
  { value: 'Forney_TX', label: 'Forney, TX' },
  { value: 'Forrest_IL', label: 'Forrest, IL' },
  { value: 'Forrest City_AR', label: 'Forrest City, AR' },
  { value: 'Forreston_IL', label: 'Forreston, IL' },
  { value: 'Forreston_TX', label: 'Forreston, TX' },
  { value: 'Forsan_TX', label: 'Forsan, TX' },
  { value: 'Forsyth_GA', label: 'Forsyth, GA' },
  { value: 'Forsyth_MT', label: 'Forsyth, MT' },
  { value: 'Forsyth_IL', label: 'Forsyth, IL' },
  { value: 'Forsyth_MO', label: 'Forsyth, MO' },
  { value: 'Fort Ann_NY', label: 'Fort Ann, NY' },
  { value: 'Fort Apache_AZ', label: 'Fort Apache, AZ' },
  { value: 'Fort Ashby_WV', label: 'Fort Ashby, WV' },
  { value: 'Fort Atkinson_IA', label: 'Fort Atkinson, IA' },
  { value: 'Fort Atkinson_WI', label: 'Fort Atkinson, WI' },
  { value: 'Fort Belvoir_VA', label: 'Fort Belvoir, VA' },
  { value: 'Fort Benton_MT', label: 'Fort Benton, MT' },
  { value: 'Fort Bidwell_CA', label: 'Fort Bidwell, CA' },
  { value: 'Fort Blackmore_VA', label: 'Fort Blackmore, VA' },
  { value: 'Fort Bliss_TX', label: 'Fort Bliss, TX' },
  { value: 'Fort Bragg_CA', label: 'Fort Bragg, CA' },
  { value: 'Fort Branch_IN', label: 'Fort Branch, IN' },
  { value: 'Fort Bridger_WY', label: 'Fort Bridger, WY' },
  { value: 'Fort Buchanan_PR', label: 'Fort Buchanan, PR' },
  { value: 'Fort Calhoun_NE', label: 'Fort Calhoun, NE' },
  { value: 'Fort Campbell_KY', label: 'Fort Campbell, KY' },
  { value: 'Fort Cavazos_TX', label: 'Fort Cavazos, TX' },
  { value: 'Fort Cobb_OK', label: 'Fort Cobb, OK' },
  { value: 'Fort Collins_CO', label: 'Fort Collins, CO' },
  { value: 'Fort Covington_NY', label: 'Fort Covington, NY' },
  { value: 'Fort Davis_AL', label: 'Fort Davis, AL' },
  { value: 'Fort Davis_TX', label: 'Fort Davis, TX' },
  { value: 'Fort Defiance_VA', label: 'Fort Defiance, VA' },
  { value: 'Fort Defiance_AZ', label: 'Fort Defiance, AZ' },
  { value: 'Fort Deposit_AL', label: 'Fort Deposit, AL' },
  { value: 'Fort Dick_CA', label: 'Fort Dick, CA' },
  { value: 'Fort Dodge_IA', label: 'Fort Dodge, IA' },
  { value: 'Fort Dodge_KS', label: 'Fort Dodge, KS' },
  { value: 'Fort Drum_NY', label: 'Fort Drum, NY' },
  { value: 'Fort Duchesne_UT', label: 'Fort Duchesne, UT' },
  { value: 'Fort Edward_NY', label: 'Fort Edward, NY' },
  { value: 'Fort Eustis_VA', label: 'Fort Eustis, VA' },
  { value: 'Fort Fairfield_ME', label: 'Fort Fairfield, ME' },
  { value: 'Fort Gaines_GA', label: 'Fort Gaines, GA' },
  { value: 'Fort Garland_CO', label: 'Fort Garland, CO' },
  { value: 'Fort Gay_WV', label: 'Fort Gay, WV' },
  { value: 'Fort George G Meade_MD', label: 'Fort George G Meade, MD' },
  { value: 'Fort Gibson_OK', label: 'Fort Gibson, OK' },
  { value: 'Fort Gratiot_MI', label: 'Fort Gratiot, MI' },
  { value: 'Fort Gregg Adams_VA', label: 'Fort Gregg Adams, VA' },
  { value: 'Fort Hall_ID', label: 'Fort Hall, ID' },
  { value: 'Fort Hancock_TX', label: 'Fort Hancock, TX' },
  { value: 'Fort Harrison_MT', label: 'Fort Harrison, MT' },
  { value: 'Fort Hill_PA', label: 'Fort Hill, PA' },
  { value: 'Fort Howard_MD', label: 'Fort Howard, MD' },
  { value: 'Fort Huachuca_AZ', label: 'Fort Huachuca, AZ' },
  { value: 'Fort Hunter_NY', label: 'Fort Hunter, NY' },
  { value: 'Fort Irwin_CA', label: 'Fort Irwin, CA' },
  { value: 'Fort Jennings_OH', label: 'Fort Jennings, OH' },
  { value: 'Fort Johnson_NY', label: 'Fort Johnson, NY' },
  { value: 'Fort Johnson_LA', label: 'Fort Johnson, LA' },
  { value: 'Fort Jones_CA', label: 'Fort Jones, CA' },
  { value: 'Fort Kent_ME', label: 'Fort Kent, ME' },
  { value: 'Fort Klamath_OR', label: 'Fort Klamath, OR' },
  { value: 'Fort Knox_KY', label: 'Fort Knox, KY' },
  { value: 'Fort Laramie_WY', label: 'Fort Laramie, WY' },
  { value: 'Fort Lauderdale_FL', label: 'Fort Lauderdale, FL' },
  { value: 'Fort Lawn_SC', label: 'Fort Lawn, SC' },
  { value: 'Fort Leavenworth_KS', label: 'Fort Leavenworth, KS' },
  { value: 'Fort Lee_NJ', label: 'Fort Lee, NJ' },
  { value: 'Fort Leonard Wood_MO', label: 'Fort Leonard Wood, MO' },
  { value: 'Fort Liberty_NC', label: 'Fort Liberty, NC' },
  { value: 'Fort Littleton_PA', label: 'Fort Littleton, PA' },
  { value: 'Fort Loramie_OH', label: 'Fort Loramie, OH' },
  { value: 'Fort Loudon_PA', label: 'Fort Loudon, PA' },
  { value: 'Fort Lupton_CO', label: 'Fort Lupton, CO' },
  { value: 'Fort Madison_IA', label: 'Fort Madison, IA' },
  { value: 'Fort McCoy_FL', label: 'Fort McCoy, FL' },
  { value: 'Fort McKavett_TX', label: 'Fort McKavett, TX' },
  { value: 'Fort Mcdowell_AZ', label: 'Fort Mcdowell, AZ' },
  { value: 'Fort Meade_FL', label: 'Fort Meade, FL' },
  { value: 'Fort Meade_SD', label: 'Fort Meade, SD' },
  { value: 'Fort Mill_SC', label: 'Fort Mill, SC' },
  { value: 'Fort Mitchell_AL', label: 'Fort Mitchell, AL' },
  { value: 'Fort Mohave_AZ', label: 'Fort Mohave, AZ' },
  { value: 'Fort Monmouth_NJ', label: 'Fort Monmouth, NJ' },
  { value: 'Fort Monroe_VA', label: 'Fort Monroe, VA' },
  { value: 'Fort Montgomery_NY', label: 'Fort Montgomery, NY' },
  { value: 'Fort Moore_GA', label: 'Fort Moore, GA' },
  { value: 'Fort Morgan_CO', label: 'Fort Morgan, CO' },
  { value: 'Fort Myer_VA', label: 'Fort Myer, VA' },
  { value: 'Fort Myers_FL', label: 'Fort Myers, FL' },
  { value: 'Fort Myers Beach_FL', label: 'Fort Myers Beach, FL' },
  { value: 'Fort Necessity_LA', label: 'Fort Necessity, LA' },
  { value: 'Fort Novosel_AL', label: 'Fort Novosel, AL' },
  { value: 'Fort Ogden_FL', label: 'Fort Ogden, FL' },
  { value: 'Fort Oglethorpe_GA', label: 'Fort Oglethorpe, GA' },
  { value: 'Fort Payne_AL', label: 'Fort Payne, AL' },
  { value: 'Fort Peck_MT', label: 'Fort Peck, MT' },
  { value: 'Fort Pierce_FL', label: 'Fort Pierce, FL' },
  { value: 'Fort Pierre_SD', label: 'Fort Pierre, SD' },
  { value: 'Fort Plain_NY', label: 'Fort Plain, NY' },
  { value: 'Fort Ransom_ND', label: 'Fort Ransom, ND' },
  { value: 'Fort Recovery_OH', label: 'Fort Recovery, OH' },
  { value: 'Fort Riley_KS', label: 'Fort Riley, KS' },
  { value: 'Fort Ripley_MN', label: 'Fort Ripley, MN' },
  { value: 'Fort Rock_OR', label: 'Fort Rock, OR' },
  { value: 'Fort Scott_KS', label: 'Fort Scott, KS' },
  { value: 'Fort Shafter_HI', label: 'Fort Shafter, HI' },
  { value: 'Fort Shaw_MT', label: 'Fort Shaw, MT' },
  { value: 'Fort Sill_OK', label: 'Fort Sill, OK' },
  { value: 'Fort Smith_MT', label: 'Fort Smith, MT' },
  { value: 'Fort Smith_AR', label: 'Fort Smith, AR' },
  { value: 'Fort Stanton_NM', label: 'Fort Stanton, NM' },
  { value: 'Fort Stewart_GA', label: 'Fort Stewart, GA' },
  { value: 'Fort Stockton_TX', label: 'Fort Stockton, TX' },
  { value: 'Fort Sumner_NM', label: 'Fort Sumner, NM' },
  { value: 'Fort Supply_OK', label: 'Fort Supply, OK' },
  { value: 'Fort Thomas_KY', label: 'Fort Thomas, KY' },
  { value: 'Fort Thomas_AZ', label: 'Fort Thomas, AZ' },
  { value: 'Fort Thompson_SD', label: 'Fort Thompson, SD' },
  { value: 'Fort Totten_ND', label: 'Fort Totten, ND' },
  { value: 'Fort Towson_OK', label: 'Fort Towson, OK' },
  { value: 'Fort Valley_VA', label: 'Fort Valley, VA' },
  { value: 'Fort Valley_GA', label: 'Fort Valley, GA' },
  { value: 'Fort Wainwright_AK', label: 'Fort Wainwright, AK' },
  { value: 'Fort Walton Beach_FL', label: 'Fort Walton Beach, FL' },
  { value: 'Fort Washakie_WY', label: 'Fort Washakie, WY' },
  { value: 'Fort Washington_PA', label: 'Fort Washington, PA' },
  { value: 'Fort Washington_MD', label: 'Fort Washington, MD' },
  { value: 'Fort Wayne_IN', label: 'Fort Wayne, IN' },
  { value: 'Fort White_FL', label: 'Fort White, FL' },
  { value: 'Fort Wingate_NM', label: 'Fort Wingate, NM' },
  { value: 'Fort Worth_TX', label: 'Fort Worth, TX' },
  { value: 'Fort Yates_ND', label: 'Fort Yates, ND' },
  { value: 'Fort Yukon_AK', label: 'Fort Yukon, AK' },
  { value: 'Fortescue_NJ', label: 'Fortescue, NJ' },
  { value: 'Fortine_MT', label: 'Fortine, MT' },
  { value: 'Fortson_GA', label: 'Fortson, GA' },
  { value: 'Fortuna_ND', label: 'Fortuna, ND' },
  { value: 'Fortuna_MO', label: 'Fortuna, MO' },
  { value: 'Fortuna_CA', label: 'Fortuna, CA' },
  { value: 'Fortville_IN', label: 'Fortville, IN' },
  { value: 'Foss_OK', label: 'Foss, OK' },
  { value: 'Fossil_OR', label: 'Fossil, OR' },
  { value: 'Fosston_MN', label: 'Fosston, MN' },
  { value: 'Foster_RI', label: 'Foster, RI' },
  { value: 'Foster_VA', label: 'Foster, VA' },
  { value: 'Foster_WV', label: 'Foster, WV' },
  { value: 'Foster_KY', label: 'Foster, KY' },
  { value: 'Foster_MO', label: 'Foster, MO' },
  { value: 'Foster_OK', label: 'Foster, OK' },
  { value: 'Foster_OR', label: 'Foster, OR' },
  { value: 'Foster City_MI', label: 'Foster City, MI' },
  { value: 'Fosters_AL', label: 'Fosters, AL' },
  { value: 'Fostoria_OH', label: 'Fostoria, OH' },
  { value: 'Fostoria_MI', label: 'Fostoria, MI' },
  { value: 'Fostoria_IA', label: 'Fostoria, IA' },
  { value: 'Fouke_AR', label: 'Fouke, AR' },
  { value: 'Fountain_NC', label: 'Fountain, NC' },
  { value: 'Fountain_FL', label: 'Fountain, FL' },
  { value: 'Fountain_MI', label: 'Fountain, MI' },
  { value: 'Fountain_MN', label: 'Fountain, MN' },
  { value: 'Fountain_CO', label: 'Fountain, CO' },
  { value: 'Fountain City_IN', label: 'Fountain City, IN' },
  { value: 'Fountain City_WI', label: 'Fountain City, WI' },
  { value: 'Fountain Green_UT', label: 'Fountain Green, UT' },
  { value: 'Fountain Hill_AR', label: 'Fountain Hill, AR' },
  { value: 'Fountain Hills_AZ', label: 'Fountain Hills, AZ' },
  { value: 'Fountain Inn_SC', label: 'Fountain Inn, SC' },
  { value: 'Fountain Run_KY', label: 'Fountain Run, KY' },
  { value: 'Fountain Valley_CA', label: 'Fountain Valley, CA' },
  { value: 'Fountaintown_IN', label: 'Fountaintown, IN' },
  { value: 'Fountainville_PA', label: 'Fountainville, PA' },
  { value: 'Four Lakes_WA', label: 'Four Lakes, WA' },
  { value: 'Four Oaks_NC', label: 'Four Oaks, NC' },
  { value: 'Four States_WV', label: 'Four States, WV' },
  { value: 'Fourmile_KY', label: 'Fourmile, KY' },
  { value: 'Fowler_OH', label: 'Fowler, OH' },
  { value: 'Fowler_IN', label: 'Fowler, IN' },
  { value: 'Fowler_MI', label: 'Fowler, MI' },
  { value: 'Fowler_IL', label: 'Fowler, IL' },
  { value: 'Fowler_KS', label: 'Fowler, KS' },
  { value: 'Fowler_CO', label: 'Fowler, CO' },
  { value: 'Fowler_CA', label: 'Fowler, CA' },
  { value: 'Fowlerton_IN', label: 'Fowlerton, IN' },
  { value: 'Fowlerton_TX', label: 'Fowlerton, TX' },
  { value: 'Fowlerville_MI', label: 'Fowlerville, MI' },
  { value: 'Fox_AR', label: 'Fox, AR' },
  { value: 'Fox_OK', label: 'Fox, OK' },
  { value: 'Fox Island_WA', label: 'Fox Island, WA' },
  { value: 'Fox Lake_WI', label: 'Fox Lake, WI' },
  { value: 'Fox Lake_IL', label: 'Fox Lake, IL' },
  { value: 'Fox River Grove_IL', label: 'Fox River Grove, IL' },
  { value: 'Foxboro_MA', label: 'Foxboro, MA' },
  { value: 'Foxboro_WI', label: 'Foxboro, WI' },
  { value: 'Foxburg_PA', label: 'Foxburg, PA' },
  { value: 'Foxhome_MN', label: 'Foxhome, MN' },
  { value: 'Foxworth_MS', label: 'Foxworth, MS' },
  { value: 'Frackville_PA', label: 'Frackville, PA' },
  { value: 'Frakes_KY', label: 'Frakes, KY' },
  { value: 'Frametown_WV', label: 'Frametown, WV' },
  { value: 'Framingham_MA', label: 'Framingham, MA' },
  { value: 'Francestown_NH', label: 'Francestown, NH' },
  { value: 'Francesville_IN', label: 'Francesville, IN' },
  { value: 'Francis_OK', label: 'Francis, OK' },
  { value: 'Francis Creek_WI', label: 'Francis Creek, WI' },
  { value: 'Francisco_IN', label: 'Francisco, IN' },
  { value: 'Francitas_TX', label: 'Francitas, TX' },
  { value: 'Franconia_NH', label: 'Franconia, NH' },
  { value: 'Frankenmuth_MI', label: 'Frankenmuth, MI' },
  { value: 'Frankewing_TN', label: 'Frankewing, TN' },
  { value: 'Frankford_DE', label: 'Frankford, DE' },
  { value: 'Frankford_WV', label: 'Frankford, WV' },
  { value: 'Frankford_MO', label: 'Frankford, MO' },
  { value: 'Frankfort_ME', label: 'Frankfort, ME' },
  { value: 'Frankfort_NY', label: 'Frankfort, NY' },
  { value: 'Frankfort_KY', label: 'Frankfort, KY' },
  { value: 'Frankfort_OH', label: 'Frankfort, OH' },
  { value: 'Frankfort_IN', label: 'Frankfort, IN' },
  { value: 'Frankfort_MI', label: 'Frankfort, MI' },
  { value: 'Frankfort_SD', label: 'Frankfort, SD' },
  { value: 'Frankfort_IL', label: 'Frankfort, IL' },
  { value: 'Frankfort_KS', label: 'Frankfort, KS' },
  { value: 'Franklin_MA', label: 'Franklin, MA' },
  { value: 'Franklin_NH', label: 'Franklin, NH' },
  { value: 'Franklin_ME', label: 'Franklin, ME' },
  { value: 'Franklin_VT', label: 'Franklin, VT' },
  { value: 'Franklin_NJ', label: 'Franklin, NJ' },
  { value: 'Franklin_NY', label: 'Franklin, NY' },
  { value: 'Franklin_PA', label: 'Franklin, PA' },
  { value: 'Franklin_VA', label: 'Franklin, VA' },
  { value: 'Franklin_WV', label: 'Franklin, WV' },
  { value: 'Franklin_NC', label: 'Franklin, NC' },
  { value: 'Franklin_GA', label: 'Franklin, GA' },
  { value: 'Franklin_AL', label: 'Franklin, AL' },
  { value: 'Franklin_TN', label: 'Franklin, TN' },
  { value: 'Franklin_KY', label: 'Franklin, KY' },
  { value: 'Franklin_OH', label: 'Franklin, OH' },
  { value: 'Franklin_IN', label: 'Franklin, IN' },
  { value: 'Franklin_MI', label: 'Franklin, MI' },
  { value: 'Franklin_WI', label: 'Franklin, WI' },
  { value: 'Franklin_MN', label: 'Franklin, MN' },
  { value: 'Franklin_IL', label: 'Franklin, IL' },
  { value: 'Franklin_MO', label: 'Franklin, MO' },
  { value: 'Franklin_KS', label: 'Franklin, KS' },
  { value: 'Franklin_NE', label: 'Franklin, NE' },
  { value: 'Franklin_LA', label: 'Franklin, LA' },
  { value: 'Franklin_AR', label: 'Franklin, AR' },
  { value: 'Franklin_TX', label: 'Franklin, TX' },
  { value: 'Franklin_ID', label: 'Franklin, ID' },
  { value: 'Franklin Furnace_OH', label: 'Franklin Furnace, OH' },
  { value: 'Franklin Grove_IL', label: 'Franklin Grove, IL' },
  { value: 'Franklin Lakes_NJ', label: 'Franklin Lakes, NJ' },
  { value: 'Franklin Park_NJ', label: 'Franklin Park, NJ' },
  { value: 'Franklin Park_IL', label: 'Franklin Park, IL' },
  { value: 'Franklin Springs_GA', label: 'Franklin Springs, GA' },
  { value: 'Franklin Square_NY', label: 'Franklin Square, NY' },
  { value: 'Franklinton_NC', label: 'Franklinton, NC' },
  { value: 'Franklinton_LA', label: 'Franklinton, LA' },
  { value: 'Franklintown_PA', label: 'Franklintown, PA' },
  { value: 'Franklinville_NJ', label: 'Franklinville, NJ' },
  { value: 'Franklinville_NY', label: 'Franklinville, NY' },
  { value: 'Franklinville_NC', label: 'Franklinville, NC' },
  { value: 'Frankston_TX', label: 'Frankston, TX' },
  { value: 'Franksville_WI', label: 'Franksville, WI' },
  { value: 'Frankton_IN', label: 'Frankton, IN' },
  { value: 'Franktown_VA', label: 'Franktown, VA' },
  { value: 'Franktown_CO', label: 'Franktown, CO' },
  { value: 'Frankville_AL', label: 'Frankville, AL' },
  { value: 'Frannie_WY', label: 'Frannie, WY' },
  { value: 'Fraser_MI', label: 'Fraser, MI' },
  { value: 'Fraser_CO', label: 'Fraser, CO' },
  { value: 'Frazee_MN', label: 'Frazee, MN' },
  { value: 'Frazer_MT', label: 'Frazer, MT' },
  { value: 'Frazeysburg_OH', label: 'Frazeysburg, OH' },
  { value: 'Frazier Park_CA', label: 'Frazier Park, CA' },
  { value: 'Fraziers Bottom_WV', label: 'Fraziers Bottom, WV' },
  { value: 'Fred_TX', label: 'Fred, TX' },
  { value: 'Frederic_MI', label: 'Frederic, MI' },
  { value: 'Frederic_WI', label: 'Frederic, WI' },
  { value: 'Frederica_DE', label: 'Frederica, DE' },
  { value: 'Frederick_PA', label: 'Frederick, PA' },
  { value: 'Frederick_MD', label: 'Frederick, MD' },
  { value: 'Frederick_SD', label: 'Frederick, SD' },
  { value: 'Frederick_IL', label: 'Frederick, IL' },
  { value: 'Frederick_OK', label: 'Frederick, OK' },
  { value: 'Frederick_CO', label: 'Frederick, CO' },
  { value: 'Fredericksburg_PA', label: 'Fredericksburg, PA' },
  { value: 'Fredericksburg_VA', label: 'Fredericksburg, VA' },
  { value: 'Fredericksburg_OH', label: 'Fredericksburg, OH' },
  { value: 'Fredericksburg_IN', label: 'Fredericksburg, IN' },
  { value: 'Fredericksburg_IA', label: 'Fredericksburg, IA' },
  { value: 'Fredericksburg_TX', label: 'Fredericksburg, TX' },
  { value: 'Fredericktown_PA', label: 'Fredericktown, PA' },
  { value: 'Fredericktown_OH', label: 'Fredericktown, OH' },
  { value: 'Fredericktown_MO', label: 'Fredericktown, MO' },
  { value: 'Frederika_IA', label: 'Frederika, IA' },
  { value: 'Frederiksted_VI', label: 'Frederiksted, VI' },
  { value: 'Fredonia_NY', label: 'Fredonia, NY' },
  { value: 'Fredonia_PA', label: 'Fredonia, PA' },
  { value: 'Fredonia_KY', label: 'Fredonia, KY' },
  { value: 'Fredonia_WI', label: 'Fredonia, WI' },
  { value: 'Fredonia_ND', label: 'Fredonia, ND' },
  { value: 'Fredonia_KS', label: 'Fredonia, KS' },
  { value: 'Fredonia_TX', label: 'Fredonia, TX' },
  { value: 'Fredonia_AZ', label: 'Fredonia, AZ' },
  { value: 'Free Soil_MI', label: 'Free Soil, MI' },
  { value: 'Free Union_VA', label: 'Free Union, VA' },
  { value: 'Freeborn_MN', label: 'Freeborn, MN' },
  { value: 'Freeburg_PA', label: 'Freeburg, PA' },
  { value: 'Freeburg_IL', label: 'Freeburg, IL' },
  { value: 'Freeburg_MO', label: 'Freeburg, MO' },
  { value: 'Freeburn_KY', label: 'Freeburn, KY' },
  { value: 'Freedom_NH', label: 'Freedom, NH' },
  { value: 'Freedom_ME', label: 'Freedom, ME' },
  { value: 'Freedom_NY', label: 'Freedom, NY' },
  { value: 'Freedom_PA', label: 'Freedom, PA' },
  { value: 'Freedom_IN', label: 'Freedom, IN' },
  { value: 'Freedom_OK', label: 'Freedom, OK' },
  { value: 'Freedom_WY', label: 'Freedom, WY' },
  { value: 'Freedom_CA', label: 'Freedom, CA' },
  { value: 'Freehold_NJ', label: 'Freehold, NJ' },
  { value: 'Freehold_NY', label: 'Freehold, NY' },
  { value: 'Freeland_PA', label: 'Freeland, PA' },
  { value: 'Freeland_MD', label: 'Freeland, MD' },
  { value: 'Freeland_MI', label: 'Freeland, MI' },
  { value: 'Freeland_WA', label: 'Freeland, WA' },
  { value: 'Freelandville_IN', label: 'Freelandville, IN' },
  { value: 'Freeman_VA', label: 'Freeman, VA' },
  { value: 'Freeman_WV', label: 'Freeman, WV' },
  { value: 'Freeman_SD', label: 'Freeman, SD' },
  { value: 'Freeman_MO', label: 'Freeman, MO' },
  { value: 'Freeman Spur_IL', label: 'Freeman Spur, IL' },
  { value: 'Freeport_ME', label: 'Freeport, ME' },
  { value: 'Freeport_NY', label: 'Freeport, NY' },
  { value: 'Freeport_PA', label: 'Freeport, PA' },
  { value: 'Freeport_FL', label: 'Freeport, FL' },
  { value: 'Freeport_OH', label: 'Freeport, OH' },
  { value: 'Freeport_MI', label: 'Freeport, MI' },
  { value: 'Freeport_MN', label: 'Freeport, MN' },
  { value: 'Freeport_IL', label: 'Freeport, IL' },
  { value: 'Freeport_KS', label: 'Freeport, KS' },
  { value: 'Freeport_TX', label: 'Freeport, TX' },
  { value: 'Freer_TX', label: 'Freer, TX' },
  { value: 'Freetown_IN', label: 'Freetown, IN' },
  { value: 'Freeville_NY', label: 'Freeville, NY' },
  { value: 'Freistatt_MO', label: 'Freistatt, MO' },
  { value: 'Fremont_NH', label: 'Fremont, NH' },
  { value: 'Fremont_NC', label: 'Fremont, NC' },
  { value: 'Fremont_OH', label: 'Fremont, OH' },
  { value: 'Fremont_IN', label: 'Fremont, IN' },
  { value: 'Fremont_MI', label: 'Fremont, MI' },
  { value: 'Fremont_IA', label: 'Fremont, IA' },
  { value: 'Fremont_WI', label: 'Fremont, WI' },
  { value: 'Fremont_MO', label: 'Fremont, MO' },
  { value: 'Fremont_NE', label: 'Fremont, NE' },
  { value: 'Fremont_CA', label: 'Fremont, CA' },
  { value: 'Fremont Center_NY', label: 'Fremont Center, NY' },
  { value: 'French Camp_MS', label: 'French Camp, MS' },
  { value: 'French Camp_CA', label: 'French Camp, CA' },
  { value: 'French Creek_WV', label: 'French Creek, WV' },
  { value: 'French Gulch_CA', label: 'French Gulch, CA' },
  { value: 'French Lick_IN', label: 'French Lick, IN' },
  { value: 'French Settlement_LA', label: 'French Settlement, LA' },
  { value: 'French Village_MO', label: 'French Village, MO' },
  { value: 'Frenchboro_ME', label: 'Frenchboro, ME' },
  { value: 'Frenchburg_KY', label: 'Frenchburg, KY' },
  { value: 'Frenchglen_OR', label: 'Frenchglen, OR' },
  { value: 'Frenchmans Bayou_AR', label: 'Frenchmans Bayou, AR' },
  { value: 'Frenchtown_NJ', label: 'Frenchtown, NJ' },
  { value: 'Frenchtown_MT', label: 'Frenchtown, MT' },
  { value: 'Frenchville_ME', label: 'Frenchville, ME' },
  { value: 'Frenchville_PA', label: 'Frenchville, PA' },
  { value: 'Fresh Meadows_NY', label: 'Fresh Meadows, NY' },
  { value: 'Fresno_OH', label: 'Fresno, OH' },
  { value: 'Fresno_TX', label: 'Fresno, TX' },
  { value: 'Fresno_CA', label: 'Fresno, CA' },
  { value: 'Frewsburg_NY', label: 'Frewsburg, NY' },
  { value: 'Friant_CA', label: 'Friant, CA' },
  { value: 'Friars Point_MS', label: 'Friars Point, MS' },
  { value: 'Friday Harbor_WA', label: 'Friday Harbor, WA' },
  { value: 'Friedens_PA', label: 'Friedens, PA' },
  { value: 'Friedensburg_PA', label: 'Friedensburg, PA' },
  { value: 'Friedheim_MO', label: 'Friedheim, MO' },
  { value: 'Friend_NE', label: 'Friend, NE' },
  { value: 'Friendly_WV', label: 'Friendly, WV' },
  { value: 'Friendship_ME', label: 'Friendship, ME' },
  { value: 'Friendship_NY', label: 'Friendship, NY' },
  { value: 'Friendship_MD', label: 'Friendship, MD' },
  { value: 'Friendship_TN', label: 'Friendship, TN' },
  { value: 'Friendship_OH', label: 'Friendship, OH' },
  { value: 'Friendship_IN', label: 'Friendship, IN' },
  { value: 'Friendship_WI', label: 'Friendship, WI' },
  { value: 'Friendship_AR', label: 'Friendship, AR' },
  { value: 'Friendsville_PA', label: 'Friendsville, PA' },
  { value: 'Friendsville_MD', label: 'Friendsville, MD' },
  { value: 'Friendsville_TN', label: 'Friendsville, TN' },
  { value: 'Friendswood_TX', label: 'Friendswood, TX' },
  { value: 'Frierson_LA', label: 'Frierson, LA' },
  { value: 'Fries_VA', label: 'Fries, VA' },
  { value: 'Friesland_WI', label: 'Friesland, WI' },
  { value: 'Friona_TX', label: 'Friona, TX' },
  { value: 'Frisco_NC', label: 'Frisco, NC' },
  { value: 'Frisco_TX', label: 'Frisco, TX' },
  { value: 'Frisco_CO', label: 'Frisco, CO' },
  { value: 'Frisco City_AL', label: 'Frisco City, AL' },
  { value: 'Fritch_TX', label: 'Fritch, TX' },
  { value: 'Frohna_MO', label: 'Frohna, MO' },
  { value: 'Froid_MT', label: 'Froid, MT' },
  { value: 'Fromberg_MT', label: 'Fromberg, MT' },
  { value: 'Front Royal_VA', label: 'Front Royal, VA' },
  { value: 'Frontenac_MN', label: 'Frontenac, MN' },
  { value: 'Frontenac_KS', label: 'Frontenac, KS' },
  { value: 'Frontier_WY', label: 'Frontier, WY' },
  { value: 'Frost_MN', label: 'Frost, MN' },
  { value: 'Frost_TX', label: 'Frost, TX' },
  { value: 'Frostburg_MD', label: 'Frostburg, MD' },
  { value: 'Frostproof_FL', label: 'Frostproof, FL' },
  { value: 'Fruita_CO', label: 'Fruita, CO' },
  { value: 'Fruitdale_AL', label: 'Fruitdale, AL' },
  { value: 'Fruithurst_AL', label: 'Fruithurst, AL' },
  { value: 'Fruitland_MD', label: 'Fruitland, MD' },
  { value: 'Fruitland_IA', label: 'Fruitland, IA' },
  { value: 'Fruitland_ID', label: 'Fruitland, ID' },
  { value: 'Fruitland_UT', label: 'Fruitland, UT' },
  { value: 'Fruitland_NM', label: 'Fruitland, NM' },
  { value: 'Fruitland_WA', label: 'Fruitland, WA' },
  { value: 'Fruitland Park_FL', label: 'Fruitland Park, FL' },
  { value: 'Fruitport_MI', label: 'Fruitport, MI' },
  { value: 'Fruitvale_TX', label: 'Fruitvale, TX' },
  { value: 'Fryburg_PA', label: 'Fryburg, PA' },
  { value: 'Fryeburg_ME', label: 'Fryeburg, ME' },
  { value: 'Ft Mitchell_KY', label: 'Ft Mitchell, KY' },
  { value: 'Fulda_MN', label: 'Fulda, MN' },
  { value: 'Fulks Run_VA', label: 'Fulks Run, VA' },
  { value: 'Fullerton_ND', label: 'Fullerton, ND' },
  { value: 'Fullerton_NE', label: 'Fullerton, NE' },
  { value: 'Fullerton_CA', label: 'Fullerton, CA' },
  { value: 'Fulshear_TX', label: 'Fulshear, TX' },
  { value: 'Fulton_NY', label: 'Fulton, NY' },
  { value: 'Fulton_MD', label: 'Fulton, MD' },
  { value: 'Fulton_AL', label: 'Fulton, AL' },
  { value: 'Fulton_MS', label: 'Fulton, MS' },
  { value: 'Fulton_KY', label: 'Fulton, KY' },
  { value: 'Fulton_OH', label: 'Fulton, OH' },
  { value: 'Fulton_IN', label: 'Fulton, IN' },
  { value: 'Fulton_MI', label: 'Fulton, MI' },
  { value: 'Fulton_SD', label: 'Fulton, SD' },
  { value: 'Fulton_IL', label: 'Fulton, IL' },
  { value: 'Fulton_MO', label: 'Fulton, MO' },
  { value: 'Fulton_KS', label: 'Fulton, KS' },
  { value: 'Fulton_AR', label: 'Fulton, AR' },
  { value: 'Fulton_TX', label: 'Fulton, TX' },
  { value: 'Fulton_CA', label: 'Fulton, CA' },
  { value: 'Fultondale_AL', label: 'Fultondale, AL' },
  { value: 'Fultonham_NY', label: 'Fultonham, NY' },
  { value: 'Fultonham_OH', label: 'Fultonham, OH' },
  { value: 'Fultonville_NY', label: 'Fultonville, NY' },
  { value: 'Fults_IL', label: 'Fults, IL' },
  { value: 'Funk_NE', label: 'Funk, NE' },
  { value: 'Funkstown_MD', label: 'Funkstown, MD' },
  { value: 'Funston_GA', label: 'Funston, GA' },
  { value: 'Fuquay Varina_NC', label: 'Fuquay Varina, NC' },
  { value: 'Furlong_PA', label: 'Furlong, PA' },
  { value: 'Furman_SC', label: 'Furman, SC' },
  { value: 'Furman_AL', label: 'Furman, AL' },
  { value: 'Fyffe_AL', label: 'Fyffe, AL' },
  { value: 'Gaastra_MI', label: 'Gaastra, MI' },
  { value: 'Gabbs_NV', label: 'Gabbs, NV' },
  { value: 'Gable_SC', label: 'Gable, SC' },
  { value: 'Gabriels_NY', label: 'Gabriels, NY' },
  { value: 'Gackle_ND', label: 'Gackle, ND' },
  { value: 'Gadsden_SC', label: 'Gadsden, SC' },
  { value: 'Gadsden_AL', label: 'Gadsden, AL' },
  { value: 'Gadsden_TN', label: 'Gadsden, TN' },
  { value: 'Gaffney_SC', label: 'Gaffney, SC' },
  { value: 'Gage_OK', label: 'Gage, OK' },
  { value: 'Gagetown_MI', label: 'Gagetown, MI' },
  { value: 'Gail_TX', label: 'Gail, TX' },
  { value: 'Gaines_PA', label: 'Gaines, PA' },
  { value: 'Gaines_MI', label: 'Gaines, MI' },
  { value: 'Gainesboro_TN', label: 'Gainesboro, TN' },
  { value: 'Gainestown_AL', label: 'Gainestown, AL' },
  { value: 'Gainesville_NY', label: 'Gainesville, NY' },
  { value: 'Gainesville_VA', label: 'Gainesville, VA' },
  { value: 'Gainesville_GA', label: 'Gainesville, GA' },
  { value: 'Gainesville_FL', label: 'Gainesville, FL' },
  { value: 'Gainesville_AL', label: 'Gainesville, AL' },
  { value: 'Gainesville_MO', label: 'Gainesville, MO' },
  { value: 'Gainesville_TX', label: 'Gainesville, TX' },
  { value: 'Gaithersburg_MD', label: 'Gaithersburg, MD' },
  { value: 'Gakona_AK', label: 'Gakona, AK' },
  { value: 'Galata_MT', label: 'Galata, MT' },
  { value: 'Galatia_IL', label: 'Galatia, IL' },
  { value: 'Galax_VA', label: 'Galax, VA' },
  { value: 'Galena_MD', label: 'Galena, MD' },
  { value: 'Galena_OH', label: 'Galena, OH' },
  { value: 'Galena_IL', label: 'Galena, IL' },
  { value: 'Galena_MO', label: 'Galena, MO' },
  { value: 'Galena_KS', label: 'Galena, KS' },
  { value: 'Galena_AK', label: 'Galena, AK' },
  { value: 'Galena Park_TX', label: 'Galena Park, TX' },
  { value: 'Gales Creek_OR', label: 'Gales Creek, OR' },
  { value: 'Gales Ferry_CT', label: 'Gales Ferry, CT' },
  { value: 'Galesburg_MI', label: 'Galesburg, MI' },
  { value: 'Galesburg_ND', label: 'Galesburg, ND' },
  { value: 'Galesburg_IL', label: 'Galesburg, IL' },
  { value: 'Galesburg_KS', label: 'Galesburg, KS' },
  { value: 'Galesville_MD', label: 'Galesville, MD' },
  { value: 'Galesville_WI', label: 'Galesville, WI' },
  { value: 'Galeton_PA', label: 'Galeton, PA' },
  { value: 'Galeton_CO', label: 'Galeton, CO' },
  { value: 'Galien_MI', label: 'Galien, MI' },
  { value: 'Galion_OH', label: 'Galion, OH' },
  { value: 'Galivants Ferry_SC', label: 'Galivants Ferry, SC' },
  { value: 'Gallagher_WV', label: 'Gallagher, WV' },
  { value: 'Gallant_AL', label: 'Gallant, AL' },
  { value: 'Gallatin_TN', label: 'Gallatin, TN' },
  { value: 'Gallatin_MO', label: 'Gallatin, MO' },
  { value: 'Gallatin_TX', label: 'Gallatin, TX' },
  { value: 'Gallatin Gateway_MT', label: 'Gallatin Gateway, MT' },
  { value: 'Gallaway_TN', label: 'Gallaway, TN' },
  { value: 'Galliano_LA', label: 'Galliano, LA' },
  { value: 'Gallina_NM', label: 'Gallina, NM' },
  { value: 'Gallion_AL', label: 'Gallion, AL' },
  { value: 'Gallipolis_OH', label: 'Gallipolis, OH' },
  { value: 'Gallipolis Ferry_WV', label: 'Gallipolis Ferry, WV' },
  { value: 'Gallitzin_PA', label: 'Gallitzin, PA' },
  { value: 'Galloway_WV', label: 'Galloway, WV' },
  { value: 'Galloway_OH', label: 'Galloway, OH' },
  { value: 'Gallup_NM', label: 'Gallup, NM' },
  { value: 'Galt_IA', label: 'Galt, IA' },
  { value: 'Galt_IL', label: 'Galt, IL' },
  { value: 'Galt_MO', label: 'Galt, MO' },
  { value: 'Galt_CA', label: 'Galt, CA' },
  { value: 'Galva_IA', label: 'Galva, IA' },
  { value: 'Galva_IL', label: 'Galva, IL' },
  { value: 'Galva_KS', label: 'Galva, KS' },
  { value: 'Galveston_IN', label: 'Galveston, IN' },
  { value: 'Galveston_TX', label: 'Galveston, TX' },
  { value: 'Galvin_WA', label: 'Galvin, WA' },
  { value: 'Galway_NY', label: 'Galway, NY' },
  { value: 'Gamaliel_KY', label: 'Gamaliel, KY' },
  { value: 'Gamaliel_AR', label: 'Gamaliel, AR' },
  { value: 'Gambell_AK', label: 'Gambell, AK' },
  { value: 'Gambier_OH', label: 'Gambier, OH' },
  { value: 'Gambrills_MD', label: 'Gambrills, MD' },
  { value: 'Gamerco_NM', label: 'Gamerco, NM' },
  { value: 'Ganado_TX', label: 'Ganado, TX' },
  { value: 'Ganado_AZ', label: 'Ganado, AZ' },
  { value: 'Gandeeville_WV', label: 'Gandeeville, WV' },
  { value: 'Gann Valley_SD', label: 'Gann Valley, SD' },
  { value: 'Gans_OK', label: 'Gans, OK' },
  { value: 'Gansevoort_NY', label: 'Gansevoort, NY' },
  { value: 'Gantt_AL', label: 'Gantt, AL' },
  { value: 'Gap_PA', label: 'Gap, PA' },
  { value: 'Gap Mills_WV', label: 'Gap Mills, WV' },
  { value: 'Garards Fort_PA', label: 'Garards Fort, PA' },
  { value: 'Garber_IA', label: 'Garber, IA' },
  { value: 'Garber_OK', label: 'Garber, OK' },
  { value: 'Garberville_CA', label: 'Garberville, CA' },
  { value: 'Garciasville_TX', label: 'Garciasville, TX' },
  { value: 'Garden_MI', label: 'Garden, MI' },
  { value: 'Garden City_NY', label: 'Garden City, NY' },
  { value: 'Garden City_AL', label: 'Garden City, AL' },
  { value: 'Garden City_MI', label: 'Garden City, MI' },
  { value: 'Garden City_IA', label: 'Garden City, IA' },
  { value: 'Garden City_MN', label: 'Garden City, MN' },
  { value: 'Garden City_SD', label: 'Garden City, SD' },
  { value: 'Garden City_MO', label: 'Garden City, MO' },
  { value: 'Garden City_KS', label: 'Garden City, KS' },
  { value: 'Garden City_TX', label: 'Garden City, TX' },
  { value: 'Garden City_ID', label: 'Garden City, ID' },
  { value: 'Garden City_UT', label: 'Garden City, UT' },
  { value: 'Garden Grove_IA', label: 'Garden Grove, IA' },
  { value: 'Garden Grove_CA', label: 'Garden Grove, CA' },
  { value: 'Garden Plain_KS', label: 'Garden Plain, KS' },
  { value: 'Garden Prairie_IL', label: 'Garden Prairie, IL' },
  { value: 'Garden Valley_ID', label: 'Garden Valley, ID' },
  { value: 'Garden Valley_CA', label: 'Garden Valley, CA' },
  { value: 'Gardena_CA', label: 'Gardena, CA' },
  { value: 'Gardendale_AL', label: 'Gardendale, AL' },
  { value: 'Gardendale_TX', label: 'Gardendale, TX' },
  { value: 'Gardiner_ME', label: 'Gardiner, ME' },
  { value: 'Gardiner_NY', label: 'Gardiner, NY' },
  { value: 'Gardiner_MT', label: 'Gardiner, MT' },
  { value: 'Gardiner_OR', label: 'Gardiner, OR' },
  { value: 'Gardner_MA', label: 'Gardner, MA' },
  { value: 'Gardner_ND', label: 'Gardner, ND' },
  { value: 'Gardner_IL', label: 'Gardner, IL' },
  { value: 'Gardner_KS', label: 'Gardner, KS' },
  { value: 'Gardner_CO', label: 'Gardner, CO' },
  { value: 'Gardners_PA', label: 'Gardners, PA' },
  { value: 'Gardnerville_NV', label: 'Gardnerville, NV' },
  { value: 'Garfield_NJ', label: 'Garfield, NJ' },
  { value: 'Garfield_GA', label: 'Garfield, GA' },
  { value: 'Garfield_KY', label: 'Garfield, KY' },
  { value: 'Garfield_MN', label: 'Garfield, MN' },
  { value: 'Garfield_KS', label: 'Garfield, KS' },
  { value: 'Garfield_AR', label: 'Garfield, AR' },
  { value: 'Garfield_NM', label: 'Garfield, NM' },
  { value: 'Garfield_WA', label: 'Garfield, WA' },
  { value: 'Garibaldi_OR', label: 'Garibaldi, OR' },
  { value: 'Garita_NM', label: 'Garita, NM' },
  { value: 'Garland_ME', label: 'Garland, ME' },
  { value: 'Garland_PA', label: 'Garland, PA' },
  { value: 'Garland_NC', label: 'Garland, NC' },
  { value: 'Garland_KS', label: 'Garland, KS' },
  { value: 'Garland_NE', label: 'Garland, NE' },
  { value: 'Garland_TX', label: 'Garland, TX' },
  { value: 'Garland_UT', label: 'Garland, UT' },
  { value: 'Garland City_AR', label: 'Garland City, AR' },
  { value: 'Garnavillo_IA', label: 'Garnavillo, IA' },
  { value: 'Garner_NC', label: 'Garner, NC' },
  { value: 'Garner_KY', label: 'Garner, KY' },
  { value: 'Garner_IA', label: 'Garner, IA' },
  { value: 'Garner_AR', label: 'Garner, AR' },
  { value: 'Garnerville_NY', label: 'Garnerville, NY' },
  { value: 'Garnet Valley_PA', label: 'Garnet Valley, PA' },
  { value: 'Garnett_SC', label: 'Garnett, SC' },
  { value: 'Garnett_KS', label: 'Garnett, KS' },
  { value: 'Garrard_KY', label: 'Garrard, KY' },
  { value: 'Garrattsville_NY', label: 'Garrattsville, NY' },
  { value: 'Garretson_SD', label: 'Garretson, SD' },
  { value: 'Garrett_PA', label: 'Garrett, PA' },
  { value: 'Garrett_KY', label: 'Garrett, KY' },
  { value: 'Garrett_IN', label: 'Garrett, IN' },
  { value: 'Garrett_WY', label: 'Garrett, WY' },
  { value: 'Garrett Park_MD', label: 'Garrett Park, MD' },
  { value: 'Garrettsville_OH', label: 'Garrettsville, OH' },
  { value: 'Garrison_NY', label: 'Garrison, NY' },
  { value: 'Garrison_KY', label: 'Garrison, KY' },
  { value: 'Garrison_IA', label: 'Garrison, IA' },
  { value: 'Garrison_MN', label: 'Garrison, MN' },
  { value: 'Garrison_ND', label: 'Garrison, ND' },
  { value: 'Garrison_MT', label: 'Garrison, MT' },
  { value: 'Garrison_MO', label: 'Garrison, MO' },
  { value: 'Garrison_TX', label: 'Garrison, TX' },
  { value: 'Garrison_UT', label: 'Garrison, UT' },
  { value: 'Garrochales_PR', label: 'Garrochales, PR' },
  { value: 'Garryowen_MT', label: 'Garryowen, MT' },
  { value: 'Garvin_MN', label: 'Garvin, MN' },
  { value: 'Garvin_OK', label: 'Garvin, OK' },
  { value: 'Garwin_IA', label: 'Garwin, IA' },
  { value: 'Garwood_NJ', label: 'Garwood, NJ' },
  { value: 'Garwood_TX', label: 'Garwood, TX' },
  { value: 'Gary_WV', label: 'Gary, WV' },
  { value: 'Gary_IN', label: 'Gary, IN' },
  { value: 'Gary_MN', label: 'Gary, MN' },
  { value: 'Gary_SD', label: 'Gary, SD' },
  { value: 'Gary_TX', label: 'Gary, TX' },
  { value: 'Garysburg_NC', label: 'Garysburg, NC' },
  { value: 'Garyville_LA', label: 'Garyville, LA' },
  { value: 'Gas_KS', label: 'Gas, KS' },
  { value: 'Gas City_IN', label: 'Gas City, IN' },
  { value: 'Gasburg_VA', label: 'Gasburg, VA' },
  { value: 'Gasport_NY', label: 'Gasport, NY' },
  { value: 'Gasquet_CA', label: 'Gasquet, CA' },
  { value: 'Gassaway_WV', label: 'Gassaway, WV' },
  { value: 'Gassville_AR', label: 'Gassville, AR' },
  { value: 'Gaston_NC', label: 'Gaston, NC' },
  { value: 'Gaston_SC', label: 'Gaston, SC' },
  { value: 'Gaston_IN', label: 'Gaston, IN' },
  { value: 'Gaston_OR', label: 'Gaston, OR' },
  { value: 'Gastonia_NC', label: 'Gastonia, NC' },
  { value: 'Gate_OK', label: 'Gate, OK' },
  { value: 'Gate City_VA', label: 'Gate City, VA' },
  { value: 'Gates_NC', label: 'Gates, NC' },
  { value: 'Gates_TN', label: 'Gates, TN' },
  { value: 'Gates_OR', label: 'Gates, OR' },
  { value: 'Gates Mills_OH', label: 'Gates Mills, OH' },
  { value: 'Gatesville_NC', label: 'Gatesville, NC' },
  { value: 'Gatesville_TX', label: 'Gatesville, TX' },
  { value: 'Gateway_CO', label: 'Gateway, CO' },
  { value: 'Gatewood_MO', label: 'Gatewood, MO' },
  { value: 'Gatlinburg_TN', label: 'Gatlinburg, TN' },
  { value: 'Gattman_MS', label: 'Gattman, MS' },
  { value: 'Gatzke_MN', label: 'Gatzke, MN' },
  { value: 'Gauley Bridge_WV', label: 'Gauley Bridge, WV' },
  { value: 'Gause_TX', label: 'Gause, TX' },
  { value: 'Gautier_MS', label: 'Gautier, MS' },
  { value: 'Gay_WV', label: 'Gay, WV' },
  { value: 'Gay_GA', label: 'Gay, GA' },
  { value: 'Gaylesville_AL', label: 'Gaylesville, AL' },
  { value: 'Gaylord_MI', label: 'Gaylord, MI' },
  { value: 'Gaylord_MN', label: 'Gaylord, MN' },
  { value: 'Gaylord_KS', label: 'Gaylord, KS' },
  { value: 'Gaylordsville_CT', label: 'Gaylordsville, CT' },
  { value: 'Gays_IL', label: 'Gays, IL' },
  { value: 'Gays Creek_KY', label: 'Gays Creek, KY' },
  { value: 'Gays Mills_WI', label: 'Gays Mills, WI' },
  { value: 'Gaysville_VT', label: 'Gaysville, VT' },
  { value: 'Gayville_SD', label: 'Gayville, SD' },
  { value: 'Gazelle_CA', label: 'Gazelle, CA' },
  { value: 'Geary_OK', label: 'Geary, OK' },
  { value: 'Geddes_SD', label: 'Geddes, SD' },
  { value: 'Geff_IL', label: 'Geff, IL' },
  { value: 'Geigertown_PA', label: 'Geigertown, PA' },
  { value: 'Geismar_LA', label: 'Geismar, LA' },
  { value: 'Gem_KS', label: 'Gem, KS' },
  { value: 'Gene Autry_OK', label: 'Gene Autry, OK' },
  { value: 'Genesee_PA', label: 'Genesee, PA' },
  { value: 'Genesee_MI', label: 'Genesee, MI' },
  { value: 'Genesee_ID', label: 'Genesee, ID' },
  { value: 'Genesee Depot_WI', label: 'Genesee Depot, WI' },
  { value: 'Geneseo_NY', label: 'Geneseo, NY' },
  { value: 'Geneseo_IL', label: 'Geneseo, IL' },
  { value: 'Geneseo_KS', label: 'Geneseo, KS' },
  { value: 'Geneva_NY', label: 'Geneva, NY' },
  { value: 'Geneva_GA', label: 'Geneva, GA' },
  { value: 'Geneva_FL', label: 'Geneva, FL' },
  { value: 'Geneva_AL', label: 'Geneva, AL' },
  { value: 'Geneva_OH', label: 'Geneva, OH' },
  { value: 'Geneva_IN', label: 'Geneva, IN' },
  { value: 'Geneva_IA', label: 'Geneva, IA' },
  { value: 'Geneva_MN', label: 'Geneva, MN' },
  { value: 'Geneva_IL', label: 'Geneva, IL' },
  { value: 'Geneva_NE', label: 'Geneva, NE' },
  { value: 'Geneva_ID', label: 'Geneva, ID' },
  { value: 'Genoa_NY', label: 'Genoa, NY' },
  { value: 'Genoa_WV', label: 'Genoa, WV' },
  { value: 'Genoa_OH', label: 'Genoa, OH' },
  { value: 'Genoa_WI', label: 'Genoa, WI' },
  { value: 'Genoa_IL', label: 'Genoa, IL' },
  { value: 'Genoa_NE', label: 'Genoa, NE' },
  { value: 'Genoa_CO', label: 'Genoa, CO' },
  { value: 'Genoa_NV', label: 'Genoa, NV' },
  { value: 'Genoa City_WI', label: 'Genoa City, WI' },
  { value: 'Gentry_MO', label: 'Gentry, MO' },
  { value: 'Gentry_AR', label: 'Gentry, AR' },
  { value: 'Gentryville_IN', label: 'Gentryville, IN' },
  { value: 'George_IA', label: 'George, IA' },
  { value: 'George_WA', label: 'George, WA' },
  { value: 'George West_TX', label: 'George West, TX' },
  { value: 'Georges Mills_NH', label: 'Georges Mills, NH' },
  { value: 'Georgetown_MA', label: 'Georgetown, MA' },
  { value: 'Georgetown_ME', label: 'Georgetown, ME' },
  { value: 'Georgetown_NY', label: 'Georgetown, NY' },
  { value: 'Georgetown_PA', label: 'Georgetown, PA' },
  { value: 'Georgetown_DE', label: 'Georgetown, DE' },
  { value: 'Georgetown_MD', label: 'Georgetown, MD' },
  { value: 'Georgetown_SC', label: 'Georgetown, SC' },
  { value: 'Georgetown_FL', label: 'Georgetown, FL' },
  { value: 'Georgetown_TN', label: 'Georgetown, TN' },
  { value: 'Georgetown_MS', label: 'Georgetown, MS' },
  { value: 'Georgetown_GA', label: 'Georgetown, GA' },
  { value: 'Georgetown_KY', label: 'Georgetown, KY' },
  { value: 'Georgetown_OH', label: 'Georgetown, OH' },
  { value: 'Georgetown_IN', label: 'Georgetown, IN' },
  { value: 'Georgetown_MN', label: 'Georgetown, MN' },
  { value: 'Georgetown_IL', label: 'Georgetown, IL' },
  { value: 'Georgetown_LA', label: 'Georgetown, LA' },
  { value: 'Georgetown_TX', label: 'Georgetown, TX' },
  { value: 'Georgetown_CO', label: 'Georgetown, CO' },
  { value: 'Georgetown_ID', label: 'Georgetown, ID' },
  { value: 'Georgetown_CA', label: 'Georgetown, CA' },
  { value: 'Georgiana_AL', label: 'Georgiana, AL' },
  { value: 'Gepp_AR', label: 'Gepp, AR' },
  { value: 'Gerald_MO', label: 'Gerald, MO' },
  { value: 'Geraldine_AL', label: 'Geraldine, AL' },
  { value: 'Geraldine_MT', label: 'Geraldine, MT' },
  { value: 'Gerber_CA', label: 'Gerber, CA' },
  { value: 'Gering_NE', label: 'Gering, NE' },
  { value: 'Gerlach_NV', label: 'Gerlach, NV' },
  { value: 'Gerlaw_IL', label: 'Gerlaw, IL' },
  { value: 'German Valley_IL', label: 'German Valley, IL' },
  { value: 'Germansville_PA', label: 'Germansville, PA' },
  { value: 'Germanton_NC', label: 'Germanton, NC' },
  { value: 'Germantown_NY', label: 'Germantown, NY' },
  { value: 'Germantown_MD', label: 'Germantown, MD' },
  { value: 'Germantown_TN', label: 'Germantown, TN' },
  { value: 'Germantown_KY', label: 'Germantown, KY' },
  { value: 'Germantown_OH', label: 'Germantown, OH' },
  { value: 'Germantown_WI', label: 'Germantown, WI' },
  { value: 'Germantown_IL', label: 'Germantown, IL' },
  { value: 'Germfask_MI', label: 'Germfask, MI' },
  { value: 'Geronimo_OK', label: 'Geronimo, OK' },
  { value: 'Gerrardstown_WV', label: 'Gerrardstown, WV' },
  { value: 'Gerry_NY', label: 'Gerry, NY' },
  { value: 'Gerton_NC', label: 'Gerton, NC' },
  { value: 'Gervais_OR', label: 'Gervais, OR' },
  { value: 'Gettysburg_PA', label: 'Gettysburg, PA' },
  { value: 'Gettysburg_OH', label: 'Gettysburg, OH' },
  { value: 'Gettysburg_SD', label: 'Gettysburg, SD' },
  { value: 'Getzville_NY', label: 'Getzville, NY' },
  { value: 'Geuda Springs_KS', label: 'Geuda Springs, KS' },
  { value: 'Geyser_MT', label: 'Geyser, MT' },
  { value: 'Geyserville_CA', label: 'Geyserville, CA' },
  { value: 'Gheens_LA', label: 'Gheens, LA' },
  { value: 'Ghent_NY', label: 'Ghent, NY' },
  { value: 'Ghent_WV', label: 'Ghent, WV' },
  { value: 'Ghent_KY', label: 'Ghent, KY' },
  { value: 'Ghent_MN', label: 'Ghent, MN' },
  { value: 'Gibbon_MN', label: 'Gibbon, MN' },
  { value: 'Gibbon_NE', label: 'Gibbon, NE' },
  { value: 'Gibbon Glade_PA', label: 'Gibbon Glade, PA' },
  { value: 'Gibbonsville_ID', label: 'Gibbonsville, ID' },
  { value: 'Gibbs_MO', label: 'Gibbs, MO' },
  { value: 'Gibbsboro_NJ', label: 'Gibbsboro, NJ' },
  { value: 'Gibbstown_NJ', label: 'Gibbstown, NJ' },
  { value: 'Gibsland_LA', label: 'Gibsland, LA' },
  { value: 'Gibson_PA', label: 'Gibson, PA' },
  { value: 'Gibson_NC', label: 'Gibson, NC' },
  { value: 'Gibson_GA', label: 'Gibson, GA' },
  { value: 'Gibson_TN', label: 'Gibson, TN' },
  { value: 'Gibson_IA', label: 'Gibson, IA' },
  { value: 'Gibson_MO', label: 'Gibson, MO' },
  { value: 'Gibson_LA', label: 'Gibson, LA' },
  { value: 'Gibson City_IL', label: 'Gibson City, IL' },
  { value: 'Gibson Island_MD', label: 'Gibson Island, MD' },
  { value: 'Gibsonburg_OH', label: 'Gibsonburg, OH' },
  { value: 'Gibsonia_PA', label: 'Gibsonia, PA' },
  { value: 'Gibsonton_FL', label: 'Gibsonton, FL' },
  { value: 'Gibsonville_NC', label: 'Gibsonville, NC' },
  { value: 'Giddings_TX', label: 'Giddings, TX' },
  { value: 'Gideon_MO', label: 'Gideon, MO' },
  { value: 'Gifford_PA', label: 'Gifford, PA' },
  { value: 'Gifford_SC', label: 'Gifford, SC' },
  { value: 'Gifford_IL', label: 'Gifford, IL' },
  { value: 'Gifford_WA', label: 'Gifford, WA' },
  { value: 'Gig Harbor_WA', label: 'Gig Harbor, WA' },
  { value: 'Gila_NM', label: 'Gila, NM' },
  { value: 'Gila Bend_AZ', label: 'Gila Bend, AZ' },
  { value: 'Gilbert_PA', label: 'Gilbert, PA' },
  { value: 'Gilbert_WV', label: 'Gilbert, WV' },
  { value: 'Gilbert_SC', label: 'Gilbert, SC' },
  { value: 'Gilbert_IA', label: 'Gilbert, IA' },
  { value: 'Gilbert_MN', label: 'Gilbert, MN' },
  { value: 'Gilbert_LA', label: 'Gilbert, LA' },
  { value: 'Gilbert_AR', label: 'Gilbert, AR' },
  { value: 'Gilbert_AZ', label: 'Gilbert, AZ' },
  { value: 'Gilberton_PA', label: 'Gilberton, PA' },
  { value: 'Gilbertown_AL', label: 'Gilbertown, AL' },
  { value: 'Gilberts_IL', label: 'Gilberts, IL' },
  { value: 'Gilbertsville_NY', label: 'Gilbertsville, NY' },
  { value: 'Gilbertsville_PA', label: 'Gilbertsville, PA' },
  { value: 'Gilbertsville_KY', label: 'Gilbertsville, KY' },
  { value: 'Gilbertville_MA', label: 'Gilbertville, MA' },
  { value: 'Gilbertville_IA', label: 'Gilbertville, IA' },
  { value: 'Gilboa_NY', label: 'Gilboa, NY' },
  { value: 'Gilboa_WV', label: 'Gilboa, WV' },
  { value: 'Gilby_ND', label: 'Gilby, ND' },
  { value: 'Gilchrist_TX', label: 'Gilchrist, TX' },
  { value: 'Gilchrist_OR', label: 'Gilchrist, OR' },
  { value: 'Gilcrest_CO', label: 'Gilcrest, CO' },
  { value: 'Gildford_MT', label: 'Gildford, MT' },
  { value: 'Gile_WI', label: 'Gile, WI' },
  { value: 'Gilead_NE', label: 'Gilead, NE' },
  { value: 'Gilford_NH', label: 'Gilford, NH' },
  { value: 'Gill_MA', label: 'Gill, MA' },
  { value: 'Gill_CO', label: 'Gill, CO' },
  { value: 'Gillespie_IL', label: 'Gillespie, IL' },
  { value: 'Gillett_PA', label: 'Gillett, PA' },
  { value: 'Gillett_WI', label: 'Gillett, WI' },
  { value: 'Gillett_AR', label: 'Gillett, AR' },
  { value: 'Gillett_TX', label: 'Gillett, TX' },
  { value: 'Gillett Grove_IA', label: 'Gillett Grove, IA' },
  { value: 'Gillette_NJ', label: 'Gillette, NJ' },
  { value: 'Gillette_WY', label: 'Gillette, WY' },
  { value: 'Gillham_AR', label: 'Gillham, AR' },
  { value: 'Gilliam_MO', label: 'Gilliam, MO' },
  { value: 'Gilliam_LA', label: 'Gilliam, LA' },
  { value: 'Gillsville_GA', label: 'Gillsville, GA' },
  { value: 'Gilman_VT', label: 'Gilman, VT' },
  { value: 'Gilman_CT', label: 'Gilman, CT' },
  { value: 'Gilman_IA', label: 'Gilman, IA' },
  { value: 'Gilman_WI', label: 'Gilman, WI' },
  { value: 'Gilman_IL', label: 'Gilman, IL' },
  { value: 'Gilman City_MO', label: 'Gilman City, MO' },
  { value: 'Gilmanton_NH', label: 'Gilmanton, NH' },
  { value: 'Gilmanton Iron Works_NH', label: 'Gilmanton Iron Works, NH' },
  { value: 'Gilmer_TX', label: 'Gilmer, TX' },
  { value: 'Gilmore_AR', label: 'Gilmore, AR' },
  { value: 'Gilmore City_IA', label: 'Gilmore City, IA' },
  { value: 'Gilroy_CA', label: 'Gilroy, CA' },
  { value: 'Gilson_IL', label: 'Gilson, IL' },
  { value: 'Gilsum_NH', label: 'Gilsum, NH' },
  { value: 'Giltner_NE', label: 'Giltner, NE' },
  { value: 'Gipsy_PA', label: 'Gipsy, PA' },
  { value: 'Gipsy_MO', label: 'Gipsy, MO' },
  { value: 'Girard_PA', label: 'Girard, PA' },
  { value: 'Girard_GA', label: 'Girard, GA' },
  { value: 'Girard_OH', label: 'Girard, OH' },
  { value: 'Girard_IL', label: 'Girard, IL' },
  { value: 'Girard_KS', label: 'Girard, KS' },
  { value: 'Girard_TX', label: 'Girard, TX' },
  { value: 'Girardville_PA', label: 'Girardville, PA' },
  { value: 'Girdler_KY', label: 'Girdler, KY' },
  { value: 'Girdletree_MD', label: 'Girdletree, MD' },
  { value: 'Girdwood_AK', label: 'Girdwood, AK' },
  { value: 'Given_WV', label: 'Given, WV' },
  { value: 'Gladbrook_IA', label: 'Gladbrook, IA' },
  { value: 'Glade_KS', label: 'Glade, KS' },
  { value: 'Glade Hill_VA', label: 'Glade Hill, VA' },
  { value: 'Glade Park_CO', label: 'Glade Park, CO' },
  { value: 'Glade Spring_VA', label: 'Glade Spring, VA' },
  { value: 'Glade Valley_NC', label: 'Glade Valley, NC' },
  { value: 'Gladewater_TX', label: 'Gladewater, TX' },
  { value: 'Gladstone_NJ', label: 'Gladstone, NJ' },
  { value: 'Gladstone_VA', label: 'Gladstone, VA' },
  { value: 'Gladstone_MI', label: 'Gladstone, MI' },
  { value: 'Gladstone_ND', label: 'Gladstone, ND' },
  { value: 'Gladstone_IL', label: 'Gladstone, IL' },
  { value: 'Gladstone_NM', label: 'Gladstone, NM' },
  { value: 'Gladstone_OR', label: 'Gladstone, OR' },
  { value: 'Gladwin_MI', label: 'Gladwin, MI' },
  { value: 'Gladwyne_PA', label: 'Gladwyne, PA' },
  { value: 'Glady_WV', label: 'Glady, WV' },
  { value: 'Gladys_VA', label: 'Gladys, VA' },
  { value: 'Glandorf_OH', label: 'Glandorf, OH' },
  { value: 'Glasco_NY', label: 'Glasco, NY' },
  { value: 'Glasco_KS', label: 'Glasco, KS' },
  { value: 'Glasford_IL', label: 'Glasford, IL' },
  { value: 'Glasgow_VA', label: 'Glasgow, VA' },
  { value: 'Glasgow_WV', label: 'Glasgow, WV' },
  { value: 'Glasgow_KY', label: 'Glasgow, KY' },
  { value: 'Glasgow_MT', label: 'Glasgow, MT' },
  { value: 'Glasgow_MO', label: 'Glasgow, MO' },
  { value: 'Glassboro_NJ', label: 'Glassboro, NJ' },
  { value: 'Glassport_PA', label: 'Glassport, PA' },
  { value: 'Glasston_ND', label: 'Glasston, ND' },
  { value: 'Glastonbury_CT', label: 'Glastonbury, CT' },
  { value: 'Gleason_TN', label: 'Gleason, TN' },
  { value: 'Gleason_WI', label: 'Gleason, WI' },
  { value: 'Glen_NH', label: 'Glen, NH' },
  { value: 'Glen_WV', label: 'Glen, WV' },
  { value: 'Glen_MS', label: 'Glen, MS' },
  { value: 'Glen_MT', label: 'Glen, MT' },
  { value: 'Glen Allan_MS', label: 'Glen Allan, MS' },
  { value: 'Glen Allen_VA', label: 'Glen Allen, VA' },
  { value: 'Glen Allen_AL', label: 'Glen Allen, AL' },
  { value: 'Glen Alpine_NC', label: 'Glen Alpine, NC' },
  { value: 'Glen Arbor_MI', label: 'Glen Arbor, MI' },
  { value: 'Glen Arm_MD', label: 'Glen Arm, MD' },
  { value: 'Glen Aubrey_NY', label: 'Glen Aubrey, NY' },
  { value: 'Glen Burnie_MD', label: 'Glen Burnie, MD' },
  { value: 'Glen Campbell_PA', label: 'Glen Campbell, PA' },
  { value: 'Glen Carbon_IL', label: 'Glen Carbon, IL' },
  { value: 'Glen Cove_NY', label: 'Glen Cove, NY' },
  { value: 'Glen Dale_WV', label: 'Glen Dale, WV' },
  { value: 'Glen Daniel_WV', label: 'Glen Daniel, WV' },
  { value: 'Glen Easton_WV', label: 'Glen Easton, WV' },
  { value: 'Glen Echo_MD', label: 'Glen Echo, MD' },
  { value: 'Glen Elder_KS', label: 'Glen Elder, KS' },
  { value: 'Glen Ellen_CA', label: 'Glen Ellen, CA' },
  { value: 'Glen Ellyn_IL', label: 'Glen Ellyn, IL' },
  { value: 'Glen Ferris_WV', label: 'Glen Ferris, WV' },
  { value: 'Glen Flora_WI', label: 'Glen Flora, WI' },
  { value: 'Glen Flora_TX', label: 'Glen Flora, TX' },
  { value: 'Glen Fork_WV', label: 'Glen Fork, WV' },
  { value: 'Glen Gardner_NJ', label: 'Glen Gardner, NJ' },
  { value: 'Glen Haven_WI', label: 'Glen Haven, WI' },
  { value: 'Glen Haven_CO', label: 'Glen Haven, CO' },
  { value: 'Glen Head_NY', label: 'Glen Head, NY' },
  { value: 'Glen Hope_PA', label: 'Glen Hope, PA' },
  { value: 'Glen Jean_WV', label: 'Glen Jean, WV' },
  { value: 'Glen Lyn_VA', label: 'Glen Lyn, VA' },
  { value: 'Glen Lyon_PA', label: 'Glen Lyon, PA' },
  { value: 'Glen Mills_PA', label: 'Glen Mills, PA' },
  { value: 'Glen Oaks_NY', label: 'Glen Oaks, NY' },
  { value: 'Glen Richey_PA', label: 'Glen Richey, PA' },
  { value: 'Glen Ridge_NJ', label: 'Glen Ridge, NJ' },
  { value: 'Glen Rock_NJ', label: 'Glen Rock, NJ' },
  { value: 'Glen Rock_PA', label: 'Glen Rock, PA' },
  { value: 'Glen Rogers_WV', label: 'Glen Rogers, WV' },
  { value: 'Glen Rose_TX', label: 'Glen Rose, TX' },
  { value: 'Glen St Mary_FL', label: 'Glen St Mary, FL' },
  { value: 'Glen Spey_NY', label: 'Glen Spey, NY' },
  { value: 'Glen Ullin_ND', label: 'Glen Ullin, ND' },
  { value: 'Glen White_WV', label: 'Glen White, WV' },
  { value: 'Glen Wild_NY', label: 'Glen Wild, NY' },
  { value: 'Glenallen_MO', label: 'Glenallen, MO' },
  { value: 'Glenarm_IL', label: 'Glenarm, IL' },
  { value: 'Glenbeulah_WI', label: 'Glenbeulah, WI' },
  { value: 'Glenbrook_NV', label: 'Glenbrook, NV' },
  { value: 'Glenburn_ND', label: 'Glenburn, ND' },
  { value: 'Glencliff_NH', label: 'Glencliff, NH' },
  { value: 'Glencoe_KY', label: 'Glencoe, KY' },
  { value: 'Glencoe_OH', label: 'Glencoe, OH' },
  { value: 'Glencoe_MN', label: 'Glencoe, MN' },
  { value: 'Glencoe_IL', label: 'Glencoe, IL' },
  { value: 'Glencoe_AR', label: 'Glencoe, AR' },
  { value: 'Glencoe_OK', label: 'Glencoe, OK' },
  { value: 'Glencoe_NM', label: 'Glencoe, NM' },
  { value: 'Glencoe_CA', label: 'Glencoe, CA' },
  { value: 'Glencross_SD', label: 'Glencross, SD' },
  { value: 'Glendale_MA', label: 'Glendale, MA' },
  { value: 'Glendale_RI', label: 'Glendale, RI' },
  { value: 'Glendale_SC', label: 'Glendale, SC' },
  { value: 'Glendale_KY', label: 'Glendale, KY' },
  { value: 'Glendale_UT', label: 'Glendale, UT' },
  { value: 'Glendale_AZ', label: 'Glendale, AZ' },
  { value: 'Glendale_CA', label: 'Glendale, CA' },
  { value: 'Glendale_OR', label: 'Glendale, OR' },
  { value: 'Glendale Heights_IL', label: 'Glendale Heights, IL' },
  { value: 'Glendale Springs_NC', label: 'Glendale Springs, NC' },
  { value: 'Glendive_MT', label: 'Glendive, MT' },
  { value: 'Glendo_WY', label: 'Glendo, WY' },
  { value: 'Glendora_NJ', label: 'Glendora, NJ' },
  { value: 'Glendora_MS', label: 'Glendora, MS' },
  { value: 'Glendora_CA', label: 'Glendora, CA' },
  { value: 'Gleneden Beach_OR', label: 'Gleneden Beach, OR' },
  { value: 'Glenelg_MD', label: 'Glenelg, MD' },
  { value: 'Glenfield_NY', label: 'Glenfield, NY' },
  { value: 'Glenfield_ND', label: 'Glenfield, ND' },
  { value: 'Glenford_NY', label: 'Glenford, NY' },
  { value: 'Glenford_OH', label: 'Glenford, OH' },
  { value: 'Glengary_WV', label: 'Glengary, WV' },
  { value: 'Glenham_NY', label: 'Glenham, NY' },
  { value: 'Glenham_SD', label: 'Glenham, SD' },
  { value: 'Glenhaven_CA', label: 'Glenhaven, CA' },
  { value: 'Glenmont_NY', label: 'Glenmont, NY' },
  { value: 'Glenmont_OH', label: 'Glenmont, OH' },
  { value: 'Glenmoore_PA', label: 'Glenmoore, PA' },
  { value: 'Glenmora_LA', label: 'Glenmora, LA' },
  { value: 'Glenn_CA', label: 'Glenn, CA' },
  { value: 'Glenn Dale_MD', label: 'Glenn Dale, MD' },
  { value: 'Glennallen_AK', label: 'Glennallen, AK' },
  { value: 'Glennie_MI', label: 'Glennie, MI' },
  { value: 'Glenns Ferry_ID', label: 'Glenns Ferry, ID' },
  { value: 'Glennville_GA', label: 'Glennville, GA' },
  { value: 'Glennville_CA', label: 'Glennville, CA' },
  { value: 'Glenolden_PA', label: 'Glenolden, PA' },
  { value: 'Glenoma_WA', label: 'Glenoma, WA' },
  { value: 'Glenpool_OK', label: 'Glenpool, OK' },
  { value: 'Glenrock_WY', label: 'Glenrock, WY' },
  { value: 'Glens Falls_NY', label: 'Glens Falls, NY' },
  { value: 'Glens Fork_KY', label: 'Glens Fork, KY' },
  { value: 'Glenshaw_PA', label: 'Glenshaw, PA' },
  { value: 'Glenside_PA', label: 'Glenside, PA' },
  { value: 'Glentana_MT', label: 'Glentana, MT' },
  { value: 'Glenview_KY', label: 'Glenview, KY' },
  { value: 'Glenview_IL', label: 'Glenview, IL' },
  { value: 'Glenvil_NE', label: 'Glenvil, NE' },
  { value: 'Glenville_PA', label: 'Glenville, PA' },
  { value: 'Glenville_WV', label: 'Glenville, WV' },
  { value: 'Glenville_NC', label: 'Glenville, NC' },
  { value: 'Glenville_MN', label: 'Glenville, MN' },
  { value: 'Glenwood_NJ', label: 'Glenwood, NJ' },
  { value: 'Glenwood_NY', label: 'Glenwood, NY' },
  { value: 'Glenwood_MD', label: 'Glenwood, MD' },
  { value: 'Glenwood_WV', label: 'Glenwood, WV' },
  { value: 'Glenwood_GA', label: 'Glenwood, GA' },
  { value: 'Glenwood_AL', label: 'Glenwood, AL' },
  { value: 'Glenwood_IN', label: 'Glenwood, IN' },
  { value: 'Glenwood_IA', label: 'Glenwood, IA' },
  { value: 'Glenwood_MN', label: 'Glenwood, MN' },
  { value: 'Glenwood_IL', label: 'Glenwood, IL' },
  { value: 'Glenwood_MO', label: 'Glenwood, MO' },
  { value: 'Glenwood_AR', label: 'Glenwood, AR' },
  { value: 'Glenwood_UT', label: 'Glenwood, UT' },
  { value: 'Glenwood_NM', label: 'Glenwood, NM' },
  { value: 'Glenwood_WA', label: 'Glenwood, WA' },
  { value: 'Glenwood City_WI', label: 'Glenwood City, WI' },
  { value: 'Glenwood Landing_NY', label: 'Glenwood Landing, NY' },
  { value: 'Glenwood Springs_CO', label: 'Glenwood Springs, CO' },
  { value: 'Glidden_IA', label: 'Glidden, IA' },
  { value: 'Glidden_WI', label: 'Glidden, WI' },
  { value: 'Glidden_TX', label: 'Glidden, TX' },
  { value: 'Glide_OR', label: 'Glide, OR' },
  { value: 'Globe_AZ', label: 'Globe, AZ' },
  { value: 'Glorieta_NM', label: 'Glorieta, NM' },
  { value: 'Gloster_MS', label: 'Gloster, MS' },
  { value: 'Gloster_LA', label: 'Gloster, LA' },
  { value: 'Gloucester_MA', label: 'Gloucester, MA' },
  { value: 'Gloucester_VA', label: 'Gloucester, VA' },
  { value: 'Gloucester_NC', label: 'Gloucester, NC' },
  { value: 'Gloucester City_NJ', label: 'Gloucester City, NJ' },
  { value: 'Gloucester Point_VA', label: 'Gloucester Point, VA' },
  { value: 'Glouster_OH', label: 'Glouster, OH' },
  { value: 'Glover_VT', label: 'Glover, VT' },
  { value: 'Gloversville_NY', label: 'Gloversville, NY' },
  { value: 'Gloverville_SC', label: 'Gloverville, SC' },
  { value: 'Glyndon_MD', label: 'Glyndon, MD' },
  { value: 'Glyndon_MN', label: 'Glyndon, MN' },
  { value: 'Glynn_LA', label: 'Glynn, LA' },
  { value: 'Gnadenhutten_OH', label: 'Gnadenhutten, OH' },
  { value: 'Gobler_MO', label: 'Gobler, MO' },
  { value: 'Gobles_MI', label: 'Gobles, MI' },
  { value: 'Goddard_KS', label: 'Goddard, KS' },
  { value: 'Godfrey_IL', label: 'Godfrey, IL' },
  { value: 'Godley_TX', label: 'Godley, TX' },
  { value: 'Godwin_NC', label: 'Godwin, NC' },
  { value: 'Goehner_NE', label: 'Goehner, NE' },
  { value: 'Goessel_KS', label: 'Goessel, KS' },
  { value: 'Goetzville_MI', label: 'Goetzville, MI' },
  { value: 'Goff_KS', label: 'Goff, KS' },
  { value: 'Goffstown_NH', label: 'Goffstown, NH' },
  { value: 'Golconda_IL', label: 'Golconda, IL' },
  { value: 'Golconda_NV', label: 'Golconda, NV' },
  { value: 'Gold Bar_WA', label: 'Gold Bar, WA' },
  { value: 'Gold Beach_OR', label: 'Gold Beach, OR' },
  { value: 'Gold Canyon_AZ', label: 'Gold Canyon, AZ' },
  { value: 'Gold Creek_MT', label: 'Gold Creek, MT' },
  { value: 'Gold Hill_NC', label: 'Gold Hill, NC' },
  { value: 'Gold Hill_OR', label: 'Gold Hill, OR' },
  { value: 'Gold Run_CA', label: 'Gold Run, CA' },
  { value: 'Golden_MS', label: 'Golden, MS' },
  { value: 'Golden_IL', label: 'Golden, IL' },
  { value: 'Golden_MO', label: 'Golden, MO' },
  { value: 'Golden_OK', label: 'Golden, OK' },
  { value: 'Golden_CO', label: 'Golden, CO' },
  { value: 'Golden City_MO', label: 'Golden City, MO' },
  { value: 'Golden Eagle_IL', label: 'Golden Eagle, IL' },
  { value: 'Golden Gate_IL', label: 'Golden Gate, IL' },
  { value: 'Golden Meadow_LA', label: 'Golden Meadow, LA' },
  { value: 'Golden Valley_ND', label: 'Golden Valley, ND' },
  { value: 'Golden Valley_AZ', label: 'Golden Valley, AZ' },
  { value: 'Goldendale_WA', label: 'Goldendale, WA' },
  { value: 'Goldens Bridge_NY', label: 'Goldens Bridge, NY' },
  { value: 'Goldfield_IA', label: 'Goldfield, IA' },
  { value: 'Goldfield_NV', label: 'Goldfield, NV' },
  { value: 'Goldonna_LA', label: 'Goldonna, LA' },
  { value: 'Goldsboro_MD', label: 'Goldsboro, MD' },
  { value: 'Goldsboro_NC', label: 'Goldsboro, NC' },
  { value: 'Goldsboro_TX', label: 'Goldsboro, TX' },
  { value: 'Goldsmith_IN', label: 'Goldsmith, IN' },
  { value: 'Goldsmith_TX', label: 'Goldsmith, TX' },
  { value: 'Goldston_NC', label: 'Goldston, NC' },
  { value: 'Goldthwaite_TX', label: 'Goldthwaite, TX' },
  { value: 'Goldvein_VA', label: 'Goldvein, VA' },
  { value: 'Goleta_CA', label: 'Goleta, CA' },
  { value: 'Golf_IL', label: 'Golf, IL' },
  { value: 'Goliad_TX', label: 'Goliad, TX' },
  { value: 'Goltry_OK', label: 'Goltry, OK' },
  { value: 'Golva_ND', label: 'Golva, ND' },
  { value: 'Gomer_OH', label: 'Gomer, OH' },
  { value: 'Gonvick_MN', label: 'Gonvick, MN' },
  { value: 'Gonzales_LA', label: 'Gonzales, LA' },
  { value: 'Gonzales_TX', label: 'Gonzales, TX' },
  { value: 'Gonzales_CA', label: 'Gonzales, CA' },
  { value: 'Goochland_VA', label: 'Goochland, VA' },
  { value: 'Good Hope_GA', label: 'Good Hope, GA' },
  { value: 'Good Hope_IL', label: 'Good Hope, IL' },
  { value: 'Good Thunder_MN', label: 'Good Thunder, MN' },
  { value: 'Goode_VA', label: 'Goode, VA' },
  { value: 'Goodell_IA', label: 'Goodell, IA' },
  { value: 'Goodells_MI', label: 'Goodells, MI' },
  { value: 'Goodfellow Afb_TX', label: 'Goodfellow Afb, TX' },
  { value: 'Goodfield_IL', label: 'Goodfield, IL' },
  { value: 'Goodhue_MN', label: 'Goodhue, MN' },
  { value: 'Gooding_ID', label: 'Gooding, ID' },
  { value: 'Goodland_FL', label: 'Goodland, FL' },
  { value: 'Goodland_IN', label: 'Goodland, IN' },
  { value: 'Goodland_MN', label: 'Goodland, MN' },
  { value: 'Goodland_KS', label: 'Goodland, KS' },
  { value: 'Goodlettsville_TN', label: 'Goodlettsville, TN' },
  { value: 'Goodman_MS', label: 'Goodman, MS' },
  { value: 'Goodman_WI', label: 'Goodman, WI' },
  { value: 'Goodman_MO', label: 'Goodman, MO' },
  { value: 'Goodnews Bay_AK', label: 'Goodnews Bay, AK' },
  { value: 'Goodrich_MI', label: 'Goodrich, MI' },
  { value: 'Goodrich_ND', label: 'Goodrich, ND' },
  { value: 'Goodrich_TX', label: 'Goodrich, TX' },
  { value: 'Goodridge_MN', label: 'Goodridge, MN' },
  { value: 'Goodspring_TN', label: 'Goodspring, TN' },
  { value: 'Goodview_VA', label: 'Goodview, VA' },
  { value: 'Goodwater_AL', label: 'Goodwater, AL' },
  { value: 'Goodwell_OK', label: 'Goodwell, OK' },
  { value: 'Goodwin_SD', label: 'Goodwin, SD' },
  { value: 'Goodwin_AR', label: 'Goodwin, AR' },
  { value: 'Goodwine_IL', label: 'Goodwine, IL' },
  { value: 'Goodyear_AZ', label: 'Goodyear, AZ' },
  { value: 'Goodyears Bar_CA', label: 'Goodyears Bar, CA' },
  { value: 'Goose Creek_SC', label: 'Goose Creek, SC' },
  { value: 'Goose Lake_IA', label: 'Goose Lake, IA' },
  { value: 'Gordo_AL', label: 'Gordo, AL' },
  { value: 'Gordon_PA', label: 'Gordon, PA' },
  { value: 'Gordon_WV', label: 'Gordon, WV' },
  { value: 'Gordon_GA', label: 'Gordon, GA' },
  { value: 'Gordon_AL', label: 'Gordon, AL' },
  { value: 'Gordon_KY', label: 'Gordon, KY' },
  { value: 'Gordon_WI', label: 'Gordon, WI' },
  { value: 'Gordon_NE', label: 'Gordon, NE' },
  { value: 'Gordon_TX', label: 'Gordon, TX' },
  { value: 'Gordonsville_VA', label: 'Gordonsville, VA' },
  { value: 'Gordonsville_TN', label: 'Gordonsville, TN' },
  { value: 'Gordonville_PA', label: 'Gordonville, PA' },
  { value: 'Gordonville_TX', label: 'Gordonville, TX' },
  { value: 'Gore_VA', label: 'Gore, VA' },
  { value: 'Gore_OK', label: 'Gore, OK' },
  { value: 'Gore Springs_MS', label: 'Gore Springs, MS' },
  { value: 'Goree_TX', label: 'Goree, TX' },
  { value: 'Goreville_IL', label: 'Goreville, IL' },
  { value: 'Gorham_NH', label: 'Gorham, NH' },
  { value: 'Gorham_ME', label: 'Gorham, ME' },
  { value: 'Gorham_IL', label: 'Gorham, IL' },
  { value: 'Gorham_KS', label: 'Gorham, KS' },
  { value: 'Gorin_MO', label: 'Gorin, MO' },
  { value: 'Gorman_TX', label: 'Gorman, TX' },
  { value: 'Gormania_WV', label: 'Gormania, WV' },
  { value: 'Gorum_LA', label: 'Gorum, LA' },
  { value: 'Goshen_MA', label: 'Goshen, MA' },
  { value: 'Goshen_NH', label: 'Goshen, NH' },
  { value: 'Goshen_CT', label: 'Goshen, CT' },
  { value: 'Goshen_NY', label: 'Goshen, NY' },
  { value: 'Goshen_VA', label: 'Goshen, VA' },
  { value: 'Goshen_AL', label: 'Goshen, AL' },
  { value: 'Goshen_KY', label: 'Goshen, KY' },
  { value: 'Goshen_OH', label: 'Goshen, OH' },
  { value: 'Goshen_IN', label: 'Goshen, IN' },
  { value: 'Goshen_UT', label: 'Goshen, UT' },
  { value: 'Goshen_CA', label: 'Goshen, CA' },
  { value: 'Gosport_IN', label: 'Gosport, IN' },
  { value: 'Gotebo_OK', label: 'Gotebo, OK' },
  { value: 'Gotha_FL', label: 'Gotha, FL' },
  { value: 'Gotham_WI', label: 'Gotham, WI' },
  { value: 'Gothenburg_NE', label: 'Gothenburg, NE' },
  { value: 'Gould_AR', label: 'Gould, AR' },
  { value: 'Gould_OK', label: 'Gould, OK' },
  { value: 'Gould City_MI', label: 'Gould City, MI' },
  { value: 'Gouldbusk_TX', label: 'Gouldbusk, TX' },
  { value: 'Gouldsboro_ME', label: 'Gouldsboro, ME' },
  { value: 'Gouldsboro_PA', label: 'Gouldsboro, PA' },
  { value: 'Gouverneur_NY', label: 'Gouverneur, NY' },
  { value: 'Gove_KS', label: 'Gove, KS' },
  { value: 'Government Camp_OR', label: 'Government Camp, OR' },
  { value: 'Gowanda_NY', label: 'Gowanda, NY' },
  { value: 'Gowen_MI', label: 'Gowen, MI' },
  { value: 'Gower_MO', label: 'Gower, MO' },
  { value: 'Gowrie_IA', label: 'Gowrie, IA' },
  { value: 'Grabill_IN', label: 'Grabill, IN' },
  { value: 'Grace_MS', label: 'Grace, MS' },
  { value: 'Grace_ID', label: 'Grace, ID' },
  { value: 'Grace City_ND', label: 'Grace City, ND' },
  { value: 'Gracemont_OK', label: 'Gracemont, OK' },
  { value: 'Graceville_FL', label: 'Graceville, FL' },
  { value: 'Graceville_MN', label: 'Graceville, MN' },
  { value: 'Gracewood_GA', label: 'Gracewood, GA' },
  { value: 'Gracey_KY', label: 'Gracey, KY' },
  { value: 'Grady_AL', label: 'Grady, AL' },
  { value: 'Grady_AR', label: 'Grady, AR' },
  { value: 'Grady_NM', label: 'Grady, NM' },
  { value: 'Gradyville_KY', label: 'Gradyville, KY' },
  { value: 'Graettinger_IA', label: 'Graettinger, IA' },
  { value: 'Graff_MO', label: 'Graff, MO' },
  { value: 'Graford_TX', label: 'Graford, TX' },
  { value: 'Grafton_MA', label: 'Grafton, MA' },
  { value: 'Grafton_NH', label: 'Grafton, NH' },
  { value: 'Grafton_VT', label: 'Grafton, VT' },
  { value: 'Grafton_NY', label: 'Grafton, NY' },
  { value: 'Grafton_WV', label: 'Grafton, WV' },
  { value: 'Grafton_OH', label: 'Grafton, OH' },
  { value: 'Grafton_IA', label: 'Grafton, IA' },
  { value: 'Grafton_WI', label: 'Grafton, WI' },
  { value: 'Grafton_ND', label: 'Grafton, ND' },
  { value: 'Grafton_IL', label: 'Grafton, IL' },
  { value: 'Grafton_NE', label: 'Grafton, NE' },
  { value: 'Graham_NC', label: 'Graham, NC' },
  { value: 'Graham_AL', label: 'Graham, AL' },
  { value: 'Graham_KY', label: 'Graham, KY' },
  { value: 'Graham_MO', label: 'Graham, MO' },
  { value: 'Graham_OK', label: 'Graham, OK' },
  { value: 'Graham_TX', label: 'Graham, TX' },
  { value: 'Graham_WA', label: 'Graham, WA' },
  { value: 'Grahamsville_NY', label: 'Grahamsville, NY' },
  { value: 'Grahn_KY', label: 'Grahn, KY' },
  { value: 'Grain Valley_MO', label: 'Grain Valley, MO' },
  { value: 'Grainfield_KS', label: 'Grainfield, KS' },
  { value: 'Grambling_LA', label: 'Grambling, LA' },
  { value: 'Gramercy_LA', label: 'Gramercy, LA' },
  { value: 'Grammer_IN', label: 'Grammer, IN' },
  { value: 'Grampian_PA', label: 'Grampian, PA' },
  { value: 'Granada_MN', label: 'Granada, MN' },
  { value: 'Granada_CO', label: 'Granada, CO' },
  { value: 'Granada Hills_CA', label: 'Granada Hills, CA' },
  { value: 'Granbury_TX', label: 'Granbury, TX' },
  { value: 'Granby_MA', label: 'Granby, MA' },
  { value: 'Granby_VT', label: 'Granby, VT' },
  { value: 'Granby_CT', label: 'Granby, CT' },
  { value: 'Granby_MO', label: 'Granby, MO' },
  { value: 'Granby_CO', label: 'Granby, CO' },
  { value: 'Grand Bay_AL', label: 'Grand Bay, AL' },
  { value: 'Grand Blanc_MI', label: 'Grand Blanc, MI' },
  { value: 'Grand Cane_LA', label: 'Grand Cane, LA' },
  { value: 'Grand Canyon_AZ', label: 'Grand Canyon, AZ' },
  { value: 'Grand Chain_IL', label: 'Grand Chain, IL' },
  { value: 'Grand Chenier_LA', label: 'Grand Chenier, LA' },
  { value: 'Grand Coteau_LA', label: 'Grand Coteau, LA' },
  { value: 'Grand Coulee_WA', label: 'Grand Coulee, WA' },
  { value: 'Grand Forks_ND', label: 'Grand Forks, ND' },
  { value: 'Grand Forks Afb_ND', label: 'Grand Forks Afb, ND' },
  { value: 'Grand Gorge_NY', label: 'Grand Gorge, NY' },
  { value: 'Grand Haven_MI', label: 'Grand Haven, MI' },
  { value: 'Grand Island_NY', label: 'Grand Island, NY' },
  { value: 'Grand Island_FL', label: 'Grand Island, FL' },
  { value: 'Grand Island_NE', label: 'Grand Island, NE' },
  { value: 'Grand Isle_ME', label: 'Grand Isle, ME' },
  { value: 'Grand Isle_VT', label: 'Grand Isle, VT' },
  { value: 'Grand Isle_LA', label: 'Grand Isle, LA' },
  { value: 'Grand Junction_TN', label: 'Grand Junction, TN' },
  { value: 'Grand Junction_MI', label: 'Grand Junction, MI' },
  { value: 'Grand Junction_IA', label: 'Grand Junction, IA' },
  { value: 'Grand Junction_CO', label: 'Grand Junction, CO' },
  { value: 'Grand Lake_CO', label: 'Grand Lake, CO' },
  { value: 'Grand Ledge_MI', label: 'Grand Ledge, MI' },
  { value: 'Grand Marais_MI', label: 'Grand Marais, MI' },
  { value: 'Grand Marais_MN', label: 'Grand Marais, MN' },
  { value: 'Grand Marsh_WI', label: 'Grand Marsh, WI' },
  { value: 'Grand Meadow_MN', label: 'Grand Meadow, MN' },
  { value: 'Grand Mound_IA', label: 'Grand Mound, IA' },
  { value: 'Grand Portage_MN', label: 'Grand Portage, MN' },
  { value: 'Grand Prairie_TX', label: 'Grand Prairie, TX' },
  { value: 'Grand Rapids_OH', label: 'Grand Rapids, OH' },
  { value: 'Grand Rapids_MI', label: 'Grand Rapids, MI' },
  { value: 'Grand Rapids_MN', label: 'Grand Rapids, MN' },
  { value: 'Grand Ridge_FL', label: 'Grand Ridge, FL' },
  { value: 'Grand Ridge_IL', label: 'Grand Ridge, IL' },
  { value: 'Grand River_OH', label: 'Grand River, OH' },
  { value: 'Grand River_IA', label: 'Grand River, IA' },
  { value: 'Grand Rivers_KY', label: 'Grand Rivers, KY' },
  { value: 'Grand Ronde_OR', label: 'Grand Ronde, OR' },
  { value: 'Grand Saline_TX', label: 'Grand Saline, TX' },
  { value: 'Grand Terrace_CA', label: 'Grand Terrace, CA' },
  { value: 'Grand Tower_IL', label: 'Grand Tower, IL' },
  { value: 'Grand Valley_PA', label: 'Grand Valley, PA' },
  { value: 'Grand View_WI', label: 'Grand View, WI' },
  { value: 'Grand View_ID', label: 'Grand View, ID' },
  { value: 'Grandfalls_TX', label: 'Grandfalls, TX' },
  { value: 'Grandfield_OK', label: 'Grandfield, OK' },
  { value: 'Grandin_ND', label: 'Grandin, ND' },
  { value: 'Grandin_MO', label: 'Grandin, MO' },
  { value: 'Grandview_TN', label: 'Grandview, TN' },
  { value: 'Grandview_IN', label: 'Grandview, IN' },
  { value: 'Grandview_IA', label: 'Grandview, IA' },
  { value: 'Grandview_MO', label: 'Grandview, MO' },
  { value: 'Grandview_TX', label: 'Grandview, TX' },
  { value: 'Grandview_WA', label: 'Grandview, WA' },
  { value: 'Grandville_MI', label: 'Grandville, MI' },
  { value: 'Grandy_NC', label: 'Grandy, NC' },
  { value: 'Grandy_MN', label: 'Grandy, MN' },
  { value: 'Granger_IN', label: 'Granger, IN' },
  { value: 'Granger_IA', label: 'Granger, IA' },
  { value: 'Granger_TX', label: 'Granger, TX' },
  { value: 'Granger_WY', label: 'Granger, WY' },
  { value: 'Granger_WA', label: 'Granger, WA' },
  { value: 'Grangeville_ID', label: 'Grangeville, ID' },
  { value: 'Granite_OK', label: 'Granite, OK' },
  { value: 'Granite_CO', label: 'Granite, CO' },
  { value: 'Granite Bay_CA', label: 'Granite Bay, CA' },
  { value: 'Granite Canon_WY', label: 'Granite Canon, WY' },
  { value: 'Granite City_IL', label: 'Granite City, IL' },
  { value: 'Granite Falls_NC', label: 'Granite Falls, NC' },
  { value: 'Granite Falls_MN', label: 'Granite Falls, MN' },
  { value: 'Granite Falls_WA', label: 'Granite Falls, WA' },
  { value: 'Granite Quarry_NC', label: 'Granite Quarry, NC' },
  { value: 'Granite Springs_NY', label: 'Granite Springs, NY' },
  { value: 'Graniteville_VT', label: 'Graniteville, VT' },
  { value: 'Graniteville_SC', label: 'Graniteville, SC' },
  { value: 'Grannis_AR', label: 'Grannis, AR' },
  { value: 'Grant_FL', label: 'Grant, FL' },
  { value: 'Grant_AL', label: 'Grant, AL' },
  { value: 'Grant_MI', label: 'Grant, MI' },
  { value: 'Grant_IA', label: 'Grant, IA' },
  { value: 'Grant_NE', label: 'Grant, NE' },
  { value: 'Grant_LA', label: 'Grant, LA' },
  { value: 'Grant_OK', label: 'Grant, OK' },
  { value: 'Grant_CO', label: 'Grant, CO' },
  { value: 'Grant City_MO', label: 'Grant City, MO' },
  { value: 'Grant Park_IL', label: 'Grant Park, IL' },
  { value: 'Grant Town_WV', label: 'Grant Town, WV' },
  { value: 'Grantham_NH', label: 'Grantham, NH' },
  { value: 'Grantham_PA', label: 'Grantham, PA' },
  { value: 'Granton_WI', label: 'Granton, WI' },
  { value: 'Grants_NM', label: 'Grants, NM' },
  { value: 'Grants Pass_OR', label: 'Grants Pass, OR' },
  { value: 'Grantsboro_NC', label: 'Grantsboro, NC' },
  { value: 'Grantsburg_IN', label: 'Grantsburg, IN' },
  { value: 'Grantsburg_WI', label: 'Grantsburg, WI' },
  { value: 'Grantsburg_IL', label: 'Grantsburg, IL' },
  { value: 'Grantsville_MD', label: 'Grantsville, MD' },
  { value: 'Grantsville_WV', label: 'Grantsville, WV' },
  { value: 'Grantsville_UT', label: 'Grantsville, UT' },
  { value: 'Grantville_PA', label: 'Grantville, PA' },
  { value: 'Grantville_GA', label: 'Grantville, GA' },
  { value: 'Grantville_KS', label: 'Grantville, KS' },
  { value: 'Granville_MA', label: 'Granville, MA' },
  { value: 'Granville_VT', label: 'Granville, VT' },
  { value: 'Granville_NY', label: 'Granville, NY' },
  { value: 'Granville_PA', label: 'Granville, PA' },
  { value: 'Granville_WV', label: 'Granville, WV' },
  { value: 'Granville_TN', label: 'Granville, TN' },
  { value: 'Granville_OH', label: 'Granville, OH' },
  { value: 'Granville_IA', label: 'Granville, IA' },
  { value: 'Granville_ND', label: 'Granville, ND' },
  { value: 'Granville_IL', label: 'Granville, IL' },
  { value: 'Granville Summit_PA', label: 'Granville Summit, PA' },
  { value: 'Grapeland_TX', label: 'Grapeland, TX' },
  { value: 'Grapeview_WA', label: 'Grapeview, WA' },
  { value: 'Grapeville_PA', label: 'Grapeville, PA' },
  { value: 'Grapevine_AR', label: 'Grapevine, AR' },
  { value: 'Grapevine_TX', label: 'Grapevine, TX' },
  { value: 'Grasonville_MD', label: 'Grasonville, MD' },
  { value: 'Grass Lake_MI', label: 'Grass Lake, MI' },
  { value: 'Grass Range_MT', label: 'Grass Range, MT' },
  { value: 'Grass Valley_CA', label: 'Grass Valley, CA' },
  { value: 'Grass Valley_OR', label: 'Grass Valley, OR' },
  { value: 'Grassflat_PA', label: 'Grassflat, PA' },
  { value: 'Grasston_MN', label: 'Grasston, MN' },
  { value: 'Grassy Butte_ND', label: 'Grassy Butte, ND' },
  { value: 'Grassy Creek_NC', label: 'Grassy Creek, NC' },
  { value: 'Grassy Meadows_WV', label: 'Grassy Meadows, WV' },
  { value: 'Gratiot_OH', label: 'Gratiot, OH' },
  { value: 'Gratiot_WI', label: 'Gratiot, WI' },
  { value: 'Gratis_OH', label: 'Gratis, OH' },
  { value: 'Graton_CA', label: 'Graton, CA' },
  { value: 'Gratz_PA', label: 'Gratz, PA' },
  { value: 'Gravel Switch_KY', label: 'Gravel Switch, KY' },
  { value: 'Gravelly_AR', label: 'Gravelly, AR' },
  { value: 'Gravette_AR', label: 'Gravette, AR' },
  { value: 'Gravity_IA', label: 'Gravity, IA' },
  { value: 'Gravois Mills_MO', label: 'Gravois Mills, MO' },
  { value: 'Grawn_MI', label: 'Grawn, MI' },
  { value: 'Gray_ME', label: 'Gray, ME' },
  { value: 'Gray_PA', label: 'Gray, PA' },
  { value: 'Gray_GA', label: 'Gray, GA' },
  { value: 'Gray_KY', label: 'Gray, KY' },
  { value: 'Gray_IA', label: 'Gray, IA' },
  { value: 'Gray_LA', label: 'Gray, LA' },
  { value: 'Gray Court_SC', label: 'Gray Court, SC' },
  { value: 'Gray Hawk_KY', label: 'Gray Hawk, KY' },
  { value: 'Gray Mountain_AZ', label: 'Gray Mountain, AZ' },
  { value: 'Gray Summit_MO', label: 'Gray Summit, MO' },
  { value: 'Grayland_WA', label: 'Grayland, WA' },
  { value: 'Grayling_MI', label: 'Grayling, MI' },
  { value: 'Grayling_AK', label: 'Grayling, AK' },
  { value: 'Graymont_IL', label: 'Graymont, IL' },
  { value: 'Grayridge_MO', label: 'Grayridge, MO' },
  { value: 'Grays Knob_KY', label: 'Grays Knob, KY' },
  { value: 'Grays River_WA', label: 'Grays River, WA' },
  { value: 'Grayslake_IL', label: 'Grayslake, IL' },
  { value: 'Grayson_GA', label: 'Grayson, GA' },
  { value: 'Grayson_KY', label: 'Grayson, KY' },
  { value: 'Grayson_LA', label: 'Grayson, LA' },
  { value: 'Graysville_PA', label: 'Graysville, PA' },
  { value: 'Graysville_GA', label: 'Graysville, GA' },
  { value: 'Graysville_AL', label: 'Graysville, AL' },
  { value: 'Graysville_TN', label: 'Graysville, TN' },
  { value: 'Graysville_OH', label: 'Graysville, OH' },
  { value: 'Graytown_OH', label: 'Graytown, OH' },
  { value: 'Grayville_IL', label: 'Grayville, IL' },
  { value: 'Great Barrington_MA', label: 'Great Barrington, MA' },
  { value: 'Great Bend_NY', label: 'Great Bend, NY' },
  { value: 'Great Bend_PA', label: 'Great Bend, PA' },
  { value: 'Great Bend_KS', label: 'Great Bend, KS' },
  { value: 'Great Cacapon_WV', label: 'Great Cacapon, WV' },
  { value: 'Great Falls_VA', label: 'Great Falls, VA' },
  { value: 'Great Falls_SC', label: 'Great Falls, SC' },
  { value: 'Great Falls_MT', label: 'Great Falls, MT' },
  { value: 'Great Lakes_IL', label: 'Great Lakes, IL' },
  { value: 'Great Meadows_NJ', label: 'Great Meadows, NJ' },
  { value: 'Great Mills_MD', label: 'Great Mills, MD' },
  { value: 'Great Neck_NY', label: 'Great Neck, NY' },
  { value: 'Great River_NY', label: 'Great River, NY' },
  { value: 'Great Valley_NY', label: 'Great Valley, NY' },
  { value: 'Greeley_PA', label: 'Greeley, PA' },
  { value: 'Greeley_IA', label: 'Greeley, IA' },
  { value: 'Greeley_KS', label: 'Greeley, KS' },
  { value: 'Greeley_NE', label: 'Greeley, NE' },
  { value: 'Greeley_CO', label: 'Greeley, CO' },
  { value: 'Greeleyville_SC', label: 'Greeleyville, SC' },
  { value: 'Green_OH', label: 'Green, OH' },
  { value: 'Green_KS', label: 'Green, KS' },
  { value: 'Green Bank_WV', label: 'Green Bank, WV' },
  { value: 'Green Bay_VA', label: 'Green Bay, VA' },
  { value: 'Green Bay_WI', label: 'Green Bay, WI' },
  { value: 'Green Camp_OH', label: 'Green Camp, OH' },
  { value: 'Green Castle_MO', label: 'Green Castle, MO' },
  { value: 'Green City_MO', label: 'Green City, MO' },
  { value: 'Green Cove Springs_FL', label: 'Green Cove Springs, FL' },
  { value: 'Green Forest_AR', label: 'Green Forest, AR' },
  { value: 'Green Isle_MN', label: 'Green Isle, MN' },
  { value: 'Green Lake_WI', label: 'Green Lake, WI' },
  { value: 'Green Lane_PA', label: 'Green Lane, PA' },
  { value: 'Green Mountain_NC', label: 'Green Mountain, NC' },
  { value: 'Green Mountain Falls_CO', label: 'Green Mountain Falls, CO' },
  { value: 'Green Pond_SC', label: 'Green Pond, SC' },
  { value: 'Green Pond_AL', label: 'Green Pond, AL' },
  { value: 'Green Ridge_MO', label: 'Green Ridge, MO' },
  { value: 'Green River_WY', label: 'Green River, WY' },
  { value: 'Green River_UT', label: 'Green River, UT' },
  { value: 'Green Road_KY', label: 'Green Road, KY' },
  { value: 'Green Sea_SC', label: 'Green Sea, SC' },
  { value: 'Green Spring_WV', label: 'Green Spring, WV' },
  { value: 'Green Springs_OH', label: 'Green Springs, OH' },
  { value: 'Green Sulphur Springs_WV', label: 'Green Sulphur Springs, WV' },
  { value: 'Green Valley_WI', label: 'Green Valley, WI' },
  { value: 'Green Valley_IL', label: 'Green Valley, IL' },
  { value: 'Green Valley_AZ', label: 'Green Valley, AZ' },
  { value: 'Green Valley Lake_CA', label: 'Green Valley Lake, CA' },
  { value: 'Green Village_NJ', label: 'Green Village, NJ' },
  { value: 'Greenacres_WA', label: 'Greenacres, WA' },
  { value: 'Greenback_TN', label: 'Greenback, TN' },
  { value: 'Greenbackville_VA', label: 'Greenbackville, VA' },
  { value: 'Greenbank_WA', label: 'Greenbank, WA' },
  { value: 'Greenbelt_MD', label: 'Greenbelt, MD' },
  { value: 'Greenbrae_CA', label: 'Greenbrae, CA' },
  { value: 'Greenbrier_TN', label: 'Greenbrier, TN' },
  { value: 'Greenbrier_AR', label: 'Greenbrier, AR' },
  { value: 'Greenbush_ME', label: 'Greenbush, ME' },
  { value: 'Greenbush_VA', label: 'Greenbush, VA' },
  { value: 'Greenbush_MI', label: 'Greenbush, MI' },
  { value: 'Greenbush_MN', label: 'Greenbush, MN' },
  { value: 'Greencastle_PA', label: 'Greencastle, PA' },
  { value: 'Greencastle_IN', label: 'Greencastle, IN' },
  { value: 'Greencreek_ID', label: 'Greencreek, ID' },
  { value: 'Greendale_WI', label: 'Greendale, WI' },
  { value: 'Greene_RI', label: 'Greene, RI' },
  { value: 'Greene_ME', label: 'Greene, ME' },
  { value: 'Greene_NY', label: 'Greene, NY' },
  { value: 'Greene_IA', label: 'Greene, IA' },
  { value: 'Greeneville_TN', label: 'Greeneville, TN' },
  { value: 'Greenfield_MA', label: 'Greenfield, MA' },
  { value: 'Greenfield_NH', label: 'Greenfield, NH' },
  { value: 'Greenfield_TN', label: 'Greenfield, TN' },
  { value: 'Greenfield_OH', label: 'Greenfield, OH' },
  { value: 'Greenfield_IN', label: 'Greenfield, IN' },
  { value: 'Greenfield_IA', label: 'Greenfield, IA' },
  { value: 'Greenfield_IL', label: 'Greenfield, IL' },
  { value: 'Greenfield_MO', label: 'Greenfield, MO' },
  { value: 'Greenfield_OK', label: 'Greenfield, OK' },
  { value: 'Greenfield_CA', label: 'Greenfield, CA' },
  { value: 'Greenfield Center_NY', label: 'Greenfield Center, NY' },
  { value: 'Greenfield Park_NY', label: 'Greenfield Park, NY' },
  { value: 'Greenhurst_NY', label: 'Greenhurst, NY' },
  { value: 'Greenland_NH', label: 'Greenland, NH' },
  { value: 'Greenland_MI', label: 'Greenland, MI' },
  { value: 'Greenlawn_NY', label: 'Greenlawn, NY' },
  { value: 'Greenleaf_WI', label: 'Greenleaf, WI' },
  { value: 'Greenleaf_KS', label: 'Greenleaf, KS' },
  { value: 'Greenleaf_ID', label: 'Greenleaf, ID' },
  { value: 'Greenock_PA', label: 'Greenock, PA' },
  { value: 'Greenport_NY', label: 'Greenport, NY' },
  { value: 'Greens Fork_IN', label: 'Greens Fork, IN' },
  { value: 'Greensboro_VT', label: 'Greensboro, VT' },
  { value: 'Greensboro_PA', label: 'Greensboro, PA' },
  { value: 'Greensboro_MD', label: 'Greensboro, MD' },
  { value: 'Greensboro_NC', label: 'Greensboro, NC' },
  { value: 'Greensboro_GA', label: 'Greensboro, GA' },
  { value: 'Greensboro_FL', label: 'Greensboro, FL' },
  { value: 'Greensboro_AL', label: 'Greensboro, AL' },
  { value: 'Greensboro_IN', label: 'Greensboro, IN' },
  { value: 'Greensboro Bend_VT', label: 'Greensboro Bend, VT' },
  { value: 'Greensburg_PA', label: 'Greensburg, PA' },
  { value: 'Greensburg_KY', label: 'Greensburg, KY' },
  { value: 'Greensburg_IN', label: 'Greensburg, IN' },
  { value: 'Greensburg_KS', label: 'Greensburg, KS' },
  { value: 'Greensburg_LA', label: 'Greensburg, LA' },
  { value: 'Greentop_MO', label: 'Greentop, MO' },
  { value: 'Greentown_PA', label: 'Greentown, PA' },
  { value: 'Greentown_OH', label: 'Greentown, OH' },
  { value: 'Greentown_IN', label: 'Greentown, IN' },
  { value: 'Greenup_KY', label: 'Greenup, KY' },
  { value: 'Greenup_IL', label: 'Greenup, IL' },
  { value: 'Greenvale_NY', label: 'Greenvale, NY' },
  { value: 'Greenview_IL', label: 'Greenview, IL' },
  { value: 'Greenview_CA', label: 'Greenview, CA' },
  { value: 'Greenville_RI', label: 'Greenville, RI' },
  { value: 'Greenville_NH', label: 'Greenville, NH' },
  { value: 'Greenville_ME', label: 'Greenville, ME' },
  { value: 'Greenville_NY', label: 'Greenville, NY' },
  { value: 'Greenville_PA', label: 'Greenville, PA' },
  { value: 'Greenville_VA', label: 'Greenville, VA' },
  { value: 'Greenville_WV', label: 'Greenville, WV' },
  { value: 'Greenville_NC', label: 'Greenville, NC' },
  { value: 'Greenville_SC', label: 'Greenville, SC' },
  { value: 'Greenville_GA', label: 'Greenville, GA' },
  { value: 'Greenville_FL', label: 'Greenville, FL' },
  { value: 'Greenville_AL', label: 'Greenville, AL' },
  { value: 'Greenville_MS', label: 'Greenville, MS' },
  { value: 'Greenville_KY', label: 'Greenville, KY' },
  { value: 'Greenville_OH', label: 'Greenville, OH' },
  { value: 'Greenville_IN', label: 'Greenville, IN' },
  { value: 'Greenville_MI', label: 'Greenville, MI' },
  { value: 'Greenville_IA', label: 'Greenville, IA' },
  { value: 'Greenville_WI', label: 'Greenville, WI' },
  { value: 'Greenville_IL', label: 'Greenville, IL' },
  { value: 'Greenville_MO', label: 'Greenville, MO' },
  { value: 'Greenville_TX', label: 'Greenville, TX' },
  { value: 'Greenville_UT', label: 'Greenville, UT' },
  { value: 'Greenville_CA', label: 'Greenville, CA' },
  { value: 'Greenville Junction_ME', label: 'Greenville Junction, ME' },
  { value: 'Greenwald_MN', label: 'Greenwald, MN' },
  { value: 'Greenway_AR', label: 'Greenway, AR' },
  { value: 'Greenwell Springs_LA', label: 'Greenwell Springs, LA' },
  { value: 'Greenwich_CT', label: 'Greenwich, CT' },
  { value: 'Greenwich_NJ', label: 'Greenwich, NJ' },
  { value: 'Greenwich_NY', label: 'Greenwich, NY' },
  { value: 'Greenwich_OH', label: 'Greenwich, OH' },
  { value: 'Greenwich_KS', label: 'Greenwich, KS' },
  { value: 'Greenwich_UT', label: 'Greenwich, UT' },
  { value: 'Greenwood_ME', label: 'Greenwood, ME' },
  { value: 'Greenwood_NY', label: 'Greenwood, NY' },
  { value: 'Greenwood_DE', label: 'Greenwood, DE' },
  { value: 'Greenwood_VA', label: 'Greenwood, VA' },
  { value: 'Greenwood_SC', label: 'Greenwood, SC' },
  { value: 'Greenwood_FL', label: 'Greenwood, FL' },
  { value: 'Greenwood_MS', label: 'Greenwood, MS' },
  { value: 'Greenwood_IN', label: 'Greenwood, IN' },
  { value: 'Greenwood_WI', label: 'Greenwood, WI' },
  { value: 'Greenwood_MO', label: 'Greenwood, MO' },
  { value: 'Greenwood_NE', label: 'Greenwood, NE' },
  { value: 'Greenwood_LA', label: 'Greenwood, LA' },
  { value: 'Greenwood_AR', label: 'Greenwood, AR' },
  { value: 'Greenwood_CA', label: 'Greenwood, CA' },
  { value: 'Greenwood Lake_NY', label: 'Greenwood Lake, NY' },
  { value: 'Greenwood Springs_MS', label: 'Greenwood Springs, MS' },
  { value: 'Greer_SC', label: 'Greer, SC' },
  { value: 'Greer_AZ', label: 'Greer, AZ' },
  { value: 'Gregory_MI', label: 'Gregory, MI' },
  { value: 'Gregory_SD', label: 'Gregory, SD' },
  { value: 'Gregory_AR', label: 'Gregory, AR' },
  { value: 'Gregory_TX', label: 'Gregory, TX' },
  { value: 'Greig_NY', label: 'Greig, NY' },
  { value: 'Grelton_OH', label: 'Grelton, OH' },
  { value: 'Grenada_MS', label: 'Grenada, MS' },
  { value: 'Grenada_CA', label: 'Grenada, CA' },
  { value: 'Grenloch_NJ', label: 'Grenloch, NJ' },
  { value: 'Grenola_KS', label: 'Grenola, KS' },
  { value: 'Grenora_ND', label: 'Grenora, ND' },
  { value: 'Grenville_SD', label: 'Grenville, SD' },
  { value: 'Grenville_NM', label: 'Grenville, NM' },
  { value: 'Gresham_SC', label: 'Gresham, SC' },
  { value: 'Gresham_WI', label: 'Gresham, WI' },
  { value: 'Gresham_NE', label: 'Gresham, NE' },
  { value: 'Gresham_OR', label: 'Gresham, OR' },
  { value: 'Grethel_KY', label: 'Grethel, KY' },
  { value: 'Gretna_VA', label: 'Gretna, VA' },
  { value: 'Gretna_FL', label: 'Gretna, FL' },
  { value: 'Gretna_NE', label: 'Gretna, NE' },
  { value: 'Gretna_LA', label: 'Gretna, LA' },
  { value: 'Grey Eagle_MN', label: 'Grey Eagle, MN' },
  { value: 'Greybull_WY', label: 'Greybull, WY' },
  { value: 'Greycliff_MT', label: 'Greycliff, MT' },
  { value: 'Gridley_IL', label: 'Gridley, IL' },
  { value: 'Gridley_KS', label: 'Gridley, KS' },
  { value: 'Gridley_CA', label: 'Gridley, CA' },
  { value: 'Griffin_GA', label: 'Griffin, GA' },
  { value: 'Griffin_IN', label: 'Griffin, IN' },
  { value: 'Griffith_IN', label: 'Griffith, IN' },
  { value: 'Griffithsville_WV', label: 'Griffithsville, WV' },
  { value: 'Griffithville_AR', label: 'Griffithville, AR' },
  { value: 'Grifton_NC', label: 'Grifton, NC' },
  { value: 'Griggsville_IL', label: 'Griggsville, IL' },
  { value: 'Grimes_IA', label: 'Grimes, IA' },
  { value: 'Grimes_CA', label: 'Grimes, CA' },
  { value: 'Grimesland_NC', label: 'Grimesland, NC' },
  { value: 'Grimsley_TN', label: 'Grimsley, TN' },
  { value: 'Grindstone_PA', label: 'Grindstone, PA' },
  { value: 'Grinnell_IA', label: 'Grinnell, IA' },
  { value: 'Grinnell_KS', label: 'Grinnell, KS' },
  { value: 'Grissom Arb_IN', label: 'Grissom Arb, IN' },
  { value: 'Griswold_IA', label: 'Griswold, IA' },
  { value: 'Grizzly Flats_CA', label: 'Grizzly Flats, CA' },
  { value: 'Groesbeck_TX', label: 'Groesbeck, TX' },
  { value: 'Groom_TX', label: 'Groom, TX' },
  { value: 'Grosse Ile_MI', label: 'Grosse Ile, MI' },
  { value: 'Grosse Pointe_MI', label: 'Grosse Pointe, MI' },
  { value: 'Grosse Tete_LA', label: 'Grosse Tete, LA' },
  { value: 'Groton_MA', label: 'Groton, MA' },
  { value: 'Groton_VT', label: 'Groton, VT' },
  { value: 'Groton_CT', label: 'Groton, CT' },
  { value: 'Groton_NY', label: 'Groton, NY' },
  { value: 'Groton_SD', label: 'Groton, SD' },
  { value: 'Grottoes_VA', label: 'Grottoes, VA' },
  { value: 'Grouse Creek_UT', label: 'Grouse Creek, UT' },
  { value: 'Grove_OK', label: 'Grove, OK' },
  { value: 'Grove City_PA', label: 'Grove City, PA' },
  { value: 'Grove City_OH', label: 'Grove City, OH' },
  { value: 'Grove City_MN', label: 'Grove City, MN' },
  { value: 'Grove Hill_AL', label: 'Grove Hill, AL' },
  { value: 'Groveland_MA', label: 'Groveland, MA' },
  { value: 'Groveland_NY', label: 'Groveland, NY' },
  { value: 'Groveland_FL', label: 'Groveland, FL' },
  { value: 'Groveland_IL', label: 'Groveland, IL' },
  { value: 'Groveland_CA', label: 'Groveland, CA' },
  { value: 'Groveoak_AL', label: 'Groveoak, AL' },
  { value: 'Groveport_OH', label: 'Groveport, OH' },
  { value: 'Grover_NC', label: 'Grover, NC' },
  { value: 'Grover_CO', label: 'Grover, CO' },
  { value: 'Grover_WY', label: 'Grover, WY' },
  { value: 'Grover Beach_CA', label: 'Grover Beach, CA' },
  { value: 'Grover Hill_OH', label: 'Grover Hill, OH' },
  { value: 'Grovertown_IN', label: 'Grovertown, IN' },
  { value: 'Groves_TX', label: 'Groves, TX' },
  { value: 'Grovespring_MO', label: 'Grovespring, MO' },
  { value: 'Groveton_NH', label: 'Groveton, NH' },
  { value: 'Groveton_TX', label: 'Groveton, TX' },
  { value: 'Grovetown_GA', label: 'Grovetown, GA' },
  { value: 'Grubbs_AR', label: 'Grubbs, AR' },
  { value: 'Grubville_MO', label: 'Grubville, MO' },
  { value: 'Gruetli Laager_TN', label: 'Gruetli Laager, TN' },
  { value: 'Grulla_TX', label: 'Grulla, TX' },
  { value: 'Grundy_VA', label: 'Grundy, VA' },
  { value: 'Grundy Center_IA', label: 'Grundy Center, IA' },
  { value: 'Gruver_TX', label: 'Gruver, TX' },
  { value: 'Grygla_MN', label: 'Grygla, MN' },
  { value: 'Guadalupe_CA', label: 'Guadalupe, CA' },
  { value: 'Guadalupita_NM', label: 'Guadalupita, NM' },
  { value: 'Gualala_CA', label: 'Gualala, CA' },
  { value: 'Guanica_PR', label: 'Guanica, PR' },
  { value: 'Guatay_CA', label: 'Guatay, CA' },
  { value: 'Guayama_PR', label: 'Guayama, PR' },
  { value: 'Guayanilla_PR', label: 'Guayanilla, PR' },
  { value: 'Guaynabo_PR', label: 'Guaynabo, PR' },
  { value: 'Guerneville_CA', label: 'Guerneville, CA' },
  { value: 'Guernsey_IA', label: 'Guernsey, IA' },
  { value: 'Guernsey_WY', label: 'Guernsey, WY' },
  { value: 'Guerra_TX', label: 'Guerra, TX' },
  { value: 'Gueydan_LA', label: 'Gueydan, LA' },
  { value: 'Guffey_CO', label: 'Guffey, CO' },
  { value: 'Guide Rock_NE', label: 'Guide Rock, NE' },
  { value: 'Guild_TN', label: 'Guild, TN' },
  { value: 'Guilderland_NY', label: 'Guilderland, NY' },
  { value: 'Guilderland Center_NY', label: 'Guilderland Center, NY' },
  { value: 'Guildhall_VT', label: 'Guildhall, VT' },
  { value: 'Guilford_ME', label: 'Guilford, ME' },
  { value: 'Guilford_CT', label: 'Guilford, CT' },
  { value: 'Guilford_NY', label: 'Guilford, NY' },
  { value: 'Guilford_IN', label: 'Guilford, IN' },
  { value: 'Guilford_MO', label: 'Guilford, MO' },
  { value: 'Guin_AL', label: 'Guin, AL' },
  { value: 'Guinda_CA', label: 'Guinda, CA' },
  { value: 'Guion_AR', label: 'Guion, AR' },
  { value: 'Gulf_NC', label: 'Gulf, NC' },
  { value: 'Gulf Breeze_FL', label: 'Gulf Breeze, FL' },
  { value: 'Gulf Hammock_FL', label: 'Gulf Hammock, FL' },
  { value: 'Gulf Shores_AL', label: 'Gulf Shores, AL' },
  { value: 'Gulfport_MS', label: 'Gulfport, MS' },
  { value: 'Gulliver_MI', label: 'Gulliver, MI' },
  { value: 'Gully_MN', label: 'Gully, MN' },
  { value: 'Gulston_KY', label: 'Gulston, KY' },
  { value: 'Gum Spring_VA', label: 'Gum Spring, VA' },
  { value: 'Gunlock_KY', label: 'Gunlock, KY' },
  { value: 'Gunlock_UT', label: 'Gunlock, UT' },
  { value: 'Gunnison_MS', label: 'Gunnison, MS' },
  { value: 'Gunnison_CO', label: 'Gunnison, CO' },
  { value: 'Gunnison_UT', label: 'Gunnison, UT' },
  { value: 'Gunpowder_MD', label: 'Gunpowder, MD' },
  { value: 'Gunter_TX', label: 'Gunter, TX' },
  { value: 'Guntersville_AL', label: 'Guntersville, AL' },
  { value: 'Guntown_MS', label: 'Guntown, MS' },
  { value: 'Gurabo_PR', label: 'Gurabo, PR' },
  { value: 'Gurdon_AR', label: 'Gurdon, AR' },
  { value: 'Gurley_AL', label: 'Gurley, AL' },
  { value: 'Gurley_NE', label: 'Gurley, NE' },
  { value: 'Gurnee_IL', label: 'Gurnee, IL' },
  { value: 'Gustavus_AK', label: 'Gustavus, AK' },
  { value: 'Gustine_TX', label: 'Gustine, TX' },
  { value: 'Gustine_CA', label: 'Gustine, CA' },
  { value: 'Guston_KY', label: 'Guston, KY' },
  { value: 'Guthrie_KY', label: 'Guthrie, KY' },
  { value: 'Guthrie_OK', label: 'Guthrie, OK' },
  { value: 'Guthrie_TX', label: 'Guthrie, TX' },
  { value: 'Guthrie Center_IA', label: 'Guthrie Center, IA' },
  { value: 'Guttenberg_IA', label: 'Guttenberg, IA' },
  { value: 'Guy_AR', label: 'Guy, AR' },
  { value: 'Guy_TX', label: 'Guy, TX' },
  { value: 'Guymon_OK', label: 'Guymon, OK' },
  { value: 'Guys_TN', label: 'Guys, TN' },
  { value: 'Guys Mills_PA', label: 'Guys Mills, PA' },
  { value: 'Guysville_OH', label: 'Guysville, OH' },
  { value: 'Guyton_GA', label: 'Guyton, GA' },
  { value: 'Gwinn_MI', label: 'Gwinn, MI' },
  { value: 'Gwinner_ND', label: 'Gwinner, ND' },
  { value: 'Gwynedd_PA', label: 'Gwynedd, PA' },
  { value: 'Gwynedd Valley_PA', label: 'Gwynedd Valley, PA' },
  { value: 'Gwynn_VA', label: 'Gwynn, VA' },
  { value: 'Gwynn Oak_MD', label: 'Gwynn Oak, MD' },
  { value: 'Gwynneville_IN', label: 'Gwynneville, IN' },
  { value: 'Gypsum_OH', label: 'Gypsum, OH' },
  { value: 'Gypsum_KS', label: 'Gypsum, KS' },
  { value: 'Gypsum_CO', label: 'Gypsum, CO' },
  { value: 'Gypsy_WV', label: 'Gypsy, WV' },
  { value: 'Hachita_NM', label: 'Hachita, NM' },
  { value: 'Hacienda Heights_CA', label: 'Hacienda Heights, CA' },
  { value: 'Hackberry_LA', label: 'Hackberry, LA' },
  { value: 'Hackberry_AZ', label: 'Hackberry, AZ' },
  { value: 'Hackensack_NJ', label: 'Hackensack, NJ' },
  { value: 'Hackensack_MN', label: 'Hackensack, MN' },
  { value: 'Hacker Valley_WV', label: 'Hacker Valley, WV' },
  { value: 'Hackett_AR', label: 'Hackett, AR' },
  { value: 'Hackettstown_NJ', label: 'Hackettstown, NJ' },
  { value: 'Hackleburg_AL', label: 'Hackleburg, AL' },
  { value: 'Hacksneck_VA', label: 'Hacksneck, VA' },
  { value: 'Haddam_CT', label: 'Haddam, CT' },
  { value: 'Haddam_KS', label: 'Haddam, KS' },
  { value: 'Haddock_GA', label: 'Haddock, GA' },
  { value: 'Haddon Heights_NJ', label: 'Haddon Heights, NJ' },
  { value: 'Haddonfield_NJ', label: 'Haddonfield, NJ' },
  { value: 'Hadley_MA', label: 'Hadley, MA' },
  { value: 'Hadley_NY', label: 'Hadley, NY' },
  { value: 'Hadley_PA', label: 'Hadley, PA' },
  { value: 'Hadley_MI', label: 'Hadley, MI' },
  { value: 'Hadlyme_CT', label: 'Hadlyme, CT' },
  { value: 'Hagaman_NY', label: 'Hagaman, NY' },
  { value: 'Hagan_GA', label: 'Hagan, GA' },
  { value: 'Hagarville_AR', label: 'Hagarville, AR' },
  { value: 'Hagatna_GU', label: 'Hagatna, GU' },
  { value: 'Hager City_WI', label: 'Hager City, WI' },
  { value: 'Hagerhill_KY', label: 'Hagerhill, KY' },
  { value: 'Hagerman_ID', label: 'Hagerman, ID' },
  { value: 'Hagerman_NM', label: 'Hagerman, NM' },
  { value: 'Hagerstown_MD', label: 'Hagerstown, MD' },
  { value: 'Hagerstown_IN', label: 'Hagerstown, IN' },
  { value: 'Hague_NY', label: 'Hague, NY' },
  { value: 'Hague_VA', label: 'Hague, VA' },
  { value: 'Hague_ND', label: 'Hague, ND' },
  { value: 'Hahira_GA', label: 'Hahira, GA' },
  { value: 'Hahnville_LA', label: 'Hahnville, LA' },
  { value: 'Haigler_NE', label: 'Haigler, NE' },
  { value: 'Haiku_HI', label: 'Haiku, HI' },
  { value: 'Hailesboro_NY', label: 'Hailesboro, NY' },
  { value: 'Hailey_ID', label: 'Hailey, ID' },
  { value: 'Haileyville_OK', label: 'Haileyville, OK' },
  { value: 'Haines_OR', label: 'Haines, OR' },
  { value: 'Haines_AK', label: 'Haines, AK' },
  { value: 'Haines City_FL', label: 'Haines City, FL' },
  { value: 'Haines Falls_NY', label: 'Haines Falls, NY' },
  { value: 'Hainesport_NJ', label: 'Hainesport, NJ' },
  { value: 'Hakalau_HI', label: 'Hakalau, HI' },
  { value: 'Halbur_IA', label: 'Halbur, IA' },
  { value: 'Halcottsville_NY', label: 'Halcottsville, NY' },
  { value: 'Hale_MI', label: 'Hale, MI' },
  { value: 'Hale_MO', label: 'Hale, MO' },
  { value: 'Hale Center_TX', label: 'Hale Center, TX' },
  { value: 'Haledon_NJ', label: 'Haledon, NJ' },
  { value: 'Haleiwa_HI', label: 'Haleiwa, HI' },
  { value: 'Hales Corners_WI', label: 'Hales Corners, WI' },
  { value: 'Halethorpe_MD', label: 'Halethorpe, MD' },
  { value: 'Haleyville_AL', label: 'Haleyville, AL' },
  { value: 'Half Moon Bay_CA', label: 'Half Moon Bay, CA' },
  { value: 'Half Way_MO', label: 'Half Way, MO' },
  { value: 'Halfway_OR', label: 'Halfway, OR' },
  { value: 'Halifax_MA', label: 'Halifax, MA' },
  { value: 'Halifax_PA', label: 'Halifax, PA' },
  { value: 'Halifax_VA', label: 'Halifax, VA' },
  { value: 'Halifax_NC', label: 'Halifax, NC' },
  { value: 'Hall_NY', label: 'Hall, NY' },
  { value: 'Hall_MT', label: 'Hall, MT' },
  { value: 'Hall Summit_LA', label: 'Hall Summit, LA' },
  { value: 'Hallam_NE', label: 'Hallam, NE' },
  { value: 'Hallandale_FL', label: 'Hallandale, FL' },
  { value: 'Hallett_OK', label: 'Hallett, OK' },
  { value: 'Hallettsville_TX', label: 'Hallettsville, TX' },
  { value: 'Halliday_ND', label: 'Halliday, ND' },
  { value: 'Hallie_KY', label: 'Hallie, KY' },
  { value: 'Hallieford_VA', label: 'Hallieford, VA' },
  { value: 'Hallock_MN', label: 'Hallock, MN' },
  { value: 'Hallowell_ME', label: 'Hallowell, ME' },
  { value: 'Halls_TN', label: 'Halls, TN' },
  { value: 'Hallsboro_NC', label: 'Hallsboro, NC' },
  { value: 'Hallstead_PA', label: 'Hallstead, PA' },
  { value: 'Hallsville_MO', label: 'Hallsville, MO' },
  { value: 'Hallsville_TX', label: 'Hallsville, TX' },
  { value: 'Halltown_MO', label: 'Halltown, MO' },
  { value: 'Hallwood_VA', label: 'Hallwood, VA' },
  { value: 'Halma_MN', label: 'Halma, MN' },
  { value: 'Halsey_NE', label: 'Halsey, NE' },
  { value: 'Halsey_OR', label: 'Halsey, OR' },
  { value: 'Halstad_MN', label: 'Halstad, MN' },
  { value: 'Halstead_KS', label: 'Halstead, KS' },
  { value: 'Haltom City_TX', label: 'Haltom City, TX' },
  { value: 'Hambleton_WV', label: 'Hambleton, WV' },
  { value: 'Hamburg_NJ', label: 'Hamburg, NJ' },
  { value: 'Hamburg_NY', label: 'Hamburg, NY' },
  { value: 'Hamburg_PA', label: 'Hamburg, PA' },
  { value: 'Hamburg_MI', label: 'Hamburg, MI' },
  { value: 'Hamburg_IA', label: 'Hamburg, IA' },
  { value: 'Hamburg_MN', label: 'Hamburg, MN' },
  { value: 'Hamburg_IL', label: 'Hamburg, IL' },
  { value: 'Hamburg_LA', label: 'Hamburg, LA' },
  { value: 'Hamburg_AR', label: 'Hamburg, AR' },
  { value: 'Hamden_CT', label: 'Hamden, CT' },
  { value: 'Hamden_NY', label: 'Hamden, NY' },
  { value: 'Hamden_OH', label: 'Hamden, OH' },
  { value: 'Hamel_MN', label: 'Hamel, MN' },
  { value: 'Hamel_IL', label: 'Hamel, IL' },
  { value: 'Hamer_SC', label: 'Hamer, SC' },
  { value: 'Hamer_ID', label: 'Hamer, ID' },
  { value: 'Hamersville_OH', label: 'Hamersville, OH' },
  { value: 'Hamill_SD', label: 'Hamill, SD' },
  { value: 'Hamilton_NY', label: 'Hamilton, NY' },
  { value: 'Hamilton_PA', label: 'Hamilton, PA' },
  { value: 'Hamilton_VA', label: 'Hamilton, VA' },
  { value: 'Hamilton_NC', label: 'Hamilton, NC' },
  { value: 'Hamilton_GA', label: 'Hamilton, GA' },
  { value: 'Hamilton_AL', label: 'Hamilton, AL' },
  { value: 'Hamilton_MS', label: 'Hamilton, MS' },
  { value: 'Hamilton_OH', label: 'Hamilton, OH' },
  { value: 'Hamilton_IN', label: 'Hamilton, IN' },
  { value: 'Hamilton_MI', label: 'Hamilton, MI' },
  { value: 'Hamilton_IA', label: 'Hamilton, IA' },
  { value: 'Hamilton_ND', label: 'Hamilton, ND' },
  { value: 'Hamilton_MT', label: 'Hamilton, MT' },
  { value: 'Hamilton_IL', label: 'Hamilton, IL' },
  { value: 'Hamilton_MO', label: 'Hamilton, MO' },
  { value: 'Hamilton_KS', label: 'Hamilton, KS' },
  { value: 'Hamilton_TX', label: 'Hamilton, TX' },
  { value: 'Hamilton_CO', label: 'Hamilton, CO' },
  { value: 'Hamilton_WA', label: 'Hamilton, WA' },
  { value: 'Hamilton City_CA', label: 'Hamilton City, CA' },
  { value: 'Hamler_OH', label: 'Hamler, OH' },
  { value: 'Hamlet_NC', label: 'Hamlet, NC' },
  { value: 'Hamlet_IN', label: 'Hamlet, IN' },
  { value: 'Hamlin_NY', label: 'Hamlin, NY' },
  { value: 'Hamlin_WV', label: 'Hamlin, WV' },
  { value: 'Hamlin_IA', label: 'Hamlin, IA' },
  { value: 'Hamlin_TX', label: 'Hamlin, TX' },
  { value: 'Hammett_ID', label: 'Hammett, ID' },
  { value: 'Hammon_OK', label: 'Hammon, OK' },
  { value: 'Hammond_NY', label: 'Hammond, NY' },
  { value: 'Hammond_IN', label: 'Hammond, IN' },
  { value: 'Hammond_WI', label: 'Hammond, WI' },
  { value: 'Hammond_MT', label: 'Hammond, MT' },
  { value: 'Hammond_IL', label: 'Hammond, IL' },
  { value: 'Hammond_LA', label: 'Hammond, LA' },
  { value: 'Hammond_OR', label: 'Hammond, OR' },
  { value: 'Hammondsport_NY', label: 'Hammondsport, NY' },
  { value: 'Hammondsville_OH', label: 'Hammondsville, OH' },
  { value: 'Hammonton_NJ', label: 'Hammonton, NJ' },
  { value: 'Hampden_MA', label: 'Hampden, MA' },
  { value: 'Hampden_ME', label: 'Hampden, ME' },
  { value: 'Hampden_ND', label: 'Hampden, ND' },
  { value: 'Hampden Sydney_VA', label: 'Hampden Sydney, VA' },
  { value: 'Hampshire_TN', label: 'Hampshire, TN' },
  { value: 'Hampshire_IL', label: 'Hampshire, IL' },
  { value: 'Hampstead_NH', label: 'Hampstead, NH' },
  { value: 'Hampstead_MD', label: 'Hampstead, MD' },
  { value: 'Hampstead_NC', label: 'Hampstead, NC' },
  { value: 'Hampton_NH', label: 'Hampton, NH' },
  { value: 'Hampton_CT', label: 'Hampton, CT' },
  { value: 'Hampton_NJ', label: 'Hampton, NJ' },
  { value: 'Hampton_NY', label: 'Hampton, NY' },
  { value: 'Hampton_VA', label: 'Hampton, VA' },
  { value: 'Hampton_SC', label: 'Hampton, SC' },
  { value: 'Hampton_GA', label: 'Hampton, GA' },
  { value: 'Hampton_FL', label: 'Hampton, FL' },
  { value: 'Hampton_TN', label: 'Hampton, TN' },
  { value: 'Hampton_KY', label: 'Hampton, KY' },
  { value: 'Hampton_IA', label: 'Hampton, IA' },
  { value: 'Hampton_MN', label: 'Hampton, MN' },
  { value: 'Hampton_IL', label: 'Hampton, IL' },
  { value: 'Hampton_NE', label: 'Hampton, NE' },
  { value: 'Hampton_AR', label: 'Hampton, AR' },
  { value: 'Hampton Bays_NY', label: 'Hampton Bays, NY' },
  { value: 'Hampton Falls_NH', label: 'Hampton Falls, NH' },
  { value: 'Hamptonville_NC', label: 'Hamptonville, NC' },
  { value: 'Hamshire_TX', label: 'Hamshire, TX' },
  { value: 'Hamtramck_MI', label: 'Hamtramck, MI' },
  { value: 'Hana_HI', label: 'Hana, HI' },
  { value: 'Hanahan_SC', label: 'Hanahan, SC' },
  { value: 'Hanalei_HI', label: 'Hanalei, HI' },
  { value: 'Hanapepe_HI', label: 'Hanapepe, HI' },
  { value: 'Hanceville_AL', label: 'Hanceville, AL' },
  { value: 'Hancock_NH', label: 'Hancock, NH' },
  { value: 'Hancock_ME', label: 'Hancock, ME' },
  { value: 'Hancock_VT', label: 'Hancock, VT' },
  { value: 'Hancock_NY', label: 'Hancock, NY' },
  { value: 'Hancock_MD', label: 'Hancock, MD' },
  { value: 'Hancock_MI', label: 'Hancock, MI' },
  { value: 'Hancock_IA', label: 'Hancock, IA' },
  { value: 'Hancock_WI', label: 'Hancock, WI' },
  { value: 'Hancock_MN', label: 'Hancock, MN' },
  { value: 'Hancocks Bridge_NJ', label: 'Hancocks Bridge, NJ' },
  { value: 'Handley_WV', label: 'Handley, WV' },
  { value: 'Hanford_CA', label: 'Hanford, CA' },
  { value: 'Hankamer_TX', label: 'Hankamer, TX' },
  { value: 'Hankins_NY', label: 'Hankins, NY' },
  { value: 'Hankinson_ND', label: 'Hankinson, ND' },
  { value: 'Hanksville_UT', label: 'Hanksville, UT' },
  { value: 'Hanley Falls_MN', label: 'Hanley Falls, MN' },
  { value: 'Hanlontown_IA', label: 'Hanlontown, IA' },
  { value: 'Hanna_IN', label: 'Hanna, IN' },
  { value: 'Hanna_OK', label: 'Hanna, OK' },
  { value: 'Hanna_WY', label: 'Hanna, WY' },
  { value: 'Hanna_UT', label: 'Hanna, UT' },
  { value: 'Hanna City_IL', label: 'Hanna City, IL' },
  { value: 'Hannacroix_NY', label: 'Hannacroix, NY' },
  { value: 'Hannaford_ND', label: 'Hannaford, ND' },
  { value: 'Hannah_ND', label: 'Hannah, ND' },
  { value: 'Hannastown_PA', label: 'Hannastown, PA' },
  { value: 'Hannawa Falls_NY', label: 'Hannawa Falls, NY' },
  { value: 'Hannibal_NY', label: 'Hannibal, NY' },
  { value: 'Hannibal_OH', label: 'Hannibal, OH' },
  { value: 'Hannibal_MO', label: 'Hannibal, MO' },
  { value: 'Hanover_MA', label: 'Hanover, MA' },
  { value: 'Hanover_NH', label: 'Hanover, NH' },
  { value: 'Hanover_ME', label: 'Hanover, ME' },
  { value: 'Hanover_CT', label: 'Hanover, CT' },
  { value: 'Hanover_PA', label: 'Hanover, PA' },
  { value: 'Hanover_MD', label: 'Hanover, MD' },
  { value: 'Hanover_VA', label: 'Hanover, VA' },
  { value: 'Hanover_WV', label: 'Hanover, WV' },
  { value: 'Hanover_IN', label: 'Hanover, IN' },
  { value: 'Hanover_MI', label: 'Hanover, MI' },
  { value: 'Hanover_MN', label: 'Hanover, MN' },
  { value: 'Hanover_IL', label: 'Hanover, IL' },
  { value: 'Hanover_KS', label: 'Hanover, KS' },
  { value: 'Hanover_NM', label: 'Hanover, NM' },
  { value: 'Hanover Park_IL', label: 'Hanover Park, IL' },
  { value: 'Hanoverton_OH', label: 'Hanoverton, OH' },
  { value: 'Hansboro_ND', label: 'Hansboro, ND' },
  { value: 'Hanscom Afb_MA', label: 'Hanscom Afb, MA' },
  { value: 'Hansen_ID', label: 'Hansen, ID' },
  { value: 'Hansford_WV', label: 'Hansford, WV' },
  { value: 'Hanska_MN', label: 'Hanska, MN' },
  { value: 'Hanson_MA', label: 'Hanson, MA' },
  { value: 'Hanson_KY', label: 'Hanson, KY' },
  { value: 'Hanston_KS', label: 'Hanston, KS' },
  { value: 'Hansville_WA', label: 'Hansville, WA' },
  { value: 'Happy_KY', label: 'Happy, KY' },
  { value: 'Happy_TX', label: 'Happy, TX' },
  { value: 'Happy Camp_CA', label: 'Happy Camp, CA' },
  { value: 'Happy Jack_AZ', label: 'Happy Jack, AZ' },
  { value: 'Happy Valley_OR', label: 'Happy Valley, OR' },
  { value: 'Haralson_GA', label: 'Haralson, GA' },
  { value: 'Harbert_MI', label: 'Harbert, MI' },
  { value: 'Harbeson_DE', label: 'Harbeson, DE' },
  { value: 'Harbinger_NC', label: 'Harbinger, NC' },
  { value: 'Harbor Beach_MI', label: 'Harbor Beach, MI' },
  { value: 'Harbor City_CA', label: 'Harbor City, CA' },
  { value: 'Harbor Springs_MI', label: 'Harbor Springs, MI' },
  { value: 'Harbor View_OH', label: 'Harbor View, OH' },
  { value: 'Harborcreek_PA', label: 'Harborcreek, PA' },
  { value: 'Harborside_ME', label: 'Harborside, ME' },
  { value: 'Harborton_VA', label: 'Harborton, VA' },
  { value: 'Harcourt_IA', label: 'Harcourt, IA' },
  { value: 'Hardaway_AL', label: 'Hardaway, AL' },
  { value: 'Hardeeville_SC', label: 'Hardeeville, SC' },
  { value: 'Hardenville_MO', label: 'Hardenville, MO' },
  { value: 'Hardesty_OK', label: 'Hardesty, OK' },
  { value: 'Hardin_KY', label: 'Hardin, KY' },
  { value: 'Hardin_MT', label: 'Hardin, MT' },
  { value: 'Hardin_IL', label: 'Hardin, IL' },
  { value: 'Hardin_MO', label: 'Hardin, MO' },
  { value: 'Hardin_TX', label: 'Hardin, TX' },
  { value: 'Hardinsburg_KY', label: 'Hardinsburg, KY' },
  { value: 'Hardinsburg_IN', label: 'Hardinsburg, IN' },
  { value: 'Hardtner_KS', label: 'Hardtner, KS' },
  { value: 'Hardwick_MA', label: 'Hardwick, MA' },
  { value: 'Hardwick_VT', label: 'Hardwick, VT' },
  { value: 'Hardwick_GA', label: 'Hardwick, GA' },
  { value: 'Hardwick_MN', label: 'Hardwick, MN' },
  { value: 'Hardy_VA', label: 'Hardy, VA' },
  { value: 'Hardy_KY', label: 'Hardy, KY' },
  { value: 'Hardy_IA', label: 'Hardy, IA' },
  { value: 'Hardy_NE', label: 'Hardy, NE' },
  { value: 'Hardy_AR', label: 'Hardy, AR' },
  { value: 'Hardyville_VA', label: 'Hardyville, VA' },
  { value: 'Hardyville_KY', label: 'Hardyville, KY' },
  { value: 'Harford_NY', label: 'Harford, NY' },
  { value: 'Harford_PA', label: 'Harford, PA' },
  { value: 'Hargill_TX', label: 'Hargill, TX' },
  { value: 'Harker Heights_TX', label: 'Harker Heights, TX' },
  { value: 'Harkers Island_NC', label: 'Harkers Island, NC' },
  { value: 'Harlan_KY', label: 'Harlan, KY' },
  { value: 'Harlan_IN', label: 'Harlan, IN' },
  { value: 'Harlan_IA', label: 'Harlan, IA' },
  { value: 'Harleigh_PA', label: 'Harleigh, PA' },
  { value: 'Harlem_GA', label: 'Harlem, GA' },
  { value: 'Harlem_MT', label: 'Harlem, MT' },
  { value: 'Harleton_TX', label: 'Harleton, TX' },
  { value: 'Harleysville_PA', label: 'Harleysville, PA' },
  { value: 'Harleyville_SC', label: 'Harleyville, SC' },
  { value: 'Harlingen_TX', label: 'Harlingen, TX' },
  { value: 'Harlowton_MT', label: 'Harlowton, MT' },
  { value: 'Harman_WV', label: 'Harman, WV' },
  { value: 'Harmans_MD', label: 'Harmans, MD' },
  { value: 'Harmon_IL', label: 'Harmon, IL' },
  { value: 'Harmonsburg_PA', label: 'Harmonsburg, PA' },
  { value: 'Harmony_RI', label: 'Harmony, RI' },
  { value: 'Harmony_ME', label: 'Harmony, ME' },
  { value: 'Harmony_PA', label: 'Harmony, PA' },
  { value: 'Harmony_NC', label: 'Harmony, NC' },
  { value: 'Harmony_IN', label: 'Harmony, IN' },
  { value: 'Harmony_MN', label: 'Harmony, MN' },
  { value: 'Harned_KY', label: 'Harned, KY' },
  { value: 'Harold_KY', label: 'Harold, KY' },
  { value: 'Harper_WV', label: 'Harper, WV' },
  { value: 'Harper_IA', label: 'Harper, IA' },
  { value: 'Harper_KS', label: 'Harper, KS' },
  { value: 'Harper_TX', label: 'Harper, TX' },
  { value: 'Harper_OR', label: 'Harper, OR' },
  { value: 'Harper Woods_MI', label: 'Harper Woods, MI' },
  { value: 'Harpers Ferry_WV', label: 'Harpers Ferry, WV' },
  { value: 'Harpers Ferry_IA', label: 'Harpers Ferry, IA' },
  { value: 'Harpersfield_NY', label: 'Harpersfield, NY' },
  { value: 'Harpersville_AL', label: 'Harpersville, AL' },
  { value: 'Harpster_OH', label: 'Harpster, OH' },
  { value: 'Harpswell_ME', label: 'Harpswell, ME' },
  { value: 'Harpursville_NY', label: 'Harpursville, NY' },
  { value: 'Harrah_OK', label: 'Harrah, OK' },
  { value: 'Harrah_WA', label: 'Harrah, WA' },
  { value: 'Harrell_AR', label: 'Harrell, AR' },
  { value: 'Harrells_NC', label: 'Harrells, NC' },
  { value: 'Harrellsville_NC', label: 'Harrellsville, NC' },
  { value: 'Harriet_AR', label: 'Harriet, AR' },
  { value: 'Harrietta_MI', label: 'Harrietta, MI' },
  { value: 'Harriman_NY', label: 'Harriman, NY' },
  { value: 'Harriman_TN', label: 'Harriman, TN' },
  { value: 'Harrington_ME', label: 'Harrington, ME' },
  { value: 'Harrington_DE', label: 'Harrington, DE' },
  { value: 'Harrington_WA', label: 'Harrington, WA' },
  { value: 'Harrington Park_NJ', label: 'Harrington Park, NJ' },
  { value: 'Harris_NY', label: 'Harris, NY' },
  { value: 'Harris_NC', label: 'Harris, NC' },
  { value: 'Harris_IA', label: 'Harris, IA' },
  { value: 'Harris_MN', label: 'Harris, MN' },
  { value: 'Harris_MO', label: 'Harris, MO' },
  { value: 'Harrisburg_PA', label: 'Harrisburg, PA' },
  { value: 'Harrisburg_NC', label: 'Harrisburg, NC' },
  { value: 'Harrisburg_OH', label: 'Harrisburg, OH' },
  { value: 'Harrisburg_SD', label: 'Harrisburg, SD' },
  { value: 'Harrisburg_IL', label: 'Harrisburg, IL' },
  { value: 'Harrisburg_MO', label: 'Harrisburg, MO' },
  { value: 'Harrisburg_NE', label: 'Harrisburg, NE' },
  { value: 'Harrisburg_AR', label: 'Harrisburg, AR' },
  { value: 'Harrisburg_OR', label: 'Harrisburg, OR' },
  { value: 'Harrison_ME', label: 'Harrison, ME' },
  { value: 'Harrison_NJ', label: 'Harrison, NJ' },
  { value: 'Harrison_NY', label: 'Harrison, NY' },
  { value: 'Harrison_GA', label: 'Harrison, GA' },
  { value: 'Harrison_TN', label: 'Harrison, TN' },
  { value: 'Harrison_OH', label: 'Harrison, OH' },
  { value: 'Harrison_MI', label: 'Harrison, MI' },
  { value: 'Harrison_SD', label: 'Harrison, SD' },
  { value: 'Harrison_MT', label: 'Harrison, MT' },
  { value: 'Harrison_NE', label: 'Harrison, NE' },
  { value: 'Harrison_AR', label: 'Harrison, AR' },
  { value: 'Harrison_ID', label: 'Harrison, ID' },
  { value: 'Harrison City_PA', label: 'Harrison City, PA' },
  { value: 'Harrison Township_MI', label: 'Harrison Township, MI' },
  { value: 'Harrison Valley_PA', label: 'Harrison Valley, PA' },
  { value: 'Harrisonburg_VA', label: 'Harrisonburg, VA' },
  { value: 'Harrisonburg_LA', label: 'Harrisonburg, LA' },
  { value: 'Harrisonville_NJ', label: 'Harrisonville, NJ' },
  { value: 'Harrisonville_PA', label: 'Harrisonville, PA' },
  { value: 'Harrisonville_MO', label: 'Harrisonville, MO' },
  { value: 'Harristown_IL', label: 'Harristown, IL' },
  { value: 'Harrisville_RI', label: 'Harrisville, RI' },
  { value: 'Harrisville_NH', label: 'Harrisville, NH' },
  { value: 'Harrisville_NY', label: 'Harrisville, NY' },
  { value: 'Harrisville_PA', label: 'Harrisville, PA' },
  { value: 'Harrisville_WV', label: 'Harrisville, WV' },
  { value: 'Harrisville_MS', label: 'Harrisville, MS' },
  { value: 'Harrisville_OH', label: 'Harrisville, OH' },
  { value: 'Harrisville_MI', label: 'Harrisville, MI' },
  { value: 'Harrod_OH', label: 'Harrod, OH' },
  { value: 'Harrodsburg_KY', label: 'Harrodsburg, KY' },
  { value: 'Harrodsburg_IN', label: 'Harrodsburg, IN' },
  { value: 'Harrogate_TN', label: 'Harrogate, TN' },
  { value: 'Harrold_SD', label: 'Harrold, SD' },
  { value: 'Harrold_TX', label: 'Harrold, TX' },
  { value: 'Harsens Island_MI', label: 'Harsens Island, MI' },
  { value: 'Harshaw_WI', label: 'Harshaw, WI' },
  { value: 'Hart_MI', label: 'Hart, MI' },
  { value: 'Hart_TX', label: 'Hart, TX' },
  { value: 'Hartfield_VA', label: 'Hartfield, VA' },
  { value: 'Hartford_CT', label: 'Hartford, CT' },
  { value: 'Hartford_NY', label: 'Hartford, NY' },
  { value: 'Hartford_WV', label: 'Hartford, WV' },
  { value: 'Hartford_AL', label: 'Hartford, AL' },
  { value: 'Hartford_TN', label: 'Hartford, TN' },
  { value: 'Hartford_KY', label: 'Hartford, KY' },
  { value: 'Hartford_OH', label: 'Hartford, OH' },
  { value: 'Hartford_MI', label: 'Hartford, MI' },
  { value: 'Hartford_IA', label: 'Hartford, IA' },
  { value: 'Hartford_WI', label: 'Hartford, WI' },
  { value: 'Hartford_SD', label: 'Hartford, SD' },
  { value: 'Hartford_IL', label: 'Hartford, IL' },
  { value: 'Hartford_KS', label: 'Hartford, KS' },
  { value: 'Hartford_AR', label: 'Hartford, AR' },
  { value: 'Hartford City_IN', label: 'Hartford City, IN' },
  { value: 'Hartington_NE', label: 'Hartington, NE' },
  { value: 'Hartland_ME', label: 'Hartland, ME' },
  { value: 'Hartland_VT', label: 'Hartland, VT' },
  { value: 'Hartland_MI', label: 'Hartland, MI' },
  { value: 'Hartland_WI', label: 'Hartland, WI' },
  { value: 'Hartland_MN', label: 'Hartland, MN' },
  { value: 'Hartleton_PA', label: 'Hartleton, PA' },
  { value: 'Hartley_IA', label: 'Hartley, IA' },
  { value: 'Hartley_TX', label: 'Hartley, TX' },
  { value: 'Hartline_WA', label: 'Hartline, WA' },
  { value: 'Hartly_DE', label: 'Hartly, DE' },
  { value: 'Hartman_AR', label: 'Hartman, AR' },
  { value: 'Hartman_CO', label: 'Hartman, CO' },
  { value: 'Harts_WV', label: 'Harts, WV' },
  { value: 'Hartsburg_IL', label: 'Hartsburg, IL' },
  { value: 'Hartsburg_MO', label: 'Hartsburg, MO' },
  { value: 'Hartsdale_NY', label: 'Hartsdale, NY' },
  { value: 'Hartsel_CO', label: 'Hartsel, CO' },
  { value: 'Hartselle_AL', label: 'Hartselle, AL' },
  { value: 'Hartsfield_GA', label: 'Hartsfield, GA' },
  { value: 'Hartshorn_MO', label: 'Hartshorn, MO' },
  { value: 'Hartshorne_OK', label: 'Hartshorne, OK' },
  { value: 'Hartstown_PA', label: 'Hartstown, PA' },
  { value: 'Hartsville_SC', label: 'Hartsville, SC' },
  { value: 'Hartsville_TN', label: 'Hartsville, TN' },
  { value: 'Hartsville_IN', label: 'Hartsville, IN' },
  { value: 'Hartville_OH', label: 'Hartville, OH' },
  { value: 'Hartville_MO', label: 'Hartville, MO' },
  { value: 'Hartville_WY', label: 'Hartville, WY' },
  { value: 'Hartwell_GA', label: 'Hartwell, GA' },
  { value: 'Hartwick_NY', label: 'Hartwick, NY' },
  { value: 'Hartwick_IA', label: 'Hartwick, IA' },
  { value: 'Harvard_MA', label: 'Harvard, MA' },
  { value: 'Harvard_IL', label: 'Harvard, IL' },
  { value: 'Harvard_NE', label: 'Harvard, NE' },
  { value: 'Harvard_ID', label: 'Harvard, ID' },
  { value: 'Harvel_IL', label: 'Harvel, IL' },
  { value: 'Harvest_AL', label: 'Harvest, AL' },
  { value: 'Harvey_IA', label: 'Harvey, IA' },
  { value: 'Harvey_ND', label: 'Harvey, ND' },
  { value: 'Harvey_IL', label: 'Harvey, IL' },
  { value: 'Harvey_LA', label: 'Harvey, LA' },
  { value: 'Harvey_AR', label: 'Harvey, AR' },
  { value: 'Harveys Lake_PA', label: 'Harveys Lake, PA' },
  { value: 'Harveysburg_OH', label: 'Harveysburg, OH' },
  { value: 'Harveyville_KS', label: 'Harveyville, KS' },
  { value: 'Harviell_MO', label: 'Harviell, MO' },
  { value: 'Harwich_MA', label: 'Harwich, MA' },
  { value: 'Harwich Port_MA', label: 'Harwich Port, MA' },
  { value: 'Harwick_PA', label: 'Harwick, PA' },
  { value: 'Harwinton_CT', label: 'Harwinton, CT' },
  { value: 'Harwood_MD', label: 'Harwood, MD' },
  { value: 'Harwood_ND', label: 'Harwood, ND' },
  { value: 'Harwood_MO', label: 'Harwood, MO' },
  { value: 'Harwood_TX', label: 'Harwood, TX' },
  { value: 'Harwood Heights_IL', label: 'Harwood Heights, IL' },
  { value: 'Hasbrouck Heights_NJ', label: 'Hasbrouck Heights, NJ' },
  { value: 'Haskell_NJ', label: 'Haskell, NJ' },
  { value: 'Haskell_OK', label: 'Haskell, OK' },
  { value: 'Haskell_TX', label: 'Haskell, TX' },
  { value: 'Haskins_OH', label: 'Haskins, OH' },
  { value: 'Haslet_TX', label: 'Haslet, TX' },
  { value: 'Haslett_MI', label: 'Haslett, MI' },
  { value: 'Hassell_NC', label: 'Hassell, NC' },
  { value: 'Hastings_NY', label: 'Hastings, NY' },
  { value: 'Hastings_PA', label: 'Hastings, PA' },
  { value: 'Hastings_FL', label: 'Hastings, FL' },
  { value: 'Hastings_MI', label: 'Hastings, MI' },
  { value: 'Hastings_IA', label: 'Hastings, IA' },
  { value: 'Hastings_MN', label: 'Hastings, MN' },
  { value: 'Hastings_NE', label: 'Hastings, NE' },
  { value: 'Hastings_OK', label: 'Hastings, OK' },
  { value: 'Hastings On Hudson_NY', label: 'Hastings On Hudson, NY' },
  { value: 'Hasty_AR', label: 'Hasty, AR' },
  { value: 'Hasty_CO', label: 'Hasty, CO' },
  { value: 'Haswell_CO', label: 'Haswell, CO' },
  { value: 'Hat Creek_CA', label: 'Hat Creek, CA' },
  { value: 'Hatboro_PA', label: 'Hatboro, PA' },
  { value: 'Hatch_UT', label: 'Hatch, UT' },
  { value: 'Hatch_NM', label: 'Hatch, NM' },
  { value: 'Hatchechubbee_AL', label: 'Hatchechubbee, AL' },
  { value: 'Hatfield_MA', label: 'Hatfield, MA' },
  { value: 'Hatfield_PA', label: 'Hatfield, PA' },
  { value: 'Hatfield_MO', label: 'Hatfield, MO' },
  { value: 'Hatfield_AR', label: 'Hatfield, AR' },
  { value: 'Hathaway_MT', label: 'Hathaway, MT' },
  { value: 'Hathaway Pines_CA', label: 'Hathaway Pines, CA' },
  { value: 'Hathorne_MA', label: 'Hathorne, MA' },
  { value: 'Hatillo_PR', label: 'Hatillo, PR' },
  { value: 'Hatley_WI', label: 'Hatley, WI' },
  { value: 'Hatteras_NC', label: 'Hatteras, NC' },
  { value: 'Hattiesburg_MS', label: 'Hattiesburg, MS' },
  { value: 'Hattieville_AR', label: 'Hattieville, AR' },
  { value: 'Hatton_ND', label: 'Hatton, ND' },
  { value: 'Haubstadt_IN', label: 'Haubstadt, IN' },
  { value: 'Haugan_MT', label: 'Haugan, MT' },
  { value: 'Haugen_WI', label: 'Haugen, WI' },
  { value: 'Haughton_LA', label: 'Haughton, LA' },
  { value: 'Hauppauge_NY', label: 'Hauppauge, NY' },
  { value: 'Hauula_HI', label: 'Hauula, HI' },
  { value: 'Havana_FL', label: 'Havana, FL' },
  { value: 'Havana_ND', label: 'Havana, ND' },
  { value: 'Havana_IL', label: 'Havana, IL' },
  { value: 'Havana_KS', label: 'Havana, KS' },
  { value: 'Havana_AR', label: 'Havana, AR' },
  { value: 'Havelock_NC', label: 'Havelock, NC' },
  { value: 'Havelock_IA', label: 'Havelock, IA' },
  { value: 'Haven_KS', label: 'Haven, KS' },
  { value: 'Havensville_KS', label: 'Havensville, KS' },
  { value: 'Haverford_PA', label: 'Haverford, PA' },
  { value: 'Haverhill_MA', label: 'Haverhill, MA' },
  { value: 'Haverhill_NH', label: 'Haverhill, NH' },
  { value: 'Haverhill_OH', label: 'Haverhill, OH' },
  { value: 'Haverhill_IA', label: 'Haverhill, IA' },
  { value: 'Haverstraw_NY', label: 'Haverstraw, NY' },
  { value: 'Havertown_PA', label: 'Havertown, PA' },
  { value: 'Haviland_OH', label: 'Haviland, OH' },
  { value: 'Haviland_KS', label: 'Haviland, KS' },
  { value: 'Havre_MT', label: 'Havre, MT' },
  { value: 'Havre De Grace_MD', label: 'Havre De Grace, MD' },
  { value: 'Haw River_NC', label: 'Haw River, NC' },
  { value: 'Hawaiian Gardens_CA', label: 'Hawaiian Gardens, CA' },
  { value: 'Hawarden_IA', label: 'Hawarden, IA' },
  { value: 'Hawesville_KY', label: 'Hawesville, KY' },
  { value: 'Hawi_HI', label: 'Hawi, HI' },
  { value: 'Hawk Point_MO', label: 'Hawk Point, MO' },
  { value: 'Hawk Run_PA', label: 'Hawk Run, PA' },
  { value: 'Hawk Springs_WY', label: 'Hawk Springs, WY' },
  { value: 'Hawkeye_IA', label: 'Hawkeye, IA' },
  { value: 'Hawkins_WI', label: 'Hawkins, WI' },
  { value: 'Hawkins_TX', label: 'Hawkins, TX' },
  { value: 'Hawkinsville_GA', label: 'Hawkinsville, GA' },
  { value: 'Hawks_MI', label: 'Hawks, MI' },
  { value: 'Hawley_PA', label: 'Hawley, PA' },
  { value: 'Hawley_MN', label: 'Hawley, MN' },
  { value: 'Hawley_TX', label: 'Hawley, TX' },
  { value: 'Haworth_NJ', label: 'Haworth, NJ' },
  { value: 'Haworth_OK', label: 'Haworth, OK' },
  { value: 'Hawthorn_PA', label: 'Hawthorn, PA' },
  { value: 'Hawthorne_NJ', label: 'Hawthorne, NJ' },
  { value: 'Hawthorne_NY', label: 'Hawthorne, NY' },
  { value: 'Hawthorne_FL', label: 'Hawthorne, FL' },
  { value: 'Hawthorne_WI', label: 'Hawthorne, WI' },
  { value: 'Hawthorne_NV', label: 'Hawthorne, NV' },
  { value: 'Hawthorne_CA', label: 'Hawthorne, CA' },
  { value: 'Haxtun_CO', label: 'Haxtun, CO' },
  { value: 'Hay_WA', label: 'Hay, WA' },
  { value: 'Hay Springs_NE', label: 'Hay Springs, NE' },
  { value: 'Hayden_AL', label: 'Hayden, AL' },
  { value: 'Hayden_CO', label: 'Hayden, CO' },
  { value: 'Hayden_ID', label: 'Hayden, ID' },
  { value: 'Hayden_AZ', label: 'Hayden, AZ' },
  { value: 'Haydenville_MA', label: 'Haydenville, MA' },
  { value: 'Haydenville_OH', label: 'Haydenville, OH' },
  { value: 'Hayes_VA', label: 'Hayes, VA' },
  { value: 'Hayes_SD', label: 'Hayes, SD' },
  { value: 'Hayes_LA', label: 'Hayes, LA' },
  { value: 'Hayes Center_NE', label: 'Hayes Center, NE' },
  { value: 'Hayesville_NC', label: 'Hayesville, NC' },
  { value: 'Hayesville_OH', label: 'Hayesville, OH' },
  { value: 'Hayesville_IA', label: 'Hayesville, IA' },
  { value: 'Hayfield_MN', label: 'Hayfield, MN' },
  { value: 'Hayfork_CA', label: 'Hayfork, CA' },
  { value: 'Haymarket_VA', label: 'Haymarket, VA' },
  { value: 'Haynes_AR', label: 'Haynes, AR' },
  { value: 'Haynesville_VA', label: 'Haynesville, VA' },
  { value: 'Haynesville_LA', label: 'Haynesville, LA' },
  { value: 'Hayneville_AL', label: 'Hayneville, AL' },
  { value: 'Hays_NC', label: 'Hays, NC' },
  { value: 'Hays_MT', label: 'Hays, MT' },
  { value: 'Hays_KS', label: 'Hays, KS' },
  { value: 'Haysi_VA', label: 'Haysi, VA' },
  { value: 'Haysville_KS', label: 'Haysville, KS' },
  { value: 'Hayti_SD', label: 'Hayti, SD' },
  { value: 'Hayti_MO', label: 'Hayti, MO' },
  { value: 'Hayward_WI', label: 'Hayward, WI' },
  { value: 'Hayward_MN', label: 'Hayward, MN' },
  { value: 'Hayward_CA', label: 'Hayward, CA' },
  { value: 'Haywood_VA', label: 'Haywood, VA' },
  { value: 'Haywood_WV', label: 'Haywood, WV' },
  { value: 'Hazard_KY', label: 'Hazard, KY' },
  { value: 'Hazard_NE', label: 'Hazard, NE' },
  { value: 'Hazel_KY', label: 'Hazel, KY' },
  { value: 'Hazel_SD', label: 'Hazel, SD' },
  { value: 'Hazel Crest_IL', label: 'Hazel Crest, IL' },
  { value: 'Hazel Green_AL', label: 'Hazel Green, AL' },
  { value: 'Hazel Green_KY', label: 'Hazel Green, KY' },
  { value: 'Hazel Green_WI', label: 'Hazel Green, WI' },
  { value: 'Hazel Hurst_PA', label: 'Hazel Hurst, PA' },
  { value: 'Hazel Park_MI', label: 'Hazel Park, MI' },
  { value: 'Hazelhurst_WI', label: 'Hazelhurst, WI' },
  { value: 'Hazelton_ND', label: 'Hazelton, ND' },
  { value: 'Hazelton_KS', label: 'Hazelton, KS' },
  { value: 'Hazelton_ID', label: 'Hazelton, ID' },
  { value: 'Hazelwood_MO', label: 'Hazelwood, MO' },
  { value: 'Hazen_ND', label: 'Hazen, ND' },
  { value: 'Hazen_AR', label: 'Hazen, AR' },
  { value: 'Hazlehurst_GA', label: 'Hazlehurst, GA' },
  { value: 'Hazlehurst_MS', label: 'Hazlehurst, MS' },
  { value: 'Hazlet_NJ', label: 'Hazlet, NJ' },
  { value: 'Hazleton_PA', label: 'Hazleton, PA' },
  { value: 'Hazleton_IN', label: 'Hazleton, IN' },
  { value: 'Hazleton_IA', label: 'Hazleton, IA' },
  { value: 'Head Waters_VA', label: 'Head Waters, VA' },
  { value: 'Headland_AL', label: 'Headland, AL' },
  { value: 'Headrick_OK', label: 'Headrick, OK' },
  { value: 'Healdsburg_CA', label: 'Healdsburg, CA' },
  { value: 'Healdton_OK', label: 'Healdton, OK' },
  { value: 'Healy_KS', label: 'Healy, KS' },
  { value: 'Healy_AK', label: 'Healy, AK' },
  { value: 'Hearne_TX', label: 'Hearne, TX' },
  { value: 'Heart Butte_MT', label: 'Heart Butte, MT' },
  { value: 'Heartwell_NE', label: 'Heartwell, NE' },
  { value: 'Heaters_WV', label: 'Heaters, WV' },
  { value: 'Heath_MA', label: 'Heath, MA' },
  { value: 'Heath_OH', label: 'Heath, OH' },
  { value: 'Heath Springs_SC', label: 'Heath Springs, SC' },
  { value: 'Heathsville_VA', label: 'Heathsville, VA' },
  { value: 'Heavener_OK', label: 'Heavener, OK' },
  { value: 'Hebbronville_TX', label: 'Hebbronville, TX' },
  { value: 'Heber_AZ', label: 'Heber, AZ' },
  { value: 'Heber_CA', label: 'Heber, CA' },
  { value: 'Heber City_UT', label: 'Heber City, UT' },
  { value: 'Heber Springs_AR', label: 'Heber Springs, AR' },
  { value: 'Hebo_OR', label: 'Hebo, OR' },
  { value: 'Hebron_NH', label: 'Hebron, NH' },
  { value: 'Hebron_ME', label: 'Hebron, ME' },
  { value: 'Hebron_CT', label: 'Hebron, CT' },
  { value: 'Hebron_MD', label: 'Hebron, MD' },
  { value: 'Hebron_KY', label: 'Hebron, KY' },
  { value: 'Hebron_OH', label: 'Hebron, OH' },
  { value: 'Hebron_IN', label: 'Hebron, IN' },
  { value: 'Hebron_ND', label: 'Hebron, ND' },
  { value: 'Hebron_IL', label: 'Hebron, IL' },
  { value: 'Hebron_NE', label: 'Hebron, NE' },
  { value: 'Hecker_IL', label: 'Hecker, IL' },
  { value: 'Hecla_SD', label: 'Hecla, SD' },
  { value: 'Hector_NY', label: 'Hector, NY' },
  { value: 'Hector_MN', label: 'Hector, MN' },
  { value: 'Hector_AR', label: 'Hector, AR' },
  { value: 'Hedgesville_WV', label: 'Hedgesville, WV' },
  { value: 'Hedley_TX', label: 'Hedley, TX' },
  { value: 'Hedrick_IA', label: 'Hedrick, IA' },
  { value: 'Heflin_AL', label: 'Heflin, AL' },
  { value: 'Heflin_LA', label: 'Heflin, LA' },
  { value: 'Hegins_PA', label: 'Hegins, PA' },
  { value: 'Heidelberg_MS', label: 'Heidelberg, MS' },
  { value: 'Heidrick_KY', label: 'Heidrick, KY' },
  { value: 'Heilwood_PA', label: 'Heilwood, PA' },
  { value: 'Heiskell_TN', label: 'Heiskell, TN' },
  { value: 'Heislerville_NJ', label: 'Heislerville, NJ' },
  { value: 'Helen_WV', label: 'Helen, WV' },
  { value: 'Helen_GA', label: 'Helen, GA' },
  { value: 'Helena_NY', label: 'Helena, NY' },
  { value: 'Helena_AL', label: 'Helena, AL' },
  { value: 'Helena_OH', label: 'Helena, OH' },
  { value: 'Helena_MT', label: 'Helena, MT' },
  { value: 'Helena_MO', label: 'Helena, MO' },
  { value: 'Helena_AR', label: 'Helena, AR' },
  { value: 'Helena_OK', label: 'Helena, OK' },
  { value: 'Helendale_CA', label: 'Helendale, CA' },
  { value: 'Helenville_WI', label: 'Helenville, WI' },
  { value: 'Helenwood_TN', label: 'Helenwood, TN' },
  { value: 'Helix_OR', label: 'Helix, OR' },
  { value: 'Hellertown_PA', label: 'Hellertown, PA' },
  { value: 'Hellier_KY', label: 'Hellier, KY' },
  { value: 'Helm_CA', label: 'Helm, CA' },
  { value: 'Helmetta_NJ', label: 'Helmetta, NJ' },
  { value: 'Helmsburg_IN', label: 'Helmsburg, IN' },
  { value: 'Helmville_MT', label: 'Helmville, MT' },
  { value: 'Helotes_TX', label: 'Helotes, TX' },
  { value: 'Helper_UT', label: 'Helper, UT' },
  { value: 'Helton_KY', label: 'Helton, KY' },
  { value: 'Heltonville_IN', label: 'Heltonville, IN' },
  { value: 'Helvetia_WV', label: 'Helvetia, WV' },
  { value: 'Hematite_MO', label: 'Hematite, MO' },
  { value: 'Hemet_CA', label: 'Hemet, CA' },
  { value: 'Hemingford_NE', label: 'Hemingford, NE' },
  { value: 'Hemingway_SC', label: 'Hemingway, SC' },
  { value: 'Hemlock_NY', label: 'Hemlock, NY' },
  { value: 'Hemlock_IN', label: 'Hemlock, IN' },
  { value: 'Hemlock_MI', label: 'Hemlock, MI' },
  { value: 'Hemphill_TX', label: 'Hemphill, TX' },
  { value: 'Hempstead_NY', label: 'Hempstead, NY' },
  { value: 'Hempstead_TX', label: 'Hempstead, TX' },
  { value: 'Henagar_AL', label: 'Henagar, AL' },
  { value: 'Henderson_NY', label: 'Henderson, NY' },
  { value: 'Henderson_MD', label: 'Henderson, MD' },
  { value: 'Henderson_WV', label: 'Henderson, WV' },
  { value: 'Henderson_NC', label: 'Henderson, NC' },
  { value: 'Henderson_TN', label: 'Henderson, TN' },
  { value: 'Henderson_KY', label: 'Henderson, KY' },
  { value: 'Henderson_MI', label: 'Henderson, MI' },
  { value: 'Henderson_IA', label: 'Henderson, IA' },
  { value: 'Henderson_MN', label: 'Henderson, MN' },
  { value: 'Henderson_IL', label: 'Henderson, IL' },
  { value: 'Henderson_NE', label: 'Henderson, NE' },
  { value: 'Henderson_AR', label: 'Henderson, AR' },
  { value: 'Henderson_TX', label: 'Henderson, TX' },
  { value: 'Henderson_CO', label: 'Henderson, CO' },
  { value: 'Henderson_NV', label: 'Henderson, NV' },
  { value: 'Henderson Harbor_NY', label: 'Henderson Harbor, NY' },
  { value: 'Hendersonville_NC', label: 'Hendersonville, NC' },
  { value: 'Hendersonville_TN', label: 'Hendersonville, TN' },
  { value: 'Hendley_NE', label: 'Hendley, NE' },
  { value: 'Hendricks_WV', label: 'Hendricks, WV' },
  { value: 'Hendricks_MN', label: 'Hendricks, MN' },
  { value: 'Hendrix_OK', label: 'Hendrix, OK' },
  { value: 'Hendrum_MN', label: 'Hendrum, MN' },
  { value: 'Henefer_UT', label: 'Henefer, UT' },
  { value: 'Henlawson_WV', label: 'Henlawson, WV' },
  { value: 'Henley_MO', label: 'Henley, MO' },
  { value: 'Hennepin_IL', label: 'Hennepin, IL' },
  { value: 'Hennepin_OK', label: 'Hennepin, OK' },
  { value: 'Hennessey_OK', label: 'Hennessey, OK' },
  { value: 'Henniker_NH', label: 'Henniker, NH' },
  { value: 'Henning_TN', label: 'Henning, TN' },
  { value: 'Henning_MN', label: 'Henning, MN' },
  { value: 'Henning_IL', label: 'Henning, IL' },
  { value: 'Henrico_VA', label: 'Henrico, VA' },
  { value: 'Henrico_NC', label: 'Henrico, NC' },
  { value: 'Henrietta_NY', label: 'Henrietta, NY' },
  { value: 'Henrietta_NC', label: 'Henrietta, NC' },
  { value: 'Henrietta_MO', label: 'Henrietta, MO' },
  { value: 'Henrietta_TX', label: 'Henrietta, TX' },
  { value: 'Henriette_MN', label: 'Henriette, MN' },
  { value: 'Henrieville_UT', label: 'Henrieville, UT' },
  { value: 'Henry_VA', label: 'Henry, VA' },
  { value: 'Henry_TN', label: 'Henry, TN' },
  { value: 'Henry_SD', label: 'Henry, SD' },
  { value: 'Henry_IL', label: 'Henry, IL' },
  { value: 'Henryetta_OK', label: 'Henryetta, OK' },
  { value: 'Henryville_PA', label: 'Henryville, PA' },
  { value: 'Henryville_IN', label: 'Henryville, IN' },
  { value: 'Hensel_ND', label: 'Hensel, ND' },
  { value: 'Hensley_WV', label: 'Hensley, WV' },
  { value: 'Hensley_AR', label: 'Hensley, AR' },
  { value: 'Hensonville_NY', label: 'Hensonville, NY' },
  { value: 'Hephzibah_GA', label: 'Hephzibah, GA' },
  { value: 'Hepler_KS', label: 'Hepler, KS' },
  { value: 'Heppner_OR', label: 'Heppner, OR' },
  { value: 'Hepzibah_WV', label: 'Hepzibah, WV' },
  { value: 'Herald_CA', label: 'Herald, CA' },
  { value: 'Herbster_WI', label: 'Herbster, WI' },
  { value: 'Herculaneum_MO', label: 'Herculaneum, MO' },
  { value: 'Hercules_CA', label: 'Hercules, CA' },
  { value: 'Hereford_PA', label: 'Hereford, PA' },
  { value: 'Hereford_TX', label: 'Hereford, TX' },
  { value: 'Hereford_CO', label: 'Hereford, CO' },
  { value: 'Hereford_AZ', label: 'Hereford, AZ' },
  { value: 'Hereford_OR', label: 'Hereford, OR' },
  { value: 'Herington_KS', label: 'Herington, KS' },
  { value: 'Herkimer_NY', label: 'Herkimer, NY' },
  { value: 'Herlong_CA', label: 'Herlong, CA' },
  { value: 'Herman_MN', label: 'Herman, MN' },
  { value: 'Herman_NE', label: 'Herman, NE' },
  { value: 'Hermann_MO', label: 'Hermann, MO' },
  { value: 'Hermansville_MI', label: 'Hermansville, MI' },
  { value: 'Hermanville_MS', label: 'Hermanville, MS' },
  { value: 'Herminie_PA', label: 'Herminie, PA' },
  { value: 'Hermiston_OR', label: 'Hermiston, OR' },
  { value: 'Hermitage_PA', label: 'Hermitage, PA' },
  { value: 'Hermitage_TN', label: 'Hermitage, TN' },
  { value: 'Hermitage_MO', label: 'Hermitage, MO' },
  { value: 'Hermitage_AR', label: 'Hermitage, AR' },
  { value: 'Hermleigh_TX', label: 'Hermleigh, TX' },
  { value: 'Hermon_NY', label: 'Hermon, NY' },
  { value: 'Hermosa_SD', label: 'Hermosa, SD' },
  { value: 'Hermosa Beach_CA', label: 'Hermosa Beach, CA' },
  { value: 'Hernandez_NM', label: 'Hernandez, NM' },
  { value: 'Hernando_FL', label: 'Hernando, FL' },
  { value: 'Hernando_MS', label: 'Hernando, MS' },
  { value: 'Herndon_PA', label: 'Herndon, PA' },
  { value: 'Herndon_VA', label: 'Herndon, VA' },
  { value: 'Herndon_WV', label: 'Herndon, WV' },
  { value: 'Herndon_KY', label: 'Herndon, KY' },
  { value: 'Herndon_KS', label: 'Herndon, KS' },
  { value: 'Hernshaw_WV', label: 'Hernshaw, WV' },
  { value: 'Herod_IL', label: 'Herod, IL' },
  { value: 'Heron_MT', label: 'Heron, MT' },
  { value: 'Heron Lake_MN', label: 'Heron Lake, MN' },
  { value: 'Herreid_SD', label: 'Herreid, SD' },
  { value: 'Herrick_SD', label: 'Herrick, SD' },
  { value: 'Herrick_IL', label: 'Herrick, IL' },
  { value: 'Herrick Center_PA', label: 'Herrick Center, PA' },
  { value: 'Herriman_UT', label: 'Herriman, UT' },
  { value: 'Herrin_IL', label: 'Herrin, IL' },
  { value: 'Herron_MI', label: 'Herron, MI' },
  { value: 'Herscher_IL', label: 'Herscher, IL' },
  { value: 'Hersey_MI', label: 'Hersey, MI' },
  { value: 'Hershey_PA', label: 'Hershey, PA' },
  { value: 'Hershey_NE', label: 'Hershey, NE' },
  { value: 'Hertel_WI', label: 'Hertel, WI' },
  { value: 'Hertford_NC', label: 'Hertford, NC' },
  { value: 'Hesperia_MI', label: 'Hesperia, MI' },
  { value: 'Hesperia_CA', label: 'Hesperia, CA' },
  { value: 'Hesperus_CO', label: 'Hesperus, CO' },
  { value: 'Hessel_MI', label: 'Hessel, MI' },
  { value: 'Hessmer_LA', label: 'Hessmer, LA' },
  { value: 'Hesston_PA', label: 'Hesston, PA' },
  { value: 'Hesston_KS', label: 'Hesston, KS' },
  { value: 'Hestand_KY', label: 'Hestand, KY' },
  { value: 'Hester_LA', label: 'Hester, LA' },
  { value: 'Heth_AR', label: 'Heth, AR' },
  { value: 'Hettick_IL', label: 'Hettick, IL' },
  { value: 'Hettinger_ND', label: 'Hettinger, ND' },
  { value: 'Heuvelton_NY', label: 'Heuvelton, NY' },
  { value: 'Hewett_WV', label: 'Hewett, WV' },
  { value: 'Hewitt_NJ', label: 'Hewitt, NJ' },
  { value: 'Hewitt_WI', label: 'Hewitt, WI' },
  { value: 'Hewitt_MN', label: 'Hewitt, MN' },
  { value: 'Hewitt_TX', label: 'Hewitt, TX' },
  { value: 'Hewlett_NY', label: 'Hewlett, NY' },
  { value: 'Hext_TX', label: 'Hext, TX' },
  { value: 'Heyburn_ID', label: 'Heyburn, ID' },
  { value: 'Heyworth_IL', label: 'Heyworth, IL' },
  { value: 'Hi Hat_KY', label: 'Hi Hat, KY' },
  { value: 'Hialeah_FL', label: 'Hialeah, FL' },
  { value: 'Hiawassee_GA', label: 'Hiawassee, GA' },
  { value: 'Hiawatha_WV', label: 'Hiawatha, WV' },
  { value: 'Hiawatha_IA', label: 'Hiawatha, IA' },
  { value: 'Hiawatha_KS', label: 'Hiawatha, KS' },
  { value: 'Hibbing_MN', label: 'Hibbing, MN' },
  { value: 'Hibbs_PA', label: 'Hibbs, PA' },
  { value: 'Hibernia_NJ', label: 'Hibernia, NJ' },
  { value: 'Hickman_TN', label: 'Hickman, TN' },
  { value: 'Hickman_KY', label: 'Hickman, KY' },
  { value: 'Hickman_NE', label: 'Hickman, NE' },
  { value: 'Hickman_CA', label: 'Hickman, CA' },
  { value: 'Hickory_PA', label: 'Hickory, PA' },
  { value: 'Hickory_NC', label: 'Hickory, NC' },
  { value: 'Hickory_MS', label: 'Hickory, MS' },
  { value: 'Hickory_KY', label: 'Hickory, KY' },
  { value: 'Hickory Corners_MI', label: 'Hickory Corners, MI' },
  { value: 'Hickory Flat_MS', label: 'Hickory Flat, MS' },
  { value: 'Hickory Grove_SC', label: 'Hickory Grove, SC' },
  { value: 'Hickory Hills_IL', label: 'Hickory Hills, IL' },
  { value: 'Hickory Plains_AR', label: 'Hickory Plains, AR' },
  { value: 'Hickory Ridge_AR', label: 'Hickory Ridge, AR' },
  { value: 'Hickory Valley_TN', label: 'Hickory Valley, TN' },
  { value: 'Hicksville_NY', label: 'Hicksville, NY' },
  { value: 'Hicksville_OH', label: 'Hicksville, OH' },
  { value: 'Hico_WV', label: 'Hico, WV' },
  { value: 'Hico_TX', label: 'Hico, TX' },
  { value: 'Hidalgo_IL', label: 'Hidalgo, IL' },
  { value: 'Hidalgo_TX', label: 'Hidalgo, TX' },
  { value: 'Hidden Valley_PA', label: 'Hidden Valley, PA' },
  { value: 'Hidden Valley Lake_CA', label: 'Hidden Valley Lake, CA' },
  { value: 'Hiddenite_NC', label: 'Hiddenite, NC' },
  { value: 'Higbee_MO', label: 'Higbee, MO' },
  { value: 'Higden_AR', label: 'Higden, AR' },
  { value: 'Higdon_AL', label: 'Higdon, AL' },
  { value: 'Higganum_CT', label: 'Higganum, CT' },
  { value: 'Higgins_TX', label: 'Higgins, TX' },
  { value: 'Higgins Lake_MI', label: 'Higgins Lake, MI' },
  { value: 'Higginson_AR', label: 'Higginson, AR' },
  { value: 'Higginsport_OH', label: 'Higginsport, OH' },
  { value: 'Higginsville_MO', label: 'Higginsville, MO' },
  { value: 'High Bridge_NJ', label: 'High Bridge, NJ' },
  { value: 'High Bridge_WI', label: 'High Bridge, WI' },
  { value: 'High Falls_NY', label: 'High Falls, NY' },
  { value: 'High Hill_MO', label: 'High Hill, MO' },
  { value: 'High Island_TX', label: 'High Island, TX' },
  { value: 'High Point_NC', label: 'High Point, NC' },
  { value: 'High Ridge_MO', label: 'High Ridge, MO' },
  {
    value: 'High Rolls Mountain Park_NM',
    label: 'High Rolls Mountain Park, NM',
  },
  { value: 'High Shoals_NC', label: 'High Shoals, NC' },
  { value: 'High Springs_FL', label: 'High Springs, FL' },
  { value: 'High View_WV', label: 'High View, WV' },
  { value: 'Highfalls_NC', label: 'Highfalls, NC' },
  { value: 'Highgate Center_VT', label: 'Highgate Center, VT' },
  { value: 'Highland_NY', label: 'Highland, NY' },
  { value: 'Highland_MD', label: 'Highland, MD' },
  { value: 'Highland_OH', label: 'Highland, OH' },
  { value: 'Highland_IN', label: 'Highland, IN' },
  { value: 'Highland_MI', label: 'Highland, MI' },
  { value: 'Highland_WI', label: 'Highland, WI' },
  { value: 'Highland_IL', label: 'Highland, IL' },
  { value: 'Highland_KS', label: 'Highland, KS' },
  { value: 'Highland_CA', label: 'Highland, CA' },
  { value: 'Highland Falls_NY', label: 'Highland Falls, NY' },
  { value: 'Highland Home_AL', label: 'Highland Home, AL' },
  { value: 'Highland Lake_NY', label: 'Highland Lake, NY' },
  { value: 'Highland Lakes_NJ', label: 'Highland Lakes, NJ' },
  { value: 'Highland Mills_NY', label: 'Highland Mills, NY' },
  { value: 'Highland Park_NJ', label: 'Highland Park, NJ' },
  { value: 'Highland Park_MI', label: 'Highland Park, MI' },
  { value: 'Highland Park_IL', label: 'Highland Park, IL' },
  { value: 'Highlands_NJ', label: 'Highlands, NJ' },
  { value: 'Highlands_NC', label: 'Highlands, NC' },
  { value: 'Highlands_TX', label: 'Highlands, TX' },
  { value: 'Highlandville_MO', label: 'Highlandville, MO' },
  { value: 'Highmore_SD', label: 'Highmore, SD' },
  { value: 'Highmount_NY', label: 'Highmount, NY' },
  { value: 'Highspire_PA', label: 'Highspire, PA' },
  { value: 'Hightstown_NJ', label: 'Hightstown, NJ' },
  { value: 'Highwood_MT', label: 'Highwood, MT' },
  { value: 'Highwood_IL', label: 'Highwood, IL' },
  { value: 'Higley_AZ', label: 'Higley, AZ' },
  { value: 'Hiko_NV', label: 'Hiko, NV' },
  { value: 'Hiland_WY', label: 'Hiland, WY' },
  { value: 'Hilbert_WI', label: 'Hilbert, WI' },
  { value: 'Hildale_UT', label: 'Hildale, UT' },
  { value: 'Hildebran_NC', label: 'Hildebran, NC' },
  { value: 'Hildreth_NE', label: 'Hildreth, NE' },
  { value: 'Hilger_MT', label: 'Hilger, MT' },
  { value: 'Hilham_TN', label: 'Hilham, TN' },
  { value: 'Hill_NH', label: 'Hill, NH' },
  { value: 'Hill Afb_UT', label: 'Hill Afb, UT' },
  { value: 'Hill City_MN', label: 'Hill City, MN' },
  { value: 'Hill City_SD', label: 'Hill City, SD' },
  { value: 'Hill City_KS', label: 'Hill City, KS' },
  { value: 'Hill City_ID', label: 'Hill City, ID' },
  { value: 'Hillburn_NY', label: 'Hillburn, NY' },
  { value: 'Hiller_PA', label: 'Hiller, PA' },
  { value: 'Hilliard_FL', label: 'Hilliard, FL' },
  { value: 'Hilliard_OH', label: 'Hilliard, OH' },
  { value: 'Hilliards_PA', label: 'Hilliards, PA' },
  { value: 'Hillister_TX', label: 'Hillister, TX' },
  { value: 'Hillman_MI', label: 'Hillman, MI' },
  { value: 'Hillman_MN', label: 'Hillman, MN' },
  { value: 'Hillpoint_WI', label: 'Hillpoint, WI' },
  { value: 'Hillrose_CO', label: 'Hillrose, CO' },
  { value: 'Hills_IA', label: 'Hills, IA' },
  { value: 'Hills_MN', label: 'Hills, MN' },
  { value: 'Hillsboro_MD', label: 'Hillsboro, MD' },
  { value: 'Hillsboro_WV', label: 'Hillsboro, WV' },
  { value: 'Hillsboro_GA', label: 'Hillsboro, GA' },
  { value: 'Hillsboro_AL', label: 'Hillsboro, AL' },
  { value: 'Hillsboro_TN', label: 'Hillsboro, TN' },
  { value: 'Hillsboro_KY', label: 'Hillsboro, KY' },
  { value: 'Hillsboro_OH', label: 'Hillsboro, OH' },
  { value: 'Hillsboro_IN', label: 'Hillsboro, IN' },
  { value: 'Hillsboro_IA', label: 'Hillsboro, IA' },
  { value: 'Hillsboro_WI', label: 'Hillsboro, WI' },
  { value: 'Hillsboro_ND', label: 'Hillsboro, ND' },
  { value: 'Hillsboro_IL', label: 'Hillsboro, IL' },
  { value: 'Hillsboro_MO', label: 'Hillsboro, MO' },
  { value: 'Hillsboro_KS', label: 'Hillsboro, KS' },
  { value: 'Hillsboro_TX', label: 'Hillsboro, TX' },
  { value: 'Hillsboro_NM', label: 'Hillsboro, NM' },
  { value: 'Hillsboro_OR', label: 'Hillsboro, OR' },
  { value: 'Hillsborough_NH', label: 'Hillsborough, NH' },
  { value: 'Hillsborough_NJ', label: 'Hillsborough, NJ' },
  { value: 'Hillsborough_NC', label: 'Hillsborough, NC' },
  { value: 'Hillsdale_NJ', label: 'Hillsdale, NJ' },
  { value: 'Hillsdale_NY', label: 'Hillsdale, NY' },
  { value: 'Hillsdale_PA', label: 'Hillsdale, PA' },
  { value: 'Hillsdale_IN', label: 'Hillsdale, IN' },
  { value: 'Hillsdale_MI', label: 'Hillsdale, MI' },
  { value: 'Hillsdale_IL', label: 'Hillsdale, IL' },
  { value: 'Hillsdale_OK', label: 'Hillsdale, OK' },
  { value: 'Hillsdale_WY', label: 'Hillsdale, WY' },
  { value: 'Hillsgrove_PA', label: 'Hillsgrove, PA' },
  { value: 'Hillside_NJ', label: 'Hillside, NJ' },
  { value: 'Hillside_IL', label: 'Hillside, IL' },
  { value: 'Hillside_CO', label: 'Hillside, CO' },
  { value: 'Hillsville_PA', label: 'Hillsville, PA' },
  { value: 'Hillsville_VA', label: 'Hillsville, VA' },
  { value: 'Hilltop_WV', label: 'Hilltop, WV' },
  { value: 'Hilltown_PA', label: 'Hilltown, PA' },
  { value: 'Hillview_IL', label: 'Hillview, IL' },
  { value: 'Hilmar_CA', label: 'Hilmar, CA' },
  { value: 'Hilo_HI', label: 'Hilo, HI' },
  { value: 'Hilton_NY', label: 'Hilton, NY' },
  { value: 'Hilton Head Island_SC', label: 'Hilton Head Island, SC' },
  { value: 'Hiltons_VA', label: 'Hiltons, VA' },
  { value: 'Himrod_NY', label: 'Himrod, NY' },
  { value: 'Hinckley_ME', label: 'Hinckley, ME' },
  { value: 'Hinckley_NY', label: 'Hinckley, NY' },
  { value: 'Hinckley_OH', label: 'Hinckley, OH' },
  { value: 'Hinckley_MN', label: 'Hinckley, MN' },
  { value: 'Hinckley_IL', label: 'Hinckley, IL' },
  { value: 'Hinckley_UT', label: 'Hinckley, UT' },
  { value: 'Hindman_KY', label: 'Hindman, KY' },
  { value: 'Hindsboro_IL', label: 'Hindsboro, IL' },
  { value: 'Hindsville_AR', label: 'Hindsville, AR' },
  { value: 'Hines_MN', label: 'Hines, MN' },
  { value: 'Hines_IL', label: 'Hines, IL' },
  { value: 'Hines_OR', label: 'Hines, OR' },
  { value: 'Hinesburg_VT', label: 'Hinesburg, VT' },
  { value: 'Hineston_LA', label: 'Hineston, LA' },
  { value: 'Hinesville_GA', label: 'Hinesville, GA' },
  { value: 'Hingham_MA', label: 'Hingham, MA' },
  { value: 'Hingham_WI', label: 'Hingham, WI' },
  { value: 'Hingham_MT', label: 'Hingham, MT' },
  { value: 'Hinkle_KY', label: 'Hinkle, KY' },
  { value: 'Hinkley_CA', label: 'Hinkley, CA' },
  { value: 'Hinsdale_MA', label: 'Hinsdale, MA' },
  { value: 'Hinsdale_NH', label: 'Hinsdale, NH' },
  { value: 'Hinsdale_NY', label: 'Hinsdale, NY' },
  { value: 'Hinsdale_MT', label: 'Hinsdale, MT' },
  { value: 'Hinsdale_IL', label: 'Hinsdale, IL' },
  { value: 'Hinton_VA', label: 'Hinton, VA' },
  { value: 'Hinton_WV', label: 'Hinton, WV' },
  { value: 'Hinton_IA', label: 'Hinton, IA' },
  { value: 'Hinton_OK', label: 'Hinton, OK' },
  { value: 'Hiram_ME', label: 'Hiram, ME' },
  { value: 'Hiram_GA', label: 'Hiram, GA' },
  { value: 'Hiram_OH', label: 'Hiram, OH' },
  { value: 'Hitchcock_SD', label: 'Hitchcock, SD' },
  { value: 'Hitchcock_OK', label: 'Hitchcock, OK' },
  { value: 'Hitchcock_TX', label: 'Hitchcock, TX' },
  { value: 'Hitchins_KY', label: 'Hitchins, KY' },
  { value: 'Hitchita_OK', label: 'Hitchita, OK' },
  { value: 'Hitterdal_MN', label: 'Hitterdal, MN' },
  { value: 'Hiwasse_AR', label: 'Hiwasse, AR' },
  { value: 'Hiwassee_VA', label: 'Hiwassee, VA' },
  { value: 'Hixson_TN', label: 'Hixson, TN' },
  { value: 'Hixton_WI', label: 'Hixton, WI' },
  { value: 'Ho Ho Kus_NJ', label: 'Ho Ho Kus, NJ' },
  { value: 'Hoagland_IN', label: 'Hoagland, IN' },
  { value: 'Hobart_NY', label: 'Hobart, NY' },
  { value: 'Hobart_IN', label: 'Hobart, IN' },
  { value: 'Hobart_OK', label: 'Hobart, OK' },
  { value: 'Hobart_WA', label: 'Hobart, WA' },
  { value: 'Hobbs_IN', label: 'Hobbs, IN' },
  { value: 'Hobbs_NM', label: 'Hobbs, NM' },
  { value: 'Hobbsville_NC', label: 'Hobbsville, NC' },
  { value: 'Hobe Sound_FL', label: 'Hobe Sound, FL' },
  { value: 'Hobgood_NC', label: 'Hobgood, NC' },
  { value: 'Hoboken_NJ', label: 'Hoboken, NJ' },
  { value: 'Hoboken_GA', label: 'Hoboken, GA' },
  { value: 'Hobson_MT', label: 'Hobson, MT' },
  { value: 'Hobson_TX', label: 'Hobson, TX' },
  { value: 'Hobucken_NC', label: 'Hobucken, NC' },
  { value: 'Hockessin_DE', label: 'Hockessin, DE' },
  { value: 'Hockingport_OH', label: 'Hockingport, OH' },
  { value: 'Hockley_TX', label: 'Hockley, TX' },
  { value: 'Hodge_LA', label: 'Hodge, LA' },
  { value: 'Hodgen_OK', label: 'Hodgen, OK' },
  { value: 'Hodgenville_KY', label: 'Hodgenville, KY' },
  { value: 'Hodges_SC', label: 'Hodges, SC' },
  { value: 'Hodges_AL', label: 'Hodges, AL' },
  { value: 'Hoehne_CO', label: 'Hoehne, CO' },
  { value: 'Hoffman_NC', label: 'Hoffman, NC' },
  { value: 'Hoffman_MN', label: 'Hoffman, MN' },
  { value: 'Hoffman_IL', label: 'Hoffman, IL' },
  { value: 'Hoffman Estates_IL', label: 'Hoffman Estates, IL' },
  { value: 'Hoffmeister_NY', label: 'Hoffmeister, NY' },
  { value: 'Hogansburg_NY', label: 'Hogansburg, NY' },
  { value: 'Hogansville_GA', label: 'Hogansville, GA' },
  { value: 'Hogeland_MT', label: 'Hogeland, MT' },
  { value: 'Hohenwald_TN', label: 'Hohenwald, TN' },
  { value: 'Hoisington_KS', label: 'Hoisington, KS' },
  { value: 'Hokah_MN', label: 'Hokah, MN' },
  { value: 'Holabird_SD', label: 'Holabird, SD' },
  { value: 'Holbrook_MA', label: 'Holbrook, MA' },
  { value: 'Holbrook_NY', label: 'Holbrook, NY' },
  { value: 'Holbrook_PA', label: 'Holbrook, PA' },
  { value: 'Holbrook_NE', label: 'Holbrook, NE' },
  { value: 'Holbrook_ID', label: 'Holbrook, ID' },
  { value: 'Holbrook_AZ', label: 'Holbrook, AZ' },
  { value: 'Holcomb_MS', label: 'Holcomb, MS' },
  { value: 'Holcomb_IL', label: 'Holcomb, IL' },
  { value: 'Holcomb_MO', label: 'Holcomb, MO' },
  { value: 'Holcomb_KS', label: 'Holcomb, KS' },
  { value: 'Holcombe_WI', label: 'Holcombe, WI' },
  { value: 'Holden_MA', label: 'Holden, MA' },
  { value: 'Holden_ME', label: 'Holden, ME' },
  { value: 'Holden_WV', label: 'Holden, WV' },
  { value: 'Holden_MO', label: 'Holden, MO' },
  { value: 'Holden_LA', label: 'Holden, LA' },
  { value: 'Holden_UT', label: 'Holden, UT' },
  { value: 'Holdenville_OK', label: 'Holdenville, OK' },
  { value: 'Holder_FL', label: 'Holder, FL' },
  { value: 'Holderness_NH', label: 'Holderness, NH' },
  { value: 'Holdingford_MN', label: 'Holdingford, MN' },
  { value: 'Holdrege_NE', label: 'Holdrege, NE' },
  { value: 'Holgate_OH', label: 'Holgate, OH' },
  { value: 'Holiday_FL', label: 'Holiday, FL' },
  { value: 'Holladay_TN', label: 'Holladay, TN' },
  { value: 'Holland_MA', label: 'Holland, MA' },
  { value: 'Holland_NY', label: 'Holland, NY' },
  { value: 'Holland_KY', label: 'Holland, KY' },
  { value: 'Holland_OH', label: 'Holland, OH' },
  { value: 'Holland_IN', label: 'Holland, IN' },
  { value: 'Holland_MI', label: 'Holland, MI' },
  { value: 'Holland_IA', label: 'Holland, IA' },
  { value: 'Holland_MN', label: 'Holland, MN' },
  { value: 'Holland_MO', label: 'Holland, MO' },
  { value: 'Holland_TX', label: 'Holland, TX' },
  { value: 'Holland Patent_NY', label: 'Holland Patent, NY' },
  { value: 'Hollandale_MS', label: 'Hollandale, MS' },
  { value: 'Hollandale_WI', label: 'Hollandale, WI' },
  { value: 'Hollandale_MN', label: 'Hollandale, MN' },
  { value: 'Hollansburg_OH', label: 'Hollansburg, OH' },
  { value: 'Hollenberg_KS', label: 'Hollenberg, KS' },
  { value: 'Holley_NY', label: 'Holley, NY' },
  { value: 'Holliday_MO', label: 'Holliday, MO' },
  { value: 'Holliday_TX', label: 'Holliday, TX' },
  { value: 'Hollidaysburg_PA', label: 'Hollidaysburg, PA' },
  { value: 'Hollins_AL', label: 'Hollins, AL' },
  { value: 'Hollis_NH', label: 'Hollis, NH' },
  { value: 'Hollis_NY', label: 'Hollis, NY' },
  { value: 'Hollis_OK', label: 'Hollis, OK' },
  { value: 'Hollis Center_ME', label: 'Hollis Center, ME' },
  { value: 'Hollister_NC', label: 'Hollister, NC' },
  { value: 'Hollister_FL', label: 'Hollister, FL' },
  { value: 'Hollister_MO', label: 'Hollister, MO' },
  { value: 'Hollister_OK', label: 'Hollister, OK' },
  { value: 'Hollister_CA', label: 'Hollister, CA' },
  { value: 'Holliston_MA', label: 'Holliston, MA' },
  { value: 'Holloman Air Force Base_NM', label: 'Holloman Air Force Base, NM' },
  { value: 'Hollow Rock_TN', label: 'Hollow Rock, TN' },
  { value: 'Holloway_OH', label: 'Holloway, OH' },
  { value: 'Holloway_MN', label: 'Holloway, MN' },
  { value: 'Hollowville_NY', label: 'Hollowville, NY' },
  { value: 'Hollsopple_PA', label: 'Hollsopple, PA' },
  { value: 'Holly_MI', label: 'Holly, MI' },
  { value: 'Holly_CO', label: 'Holly, CO' },
  { value: 'Holly Bluff_MS', label: 'Holly Bluff, MS' },
  { value: 'Holly Grove_AR', label: 'Holly Grove, AR' },
  { value: 'Holly Hill_SC', label: 'Holly Hill, SC' },
  { value: 'Holly Pond_AL', label: 'Holly Pond, AL' },
  { value: 'Holly Ridge_NC', label: 'Holly Ridge, NC' },
  { value: 'Holly Springs_NC', label: 'Holly Springs, NC' },
  { value: 'Holly Springs_MS', label: 'Holly Springs, MS' },
  { value: 'Hollytree_AL', label: 'Hollytree, AL' },
  { value: 'Hollywood_MD', label: 'Hollywood, MD' },
  { value: 'Hollywood_SC', label: 'Hollywood, SC' },
  { value: 'Hollywood_FL', label: 'Hollywood, FL' },
  { value: 'Hollywood_AL', label: 'Hollywood, AL' },
  { value: 'Holman_NM', label: 'Holman, NM' },
  { value: 'Holmdel_NJ', label: 'Holmdel, NJ' },
  { value: 'Holmen_WI', label: 'Holmen, WI' },
  { value: 'Holmes_NY', label: 'Holmes, NY' },
  { value: 'Holmes_PA', label: 'Holmes, PA' },
  { value: 'Holmes Mill_KY', label: 'Holmes Mill, KY' },
  { value: 'Holmesville_OH', label: 'Holmesville, OH' },
  { value: 'Holstein_IA', label: 'Holstein, IA' },
  { value: 'Holstein_NE', label: 'Holstein, NE' },
  { value: 'Holt_FL', label: 'Holt, FL' },
  { value: 'Holt_MI', label: 'Holt, MI' },
  { value: 'Holt_MO', label: 'Holt, MO' },
  { value: 'Holton_IN', label: 'Holton, IN' },
  { value: 'Holton_MI', label: 'Holton, MI' },
  { value: 'Holton_KS', label: 'Holton, KS' },
  { value: 'Holts Summit_MO', label: 'Holts Summit, MO' },
  { value: 'Holtsville_NY', label: 'Holtsville, NY' },
  { value: 'Holtville_CA', label: 'Holtville, CA' },
  { value: 'Holtwood_PA', label: 'Holtwood, PA' },
  { value: 'Holualoa_HI', label: 'Holualoa, HI' },
  { value: 'Holy Cross_IA', label: 'Holy Cross, IA' },
  { value: 'Holy Cross_AK', label: 'Holy Cross, AK' },
  { value: 'Holyoke_MA', label: 'Holyoke, MA' },
  { value: 'Holyoke_MN', label: 'Holyoke, MN' },
  { value: 'Holyoke_CO', label: 'Holyoke, CO' },
  { value: 'Holyrood_KS', label: 'Holyrood, KS' },
  { value: 'Home_PA', label: 'Home, PA' },
  { value: 'Home_KS', label: 'Home, KS' },
  { value: 'Homedale_ID', label: 'Homedale, ID' },
  { value: 'Homeland_FL', label: 'Homeland, FL' },
  { value: 'Homeland_CA', label: 'Homeland, CA' },
  { value: 'Homer_NY', label: 'Homer, NY' },
  { value: 'Homer_GA', label: 'Homer, GA' },
  { value: 'Homer_OH', label: 'Homer, OH' },
  { value: 'Homer_IN', label: 'Homer, IN' },
  { value: 'Homer_MI', label: 'Homer, MI' },
  { value: 'Homer_IL', label: 'Homer, IL' },
  { value: 'Homer_NE', label: 'Homer, NE' },
  { value: 'Homer_LA', label: 'Homer, LA' },
  { value: 'Homer_AK', label: 'Homer, AK' },
  { value: 'Homer City_PA', label: 'Homer City, PA' },
  { value: 'Homer Glen_IL', label: 'Homer Glen, IL' },
  { value: 'Homerville_GA', label: 'Homerville, GA' },
  { value: 'Homerville_OH', label: 'Homerville, OH' },
  { value: 'Homestead_PA', label: 'Homestead, PA' },
  { value: 'Homestead_FL', label: 'Homestead, FL' },
  { value: 'Homestead_IA', label: 'Homestead, IA' },
  { value: 'Homestead_MT', label: 'Homestead, MT' },
  { value: 'Hometown_WV', label: 'Hometown, WV' },
  { value: 'Hometown_IL', label: 'Hometown, IL' },
  { value: 'Homewood_IL', label: 'Homewood, IL' },
  { value: 'Homewood_CA', label: 'Homewood, CA' },
  { value: 'Homeworth_OH', label: 'Homeworth, OH' },
  { value: 'Hominy_OK', label: 'Hominy, OK' },
  { value: 'Homosassa_FL', label: 'Homosassa, FL' },
  { value: 'Honaker_VA', label: 'Honaker, VA' },
  { value: 'Honaunau_HI', label: 'Honaunau, HI' },
  { value: 'Hondo_TX', label: 'Hondo, TX' },
  { value: 'Hondo_NM', label: 'Hondo, NM' },
  { value: 'Honea Path_SC', label: 'Honea Path, SC' },
  { value: 'Honeoye_NY', label: 'Honeoye, NY' },
  { value: 'Honeoye Falls_NY', label: 'Honeoye Falls, NY' },
  { value: 'Honesdale_PA', label: 'Honesdale, PA' },
  { value: 'Honey Brook_PA', label: 'Honey Brook, PA' },
  { value: 'Honey Creek_IA', label: 'Honey Creek, IA' },
  { value: 'Honey Grove_PA', label: 'Honey Grove, PA' },
  { value: 'Honey Grove_TX', label: 'Honey Grove, TX' },
  { value: 'Honeydew_CA', label: 'Honeydew, CA' },
  { value: 'Honeyville_UT', label: 'Honeyville, UT' },
  { value: 'Honobia_OK', label: 'Honobia, OK' },
  { value: 'Honokaa_HI', label: 'Honokaa, HI' },
  { value: 'Honolulu_HI', label: 'Honolulu, HI' },
  { value: 'Honomu_HI', label: 'Honomu, HI' },
  { value: 'Honor_MI', label: 'Honor, MI' },
  { value: 'Honoraville_AL', label: 'Honoraville, AL' },
  { value: 'Hood_VA', label: 'Hood, VA' },
  { value: 'Hood_CA', label: 'Hood, CA' },
  { value: 'Hood River_OR', label: 'Hood River, OR' },
  { value: 'Hoodsport_WA', label: 'Hoodsport, WA' },
  { value: 'Hooker_OK', label: 'Hooker, OK' },
  { value: 'Hookerton_NC', label: 'Hookerton, NC' },
  { value: 'Hooks_TX', label: 'Hooks, TX' },
  { value: 'Hooksett_NH', label: 'Hooksett, NH' },
  { value: 'Hookstown_PA', label: 'Hookstown, PA' },
  { value: 'Hoolehua_HI', label: 'Hoolehua, HI' },
  { value: 'Hoonah_AK', label: 'Hoonah, AK' },
  { value: 'Hoopa_CA', label: 'Hoopa, CA' },
  { value: 'Hooper_NE', label: 'Hooper, NE' },
  { value: 'Hooper_CO', label: 'Hooper, CO' },
  { value: 'Hooper_UT', label: 'Hooper, UT' },
  { value: 'Hooper_WA', label: 'Hooper, WA' },
  { value: 'Hooper Bay_AK', label: 'Hooper Bay, AK' },
  { value: 'Hoopeston_IL', label: 'Hoopeston, IL' },
  { value: 'Hoople_ND', label: 'Hoople, ND' },
  { value: 'Hooppole_IL', label: 'Hooppole, IL' },
  { value: 'Hoosick Falls_NY', label: 'Hoosick Falls, NY' },
  { value: 'Hooven_OH', label: 'Hooven, OH' },
  { value: 'Hooversville_PA', label: 'Hooversville, PA' },
  { value: 'Hop Bottom_PA', label: 'Hop Bottom, PA' },
  { value: 'Hopatcong_NJ', label: 'Hopatcong, NJ' },
  { value: 'Hope_RI', label: 'Hope, RI' },
  { value: 'Hope_ME', label: 'Hope, ME' },
  { value: 'Hope_NJ', label: 'Hope, NJ' },
  { value: 'Hope_KY', label: 'Hope, KY' },
  { value: 'Hope_IN', label: 'Hope, IN' },
  { value: 'Hope_MI', label: 'Hope, MI' },
  { value: 'Hope_MN', label: 'Hope, MN' },
  { value: 'Hope_ND', label: 'Hope, ND' },
  { value: 'Hope_KS', label: 'Hope, KS' },
  { value: 'Hope_AR', label: 'Hope, AR' },
  { value: 'Hope_ID', label: 'Hope, ID' },
  { value: 'Hope_NM', label: 'Hope, NM' },
  { value: 'Hope_AK', label: 'Hope, AK' },
  { value: 'Hope Hull_AL', label: 'Hope Hull, AL' },
  { value: 'Hope Mills_NC', label: 'Hope Mills, NC' },
  { value: 'Hope Valley_RI', label: 'Hope Valley, RI' },
  { value: 'Hopedale_MA', label: 'Hopedale, MA' },
  { value: 'Hopedale_OH', label: 'Hopedale, OH' },
  { value: 'Hopedale_IL', label: 'Hopedale, IL' },
  { value: 'Hopeton_OK', label: 'Hopeton, OK' },
  { value: 'Hopewell_NJ', label: 'Hopewell, NJ' },
  { value: 'Hopewell_PA', label: 'Hopewell, PA' },
  { value: 'Hopewell_VA', label: 'Hopewell, VA' },
  { value: 'Hopewell_OH', label: 'Hopewell, OH' },
  { value: 'Hopewell Junction_NY', label: 'Hopewell Junction, NY' },
  { value: 'Hopkins_SC', label: 'Hopkins, SC' },
  { value: 'Hopkins_MI', label: 'Hopkins, MI' },
  { value: 'Hopkins_MN', label: 'Hopkins, MN' },
  { value: 'Hopkins_MO', label: 'Hopkins, MO' },
  { value: 'Hopkinsville_KY', label: 'Hopkinsville, KY' },
  { value: 'Hopkinton_MA', label: 'Hopkinton, MA' },
  { value: 'Hopkinton_RI', label: 'Hopkinton, RI' },
  { value: 'Hopkinton_IA', label: 'Hopkinton, IA' },
  { value: 'Hopland_CA', label: 'Hopland, CA' },
  { value: 'Hopwood_PA', label: 'Hopwood, PA' },
  { value: 'Hoquiam_WA', label: 'Hoquiam, WA' },
  { value: 'Horace_ND', label: 'Horace, ND' },
  { value: 'Horatio_SC', label: 'Horatio, SC' },
  { value: 'Horatio_AR', label: 'Horatio, AR' },
  { value: 'Hordville_NE', label: 'Hordville, NE' },
  { value: 'Horicon_WI', label: 'Horicon, WI' },
  { value: 'Hormigueros_PR', label: 'Hormigueros, PR' },
  { value: 'Horn Lake_MS', label: 'Horn Lake, MS' },
  { value: 'Hornbeak_TN', label: 'Hornbeak, TN' },
  { value: 'Hornbeck_LA', label: 'Hornbeck, LA' },
  { value: 'Hornbrook_CA', label: 'Hornbrook, CA' },
  { value: 'Hornell_NY', label: 'Hornell, NY' },
  { value: 'Horner_WV', label: 'Horner, WV' },
  { value: 'Hornersville_MO', label: 'Hornersville, MO' },
  { value: 'Hornick_IA', label: 'Hornick, IA' },
  { value: 'Hornitos_CA', label: 'Hornitos, CA' },
  { value: 'Hornsby_TN', label: 'Hornsby, TN' },
  { value: 'Horntown_VA', label: 'Horntown, VA' },
  { value: 'Horse Branch_KY', label: 'Horse Branch, KY' },
  { value: 'Horse Cave_KY', label: 'Horse Cave, KY' },
  { value: 'Horse Creek_WY', label: 'Horse Creek, WY' },
  { value: 'Horse Shoe_NC', label: 'Horse Shoe, NC' },
  { value: 'Horseheads_NY', label: 'Horseheads, NY' },
  { value: 'Horseshoe Bay_TX', label: 'Horseshoe Bay, TX' },
  { value: 'Horseshoe Beach_FL', label: 'Horseshoe Beach, FL' },
  { value: 'Horseshoe Bend_AR', label: 'Horseshoe Bend, AR' },
  { value: 'Horseshoe Bend_ID', label: 'Horseshoe Bend, ID' },
  { value: 'Horsham_PA', label: 'Horsham, PA' },
  { value: 'Hortense_GA', label: 'Hortense, GA' },
  { value: 'Horton_AL', label: 'Horton, AL' },
  { value: 'Horton_MI', label: 'Horton, MI' },
  { value: 'Horton_KS', label: 'Horton, KS' },
  { value: 'Hortonville_NY', label: 'Hortonville, NY' },
  { value: 'Hortonville_WI', label: 'Hortonville, WI' },
  { value: 'Hoschton_GA', label: 'Hoschton, GA' },
  { value: 'Hosford_FL', label: 'Hosford, FL' },
  { value: 'Hoskins_NE', label: 'Hoskins, NE' },
  { value: 'Hoskinston_KY', label: 'Hoskinston, KY' },
  { value: 'Hosmer_SD', label: 'Hosmer, SD' },
  { value: 'Hospers_IA', label: 'Hospers, IA' },
  { value: 'Hosston_LA', label: 'Hosston, LA' },
  { value: 'Hostetter_PA', label: 'Hostetter, PA' },
  { value: 'Hot Springs_VA', label: 'Hot Springs, VA' },
  { value: 'Hot Springs_NC', label: 'Hot Springs, NC' },
  { value: 'Hot Springs_SD', label: 'Hot Springs, SD' },
  { value: 'Hot Springs_MT', label: 'Hot Springs, MT' },
  {
    value: 'Hot Springs National Park_AR',
    label: 'Hot Springs National Park, AR',
  },
  { value: 'Hot Springs Village_AR', label: 'Hot Springs Village, AR' },
  { value: 'Hot Sulphur Springs_CO', label: 'Hot Sulphur Springs, CO' },
  { value: 'Hotchkiss_CO', label: 'Hotchkiss, CO' },
  { value: 'Hotevilla_AZ', label: 'Hotevilla, AZ' },
  { value: 'Houck_AZ', label: 'Houck, AZ' },
  { value: 'Houghton_NY', label: 'Houghton, NY' },
  { value: 'Houghton_MI', label: 'Houghton, MI' },
  { value: 'Houghton_IA', label: 'Houghton, IA' },
  { value: 'Houghton_SD', label: 'Houghton, SD' },
  { value: 'Houghton Lake_MI', label: 'Houghton Lake, MI' },
  { value: 'Houghton Lake Heights_MI', label: 'Houghton Lake Heights, MI' },
  { value: 'Houlka_MS', label: 'Houlka, MS' },
  { value: 'Houlton_ME', label: 'Houlton, ME' },
  { value: 'Houlton_WI', label: 'Houlton, WI' },
  { value: 'Houma_LA', label: 'Houma, LA' },
  { value: 'Housatonic_MA', label: 'Housatonic, MA' },
  { value: 'House_NM', label: 'House, NM' },
  { value: 'House Springs_MO', label: 'House Springs, MO' },
  { value: 'Houston_PA', label: 'Houston, PA' },
  { value: 'Houston_DE', label: 'Houston, DE' },
  { value: 'Houston_AL', label: 'Houston, AL' },
  { value: 'Houston_MS', label: 'Houston, MS' },
  { value: 'Houston_OH', label: 'Houston, OH' },
  { value: 'Houston_MN', label: 'Houston, MN' },
  { value: 'Houston_MO', label: 'Houston, MO' },
  { value: 'Houston_AR', label: 'Houston, AR' },
  { value: 'Houston_TX', label: 'Houston, TX' },
  { value: 'Houston_AK', label: 'Houston, AK' },
  { value: 'Houstonia_MO', label: 'Houstonia, MO' },
  { value: 'Houtzdale_PA', label: 'Houtzdale, PA' },
  { value: 'Hoven_SD', label: 'Hoven, SD' },
  { value: 'Hovland_MN', label: 'Hovland, MN' },
  { value: 'Howard_PA', label: 'Howard, PA' },
  { value: 'Howard_GA', label: 'Howard, GA' },
  { value: 'Howard_OH', label: 'Howard, OH' },
  { value: 'Howard_SD', label: 'Howard, SD' },
  { value: 'Howard_KS', label: 'Howard, KS' },
  { value: 'Howard_CO', label: 'Howard, CO' },
  { value: 'Howard Beach_NY', label: 'Howard Beach, NY' },
  { value: 'Howard City_MI', label: 'Howard City, MI' },
  { value: 'Howard Lake_MN', label: 'Howard Lake, MN' },
  { value: 'Howardsville_VA', label: 'Howardsville, VA' },
  { value: 'Howe_IN', label: 'Howe, IN' },
  { value: 'Howe_OK', label: 'Howe, OK' },
  { value: 'Howe_TX', label: 'Howe, TX' },
  { value: 'Howe_ID', label: 'Howe, ID' },
  { value: 'Howell_NJ', label: 'Howell, NJ' },
  { value: 'Howell_MI', label: 'Howell, MI' },
  { value: 'Howell_UT', label: 'Howell, UT' },
  { value: 'Howells_NY', label: 'Howells, NY' },
  { value: 'Howells_NE', label: 'Howells, NE' },
  { value: 'Howes_SD', label: 'Howes, SD' },
  { value: 'Howes Cave_NY', label: 'Howes Cave, NY' },
  { value: 'Howey In The Hills_FL', label: 'Howey In The Hills, FL' },
  { value: 'Howland_ME', label: 'Howland, ME' },
  { value: 'Hoxie_KS', label: 'Hoxie, KS' },
  { value: 'Hoxie_AR', label: 'Hoxie, AR' },
  { value: 'Hoyleton_IL', label: 'Hoyleton, IL' },
  { value: 'Hoyt_KS', label: 'Hoyt, KS' },
  { value: 'Hoyt Lakes_MN', label: 'Hoyt Lakes, MN' },
  { value: 'Hoytville_OH', label: 'Hoytville, OH' },
  { value: 'Huachuca City_AZ', label: 'Huachuca City, AZ' },
  { value: 'Hubbard_OH', label: 'Hubbard, OH' },
  { value: 'Hubbard_IA', label: 'Hubbard, IA' },
  { value: 'Hubbard_NE', label: 'Hubbard, NE' },
  { value: 'Hubbard_TX', label: 'Hubbard, TX' },
  { value: 'Hubbard_OR', label: 'Hubbard, OR' },
  { value: 'Hubbard Lake_MI', label: 'Hubbard Lake, MI' },
  { value: 'Hubbardston_MA', label: 'Hubbardston, MA' },
  { value: 'Hubbardston_MI', label: 'Hubbardston, MI' },
  { value: 'Hubbardsville_NY', label: 'Hubbardsville, NY' },
  { value: 'Hubbell_MI', label: 'Hubbell, MI' },
  { value: 'Hubbell_NE', label: 'Hubbell, NE' },
  { value: 'Hubert_NC', label: 'Hubert, NC' },
  { value: 'Hubertus_WI', label: 'Hubertus, WI' },
  { value: 'Huddleston_VA', label: 'Huddleston, VA' },
  { value: 'Huddy_KY', label: 'Huddy, KY' },
  { value: 'Hudgins_VA', label: 'Hudgins, VA' },
  { value: 'Hudson_MA', label: 'Hudson, MA' },
  { value: 'Hudson_NH', label: 'Hudson, NH' },
  { value: 'Hudson_ME', label: 'Hudson, ME' },
  { value: 'Hudson_NY', label: 'Hudson, NY' },
  { value: 'Hudson_NC', label: 'Hudson, NC' },
  { value: 'Hudson_FL', label: 'Hudson, FL' },
  { value: 'Hudson_KY', label: 'Hudson, KY' },
  { value: 'Hudson_OH', label: 'Hudson, OH' },
  { value: 'Hudson_IN', label: 'Hudson, IN' },
  { value: 'Hudson_MI', label: 'Hudson, MI' },
  { value: 'Hudson_IA', label: 'Hudson, IA' },
  { value: 'Hudson_WI', label: 'Hudson, WI' },
  { value: 'Hudson_SD', label: 'Hudson, SD' },
  { value: 'Hudson_IL', label: 'Hudson, IL' },
  { value: 'Hudson_KS', label: 'Hudson, KS' },
  { value: 'Hudson_CO', label: 'Hudson, CO' },
  { value: 'Hudson_WY', label: 'Hudson, WY' },
  { value: 'Hudson Falls_NY', label: 'Hudson Falls, NY' },
  { value: 'Hudsonville_MI', label: 'Hudsonville, MI' },
  { value: 'Hueysville_KY', label: 'Hueysville, KY' },
  { value: 'Huffman_TX', label: 'Huffman, TX' },
  { value: 'Huger_SC', label: 'Huger, SC' },
  { value: 'Huggins_MO', label: 'Huggins, MO' },
  { value: 'Hughes_AR', label: 'Hughes, AR' },
  { value: 'Hughes_AK', label: 'Hughes, AK' },
  { value: 'Hughes Springs_TX', label: 'Hughes Springs, TX' },
  { value: 'Hugheston_WV', label: 'Hugheston, WV' },
  { value: 'Hughesville_PA', label: 'Hughesville, PA' },
  { value: 'Hughesville_MD', label: 'Hughesville, MD' },
  { value: 'Hughesville_MO', label: 'Hughesville, MO' },
  { value: 'Hughson_CA', label: 'Hughson, CA' },
  { value: 'Hugo_MN', label: 'Hugo, MN' },
  { value: 'Hugo_OK', label: 'Hugo, OK' },
  { value: 'Hugo_CO', label: 'Hugo, CO' },
  { value: 'Hugoton_KS', label: 'Hugoton, KS' },
  { value: 'Huguenot_NY', label: 'Huguenot, NY' },
  { value: 'Hulbert_MI', label: 'Hulbert, MI' },
  { value: 'Hulbert_OK', label: 'Hulbert, OK' },
  { value: 'Hulen_KY', label: 'Hulen, KY' },
  { value: 'Hulett_WY', label: 'Hulett, WY' },
  { value: 'Huletts Landing_NY', label: 'Huletts Landing, NY' },
  { value: 'Hull_MA', label: 'Hull, MA' },
  { value: 'Hull_GA', label: 'Hull, GA' },
  { value: 'Hull_IA', label: 'Hull, IA' },
  { value: 'Hull_IL', label: 'Hull, IL' },
  { value: 'Hull_TX', label: 'Hull, TX' },
  { value: 'Humacao_PR', label: 'Humacao, PR' },
  { value: 'Humansville_MO', label: 'Humansville, MO' },
  { value: 'Humarock_MA', label: 'Humarock, MA' },
  { value: 'Humbird_WI', label: 'Humbird, WI' },
  { value: 'Humble_TX', label: 'Humble, TX' },
  { value: 'Humboldt_TN', label: 'Humboldt, TN' },
  { value: 'Humboldt_IA', label: 'Humboldt, IA' },
  { value: 'Humboldt_MN', label: 'Humboldt, MN' },
  { value: 'Humboldt_SD', label: 'Humboldt, SD' },
  { value: 'Humboldt_IL', label: 'Humboldt, IL' },
  { value: 'Humboldt_KS', label: 'Humboldt, KS' },
  { value: 'Humboldt_NE', label: 'Humboldt, NE' },
  { value: 'Humboldt_AZ', label: 'Humboldt, AZ' },
  { value: 'Hume_VA', label: 'Hume, VA' },
  { value: 'Hume_IL', label: 'Hume, IL' },
  { value: 'Hume_MO', label: 'Hume, MO' },
  { value: 'Hume_CA', label: 'Hume, CA' },
  { value: 'Humeston_IA', label: 'Humeston, IA' },
  { value: 'Hummelstown_PA', label: 'Hummelstown, PA' },
  { value: 'Humnoke_AR', label: 'Humnoke, AR' },
  { value: 'Humphrey_NE', label: 'Humphrey, NE' },
  { value: 'Humphrey_AR', label: 'Humphrey, AR' },
  { value: 'Humphreys_MO', label: 'Humphreys, MO' },
  { value: 'Humptulips_WA', label: 'Humptulips, WA' },
  { value: 'Hundred_WV', label: 'Hundred, WV' },
  { value: 'Hungerford_TX', label: 'Hungerford, TX' },
  { value: 'Hungry Horse_MT', label: 'Hungry Horse, MT' },
  { value: 'Hunker_PA', label: 'Hunker, PA' },
  { value: 'Hunlock Creek_PA', label: 'Hunlock Creek, PA' },
  { value: 'Hunnewell_MO', label: 'Hunnewell, MO' },
  { value: 'Hunt_NY', label: 'Hunt, NY' },
  { value: 'Hunt_TX', label: 'Hunt, TX' },
  { value: 'Hunt Valley_MD', label: 'Hunt Valley, MD' },
  { value: 'Hunter_NY', label: 'Hunter, NY' },
  { value: 'Hunter_ND', label: 'Hunter, ND' },
  { value: 'Hunter_KS', label: 'Hunter, KS' },
  { value: 'Hunter_AR', label: 'Hunter, AR' },
  { value: 'Hunter_OK', label: 'Hunter, OK' },
  { value: 'Hunters_WA', label: 'Hunters, WA' },
  { value: 'Huntersville_NC', label: 'Huntersville, NC' },
  { value: 'Huntertown_IN', label: 'Huntertown, IN' },
  { value: 'Huntingburg_IN', label: 'Huntingburg, IN' },
  { value: 'Huntingdon_PA', label: 'Huntingdon, PA' },
  { value: 'Huntingdon_TN', label: 'Huntingdon, TN' },
  { value: 'Huntingdon Valley_PA', label: 'Huntingdon Valley, PA' },
  { value: 'Huntington_MA', label: 'Huntington, MA' },
  { value: 'Huntington_VT', label: 'Huntington, VT' },
  { value: 'Huntington_NY', label: 'Huntington, NY' },
  { value: 'Huntington_WV', label: 'Huntington, WV' },
  { value: 'Huntington_IN', label: 'Huntington, IN' },
  { value: 'Huntington_AR', label: 'Huntington, AR' },
  { value: 'Huntington_TX', label: 'Huntington, TX' },
  { value: 'Huntington_UT', label: 'Huntington, UT' },
  { value: 'Huntington_OR', label: 'Huntington, OR' },
  { value: 'Huntington Beach_CA', label: 'Huntington Beach, CA' },
  { value: 'Huntington Mills_PA', label: 'Huntington Mills, PA' },
  { value: 'Huntington Park_CA', label: 'Huntington Park, CA' },
  { value: 'Huntington Station_NY', label: 'Huntington Station, NY' },
  { value: 'Huntington Woods_MI', label: 'Huntington Woods, MI' },
  { value: 'Huntingtown_MD', label: 'Huntingtown, MD' },
  { value: 'Huntland_TN', label: 'Huntland, TN' },
  { value: 'Huntley_MT', label: 'Huntley, MT' },
  { value: 'Huntley_IL', label: 'Huntley, IL' },
  { value: 'Huntley_WY', label: 'Huntley, WY' },
  { value: 'Huntly_VA', label: 'Huntly, VA' },
  { value: 'Huntsburg_OH', label: 'Huntsburg, OH' },
  { value: 'Huntsville_AL', label: 'Huntsville, AL' },
  { value: 'Huntsville_TN', label: 'Huntsville, TN' },
  { value: 'Huntsville_OH', label: 'Huntsville, OH' },
  { value: 'Huntsville_IL', label: 'Huntsville, IL' },
  { value: 'Huntsville_MO', label: 'Huntsville, MO' },
  { value: 'Huntsville_AR', label: 'Huntsville, AR' },
  { value: 'Huntsville_TX', label: 'Huntsville, TX' },
  { value: 'Huntsville_UT', label: 'Huntsville, UT' },
  { value: 'Hurdland_MO', label: 'Hurdland, MO' },
  { value: 'Hurdle Mills_NC', label: 'Hurdle Mills, NC' },
  { value: 'Hurdsfield_ND', label: 'Hurdsfield, ND' },
  { value: 'Hurlburt Field_FL', label: 'Hurlburt Field, FL' },
  { value: 'Hurley_NY', label: 'Hurley, NY' },
  { value: 'Hurley_VA', label: 'Hurley, VA' },
  { value: 'Hurley_WI', label: 'Hurley, WI' },
  { value: 'Hurley_SD', label: 'Hurley, SD' },
  { value: 'Hurley_MO', label: 'Hurley, MO' },
  { value: 'Hurley_NM', label: 'Hurley, NM' },
  { value: 'Hurleyville_NY', label: 'Hurleyville, NY' },
  { value: 'Hurlock_MD', label: 'Hurlock, MD' },
  { value: 'Huron_TN', label: 'Huron, TN' },
  { value: 'Huron_OH', label: 'Huron, OH' },
  { value: 'Huron_IN', label: 'Huron, IN' },
  { value: 'Huron_SD', label: 'Huron, SD' },
  { value: 'Huron_CA', label: 'Huron, CA' },
  { value: 'Hurricane_WV', label: 'Hurricane, WV' },
  { value: 'Hurricane_UT', label: 'Hurricane, UT' },
  { value: 'Hurricane Mills_TN', label: 'Hurricane Mills, TN' },
  { value: 'Hurst_IL', label: 'Hurst, IL' },
  { value: 'Hurst_TX', label: 'Hurst, TX' },
  { value: 'Hurt_VA', label: 'Hurt, VA' },
  { value: 'Hurtsboro_AL', label: 'Hurtsboro, AL' },
  { value: 'Huslia_AK', label: 'Huslia, AK' },
  { value: 'Huson_MT', label: 'Huson, MT' },
  { value: 'Husser_LA', label: 'Husser, LA' },
  { value: 'Hustisford_WI', label: 'Hustisford, WI' },
  { value: 'Hustle_VA', label: 'Hustle, VA' },
  { value: 'Hustler_WI', label: 'Hustler, WI' },
  { value: 'Hustontown_PA', label: 'Hustontown, PA' },
  { value: 'Hustonville_KY', label: 'Hustonville, KY' },
  { value: 'Husum_WA', label: 'Husum, WA' },
  { value: 'Hutchins_TX', label: 'Hutchins, TX' },
  { value: 'Hutchinson_PA', label: 'Hutchinson, PA' },
  { value: 'Hutchinson_MN', label: 'Hutchinson, MN' },
  { value: 'Hutchinson_KS', label: 'Hutchinson, KS' },
  { value: 'Hutsonville_IL', label: 'Hutsonville, IL' },
  { value: 'Huttig_AR', label: 'Huttig, AR' },
  { value: 'Hutto_TX', label: 'Hutto, TX' },
  { value: 'Huttonsville_WV', label: 'Huttonsville, WV' },
  { value: 'Huxford_AL', label: 'Huxford, AL' },
  { value: 'Huxley_IA', label: 'Huxley, IA' },
  { value: 'Hyampom_CA', label: 'Hyampom, CA' },
  { value: 'Hyannis_MA', label: 'Hyannis, MA' },
  { value: 'Hyannis_NE', label: 'Hyannis, NE' },
  { value: 'Hyannis Port_MA', label: 'Hyannis Port, MA' },
  { value: 'Hyattsville_MD', label: 'Hyattsville, MD' },
  { value: 'Hyattville_WY', label: 'Hyattville, WY' },
  { value: 'Hydaburg_AK', label: 'Hydaburg, AK' },
  { value: 'Hyde_PA', label: 'Hyde, PA' },
  { value: 'Hyde Park_MA', label: 'Hyde Park, MA' },
  { value: 'Hyde Park_VT', label: 'Hyde Park, VT' },
  { value: 'Hyde Park_NY', label: 'Hyde Park, NY' },
  { value: 'Hyde Park_PA', label: 'Hyde Park, PA' },
  { value: 'Hyde Park_UT', label: 'Hyde Park, UT' },
  { value: 'Hyden_KY', label: 'Hyden, KY' },
  { value: 'Hyder_AK', label: 'Hyder, AK' },
  { value: 'Hydes_MD', label: 'Hydes, MD' },
  { value: 'Hydesville_CA', label: 'Hydesville, CA' },
  { value: 'Hydetown_PA', label: 'Hydetown, PA' },
  { value: 'Hydro_OK', label: 'Hydro, OK' },
  { value: 'Hye_TX', label: 'Hye, TX' },
  { value: 'Hymera_IN', label: 'Hymera, IN' },
  { value: 'Hyndman_PA', label: 'Hyndman, PA' },
  { value: 'Hyrum_UT', label: 'Hyrum, UT' },
  { value: 'Hysham_MT', label: 'Hysham, MT' },
  { value: 'Iaeger_WV', label: 'Iaeger, WV' },
  { value: 'Ibapah_UT', label: 'Ibapah, UT' },
  { value: 'Iberia_OH', label: 'Iberia, OH' },
  { value: 'Iberia_MO', label: 'Iberia, MO' },
  { value: 'Icard_NC', label: 'Icard, NC' },
  { value: 'Ickesburg_PA', label: 'Ickesburg, PA' },
  { value: 'Ida_MI', label: 'Ida, MI' },
  { value: 'Ida_LA', label: 'Ida, LA' },
  { value: 'Ida_AR', label: 'Ida, AR' },
  { value: 'Ida Grove_IA', label: 'Ida Grove, IA' },
  { value: 'Idabel_OK', label: 'Idabel, OK' },
  { value: 'Idaho City_ID', label: 'Idaho City, ID' },
  { value: 'Idaho Falls_ID', label: 'Idaho Falls, ID' },
  { value: 'Idaho Springs_CO', label: 'Idaho Springs, CO' },
  { value: 'Idalia_CO', label: 'Idalia, CO' },
  { value: 'Idalou_TX', label: 'Idalou, TX' },
  { value: 'Idamay_WV', label: 'Idamay, WV' },
  { value: 'Idanha_OR', label: 'Idanha, OR' },
  { value: 'Idaville_IN', label: 'Idaville, IN' },
  { value: 'Ideal_GA', label: 'Ideal, GA' },
  { value: 'Ideal_SD', label: 'Ideal, SD' },
  { value: 'Ider_AL', label: 'Ider, AL' },
  { value: 'Idledale_CO', label: 'Idledale, CO' },
  { value: 'Idlewild_MI', label: 'Idlewild, MI' },
  { value: 'Idleyld Park_OR', label: 'Idleyld Park, OR' },
  { value: 'Idyllwild_CA', label: 'Idyllwild, CA' },
  { value: 'Ignacio_CO', label: 'Ignacio, CO' },
  { value: 'Igo_CA', label: 'Igo, CA' },
  { value: 'Ihlen_MN', label: 'Ihlen, MN' },
  { value: 'Ijamsville_MD', label: 'Ijamsville, MD' },
  { value: 'Ikes Fork_WV', label: 'Ikes Fork, WV' },
  { value: 'Ilfeld_NM', label: 'Ilfeld, NM' },
  { value: 'Iliamna_AK', label: 'Iliamna, AK' },
  { value: 'Iliff_CO', label: 'Iliff, CO' },
  { value: 'Ilion_NY', label: 'Ilion, NY' },
  { value: 'Illinois City_IL', label: 'Illinois City, IL' },
  { value: 'Illiopolis_IL', label: 'Illiopolis, IL' },
  { value: 'Ilwaco_WA', label: 'Ilwaco, WA' },
  { value: 'Imbler_OR', label: 'Imbler, OR' },
  { value: 'Imboden_AR', label: 'Imboden, AR' },
  { value: 'Imlay_NV', label: 'Imlay, NV' },
  { value: 'Imlay City_MI', label: 'Imlay City, MI' },
  { value: 'Imler_PA', label: 'Imler, PA' },
  { value: 'Immaculata_PA', label: 'Immaculata, PA' },
  { value: 'Immokalee_FL', label: 'Immokalee, FL' },
  { value: 'Imnaha_OR', label: 'Imnaha, OR' },
  { value: 'Imogene_IA', label: 'Imogene, IA' },
  { value: 'Imperial_PA', label: 'Imperial, PA' },
  { value: 'Imperial_MO', label: 'Imperial, MO' },
  { value: 'Imperial_NE', label: 'Imperial, NE' },
  { value: 'Imperial_TX', label: 'Imperial, TX' },
  { value: 'Imperial_CA', label: 'Imperial, CA' },
  { value: 'Imperial Beach_CA', label: 'Imperial Beach, CA' },
  { value: 'Ina_IL', label: 'Ina, IL' },
  { value: 'Inarajan_GU', label: 'Inarajan, GU' },
  { value: 'Inavale_NE', label: 'Inavale, NE' },
  { value: 'Inchelium_WA', label: 'Inchelium, WA' },
  { value: 'Incline Village_NV', label: 'Incline Village, NV' },
  { value: 'Independence_VA', label: 'Independence, VA' },
  { value: 'Independence_WV', label: 'Independence, WV' },
  { value: 'Independence_KY', label: 'Independence, KY' },
  { value: 'Independence_OH', label: 'Independence, OH' },
  { value: 'Independence_IA', label: 'Independence, IA' },
  { value: 'Independence_WI', label: 'Independence, WI' },
  { value: 'Independence_MO', label: 'Independence, MO' },
  { value: 'Independence_KS', label: 'Independence, KS' },
  { value: 'Independence_LA', label: 'Independence, LA' },
  { value: 'Independence_CA', label: 'Independence, CA' },
  { value: 'Independence_OR', label: 'Independence, OR' },
  { value: 'Index_WA', label: 'Index, WA' },
  { value: 'Indiahoma_OK', label: 'Indiahoma, OK' },
  { value: 'Indialantic_FL', label: 'Indialantic, FL' },
  { value: 'Indian_AK', label: 'Indian, AK' },
  { value: 'Indian Head_PA', label: 'Indian Head, PA' },
  { value: 'Indian Head_MD', label: 'Indian Head, MD' },
  { value: 'Indian Hills_CO', label: 'Indian Hills, CO' },
  { value: 'Indian Lake_NY', label: 'Indian Lake, NY' },
  { value: 'Indian Lake Estates_FL', label: 'Indian Lake Estates, FL' },
  { value: 'Indian Mound_TN', label: 'Indian Mound, TN' },
  { value: 'Indian Orchard_MA', label: 'Indian Orchard, MA' },
  { value: 'Indian River_MI', label: 'Indian River, MI' },
  { value: 'Indian Rocks Beach_FL', label: 'Indian Rocks Beach, FL' },
  { value: 'Indian Springs_NV', label: 'Indian Springs, NV' },
  { value: 'Indian Trail_NC', label: 'Indian Trail, NC' },
  { value: 'Indian Valley_VA', label: 'Indian Valley, VA' },
  { value: 'Indian Valley_ID', label: 'Indian Valley, ID' },
  { value: 'Indian Wells_AZ', label: 'Indian Wells, AZ' },
  { value: 'Indian Wells_CA', label: 'Indian Wells, CA' },
  { value: 'Indiana_PA', label: 'Indiana, PA' },
  { value: 'Indianapolis_IN', label: 'Indianapolis, IN' },
  { value: 'Indianola_PA', label: 'Indianola, PA' },
  { value: 'Indianola_MS', label: 'Indianola, MS' },
  { value: 'Indianola_IA', label: 'Indianola, IA' },
  { value: 'Indianola_IL', label: 'Indianola, IL' },
  { value: 'Indianola_NE', label: 'Indianola, NE' },
  { value: 'Indianola_OK', label: 'Indianola, OK' },
  { value: 'Indianola_WA', label: 'Indianola, WA' },
  { value: 'Indiantown_FL', label: 'Indiantown, FL' },
  { value: 'Indio_CA', label: 'Indio, CA' },
  { value: 'Indore_WV', label: 'Indore, WV' },
  { value: 'Industry_PA', label: 'Industry, PA' },
  { value: 'Industry_IL', label: 'Industry, IL' },
  { value: 'Industry_TX', label: 'Industry, TX' },
  { value: 'Inez_KY', label: 'Inez, KY' },
  { value: 'Inez_TX', label: 'Inez, TX' },
  { value: 'Ingalls_IN', label: 'Ingalls, IN' },
  { value: 'Ingalls_MI', label: 'Ingalls, MI' },
  { value: 'Ingalls_KS', label: 'Ingalls, KS' },
  { value: 'Ingleside_MD', label: 'Ingleside, MD' },
  { value: 'Ingleside_IL', label: 'Ingleside, IL' },
  { value: 'Ingleside_TX', label: 'Ingleside, TX' },
  { value: 'Inglewood_CA', label: 'Inglewood, CA' },
  { value: 'Inglis_FL', label: 'Inglis, FL' },
  { value: 'Ingomar_MT', label: 'Ingomar, MT' },
  { value: 'Ingraham_IL', label: 'Ingraham, IL' },
  { value: 'Ingram_TX', label: 'Ingram, TX' },
  { value: 'Inkom_ID', label: 'Inkom, ID' },
  { value: 'Inkster_MI', label: 'Inkster, MI' },
  { value: 'Inkster_ND', label: 'Inkster, ND' },
  { value: 'Inland_NE', label: 'Inland, NE' },
  { value: 'Inlet_NY', label: 'Inlet, NY' },
  { value: 'Inlet Beach_FL', label: 'Inlet Beach, FL' },
  { value: 'Inman_SC', label: 'Inman, SC' },
  { value: 'Inman_KS', label: 'Inman, KS' },
  { value: 'Inman_NE', label: 'Inman, NE' },
  { value: 'Innis_LA', label: 'Innis, LA' },
  { value: 'Inola_OK', label: 'Inola, OK' },
  { value: 'Institute_WV', label: 'Institute, WV' },
  { value: 'Intercession City_FL', label: 'Intercession City, FL' },
  { value: 'Intercourse_PA', label: 'Intercourse, PA' },
  { value: 'Interior_SD', label: 'Interior, SD' },
  { value: 'Interlachen_FL', label: 'Interlachen, FL' },
  { value: 'Interlaken_NY', label: 'Interlaken, NY' },
  { value: 'Interlochen_MI', label: 'Interlochen, MI' },
  { value: 'International Falls_MN', label: 'International Falls, MN' },
  { value: 'Intervale_NH', label: 'Intervale, NH' },
  { value: 'Inver Grove Heights_MN', label: 'Inver Grove Heights, MN' },
  { value: 'Inverness_FL', label: 'Inverness, FL' },
  { value: 'Inverness_MS', label: 'Inverness, MS' },
  { value: 'Inverness_MT', label: 'Inverness, MT' },
  { value: 'Inverness_CA', label: 'Inverness, CA' },
  { value: 'Inwood_NY', label: 'Inwood, NY' },
  { value: 'Inwood_WV', label: 'Inwood, WV' },
  { value: 'Inwood_IA', label: 'Inwood, IA' },
  { value: 'Inyokern_CA', label: 'Inyokern, CA' },
  { value: 'Iola_WI', label: 'Iola, WI' },
  { value: 'Iola_KS', label: 'Iola, KS' },
  { value: 'Iola_TX', label: 'Iola, TX' },
  { value: 'Iona_MN', label: 'Iona, MN' },
  { value: 'Iona_ID', label: 'Iona, ID' },
  { value: 'Ione_CA', label: 'Ione, CA' },
  { value: 'Ione_OR', label: 'Ione, OR' },
  { value: 'Ione_WA', label: 'Ione, WA' },
  { value: 'Ionia_NY', label: 'Ionia, NY' },
  { value: 'Ionia_MI', label: 'Ionia, MI' },
  { value: 'Ionia_IA', label: 'Ionia, IA' },
  { value: 'Ionia_MO', label: 'Ionia, MO' },
  { value: 'Iota_LA', label: 'Iota, LA' },
  { value: 'Iowa_LA', label: 'Iowa, LA' },
  { value: 'Iowa City_IA', label: 'Iowa City, IA' },
  { value: 'Iowa Falls_IA', label: 'Iowa Falls, IA' },
  { value: 'Iowa Park_TX', label: 'Iowa Park, TX' },
  { value: 'Ipava_IL', label: 'Ipava, IL' },
  { value: 'Ipswich_MA', label: 'Ipswich, MA' },
  { value: 'Ipswich_SD', label: 'Ipswich, SD' },
  { value: 'Ira_IA', label: 'Ira, IA' },
  { value: 'Ira_TX', label: 'Ira, TX' },
  { value: 'Iraan_TX', label: 'Iraan, TX' },
  { value: 'Irasburg_VT', label: 'Irasburg, VT' },
  { value: 'Iredell_TX', label: 'Iredell, TX' },
  { value: 'Ireland_WV', label: 'Ireland, WV' },
  { value: 'Irene_SD', label: 'Irene, SD' },
  { value: 'Irene_TX', label: 'Irene, TX' },
  { value: 'Ireton_IA', label: 'Ireton, IA' },
  { value: 'Irma_WI', label: 'Irma, WI' },
  { value: 'Irmo_SC', label: 'Irmo, SC' },
  { value: 'Iron_MN', label: 'Iron, MN' },
  { value: 'Iron Belt_WI', label: 'Iron Belt, WI' },
  { value: 'Iron City_TN', label: 'Iron City, TN' },
  { value: 'Iron City_GA', label: 'Iron City, GA' },
  { value: 'Iron Gate_VA', label: 'Iron Gate, VA' },
  { value: 'Iron Mountain_MI', label: 'Iron Mountain, MI' },
  { value: 'Iron Ridge_WI', label: 'Iron Ridge, WI' },
  { value: 'Iron River_MI', label: 'Iron River, MI' },
  { value: 'Iron River_WI', label: 'Iron River, WI' },
  { value: 'Iron Station_NC', label: 'Iron Station, NC' },
  { value: 'Irondale_OH', label: 'Irondale, OH' },
  { value: 'Irondale_MO', label: 'Irondale, MO' },
  { value: 'Irons_MI', label: 'Irons, MI' },
  { value: 'Ironside_OR', label: 'Ironside, OR' },
  { value: 'Ironton_OH', label: 'Ironton, OH' },
  { value: 'Ironton_MN', label: 'Ironton, MN' },
  { value: 'Ironton_MO', label: 'Ironton, MO' },
  { value: 'Ironwood_MI', label: 'Ironwood, MI' },
  { value: 'Iroquois_SD', label: 'Iroquois, SD' },
  { value: 'Iroquois_IL', label: 'Iroquois, IL' },
  { value: 'Irrigon_OR', label: 'Irrigon, OR' },
  { value: 'Irvine_PA', label: 'Irvine, PA' },
  { value: 'Irvine_KY', label: 'Irvine, KY' },
  { value: 'Irvine_CA', label: 'Irvine, CA' },
  { value: 'Irving_NY', label: 'Irving, NY' },
  { value: 'Irving_IL', label: 'Irving, IL' },
  { value: 'Irving_TX', label: 'Irving, TX' },
  { value: 'Irvington_NJ', label: 'Irvington, NJ' },
  { value: 'Irvington_NY', label: 'Irvington, NY' },
  { value: 'Irvington_VA', label: 'Irvington, VA' },
  { value: 'Irvington_AL', label: 'Irvington, AL' },
  { value: 'Irvington_KY', label: 'Irvington, KY' },
  { value: 'Irvington_IL', label: 'Irvington, IL' },
  { value: 'Irvona_PA', label: 'Irvona, PA' },
  { value: 'Irwin_PA', label: 'Irwin, PA' },
  { value: 'Irwin_OH', label: 'Irwin, OH' },
  { value: 'Irwin_IA', label: 'Irwin, IA' },
  { value: 'Irwin_ID', label: 'Irwin, ID' },
  { value: 'Irwinton_GA', label: 'Irwinton, GA' },
  { value: 'Isaban_WV', label: 'Isaban, WV' },
  { value: 'Isabel_SD', label: 'Isabel, SD' },
  { value: 'Isabel_KS', label: 'Isabel, KS' },
  { value: 'Isabela_PR', label: 'Isabela, PR' },
  { value: 'Isabella_PA', label: 'Isabella, PA' },
  { value: 'Isabella_MN', label: 'Isabella, MN' },
  { value: 'Isabella_MO', label: 'Isabella, MO' },
  { value: 'Isabella_OK', label: 'Isabella, OK' },
  { value: 'Isanti_MN', label: 'Isanti, MN' },
  { value: 'Iselin_NJ', label: 'Iselin, NJ' },
  { value: 'Ishpeming_MI', label: 'Ishpeming, MI' },
  { value: 'Islamorada_FL', label: 'Islamorada, FL' },
  { value: 'Island_KY', label: 'Island, KY' },
  { value: 'Island Falls_ME', label: 'Island Falls, ME' },
  { value: 'Island Heights_NJ', label: 'Island Heights, NJ' },
  { value: 'Island Lake_IL', label: 'Island Lake, IL' },
  { value: 'Island Park_NY', label: 'Island Park, NY' },
  { value: 'Island Park_ID', label: 'Island Park, ID' },
  { value: 'Island Pond_VT', label: 'Island Pond, VT' },
  { value: 'Islandia_NY', label: 'Islandia, NY' },
  { value: 'Islandton_SC', label: 'Islandton, SC' },
  { value: 'Isle_MN', label: 'Isle, MN' },
  { value: 'Isle Au Haut_ME', label: 'Isle Au Haut, ME' },
  { value: 'Isle La Motte_VT', label: 'Isle La Motte, VT' },
  { value: 'Isle Of Palms_SC', label: 'Isle Of Palms, SC' },
  { value: 'Isle St George_OH', label: 'Isle St George, OH' },
  { value: 'Islesboro_ME', label: 'Islesboro, ME' },
  { value: 'Islesford_ME', label: 'Islesford, ME' },
  { value: 'Isleta_NM', label: 'Isleta, NM' },
  { value: 'Isleton_CA', label: 'Isleton, CA' },
  { value: 'Islip_NY', label: 'Islip, NY' },
  { value: 'Islip Terrace_NY', label: 'Islip Terrace, NY' },
  { value: 'Ismay_MT', label: 'Ismay, MT' },
  { value: 'Isola_MS', label: 'Isola, MS' },
  { value: 'Isom_KY', label: 'Isom, KY' },
  { value: 'Isonville_KY', label: 'Isonville, KY' },
  { value: 'Issaquah_WA', label: 'Issaquah, WA' },
  { value: 'Issue_MD', label: 'Issue, MD' },
  { value: 'Italy_TX', label: 'Italy, TX' },
  { value: 'Itasca_IL', label: 'Itasca, IL' },
  { value: 'Itasca_TX', label: 'Itasca, TX' },
  { value: 'Ithaca_NY', label: 'Ithaca, NY' },
  { value: 'Ithaca_MI', label: 'Ithaca, MI' },
  { value: 'Ithaca_NE', label: 'Ithaca, NE' },
  { value: 'Itmann_WV', label: 'Itmann, WV' },
  { value: 'Itta Bena_MS', label: 'Itta Bena, MS' },
  { value: 'Iuka_MS', label: 'Iuka, MS' },
  { value: 'Iuka_IL', label: 'Iuka, IL' },
  { value: 'Iuka_KS', label: 'Iuka, KS' },
  { value: 'Iva_SC', label: 'Iva, SC' },
  { value: 'Ivan_AR', label: 'Ivan, AR' },
  { value: 'Ivanhoe_VA', label: 'Ivanhoe, VA' },
  { value: 'Ivanhoe_NC', label: 'Ivanhoe, NC' },
  { value: 'Ivanhoe_MN', label: 'Ivanhoe, MN' },
  { value: 'Ivanhoe_TX', label: 'Ivanhoe, TX' },
  { value: 'Ivanhoe_CA', label: 'Ivanhoe, CA' },
  { value: 'Ivel_KY', label: 'Ivel, KY' },
  { value: 'Ivesdale_IL', label: 'Ivesdale, IL' },
  { value: 'Ivins_UT', label: 'Ivins, UT' },
  { value: 'Ivor_VA', label: 'Ivor, VA' },
  { value: 'Ivoryton_CT', label: 'Ivoryton, CT' },
  { value: 'Ivydale_WV', label: 'Ivydale, WV' },
  { value: 'Ixonia_WI', label: 'Ixonia, WI' },
  { value: 'Jachin_AL', label: 'Jachin, AL' },
  { value: 'Jack_AL', label: 'Jack, AL' },
  { value: 'Jackhorn_KY', label: 'Jackhorn, KY' },
  { value: 'Jackman_ME', label: 'Jackman, ME' },
  { value: 'Jackpot_NV', label: 'Jackpot, NV' },
  { value: 'Jacks Creek_TN', label: 'Jacks Creek, TN' },
  { value: 'Jacksboro_TN', label: 'Jacksboro, TN' },
  { value: 'Jacksboro_TX', label: 'Jacksboro, TX' },
  { value: 'Jackson_NH', label: 'Jackson, NH' },
  { value: 'Jackson_NJ', label: 'Jackson, NJ' },
  { value: 'Jackson_PA', label: 'Jackson, PA' },
  { value: 'Jackson_NC', label: 'Jackson, NC' },
  { value: 'Jackson_SC', label: 'Jackson, SC' },
  { value: 'Jackson_GA', label: 'Jackson, GA' },
  { value: 'Jackson_AL', label: 'Jackson, AL' },
  { value: 'Jackson_TN', label: 'Jackson, TN' },
  { value: 'Jackson_MS', label: 'Jackson, MS' },
  { value: 'Jackson_KY', label: 'Jackson, KY' },
  { value: 'Jackson_OH', label: 'Jackson, OH' },
  { value: 'Jackson_MI', label: 'Jackson, MI' },
  { value: 'Jackson_WI', label: 'Jackson, WI' },
  { value: 'Jackson_MN', label: 'Jackson, MN' },
  { value: 'Jackson_MT', label: 'Jackson, MT' },
  { value: 'Jackson_MO', label: 'Jackson, MO' },
  { value: 'Jackson_NE', label: 'Jackson, NE' },
  { value: 'Jackson_LA', label: 'Jackson, LA' },
  { value: 'Jackson_WY', label: 'Jackson, WY' },
  { value: 'Jackson_CA', label: 'Jackson, CA' },
  { value: 'Jackson Center_PA', label: 'Jackson Center, PA' },
  { value: 'Jackson Center_OH', label: 'Jackson Center, OH' },
  { value: 'Jackson Heights_NY', label: 'Jackson Heights, NY' },
  { value: 'Jackson Springs_NC', label: 'Jackson Springs, NC' },
  { value: 'Jacksonboro_SC', label: 'Jacksonboro, SC' },
  { value: 'Jacksonburg_WV', label: 'Jacksonburg, WV' },
  { value: 'Jacksonport_AR', label: 'Jacksonport, AR' },
  { value: 'Jacksons Gap_AL', label: 'Jacksons Gap, AL' },
  { value: 'Jacksontown_OH', label: 'Jacksontown, OH' },
  { value: 'Jacksonville_VT', label: 'Jacksonville, VT' },
  { value: 'Jacksonville_NY', label: 'Jacksonville, NY' },
  { value: 'Jacksonville_NC', label: 'Jacksonville, NC' },
  { value: 'Jacksonville_GA', label: 'Jacksonville, GA' },
  { value: 'Jacksonville_FL', label: 'Jacksonville, FL' },
  { value: 'Jacksonville_AL', label: 'Jacksonville, AL' },
  { value: 'Jacksonville_OH', label: 'Jacksonville, OH' },
  { value: 'Jacksonville_IL', label: 'Jacksonville, IL' },
  { value: 'Jacksonville_MO', label: 'Jacksonville, MO' },
  { value: 'Jacksonville_AR', label: 'Jacksonville, AR' },
  { value: 'Jacksonville_TX', label: 'Jacksonville, TX' },
  { value: 'Jacksonville_OR', label: 'Jacksonville, OR' },
  { value: 'Jacksonville Beach_FL', label: 'Jacksonville Beach, FL' },
  { value: 'Jacob_IL', label: 'Jacob, IL' },
  { value: 'Jacobs Creek_PA', label: 'Jacobs Creek, PA' },
  { value: 'Jacobsburg_OH', label: 'Jacobsburg, OH' },
  { value: 'Jacobson_MN', label: 'Jacobson, MN' },
  { value: 'Jacumba_CA', label: 'Jacumba, CA' },
  { value: 'Jadwin_MO', label: 'Jadwin, MO' },
  { value: 'Jaffrey_NH', label: 'Jaffrey, NH' },
  { value: 'Jakin_GA', label: 'Jakin, GA' },
  { value: 'Jal_NM', label: 'Jal, NM' },
  { value: 'Jamaica_VT', label: 'Jamaica, VT' },
  { value: 'Jamaica_NY', label: 'Jamaica, NY' },
  { value: 'Jamaica_VA', label: 'Jamaica, VA' },
  { value: 'Jamaica_IA', label: 'Jamaica, IA' },
  { value: 'Jamaica Plain_MA', label: 'Jamaica Plain, MA' },
  { value: 'James City_PA', label: 'James City, PA' },
  { value: 'James Creek_PA', label: 'James Creek, PA' },
  { value: 'Jameson_MO', label: 'Jameson, MO' },
  { value: 'Jamesport_NY', label: 'Jamesport, NY' },
  { value: 'Jamesport_MO', label: 'Jamesport, MO' },
  { value: 'Jamestown_RI', label: 'Jamestown, RI' },
  { value: 'Jamestown_NY', label: 'Jamestown, NY' },
  { value: 'Jamestown_PA', label: 'Jamestown, PA' },
  { value: 'Jamestown_VA', label: 'Jamestown, VA' },
  { value: 'Jamestown_NC', label: 'Jamestown, NC' },
  { value: 'Jamestown_SC', label: 'Jamestown, SC' },
  { value: 'Jamestown_TN', label: 'Jamestown, TN' },
  { value: 'Jamestown_KY', label: 'Jamestown, KY' },
  { value: 'Jamestown_OH', label: 'Jamestown, OH' },
  { value: 'Jamestown_IN', label: 'Jamestown, IN' },
  { value: 'Jamestown_ND', label: 'Jamestown, ND' },
  { value: 'Jamestown_MO', label: 'Jamestown, MO' },
  { value: 'Jamestown_KS', label: 'Jamestown, KS' },
  { value: 'Jamestown_LA', label: 'Jamestown, LA' },
  { value: 'Jamestown_CO', label: 'Jamestown, CO' },
  { value: 'Jamestown_NM', label: 'Jamestown, NM' },
  { value: 'Jamestown_CA', label: 'Jamestown, CA' },
  { value: 'Jamesville_NY', label: 'Jamesville, NY' },
  { value: 'Jamesville_VA', label: 'Jamesville, VA' },
  { value: 'Jamesville_NC', label: 'Jamesville, NC' },
  { value: 'Jamieson_OR', label: 'Jamieson, OR' },
  { value: 'Jamison_PA', label: 'Jamison, PA' },
  { value: 'Jamul_CA', label: 'Jamul, CA' },
  { value: 'Jane Lew_WV', label: 'Jane Lew, WV' },
  { value: 'Janesville_IA', label: 'Janesville, IA' },
  { value: 'Janesville_WI', label: 'Janesville, WI' },
  { value: 'Janesville_MN', label: 'Janesville, MN' },
  { value: 'Janesville_CA', label: 'Janesville, CA' },
  { value: 'Jansen_NE', label: 'Jansen, NE' },
  { value: 'Jarales_NM', label: 'Jarales, NM' },
  { value: 'Jarbidge_NV', label: 'Jarbidge, NV' },
  { value: 'Jaroso_CO', label: 'Jaroso, CO' },
  { value: 'Jarratt_VA', label: 'Jarratt, VA' },
  { value: 'Jarreau_LA', label: 'Jarreau, LA' },
  { value: 'Jarrell_TX', label: 'Jarrell, TX' },
  { value: 'Jarrettsville_MD', label: 'Jarrettsville, MD' },
  { value: 'Jarvisburg_NC', label: 'Jarvisburg, NC' },
  { value: 'Jasonville_IN', label: 'Jasonville, IN' },
  { value: 'Jasper_NY', label: 'Jasper, NY' },
  { value: 'Jasper_GA', label: 'Jasper, GA' },
  { value: 'Jasper_FL', label: 'Jasper, FL' },
  { value: 'Jasper_AL', label: 'Jasper, AL' },
  { value: 'Jasper_TN', label: 'Jasper, TN' },
  { value: 'Jasper_OH', label: 'Jasper, OH' },
  { value: 'Jasper_IN', label: 'Jasper, IN' },
  { value: 'Jasper_MI', label: 'Jasper, MI' },
  { value: 'Jasper_MN', label: 'Jasper, MN' },
  { value: 'Jasper_MO', label: 'Jasper, MO' },
  { value: 'Jasper_AR', label: 'Jasper, AR' },
  { value: 'Jasper_TX', label: 'Jasper, TX' },
  { value: 'Java_VA', label: 'Java, VA' },
  { value: 'Java_SD', label: 'Java, SD' },
  { value: 'Java Center_NY', label: 'Java Center, NY' },
  { value: 'Java Village_NY', label: 'Java Village, NY' },
  { value: 'Jay_ME', label: 'Jay, ME' },
  { value: 'Jay_NY', label: 'Jay, NY' },
  { value: 'Jay_FL', label: 'Jay, FL' },
  { value: 'Jay_OK', label: 'Jay, OK' },
  { value: 'Jay Em_WY', label: 'Jay Em, WY' },
  { value: 'Jayess_MS', label: 'Jayess, MS' },
  { value: 'Jayton_TX', label: 'Jayton, TX' },
  { value: 'Jayuya_PR', label: 'Jayuya, PR' },
  { value: 'Jber_AK', label: 'Jber, AK' },
  { value: 'Jbphh_HI', label: 'Jbphh, HI' },
  { value: 'Jbsa Ft Sam Houston_TX', label: 'Jbsa Ft Sam Houston, TX' },
  { value: 'Jbsa Lackland_TX', label: 'Jbsa Lackland, TX' },
  { value: 'Jbsa Randolph_TX', label: 'Jbsa Randolph, TX' },
  { value: 'Jean_NV', label: 'Jean, NV' },
  { value: 'Jeanerette_LA', label: 'Jeanerette, LA' },
  { value: 'Jeannette_PA', label: 'Jeannette, PA' },
  { value: 'Jeddo_MI', label: 'Jeddo, MI' },
  { value: 'Jeff_KY', label: 'Jeff, KY' },
  { value: 'Jeffers_MN', label: 'Jeffers, MN' },
  { value: 'Jefferson_MA', label: 'Jefferson, MA' },
  { value: 'Jefferson_NH', label: 'Jefferson, NH' },
  { value: 'Jefferson_ME', label: 'Jefferson, ME' },
  { value: 'Jefferson_NY', label: 'Jefferson, NY' },
  { value: 'Jefferson_PA', label: 'Jefferson, PA' },
  { value: 'Jefferson_MD', label: 'Jefferson, MD' },
  { value: 'Jefferson_NC', label: 'Jefferson, NC' },
  { value: 'Jefferson_SC', label: 'Jefferson, SC' },
  { value: 'Jefferson_GA', label: 'Jefferson, GA' },
  { value: 'Jefferson_OH', label: 'Jefferson, OH' },
  { value: 'Jefferson_IA', label: 'Jefferson, IA' },
  { value: 'Jefferson_WI', label: 'Jefferson, WI' },
  { value: 'Jefferson_SD', label: 'Jefferson, SD' },
  { value: 'Jefferson_AR', label: 'Jefferson, AR' },
  { value: 'Jefferson_TX', label: 'Jefferson, TX' },
  { value: 'Jefferson_CO', label: 'Jefferson, CO' },
  { value: 'Jefferson_OR', label: 'Jefferson, OR' },
  { value: 'Jefferson City_TN', label: 'Jefferson City, TN' },
  { value: 'Jefferson City_MT', label: 'Jefferson City, MT' },
  { value: 'Jefferson City_MO', label: 'Jefferson City, MO' },
  { value: 'Jefferson Valley_NY', label: 'Jefferson Valley, NY' },
  { value: 'Jeffersonton_VA', label: 'Jeffersonton, VA' },
  { value: 'Jeffersonville_VT', label: 'Jeffersonville, VT' },
  { value: 'Jeffersonville_NY', label: 'Jeffersonville, NY' },
  { value: 'Jeffersonville_GA', label: 'Jeffersonville, GA' },
  { value: 'Jeffersonville_KY', label: 'Jeffersonville, KY' },
  { value: 'Jeffersonville_OH', label: 'Jeffersonville, OH' },
  { value: 'Jeffersonville_IN', label: 'Jeffersonville, IN' },
  { value: 'Jeffrey_WV', label: 'Jeffrey, WV' },
  { value: 'Jeffrey City_WY', label: 'Jeffrey City, WY' },
  { value: 'Jekyll Island_GA', label: 'Jekyll Island, GA' },
  { value: 'Jellico_TN', label: 'Jellico, TN' },
  { value: 'Jelm_WY', label: 'Jelm, WY' },
  { value: 'Jemez Pueblo_NM', label: 'Jemez Pueblo, NM' },
  { value: 'Jemez Springs_NM', label: 'Jemez Springs, NM' },
  { value: 'Jemison_AL', label: 'Jemison, AL' },
  { value: 'Jena_LA', label: 'Jena, LA' },
  { value: 'Jenera_OH', label: 'Jenera, OH' },
  { value: 'Jenison_MI', label: 'Jenison, MI' },
  { value: 'Jenkinjones_WV', label: 'Jenkinjones, WV' },
  { value: 'Jenkins_KY', label: 'Jenkins, KY' },
  { value: 'Jenkinsburg_GA', label: 'Jenkinsburg, GA' },
  { value: 'Jenkinsville_SC', label: 'Jenkinsville, SC' },
  { value: 'Jenkintown_PA', label: 'Jenkintown, PA' },
  { value: 'Jenks_OK', label: 'Jenks, OK' },
  { value: 'Jenner_CA', label: 'Jenner, CA' },
  { value: 'Jenners_PA', label: 'Jenners, PA' },
  { value: 'Jennerstown_PA', label: 'Jennerstown, PA' },
  { value: 'Jennings_FL', label: 'Jennings, FL' },
  { value: 'Jennings_KS', label: 'Jennings, KS' },
  { value: 'Jennings_LA', label: 'Jennings, LA' },
  { value: 'Jennings_OK', label: 'Jennings, OK' },
  { value: 'Jensen_UT', label: 'Jensen, UT' },
  { value: 'Jensen Beach_FL', label: 'Jensen Beach, FL' },
  { value: 'Jeremiah_KY', label: 'Jeremiah, KY' },
  { value: 'Jericho_VT', label: 'Jericho, VT' },
  { value: 'Jericho_NY', label: 'Jericho, NY' },
  { value: 'Jerico Springs_MO', label: 'Jerico Springs, MO' },
  { value: 'Jermyn_PA', label: 'Jermyn, PA' },
  { value: 'Jermyn_TX', label: 'Jermyn, TX' },
  { value: 'Jerome_PA', label: 'Jerome, PA' },
  { value: 'Jerome_MI', label: 'Jerome, MI' },
  { value: 'Jerome_MO', label: 'Jerome, MO' },
  { value: 'Jerome_ID', label: 'Jerome, ID' },
  { value: 'Jerome_AZ', label: 'Jerome, AZ' },
  { value: 'Jeromesville_OH', label: 'Jeromesville, OH' },
  { value: 'Jerry City_OH', label: 'Jerry City, OH' },
  { value: 'Jersey_GA', label: 'Jersey, GA' },
  { value: 'Jersey_AR', label: 'Jersey, AR' },
  { value: 'Jersey City_NJ', label: 'Jersey City, NJ' },
  { value: 'Jersey Mills_PA', label: 'Jersey Mills, PA' },
  { value: 'Jersey Shore_PA', label: 'Jersey Shore, PA' },
  { value: 'Jerseyville_IL', label: 'Jerseyville, IL' },
  { value: 'Jerusalem_OH', label: 'Jerusalem, OH' },
  { value: 'Jerusalem_AR', label: 'Jerusalem, AR' },
  { value: 'Jesse_WV', label: 'Jesse, WV' },
  { value: 'Jessie_ND', label: 'Jessie, ND' },
  { value: 'Jessieville_AR', label: 'Jessieville, AR' },
  { value: 'Jessup_PA', label: 'Jessup, PA' },
  { value: 'Jessup_MD', label: 'Jessup, MD' },
  { value: 'Jesup_GA', label: 'Jesup, GA' },
  { value: 'Jesup_IA', label: 'Jesup, IA' },
  { value: 'Jet_OK', label: 'Jet, OK' },
  { value: 'Jetersville_VA', label: 'Jetersville, VA' },
  { value: 'Jetmore_KS', label: 'Jetmore, KS' },
  { value: 'Jetson_KY', label: 'Jetson, KY' },
  { value: 'Jewell_GA', label: 'Jewell, GA' },
  { value: 'Jewell_OH', label: 'Jewell, OH' },
  { value: 'Jewell_IA', label: 'Jewell, IA' },
  { value: 'Jewell_KS', label: 'Jewell, KS' },
  { value: 'Jewell Ridge_VA', label: 'Jewell Ridge, VA' },
  { value: 'Jewett_NY', label: 'Jewett, NY' },
  { value: 'Jewett_OH', label: 'Jewett, OH' },
  { value: 'Jewett_IL', label: 'Jewett, IL' },
  { value: 'Jewett_TX', label: 'Jewett, TX' },
  { value: 'Jewett City_CT', label: 'Jewett City, CT' },
  { value: 'Jim Falls_WI', label: 'Jim Falls, WI' },
  { value: 'Jim Thorpe_PA', label: 'Jim Thorpe, PA' },
  { value: 'Joanna_SC', label: 'Joanna, SC' },
  { value: 'Joaquin_TX', label: 'Joaquin, TX' },
  { value: 'Jobstown_NJ', label: 'Jobstown, NJ' },
  { value: 'Joelton_TN', label: 'Joelton, TN' },
  { value: 'Joes_CO', label: 'Joes, CO' },
  { value: 'Joffre_PA', label: 'Joffre, PA' },
  { value: 'Johannesburg_MI', label: 'Johannesburg, MI' },
  { value: 'Johannesburg_CA', label: 'Johannesburg, CA' },
  { value: 'John Day_OR', label: 'John Day, OR' },
  { value: 'Johns Island_SC', label: 'Johns Island, SC' },
  { value: 'Johnsburg_NY', label: 'Johnsburg, NY' },
  { value: 'Johnson_VT', label: 'Johnson, VT' },
  { value: 'Johnson_NY', label: 'Johnson, NY' },
  { value: 'Johnson_KS', label: 'Johnson, KS' },
  { value: 'Johnson_NE', label: 'Johnson, NE' },
  { value: 'Johnson City_NY', label: 'Johnson City, NY' },
  { value: 'Johnson City_TN', label: 'Johnson City, TN' },
  { value: 'Johnson City_TX', label: 'Johnson City, TX' },
  { value: 'Johnson Creek_WI', label: 'Johnson Creek, WI' },
  { value: 'Johnsonburg_NJ', label: 'Johnsonburg, NJ' },
  { value: 'Johnsonburg_PA', label: 'Johnsonburg, PA' },
  { value: 'Johnsonville_NY', label: 'Johnsonville, NY' },
  { value: 'Johnsonville_SC', label: 'Johnsonville, SC' },
  { value: 'Johnsonville_IL', label: 'Johnsonville, IL' },
  { value: 'Johnston_RI', label: 'Johnston, RI' },
  { value: 'Johnston_SC', label: 'Johnston, SC' },
  { value: 'Johnston_IA', label: 'Johnston, IA' },
  { value: 'Johnston City_IL', label: 'Johnston City, IL' },
  { value: 'Johnstown_NY', label: 'Johnstown, NY' },
  { value: 'Johnstown_PA', label: 'Johnstown, PA' },
  { value: 'Johnstown_OH', label: 'Johnstown, OH' },
  { value: 'Johnstown_NE', label: 'Johnstown, NE' },
  { value: 'Johnstown_CO', label: 'Johnstown, CO' },
  { value: 'Joice_IA', label: 'Joice, IA' },
  { value: 'Joiner_AR', label: 'Joiner, AR' },
  { value: 'Joinerville_TX', label: 'Joinerville, TX' },
  { value: 'Joint Base Mdl_NJ', label: 'Joint Base Mdl, NJ' },
  { value: 'Joliet_MT', label: 'Joliet, MT' },
  { value: 'Joliet_IL', label: 'Joliet, IL' },
  { value: 'Jolley_IA', label: 'Jolley, IA' },
  { value: 'Jolo_WV', label: 'Jolo, WV' },
  { value: 'Jolon_CA', label: 'Jolon, CA' },
  { value: 'Jonancy_KY', label: 'Jonancy, KY' },
  { value: 'Jonas Ridge_NC', label: 'Jonas Ridge, NC' },
  { value: 'Jones_AL', label: 'Jones, AL' },
  { value: 'Jones_MI', label: 'Jones, MI' },
  { value: 'Jones_LA', label: 'Jones, LA' },
  { value: 'Jones_OK', label: 'Jones, OK' },
  { value: 'Jones Mills_PA', label: 'Jones Mills, PA' },
  { value: 'Jonesboro_ME', label: 'Jonesboro, ME' },
  { value: 'Jonesboro_GA', label: 'Jonesboro, GA' },
  { value: 'Jonesboro_IN', label: 'Jonesboro, IN' },
  { value: 'Jonesboro_IL', label: 'Jonesboro, IL' },
  { value: 'Jonesboro_LA', label: 'Jonesboro, LA' },
  { value: 'Jonesboro_AR', label: 'Jonesboro, AR' },
  { value: 'Jonesboro_TX', label: 'Jonesboro, TX' },
  { value: 'Jonesborough_TN', label: 'Jonesborough, TN' },
  { value: 'Jonesburg_MO', label: 'Jonesburg, MO' },
  { value: 'Jonesport_ME', label: 'Jonesport, ME' },
  { value: 'Jonestown_PA', label: 'Jonestown, PA' },
  { value: 'Jonestown_MS', label: 'Jonestown, MS' },
  { value: 'Jonesville_VA', label: 'Jonesville, VA' },
  { value: 'Jonesville_NC', label: 'Jonesville, NC' },
  { value: 'Jonesville_SC', label: 'Jonesville, SC' },
  { value: 'Jonesville_KY', label: 'Jonesville, KY' },
  { value: 'Jonesville_IN', label: 'Jonesville, IN' },
  { value: 'Jonesville_MI', label: 'Jonesville, MI' },
  { value: 'Jonesville_LA', label: 'Jonesville, LA' },
  { value: 'Jonesville_TX', label: 'Jonesville, TX' },
  { value: 'Joplin_MT', label: 'Joplin, MT' },
  { value: 'Joplin_MO', label: 'Joplin, MO' },
  { value: 'Joppa_MD', label: 'Joppa, MD' },
  { value: 'Joppa_AL', label: 'Joppa, AL' },
  { value: 'Joppa_IL', label: 'Joppa, IL' },
  { value: 'Jordan_NY', label: 'Jordan, NY' },
  { value: 'Jordan_MN', label: 'Jordan, MN' },
  { value: 'Jordan_MT', label: 'Jordan, MT' },
  { value: 'Jordan Valley_OR', label: 'Jordan Valley, OR' },
  { value: 'Jordanville_NY', label: 'Jordanville, NY' },
  { value: 'Joseph_UT', label: 'Joseph, UT' },
  { value: 'Joseph_OR', label: 'Joseph, OR' },
  { value: 'Joseph City_AZ', label: 'Joseph City, AZ' },
  { value: 'Josephine_PA', label: 'Josephine, PA' },
  { value: 'Josephine_WV', label: 'Josephine, WV' },
  { value: 'Josephine_TX', label: 'Josephine, TX' },
  { value: 'Joshua_TX', label: 'Joshua, TX' },
  { value: 'Joshua Tree_CA', label: 'Joshua Tree, CA' },
  { value: 'Jourdanton_TX', label: 'Jourdanton, TX' },
  { value: 'Joy_IL', label: 'Joy, IL' },
  { value: 'Juana Diaz_PR', label: 'Juana Diaz, PR' },
  { value: 'Jud_ND', label: 'Jud, ND' },
  { value: 'Juda_WI', label: 'Juda, WI' },
  { value: 'Judith Gap_MT', label: 'Judith Gap, MT' },
  { value: 'Judsonia_AR', label: 'Judsonia, AR' },
  { value: 'Julesburg_CO', label: 'Julesburg, CO' },
  { value: 'Juliaetta_ID', label: 'Juliaetta, ID' },
  { value: 'Julian_PA', label: 'Julian, PA' },
  { value: 'Julian_WV', label: 'Julian, WV' },
  { value: 'Julian_NC', label: 'Julian, NC' },
  { value: 'Julian_NE', label: 'Julian, NE' },
  { value: 'Julian_CA', label: 'Julian, CA' },
  { value: 'Juliette_GA', label: 'Juliette, GA' },
  { value: 'Juliustown_NJ', label: 'Juliustown, NJ' },
  { value: 'Jumping Branch_WV', label: 'Jumping Branch, WV' },
  { value: 'Juncos_PR', label: 'Juncos, PR' },
  { value: 'Junction_IL', label: 'Junction, IL' },
  { value: 'Junction_TX', label: 'Junction, TX' },
  { value: 'Junction_UT', label: 'Junction, UT' },
  { value: 'Junction City_GA', label: 'Junction City, GA' },
  { value: 'Junction City_KY', label: 'Junction City, KY' },
  { value: 'Junction City_OH', label: 'Junction City, OH' },
  { value: 'Junction City_WI', label: 'Junction City, WI' },
  { value: 'Junction City_KS', label: 'Junction City, KS' },
  { value: 'Junction City_AR', label: 'Junction City, AR' },
  { value: 'Junction City_CA', label: 'Junction City, CA' },
  { value: 'Junction City_OR', label: 'Junction City, OR' },
  { value: 'June Lake_CA', label: 'June Lake, CA' },
  { value: 'Juneau_WI', label: 'Juneau, WI' },
  { value: 'Juneau_AK', label: 'Juneau, AK' },
  { value: 'Junedale_PA', label: 'Junedale, PA' },
  { value: 'Juniata_NE', label: 'Juniata, NE' },
  { value: 'Junior_WV', label: 'Junior, WV' },
  { value: 'Juntura_OR', label: 'Juntura, OR' },
  { value: 'Jupiter_FL', label: 'Jupiter, FL' },
  { value: 'Jurupa Valley_CA', label: 'Jurupa Valley, CA' },
  { value: 'Justice_WV', label: 'Justice, WV' },
  { value: 'Justice_IL', label: 'Justice, IL' },
  { value: 'Justiceburg_TX', label: 'Justiceburg, TX' },
  { value: 'Justin_TX', label: 'Justin, TX' },
  { value: 'Kaaawa_HI', label: 'Kaaawa, HI' },
  { value: 'Kabetogama_MN', label: 'Kabetogama, MN' },
  { value: 'Kadoka_SD', label: 'Kadoka, SD' },
  { value: 'Kahlotus_WA', label: 'Kahlotus, WA' },
  { value: 'Kahoka_MO', label: 'Kahoka, MO' },
  { value: 'Kahuku_HI', label: 'Kahuku, HI' },
  { value: 'Kahului_HI', label: 'Kahului, HI' },
  { value: 'Kaibeto_AZ', label: 'Kaibeto, AZ' },
  { value: 'Kailua_HI', label: 'Kailua, HI' },
  { value: 'Kailua Kona_HI', label: 'Kailua Kona, HI' },
  { value: 'Kaiser_MO', label: 'Kaiser, MO' },
  { value: 'Kake_AK', label: 'Kake, AK' },
  { value: 'Kaktovik_AK', label: 'Kaktovik, AK' },
  { value: 'Kalaheo_HI', label: 'Kalaheo, HI' },
  { value: 'Kalama_WA', label: 'Kalama, WA' },
  { value: 'Kalamazoo_MI', label: 'Kalamazoo, MI' },
  { value: 'Kalaupapa_HI', label: 'Kalaupapa, HI' },
  { value: 'Kaleva_MI', label: 'Kaleva, MI' },
  { value: 'Kalida_OH', label: 'Kalida, OH' },
  { value: 'Kalispell_MT', label: 'Kalispell, MT' },
  { value: 'Kalkaska_MI', label: 'Kalkaska, MI' },
  { value: 'Kalona_IA', label: 'Kalona, IA' },
  { value: 'Kalskag_AK', label: 'Kalskag, AK' },
  { value: 'Kaltag_AK', label: 'Kaltag, AK' },
  { value: 'Kamas_UT', label: 'Kamas, UT' },
  { value: 'Kamiah_ID', label: 'Kamiah, ID' },
  { value: 'Kampsville_IL', label: 'Kampsville, IL' },
  { value: 'Kamrar_IA', label: 'Kamrar, IA' },
  { value: 'Kamuela_HI', label: 'Kamuela, HI' },
  { value: 'Kanab_UT', label: 'Kanab, UT' },
  { value: 'Kanaranzi_MN', label: 'Kanaranzi, MN' },
  { value: 'Kanarraville_UT', label: 'Kanarraville, UT' },
  { value: 'Kanawha_IA', label: 'Kanawha, IA' },
  { value: 'Kanawha Falls_WV', label: 'Kanawha Falls, WV' },
  { value: 'Kanawha Head_WV', label: 'Kanawha Head, WV' },
  { value: 'Kandiyohi_MN', label: 'Kandiyohi, MN' },
  { value: 'Kane_PA', label: 'Kane, PA' },
  { value: 'Kane_IL', label: 'Kane, IL' },
  { value: 'Kaneohe_HI', label: 'Kaneohe, HI' },
  { value: 'Kaneville_IL', label: 'Kaneville, IL' },
  { value: 'Kankakee_IL', label: 'Kankakee, IL' },
  { value: 'Kannapolis_NC', label: 'Kannapolis, NC' },
  { value: 'Kanona_NY', label: 'Kanona, NY' },
  { value: 'Kanopolis_KS', label: 'Kanopolis, KS' },
  { value: 'Kanorado_KS', label: 'Kanorado, KS' },
  { value: 'Kanosh_UT', label: 'Kanosh, UT' },
  { value: 'Kansas_AL', label: 'Kansas, AL' },
  { value: 'Kansas_OH', label: 'Kansas, OH' },
  { value: 'Kansas_IL', label: 'Kansas, IL' },
  { value: 'Kansas_OK', label: 'Kansas, OK' },
  { value: 'Kansas City_MO', label: 'Kansas City, MO' },
  { value: 'Kansas City_KS', label: 'Kansas City, KS' },
  { value: 'Kansasville_WI', label: 'Kansasville, WI' },
  { value: 'Kapaa_HI', label: 'Kapaa, HI' },
  { value: 'Kapaau_HI', label: 'Kapaau, HI' },
  { value: 'Kaplan_LA', label: 'Kaplan, LA' },
  { value: 'Kapolei_HI', label: 'Kapolei, HI' },
  { value: 'Karlsruhe_ND', label: 'Karlsruhe, ND' },
  { value: 'Karlstad_MN', label: 'Karlstad, MN' },
  { value: 'Karluk_AK', label: 'Karluk, AK' },
  { value: 'Karnack_TX', label: 'Karnack, TX' },
  { value: 'Karnak_IL', label: 'Karnak, IL' },
  { value: 'Karnes City_TX', label: 'Karnes City, TX' },
  { value: 'Karns City_PA', label: 'Karns City, PA' },
  { value: 'Karthaus_PA', label: 'Karthaus, PA' },
  { value: 'Karval_CO', label: 'Karval, CO' },
  { value: 'Kasbeer_IL', label: 'Kasbeer, IL' },
  { value: 'Kasigluk_AK', label: 'Kasigluk, AK' },
  { value: 'Kasilof_AK', label: 'Kasilof, AK' },
  { value: 'Kasota_MN', label: 'Kasota, MN' },
  { value: 'Kasson_MN', label: 'Kasson, MN' },
  { value: 'Kathleen_GA', label: 'Kathleen, GA' },
  { value: 'Kathleen_FL', label: 'Kathleen, FL' },
  { value: 'Kathryn_ND', label: 'Kathryn, ND' },
  { value: 'Katonah_NY', label: 'Katonah, NY' },
  { value: 'Kattskill Bay_NY', label: 'Kattskill Bay, NY' },
  { value: 'Katy_TX', label: 'Katy, TX' },
  { value: 'Kaufman_TX', label: 'Kaufman, TX' },
  { value: 'Kaukauna_WI', label: 'Kaukauna, WI' },
  { value: 'Kaumakani_HI', label: 'Kaumakani, HI' },
  { value: 'Kaunakakai_HI', label: 'Kaunakakai, HI' },
  { value: 'Kauneonga Lake_NY', label: 'Kauneonga Lake, NY' },
  { value: 'Kaw City_OK', label: 'Kaw City, OK' },
  { value: 'Kaweah_CA', label: 'Kaweah, CA' },
  { value: 'Kawkawlin_MI', label: 'Kawkawlin, MI' },
  { value: 'Kaycee_WY', label: 'Kaycee, WY' },
  { value: 'Kayenta_AZ', label: 'Kayenta, AZ' },
  { value: 'Kaylor_SD', label: 'Kaylor, SD' },
  { value: 'Kaysville_UT', label: 'Kaysville, UT' },
  { value: 'Keaau_HI', label: 'Keaau, HI' },
  { value: 'Kealakekua_HI', label: 'Kealakekua, HI' },
  { value: 'Kealia_HI', label: 'Kealia, HI' },
  { value: 'Keams Canyon_AZ', label: 'Keams Canyon, AZ' },
  { value: 'Keansburg_NJ', label: 'Keansburg, NJ' },
  { value: 'Kearney_MO', label: 'Kearney, MO' },
  { value: 'Kearney_NE', label: 'Kearney, NE' },
  { value: 'Kearneysville_WV', label: 'Kearneysville, WV' },
  { value: 'Kearny_NJ', label: 'Kearny, NJ' },
  { value: 'Kearny_AZ', label: 'Kearny, AZ' },
  { value: 'Kearsarge_NH', label: 'Kearsarge, NH' },
  { value: 'Kearsarge_MI', label: 'Kearsarge, MI' },
  { value: 'Keasbey_NJ', label: 'Keasbey, NJ' },
  { value: 'Keatchie_LA', label: 'Keatchie, LA' },
  { value: 'Keaton_KY', label: 'Keaton, KY' },
  { value: 'Keavy_KY', label: 'Keavy, KY' },
  { value: 'Kechi_KS', label: 'Kechi, KS' },
  { value: 'Keedysville_MD', label: 'Keedysville, MD' },
  { value: 'Keego Harbor_MI', label: 'Keego Harbor, MI' },
  { value: 'Keeler_CA', label: 'Keeler, CA' },
  { value: 'Keeling_VA', label: 'Keeling, VA' },
  { value: 'Keene_NH', label: 'Keene, NH' },
  { value: 'Keene_NY', label: 'Keene, NY' },
  { value: 'Keene_VA', label: 'Keene, VA' },
  { value: 'Keene_KY', label: 'Keene, KY' },
  { value: 'Keene_ND', label: 'Keene, ND' },
  { value: 'Keene_TX', label: 'Keene, TX' },
  { value: 'Keene_CA', label: 'Keene, CA' },
  { value: 'Keene Valley_NY', label: 'Keene Valley, NY' },
  { value: 'Keenes_IL', label: 'Keenes, IL' },
  { value: 'Keenesburg_CO', label: 'Keenesburg, CO' },
  { value: 'Keensburg_IL', label: 'Keensburg, IL' },
  { value: 'Keeseville_NY', label: 'Keeseville, NY' },
  { value: 'Keewatin_MN', label: 'Keewatin, MN' },
  { value: 'Keezletown_VA', label: 'Keezletown, VA' },
  { value: 'Kegley_WV', label: 'Kegley, WV' },
  { value: 'Keiser_AR', label: 'Keiser, AR' },
  { value: 'Keisterville_PA', label: 'Keisterville, PA' },
  { value: 'Keithsburg_IL', label: 'Keithsburg, IL' },
  { value: 'Keithville_LA', label: 'Keithville, LA' },
  { value: 'Kekaha_HI', label: 'Kekaha, HI' },
  { value: 'Kelayres_PA', label: 'Kelayres, PA' },
  { value: 'Keldron_SD', label: 'Keldron, SD' },
  { value: 'Kelford_NC', label: 'Kelford, NC' },
  { value: 'Kell_IL', label: 'Kell, IL' },
  { value: 'Keller_VA', label: 'Keller, VA' },
  { value: 'Keller_TX', label: 'Keller, TX' },
  { value: 'Keller_WA', label: 'Keller, WA' },
  { value: 'Kellerton_IA', label: 'Kellerton, IA' },
  { value: 'Kelley_IA', label: 'Kelley, IA' },
  { value: 'Kelleys Island_OH', label: 'Kelleys Island, OH' },
  { value: 'Kelliher_MN', label: 'Kelliher, MN' },
  { value: 'Kellnersville_WI', label: 'Kellnersville, WI' },
  { value: 'Kellogg_IA', label: 'Kellogg, IA' },
  { value: 'Kellogg_MN', label: 'Kellogg, MN' },
  { value: 'Kellogg_ID', label: 'Kellogg, ID' },
  { value: 'Kelly_NC', label: 'Kelly, NC' },
  { value: 'Kelly_LA', label: 'Kelly, LA' },
  { value: 'Kelly_WY', label: 'Kelly, WY' },
  { value: 'Kellyton_AL', label: 'Kellyton, AL' },
  { value: 'Kellyville_OK', label: 'Kellyville, OK' },
  { value: 'Kelseyville_CA', label: 'Kelseyville, CA' },
  { value: 'Kelso_TN', label: 'Kelso, TN' },
  { value: 'Kelso_MO', label: 'Kelso, MO' },
  { value: 'Kelso_WA', label: 'Kelso, WA' },
  { value: 'Kemah_TX', label: 'Kemah, TX' },
  { value: 'Kemmerer_WY', label: 'Kemmerer, WY' },
  { value: 'Kemp_OK', label: 'Kemp, OK' },
  { value: 'Kemp_TX', label: 'Kemp, TX' },
  { value: 'Kempner_TX', label: 'Kempner, TX' },
  { value: 'Kempton_PA', label: 'Kempton, PA' },
  { value: 'Kempton_IN', label: 'Kempton, IN' },
  { value: 'Kempton_IL', label: 'Kempton, IL' },
  { value: 'Kenai_AK', label: 'Kenai, AK' },
  { value: 'Kenansville_NC', label: 'Kenansville, NC' },
  { value: 'Kenansville_FL', label: 'Kenansville, FL' },
  { value: 'Kenbridge_VA', label: 'Kenbridge, VA' },
  { value: 'Kendalia_TX', label: 'Kendalia, TX' },
  { value: 'Kendall_NY', label: 'Kendall, NY' },
  { value: 'Kendall_WI', label: 'Kendall, WI' },
  { value: 'Kendall_KS', label: 'Kendall, KS' },
  { value: 'Kendall Park_NJ', label: 'Kendall Park, NJ' },
  { value: 'Kendallville_IN', label: 'Kendallville, IN' },
  { value: 'Kendleton_TX', label: 'Kendleton, TX' },
  { value: 'Kendrick_ID', label: 'Kendrick, ID' },
  { value: 'Kenduskeag_ME', label: 'Kenduskeag, ME' },
  { value: 'Kenedy_TX', label: 'Kenedy, TX' },
  { value: 'Kenefic_OK', label: 'Kenefic, OK' },
  { value: 'Kenesaw_NE', label: 'Kenesaw, NE' },
  { value: 'Kenilworth_NJ', label: 'Kenilworth, NJ' },
  { value: 'Kenilworth_IL', label: 'Kenilworth, IL' },
  { value: 'Kenilworth_UT', label: 'Kenilworth, UT' },
  { value: 'Kenly_NC', label: 'Kenly, NC' },
  { value: 'Kenmare_ND', label: 'Kenmare, ND' },
  { value: 'Kenmore_WA', label: 'Kenmore, WA' },
  { value: 'Kenna_WV', label: 'Kenna, WV' },
  { value: 'Kennan_WI', label: 'Kennan, WI' },
  { value: 'Kennard_IN', label: 'Kennard, IN' },
  { value: 'Kennard_NE', label: 'Kennard, NE' },
  { value: 'Kennard_TX', label: 'Kennard, TX' },
  { value: 'Kennebec_SD', label: 'Kennebec, SD' },
  { value: 'Kennebunk_ME', label: 'Kennebunk, ME' },
  { value: 'Kennebunkport_ME', label: 'Kennebunkport, ME' },
  { value: 'Kennedale_TX', label: 'Kennedale, TX' },
  { value: 'Kennedy_NY', label: 'Kennedy, NY' },
  { value: 'Kennedy_AL', label: 'Kennedy, AL' },
  { value: 'Kennedy_MN', label: 'Kennedy, MN' },
  { value: 'Kennedyville_MD', label: 'Kennedyville, MD' },
  { value: 'Kenner_LA', label: 'Kenner, LA' },
  { value: 'Kennerdell_PA', label: 'Kennerdell, PA' },
  { value: 'Kennesaw_GA', label: 'Kennesaw, GA' },
  { value: 'Kenneth_MN', label: 'Kenneth, MN' },
  { value: 'Kennett_MO', label: 'Kennett, MO' },
  { value: 'Kennett Square_PA', label: 'Kennett Square, PA' },
  { value: 'Kennewick_WA', label: 'Kennewick, WA' },
  { value: 'Kenney_IL', label: 'Kenney, IL' },
  { value: 'Keno_OR', label: 'Keno, OR' },
  { value: 'Kenosha_WI', label: 'Kenosha, WI' },
  { value: 'Kenova_WV', label: 'Kenova, WV' },
  { value: 'Kenoza Lake_NY', label: 'Kenoza Lake, NY' },
  { value: 'Kensal_ND', label: 'Kensal, ND' },
  { value: 'Kensett_IA', label: 'Kensett, IA' },
  { value: 'Kensett_AR', label: 'Kensett, AR' },
  { value: 'Kensington_MD', label: 'Kensington, MD' },
  { value: 'Kensington_OH', label: 'Kensington, OH' },
  { value: 'Kensington_MN', label: 'Kensington, MN' },
  { value: 'Kensington_KS', label: 'Kensington, KS' },
  { value: 'Kent_CT', label: 'Kent, CT' },
  { value: 'Kent_NY', label: 'Kent, NY' },
  { value: 'Kent_PA', label: 'Kent, PA' },
  { value: 'Kent_OH', label: 'Kent, OH' },
  { value: 'Kent_MN', label: 'Kent, MN' },
  { value: 'Kent_IL', label: 'Kent, IL' },
  { value: 'Kent_OR', label: 'Kent, OR' },
  { value: 'Kent_WA', label: 'Kent, WA' },
  { value: 'Kent City_MI', label: 'Kent City, MI' },
  { value: 'Kentland_IN', label: 'Kentland, IN' },
  { value: 'Kenton_DE', label: 'Kenton, DE' },
  { value: 'Kenton_TN', label: 'Kenton, TN' },
  { value: 'Kenton_OH', label: 'Kenton, OH' },
  { value: 'Kenton_OK', label: 'Kenton, OK' },
  { value: 'Kents Hill_ME', label: 'Kents Hill, ME' },
  { value: 'Kents Store_VA', label: 'Kents Store, VA' },
  { value: 'Kentwood_LA', label: 'Kentwood, LA' },
  { value: 'Kenvil_NJ', label: 'Kenvil, NJ' },
  { value: 'Kenvir_KY', label: 'Kenvir, KY' },
  { value: 'Kenwood_CA', label: 'Kenwood, CA' },
  { value: 'Kenyon_RI', label: 'Kenyon, RI' },
  { value: 'Kenyon_MN', label: 'Kenyon, MN' },
  { value: 'Keo_AR', label: 'Keo, AR' },
  { value: 'Keokee_VA', label: 'Keokee, VA' },
  { value: 'Keokuk_IA', label: 'Keokuk, IA' },
  { value: 'Keosauqua_IA', label: 'Keosauqua, IA' },
  { value: 'Keota_IA', label: 'Keota, IA' },
  { value: 'Keota_OK', label: 'Keota, OK' },
  { value: 'Kerby_OR', label: 'Kerby, OR' },
  { value: 'Kerens_WV', label: 'Kerens, WV' },
  { value: 'Kerens_TX', label: 'Kerens, TX' },
  { value: 'Kerhonkson_NY', label: 'Kerhonkson, NY' },
  { value: 'Kerkhoven_MN', label: 'Kerkhoven, MN' },
  { value: 'Kerman_CA', label: 'Kerman, CA' },
  { value: 'Kermit_WV', label: 'Kermit, WV' },
  { value: 'Kermit_TX', label: 'Kermit, TX' },
  { value: 'Kernersville_NC', label: 'Kernersville, NC' },
  { value: 'Kernville_CA', label: 'Kernville, CA' },
  { value: 'Kerrick_MN', label: 'Kerrick, MN' },
  { value: 'Kerrick_TX', label: 'Kerrick, TX' },
  { value: 'Kerrville_TX', label: 'Kerrville, TX' },
  { value: 'Kersey_PA', label: 'Kersey, PA' },
  { value: 'Kersey_CO', label: 'Kersey, CO' },
  { value: 'Kershaw_SC', label: 'Kershaw, SC' },
  { value: 'Keshena_WI', label: 'Keshena, WI' },
  { value: 'Kesley_IA', label: 'Kesley, IA' },
  { value: 'Keswick_VA', label: 'Keswick, VA' },
  { value: 'Keswick_IA', label: 'Keswick, IA' },
  { value: 'Ketchikan_AK', label: 'Ketchikan, AK' },
  { value: 'Ketchum_OK', label: 'Ketchum, OK' },
  { value: 'Ketchum_ID', label: 'Ketchum, ID' },
  { value: 'Kettle Falls_WA', label: 'Kettle Falls, WA' },
  { value: 'Kettle Island_KY', label: 'Kettle Island, KY' },
  { value: 'Kettle River_MN', label: 'Kettle River, MN' },
  { value: 'Kettleman City_CA', label: 'Kettleman City, CA' },
  { value: 'Kettlersville_OH', label: 'Kettlersville, OH' },
  { value: 'Keuka Park_NY', label: 'Keuka Park, NY' },
  { value: 'Kevil_KY', label: 'Kevil, KY' },
  { value: 'Kevin_MT', label: 'Kevin, MT' },
  { value: 'Kew Gardens_NY', label: 'Kew Gardens, NY' },
  { value: 'Kewadin_MI', label: 'Kewadin, MI' },
  { value: 'Kewanee_IL', label: 'Kewanee, IL' },
  { value: 'Kewanee_MO', label: 'Kewanee, MO' },
  { value: 'Kewanna_IN', label: 'Kewanna, IN' },
  { value: 'Kewaskum_WI', label: 'Kewaskum, WI' },
  { value: 'Kewaunee_WI', label: 'Kewaunee, WI' },
  { value: 'Key Biscayne_FL', label: 'Key Biscayne, FL' },
  { value: 'Key Colony Beach_FL', label: 'Key Colony Beach, FL' },
  { value: 'Key Largo_FL', label: 'Key Largo, FL' },
  { value: 'Key West_FL', label: 'Key West, FL' },
  { value: 'Keyes_OK', label: 'Keyes, OK' },
  { value: 'Keyes_CA', label: 'Keyes, CA' },
  { value: 'Keyesport_IL', label: 'Keyesport, IL' },
  { value: 'Keymar_MD', label: 'Keymar, MD' },
  { value: 'Keyport_NJ', label: 'Keyport, NJ' },
  { value: 'Keyport_WA', label: 'Keyport, WA' },
  { value: 'Keyser_WV', label: 'Keyser, WV' },
  { value: 'Keystone_IN', label: 'Keystone, IN' },
  { value: 'Keystone_IA', label: 'Keystone, IA' },
  { value: 'Keystone_SD', label: 'Keystone, SD' },
  { value: 'Keystone_NE', label: 'Keystone, NE' },
  { value: 'Keystone Heights_FL', label: 'Keystone Heights, FL' },
  { value: 'Keysville_VA', label: 'Keysville, VA' },
  { value: 'Keysville_GA', label: 'Keysville, GA' },
  { value: 'Keytesville_MO', label: 'Keytesville, MO' },
  { value: 'Kiahsville_WV', label: 'Kiahsville, WV' },
  { value: 'Kiamesha Lake_NY', label: 'Kiamesha Lake, NY' },
  { value: 'Kiana_AK', label: 'Kiana, AK' },
  { value: 'Kidder_MO', label: 'Kidder, MO' },
  { value: 'Kiefer_OK', label: 'Kiefer, OK' },
  { value: 'Kiel_WI', label: 'Kiel, WI' },
  { value: 'Kieler_WI', label: 'Kieler, WI' },
  { value: 'Kiester_MN', label: 'Kiester, MN' },
  { value: 'Kihei_HI', label: 'Kihei, HI' },
  { value: 'Kila_MT', label: 'Kila, MT' },
  { value: 'Kilauea_HI', label: 'Kilauea, HI' },
  { value: 'Kilbourne_OH', label: 'Kilbourne, OH' },
  { value: 'Kilbourne_IL', label: 'Kilbourne, IL' },
  { value: 'Kilbourne_LA', label: 'Kilbourne, LA' },
  { value: 'Kildare_TX', label: 'Kildare, TX' },
  { value: 'Kilgore_NE', label: 'Kilgore, NE' },
  { value: 'Kilgore_TX', label: 'Kilgore, TX' },
  { value: 'Kilkenny_MN', label: 'Kilkenny, MN' },
  { value: 'Kill Buck_NY', label: 'Kill Buck, NY' },
  { value: 'Kill Devil Hills_NC', label: 'Kill Devil Hills, NC' },
  { value: 'Killbuck_OH', label: 'Killbuck, OH' },
  { value: 'Killdeer_ND', label: 'Killdeer, ND' },
  { value: 'Killduff_IA', label: 'Killduff, IA' },
  { value: 'Killeen_TX', label: 'Killeen, TX' },
  { value: 'Killen_AL', label: 'Killen, AL' },
  { value: 'Killington_VT', label: 'Killington, VT' },
  { value: 'Killingworth_CT', label: 'Killingworth, CT' },
  { value: 'Kilmarnock_VA', label: 'Kilmarnock, VA' },
  { value: 'Kilmichael_MS', label: 'Kilmichael, MS' },
  { value: 'Kiln_MS', label: 'Kiln, MS' },
  { value: 'Kim_CO', label: 'Kim, CO' },
  { value: 'Kimball_WV', label: 'Kimball, WV' },
  { value: 'Kimball_MN', label: 'Kimball, MN' },
  { value: 'Kimball_SD', label: 'Kimball, SD' },
  { value: 'Kimball_NE', label: 'Kimball, NE' },
  { value: 'Kimballton_IA', label: 'Kimballton, IA' },
  { value: 'Kimberling City_MO', label: 'Kimberling City, MO' },
  { value: 'Kimberly_WV', label: 'Kimberly, WV' },
  { value: 'Kimberly_AL', label: 'Kimberly, AL' },
  { value: 'Kimberly_WI', label: 'Kimberly, WI' },
  { value: 'Kimberly_ID', label: 'Kimberly, ID' },
  { value: 'Kimberly_OR', label: 'Kimberly, OR' },
  { value: 'Kimberton_PA', label: 'Kimberton, PA' },
  { value: 'Kimbolton_OH', label: 'Kimbolton, OH' },
  { value: 'Kimmell_IN', label: 'Kimmell, IN' },
  { value: 'Kimmswick_MO', label: 'Kimmswick, MO' },
  { value: 'Kimper_KY', label: 'Kimper, KY' },
  { value: 'Kinards_SC', label: 'Kinards, SC' },
  { value: 'Kincaid_WV', label: 'Kincaid, WV' },
  { value: 'Kincaid_IL', label: 'Kincaid, IL' },
  { value: 'Kincaid_KS', label: 'Kincaid, KS' },
  { value: 'Kincheloe_MI', label: 'Kincheloe, MI' },
  { value: 'Kinde_MI', label: 'Kinde, MI' },
  { value: 'Kinder_LA', label: 'Kinder, LA' },
  { value: 'Kinderhook_NY', label: 'Kinderhook, NY' },
  { value: 'Kinderhook_IL', label: 'Kinderhook, IL' },
  { value: 'Kindred_ND', label: 'Kindred, ND' },
  { value: 'King_NC', label: 'King, NC' },
  { value: 'King_WI', label: 'King, WI' },
  {
    value: 'King And Queen Court House_VA',
    label: 'King And Queen Court House, VA',
  },
  { value: 'King City_MO', label: 'King City, MO' },
  { value: 'King City_CA', label: 'King City, CA' },
  { value: 'King Cove_AK', label: 'King Cove, AK' },
  { value: 'King Ferry_NY', label: 'King Ferry, NY' },
  { value: 'King George_VA', label: 'King George, VA' },
  { value: 'King Hill_ID', label: 'King Hill, ID' },
  { value: 'King Of Prussia_PA', label: 'King Of Prussia, PA' },
  { value: 'King Salmon_AK', label: 'King Salmon, AK' },
  { value: 'King William_VA', label: 'King William, VA' },
  { value: 'Kingdom City_MO', label: 'Kingdom City, MO' },
  { value: 'Kingfield_ME', label: 'Kingfield, ME' },
  { value: 'Kingfisher_OK', label: 'Kingfisher, OK' },
  { value: 'Kingman_ME', label: 'Kingman, ME' },
  { value: 'Kingman_IN', label: 'Kingman, IN' },
  { value: 'Kingman_KS', label: 'Kingman, KS' },
  { value: 'Kingman_AZ', label: 'Kingman, AZ' },
  { value: 'Kings Bay_GA', label: 'Kings Bay, GA' },
  { value: 'Kings Beach_CA', label: 'Kings Beach, CA' },
  {
    value: 'Kings Canyon National Pk_CA',
    label: 'Kings Canyon National Pk, CA',
  },
  { value: 'Kings Mills_OH', label: 'Kings Mills, OH' },
  { value: 'Kings Mountain_NC', label: 'Kings Mountain, NC' },
  { value: 'Kings Mountain_KY', label: 'Kings Mountain, KY' },
  { value: 'Kings Park_NY', label: 'Kings Park, NY' },
  { value: 'Kingsburg_CA', label: 'Kingsburg, CA' },
  { value: 'Kingsbury_IN', label: 'Kingsbury, IN' },
  { value: 'Kingsbury_TX', label: 'Kingsbury, TX' },
  { value: 'Kingsford_MI', label: 'Kingsford, MI' },
  { value: 'Kingsford Heights_IN', label: 'Kingsford Heights, IN' },
  { value: 'Kingshill_VI', label: 'Kingshill, VI' },
  { value: 'Kingsland_GA', label: 'Kingsland, GA' },
  { value: 'Kingsland_AR', label: 'Kingsland, AR' },
  { value: 'Kingsland_TX', label: 'Kingsland, TX' },
  { value: 'Kingsley_PA', label: 'Kingsley, PA' },
  { value: 'Kingsley_MI', label: 'Kingsley, MI' },
  { value: 'Kingsley_IA', label: 'Kingsley, IA' },
  { value: 'Kingsport_TN', label: 'Kingsport, TN' },
  { value: 'Kingston_MA', label: 'Kingston, MA' },
  { value: 'Kingston_RI', label: 'Kingston, RI' },
  { value: 'Kingston_NH', label: 'Kingston, NH' },
  { value: 'Kingston_NJ', label: 'Kingston, NJ' },
  { value: 'Kingston_NY', label: 'Kingston, NY' },
  { value: 'Kingston_PA', label: 'Kingston, PA' },
  { value: 'Kingston_GA', label: 'Kingston, GA' },
  { value: 'Kingston_TN', label: 'Kingston, TN' },
  { value: 'Kingston_OH', label: 'Kingston, OH' },
  { value: 'Kingston_MI', label: 'Kingston, MI' },
  { value: 'Kingston_WI', label: 'Kingston, WI' },
  { value: 'Kingston_IL', label: 'Kingston, IL' },
  { value: 'Kingston_MO', label: 'Kingston, MO' },
  { value: 'Kingston_AR', label: 'Kingston, AR' },
  { value: 'Kingston_OK', label: 'Kingston, OK' },
  { value: 'Kingston_ID', label: 'Kingston, ID' },
  { value: 'Kingston_UT', label: 'Kingston, UT' },
  { value: 'Kingston_WA', label: 'Kingston, WA' },
  { value: 'Kingston Mines_IL', label: 'Kingston Mines, IL' },
  { value: 'Kingston Springs_TN', label: 'Kingston Springs, TN' },
  { value: 'Kingstree_SC', label: 'Kingstree, SC' },
  { value: 'Kingsville_MD', label: 'Kingsville, MD' },
  { value: 'Kingsville_OH', label: 'Kingsville, OH' },
  { value: 'Kingsville_MO', label: 'Kingsville, MO' },
  { value: 'Kingsville_TX', label: 'Kingsville, TX' },
  { value: 'Kingwood_WV', label: 'Kingwood, WV' },
  { value: 'Kingwood_TX', label: 'Kingwood, TX' },
  { value: 'Kinmundy_IL', label: 'Kinmundy, IL' },
  { value: 'Kinnear_WY', label: 'Kinnear, WY' },
  { value: 'Kinney_MN', label: 'Kinney, MN' },
  { value: 'Kinross_MI', label: 'Kinross, MI' },
  { value: 'Kinsale_VA', label: 'Kinsale, VA' },
  { value: 'Kinsey_MT', label: 'Kinsey, MT' },
  { value: 'Kinsley_KS', label: 'Kinsley, KS' },
  { value: 'Kinsman_OH', label: 'Kinsman, OH' },
  { value: 'Kinsman_IL', label: 'Kinsman, IL' },
  { value: 'Kinston_NC', label: 'Kinston, NC' },
  { value: 'Kinston_AL', label: 'Kinston, AL' },
  { value: 'Kinta_OK', label: 'Kinta, OK' },
  { value: 'Kintnersville_PA', label: 'Kintnersville, PA' },
  { value: 'Kintyre_ND', label: 'Kintyre, ND' },
  { value: 'Kinzers_PA', label: 'Kinzers, PA' },
  { value: 'Kiowa_KS', label: 'Kiowa, KS' },
  { value: 'Kiowa_OK', label: 'Kiowa, OK' },
  { value: 'Kiowa_CO', label: 'Kiowa, CO' },
  { value: 'Kipling_OH', label: 'Kipling, OH' },
  { value: 'Kipnuk_AK', label: 'Kipnuk, AK' },
  { value: 'Kipton_OH', label: 'Kipton, OH' },
  { value: 'Kirby_OH', label: 'Kirby, OH' },
  { value: 'Kirby_AR', label: 'Kirby, AR' },
  { value: 'Kirby_WY', label: 'Kirby, WY' },
  { value: 'Kirbyville_MO', label: 'Kirbyville, MO' },
  { value: 'Kirbyville_TX', label: 'Kirbyville, TX' },
  { value: 'Kirk_CO', label: 'Kirk, CO' },
  { value: 'Kirkersville_OH', label: 'Kirkersville, OH' },
  { value: 'Kirkland_IL', label: 'Kirkland, IL' },
  { value: 'Kirkland_AZ', label: 'Kirkland, AZ' },
  { value: 'Kirkland_WA', label: 'Kirkland, WA' },
  { value: 'Kirklin_IN', label: 'Kirklin, IN' },
  { value: 'Kirkman_IA', label: 'Kirkman, IA' },
  { value: 'Kirksey_KY', label: 'Kirksey, KY' },
  { value: 'Kirksville_MO', label: 'Kirksville, MO' },
  { value: 'Kirkville_NY', label: 'Kirkville, NY' },
  { value: 'Kirkville_IA', label: 'Kirkville, IA' },
  { value: 'Kirkwood_NY', label: 'Kirkwood, NY' },
  { value: 'Kirkwood_PA', label: 'Kirkwood, PA' },
  { value: 'Kirkwood_IL', label: 'Kirkwood, IL' },
  { value: 'Kirkwood_CA', label: 'Kirkwood, CA' },
  { value: 'Kiron_IA', label: 'Kiron, IA' },
  { value: 'Kirtland_NM', label: 'Kirtland, NM' },
  { value: 'Kirtland Afb_NM', label: 'Kirtland Afb, NM' },
  { value: 'Kirvin_TX', label: 'Kirvin, TX' },
  { value: 'Kirwin_KS', label: 'Kirwin, KS' },
  { value: 'Kismet_KS', label: 'Kismet, KS' },
  { value: 'Kissee Mills_MO', label: 'Kissee Mills, MO' },
  { value: 'Kissimmee_FL', label: 'Kissimmee, FL' },
  { value: 'Kistler_WV', label: 'Kistler, WV' },
  { value: 'Kit Carson_CO', label: 'Kit Carson, CO' },
  { value: 'Kite_GA', label: 'Kite, GA' },
  { value: 'Kite_KY', label: 'Kite, KY' },
  { value: 'Kittanning_PA', label: 'Kittanning, PA' },
  { value: 'Kittery_ME', label: 'Kittery, ME' },
  { value: 'Kittery Point_ME', label: 'Kittery Point, ME' },
  { value: 'Kittitas_WA', label: 'Kittitas, WA' },
  { value: 'Kittredge_CO', label: 'Kittredge, CO' },
  { value: 'Kittrell_NC', label: 'Kittrell, NC' },
  { value: 'Kitts Hill_OH', label: 'Kitts Hill, OH' },
  { value: 'Kitty Hawk_NC', label: 'Kitty Hawk, NC' },
  { value: 'Kitzmiller_MD', label: 'Kitzmiller, MD' },
  { value: 'Kivalina_AK', label: 'Kivalina, AK' },
  { value: 'Klamath_CA', label: 'Klamath, CA' },
  { value: 'Klamath Falls_OR', label: 'Klamath Falls, OR' },
  { value: 'Klamath River_CA', label: 'Klamath River, CA' },
  { value: 'Klawock_AK', label: 'Klawock, AK' },
  { value: 'Kleinfeltersville_PA', label: 'Kleinfeltersville, PA' },
  { value: 'Klemme_IA', label: 'Klemme, IA' },
  { value: 'Klickitat_WA', label: 'Klickitat, WA' },
  { value: 'Klingerstown_PA', label: 'Klingerstown, PA' },
  { value: 'Klondike_TX', label: 'Klondike, TX' },
  { value: 'Knapp_WI', label: 'Knapp, WI' },
  { value: 'Kneeland_CA', label: 'Kneeland, CA' },
  { value: 'Knickerbocker_TX', label: 'Knickerbocker, TX' },
  { value: 'Knierim_IA', label: 'Knierim, IA' },
  { value: 'Knife River_MN', label: 'Knife River, MN' },
  { value: 'Knifley_KY', label: 'Knifley, KY' },
  { value: 'Knightdale_NC', label: 'Knightdale, NC' },
  { value: 'Knights Landing_CA', label: 'Knights Landing, CA' },
  { value: 'Knightsen_CA', label: 'Knightsen, CA' },
  { value: 'Knightstown_IN', label: 'Knightstown, IN' },
  { value: 'Knightsville_IN', label: 'Knightsville, IN' },
  { value: 'Knippa_TX', label: 'Knippa, TX' },
  { value: 'Knob Lick_KY', label: 'Knob Lick, KY' },
  { value: 'Knob Noster_MO', label: 'Knob Noster, MO' },
  { value: 'Knobel_AR', label: 'Knobel, AR' },
  { value: 'Knott_TX', label: 'Knott, TX' },
  { value: 'Knotts Island_NC', label: 'Knotts Island, NC' },
  { value: 'Knowlesville_NY', label: 'Knowlesville, NY' },
  { value: 'Knox_PA', label: 'Knox, PA' },
  { value: 'Knox_IN', label: 'Knox, IN' },
  { value: 'Knox_ND', label: 'Knox, ND' },
  { value: 'Knox City_MO', label: 'Knox City, MO' },
  { value: 'Knox City_TX', label: 'Knox City, TX' },
  { value: 'Knox Dale_PA', label: 'Knox Dale, PA' },
  { value: 'Knoxville_PA', label: 'Knoxville, PA' },
  { value: 'Knoxville_MD', label: 'Knoxville, MD' },
  { value: 'Knoxville_GA', label: 'Knoxville, GA' },
  { value: 'Knoxville_AL', label: 'Knoxville, AL' },
  { value: 'Knoxville_TN', label: 'Knoxville, TN' },
  { value: 'Knoxville_IA', label: 'Knoxville, IA' },
  { value: 'Knoxville_IL', label: 'Knoxville, IL' },
  { value: 'Knoxville_AR', label: 'Knoxville, AR' },
  { value: 'Kobuk_AK', label: 'Kobuk, AK' },
  { value: 'Kodak_TN', label: 'Kodak, TN' },
  { value: 'Kodiak_AK', label: 'Kodiak, AK' },
  { value: 'Koeltztown_MO', label: 'Koeltztown, MO' },
  { value: 'Kohler_WI', label: 'Kohler, WI' },
  { value: 'Kokomo_MS', label: 'Kokomo, MS' },
  { value: 'Kokomo_IN', label: 'Kokomo, IN' },
  { value: 'Koloa_HI', label: 'Koloa, HI' },
  { value: 'Konawa_OK', label: 'Konawa, OK' },
  { value: 'Koosharem_UT', label: 'Koosharem, UT' },
  { value: 'Kooskia_ID', label: 'Kooskia, ID' },
  { value: 'Kootenai_ID', label: 'Kootenai, ID' },
  { value: 'Koppel_PA', label: 'Koppel, PA' },
  { value: 'Kopperl_TX', label: 'Kopperl, TX' },
  { value: 'Kopperston_WV', label: 'Kopperston, WV' },
  { value: 'Korbel_CA', label: 'Korbel, CA' },
  { value: 'Kosciusko_MS', label: 'Kosciusko, MS' },
  { value: 'Koshkonong_MO', label: 'Koshkonong, MO' },
  { value: 'Kosse_TX', label: 'Kosse, TX' },
  { value: 'Kossuth_PA', label: 'Kossuth, PA' },
  { value: 'Kotlik_AK', label: 'Kotlik, AK' },
  { value: 'Kotzebue_AK', label: 'Kotzebue, AK' },
  { value: 'Kountze_TX', label: 'Kountze, TX' },
  { value: 'Kouts_IN', label: 'Kouts, IN' },
  { value: 'Koyuk_AK', label: 'Koyuk, AK' },
  { value: 'Koyukuk_AK', label: 'Koyukuk, AK' },
  { value: 'Krakow_WI', label: 'Krakow, WI' },
  { value: 'Kramer_ND', label: 'Kramer, ND' },
  { value: 'Kranzburg_SD', label: 'Kranzburg, SD' },
  { value: 'Krebs_OK', label: 'Krebs, OK' },
  { value: 'Kremlin_MT', label: 'Kremlin, MT' },
  { value: 'Kremlin_OK', label: 'Kremlin, OK' },
  { value: 'Kremmling_CO', label: 'Kremmling, CO' },
  { value: 'Kresgeville_PA', label: 'Kresgeville, PA' },
  { value: 'Kress_TX', label: 'Kress, TX' },
  { value: 'Krotz Springs_LA', label: 'Krotz Springs, LA' },
  { value: 'Krum_TX', label: 'Krum, TX' },
  { value: 'Krypton_KY', label: 'Krypton, KY' },
  { value: 'Kualapuu_HI', label: 'Kualapuu, HI' },
  { value: 'Kula_HI', label: 'Kula, HI' },
  { value: 'Kulm_ND', label: 'Kulm, ND' },
  { value: 'Kulpmont_PA', label: 'Kulpmont, PA' },
  { value: 'Kuna_ID', label: 'Kuna, ID' },
  { value: 'Kunia_HI', label: 'Kunia, HI' },
  { value: 'Kunkle_OH', label: 'Kunkle, OH' },
  { value: 'Kunkletown_PA', label: 'Kunkletown, PA' },
  { value: 'Kure Beach_NC', label: 'Kure Beach, NC' },
  { value: 'Kurtistown_HI', label: 'Kurtistown, HI' },
  { value: 'Kuttawa_KY', label: 'Kuttawa, KY' },
  { value: 'Kutztown_PA', label: 'Kutztown, PA' },
  { value: 'Kwethluk_AK', label: 'Kwethluk, AK' },
  { value: 'Kwigillingok_AK', label: 'Kwigillingok, AK' },
  { value: 'Kyburz_CA', label: 'Kyburz, CA' },
  { value: 'Kykotsmovi Village_AZ', label: 'Kykotsmovi Village, AZ' },
  { value: 'Kyle_WV', label: 'Kyle, WV' },
  { value: 'Kyle_SD', label: 'Kyle, SD' },
  { value: 'Kyle_TX', label: 'Kyle, TX' },
  { value: 'Kylertown_PA', label: 'Kylertown, PA' },
  { value: 'Kyles Ford_TN', label: 'Kyles Ford, TN' },
  { value: 'La Barge_WY', label: 'La Barge, WY' },
  { value: 'La Belle_PA', label: 'La Belle, PA' },
  { value: 'La Belle_MO', label: 'La Belle, MO' },
  { value: 'La Blanca_TX', label: 'La Blanca, TX' },
  { value: 'La Canada Flintridge_CA', label: 'La Canada Flintridge, CA' },
  { value: 'La Center_KY', label: 'La Center, KY' },
  { value: 'La Center_WA', label: 'La Center, WA' },
  { value: 'La Conner_WA', label: 'La Conner, WA' },
  { value: 'La Coste_TX', label: 'La Coste, TX' },
  { value: 'La Crescent_MN', label: 'La Crescent, MN' },
  { value: 'La Crescenta_CA', label: 'La Crescenta, CA' },
  { value: 'La Crosse_VA', label: 'La Crosse, VA' },
  { value: 'La Crosse_FL', label: 'La Crosse, FL' },
  { value: 'La Crosse_IN', label: 'La Crosse, IN' },
  { value: 'La Crosse_WI', label: 'La Crosse, WI' },
  { value: 'La Crosse_KS', label: 'La Crosse, KS' },
  { value: 'La Cygne_KS', label: 'La Cygne, KS' },
  { value: 'La Farge_WI', label: 'La Farge, WI' },
  { value: 'La Fargeville_NY', label: 'La Fargeville, NY' },
  { value: 'La Fayette_NY', label: 'La Fayette, NY' },
  { value: 'La Fayette_GA', label: 'La Fayette, GA' },
  { value: 'La Fayette_KY', label: 'La Fayette, KY' },
  { value: 'La Fayette_IL', label: 'La Fayette, IL' },
  { value: 'La Feria_TX', label: 'La Feria, TX' },
  { value: 'La Follette_TN', label: 'La Follette, TN' },
  { value: 'La Fontaine_IN', label: 'La Fontaine, IN' },
  { value: 'La Grande_OR', label: 'La Grande, OR' },
  { value: 'La Grange_NC', label: 'La Grange, NC' },
  { value: 'La Grange_TN', label: 'La Grange, TN' },
  { value: 'La Grange_KY', label: 'La Grange, KY' },
  { value: 'La Grange_IL', label: 'La Grange, IL' },
  { value: 'La Grange_MO', label: 'La Grange, MO' },
  { value: 'La Grange_AR', label: 'La Grange, AR' },
  { value: 'La Grange_TX', label: 'La Grange, TX' },
  { value: 'La Grange_CA', label: 'La Grange, CA' },
  { value: 'La Grange Park_IL', label: 'La Grange Park, IL' },
  { value: 'La Habra_CA', label: 'La Habra, CA' },
  { value: 'La Harpe_IL', label: 'La Harpe, IL' },
  { value: 'La Harpe_KS', label: 'La Harpe, KS' },
  { value: 'La Honda_CA', label: 'La Honda, CA' },
  { value: 'La Jara_CO', label: 'La Jara, CO' },
  { value: 'La Jara_NM', label: 'La Jara, NM' },
  { value: 'La Jolla_CA', label: 'La Jolla, CA' },
  { value: 'La Jose_PA', label: 'La Jose, PA' },
  { value: 'La Joya_TX', label: 'La Joya, TX' },
  { value: 'La Joya_NM', label: 'La Joya, NM' },
  { value: 'La Junta_CO', label: 'La Junta, CO' },
  { value: 'La Loma_NM', label: 'La Loma, NM' },
  { value: 'La Luz_NM', label: 'La Luz, NM' },
  { value: 'La Madera_NM', label: 'La Madera, NM' },
  { value: 'La Marque_TX', label: 'La Marque, TX' },
  { value: 'La Mesa_NM', label: 'La Mesa, NM' },
  { value: 'La Mesa_CA', label: 'La Mesa, CA' },
  { value: 'La Mirada_CA', label: 'La Mirada, CA' },
  { value: 'La Moille_IL', label: 'La Moille, IL' },
  { value: 'La Monte_MO', label: 'La Monte, MO' },
  { value: 'La Motte_IA', label: 'La Motte, IA' },
  { value: 'La Palma_CA', label: 'La Palma, CA' },
  { value: 'La Pine_OR', label: 'La Pine, OR' },
  { value: 'La Place_IL', label: 'La Place, IL' },
  { value: 'La Place_LA', label: 'La Place, LA' },
  { value: 'La Plata_PR', label: 'La Plata, PR' },
  { value: 'La Plata_MD', label: 'La Plata, MD' },
  { value: 'La Plata_MO', label: 'La Plata, MO' },
  { value: 'La Plata_NM', label: 'La Plata, NM' },
  { value: 'La Plume_PA', label: 'La Plume, PA' },
  { value: 'La Pointe_WI', label: 'La Pointe, WI' },
  { value: 'La Porte_IN', label: 'La Porte, IN' },
  { value: 'La Porte_TX', label: 'La Porte, TX' },
  { value: 'La Porte City_IA', label: 'La Porte City, IA' },
  { value: 'La Prairie_IL', label: 'La Prairie, IL' },
  { value: 'La Pryor_TX', label: 'La Pryor, TX' },
  { value: 'La Puente_CA', label: 'La Puente, CA' },
  { value: 'La Push_WA', label: 'La Push, WA' },
  { value: 'La Quinta_CA', label: 'La Quinta, CA' },
  { value: 'La Rose_IL', label: 'La Rose, IL' },
  { value: 'La Rue_OH', label: 'La Rue, OH' },
  { value: 'La Russell_MO', label: 'La Russell, MO' },
  { value: 'La Sal_UT', label: 'La Sal, UT' },
  { value: 'La Salle_MI', label: 'La Salle, MI' },
  { value: 'La Salle_MN', label: 'La Salle, MN' },
  { value: 'La Salle_IL', label: 'La Salle, IL' },
  { value: 'La Salle_TX', label: 'La Salle, TX' },
  { value: 'La Salle_CO', label: 'La Salle, CO' },
  { value: 'La Valle_WI', label: 'La Valle, WI' },
  { value: 'La Vergne_TN', label: 'La Vergne, TN' },
  { value: 'La Verkin_UT', label: 'La Verkin, UT' },
  { value: 'La Verne_CA', label: 'La Verne, CA' },
  { value: 'La Vernia_TX', label: 'La Vernia, TX' },
  { value: 'La Veta_CO', label: 'La Veta, CO' },
  { value: 'La Villa_TX', label: 'La Villa, TX' },
  { value: 'La Vista_NE', label: 'La Vista, NE' },
  { value: 'La Ward_TX', label: 'La Ward, TX' },
  { value: 'Labadie_MO', label: 'Labadie, MO' },
  { value: 'Labadieville_LA', label: 'Labadieville, LA' },
  { value: 'Labelle_FL', label: 'Labelle, FL' },
  { value: 'Labolt_SD', label: 'Labolt, SD' },
  { value: 'Lac Du Flambeau_WI', label: 'Lac Du Flambeau, WI' },
  { value: 'Lacarne_OH', label: 'Lacarne, OH' },
  { value: 'Lacassine_LA', label: 'Lacassine, LA' },
  { value: 'Lacey_WA', label: 'Lacey, WA' },
  { value: 'Laceys Spring_AL', label: 'Laceys Spring, AL' },
  { value: 'Laceyville_PA', label: 'Laceyville, PA' },
  { value: 'Lachine_MI', label: 'Lachine, MI' },
  { value: 'Lackawaxen_PA', label: 'Lackawaxen, PA' },
  { value: 'Lackey_KY', label: 'Lackey, KY' },
  { value: 'Laclede_MO', label: 'Laclede, MO' },
  { value: 'Laclede_ID', label: 'Laclede, ID' },
  { value: 'Lacombe_LA', label: 'Lacombe, LA' },
  { value: 'Lacon_IL', label: 'Lacon, IL' },
  { value: 'Lacona_NY', label: 'Lacona, NY' },
  { value: 'Lacona_IA', label: 'Lacona, IA' },
  { value: 'Laconia_NH', label: 'Laconia, NH' },
  { value: 'Laconia_IN', label: 'Laconia, IN' },
  { value: 'Lacota_MI', label: 'Lacota, MI' },
  { value: 'Lacrosse_WA', label: 'Lacrosse, WA' },
  { value: 'Ladd_IL', label: 'Ladd, IL' },
  { value: 'Laddonia_MO', label: 'Laddonia, MO' },
  { value: 'Ladera Ranch_CA', label: 'Ladera Ranch, CA' },
  { value: 'Ladoga_IN', label: 'Ladoga, IN' },
  { value: 'Ladonia_TX', label: 'Ladonia, TX' },
  { value: 'Ladora_IA', label: 'Ladora, IA' },
  { value: 'Ladson_SC', label: 'Ladson, SC' },
  { value: 'Lady Lake_FL', label: 'Lady Lake, FL' },
  { value: 'Ladysmith_WI', label: 'Ladysmith, WI' },
  { value: 'Lafayette_NJ', label: 'Lafayette, NJ' },
  { value: 'Lafayette_AL', label: 'Lafayette, AL' },
  { value: 'Lafayette_TN', label: 'Lafayette, TN' },
  { value: 'Lafayette_OH', label: 'Lafayette, OH' },
  { value: 'Lafayette_IN', label: 'Lafayette, IN' },
  { value: 'Lafayette_MN', label: 'Lafayette, MN' },
  { value: 'Lafayette_LA', label: 'Lafayette, LA' },
  { value: 'Lafayette_CO', label: 'Lafayette, CO' },
  { value: 'Lafayette_CA', label: 'Lafayette, CA' },
  { value: 'Lafayette_OR', label: 'Lafayette, OR' },
  { value: 'Lafayette Hill_PA', label: 'Lafayette Hill, PA' },
  { value: 'Lafe_AR', label: 'Lafe, AR' },
  { value: 'Lafferty_OH', label: 'Lafferty, OH' },
  { value: 'Lafitte_LA', label: 'Lafitte, LA' },
  { value: 'Lagrange_ME', label: 'Lagrange, ME' },
  { value: 'Lagrange_GA', label: 'Lagrange, GA' },
  { value: 'Lagrange_OH', label: 'Lagrange, OH' },
  { value: 'Lagrange_IN', label: 'Lagrange, IN' },
  { value: 'Lagrange_WY', label: 'Lagrange, WY' },
  { value: 'Lagrangeville_NY', label: 'Lagrangeville, NY' },
  { value: 'Lagro_IN', label: 'Lagro, IN' },
  { value: 'Laguna_NM', label: 'Laguna, NM' },
  { value: 'Laguna Beach_CA', label: 'Laguna Beach, CA' },
  { value: 'Laguna Hills_CA', label: 'Laguna Hills, CA' },
  { value: 'Laguna Niguel_CA', label: 'Laguna Niguel, CA' },
  { value: 'Laguna Woods_CA', label: 'Laguna Woods, CA' },
  { value: 'Lagunitas_CA', label: 'Lagunitas, CA' },
  { value: 'Lahaina_HI', label: 'Lahaina, HI' },
  { value: 'Lahmansville_WV', label: 'Lahmansville, WV' },
  { value: 'Lahoma_OK', label: 'Lahoma, OK' },
  { value: 'Laie_HI', label: 'Laie, HI' },
  { value: 'Laingsburg_MI', label: 'Laingsburg, MI' },
  { value: 'Lairdsville_PA', label: 'Lairdsville, PA' },
  { value: 'Lajas_PR', label: 'Lajas, PR' },
  { value: 'Lake_WV', label: 'Lake, WV' },
  { value: 'Lake_MS', label: 'Lake, MS' },
  { value: 'Lake_MI', label: 'Lake, MI' },
  { value: 'Lake Alfred_FL', label: 'Lake Alfred, FL' },
  { value: 'Lake Andes_SD', label: 'Lake Andes, SD' },
  { value: 'Lake Ann_MI', label: 'Lake Ann, MI' },
  { value: 'Lake Ariel_PA', label: 'Lake Ariel, PA' },
  { value: 'Lake Arrowhead_CA', label: 'Lake Arrowhead, CA' },
  { value: 'Lake Arthur_LA', label: 'Lake Arthur, LA' },
  { value: 'Lake Arthur_NM', label: 'Lake Arthur, NM' },
  { value: 'Lake Benton_MN', label: 'Lake Benton, MN' },
  { value: 'Lake Bluff_IL', label: 'Lake Bluff, IL' },
  { value: 'Lake Bronson_MN', label: 'Lake Bronson, MN' },
  { value: 'Lake Butler_FL', label: 'Lake Butler, FL' },
  { value: 'Lake Charles_LA', label: 'Lake Charles, LA' },
  { value: 'Lake City_PA', label: 'Lake City, PA' },
  { value: 'Lake City_SC', label: 'Lake City, SC' },
  { value: 'Lake City_FL', label: 'Lake City, FL' },
  { value: 'Lake City_MI', label: 'Lake City, MI' },
  { value: 'Lake City_IA', label: 'Lake City, IA' },
  { value: 'Lake City_MN', label: 'Lake City, MN' },
  { value: 'Lake City_SD', label: 'Lake City, SD' },
  { value: 'Lake City_KS', label: 'Lake City, KS' },
  { value: 'Lake City_AR', label: 'Lake City, AR' },
  { value: 'Lake City_CO', label: 'Lake City, CO' },
  { value: 'Lake City_CA', label: 'Lake City, CA' },
  { value: 'Lake Clear_NY', label: 'Lake Clear, NY' },
  { value: 'Lake Como_PA', label: 'Lake Como, PA' },
  { value: 'Lake Como_FL', label: 'Lake Como, FL' },
  { value: 'Lake Cormorant_MS', label: 'Lake Cormorant, MS' },
  { value: 'Lake Creek_TX', label: 'Lake Creek, TX' },
  { value: 'Lake Crystal_MN', label: 'Lake Crystal, MN' },
  { value: 'Lake Dallas_TX', label: 'Lake Dallas, TX' },
  { value: 'Lake Delton_WI', label: 'Lake Delton, WI' },
  { value: 'Lake Elmo_MN', label: 'Lake Elmo, MN' },
  { value: 'Lake Elsinore_CA', label: 'Lake Elsinore, CA' },
  { value: 'Lake Forest_IL', label: 'Lake Forest, IL' },
  { value: 'Lake Forest_CA', label: 'Lake Forest, CA' },
  { value: 'Lake Fork_IL', label: 'Lake Fork, IL' },
  { value: 'Lake Geneva_WI', label: 'Lake Geneva, WI' },
  { value: 'Lake George_NY', label: 'Lake George, NY' },
  { value: 'Lake George_MI', label: 'Lake George, MI' },
  { value: 'Lake George_MN', label: 'Lake George, MN' },
  { value: 'Lake George_CO', label: 'Lake George, CO' },
  { value: 'Lake Grove_NY', label: 'Lake Grove, NY' },
  { value: 'Lake Hamilton_FL', label: 'Lake Hamilton, FL' },
  { value: 'Lake Harmony_PA', label: 'Lake Harmony, PA' },
  { value: 'Lake Havasu City_AZ', label: 'Lake Havasu City, AZ' },
  { value: 'Lake Helen_FL', label: 'Lake Helen, FL' },
  { value: 'Lake Hiawatha_NJ', label: 'Lake Hiawatha, NJ' },
  { value: 'Lake Hill_NY', label: 'Lake Hill, NY' },
  { value: 'Lake Hopatcong_NJ', label: 'Lake Hopatcong, NJ' },
  { value: 'Lake Hughes_CA', label: 'Lake Hughes, CA' },
  { value: 'Lake Huntington_NY', label: 'Lake Huntington, NY' },
  { value: 'Lake In The Hills_IL', label: 'Lake In The Hills, IL' },
  { value: 'Lake Isabella_CA', label: 'Lake Isabella, CA' },
  { value: 'Lake Jackson_TX', label: 'Lake Jackson, TX' },
  { value: 'Lake Junaluska_NC', label: 'Lake Junaluska, NC' },
  { value: 'Lake Katrine_NY', label: 'Lake Katrine, NY' },
  { value: 'Lake Leelanau_MI', label: 'Lake Leelanau, MI' },
  { value: 'Lake Lillian_MN', label: 'Lake Lillian, MN' },
  { value: 'Lake Linden_MI', label: 'Lake Linden, MI' },
  { value: 'Lake Lure_NC', label: 'Lake Lure, NC' },
  { value: 'Lake Luzerne_NY', label: 'Lake Luzerne, NY' },
  { value: 'Lake Lynn_PA', label: 'Lake Lynn, PA' },
  { value: 'Lake Mary_FL', label: 'Lake Mary, FL' },
  { value: 'Lake Mills_IA', label: 'Lake Mills, IA' },
  { value: 'Lake Mills_WI', label: 'Lake Mills, WI' },
  { value: 'Lake Milton_OH', label: 'Lake Milton, OH' },
  { value: 'Lake Minchumina_AK', label: 'Lake Minchumina, AK' },
  { value: 'Lake Nebagamon_WI', label: 'Lake Nebagamon, WI' },
  { value: 'Lake Norden_SD', label: 'Lake Norden, SD' },
  { value: 'Lake Odessa_MI', label: 'Lake Odessa, MI' },
  { value: 'Lake Orion_MI', label: 'Lake Orion, MI' },
  { value: 'Lake Oswego_OR', label: 'Lake Oswego, OR' },
  { value: 'Lake Ozark_MO', label: 'Lake Ozark, MO' },
  { value: 'Lake Panasoffkee_FL', label: 'Lake Panasoffkee, FL' },
  { value: 'Lake Park_GA', label: 'Lake Park, GA' },
  { value: 'Lake Park_IA', label: 'Lake Park, IA' },
  { value: 'Lake Park_MN', label: 'Lake Park, MN' },
  { value: 'Lake Peekskill_NY', label: 'Lake Peekskill, NY' },
  { value: 'Lake Placid_NY', label: 'Lake Placid, NY' },
  { value: 'Lake Placid_FL', label: 'Lake Placid, FL' },
  { value: 'Lake Pleasant_MA', label: 'Lake Pleasant, MA' },
  { value: 'Lake Pleasant_NY', label: 'Lake Pleasant, NY' },
  { value: 'Lake Powell_UT', label: 'Lake Powell, UT' },
  { value: 'Lake Preston_SD', label: 'Lake Preston, SD' },
  { value: 'Lake Providence_LA', label: 'Lake Providence, LA' },
  { value: 'Lake St Louis_MO', label: 'Lake St Louis, MO' },
  { value: 'Lake Station_IN', label: 'Lake Station, IN' },
  { value: 'Lake Stevens_WA', label: 'Lake Stevens, WA' },
  { value: 'Lake Tomahawk_WI', label: 'Lake Tomahawk, WI' },
  { value: 'Lake Toxaway_NC', label: 'Lake Toxaway, NC' },
  { value: 'Lake View_NY', label: 'Lake View, NY' },
  { value: 'Lake View_SC', label: 'Lake View, SC' },
  { value: 'Lake View_IA', label: 'Lake View, IA' },
  { value: 'Lake Villa_IL', label: 'Lake Villa, IL' },
  { value: 'Lake Village_IN', label: 'Lake Village, IN' },
  { value: 'Lake Village_AR', label: 'Lake Village, AR' },
  { value: 'Lake Waccamaw_NC', label: 'Lake Waccamaw, NC' },
  { value: 'Lake Wales_FL', label: 'Lake Wales, FL' },
  { value: 'Lake Wilson_MN', label: 'Lake Wilson, MN' },
  { value: 'Lake Winola_PA', label: 'Lake Winola, PA' },
  { value: 'Lake Worth_FL', label: 'Lake Worth, FL' },
  { value: 'Lake Worth Beach_FL', label: 'Lake Worth Beach, FL' },
  { value: 'Lake Zurich_IL', label: 'Lake Zurich, IL' },
  { value: 'Lakebay_WA', label: 'Lakebay, WA' },
  { value: 'Lakefield_MN', label: 'Lakefield, MN' },
  { value: 'Lakehead_CA', label: 'Lakehead, CA' },
  { value: 'Lakehurst_NJ', label: 'Lakehurst, NJ' },
  { value: 'Lakeland_GA', label: 'Lakeland, GA' },
  { value: 'Lakeland_FL', label: 'Lakeland, FL' },
  { value: 'Lakeland_MI', label: 'Lakeland, MI' },
  { value: 'Lakeland_MN', label: 'Lakeland, MN' },
  { value: 'Lakeland_LA', label: 'Lakeland, LA' },
  { value: 'Lakemont_GA', label: 'Lakemont, GA' },
  { value: 'Lakemore_OH', label: 'Lakemore, OH' },
  { value: 'Lakeport_CA', label: 'Lakeport, CA' },
  { value: 'Lakeshore_FL', label: 'Lakeshore, FL' },
  { value: 'Lakeshore_CA', label: 'Lakeshore, CA' },
  { value: 'Lakeside_CT', label: 'Lakeside, CT' },
  { value: 'Lakeside_MI', label: 'Lakeside, MI' },
  { value: 'Lakeside_MT', label: 'Lakeside, MT' },
  { value: 'Lakeside_NE', label: 'Lakeside, NE' },
  { value: 'Lakeside_AZ', label: 'Lakeside, AZ' },
  { value: 'Lakeside_CA', label: 'Lakeside, CA' },
  { value: 'Lakeside_OR', label: 'Lakeside, OR' },
  { value: 'Lakeside Marblehead_OH', label: 'Lakeside Marblehead, OH' },
  { value: 'Laketon_IN', label: 'Laketon, IN' },
  { value: 'Laketown_UT', label: 'Laketown, UT' },
  { value: 'Lakeview_NC', label: 'Lakeview, NC' },
  { value: 'Lakeview_OH', label: 'Lakeview, OH' },
  { value: 'Lakeview_MI', label: 'Lakeview, MI' },
  { value: 'Lakeview_AR', label: 'Lakeview, AR' },
  { value: 'Lakeview_TX', label: 'Lakeview, TX' },
  { value: 'Lakeview_OR', label: 'Lakeview, OR' },
  { value: 'Lakeville_MA', label: 'Lakeville, MA' },
  { value: 'Lakeville_CT', label: 'Lakeville, CT' },
  { value: 'Lakeville_NY', label: 'Lakeville, NY' },
  { value: 'Lakeville_PA', label: 'Lakeville, PA' },
  { value: 'Lakeville_OH', label: 'Lakeville, OH' },
  { value: 'Lakeville_IN', label: 'Lakeville, IN' },
  { value: 'Lakeville_MN', label: 'Lakeville, MN' },
  { value: 'Lakewood_NJ', label: 'Lakewood, NJ' },
  { value: 'Lakewood_NY', label: 'Lakewood, NY' },
  { value: 'Lakewood_PA', label: 'Lakewood, PA' },
  { value: 'Lakewood_OH', label: 'Lakewood, OH' },
  { value: 'Lakewood_WI', label: 'Lakewood, WI' },
  { value: 'Lakewood_IL', label: 'Lakewood, IL' },
  { value: 'Lakewood_NM', label: 'Lakewood, NM' },
  { value: 'Lakewood_CA', label: 'Lakewood, CA' },
  { value: 'Lakewood_WA', label: 'Lakewood, WA' },
  { value: 'Lakin_KS', label: 'Lakin, KS' },
  { value: 'Lakota_IA', label: 'Lakota, IA' },
  { value: 'Lakota_ND', label: 'Lakota, ND' },
  { value: 'Lamar_PA', label: 'Lamar, PA' },
  { value: 'Lamar_SC', label: 'Lamar, SC' },
  { value: 'Lamar_MS', label: 'Lamar, MS' },
  { value: 'Lamar_IN', label: 'Lamar, IN' },
  { value: 'Lamar_MO', label: 'Lamar, MO' },
  { value: 'Lamar_AR', label: 'Lamar, AR' },
  { value: 'Lamar_OK', label: 'Lamar, OK' },
  { value: 'Lamar_CO', label: 'Lamar, CO' },
  { value: 'Lambert_MS', label: 'Lambert, MS' },
  { value: 'Lambert_MT', label: 'Lambert, MT' },
  { value: 'Lambert Lake_ME', label: 'Lambert Lake, ME' },
  { value: 'Lamberton_MN', label: 'Lamberton, MN' },
  { value: 'Lambertville_NJ', label: 'Lambertville, NJ' },
  { value: 'Lambertville_MI', label: 'Lambertville, MI' },
  { value: 'Lambrook_AR', label: 'Lambrook, AR' },
  { value: 'Lambsburg_VA', label: 'Lambsburg, VA' },
  { value: 'Lame Deer_MT', label: 'Lame Deer, MT' },
  { value: 'Lamesa_TX', label: 'Lamesa, TX' },
  { value: 'Lamoille_NV', label: 'Lamoille, NV' },
  { value: 'Lamona_WA', label: 'Lamona, WA' },
  { value: 'Lamoni_IA', label: 'Lamoni, IA' },
  { value: 'Lamont_FL', label: 'Lamont, FL' },
  { value: 'Lamont_MI', label: 'Lamont, MI' },
  { value: 'Lamont_IA', label: 'Lamont, IA' },
  { value: 'Lamont_OK', label: 'Lamont, OK' },
  { value: 'Lamont_CA', label: 'Lamont, CA' },
  { value: 'Lamont_WA', label: 'Lamont, WA' },
  { value: 'Lamoure_ND', label: 'Lamoure, ND' },
  { value: 'Lampasas_TX', label: 'Lampasas, TX' },
  { value: 'Lampe_MO', label: 'Lampe, MO' },
  { value: 'Lamy_NM', label: 'Lamy, NM' },
  { value: 'Lanagan_MO', label: 'Lanagan, MO' },
  { value: 'Lanai City_HI', label: 'Lanai City, HI' },
  { value: 'Lanark_IL', label: 'Lanark, IL' },
  { value: 'Lanark Village_FL', label: 'Lanark Village, FL' },
  { value: 'Lancaster_MA', label: 'Lancaster, MA' },
  { value: 'Lancaster_NH', label: 'Lancaster, NH' },
  { value: 'Lancaster_NY', label: 'Lancaster, NY' },
  { value: 'Lancaster_PA', label: 'Lancaster, PA' },
  { value: 'Lancaster_VA', label: 'Lancaster, VA' },
  { value: 'Lancaster_SC', label: 'Lancaster, SC' },
  { value: 'Lancaster_TN', label: 'Lancaster, TN' },
  { value: 'Lancaster_KY', label: 'Lancaster, KY' },
  { value: 'Lancaster_OH', label: 'Lancaster, OH' },
  { value: 'Lancaster_WI', label: 'Lancaster, WI' },
  { value: 'Lancaster_MN', label: 'Lancaster, MN' },
  { value: 'Lancaster_MO', label: 'Lancaster, MO' },
  { value: 'Lancaster_KS', label: 'Lancaster, KS' },
  { value: 'Lancaster_TX', label: 'Lancaster, TX' },
  { value: 'Lancaster_CA', label: 'Lancaster, CA' },
  { value: 'Lance Creek_WY', label: 'Lance Creek, WY' },
  { value: 'Lancing_TN', label: 'Lancing, TN' },
  { value: "Land O'Lakes_FL", label: "Land O'Lakes, FL" },
  { value: "Land O'Lakes_WI", label: "Land O'Lakes, WI" },
  { value: 'Landenberg_PA', label: 'Landenberg, PA' },
  { value: 'Lander_WY', label: 'Lander, WY' },
  { value: 'Landers_CA', label: 'Landers, CA' },
  { value: 'Landing_NJ', label: 'Landing, NJ' },
  { value: 'Landis_NC', label: 'Landis, NC' },
  { value: 'Landisburg_PA', label: 'Landisburg, PA' },
  { value: 'Landisville_NJ', label: 'Landisville, NJ' },
  { value: 'Landisville_PA', label: 'Landisville, PA' },
  { value: 'Lando_SC', label: 'Lando, SC' },
  { value: 'Landrum_SC', label: 'Landrum, SC' },
  { value: 'Lane_SC', label: 'Lane, SC' },
  { value: 'Lane_SD', label: 'Lane, SD' },
  { value: 'Lane_IL', label: 'Lane, IL' },
  { value: 'Lane_KS', label: 'Lane, KS' },
  { value: 'Lane_OK', label: 'Lane, OK' },
  { value: 'Lane City_TX', label: 'Lane City, TX' },
  { value: 'Lanesboro_IA', label: 'Lanesboro, IA' },
  { value: 'Lanesboro_MN', label: 'Lanesboro, MN' },
  { value: 'Lanesborough_MA', label: 'Lanesborough, MA' },
  { value: 'Lanesville_NY', label: 'Lanesville, NY' },
  { value: 'Lanesville_IN', label: 'Lanesville, IN' },
  { value: 'Lanett_AL', label: 'Lanett, AL' },
  { value: 'Laneview_VA', label: 'Laneview, VA' },
  { value: 'Laneville_TX', label: 'Laneville, TX' },
  { value: 'Lanexa_VA', label: 'Lanexa, VA' },
  { value: 'Langdon_ND', label: 'Langdon, ND' },
  { value: 'Langeloth_PA', label: 'Langeloth, PA' },
  { value: 'Langford_SD', label: 'Langford, SD' },
  { value: 'Langhorne_PA', label: 'Langhorne, PA' },
  { value: 'Langley_SC', label: 'Langley, SC' },
  { value: 'Langley_KY', label: 'Langley, KY' },
  { value: 'Langley_AR', label: 'Langley, AR' },
  { value: 'Langley_OK', label: 'Langley, OK' },
  { value: 'Langley_WA', label: 'Langley, WA' },
  { value: 'Langlois_OR', label: 'Langlois, OR' },
  { value: 'Langston_AL', label: 'Langston, AL' },
  { value: 'Langston_OK', label: 'Langston, OK' },
  { value: 'Langsville_OH', label: 'Langsville, OH' },
  { value: 'Langtry_TX', label: 'Langtry, TX' },
  { value: 'Langworthy_IA', label: 'Langworthy, IA' },
  { value: 'Lanham_MD', label: 'Lanham, MD' },
  { value: 'Lankin_ND', label: 'Lankin, ND' },
  { value: 'Lannon_WI', label: 'Lannon, WI' },
  { value: 'Lanoka Harbor_NJ', label: 'Lanoka Harbor, NJ' },
  { value: 'Lansdale_PA', label: 'Lansdale, PA' },
  { value: 'Lansdowne_PA', label: 'Lansdowne, PA' },
  { value: 'Lanse_PA', label: 'Lanse, PA' },
  { value: 'Lanse_MI', label: 'Lanse, MI' },
  { value: 'Lansford_PA', label: 'Lansford, PA' },
  { value: 'Lansford_ND', label: 'Lansford, ND' },
  { value: 'Lansing_NY', label: 'Lansing, NY' },
  { value: 'Lansing_WV', label: 'Lansing, WV' },
  { value: 'Lansing_NC', label: 'Lansing, NC' },
  { value: 'Lansing_OH', label: 'Lansing, OH' },
  { value: 'Lansing_MI', label: 'Lansing, MI' },
  { value: 'Lansing_IA', label: 'Lansing, IA' },
  { value: 'Lansing_MN', label: 'Lansing, MN' },
  { value: 'Lansing_IL', label: 'Lansing, IL' },
  { value: 'Lansing_KS', label: 'Lansing, KS' },
  { value: 'Lantry_SD', label: 'Lantry, SD' },
  { value: 'Laona_WI', label: 'Laona, WI' },
  { value: 'Laotto_IN', label: 'Laotto, IN' },
  { value: 'Lapaz_IN', label: 'Lapaz, IN' },
  { value: 'Lapeer_MI', label: 'Lapeer, MI' },
  { value: 'Lapel_IN', label: 'Lapel, IN' },
  { value: 'Lapine_AL', label: 'Lapine, AL' },
  { value: 'Lapoint_UT', label: 'Lapoint, UT' },
  { value: 'Laporte_PA', label: 'Laporte, PA' },
  { value: 'Laporte_MN', label: 'Laporte, MN' },
  { value: 'Laporte_CO', label: 'Laporte, CO' },
  { value: 'Lapwai_ID', label: 'Lapwai, ID' },
  { value: 'Laquey_MO', label: 'Laquey, MO' },
  { value: 'Laramie_WY', label: 'Laramie, WY' },
  { value: 'Larchmont_NY', label: 'Larchmont, NY' },
  { value: 'Larchwood_IA', label: 'Larchwood, IA' },
  { value: 'Laredo_MO', label: 'Laredo, MO' },
  { value: 'Laredo_TX', label: 'Laredo, TX' },
  { value: 'Lares_PR', label: 'Lares, PR' },
  { value: 'Largo_FL', label: 'Largo, FL' },
  { value: 'Larimer_PA', label: 'Larimer, PA' },
  { value: 'Larimore_ND', label: 'Larimore, ND' },
  { value: 'Larkspur_CO', label: 'Larkspur, CO' },
  { value: 'Larkspur_CA', label: 'Larkspur, CA' },
  { value: 'Larned_KS', label: 'Larned, KS' },
  { value: 'Larose_LA', label: 'Larose, LA' },
  { value: 'Larrabee_IA', label: 'Larrabee, IA' },
  { value: 'Larsen_WI', label: 'Larsen, WI' },
  { value: 'Larsen Bay_AK', label: 'Larsen Bay, AK' },
  { value: 'Larslan_MT', label: 'Larslan, MT' },
  { value: 'Larue_TX', label: 'Larue, TX' },
  { value: 'Larwill_IN', label: 'Larwill, IN' },
  { value: 'Las Animas_CO', label: 'Las Animas, CO' },
  { value: 'Las Cruces_NM', label: 'Las Cruces, NM' },
  { value: 'Las Marias_PR', label: 'Las Marias, PR' },
  { value: 'Las Piedras_PR', label: 'Las Piedras, PR' },
  { value: 'Las Vegas_NM', label: 'Las Vegas, NM' },
  { value: 'Las Vegas_NV', label: 'Las Vegas, NV' },
  { value: 'Lasara_TX', label: 'Lasara, TX' },
  { value: 'Lascassas_TN', label: 'Lascassas, TN' },
  { value: 'Lashmeet_WV', label: 'Lashmeet, WV' },
  { value: 'Latah_WA', label: 'Latah, WA' },
  { value: 'Latexo_TX', label: 'Latexo, TX' },
  { value: 'Latham_NY', label: 'Latham, NY' },
  { value: 'Latham_OH', label: 'Latham, OH' },
  { value: 'Latham_IL', label: 'Latham, IL' },
  { value: 'Latham_MO', label: 'Latham, MO' },
  { value: 'Latham_KS', label: 'Latham, KS' },
  { value: 'Lathrop_MO', label: 'Lathrop, MO' },
  { value: 'Lathrop_CA', label: 'Lathrop, CA' },
  { value: 'Latimer_IA', label: 'Latimer, IA' },
  { value: 'Laton_CA', label: 'Laton, CA' },
  { value: 'Latonia_KY', label: 'Latonia, KY' },
  { value: 'Latrobe_PA', label: 'Latrobe, PA' },
  { value: 'Latta_SC', label: 'Latta, SC' },
  { value: 'Lattimer Mines_PA', label: 'Lattimer Mines, PA' },
  { value: 'Lattimore_NC', label: 'Lattimore, NC' },
  { value: 'Latty_OH', label: 'Latty, OH' },
  { value: 'Lauderdale_MS', label: 'Lauderdale, MS' },
  { value: 'Laughlin_NV', label: 'Laughlin, NV' },
  { value: 'Laughlin Afb_TX', label: 'Laughlin Afb, TX' },
  { value: 'Laughlintown_PA', label: 'Laughlintown, PA' },
  { value: 'Laupahoehoe_HI', label: 'Laupahoehoe, HI' },
  { value: 'Laura_OH', label: 'Laura, OH' },
  { value: 'Laura_IL', label: 'Laura, IL' },
  { value: 'Laurel_NY', label: 'Laurel, NY' },
  { value: 'Laurel_DE', label: 'Laurel, DE' },
  { value: 'Laurel_MD', label: 'Laurel, MD' },
  { value: 'Laurel_MS', label: 'Laurel, MS' },
  { value: 'Laurel_IN', label: 'Laurel, IN' },
  { value: 'Laurel_IA', label: 'Laurel, IA' },
  { value: 'Laurel_MT', label: 'Laurel, MT' },
  { value: 'Laurel_NE', label: 'Laurel, NE' },
  { value: 'Laurel Bloomery_TN', label: 'Laurel Bloomery, TN' },
  { value: 'Laurel Fork_VA', label: 'Laurel Fork, VA' },
  { value: 'Laurel Hill_NC', label: 'Laurel Hill, NC' },
  { value: 'Laurel Hill_FL', label: 'Laurel Hill, FL' },
  { value: 'Laurel Springs_NC', label: 'Laurel Springs, NC' },
  { value: 'Laurelton_PA', label: 'Laurelton, PA' },
  { value: 'Laurelville_OH', label: 'Laurelville, OH' },
  { value: 'Laurens_NY', label: 'Laurens, NY' },
  { value: 'Laurens_SC', label: 'Laurens, SC' },
  { value: 'Laurens_IA', label: 'Laurens, IA' },
  { value: 'Laurier_WA', label: 'Laurier, WA' },
  { value: 'Laurinburg_NC', label: 'Laurinburg, NC' },
  { value: 'Laurys Station_PA', label: 'Laurys Station, PA' },
  { value: 'Lava Hot Springs_ID', label: 'Lava Hot Springs, ID' },
  { value: 'Lavaca_AR', label: 'Lavaca, AR' },
  { value: 'Lavalette_WV', label: 'Lavalette, WV' },
  { value: 'Lavallette_NJ', label: 'Lavallette, NJ' },
  { value: 'Laveen_AZ', label: 'Laveen, AZ' },
  { value: 'Lavelle_PA', label: 'Lavelle, PA' },
  { value: 'Laverne_OK', label: 'Laverne, OK' },
  { value: 'Lavina_MT', label: 'Lavina, MT' },
  { value: 'Lavinia_TN', label: 'Lavinia, TN' },
  { value: 'Lavon_TX', label: 'Lavon, TX' },
  { value: 'Lavonia_GA', label: 'Lavonia, GA' },
  { value: 'Lawai_HI', label: 'Lawai, HI' },
  { value: 'Lawler_IA', label: 'Lawler, IA' },
  { value: 'Lawley_AL', label: 'Lawley, AL' },
  { value: 'Lawn_PA', label: 'Lawn, PA' },
  { value: 'Lawn_TX', label: 'Lawn, TX' },
  { value: 'Lawndale_NC', label: 'Lawndale, NC' },
  { value: 'Lawndale_IL', label: 'Lawndale, IL' },
  { value: 'Lawndale_CA', label: 'Lawndale, CA' },
  { value: 'Lawnside_NJ', label: 'Lawnside, NJ' },
  { value: 'Lawrence_MA', label: 'Lawrence, MA' },
  { value: 'Lawrence_NY', label: 'Lawrence, NY' },
  { value: 'Lawrence_PA', label: 'Lawrence, PA' },
  { value: 'Lawrence_MS', label: 'Lawrence, MS' },
  { value: 'Lawrence_MI', label: 'Lawrence, MI' },
  { value: 'Lawrence_KS', label: 'Lawrence, KS' },
  { value: 'Lawrence_NE', label: 'Lawrence, NE' },
  { value: 'Lawrence Township_NJ', label: 'Lawrence Township, NJ' },
  { value: 'Lawrenceburg_TN', label: 'Lawrenceburg, TN' },
  { value: 'Lawrenceburg_KY', label: 'Lawrenceburg, KY' },
  { value: 'Lawrenceburg_IN', label: 'Lawrenceburg, IN' },
  { value: 'Lawrenceville_PA', label: 'Lawrenceville, PA' },
  { value: 'Lawrenceville_VA', label: 'Lawrenceville, VA' },
  { value: 'Lawrenceville_GA', label: 'Lawrenceville, GA' },
  { value: 'Lawrenceville_IL', label: 'Lawrenceville, IL' },
  { value: 'Lawson_MO', label: 'Lawson, MO' },
  { value: 'Lawsonville_NC', label: 'Lawsonville, NC' },
  { value: 'Lawtell_LA', label: 'Lawtell, LA' },
  { value: 'Lawtey_FL', label: 'Lawtey, FL' },
  { value: 'Lawton_PA', label: 'Lawton, PA' },
  { value: 'Lawton_MI', label: 'Lawton, MI' },
  { value: 'Lawton_IA', label: 'Lawton, IA' },
  { value: 'Lawton_ND', label: 'Lawton, ND' },
  { value: 'Lawton_OK', label: 'Lawton, OK' },
  { value: 'Lawtons_NY', label: 'Lawtons, NY' },
  { value: 'Layland_WV', label: 'Layland, WV' },
  { value: 'Layton_NJ', label: 'Layton, NJ' },
  { value: 'Layton_UT', label: 'Layton, UT' },
  { value: 'Laytonville_CA', label: 'Laytonville, CA' },
  { value: 'Lazbuddie_TX', label: 'Lazbuddie, TX' },
  { value: 'Le Center_MN', label: 'Le Center, MN' },
  { value: 'Le Claire_IA', label: 'Le Claire, IA' },
  { value: 'Le Grand_IA', label: 'Le Grand, IA' },
  { value: 'Le Grand_CA', label: 'Le Grand, CA' },
  { value: 'Le Mars_IA', label: 'Le Mars, IA' },
  { value: 'Le Raysville_PA', label: 'Le Raysville, PA' },
  { value: 'Le Roy_NY', label: 'Le Roy, NY' },
  { value: 'Le Roy_WV', label: 'Le Roy, WV' },
  { value: 'Le Roy_MN', label: 'Le Roy, MN' },
  { value: 'Le Roy_IL', label: 'Le Roy, IL' },
  { value: 'Le Roy_KS', label: 'Le Roy, KS' },
  { value: 'Le Sueur_MN', label: 'Le Sueur, MN' },
  { value: 'Leachville_AR', label: 'Leachville, AR' },
  { value: 'Lead_SD', label: 'Lead, SD' },
  { value: 'Lead Hill_AR', label: 'Lead Hill, AR' },
  { value: 'Leadore_ID', label: 'Leadore, ID' },
  { value: 'Leadville_CO', label: 'Leadville, CO' },
  { value: 'Leadwood_MO', label: 'Leadwood, MO' },
  { value: 'Leaf River_IL', label: 'Leaf River, IL' },
  { value: 'League City_TX', label: 'League City, TX' },
  { value: 'Leakesville_MS', label: 'Leakesville, MS' },
  { value: 'Leakey_TX', label: 'Leakey, TX' },
  { value: 'Leamington_UT', label: 'Leamington, UT' },
  { value: 'Leander_TX', label: 'Leander, TX' },
  { value: 'Leary_GA', label: 'Leary, GA' },
  { value: 'Leasburg_NC', label: 'Leasburg, NC' },
  { value: 'Leasburg_MO', label: 'Leasburg, MO' },
  { value: 'Leavenworth_IN', label: 'Leavenworth, IN' },
  { value: 'Leavenworth_KS', label: 'Leavenworth, KS' },
  { value: 'Leavenworth_WA', label: 'Leavenworth, WA' },
  { value: 'Leavittsburg_OH', label: 'Leavittsburg, OH' },
  { value: 'Leawood_KS', label: 'Leawood, KS' },
  { value: 'Lebanon_NH', label: 'Lebanon, NH' },
  { value: 'Lebanon_ME', label: 'Lebanon, ME' },
  { value: 'Lebanon_CT', label: 'Lebanon, CT' },
  { value: 'Lebanon_NJ', label: 'Lebanon, NJ' },
  { value: 'Lebanon_PA', label: 'Lebanon, PA' },
  { value: 'Lebanon_VA', label: 'Lebanon, VA' },
  { value: 'Lebanon_TN', label: 'Lebanon, TN' },
  { value: 'Lebanon_KY', label: 'Lebanon, KY' },
  { value: 'Lebanon_OH', label: 'Lebanon, OH' },
  { value: 'Lebanon_IN', label: 'Lebanon, IN' },
  { value: 'Lebanon_WI', label: 'Lebanon, WI' },
  { value: 'Lebanon_SD', label: 'Lebanon, SD' },
  { value: 'Lebanon_IL', label: 'Lebanon, IL' },
  { value: 'Lebanon_MO', label: 'Lebanon, MO' },
  { value: 'Lebanon_KS', label: 'Lebanon, KS' },
  { value: 'Lebanon_NE', label: 'Lebanon, NE' },
  { value: 'Lebanon_OK', label: 'Lebanon, OK' },
  { value: 'Lebanon_OR', label: 'Lebanon, OR' },
  { value: 'Lebanon Junction_KY', label: 'Lebanon Junction, KY' },
  { value: 'Lebeau_LA', label: 'Lebeau, LA' },
  { value: 'Lebec_CA', label: 'Lebec, CA' },
  { value: 'Leblanc_LA', label: 'Leblanc, LA' },
  { value: 'Lebo_KS', label: 'Lebo, KS' },
  { value: 'Leburn_KY', label: 'Leburn, KY' },
  { value: 'Lecanto_FL', label: 'Lecanto, FL' },
  { value: 'Leck Kill_PA', label: 'Leck Kill, PA' },
  { value: 'Leckrone_PA', label: 'Leckrone, PA' },
  { value: 'Lecompte_LA', label: 'Lecompte, LA' },
  { value: 'Lecompton_KS', label: 'Lecompton, KS' },
  { value: 'Ledbetter_KY', label: 'Ledbetter, KY' },
  { value: 'Ledbetter_TX', label: 'Ledbetter, TX' },
  { value: 'Ledger_MT', label: 'Ledger, MT' },
  { value: 'Ledgewood_NJ', label: 'Ledgewood, NJ' },
  { value: 'Ledyard_CT', label: 'Ledyard, CT' },
  { value: 'Ledyard_IA', label: 'Ledyard, IA' },
  { value: 'Lee_MA', label: 'Lee, MA' },
  { value: 'Lee_NH', label: 'Lee, NH' },
  { value: 'Lee_ME', label: 'Lee, ME' },
  { value: 'Lee_FL', label: 'Lee, FL' },
  { value: 'Lee_IL', label: 'Lee, IL' },
  { value: 'Lee Center_NY', label: 'Lee Center, NY' },
  { value: 'Lee Center_IL', label: 'Lee Center, IL' },
  { value: 'Lee Vining_CA', label: 'Lee Vining, CA' },
  { value: 'Leechburg_PA', label: 'Leechburg, PA' },
  { value: 'Leedey_OK', label: 'Leedey, OK' },
  { value: 'Leeds_MA', label: 'Leeds, MA' },
  { value: 'Leeds_ME', label: 'Leeds, ME' },
  { value: 'Leeds_NY', label: 'Leeds, NY' },
  { value: 'Leeds_AL', label: 'Leeds, AL' },
  { value: 'Leeds_ND', label: 'Leeds, ND' },
  { value: 'Leeds_UT', label: 'Leeds, UT' },
  { value: 'Leeper_PA', label: 'Leeper, PA' },
  { value: 'Lees Summit_MO', label: 'Lees Summit, MO' },
  { value: 'Leesburg_NJ', label: 'Leesburg, NJ' },
  { value: 'Leesburg_VA', label: 'Leesburg, VA' },
  { value: 'Leesburg_GA', label: 'Leesburg, GA' },
  { value: 'Leesburg_FL', label: 'Leesburg, FL' },
  { value: 'Leesburg_AL', label: 'Leesburg, AL' },
  { value: 'Leesburg_OH', label: 'Leesburg, OH' },
  { value: 'Leesburg_IN', label: 'Leesburg, IN' },
  { value: 'Leesburg_TX', label: 'Leesburg, TX' },
  { value: 'Leesport_PA', label: 'Leesport, PA' },
  { value: 'Leesville_SC', label: 'Leesville, SC' },
  { value: 'Leesville_OH', label: 'Leesville, OH' },
  { value: 'Leesville_LA', label: 'Leesville, LA' },
  { value: 'Leesville_TX', label: 'Leesville, TX' },
  { value: 'Leeton_MO', label: 'Leeton, MO' },
  { value: 'Leetonia_OH', label: 'Leetonia, OH' },
  { value: 'Leetsdale_PA', label: 'Leetsdale, PA' },
  { value: 'Leflore_OK', label: 'Leflore, OK' },
  { value: 'Lefor_ND', label: 'Lefor, ND' },
  { value: 'Lefors_TX', label: 'Lefors, TX' },
  { value: 'Left Hand_WV', label: 'Left Hand, WV' },
  { value: 'Leggett_TX', label: 'Leggett, TX' },
  { value: 'Leggett_CA', label: 'Leggett, CA' },
  { value: 'Lehi_UT', label: 'Lehi, UT' },
  { value: 'Lehigh_IA', label: 'Lehigh, IA' },
  { value: 'Lehigh_KS', label: 'Lehigh, KS' },
  { value: 'Lehigh_OK', label: 'Lehigh, OK' },
  { value: 'Lehigh Acres_FL', label: 'Lehigh Acres, FL' },
  { value: 'Lehighton_PA', label: 'Lehighton, PA' },
  { value: 'Lehman_PA', label: 'Lehman, PA' },
  { value: 'Lehr_ND', label: 'Lehr, ND' },
  { value: 'Leicester_MA', label: 'Leicester, MA' },
  { value: 'Leicester_NY', label: 'Leicester, NY' },
  { value: 'Leicester_NC', label: 'Leicester, NC' },
  { value: 'Leigh_NE', label: 'Leigh, NE' },
  { value: 'Leighton_AL', label: 'Leighton, AL' },
  { value: 'Leighton_IA', label: 'Leighton, IA' },
  { value: 'Leipsic_OH', label: 'Leipsic, OH' },
  { value: 'Leisenring_PA', label: 'Leisenring, PA' },
  { value: 'Leitchfield_KY', label: 'Leitchfield, KY' },
  { value: 'Leiter_WY', label: 'Leiter, WY' },
  { value: 'Leiters Ford_IN', label: 'Leiters Ford, IN' },
  { value: 'Leivasy_WV', label: 'Leivasy, WV' },
  { value: 'Lejunior_KY', label: 'Lejunior, KY' },
  { value: 'Leland_NC', label: 'Leland, NC' },
  { value: 'Leland_MS', label: 'Leland, MS' },
  { value: 'Leland_MI', label: 'Leland, MI' },
  { value: 'Leland_IA', label: 'Leland, IA' },
  { value: 'Leland_IL', label: 'Leland, IL' },
  { value: 'Lelia Lake_TX', label: 'Lelia Lake, TX' },
  { value: 'Lemhi_ID', label: 'Lemhi, ID' },
  { value: 'Leming_TX', label: 'Leming, TX' },
  { value: 'Lemitar_NM', label: 'Lemitar, NM' },
  { value: 'Lemmon_SD', label: 'Lemmon, SD' },
  { value: 'Lemon Cove_CA', label: 'Lemon Cove, CA' },
  { value: 'Lemon Grove_CA', label: 'Lemon Grove, CA' },
  { value: 'Lemon Springs_NC', label: 'Lemon Springs, NC' },
  { value: 'Lemont_PA', label: 'Lemont, PA' },
  { value: 'Lemont_IL', label: 'Lemont, IL' },
  { value: 'Lemont Furnace_PA', label: 'Lemont Furnace, PA' },
  { value: 'Lemoore_CA', label: 'Lemoore, CA' },
  { value: 'Lemoyne_PA', label: 'Lemoyne, PA' },
  { value: 'Lemoyne_NE', label: 'Lemoyne, NE' },
  { value: 'Lempster_NH', label: 'Lempster, NH' },
  { value: 'Lena_MS', label: 'Lena, MS' },
  { value: 'Lena_WI', label: 'Lena, WI' },
  { value: 'Lena_IL', label: 'Lena, IL' },
  { value: 'Lena_LA', label: 'Lena, LA' },
  { value: 'Lenapah_OK', label: 'Lenapah, OK' },
  { value: 'Lenexa_KS', label: 'Lenexa, KS' },
  { value: 'Lengby_MN', label: 'Lengby, MN' },
  { value: 'Lenhartsville_PA', label: 'Lenhartsville, PA' },
  { value: 'Lenni_PA', label: 'Lenni, PA' },
  { value: 'Lennon_MI', label: 'Lennon, MI' },
  { value: 'Lennox_SD', label: 'Lennox, SD' },
  { value: 'Lenoir_NC', label: 'Lenoir, NC' },
  { value: 'Lenoir City_TN', label: 'Lenoir City, TN' },
  { value: 'Lenora_KS', label: 'Lenora, KS' },
  { value: 'Lenorah_TX', label: 'Lenorah, TX' },
  { value: 'Lenore_WV', label: 'Lenore, WV' },
  { value: 'Lenore_ID', label: 'Lenore, ID' },
  { value: 'Lenox_MA', label: 'Lenox, MA' },
  { value: 'Lenox_GA', label: 'Lenox, GA' },
  { value: 'Lenox_AL', label: 'Lenox, AL' },
  { value: 'Lenox_TN', label: 'Lenox, TN' },
  { value: 'Lenox_IA', label: 'Lenox, IA' },
  { value: 'Lenox_MO', label: 'Lenox, MO' },
  { value: 'Lenox Dale_MA', label: 'Lenox Dale, MA' },
  { value: 'Lenoxville_PA', label: 'Lenoxville, PA' },
  { value: 'Lentner_MO', label: 'Lentner, MO' },
  { value: 'Lenzburg_IL', label: 'Lenzburg, IL' },
  { value: 'Leo_IN', label: 'Leo, IN' },
  { value: 'Leola_PA', label: 'Leola, PA' },
  { value: 'Leola_SD', label: 'Leola, SD' },
  { value: 'Leola_AR', label: 'Leola, AR' },
  { value: 'Leoma_TN', label: 'Leoma, TN' },
  { value: 'Leominster_MA', label: 'Leominster, MA' },
  { value: 'Leon_VA', label: 'Leon, VA' },
  { value: 'Leon_WV', label: 'Leon, WV' },
  { value: 'Leon_IA', label: 'Leon, IA' },
  { value: 'Leon_KS', label: 'Leon, KS' },
  { value: 'Leon_OK', label: 'Leon, OK' },
  { value: 'Leona_TX', label: 'Leona, TX' },
  { value: 'Leonard_MI', label: 'Leonard, MI' },
  { value: 'Leonard_MN', label: 'Leonard, MN' },
  { value: 'Leonard_ND', label: 'Leonard, ND' },
  { value: 'Leonard_MO', label: 'Leonard, MO' },
  { value: 'Leonard_TX', label: 'Leonard, TX' },
  { value: 'Leonardo_NJ', label: 'Leonardo, NJ' },
  { value: 'Leonardsville_NY', label: 'Leonardsville, NY' },
  { value: 'Leonardtown_MD', label: 'Leonardtown, MD' },
  { value: 'Leonardville_KS', label: 'Leonardville, KS' },
  { value: 'Leonia_NJ', label: 'Leonia, NJ' },
  { value: 'Leonidas_MI', label: 'Leonidas, MI' },
  { value: 'Leonore_IL', label: 'Leonore, IL' },
  { value: 'Leonville_LA', label: 'Leonville, LA' },
  { value: 'Leopold_IN', label: 'Leopold, IN' },
  { value: 'Leopold_MO', label: 'Leopold, MO' },
  { value: 'Leopolis_WI', label: 'Leopolis, WI' },
  { value: 'Leota_MN', label: 'Leota, MN' },
  { value: 'Leoti_KS', label: 'Leoti, KS' },
  { value: 'Lepanto_AR', label: 'Lepanto, AR' },
  { value: 'Lequire_OK', label: 'Lequire, OK' },
  { value: 'Lerna_IL', label: 'Lerna, IL' },
  { value: 'Lerona_WV', label: 'Lerona, WV' },
  { value: 'Leroy_AL', label: 'Leroy, AL' },
  { value: 'Leroy_MI', label: 'Leroy, MI' },
  { value: 'Leroy_TX', label: 'Leroy, TX' },
  { value: 'Lesage_WV', label: 'Lesage, WV' },
  { value: 'Leslie_WV', label: 'Leslie, WV' },
  { value: 'Leslie_GA', label: 'Leslie, GA' },
  { value: 'Leslie_MI', label: 'Leslie, MI' },
  { value: 'Leslie_MO', label: 'Leslie, MO' },
  { value: 'Leslie_AR', label: 'Leslie, AR' },
  { value: 'Lester_WV', label: 'Lester, WV' },
  { value: 'Lester_AL', label: 'Lester, AL' },
  { value: 'Lester_IA', label: 'Lester, IA' },
  { value: 'Lester Prairie_MN', label: 'Lester Prairie, MN' },
  { value: 'Lesterville_SD', label: 'Lesterville, SD' },
  { value: 'Lesterville_MO', label: 'Lesterville, MO' },
  { value: 'Letart_WV', label: 'Letart, WV' },
  { value: 'Letcher_KY', label: 'Letcher, KY' },
  { value: 'Letcher_SD', label: 'Letcher, SD' },
  { value: 'Letha_ID', label: 'Letha, ID' },
  { value: 'Letohatchee_AL', label: 'Letohatchee, AL' },
  { value: 'Letona_AR', label: 'Letona, AR' },
  { value: 'Letts_IA', label: 'Letts, IA' },
  { value: 'Lettsworth_LA', label: 'Lettsworth, LA' },
  { value: 'Leupp_AZ', label: 'Leupp, AZ' },
  { value: 'Levan_UT', label: 'Levan, UT' },
  { value: 'Levant_ME', label: 'Levant, ME' },
  { value: 'Levant_KS', label: 'Levant, KS' },
  { value: 'Levasy_MO', label: 'Levasy, MO' },
  { value: 'Levelland_TX', label: 'Levelland, TX' },
  { value: 'Levelock_AK', label: 'Levelock, AK' },
  { value: 'Levels_WV', label: 'Levels, WV' },
  { value: 'Leverett_MA', label: 'Leverett, MA' },
  { value: 'Levering_MI', label: 'Levering, MI' },
  { value: 'Levittown_NY', label: 'Levittown, NY' },
  { value: 'Levittown_PA', label: 'Levittown, PA' },
  { value: 'Lewellen_NE', label: 'Lewellen, NE' },
  { value: 'Lewes_DE', label: 'Lewes, DE' },
  { value: 'Lewis_NY', label: 'Lewis, NY' },
  { value: 'Lewis_IN', label: 'Lewis, IN' },
  { value: 'Lewis_IA', label: 'Lewis, IA' },
  { value: 'Lewis_KS', label: 'Lewis, KS' },
  { value: 'Lewis_CO', label: 'Lewis, CO' },
  { value: 'Lewis Center_OH', label: 'Lewis Center, OH' },
  { value: 'Lewis Run_PA', label: 'Lewis Run, PA' },
  { value: 'Lewisberry_PA', label: 'Lewisberry, PA' },
  { value: 'Lewisburg_PA', label: 'Lewisburg, PA' },
  { value: 'Lewisburg_WV', label: 'Lewisburg, WV' },
  { value: 'Lewisburg_TN', label: 'Lewisburg, TN' },
  { value: 'Lewisburg_KY', label: 'Lewisburg, KY' },
  { value: 'Lewisburg_OH', label: 'Lewisburg, OH' },
  { value: 'Lewisport_KY', label: 'Lewisport, KY' },
  { value: 'Lewiston_ME', label: 'Lewiston, ME' },
  { value: 'Lewiston_NY', label: 'Lewiston, NY' },
  { value: 'Lewiston_MI', label: 'Lewiston, MI' },
  { value: 'Lewiston_MN', label: 'Lewiston, MN' },
  { value: 'Lewiston_NE', label: 'Lewiston, NE' },
  { value: 'Lewiston_ID', label: 'Lewiston, ID' },
  { value: 'Lewiston_UT', label: 'Lewiston, UT' },
  { value: 'Lewiston_CA', label: 'Lewiston, CA' },
  { value: 'Lewiston Woodville_NC', label: 'Lewiston Woodville, NC' },
  { value: 'Lewistown_PA', label: 'Lewistown, PA' },
  { value: 'Lewistown_OH', label: 'Lewistown, OH' },
  { value: 'Lewistown_MT', label: 'Lewistown, MT' },
  { value: 'Lewistown_IL', label: 'Lewistown, IL' },
  { value: 'Lewistown_MO', label: 'Lewistown, MO' },
  { value: 'Lewisville_NC', label: 'Lewisville, NC' },
  { value: 'Lewisville_OH', label: 'Lewisville, OH' },
  { value: 'Lewisville_IN', label: 'Lewisville, IN' },
  { value: 'Lewisville_MN', label: 'Lewisville, MN' },
  { value: 'Lewisville_AR', label: 'Lewisville, AR' },
  { value: 'Lewisville_TX', label: 'Lewisville, TX' },
  { value: 'Lewisville_ID', label: 'Lewisville, ID' },
  { value: 'Lexa_AR', label: 'Lexa, AR' },
  { value: 'Lexington_MA', label: 'Lexington, MA' },
  { value: 'Lexington_NY', label: 'Lexington, NY' },
  { value: 'Lexington_VA', label: 'Lexington, VA' },
  { value: 'Lexington_NC', label: 'Lexington, NC' },
  { value: 'Lexington_SC', label: 'Lexington, SC' },
  { value: 'Lexington_GA', label: 'Lexington, GA' },
  { value: 'Lexington_AL', label: 'Lexington, AL' },
  { value: 'Lexington_TN', label: 'Lexington, TN' },
  { value: 'Lexington_MS', label: 'Lexington, MS' },
  { value: 'Lexington_KY', label: 'Lexington, KY' },
  { value: 'Lexington_IN', label: 'Lexington, IN' },
  { value: 'Lexington_MI', label: 'Lexington, MI' },
  { value: 'Lexington_IL', label: 'Lexington, IL' },
  { value: 'Lexington_MO', label: 'Lexington, MO' },
  { value: 'Lexington_NE', label: 'Lexington, NE' },
  { value: 'Lexington_OK', label: 'Lexington, OK' },
  { value: 'Lexington_TX', label: 'Lexington, TX' },
  { value: 'Lexington_OR', label: 'Lexington, OR' },
  { value: 'Lexington Park_MD', label: 'Lexington Park, MD' },
  { value: 'Libby_MT', label: 'Libby, MT' },
  { value: 'Liberal_MO', label: 'Liberal, MO' },
  { value: 'Liberal_KS', label: 'Liberal, KS' },
  { value: 'Liberty_ME', label: 'Liberty, ME' },
  { value: 'Liberty_NY', label: 'Liberty, NY' },
  { value: 'Liberty_PA', label: 'Liberty, PA' },
  { value: 'Liberty_WV', label: 'Liberty, WV' },
  { value: 'Liberty_NC', label: 'Liberty, NC' },
  { value: 'Liberty_SC', label: 'Liberty, SC' },
  { value: 'Liberty_TN', label: 'Liberty, TN' },
  { value: 'Liberty_MS', label: 'Liberty, MS' },
  { value: 'Liberty_KY', label: 'Liberty, KY' },
  { value: 'Liberty_IN', label: 'Liberty, IN' },
  { value: 'Liberty_IL', label: 'Liberty, IL' },
  { value: 'Liberty_MO', label: 'Liberty, MO' },
  { value: 'Liberty_KS', label: 'Liberty, KS' },
  { value: 'Liberty_NE', label: 'Liberty, NE' },
  { value: 'Liberty_TX', label: 'Liberty, TX' },
  { value: 'Liberty Center_OH', label: 'Liberty Center, OH' },
  { value: 'Liberty Center_IN', label: 'Liberty Center, IN' },
  { value: 'Liberty Center_IA', label: 'Liberty Center, IA' },
  { value: 'Liberty Hill_SC', label: 'Liberty Hill, SC' },
  { value: 'Liberty Hill_TX', label: 'Liberty Hill, TX' },
  { value: 'Liberty Lake_WA', label: 'Liberty Lake, WA' },
  { value: 'Liberty Mills_IN', label: 'Liberty Mills, IN' },
  { value: 'Libertytown_MD', label: 'Libertytown, MD' },
  { value: 'Libertyville_IA', label: 'Libertyville, IA' },
  { value: 'Libertyville_IL', label: 'Libertyville, IL' },
  { value: 'Lick Creek_KY', label: 'Lick Creek, KY' },
  { value: 'Licking_MO', label: 'Licking, MO' },
  { value: 'Lickingville_PA', label: 'Lickingville, PA' },
  { value: 'Lidderdale_IA', label: 'Lidderdale, IA' },
  { value: 'Lidgerwood_ND', label: 'Lidgerwood, ND' },
  { value: 'Liebenthal_KS', label: 'Liebenthal, KS' },
  { value: 'Lignite_ND', label: 'Lignite, ND' },
  { value: 'Lignum_VA', label: 'Lignum, VA' },
  { value: 'Ligonier_PA', label: 'Ligonier, PA' },
  { value: 'Ligonier_IN', label: 'Ligonier, IN' },
  { value: 'Lihue_HI', label: 'Lihue, HI' },
  { value: 'Likely_CA', label: 'Likely, CA' },
  { value: 'Lilbourn_MO', label: 'Lilbourn, MO' },
  { value: 'Lilburn_GA', label: 'Lilburn, GA' },
  { value: 'Lilesville_NC', label: 'Lilesville, NC' },
  { value: 'Lillian_AL', label: 'Lillian, AL' },
  { value: 'Lillian_TX', label: 'Lillian, TX' },
  { value: 'Lillie_LA', label: 'Lillie, LA' },
  { value: 'Lillington_NC', label: 'Lillington, NC' },
  { value: 'Lilliwaup_WA', label: 'Lilliwaup, WA' },
  { value: 'Lilly_PA', label: 'Lilly, PA' },
  { value: 'Lilly_GA', label: 'Lilly, GA' },
  { value: 'Lily_KY', label: 'Lily, KY' },
  { value: 'Lily Dale_NY', label: 'Lily Dale, NY' },
  { value: 'Lima_NY', label: 'Lima, NY' },
  { value: 'Lima_OH', label: 'Lima, OH' },
  { value: 'Lima_MT', label: 'Lima, MT' },
  { value: 'Lima_IL', label: 'Lima, IL' },
  { value: 'Limaville_OH', label: 'Limaville, OH' },
  { value: 'Lime Ridge_WI', label: 'Lime Ridge, WI' },
  { value: 'Lime Springs_IA', label: 'Lime Springs, IA' },
  { value: 'Limekiln_PA', label: 'Limekiln, PA' },
  { value: 'Limerick_ME', label: 'Limerick, ME' },
  { value: 'Limestone_ME', label: 'Limestone, ME' },
  { value: 'Limestone_NY', label: 'Limestone, NY' },
  { value: 'Limestone_TN', label: 'Limestone, TN' },
  { value: 'Limington_ME', label: 'Limington, ME' },
  { value: 'Limon_CO', label: 'Limon, CO' },
  { value: 'Linch_WY', label: 'Linch, WY' },
  { value: 'Lincoln_MA', label: 'Lincoln, MA' },
  { value: 'Lincoln_RI', label: 'Lincoln, RI' },
  { value: 'Lincoln_NH', label: 'Lincoln, NH' },
  { value: 'Lincoln_ME', label: 'Lincoln, ME' },
  { value: 'Lincoln_DE', label: 'Lincoln, DE' },
  { value: 'Lincoln_AL', label: 'Lincoln, AL' },
  { value: 'Lincoln_MI', label: 'Lincoln, MI' },
  { value: 'Lincoln_IA', label: 'Lincoln, IA' },
  { value: 'Lincoln_MT', label: 'Lincoln, MT' },
  { value: 'Lincoln_IL', label: 'Lincoln, IL' },
  { value: 'Lincoln_MO', label: 'Lincoln, MO' },
  { value: 'Lincoln_KS', label: 'Lincoln, KS' },
  { value: 'Lincoln_NE', label: 'Lincoln, NE' },
  { value: 'Lincoln_AR', label: 'Lincoln, AR' },
  { value: 'Lincoln_TX', label: 'Lincoln, TX' },
  { value: 'Lincoln_NM', label: 'Lincoln, NM' },
  { value: 'Lincoln_CA', label: 'Lincoln, CA' },
  { value: 'Lincoln_WA', label: 'Lincoln, WA' },
  { value: 'Lincoln City_IN', label: 'Lincoln City, IN' },
  { value: 'Lincoln City_OR', label: 'Lincoln City, OR' },
  { value: 'Lincoln Park_NJ', label: 'Lincoln Park, NJ' },
  { value: 'Lincoln Park_MI', label: 'Lincoln Park, MI' },
  { value: 'Lincoln University_PA', label: 'Lincoln University, PA' },
  { value: 'Lincolndale_NY', label: 'Lincolndale, NY' },
  { value: 'Lincolnshire_IL', label: 'Lincolnshire, IL' },
  { value: 'Lincolnton_NC', label: 'Lincolnton, NC' },
  { value: 'Lincolnton_GA', label: 'Lincolnton, GA' },
  { value: 'Lincolnville_ME', label: 'Lincolnville, ME' },
  { value: 'Lincolnville_KS', label: 'Lincolnville, KS' },
  { value: 'Lincolnwood_IL', label: 'Lincolnwood, IL' },
  { value: 'Lincroft_NJ', label: 'Lincroft, NJ' },
  { value: 'Lind_WA', label: 'Lind, WA' },
  { value: 'Lindale_GA', label: 'Lindale, GA' },
  { value: 'Lindale_TX', label: 'Lindale, TX' },
  { value: 'Linden_NJ', label: 'Linden, NJ' },
  { value: 'Linden_PA', label: 'Linden, PA' },
  { value: 'Linden_VA', label: 'Linden, VA' },
  { value: 'Linden_NC', label: 'Linden, NC' },
  { value: 'Linden_AL', label: 'Linden, AL' },
  { value: 'Linden_TN', label: 'Linden, TN' },
  { value: 'Linden_IN', label: 'Linden, IN' },
  { value: 'Linden_MI', label: 'Linden, MI' },
  { value: 'Linden_IA', label: 'Linden, IA' },
  { value: 'Linden_WI', label: 'Linden, WI' },
  { value: 'Linden_TX', label: 'Linden, TX' },
  { value: 'Linden_CA', label: 'Linden, CA' },
  { value: 'Lindenhurst_NY', label: 'Lindenhurst, NY' },
  { value: 'Lindenwood_IL', label: 'Lindenwood, IL' },
  { value: 'Lindley_NY', label: 'Lindley, NY' },
  { value: 'Lindon_CO', label: 'Lindon, CO' },
  { value: 'Lindon_UT', label: 'Lindon, UT' },
  { value: 'Lindrith_NM', label: 'Lindrith, NM' },
  { value: 'Lindsay_MT', label: 'Lindsay, MT' },
  { value: 'Lindsay_NE', label: 'Lindsay, NE' },
  { value: 'Lindsay_OK', label: 'Lindsay, OK' },
  { value: 'Lindsay_TX', label: 'Lindsay, TX' },
  { value: 'Lindsay_CA', label: 'Lindsay, CA' },
  { value: 'Lindsborg_KS', label: 'Lindsborg, KS' },
  { value: 'Lindsey_OH', label: 'Lindsey, OH' },
  { value: 'Lindside_WV', label: 'Lindside, WV' },
  { value: 'Lindstrom_MN', label: 'Lindstrom, MN' },
  { value: 'Line Lexington_PA', label: 'Line Lexington, PA' },
  { value: 'Linefork_KY', label: 'Linefork, KY' },
  { value: 'Linesville_PA', label: 'Linesville, PA' },
  { value: 'Lineville_AL', label: 'Lineville, AL' },
  { value: 'Lineville_IA', label: 'Lineville, IA' },
  { value: 'Lingle_WY', label: 'Lingle, WY' },
  { value: 'Linkwood_MD', label: 'Linkwood, MD' },
  { value: 'Linn_WV', label: 'Linn, WV' },
  { value: 'Linn_MO', label: 'Linn, MO' },
  { value: 'Linn_KS', label: 'Linn, KS' },
  { value: 'Linn_TX', label: 'Linn, TX' },
  { value: 'Linn Creek_MO', label: 'Linn Creek, MO' },
  { value: 'Linn Grove_IA', label: 'Linn Grove, IA' },
  { value: 'Linneus_MO', label: 'Linneus, MO' },
  { value: 'Linthicum Heights_MD', label: 'Linthicum Heights, MD' },
  { value: 'Linton_IN', label: 'Linton, IN' },
  { value: 'Linton_ND', label: 'Linton, ND' },
  { value: 'Linville_VA', label: 'Linville, VA' },
  { value: 'Linville_NC', label: 'Linville, NC' },
  { value: 'Linwood_MA', label: 'Linwood, MA' },
  { value: 'Linwood_NJ', label: 'Linwood, NJ' },
  { value: 'Linwood_NY', label: 'Linwood, NY' },
  { value: 'Linwood_NC', label: 'Linwood, NC' },
  { value: 'Linwood_MI', label: 'Linwood, MI' },
  { value: 'Linwood_KS', label: 'Linwood, KS' },
  { value: 'Linwood_NE', label: 'Linwood, NE' },
  { value: 'Lipan_TX', label: 'Lipan, TX' },
  { value: 'Lipscomb_TX', label: 'Lipscomb, TX' },
  { value: 'Lisbon_NH', label: 'Lisbon, NH' },
  { value: 'Lisbon_ME', label: 'Lisbon, ME' },
  { value: 'Lisbon_NY', label: 'Lisbon, NY' },
  { value: 'Lisbon_OH', label: 'Lisbon, OH' },
  { value: 'Lisbon_IA', label: 'Lisbon, IA' },
  { value: 'Lisbon_ND', label: 'Lisbon, ND' },
  { value: 'Lisbon_LA', label: 'Lisbon, LA' },
  { value: 'Lisbon Falls_ME', label: 'Lisbon Falls, ME' },
  { value: 'Lisco_NE', label: 'Lisco, NE' },
  { value: 'Liscomb_IA', label: 'Liscomb, IA' },
  { value: 'Lisle_NY', label: 'Lisle, NY' },
  { value: 'Lisle_IL', label: 'Lisle, IL' },
  { value: 'Lisman_AL', label: 'Lisman, AL' },
  { value: 'Lismore_MN', label: 'Lismore, MN' },
  { value: 'Lissie_TX', label: 'Lissie, TX' },
  { value: 'Litchfield_NH', label: 'Litchfield, NH' },
  { value: 'Litchfield_ME', label: 'Litchfield, ME' },
  { value: 'Litchfield_CT', label: 'Litchfield, CT' },
  { value: 'Litchfield_OH', label: 'Litchfield, OH' },
  { value: 'Litchfield_MI', label: 'Litchfield, MI' },
  { value: 'Litchfield_MN', label: 'Litchfield, MN' },
  { value: 'Litchfield_IL', label: 'Litchfield, IL' },
  { value: 'Litchfield_NE', label: 'Litchfield, NE' },
  { value: 'Litchfield_CA', label: 'Litchfield, CA' },
  { value: 'Litchfield Park_AZ', label: 'Litchfield Park, AZ' },
  { value: 'Litchville_ND', label: 'Litchville, ND' },
  { value: 'Lithia_FL', label: 'Lithia, FL' },
  { value: 'Lithia Springs_GA', label: 'Lithia Springs, GA' },
  { value: 'Lithonia_GA', label: 'Lithonia, GA' },
  { value: 'Lithopolis_OH', label: 'Lithopolis, OH' },
  { value: 'Lititz_PA', label: 'Lititz, PA' },
  { value: 'Littcarr_KY', label: 'Littcarr, KY' },
  { value: 'Little America_WY', label: 'Little America, WY' },
  { value: 'Little Birch_WV', label: 'Little Birch, WV' },
  { value: 'Little Cedar_IA', label: 'Little Cedar, IA' },
  { value: 'Little Chute_WI', label: 'Little Chute, WI' },
  { value: 'Little Compton_RI', label: 'Little Compton, RI' },
  { value: 'Little Deer Isle_ME', label: 'Little Deer Isle, ME' },
  { value: 'Little Eagle_SD', label: 'Little Eagle, SD' },
  { value: 'Little Elm_TX', label: 'Little Elm, TX' },
  { value: 'Little Falls_NJ', label: 'Little Falls, NJ' },
  { value: 'Little Falls_NY', label: 'Little Falls, NY' },
  { value: 'Little Falls_MN', label: 'Little Falls, MN' },
  { value: 'Little Ferry_NJ', label: 'Little Ferry, NJ' },
  { value: 'Little Genesee_NY', label: 'Little Genesee, NY' },
  { value: 'Little Hocking_OH', label: 'Little Hocking, OH' },
  { value: 'Little Lake_MI', label: 'Little Lake, MI' },
  { value: 'Little Meadows_PA', label: 'Little Meadows, PA' },
  { value: 'Little Mountain_SC', label: 'Little Mountain, SC' },
  { value: 'Little Neck_NY', label: 'Little Neck, NY' },
  { value: 'Little Orleans_MD', label: 'Little Orleans, MD' },
  { value: 'Little Plymouth_VA', label: 'Little Plymouth, VA' },
  { value: 'Little River_SC', label: 'Little River, SC' },
  { value: 'Little River_AL', label: 'Little River, AL' },
  { value: 'Little River_KS', label: 'Little River, KS' },
  { value: 'Little River_CA', label: 'Little River, CA' },
  { value: 'Little River Academy_TX', label: 'Little River Academy, TX' },
  { value: 'Little Rock_SC', label: 'Little Rock, SC' },
  { value: 'Little Rock_MS', label: 'Little Rock, MS' },
  { value: 'Little Rock_IA', label: 'Little Rock, IA' },
  { value: 'Little Rock_AR', label: 'Little Rock, AR' },
  {
    value: 'Little Rock Air Force Base_AR',
    label: 'Little Rock Air Force Base, AR',
  },
  { value: 'Little Silver_NJ', label: 'Little Silver, NJ' },
  { value: 'Little Sioux_IA', label: 'Little Sioux, IA' },
  { value: 'Little Suamico_WI', label: 'Little Suamico, WI' },
  { value: 'Little Switzerland_NC', label: 'Little Switzerland, NC' },
  { value: 'Little Valley_NY', label: 'Little Valley, NY' },
  { value: 'Little York_NY', label: 'Little York, NY' },
  { value: 'Little York_IL', label: 'Little York, IL' },
  { value: 'Littlefield_TX', label: 'Littlefield, TX' },
  { value: 'Littlefield_AZ', label: 'Littlefield, AZ' },
  { value: 'Littlefork_MN', label: 'Littlefork, MN' },
  { value: 'Littlerock_CA', label: 'Littlerock, CA' },
  { value: 'Littlerock_WA', label: 'Littlerock, WA' },
  { value: 'Littlestown_PA', label: 'Littlestown, PA' },
  { value: 'Littleton_MA', label: 'Littleton, MA' },
  { value: 'Littleton_NH', label: 'Littleton, NH' },
  { value: 'Littleton_WV', label: 'Littleton, WV' },
  { value: 'Littleton_NC', label: 'Littleton, NC' },
  { value: 'Littleton_IL', label: 'Littleton, IL' },
  { value: 'Littleton_CO', label: 'Littleton, CO' },
  { value: 'Live Oak_FL', label: 'Live Oak, FL' },
  { value: 'Live Oak_CA', label: 'Live Oak, CA' },
  { value: 'Livermore_ME', label: 'Livermore, ME' },
  { value: 'Livermore_KY', label: 'Livermore, KY' },
  { value: 'Livermore_IA', label: 'Livermore, IA' },
  { value: 'Livermore_CO', label: 'Livermore, CO' },
  { value: 'Livermore_CA', label: 'Livermore, CA' },
  { value: 'Livermore Falls_ME', label: 'Livermore Falls, ME' },
  { value: 'Liverpool_NY', label: 'Liverpool, NY' },
  { value: 'Liverpool_PA', label: 'Liverpool, PA' },
  { value: 'Liverpool_IL', label: 'Liverpool, IL' },
  { value: 'Liverpool_TX', label: 'Liverpool, TX' },
  { value: 'Livingston_NJ', label: 'Livingston, NJ' },
  { value: 'Livingston_AL', label: 'Livingston, AL' },
  { value: 'Livingston_TN', label: 'Livingston, TN' },
  { value: 'Livingston_KY', label: 'Livingston, KY' },
  { value: 'Livingston_WI', label: 'Livingston, WI' },
  { value: 'Livingston_MT', label: 'Livingston, MT' },
  { value: 'Livingston_IL', label: 'Livingston, IL' },
  { value: 'Livingston_LA', label: 'Livingston, LA' },
  { value: 'Livingston_TX', label: 'Livingston, TX' },
  { value: 'Livingston_CA', label: 'Livingston, CA' },
  { value: 'Livingston Manor_NY', label: 'Livingston Manor, NY' },
  { value: 'Livonia_NY', label: 'Livonia, NY' },
  { value: 'Livonia_MI', label: 'Livonia, MI' },
  { value: 'Livonia_MO', label: 'Livonia, MO' },
  { value: 'Livonia_LA', label: 'Livonia, LA' },
  { value: 'Lizella_GA', label: 'Lizella, GA' },
  { value: 'Lizemores_WV', label: 'Lizemores, WV' },
  { value: 'Lizton_IN', label: 'Lizton, IN' },
  { value: 'Llano_TX', label: 'Llano, TX' },
  { value: 'Llano_NM', label: 'Llano, NM' },
  { value: 'Llano_CA', label: 'Llano, CA' },
  { value: 'Llewellyn_PA', label: 'Llewellyn, PA' },
  { value: 'Lloyd_FL', label: 'Lloyd, FL' },
  { value: 'Lloyd_MT', label: 'Lloyd, MT' },
  { value: 'Loa_UT', label: 'Loa, UT' },
  { value: 'Loachapoka_AL', label: 'Loachapoka, AL' },
  { value: 'Loami_IL', label: 'Loami, IL' },
  { value: 'Lobelville_TN', label: 'Lobelville, TN' },
  { value: 'Loch Sheldrake_NY', label: 'Loch Sheldrake, NY' },
  { value: 'Lochgelly_WV', label: 'Lochgelly, WV' },
  { value: 'Lock Haven_PA', label: 'Lock Haven, PA' },
  { value: 'Lock Springs_MO', label: 'Lock Springs, MO' },
  { value: 'Lockbourne_OH', label: 'Lockbourne, OH' },
  { value: 'Locke_NY', label: 'Locke, NY' },
  { value: 'Lockeford_CA', label: 'Lockeford, CA' },
  { value: 'Lockesburg_AR', label: 'Lockesburg, AR' },
  { value: 'Lockhart_SC', label: 'Lockhart, SC' },
  { value: 'Lockhart_AL', label: 'Lockhart, AL' },
  { value: 'Lockhart_TX', label: 'Lockhart, TX' },
  { value: 'Lockney_TX', label: 'Lockney, TX' },
  { value: 'Lockport_NY', label: 'Lockport, NY' },
  { value: 'Lockport_KY', label: 'Lockport, KY' },
  { value: 'Lockport_IL', label: 'Lockport, IL' },
  { value: 'Lockport_LA', label: 'Lockport, LA' },
  { value: 'Lockridge_IA', label: 'Lockridge, IA' },
  { value: 'Lockwood_NY', label: 'Lockwood, NY' },
  { value: 'Lockwood_MO', label: 'Lockwood, MO' },
  { value: 'Lockwood_CA', label: 'Lockwood, CA' },
  { value: 'Loco_OK', label: 'Loco, OK' },
  { value: 'Loco Hills_NM', label: 'Loco Hills, NM' },
  { value: 'Locust_NC', label: 'Locust, NC' },
  { value: 'Locust Dale_VA', label: 'Locust Dale, VA' },
  { value: 'Locust Fork_AL', label: 'Locust Fork, AL' },
  { value: 'Locust Gap_PA', label: 'Locust Gap, PA' },
  { value: 'Locust Grove_VA', label: 'Locust Grove, VA' },
  { value: 'Locust Grove_GA', label: 'Locust Grove, GA' },
  { value: 'Locust Grove_AR', label: 'Locust Grove, AR' },
  { value: 'Locust Grove_OK', label: 'Locust Grove, OK' },
  { value: 'Locust Hill_VA', label: 'Locust Hill, VA' },
  { value: 'Locust Valley_NY', label: 'Locust Valley, NY' },
  { value: 'Locustdale_PA', label: 'Locustdale, PA' },
  { value: 'Loda_IL', label: 'Loda, IL' },
  { value: 'Lodge_SC', label: 'Lodge, SC' },
  { value: 'Lodge Grass_MT', label: 'Lodge Grass, MT' },
  { value: 'Lodgepole_SD', label: 'Lodgepole, SD' },
  { value: 'Lodgepole_NE', label: 'Lodgepole, NE' },
  { value: 'Lodi_NJ', label: 'Lodi, NJ' },
  { value: 'Lodi_NY', label: 'Lodi, NY' },
  { value: 'Lodi_OH', label: 'Lodi, OH' },
  { value: 'Lodi_WI', label: 'Lodi, WI' },
  { value: 'Lodi_MO', label: 'Lodi, MO' },
  { value: 'Lodi_TX', label: 'Lodi, TX' },
  { value: 'Lodi_CA', label: 'Lodi, CA' },
  { value: 'Log Lane Village_CO', label: 'Log Lane Village, CO' },
  { value: 'Logan_WV', label: 'Logan, WV' },
  { value: 'Logan_AL', label: 'Logan, AL' },
  { value: 'Logan_OH', label: 'Logan, OH' },
  { value: 'Logan_IA', label: 'Logan, IA' },
  { value: 'Logan_IL', label: 'Logan, IL' },
  { value: 'Logan_KS', label: 'Logan, KS' },
  { value: 'Logan_UT', label: 'Logan, UT' },
  { value: 'Logan_NM', label: 'Logan, NM' },
  { value: 'Logandale_NV', label: 'Logandale, NV' },
  { value: 'Logansport_IN', label: 'Logansport, IN' },
  { value: 'Logansport_LA', label: 'Logansport, LA' },
  { value: 'Loganton_PA', label: 'Loganton, PA' },
  { value: 'Loganville_PA', label: 'Loganville, PA' },
  { value: 'Loganville_GA', label: 'Loganville, GA' },
  { value: 'Loganville_WI', label: 'Loganville, WI' },
  { value: 'Logsden_OR', label: 'Logsden, OR' },
  { value: 'Lohman_MO', label: 'Lohman, MO' },
  { value: 'Lohn_TX', label: 'Lohn, TX' },
  { value: 'Lohrville_IA', label: 'Lohrville, IA' },
  { value: 'Loiza_PR', label: 'Loiza, PR' },
  { value: 'Loleta_CA', label: 'Loleta, CA' },
  { value: 'Lolita_TX', label: 'Lolita, TX' },
  { value: 'Lolo_MT', label: 'Lolo, MT' },
  { value: 'Loma_MT', label: 'Loma, MT' },
  { value: 'Loma_CO', label: 'Loma, CO' },
  { value: 'Loma Linda_CA', label: 'Loma Linda, CA' },
  { value: 'Loma Mar_CA', label: 'Loma Mar, CA' },
  { value: 'Loman_MN', label: 'Loman, MN' },
  { value: 'Lomax_IL', label: 'Lomax, IL' },
  { value: 'Lombard_IL', label: 'Lombard, IL' },
  { value: 'Lometa_TX', label: 'Lometa, TX' },
  { value: 'Lomira_WI', label: 'Lomira, WI' },
  { value: 'Lomita_CA', label: 'Lomita, CA' },
  { value: 'Lompoc_CA', label: 'Lompoc, CA' },
  { value: 'Lonaconing_MD', label: 'Lonaconing, MD' },
  { value: 'London_WV', label: 'London, WV' },
  { value: 'London_KY', label: 'London, KY' },
  { value: 'London_OH', label: 'London, OH' },
  { value: 'London_AR', label: 'London, AR' },
  { value: 'London_TX', label: 'London, TX' },
  { value: 'London Mills_IL', label: 'London Mills, IL' },
  { value: 'Londonderry_NH', label: 'Londonderry, NH' },
  { value: 'Londonderry_VT', label: 'Londonderry, VT' },
  { value: 'Londonderry_OH', label: 'Londonderry, OH' },
  { value: 'Lone Grove_OK', label: 'Lone Grove, OK' },
  { value: 'Lone Jack_MO', label: 'Lone Jack, MO' },
  { value: 'Lone Oak_TX', label: 'Lone Oak, TX' },
  { value: 'Lone Pine_CA', label: 'Lone Pine, CA' },
  { value: 'Lone Rock_IA', label: 'Lone Rock, IA' },
  { value: 'Lone Rock_WI', label: 'Lone Rock, WI' },
  { value: 'Lone Star_TX', label: 'Lone Star, TX' },
  { value: 'Lone Tree_IA', label: 'Lone Tree, IA' },
  { value: 'Lone Tree_CO', label: 'Lone Tree, CO' },
  { value: 'Lone Wolf_OK', label: 'Lone Wolf, OK' },
  { value: 'Lonedell_MO', label: 'Lonedell, MO' },
  { value: 'Lonepine_MT', label: 'Lonepine, MT' },
  { value: 'Lonetree_WY', label: 'Lonetree, WY' },
  { value: 'Long Barn_CA', label: 'Long Barn, CA' },
  { value: 'Long Beach_NY', label: 'Long Beach, NY' },
  { value: 'Long Beach_MS', label: 'Long Beach, MS' },
  { value: 'Long Beach_CA', label: 'Long Beach, CA' },
  { value: 'Long Beach_WA', label: 'Long Beach, WA' },
  { value: 'Long Bottom_OH', label: 'Long Bottom, OH' },
  { value: 'Long Branch_NJ', label: 'Long Branch, NJ' },
  { value: 'Long Branch_TX', label: 'Long Branch, TX' },
  { value: 'Long Creek_SC', label: 'Long Creek, SC' },
  { value: 'Long Creek_OR', label: 'Long Creek, OR' },
  { value: 'Long Eddy_NY', label: 'Long Eddy, NY' },
  { value: 'Long Grove_IA', label: 'Long Grove, IA' },
  { value: 'Long Island_ME', label: 'Long Island, ME' },
  { value: 'Long Island_VA', label: 'Long Island, VA' },
  { value: 'Long Island_KS', label: 'Long Island, KS' },
  { value: 'Long Island City_NY', label: 'Long Island City, NY' },
  { value: 'Long Key_FL', label: 'Long Key, FL' },
  { value: 'Long Lake_NY', label: 'Long Lake, NY' },
  { value: 'Long Lake_MI', label: 'Long Lake, MI' },
  { value: 'Long Lake_WI', label: 'Long Lake, WI' },
  { value: 'Long Lake_MN', label: 'Long Lake, MN' },
  { value: 'Long Lake_SD', label: 'Long Lake, SD' },
  { value: 'Long Lane_MO', label: 'Long Lane, MO' },
  { value: 'Long Pine_NE', label: 'Long Pine, NE' },
  { value: 'Long Point_IL', label: 'Long Point, IL' },
  { value: 'Long Pond_PA', label: 'Long Pond, PA' },
  { value: 'Long Prairie_MN', label: 'Long Prairie, MN' },
  { value: 'Long Valley_NJ', label: 'Long Valley, NJ' },
  { value: 'Long Valley_SD', label: 'Long Valley, SD' },
  { value: 'Longboat Key_FL', label: 'Longboat Key, FL' },
  { value: 'Longbranch_WA', label: 'Longbranch, WA' },
  { value: 'Longdale_OK', label: 'Longdale, OK' },
  { value: 'Longford_KS', label: 'Longford, KS' },
  { value: 'Longmeadow_MA', label: 'Longmeadow, MA' },
  { value: 'Longmont_CO', label: 'Longmont, CO' },
  { value: 'Longport_NJ', label: 'Longport, NJ' },
  { value: 'Longs_SC', label: 'Longs, SC' },
  { value: 'Longton_KS', label: 'Longton, KS' },
  { value: 'Longview_IL', label: 'Longview, IL' },
  { value: 'Longview_TX', label: 'Longview, TX' },
  { value: 'Longview_WA', label: 'Longview, WA' },
  { value: 'Longville_MN', label: 'Longville, MN' },
  { value: 'Longville_LA', label: 'Longville, LA' },
  { value: 'Longwood_NC', label: 'Longwood, NC' },
  { value: 'Longwood_FL', label: 'Longwood, FL' },
  { value: 'Lonoke_AR', label: 'Lonoke, AR' },
  { value: 'Lonsdale_MN', label: 'Lonsdale, MN' },
  { value: 'Lonsdale_AR', label: 'Lonsdale, AR' },
  { value: 'Loogootee_IN', label: 'Loogootee, IN' },
  { value: 'Lookeba_OK', label: 'Lookeba, OK' },
  { value: 'Lookout_WV', label: 'Lookout, WV' },
  { value: 'Lookout_CA', label: 'Lookout, CA' },
  { value: 'Lookout Mountain_GA', label: 'Lookout Mountain, GA' },
  { value: 'Lookout Mountain_TN', label: 'Lookout Mountain, TN' },
  { value: 'Loomis_NE', label: 'Loomis, NE' },
  { value: 'Loomis_CA', label: 'Loomis, CA' },
  { value: 'Loomis_WA', label: 'Loomis, WA' },
  { value: 'Loon Lake_WA', label: 'Loon Lake, WA' },
  { value: 'Looneyville_WV', label: 'Looneyville, WV' },
  { value: 'Loop_TX', label: 'Loop, TX' },
  { value: 'Loose Creek_MO', label: 'Loose Creek, MO' },
  { value: 'Lopeno_TX', label: 'Lopeno, TX' },
  { value: 'Lopez_PA', label: 'Lopez, PA' },
  { value: 'Lopez Island_WA', label: 'Lopez Island, WA' },
  { value: 'Lorado_WV', label: 'Lorado, WV' },
  { value: 'Lorain_OH', label: 'Lorain, OH' },
  { value: 'Loraine_IL', label: 'Loraine, IL' },
  { value: 'Loraine_TX', label: 'Loraine, TX' },
  { value: 'Lorane_OR', label: 'Lorane, OR' },
  { value: 'Loranger_LA', label: 'Loranger, LA' },
  { value: 'Lordsburg_NM', label: 'Lordsburg, NM' },
  { value: 'Lore City_OH', label: 'Lore City, OH' },
  { value: 'Loreauville_LA', label: 'Loreauville, LA' },
  { value: 'Lorena_TX', label: 'Lorena, TX' },
  { value: 'Lorenzo_TX', label: 'Lorenzo, TX' },
  { value: 'Loretto_PA', label: 'Loretto, PA' },
  { value: 'Loretto_VA', label: 'Loretto, VA' },
  { value: 'Loretto_TN', label: 'Loretto, TN' },
  { value: 'Loretto_KY', label: 'Loretto, KY' },
  { value: 'Loretto_MI', label: 'Loretto, MI' },
  { value: 'Loretto_MN', label: 'Loretto, MN' },
  { value: 'Lorida_FL', label: 'Lorida, FL' },
  { value: 'Lorimor_IA', label: 'Lorimor, IA' },
  { value: 'Loring_MT', label: 'Loring, MT' },
  { value: 'Loris_SC', label: 'Loris, SC' },
  { value: 'Lorman_MS', label: 'Lorman, MS' },
  { value: 'Lorraine_NY', label: 'Lorraine, NY' },
  { value: 'Lorraine_KS', label: 'Lorraine, KS' },
  { value: 'Lorton_VA', label: 'Lorton, VA' },
  { value: 'Lorton_NE', label: 'Lorton, NE' },
  { value: 'Los Alamitos_CA', label: 'Los Alamitos, CA' },
  { value: 'Los Alamos_NM', label: 'Los Alamos, NM' },
  { value: 'Los Alamos_CA', label: 'Los Alamos, CA' },
  { value: 'Los Altos_CA', label: 'Los Altos, CA' },
  { value: 'Los Angeles_CA', label: 'Los Angeles, CA' },
  { value: 'Los Banos_CA', label: 'Los Banos, CA' },
  { value: 'Los Ebanos_TX', label: 'Los Ebanos, TX' },
  { value: 'Los Fresnos_TX', label: 'Los Fresnos, TX' },
  { value: 'Los Gatos_CA', label: 'Los Gatos, CA' },
  { value: 'Los Indios_TX', label: 'Los Indios, TX' },
  { value: 'Los Lunas_NM', label: 'Los Lunas, NM' },
  { value: 'Los Molinos_CA', label: 'Los Molinos, CA' },
  { value: 'Los Ojos_NM', label: 'Los Ojos, NM' },
  { value: 'Los Olivos_CA', label: 'Los Olivos, CA' },
  { value: 'Los Osos_CA', label: 'Los Osos, CA' },
  { value: 'Losantville_IN', label: 'Losantville, IN' },
  { value: 'Lost City_WV', label: 'Lost City, WV' },
  { value: 'Lost Creek_PA', label: 'Lost Creek, PA' },
  { value: 'Lost Creek_WV', label: 'Lost Creek, WV' },
  { value: 'Lost Creek_KY', label: 'Lost Creek, KY' },
  { value: 'Lost Hills_CA', label: 'Lost Hills, CA' },
  { value: 'Lost Nation_IA', label: 'Lost Nation, IA' },
  { value: 'Lost Springs_KS', label: 'Lost Springs, KS' },
  { value: 'Lost Springs_WY', label: 'Lost Springs, WY' },
  { value: 'Lostant_IL', label: 'Lostant, IL' },
  { value: 'Lostine_OR', label: 'Lostine, OR' },
  { value: 'Lothair_MT', label: 'Lothair, MT' },
  { value: 'Lothian_MD', label: 'Lothian, MD' },
  { value: 'Lott_TX', label: 'Lott, TX' },
  { value: 'Lottie_LA', label: 'Lottie, LA' },
  { value: 'Lottsburg_VA', label: 'Lottsburg, VA' },
  { value: 'Lotus_CA', label: 'Lotus, CA' },
  { value: 'Louann_AR', label: 'Louann, AR' },
  { value: 'Loudon_NH', label: 'Loudon, NH' },
  { value: 'Loudon_TN', label: 'Loudon, TN' },
  { value: 'Loudonville_OH', label: 'Loudonville, OH' },
  { value: 'Loughman_FL', label: 'Loughman, FL' },
  { value: 'Louin_MS', label: 'Louin, MS' },
  { value: 'Louisa_VA', label: 'Louisa, VA' },
  { value: 'Louisa_KY', label: 'Louisa, KY' },
  { value: 'Louisburg_NC', label: 'Louisburg, NC' },
  { value: 'Louisburg_MO', label: 'Louisburg, MO' },
  { value: 'Louisburg_KS', label: 'Louisburg, KS' },
  { value: 'Louise_MS', label: 'Louise, MS' },
  { value: 'Louise_TX', label: 'Louise, TX' },
  { value: 'Louisiana_MO', label: 'Louisiana, MO' },
  { value: 'Louisville_GA', label: 'Louisville, GA' },
  { value: 'Louisville_AL', label: 'Louisville, AL' },
  { value: 'Louisville_TN', label: 'Louisville, TN' },
  { value: 'Louisville_MS', label: 'Louisville, MS' },
  { value: 'Louisville_KY', label: 'Louisville, KY' },
  { value: 'Louisville_OH', label: 'Louisville, OH' },
  { value: 'Louisville_IL', label: 'Louisville, IL' },
  { value: 'Louisville_NE', label: 'Louisville, NE' },
  { value: 'Louisville_CO', label: 'Louisville, CO' },
  { value: 'Loup City_NE', label: 'Loup City, NE' },
  { value: 'Louvale_GA', label: 'Louvale, GA' },
  { value: 'Louviers_CO', label: 'Louviers, CO' },
  { value: 'Lovejoy_GA', label: 'Lovejoy, GA' },
  { value: 'Lovejoy_IL', label: 'Lovejoy, IL' },
  { value: 'Lovelaceville_KY', label: 'Lovelaceville, KY' },
  { value: 'Lovelady_TX', label: 'Lovelady, TX' },
  { value: 'Loveland_OH', label: 'Loveland, OH' },
  { value: 'Loveland_OK', label: 'Loveland, OK' },
  { value: 'Loveland_CO', label: 'Loveland, CO' },
  { value: 'Lovell_ME', label: 'Lovell, ME' },
  { value: 'Lovell_WY', label: 'Lovell, WY' },
  { value: 'Lovelock_NV', label: 'Lovelock, NV' },
  { value: 'Lovely_KY', label: 'Lovely, KY' },
  { value: 'Loves Park_IL', label: 'Loves Park, IL' },
  { value: 'Lovettsville_VA', label: 'Lovettsville, VA' },
  { value: 'Loveville_MD', label: 'Loveville, MD' },
  { value: 'Lovilia_IA', label: 'Lovilia, IA' },
  { value: 'Loving_TX', label: 'Loving, TX' },
  { value: 'Loving_NM', label: 'Loving, NM' },
  { value: 'Lovingston_VA', label: 'Lovingston, VA' },
  { value: 'Lovington_IL', label: 'Lovington, IL' },
  { value: 'Lovington_NM', label: 'Lovington, NM' },
  { value: 'Low Moor_VA', label: 'Low Moor, VA' },
  { value: 'Low Moor_IA', label: 'Low Moor, IA' },
  { value: 'Lowber_PA', label: 'Lowber, PA' },
  { value: 'Lowden_IA', label: 'Lowden, IA' },
  { value: 'Lowell_MA', label: 'Lowell, MA' },
  { value: 'Lowell_VT', label: 'Lowell, VT' },
  { value: 'Lowell_NC', label: 'Lowell, NC' },
  { value: 'Lowell_OH', label: 'Lowell, OH' },
  { value: 'Lowell_IN', label: 'Lowell, IN' },
  { value: 'Lowell_MI', label: 'Lowell, MI' },
  { value: 'Lowell_WI', label: 'Lowell, WI' },
  { value: 'Lowell_AR', label: 'Lowell, AR' },
  { value: 'Lowell_OR', label: 'Lowell, OR' },
  { value: 'Lowellville_OH', label: 'Lowellville, OH' },
  { value: 'Lower Brule_SD', label: 'Lower Brule, SD' },
  { value: 'Lower Kalskag_AK', label: 'Lower Kalskag, AK' },
  { value: 'Lower Lake_CA', label: 'Lower Lake, CA' },
  { value: 'Lower Peach Tree_AL', label: 'Lower Peach Tree, AL' },
  { value: 'Lower Salem_OH', label: 'Lower Salem, OH' },
  { value: 'Lowes_KY', label: 'Lowes, KY' },
  { value: 'Lowgap_NC', label: 'Lowgap, NC' },
  { value: 'Lowland_NC', label: 'Lowland, NC' },
  { value: 'Lowman_NY', label: 'Lowman, NY' },
  { value: 'Lowman_ID', label: 'Lowman, ID' },
  { value: 'Lowmansville_KY', label: 'Lowmansville, KY' },
  { value: 'Lowndes_MO', label: 'Lowndes, MO' },
  { value: 'Lowndesboro_AL', label: 'Lowndesboro, AL' },
  { value: 'Lowndesville_SC', label: 'Lowndesville, SC' },
  { value: 'Lowpoint_IL', label: 'Lowpoint, IL' },
  { value: 'Lowry_VA', label: 'Lowry, VA' },
  { value: 'Lowry_MN', label: 'Lowry, MN' },
  { value: 'Lowry City_MO', label: 'Lowry City, MO' },
  { value: 'Lowville_NY', label: 'Lowville, NY' },
  { value: 'Loxahatchee_FL', label: 'Loxahatchee, FL' },
  { value: 'Loxley_AL', label: 'Loxley, AL' },
  { value: 'Loyal_WI', label: 'Loyal, WI' },
  { value: 'Loyal_OK', label: 'Loyal, OK' },
  { value: 'Loyalhanna_PA', label: 'Loyalhanna, PA' },
  { value: 'Loyall_KY', label: 'Loyall, KY' },
  { value: 'Loyalton_CA', label: 'Loyalton, CA' },
  { value: 'Loysburg_PA', label: 'Loysburg, PA' },
  { value: 'Loysville_PA', label: 'Loysville, PA' },
  { value: 'Lu Verne_IA', label: 'Lu Verne, IA' },
  { value: 'Luana_IA', label: 'Luana, IA' },
  { value: 'Lubbock_TX', label: 'Lubbock, TX' },
  { value: 'Lubec_ME', label: 'Lubec, ME' },
  { value: 'Lublin_WI', label: 'Lublin, WI' },
  { value: 'Lucama_NC', label: 'Lucama, NC' },
  { value: 'Lucan_MN', label: 'Lucan, MN' },
  { value: 'Lucas_KY', label: 'Lucas, KY' },
  { value: 'Lucas_OH', label: 'Lucas, OH' },
  { value: 'Lucas_IA', label: 'Lucas, IA' },
  { value: 'Lucas_KS', label: 'Lucas, KS' },
  { value: 'Lucasville_OH', label: 'Lucasville, OH' },
  { value: 'Lucedale_MS', label: 'Lucedale, MS' },
  { value: 'Lucerne_IN', label: 'Lucerne, IN' },
  { value: 'Lucerne_MO', label: 'Lucerne, MO' },
  { value: 'Lucerne_CO', label: 'Lucerne, CO' },
  { value: 'Lucerne_CA', label: 'Lucerne, CA' },
  { value: 'Lucerne Valley_CA', label: 'Lucerne Valley, CA' },
  { value: 'Lucernemines_PA', label: 'Lucernemines, PA' },
  { value: 'Lucien_OK', label: 'Lucien, OK' },
  { value: 'Lucile_ID', label: 'Lucile, ID' },
  { value: 'Lucinda_PA', label: 'Lucinda, PA' },
  { value: 'Luck_WI', label: 'Luck, WI' },
  { value: 'Luckey_OH', label: 'Luckey, OH' },
  { value: 'Ludell_KS', label: 'Ludell, KS' },
  { value: 'Ludington_MI', label: 'Ludington, MI' },
  { value: 'Ludlow_MA', label: 'Ludlow, MA' },
  { value: 'Ludlow_VT', label: 'Ludlow, VT' },
  { value: 'Ludlow_PA', label: 'Ludlow, PA' },
  { value: 'Ludlow_SD', label: 'Ludlow, SD' },
  { value: 'Ludlow_IL', label: 'Ludlow, IL' },
  { value: 'Ludlow_MO', label: 'Ludlow, MO' },
  { value: 'Ludlow_CA', label: 'Ludlow, CA' },
  { value: 'Ludlow Falls_OH', label: 'Ludlow Falls, OH' },
  { value: 'Ludowici_GA', label: 'Ludowici, GA' },
  { value: 'Luebbering_MO', label: 'Luebbering, MO' },
  { value: 'Lueders_TX', label: 'Lueders, TX' },
  { value: 'Lufkin_TX', label: 'Lufkin, TX' },
  { value: 'Lugoff_SC', label: 'Lugoff, SC' },
  { value: 'Lukachukai_AZ', label: 'Lukachukai, AZ' },
  { value: 'Luke_MD', label: 'Luke, MD' },
  { value: 'Luke Air Force Base_AZ', label: 'Luke Air Force Base, AZ' },
  { value: 'Lukeville_AZ', label: 'Lukeville, AZ' },
  { value: 'Lula_GA', label: 'Lula, GA' },
  { value: 'Lula_MS', label: 'Lula, MS' },
  { value: 'Luling_LA', label: 'Luling, LA' },
  { value: 'Luling_TX', label: 'Luling, TX' },
  { value: 'Lulu_FL', label: 'Lulu, FL' },
  { value: 'Lumber Bridge_NC', label: 'Lumber Bridge, NC' },
  { value: 'Lumber City_GA', label: 'Lumber City, GA' },
  { value: 'Lumberport_WV', label: 'Lumberport, WV' },
  { value: 'Lumberton_NJ', label: 'Lumberton, NJ' },
  { value: 'Lumberton_NC', label: 'Lumberton, NC' },
  { value: 'Lumberton_MS', label: 'Lumberton, MS' },
  { value: 'Lumberton_TX', label: 'Lumberton, TX' },
  { value: 'Lumberville_PA', label: 'Lumberville, PA' },
  { value: 'Lummi Island_WA', label: 'Lummi Island, WA' },
  { value: 'Lumpkin_GA', label: 'Lumpkin, GA' },
  { value: 'Luna_NM', label: 'Luna, NM' },
  { value: 'Luna Pier_MI', label: 'Luna Pier, MI' },
  { value: 'Lund_NV', label: 'Lund, NV' },
  { value: 'Lunenburg_MA', label: 'Lunenburg, MA' },
  { value: 'Lunenburg_VT', label: 'Lunenburg, VT' },
  { value: 'Lunenburg_VA', label: 'Lunenburg, VA' },
  { value: 'Luning_NV', label: 'Luning, NV' },
  { value: 'Lupton_MI', label: 'Lupton, MI' },
  { value: 'Lupton_AZ', label: 'Lupton, AZ' },
  { value: 'Lupton City_TN', label: 'Lupton City, TN' },
  { value: 'Luquillo_PR', label: 'Luquillo, PR' },
  { value: 'Luray_VA', label: 'Luray, VA' },
  { value: 'Luray_SC', label: 'Luray, SC' },
  { value: 'Luray_TN', label: 'Luray, TN' },
  { value: 'Luray_MO', label: 'Luray, MO' },
  { value: 'Luray_KS', label: 'Luray, KS' },
  { value: 'Lurgan_PA', label: 'Lurgan, PA' },
  { value: 'Lusby_MD', label: 'Lusby, MD' },
  { value: 'Lusk_WY', label: 'Lusk, WY' },
  { value: 'Lutcher_LA', label: 'Lutcher, LA' },
  { value: 'Luther_MI', label: 'Luther, MI' },
  { value: 'Luther_IA', label: 'Luther, IA' },
  { value: 'Luther_OK', label: 'Luther, OK' },
  { value: 'Luthersburg_PA', label: 'Luthersburg, PA' },
  { value: 'Luthersville_GA', label: 'Luthersville, GA' },
  { value: 'Lutherville Timonium_MD', label: 'Lutherville Timonium, MD' },
  { value: 'Lutsen_MN', label: 'Lutsen, MN' },
  { value: 'Luttrell_TN', label: 'Luttrell, TN' },
  { value: 'Lutts_TN', label: 'Lutts, TN' },
  { value: 'Lutz_FL', label: 'Lutz, FL' },
  { value: 'Luverne_AL', label: 'Luverne, AL' },
  { value: 'Luverne_MN', label: 'Luverne, MN' },
  { value: 'Luverne_ND', label: 'Luverne, ND' },
  { value: 'Luxemburg_IA', label: 'Luxemburg, IA' },
  { value: 'Luxemburg_WI', label: 'Luxemburg, WI' },
  { value: 'Luxor_PA', label: 'Luxor, PA' },
  { value: 'Luxora_AR', label: 'Luxora, AR' },
  { value: 'Luzerne_PA', label: 'Luzerne, PA' },
  { value: 'Luzerne_MI', label: 'Luzerne, MI' },
  { value: 'Luzerne_IA', label: 'Luzerne, IA' },
  { value: 'Lyburn_WV', label: 'Lyburn, WV' },
  { value: 'Lydia_SC', label: 'Lydia, SC' },
  { value: 'Lyerly_GA', label: 'Lyerly, GA' },
  { value: 'Lyford_TX', label: 'Lyford, TX' },
  { value: 'Lykens_PA', label: 'Lykens, PA' },
  { value: 'Lyle_MN', label: 'Lyle, MN' },
  { value: 'Lyle_WA', label: 'Lyle, WA' },
  { value: 'Lyles_TN', label: 'Lyles, TN' },
  { value: 'Lyman_SC', label: 'Lyman, SC' },
  { value: 'Lyman_NE', label: 'Lyman, NE' },
  { value: 'Lyman_WY', label: 'Lyman, WY' },
  { value: 'Lyman_UT', label: 'Lyman, UT' },
  { value: 'Lyman_WA', label: 'Lyman, WA' },
  { value: 'Lyme_NH', label: 'Lyme, NH' },
  { value: 'Lynbrook_NY', label: 'Lynbrook, NY' },
  { value: 'Lynch_KY', label: 'Lynch, KY' },
  { value: 'Lynch_NE', label: 'Lynch, NE' },
  { value: 'Lynch Station_VA', label: 'Lynch Station, VA' },
  { value: 'Lynchburg_VA', label: 'Lynchburg, VA' },
  { value: 'Lynchburg_SC', label: 'Lynchburg, SC' },
  { value: 'Lynchburg_TN', label: 'Lynchburg, TN' },
  { value: 'Lynchburg_OH', label: 'Lynchburg, OH' },
  { value: 'Lynchburg_MO', label: 'Lynchburg, MO' },
  { value: 'Lynco_WV', label: 'Lynco, WV' },
  { value: 'Lynd_MN', label: 'Lynd, MN' },
  { value: 'Lyndeborough_NH', label: 'Lyndeborough, NH' },
  { value: 'Lynden_WA', label: 'Lynden, WA' },
  { value: 'Lyndhurst_NJ', label: 'Lyndhurst, NJ' },
  { value: 'Lyndhurst_VA', label: 'Lyndhurst, VA' },
  { value: 'Lyndon_IL', label: 'Lyndon, IL' },
  { value: 'Lyndon_KS', label: 'Lyndon, KS' },
  { value: 'Lyndon Center_VT', label: 'Lyndon Center, VT' },
  { value: 'Lyndon Station_WI', label: 'Lyndon Station, WI' },
  { value: 'Lyndonville_VT', label: 'Lyndonville, VT' },
  { value: 'Lyndonville_NY', label: 'Lyndonville, NY' },
  { value: 'Lyndora_PA', label: 'Lyndora, PA' },
  { value: 'Lynn_MA', label: 'Lynn, MA' },
  { value: 'Lynn_AL', label: 'Lynn, AL' },
  { value: 'Lynn_IN', label: 'Lynn, IN' },
  { value: 'Lynn_AR', label: 'Lynn, AR' },
  { value: 'Lynn Center_IL', label: 'Lynn Center, IL' },
  { value: 'Lynn Haven_FL', label: 'Lynn Haven, FL' },
  { value: 'Lynndyl_UT', label: 'Lynndyl, UT' },
  { value: 'Lynnfield_MA', label: 'Lynnfield, MA' },
  { value: 'Lynnville_TN', label: 'Lynnville, TN' },
  { value: 'Lynnville_IN', label: 'Lynnville, IN' },
  { value: 'Lynnville_IA', label: 'Lynnville, IA' },
  { value: 'Lynnwood_WA', label: 'Lynnwood, WA' },
  { value: 'Lynwood_CA', label: 'Lynwood, CA' },
  { value: 'Lynx_OH', label: 'Lynx, OH' },
  { value: 'Lyon_MS', label: 'Lyon, MS' },
  { value: 'Lyon Mountain_NY', label: 'Lyon Mountain, NY' },
  { value: 'Lyon Station_PA', label: 'Lyon Station, PA' },
  { value: 'Lyons_NJ', label: 'Lyons, NJ' },
  { value: 'Lyons_NY', label: 'Lyons, NY' },
  { value: 'Lyons_GA', label: 'Lyons, GA' },
  { value: 'Lyons_OH', label: 'Lyons, OH' },
  { value: 'Lyons_IN', label: 'Lyons, IN' },
  { value: 'Lyons_MI', label: 'Lyons, MI' },
  { value: 'Lyons_SD', label: 'Lyons, SD' },
  { value: 'Lyons_IL', label: 'Lyons, IL' },
  { value: 'Lyons_KS', label: 'Lyons, KS' },
  { value: 'Lyons_NE', label: 'Lyons, NE' },
  { value: 'Lyons_TX', label: 'Lyons, TX' },
  { value: 'Lyons_CO', label: 'Lyons, CO' },
  { value: 'Lyons_OR', label: 'Lyons, OR' },
  { value: 'Lyons Falls_NY', label: 'Lyons Falls, NY' },
  { value: 'Lysite_WY', label: 'Lysite, WY' },
  { value: 'Lytle_TX', label: 'Lytle, TX' },
  { value: 'Lytle Creek_CA', label: 'Lytle Creek, CA' },
  { value: 'Lytton_IA', label: 'Lytton, IA' },
  { value: 'Mabank_TX', label: 'Mabank, TX' },
  { value: 'Mabel_MN', label: 'Mabel, MN' },
  { value: 'Mabelvale_AR', label: 'Mabelvale, AR' },
  { value: 'Maben_WV', label: 'Maben, WV' },
  { value: 'Maben_MS', label: 'Maben, MS' },
  { value: 'Mabie_WV', label: 'Mabie, WV' },
  { value: 'Mableton_GA', label: 'Mableton, GA' },
  { value: 'Mabscott_WV', label: 'Mabscott, WV' },
  { value: 'Mabton_WA', label: 'Mabton, WA' },
  { value: 'MacArthur_WV', label: 'MacArthur, WV' },
  { value: 'Macatawa_MI', label: 'Macatawa, MI' },
  { value: 'Macclenny_FL', label: 'Macclenny, FL' },
  { value: 'Macclesfield_NC', label: 'Macclesfield, NC' },
  { value: 'Macdoel_CA', label: 'Macdoel, CA' },
  { value: 'Macedon_NY', label: 'Macedon, NY' },
  { value: 'Macedonia_OH', label: 'Macedonia, OH' },
  { value: 'Macedonia_IA', label: 'Macedonia, IA' },
  { value: 'Macedonia_IL', label: 'Macedonia, IL' },
  { value: 'Maceo_KY', label: 'Maceo, KY' },
  { value: 'Macfarlan_WV', label: 'Macfarlan, WV' },
  { value: 'Machesney Park_IL', label: 'Machesney Park, IL' },
  { value: 'Machias_ME', label: 'Machias, ME' },
  { value: 'Machias_NY', label: 'Machias, NY' },
  { value: 'Machiasport_ME', label: 'Machiasport, ME' },
  { value: 'Machipongo_VA', label: 'Machipongo, VA' },
  { value: 'Mack_CO', label: 'Mack, CO' },
  { value: 'Mackay_ID', label: 'Mackay, ID' },
  { value: 'Mackey_IN', label: 'Mackey, IN' },
  { value: 'Mackeyville_PA', label: 'Mackeyville, PA' },
  { value: 'Mackinac Island_MI', label: 'Mackinac Island, MI' },
  { value: 'Mackinaw_IL', label: 'Mackinaw, IL' },
  { value: 'Mackinaw City_MI', label: 'Mackinaw City, MI' },
  { value: 'Macks Creek_MO', label: 'Macks Creek, MO' },
  { value: 'Macks Inn_ID', label: 'Macks Inn, ID' },
  { value: 'Macksburg_OH', label: 'Macksburg, OH' },
  { value: 'Macksburg_IA', label: 'Macksburg, IA' },
  { value: 'Macksville_KS', label: 'Macksville, KS' },
  { value: 'Mackville_KY', label: 'Mackville, KY' },
  { value: 'Macomb_MI', label: 'Macomb, MI' },
  { value: 'Macomb_IL', label: 'Macomb, IL' },
  { value: 'Macomb_MO', label: 'Macomb, MO' },
  { value: 'Macomb_OK', label: 'Macomb, OK' },
  { value: 'Macon_NC', label: 'Macon, NC' },
  { value: 'Macon_GA', label: 'Macon, GA' },
  { value: 'Macon_MS', label: 'Macon, MS' },
  { value: 'Macon_IL', label: 'Macon, IL' },
  { value: 'Macon_MO', label: 'Macon, MO' },
  { value: 'Macungie_PA', label: 'Macungie, PA' },
  { value: 'Macy_IN', label: 'Macy, IN' },
  { value: 'Macy_NE', label: 'Macy, NE' },
  { value: 'Mad River_CA', label: 'Mad River, CA' },
  { value: 'Madawaska_ME', label: 'Madawaska, ME' },
  { value: 'Madbury_NH', label: 'Madbury, NH' },
  { value: 'Maddock_ND', label: 'Maddock, ND' },
  { value: 'Madelia_MN', label: 'Madelia, MN' },
  { value: 'Madeline_CA', label: 'Madeline, CA' },
  { value: 'Madera_PA', label: 'Madera, PA' },
  { value: 'Madera_CA', label: 'Madera, CA' },
  { value: 'Madill_OK', label: 'Madill, OK' },
  { value: 'Madison_NH', label: 'Madison, NH' },
  { value: 'Madison_ME', label: 'Madison, ME' },
  { value: 'Madison_CT', label: 'Madison, CT' },
  { value: 'Madison_NJ', label: 'Madison, NJ' },
  { value: 'Madison_NY', label: 'Madison, NY' },
  { value: 'Madison_PA', label: 'Madison, PA' },
  { value: 'Madison_MD', label: 'Madison, MD' },
  { value: 'Madison_VA', label: 'Madison, VA' },
  { value: 'Madison_WV', label: 'Madison, WV' },
  { value: 'Madison_NC', label: 'Madison, NC' },
  { value: 'Madison_GA', label: 'Madison, GA' },
  { value: 'Madison_FL', label: 'Madison, FL' },
  { value: 'Madison_AL', label: 'Madison, AL' },
  { value: 'Madison_TN', label: 'Madison, TN' },
  { value: 'Madison_MS', label: 'Madison, MS' },
  { value: 'Madison_OH', label: 'Madison, OH' },
  { value: 'Madison_IN', label: 'Madison, IN' },
  { value: 'Madison_WI', label: 'Madison, WI' },
  { value: 'Madison_MN', label: 'Madison, MN' },
  { value: 'Madison_SD', label: 'Madison, SD' },
  { value: 'Madison_IL', label: 'Madison, IL' },
  { value: 'Madison_MO', label: 'Madison, MO' },
  { value: 'Madison_KS', label: 'Madison, KS' },
  { value: 'Madison_NE', label: 'Madison, NE' },
  { value: 'Madison_AR', label: 'Madison, AR' },
  { value: 'Madison_CA', label: 'Madison, CA' },
  { value: 'Madison Heights_VA', label: 'Madison Heights, VA' },
  { value: 'Madison Heights_MI', label: 'Madison Heights, MI' },
  { value: 'Madison Lake_MN', label: 'Madison Lake, MN' },
  { value: 'Madisonburg_PA', label: 'Madisonburg, PA' },
  { value: 'Madisonville_TN', label: 'Madisonville, TN' },
  { value: 'Madisonville_KY', label: 'Madisonville, KY' },
  { value: 'Madisonville_LA', label: 'Madisonville, LA' },
  { value: 'Madisonville_TX', label: 'Madisonville, TX' },
  { value: 'Madras_OR', label: 'Madras, OR' },
  { value: 'Madrid_NY', label: 'Madrid, NY' },
  { value: 'Madrid_IA', label: 'Madrid, IA' },
  { value: 'Madrid_NE', label: 'Madrid, NE' },
  { value: 'Maeystown_IL', label: 'Maeystown, IL' },
  { value: 'Magalia_CA', label: 'Magalia, CA' },
  { value: 'Magazine_AR', label: 'Magazine, AR' },
  { value: 'Magdalena_NM', label: 'Magdalena, NM' },
  { value: 'Magee_MS', label: 'Magee, MS' },
  { value: 'Maggie Valley_NC', label: 'Maggie Valley, NC' },
  { value: 'Magna_UT', label: 'Magna, UT' },
  { value: 'Magness_AR', label: 'Magness, AR' },
  { value: 'Magnet_NE', label: 'Magnet, NE' },
  { value: 'Magnetic Springs_OH', label: 'Magnetic Springs, OH' },
  { value: 'Magnolia_NJ', label: 'Magnolia, NJ' },
  { value: 'Magnolia_DE', label: 'Magnolia, DE' },
  { value: 'Magnolia_NC', label: 'Magnolia, NC' },
  { value: 'Magnolia_AL', label: 'Magnolia, AL' },
  { value: 'Magnolia_MS', label: 'Magnolia, MS' },
  { value: 'Magnolia_KY', label: 'Magnolia, KY' },
  { value: 'Magnolia_OH', label: 'Magnolia, OH' },
  { value: 'Magnolia_IA', label: 'Magnolia, IA' },
  { value: 'Magnolia_MN', label: 'Magnolia, MN' },
  { value: 'Magnolia_IL', label: 'Magnolia, IL' },
  { value: 'Magnolia_AR', label: 'Magnolia, AR' },
  { value: 'Magnolia_TX', label: 'Magnolia, TX' },
  { value: 'Magnolia Springs_AL', label: 'Magnolia Springs, AL' },
  { value: 'Mahaffey_PA', label: 'Mahaffey, PA' },
  { value: 'Mahanoy City_PA', label: 'Mahanoy City, PA' },
  { value: 'Mahanoy Plane_PA', label: 'Mahanoy Plane, PA' },
  { value: 'Mahaska_KS', label: 'Mahaska, KS' },
  { value: 'Mahnomen_MN', label: 'Mahnomen, MN' },
  { value: 'Mahomet_IL', label: 'Mahomet, IL' },
  { value: 'Mahopac_NY', label: 'Mahopac, NY' },
  { value: 'Mahopac Falls_NY', label: 'Mahopac Falls, NY' },
  { value: 'Mahwah_NJ', label: 'Mahwah, NJ' },
  { value: 'Maida_ND', label: 'Maida, ND' },
  { value: 'Maiden_NC', label: 'Maiden, NC' },
  { value: 'Maiden Rock_WI', label: 'Maiden Rock, WI' },
  { value: 'Maidens_VA', label: 'Maidens, VA' },
  { value: 'Maidsville_WV', label: 'Maidsville, WV' },
  { value: 'Maine_NY', label: 'Maine, NY' },
  { value: 'Mainesburg_PA', label: 'Mainesburg, PA' },
  { value: 'Maineville_OH', label: 'Maineville, OH' },
  { value: 'Maitland_FL', label: 'Maitland, FL' },
  { value: 'Maitland_MO', label: 'Maitland, MO' },
  { value: 'Maize_KS', label: 'Maize, KS' },
  { value: 'Majestic_KY', label: 'Majestic, KY' },
  { value: 'Makanda_IL', label: 'Makanda, IL' },
  { value: 'Makawao_HI', label: 'Makawao, HI' },
  { value: 'Makaweli_HI', label: 'Makaweli, HI' },
  { value: 'Makinen_MN', label: 'Makinen, MN' },
  { value: 'Makoti_ND', label: 'Makoti, ND' },
  { value: 'Malabar_FL', label: 'Malabar, FL' },
  { value: 'Malad City_ID', label: 'Malad City, ID' },
  { value: 'Malaga_NJ', label: 'Malaga, NJ' },
  { value: 'Malaga_NM', label: 'Malaga, NM' },
  { value: 'Malaga_WA', label: 'Malaga, WA' },
  { value: 'Malakoff_TX', label: 'Malakoff, TX' },
  { value: 'Malcolm_AL', label: 'Malcolm, AL' },
  { value: 'Malcolm_NE', label: 'Malcolm, NE' },
  { value: 'Malcom_IA', label: 'Malcom, IA' },
  { value: 'Malden_MA', label: 'Malden, MA' },
  { value: 'Malden_IL', label: 'Malden, IL' },
  { value: 'Malden_MO', label: 'Malden, MO' },
  { value: 'Malden_WA', label: 'Malden, WA' },
  { value: 'Malden Bridge_NY', label: 'Malden Bridge, NY' },
  { value: 'Malden On Hudson_NY', label: 'Malden On Hudson, NY' },
  { value: 'Malibu_CA', label: 'Malibu, CA' },
  { value: 'Malin_OR', label: 'Malin, OR' },
  { value: 'Malinta_OH', label: 'Malinta, OH' },
  { value: 'Maljamar_NM', label: 'Maljamar, NM' },
  { value: 'Mallard_IA', label: 'Mallard, IA' },
  { value: 'Mallie_KY', label: 'Mallie, KY' },
  { value: 'Mallory_NY', label: 'Mallory, NY' },
  { value: 'Mallory_WV', label: 'Mallory, WV' },
  { value: 'Malmo_NE', label: 'Malmo, NE' },
  { value: 'Malo_WA', label: 'Malo, WA' },
  { value: 'Malone_NY', label: 'Malone, NY' },
  { value: 'Malone_FL', label: 'Malone, FL' },
  { value: 'Malone_WI', label: 'Malone, WI' },
  { value: 'Malone_TX', label: 'Malone, TX' },
  { value: 'Malone_WA', label: 'Malone, WA' },
  { value: 'Malott_WA', label: 'Malott, WA' },
  { value: 'Malta_OH', label: 'Malta, OH' },
  { value: 'Malta_MT', label: 'Malta, MT' },
  { value: 'Malta_IL', label: 'Malta, IL' },
  { value: 'Malta_ID', label: 'Malta, ID' },
  { value: 'Malta Bend_MO', label: 'Malta Bend, MO' },
  { value: 'Malvern_PA', label: 'Malvern, PA' },
  { value: 'Malvern_OH', label: 'Malvern, OH' },
  { value: 'Malvern_IA', label: 'Malvern, IA' },
  { value: 'Malvern_AR', label: 'Malvern, AR' },
  { value: 'Malverne_NY', label: 'Malverne, NY' },
  { value: 'Mamaroneck_NY', label: 'Mamaroneck, NY' },
  { value: 'Mammoth_WV', label: 'Mammoth, WV' },
  { value: 'Mammoth_AZ', label: 'Mammoth, AZ' },
  { value: 'Mammoth Cave_KY', label: 'Mammoth Cave, KY' },
  { value: 'Mammoth Lakes_CA', label: 'Mammoth Lakes, CA' },
  { value: 'Mammoth Spring_AR', label: 'Mammoth Spring, AR' },
  { value: 'Mamou_LA', label: 'Mamou, LA' },
  { value: 'Man_WV', label: 'Man, WV' },
  { value: 'Manahawkin_NJ', label: 'Manahawkin, NJ' },
  { value: 'Manakin Sabot_VA', label: 'Manakin Sabot, VA' },
  { value: 'Manasquan_NJ', label: 'Manasquan, NJ' },
  { value: 'Manassa_CO', label: 'Manassa, CO' },
  { value: 'Manassas_VA', label: 'Manassas, VA' },
  { value: 'Manati_PR', label: 'Manati, PR' },
  { value: 'Manawa_WI', label: 'Manawa, WI' },
  { value: 'Mancelona_MI', label: 'Mancelona, MI' },
  { value: 'Manchaca_TX', label: 'Manchaca, TX' },
  { value: 'Manchester_MA', label: 'Manchester, MA' },
  { value: 'Manchester_NH', label: 'Manchester, NH' },
  { value: 'Manchester_ME', label: 'Manchester, ME' },
  { value: 'Manchester_VT', label: 'Manchester, VT' },
  { value: 'Manchester_CT', label: 'Manchester, CT' },
  { value: 'Manchester_NY', label: 'Manchester, NY' },
  { value: 'Manchester_PA', label: 'Manchester, PA' },
  { value: 'Manchester_MD', label: 'Manchester, MD' },
  { value: 'Manchester_GA', label: 'Manchester, GA' },
  { value: 'Manchester_TN', label: 'Manchester, TN' },
  { value: 'Manchester_KY', label: 'Manchester, KY' },
  { value: 'Manchester_OH', label: 'Manchester, OH' },
  { value: 'Manchester_MI', label: 'Manchester, MI' },
  { value: 'Manchester_IA', label: 'Manchester, IA' },
  { value: 'Manchester_IL', label: 'Manchester, IL' },
  { value: 'Manchester_OK', label: 'Manchester, OK' },
  { value: 'Manchester_CA', label: 'Manchester, CA' },
  { value: 'Manchester_WA', label: 'Manchester, WA' },
  { value: 'Manchester Center_VT', label: 'Manchester Center, VT' },
  { value: 'Manchester Township_NJ', label: 'Manchester Township, NJ' },
  { value: 'Mancos_CO', label: 'Mancos, CO' },
  { value: 'Mandan_ND', label: 'Mandan, ND' },
  { value: 'Mandaree_ND', label: 'Mandaree, ND' },
  { value: 'Manderson_SD', label: 'Manderson, SD' },
  { value: 'Manderson_WY', label: 'Manderson, WY' },
  { value: 'Mandeville_LA', label: 'Mandeville, LA' },
  { value: 'Mangham_LA', label: 'Mangham, LA' },
  { value: 'Mango_FL', label: 'Mango, FL' },
  { value: 'Mangum_OK', label: 'Mangum, OK' },
  { value: 'Manhasset_NY', label: 'Manhasset, NY' },
  { value: 'Manhattan_MT', label: 'Manhattan, MT' },
  { value: 'Manhattan_IL', label: 'Manhattan, IL' },
  { value: 'Manhattan_KS', label: 'Manhattan, KS' },
  { value: 'Manhattan_NV', label: 'Manhattan, NV' },
  { value: 'Manhattan Beach_CA', label: 'Manhattan Beach, CA' },
  { value: 'Manheim_PA', label: 'Manheim, PA' },
  { value: 'Manila_AR', label: 'Manila, AR' },
  { value: 'Manila_UT', label: 'Manila, UT' },
  { value: 'Manilla_IN', label: 'Manilla, IN' },
  { value: 'Manilla_IA', label: 'Manilla, IA' },
  { value: 'Manistee_MI', label: 'Manistee, MI' },
  { value: 'Manistique_MI', label: 'Manistique, MI' },
  { value: 'Manito_IL', label: 'Manito, IL' },
  { value: 'Manitou_KY', label: 'Manitou, KY' },
  { value: 'Manitou_OK', label: 'Manitou, OK' },
  { value: 'Manitou Beach_MI', label: 'Manitou Beach, MI' },
  { value: 'Manitou Springs_CO', label: 'Manitou Springs, CO' },
  { value: 'Manitowish Waters_WI', label: 'Manitowish Waters, WI' },
  { value: 'Manitowoc_WI', label: 'Manitowoc, WI' },
  { value: 'Mankato_MN', label: 'Mankato, MN' },
  { value: 'Mankato_KS', label: 'Mankato, KS' },
  { value: 'Manley_NE', label: 'Manley, NE' },
  { value: 'Manley Hot Springs_AK', label: 'Manley Hot Springs, AK' },
  { value: 'Manlius_NY', label: 'Manlius, NY' },
  { value: 'Manlius_IL', label: 'Manlius, IL' },
  { value: 'Manly_IA', label: 'Manly, IA' },
  { value: 'Mannford_OK', label: 'Mannford, OK' },
  { value: 'Manning_SC', label: 'Manning, SC' },
  { value: 'Manning_IA', label: 'Manning, IA' },
  { value: 'Manning_ND', label: 'Manning, ND' },
  { value: 'Manning_OR', label: 'Manning, OR' },
  { value: 'Mannington_WV', label: 'Mannington, WV' },
  { value: 'Manns Choice_PA', label: 'Manns Choice, PA' },
  { value: 'Manns Harbor_NC', label: 'Manns Harbor, NC' },
  { value: 'Mannsville_NY', label: 'Mannsville, NY' },
  { value: 'Mannsville_KY', label: 'Mannsville, KY' },
  { value: 'Mannsville_OK', label: 'Mannsville, OK' },
  { value: 'Manokotak_AK', label: 'Manokotak, AK' },
  { value: 'Manor_PA', label: 'Manor, PA' },
  { value: 'Manor_GA', label: 'Manor, GA' },
  { value: 'Manor_TX', label: 'Manor, TX' },
  { value: 'Manorville_NY', label: 'Manorville, NY' },
  { value: 'Manorville_PA', label: 'Manorville, PA' },
  { value: 'Manquin_VA', label: 'Manquin, VA' },
  { value: 'Mansfield_MA', label: 'Mansfield, MA' },
  { value: 'Mansfield_PA', label: 'Mansfield, PA' },
  { value: 'Mansfield_GA', label: 'Mansfield, GA' },
  { value: 'Mansfield_TN', label: 'Mansfield, TN' },
  { value: 'Mansfield_OH', label: 'Mansfield, OH' },
  { value: 'Mansfield_SD', label: 'Mansfield, SD' },
  { value: 'Mansfield_IL', label: 'Mansfield, IL' },
  { value: 'Mansfield_MO', label: 'Mansfield, MO' },
  { value: 'Mansfield_LA', label: 'Mansfield, LA' },
  { value: 'Mansfield_AR', label: 'Mansfield, AR' },
  { value: 'Mansfield_TX', label: 'Mansfield, TX' },
  { value: 'Mansfield_WA', label: 'Mansfield, WA' },
  { value: 'Mansfield Center_CT', label: 'Mansfield Center, CT' },
  { value: 'Manson_NC', label: 'Manson, NC' },
  { value: 'Manson_IA', label: 'Manson, IA' },
  { value: 'Manson_WA', label: 'Manson, WA' },
  { value: 'Mansura_LA', label: 'Mansura, LA' },
  { value: 'Mantachie_MS', label: 'Mantachie, MS' },
  { value: 'Mantador_ND', label: 'Mantador, ND' },
  { value: 'Manteca_CA', label: 'Manteca, CA' },
  { value: 'Mantee_MS', label: 'Mantee, MS' },
  { value: 'Manteno_IL', label: 'Manteno, IL' },
  { value: 'Manteo_NC', label: 'Manteo, NC' },
  { value: 'Manter_KS', label: 'Manter, KS' },
  { value: 'Manti_UT', label: 'Manti, UT' },
  { value: 'Mantoloking_NJ', label: 'Mantoloking, NJ' },
  { value: 'Manton_MI', label: 'Manton, MI' },
  { value: 'Manton_CA', label: 'Manton, CA' },
  { value: 'Mantorville_MN', label: 'Mantorville, MN' },
  { value: 'Mantua_NJ', label: 'Mantua, NJ' },
  { value: 'Mantua_OH', label: 'Mantua, OH' },
  { value: 'Mantua_UT', label: 'Mantua, UT' },
  { value: 'Manvel_ND', label: 'Manvel, ND' },
  { value: 'Manvel_TX', label: 'Manvel, TX' },
  { value: 'Manville_RI', label: 'Manville, RI' },
  { value: 'Manville_NJ', label: 'Manville, NJ' },
  { value: 'Manville_WY', label: 'Manville, WY' },
  { value: 'Many_LA', label: 'Many, LA' },
  { value: 'Many Farms_AZ', label: 'Many Farms, AZ' },
  { value: 'Manzanita_OR', label: 'Manzanita, OR' },
  { value: 'Manzanola_CO', label: 'Manzanola, CO' },
  { value: 'Maple_NC', label: 'Maple, NC' },
  { value: 'Maple_WI', label: 'Maple, WI' },
  { value: 'Maple_TX', label: 'Maple, TX' },
  { value: 'Maple City_MI', label: 'Maple City, MI' },
  { value: 'Maple City_KS', label: 'Maple City, KS' },
  { value: 'Maple Falls_WA', label: 'Maple Falls, WA' },
  { value: 'Maple Grove_MN', label: 'Maple Grove, MN' },
  { value: 'Maple Heights_OH', label: 'Maple Heights, OH' },
  { value: 'Maple Hill_NC', label: 'Maple Hill, NC' },
  { value: 'Maple Hill_KS', label: 'Maple Hill, KS' },
  { value: 'Maple Lake_MN', label: 'Maple Lake, MN' },
  { value: 'Maple Mount_KY', label: 'Maple Mount, KY' },
  { value: 'Maple Park_IL', label: 'Maple Park, IL' },
  { value: 'Maple Plain_MN', label: 'Maple Plain, MN' },
  { value: 'Maple Rapids_MI', label: 'Maple Rapids, MI' },
  { value: 'Maple Shade_NJ', label: 'Maple Shade, NJ' },
  { value: 'Maple Springs_NY', label: 'Maple Springs, NY' },
  { value: 'Maple Valley_WA', label: 'Maple Valley, WA' },
  { value: 'Maplecrest_NY', label: 'Maplecrest, NY' },
  { value: 'Maplesville_AL', label: 'Maplesville, AL' },
  { value: 'Mapleton_ME', label: 'Mapleton, ME' },
  { value: 'Mapleton_IA', label: 'Mapleton, IA' },
  { value: 'Mapleton_MN', label: 'Mapleton, MN' },
  { value: 'Mapleton_ND', label: 'Mapleton, ND' },
  { value: 'Mapleton_IL', label: 'Mapleton, IL' },
  { value: 'Mapleton_KS', label: 'Mapleton, KS' },
  { value: 'Mapleton_UT', label: 'Mapleton, UT' },
  { value: 'Mapleton_OR', label: 'Mapleton, OR' },
  { value: 'Mapleton Depot_PA', label: 'Mapleton Depot, PA' },
  { value: 'Mapleville_RI', label: 'Mapleville, RI' },
  { value: 'Maplewood_NJ', label: 'Maplewood, NJ' },
  { value: 'Maplewood_OH', label: 'Maplewood, OH' },
  { value: 'Mappsville_VA', label: 'Mappsville, VA' },
  { value: 'Maquoketa_IA', label: 'Maquoketa, IA' },
  { value: 'Maquon_IL', label: 'Maquon, IL' },
  { value: 'Mar Lin_PA', label: 'Mar Lin, PA' },
  { value: 'Maramec_OK', label: 'Maramec, OK' },
  { value: 'Marana_AZ', label: 'Marana, AZ' },
  { value: 'Marathon_NY', label: 'Marathon, NY' },
  { value: 'Marathon_FL', label: 'Marathon, FL' },
  { value: 'Marathon_IA', label: 'Marathon, IA' },
  { value: 'Marathon_WI', label: 'Marathon, WI' },
  { value: 'Marathon_TX', label: 'Marathon, TX' },
  { value: 'Marble_PA', label: 'Marble, PA' },
  { value: 'Marble_NC', label: 'Marble, NC' },
  { value: 'Marble_MN', label: 'Marble, MN' },
  { value: 'Marble Canyon_AZ', label: 'Marble Canyon, AZ' },
  { value: 'Marble City_OK', label: 'Marble City, OK' },
  { value: 'Marble Falls_AR', label: 'Marble Falls, AR' },
  { value: 'Marble Falls_TX', label: 'Marble Falls, TX' },
  { value: 'Marble Hill_GA', label: 'Marble Hill, GA' },
  { value: 'Marble Hill_MO', label: 'Marble Hill, MO' },
  { value: 'Marble Rock_IA', label: 'Marble Rock, IA' },
  { value: 'Marblehead_MA', label: 'Marblehead, MA' },
  { value: 'Marblemount_WA', label: 'Marblemount, WA' },
  { value: 'Marbury_MD', label: 'Marbury, MD' },
  { value: 'Marbury_AL', label: 'Marbury, AL' },
  { value: 'Marceline_MO', label: 'Marceline, MO' },
  { value: 'Marcell_MN', label: 'Marcell, MN' },
  { value: 'Marcella_AR', label: 'Marcella, AR' },
  { value: 'Marcellus_NY', label: 'Marcellus, NY' },
  { value: 'Marcellus_MI', label: 'Marcellus, MI' },
  { value: 'March Air Reserve Base_CA', label: 'March Air Reserve Base, CA' },
  { value: 'Marco Island_FL', label: 'Marco Island, FL' },
  { value: 'Marcola_OR', label: 'Marcola, OR' },
  { value: 'Marcus_IA', label: 'Marcus, IA' },
  { value: 'Marcus_WA', label: 'Marcus, WA' },
  { value: 'Marcus Hook_PA', label: 'Marcus Hook, PA' },
  { value: 'Marcy_NY', label: 'Marcy, NY' },
  { value: 'Mardela Springs_MD', label: 'Mardela Springs, MD' },
  { value: 'Marengo_OH', label: 'Marengo, OH' },
  { value: 'Marengo_IN', label: 'Marengo, IN' },
  { value: 'Marengo_IA', label: 'Marengo, IA' },
  { value: 'Marengo_WI', label: 'Marengo, WI' },
  { value: 'Marengo_IL', label: 'Marengo, IL' },
  { value: 'Marenisco_MI', label: 'Marenisco, MI' },
  { value: 'Marfa_TX', label: 'Marfa, TX' },
  { value: 'Margaret_AL', label: 'Margaret, AL' },
  { value: 'Margarettsville_NC', label: 'Margarettsville, NC' },
  { value: 'Margaretville_NY', label: 'Margaretville, NY' },
  { value: 'Margate City_NJ', label: 'Margate City, NJ' },
  { value: 'Margie_MN', label: 'Margie, MN' },
  { value: 'Maria Stein_OH', label: 'Maria Stein, OH' },
  { value: 'Marianna_PA', label: 'Marianna, PA' },
  { value: 'Marianna_FL', label: 'Marianna, FL' },
  { value: 'Marianna_AR', label: 'Marianna, AR' },
  { value: 'Maribel_WI', label: 'Maribel, WI' },
  { value: 'Maricao_PR', label: 'Maricao, PR' },
  { value: 'Maricopa_AZ', label: 'Maricopa, AZ' },
  { value: 'Maricopa_CA', label: 'Maricopa, CA' },
  { value: 'Marienthal_KS', label: 'Marienthal, KS' },
  { value: 'Marienville_PA', label: 'Marienville, PA' },
  { value: 'Marietta_NY', label: 'Marietta, NY' },
  { value: 'Marietta_PA', label: 'Marietta, PA' },
  { value: 'Marietta_NC', label: 'Marietta, NC' },
  { value: 'Marietta_SC', label: 'Marietta, SC' },
  { value: 'Marietta_GA', label: 'Marietta, GA' },
  { value: 'Marietta_MS', label: 'Marietta, MS' },
  { value: 'Marietta_OH', label: 'Marietta, OH' },
  { value: 'Marietta_MN', label: 'Marietta, MN' },
  { value: 'Marietta_IL', label: 'Marietta, IL' },
  { value: 'Marietta_OK', label: 'Marietta, OK' },
  { value: 'Marietta_TX', label: 'Marietta, TX' },
  { value: 'Marilla_NY', label: 'Marilla, NY' },
  { value: 'Marina_CA', label: 'Marina, CA' },
  { value: 'Marina Del Rey_CA', label: 'Marina Del Rey, CA' },
  { value: 'Marine_IL', label: 'Marine, IL' },
  { value: 'Marine City_MI', label: 'Marine City, MI' },
  { value: 'Marine On St Croix_MN', label: 'Marine On St Croix, MN' },
  { value: 'Marinette_WI', label: 'Marinette, WI' },
  { value: 'Maringouin_LA', label: 'Maringouin, LA' },
  { value: 'Marion_MA', label: 'Marion, MA' },
  { value: 'Marion_CT', label: 'Marion, CT' },
  { value: 'Marion_NY', label: 'Marion, NY' },
  { value: 'Marion_PA', label: 'Marion, PA' },
  { value: 'Marion_VA', label: 'Marion, VA' },
  { value: 'Marion_NC', label: 'Marion, NC' },
  { value: 'Marion_SC', label: 'Marion, SC' },
  { value: 'Marion_AL', label: 'Marion, AL' },
  { value: 'Marion_MS', label: 'Marion, MS' },
  { value: 'Marion_KY', label: 'Marion, KY' },
  { value: 'Marion_OH', label: 'Marion, OH' },
  { value: 'Marion_IN', label: 'Marion, IN' },
  { value: 'Marion_MI', label: 'Marion, MI' },
  { value: 'Marion_IA', label: 'Marion, IA' },
  { value: 'Marion_WI', label: 'Marion, WI' },
  { value: 'Marion_SD', label: 'Marion, SD' },
  { value: 'Marion_ND', label: 'Marion, ND' },
  { value: 'Marion_MT', label: 'Marion, MT' },
  { value: 'Marion_IL', label: 'Marion, IL' },
  { value: 'Marion_KS', label: 'Marion, KS' },
  { value: 'Marion_LA', label: 'Marion, LA' },
  { value: 'Marion_AR', label: 'Marion, AR' },
  { value: 'Marion_TX', label: 'Marion, TX' },
  { value: 'Marion Center_PA', label: 'Marion Center, PA' },
  { value: 'Marion Heights_PA', label: 'Marion Heights, PA' },
  { value: 'Marion Junction_AL', label: 'Marion Junction, AL' },
  { value: 'Marion Station_MD', label: 'Marion Station, MD' },
  { value: 'Marionville_VA', label: 'Marionville, VA' },
  { value: 'Marionville_MO', label: 'Marionville, MO' },
  { value: 'Mariposa_CA', label: 'Mariposa, CA' },
  { value: 'Marissa_IL', label: 'Marissa, IL' },
  { value: 'Mark_IL', label: 'Mark, IL' },
  { value: 'Mark Center_OH', label: 'Mark Center, OH' },
  { value: 'Marked Tree_AR', label: 'Marked Tree, AR' },
  { value: 'Markesan_WI', label: 'Markesan, WI' },
  { value: 'Markham_VA', label: 'Markham, VA' },
  { value: 'Markham_IL', label: 'Markham, IL' },
  { value: 'Markham_TX', label: 'Markham, TX' },
  { value: 'Markle_IN', label: 'Markle, IN' },
  { value: 'Markleeville_CA', label: 'Markleeville, CA' },
  { value: 'Markleton_PA', label: 'Markleton, PA' },
  { value: 'Markleville_IN', label: 'Markleville, IN' },
  { value: 'Markleysburg_PA', label: 'Markleysburg, PA' },
  { value: 'Marks_MS', label: 'Marks, MS' },
  { value: 'Marksville_LA', label: 'Marksville, LA' },
  { value: 'Marland_OK', label: 'Marland, OK' },
  { value: 'Marlboro_VT', label: 'Marlboro, VT' },
  { value: 'Marlboro_NJ', label: 'Marlboro, NJ' },
  { value: 'Marlboro_NY', label: 'Marlboro, NY' },
  { value: 'Marlborough_MA', label: 'Marlborough, MA' },
  { value: 'Marlborough_NH', label: 'Marlborough, NH' },
  { value: 'Marlborough_CT', label: 'Marlborough, CT' },
  { value: 'Marlette_MI', label: 'Marlette, MI' },
  { value: 'Marlin_TX', label: 'Marlin, TX' },
  { value: 'Marlin_WA', label: 'Marlin, WA' },
  { value: 'Marlinton_WV', label: 'Marlinton, WV' },
  { value: 'Marlow_NH', label: 'Marlow, NH' },
  { value: 'Marlow_OK', label: 'Marlow, OK' },
  { value: 'Marlton_NJ', label: 'Marlton, NJ' },
  { value: 'Marmaduke_AR', label: 'Marmaduke, AR' },
  { value: 'Marmarth_ND', label: 'Marmarth, ND' },
  { value: 'Marmora_NJ', label: 'Marmora, NJ' },
  { value: 'Marne_MI', label: 'Marne, MI' },
  { value: 'Marne_IA', label: 'Marne, IA' },
  { value: 'Maroa_IL', label: 'Maroa, IL' },
  { value: 'Marquand_MO', label: 'Marquand, MO' },
  { value: 'Marquette_MI', label: 'Marquette, MI' },
  { value: 'Marquette_IA', label: 'Marquette, IA' },
  { value: 'Marquette_WI', label: 'Marquette, WI' },
  { value: 'Marquette_KS', label: 'Marquette, KS' },
  { value: 'Marquette_NE', label: 'Marquette, NE' },
  { value: 'Marquez_TX', label: 'Marquez, TX' },
  { value: 'Marrero_LA', label: 'Marrero, LA' },
  { value: 'Marriottsville_MD', label: 'Marriottsville, MD' },
  { value: 'Marrowbone_KY', label: 'Marrowbone, KY' },
  { value: 'Mars_PA', label: 'Mars, PA' },
  { value: 'Mars Hill_ME', label: 'Mars Hill, ME' },
  { value: 'Mars Hill_NC', label: 'Mars Hill, NC' },
  { value: 'Marseilles_IL', label: 'Marseilles, IL' },
  { value: 'Marshall_VA', label: 'Marshall, VA' },
  { value: 'Marshall_NC', label: 'Marshall, NC' },
  { value: 'Marshall_IN', label: 'Marshall, IN' },
  { value: 'Marshall_MI', label: 'Marshall, MI' },
  { value: 'Marshall_WI', label: 'Marshall, WI' },
  { value: 'Marshall_MN', label: 'Marshall, MN' },
  { value: 'Marshall_ND', label: 'Marshall, ND' },
  { value: 'Marshall_IL', label: 'Marshall, IL' },
  { value: 'Marshall_MO', label: 'Marshall, MO' },
  { value: 'Marshall_AR', label: 'Marshall, AR' },
  { value: 'Marshall_OK', label: 'Marshall, OK' },
  { value: 'Marshall_TX', label: 'Marshall, TX' },
  { value: 'Marshall_CA', label: 'Marshall, CA' },
  { value: 'Marshall_WA', label: 'Marshall, WA' },
  { value: 'Marshall_AK', label: 'Marshall, AK' },
  { value: 'Marshallberg_NC', label: 'Marshallberg, NC' },
  { value: 'Marshalls Creek_PA', label: 'Marshalls Creek, PA' },
  { value: 'Marshalltown_IA', label: 'Marshalltown, IA' },
  { value: 'Marshallville_GA', label: 'Marshallville, GA' },
  { value: 'Marshallville_OH', label: 'Marshallville, OH' },
  { value: 'Marshes Siding_KY', label: 'Marshes Siding, KY' },
  { value: 'Marshfield_MA', label: 'Marshfield, MA' },
  { value: 'Marshfield_VT', label: 'Marshfield, VT' },
  { value: 'Marshfield_WI', label: 'Marshfield, WI' },
  { value: 'Marshfield_MO', label: 'Marshfield, MO' },
  { value: 'Marshville_NC', label: 'Marshville, NC' },
  { value: 'Marsing_ID', label: 'Marsing, ID' },
  { value: 'Marsland_NE', label: 'Marsland, NE' },
  { value: 'Marsteller_PA', label: 'Marsteller, PA' },
  { value: 'Marston_NC', label: 'Marston, NC' },
  { value: 'Marston_MO', label: 'Marston, MO' },
  { value: 'Marstons Mills_MA', label: 'Marstons Mills, MA' },
  { value: 'Mart_TX', label: 'Mart, TX' },
  { value: 'Martell_NE', label: 'Martell, NE' },
  { value: 'Martelle_IA', label: 'Martelle, IA' },
  { value: 'Martensdale_IA', label: 'Martensdale, IA' },
  { value: 'Martha_KY', label: 'Martha, KY' },
  { value: 'Martha_OK', label: 'Martha, OK' },
  { value: 'Marthasville_MO', label: 'Marthasville, MO' },
  { value: 'Marthaville_LA', label: 'Marthaville, LA' },
  { value: 'Martin_PA', label: 'Martin, PA' },
  { value: 'Martin_SC', label: 'Martin, SC' },
  { value: 'Martin_GA', label: 'Martin, GA' },
  { value: 'Martin_TN', label: 'Martin, TN' },
  { value: 'Martin_KY', label: 'Martin, KY' },
  { value: 'Martin_OH', label: 'Martin, OH' },
  { value: 'Martin_MI', label: 'Martin, MI' },
  { value: 'Martin_SD', label: 'Martin, SD' },
  { value: 'Martin_ND', label: 'Martin, ND' },
  { value: 'Martin City_MT', label: 'Martin City, MT' },
  { value: 'Martindale_TX', label: 'Martindale, TX' },
  { value: 'Martinez_CA', label: 'Martinez, CA' },
  { value: 'Martins Creek_PA', label: 'Martins Creek, PA' },
  { value: 'Martins Ferry_OH', label: 'Martins Ferry, OH' },
  { value: 'Martinsburg_NY', label: 'Martinsburg, NY' },
  { value: 'Martinsburg_PA', label: 'Martinsburg, PA' },
  { value: 'Martinsburg_WV', label: 'Martinsburg, WV' },
  { value: 'Martinsburg_OH', label: 'Martinsburg, OH' },
  { value: 'Martinsburg_IA', label: 'Martinsburg, IA' },
  { value: 'Martinsburg_MO', label: 'Martinsburg, MO' },
  { value: 'Martinsdale_MT', label: 'Martinsdale, MT' },
  { value: 'Martinsville_NJ', label: 'Martinsville, NJ' },
  { value: 'Martinsville_VA', label: 'Martinsville, VA' },
  { value: 'Martinsville_OH', label: 'Martinsville, OH' },
  { value: 'Martinsville_IN', label: 'Martinsville, IN' },
  { value: 'Martinsville_IL', label: 'Martinsville, IL' },
  { value: 'Martinsville_MO', label: 'Martinsville, MO' },
  { value: 'Martinton_IL', label: 'Martinton, IL' },
  { value: 'Martville_NY', label: 'Martville, NY' },
  { value: 'Marty_SD', label: 'Marty, SD' },
  { value: 'Marvell_AR', label: 'Marvell, AR' },
  { value: 'Marvin_SD', label: 'Marvin, SD' },
  { value: 'Mary Alice_KY', label: 'Mary Alice, KY' },
  { value: 'Mary D_PA', label: 'Mary D, PA' },
  { value: 'Mary Esther_FL', label: 'Mary Esther, FL' },
  { value: 'Marydel_DE', label: 'Marydel, DE' },
  { value: 'Marydel_MD', label: 'Marydel, MD' },
  { value: 'Maryknoll_NY', label: 'Maryknoll, NY' },
  { value: 'Maryland_NY', label: 'Maryland, NY' },
  { value: 'Maryland Heights_MO', label: 'Maryland Heights, MO' },
  { value: 'Maryland Line_MD', label: 'Maryland Line, MD' },
  { value: 'Maryneal_TX', label: 'Maryneal, TX' },
  { value: 'Marysvale_UT', label: 'Marysvale, UT' },
  { value: 'Marysville_PA', label: 'Marysville, PA' },
  { value: 'Marysville_OH', label: 'Marysville, OH' },
  { value: 'Marysville_IN', label: 'Marysville, IN' },
  { value: 'Marysville_MI', label: 'Marysville, MI' },
  { value: 'Marysville_MT', label: 'Marysville, MT' },
  { value: 'Marysville_KS', label: 'Marysville, KS' },
  { value: 'Marysville_CA', label: 'Marysville, CA' },
  { value: 'Marysville_WA', label: 'Marysville, WA' },
  { value: 'Maryville_TN', label: 'Maryville, TN' },
  { value: 'Maryville_IL', label: 'Maryville, IL' },
  { value: 'Maryville_MO', label: 'Maryville, MO' },
  { value: 'Mascot_VA', label: 'Mascot, VA' },
  { value: 'Mascot_TN', label: 'Mascot, TN' },
  { value: 'Mascotte_FL', label: 'Mascotte, FL' },
  { value: 'Mascoutah_IL', label: 'Mascoutah, IL' },
  { value: 'Mashantucket_CT', label: 'Mashantucket, CT' },
  { value: 'Mashpee_MA', label: 'Mashpee, MA' },
  { value: 'Maskell_NE', label: 'Maskell, NE' },
  { value: 'Mason_WV', label: 'Mason, WV' },
  { value: 'Mason_TN', label: 'Mason, TN' },
  { value: 'Mason_OH', label: 'Mason, OH' },
  { value: 'Mason_MI', label: 'Mason, MI' },
  { value: 'Mason_WI', label: 'Mason, WI' },
  { value: 'Mason_IL', label: 'Mason, IL' },
  { value: 'Mason_TX', label: 'Mason, TX' },
  { value: 'Mason City_IA', label: 'Mason City, IA' },
  { value: 'Mason City_IL', label: 'Mason City, IL' },
  { value: 'Mason City_NE', label: 'Mason City, NE' },
  { value: 'Masonic Home_KY', label: 'Masonic Home, KY' },
  { value: 'Masontown_PA', label: 'Masontown, PA' },
  { value: 'Masontown_WV', label: 'Masontown, WV' },
  { value: 'Masonville_NY', label: 'Masonville, NY' },
  { value: 'Masonville_IA', label: 'Masonville, IA' },
  { value: 'Maspeth_NY', label: 'Maspeth, NY' },
  { value: 'Mass City_MI', label: 'Mass City, MI' },
  { value: 'Massapequa_NY', label: 'Massapequa, NY' },
  { value: 'Massapequa Park_NY', label: 'Massapequa Park, NY' },
  { value: 'Massena_NY', label: 'Massena, NY' },
  { value: 'Massena_IA', label: 'Massena, IA' },
  { value: 'Massey_MD', label: 'Massey, MD' },
  { value: 'Massillon_OH', label: 'Massillon, OH' },
  { value: 'Masterson_TX', label: 'Masterson, TX' },
  { value: 'Mastic_NY', label: 'Mastic, NY' },
  { value: 'Mastic Beach_NY', label: 'Mastic Beach, NY' },
  { value: 'Masury_OH', label: 'Masury, OH' },
  { value: 'Matador_TX', label: 'Matador, TX' },
  { value: 'Matagorda_TX', label: 'Matagorda, TX' },
  { value: 'Matamoras_PA', label: 'Matamoras, PA' },
  { value: 'Matawan_NJ', label: 'Matawan, NJ' },
  { value: 'Matewan_WV', label: 'Matewan, WV' },
  { value: 'Matfield Green_KS', label: 'Matfield Green, KS' },
  { value: 'Matheny_WV', label: 'Matheny, WV' },
  { value: 'Mather_PA', label: 'Mather, PA' },
  { value: 'Mather_CA', label: 'Mather, CA' },
  { value: 'Matherville_IL', label: 'Matherville, IL' },
  { value: 'Matheson_CO', label: 'Matheson, CO' },
  { value: 'Mathews_VA', label: 'Mathews, VA' },
  { value: 'Mathews_AL', label: 'Mathews, AL' },
  { value: 'Mathews_LA', label: 'Mathews, LA' },
  { value: 'Mathias_WV', label: 'Mathias, WV' },
  { value: 'Mathis_TX', label: 'Mathis, TX' },
  { value: 'Mathiston_MS', label: 'Mathiston, MS' },
  { value: 'Matinicus_ME', label: 'Matinicus, ME' },
  { value: 'Matlock_IA', label: 'Matlock, IA' },
  { value: 'Matlock_WA', label: 'Matlock, WA' },
  { value: 'Matoaka_WV', label: 'Matoaka, WV' },
  { value: 'Mattapan_MA', label: 'Mattapan, MA' },
  { value: 'Mattapoisett_MA', label: 'Mattapoisett, MA' },
  { value: 'Mattaponi_VA', label: 'Mattaponi, VA' },
  { value: 'Mattawa_WA', label: 'Mattawa, WA' },
  { value: 'Mattawamkeag_ME', label: 'Mattawamkeag, ME' },
  { value: 'Mattawan_MI', label: 'Mattawan, MI' },
  { value: 'Mattawana_PA', label: 'Mattawana, PA' },
  { value: 'Matteson_IL', label: 'Matteson, IL' },
  { value: 'Matthews_NC', label: 'Matthews, NC' },
  { value: 'Matthews_GA', label: 'Matthews, GA' },
  { value: 'Matthews_IN', label: 'Matthews, IN' },
  { value: 'Matthews_MO', label: 'Matthews, MO' },
  { value: 'Mattituck_NY', label: 'Mattituck, NY' },
  { value: 'Mattoon_WI', label: 'Mattoon, WI' },
  { value: 'Mattoon_IL', label: 'Mattoon, IL' },
  { value: 'Mauckport_IN', label: 'Mauckport, IN' },
  { value: 'Maud_OK', label: 'Maud, OK' },
  { value: 'Maud_TX', label: 'Maud, TX' },
  { value: 'Maugansville_MD', label: 'Maugansville, MD' },
  { value: 'Mauk_GA', label: 'Mauk, GA' },
  { value: 'Mauldin_SC', label: 'Mauldin, SC' },
  { value: 'Maumee_OH', label: 'Maumee, OH' },
  { value: 'Maumelle_AR', label: 'Maumelle, AR' },
  { value: 'Maunabo_PR', label: 'Maunabo, PR' },
  { value: 'Maunaloa_HI', label: 'Maunaloa, HI' },
  { value: 'Maunie_IL', label: 'Maunie, IL' },
  { value: 'Maupin_OR', label: 'Maupin, OR' },
  { value: 'Maurepas_LA', label: 'Maurepas, LA' },
  { value: 'Maurertown_VA', label: 'Maurertown, VA' },
  { value: 'Maurice_IA', label: 'Maurice, IA' },
  { value: 'Maurice_LA', label: 'Maurice, LA' },
  { value: 'Mauricetown_NJ', label: 'Mauricetown, NJ' },
  { value: 'Maury_NC', label: 'Maury, NC' },
  { value: 'Maury City_TN', label: 'Maury City, TN' },
  { value: 'Mauston_WI', label: 'Mauston, WI' },
  { value: 'Max_MN', label: 'Max, MN' },
  { value: 'Max_ND', label: 'Max, ND' },
  { value: 'Max_NE', label: 'Max, NE' },
  { value: 'Max Meadows_VA', label: 'Max Meadows, VA' },
  { value: 'Maxatawny_PA', label: 'Maxatawny, PA' },
  { value: 'Maxbass_ND', label: 'Maxbass, ND' },
  { value: 'Maxton_NC', label: 'Maxton, NC' },
  { value: 'Maxwell_IN', label: 'Maxwell, IN' },
  { value: 'Maxwell_IA', label: 'Maxwell, IA' },
  { value: 'Maxwell_NE', label: 'Maxwell, NE' },
  { value: 'Maxwell_TX', label: 'Maxwell, TX' },
  { value: 'Maxwell_NM', label: 'Maxwell, NM' },
  { value: 'Maxwell_CA', label: 'Maxwell, CA' },
  { value: 'Maxwelton_WV', label: 'Maxwelton, WV' },
  { value: 'May_OK', label: 'May, OK' },
  { value: 'May_TX', label: 'May, TX' },
  { value: 'May_ID', label: 'May, ID' },
  { value: 'Mayaguez_PR', label: 'Mayaguez, PR' },
  { value: 'Maybee_MI', label: 'Maybee, MI' },
  { value: 'Maybell_CO', label: 'Maybell, CO' },
  { value: 'Maybeury_WV', label: 'Maybeury, WV' },
  { value: 'Maybrook_NY', label: 'Maybrook, NY' },
  { value: 'Mayer_MN', label: 'Mayer, MN' },
  { value: 'Mayer_AZ', label: 'Mayer, AZ' },
  { value: 'Mayersville_MS', label: 'Mayersville, MS' },
  { value: 'Mayesville_SC', label: 'Mayesville, SC' },
  { value: 'Mayetta_KS', label: 'Mayetta, KS' },
  { value: 'Mayfield_NY', label: 'Mayfield, NY' },
  { value: 'Mayfield_KY', label: 'Mayfield, KY' },
  { value: 'Mayfield_KS', label: 'Mayfield, KS' },
  { value: 'Mayfield_UT', label: 'Mayfield, UT' },
  { value: 'Mayflower_AR', label: 'Mayflower, AR' },
  { value: 'Mayhew_MS', label: 'Mayhew, MS' },
  { value: 'Mayhill_NM', label: 'Mayhill, NM' },
  { value: 'Mayking_KY', label: 'Mayking, KY' },
  { value: 'Maynard_MA', label: 'Maynard, MA' },
  { value: 'Maynard_IA', label: 'Maynard, IA' },
  { value: 'Maynard_MN', label: 'Maynard, MN' },
  { value: 'Maynard_AR', label: 'Maynard, AR' },
  { value: 'Maynardville_TN', label: 'Maynardville, TN' },
  { value: 'Mayo_SC', label: 'Mayo, SC' },
  { value: 'Mayo_FL', label: 'Mayo, FL' },
  { value: 'Mayodan_NC', label: 'Mayodan, NC' },
  { value: 'Maypearl_TX', label: 'Maypearl, TX' },
  { value: 'Mayport_PA', label: 'Mayport, PA' },
  { value: 'Mays_IN', label: 'Mays, IN' },
  { value: 'Mays Landing_NJ', label: 'Mays Landing, NJ' },
  { value: 'Maysel_WV', label: 'Maysel, WV' },
  { value: 'Mayslick_KY', label: 'Mayslick, KY' },
  { value: 'Maysville_WV', label: 'Maysville, WV' },
  { value: 'Maysville_NC', label: 'Maysville, NC' },
  { value: 'Maysville_GA', label: 'Maysville, GA' },
  { value: 'Maysville_KY', label: 'Maysville, KY' },
  { value: 'Maysville_MO', label: 'Maysville, MO' },
  { value: 'Maysville_AR', label: 'Maysville, AR' },
  { value: 'Maysville_OK', label: 'Maysville, OK' },
  { value: 'Maytown_PA', label: 'Maytown, PA' },
  { value: 'Mayview_MO', label: 'Mayview, MO' },
  { value: 'Mayville_NY', label: 'Mayville, NY' },
  { value: 'Mayville_MI', label: 'Mayville, MI' },
  { value: 'Mayville_WI', label: 'Mayville, WI' },
  { value: 'Mayville_ND', label: 'Mayville, ND' },
  { value: 'Maywood_NJ', label: 'Maywood, NJ' },
  { value: 'Maywood_IL', label: 'Maywood, IL' },
  { value: 'Maywood_MO', label: 'Maywood, MO' },
  { value: 'Maywood_NE', label: 'Maywood, NE' },
  { value: 'Maywood_CA', label: 'Maywood, CA' },
  { value: 'Mazama_WA', label: 'Mazama, WA' },
  { value: 'Mazeppa_MN', label: 'Mazeppa, MN' },
  { value: 'Mazomanie_WI', label: 'Mazomanie, WI' },
  { value: 'Mazon_IL', label: 'Mazon, IL' },
  { value: 'McAdenville_NC', label: 'McAdenville, NC' },
  { value: 'McAlisterville_PA', label: 'McAlisterville, PA' },
  { value: 'McAllister_MT', label: 'McAllister, MT' },
  { value: 'McAlpin_FL', label: 'McAlpin, FL' },
  { value: 'McAndrews_KY', label: 'McAndrews, KY' },
  { value: 'McArthur_OH', label: 'McArthur, OH' },
  { value: 'McBain_MI', label: 'McBain, MI' },
  { value: 'McBee_SC', label: 'McBee, SC' },
  { value: 'McCall Creek_MS', label: 'McCall Creek, MS' },
  { value: 'McCalla_AL', label: 'McCalla, AL' },
  { value: 'McCallsburg_IA', label: 'McCallsburg, IA' },
  { value: 'McCamey_TX', label: 'McCamey, TX' },
  { value: 'McCarley_MS', label: 'McCarley, MS' },
  { value: 'McCarr_KY', label: 'McCarr, KY' },
  { value: 'McCaskill_AR', label: 'McCaskill, AR' },
  { value: 'McCaulley_TX', label: 'McCaulley, TX' },
  { value: 'McCausland_IA', label: 'McCausland, IA' },
  { value: 'McCaysville_GA', label: 'McCaysville, GA' },
  { value: 'McClave_CO', label: 'McClave, CO' },
  { value: 'McClelland_IA', label: 'McClelland, IA' },
  { value: 'McClellandtown_PA', label: 'McClellandtown, PA' },
  { value: 'McClellanville_SC', label: 'McClellanville, SC' },
  { value: 'McClure_PA', label: 'McClure, PA' },
  { value: 'McClure_VA', label: 'McClure, VA' },
  { value: 'McClure_OH', label: 'McClure, OH' },
  { value: 'McClure_IL', label: 'McClure, IL' },
  { value: 'McColl_SC', label: 'McColl, SC' },
  { value: 'McComb_OH', label: 'McComb, OH' },
  { value: 'McConnell_IL', label: 'McConnell, IL' },
  { value: 'McConnells_SC', label: 'McConnells, SC' },
  { value: 'McConnellsburg_PA', label: 'McConnellsburg, PA' },
  { value: 'McCook_NE', label: 'McCook, NE' },
  { value: 'McCool_MS', label: 'McCool, MS' },
  { value: 'McCool Junction_NE', label: 'McCool Junction, NE' },
  { value: 'McCormick_SC', label: 'McCormick, SC' },
  { value: 'McCoy_CO', label: 'McCoy, CO' },
  { value: 'McCracken_KS', label: 'McCracken, KS' },
  { value: 'McCrory_AR', label: 'McCrory, AR' },
  { value: 'McCune_KS', label: 'McCune, KS' },
  { value: 'McCutchenville_OH', label: 'McCutchenville, OH' },
  { value: 'McDade_TX', label: 'McDade, TX' },
  { value: 'McDaniels_KY', label: 'McDaniels, KY' },
  { value: 'McDavid_FL', label: 'McDavid, FL' },
  { value: 'McDermitt_NV', label: 'McDermitt, NV' },
  { value: 'McDermott_OH', label: 'McDermott, OH' },
  { value: 'McDonald_PA', label: 'McDonald, PA' },
  { value: 'McDonald_TN', label: 'McDonald, TN' },
  { value: 'McDonald_OH', label: 'McDonald, OH' },
  { value: 'McDonald_KS', label: 'McDonald, KS' },
  { value: 'McDonough_NY', label: 'McDonough, NY' },
  { value: 'McDougal_AR', label: 'McDougal, AR' },
  { value: 'McDowell_VA', label: 'McDowell, VA' },
  { value: 'McDowell_KY', label: 'McDowell, KY' },
  { value: 'McElhattan_PA', label: 'McElhattan, PA' },
  { value: 'McEwen_TN', label: 'McEwen, TN' },
  { value: 'McEwensville_PA', label: 'McEwensville, PA' },
  { value: 'McFall_MO', label: 'McFall, MO' },
  { value: 'McFarlan_NC', label: 'McFarlan, NC' },
  { value: 'McFarland_KS', label: 'McFarland, KS' },
  { value: 'McFarland_CA', label: 'McFarland, CA' },
  { value: 'McGaheysville_VA', label: 'McGaheysville, VA' },
  { value: 'McGee_MO', label: 'McGee, MO' },
  { value: 'McGehee_AR', label: 'McGehee, AR' },
  { value: 'McGill_NV', label: 'McGill, NV' },
  { value: 'McGrady_NC', label: 'McGrady, NC' },
  { value: 'McGrann_PA', label: 'McGrann, PA' },
  { value: 'McGrath_MN', label: 'McGrath, MN' },
  { value: 'McGrath_AK', label: 'McGrath, AK' },
  { value: 'McGraw_NY', label: 'McGraw, NY' },
  { value: 'McGraws_WV', label: 'McGraws, WV' },
  { value: 'McGregor_IA', label: 'McGregor, IA' },
  { value: 'McGuffey_OH', label: 'McGuffey, OH' },
  { value: 'McHenry_MD', label: 'McHenry, MD' },
  { value: 'McHenry_MS', label: 'McHenry, MS' },
  { value: 'McHenry_KY', label: 'McHenry, KY' },
  { value: 'McIndoe Falls_VT', label: 'McIndoe Falls, VT' },
  { value: 'McIntire_IA', label: 'McIntire, IA' },
  { value: 'McIntosh_FL', label: 'McIntosh, FL' },
  { value: 'McIntosh_AL', label: 'McIntosh, AL' },
  { value: 'McIntosh_SD', label: 'McIntosh, SD' },
  { value: 'McIntyre_PA', label: 'McIntyre, PA' },
  { value: 'McIntyre_GA', label: 'McIntyre, GA' },
  { value: 'McKean_PA', label: 'McKean, PA' },
  { value: 'McKee_KY', label: 'McKee, KY' },
  { value: 'McKees Rocks_PA', label: 'McKees Rocks, PA' },
  { value: 'McKenney_VA', label: 'McKenney, VA' },
  { value: 'McKenzie_AL', label: 'McKenzie, AL' },
  { value: 'McKenzie_TN', label: 'McKenzie, TN' },
  { value: 'McKinney_KY', label: 'McKinney, KY' },
  { value: 'McKinnon_WY', label: 'McKinnon, WY' },
  { value: 'McKittrick_CA', label: 'McKittrick, CA' },
  { value: 'McKnightstown_PA', label: 'McKnightstown, PA' },
  { value: 'McLain_MS', label: 'McLain, MS' },
  { value: 'McLaughlin_SD', label: 'McLaughlin, SD' },
  { value: 'McLean_NY', label: 'McLean, NY' },
  { value: 'McLean_VA', label: 'McLean, VA' },
  { value: 'McLean_IL', label: 'McLean, IL' },
  { value: 'McLeansboro_IL', label: 'McLeansboro, IL' },
  { value: 'McLeansville_NC', label: 'McLeansville, NC' },
  { value: 'McLemoresville_TN', label: 'McLemoresville, TN' },
  { value: 'McLeod_MT', label: 'McLeod, MT' },
  { value: 'McLeod_TX', label: 'McLeod, TX' },
  { value: 'McLouth_KS', label: 'McLouth, KS' },
  { value: 'McMillan_MI', label: 'McMillan, MI' },
  { value: 'McNabb_IL', label: 'McNabb, IL' },
  { value: 'McNeal_AZ', label: 'McNeal, AZ' },
  { value: 'McNeil_AR', label: 'McNeil, AR' },
  { value: 'McQueeney_TX', label: 'McQueeney, TX' },
  { value: 'McRae_AR', label: 'McRae, AR' },
  { value: 'McRae Helena_GA', label: 'McRae Helena, GA' },
  { value: 'McRoberts_KY', label: 'McRoberts, KY' },
  { value: 'McSherrystown_PA', label: 'McSherrystown, PA' },
  { value: 'McVeytown_PA', label: 'McVeytown, PA' },
  { value: 'McWilliams_AL', label: 'McWilliams, AL' },
  { value: 'Mcadoo_PA', label: 'Mcadoo, PA' },
  { value: 'Mcadoo_TX', label: 'Mcadoo, TX' },
  { value: 'Mcalester_OK', label: 'Mcalester, OK' },
  { value: 'Mcalister_NM', label: 'Mcalister, NM' },
  { value: 'Mcallen_TX', label: 'Mcallen, TX' },
  { value: 'Mcarthur_CA', label: 'Mcarthur, CA' },
  { value: 'Mcbh Kaneohe Bay_HI', label: 'Mcbh Kaneohe Bay, HI' },
  { value: 'Mcbrides_MI', label: 'Mcbrides, MI' },
  { value: 'Mccall_ID', label: 'Mccall, ID' },
  { value: 'Mccammon_ID', label: 'Mccammon, ID' },
  { value: 'Mcchord Afb_WA', label: 'Mcchord Afb, WA' },
  { value: 'Mccleary_WA', label: 'Mccleary, WA' },
  { value: 'Mcclellan_CA', label: 'Mcclellan, CA' },
  { value: 'Mccloud_CA', label: 'Mccloud, CA' },
  { value: 'Mcclusky_ND', label: 'Mcclusky, ND' },
  { value: 'Mccomb_MS', label: 'Mccomb, MS' },
  { value: 'Mcconnell Afb_KS', label: 'Mcconnell Afb, KS' },
  { value: 'Mcconnelsville_OH', label: 'Mcconnelsville, OH' },
  { value: 'Mccordsville_IN', label: 'Mccordsville, IN' },
  { value: 'Mccurtain_OK', label: 'Mccurtain, OK' },
  { value: 'Mcdaniel_MD', label: 'Mcdaniel, MD' },
  { value: 'Mcdonald_NM', label: 'Mcdonald, NM' },
  { value: 'Mcdonough_GA', label: 'Mcdonough, GA' },
  { value: 'Mcfaddin_TX', label: 'Mcfaddin, TX' },
  { value: 'Mcfarland_WI', label: 'Mcfarland, WI' },
  { value: 'Mcgregor_MN', label: 'Mcgregor, MN' },
  { value: 'Mcgregor_ND', label: 'Mcgregor, ND' },
  { value: 'Mcgregor_TX', label: 'Mcgregor, TX' },
  { value: 'Mcgrew_NE', label: 'Mcgrew, NE' },
  { value: 'Mchenry_ND', label: 'Mchenry, ND' },
  { value: 'Mchenry_IL', label: 'Mchenry, IL' },
  { value: 'Mcintosh_MN', label: 'Mcintosh, MN' },
  { value: 'Mcintosh_NM', label: 'Mcintosh, NM' },
  { value: 'Mckeesport_PA', label: 'Mckeesport, PA' },
  { value: 'Mckenna_WA', label: 'Mckenna, WA' },
  { value: 'Mckinleyville_CA', label: 'Mckinleyville, CA' },
  { value: 'Mckinney_TX', label: 'Mckinney, TX' },
  { value: 'Mclean_NE', label: 'Mclean, NE' },
  { value: 'Mclean_TX', label: 'Mclean, TX' },
  { value: 'Mcleod_ND', label: 'Mcleod, ND' },
  { value: 'Mcloud_OK', label: 'Mcloud, OK' },
  { value: 'Mcmechen_WV', label: 'Mcmechen, WV' },
  { value: 'Mcminnville_TN', label: 'Mcminnville, TN' },
  { value: 'Mcminnville_OR', label: 'Mcminnville, OR' },
  { value: 'Mcnary_AZ', label: 'Mcnary, AZ' },
  { value: 'Mcpherson_KS', label: 'Mcpherson, KS' },
  { value: 'Mcville_ND', label: 'Mcville, ND' },
  { value: 'Meacham_OR', label: 'Meacham, OR' },
  { value: 'Mead_NE', label: 'Mead, NE' },
  { value: 'Mead_OK', label: 'Mead, OK' },
  { value: 'Mead_CO', label: 'Mead, CO' },
  { value: 'Mead_WA', label: 'Mead, WA' },
  { value: 'Meade_KS', label: 'Meade, KS' },
  { value: 'Meadow_SD', label: 'Meadow, SD' },
  { value: 'Meadow_TX', label: 'Meadow, TX' },
  { value: 'Meadow_UT', label: 'Meadow, UT' },
  { value: 'Meadow Bridge_WV', label: 'Meadow Bridge, WV' },
  { value: 'Meadow Creek_WV', label: 'Meadow Creek, WV' },
  { value: 'Meadow Grove_NE', label: 'Meadow Grove, NE' },
  { value: 'Meadow Lands_PA', label: 'Meadow Lands, PA' },
  { value: 'Meadow Valley_CA', label: 'Meadow Valley, CA' },
  { value: 'Meadow Vista_CA', label: 'Meadow Vista, CA' },
  { value: 'Meadowbrook_WV', label: 'Meadowbrook, WV' },
  { value: 'Meadowlands_MN', label: 'Meadowlands, MN' },
  { value: 'Meadows Of Dan_VA', label: 'Meadows Of Dan, VA' },
  { value: 'Meadowview_VA', label: 'Meadowview, VA' },
  { value: 'Meadview_AZ', label: 'Meadview, AZ' },
  { value: 'Meadville_PA', label: 'Meadville, PA' },
  { value: 'Meadville_MS', label: 'Meadville, MS' },
  { value: 'Meadville_MO', label: 'Meadville, MO' },
  { value: 'Meally_KY', label: 'Meally, KY' },
  { value: 'Means_KY', label: 'Means, KY' },
  { value: 'Meansville_GA', label: 'Meansville, GA' },
  { value: 'Mears_VA', label: 'Mears, VA' },
  { value: 'Mears_MI', label: 'Mears, MI' },
  { value: 'Mebane_NC', label: 'Mebane, NC' },
  { value: 'Mecca_IN', label: 'Mecca, IN' },
  { value: 'Mecca_CA', label: 'Mecca, CA' },
  { value: 'Mechanic Falls_ME', label: 'Mechanic Falls, ME' },
  { value: 'Mechanicsburg_PA', label: 'Mechanicsburg, PA' },
  { value: 'Mechanicsburg_OH', label: 'Mechanicsburg, OH' },
  { value: 'Mechanicsburg_IL', label: 'Mechanicsburg, IL' },
  { value: 'Mechanicstown_OH', label: 'Mechanicstown, OH' },
  { value: 'Mechanicsville_MD', label: 'Mechanicsville, MD' },
  { value: 'Mechanicsville_VA', label: 'Mechanicsville, VA' },
  { value: 'Mechanicsville_IA', label: 'Mechanicsville, IA' },
  { value: 'Mechanicville_NY', label: 'Mechanicville, NY' },
  { value: 'Mecosta_MI', label: 'Mecosta, MI' },
  { value: 'Medanales_NM', label: 'Medanales, NM' },
  { value: 'Medaryville_IN', label: 'Medaryville, IN' },
  { value: 'Meddybemps_ME', label: 'Meddybemps, ME' },
  { value: 'Medfield_MA', label: 'Medfield, MA' },
  { value: 'Medford_MA', label: 'Medford, MA' },
  { value: 'Medford_NJ', label: 'Medford, NJ' },
  { value: 'Medford_NY', label: 'Medford, NY' },
  { value: 'Medford_WI', label: 'Medford, WI' },
  { value: 'Medford_MN', label: 'Medford, MN' },
  { value: 'Medford_OK', label: 'Medford, OK' },
  { value: 'Medford_OR', label: 'Medford, OR' },
  { value: 'Media_PA', label: 'Media, PA' },
  { value: 'Media_IL', label: 'Media, IL' },
  { value: 'Mediapolis_IA', label: 'Mediapolis, IA' },
  { value: 'Medical Lake_WA', label: 'Medical Lake, WA' },
  { value: 'Medicine Bow_WY', label: 'Medicine Bow, WY' },
  { value: 'Medicine Lake_MT', label: 'Medicine Lake, MT' },
  { value: 'Medicine Lodge_KS', label: 'Medicine Lodge, KS' },
  { value: 'Medicine Park_OK', label: 'Medicine Park, OK' },
  { value: 'Medimont_ID', label: 'Medimont, ID' },
  { value: 'Medina_NY', label: 'Medina, NY' },
  { value: 'Medina_TN', label: 'Medina, TN' },
  { value: 'Medina_OH', label: 'Medina, OH' },
  { value: 'Medina_ND', label: 'Medina, ND' },
  { value: 'Medina_TX', label: 'Medina, TX' },
  { value: 'Medina_WA', label: 'Medina, WA' },
  { value: 'Medinah_IL', label: 'Medinah, IL' },
  { value: 'Medon_TN', label: 'Medon, TN' },
  { value: 'Medora_IN', label: 'Medora, IN' },
  { value: 'Medora_ND', label: 'Medora, ND' },
  { value: 'Medora_IL', label: 'Medora, IL' },
  { value: 'Medusa_NY', label: 'Medusa, NY' },
  { value: 'Medway_MA', label: 'Medway, MA' },
  { value: 'Medway_ME', label: 'Medway, ME' },
  { value: 'Medway_OH', label: 'Medway, OH' },
  { value: 'Meeker_OK', label: 'Meeker, OK' },
  { value: 'Meeker_CO', label: 'Meeker, CO' },
  { value: 'Meeteetse_WY', label: 'Meeteetse, WY' },
  { value: 'Megargel_TX', label: 'Megargel, TX' },
  { value: 'Mehama_OR', label: 'Mehama, OR' },
  { value: 'Meherrin_VA', label: 'Meherrin, VA' },
  { value: 'Mehoopany_PA', label: 'Mehoopany, PA' },
  { value: 'Meigs_GA', label: 'Meigs, GA' },
  { value: 'Mekinock_ND', label: 'Mekinock, ND' },
  { value: 'Mekoryuk_AK', label: 'Mekoryuk, AK' },
  { value: 'Melba_ID', label: 'Melba, ID' },
  { value: 'Melber_KY', label: 'Melber, KY' },
  { value: 'Melbeta_NE', label: 'Melbeta, NE' },
  { value: 'Melbourne_FL', label: 'Melbourne, FL' },
  { value: 'Melbourne_KY', label: 'Melbourne, KY' },
  { value: 'Melbourne_IA', label: 'Melbourne, IA' },
  { value: 'Melbourne_AR', label: 'Melbourne, AR' },
  { value: 'Melbourne Beach_FL', label: 'Melbourne Beach, FL' },
  { value: 'Melcher Dallas_IA', label: 'Melcher Dallas, IA' },
  { value: 'Melcroft_PA', label: 'Melcroft, PA' },
  { value: 'Meldrim_GA', label: 'Meldrim, GA' },
  { value: 'Melfa_VA', label: 'Melfa, VA' },
  { value: 'Melissa_TX', label: 'Melissa, TX' },
  { value: 'Mellen_WI', label: 'Mellen, WI' },
  { value: 'Mellenville_NY', label: 'Mellenville, NY' },
  { value: 'Mellette_SD', label: 'Mellette, SD' },
  { value: 'Mellott_IN', label: 'Mellott, IN' },
  { value: 'Mellwood_AR', label: 'Mellwood, AR' },
  { value: 'Melrose_MA', label: 'Melrose, MA' },
  { value: 'Melrose_NY', label: 'Melrose, NY' },
  { value: 'Melrose_FL', label: 'Melrose, FL' },
  { value: 'Melrose_OH', label: 'Melrose, OH' },
  { value: 'Melrose_IA', label: 'Melrose, IA' },
  { value: 'Melrose_WI', label: 'Melrose, WI' },
  { value: 'Melrose_MN', label: 'Melrose, MN' },
  { value: 'Melrose_MT', label: 'Melrose, MT' },
  { value: 'Melrose_LA', label: 'Melrose, LA' },
  { value: 'Melrose_NM', label: 'Melrose, NM' },
  { value: 'Melrose Park_IL', label: 'Melrose Park, IL' },
  { value: 'Melstone_MT', label: 'Melstone, MT' },
  { value: 'Melvern_KS', label: 'Melvern, KS' },
  { value: 'Melville_NY', label: 'Melville, NY' },
  { value: 'Melville_MT', label: 'Melville, MT' },
  { value: 'Melville_LA', label: 'Melville, LA' },
  { value: 'Melvin_AL', label: 'Melvin, AL' },
  { value: 'Melvin_KY', label: 'Melvin, KY' },
  { value: 'Melvin_MI', label: 'Melvin, MI' },
  { value: 'Melvin_IA', label: 'Melvin, IA' },
  { value: 'Melvin_IL', label: 'Melvin, IL' },
  { value: 'Melvin_TX', label: 'Melvin, TX' },
  { value: 'Melvin Village_NH', label: 'Melvin Village, NH' },
  { value: 'Melvindale_MI', label: 'Melvindale, MI' },
  { value: 'Memphis_NY', label: 'Memphis, NY' },
  { value: 'Memphis_TN', label: 'Memphis, TN' },
  { value: 'Memphis_IN', label: 'Memphis, IN' },
  { value: 'Memphis_MI', label: 'Memphis, MI' },
  { value: 'Memphis_MO', label: 'Memphis, MO' },
  { value: 'Memphis_NE', label: 'Memphis, NE' },
  { value: 'Memphis_TX', label: 'Memphis, TX' },
  { value: 'Mena_AR', label: 'Mena, AR' },
  { value: 'Menahga_MN', label: 'Menahga, MN' },
  { value: 'Menan_ID', label: 'Menan, ID' },
  { value: 'Menard_TX', label: 'Menard, TX' },
  { value: 'Menasha_WI', label: 'Menasha, WI' },
  { value: 'Mendenhall_MS', label: 'Mendenhall, MS' },
  { value: 'Mendham_NJ', label: 'Mendham, NJ' },
  { value: 'Mendocino_CA', label: 'Mendocino, CA' },
  { value: 'Mendon_MA', label: 'Mendon, MA' },
  { value: 'Mendon_NY', label: 'Mendon, NY' },
  { value: 'Mendon_OH', label: 'Mendon, OH' },
  { value: 'Mendon_MI', label: 'Mendon, MI' },
  { value: 'Mendon_IL', label: 'Mendon, IL' },
  { value: 'Mendon_MO', label: 'Mendon, MO' },
  { value: 'Mendon_UT', label: 'Mendon, UT' },
  { value: 'Mendota_VA', label: 'Mendota, VA' },
  { value: 'Mendota_MN', label: 'Mendota, MN' },
  { value: 'Mendota_IL', label: 'Mendota, IL' },
  { value: 'Mendota_CA', label: 'Mendota, CA' },
  { value: 'Menifee_AR', label: 'Menifee, AR' },
  { value: 'Menifee_CA', label: 'Menifee, CA' },
  { value: 'Menlo_GA', label: 'Menlo, GA' },
  { value: 'Menlo_IA', label: 'Menlo, IA' },
  { value: 'Menlo Park_CA', label: 'Menlo Park, CA' },
  { value: 'Menno_SD', label: 'Menno, SD' },
  { value: 'Meno_OK', label: 'Meno, OK' },
  { value: 'Menoken_ND', label: 'Menoken, ND' },
  { value: 'Menominee_MI', label: 'Menominee, MI' },
  { value: 'Menomonee Falls_WI', label: 'Menomonee Falls, WI' },
  { value: 'Menomonie_WI', label: 'Menomonie, WI' },
  { value: 'Mentcle_PA', label: 'Mentcle, PA' },
  { value: 'Mentmore_NM', label: 'Mentmore, NM' },
  { value: 'Mentone_AL', label: 'Mentone, AL' },
  { value: 'Mentone_IN', label: 'Mentone, IN' },
  { value: 'Mentone_TX', label: 'Mentone, TX' },
  { value: 'Mentone_CA', label: 'Mentone, CA' },
  { value: 'Mentor_OH', label: 'Mentor, OH' },
  { value: 'Mentor_MN', label: 'Mentor, MN' },
  { value: 'Mequon_WI', label: 'Mequon, WI' },
  { value: 'Mer Rouge_LA', label: 'Mer Rouge, LA' },
  { value: 'Meraux_LA', label: 'Meraux, LA' },
  { value: 'Merced_CA', label: 'Merced, CA' },
  { value: 'Mercedes_TX', label: 'Mercedes, TX' },
  { value: 'Mercedita_PR', label: 'Mercedita, PR' },
  { value: 'Mercer_PA', label: 'Mercer, PA' },
  { value: 'Mercer_TN', label: 'Mercer, TN' },
  { value: 'Mercer_WI', label: 'Mercer, WI' },
  { value: 'Mercer_ND', label: 'Mercer, ND' },
  { value: 'Mercer_MO', label: 'Mercer, MO' },
  { value: 'Mercer Island_WA', label: 'Mercer Island, WA' },
  { value: 'Mercersburg_PA', label: 'Mercersburg, PA' },
  { value: 'Merchantville_NJ', label: 'Merchantville, NJ' },
  { value: 'Meredith_NH', label: 'Meredith, NH' },
  { value: 'Meredith_CO', label: 'Meredith, CO' },
  { value: 'Meredosia_IL', label: 'Meredosia, IL' },
  { value: 'Mereta_TX', label: 'Mereta, TX' },
  { value: 'Meridale_NY', label: 'Meridale, NY' },
  { value: 'Meriden_NH', label: 'Meriden, NH' },
  { value: 'Meriden_CT', label: 'Meriden, CT' },
  { value: 'Meriden_IA', label: 'Meriden, IA' },
  { value: 'Meriden_KS', label: 'Meriden, KS' },
  { value: 'Meriden_WY', label: 'Meriden, WY' },
  { value: 'Meridian_NY', label: 'Meridian, NY' },
  { value: 'Meridian_MS', label: 'Meridian, MS' },
  { value: 'Meridian_OK', label: 'Meridian, OK' },
  { value: 'Meridian_TX', label: 'Meridian, TX' },
  { value: 'Meridian_ID', label: 'Meridian, ID' },
  { value: 'Meridian_CA', label: 'Meridian, CA' },
  { value: 'Meridianville_AL', label: 'Meridianville, AL' },
  { value: 'Merigold_MS', label: 'Merigold, MS' },
  { value: 'Merino_CO', label: 'Merino, CO' },
  { value: 'Merion Station_PA', label: 'Merion Station, PA' },
  { value: 'Merizo_GU', label: 'Merizo, GU' },
  { value: 'Merkel_TX', label: 'Merkel, TX' },
  { value: 'Merlin_OR', label: 'Merlin, OR' },
  { value: 'Mermentau_LA', label: 'Mermentau, LA' },
  { value: 'Merna_NE', label: 'Merna, NE' },
  { value: 'Merom_IN', label: 'Merom, IN' },
  { value: 'Merrick_NY', label: 'Merrick, NY' },
  { value: 'Merrifield_MN', label: 'Merrifield, MN' },
  { value: 'Merrill_MI', label: 'Merrill, MI' },
  { value: 'Merrill_IA', label: 'Merrill, IA' },
  { value: 'Merrill_WI', label: 'Merrill, WI' },
  { value: 'Merrill_OR', label: 'Merrill, OR' },
  { value: 'Merrillan_WI', label: 'Merrillan, WI' },
  { value: 'Merrillville_IN', label: 'Merrillville, IN' },
  { value: 'Merrimac_MA', label: 'Merrimac, MA' },
  { value: 'Merrimac_WI', label: 'Merrimac, WI' },
  { value: 'Merrimack_NH', label: 'Merrimack, NH' },
  { value: 'Merriman_NE', label: 'Merriman, NE' },
  { value: 'Merritt_NC', label: 'Merritt, NC' },
  { value: 'Merritt_MI', label: 'Merritt, MI' },
  { value: 'Merritt Island_FL', label: 'Merritt Island, FL' },
  { value: 'Merrittstown_PA', label: 'Merrittstown, PA' },
  { value: 'Merry Hill_NC', label: 'Merry Hill, NC' },
  { value: 'Merryville_LA', label: 'Merryville, LA' },
  { value: 'Mershon_GA', label: 'Mershon, GA' },
  { value: 'Mertens_TX', label: 'Mertens, TX' },
  { value: 'Mertzon_TX', label: 'Mertzon, TX' },
  { value: 'Mertztown_PA', label: 'Mertztown, PA' },
  { value: 'Mesa_CO', label: 'Mesa, CO' },
  { value: 'Mesa_ID', label: 'Mesa, ID' },
  { value: 'Mesa_AZ', label: 'Mesa, AZ' },
  { value: 'Mesa_WA', label: 'Mesa, WA' },
  {
    value: 'Mesa Verde National Park_CO',
    label: 'Mesa Verde National Park, CO',
  },
  { value: 'Mescalero_NM', label: 'Mescalero, NM' },
  { value: 'Meservey_IA', label: 'Meservey, IA' },
  { value: 'Meshoppen_PA', label: 'Meshoppen, PA' },
  { value: 'Mesick_MI', label: 'Mesick, MI' },
  { value: 'Mesilla_NM', label: 'Mesilla, NM' },
  { value: 'Mesilla Park_NM', label: 'Mesilla Park, NM' },
  { value: 'Mesopotamia_OH', label: 'Mesopotamia, OH' },
  { value: 'Mesquite_TX', label: 'Mesquite, TX' },
  { value: 'Mesquite_NM', label: 'Mesquite, NM' },
  { value: 'Mesquite_NV', label: 'Mesquite, NV' },
  { value: 'Meta_MO', label: 'Meta, MO' },
  { value: 'Metairie_LA', label: 'Metairie, LA' },
  { value: 'Metaline_WA', label: 'Metaline, WA' },
  { value: 'Metaline Falls_WA', label: 'Metaline Falls, WA' },
  { value: 'Metamora_OH', label: 'Metamora, OH' },
  { value: 'Metamora_IN', label: 'Metamora, IN' },
  { value: 'Metamora_MI', label: 'Metamora, MI' },
  { value: 'Metamora_IL', label: 'Metamora, IL' },
  { value: 'Metcalf_IL', label: 'Metcalf, IL' },
  { value: 'Metcalfe_MS', label: 'Metcalfe, MS' },
  { value: 'Methow_WA', label: 'Methow, WA' },
  { value: 'Methuen_MA', label: 'Methuen, MA' },
  { value: 'Metlakatla_AK', label: 'Metlakatla, AK' },
  { value: 'Metropolis_IL', label: 'Metropolis, IL' },
  { value: 'Metter_GA', label: 'Metter, GA' },
  { value: 'Metuchen_NJ', label: 'Metuchen, NJ' },
  { value: 'Metz_WV', label: 'Metz, WV' },
  { value: 'Metz_MO', label: 'Metz, MO' },
  { value: 'Mexia_TX', label: 'Mexia, TX' },
  { value: 'Mexican Hat_UT', label: 'Mexican Hat, UT' },
  { value: 'Mexican Springs_NM', label: 'Mexican Springs, NM' },
  { value: 'Mexico_ME', label: 'Mexico, ME' },
  { value: 'Mexico_NY', label: 'Mexico, NY' },
  { value: 'Mexico_PA', label: 'Mexico, PA' },
  { value: 'Mexico_IN', label: 'Mexico, IN' },
  { value: 'Mexico_MO', label: 'Mexico, MO' },
  { value: 'Mexico Beach_FL', label: 'Mexico Beach, FL' },
  { value: 'Meyers Chuck_AK', label: 'Meyers Chuck, AK' },
  { value: 'Meyersdale_PA', label: 'Meyersdale, PA' },
  { value: 'Meyersville_TX', label: 'Meyersville, TX' },
  { value: 'Mi Wuk Village_CA', label: 'Mi Wuk Village, CA' },
  { value: 'Miami_WV', label: 'Miami, WV' },
  { value: 'Miami_FL', label: 'Miami, FL' },
  { value: 'Miami_IN', label: 'Miami, IN' },
  { value: 'Miami_MO', label: 'Miami, MO' },
  { value: 'Miami_OK', label: 'Miami, OK' },
  { value: 'Miami_TX', label: 'Miami, TX' },
  { value: 'Miami_AZ', label: 'Miami, AZ' },
  { value: 'Miami_NM', label: 'Miami, NM' },
  { value: 'Miami Beach_FL', label: 'Miami Beach, FL' },
  { value: 'Miami Gardens_FL', label: 'Miami Gardens, FL' },
  { value: 'Miamisburg_OH', label: 'Miamisburg, OH' },
  { value: 'Miamitown_OH', label: 'Miamitown, OH' },
  { value: 'Miamiville_OH', label: 'Miamiville, OH' },
  { value: 'Mica_WA', label: 'Mica, WA' },
  { value: 'Micanopy_FL', label: 'Micanopy, FL' },
  { value: 'Micaville_NC', label: 'Micaville, NC' },
  { value: 'Michie_TN', label: 'Michie, TN' },
  { value: 'Michigamme_MI', label: 'Michigamme, MI' },
  { value: 'Michigan_ND', label: 'Michigan, ND' },
  { value: 'Michigan Center_MI', label: 'Michigan Center, MI' },
  { value: 'Michigan City_MS', label: 'Michigan City, MS' },
  { value: 'Michigan City_IN', label: 'Michigan City, IN' },
  { value: 'Michigantown_IN', label: 'Michigantown, IN' },
  { value: 'Mickleton_NJ', label: 'Mickleton, NJ' },
  { value: 'Mico_TX', label: 'Mico, TX' },
  { value: 'Micro_NC', label: 'Micro, NC' },
  { value: 'Middle Amana_IA', label: 'Middle Amana, IA' },
  { value: 'Middle Bass_OH', label: 'Middle Bass, OH' },
  { value: 'Middle Brook_MO', label: 'Middle Brook, MO' },
  { value: 'Middle Granville_NY', label: 'Middle Granville, NY' },
  { value: 'Middle Grove_NY', label: 'Middle Grove, NY' },
  { value: 'Middle Haddam_CT', label: 'Middle Haddam, CT' },
  { value: 'Middle Island_NY', label: 'Middle Island, NY' },
  { value: 'Middle Point_OH', label: 'Middle Point, OH' },
  { value: 'Middle River_MD', label: 'Middle River, MD' },
  { value: 'Middle River_MN', label: 'Middle River, MN' },
  { value: 'Middle Village_NY', label: 'Middle Village, NY' },
  { value: 'Middleboro_MA', label: 'Middleboro, MA' },
  { value: 'Middlebourne_WV', label: 'Middlebourne, WV' },
  { value: 'Middlebranch_OH', label: 'Middlebranch, OH' },
  { value: 'Middlebrook_VA', label: 'Middlebrook, VA' },
  { value: 'Middleburg_PA', label: 'Middleburg, PA' },
  { value: 'Middleburg_VA', label: 'Middleburg, VA' },
  { value: 'Middleburg_NC', label: 'Middleburg, NC' },
  { value: 'Middleburg_FL', label: 'Middleburg, FL' },
  { value: 'Middleburg_KY', label: 'Middleburg, KY' },
  { value: 'Middleburg_OH', label: 'Middleburg, OH' },
  { value: 'Middleburgh_NY', label: 'Middleburgh, NY' },
  { value: 'Middlebury_VT', label: 'Middlebury, VT' },
  { value: 'Middlebury_CT', label: 'Middlebury, CT' },
  { value: 'Middlebury_IN', label: 'Middlebury, IN' },
  { value: 'Middlebury Center_PA', label: 'Middlebury Center, PA' },
  { value: 'Middlefield_MA', label: 'Middlefield, MA' },
  { value: 'Middlefield_CT', label: 'Middlefield, CT' },
  { value: 'Middlefield_OH', label: 'Middlefield, OH' },
  { value: 'Middleport_NY', label: 'Middleport, NY' },
  { value: 'Middleport_PA', label: 'Middleport, PA' },
  { value: 'Middleport_OH', label: 'Middleport, OH' },
  { value: 'Middlesboro_KY', label: 'Middlesboro, KY' },
  { value: 'Middlesex_NJ', label: 'Middlesex, NJ' },
  { value: 'Middlesex_NY', label: 'Middlesex, NY' },
  { value: 'Middlesex_NC', label: 'Middlesex, NC' },
  { value: 'Middleton_MA', label: 'Middleton, MA' },
  { value: 'Middleton_TN', label: 'Middleton, TN' },
  { value: 'Middleton_MI', label: 'Middleton, MI' },
  { value: 'Middleton_WI', label: 'Middleton, WI' },
  { value: 'Middleton_ID', label: 'Middleton, ID' },
  { value: 'Middletown_RI', label: 'Middletown, RI' },
  { value: 'Middletown_CT', label: 'Middletown, CT' },
  { value: 'Middletown_NJ', label: 'Middletown, NJ' },
  { value: 'Middletown_NY', label: 'Middletown, NY' },
  { value: 'Middletown_PA', label: 'Middletown, PA' },
  { value: 'Middletown_DE', label: 'Middletown, DE' },
  { value: 'Middletown_MD', label: 'Middletown, MD' },
  { value: 'Middletown_VA', label: 'Middletown, VA' },
  { value: 'Middletown_OH', label: 'Middletown, OH' },
  { value: 'Middletown_IN', label: 'Middletown, IN' },
  { value: 'Middletown_IA', label: 'Middletown, IA' },
  { value: 'Middletown_IL', label: 'Middletown, IL' },
  { value: 'Middletown_MO', label: 'Middletown, MO' },
  { value: 'Middletown_CA', label: 'Middletown, CA' },
  { value: 'Middletown Springs_VT', label: 'Middletown Springs, VT' },
  { value: 'Middleville_NY', label: 'Middleville, NY' },
  { value: 'Middleville_MI', label: 'Middleville, MI' },
  { value: 'Midfield_TX', label: 'Midfield, TX' },
  { value: 'Midkiff_WV', label: 'Midkiff, WV' },
  { value: 'Midkiff_TX', label: 'Midkiff, TX' },
  { value: 'Midland_PA', label: 'Midland, PA' },
  { value: 'Midland_MD', label: 'Midland, MD' },
  { value: 'Midland_VA', label: 'Midland, VA' },
  { value: 'Midland_NC', label: 'Midland, NC' },
  { value: 'Midland_GA', label: 'Midland, GA' },
  { value: 'Midland_OH', label: 'Midland, OH' },
  { value: 'Midland_IN', label: 'Midland, IN' },
  { value: 'Midland_MI', label: 'Midland, MI' },
  { value: 'Midland_SD', label: 'Midland, SD' },
  { value: 'Midland_AR', label: 'Midland, AR' },
  { value: 'Midland_TX', label: 'Midland, TX' },
  { value: 'Midland_OR', label: 'Midland, OR' },
  { value: 'Midland City_AL', label: 'Midland City, AL' },
  { value: 'Midland Park_NJ', label: 'Midland Park, NJ' },
  { value: 'Midlothian_MD', label: 'Midlothian, MD' },
  { value: 'Midlothian_VA', label: 'Midlothian, VA' },
  { value: 'Midlothian_IL', label: 'Midlothian, IL' },
  { value: 'Midlothian_TX', label: 'Midlothian, TX' },
  { value: 'Midnight_MS', label: 'Midnight, MS' },
  { value: 'Midpines_CA', label: 'Midpines, CA' },
  { value: 'Midvale_OH', label: 'Midvale, OH' },
  { value: 'Midvale_ID', label: 'Midvale, ID' },
  { value: 'Midvale_UT', label: 'Midvale, UT' },
  { value: 'Midville_GA', label: 'Midville, GA' },
  { value: 'Midway_PA', label: 'Midway, PA' },
  { value: 'Midway_WV', label: 'Midway, WV' },
  { value: 'Midway_GA', label: 'Midway, GA' },
  { value: 'Midway_FL', label: 'Midway, FL' },
  { value: 'Midway_AL', label: 'Midway, AL' },
  { value: 'Midway_TN', label: 'Midway, TN' },
  { value: 'Midway_KY', label: 'Midway, KY' },
  { value: 'Midway_AR', label: 'Midway, AR' },
  { value: 'Midway_TX', label: 'Midway, TX' },
  { value: 'Midway_UT', label: 'Midway, UT' },
  { value: 'Midway City_CA', label: 'Midway City, CA' },
  { value: 'Midway Park_NC', label: 'Midway Park, NC' },
  { value: 'Midwest_WY', label: 'Midwest, WY' },
  { value: 'Mifflin_PA', label: 'Mifflin, PA' },
  { value: 'Mifflinburg_PA', label: 'Mifflinburg, PA' },
  { value: 'Mifflintown_PA', label: 'Mifflintown, PA' },
  { value: 'Mifflinville_PA', label: 'Mifflinville, PA' },
  { value: 'Mikado_MI', label: 'Mikado, MI' },
  { value: 'Mikana_WI', label: 'Mikana, WI' },
  { value: 'Milaca_MN', label: 'Milaca, MN' },
  { value: 'Milam_WV', label: 'Milam, WV' },
  { value: 'Milam_TX', label: 'Milam, TX' },
  { value: 'Milan_NH', label: 'Milan, NH' },
  { value: 'Milan_PA', label: 'Milan, PA' },
  { value: 'Milan_GA', label: 'Milan, GA' },
  { value: 'Milan_TN', label: 'Milan, TN' },
  { value: 'Milan_OH', label: 'Milan, OH' },
  { value: 'Milan_IN', label: 'Milan, IN' },
  { value: 'Milan_MI', label: 'Milan, MI' },
  { value: 'Milan_MN', label: 'Milan, MN' },
  { value: 'Milan_IL', label: 'Milan, IL' },
  { value: 'Milan_MO', label: 'Milan, MO' },
  { value: 'Milan_KS', label: 'Milan, KS' },
  { value: 'Milan_NM', label: 'Milan, NM' },
  { value: 'Milano_TX', label: 'Milano, TX' },
  { value: 'Milanville_PA', label: 'Milanville, PA' },
  { value: 'Milbank_SD', label: 'Milbank, SD' },
  { value: 'Milbridge_ME', label: 'Milbridge, ME' },
  { value: 'Milburn_OK', label: 'Milburn, OK' },
  { value: 'Mildred_PA', label: 'Mildred, PA' },
  { value: 'Miles_IA', label: 'Miles, IA' },
  { value: 'Miles_TX', label: 'Miles, TX' },
  { value: 'Miles City_MT', label: 'Miles City, MT' },
  { value: 'Milesburg_PA', label: 'Milesburg, PA' },
  { value: 'Milesville_SD', label: 'Milesville, SD' },
  { value: 'Milfay_OK', label: 'Milfay, OK' },
  { value: 'Milford_MA', label: 'Milford, MA' },
  { value: 'Milford_NH', label: 'Milford, NH' },
  { value: 'Milford_ME', label: 'Milford, ME' },
  { value: 'Milford_CT', label: 'Milford, CT' },
  { value: 'Milford_NJ', label: 'Milford, NJ' },
  { value: 'Milford_NY', label: 'Milford, NY' },
  { value: 'Milford_PA', label: 'Milford, PA' },
  { value: 'Milford_DE', label: 'Milford, DE' },
  { value: 'Milford_VA', label: 'Milford, VA' },
  { value: 'Milford_OH', label: 'Milford, OH' },
  { value: 'Milford_IN', label: 'Milford, IN' },
  { value: 'Milford_MI', label: 'Milford, MI' },
  { value: 'Milford_IA', label: 'Milford, IA' },
  { value: 'Milford_IL', label: 'Milford, IL' },
  { value: 'Milford_KS', label: 'Milford, KS' },
  { value: 'Milford_NE', label: 'Milford, NE' },
  { value: 'Milford_TX', label: 'Milford, TX' },
  { value: 'Milford_UT', label: 'Milford, UT' },
  { value: 'Milford_CA', label: 'Milford, CA' },
  { value: 'Milford Center_OH', label: 'Milford Center, OH' },
  { value: 'Milford Square_PA', label: 'Milford Square, PA' },
  { value: 'Mililani_HI', label: 'Mililani, HI' },
  { value: 'Mill City_OR', label: 'Mill City, OR' },
  { value: 'Mill Creek_PA', label: 'Mill Creek, PA' },
  { value: 'Mill Creek_WV', label: 'Mill Creek, WV' },
  { value: 'Mill Creek_IN', label: 'Mill Creek, IN' },
  { value: 'Mill Creek_OK', label: 'Mill Creek, OK' },
  { value: 'Mill Creek_CA', label: 'Mill Creek, CA' },
  { value: 'Mill Hall_PA', label: 'Mill Hall, PA' },
  { value: 'Mill Neck_NY', label: 'Mill Neck, NY' },
  { value: 'Mill River_MA', label: 'Mill River, MA' },
  { value: 'Mill Run_PA', label: 'Mill Run, PA' },
  { value: 'Mill Shoals_IL', label: 'Mill Shoals, IL' },
  { value: 'Mill Spring_NC', label: 'Mill Spring, NC' },
  { value: 'Mill Spring_MO', label: 'Mill Spring, MO' },
  { value: 'Mill Valley_CA', label: 'Mill Valley, CA' },
  { value: 'Mill Village_PA', label: 'Mill Village, PA' },
  { value: 'Milladore_WI', label: 'Milladore, WI' },
  { value: 'Millboro_VA', label: 'Millboro, VA' },
  { value: 'Millbrae_CA', label: 'Millbrae, CA' },
  { value: 'Millbrook_NY', label: 'Millbrook, NY' },
  { value: 'Millbrook_AL', label: 'Millbrook, AL' },
  { value: 'Millbrook_IL', label: 'Millbrook, IL' },
  { value: 'Millburn_NJ', label: 'Millburn, NJ' },
  { value: 'Millbury_MA', label: 'Millbury, MA' },
  { value: 'Millbury_OH', label: 'Millbury, OH' },
  { value: 'Millcreek_IL', label: 'Millcreek, IL' },
  { value: 'Milldale_CT', label: 'Milldale, CT' },
  { value: 'Milledgeville_GA', label: 'Milledgeville, GA' },
  { value: 'Milledgeville_TN', label: 'Milledgeville, TN' },
  { value: 'Milledgeville_OH', label: 'Milledgeville, OH' },
  { value: 'Milledgeville_IL', label: 'Milledgeville, IL' },
  { value: 'Millen_GA', label: 'Millen, GA' },
  { value: 'Miller_SD', label: 'Miller, SD' },
  { value: 'Miller_MO', label: 'Miller, MO' },
  { value: 'Miller_NE', label: 'Miller, NE' },
  { value: 'Miller City_OH', label: 'Miller City, OH' },
  { value: 'Miller City_IL', label: 'Miller City, IL' },
  { value: 'Miller Place_NY', label: 'Miller Place, NY' },
  { value: 'Millers Creek_NC', label: 'Millers Creek, NC' },
  { value: 'Millers Falls_MA', label: 'Millers Falls, MA' },
  { value: 'Millersburg_PA', label: 'Millersburg, PA' },
  { value: 'Millersburg_KY', label: 'Millersburg, KY' },
  { value: 'Millersburg_OH', label: 'Millersburg, OH' },
  { value: 'Millersburg_IN', label: 'Millersburg, IN' },
  { value: 'Millersburg_MI', label: 'Millersburg, MI' },
  { value: 'Millersburg_IA', label: 'Millersburg, IA' },
  { value: 'Millersport_OH', label: 'Millersport, OH' },
  { value: 'Millerstown_PA', label: 'Millerstown, PA' },
  { value: 'Millersview_TX', label: 'Millersview, TX' },
  { value: 'Millersville_PA', label: 'Millersville, PA' },
  { value: 'Millersville_MD', label: 'Millersville, MD' },
  { value: 'Millersville_MO', label: 'Millersville, MO' },
  { value: 'Millerton_NY', label: 'Millerton, NY' },
  { value: 'Millerton_PA', label: 'Millerton, PA' },
  { value: 'Millerton_IA', label: 'Millerton, IA' },
  { value: 'Millerton_OK', label: 'Millerton, OK' },
  { value: 'Millerville_AL', label: 'Millerville, AL' },
  { value: 'Millfield_OH', label: 'Millfield, OH' },
  { value: 'Millheim_PA', label: 'Millheim, PA' },
  { value: 'Millican_TX', label: 'Millican, TX' },
  { value: 'Milligan_NE', label: 'Milligan, NE' },
  { value: 'Milligan College_TN', label: 'Milligan College, TN' },
  { value: 'Milliken_CO', label: 'Milliken, CO' },
  { value: 'Millington_NJ', label: 'Millington, NJ' },
  { value: 'Millington_MD', label: 'Millington, MD' },
  { value: 'Millington_TN', label: 'Millington, TN' },
  { value: 'Millington_MI', label: 'Millington, MI' },
  { value: 'Millington_IL', label: 'Millington, IL' },
  { value: 'Millinocket_ME', label: 'Millinocket, ME' },
  { value: 'Millis_MA', label: 'Millis, MA' },
  { value: 'Millmont_PA', label: 'Millmont, PA' },
  { value: 'Millport_NY', label: 'Millport, NY' },
  { value: 'Millport_AL', label: 'Millport, AL' },
  { value: 'Millrift_PA', label: 'Millrift, PA' },
  { value: 'Millry_AL', label: 'Millry, AL' },
  { value: 'Mills_PA', label: 'Mills, PA' },
  { value: 'Mills_NE', label: 'Mills, NE' },
  { value: 'Mills_WY', label: 'Mills, WY' },
  { value: 'Mills_NM', label: 'Mills, NM' },
  { value: 'Mills River_NC', label: 'Mills River, NC' },
  { value: 'Millsap_TX', label: 'Millsap, TX' },
  { value: 'Millsboro_PA', label: 'Millsboro, PA' },
  { value: 'Millsboro_DE', label: 'Millsboro, DE' },
  { value: 'Millstadt_IL', label: 'Millstadt, IL' },
  { value: 'Millston_WI', label: 'Millston, WI' },
  { value: 'Millstone_WV', label: 'Millstone, WV' },
  { value: 'Millstone_KY', label: 'Millstone, KY' },
  { value: 'Millstone Township_NJ', label: 'Millstone Township, NJ' },
  { value: 'Milltown_NJ', label: 'Milltown, NJ' },
  { value: 'Milltown_IN', label: 'Milltown, IN' },
  { value: 'Milltown_WI', label: 'Milltown, WI' },
  { value: 'Milltown_MT', label: 'Milltown, MT' },
  { value: 'Millville_MA', label: 'Millville, MA' },
  { value: 'Millville_NJ', label: 'Millville, NJ' },
  { value: 'Millville_PA', label: 'Millville, PA' },
  { value: 'Millville_DE', label: 'Millville, DE' },
  { value: 'Millville_WV', label: 'Millville, WV' },
  { value: 'Millville_MN', label: 'Millville, MN' },
  { value: 'Millville_UT', label: 'Millville, UT' },
  { value: 'Millville_CA', label: 'Millville, CA' },
  { value: 'Millwood_NY', label: 'Millwood, NY' },
  { value: 'Millwood_VA', label: 'Millwood, VA' },
  { value: 'Millwood_WV', label: 'Millwood, WV' },
  { value: 'Millwood_GA', label: 'Millwood, GA' },
  { value: 'Millwood_KY', label: 'Millwood, KY' },
  { value: 'Milmay_NJ', label: 'Milmay, NJ' },
  { value: 'Milmine_IL', label: 'Milmine, IL' },
  { value: 'Milner_GA', label: 'Milner, GA' },
  { value: 'Milnesand_NM', label: 'Milnesand, NM' },
  { value: 'Milnesville_PA', label: 'Milnesville, PA' },
  { value: 'Milnor_ND', label: 'Milnor, ND' },
  { value: 'Milo_ME', label: 'Milo, ME' },
  { value: 'Milo_IA', label: 'Milo, IA' },
  { value: 'Milo_MO', label: 'Milo, MO' },
  { value: 'Milpitas_CA', label: 'Milpitas, CA' },
  { value: 'Milroy_PA', label: 'Milroy, PA' },
  { value: 'Milroy_IN', label: 'Milroy, IN' },
  { value: 'Milroy_MN', label: 'Milroy, MN' },
  { value: 'Milton_MA', label: 'Milton, MA' },
  { value: 'Milton_NH', label: 'Milton, NH' },
  { value: 'Milton_VT', label: 'Milton, VT' },
  { value: 'Milton_NY', label: 'Milton, NY' },
  { value: 'Milton_PA', label: 'Milton, PA' },
  { value: 'Milton_DE', label: 'Milton, DE' },
  { value: 'Milton_WV', label: 'Milton, WV' },
  { value: 'Milton_NC', label: 'Milton, NC' },
  { value: 'Milton_FL', label: 'Milton, FL' },
  { value: 'Milton_TN', label: 'Milton, TN' },
  { value: 'Milton_KY', label: 'Milton, KY' },
  { value: 'Milton_IN', label: 'Milton, IN' },
  { value: 'Milton_IA', label: 'Milton, IA' },
  { value: 'Milton_WI', label: 'Milton, WI' },
  { value: 'Milton_ND', label: 'Milton, ND' },
  { value: 'Milton_IL', label: 'Milton, IL' },
  { value: 'Milton_KS', label: 'Milton, KS' },
  { value: 'Milton_LA', label: 'Milton, LA' },
  { value: 'Milton_WA', label: 'Milton, WA' },
  { value: 'Milton Center_OH', label: 'Milton Center, OH' },
  { value: 'Milton Freewater_OR', label: 'Milton Freewater, OR' },
  { value: 'Milton Mills_NH', label: 'Milton Mills, NH' },
  { value: 'Miltona_MN', label: 'Miltona, MN' },
  { value: 'Miltonvale_KS', label: 'Miltonvale, KS' },
  { value: 'Milwaukee_WI', label: 'Milwaukee, WI' },
  { value: 'Mimbres_NM', label: 'Mimbres, NM' },
  { value: 'Mims_FL', label: 'Mims, FL' },
  { value: 'Mina_NV', label: 'Mina, NV' },
  { value: 'Minatare_NE', label: 'Minatare, NE' },
  { value: 'Minburn_IA', label: 'Minburn, IA' },
  { value: 'Minco_OK', label: 'Minco, OK' },
  { value: 'Minden_WV', label: 'Minden, WV' },
  { value: 'Minden_IA', label: 'Minden, IA' },
  { value: 'Minden_NE', label: 'Minden, NE' },
  { value: 'Minden_LA', label: 'Minden, LA' },
  { value: 'Minden_TX', label: 'Minden, TX' },
  { value: 'Minden_NV', label: 'Minden, NV' },
  { value: 'Minden City_MI', label: 'Minden City, MI' },
  { value: 'Mindenmines_MO', label: 'Mindenmines, MO' },
  { value: 'Mindoro_WI', label: 'Mindoro, WI' },
  { value: 'Mine Hill_NJ', label: 'Mine Hill, NJ' },
  { value: 'Mineola_NY', label: 'Mineola, NY' },
  { value: 'Mineola_IA', label: 'Mineola, IA' },
  { value: 'Mineola_TX', label: 'Mineola, TX' },
  { value: 'Mineral_VA', label: 'Mineral, VA' },
  { value: 'Mineral_IL', label: 'Mineral, IL' },
  { value: 'Mineral_TX', label: 'Mineral, TX' },
  { value: 'Mineral_CA', label: 'Mineral, CA' },
  { value: 'Mineral_WA', label: 'Mineral, WA' },
  { value: 'Mineral Bluff_GA', label: 'Mineral Bluff, GA' },
  { value: 'Mineral City_OH', label: 'Mineral City, OH' },
  { value: 'Mineral Point_PA', label: 'Mineral Point, PA' },
  { value: 'Mineral Point_WI', label: 'Mineral Point, WI' },
  { value: 'Mineral Point_MO', label: 'Mineral Point, MO' },
  { value: 'Mineral Ridge_OH', label: 'Mineral Ridge, OH' },
  { value: 'Mineral Springs_PA', label: 'Mineral Springs, PA' },
  { value: 'Mineral Springs_NC', label: 'Mineral Springs, NC' },
  { value: 'Mineral Springs_AR', label: 'Mineral Springs, AR' },
  { value: 'Mineral Wells_WV', label: 'Mineral Wells, WV' },
  { value: 'Mineral Wells_TX', label: 'Mineral Wells, TX' },
  { value: 'Minersville_PA', label: 'Minersville, PA' },
  { value: 'Minersville_UT', label: 'Minersville, UT' },
  { value: 'Minerva_NY', label: 'Minerva, NY' },
  { value: 'Minerva_OH', label: 'Minerva, OH' },
  { value: 'Minetto_NY', label: 'Minetto, NY' },
  { value: 'Mineville_NY', label: 'Mineville, NY' },
  { value: 'Minford_OH', label: 'Minford, OH' },
  { value: 'Mingo_OH', label: 'Mingo, OH' },
  { value: 'Mingo_IA', label: 'Mingo, IA' },
  { value: 'Mingo Junction_OH', label: 'Mingo Junction, OH' },
  { value: 'Mingoville_PA', label: 'Mingoville, PA' },
  { value: 'Mingus_TX', label: 'Mingus, TX' },
  { value: 'Minier_IL', label: 'Minier, IL' },
  { value: 'Minneapolis_NC', label: 'Minneapolis, NC' },
  { value: 'Minneapolis_MN', label: 'Minneapolis, MN' },
  { value: 'Minneapolis_KS', label: 'Minneapolis, KS' },
  { value: 'Minneola_KS', label: 'Minneola, KS' },
  { value: 'Minneota_MN', label: 'Minneota, MN' },
  { value: 'Minnesota City_MN', label: 'Minnesota City, MN' },
  { value: 'Minnesota Lake_MN', label: 'Minnesota Lake, MN' },
  { value: 'Minnetonka_MN', label: 'Minnetonka, MN' },
  { value: 'Minnewaukan_ND', label: 'Minnewaukan, ND' },
  { value: 'Minnie_KY', label: 'Minnie, KY' },
  { value: 'Minoa_NY', label: 'Minoa, NY' },
  { value: 'Minocqua_WI', label: 'Minocqua, WI' },
  { value: 'Minong_WI', label: 'Minong, WI' },
  { value: 'Minonk_IL', label: 'Minonk, IL' },
  { value: 'Minooka_IL', label: 'Minooka, IL' },
  { value: 'Minor Hill_TN', label: 'Minor Hill, TN' },
  { value: 'Minot_ME', label: 'Minot, ME' },
  { value: 'Minot_ND', label: 'Minot, ND' },
  { value: 'Minot Afb_ND', label: 'Minot Afb, ND' },
  { value: 'Minotola_NJ', label: 'Minotola, NJ' },
  { value: 'Minster_OH', label: 'Minster, OH' },
  { value: 'Minter_AL', label: 'Minter, AL' },
  { value: 'Minter City_MS', label: 'Minter City, MS' },
  { value: 'Minto_ND', label: 'Minto, ND' },
  { value: 'Minto_AK', label: 'Minto, AK' },
  { value: 'Minturn_AR', label: 'Minturn, AR' },
  { value: 'Minturn_CO', label: 'Minturn, CO' },
  { value: 'Mio_MI', label: 'Mio, MI' },
  { value: 'Mira Loma_CA', label: 'Mira Loma, CA' },
  { value: 'Miracle_KY', label: 'Miracle, KY' },
  { value: 'Miramar Beach_FL', label: 'Miramar Beach, FL' },
  { value: 'Miramonte_CA', label: 'Miramonte, CA' },
  { value: 'Miranda_CA', label: 'Miranda, CA' },
  { value: 'Mirando City_TX', label: 'Mirando City, TX' },
  { value: 'Mirror Lake_NH', label: 'Mirror Lake, NH' },
  { value: 'Misenheimer_NC', label: 'Misenheimer, NC' },
  { value: 'Mishawaka_IN', label: 'Mishawaka, IN' },
  { value: 'Mishicot_WI', label: 'Mishicot, WI' },
  { value: 'Mission_SD', label: 'Mission, SD' },
  { value: 'Mission_KS', label: 'Mission, KS' },
  { value: 'Mission_TX', label: 'Mission, TX' },
  { value: 'Mission Hill_SD', label: 'Mission Hill, SD' },
  { value: 'Mission Hills_CA', label: 'Mission Hills, CA' },
  { value: 'Mission Viejo_CA', label: 'Mission Viejo, CA' },
  { value: 'Mississippi State_MS', label: 'Mississippi State, MS' },
  { value: 'Missoula_MT', label: 'Missoula, MT' },
  { value: 'Missouri City_MO', label: 'Missouri City, MO' },
  { value: 'Missouri City_TX', label: 'Missouri City, TX' },
  { value: 'Missouri Valley_IA', label: 'Missouri Valley, IA' },
  { value: 'Mistletoe_KY', label: 'Mistletoe, KY' },
  { value: 'Mitchell_GA', label: 'Mitchell, GA' },
  { value: 'Mitchell_IN', label: 'Mitchell, IN' },
  { value: 'Mitchell_SD', label: 'Mitchell, SD' },
  { value: 'Mitchell_NE', label: 'Mitchell, NE' },
  { value: 'Mitchell_OR', label: 'Mitchell, OR' },
  { value: 'Mitchells_VA', label: 'Mitchells, VA' },
  { value: 'Mitchellville_IA', label: 'Mitchellville, IA' },
  { value: 'Mittie_LA', label: 'Mittie, LA' },
  { value: 'Mize_MS', label: 'Mize, MS' },
  { value: 'Mize_KY', label: 'Mize, KY' },
  { value: 'Mizpah_MN', label: 'Mizpah, MN' },
  { value: 'Moab_UT', label: 'Moab, UT' },
  { value: 'Moapa_NV', label: 'Moapa, NV' },
  { value: 'Moatsville_WV', label: 'Moatsville, WV' },
  { value: 'Mobeetie_TX', label: 'Mobeetie, TX' },
  { value: 'Moberly_MO', label: 'Moberly, MO' },
  { value: 'Mobile_AL', label: 'Mobile, AL' },
  { value: 'Mobridge_SD', label: 'Mobridge, SD' },
  { value: 'Moca_PR', label: 'Moca, PR' },
  { value: 'Moccasin_MT', label: 'Moccasin, MT' },
  { value: 'Mocksville_NC', label: 'Mocksville, NC' },
  { value: 'Moclips_WA', label: 'Moclips, WA' },
  { value: 'Modale_IA', label: 'Modale, IA' },
  { value: 'Mode_IL', label: 'Mode, IL' },
  { value: 'Model_CO', label: 'Model, CO' },
  { value: 'Modena_NY', label: 'Modena, NY' },
  { value: 'Modena_PA', label: 'Modena, PA' },
  { value: 'Modena_UT', label: 'Modena, UT' },
  { value: 'Modesto_IL', label: 'Modesto, IL' },
  { value: 'Modesto_CA', label: 'Modesto, CA' },
  { value: 'Modoc_SC', label: 'Modoc, SC' },
  { value: 'Modoc_IN', label: 'Modoc, IN' },
  { value: 'Modoc_IL', label: 'Modoc, IL' },
  { value: 'Moffat_CO', label: 'Moffat, CO' },
  { value: 'Moffett_OK', label: 'Moffett, OK' },
  { value: 'Moffit_ND', label: 'Moffit, ND' },
  { value: 'Mogadore_OH', label: 'Mogadore, OH' },
  { value: 'Mohall_ND', label: 'Mohall, ND' },
  { value: 'Mohave Valley_AZ', label: 'Mohave Valley, AZ' },
  { value: 'Mohawk_NY', label: 'Mohawk, NY' },
  { value: 'Mohawk_WV', label: 'Mohawk, WV' },
  { value: 'Mohawk_TN', label: 'Mohawk, TN' },
  { value: 'Mohawk_MI', label: 'Mohawk, MI' },
  { value: 'Mohegan Lake_NY', label: 'Mohegan Lake, NY' },
  { value: 'Mohler_WA', label: 'Mohler, WA' },
  { value: 'Mohnton_PA', label: 'Mohnton, PA' },
  { value: 'Mohrsville_PA', label: 'Mohrsville, PA' },
  { value: 'Moira_NY', label: 'Moira, NY' },
  { value: 'Mojave_CA', label: 'Mojave, CA' },
  { value: 'Mokane_MO', label: 'Mokane, MO' },
  { value: 'Mokelumne Hill_CA', label: 'Mokelumne Hill, CA' },
  { value: 'Mokena_IL', label: 'Mokena, IL' },
  { value: 'Molalla_OR', label: 'Molalla, OR' },
  { value: 'Molena_GA', label: 'Molena, GA' },
  { value: 'Molina_CO', label: 'Molina, CO' },
  { value: 'Moline_MI', label: 'Moline, MI' },
  { value: 'Moline_IL', label: 'Moline, IL' },
  { value: 'Moline_KS', label: 'Moline, KS' },
  { value: 'Molino_FL', label: 'Molino, FL' },
  { value: 'Molt_MT', label: 'Molt, MT' },
  { value: 'Momence_IL', label: 'Momence, IL' },
  { value: 'Mona_UT', label: 'Mona, UT' },
  { value: 'Monaca_PA', label: 'Monaca, PA' },
  { value: 'Monahans_TX', label: 'Monahans, TX' },
  { value: 'Monarch_MT', label: 'Monarch, MT' },
  { value: 'Monarch_CO', label: 'Monarch, CO' },
  { value: 'Moncks Corner_SC', label: 'Moncks Corner, SC' },
  { value: 'Monclova_OH', label: 'Monclova, OH' },
  { value: 'Moncure_NC', label: 'Moncure, NC' },
  { value: 'Mondamin_IA', label: 'Mondamin, IA' },
  { value: 'Mondovi_WI', label: 'Mondovi, WI' },
  { value: 'Monee_IL', label: 'Monee, IL' },
  { value: 'Monessen_PA', label: 'Monessen, PA' },
  { value: 'Moneta_VA', label: 'Moneta, VA' },
  { value: 'Monett_MO', label: 'Monett, MO' },
  { value: 'Monetta_SC', label: 'Monetta, SC' },
  { value: 'Monette_AR', label: 'Monette, AR' },
  { value: 'Mongaup Valley_NY', label: 'Mongaup Valley, NY' },
  { value: 'Mongo_IN', label: 'Mongo, IN' },
  { value: 'Monhegan_ME', label: 'Monhegan, ME' },
  { value: 'Monitor_WA', label: 'Monitor, WA' },
  { value: 'Monkton_MD', label: 'Monkton, MD' },
  { value: 'Monmouth_ME', label: 'Monmouth, ME' },
  { value: 'Monmouth_IA', label: 'Monmouth, IA' },
  { value: 'Monmouth_IL', label: 'Monmouth, IL' },
  { value: 'Monmouth_OR', label: 'Monmouth, OR' },
  { value: 'Monmouth Beach_NJ', label: 'Monmouth Beach, NJ' },
  { value: 'Monmouth Junction_NJ', label: 'Monmouth Junction, NJ' },
  { value: 'Monon_IN', label: 'Monon, IN' },
  { value: 'Monona_IA', label: 'Monona, IA' },
  { value: 'Monongahela_PA', label: 'Monongahela, PA' },
  { value: 'Monponsett_MA', label: 'Monponsett, MA' },
  { value: 'Monroe_NH', label: 'Monroe, NH' },
  { value: 'Monroe_ME', label: 'Monroe, ME' },
  { value: 'Monroe_CT', label: 'Monroe, CT' },
  { value: 'Monroe_NY', label: 'Monroe, NY' },
  { value: 'Monroe_VA', label: 'Monroe, VA' },
  { value: 'Monroe_NC', label: 'Monroe, NC' },
  { value: 'Monroe_GA', label: 'Monroe, GA' },
  { value: 'Monroe_TN', label: 'Monroe, TN' },
  { value: 'Monroe_OH', label: 'Monroe, OH' },
  { value: 'Monroe_IN', label: 'Monroe, IN' },
  { value: 'Monroe_MI', label: 'Monroe, MI' },
  { value: 'Monroe_IA', label: 'Monroe, IA' },
  { value: 'Monroe_WI', label: 'Monroe, WI' },
  { value: 'Monroe_SD', label: 'Monroe, SD' },
  { value: 'Monroe_NE', label: 'Monroe, NE' },
  { value: 'Monroe_LA', label: 'Monroe, LA' },
  { value: 'Monroe_AR', label: 'Monroe, AR' },
  { value: 'Monroe_OK', label: 'Monroe, OK' },
  { value: 'Monroe_UT', label: 'Monroe, UT' },
  { value: 'Monroe_OR', label: 'Monroe, OR' },
  { value: 'Monroe_WA', label: 'Monroe, WA' },
  { value: 'Monroe Bridge_MA', label: 'Monroe Bridge, MA' },
  { value: 'Monroe Center_IL', label: 'Monroe Center, IL' },
  { value: 'Monroe City_IN', label: 'Monroe City, IN' },
  { value: 'Monroe City_MO', label: 'Monroe City, MO' },
  { value: 'Monroe Township_NJ', label: 'Monroe Township, NJ' },
  { value: 'Monroeton_PA', label: 'Monroeton, PA' },
  { value: 'Monroeville_NJ', label: 'Monroeville, NJ' },
  { value: 'Monroeville_PA', label: 'Monroeville, PA' },
  { value: 'Monroeville_AL', label: 'Monroeville, AL' },
  { value: 'Monroeville_OH', label: 'Monroeville, OH' },
  { value: 'Monroeville_IN', label: 'Monroeville, IN' },
  { value: 'Monrovia_MD', label: 'Monrovia, MD' },
  { value: 'Monrovia_IN', label: 'Monrovia, IN' },
  { value: 'Monrovia_CA', label: 'Monrovia, CA' },
  { value: 'Monsey_NY', label: 'Monsey, NY' },
  { value: 'Monson_MA', label: 'Monson, MA' },
  { value: 'Monson_ME', label: 'Monson, ME' },
  { value: 'Mont Alto_PA', label: 'Mont Alto, PA' },
  { value: 'Mont Belvieu_TX', label: 'Mont Belvieu, TX' },
  { value: 'Mont Clare_PA', label: 'Mont Clare, PA' },
  { value: 'Mont Vernon_NH', label: 'Mont Vernon, NH' },
  { value: 'Montague_MA', label: 'Montague, MA' },
  { value: 'Montague_NJ', label: 'Montague, NJ' },
  { value: 'Montague_MI', label: 'Montague, MI' },
  { value: 'Montague_TX', label: 'Montague, TX' },
  { value: 'Montague_CA', label: 'Montague, CA' },
  { value: 'Montalba_TX', label: 'Montalba, TX' },
  { value: 'Montana Mines_WV', label: 'Montana Mines, WV' },
  { value: 'Montandon_PA', label: 'Montandon, PA' },
  { value: 'Montara_CA', label: 'Montara, CA' },
  { value: 'Montauk_NY', label: 'Montauk, NY' },
  { value: 'Montcalm_WV', label: 'Montcalm, WV' },
  { value: 'Montchanin_DE', label: 'Montchanin, DE' },
  { value: 'Montclair_NJ', label: 'Montclair, NJ' },
  { value: 'Montclair_CA', label: 'Montclair, CA' },
  { value: 'Monte Rio_CA', label: 'Monte Rio, CA' },
  { value: 'Monte Vista_CO', label: 'Monte Vista, CO' },
  { value: 'Monteagle_TN', label: 'Monteagle, TN' },
  { value: 'Montebello_VA', label: 'Montebello, VA' },
  { value: 'Montebello_CA', label: 'Montebello, CA' },
  { value: 'Montegut_LA', label: 'Montegut, LA' },
  { value: 'Montello_WI', label: 'Montello, WI' },
  { value: 'Montello_NV', label: 'Montello, NV' },
  { value: 'Monterey_MA', label: 'Monterey, MA' },
  { value: 'Monterey_VA', label: 'Monterey, VA' },
  { value: 'Monterey_TN', label: 'Monterey, TN' },
  { value: 'Monterey_IN', label: 'Monterey, IN' },
  { value: 'Monterey_LA', label: 'Monterey, LA' },
  { value: 'Monterey_CA', label: 'Monterey, CA' },
  { value: 'Monterey Park_CA', label: 'Monterey Park, CA' },
  { value: 'Monterville_WV', label: 'Monterville, WV' },
  { value: 'Montesano_WA', label: 'Montesano, WA' },
  { value: 'Montevallo_AL', label: 'Montevallo, AL' },
  { value: 'Montevideo_MN', label: 'Montevideo, MN' },
  { value: 'Monteview_ID', label: 'Monteview, ID' },
  { value: 'Montezuma_NY', label: 'Montezuma, NY' },
  { value: 'Montezuma_NC', label: 'Montezuma, NC' },
  { value: 'Montezuma_GA', label: 'Montezuma, GA' },
  { value: 'Montezuma_OH', label: 'Montezuma, OH' },
  { value: 'Montezuma_IN', label: 'Montezuma, IN' },
  { value: 'Montezuma_IA', label: 'Montezuma, IA' },
  { value: 'Montezuma_KS', label: 'Montezuma, KS' },
  { value: 'Montezuma_NM', label: 'Montezuma, NM' },
  { value: 'Montezuma Creek_UT', label: 'Montezuma Creek, UT' },
  { value: 'Montfort_WI', label: 'Montfort, WI' },
  { value: 'Montgomery_NY', label: 'Montgomery, NY' },
  { value: 'Montgomery_PA', label: 'Montgomery, PA' },
  { value: 'Montgomery_WV', label: 'Montgomery, WV' },
  { value: 'Montgomery_AL', label: 'Montgomery, AL' },
  { value: 'Montgomery_IN', label: 'Montgomery, IN' },
  { value: 'Montgomery_MI', label: 'Montgomery, MI' },
  { value: 'Montgomery_MN', label: 'Montgomery, MN' },
  { value: 'Montgomery_IL', label: 'Montgomery, IL' },
  { value: 'Montgomery_LA', label: 'Montgomery, LA' },
  { value: 'Montgomery_TX', label: 'Montgomery, TX' },
  { value: 'Montgomery Center_VT', label: 'Montgomery Center, VT' },
  { value: 'Montgomery City_MO', label: 'Montgomery City, MO' },
  { value: 'Montgomery Creek_CA', label: 'Montgomery Creek, CA' },
  { value: 'Montgomery Village_MD', label: 'Montgomery Village, MD' },
  { value: 'Montgomeryville_PA', label: 'Montgomeryville, PA' },
  { value: 'Monticello_ME', label: 'Monticello, ME' },
  { value: 'Monticello_NY', label: 'Monticello, NY' },
  { value: 'Monticello_GA', label: 'Monticello, GA' },
  { value: 'Monticello_FL', label: 'Monticello, FL' },
  { value: 'Monticello_MS', label: 'Monticello, MS' },
  { value: 'Monticello_KY', label: 'Monticello, KY' },
  { value: 'Monticello_IN', label: 'Monticello, IN' },
  { value: 'Monticello_IA', label: 'Monticello, IA' },
  { value: 'Monticello_WI', label: 'Monticello, WI' },
  { value: 'Monticello_MN', label: 'Monticello, MN' },
  { value: 'Monticello_IL', label: 'Monticello, IL' },
  { value: 'Monticello_MO', label: 'Monticello, MO' },
  { value: 'Monticello_AR', label: 'Monticello, AR' },
  { value: 'Monticello_UT', label: 'Monticello, UT' },
  { value: 'Monticello_NM', label: 'Monticello, NM' },
  { value: 'Montour_IA', label: 'Montour, IA' },
  { value: 'Montour Falls_NY', label: 'Montour Falls, NY' },
  { value: 'Montoursville_PA', label: 'Montoursville, PA' },
  { value: 'Montpelier_VT', label: 'Montpelier, VT' },
  { value: 'Montpelier_VA', label: 'Montpelier, VA' },
  { value: 'Montpelier_OH', label: 'Montpelier, OH' },
  { value: 'Montpelier_IN', label: 'Montpelier, IN' },
  { value: 'Montpelier_IA', label: 'Montpelier, IA' },
  { value: 'Montpelier_ND', label: 'Montpelier, ND' },
  { value: 'Montpelier_ID', label: 'Montpelier, ID' },
  { value: 'Montpelier Station_VA', label: 'Montpelier Station, VA' },
  { value: 'Montreal_WI', label: 'Montreal, WI' },
  { value: 'Montreal_MO', label: 'Montreal, MO' },
  { value: 'Montreat_NC', label: 'Montreat, NC' },
  { value: 'Montrose_NY', label: 'Montrose, NY' },
  { value: 'Montrose_PA', label: 'Montrose, PA' },
  { value: 'Montrose_WV', label: 'Montrose, WV' },
  { value: 'Montrose_GA', label: 'Montrose, GA' },
  { value: 'Montrose_AL', label: 'Montrose, AL' },
  { value: 'Montrose_MI', label: 'Montrose, MI' },
  { value: 'Montrose_IA', label: 'Montrose, IA' },
  { value: 'Montrose_MN', label: 'Montrose, MN' },
  { value: 'Montrose_SD', label: 'Montrose, SD' },
  { value: 'Montrose_IL', label: 'Montrose, IL' },
  { value: 'Montrose_MO', label: 'Montrose, MO' },
  { value: 'Montrose_AR', label: 'Montrose, AR' },
  { value: 'Montrose_CO', label: 'Montrose, CO' },
  { value: 'Montrose_CA', label: 'Montrose, CA' },
  { value: 'Montross_VA', label: 'Montross, VA' },
  { value: 'Montvale_NJ', label: 'Montvale, NJ' },
  { value: 'Montvale_VA', label: 'Montvale, VA' },
  { value: 'Montverde_FL', label: 'Montverde, FL' },
  { value: 'Montville_CT', label: 'Montville, CT' },
  { value: 'Montville_NJ', label: 'Montville, NJ' },
  { value: 'Montville_OH', label: 'Montville, OH' },
  { value: 'Monument_KS', label: 'Monument, KS' },
  { value: 'Monument_CO', label: 'Monument, CO' },
  { value: 'Monument_NM', label: 'Monument, NM' },
  { value: 'Monument_OR', label: 'Monument, OR' },
  { value: 'Monument Beach_MA', label: 'Monument Beach, MA' },
  { value: 'Monument Valley New_UT', label: 'Monument Valley New, UT' },
  { value: 'Moodus_CT', label: 'Moodus, CT' },
  { value: 'Moody_AL', label: 'Moody, AL' },
  { value: 'Moody_MO', label: 'Moody, MO' },
  { value: 'Moody_TX', label: 'Moody, TX' },
  { value: 'Moody Afb_GA', label: 'Moody Afb, GA' },
  { value: 'Mooers_NY', label: 'Mooers, NY' },
  { value: 'Mooers Forks_NY', label: 'Mooers Forks, NY' },
  { value: 'Moon_VA', label: 'Moon, VA' },
  { value: 'Moonachie_NJ', label: 'Moonachie, NJ' },
  { value: 'Moorcroft_WY', label: 'Moorcroft, WY' },
  { value: 'Moore_SC', label: 'Moore, SC' },
  { value: 'Moore_MT', label: 'Moore, MT' },
  { value: 'Moore_TX', label: 'Moore, TX' },
  { value: 'Moore_ID', label: 'Moore, ID' },
  { value: 'Moore Haven_FL', label: 'Moore Haven, FL' },
  { value: 'Moorefield_WV', label: 'Moorefield, WV' },
  { value: 'Moorefield_KY', label: 'Moorefield, KY' },
  { value: 'Moorefield_NE', label: 'Moorefield, NE' },
  { value: 'Mooreland_IN', label: 'Mooreland, IN' },
  { value: 'Mooreland_OK', label: 'Mooreland, OK' },
  { value: 'Moores Hill_IN', label: 'Moores Hill, IN' },
  { value: 'Mooresboro_NC', label: 'Mooresboro, NC' },
  { value: 'Mooresburg_TN', label: 'Mooresburg, TN' },
  { value: 'Moorestown_NJ', label: 'Moorestown, NJ' },
  { value: 'Mooresville_NC', label: 'Mooresville, NC' },
  { value: 'Mooresville_AL', label: 'Mooresville, AL' },
  { value: 'Mooresville_IN', label: 'Mooresville, IN' },
  { value: 'Mooresville_MO', label: 'Mooresville, MO' },
  { value: 'Mooreton_ND', label: 'Mooreton, ND' },
  { value: 'Mooreville_MS', label: 'Mooreville, MS' },
  { value: 'Moorhead_MS', label: 'Moorhead, MS' },
  { value: 'Moorhead_IA', label: 'Moorhead, IA' },
  { value: 'Moorhead_MN', label: 'Moorhead, MN' },
  { value: 'Mooringsport_LA', label: 'Mooringsport, LA' },
  { value: 'Moorland_IA', label: 'Moorland, IA' },
  { value: 'Moorpark_CA', label: 'Moorpark, CA' },
  { value: 'Moose_WY', label: 'Moose, WY' },
  { value: 'Moose Lake_MN', label: 'Moose Lake, MN' },
  { value: 'Moose Pass_AK', label: 'Moose Pass, AK' },
  { value: 'Mooseheart_IL', label: 'Mooseheart, IL' },
  { value: 'Moosic_PA', label: 'Moosic, PA' },
  { value: 'Moosup_CT', label: 'Moosup, CT' },
  { value: 'Mora_MN', label: 'Mora, MN' },
  { value: 'Mora_MO', label: 'Mora, MO' },
  { value: 'Mora_LA', label: 'Mora, LA' },
  { value: 'Mora_NM', label: 'Mora, NM' },
  { value: 'Moraga_CA', label: 'Moraga, CA' },
  { value: 'Moran_MI', label: 'Moran, MI' },
  { value: 'Moran_KS', label: 'Moran, KS' },
  { value: 'Moran_TX', label: 'Moran, TX' },
  { value: 'Moran_WY', label: 'Moran, WY' },
  { value: 'Moravia_NY', label: 'Moravia, NY' },
  { value: 'Moravia_IA', label: 'Moravia, IA' },
  { value: 'Moravian Falls_NC', label: 'Moravian Falls, NC' },
  { value: 'Moreauville_LA', label: 'Moreauville, LA' },
  { value: 'Morehead_KY', label: 'Morehead, KY' },
  { value: 'Morehead City_NC', label: 'Morehead City, NC' },
  { value: 'Morehouse_MO', label: 'Morehouse, MO' },
  { value: 'Moreland_GA', label: 'Moreland, GA' },
  { value: 'Morenci_MI', label: 'Morenci, MI' },
  { value: 'Morenci_AZ', label: 'Morenci, AZ' },
  { value: 'Moreno Valley_CA', label: 'Moreno Valley, CA' },
  { value: 'Moretown_VT', label: 'Moretown, VT' },
  { value: 'Morgan_VT', label: 'Morgan, VT' },
  { value: 'Morgan_PA', label: 'Morgan, PA' },
  { value: 'Morgan_GA', label: 'Morgan, GA' },
  { value: 'Morgan_MN', label: 'Morgan, MN' },
  { value: 'Morgan_TX', label: 'Morgan, TX' },
  { value: 'Morgan_UT', label: 'Morgan, UT' },
  { value: 'Morgan City_MS', label: 'Morgan City, MS' },
  { value: 'Morgan City_LA', label: 'Morgan City, LA' },
  { value: 'Morgan Hill_CA', label: 'Morgan Hill, CA' },
  { value: 'Morganfield_KY', label: 'Morganfield, KY' },
  { value: 'Morganton_NC', label: 'Morganton, NC' },
  { value: 'Morganton_GA', label: 'Morganton, GA' },
  { value: 'Morgantown_PA', label: 'Morgantown, PA' },
  { value: 'Morgantown_WV', label: 'Morgantown, WV' },
  { value: 'Morgantown_KY', label: 'Morgantown, KY' },
  { value: 'Morgantown_IN', label: 'Morgantown, IN' },
  { value: 'Morganville_NJ', label: 'Morganville, NJ' },
  { value: 'Morganville_KS', label: 'Morganville, KS' },
  { value: 'Morganza_MD', label: 'Morganza, MD' },
  { value: 'Morganza_LA', label: 'Morganza, LA' },
  { value: 'Moriah_NY', label: 'Moriah, NY' },
  { value: 'Moriah Center_NY', label: 'Moriah Center, NY' },
  { value: 'Moriarty_NM', label: 'Moriarty, NM' },
  { value: 'Moriches_NY', label: 'Moriches, NY' },
  { value: 'Morland_KS', label: 'Morland, KS' },
  { value: 'Morley_MI', label: 'Morley, MI' },
  { value: 'Morley_IA', label: 'Morley, IA' },
  { value: 'Morley_MO', label: 'Morley, MO' },
  { value: 'Mormon Lake_AZ', label: 'Mormon Lake, AZ' },
  { value: 'Morning Sun_IA', label: 'Morning Sun, IA' },
  { value: 'Morning View_KY', label: 'Morning View, KY' },
  { value: 'Moro_IL', label: 'Moro, IL' },
  { value: 'Moro_AR', label: 'Moro, AR' },
  { value: 'Moro_OR', label: 'Moro, OR' },
  { value: 'Morocco_IN', label: 'Morocco, IN' },
  { value: 'Morongo Valley_CA', label: 'Morongo Valley, CA' },
  { value: 'Moroni_UT', label: 'Moroni, UT' },
  { value: 'Morovis_PR', label: 'Morovis, PR' },
  { value: 'Morral_OH', label: 'Morral, OH' },
  { value: 'Morrice_MI', label: 'Morrice, MI' },
  { value: 'Morrill_ME', label: 'Morrill, ME' },
  { value: 'Morrill_KS', label: 'Morrill, KS' },
  { value: 'Morrill_NE', label: 'Morrill, NE' },
  { value: 'Morrilton_AR', label: 'Morrilton, AR' },
  { value: 'Morris_CT', label: 'Morris, CT' },
  { value: 'Morris_NY', label: 'Morris, NY' },
  { value: 'Morris_PA', label: 'Morris, PA' },
  { value: 'Morris_AL', label: 'Morris, AL' },
  { value: 'Morris_GA', label: 'Morris, GA' },
  { value: 'Morris_IN', label: 'Morris, IN' },
  { value: 'Morris_MN', label: 'Morris, MN' },
  { value: 'Morris_IL', label: 'Morris, IL' },
  { value: 'Morris_OK', label: 'Morris, OK' },
  { value: 'Morris Chapel_TN', label: 'Morris Chapel, TN' },
  { value: 'Morris Plains_NJ', label: 'Morris Plains, NJ' },
  { value: 'Morris Run_PA', label: 'Morris Run, PA' },
  { value: 'Morrisdale_PA', label: 'Morrisdale, PA' },
  { value: 'Morrison_TN', label: 'Morrison, TN' },
  { value: 'Morrison_IA', label: 'Morrison, IA' },
  { value: 'Morrison_IL', label: 'Morrison, IL' },
  { value: 'Morrison_MO', label: 'Morrison, MO' },
  { value: 'Morrison_OK', label: 'Morrison, OK' },
  { value: 'Morrison_CO', label: 'Morrison, CO' },
  { value: 'Morrisonville_NY', label: 'Morrisonville, NY' },
  { value: 'Morrisonville_WI', label: 'Morrisonville, WI' },
  { value: 'Morrisonville_IL', label: 'Morrisonville, IL' },
  { value: 'Morriston_FL', label: 'Morriston, FL' },
  { value: 'Morristown_NJ', label: 'Morristown, NJ' },
  { value: 'Morristown_NY', label: 'Morristown, NY' },
  { value: 'Morristown_TN', label: 'Morristown, TN' },
  { value: 'Morristown_OH', label: 'Morristown, OH' },
  { value: 'Morristown_IN', label: 'Morristown, IN' },
  { value: 'Morristown_MN', label: 'Morristown, MN' },
  { value: 'Morristown_SD', label: 'Morristown, SD' },
  { value: 'Morristown_AZ', label: 'Morristown, AZ' },
  { value: 'Morrisville_VT', label: 'Morrisville, VT' },
  { value: 'Morrisville_NY', label: 'Morrisville, NY' },
  { value: 'Morrisville_PA', label: 'Morrisville, PA' },
  { value: 'Morrisville_NC', label: 'Morrisville, NC' },
  { value: 'Morrisville_MO', label: 'Morrisville, MO' },
  { value: 'Morro Bay_CA', label: 'Morro Bay, CA' },
  { value: 'Morrow_GA', label: 'Morrow, GA' },
  { value: 'Morrow_OH', label: 'Morrow, OH' },
  { value: 'Morrow_LA', label: 'Morrow, LA' },
  { value: 'Morrow_AR', label: 'Morrow, AR' },
  { value: 'Morrowville_KS', label: 'Morrowville, KS' },
  { value: 'Morse_LA', label: 'Morse, LA' },
  { value: 'Morse_TX', label: 'Morse, TX' },
  { value: 'Morse Bluff_NE', label: 'Morse Bluff, NE' },
  { value: 'Morton_PA', label: 'Morton, PA' },
  { value: 'Morton_MS', label: 'Morton, MS' },
  { value: 'Morton_MN', label: 'Morton, MN' },
  { value: 'Morton_IL', label: 'Morton, IL' },
  { value: 'Morton_TX', label: 'Morton, TX' },
  { value: 'Morton_WA', label: 'Morton, WA' },
  { value: 'Morton Grove_IL', label: 'Morton Grove, IL' },
  { value: 'Mortons Gap_KY', label: 'Mortons Gap, KY' },
  { value: 'Morven_NC', label: 'Morven, NC' },
  { value: 'Morven_GA', label: 'Morven, GA' },
  { value: 'Mosby_MT', label: 'Mosby, MT' },
  { value: 'Mosca_CO', label: 'Mosca, CO' },
  { value: 'Moscow_PA', label: 'Moscow, PA' },
  { value: 'Moscow_TN', label: 'Moscow, TN' },
  { value: 'Moscow_OH', label: 'Moscow, OH' },
  { value: 'Moscow_IA', label: 'Moscow, IA' },
  { value: 'Moscow_KS', label: 'Moscow, KS' },
  { value: 'Moscow_AR', label: 'Moscow, AR' },
  { value: 'Moscow_TX', label: 'Moscow, TX' },
  { value: 'Moscow_ID', label: 'Moscow, ID' },
  { value: 'Moscow Mills_MO', label: 'Moscow Mills, MO' },
  { value: 'Moseley_VA', label: 'Moseley, VA' },
  { value: 'Moselle_MS', label: 'Moselle, MS' },
  { value: 'Moses Lake_WA', label: 'Moses Lake, WA' },
  { value: 'Moshannon_PA', label: 'Moshannon, PA' },
  { value: 'Mosheim_TN', label: 'Mosheim, TN' },
  { value: 'Mosherville_MI', label: 'Mosherville, MI' },
  { value: 'Mosier_OR', label: 'Mosier, OR' },
  { value: 'Mosinee_WI', label: 'Mosinee, WI' },
  { value: 'Mosquero_NM', label: 'Mosquero, NM' },
  { value: 'Moss_TN', label: 'Moss, TN' },
  { value: 'Moss Beach_CA', label: 'Moss Beach, CA' },
  { value: 'Moss Landing_CA', label: 'Moss Landing, CA' },
  { value: 'Moss Point_MS', label: 'Moss Point, MS' },
  { value: 'Mossville_IL', label: 'Mossville, IL' },
  { value: 'Mossyrock_WA', label: 'Mossyrock, WA' },
  { value: 'Motley_MN', label: 'Motley, MN' },
  { value: 'Mott_ND', label: 'Mott, ND' },
  { value: 'Mottville_NY', label: 'Mottville, NY' },
  { value: 'Moulton_AL', label: 'Moulton, AL' },
  { value: 'Moulton_IA', label: 'Moulton, IA' },
  { value: 'Moulton_TX', label: 'Moulton, TX' },
  { value: 'Moultonborough_NH', label: 'Moultonborough, NH' },
  { value: 'Moultrie_GA', label: 'Moultrie, GA' },
  { value: 'Mound_MN', label: 'Mound, MN' },
  { value: 'Mound Bayou_MS', label: 'Mound Bayou, MS' },
  { value: 'Mound City_SD', label: 'Mound City, SD' },
  { value: 'Mound City_IL', label: 'Mound City, IL' },
  { value: 'Mound City_MO', label: 'Mound City, MO' },
  { value: 'Mound City_KS', label: 'Mound City, KS' },
  { value: 'Mound Valley_KS', label: 'Mound Valley, KS' },
  { value: 'Moundridge_KS', label: 'Moundridge, KS' },
  { value: 'Mounds_IL', label: 'Mounds, IL' },
  { value: 'Mounds_OK', label: 'Mounds, OK' },
  { value: 'Moundsville_WV', label: 'Moundsville, WV' },
  { value: 'Moundville_AL', label: 'Moundville, AL' },
  { value: 'Moundville_MO', label: 'Moundville, MO' },
  { value: 'Mount Aetna_PA', label: 'Mount Aetna, PA' },
  { value: 'Mount Airy_MD', label: 'Mount Airy, MD' },
  { value: 'Mount Airy_NC', label: 'Mount Airy, NC' },
  { value: 'Mount Airy_GA', label: 'Mount Airy, GA' },
  { value: 'Mount Airy_LA', label: 'Mount Airy, LA' },
  { value: 'Mount Alto_WV', label: 'Mount Alto, WV' },
  { value: 'Mount Angel_OR', label: 'Mount Angel, OR' },
  { value: 'Mount Arlington_NJ', label: 'Mount Arlington, NJ' },
  { value: 'Mount Auburn_IA', label: 'Mount Auburn, IA' },
  { value: 'Mount Auburn_IL', label: 'Mount Auburn, IL' },
  { value: 'Mount Ayr_IN', label: 'Mount Ayr, IN' },
  { value: 'Mount Ayr_IA', label: 'Mount Ayr, IA' },
  { value: 'Mount Berry_GA', label: 'Mount Berry, GA' },
  { value: 'Mount Bethel_PA', label: 'Mount Bethel, PA' },
  { value: 'Mount Blanchard_OH', label: 'Mount Blanchard, OH' },
  { value: 'Mount Braddock_PA', label: 'Mount Braddock, PA' },
  { value: 'Mount Calm_TX', label: 'Mount Calm, TX' },
  { value: 'Mount Calvary_WI', label: 'Mount Calvary, WI' },
  { value: 'Mount Carbon_WV', label: 'Mount Carbon, WV' },
  { value: 'Mount Carmel_PA', label: 'Mount Carmel, PA' },
  { value: 'Mount Carmel_SC', label: 'Mount Carmel, SC' },
  { value: 'Mount Carmel_TN', label: 'Mount Carmel, TN' },
  { value: 'Mount Carmel_IL', label: 'Mount Carmel, IL' },
  { value: 'Mount Carmel_UT', label: 'Mount Carmel, UT' },
  { value: 'Mount Carroll_IL', label: 'Mount Carroll, IL' },
  { value: 'Mount Clare_WV', label: 'Mount Clare, WV' },
  { value: 'Mount Clemens_MI', label: 'Mount Clemens, MI' },
  { value: 'Mount Cory_OH', label: 'Mount Cory, OH' },
  { value: 'Mount Crawford_VA', label: 'Mount Crawford, VA' },
  { value: 'Mount Croghan_SC', label: 'Mount Croghan, SC' },
  { value: 'Mount Desert_ME', label: 'Mount Desert, ME' },
  { value: 'Mount Dora_FL', label: 'Mount Dora, FL' },
  { value: 'Mount Eaton_OH', label: 'Mount Eaton, OH' },
  { value: 'Mount Eden_KY', label: 'Mount Eden, KY' },
  { value: 'Mount Enterprise_TX', label: 'Mount Enterprise, TX' },
  { value: 'Mount Ephraim_NJ', label: 'Mount Ephraim, NJ' },
  { value: 'Mount Erie_IL', label: 'Mount Erie, IL' },
  { value: 'Mount Freedom_NJ', label: 'Mount Freedom, NJ' },
  { value: 'Mount Gay_WV', label: 'Mount Gay, WV' },
  { value: 'Mount Gilead_NC', label: 'Mount Gilead, NC' },
  { value: 'Mount Gilead_OH', label: 'Mount Gilead, OH' },
  { value: 'Mount Gretna_PA', label: 'Mount Gretna, PA' },
  { value: 'Mount Hamilton_CA', label: 'Mount Hamilton, CA' },
  { value: 'Mount Hermon_KY', label: 'Mount Hermon, KY' },
  { value: 'Mount Hermon_LA', label: 'Mount Hermon, LA' },
  { value: 'Mount Hermon_CA', label: 'Mount Hermon, CA' },
  { value: 'Mount Holly_VT', label: 'Mount Holly, VT' },
  { value: 'Mount Holly_NJ', label: 'Mount Holly, NJ' },
  { value: 'Mount Holly_NC', label: 'Mount Holly, NC' },
  { value: 'Mount Holly_AR', label: 'Mount Holly, AR' },
  { value: 'Mount Holly Springs_PA', label: 'Mount Holly Springs, PA' },
  { value: 'Mount Hood Parkdale_OR', label: 'Mount Hood Parkdale, OR' },
  { value: 'Mount Hope_WV', label: 'Mount Hope, WV' },
  { value: 'Mount Hope_AL', label: 'Mount Hope, AL' },
  { value: 'Mount Hope_OH', label: 'Mount Hope, OH' },
  { value: 'Mount Hope_WI', label: 'Mount Hope, WI' },
  { value: 'Mount Hope_KS', label: 'Mount Hope, KS' },
  { value: 'Mount Horeb_WI', label: 'Mount Horeb, WI' },
  { value: 'Mount Ida_AR', label: 'Mount Ida, AR' },
  { value: 'Mount Jackson_VA', label: 'Mount Jackson, VA' },
  { value: 'Mount Jewett_PA', label: 'Mount Jewett, PA' },
  { value: 'Mount Joy_PA', label: 'Mount Joy, PA' },
  { value: 'Mount Judea_AR', label: 'Mount Judea, AR' },
  { value: 'Mount Juliet_TN', label: 'Mount Juliet, TN' },
  { value: 'Mount Kisco_NY', label: 'Mount Kisco, NY' },
  { value: 'Mount Laguna_CA', label: 'Mount Laguna, CA' },
  { value: 'Mount Laurel_NJ', label: 'Mount Laurel, NJ' },
  { value: 'Mount Lemmon_AZ', label: 'Mount Lemmon, AZ' },
  { value: 'Mount Lookout_WV', label: 'Mount Lookout, WV' },
  { value: 'Mount Marion_NY', label: 'Mount Marion, NY' },
  { value: 'Mount Meigs_AL', label: 'Mount Meigs, AL' },
  { value: 'Mount Morris_NY', label: 'Mount Morris, NY' },
  { value: 'Mount Morris_PA', label: 'Mount Morris, PA' },
  { value: 'Mount Morris_MI', label: 'Mount Morris, MI' },
  { value: 'Mount Morris_IL', label: 'Mount Morris, IL' },
  { value: 'Mount Nebo_WV', label: 'Mount Nebo, WV' },
  { value: 'Mount Olive_WV', label: 'Mount Olive, WV' },
  { value: 'Mount Olive_NC', label: 'Mount Olive, NC' },
  { value: 'Mount Olive_AL', label: 'Mount Olive, AL' },
  { value: 'Mount Olive_MS', label: 'Mount Olive, MS' },
  { value: 'Mount Olive_IL', label: 'Mount Olive, IL' },
  { value: 'Mount Olivet_KY', label: 'Mount Olivet, KY' },
  { value: 'Mount Orab_OH', label: 'Mount Orab, OH' },
  { value: 'Mount Perry_OH', label: 'Mount Perry, OH' },
  { value: 'Mount Pleasant_PA', label: 'Mount Pleasant, PA' },
  { value: 'Mount Pleasant_NC', label: 'Mount Pleasant, NC' },
  { value: 'Mount Pleasant_SC', label: 'Mount Pleasant, SC' },
  { value: 'Mount Pleasant_TN', label: 'Mount Pleasant, TN' },
  { value: 'Mount Pleasant_OH', label: 'Mount Pleasant, OH' },
  { value: 'Mount Pleasant_MI', label: 'Mount Pleasant, MI' },
  { value: 'Mount Pleasant_IA', label: 'Mount Pleasant, IA' },
  { value: 'Mount Pleasant_AR', label: 'Mount Pleasant, AR' },
  { value: 'Mount Pleasant_TX', label: 'Mount Pleasant, TX' },
  { value: 'Mount Pleasant_UT', label: 'Mount Pleasant, UT' },
  { value: 'Mount Pleasant Mills_PA', label: 'Mount Pleasant Mills, PA' },
  { value: 'Mount Pocono_PA', label: 'Mount Pocono, PA' },
  { value: 'Mount Prospect_IL', label: 'Mount Prospect, IL' },
  { value: 'Mount Pulaski_IL', label: 'Mount Pulaski, IL' },
  { value: 'Mount Rainier_MD', label: 'Mount Rainier, MD' },
  { value: 'Mount Royal_NJ', label: 'Mount Royal, NJ' },
  { value: 'Mount St Joseph_OH', label: 'Mount St Joseph, OH' },
  { value: 'Mount Savage_MD', label: 'Mount Savage, MD' },
  { value: 'Mount Shasta_CA', label: 'Mount Shasta, CA' },
  { value: 'Mount Sherman_KY', label: 'Mount Sherman, KY' },
  { value: 'Mount Sidney_VA', label: 'Mount Sidney, VA' },
  { value: 'Mount Sinai_NY', label: 'Mount Sinai, NY' },
  { value: 'Mount Solon_VA', label: 'Mount Solon, VA' },
  { value: 'Mount Sterling_KY', label: 'Mount Sterling, KY' },
  { value: 'Mount Sterling_OH', label: 'Mount Sterling, OH' },
  { value: 'Mount Sterling_IA', label: 'Mount Sterling, IA' },
  { value: 'Mount Sterling_WI', label: 'Mount Sterling, WI' },
  { value: 'Mount Sterling_IL', label: 'Mount Sterling, IL' },
  { value: 'Mount Sterling_MO', label: 'Mount Sterling, MO' },
  { value: 'Mount Storm_WV', label: 'Mount Storm, WV' },
  { value: 'Mount Summit_IN', label: 'Mount Summit, IN' },
  { value: 'Mount Tabor_NJ', label: 'Mount Tabor, NJ' },
  { value: 'Mount Tremper_NY', label: 'Mount Tremper, NY' },
  { value: 'Mount Ulla_NC', label: 'Mount Ulla, NC' },
  { value: 'Mount Union_PA', label: 'Mount Union, PA' },
  { value: 'Mount Union_IA', label: 'Mount Union, IA' },
  { value: 'Mount Upton_NY', label: 'Mount Upton, NY' },
  { value: 'Mount Vernon_ME', label: 'Mount Vernon, ME' },
  { value: 'Mount Vernon_NY', label: 'Mount Vernon, NY' },
  { value: 'Mount Vernon_GA', label: 'Mount Vernon, GA' },
  { value: 'Mount Vernon_AL', label: 'Mount Vernon, AL' },
  { value: 'Mount Vernon_KY', label: 'Mount Vernon, KY' },
  { value: 'Mount Vernon_OH', label: 'Mount Vernon, OH' },
  { value: 'Mount Vernon_IN', label: 'Mount Vernon, IN' },
  { value: 'Mount Vernon_IA', label: 'Mount Vernon, IA' },
  { value: 'Mount Vernon_SD', label: 'Mount Vernon, SD' },
  { value: 'Mount Vernon_IL', label: 'Mount Vernon, IL' },
  { value: 'Mount Vernon_MO', label: 'Mount Vernon, MO' },
  { value: 'Mount Vernon_AR', label: 'Mount Vernon, AR' },
  { value: 'Mount Vernon_TX', label: 'Mount Vernon, TX' },
  { value: 'Mount Vernon_OR', label: 'Mount Vernon, OR' },
  { value: 'Mount Vernon_WA', label: 'Mount Vernon, WA' },
  { value: 'Mount Victoria_MD', label: 'Mount Victoria, MD' },
  { value: 'Mount Victory_OH', label: 'Mount Victory, OH' },
  { value: 'Mount Vision_NY', label: 'Mount Vision, NY' },
  { value: 'Mount Washington_KY', label: 'Mount Washington, KY' },
  { value: 'Mount Wolf_PA', label: 'Mount Wolf, PA' },
  { value: 'Mount Zion_WV', label: 'Mount Zion, WV' },
  { value: 'Mountain_WI', label: 'Mountain, WI' },
  { value: 'Mountain_ND', label: 'Mountain, ND' },
  { value: 'Mountain Center_CA', label: 'Mountain Center, CA' },
  { value: 'Mountain City_GA', label: 'Mountain City, GA' },
  { value: 'Mountain City_TN', label: 'Mountain City, TN' },
  { value: 'Mountain City_NV', label: 'Mountain City, NV' },
  { value: 'Mountain Dale_NY', label: 'Mountain Dale, NY' },
  { value: 'Mountain Grove_MO', label: 'Mountain Grove, MO' },
  { value: 'Mountain Home_NC', label: 'Mountain Home, NC' },
  { value: 'Mountain Home_AR', label: 'Mountain Home, AR' },
  { value: 'Mountain Home_TX', label: 'Mountain Home, TX' },
  { value: 'Mountain Home_ID', label: 'Mountain Home, ID' },
  { value: 'Mountain Home_UT', label: 'Mountain Home, UT' },
  { value: 'Mountain Home Afb_ID', label: 'Mountain Home Afb, ID' },
  { value: 'Mountain Iron_MN', label: 'Mountain Iron, MN' },
  { value: 'Mountain Lake_MN', label: 'Mountain Lake, MN' },
  { value: 'Mountain Lakes_NJ', label: 'Mountain Lakes, NJ' },
  { value: 'Mountain Park_OK', label: 'Mountain Park, OK' },
  { value: 'Mountain Pine_AR', label: 'Mountain Pine, AR' },
  { value: 'Mountain Ranch_CA', label: 'Mountain Ranch, CA' },
  { value: 'Mountain Rest_SC', label: 'Mountain Rest, SC' },
  { value: 'Mountain Top_PA', label: 'Mountain Top, PA' },
  { value: 'Mountain View_MO', label: 'Mountain View, MO' },
  { value: 'Mountain View_AR', label: 'Mountain View, AR' },
  { value: 'Mountain View_OK', label: 'Mountain View, OK' },
  { value: 'Mountain View_WY', label: 'Mountain View, WY' },
  { value: 'Mountain View_CA', label: 'Mountain View, CA' },
  { value: 'Mountain View_HI', label: 'Mountain View, HI' },
  { value: 'Mountain Village_AK', label: 'Mountain Village, AK' },
  { value: 'Mountainair_NM', label: 'Mountainair, NM' },
  { value: 'Mountainburg_AR', label: 'Mountainburg, AR' },
  { value: 'Mountainhome_PA', label: 'Mountainhome, PA' },
  { value: 'Mountainside_NJ', label: 'Mountainside, NJ' },
  { value: 'Mountainville_NY', label: 'Mountainville, NY' },
  { value: 'Mountlake Terrace_WA', label: 'Mountlake Terrace, WA' },
  { value: 'Mountville_PA', label: 'Mountville, PA' },
  { value: 'Mountville_SC', label: 'Mountville, SC' },
  { value: 'Mousie_KY', label: 'Mousie, KY' },
  { value: 'Mouth Of Wilson_VA', label: 'Mouth Of Wilson, VA' },
  { value: 'Mouthcard_KY', label: 'Mouthcard, KY' },
  { value: 'Moville_IA', label: 'Moville, IA' },
  { value: 'Moweaqua_IL', label: 'Moweaqua, IL' },
  { value: 'Mowrystown_OH', label: 'Mowrystown, OH' },
  { value: 'Moxahala_OH', label: 'Moxahala, OH' },
  { value: 'Moxee_WA', label: 'Moxee, WA' },
  { value: 'Moyers_OK', label: 'Moyers, OK' },
  { value: 'Moyie Springs_ID', label: 'Moyie Springs, ID' },
  { value: 'Moyock_NC', label: 'Moyock, NC' },
  { value: 'Mozelle_KY', label: 'Mozelle, KY' },
  { value: 'Mt Baldy_CA', label: 'Mt Baldy, CA' },
  { value: 'Mt Zion_IL', label: 'Mt Zion, IL' },
  { value: 'Mud Butte_SD', label: 'Mud Butte, SD' },
  { value: 'Muddy_IL', label: 'Muddy, IL' },
  { value: 'Muenster_TX', label: 'Muenster, TX' },
  { value: 'Muir_PA', label: 'Muir, PA' },
  { value: 'Muir_MI', label: 'Muir, MI' },
  { value: 'Mukilteo_WA', label: 'Mukilteo, WA' },
  { value: 'Mukwonago_WI', label: 'Mukwonago, WI' },
  { value: 'Mulberry_FL', label: 'Mulberry, FL' },
  { value: 'Mulberry_TN', label: 'Mulberry, TN' },
  { value: 'Mulberry_IN', label: 'Mulberry, IN' },
  { value: 'Mulberry_KS', label: 'Mulberry, KS' },
  { value: 'Mulberry_AR', label: 'Mulberry, AR' },
  { value: 'Mulberry Grove_IL', label: 'Mulberry Grove, IL' },
  { value: 'Muldoon_TX', label: 'Muldoon, TX' },
  { value: 'Muldraugh_KY', label: 'Muldraugh, KY' },
  { value: 'Muldrow_OK', label: 'Muldrow, OK' },
  { value: 'Mule Creek_NM', label: 'Mule Creek, NM' },
  { value: 'Muleshoe_TX', label: 'Muleshoe, TX' },
  { value: 'Mulga_AL', label: 'Mulga, AL' },
  { value: 'Mulhall_OK', label: 'Mulhall, OK' },
  { value: 'Mulino_OR', label: 'Mulino, OR' },
  { value: 'Mulkeytown_IL', label: 'Mulkeytown, IL' },
  { value: 'Mullan_ID', label: 'Mullan, ID' },
  { value: 'Mullen_NE', label: 'Mullen, NE' },
  { value: 'Mullens_WV', label: 'Mullens, WV' },
  { value: 'Mullica Hill_NJ', label: 'Mullica Hill, NJ' },
  { value: 'Mulliken_MI', label: 'Mulliken, MI' },
  { value: 'Mullin_TX', label: 'Mullin, TX' },
  { value: 'Mullins_SC', label: 'Mullins, SC' },
  { value: 'Mullinville_KS', label: 'Mullinville, KS' },
  { value: 'Mulvane_KS', label: 'Mulvane, KS' },
  { value: 'Mumford_NY', label: 'Mumford, NY' },
  { value: 'Mumford_TX', label: 'Mumford, TX' },
  { value: 'Muncie_IN', label: 'Muncie, IN' },
  { value: 'Muncie_IL', label: 'Muncie, IL' },
  { value: 'Muncy_PA', label: 'Muncy, PA' },
  { value: 'Muncy Valley_PA', label: 'Muncy Valley, PA' },
  { value: 'Munday_WV', label: 'Munday, WV' },
  { value: 'Munday_TX', label: 'Munday, TX' },
  { value: 'Mundelein_IL', label: 'Mundelein, IL' },
  { value: 'Munden_KS', label: 'Munden, KS' },
  { value: 'Munds Park_AZ', label: 'Munds Park, AZ' },
  { value: 'Munford_AL', label: 'Munford, AL' },
  { value: 'Munford_TN', label: 'Munford, TN' },
  { value: 'Munfordville_KY', label: 'Munfordville, KY' },
  { value: 'Munger_MI', label: 'Munger, MI' },
  { value: 'Munich_ND', label: 'Munich, ND' },
  { value: 'Munising_MI', label: 'Munising, MI' },
  { value: 'Munith_MI', label: 'Munith, MI' },
  { value: 'Munnsville_NY', label: 'Munnsville, NY' },
  { value: 'Munroe Falls_OH', label: 'Munroe Falls, OH' },
  { value: 'Munson_PA', label: 'Munson, PA' },
  { value: 'Munster_IN', label: 'Munster, IN' },
  { value: 'Murchison_TX', label: 'Murchison, TX' },
  { value: 'Murdo_SD', label: 'Murdo, SD' },
  { value: 'Murdock_MN', label: 'Murdock, MN' },
  { value: 'Murdock_IL', label: 'Murdock, IL' },
  { value: 'Murdock_KS', label: 'Murdock, KS' },
  { value: 'Murdock_NE', label: 'Murdock, NE' },
  { value: 'Murfreesboro_NC', label: 'Murfreesboro, NC' },
  { value: 'Murfreesboro_TN', label: 'Murfreesboro, TN' },
  { value: 'Murfreesboro_AR', label: 'Murfreesboro, AR' },
  { value: 'Murphy_NC', label: 'Murphy, NC' },
  { value: 'Murphy_ID', label: 'Murphy, ID' },
  { value: 'Murphys_CA', label: 'Murphys, CA' },
  { value: 'Murphysboro_IL', label: 'Murphysboro, IL' },
  { value: 'Murray_KY', label: 'Murray, KY' },
  { value: 'Murray_IA', label: 'Murray, IA' },
  { value: 'Murray_NE', label: 'Murray, NE' },
  { value: 'Murray_ID', label: 'Murray, ID' },
  { value: 'Murray City_OH', label: 'Murray City, OH' },
  { value: 'Murrayville_GA', label: 'Murrayville, GA' },
  { value: 'Murrayville_IL', label: 'Murrayville, IL' },
  { value: 'Murrells Inlet_SC', label: 'Murrells Inlet, SC' },
  { value: 'Murrieta_CA', label: 'Murrieta, CA' },
  { value: 'Murrysville_PA', label: 'Murrysville, PA' },
  { value: 'Murtaugh_ID', label: 'Murtaugh, ID' },
  { value: 'Muscadine_AL', label: 'Muscadine, AL' },
  { value: 'Muscatine_IA', label: 'Muscatine, IA' },
  { value: 'Muscle Shoals_AL', label: 'Muscle Shoals, AL' },
  { value: 'Muscoda_WI', label: 'Muscoda, WI' },
  { value: 'Muscotah_KS', label: 'Muscotah, KS' },
  { value: 'Muse_PA', label: 'Muse, PA' },
  { value: 'Muse_OK', label: 'Muse, OK' },
  { value: 'Musella_GA', label: 'Musella, GA' },
  { value: 'Muskego_WI', label: 'Muskego, WI' },
  { value: 'Muskegon_MI', label: 'Muskegon, MI' },
  { value: 'Muskogee_OK', label: 'Muskogee, OK' },
  { value: 'Musselshell_MT', label: 'Musselshell, MT' },
  { value: 'Mustang_OK', label: 'Mustang, OK' },
  { value: 'Mutual_OK', label: 'Mutual, OK' },
  { value: 'Myakka City_FL', label: 'Myakka City, FL' },
  { value: 'Myers Flat_CA', label: 'Myers Flat, CA' },
  { value: 'Myerstown_PA', label: 'Myerstown, PA' },
  { value: 'Myersville_MD', label: 'Myersville, MD' },
  { value: 'Mylo_ND', label: 'Mylo, ND' },
  { value: 'Myra_WV', label: 'Myra, WV' },
  { value: 'Myra_TX', label: 'Myra, TX' },
  { value: 'Myrtle_MS', label: 'Myrtle, MS' },
  { value: 'Myrtle_MO', label: 'Myrtle, MO' },
  { value: 'Myrtle Beach_SC', label: 'Myrtle Beach, SC' },
  { value: 'Myrtle Creek_OR', label: 'Myrtle Creek, OR' },
  { value: 'Myrtle Point_OR', label: 'Myrtle Point, OR' },
  { value: 'Myrtlewood_AL', label: 'Myrtlewood, AL' },
  { value: 'Mystic_CT', label: 'Mystic, CT' },
  { value: 'Mystic_IA', label: 'Mystic, IA' },
  { value: 'Myton_UT', label: 'Myton, UT' },
  { value: 'Naalehu_HI', label: 'Naalehu, HI' },
  { value: 'Nabb_IN', label: 'Nabb, IN' },
  { value: 'Naches_WA', label: 'Naches, WA' },
  { value: 'Nachusa_IL', label: 'Nachusa, IL' },
  { value: 'Naco_AZ', label: 'Naco, AZ' },
  { value: 'Nacogdoches_TX', label: 'Nacogdoches, TX' },
  { value: 'Nada_TX', label: 'Nada, TX' },
  { value: 'Nadeau_MI', label: 'Nadeau, MI' },
  { value: 'Nageezi_NM', label: 'Nageezi, NM' },
  { value: 'Nags Head_NC', label: 'Nags Head, NC' },
  { value: 'Naguabo_PR', label: 'Naguabo, PR' },
  { value: 'Nahant_MA', label: 'Nahant, MA' },
  { value: 'Nahma_MI', label: 'Nahma, MI' },
  { value: 'Nahunta_GA', label: 'Nahunta, GA' },
  { value: 'Nakina_NC', label: 'Nakina, NC' },
  { value: 'Naknek_AK', label: 'Naknek, AK' },
  { value: 'Nalcrest_FL', label: 'Nalcrest, FL' },
  { value: 'Nallen_WV', label: 'Nallen, WV' },
  { value: 'Nampa_ID', label: 'Nampa, ID' },
  { value: 'Nanafalia_AL', label: 'Nanafalia, AL' },
  { value: 'Nancy_KY', label: 'Nancy, KY' },
  { value: 'Nanjemoy_MD', label: 'Nanjemoy, MD' },
  { value: 'Nanticoke_PA', label: 'Nanticoke, PA' },
  { value: 'Nanticoke_MD', label: 'Nanticoke, MD' },
  { value: 'Nantucket_MA', label: 'Nantucket, MA' },
  { value: 'Nanty Glo_PA', label: 'Nanty Glo, PA' },
  { value: 'Nanuet_NY', label: 'Nanuet, NY' },
  { value: 'Naoma_WV', label: 'Naoma, WV' },
  { value: 'Napa_CA', label: 'Napa, CA' },
  { value: 'Napakiak_AK', label: 'Napakiak, AK' },
  { value: 'Napanoch_NY', label: 'Napanoch, NY' },
  { value: 'Napavine_WA', label: 'Napavine, WA' },
  { value: 'Naper_NE', label: 'Naper, NE' },
  { value: 'Naperville_IL', label: 'Naperville, IL' },
  { value: 'Napier_WV', label: 'Napier, WV' },
  { value: 'Naples_ME', label: 'Naples, ME' },
  { value: 'Naples_NY', label: 'Naples, NY' },
  { value: 'Naples_FL', label: 'Naples, FL' },
  { value: 'Naples_TX', label: 'Naples, TX' },
  { value: 'Naples_ID', label: 'Naples, ID' },
  { value: 'Napoleon_OH', label: 'Napoleon, OH' },
  { value: 'Napoleon_IN', label: 'Napoleon, IN' },
  { value: 'Napoleon_MI', label: 'Napoleon, MI' },
  { value: 'Napoleon_ND', label: 'Napoleon, ND' },
  { value: 'Napoleon_MO', label: 'Napoleon, MO' },
  { value: 'Napoleonville_LA', label: 'Napoleonville, LA' },
  { value: 'Naponee_NE', label: 'Naponee, NE' },
  { value: 'Nappanee_IN', label: 'Nappanee, IN' },
  { value: 'Nara Visa_NM', label: 'Nara Visa, NM' },
  { value: 'Naranjito_PR', label: 'Naranjito, PR' },
  { value: 'Narberth_PA', label: 'Narberth, PA' },
  { value: 'Nardin_OK', label: 'Nardin, OK' },
  { value: 'Narka_KS', label: 'Narka, KS' },
  { value: 'Narragansett_RI', label: 'Narragansett, RI' },
  { value: 'Narrows_VA', label: 'Narrows, VA' },
  { value: 'Narrowsburg_NY', label: 'Narrowsburg, NY' },
  { value: 'Narvon_PA', label: 'Narvon, PA' },
  { value: 'Naselle_WA', label: 'Naselle, WA' },
  { value: 'Nash_OK', label: 'Nash, OK' },
  { value: 'Nash_TX', label: 'Nash, TX' },
  { value: 'Nashoba_OK', label: 'Nashoba, OK' },
  { value: 'Nashotah_WI', label: 'Nashotah, WI' },
  { value: 'Nashport_OH', label: 'Nashport, OH' },
  { value: 'Nashua_NH', label: 'Nashua, NH' },
  { value: 'Nashua_IA', label: 'Nashua, IA' },
  { value: 'Nashua_MN', label: 'Nashua, MN' },
  { value: 'Nashua_MT', label: 'Nashua, MT' },
  { value: 'Nashville_NC', label: 'Nashville, NC' },
  { value: 'Nashville_GA', label: 'Nashville, GA' },
  { value: 'Nashville_TN', label: 'Nashville, TN' },
  { value: 'Nashville_OH', label: 'Nashville, OH' },
  { value: 'Nashville_IN', label: 'Nashville, IN' },
  { value: 'Nashville_MI', label: 'Nashville, MI' },
  { value: 'Nashville_IL', label: 'Nashville, IL' },
  { value: 'Nashville_KS', label: 'Nashville, KS' },
  { value: 'Nashville_AR', label: 'Nashville, AR' },
  { value: 'Nashwauk_MN', label: 'Nashwauk, MN' },
  { value: 'Nassau_NY', label: 'Nassau, NY' },
  { value: 'Nassawadox_VA', label: 'Nassawadox, VA' },
  { value: 'Natalbany_LA', label: 'Natalbany, LA' },
  { value: 'Natalia_TX', label: 'Natalia, TX' },
  { value: 'Natchez_MS', label: 'Natchez, MS' },
  { value: 'Natchez_LA', label: 'Natchez, LA' },
  { value: 'Natchitoches_LA', label: 'Natchitoches, LA' },
  { value: 'Nathalie_VA', label: 'Nathalie, VA' },
  { value: 'Nathrop_CO', label: 'Nathrop, CO' },
  { value: 'Natick_MA', label: 'Natick, MA' },
  { value: 'National City_MI', label: 'National City, MI' },
  { value: 'National City_CA', label: 'National City, CA' },
  { value: 'National Park_NJ', label: 'National Park, NJ' },
  { value: 'Natoma_KS', label: 'Natoma, KS' },
  { value: 'Natrona_WY', label: 'Natrona, WY' },
  { value: 'Natrona Heights_PA', label: 'Natrona Heights, PA' },
  { value: 'Natural Bridge_NY', label: 'Natural Bridge, NY' },
  { value: 'Natural Bridge_VA', label: 'Natural Bridge, VA' },
  { value: 'Natural Bridge_AL', label: 'Natural Bridge, AL' },
  { value: 'Natural Bridge Station_VA', label: 'Natural Bridge Station, VA' },
  { value: 'Natural Dam_AR', label: 'Natural Dam, AR' },
  { value: 'Naturita_CO', label: 'Naturita, CO' },
  { value: 'Naubinway_MI', label: 'Naubinway, MI' },
  { value: 'Naugatuck_CT', label: 'Naugatuck, CT' },
  { value: 'Naugatuck_WV', label: 'Naugatuck, WV' },
  { value: 'Nauvoo_AL', label: 'Nauvoo, AL' },
  { value: 'Nauvoo_IL', label: 'Nauvoo, IL' },
  { value: 'Navajo_NM', label: 'Navajo, NM' },
  { value: 'Navajo Dam_NM', label: 'Navajo Dam, NM' },
  { value: 'Naval Air Station Jrb_TX', label: 'Naval Air Station Jrb, TX' },
  { value: 'Naval Anacost Annex_DC', label: 'Naval Anacost Annex, DC' },
  { value: 'Navarre_FL', label: 'Navarre, FL' },
  { value: 'Navarre_OH', label: 'Navarre, OH' },
  { value: 'Navarro_CA', label: 'Navarro, CA' },
  { value: 'Navasota_TX', label: 'Navasota, TX' },
  { value: 'Naylor_GA', label: 'Naylor, GA' },
  { value: 'Naylor_MO', label: 'Naylor, MO' },
  { value: 'Naytahwaush_MN', label: 'Naytahwaush, MN' },
  { value: 'Nazareth_PA', label: 'Nazareth, PA' },
  { value: 'Nazareth_MI', label: 'Nazareth, MI' },
  { value: 'Nazareth_TX', label: 'Nazareth, TX' },
  { value: 'Nazlini_AZ', label: 'Nazlini, AZ' },
  { value: 'Neah Bay_WA', label: 'Neah Bay, WA' },
  { value: 'Neal_KS', label: 'Neal, KS' },
  { value: 'Neapolis_OH', label: 'Neapolis, OH' },
  { value: 'Neavitt_MD', label: 'Neavitt, MD' },
  { value: 'Nebo_WV', label: 'Nebo, WV' },
  { value: 'Nebo_NC', label: 'Nebo, NC' },
  { value: 'Nebo_KY', label: 'Nebo, KY' },
  { value: 'Nebo_IL', label: 'Nebo, IL' },
  { value: 'Nebraska City_NE', label: 'Nebraska City, NE' },
  { value: 'Necedah_WI', label: 'Necedah, WI' },
  { value: 'Neche_ND', label: 'Neche, ND' },
  { value: 'Neches_TX', label: 'Neches, TX' },
  { value: 'Neck City_MO', label: 'Neck City, MO' },
  { value: 'Nederland_TX', label: 'Nederland, TX' },
  { value: 'Nederland_CO', label: 'Nederland, CO' },
  { value: 'Nedrow_NY', label: 'Nedrow, NY' },
  { value: 'Needham_MA', label: 'Needham, MA' },
  { value: 'Needham_AL', label: 'Needham, AL' },
  { value: 'Needham_IN', label: 'Needham, IN' },
  { value: 'Needham Heights_MA', label: 'Needham Heights, MA' },
  { value: 'Needles_CA', label: 'Needles, CA' },
  { value: 'Needmore_PA', label: 'Needmore, PA' },
  { value: 'Needville_TX', label: 'Needville, TX' },
  { value: 'Neely_MS', label: 'Neely, MS' },
  { value: 'Neelyton_PA', label: 'Neelyton, PA' },
  { value: 'Neelyville_MO', label: 'Neelyville, MO' },
  { value: 'Neenah_WI', label: 'Neenah, WI' },
  { value: 'Neeses_SC', label: 'Neeses, SC' },
  { value: 'Neffs_PA', label: 'Neffs, PA' },
  { value: 'Neffs_OH', label: 'Neffs, OH' },
  { value: 'Negaunee_MI', label: 'Negaunee, MI' },
  { value: 'Negley_OH', label: 'Negley, OH' },
  { value: 'Nehalem_OR', label: 'Nehalem, OR' },
  { value: 'Nehawka_NE', label: 'Nehawka, NE' },
  { value: 'Neihart_MT', label: 'Neihart, MT' },
  { value: 'Neillsville_WI', label: 'Neillsville, WI' },
  { value: 'Neilton_WA', label: 'Neilton, WA' },
  { value: 'Nekoma_ND', label: 'Nekoma, ND' },
  { value: 'Nekoma_KS', label: 'Nekoma, KS' },
  { value: 'Nekoosa_WI', label: 'Nekoosa, WI' },
  { value: 'Neligh_NE', label: 'Neligh, NE' },
  { value: 'Nellis_WV', label: 'Nellis, WV' },
  { value: 'Nellis Afb_NV', label: 'Nellis Afb, NV' },
  { value: 'Nelliston_NY', label: 'Nelliston, NY' },
  { value: 'Nellysford_VA', label: 'Nellysford, VA' },
  { value: 'Nelson_NH', label: 'Nelson, NH' },
  { value: 'Nelson_PA', label: 'Nelson, PA' },
  { value: 'Nelson_VA', label: 'Nelson, VA' },
  { value: 'Nelson_WI', label: 'Nelson, WI' },
  { value: 'Nelson_MN', label: 'Nelson, MN' },
  { value: 'Nelson_MO', label: 'Nelson, MO' },
  { value: 'Nelson_NE', label: 'Nelson, NE' },
  { value: 'Nelson_CA', label: 'Nelson, CA' },
  { value: 'Nelsonia_VA', label: 'Nelsonia, VA' },
  { value: 'Nelsonville_OH', label: 'Nelsonville, OH' },
  { value: 'Nelsonville_WI', label: 'Nelsonville, WI' },
  { value: 'Nemacolin_PA', label: 'Nemacolin, PA' },
  { value: 'Nemaha_IA', label: 'Nemaha, IA' },
  { value: 'Nemaha_NE', label: 'Nemaha, NE' },
  { value: 'Nemo_SD', label: 'Nemo, SD' },
  { value: 'Nemo_TX', label: 'Nemo, TX' },
  { value: 'Nemours_WV', label: 'Nemours, WV' },
  { value: 'Nenana_AK', label: 'Nenana, AK' },
  { value: 'Nenzel_NE', label: 'Nenzel, NE' },
  { value: 'Neodesha_KS', label: 'Neodesha, KS' },
  { value: 'Neoga_IL', label: 'Neoga, IL' },
  { value: 'Neola_IA', label: 'Neola, IA' },
  { value: 'Neola_UT', label: 'Neola, UT' },
  { value: 'Neon_KY', label: 'Neon, KY' },
  { value: 'Neopit_WI', label: 'Neopit, WI' },
  { value: 'Neosho_WI', label: 'Neosho, WI' },
  { value: 'Neosho_MO', label: 'Neosho, MO' },
  { value: 'Neosho Falls_KS', label: 'Neosho Falls, KS' },
  { value: 'Neosho Rapids_KS', label: 'Neosho Rapids, KS' },
  { value: 'Neotsu_OR', label: 'Neotsu, OR' },
  { value: 'Nephi_UT', label: 'Nephi, UT' },
  { value: 'Neponset_IL', label: 'Neponset, IL' },
  { value: 'Neptune_NJ', label: 'Neptune, NJ' },
  { value: 'Neptune Beach_FL', label: 'Neptune Beach, FL' },
  { value: 'Nerinx_KY', label: 'Nerinx, KY' },
  { value: 'Nerstrand_MN', label: 'Nerstrand, MN' },
  { value: 'Nesbit_MS', label: 'Nesbit, MS' },
  { value: 'Nesconset_NY', label: 'Nesconset, NY' },
  { value: 'Nescopeck_PA', label: 'Nescopeck, PA' },
  { value: 'Neshanic Station_NJ', label: 'Neshanic Station, NJ' },
  { value: 'Neshkoro_WI', label: 'Neshkoro, WI' },
  { value: 'Neskowin_OR', label: 'Neskowin, OR' },
  { value: 'Nesmith_SC', label: 'Nesmith, SC' },
  { value: 'Nespelem_WA', label: 'Nespelem, WA' },
  { value: 'Nesquehoning_PA', label: 'Nesquehoning, PA' },
  { value: 'Ness City_KS', label: 'Ness City, KS' },
  { value: 'Netawaka_KS', label: 'Netawaka, KS' },
  { value: 'Netcong_NJ', label: 'Netcong, NJ' },
  { value: 'Nett Lake_MN', label: 'Nett Lake, MN' },
  { value: 'Nettie_WV', label: 'Nettie, WV' },
  { value: 'Nettleton_MS', label: 'Nettleton, MS' },
  { value: 'Nevada_OH', label: 'Nevada, OH' },
  { value: 'Nevada_IA', label: 'Nevada, IA' },
  { value: 'Nevada_MO', label: 'Nevada, MO' },
  { value: 'Nevada_TX', label: 'Nevada, TX' },
  { value: 'Nevada City_CA', label: 'Nevada City, CA' },
  { value: 'Neversink_NY', label: 'Neversink, NY' },
  { value: 'Neville_OH', label: 'Neville, OH' },
  { value: 'Nevis_MN', label: 'Nevis, MN' },
  { value: 'New Albany_PA', label: 'New Albany, PA' },
  { value: 'New Albany_MS', label: 'New Albany, MS' },
  { value: 'New Albany_OH', label: 'New Albany, OH' },
  { value: 'New Albany_IN', label: 'New Albany, IN' },
  { value: 'New Albin_IA', label: 'New Albin, IA' },
  { value: 'New Alexandria_PA', label: 'New Alexandria, PA' },
  { value: 'New Athens_OH', label: 'New Athens, OH' },
  { value: 'New Athens_IL', label: 'New Athens, IL' },
  { value: 'New Auburn_WI', label: 'New Auburn, WI' },
  { value: 'New Auburn_MN', label: 'New Auburn, MN' },
  { value: 'New Augusta_MS', label: 'New Augusta, MS' },
  { value: 'New Baden_IL', label: 'New Baden, IL' },
  { value: 'New Baltimore_NY', label: 'New Baltimore, NY' },
  { value: 'New Baltimore_MI', label: 'New Baltimore, MI' },
  { value: 'New Bavaria_OH', label: 'New Bavaria, OH' },
  { value: 'New Bedford_MA', label: 'New Bedford, MA' },
  { value: 'New Bedford_PA', label: 'New Bedford, PA' },
  { value: 'New Bedford_IL', label: 'New Bedford, IL' },
  { value: 'New Berlin_NY', label: 'New Berlin, NY' },
  { value: 'New Berlin_PA', label: 'New Berlin, PA' },
  { value: 'New Berlin_WI', label: 'New Berlin, WI' },
  { value: 'New Berlin_IL', label: 'New Berlin, IL' },
  { value: 'New Berlinville_PA', label: 'New Berlinville, PA' },
  { value: 'New Bern_NC', label: 'New Bern, NC' },
  { value: 'New Bethlehem_PA', label: 'New Bethlehem, PA' },
  { value: 'New Blaine_AR', label: 'New Blaine, AR' },
  { value: 'New Bloomfield_PA', label: 'New Bloomfield, PA' },
  { value: 'New Bloomfield_MO', label: 'New Bloomfield, MO' },
  { value: 'New Bloomington_OH', label: 'New Bloomington, OH' },
  { value: 'New Boston_NH', label: 'New Boston, NH' },
  { value: 'New Boston_MI', label: 'New Boston, MI' },
  { value: 'New Boston_IL', label: 'New Boston, IL' },
  { value: 'New Boston_MO', label: 'New Boston, MO' },
  { value: 'New Boston_TX', label: 'New Boston, TX' },
  { value: 'New Braintree_MA', label: 'New Braintree, MA' },
  { value: 'New Braunfels_TX', label: 'New Braunfels, TX' },
  { value: 'New Bremen_OH', label: 'New Bremen, OH' },
  { value: 'New Brighton_PA', label: 'New Brighton, PA' },
  { value: 'New Britain_CT', label: 'New Britain, CT' },
  { value: 'New Brockton_AL', label: 'New Brockton, AL' },
  { value: 'New Brunswick_NJ', label: 'New Brunswick, NJ' },
  { value: 'New Buffalo_PA', label: 'New Buffalo, PA' },
  { value: 'New Buffalo_MI', label: 'New Buffalo, MI' },
  { value: 'New Burnside_IL', label: 'New Burnside, IL' },
  { value: 'New Cambria_MO', label: 'New Cambria, MO' },
  { value: 'New Cambria_KS', label: 'New Cambria, KS' },
  { value: 'New Canaan_CT', label: 'New Canaan, CT' },
  { value: 'New Caney_TX', label: 'New Caney, TX' },
  { value: 'New Canton_VA', label: 'New Canton, VA' },
  { value: 'New Canton_IL', label: 'New Canton, IL' },
  { value: 'New Carlisle_OH', label: 'New Carlisle, OH' },
  { value: 'New Carlisle_IN', label: 'New Carlisle, IN' },
  { value: 'New Castle_NH', label: 'New Castle, NH' },
  { value: 'New Castle_PA', label: 'New Castle, PA' },
  { value: 'New Castle_DE', label: 'New Castle, DE' },
  { value: 'New Castle_VA', label: 'New Castle, VA' },
  { value: 'New Castle_AL', label: 'New Castle, AL' },
  { value: 'New Castle_KY', label: 'New Castle, KY' },
  { value: 'New Castle_IN', label: 'New Castle, IN' },
  { value: 'New Castle_CO', label: 'New Castle, CO' },
  { value: 'New Century_KS', label: 'New Century, KS' },
  { value: 'New Church_VA', label: 'New Church, VA' },
  { value: 'New City_NY', label: 'New City, NY' },
  { value: 'New Columbia_PA', label: 'New Columbia, PA' },
  { value: 'New Concord_KY', label: 'New Concord, KY' },
  { value: 'New Concord_OH', label: 'New Concord, OH' },
  { value: 'New Creek_WV', label: 'New Creek, WV' },
  { value: 'New Cumberland_PA', label: 'New Cumberland, PA' },
  { value: 'New Cumberland_WV', label: 'New Cumberland, WV' },
  { value: 'New Cuyama_CA', label: 'New Cuyama, CA' },
  { value: 'New Deal_TX', label: 'New Deal, TX' },
  { value: 'New Derry_PA', label: 'New Derry, PA' },
  { value: 'New Douglas_IL', label: 'New Douglas, IL' },
  { value: 'New Durham_NH', label: 'New Durham, NH' },
  { value: 'New Eagle_PA', label: 'New Eagle, PA' },
  { value: 'New Edinburg_AR', label: 'New Edinburg, AR' },
  { value: 'New Effington_SD', label: 'New Effington, SD' },
  { value: 'New Egypt_NJ', label: 'New Egypt, NJ' },
  { value: 'New Ellenton_SC', label: 'New Ellenton, SC' },
  { value: 'New England_ND', label: 'New England, ND' },
  { value: 'New Enterprise_PA', label: 'New Enterprise, PA' },
  { value: 'New Era_MI', label: 'New Era, MI' },
  { value: 'New Fairfield_CT', label: 'New Fairfield, CT' },
  { value: 'New Florence_PA', label: 'New Florence, PA' },
  { value: 'New Florence_MO', label: 'New Florence, MO' },
  { value: 'New Franken_WI', label: 'New Franken, WI' },
  { value: 'New Franklin_MO', label: 'New Franklin, MO' },
  { value: 'New Freedom_PA', label: 'New Freedom, PA' },
  { value: 'New Freeport_PA', label: 'New Freeport, PA' },
  { value: 'New Galilee_PA', label: 'New Galilee, PA' },
  { value: 'New Geneva_PA', label: 'New Geneva, PA' },
  { value: 'New Germantown_PA', label: 'New Germantown, PA' },
  { value: 'New Germany_MN', label: 'New Germany, MN' },
  { value: 'New Glarus_WI', label: 'New Glarus, WI' },
  { value: 'New Gloucester_ME', label: 'New Gloucester, ME' },
  { value: 'New Goshen_IN', label: 'New Goshen, IN' },
  { value: 'New Gretna_NJ', label: 'New Gretna, NJ' },
  { value: 'New Hampshire_OH', label: 'New Hampshire, OH' },
  { value: 'New Hampton_NH', label: 'New Hampton, NH' },
  { value: 'New Hampton_NY', label: 'New Hampton, NY' },
  { value: 'New Hampton_IA', label: 'New Hampton, IA' },
  { value: 'New Hampton_MO', label: 'New Hampton, MO' },
  { value: 'New Harbor_ME', label: 'New Harbor, ME' },
  { value: 'New Harmony_IN', label: 'New Harmony, IN' },
  { value: 'New Harmony_UT', label: 'New Harmony, UT' },
  { value: 'New Hartford_CT', label: 'New Hartford, CT' },
  { value: 'New Hartford_NY', label: 'New Hartford, NY' },
  { value: 'New Hartford_IA', label: 'New Hartford, IA' },
  { value: 'New Haven_VT', label: 'New Haven, VT' },
  { value: 'New Haven_CT', label: 'New Haven, CT' },
  { value: 'New Haven_NY', label: 'New Haven, NY' },
  { value: 'New Haven_WV', label: 'New Haven, WV' },
  { value: 'New Haven_KY', label: 'New Haven, KY' },
  { value: 'New Haven_OH', label: 'New Haven, OH' },
  { value: 'New Haven_IN', label: 'New Haven, IN' },
  { value: 'New Haven_MI', label: 'New Haven, MI' },
  { value: 'New Haven_IL', label: 'New Haven, IL' },
  { value: 'New Haven_MO', label: 'New Haven, MO' },
  { value: 'New Hill_NC', label: 'New Hill, NC' },
  { value: 'New Holland_PA', label: 'New Holland, PA' },
  { value: 'New Holland_OH', label: 'New Holland, OH' },
  { value: 'New Holland_SD', label: 'New Holland, SD' },
  { value: 'New Holland_IL', label: 'New Holland, IL' },
  { value: 'New Holstein_WI', label: 'New Holstein, WI' },
  { value: 'New Home_TX', label: 'New Home, TX' },
  { value: 'New Hope_PA', label: 'New Hope, PA' },
  { value: 'New Hope_VA', label: 'New Hope, VA' },
  { value: 'New Hope_AL', label: 'New Hope, AL' },
  { value: 'New Hope_KY', label: 'New Hope, KY' },
  { value: 'New Hudson_MI', label: 'New Hudson, MI' },
  { value: 'New Hyde Park_NY', label: 'New Hyde Park, NY' },
  { value: 'New Iberia_LA', label: 'New Iberia, LA' },
  { value: 'New Ipswich_NH', label: 'New Ipswich, NH' },
  { value: 'New Johnsonville_TN', label: 'New Johnsonville, TN' },
  { value: 'New Kensington_PA', label: 'New Kensington, PA' },
  { value: 'New Kent_VA', label: 'New Kent, VA' },
  { value: 'New Kingston_NY', label: 'New Kingston, NY' },
  { value: 'New Knoxville_OH', label: 'New Knoxville, OH' },
  { value: 'New Laguna_NM', label: 'New Laguna, NM' },
  { value: 'New Lebanon_NY', label: 'New Lebanon, NY' },
  { value: 'New Lebanon_OH', label: 'New Lebanon, OH' },
  { value: 'New Leipzig_ND', label: 'New Leipzig, ND' },
  { value: 'New Lenox_IL', label: 'New Lenox, IL' },
  { value: 'New Lexington_OH', label: 'New Lexington, OH' },
  { value: 'New Liberty_KY', label: 'New Liberty, KY' },
  { value: 'New Liberty_IA', label: 'New Liberty, IA' },
  { value: 'New Limerick_ME', label: 'New Limerick, ME' },
  { value: 'New Lisbon_NJ', label: 'New Lisbon, NJ' },
  { value: 'New Lisbon_NY', label: 'New Lisbon, NY' },
  { value: 'New Lisbon_IN', label: 'New Lisbon, IN' },
  { value: 'New Lisbon_WI', label: 'New Lisbon, WI' },
  { value: 'New Llano_LA', label: 'New Llano, LA' },
  { value: 'New London_NH', label: 'New London, NH' },
  { value: 'New London_CT', label: 'New London, CT' },
  { value: 'New London_NC', label: 'New London, NC' },
  { value: 'New London_OH', label: 'New London, OH' },
  { value: 'New London_IA', label: 'New London, IA' },
  { value: 'New London_WI', label: 'New London, WI' },
  { value: 'New London_MN', label: 'New London, MN' },
  { value: 'New London_MO', label: 'New London, MO' },
  { value: 'New London_TX', label: 'New London, TX' },
  { value: 'New Lothrop_MI', label: 'New Lothrop, MI' },
  { value: 'New Madison_OH', label: 'New Madison, OH' },
  { value: 'New Madrid_MO', label: 'New Madrid, MO' },
  { value: 'New Manchester_WV', label: 'New Manchester, WV' },
  { value: 'New Market_MD', label: 'New Market, MD' },
  { value: 'New Market_VA', label: 'New Market, VA' },
  { value: 'New Market_AL', label: 'New Market, AL' },
  { value: 'New Market_TN', label: 'New Market, TN' },
  { value: 'New Market_IN', label: 'New Market, IN' },
  { value: 'New Market_IA', label: 'New Market, IA' },
  { value: 'New Marshfield_OH', label: 'New Marshfield, OH' },
  { value: 'New Martinsville_WV', label: 'New Martinsville, WV' },
  { value: 'New Matamoras_OH', label: 'New Matamoras, OH' },
  { value: 'New Meadows_ID', label: 'New Meadows, ID' },
  { value: 'New Melle_MO', label: 'New Melle, MO' },
  { value: 'New Memphis_IL', label: 'New Memphis, IL' },
  { value: 'New Middletown_OH', label: 'New Middletown, OH' },
  { value: 'New Middletown_IN', label: 'New Middletown, IN' },
  { value: 'New Milford_CT', label: 'New Milford, CT' },
  { value: 'New Milford_NJ', label: 'New Milford, NJ' },
  { value: 'New Milford_PA', label: 'New Milford, PA' },
  { value: 'New Millport_PA', label: 'New Millport, PA' },
  { value: 'New Milton_WV', label: 'New Milton, WV' },
  { value: 'New Munich_MN', label: 'New Munich, MN' },
  { value: 'New Orleans_LA', label: 'New Orleans, LA' },
  { value: 'New Oxford_PA', label: 'New Oxford, PA' },
  { value: 'New Palestine_IN', label: 'New Palestine, IN' },
  { value: 'New Paltz_NY', label: 'New Paltz, NY' },
  { value: 'New Paris_PA', label: 'New Paris, PA' },
  { value: 'New Paris_OH', label: 'New Paris, OH' },
  { value: 'New Paris_IN', label: 'New Paris, IN' },
  { value: 'New Park_PA', label: 'New Park, PA' },
  { value: 'New Philadelphia_PA', label: 'New Philadelphia, PA' },
  { value: 'New Philadelphia_OH', label: 'New Philadelphia, OH' },
  { value: 'New Pine Creek_OR', label: 'New Pine Creek, OR' },
  { value: 'New Plymouth_OH', label: 'New Plymouth, OH' },
  { value: 'New Plymouth_ID', label: 'New Plymouth, ID' },
  { value: 'New Point_IN', label: 'New Point, IN' },
  { value: 'New Port Richey_FL', label: 'New Port Richey, FL' },
  { value: 'New Portland_ME', label: 'New Portland, ME' },
  { value: 'New Prague_MN', label: 'New Prague, MN' },
  { value: 'New Preston Marble Dale_CT', label: 'New Preston Marble Dale, CT' },
  { value: 'New Providence_NJ', label: 'New Providence, NJ' },
  { value: 'New Providence_PA', label: 'New Providence, PA' },
  { value: 'New Providence_IA', label: 'New Providence, IA' },
  { value: 'New Raymer_CO', label: 'New Raymer, CO' },
  { value: 'New Richland_MN', label: 'New Richland, MN' },
  { value: 'New Richmond_WV', label: 'New Richmond, WV' },
  { value: 'New Richmond_OH', label: 'New Richmond, OH' },
  { value: 'New Richmond_IN', label: 'New Richmond, IN' },
  { value: 'New Richmond_WI', label: 'New Richmond, WI' },
  { value: 'New Riegel_OH', label: 'New Riegel, OH' },
  { value: 'New Ringgold_PA', label: 'New Ringgold, PA' },
  { value: 'New River_VA', label: 'New River, VA' },
  { value: 'New River_AZ', label: 'New River, AZ' },
  { value: 'New Roads_LA', label: 'New Roads, LA' },
  { value: 'New Rochelle_NY', label: 'New Rochelle, NY' },
  { value: 'New Rockford_ND', label: 'New Rockford, ND' },
  { value: 'New Ross_IN', label: 'New Ross, IN' },
  { value: 'New Russia_NY', label: 'New Russia, NY' },
  { value: 'New Salem_MA', label: 'New Salem, MA' },
  { value: 'New Salem_PA', label: 'New Salem, PA' },
  { value: 'New Salem_ND', label: 'New Salem, ND' },
  { value: 'New Salem_IL', label: 'New Salem, IL' },
  { value: 'New Salisbury_IN', label: 'New Salisbury, IN' },
  { value: 'New Sharon_ME', label: 'New Sharon, ME' },
  { value: 'New Sharon_IA', label: 'New Sharon, IA' },
  { value: 'New Site_MS', label: 'New Site, MS' },
  { value: 'New Smyrna Beach_FL', label: 'New Smyrna Beach, FL' },
  { value: 'New Springfield_OH', label: 'New Springfield, OH' },
  { value: 'New Stanton_PA', label: 'New Stanton, PA' },
  { value: 'New Straitsville_OH', label: 'New Straitsville, OH' },
  { value: 'New Stuyahok_AK', label: 'New Stuyahok, AK' },
  { value: 'New Suffolk_NY', label: 'New Suffolk, NY' },
  { value: 'New Summerfield_TX', label: 'New Summerfield, TX' },
  { value: 'New Sweden_ME', label: 'New Sweden, ME' },
  { value: 'New Tazewell_TN', label: 'New Tazewell, TN' },
  { value: 'New Town_ND', label: 'New Town, ND' },
  { value: 'New Trenton_IN', label: 'New Trenton, IN' },
  { value: 'New Tripoli_PA', label: 'New Tripoli, PA' },
  { value: 'New Troy_MI', label: 'New Troy, MI' },
  { value: 'New Ulm_MN', label: 'New Ulm, MN' },
  { value: 'New Ulm_TX', label: 'New Ulm, TX' },
  { value: 'New Underwood_SD', label: 'New Underwood, SD' },
  { value: 'New Vernon_NJ', label: 'New Vernon, NJ' },
  { value: 'New Vienna_OH', label: 'New Vienna, OH' },
  { value: 'New Vienna_IA', label: 'New Vienna, IA' },
  { value: 'New Vineyard_ME', label: 'New Vineyard, ME' },
  { value: 'New Virginia_IA', label: 'New Virginia, IA' },
  { value: 'New Washington_OH', label: 'New Washington, OH' },
  { value: 'New Washington_IN', label: 'New Washington, IN' },
  { value: 'New Waterford_OH', label: 'New Waterford, OH' },
  { value: 'New Waverly_IN', label: 'New Waverly, IN' },
  { value: 'New Waverly_TX', label: 'New Waverly, TX' },
  { value: 'New Weston_OH', label: 'New Weston, OH' },
  { value: 'New Wilmington_PA', label: 'New Wilmington, PA' },
  { value: 'New Windsor_NY', label: 'New Windsor, NY' },
  { value: 'New Windsor_MD', label: 'New Windsor, MD' },
  { value: 'New Windsor_IL', label: 'New Windsor, IL' },
  { value: 'New Woodstock_NY', label: 'New Woodstock, NY' },
  { value: 'New York_NY', label: 'New York, NY' },
  { value: 'New York Mills_NY', label: 'New York Mills, NY' },
  { value: 'New York Mills_MN', label: 'New York Mills, MN' },
  { value: 'New Zion_SC', label: 'New Zion, SC' },
  { value: 'Newalla_OK', label: 'Newalla, OK' },
  { value: 'Newark_NJ', label: 'Newark, NJ' },
  { value: 'Newark_NY', label: 'Newark, NY' },
  { value: 'Newark_DE', label: 'Newark, DE' },
  { value: 'Newark_MD', label: 'Newark, MD' },
  { value: 'Newark_OH', label: 'Newark, OH' },
  { value: 'Newark_IL', label: 'Newark, IL' },
  { value: 'Newark_MO', label: 'Newark, MO' },
  { value: 'Newark_AR', label: 'Newark, AR' },
  { value: 'Newark_TX', label: 'Newark, TX' },
  { value: 'Newark_CA', label: 'Newark, CA' },
  { value: 'Newark Valley_NY', label: 'Newark Valley, NY' },
  { value: 'Newaygo_MI', label: 'Newaygo, MI' },
  { value: 'Newberg_OR', label: 'Newberg, OR' },
  { value: 'Newbern_AL', label: 'Newbern, AL' },
  { value: 'Newbern_TN', label: 'Newbern, TN' },
  { value: 'Newberry_SC', label: 'Newberry, SC' },
  { value: 'Newberry_FL', label: 'Newberry, FL' },
  { value: 'Newberry_IN', label: 'Newberry, IN' },
  { value: 'Newberry_MI', label: 'Newberry, MI' },
  { value: 'Newberry Springs_CA', label: 'Newberry Springs, CA' },
  { value: 'Newborn_GA', label: 'Newborn, GA' },
  { value: 'Newburg_PA', label: 'Newburg, PA' },
  { value: 'Newburg_MD', label: 'Newburg, MD' },
  { value: 'Newburg_WV', label: 'Newburg, WV' },
  { value: 'Newburg_ND', label: 'Newburg, ND' },
  { value: 'Newburg_MO', label: 'Newburg, MO' },
  { value: 'Newburgh_NY', label: 'Newburgh, NY' },
  { value: 'Newburgh_IN', label: 'Newburgh, IN' },
  { value: 'Newbury_MA', label: 'Newbury, MA' },
  { value: 'Newbury_NH', label: 'Newbury, NH' },
  { value: 'Newbury_VT', label: 'Newbury, VT' },
  { value: 'Newbury_OH', label: 'Newbury, OH' },
  { value: 'Newbury Park_CA', label: 'Newbury Park, CA' },
  { value: 'Newburyport_MA', label: 'Newburyport, MA' },
  { value: 'Newcastle_ME', label: 'Newcastle, ME' },
  { value: 'Newcastle_NE', label: 'Newcastle, NE' },
  { value: 'Newcastle_OK', label: 'Newcastle, OK' },
  { value: 'Newcastle_TX', label: 'Newcastle, TX' },
  { value: 'Newcastle_WY', label: 'Newcastle, WY' },
  { value: 'Newcastle_UT', label: 'Newcastle, UT' },
  { value: 'Newcastle_CA', label: 'Newcastle, CA' },
  { value: 'Newcomb_NY', label: 'Newcomb, NY' },
  { value: 'Newcomb_MD', label: 'Newcomb, MD' },
  { value: 'Newcomb_TN', label: 'Newcomb, TN' },
  { value: 'Newcomb_NM', label: 'Newcomb, NM' },
  { value: 'Newcomerstown_OH', label: 'Newcomerstown, OH' },
  { value: 'Newdale_ID', label: 'Newdale, ID' },
  { value: 'Newell_PA', label: 'Newell, PA' },
  { value: 'Newell_WV', label: 'Newell, WV' },
  { value: 'Newell_IA', label: 'Newell, IA' },
  { value: 'Newell_SD', label: 'Newell, SD' },
  { value: 'Newellton_LA', label: 'Newellton, LA' },
  { value: 'Newfane_VT', label: 'Newfane, VT' },
  { value: 'Newfane_NY', label: 'Newfane, NY' },
  { value: 'Newfield_ME', label: 'Newfield, ME' },
  { value: 'Newfield_NJ', label: 'Newfield, NJ' },
  { value: 'Newfield_NY', label: 'Newfield, NY' },
  { value: 'Newfields_NH', label: 'Newfields, NH' },
  { value: 'Newfolden_MN', label: 'Newfolden, MN' },
  { value: 'Newfoundland_NJ', label: 'Newfoundland, NJ' },
  { value: 'Newfoundland_PA', label: 'Newfoundland, PA' },
  { value: 'Newhall_WV', label: 'Newhall, WV' },
  { value: 'Newhall_IA', label: 'Newhall, IA' },
  { value: 'Newhall_CA', label: 'Newhall, CA' },
  { value: 'Newhebron_MS', label: 'Newhebron, MS' },
  { value: 'Newhope_AR', label: 'Newhope, AR' },
  { value: 'Newington_CT', label: 'Newington, CT' },
  { value: 'Newington_GA', label: 'Newington, GA' },
  { value: 'Newkirk_OK', label: 'Newkirk, OK' },
  { value: 'Newkirk_NM', label: 'Newkirk, NM' },
  { value: 'Newland_NC', label: 'Newland, NC' },
  { value: 'Newman_IL', label: 'Newman, IL' },
  { value: 'Newman_CA', label: 'Newman, CA' },
  { value: 'Newman Grove_NE', label: 'Newman Grove, NE' },
  { value: 'Newman Lake_WA', label: 'Newman Lake, WA' },
  { value: 'Newmanstown_PA', label: 'Newmanstown, PA' },
  { value: 'Newmarket_NH', label: 'Newmarket, NH' },
  { value: 'Newnan_GA', label: 'Newnan, GA' },
  { value: 'Newport_RI', label: 'Newport, RI' },
  { value: 'Newport_NH', label: 'Newport, NH' },
  { value: 'Newport_ME', label: 'Newport, ME' },
  { value: 'Newport_VT', label: 'Newport, VT' },
  { value: 'Newport_NJ', label: 'Newport, NJ' },
  { value: 'Newport_NY', label: 'Newport, NY' },
  { value: 'Newport_PA', label: 'Newport, PA' },
  { value: 'Newport_VA', label: 'Newport, VA' },
  { value: 'Newport_NC', label: 'Newport, NC' },
  { value: 'Newport_TN', label: 'Newport, TN' },
  { value: 'Newport_KY', label: 'Newport, KY' },
  { value: 'Newport_OH', label: 'Newport, OH' },
  { value: 'Newport_IN', label: 'Newport, IN' },
  { value: 'Newport_MI', label: 'Newport, MI' },
  { value: 'Newport_MN', label: 'Newport, MN' },
  { value: 'Newport_NE', label: 'Newport, NE' },
  { value: 'Newport_AR', label: 'Newport, AR' },
  { value: 'Newport_OR', label: 'Newport, OR' },
  { value: 'Newport_WA', label: 'Newport, WA' },
  { value: 'Newport Beach_CA', label: 'Newport Beach, CA' },
  { value: 'Newport Center_VT', label: 'Newport Center, VT' },
  { value: 'Newport Coast_CA', label: 'Newport Coast, CA' },
  { value: 'Newport News_VA', label: 'Newport News, VA' },
  { value: 'Newry_ME', label: 'Newry, ME' },
  { value: 'Newry_PA', label: 'Newry, PA' },
  { value: 'Newry_SC', label: 'Newry, SC' },
  { value: 'Newsoms_VA', label: 'Newsoms, VA' },
  { value: 'Newton_MA', label: 'Newton, MA' },
  { value: 'Newton_NH', label: 'Newton, NH' },
  { value: 'Newton_NJ', label: 'Newton, NJ' },
  { value: 'Newton_WV', label: 'Newton, WV' },
  { value: 'Newton_NC', label: 'Newton, NC' },
  { value: 'Newton_AL', label: 'Newton, AL' },
  { value: 'Newton_MS', label: 'Newton, MS' },
  { value: 'Newton_GA', label: 'Newton, GA' },
  { value: 'Newton_IA', label: 'Newton, IA' },
  { value: 'Newton_WI', label: 'Newton, WI' },
  { value: 'Newton_IL', label: 'Newton, IL' },
  { value: 'Newton_KS', label: 'Newton, KS' },
  { value: 'Newton_TX', label: 'Newton, TX' },
  { value: 'Newton_UT', label: 'Newton, UT' },
  { value: 'Newton Center_MA', label: 'Newton Center, MA' },
  { value: 'Newton Falls_NY', label: 'Newton Falls, NY' },
  { value: 'Newton Falls_OH', label: 'Newton Falls, OH' },
  { value: 'Newton Grove_NC', label: 'Newton Grove, NC' },
  { value: 'Newton Hamilton_PA', label: 'Newton Hamilton, PA' },
  { value: 'Newton Highlands_MA', label: 'Newton Highlands, MA' },
  { value: 'Newton Lower Falls_MA', label: 'Newton Lower Falls, MA' },
  { value: 'Newton Upper Falls_MA', label: 'Newton Upper Falls, MA' },
  { value: 'Newtonsville_OH', label: 'Newtonsville, OH' },
  { value: 'Newtonville_MA', label: 'Newtonville, MA' },
  { value: 'Newtonville_NJ', label: 'Newtonville, NJ' },
  { value: 'Newtown_CT', label: 'Newtown, CT' },
  { value: 'Newtown_PA', label: 'Newtown, PA' },
  { value: 'Newtown_VA', label: 'Newtown, VA' },
  { value: 'Newtown_IN', label: 'Newtown, IN' },
  { value: 'Newtown_MO', label: 'Newtown, MO' },
  { value: 'Newtown Square_PA', label: 'Newtown Square, PA' },
  { value: 'Newville_PA', label: 'Newville, PA' },
  { value: 'Newville_AL', label: 'Newville, AL' },
  { value: 'Ney_OH', label: 'Ney, OH' },
  { value: 'Nezperce_ID', label: 'Nezperce, ID' },
  { value: 'Niagara_WI', label: 'Niagara, WI' },
  { value: 'Niagara_ND', label: 'Niagara, ND' },
  { value: 'Niagara Falls_NY', label: 'Niagara Falls, NY' },
  { value: 'Niagara University_NY', label: 'Niagara University, NY' },
  { value: 'Niangua_MO', label: 'Niangua, MO' },
  { value: 'Niantic_CT', label: 'Niantic, CT' },
  { value: 'Niantic_IL', label: 'Niantic, IL' },
  { value: 'Nicasio_CA', label: 'Nicasio, CA' },
  { value: 'Nice_CA', label: 'Nice, CA' },
  { value: 'Niceville_FL', label: 'Niceville, FL' },
  { value: 'Nicholasville_KY', label: 'Nicholasville, KY' },
  { value: 'Nicholls_GA', label: 'Nicholls, GA' },
  { value: 'Nichols_NY', label: 'Nichols, NY' },
  { value: 'Nichols_SC', label: 'Nichols, SC' },
  { value: 'Nichols_IA', label: 'Nichols, IA' },
  { value: 'Nichols_WI', label: 'Nichols, WI' },
  { value: 'Nicholson_PA', label: 'Nicholson, PA' },
  { value: 'Nicholson_GA', label: 'Nicholson, GA' },
  { value: 'Nicholville_NY', label: 'Nicholville, NY' },
  { value: 'Nickelsville_VA', label: 'Nickelsville, VA' },
  { value: 'Nickerson_KS', label: 'Nickerson, KS' },
  { value: 'Nickerson_NE', label: 'Nickerson, NE' },
  { value: 'Nicktown_PA', label: 'Nicktown, PA' },
  { value: 'Nicolaus_CA', label: 'Nicolaus, CA' },
  { value: 'Nicollet_MN', label: 'Nicollet, MN' },
  { value: 'Nicoma Park_OK', label: 'Nicoma Park, OK' },
  { value: 'Nielsville_MN', label: 'Nielsville, MN' },
  { value: 'Nightmute_AK', label: 'Nightmute, AK' },
  { value: 'Nikiski_AK', label: 'Nikiski, AK' },
  { value: 'Nikolai_AK', label: 'Nikolai, AK' },
  { value: 'Nikolski_AK', label: 'Nikolski, AK' },
  { value: 'Niland_CA', label: 'Niland, CA' },
  { value: 'Niles_OH', label: 'Niles, OH' },
  { value: 'Niles_MI', label: 'Niles, MI' },
  { value: 'Niles_IL', label: 'Niles, IL' },
  { value: 'Nilwood_IL', label: 'Nilwood, IL' },
  { value: 'Nimitz_WV', label: 'Nimitz, WV' },
  { value: 'Nine Mile Falls_WA', label: 'Nine Mile Falls, WA' },
  { value: 'Ninety Six_SC', label: 'Ninety Six, SC' },
  { value: 'Nineveh_NY', label: 'Nineveh, NY' },
  { value: 'Nineveh_PA', label: 'Nineveh, PA' },
  { value: 'Nineveh_IN', label: 'Nineveh, IN' },
  { value: 'Ninilchik_AK', label: 'Ninilchik, AK' },
  { value: 'Ninnekah_OK', label: 'Ninnekah, OK' },
  { value: 'Ninole_HI', label: 'Ninole, HI' },
  { value: 'Niobrara_NE', label: 'Niobrara, NE' },
  { value: 'Niota_TN', label: 'Niota, TN' },
  { value: 'Niota_IL', label: 'Niota, IL' },
  { value: 'Niotaze_KS', label: 'Niotaze, KS' },
  { value: 'Nipomo_CA', label: 'Nipomo, CA' },
  { value: 'Nipton_CA', label: 'Nipton, CA' },
  { value: 'Nisland_SD', label: 'Nisland, SD' },
  { value: 'Nisswa_MN', label: 'Nisswa, MN' },
  { value: 'Nisula_MI', label: 'Nisula, MI' },
  { value: 'Nitro_WV', label: 'Nitro, WV' },
  { value: 'Niverville_NY', label: 'Niverville, NY' },
  { value: 'Niwot_CO', label: 'Niwot, CO' },
  { value: 'Nixa_MO', label: 'Nixa, MO' },
  { value: 'Nixon_TX', label: 'Nixon, TX' },
  { value: 'Nixon_NV', label: 'Nixon, NV' },
  { value: 'Noatak_AK', label: 'Noatak, AK' },
  { value: 'Noble_IL', label: 'Noble, IL' },
  { value: 'Noble_MO', label: 'Noble, MO' },
  { value: 'Noble_LA', label: 'Noble, LA' },
  { value: 'Noble_OK', label: 'Noble, OK' },
  { value: 'Nobleboro_ME', label: 'Nobleboro, ME' },
  { value: 'Noblesville_IN', label: 'Noblesville, IN' },
  { value: 'Nobleton_FL', label: 'Nobleton, FL' },
  { value: 'Nocatee_FL', label: 'Nocatee, FL' },
  { value: 'Nocona_TX', label: 'Nocona, TX' },
  { value: 'Nodaway_IA', label: 'Nodaway, IA' },
  { value: 'Noel_MO', label: 'Noel, MO' },
  { value: 'Nogal_NM', label: 'Nogal, NM' },
  { value: 'Nogales_AZ', label: 'Nogales, AZ' },
  { value: 'Nokesville_VA', label: 'Nokesville, VA' },
  { value: 'Nokomis_FL', label: 'Nokomis, FL' },
  { value: 'Nokomis_IL', label: 'Nokomis, IL' },
  { value: 'Nolan_TX', label: 'Nolan, TX' },
  { value: 'Nolanville_TX', label: 'Nolanville, TX' },
  { value: 'Nolensville_TN', label: 'Nolensville, TN' },
  { value: 'Nome_ND', label: 'Nome, ND' },
  { value: 'Nome_TX', label: 'Nome, TX' },
  { value: 'Nome_AK', label: 'Nome, AK' },
  { value: 'Nondalton_AK', label: 'Nondalton, AK' },
  { value: 'Nooksack_WA', label: 'Nooksack, WA' },
  { value: 'Noonan_ND', label: 'Noonan, ND' },
  { value: 'Noorvik_AK', label: 'Noorvik, AK' },
  { value: 'Nora_VA', label: 'Nora, VA' },
  { value: 'Nora_IL', label: 'Nora, IL' },
  { value: 'Nora Springs_IA', label: 'Nora Springs, IA' },
  { value: 'Norborne_MO', label: 'Norborne, MO' },
  { value: 'Norcatur_KS', label: 'Norcatur, KS' },
  { value: 'Norco_LA', label: 'Norco, LA' },
  { value: 'Norco_CA', label: 'Norco, CA' },
  { value: 'Norcross_GA', label: 'Norcross, GA' },
  { value: 'Norcross_MN', label: 'Norcross, MN' },
  { value: 'Nordheim_TX', label: 'Nordheim, TX' },
  { value: 'Nordland_WA', label: 'Nordland, WA' },
  { value: 'Nordman_ID', label: 'Nordman, ID' },
  { value: 'Norfolk_MA', label: 'Norfolk, MA' },
  { value: 'Norfolk_CT', label: 'Norfolk, CT' },
  { value: 'Norfolk_NY', label: 'Norfolk, NY' },
  { value: 'Norfolk_VA', label: 'Norfolk, VA' },
  { value: 'Norfolk_NE', label: 'Norfolk, NE' },
  { value: 'Norfork_AR', label: 'Norfork, AR' },
  { value: 'Norlina_NC', label: 'Norlina, NC' },
  { value: 'Normal_IL', label: 'Normal, IL' },
  { value: 'Normalville_PA', label: 'Normalville, PA' },
  { value: 'Norman_NC', label: 'Norman, NC' },
  { value: 'Norman_IN', label: 'Norman, IN' },
  { value: 'Norman_AR', label: 'Norman, AR' },
  { value: 'Norman_OK', label: 'Norman, OK' },
  { value: 'Norman Park_GA', label: 'Norman Park, GA' },
  { value: 'Normandy_TN', label: 'Normandy, TN' },
  { value: 'Normangee_TX', label: 'Normangee, TX' },
  { value: 'Normanna_TX', label: 'Normanna, TX' },
  { value: 'Normantown_WV', label: 'Normantown, WV' },
  { value: 'Norphlet_AR', label: 'Norphlet, AR' },
  { value: 'Norridgewock_ME', label: 'Norridgewock, ME' },
  { value: 'Norris_SC', label: 'Norris, SC' },
  { value: 'Norris_TN', label: 'Norris, TN' },
  { value: 'Norris_SD', label: 'Norris, SD' },
  { value: 'Norris_MT', label: 'Norris, MT' },
  { value: 'Norris_IL', label: 'Norris, IL' },
  { value: 'Norris City_IL', label: 'Norris City, IL' },
  { value: 'Norristown_PA', label: 'Norristown, PA' },
  { value: 'North_VA', label: 'North, VA' },
  { value: 'North_SC', label: 'North, SC' },
  { value: 'North Adams_MA', label: 'North Adams, MA' },
  { value: 'North Adams_MI', label: 'North Adams, MI' },
  { value: 'North Andover_MA', label: 'North Andover, MA' },
  { value: 'North Anson_ME', label: 'North Anson, ME' },
  { value: 'North Apollo_PA', label: 'North Apollo, PA' },
  { value: 'North Arlington_NJ', label: 'North Arlington, NJ' },
  { value: 'North Attleboro_MA', label: 'North Attleboro, MA' },
  { value: 'North Augusta_SC', label: 'North Augusta, SC' },
  { value: 'North Aurora_IL', label: 'North Aurora, IL' },
  { value: 'North Babylon_NY', label: 'North Babylon, NY' },
  { value: 'North Baltimore_OH', label: 'North Baltimore, OH' },
  { value: 'North Bangor_NY', label: 'North Bangor, NY' },
  { value: 'North Bay_NY', label: 'North Bay, NY' },
  { value: 'North Beach_MD', label: 'North Beach, MD' },
  { value: 'North Bend_PA', label: 'North Bend, PA' },
  { value: 'North Bend_OH', label: 'North Bend, OH' },
  { value: 'North Bend_NE', label: 'North Bend, NE' },
  { value: 'North Bend_OR', label: 'North Bend, OR' },
  { value: 'North Bend_WA', label: 'North Bend, WA' },
  { value: 'North Bennington_VT', label: 'North Bennington, VT' },
  { value: 'North Benton_OH', label: 'North Benton, OH' },
  { value: 'North Bergen_NJ', label: 'North Bergen, NJ' },
  { value: 'North Berwick_ME', label: 'North Berwick, ME' },
  { value: 'North Billerica_MA', label: 'North Billerica, MA' },
  { value: 'North Blenheim_NY', label: 'North Blenheim, NY' },
  { value: 'North Bloomfield_OH', label: 'North Bloomfield, OH' },
  { value: 'North Bonneville_WA', label: 'North Bonneville, WA' },
  { value: 'North Branch_NY', label: 'North Branch, NY' },
  { value: 'North Branch_MI', label: 'North Branch, MI' },
  { value: 'North Branch_MN', label: 'North Branch, MN' },
  { value: 'North Branford_CT', label: 'North Branford, CT' },
  { value: 'North Bridgton_ME', label: 'North Bridgton, ME' },
  { value: 'North Brookfield_MA', label: 'North Brookfield, MA' },
  { value: 'North Brookfield_NY', label: 'North Brookfield, NY' },
  { value: 'North Brunswick_NJ', label: 'North Brunswick, NJ' },
  { value: 'North Buena Vista_IA', label: 'North Buena Vista, IA' },
  { value: 'North Canton_CT', label: 'North Canton, CT' },
  { value: 'North Canton_OH', label: 'North Canton, OH' },
  { value: 'North Carrollton_MS', label: 'North Carrollton, MS' },
  { value: 'North Charleston_SC', label: 'North Charleston, SC' },
  { value: 'North Chatham_MA', label: 'North Chatham, MA' },
  { value: 'North Chatham_NY', label: 'North Chatham, NY' },
  { value: 'North Chelmsford_MA', label: 'North Chelmsford, MA' },
  { value: 'North Chicago_IL', label: 'North Chicago, IL' },
  { value: 'North Chili_NY', label: 'North Chili, NY' },
  { value: 'North Clarendon_VT', label: 'North Clarendon, VT' },
  { value: 'North Collins_NY', label: 'North Collins, NY' },
  { value: 'North Concord_VT', label: 'North Concord, VT' },
  { value: 'North Conway_NH', label: 'North Conway, NH' },
  { value: 'North Creek_NY', label: 'North Creek, NY' },
  { value: 'North Dartmouth_MA', label: 'North Dartmouth, MA' },
  { value: 'North Dighton_MA', label: 'North Dighton, MA' },
  { value: 'North East_PA', label: 'North East, PA' },
  { value: 'North East_MD', label: 'North East, MD' },
  { value: 'North Easton_MA', label: 'North Easton, MA' },
  { value: 'North English_IA', label: 'North English, IA' },
  { value: 'North Evans_NY', label: 'North Evans, NY' },
  { value: 'North Fairfield_OH', label: 'North Fairfield, OH' },
  { value: 'North Falmouth_MA', label: 'North Falmouth, MA' },
  { value: 'North Ferrisburgh_VT', label: 'North Ferrisburgh, VT' },
  { value: 'North Fork_ID', label: 'North Fork, ID' },
  { value: 'North Fork_CA', label: 'North Fork, CA' },
  { value: 'North Fort Myers_FL', label: 'North Fort Myers, FL' },
  { value: 'North Franklin_CT', label: 'North Franklin, CT' },
  { value: 'North Freedom_WI', label: 'North Freedom, WI' },
  { value: 'North Garden_VA', label: 'North Garden, VA' },
  { value: 'North Georgetown_OH', label: 'North Georgetown, OH' },
  { value: 'North Grafton_MA', label: 'North Grafton, MA' },
  { value: 'North Granby_CT', label: 'North Granby, CT' },
  { value: 'North Granville_NY', label: 'North Granville, NY' },
  { value: 'North Greece_NY', label: 'North Greece, NY' },
  { value: 'North Grosvenordale_CT', label: 'North Grosvenordale, CT' },
  { value: 'North Hampton_NH', label: 'North Hampton, NH' },
  { value: 'North Hampton_OH', label: 'North Hampton, OH' },
  { value: 'North Hartland_VT', label: 'North Hartland, VT' },
  { value: 'North Hatfield_MA', label: 'North Hatfield, MA' },
  { value: 'North Haven_ME', label: 'North Haven, ME' },
  { value: 'North Haven_CT', label: 'North Haven, CT' },
  { value: 'North Haverhill_NH', label: 'North Haverhill, NH' },
  { value: 'North Henderson_IL', label: 'North Henderson, IL' },
  { value: 'North Hero_VT', label: 'North Hero, VT' },
  { value: 'North Highlands_CA', label: 'North Highlands, CA' },
  { value: 'North Hills_CA', label: 'North Hills, CA' },
  { value: 'North Hollywood_CA', label: 'North Hollywood, CA' },
  { value: 'North Hudson_NY', label: 'North Hudson, NY' },
  { value: 'North Jackson_OH', label: 'North Jackson, OH' },
  { value: 'North Java_NY', label: 'North Java, NY' },
  { value: 'North Judson_IN', label: 'North Judson, IN' },
  { value: 'North Kingstown_RI', label: 'North Kingstown, RI' },
  { value: 'North Kingsville_OH', label: 'North Kingsville, OH' },
  { value: 'North Las Vegas_NV', label: 'North Las Vegas, NV' },
  { value: 'North Lawrence_NY', label: 'North Lawrence, NY' },
  { value: 'North Lawrence_OH', label: 'North Lawrence, OH' },
  { value: 'North Lewisburg_OH', label: 'North Lewisburg, OH' },
  { value: 'North Liberty_IN', label: 'North Liberty, IN' },
  { value: 'North Liberty_IA', label: 'North Liberty, IA' },
  { value: 'North Lima_OH', label: 'North Lima, OH' },
  { value: 'North Little Rock_AR', label: 'North Little Rock, AR' },
  { value: 'North Loup_NE', label: 'North Loup, NE' },
  { value: 'North Manchester_IN', label: 'North Manchester, IN' },
  { value: 'North Matewan_WV', label: 'North Matewan, WV' },
  { value: 'North Miami_OK', label: 'North Miami, OK' },
  { value: 'North Miami Beach_FL', label: 'North Miami Beach, FL' },
  { value: 'North Middletown_KY', label: 'North Middletown, KY' },
  { value: 'North Monmouth_ME', label: 'North Monmouth, ME' },
  { value: 'North Montpelier_VT', label: 'North Montpelier, VT' },
  { value: 'North Myrtle Beach_SC', label: 'North Myrtle Beach, SC' },
  { value: 'North Newton_KS', label: 'North Newton, KS' },
  { value: 'North Norwich_NY', label: 'North Norwich, NY' },
  { value: 'North Olmsted_OH', label: 'North Olmsted, OH' },
  { value: 'North Oxford_MA', label: 'North Oxford, MA' },
  { value: 'North Palm Beach_FL', label: 'North Palm Beach, FL' },
  { value: 'North Palm Springs_CA', label: 'North Palm Springs, CA' },
  { value: 'North Pitcher_NY', label: 'North Pitcher, NY' },
  { value: 'North Plains_OR', label: 'North Plains, OR' },
  { value: 'North Platte_NE', label: 'North Platte, NE' },
  { value: 'North Pole_AK', label: 'North Pole, AK' },
  { value: 'North Pomfret_VT', label: 'North Pomfret, VT' },
  { value: 'North Port_FL', label: 'North Port, FL' },
  { value: 'North Powder_OR', label: 'North Powder, OR' },
  { value: 'North Pownal_VT', label: 'North Pownal, VT' },
  { value: 'North Prairie_WI', label: 'North Prairie, WI' },
  { value: 'North Providence_RI', label: 'North Providence, RI' },
  { value: 'North Reading_MA', label: 'North Reading, MA' },
  { value: 'North Richland Hills_TX', label: 'North Richland Hills, TX' },
  { value: 'North Ridgeville_OH', label: 'North Ridgeville, OH' },
  { value: 'North Rim_AZ', label: 'North Rim, AZ' },
  { value: 'North River_NY', label: 'North River, NY' },
  { value: 'North Robinson_OH', label: 'North Robinson, OH' },
  { value: 'North Rose_NY', label: 'North Rose, NY' },
  { value: 'North Royalton_OH', label: 'North Royalton, OH' },
  { value: 'North Salem_NY', label: 'North Salem, NY' },
  { value: 'North Salem_IN', label: 'North Salem, IN' },
  { value: 'North Salt Lake_UT', label: 'North Salt Lake, UT' },
  { value: 'North San Juan_CA', label: 'North San Juan, CA' },
  { value: 'North Sandwich_NH', label: 'North Sandwich, NH' },
  { value: 'North Scituate_RI', label: 'North Scituate, RI' },
  { value: 'North Sioux City_SD', label: 'North Sioux City, SD' },
  { value: 'North Smithfield_RI', label: 'North Smithfield, RI' },
  { value: 'North Spring_WV', label: 'North Spring, WV' },
  { value: 'North Springfield_VT', label: 'North Springfield, VT' },
  { value: 'North Springfield_PA', label: 'North Springfield, PA' },
  { value: 'North Star_OH', label: 'North Star, OH' },
  { value: 'North Star_MI', label: 'North Star, MI' },
  { value: 'North Stonington_CT', label: 'North Stonington, CT' },
  { value: 'North Stratford_NH', label: 'North Stratford, NH' },
  { value: 'North Street_MI', label: 'North Street, MI' },
  { value: 'North Sutton_NH', label: 'North Sutton, NH' },
  { value: 'North Tazewell_VA', label: 'North Tazewell, VA' },
  { value: 'North Tonawanda_NY', label: 'North Tonawanda, NY' },
  { value: 'North Troy_VT', label: 'North Troy, VT' },
  { value: 'North Truro_MA', label: 'North Truro, MA' },
  { value: 'North Vernon_IN', label: 'North Vernon, IN' },
  { value: 'North Versailles_PA', label: 'North Versailles, PA' },
  { value: 'North Wales_PA', label: 'North Wales, PA' },
  { value: 'North Walpole_NH', label: 'North Walpole, NH' },
  { value: 'North Washington_PA', label: 'North Washington, PA' },
  { value: 'North Waterboro_ME', label: 'North Waterboro, ME' },
  { value: 'North Waterford_ME', label: 'North Waterford, ME' },
  { value: 'North Webster_IN', label: 'North Webster, IN' },
  { value: 'North Weymouth_MA', label: 'North Weymouth, MA' },
  { value: 'North Wilkesboro_NC', label: 'North Wilkesboro, NC' },
  { value: 'North Windham_CT', label: 'North Windham, CT' },
  { value: 'North Woodstock_NH', label: 'North Woodstock, NH' },
  { value: 'North Yarmouth_ME', label: 'North Yarmouth, ME' },
  { value: 'North Zulch_TX', label: 'North Zulch, TX' },
  { value: 'Northampton_MA', label: 'Northampton, MA' },
  { value: 'Northampton_PA', label: 'Northampton, PA' },
  { value: 'Northboro_IA', label: 'Northboro, IA' },
  { value: 'Northborough_MA', label: 'Northborough, MA' },
  { value: 'Northbridge_MA', label: 'Northbridge, MA' },
  { value: 'Northbrook_IL', label: 'Northbrook, IL' },
  { value: 'Northeast Harbor_ME', label: 'Northeast Harbor, ME' },
  { value: 'Northern Cambria_PA', label: 'Northern Cambria, PA' },
  { value: 'Northfield_MA', label: 'Northfield, MA' },
  { value: 'Northfield_VT', label: 'Northfield, VT' },
  { value: 'Northfield_CT', label: 'Northfield, CT' },
  { value: 'Northfield_NJ', label: 'Northfield, NJ' },
  { value: 'Northfield_OH', label: 'Northfield, OH' },
  { value: 'Northfield_MN', label: 'Northfield, MN' },
  { value: 'Northfield Falls_VT', label: 'Northfield Falls, VT' },
  { value: 'Northford_CT', label: 'Northford, CT' },
  { value: 'Northfork_WV', label: 'Northfork, WV' },
  { value: 'Northome_MN', label: 'Northome, MN' },
  { value: 'Northpoint_PA', label: 'Northpoint, PA' },
  { value: 'Northport_NY', label: 'Northport, NY' },
  { value: 'Northport_AL', label: 'Northport, AL' },
  { value: 'Northport_MI', label: 'Northport, MI' },
  { value: 'Northport_WA', label: 'Northport, WA' },
  { value: 'Northridge_CA', label: 'Northridge, CA' },
  { value: 'Northrop_MN', label: 'Northrop, MN' },
  { value: 'Northumberland_PA', label: 'Northumberland, PA' },
  { value: 'Northvale_NJ', label: 'Northvale, NJ' },
  { value: 'Northville_NY', label: 'Northville, NY' },
  { value: 'Northville_MI', label: 'Northville, MI' },
  { value: 'Northville_SD', label: 'Northville, SD' },
  { value: 'Northway_AK', label: 'Northway, AK' },
  { value: 'Northwood_NH', label: 'Northwood, NH' },
  { value: 'Northwood_OH', label: 'Northwood, OH' },
  { value: 'Northwood_IA', label: 'Northwood, IA' },
  { value: 'Northwood_ND', label: 'Northwood, ND' },
  { value: 'Norton_MA', label: 'Norton, MA' },
  { value: 'Norton_VT', label: 'Norton, VT' },
  { value: 'Norton_VA', label: 'Norton, VA' },
  { value: 'Norton_WV', label: 'Norton, WV' },
  { value: 'Norton_KS', label: 'Norton, KS' },
  { value: 'Norton_TX', label: 'Norton, TX' },
  { value: 'Nortonville_KY', label: 'Nortonville, KY' },
  { value: 'Nortonville_KS', label: 'Nortonville, KS' },
  { value: 'Norvell_MI', label: 'Norvell, MI' },
  { value: 'Norvelt_PA', label: 'Norvelt, PA' },
  { value: 'Norwalk_CT', label: 'Norwalk, CT' },
  { value: 'Norwalk_OH', label: 'Norwalk, OH' },
  { value: 'Norwalk_IA', label: 'Norwalk, IA' },
  { value: 'Norwalk_WI', label: 'Norwalk, WI' },
  { value: 'Norwalk_CA', label: 'Norwalk, CA' },
  { value: 'Norway_ME', label: 'Norway, ME' },
  { value: 'Norway_SC', label: 'Norway, SC' },
  { value: 'Norway_MI', label: 'Norway, MI' },
  { value: 'Norway_IA', label: 'Norway, IA' },
  { value: 'Norway_KS', label: 'Norway, KS' },
  { value: 'Norwell_MA', label: 'Norwell, MA' },
  { value: 'Norwich_VT', label: 'Norwich, VT' },
  { value: 'Norwich_CT', label: 'Norwich, CT' },
  { value: 'Norwich_NY', label: 'Norwich, NY' },
  { value: 'Norwich_OH', label: 'Norwich, OH' },
  { value: 'Norwich_ND', label: 'Norwich, ND' },
  { value: 'Norwich_KS', label: 'Norwich, KS' },
  { value: 'Norwood_MA', label: 'Norwood, MA' },
  { value: 'Norwood_NJ', label: 'Norwood, NJ' },
  { value: 'Norwood_NY', label: 'Norwood, NY' },
  { value: 'Norwood_PA', label: 'Norwood, PA' },
  { value: 'Norwood_VA', label: 'Norwood, VA' },
  { value: 'Norwood_NC', label: 'Norwood, NC' },
  { value: 'Norwood_GA', label: 'Norwood, GA' },
  { value: 'Norwood_MO', label: 'Norwood, MO' },
  { value: 'Norwood_LA', label: 'Norwood, LA' },
  { value: 'Norwood_CO', label: 'Norwood, CO' },
  { value: 'Norwood Young America_MN', label: 'Norwood Young America, MN' },
  { value: 'Notasulga_AL', label: 'Notasulga, AL' },
  { value: 'Noti_OR', label: 'Noti, OR' },
  { value: 'Notre Dame_IN', label: 'Notre Dame, IN' },
  { value: 'Notrees_TX', label: 'Notrees, TX' },
  { value: 'Nottawa_MI', label: 'Nottawa, MI' },
  { value: 'Nottingham_NH', label: 'Nottingham, NH' },
  { value: 'Nottingham_PA', label: 'Nottingham, PA' },
  { value: 'Nottingham_MD', label: 'Nottingham, MD' },
  { value: 'Nottoway_VA', label: 'Nottoway, VA' },
  { value: 'Notus_ID', label: 'Notus, ID' },
  { value: 'Nova_OH', label: 'Nova, OH' },
  { value: 'Novato_CA', label: 'Novato, CA' },
  { value: 'Novelty_OH', label: 'Novelty, OH' },
  { value: 'Novelty_MO', label: 'Novelty, MO' },
  { value: 'Novi_MI', label: 'Novi, MI' },
  { value: 'Novice_TX', label: 'Novice, TX' },
  { value: 'Novinger_MO', label: 'Novinger, MO' },
  { value: 'Nowata_OK', label: 'Nowata, OK' },
  { value: 'Noxapater_MS', label: 'Noxapater, MS' },
  { value: 'Noxen_PA', label: 'Noxen, PA' },
  { value: 'Noxon_MT', label: 'Noxon, MT' },
  { value: 'Nu Mine_PA', label: 'Nu Mine, PA' },
  { value: 'Nubieber_CA', label: 'Nubieber, CA' },
  { value: 'Nucla_CO', label: 'Nucla, CO' },
  { value: 'Nuevo_CA', label: 'Nuevo, CA' },
  { value: 'Nuiqsut_AK', label: 'Nuiqsut, AK' },
  { value: 'Nulato_AK', label: 'Nulato, AK' },
  { value: 'Numidia_PA', label: 'Numidia, PA' },
  { value: 'Nunam Iqua_AK', label: 'Nunam Iqua, AK' },
  { value: 'Nunapitchuk_AK', label: 'Nunapitchuk, AK' },
  { value: 'Nunda_NY', label: 'Nunda, NY' },
  { value: 'Nunda_SD', label: 'Nunda, SD' },
  { value: 'Nunez_GA', label: 'Nunez, GA' },
  { value: 'Nunica_MI', label: 'Nunica, MI' },
  { value: 'Nunn_CO', label: 'Nunn, CO' },
  { value: 'Nunnelly_TN', label: 'Nunnelly, TN' },
  { value: 'Nuremberg_PA', label: 'Nuremberg, PA' },
  { value: 'Nursery_TX', label: 'Nursery, TX' },
  { value: 'Nutley_NJ', label: 'Nutley, NJ' },
  { value: 'Nutrioso_AZ', label: 'Nutrioso, AZ' },
  { value: 'Nyack_NY', label: 'Nyack, NY' },
  { value: 'Nye_MT', label: 'Nye, MT' },
  { value: 'Nyssa_OR', label: 'Nyssa, OR' },
  { value: "O'Brien_FL", label: "O'Brien, FL" },
  { value: "O'Brien_TX", label: "O'Brien, TX" },
  { value: "O'Brien_OR", label: "O'Brien, OR" },
  { value: "O'Fallon_IL", label: "O'Fallon, IL" },
  { value: "O'Fallon_MO", label: "O'Fallon, MO" },
  { value: "O'Kean_AR", label: "O'Kean, AR" },
  { value: "O'Neals_CA", label: "O'Neals, CA" },
  { value: 'Oacoma_SD', label: 'Oacoma, SD' },
  { value: 'Oak_NE', label: 'Oak, NE' },
  { value: 'Oak Bluffs_MA', label: 'Oak Bluffs, MA' },
  { value: 'Oak Brook_IL', label: 'Oak Brook, IL' },
  { value: 'Oak City_NC', label: 'Oak City, NC' },
  { value: 'Oak City_UT', label: 'Oak City, UT' },
  { value: 'Oak Creek_WI', label: 'Oak Creek, WI' },
  { value: 'Oak Creek_CO', label: 'Oak Creek, CO' },
  { value: 'Oak Forest_IL', label: 'Oak Forest, IL' },
  { value: 'Oak Grove_KY', label: 'Oak Grove, KY' },
  { value: 'Oak Grove_MO', label: 'Oak Grove, MO' },
  { value: 'Oak Grove_LA', label: 'Oak Grove, LA' },
  { value: 'Oak Grove_AR', label: 'Oak Grove, AR' },
  { value: 'Oak Hall_VA', label: 'Oak Hall, VA' },
  { value: 'Oak Harbor_OH', label: 'Oak Harbor, OH' },
  { value: 'Oak Harbor_WA', label: 'Oak Harbor, WA' },
  { value: 'Oak Hill_NY', label: 'Oak Hill, NY' },
  { value: 'Oak Hill_WV', label: 'Oak Hill, WV' },
  { value: 'Oak Hill_FL', label: 'Oak Hill, FL' },
  { value: 'Oak Hill_AL', label: 'Oak Hill, AL' },
  { value: 'Oak Hill_OH', label: 'Oak Hill, OH' },
  { value: 'Oak Island_NC', label: 'Oak Island, NC' },
  { value: 'Oak Lawn_IL', label: 'Oak Lawn, IL' },
  { value: 'Oak Park_MI', label: 'Oak Park, MI' },
  { value: 'Oak Park_MN', label: 'Oak Park, MN' },
  { value: 'Oak Park_IL', label: 'Oak Park, IL' },
  { value: 'Oak Park_CA', label: 'Oak Park, CA' },
  { value: 'Oak Ridge_NJ', label: 'Oak Ridge, NJ' },
  { value: 'Oak Ridge_PA', label: 'Oak Ridge, PA' },
  { value: 'Oak Ridge_NC', label: 'Oak Ridge, NC' },
  { value: 'Oak Ridge_TN', label: 'Oak Ridge, TN' },
  { value: 'Oak Ridge_MO', label: 'Oak Ridge, MO' },
  { value: 'Oak Ridge_LA', label: 'Oak Ridge, LA' },
  { value: 'Oak Run_CA', label: 'Oak Run, CA' },
  { value: 'Oak Vale_MS', label: 'Oak Vale, MS' },
  { value: 'Oak View_CA', label: 'Oak View, CA' },
  { value: 'Oakboro_NC', label: 'Oakboro, NC' },
  { value: 'Oakdale_CT', label: 'Oakdale, CT' },
  { value: 'Oakdale_NY', label: 'Oakdale, NY' },
  { value: 'Oakdale_PA', label: 'Oakdale, PA' },
  { value: 'Oakdale_TN', label: 'Oakdale, TN' },
  { value: 'Oakdale_IL', label: 'Oakdale, IL' },
  { value: 'Oakdale_NE', label: 'Oakdale, NE' },
  { value: 'Oakdale_LA', label: 'Oakdale, LA' },
  { value: 'Oakdale_CA', label: 'Oakdale, CA' },
  { value: 'Oakes_ND', label: 'Oakes, ND' },
  { value: 'Oakesdale_WA', label: 'Oakesdale, WA' },
  { value: 'Oakfield_ME', label: 'Oakfield, ME' },
  { value: 'Oakfield_NY', label: 'Oakfield, NY' },
  { value: 'Oakfield_GA', label: 'Oakfield, GA' },
  { value: 'Oakfield_TN', label: 'Oakfield, TN' },
  { value: 'Oakfield_WI', label: 'Oakfield, WI' },
  { value: 'Oakford_IN', label: 'Oakford, IN' },
  { value: 'Oakford_IL', label: 'Oakford, IL' },
  { value: 'Oakham_MA', label: 'Oakham, MA' },
  { value: 'Oakhurst_NJ', label: 'Oakhurst, NJ' },
  { value: 'Oakhurst_OK', label: 'Oakhurst, OK' },
  { value: 'Oakhurst_TX', label: 'Oakhurst, TX' },
  { value: 'Oakhurst_CA', label: 'Oakhurst, CA' },
  { value: 'Oakland_RI', label: 'Oakland, RI' },
  { value: 'Oakland_ME', label: 'Oakland, ME' },
  { value: 'Oakland_NJ', label: 'Oakland, NJ' },
  { value: 'Oakland_MD', label: 'Oakland, MD' },
  { value: 'Oakland_FL', label: 'Oakland, FL' },
  { value: 'Oakland_TN', label: 'Oakland, TN' },
  { value: 'Oakland_MS', label: 'Oakland, MS' },
  { value: 'Oakland_KY', label: 'Oakland, KY' },
  { value: 'Oakland_MI', label: 'Oakland, MI' },
  { value: 'Oakland_IA', label: 'Oakland, IA' },
  { value: 'Oakland_IL', label: 'Oakland, IL' },
  { value: 'Oakland_NE', label: 'Oakland, NE' },
  { value: 'Oakland_AR', label: 'Oakland, AR' },
  { value: 'Oakland_TX', label: 'Oakland, TX' },
  { value: 'Oakland_CA', label: 'Oakland, CA' },
  { value: 'Oakland_OR', label: 'Oakland, OR' },
  { value: 'Oakland City_IN', label: 'Oakland City, IN' },
  { value: 'Oakland Gardens_NY', label: 'Oakland Gardens, NY' },
  { value: 'Oakland Mills_PA', label: 'Oakland Mills, PA' },
  { value: 'Oakley_MI', label: 'Oakley, MI' },
  { value: 'Oakley_KS', label: 'Oakley, KS' },
  { value: 'Oakley_ID', label: 'Oakley, ID' },
  { value: 'Oakley_UT', label: 'Oakley, UT' },
  { value: 'Oakley_CA', label: 'Oakley, CA' },
  { value: 'Oaklyn_NJ', label: 'Oaklyn, NJ' },
  { value: 'Oakman_AL', label: 'Oakman, AL' },
  { value: 'Oakmont_PA', label: 'Oakmont, PA' },
  { value: 'Oakpark_VA', label: 'Oakpark, VA' },
  { value: 'Oakridge_OR', label: 'Oakridge, OR' },
  { value: 'Oaks_PA', label: 'Oaks, PA' },
  { value: 'Oaks_OK', label: 'Oaks, OK' },
  { value: 'Oakton_VA', label: 'Oakton, VA' },
  { value: 'Oaktown_IN', label: 'Oaktown, IN' },
  { value: 'Oakville_CT', label: 'Oakville, CT' },
  { value: 'Oakville_IN', label: 'Oakville, IN' },
  { value: 'Oakville_IA', label: 'Oakville, IA' },
  { value: 'Oakville_CA', label: 'Oakville, CA' },
  { value: 'Oakville_WA', label: 'Oakville, WA' },
  { value: 'Oakwood_VA', label: 'Oakwood, VA' },
  { value: 'Oakwood_GA', label: 'Oakwood, GA' },
  { value: 'Oakwood_OH', label: 'Oakwood, OH' },
  { value: 'Oakwood_IL', label: 'Oakwood, IL' },
  { value: 'Oakwood_OK', label: 'Oakwood, OK' },
  { value: 'Oakwood_TX', label: 'Oakwood, TX' },
  { value: 'Oark_AR', label: 'Oark, AR' },
  { value: 'Oatman_AZ', label: 'Oatman, AZ' },
  { value: 'Oberlin_OH', label: 'Oberlin, OH' },
  { value: 'Oberlin_KS', label: 'Oberlin, KS' },
  { value: 'Oberlin_LA', label: 'Oberlin, LA' },
  { value: 'Obernburg_NY', label: 'Obernburg, NY' },
  { value: 'Oberon_ND', label: 'Oberon, ND' },
  { value: 'Obion_TN', label: 'Obion, TN' },
  { value: 'Oblong_IL', label: 'Oblong, IL' },
  { value: 'Ocala_FL', label: 'Ocala, FL' },
  { value: 'Ocate_NM', label: 'Ocate, NM' },
  { value: 'Occidental_CA', label: 'Occidental, CA' },
  { value: 'Occoquan_VA', label: 'Occoquan, VA' },
  { value: 'Ocean Beach_NY', label: 'Ocean Beach, NY' },
  { value: 'Ocean City_NJ', label: 'Ocean City, NJ' },
  { value: 'Ocean City_MD', label: 'Ocean City, MD' },
  { value: 'Ocean Gate_NJ', label: 'Ocean Gate, NJ' },
  { value: 'Ocean Grove_NJ', label: 'Ocean Grove, NJ' },
  { value: 'Ocean Isle Beach_NC', label: 'Ocean Isle Beach, NC' },
  { value: 'Ocean Park_ME', label: 'Ocean Park, ME' },
  { value: 'Ocean Park_WA', label: 'Ocean Park, WA' },
  { value: 'Ocean Shores_WA', label: 'Ocean Shores, WA' },
  { value: 'Ocean Springs_MS', label: 'Ocean Springs, MS' },
  { value: 'Ocean View_NJ', label: 'Ocean View, NJ' },
  { value: 'Ocean View_DE', label: 'Ocean View, DE' },
  { value: 'Ocean View_HI', label: 'Ocean View, HI' },
  { value: 'Oceana_WV', label: 'Oceana, WV' },
  { value: 'Oceano_CA', label: 'Oceano, CA' },
  { value: 'Oceanport_NJ', label: 'Oceanport, NJ' },
  { value: 'Oceanside_NY', label: 'Oceanside, NY' },
  { value: 'Oceanside_CA', label: 'Oceanside, CA' },
  { value: 'Oceanside_OR', label: 'Oceanside, OR' },
  { value: 'Ochelata_OK', label: 'Ochelata, OK' },
  { value: 'Ocheyedan_IA', label: 'Ocheyedan, IA' },
  { value: 'Ochlocknee_GA', label: 'Ochlocknee, GA' },
  { value: 'Ochopee_FL', label: 'Ochopee, FL' },
  { value: 'Ocilla_GA', label: 'Ocilla, GA' },
  { value: 'Ocklawaha_FL', label: 'Ocklawaha, FL' },
  { value: 'Ocoee_FL', label: 'Ocoee, FL' },
  { value: 'Ocoee_TN', label: 'Ocoee, TN' },
  { value: 'Oconee_GA', label: 'Oconee, GA' },
  { value: 'Oconee_IL', label: 'Oconee, IL' },
  { value: 'Oconomowoc_WI', label: 'Oconomowoc, WI' },
  { value: 'Oconto_WI', label: 'Oconto, WI' },
  { value: 'Oconto_NE', label: 'Oconto, NE' },
  { value: 'Oconto Falls_WI', label: 'Oconto Falls, WI' },
  { value: 'Ocotillo_CA', label: 'Ocotillo, CA' },
  { value: 'Ocracoke_NC', label: 'Ocracoke, NC' },
  { value: 'Odanah_WI', label: 'Odanah, WI' },
  { value: 'Odd_WV', label: 'Odd, WV' },
  { value: 'Odebolt_IA', label: 'Odebolt, IA' },
  { value: 'Odell_IL', label: 'Odell, IL' },
  { value: 'Odell_NE', label: 'Odell, NE' },
  { value: 'Odell_TX', label: 'Odell, TX' },
  { value: 'Odem_TX', label: 'Odem, TX' },
  { value: 'Oden_MI', label: 'Oden, MI' },
  { value: 'Oden_AR', label: 'Oden, AR' },
  { value: 'Odenton_MD', label: 'Odenton, MD' },
  { value: 'Odenville_AL', label: 'Odenville, AL' },
  { value: 'Odessa_NY', label: 'Odessa, NY' },
  { value: 'Odessa_DE', label: 'Odessa, DE' },
  { value: 'Odessa_FL', label: 'Odessa, FL' },
  { value: 'Odessa_MN', label: 'Odessa, MN' },
  { value: 'Odessa_MO', label: 'Odessa, MO' },
  { value: 'Odessa_NE', label: 'Odessa, NE' },
  { value: 'Odessa_TX', label: 'Odessa, TX' },
  { value: 'Odessa_WA', label: 'Odessa, WA' },
  { value: 'Odin_MN', label: 'Odin, MN' },
  { value: 'Odin_IL', label: 'Odin, IL' },
  { value: 'Odon_IN', label: 'Odon, IN' },
  { value: 'Odonnell_TX', label: 'Odonnell, TX' },
  { value: 'Odum_GA', label: 'Odum, GA' },
  { value: 'Oelrichs_SD', label: 'Oelrichs, SD' },
  { value: 'Oelwein_IA', label: 'Oelwein, IA' },
  { value: 'Offerle_KS', label: 'Offerle, KS' },
  { value: 'Offerman_GA', label: 'Offerman, GA' },
  { value: 'Offutt Afb_NE', label: 'Offutt Afb, NE' },
  { value: 'Ogallah_KS', label: 'Ogallah, KS' },
  { value: 'Ogallala_NE', label: 'Ogallala, NE' },
  { value: 'Ogden_IA', label: 'Ogden, IA' },
  { value: 'Ogden_IL', label: 'Ogden, IL' },
  { value: 'Ogden_KS', label: 'Ogden, KS' },
  { value: 'Ogden_AR', label: 'Ogden, AR' },
  { value: 'Ogden_UT', label: 'Ogden, UT' },
  { value: 'Ogdensburg_NJ', label: 'Ogdensburg, NJ' },
  { value: 'Ogdensburg_NY', label: 'Ogdensburg, NY' },
  { value: 'Ogdensburg_WI', label: 'Ogdensburg, WI' },
  { value: 'Ogema_WI', label: 'Ogema, WI' },
  { value: 'Ogema_MN', label: 'Ogema, MN' },
  { value: 'Ogilvie_MN', label: 'Ogilvie, MN' },
  { value: 'Oglala_SD', label: 'Oglala, SD' },
  { value: 'Oglesby_IL', label: 'Oglesby, IL' },
  { value: 'Oglesby_TX', label: 'Oglesby, TX' },
  { value: 'Oglethorpe_GA', label: 'Oglethorpe, GA' },
  { value: 'Ogunquit_ME', label: 'Ogunquit, ME' },
  { value: 'Ohatchee_AL', label: 'Ohatchee, AL' },
  { value: 'Ohio_IL', label: 'Ohio, IL' },
  { value: 'Ohio City_OH', label: 'Ohio City, OH' },
  { value: 'Ohio City_CO', label: 'Ohio City, CO' },
  { value: 'Ohiopyle_PA', label: 'Ohiopyle, PA' },
  { value: 'Ohiowa_NE', label: 'Ohiowa, NE' },
  { value: 'Ohkay Owingeh_NM', label: 'Ohkay Owingeh, NM' },
  { value: 'Ohlman_IL', label: 'Ohlman, IL' },
  { value: 'Oil City_PA', label: 'Oil City, PA' },
  { value: 'Oil City_LA', label: 'Oil City, LA' },
  { value: 'Oil Springs_KY', label: 'Oil Springs, KY' },
  { value: 'Oil Trough_AR', label: 'Oil Trough, AR' },
  { value: 'Oilmont_MT', label: 'Oilmont, MT' },
  { value: 'Oilton_OK', label: 'Oilton, OK' },
  { value: 'Oilton_TX', label: 'Oilton, TX' },
  { value: 'Oilville_VA', label: 'Oilville, VA' },
  { value: 'Ojai_CA', label: 'Ojai, CA' },
  { value: 'Ojibwa_WI', label: 'Ojibwa, WI' },
  { value: 'Ojo Caliente_NM', label: 'Ojo Caliente, NM' },
  { value: 'Ojo Feliz_NM', label: 'Ojo Feliz, NM' },
  { value: 'Okabena_MN', label: 'Okabena, MN' },
  { value: 'Okanogan_WA', label: 'Okanogan, WA' },
  { value: 'Okarche_OK', label: 'Okarche, OK' },
  { value: 'Okatie_SC', label: 'Okatie, SC' },
  { value: 'Okaton_SD', label: 'Okaton, SD' },
  { value: 'Okauchee_WI', label: 'Okauchee, WI' },
  { value: 'Okawville_IL', label: 'Okawville, IL' },
  { value: 'Okay_OK', label: 'Okay, OK' },
  { value: 'Okeana_OH', label: 'Okeana, OH' },
  { value: 'Okeechobee_FL', label: 'Okeechobee, FL' },
  { value: 'Okeene_OK', label: 'Okeene, OK' },
  { value: 'Okemah_OK', label: 'Okemah, OK' },
  { value: 'Okemos_MI', label: 'Okemos, MI' },
  { value: 'Oketo_KS', label: 'Oketo, KS' },
  { value: 'Oklahoma City_OK', label: 'Oklahoma City, OK' },
  { value: 'Oklaunion_TX', label: 'Oklaunion, TX' },
  { value: 'Oklee_MN', label: 'Oklee, MN' },
  { value: 'Okmulgee_OK', label: 'Okmulgee, OK' },
  { value: 'Okoboji_IA', label: 'Okoboji, IA' },
  { value: 'Okolona_MS', label: 'Okolona, MS' },
  { value: 'Okolona_AR', label: 'Okolona, AR' },
  { value: 'Okreek_SD', label: 'Okreek, SD' },
  { value: 'Oktaha_OK', label: 'Oktaha, OK' },
  { value: 'Ola_AR', label: 'Ola, AR' },
  { value: 'Ola_ID', label: 'Ola, ID' },
  { value: 'Olalla_WA', label: 'Olalla, WA' },
  { value: 'Olancha_CA', label: 'Olancha, CA' },
  { value: 'Olanta_PA', label: 'Olanta, PA' },
  { value: 'Olanta_SC', label: 'Olanta, SC' },
  { value: 'Olar_SC', label: 'Olar, SC' },
  { value: 'Olathe_KS', label: 'Olathe, KS' },
  { value: 'Olathe_CO', label: 'Olathe, CO' },
  { value: 'Olaton_KY', label: 'Olaton, KY' },
  { value: 'Olcott_NY', label: 'Olcott, NY' },
  { value: 'Old Appleton_MO', label: 'Old Appleton, MO' },
  { value: 'Old Bethpage_NY', label: 'Old Bethpage, NY' },
  { value: 'Old Bridge_NJ', label: 'Old Bridge, NJ' },
  { value: 'Old Chatham_NY', label: 'Old Chatham, NY' },
  { value: 'Old Fields_WV', label: 'Old Fields, WV' },
  { value: 'Old Forge_NY', label: 'Old Forge, NY' },
  { value: 'Old Forge_PA', label: 'Old Forge, PA' },
  { value: 'Old Fort_NC', label: 'Old Fort, NC' },
  { value: 'Old Fort_TN', label: 'Old Fort, TN' },
  { value: 'Old Fort_OH', label: 'Old Fort, OH' },
  { value: 'Old Glory_TX', label: 'Old Glory, TX' },
  { value: 'Old Greenwich_CT', label: 'Old Greenwich, CT' },
  { value: 'Old Harbor_AK', label: 'Old Harbor, AK' },
  { value: 'Old Hickory_TN', label: 'Old Hickory, TN' },
  { value: 'Old Lyme_CT', label: 'Old Lyme, CT' },
  { value: 'Old Monroe_MO', label: 'Old Monroe, MO' },
  { value: 'Old Orchard Beach_ME', label: 'Old Orchard Beach, ME' },
  { value: 'Old Saybrook_CT', label: 'Old Saybrook, CT' },
  { value: 'Old Station_CA', label: 'Old Station, CA' },
  { value: 'Old Town_ME', label: 'Old Town, ME' },
  { value: 'Old Town_FL', label: 'Old Town, FL' },
  { value: 'Old Washington_OH', label: 'Old Washington, OH' },
  { value: 'Old Westbury_NY', label: 'Old Westbury, NY' },
  { value: 'Olden_TX', label: 'Olden, TX' },
  { value: 'Oldenburg_IN', label: 'Oldenburg, IN' },
  { value: 'Oldfield_MO', label: 'Oldfield, MO' },
  { value: 'Oldham_SD', label: 'Oldham, SD' },
  { value: 'Oldhams_VA', label: 'Oldhams, VA' },
  { value: 'Olds_IA', label: 'Olds, IA' },
  { value: 'Oldsmar_FL', label: 'Oldsmar, FL' },
  { value: 'Oldtown_MD', label: 'Oldtown, MD' },
  { value: 'Oldtown_ID', label: 'Oldtown, ID' },
  { value: 'Oldwick_NJ', label: 'Oldwick, NJ' },
  { value: 'Olean_NY', label: 'Olean, NY' },
  { value: 'Olean_MO', label: 'Olean, MO' },
  { value: 'Olema_CA', label: 'Olema, CA' },
  { value: 'Oley_PA', label: 'Oley, PA' },
  { value: 'Olga_WA', label: 'Olga, WA' },
  { value: 'Olin_NC', label: 'Olin, NC' },
  { value: 'Olin_IA', label: 'Olin, IA' },
  { value: 'Olive_MT', label: 'Olive, MT' },
  { value: 'Olive Branch_MS', label: 'Olive Branch, MS' },
  { value: 'Olive Branch_IL', label: 'Olive Branch, IL' },
  { value: 'Olive Hill_KY', label: 'Olive Hill, KY' },
  { value: 'Olivebridge_NY', label: 'Olivebridge, NY' },
  { value: 'Oliveburg_PA', label: 'Oliveburg, PA' },
  { value: 'Olivehill_TN', label: 'Olivehill, TN' },
  { value: 'Olivehurst_CA', label: 'Olivehurst, CA' },
  { value: 'Oliver_PA', label: 'Oliver, PA' },
  { value: 'Oliver_GA', label: 'Oliver, GA' },
  { value: 'Oliver Springs_TN', label: 'Oliver Springs, TN' },
  { value: 'Olivet_MI', label: 'Olivet, MI' },
  { value: 'Olivet_SD', label: 'Olivet, SD' },
  { value: 'Olivia_NC', label: 'Olivia, NC' },
  { value: 'Olivia_MN', label: 'Olivia, MN' },
  { value: 'Olla_LA', label: 'Olla, LA' },
  { value: 'Ollie_IA', label: 'Ollie, IA' },
  { value: 'Olmito_TX', label: 'Olmito, TX' },
  { value: 'Olmitz_KS', label: 'Olmitz, KS' },
  { value: 'Olmstead_KY', label: 'Olmstead, KY' },
  { value: 'Olmsted_IL', label: 'Olmsted, IL' },
  { value: 'Olmsted Falls_OH', label: 'Olmsted Falls, OH' },
  { value: 'Olmstedville_NY', label: 'Olmstedville, NY' },
  { value: 'Olney_MD', label: 'Olney, MD' },
  { value: 'Olney_MT', label: 'Olney, MT' },
  { value: 'Olney_IL', label: 'Olney, IL' },
  { value: 'Olney_TX', label: 'Olney, TX' },
  { value: 'Olney Springs_CO', label: 'Olney Springs, CO' },
  { value: 'Olpe_KS', label: 'Olpe, KS' },
  { value: 'Olsburg_KS', label: 'Olsburg, KS' },
  { value: 'Olton_TX', label: 'Olton, TX' },
  { value: 'Olustee_OK', label: 'Olustee, OK' },
  { value: 'Olympia_KY', label: 'Olympia, KY' },
  { value: 'Olympia_WA', label: 'Olympia, WA' },
  { value: 'Olympia Fields_IL', label: 'Olympia Fields, IL' },
  { value: 'Olympic Valley_CA', label: 'Olympic Valley, CA' },
  { value: 'Olyphant_PA', label: 'Olyphant, PA' },
  { value: 'Omaha_GA', label: 'Omaha, GA' },
  { value: 'Omaha_IL', label: 'Omaha, IL' },
  { value: 'Omaha_NE', label: 'Omaha, NE' },
  { value: 'Omaha_AR', label: 'Omaha, AR' },
  { value: 'Omaha_TX', label: 'Omaha, TX' },
  { value: 'Omak_WA', label: 'Omak, WA' },
  { value: 'Omar_WV', label: 'Omar, WV' },
  { value: 'Omega_GA', label: 'Omega, GA' },
  { value: 'Omega_OK', label: 'Omega, OK' },
  { value: 'Omena_MI', label: 'Omena, MI' },
  { value: 'Omer_MI', label: 'Omer, MI' },
  { value: 'Omro_WI', label: 'Omro, WI' },
  { value: 'Ona_WV', label: 'Ona, WV' },
  { value: 'Ona_FL', label: 'Ona, FL' },
  { value: 'Onaga_KS', label: 'Onaga, KS' },
  { value: 'Onaka_SD', label: 'Onaka, SD' },
  { value: 'Onalaska_WI', label: 'Onalaska, WI' },
  { value: 'Onalaska_TX', label: 'Onalaska, TX' },
  { value: 'Onalaska_WA', label: 'Onalaska, WA' },
  { value: 'Onamia_MN', label: 'Onamia, MN' },
  { value: 'Onancock_VA', label: 'Onancock, VA' },
  { value: 'Onarga_IL', label: 'Onarga, IL' },
  { value: 'Onawa_IA', label: 'Onawa, IA' },
  { value: 'Onaway_MI', label: 'Onaway, MI' },
  { value: 'Oneco_CT', label: 'Oneco, CT' },
  { value: 'Onego_WV', label: 'Onego, WV' },
  { value: 'Oneida_NY', label: 'Oneida, NY' },
  { value: 'Oneida_PA', label: 'Oneida, PA' },
  { value: 'Oneida_TN', label: 'Oneida, TN' },
  { value: 'Oneida_KY', label: 'Oneida, KY' },
  { value: 'Oneida_WI', label: 'Oneida, WI' },
  { value: 'Oneida_IL', label: 'Oneida, IL' },
  { value: 'Oneida_KS', label: 'Oneida, KS' },
  { value: 'Oneida_AR', label: 'Oneida, AR' },
  { value: 'Oneill_NE', label: 'Oneill, NE' },
  { value: 'Onekama_MI', label: 'Onekama, MI' },
  { value: 'Onemo_VA', label: 'Onemo, VA' },
  { value: 'Oneonta_NY', label: 'Oneonta, NY' },
  { value: 'Oneonta_AL', label: 'Oneonta, AL' },
  { value: 'Ong_NE', label: 'Ong, NE' },
  { value: 'Onia_AR', label: 'Onia, AR' },
  { value: 'Onida_SD', label: 'Onida, SD' },
  { value: 'Onley_VA', label: 'Onley, VA' },
  { value: 'Only_TN', label: 'Only, TN' },
  { value: 'Ono_PA', label: 'Ono, PA' },
  { value: 'Onondaga_MI', label: 'Onondaga, MI' },
  { value: 'Onset_MA', label: 'Onset, MA' },
  { value: 'Onslow_IA', label: 'Onslow, IA' },
  { value: 'Onsted_MI', label: 'Onsted, MI' },
  { value: 'Ontario_NY', label: 'Ontario, NY' },
  { value: 'Ontario_WI', label: 'Ontario, WI' },
  { value: 'Ontario_CA', label: 'Ontario, CA' },
  { value: 'Ontario_OR', label: 'Ontario, OR' },
  { value: 'Ontonagon_MI', label: 'Ontonagon, MI' },
  { value: 'Onward_IN', label: 'Onward, IN' },
  { value: 'Onyx_CA', label: 'Onyx, CA' },
  { value: 'Ookala_HI', label: 'Ookala, HI' },
  { value: 'Oolitic_IN', label: 'Oolitic, IN' },
  { value: 'Oologah_OK', label: 'Oologah, OK' },
  { value: 'Ooltewah_TN', label: 'Ooltewah, TN' },
  { value: 'Oostburg_WI', label: 'Oostburg, WI' },
  { value: 'Opa Locka_FL', label: 'Opa Locka, FL' },
  { value: 'Opal_WY', label: 'Opal, WY' },
  { value: 'Opdyke_IL', label: 'Opdyke, IL' },
  { value: 'Opelika_AL', label: 'Opelika, AL' },
  { value: 'Opelousas_LA', label: 'Opelousas, LA' },
  { value: 'Opheim_MT', label: 'Opheim, MT' },
  { value: 'Ophiem_IL', label: 'Ophiem, IL' },
  { value: 'Ophir_CO', label: 'Ophir, CO' },
  { value: 'Opolis_KS', label: 'Opolis, KS' },
  { value: 'Opp_AL', label: 'Opp, AL' },
  { value: 'Oquawka_IL', label: 'Oquawka, IL' },
  { value: 'Oquossoc_ME', label: 'Oquossoc, ME' },
  { value: 'Ora_IN', label: 'Ora, IN' },
  { value: 'Oracle_AZ', label: 'Oracle, AZ' },
  { value: 'Oradell_NJ', label: 'Oradell, NJ' },
  { value: 'Oral_SD', label: 'Oral, SD' },
  { value: 'Oran_IA', label: 'Oran, IA' },
  { value: 'Oran_MO', label: 'Oran, MO' },
  { value: 'Orange_MA', label: 'Orange, MA' },
  { value: 'Orange_CT', label: 'Orange, CT' },
  { value: 'Orange_NJ', label: 'Orange, NJ' },
  { value: 'Orange_VA', label: 'Orange, VA' },
  { value: 'Orange_TX', label: 'Orange, TX' },
  { value: 'Orange_CA', label: 'Orange, CA' },
  { value: 'Orange Beach_AL', label: 'Orange Beach, AL' },
  { value: 'Orange City_FL', label: 'Orange City, FL' },
  { value: 'Orange City_IA', label: 'Orange City, IA' },
  { value: 'Orange Cove_CA', label: 'Orange Cove, CA' },
  { value: 'Orange Grove_TX', label: 'Orange Grove, TX' },
  { value: 'Orange Lake_FL', label: 'Orange Lake, FL' },
  { value: 'Orange Park_FL', label: 'Orange Park, FL' },
  { value: 'Orange Springs_FL', label: 'Orange Springs, FL' },
  { value: 'Orangeburg_NY', label: 'Orangeburg, NY' },
  { value: 'Orangeburg_SC', label: 'Orangeburg, SC' },
  { value: 'Orangevale_CA', label: 'Orangevale, CA' },
  { value: 'Orangeville_PA', label: 'Orangeville, PA' },
  { value: 'Orangeville_IL', label: 'Orangeville, IL' },
  { value: 'Orangeville_UT', label: 'Orangeville, UT' },
  { value: 'Orbisonia_PA', label: 'Orbisonia, PA' },
  { value: 'Orcas_WA', label: 'Orcas, WA' },
  { value: 'Orchard_IA', label: 'Orchard, IA' },
  { value: 'Orchard_NE', label: 'Orchard, NE' },
  { value: 'Orchard_TX', label: 'Orchard, TX' },
  { value: 'Orchard_CO', label: 'Orchard, CO' },
  { value: 'Orchard Park_NY', label: 'Orchard Park, NY' },
  { value: 'Ord_NE', label: 'Ord, NE' },
  { value: 'Orderville_UT', label: 'Orderville, UT' },
  { value: 'Ordway_CO', label: 'Ordway, CO' },
  { value: 'Ore City_TX', label: 'Ore City, TX' },
  { value: 'Oreana_IL', label: 'Oreana, IL' },
  { value: 'Orefield_PA', label: 'Orefield, PA' },
  { value: 'Oregon_OH', label: 'Oregon, OH' },
  { value: 'Oregon_WI', label: 'Oregon, WI' },
  { value: 'Oregon_IL', label: 'Oregon, IL' },
  { value: 'Oregon_MO', label: 'Oregon, MO' },
  { value: 'Oregon City_OR', label: 'Oregon City, OR' },
  { value: 'Oregon House_CA', label: 'Oregon House, CA' },
  { value: 'Oregonia_OH', label: 'Oregonia, OH' },
  { value: 'Oreland_PA', label: 'Oreland, PA' },
  { value: 'Orem_UT', label: 'Orem, UT' },
  { value: 'Orestes_IN', label: 'Orestes, IN' },
  { value: 'Orford_NH', label: 'Orford, NH' },
  { value: 'Orfordville_WI', label: 'Orfordville, WI' },
  { value: 'Organ_NM', label: 'Organ, NM' },
  { value: 'Orgas_WV', label: 'Orgas, WV' },
  { value: 'Orick_CA', label: 'Orick, CA' },
  { value: 'Orient_ME', label: 'Orient, ME' },
  { value: 'Orient_NY', label: 'Orient, NY' },
  { value: 'Orient_OH', label: 'Orient, OH' },
  { value: 'Orient_IA', label: 'Orient, IA' },
  { value: 'Orient_SD', label: 'Orient, SD' },
  { value: 'Orient_IL', label: 'Orient, IL' },
  { value: 'Orient_WA', label: 'Orient, WA' },
  { value: 'Oriental_NC', label: 'Oriental, NC' },
  { value: 'Orinda_CA', label: 'Orinda, CA' },
  { value: 'Orion_IL', label: 'Orion, IL' },
  { value: 'Oriska_ND', label: 'Oriska, ND' },
  { value: 'Oriskany_NY', label: 'Oriskany, NY' },
  { value: 'Oriskany Falls_NY', label: 'Oriskany Falls, NY' },
  { value: 'Orkney Springs_VA', label: 'Orkney Springs, VA' },
  { value: 'Orla_TX', label: 'Orla, TX' },
  { value: 'Orland_ME', label: 'Orland, ME' },
  { value: 'Orland_IN', label: 'Orland, IN' },
  { value: 'Orland_CA', label: 'Orland, CA' },
  { value: 'Orland Park_IL', label: 'Orland Park, IL' },
  { value: 'Orlando_WV', label: 'Orlando, WV' },
  { value: 'Orlando_FL', label: 'Orlando, FL' },
  { value: 'Orlando_KY', label: 'Orlando, KY' },
  { value: 'Orlando_OK', label: 'Orlando, OK' },
  { value: 'Orleans_MA', label: 'Orleans, MA' },
  { value: 'Orleans_VT', label: 'Orleans, VT' },
  { value: 'Orleans_IN', label: 'Orleans, IN' },
  { value: 'Orleans_MI', label: 'Orleans, MI' },
  { value: 'Orleans_NE', label: 'Orleans, NE' },
  { value: 'Orleans_CA', label: 'Orleans, CA' },
  { value: 'Orlinda_TN', label: 'Orlinda, TN' },
  { value: 'Orma_WV', label: 'Orma, WV' },
  { value: 'Ormond Beach_FL', label: 'Ormond Beach, FL' },
  { value: 'Ormsby_MN', label: 'Ormsby, MN' },
  { value: 'Oro Grande_CA', label: 'Oro Grande, CA' },
  { value: 'Orocovis_PR', label: 'Orocovis, PR' },
  { value: 'Orofino_ID', label: 'Orofino, ID' },
  { value: 'Orogrande_NM', label: 'Orogrande, NM' },
  { value: 'Orondo_WA', label: 'Orondo, WA' },
  { value: 'Orono_ME', label: 'Orono, ME' },
  { value: 'Oronoco_MN', label: 'Oronoco, MN' },
  { value: 'Oronogo_MO', label: 'Oronogo, MO' },
  { value: 'Orosi_CA', label: 'Orosi, CA' },
  { value: 'Orovada_NV', label: 'Orovada, NV' },
  { value: 'Oroville_CA', label: 'Oroville, CA' },
  { value: 'Oroville_WA', label: 'Oroville, WA' },
  { value: 'Orr_MN', label: 'Orr, MN' },
  { value: 'Orrick_MO', label: 'Orrick, MO' },
  { value: 'Orrington_ME', label: 'Orrington, ME' },
  { value: 'Orrs Island_ME', label: 'Orrs Island, ME' },
  { value: 'Orrstown_PA', label: 'Orrstown, PA' },
  { value: 'Orrtanna_PA', label: 'Orrtanna, PA' },
  { value: 'Orrum_NC', label: 'Orrum, NC' },
  { value: 'Orrville_AL', label: 'Orrville, AL' },
  { value: 'Orrville_OH', label: 'Orrville, OH' },
  { value: 'Orting_WA', label: 'Orting, WA' },
  { value: 'Ortley_SD', label: 'Ortley, SD' },
  { value: 'Ortonville_MI', label: 'Ortonville, MI' },
  { value: 'Ortonville_MN', label: 'Ortonville, MN' },
  { value: 'Orviston_PA', label: 'Orviston, PA' },
  { value: 'Orwell_VT', label: 'Orwell, VT' },
  { value: 'Orwell_OH', label: 'Orwell, OH' },
  { value: 'Orwigsburg_PA', label: 'Orwigsburg, PA' },
  { value: 'Osage_WV', label: 'Osage, WV' },
  { value: 'Osage_IA', label: 'Osage, IA' },
  { value: 'Osage_MN', label: 'Osage, MN' },
  { value: 'Osage_OK', label: 'Osage, OK' },
  { value: 'Osage_WY', label: 'Osage, WY' },
  { value: 'Osage Beach_MO', label: 'Osage Beach, MO' },
  { value: 'Osage City_KS', label: 'Osage City, KS' },
  { value: 'Osakis_MN', label: 'Osakis, MN' },
  { value: 'Osawatomie_KS', label: 'Osawatomie, KS' },
  { value: 'Osborn_MO', label: 'Osborn, MO' },
  { value: 'Osborne_KS', label: 'Osborne, KS' },
  { value: 'Osburn_ID', label: 'Osburn, ID' },
  { value: 'Oscar_LA', label: 'Oscar, LA' },
  { value: 'Osceola_PA', label: 'Osceola, PA' },
  { value: 'Osceola_IN', label: 'Osceola, IN' },
  { value: 'Osceola_IA', label: 'Osceola, IA' },
  { value: 'Osceola_WI', label: 'Osceola, WI' },
  { value: 'Osceola_MO', label: 'Osceola, MO' },
  { value: 'Osceola_NE', label: 'Osceola, NE' },
  { value: 'Osceola_AR', label: 'Osceola, AR' },
  { value: 'Osceola Mills_PA', label: 'Osceola Mills, PA' },
  { value: 'Osco_IL', label: 'Osco, IL' },
  { value: 'Oscoda_MI', label: 'Oscoda, MI' },
  { value: 'Osgood_OH', label: 'Osgood, OH' },
  { value: 'Osgood_IN', label: 'Osgood, IN' },
  { value: 'Oshkosh_WI', label: 'Oshkosh, WI' },
  { value: 'Oshkosh_NE', label: 'Oshkosh, NE' },
  { value: 'Oskaloosa_IA', label: 'Oskaloosa, IA' },
  { value: 'Oskaloosa_KS', label: 'Oskaloosa, KS' },
  { value: 'Oslo_MN', label: 'Oslo, MN' },
  { value: 'Osmond_NE', label: 'Osmond, NE' },
  { value: 'Osnabrock_ND', label: 'Osnabrock, ND' },
  { value: 'Osprey_FL', label: 'Osprey, FL' },
  { value: 'Osseo_MI', label: 'Osseo, MI' },
  { value: 'Osseo_WI', label: 'Osseo, WI' },
  { value: 'Osseo_MN', label: 'Osseo, MN' },
  { value: 'Ossian_IN', label: 'Ossian, IN' },
  { value: 'Ossian_IA', label: 'Ossian, IA' },
  { value: 'Ossineke_MI', label: 'Ossineke, MI' },
  { value: 'Ossining_NY', label: 'Ossining, NY' },
  { value: 'Ossipee_NH', label: 'Ossipee, NH' },
  { value: 'Osteen_FL', label: 'Osteen, FL' },
  { value: 'Osterburg_PA', label: 'Osterburg, PA' },
  { value: 'Osterville_MA', label: 'Osterville, MA' },
  { value: 'Ostrander_OH', label: 'Ostrander, OH' },
  { value: 'Ostrander_MN', label: 'Ostrander, MN' },
  { value: 'Oswegatchie_NY', label: 'Oswegatchie, NY' },
  { value: 'Oswego_NY', label: 'Oswego, NY' },
  { value: 'Oswego_IL', label: 'Oswego, IL' },
  { value: 'Oswego_KS', label: 'Oswego, KS' },
  { value: 'Osyka_MS', label: 'Osyka, MS' },
  { value: 'Otego_NY', label: 'Otego, NY' },
  { value: 'Othello_WA', label: 'Othello, WA' },
  { value: 'Otho_IA', label: 'Otho, IA' },
  { value: 'Otis_MA', label: 'Otis, MA' },
  { value: 'Otis_KS', label: 'Otis, KS' },
  { value: 'Otis_LA', label: 'Otis, LA' },
  { value: 'Otis_CO', label: 'Otis, CO' },
  { value: 'Otis_OR', label: 'Otis, OR' },
  { value: 'Otis Orchards_WA', label: 'Otis Orchards, WA' },
  { value: 'Otisco_IN', label: 'Otisco, IN' },
  { value: 'Otisville_NY', label: 'Otisville, NY' },
  { value: 'Otisville_MI', label: 'Otisville, MI' },
  { value: 'Otley_IA', label: 'Otley, IA' },
  { value: 'Oto_IA', label: 'Oto, IA' },
  { value: 'Otoe_NE', label: 'Otoe, NE' },
  { value: 'Otsego_MI', label: 'Otsego, MI' },
  { value: 'Ottawa_WV', label: 'Ottawa, WV' },
  { value: 'Ottawa_OH', label: 'Ottawa, OH' },
  { value: 'Ottawa_IL', label: 'Ottawa, IL' },
  { value: 'Ottawa_KS', label: 'Ottawa, KS' },
  { value: 'Ottawa Lake_MI', label: 'Ottawa Lake, MI' },
  { value: 'Otter_MT', label: 'Otter, MT' },
  { value: 'Otter Creek_FL', label: 'Otter Creek, FL' },
  { value: 'Otter Lake_MI', label: 'Otter Lake, MI' },
  { value: 'Otter Rock_OR', label: 'Otter Rock, OR' },
  { value: 'Otterbein_IN', label: 'Otterbein, IN' },
  { value: 'Ottertail_MN', label: 'Ottertail, MN' },
  { value: 'Otterville_MO', label: 'Otterville, MO' },
  { value: 'Ottine_TX', label: 'Ottine, TX' },
  { value: 'Otto_NC', label: 'Otto, NC' },
  { value: 'Otto_WY', label: 'Otto, WY' },
  { value: 'Ottosen_IA', label: 'Ottosen, IA' },
  { value: 'Ottoville_OH', label: 'Ottoville, OH' },
  { value: 'Ottsville_PA', label: 'Ottsville, PA' },
  { value: 'Ottumwa_IA', label: 'Ottumwa, IA' },
  { value: 'Otway_OH', label: 'Otway, OH' },
  { value: 'Otwell_IN', label: 'Otwell, IN' },
  { value: 'Ouaquaga_NY', label: 'Ouaquaga, NY' },
  { value: 'Ouray_CO', label: 'Ouray, CO' },
  { value: 'Outing_MN', label: 'Outing, MN' },
  { value: 'Outlook_MT', label: 'Outlook, MT' },
  { value: 'Outlook_WA', label: 'Outlook, WA' },
  { value: 'Ouzinkie_AK', label: 'Ouzinkie, AK' },
  { value: 'Ovalo_TX', label: 'Ovalo, TX' },
  { value: 'Ovando_MT', label: 'Ovando, MT' },
  { value: 'Overbrook_KS', label: 'Overbrook, KS' },
  { value: 'Overbrook_OK', label: 'Overbrook, OK' },
  { value: 'Overgaard_AZ', label: 'Overgaard, AZ' },
  { value: 'Overland Park_KS', label: 'Overland Park, KS' },
  { value: 'Overpeck_OH', label: 'Overpeck, OH' },
  { value: 'Overton_NE', label: 'Overton, NE' },
  { value: 'Overton_TX', label: 'Overton, TX' },
  { value: 'Overton_NV', label: 'Overton, NV' },
  { value: 'Ovett_MS', label: 'Ovett, MS' },
  { value: 'Ovid_NY', label: 'Ovid, NY' },
  { value: 'Ovid_MI', label: 'Ovid, MI' },
  { value: 'Ovid_CO', label: 'Ovid, CO' },
  { value: 'Oviedo_FL', label: 'Oviedo, FL' },
  { value: 'Owaneco_IL', label: 'Owaneco, IL' },
  { value: 'Owanka_SD', label: 'Owanka, SD' },
  { value: 'Owasso_OK', label: 'Owasso, OK' },
  { value: 'Owatonna_MN', label: 'Owatonna, MN' },
  { value: 'Owego_NY', label: 'Owego, NY' },
  { value: 'Owen_WI', label: 'Owen, WI' },
  { value: 'Owendale_MI', label: 'Owendale, MI' },
  { value: 'Owens Cross Roads_AL', label: 'Owens Cross Roads, AL' },
  { value: 'Owensboro_KY', label: 'Owensboro, KY' },
  { value: 'Owensburg_IN', label: 'Owensburg, IN' },
  { value: 'Owensville_OH', label: 'Owensville, OH' },
  { value: 'Owensville_IN', label: 'Owensville, IN' },
  { value: 'Owensville_MO', label: 'Owensville, MO' },
  { value: 'Owenton_KY', label: 'Owenton, KY' },
  { value: 'Owings_MD', label: 'Owings, MD' },
  { value: 'Owings Mills_MD', label: 'Owings Mills, MD' },
  { value: 'Owingsville_KY', label: 'Owingsville, KY' },
  { value: 'Owls Head_ME', label: 'Owls Head, ME' },
  { value: 'Owls Head_NY', label: 'Owls Head, NY' },
  { value: 'Owosso_MI', label: 'Owosso, MI' },
  { value: 'Owyhee_NV', label: 'Owyhee, NV' },
  { value: 'Oxbow_ME', label: 'Oxbow, ME' },
  { value: 'Oxbow_OR', label: 'Oxbow, OR' },
  { value: 'Oxford_MA', label: 'Oxford, MA' },
  { value: 'Oxford_ME', label: 'Oxford, ME' },
  { value: 'Oxford_CT', label: 'Oxford, CT' },
  { value: 'Oxford_NJ', label: 'Oxford, NJ' },
  { value: 'Oxford_NY', label: 'Oxford, NY' },
  { value: 'Oxford_PA', label: 'Oxford, PA' },
  { value: 'Oxford_MD', label: 'Oxford, MD' },
  { value: 'Oxford_NC', label: 'Oxford, NC' },
  { value: 'Oxford_GA', label: 'Oxford, GA' },
  { value: 'Oxford_FL', label: 'Oxford, FL' },
  { value: 'Oxford_AL', label: 'Oxford, AL' },
  { value: 'Oxford_MS', label: 'Oxford, MS' },
  { value: 'Oxford_OH', label: 'Oxford, OH' },
  { value: 'Oxford_IN', label: 'Oxford, IN' },
  { value: 'Oxford_MI', label: 'Oxford, MI' },
  { value: 'Oxford_IA', label: 'Oxford, IA' },
  { value: 'Oxford_WI', label: 'Oxford, WI' },
  { value: 'Oxford_KS', label: 'Oxford, KS' },
  { value: 'Oxford_NE', label: 'Oxford, NE' },
  { value: 'Oxford_AR', label: 'Oxford, AR' },
  { value: 'Oxford Junction_IA', label: 'Oxford Junction, IA' },
  { value: 'Oxly_MO', label: 'Oxly, MO' },
  { value: 'Oxnard_CA', label: 'Oxnard, CA' },
  { value: 'Oxon Hill_MD', label: 'Oxon Hill, MD' },
  { value: 'Oyens_IA', label: 'Oyens, IA' },
  { value: 'Oyster Bay_NY', label: 'Oyster Bay, NY' },
  { value: 'Oysterville_WA', label: 'Oysterville, WA' },
  { value: 'Ozan_AR', label: 'Ozan, AR' },
  { value: 'Ozark_AL', label: 'Ozark, AL' },
  { value: 'Ozark_IL', label: 'Ozark, IL' },
  { value: 'Ozark_MO', label: 'Ozark, MO' },
  { value: 'Ozark_AR', label: 'Ozark, AR' },
  { value: 'Ozawkie_KS', label: 'Ozawkie, KS' },
  { value: 'Ozona_TX', label: 'Ozona, TX' },
  { value: 'Ozone_AR', label: 'Ozone, AR' },
  { value: 'Ozone Park_NY', label: 'Ozone Park, NY' },
  { value: 'Paauilo_HI', label: 'Paauilo, HI' },
  { value: 'Pablo_MT', label: 'Pablo, MT' },
  { value: 'Pace_MS', label: 'Pace, MS' },
  { value: 'Pachuta_MS', label: 'Pachuta, MS' },
  { value: 'Pacific_MO', label: 'Pacific, MO' },
  { value: 'Pacific_WA', label: 'Pacific, WA' },
  { value: 'Pacific Beach_WA', label: 'Pacific Beach, WA' },
  { value: 'Pacific City_OR', label: 'Pacific City, OR' },
  { value: 'Pacific Grove_CA', label: 'Pacific Grove, CA' },
  { value: 'Pacific Junction_IA', label: 'Pacific Junction, IA' },
  { value: 'Pacific Palisades_CA', label: 'Pacific Palisades, CA' },
  { value: 'Pacifica_CA', label: 'Pacifica, CA' },
  { value: 'Packwaukee_WI', label: 'Packwaukee, WI' },
  { value: 'Packwood_IA', label: 'Packwood, IA' },
  { value: 'Packwood_WA', label: 'Packwood, WA' },
  { value: 'Pacoima_CA', label: 'Pacoima, CA' },
  { value: 'Pacolet_SC', label: 'Pacolet, SC' },
  { value: 'Pacolet Mills_SC', label: 'Pacolet Mills, SC' },
  { value: 'Paden_OK', label: 'Paden, OK' },
  { value: 'Paden City_WV', label: 'Paden City, WV' },
  { value: 'Padroni_CO', label: 'Padroni, CO' },
  { value: 'Paducah_KY', label: 'Paducah, KY' },
  { value: 'Paducah_TX', label: 'Paducah, TX' },
  { value: 'Paeonian Springs_VA', label: 'Paeonian Springs, VA' },
  { value: 'Page_WV', label: 'Page, WV' },
  { value: 'Page_ND', label: 'Page, ND' },
  { value: 'Page_NE', label: 'Page, NE' },
  { value: 'Page_AZ', label: 'Page, AZ' },
  { value: 'Pageland_SC', label: 'Pageland, SC' },
  { value: 'Pageton_WV', label: 'Pageton, WV' },
  { value: 'Pago Pago_AS', label: 'Pago Pago, AS' },
  { value: 'Pagosa Springs_CO', label: 'Pagosa Springs, CO' },
  { value: 'Paguate_NM', label: 'Paguate, NM' },
  { value: 'Pahala_HI', label: 'Pahala, HI' },
  { value: 'Pahoa_HI', label: 'Pahoa, HI' },
  { value: 'Pahokee_FL', label: 'Pahokee, FL' },
  { value: 'Pahrump_NV', label: 'Pahrump, NV' },
  { value: 'Paia_HI', label: 'Paia, HI' },
  { value: 'Paicines_CA', label: 'Paicines, CA' },
  { value: 'Paige_TX', label: 'Paige, TX' },
  { value: 'Paincourtville_LA', label: 'Paincourtville, LA' },
  { value: 'Painesdale_MI', label: 'Painesdale, MI' },
  { value: 'Painesville_OH', label: 'Painesville, OH' },
  { value: 'Paint Bank_VA', label: 'Paint Bank, VA' },
  { value: 'Paint Lick_KY', label: 'Paint Lick, KY' },
  { value: 'Paint Rock_AL', label: 'Paint Rock, AL' },
  { value: 'Paint Rock_TX', label: 'Paint Rock, TX' },
  { value: 'Painted Post_NY', label: 'Painted Post, NY' },
  { value: 'Painter_VA', label: 'Painter, VA' },
  { value: 'Paintsville_KY', label: 'Paintsville, KY' },
  { value: 'Paisley_FL', label: 'Paisley, FL' },
  { value: 'Paisley_OR', label: 'Paisley, OR' },
  { value: 'Pala_CA', label: 'Pala, CA' },
  { value: 'Palacios_TX', label: 'Palacios, TX' },
  { value: 'Palatine_IL', label: 'Palatine, IL' },
  { value: 'Palatine Bridge_NY', label: 'Palatine Bridge, NY' },
  { value: 'Palatka_FL', label: 'Palatka, FL' },
  { value: 'Palco_KS', label: 'Palco, KS' },
  { value: 'Palenville_NY', label: 'Palenville, NY' },
  { value: 'Palermo_ME', label: 'Palermo, ME' },
  { value: 'Palermo_ND', label: 'Palermo, ND' },
  { value: 'Palermo_CA', label: 'Palermo, CA' },
  { value: 'Palestine_WV', label: 'Palestine, WV' },
  { value: 'Palestine_OH', label: 'Palestine, OH' },
  { value: 'Palestine_IL', label: 'Palestine, IL' },
  { value: 'Palestine_AR', label: 'Palestine, AR' },
  { value: 'Palestine_TX', label: 'Palestine, TX' },
  { value: 'Palisade_MN', label: 'Palisade, MN' },
  { value: 'Palisade_NE', label: 'Palisade, NE' },
  { value: 'Palisade_CO', label: 'Palisade, CO' },
  { value: 'Palisades_NY', label: 'Palisades, NY' },
  { value: 'Palisades_WA', label: 'Palisades, WA' },
  { value: 'Palisades Park_NJ', label: 'Palisades Park, NJ' },
  { value: 'Pall Mall_TN', label: 'Pall Mall, TN' },
  { value: 'Palm_PA', label: 'Palm, PA' },
  { value: 'Palm Bay_FL', label: 'Palm Bay, FL' },
  { value: 'Palm Beach_FL', label: 'Palm Beach, FL' },
  { value: 'Palm Beach Gardens_FL', label: 'Palm Beach Gardens, FL' },
  { value: 'Palm City_FL', label: 'Palm City, FL' },
  { value: 'Palm Coast_FL', label: 'Palm Coast, FL' },
  { value: 'Palm Desert_CA', label: 'Palm Desert, CA' },
  { value: 'Palm Harbor_FL', label: 'Palm Harbor, FL' },
  { value: 'Palm Springs_CA', label: 'Palm Springs, CA' },
  { value: 'Palmdale_FL', label: 'Palmdale, FL' },
  { value: 'Palmdale_CA', label: 'Palmdale, CA' },
  { value: 'Palmer_MA', label: 'Palmer, MA' },
  { value: 'Palmer_TN', label: 'Palmer, TN' },
  { value: 'Palmer_MI', label: 'Palmer, MI' },
  { value: 'Palmer_IA', label: 'Palmer, IA' },
  { value: 'Palmer_IL', label: 'Palmer, IL' },
  { value: 'Palmer_KS', label: 'Palmer, KS' },
  { value: 'Palmer_NE', label: 'Palmer, NE' },
  { value: 'Palmer_TX', label: 'Palmer, TX' },
  { value: 'Palmer_AK', label: 'Palmer, AK' },
  { value: 'Palmer Lake_CO', label: 'Palmer Lake, CO' },
  { value: 'Palmersville_TN', label: 'Palmersville, TN' },
  { value: 'Palmerton_PA', label: 'Palmerton, PA' },
  { value: 'Palmetto_GA', label: 'Palmetto, GA' },
  { value: 'Palmetto_FL', label: 'Palmetto, FL' },
  { value: 'Palmetto_LA', label: 'Palmetto, LA' },
  { value: 'Palms_MI', label: 'Palms, MI' },
  { value: 'Palmyra_ME', label: 'Palmyra, ME' },
  { value: 'Palmyra_NJ', label: 'Palmyra, NJ' },
  { value: 'Palmyra_NY', label: 'Palmyra, NY' },
  { value: 'Palmyra_PA', label: 'Palmyra, PA' },
  { value: 'Palmyra_VA', label: 'Palmyra, VA' },
  { value: 'Palmyra_TN', label: 'Palmyra, TN' },
  { value: 'Palmyra_IN', label: 'Palmyra, IN' },
  { value: 'Palmyra_MI', label: 'Palmyra, MI' },
  { value: 'Palmyra_WI', label: 'Palmyra, WI' },
  { value: 'Palmyra_IL', label: 'Palmyra, IL' },
  { value: 'Palmyra_MO', label: 'Palmyra, MO' },
  { value: 'Palmyra_NE', label: 'Palmyra, NE' },
  { value: 'Palo_MI', label: 'Palo, MI' },
  { value: 'Palo_IA', label: 'Palo, IA' },
  { value: 'Palo Alto_CA', label: 'Palo Alto, CA' },
  { value: 'Palo Cedro_CA', label: 'Palo Cedro, CA' },
  { value: 'Palo Pinto_TX', label: 'Palo Pinto, TX' },
  { value: 'Palo Verde_AZ', label: 'Palo Verde, AZ' },
  { value: 'Palo Verde_CA', label: 'Palo Verde, CA' },
  { value: 'Paloma_IL', label: 'Paloma, IL' },
  { value: 'Palomar Mountain_CA', label: 'Palomar Mountain, CA' },
  { value: 'Palos Heights_IL', label: 'Palos Heights, IL' },
  { value: 'Palos Hills_IL', label: 'Palos Hills, IL' },
  { value: 'Palos Park_IL', label: 'Palos Park, IL' },
  { value: 'Palos Verdes Peninsula_CA', label: 'Palos Verdes Peninsula, CA' },
  { value: 'Palouse_WA', label: 'Palouse, WA' },
  { value: 'Pampa_TX', label: 'Pampa, TX' },
  { value: 'Pamplico_SC', label: 'Pamplico, SC' },
  { value: 'Pamplin_VA', label: 'Pamplin, VA' },
  { value: 'Pana_IL', label: 'Pana, IL' },
  { value: 'Panaca_NV', label: 'Panaca, NV' },
  { value: 'Panacea_FL', label: 'Panacea, FL' },
  { value: 'Panama_NY', label: 'Panama, NY' },
  { value: 'Panama_IA', label: 'Panama, IA' },
  { value: 'Panama_IL', label: 'Panama, IL' },
  { value: 'Panama_NE', label: 'Panama, NE' },
  { value: 'Panama_OK', label: 'Panama, OK' },
  { value: 'Panama City_FL', label: 'Panama City, FL' },
  { value: 'Panama City Beach_FL', label: 'Panama City Beach, FL' },
  { value: 'Pandora_OH', label: 'Pandora, OH' },
  { value: 'Pandora_TX', label: 'Pandora, TX' },
  { value: 'Pangburn_AR', label: 'Pangburn, AR' },
  { value: 'Panguitch_UT', label: 'Panguitch, UT' },
  { value: 'Panhandle_TX', label: 'Panhandle, TX' },
  { value: 'Panna Maria_TX', label: 'Panna Maria, TX' },
  { value: 'Panola_AL', label: 'Panola, AL' },
  { value: 'Panora_IA', label: 'Panora, IA' },
  { value: 'Panorama City_CA', label: 'Panorama City, CA' },
  { value: 'Pansey_AL', label: 'Pansey, AL' },
  { value: 'Pantego_NC', label: 'Pantego, NC' },
  { value: 'Panther_WV', label: 'Panther, WV' },
  { value: 'Panther Burn_MS', label: 'Panther Burn, MS' },
  { value: 'Paola_KS', label: 'Paola, KS' },
  { value: 'Paoli_PA', label: 'Paoli, PA' },
  { value: 'Paoli_IN', label: 'Paoli, IN' },
  { value: 'Paoli_OK', label: 'Paoli, OK' },
  { value: 'Paoli_CO', label: 'Paoli, CO' },
  { value: 'Paonia_CO', label: 'Paonia, CO' },
  { value: 'Papaaloa_HI', label: 'Papaaloa, HI' },
  { value: 'Papaikou_HI', label: 'Papaikou, HI' },
  { value: 'Papillion_NE', label: 'Papillion, NE' },
  { value: 'Papineau_IL', label: 'Papineau, IL' },
  { value: 'Parachute_CO', label: 'Parachute, CO' },
  { value: 'Paradis_LA', label: 'Paradis, LA' },
  { value: 'Paradise_PA', label: 'Paradise, PA' },
  { value: 'Paradise_MI', label: 'Paradise, MI' },
  { value: 'Paradise_MT', label: 'Paradise, MT' },
  { value: 'Paradise_KS', label: 'Paradise, KS' },
  { value: 'Paradise_TX', label: 'Paradise, TX' },
  { value: 'Paradise_UT', label: 'Paradise, UT' },
  { value: 'Paradise_CA', label: 'Paradise, CA' },
  { value: 'Paradise Valley_AZ', label: 'Paradise Valley, AZ' },
  { value: 'Paradise Valley_NV', label: 'Paradise Valley, NV' },
  { value: 'Paradox_NY', label: 'Paradox, NY' },
  { value: 'Paradox_CO', label: 'Paradox, CO' },
  { value: 'Paragon_IN', label: 'Paragon, IN' },
  { value: 'Paragonah_UT', label: 'Paragonah, UT' },
  { value: 'Paragould_AR', label: 'Paragould, AR' },
  { value: 'Paramount_CA', label: 'Paramount, CA' },
  { value: 'Paramus_NJ', label: 'Paramus, NJ' },
  { value: 'Parchman_MS', label: 'Parchman, MS' },
  { value: 'Pardeeville_WI', label: 'Pardeeville, WI' },
  { value: 'Paris_ME', label: 'Paris, ME' },
  { value: 'Paris_VA', label: 'Paris, VA' },
  { value: 'Paris_TN', label: 'Paris, TN' },
  { value: 'Paris_MS', label: 'Paris, MS' },
  { value: 'Paris_KY', label: 'Paris, KY' },
  { value: 'Paris_OH', label: 'Paris, OH' },
  { value: 'Paris_MI', label: 'Paris, MI' },
  { value: 'Paris_IL', label: 'Paris, IL' },
  { value: 'Paris_MO', label: 'Paris, MO' },
  { value: 'Paris_AR', label: 'Paris, AR' },
  { value: 'Paris_TX', label: 'Paris, TX' },
  { value: 'Paris_ID', label: 'Paris, ID' },
  { value: 'Paris Crossing_IN', label: 'Paris Crossing, IN' },
  { value: 'Parish_NY', label: 'Parish, NY' },
  { value: 'Parishville_NY', label: 'Parishville, NY' },
  { value: 'Park_KS', label: 'Park, KS' },
  { value: 'Park City_KY', label: 'Park City, KY' },
  { value: 'Park City_MT', label: 'Park City, MT' },
  { value: 'Park City_UT', label: 'Park City, UT' },
  { value: 'Park Falls_WI', label: 'Park Falls, WI' },
  { value: 'Park Forest_IL', label: 'Park Forest, IL' },
  { value: 'Park Hall_MD', label: 'Park Hall, MD' },
  { value: 'Park Hill_OK', label: 'Park Hill, OK' },
  { value: 'Park Hills_MO', label: 'Park Hills, MO' },
  { value: 'Park Rapids_MN', label: 'Park Rapids, MN' },
  { value: 'Park Ridge_NJ', label: 'Park Ridge, NJ' },
  { value: 'Park Ridge_IL', label: 'Park Ridge, IL' },
  { value: 'Park River_ND', label: 'Park River, ND' },
  { value: 'Park Valley_UT', label: 'Park Valley, UT' },
  { value: 'Parkdale_AR', label: 'Parkdale, AR' },
  { value: 'Parker_PA', label: 'Parker, PA' },
  { value: 'Parker_SD', label: 'Parker, SD' },
  { value: 'Parker_KS', label: 'Parker, KS' },
  { value: 'Parker_CO', label: 'Parker, CO' },
  { value: 'Parker_ID', label: 'Parker, ID' },
  { value: 'Parker_AZ', label: 'Parker, AZ' },
  { value: 'Parker_WA', label: 'Parker, WA' },
  { value: 'Parker City_IN', label: 'Parker City, IN' },
  { value: 'Parker Dam_CA', label: 'Parker Dam, CA' },
  { value: 'Parker Ford_PA', label: 'Parker Ford, PA' },
  { value: 'Parkers Lake_KY', label: 'Parkers Lake, KY' },
  { value: 'Parkers Prairie_MN', label: 'Parkers Prairie, MN' },
  { value: 'Parkersburg_WV', label: 'Parkersburg, WV' },
  { value: 'Parkersburg_IA', label: 'Parkersburg, IA' },
  { value: 'Parkersburg_IL', label: 'Parkersburg, IL' },
  { value: 'Parkesburg_PA', label: 'Parkesburg, PA' },
  { value: 'Parkhill_PA', label: 'Parkhill, PA' },
  { value: 'Parkin_AR', label: 'Parkin, AR' },
  { value: 'Parkman_OH', label: 'Parkman, OH' },
  { value: 'Parkman_WY', label: 'Parkman, WY' },
  { value: 'Parks_NE', label: 'Parks, NE' },
  { value: 'Parks_AR', label: 'Parks, AR' },
  { value: 'Parks_AZ', label: 'Parks, AZ' },
  { value: 'Parksley_VA', label: 'Parksley, VA' },
  { value: 'Parkston_SD', label: 'Parkston, SD' },
  { value: 'Parksville_NY', label: 'Parksville, NY' },
  { value: 'Parksville_SC', label: 'Parksville, SC' },
  { value: 'Parksville_KY', label: 'Parksville, KY' },
  { value: 'Parkton_MD', label: 'Parkton, MD' },
  { value: 'Parkton_NC', label: 'Parkton, NC' },
  { value: 'Parkville_MD', label: 'Parkville, MD' },
  { value: 'Parlier_CA', label: 'Parlier, CA' },
  { value: 'Parlin_NJ', label: 'Parlin, NJ' },
  { value: 'Parlin_CO', label: 'Parlin, CO' },
  { value: 'Parma_MI', label: 'Parma, MI' },
  { value: 'Parma_MO', label: 'Parma, MO' },
  { value: 'Parma_ID', label: 'Parma, ID' },
  { value: 'Parmele_NC', label: 'Parmele, NC' },
  { value: 'Parmelee_SD', label: 'Parmelee, SD' },
  { value: 'Parnell_IA', label: 'Parnell, IA' },
  { value: 'Parnell_MO', label: 'Parnell, MO' },
  { value: 'Paron_AR', label: 'Paron, AR' },
  { value: 'Parowan_UT', label: 'Parowan, UT' },
  { value: 'Parris Island_SC', label: 'Parris Island, SC' },
  { value: 'Parrish_FL', label: 'Parrish, FL' },
  { value: 'Parrish_AL', label: 'Parrish, AL' },
  { value: 'Parrott_VA', label: 'Parrott, VA' },
  { value: 'Parrott_GA', label: 'Parrott, GA' },
  { value: 'Parrottsville_TN', label: 'Parrottsville, TN' },
  { value: 'Parryville_PA', label: 'Parryville, PA' },
  { value: 'Parshall_ND', label: 'Parshall, ND' },
  { value: 'Parshall_CO', label: 'Parshall, CO' },
  { value: 'Parsippany_NJ', label: 'Parsippany, NJ' },
  { value: 'Parsons_WV', label: 'Parsons, WV' },
  { value: 'Parsons_TN', label: 'Parsons, TN' },
  { value: 'Parsons_KS', label: 'Parsons, KS' },
  { value: 'Parsonsburg_MD', label: 'Parsonsburg, MD' },
  { value: 'Parsonsfield_ME', label: 'Parsonsfield, ME' },
  { value: 'Parthenon_AR', label: 'Parthenon, AR' },
  { value: 'Partlow_VA', label: 'Partlow, VA' },
  { value: 'Partridge_KY', label: 'Partridge, KY' },
  { value: 'Partridge_KS', label: 'Partridge, KS' },
  { value: 'Pasadena_MD', label: 'Pasadena, MD' },
  { value: 'Pasadena_TX', label: 'Pasadena, TX' },
  { value: 'Pasadena_CA', label: 'Pasadena, CA' },
  { value: 'Pascagoula_MS', label: 'Pascagoula, MS' },
  { value: 'Pasco_WA', label: 'Pasco, WA' },
  { value: 'Pascoag_RI', label: 'Pascoag, RI' },
  { value: 'Paskenta_CA', label: 'Paskenta, CA' },
  { value: 'Paso Robles_CA', label: 'Paso Robles, CA' },
  { value: 'Pass Christian_MS', label: 'Pass Christian, MS' },
  { value: 'Passadumkeag_ME', label: 'Passadumkeag, ME' },
  { value: 'Passaic_NJ', label: 'Passaic, NJ' },
  { value: 'Patagonia_AZ', label: 'Patagonia, AZ' },
  { value: 'Pataskala_OH', label: 'Pataskala, OH' },
  { value: 'Patch Grove_WI', label: 'Patch Grove, WI' },
  { value: 'Patchogue_NY', label: 'Patchogue, NY' },
  { value: 'Pateros_WA', label: 'Pateros, WA' },
  { value: 'Paterson_NJ', label: 'Paterson, NJ' },
  { value: 'Paterson_WA', label: 'Paterson, WA' },
  { value: 'Pathfork_KY', label: 'Pathfork, KY' },
  { value: 'Patillas_PR', label: 'Patillas, PR' },
  { value: 'Patoka_IN', label: 'Patoka, IN' },
  { value: 'Patoka_IL', label: 'Patoka, IL' },
  { value: 'Paton_IA', label: 'Paton, IA' },
  { value: 'Patrick_SC', label: 'Patrick, SC' },
  { value: 'Patrick Afb_FL', label: 'Patrick Afb, FL' },
  { value: 'Patrick Springs_VA', label: 'Patrick Springs, VA' },
  { value: 'Patricksburg_IN', label: 'Patricksburg, IN' },
  { value: 'Patriot_OH', label: 'Patriot, OH' },
  { value: 'Patriot_IN', label: 'Patriot, IN' },
  { value: 'Patten_ME', label: 'Patten, ME' },
  { value: 'Patterson_NY', label: 'Patterson, NY' },
  { value: 'Patterson_GA', label: 'Patterson, GA' },
  { value: 'Patterson_IA', label: 'Patterson, IA' },
  { value: 'Patterson_IL', label: 'Patterson, IL' },
  { value: 'Patterson_MO', label: 'Patterson, MO' },
  { value: 'Patterson_LA', label: 'Patterson, LA' },
  { value: 'Patterson_AR', label: 'Patterson, AR' },
  { value: 'Patterson_CA', label: 'Patterson, CA' },
  { value: 'Pattersonville_NY', label: 'Pattersonville, NY' },
  { value: 'Pattison_MS', label: 'Pattison, MS' },
  { value: 'Pattison_TX', label: 'Pattison, TX' },
  { value: 'Patton_PA', label: 'Patton, PA' },
  { value: 'Patton_MO', label: 'Patton, MO' },
  { value: 'Patton_CA', label: 'Patton, CA' },
  { value: 'Pattonsburg_MO', label: 'Pattonsburg, MO' },
  { value: 'Pattonville_TX', label: 'Pattonville, TX' },
  { value: 'Patuxent River_MD', label: 'Patuxent River, MD' },
  { value: 'Paul_ID', label: 'Paul, ID' },
  { value: 'Paul Smiths_NY', label: 'Paul Smiths, NY' },
  { value: 'Paulden_AZ', label: 'Paulden, AZ' },
  { value: 'Paulding_MS', label: 'Paulding, MS' },
  { value: 'Paulding_OH', label: 'Paulding, OH' },
  { value: 'Paulina_LA', label: 'Paulina, LA' },
  { value: 'Paulina_OR', label: 'Paulina, OR' },
  { value: 'Pauline_SC', label: 'Pauline, SC' },
  { value: 'Paullina_IA', label: 'Paullina, IA' },
  { value: 'Pauls Valley_OK', label: 'Pauls Valley, OK' },
  { value: 'Paulsboro_NJ', label: 'Paulsboro, NJ' },
  { value: 'Pauma Valley_CA', label: 'Pauma Valley, CA' },
  { value: 'Paupack_PA', label: 'Paupack, PA' },
  { value: 'Pavilion_NY', label: 'Pavilion, NY' },
  { value: 'Pavillion_WY', label: 'Pavillion, WY' },
  { value: 'Pavo_GA', label: 'Pavo, GA' },
  { value: 'Paw Paw_WV', label: 'Paw Paw, WV' },
  { value: 'Paw Paw_MI', label: 'Paw Paw, MI' },
  { value: 'Paw Paw_IL', label: 'Paw Paw, IL' },
  { value: 'Pawcatuck_CT', label: 'Pawcatuck, CT' },
  { value: 'Pawhuska_OK', label: 'Pawhuska, OK' },
  { value: 'Pawlet_VT', label: 'Pawlet, VT' },
  { value: 'Pawleys Island_SC', label: 'Pawleys Island, SC' },
  { value: 'Pawling_NY', label: 'Pawling, NY' },
  { value: 'Pawnee_IL', label: 'Pawnee, IL' },
  { value: 'Pawnee_OK', label: 'Pawnee, OK' },
  { value: 'Pawnee_TX', label: 'Pawnee, TX' },
  { value: 'Pawnee City_NE', label: 'Pawnee City, NE' },
  { value: 'Pawnee Rock_KS', label: 'Pawnee Rock, KS' },
  { value: 'Pawtucket_RI', label: 'Pawtucket, RI' },
  { value: 'Pax_WV', label: 'Pax, WV' },
  { value: 'Paxico_KS', label: 'Paxico, KS' },
  { value: 'Paxinos_PA', label: 'Paxinos, PA' },
  { value: 'Paxton_MA', label: 'Paxton, MA' },
  { value: 'Paxton_IN', label: 'Paxton, IN' },
  { value: 'Paxton_IL', label: 'Paxton, IL' },
  { value: 'Paxton_NE', label: 'Paxton, NE' },
  { value: 'Paxtonville_PA', label: 'Paxtonville, PA' },
  { value: 'Payette_ID', label: 'Payette, ID' },
  { value: 'Payne_OH', label: 'Payne, OH' },
  { value: 'Paynes Creek_CA', label: 'Paynes Creek, CA' },
  { value: 'Paynesville_WV', label: 'Paynesville, WV' },
  { value: 'Paynesville_MN', label: 'Paynesville, MN' },
  { value: 'Payneville_KY', label: 'Payneville, KY' },
  { value: 'Payson_IL', label: 'Payson, IL' },
  { value: 'Payson_UT', label: 'Payson, UT' },
  { value: 'Payson_AZ', label: 'Payson, AZ' },
  { value: 'Pe Ell_WA', label: 'Pe Ell, WA' },
  { value: 'Pea Ridge_AR', label: 'Pea Ridge, AR' },
  { value: 'Peabody_MA', label: 'Peabody, MA' },
  { value: 'Peabody_KS', label: 'Peabody, KS' },
  { value: 'Peace Valley_MO', label: 'Peace Valley, MO' },
  { value: 'Peach Bottom_PA', label: 'Peach Bottom, PA' },
  { value: 'Peach Creek_WV', label: 'Peach Creek, WV' },
  { value: 'Peach Orchard_AR', label: 'Peach Orchard, AR' },
  { value: 'Peach Springs_AZ', label: 'Peach Springs, AZ' },
  { value: 'Peacham_VT', label: 'Peacham, VT' },
  { value: 'Peachland_NC', label: 'Peachland, NC' },
  { value: 'Peachtree City_GA', label: 'Peachtree City, GA' },
  { value: 'Peachtree Corners_GA', label: 'Peachtree Corners, GA' },
  { value: 'Peak_SC', label: 'Peak, SC' },
  { value: 'Peaks Island_ME', label: 'Peaks Island, ME' },
  { value: 'Peapack_NJ', label: 'Peapack, NJ' },
  { value: 'Pearblossom_CA', label: 'Pearblossom, CA' },
  { value: 'Pearce_AZ', label: 'Pearce, AZ' },
  { value: 'Pearcy_AR', label: 'Pearcy, AR' },
  { value: 'Pearisburg_VA', label: 'Pearisburg, VA' },
  { value: 'Pearl_MS', label: 'Pearl, MS' },
  { value: 'Pearl_IL', label: 'Pearl, IL' },
  { value: 'Pearl City_IL', label: 'Pearl City, IL' },
  { value: 'Pearl City_HI', label: 'Pearl City, HI' },
  { value: 'Pearl River_NY', label: 'Pearl River, NY' },
  { value: 'Pearl River_LA', label: 'Pearl River, LA' },
  { value: 'Pearland_TX', label: 'Pearland, TX' },
  { value: 'Pearlington_MS', label: 'Pearlington, MS' },
  { value: 'Pearsall_TX', label: 'Pearsall, TX' },
  { value: 'Pearson_GA', label: 'Pearson, GA' },
  { value: 'Pearson_WI', label: 'Pearson, WI' },
  { value: 'Pease_MN', label: 'Pease, MN' },
  { value: 'Pebble Beach_CA', label: 'Pebble Beach, CA' },
  { value: 'Pecan Gap_TX', label: 'Pecan Gap, TX' },
  { value: 'Pecatonica_IL', label: 'Pecatonica, IL' },
  { value: 'Peck_MI', label: 'Peck, MI' },
  { value: 'Peck_KS', label: 'Peck, KS' },
  { value: 'Peck_ID', label: 'Peck, ID' },
  { value: 'Pecks Mill_WV', label: 'Pecks Mill, WV' },
  { value: 'Peckville_PA', label: 'Peckville, PA' },
  { value: 'Peconic_NY', label: 'Peconic, NY' },
  { value: 'Pecos_TX', label: 'Pecos, TX' },
  { value: 'Pecos_NM', label: 'Pecos, NM' },
  { value: 'Peculiar_MO', label: 'Peculiar, MO' },
  { value: 'Pedricktown_NJ', label: 'Pedricktown, NJ' },
  { value: 'Pedro_OH', label: 'Pedro, OH' },
  { value: 'Pedro Bay_AK', label: 'Pedro Bay, AK' },
  { value: 'Peebles_OH', label: 'Peebles, OH' },
  { value: 'Peekskill_NY', label: 'Peekskill, NY' },
  { value: 'Peel_AR', label: 'Peel, AR' },
  { value: 'Peerless_MT', label: 'Peerless, MT' },
  { value: 'Peetz_CO', label: 'Peetz, CO' },
  { value: 'Peever_SD', label: 'Peever, SD' },
  { value: 'Peggs_OK', label: 'Peggs, OK' },
  { value: 'Peggy_TX', label: 'Peggy, TX' },
  { value: 'Pegram_TN', label: 'Pegram, TN' },
  { value: 'Pekin_IN', label: 'Pekin, IN' },
  { value: 'Pekin_ND', label: 'Pekin, ND' },
  { value: 'Pekin_IL', label: 'Pekin, IL' },
  { value: 'Pelahatchie_MS', label: 'Pelahatchie, MS' },
  { value: 'Pelham_NH', label: 'Pelham, NH' },
  { value: 'Pelham_NY', label: 'Pelham, NY' },
  { value: 'Pelham_NC', label: 'Pelham, NC' },
  { value: 'Pelham_GA', label: 'Pelham, GA' },
  { value: 'Pelham_AL', label: 'Pelham, AL' },
  { value: 'Pelham_TN', label: 'Pelham, TN' },
  { value: 'Pelican_LA', label: 'Pelican, LA' },
  { value: 'Pelican_AK', label: 'Pelican, AK' },
  { value: 'Pelican Lake_WI', label: 'Pelican Lake, WI' },
  { value: 'Pelican Rapids_MN', label: 'Pelican Rapids, MN' },
  { value: 'Pelion_SC', label: 'Pelion, SC' },
  { value: 'Pelkie_MI', label: 'Pelkie, MI' },
  { value: 'Pell City_AL', label: 'Pell City, AL' },
  { value: 'Pell Lake_WI', label: 'Pell Lake, WI' },
  { value: 'Pella_IA', label: 'Pella, IA' },
  { value: 'Pellston_MI', label: 'Pellston, MI' },
  { value: 'Pelsor_AR', label: 'Pelsor, AR' },
  { value: 'Pelzer_SC', label: 'Pelzer, SC' },
  { value: 'Pemaquid_ME', label: 'Pemaquid, ME' },
  { value: 'Pemberton_NJ', label: 'Pemberton, NJ' },
  { value: 'Pemberton_OH', label: 'Pemberton, OH' },
  { value: 'Pemberton_MN', label: 'Pemberton, MN' },
  { value: 'Pemberville_OH', label: 'Pemberville, OH' },
  { value: 'Pembina_ND', label: 'Pembina, ND' },
  { value: 'Pembine_WI', label: 'Pembine, WI' },
  { value: 'Pembroke_MA', label: 'Pembroke, MA' },
  { value: 'Pembroke_ME', label: 'Pembroke, ME' },
  { value: 'Pembroke_VA', label: 'Pembroke, VA' },
  { value: 'Pembroke_NC', label: 'Pembroke, NC' },
  { value: 'Pembroke_GA', label: 'Pembroke, GA' },
  { value: 'Pembroke_KY', label: 'Pembroke, KY' },
  { value: 'Pembroke Pines_FL', label: 'Pembroke Pines, FL' },
  { value: 'Pembroke Township_IL', label: 'Pembroke Township, IL' },
  { value: 'Pen Argyl_PA', label: 'Pen Argyl, PA' },
  { value: 'Pena Blanca_NM', label: 'Pena Blanca, NM' },
  { value: 'Penasco_NM', label: 'Penasco, NM' },
  { value: 'Pence Springs_WV', label: 'Pence Springs, WV' },
  { value: 'Pencil Bluff_AR', label: 'Pencil Bluff, AR' },
  { value: 'Pender_NE', label: 'Pender, NE' },
  { value: 'Pendergrass_GA', label: 'Pendergrass, GA' },
  { value: 'Pendleton_NC', label: 'Pendleton, NC' },
  { value: 'Pendleton_SC', label: 'Pendleton, SC' },
  { value: 'Pendleton_KY', label: 'Pendleton, KY' },
  { value: 'Pendleton_IN', label: 'Pendleton, IN' },
  { value: 'Pendleton_OR', label: 'Pendleton, OR' },
  { value: 'Pendroy_MT', label: 'Pendroy, MT' },
  { value: 'Penelope_TX', label: 'Penelope, TX' },
  { value: 'Penfield_NY', label: 'Penfield, NY' },
  { value: 'Penfield_PA', label: 'Penfield, PA' },
  { value: 'Penfield_IL', label: 'Penfield, IL' },
  { value: 'Pengilly_MN', label: 'Pengilly, MN' },
  { value: 'Penhook_VA', label: 'Penhook, VA' },
  { value: 'Peninsula_OH', label: 'Peninsula, OH' },
  { value: 'Penitas_TX', label: 'Penitas, TX' },
  { value: 'Penn_PA', label: 'Penn, PA' },
  { value: 'Penn_ND', label: 'Penn, ND' },
  { value: 'Penn Laird_VA', label: 'Penn Laird, VA' },
  { value: 'Penn Run_PA', label: 'Penn Run, PA' },
  { value: 'Penn Valley_CA', label: 'Penn Valley, CA' },
  { value: 'Penn Yan_NY', label: 'Penn Yan, NY' },
  { value: 'Pennellville_NY', label: 'Pennellville, NY' },
  { value: 'Penney Farms_FL', label: 'Penney Farms, FL' },
  { value: 'Penngrove_CA', label: 'Penngrove, CA' },
  { value: 'Pennington_NJ', label: 'Pennington, NJ' },
  { value: 'Pennington_AL', label: 'Pennington, AL' },
  { value: 'Pennington_MN', label: 'Pennington, MN' },
  { value: 'Pennington_TX', label: 'Pennington, TX' },
  { value: 'Pennington Gap_VA', label: 'Pennington Gap, VA' },
  { value: 'Pennock_MN', label: 'Pennock, MN' },
  { value: 'Penns Creek_PA', label: 'Penns Creek, PA' },
  { value: 'Penns Grove_NJ', label: 'Penns Grove, NJ' },
  { value: 'Pennsauken_NJ', label: 'Pennsauken, NJ' },
  { value: 'Pennsboro_WV', label: 'Pennsboro, WV' },
  { value: 'Pennsburg_PA', label: 'Pennsburg, PA' },
  { value: 'Pennsville_NJ', label: 'Pennsville, NJ' },
  { value: 'Pennsylvania Furnace_PA', label: 'Pennsylvania Furnace, PA' },
  { value: 'Pennville_IN', label: 'Pennville, IN' },
  { value: 'Penobscot_ME', label: 'Penobscot, ME' },
  { value: 'Penokee_KS', label: 'Penokee, KS' },
  { value: 'Penrose_NC', label: 'Penrose, NC' },
  { value: 'Penrose_CO', label: 'Penrose, CO' },
  { value: 'Penryn_CA', label: 'Penryn, CA' },
  { value: 'Pensacola_FL', label: 'Pensacola, FL' },
  { value: 'Pentress_WV', label: 'Pentress, WV' },
  { value: 'Pentwater_MI', label: 'Pentwater, MI' },
  { value: 'Penuelas_PR', label: 'Penuelas, PR' },
  { value: 'Penwell_TX', label: 'Penwell, TX' },
  { value: 'Peoa_UT', label: 'Peoa, UT' },
  { value: 'Peoria_IL', label: 'Peoria, IL' },
  { value: 'Peoria_AZ', label: 'Peoria, AZ' },
  { value: 'Peoria Heights_IL', label: 'Peoria Heights, IL' },
  { value: 'Peosta_IA', label: 'Peosta, IA' },
  { value: 'Peotone_IL', label: 'Peotone, IL' },
  { value: 'Pep_TX', label: 'Pep, TX' },
  { value: 'Pep_NM', label: 'Pep, NM' },
  { value: 'Pepeekeo_HI', label: 'Pepeekeo, HI' },
  { value: 'Pepin_WI', label: 'Pepin, WI' },
  { value: 'Pepperell_MA', label: 'Pepperell, MA' },
  { value: 'Pequannock_NJ', label: 'Pequannock, NJ' },
  { value: 'Pequea_PA', label: 'Pequea, PA' },
  { value: 'Pequot Lakes_MN', label: 'Pequot Lakes, MN' },
  { value: 'Peralta_NM', label: 'Peralta, NM' },
  { value: 'Percival_IA', label: 'Percival, IA' },
  { value: 'Percy_IL', label: 'Percy, IL' },
  { value: 'Perdido_AL', label: 'Perdido, AL' },
  { value: 'Perdue Hill_AL', label: 'Perdue Hill, AL' },
  { value: 'Perham_ME', label: 'Perham, ME' },
  { value: 'Perham_MN', label: 'Perham, MN' },
  { value: 'Peridot_AZ', label: 'Peridot, AZ' },
  { value: 'Perkasie_PA', label: 'Perkasie, PA' },
  { value: 'Perkins_GA', label: 'Perkins, GA' },
  { value: 'Perkins_MI', label: 'Perkins, MI' },
  { value: 'Perkins_MO', label: 'Perkins, MO' },
  { value: 'Perkins_OK', label: 'Perkins, OK' },
  { value: 'Perkinston_MS', label: 'Perkinston, MS' },
  { value: 'Perkinsville_VT', label: 'Perkinsville, VT' },
  { value: 'Perkinsville_NY', label: 'Perkinsville, NY' },
  { value: 'Perkiomenville_PA', label: 'Perkiomenville, PA' },
  { value: 'Perley_MN', label: 'Perley, MN' },
  { value: 'Perrin_TX', label: 'Perrin, TX' },
  { value: 'Perrinton_MI', label: 'Perrinton, MI' },
  { value: 'Perris_CA', label: 'Perris, CA' },
  { value: 'Perronville_MI', label: 'Perronville, MI' },
  { value: 'Perry_ME', label: 'Perry, ME' },
  { value: 'Perry_NY', label: 'Perry, NY' },
  { value: 'Perry_GA', label: 'Perry, GA' },
  { value: 'Perry_FL', label: 'Perry, FL' },
  { value: 'Perry_OH', label: 'Perry, OH' },
  { value: 'Perry_MI', label: 'Perry, MI' },
  { value: 'Perry_IA', label: 'Perry, IA' },
  { value: 'Perry_IL', label: 'Perry, IL' },
  { value: 'Perry_MO', label: 'Perry, MO' },
  { value: 'Perry_KS', label: 'Perry, KS' },
  { value: 'Perry_LA', label: 'Perry, LA' },
  { value: 'Perry_AR', label: 'Perry, AR' },
  { value: 'Perry_OK', label: 'Perry, OK' },
  { value: 'Perry Hall_MD', label: 'Perry Hall, MD' },
  { value: 'Perry Park_KY', label: 'Perry Park, KY' },
  { value: 'Perry Point_MD', label: 'Perry Point, MD' },
  { value: 'Perryman_MD', label: 'Perryman, MD' },
  { value: 'Perryopolis_PA', label: 'Perryopolis, PA' },
  { value: 'Perrysburg_NY', label: 'Perrysburg, NY' },
  { value: 'Perrysburg_OH', label: 'Perrysburg, OH' },
  { value: 'Perrysville_OH', label: 'Perrysville, OH' },
  { value: 'Perrysville_IN', label: 'Perrysville, IN' },
  { value: 'Perryton_TX', label: 'Perryton, TX' },
  { value: 'Perryville_MD', label: 'Perryville, MD' },
  { value: 'Perryville_KY', label: 'Perryville, KY' },
  { value: 'Perryville_MO', label: 'Perryville, MO' },
  { value: 'Perryville_AR', label: 'Perryville, AR' },
  { value: 'Perryville_AK', label: 'Perryville, AK' },
  { value: 'Pershing_IN', label: 'Pershing, IN' },
  { value: 'Persia_IA', label: 'Persia, IA' },
  { value: 'Perth_ND', label: 'Perth, ND' },
  { value: 'Perth Amboy_NJ', label: 'Perth Amboy, NJ' },
  { value: 'Peru_ME', label: 'Peru, ME' },
  { value: 'Peru_VT', label: 'Peru, VT' },
  { value: 'Peru_NY', label: 'Peru, NY' },
  { value: 'Peru_IN', label: 'Peru, IN' },
  { value: 'Peru_IA', label: 'Peru, IA' },
  { value: 'Peru_IL', label: 'Peru, IL' },
  { value: 'Peru_KS', label: 'Peru, KS' },
  { value: 'Peru_NE', label: 'Peru, NE' },
  { value: 'Pescadero_CA', label: 'Pescadero, CA' },
  { value: 'Peshastin_WA', label: 'Peshastin, WA' },
  { value: 'Peshtigo_WI', label: 'Peshtigo, WI' },
  { value: 'Pesotum_IL', label: 'Pesotum, IL' },
  { value: 'Petaca_NM', label: 'Petaca, NM' },
  { value: 'Petal_MS', label: 'Petal, MS' },
  { value: 'Petaluma_CA', label: 'Petaluma, CA' },
  { value: 'Peterboro_NY', label: 'Peterboro, NY' },
  { value: 'Peterborough_NH', label: 'Peterborough, NH' },
  { value: 'Peterman_AL', label: 'Peterman, AL' },
  { value: 'Petersburg_NY', label: 'Petersburg, NY' },
  { value: 'Petersburg_PA', label: 'Petersburg, PA' },
  { value: 'Petersburg_VA', label: 'Petersburg, VA' },
  { value: 'Petersburg_WV', label: 'Petersburg, WV' },
  { value: 'Petersburg_TN', label: 'Petersburg, TN' },
  { value: 'Petersburg_KY', label: 'Petersburg, KY' },
  { value: 'Petersburg_OH', label: 'Petersburg, OH' },
  { value: 'Petersburg_IN', label: 'Petersburg, IN' },
  { value: 'Petersburg_MI', label: 'Petersburg, MI' },
  { value: 'Petersburg_ND', label: 'Petersburg, ND' },
  { value: 'Petersburg_IL', label: 'Petersburg, IL' },
  { value: 'Petersburg_NE', label: 'Petersburg, NE' },
  { value: 'Petersburg_TX', label: 'Petersburg, TX' },
  { value: 'Petersburg_AK', label: 'Petersburg, AK' },
  { value: 'Petersham_MA', label: 'Petersham, MA' },
  { value: 'Peterson_IA', label: 'Peterson, IA' },
  { value: 'Peterson_MN', label: 'Peterson, MN' },
  { value: 'Peterstown_WV', label: 'Peterstown, WV' },
  { value: 'Petoskey_MI', label: 'Petoskey, MI' },
  {
    value: 'Petrified Forest Natl Pk_AZ',
    label: 'Petrified Forest Natl Pk, AZ',
  },
  { value: 'Petroleum_WV', label: 'Petroleum, WV' },
  { value: 'Petroleum_IN', label: 'Petroleum, IN' },
  { value: 'Petrolia_PA', label: 'Petrolia, PA' },
  { value: 'Petrolia_TX', label: 'Petrolia, TX' },
  { value: 'Petrolia_CA', label: 'Petrolia, CA' },
  { value: 'Petros_TN', label: 'Petros, TN' },
  { value: 'Pettibone_ND', label: 'Pettibone, ND' },
  { value: 'Pettigrew_AR', label: 'Pettigrew, AR' },
  { value: 'Pettisville_OH', label: 'Pettisville, OH' },
  { value: 'Pettus_TX', label: 'Pettus, TX' },
  { value: 'Petty_TX', label: 'Petty, TX' },
  { value: 'Pevely_MO', label: 'Pevely, MO' },
  { value: 'Pewamo_MI', label: 'Pewamo, MI' },
  { value: 'Pewaukee_WI', label: 'Pewaukee, WI' },
  { value: 'Pewee Valley_KY', label: 'Pewee Valley, KY' },
  { value: 'Peyton_CO', label: 'Peyton, CO' },
  { value: 'Peytona_WV', label: 'Peytona, WV' },
  { value: 'Pfafftown_NC', label: 'Pfafftown, NC' },
  { value: 'Pfeifer_KS', label: 'Pfeifer, KS' },
  { value: 'Pflugerville_TX', label: 'Pflugerville, TX' },
  { value: 'Pharr_TX', label: 'Pharr, TX' },
  { value: 'Pheba_MS', label: 'Pheba, MS' },
  { value: 'Phelan_CA', label: 'Phelan, CA' },
  { value: 'Phelps_NY', label: 'Phelps, NY' },
  { value: 'Phelps_KY', label: 'Phelps, KY' },
  { value: 'Phelps_WI', label: 'Phelps, WI' },
  { value: 'Phenix_VA', label: 'Phenix, VA' },
  { value: 'Phenix City_AL', label: 'Phenix City, AL' },
  { value: 'Phil Campbell_AL', label: 'Phil Campbell, AL' },
  { value: 'Philadelphia_NY', label: 'Philadelphia, NY' },
  { value: 'Philadelphia_PA', label: 'Philadelphia, PA' },
  { value: 'Philadelphia_TN', label: 'Philadelphia, TN' },
  { value: 'Philadelphia_MS', label: 'Philadelphia, MS' },
  { value: 'Philadelphia_MO', label: 'Philadelphia, MO' },
  { value: 'Philip_SD', label: 'Philip, SD' },
  { value: 'Philipp_MS', label: 'Philipp, MS' },
  { value: 'Philippi_WV', label: 'Philippi, WV' },
  { value: 'Philipsburg_PA', label: 'Philipsburg, PA' },
  { value: 'Philipsburg_MT', label: 'Philipsburg, MT' },
  { value: 'Phillips_ME', label: 'Phillips, ME' },
  { value: 'Phillips_WI', label: 'Phillips, WI' },
  { value: 'Phillips_NE', label: 'Phillips, NE' },
  { value: 'Phillipsburg_NJ', label: 'Phillipsburg, NJ' },
  { value: 'Phillipsburg_OH', label: 'Phillipsburg, OH' },
  { value: 'Phillipsburg_MO', label: 'Phillipsburg, MO' },
  { value: 'Phillipsburg_KS', label: 'Phillipsburg, KS' },
  { value: 'Phillipsport_NY', label: 'Phillipsport, NY' },
  { value: 'Phillipsville_CA', label: 'Phillipsville, CA' },
  { value: 'Philmont_NY', label: 'Philmont, NY' },
  { value: 'Philo_OH', label: 'Philo, OH' },
  { value: 'Philo_IL', label: 'Philo, IL' },
  { value: 'Philo_CA', label: 'Philo, CA' },
  { value: 'Philomath_OR', label: 'Philomath, OR' },
  { value: 'Philpot_KY', label: 'Philpot, KY' },
  { value: 'Phippsburg_ME', label: 'Phippsburg, ME' },
  { value: 'Phippsburg_CO', label: 'Phippsburg, CO' },
  { value: 'Phlox_WI', label: 'Phlox, WI' },
  { value: 'Phoenicia_NY', label: 'Phoenicia, NY' },
  { value: 'Phoenix_NY', label: 'Phoenix, NY' },
  { value: 'Phoenix_MD', label: 'Phoenix, MD' },
  { value: 'Phoenix_AZ', label: 'Phoenix, AZ' },
  { value: 'Phoenix_OR', label: 'Phoenix, OR' },
  { value: 'Phoenixville_PA', label: 'Phoenixville, PA' },
  { value: 'Phyllis_KY', label: 'Phyllis, KY' },
  { value: 'Piasa_IL', label: 'Piasa, IL' },
  { value: 'Picabo_ID', label: 'Picabo, ID' },
  { value: 'Picacho_AZ', label: 'Picacho, AZ' },
  { value: 'Picacho_NM', label: 'Picacho, NM' },
  { value: 'Picayune_MS', label: 'Picayune, MS' },
  { value: 'Picher_OK', label: 'Picher, OK' },
  { value: 'Pickens_WV', label: 'Pickens, WV' },
  { value: 'Pickens_SC', label: 'Pickens, SC' },
  { value: 'Pickens_MS', label: 'Pickens, MS' },
  { value: 'Pickens_AR', label: 'Pickens, AR' },
  { value: 'Pickerel_WI', label: 'Pickerel, WI' },
  { value: 'Pickering_MO', label: 'Pickering, MO' },
  { value: 'Pickerington_OH', label: 'Pickerington, OH' },
  { value: 'Pickett_WI', label: 'Pickett, WI' },
  { value: 'Pickford_MI', label: 'Pickford, MI' },
  { value: 'Pickrell_NE', label: 'Pickrell, NE' },
  { value: 'Pickstown_SD', label: 'Pickstown, SD' },
  { value: 'Pickton_TX', label: 'Pickton, TX' },
  { value: 'Pickwick Dam_TN', label: 'Pickwick Dam, TN' },
  { value: 'Pico Rivera_CA', label: 'Pico Rivera, CA' },
  { value: 'Picture Rocks_PA', label: 'Picture Rocks, PA' },
  { value: 'Pie Town_NM', label: 'Pie Town, NM' },
  { value: 'Piedmont_WV', label: 'Piedmont, WV' },
  { value: 'Piedmont_SC', label: 'Piedmont, SC' },
  { value: 'Piedmont_AL', label: 'Piedmont, AL' },
  { value: 'Piedmont_OH', label: 'Piedmont, OH' },
  { value: 'Piedmont_SD', label: 'Piedmont, SD' },
  { value: 'Piedmont_MO', label: 'Piedmont, MO' },
  { value: 'Piedmont_KS', label: 'Piedmont, KS' },
  { value: 'Piedmont_OK', label: 'Piedmont, OK' },
  { value: 'Pierce_NE', label: 'Pierce, NE' },
  { value: 'Pierce_TX', label: 'Pierce, TX' },
  { value: 'Pierce_CO', label: 'Pierce, CO' },
  { value: 'Pierce_ID', label: 'Pierce, ID' },
  { value: 'Pierce City_MO', label: 'Pierce City, MO' },
  { value: 'Piercefield_NY', label: 'Piercefield, NY' },
  { value: 'Pierceton_IN', label: 'Pierceton, IN' },
  { value: 'Pierceville_KS', label: 'Pierceville, KS' },
  { value: 'Piercy_CA', label: 'Piercy, CA' },
  { value: 'Piermont_NH', label: 'Piermont, NH' },
  { value: 'Piermont_NY', label: 'Piermont, NY' },
  { value: 'Pierpont_OH', label: 'Pierpont, OH' },
  { value: 'Pierpont_SD', label: 'Pierpont, SD' },
  { value: 'Pierre_SD', label: 'Pierre, SD' },
  { value: 'Pierre Part_LA', label: 'Pierre Part, LA' },
  { value: 'Pierrepont Manor_NY', label: 'Pierrepont Manor, NY' },
  { value: 'Pierron_IL', label: 'Pierron, IL' },
  { value: 'Pierson_FL', label: 'Pierson, FL' },
  { value: 'Pierson_MI', label: 'Pierson, MI' },
  { value: 'Pierson_IA', label: 'Pierson, IA' },
  { value: 'Pierz_MN', label: 'Pierz, MN' },
  { value: 'Piffard_NY', label: 'Piffard, NY' },
  { value: 'Pigeon_MI', label: 'Pigeon, MI' },
  { value: 'Pigeon Falls_WI', label: 'Pigeon Falls, WI' },
  { value: 'Pigeon Forge_TN', label: 'Pigeon Forge, TN' },
  { value: 'Piggott_AR', label: 'Piggott, AR' },
  { value: 'Pike_NH', label: 'Pike, NH' },
  { value: 'Pike_NY', label: 'Pike, NY' },
  { value: 'Pike Road_AL', label: 'Pike Road, AL' },
  { value: 'Pikesville_MD', label: 'Pikesville, MD' },
  { value: 'Piketon_OH', label: 'Piketon, OH' },
  { value: 'Pikeville_NC', label: 'Pikeville, NC' },
  { value: 'Pikeville_TN', label: 'Pikeville, TN' },
  { value: 'Pikeville_KY', label: 'Pikeville, KY' },
  { value: 'Pilger_NE', label: 'Pilger, NE' },
  { value: 'Pilgrim_KY', label: 'Pilgrim, KY' },
  { value: 'Pilgrims Knob_VA', label: 'Pilgrims Knob, VA' },
  { value: 'Pillager_MN', label: 'Pillager, MN' },
  { value: 'Pillow_PA', label: 'Pillow, PA' },
  { value: 'Pillsbury_ND', label: 'Pillsbury, ND' },
  { value: 'Pilot_VA', label: 'Pilot, VA' },
  { value: 'Pilot Grove_MO', label: 'Pilot Grove, MO' },
  { value: 'Pilot Hill_CA', label: 'Pilot Hill, CA' },
  { value: 'Pilot Knob_MO', label: 'Pilot Knob, MO' },
  { value: 'Pilot Mound_IA', label: 'Pilot Mound, IA' },
  { value: 'Pilot Mountain_NC', label: 'Pilot Mountain, NC' },
  { value: 'Pilot Point_TX', label: 'Pilot Point, TX' },
  { value: 'Pilot Point_AK', label: 'Pilot Point, AK' },
  { value: 'Pilot Rock_OR', label: 'Pilot Rock, OR' },
  { value: 'Pilot Station_AK', label: 'Pilot Station, AK' },
  { value: 'Pima_AZ', label: 'Pima, AZ' },
  { value: 'Pimento_IN', label: 'Pimento, IN' },
  { value: 'Pinch_WV', label: 'Pinch, WV' },
  { value: 'Pinckard_AL', label: 'Pinckard, AL' },
  { value: 'Pinckney_MI', label: 'Pinckney, MI' },
  { value: 'Pinckneyville_IL', label: 'Pinckneyville, IL' },
  { value: 'Pinconning_MI', label: 'Pinconning, MI' },
  { value: 'Pindall_AR', label: 'Pindall, AR' },
  { value: 'Pine_CO', label: 'Pine, CO' },
  { value: 'Pine_AZ', label: 'Pine, AZ' },
  { value: 'Pine Apple_AL', label: 'Pine Apple, AL' },
  { value: 'Pine Beach_NJ', label: 'Pine Beach, NJ' },
  { value: 'Pine Bluff_AR', label: 'Pine Bluff, AR' },
  { value: 'Pine Bluffs_WY', label: 'Pine Bluffs, WY' },
  { value: 'Pine Brook_NJ', label: 'Pine Brook, NJ' },
  { value: 'Pine Bush_NY', label: 'Pine Bush, NY' },
  { value: 'Pine City_NY', label: 'Pine City, NY' },
  { value: 'Pine City_MN', label: 'Pine City, MN' },
  { value: 'Pine Grove_PA', label: 'Pine Grove, PA' },
  { value: 'Pine Grove_WV', label: 'Pine Grove, WV' },
  { value: 'Pine Grove_LA', label: 'Pine Grove, LA' },
  { value: 'Pine Grove_CA', label: 'Pine Grove, CA' },
  { value: 'Pine Grove Mills_PA', label: 'Pine Grove Mills, PA' },
  { value: 'Pine Hall_NC', label: 'Pine Hall, NC' },
  { value: 'Pine Hill_NY', label: 'Pine Hill, NY' },
  { value: 'Pine Hill_AL', label: 'Pine Hill, AL' },
  { value: 'Pine Island_NY', label: 'Pine Island, NY' },
  { value: 'Pine Island_MN', label: 'Pine Island, MN' },
  { value: 'Pine Knot_KY', label: 'Pine Knot, KY' },
  { value: 'Pine Lake_GA', label: 'Pine Lake, GA' },
  { value: 'Pine Level_NC', label: 'Pine Level, NC' },
  { value: 'Pine Meadow_CT', label: 'Pine Meadow, CT' },
  { value: 'Pine Mountain_GA', label: 'Pine Mountain, GA' },
  { value: 'Pine Mountain Club_CA', label: 'Pine Mountain Club, CA' },
  { value: 'Pine Mountain Valley_GA', label: 'Pine Mountain Valley, GA' },
  { value: 'Pine Plains_NY', label: 'Pine Plains, NY' },
  { value: 'Pine Prairie_LA', label: 'Pine Prairie, LA' },
  { value: 'Pine Ridge_KY', label: 'Pine Ridge, KY' },
  { value: 'Pine Ridge_SD', label: 'Pine Ridge, SD' },
  { value: 'Pine River_WI', label: 'Pine River, WI' },
  { value: 'Pine River_MN', label: 'Pine River, MN' },
  { value: 'Pine Top_KY', label: 'Pine Top, KY' },
  { value: 'Pine Valley_NY', label: 'Pine Valley, NY' },
  { value: 'Pine Valley_UT', label: 'Pine Valley, UT' },
  { value: 'Pine Valley_CA', label: 'Pine Valley, CA' },
  { value: 'Pine Village_IN', label: 'Pine Village, IN' },
  { value: 'Pinebluff_NC', label: 'Pinebluff, NC' },
  { value: 'Pinecliffe_CO', label: 'Pinecliffe, CO' },
  { value: 'Pinecrest_CA', label: 'Pinecrest, CA' },
  { value: 'Pinedale_WY', label: 'Pinedale, WY' },
  { value: 'Pinedale_AZ', label: 'Pinedale, AZ' },
  { value: 'Pinehill_NM', label: 'Pinehill, NM' },
  { value: 'Pinehurst_NC', label: 'Pinehurst, NC' },
  { value: 'Pinehurst_GA', label: 'Pinehurst, GA' },
  { value: 'Pinehurst_TX', label: 'Pinehurst, TX' },
  { value: 'Pinehurst_ID', label: 'Pinehurst, ID' },
  { value: 'Pineland_SC', label: 'Pineland, SC' },
  { value: 'Pineland_TX', label: 'Pineland, TX' },
  { value: 'Pinellas Park_FL', label: 'Pinellas Park, FL' },
  { value: 'Pineola_NC', label: 'Pineola, NC' },
  { value: 'Pinesdale_MT', label: 'Pinesdale, MT' },
  { value: 'Pinetop_AZ', label: 'Pinetop, AZ' },
  { value: 'Pinetops_NC', label: 'Pinetops, NC' },
  { value: 'Pinetown_NC', label: 'Pinetown, NC' },
  { value: 'Pinetta_FL', label: 'Pinetta, FL' },
  { value: 'Pineview_GA', label: 'Pineview, GA' },
  { value: 'Pineville_PA', label: 'Pineville, PA' },
  { value: 'Pineville_WV', label: 'Pineville, WV' },
  { value: 'Pineville_NC', label: 'Pineville, NC' },
  { value: 'Pineville_SC', label: 'Pineville, SC' },
  { value: 'Pineville_KY', label: 'Pineville, KY' },
  { value: 'Pineville_MO', label: 'Pineville, MO' },
  { value: 'Pineville_LA', label: 'Pineville, LA' },
  { value: 'Pineville_AR', label: 'Pineville, AR' },
  { value: 'Pinewood_SC', label: 'Pinewood, SC' },
  { value: 'Piney Creek_NC', label: 'Piney Creek, NC' },
  { value: 'Piney Flats_TN', label: 'Piney Flats, TN' },
  { value: 'Piney Point_MD', label: 'Piney Point, MD' },
  { value: 'Piney River_VA', label: 'Piney River, VA' },
  { value: 'Piney Woods_MS', label: 'Piney Woods, MS' },
  { value: 'Pingree_ND', label: 'Pingree, ND' },
  { value: 'Pingree_ID', label: 'Pingree, ID' },
  { value: 'Pink Hill_NC', label: 'Pink Hill, NC' },
  { value: 'Pinnacle_NC', label: 'Pinnacle, NC' },
  { value: 'Pinola_MS', label: 'Pinola, MS' },
  { value: 'Pinole_CA', label: 'Pinole, CA' },
  { value: 'Pinon_AZ', label: 'Pinon, AZ' },
  { value: 'Pinon_NM', label: 'Pinon, NM' },
  { value: 'Pinon Hills_CA', label: 'Pinon Hills, CA' },
  { value: 'Pinopolis_SC', label: 'Pinopolis, SC' },
  { value: 'Pinos Altos_NM', label: 'Pinos Altos, NM' },
  { value: 'Pinson_AL', label: 'Pinson, AL' },
  { value: 'Pinson_TN', label: 'Pinson, TN' },
  { value: 'Pinsonfork_KY', label: 'Pinsonfork, KY' },
  { value: 'Pioche_NV', label: 'Pioche, NV' },
  { value: 'Pioneer_TN', label: 'Pioneer, TN' },
  { value: 'Pioneer_OH', label: 'Pioneer, OH' },
  { value: 'Pioneer_LA', label: 'Pioneer, LA' },
  { value: 'Pioneer_CA', label: 'Pioneer, CA' },
  { value: 'Pioneertown_CA', label: 'Pioneertown, CA' },
  { value: 'Pipe Creek_TX', label: 'Pipe Creek, TX' },
  { value: 'Piper City_IL', label: 'Piper City, IL' },
  { value: 'Pipersville_PA', label: 'Pipersville, PA' },
  { value: 'Pipestem_WV', label: 'Pipestem, WV' },
  { value: 'Pipestone_MN', label: 'Pipestone, MN' },
  { value: 'Pippa Passes_KY', label: 'Pippa Passes, KY' },
  { value: 'Piqua_OH', label: 'Piqua, OH' },
  { value: 'Piqua_KS', label: 'Piqua, KS' },
  { value: 'Pirtleville_AZ', label: 'Pirtleville, AZ' },
  { value: 'Piru_CA', label: 'Piru, CA' },
  { value: 'Piscataway_NJ', label: 'Piscataway, NJ' },
  { value: 'Piseco_NY', label: 'Piseco, NY' },
  { value: 'Pisek_ND', label: 'Pisek, ND' },
  { value: 'Pisgah_AL', label: 'Pisgah, AL' },
  { value: 'Pisgah_IA', label: 'Pisgah, IA' },
  { value: 'Pisgah Forest_NC', label: 'Pisgah Forest, NC' },
  { value: 'Pismo Beach_CA', label: 'Pismo Beach, CA' },
  { value: 'Pitcairn_PA', label: 'Pitcairn, PA' },
  { value: 'Pitcher_NY', label: 'Pitcher, NY' },
  { value: 'Pitkin_LA', label: 'Pitkin, LA' },
  { value: 'Pitkin_CO', label: 'Pitkin, CO' },
  { value: 'Pitman_NJ', label: 'Pitman, NJ' },
  { value: 'Pitman_PA', label: 'Pitman, PA' },
  { value: 'Pitsburg_OH', label: 'Pitsburg, OH' },
  { value: 'Pitts_GA', label: 'Pitts, GA' },
  { value: 'Pittsboro_NC', label: 'Pittsboro, NC' },
  { value: 'Pittsboro_MS', label: 'Pittsboro, MS' },
  { value: 'Pittsboro_IN', label: 'Pittsboro, IN' },
  { value: 'Pittsburg_NH', label: 'Pittsburg, NH' },
  { value: 'Pittsburg_IL', label: 'Pittsburg, IL' },
  { value: 'Pittsburg_MO', label: 'Pittsburg, MO' },
  { value: 'Pittsburg_KS', label: 'Pittsburg, KS' },
  { value: 'Pittsburg_OK', label: 'Pittsburg, OK' },
  { value: 'Pittsburg_TX', label: 'Pittsburg, TX' },
  { value: 'Pittsburg_CA', label: 'Pittsburg, CA' },
  { value: 'Pittsburgh_PA', label: 'Pittsburgh, PA' },
  { value: 'Pittsfield_MA', label: 'Pittsfield, MA' },
  { value: 'Pittsfield_NH', label: 'Pittsfield, NH' },
  { value: 'Pittsfield_ME', label: 'Pittsfield, ME' },
  { value: 'Pittsfield_VT', label: 'Pittsfield, VT' },
  { value: 'Pittsfield_PA', label: 'Pittsfield, PA' },
  { value: 'Pittsfield_IL', label: 'Pittsfield, IL' },
  { value: 'Pittsford_VT', label: 'Pittsford, VT' },
  { value: 'Pittsford_NY', label: 'Pittsford, NY' },
  { value: 'Pittsford_MI', label: 'Pittsford, MI' },
  { value: 'Pittston_PA', label: 'Pittston, PA' },
  { value: 'Pittstown_NJ', label: 'Pittstown, NJ' },
  { value: 'Pittsview_AL', label: 'Pittsview, AL' },
  { value: 'Pittsville_MD', label: 'Pittsville, MD' },
  { value: 'Pittsville_VA', label: 'Pittsville, VA' },
  { value: 'Pittsville_WI', label: 'Pittsville, WI' },
  { value: 'Pixley_CA', label: 'Pixley, CA' },
  { value: 'Placedo_TX', label: 'Placedo, TX' },
  { value: 'Placentia_CA', label: 'Placentia, CA' },
  { value: 'Placerville_CO', label: 'Placerville, CO' },
  { value: 'Placerville_ID', label: 'Placerville, ID' },
  { value: 'Placerville_CA', label: 'Placerville, CA' },
  { value: 'Placida_FL', label: 'Placida, FL' },
  { value: 'Placitas_NM', label: 'Placitas, NM' },
  { value: 'Plain_WI', label: 'Plain, WI' },
  { value: 'Plain City_OH', label: 'Plain City, OH' },
  { value: 'Plain Dealing_LA', label: 'Plain Dealing, LA' },
  { value: 'Plainfield_MA', label: 'Plainfield, MA' },
  { value: 'Plainfield_NH', label: 'Plainfield, NH' },
  { value: 'Plainfield_VT', label: 'Plainfield, VT' },
  { value: 'Plainfield_CT', label: 'Plainfield, CT' },
  { value: 'Plainfield_NJ', label: 'Plainfield, NJ' },
  { value: 'Plainfield_PA', label: 'Plainfield, PA' },
  { value: 'Plainfield_OH', label: 'Plainfield, OH' },
  { value: 'Plainfield_IN', label: 'Plainfield, IN' },
  { value: 'Plainfield_IA', label: 'Plainfield, IA' },
  { value: 'Plainfield_WI', label: 'Plainfield, WI' },
  { value: 'Plainfield_IL', label: 'Plainfield, IL' },
  { value: 'Plains_GA', label: 'Plains, GA' },
  { value: 'Plains_MT', label: 'Plains, MT' },
  { value: 'Plains_KS', label: 'Plains, KS' },
  { value: 'Plains_TX', label: 'Plains, TX' },
  { value: 'Plainsboro_NJ', label: 'Plainsboro, NJ' },
  { value: 'Plainview_NY', label: 'Plainview, NY' },
  { value: 'Plainview_MN', label: 'Plainview, MN' },
  { value: 'Plainview_NE', label: 'Plainview, NE' },
  { value: 'Plainview_AR', label: 'Plainview, AR' },
  { value: 'Plainview_TX', label: 'Plainview, TX' },
  { value: 'Plainville_MA', label: 'Plainville, MA' },
  { value: 'Plainville_CT', label: 'Plainville, CT' },
  { value: 'Plainville_GA', label: 'Plainville, GA' },
  { value: 'Plainville_IN', label: 'Plainville, IN' },
  { value: 'Plainville_IL', label: 'Plainville, IL' },
  { value: 'Plainville_KS', label: 'Plainville, KS' },
  { value: 'Plainwell_MI', label: 'Plainwell, MI' },
  { value: 'Plaistow_NH', label: 'Plaistow, NH' },
  { value: 'Planada_CA', label: 'Planada, CA' },
  { value: 'Plankinton_SD', label: 'Plankinton, SD' },
  { value: 'Plano_IA', label: 'Plano, IA' },
  { value: 'Plano_IL', label: 'Plano, IL' },
  { value: 'Plano_TX', label: 'Plano, TX' },
  { value: 'Plant City_FL', label: 'Plant City, FL' },
  { value: 'Plantation_FL', label: 'Plantation, FL' },
  { value: 'Plantersville_AL', label: 'Plantersville, AL' },
  { value: 'Plantersville_MS', label: 'Plantersville, MS' },
  { value: 'Plantersville_TX', label: 'Plantersville, TX' },
  { value: 'Plantsville_CT', label: 'Plantsville, CT' },
  { value: 'Plaquemine_LA', label: 'Plaquemine, LA' },
  { value: 'Platina_CA', label: 'Platina, CA' },
  { value: 'Platinum_AK', label: 'Platinum, AK' },
  { value: 'Plato_MN', label: 'Plato, MN' },
  { value: 'Plato_MO', label: 'Plato, MO' },
  { value: 'Platte_SD', label: 'Platte, SD' },
  { value: 'Platte Center_NE', label: 'Platte Center, NE' },
  { value: 'Platte City_MO', label: 'Platte City, MO' },
  { value: 'Plattenville_LA', label: 'Plattenville, LA' },
  { value: 'Platter_OK', label: 'Platter, OK' },
  { value: 'Platteville_WI', label: 'Platteville, WI' },
  { value: 'Platteville_CO', label: 'Platteville, CO' },
  { value: 'Plattsburg_MO', label: 'Plattsburg, MO' },
  { value: 'Plattsburgh_NY', label: 'Plattsburgh, NY' },
  { value: 'Plattsmouth_NE', label: 'Plattsmouth, NE' },
  { value: 'Plaucheville_LA', label: 'Plaucheville, LA' },
  { value: 'Playa Del Rey_CA', label: 'Playa Del Rey, CA' },
  { value: 'Playa Vista_CA', label: 'Playa Vista, CA' },
  { value: 'Playas_NM', label: 'Playas, NM' },
  { value: 'Plaza_ND', label: 'Plaza, ND' },
  { value: 'Pleasant City_OH', label: 'Pleasant City, OH' },
  { value: 'Pleasant Dale_NE', label: 'Pleasant Dale, NE' },
  { value: 'Pleasant Garden_NC', label: 'Pleasant Garden, NC' },
  { value: 'Pleasant Grove_AL', label: 'Pleasant Grove, AL' },
  { value: 'Pleasant Grove_AR', label: 'Pleasant Grove, AR' },
  { value: 'Pleasant Grove_UT', label: 'Pleasant Grove, UT' },
  { value: 'Pleasant Grove_CA', label: 'Pleasant Grove, CA' },
  { value: 'Pleasant Hall_PA', label: 'Pleasant Hall, PA' },
  { value: 'Pleasant Hill_NC', label: 'Pleasant Hill, NC' },
  { value: 'Pleasant Hill_TN', label: 'Pleasant Hill, TN' },
  { value: 'Pleasant Hill_OH', label: 'Pleasant Hill, OH' },
  { value: 'Pleasant Hill_IA', label: 'Pleasant Hill, IA' },
  { value: 'Pleasant Hill_IL', label: 'Pleasant Hill, IL' },
  { value: 'Pleasant Hill_MO', label: 'Pleasant Hill, MO' },
  { value: 'Pleasant Hill_LA', label: 'Pleasant Hill, LA' },
  { value: 'Pleasant Hill_CA', label: 'Pleasant Hill, CA' },
  { value: 'Pleasant Hill_OR', label: 'Pleasant Hill, OR' },
  { value: 'Pleasant Hope_MO', label: 'Pleasant Hope, MO' },
  { value: 'Pleasant Lake_IN', label: 'Pleasant Lake, IN' },
  { value: 'Pleasant Lake_MI', label: 'Pleasant Lake, MI' },
  { value: 'Pleasant Mount_PA', label: 'Pleasant Mount, PA' },
  { value: 'Pleasant Plain_OH', label: 'Pleasant Plain, OH' },
  { value: 'Pleasant Plains_IL', label: 'Pleasant Plains, IL' },
  { value: 'Pleasant Plains_AR', label: 'Pleasant Plains, AR' },
  { value: 'Pleasant Prairie_WI', label: 'Pleasant Prairie, WI' },
  { value: 'Pleasant Ridge_MI', label: 'Pleasant Ridge, MI' },
  { value: 'Pleasant Shade_TN', label: 'Pleasant Shade, TN' },
  { value: 'Pleasant Unity_PA', label: 'Pleasant Unity, PA' },
  { value: 'Pleasant Valley_NY', label: 'Pleasant Valley, NY' },
  { value: 'Pleasant Valley_IA', label: 'Pleasant Valley, IA' },
  { value: 'Pleasant View_TN', label: 'Pleasant View, TN' },
  { value: 'Pleasant View_CO', label: 'Pleasant View, CO' },
  { value: 'Pleasanton_KS', label: 'Pleasanton, KS' },
  { value: 'Pleasanton_NE', label: 'Pleasanton, NE' },
  { value: 'Pleasanton_TX', label: 'Pleasanton, TX' },
  { value: 'Pleasanton_CA', label: 'Pleasanton, CA' },
  { value: 'Pleasantville_NJ', label: 'Pleasantville, NJ' },
  { value: 'Pleasantville_NY', label: 'Pleasantville, NY' },
  { value: 'Pleasantville_PA', label: 'Pleasantville, PA' },
  { value: 'Pleasantville_OH', label: 'Pleasantville, OH' },
  { value: 'Pleasantville_IA', label: 'Pleasantville, IA' },
  { value: 'Pleasureville_KY', label: 'Pleasureville, KY' },
  { value: 'Pledger_TX', label: 'Pledger, TX' },
  { value: 'Plentywood_MT', label: 'Plentywood, MT' },
  { value: 'Plessis_NY', label: 'Plessis, NY' },
  { value: 'Plevna_MT', label: 'Plevna, MT' },
  { value: 'Plevna_KS', label: 'Plevna, KS' },
  { value: 'Plover_IA', label: 'Plover, IA' },
  { value: 'Plover_WI', label: 'Plover, WI' },
  { value: 'Plum Branch_SC', label: 'Plum Branch, SC' },
  { value: 'Plum City_WI', label: 'Plum City, WI' },
  { value: 'Plumerville_AR', label: 'Plumerville, AR' },
  { value: 'Plummer_MN', label: 'Plummer, MN' },
  { value: 'Plummer_ID', label: 'Plummer, ID' },
  { value: 'Plumtree_NC', label: 'Plumtree, NC' },
  { value: 'Plumville_PA', label: 'Plumville, PA' },
  { value: 'Plush_OR', label: 'Plush, OR' },
  { value: 'Plymouth_MA', label: 'Plymouth, MA' },
  { value: 'Plymouth_NH', label: 'Plymouth, NH' },
  { value: 'Plymouth_ME', label: 'Plymouth, ME' },
  { value: 'Plymouth_VT', label: 'Plymouth, VT' },
  { value: 'Plymouth_CT', label: 'Plymouth, CT' },
  { value: 'Plymouth_NY', label: 'Plymouth, NY' },
  { value: 'Plymouth_PA', label: 'Plymouth, PA' },
  { value: 'Plymouth_NC', label: 'Plymouth, NC' },
  { value: 'Plymouth_FL', label: 'Plymouth, FL' },
  { value: 'Plymouth_OH', label: 'Plymouth, OH' },
  { value: 'Plymouth_IN', label: 'Plymouth, IN' },
  { value: 'Plymouth_MI', label: 'Plymouth, MI' },
  { value: 'Plymouth_IA', label: 'Plymouth, IA' },
  { value: 'Plymouth_WI', label: 'Plymouth, WI' },
  { value: 'Plymouth_IL', label: 'Plymouth, IL' },
  { value: 'Plymouth_NE', label: 'Plymouth, NE' },
  { value: 'Plymouth_UT', label: 'Plymouth, UT' },
  { value: 'Plymouth_CA', label: 'Plymouth, CA' },
  { value: 'Plymouth_WA', label: 'Plymouth, WA' },
  { value: 'Plymouth Meeting_PA', label: 'Plymouth Meeting, PA' },
  { value: 'Plympton_MA', label: 'Plympton, MA' },
  { value: 'Poca_WV', label: 'Poca, WV' },
  { value: 'Pocahontas_VA', label: 'Pocahontas, VA' },
  { value: 'Pocahontas_TN', label: 'Pocahontas, TN' },
  { value: 'Pocahontas_IA', label: 'Pocahontas, IA' },
  { value: 'Pocahontas_IL', label: 'Pocahontas, IL' },
  { value: 'Pocahontas_AR', label: 'Pocahontas, AR' },
  { value: 'Pocasset_MA', label: 'Pocasset, MA' },
  { value: 'Pocasset_OK', label: 'Pocasset, OK' },
  { value: 'Pocatello_ID', label: 'Pocatello, ID' },
  { value: 'Pocola_OK', label: 'Pocola, OK' },
  { value: 'Pocomoke City_MD', label: 'Pocomoke City, MD' },
  { value: 'Pocono Lake_PA', label: 'Pocono Lake, PA' },
  { value: 'Pocono Manor_PA', label: 'Pocono Manor, PA' },
  { value: 'Pocono Pines_PA', label: 'Pocono Pines, PA' },
  { value: 'Pocono Summit_PA', label: 'Pocono Summit, PA' },
  { value: 'Poestenkill_NY', label: 'Poestenkill, NY' },
  { value: 'Point_TX', label: 'Point, TX' },
  { value: 'Point Arena_CA', label: 'Point Arena, CA' },
  { value: 'Point Baker_AK', label: 'Point Baker, AK' },
  { value: 'Point Clear_AL', label: 'Point Clear, AL' },
  { value: 'Point Comfort_TX', label: 'Point Comfort, TX' },
  { value: 'Point Harbor_NC', label: 'Point Harbor, NC' },
  { value: 'Point Hope_AK', label: 'Point Hope, AK' },
  { value: 'Point Lay_AK', label: 'Point Lay, AK' },
  { value: 'Point Lookout_NY', label: 'Point Lookout, NY' },
  { value: 'Point Lookout_MO', label: 'Point Lookout, MO' },
  { value: 'Point Marion_PA', label: 'Point Marion, PA' },
  { value: 'Point Mugu Nawc_CA', label: 'Point Mugu Nawc, CA' },
  { value: 'Point Of Rocks_MD', label: 'Point Of Rocks, MD' },
  { value: 'Point Of Rocks_WY', label: 'Point Of Rocks, WY' },
  { value: 'Point Pleasant_PA', label: 'Point Pleasant, PA' },
  { value: 'Point Pleasant_WV', label: 'Point Pleasant, WV' },
  { value: 'Point Pleasant Beach_NJ', label: 'Point Pleasant Beach, NJ' },
  { value: 'Point Reyes Station_CA', label: 'Point Reyes Station, CA' },
  { value: 'Point Roberts_WA', label: 'Point Roberts, WA' },
  { value: 'Pointblank_TX', label: 'Pointblank, TX' },
  { value: 'Pointe A La Hache_LA', label: 'Pointe A La Hache, LA' },
  { value: 'Pointe Aux Pins_MI', label: 'Pointe Aux Pins, MI' },
  { value: 'Points_WV', label: 'Points, WV' },
  { value: 'Polacca_AZ', label: 'Polacca, AZ' },
  { value: 'Poland_ME', label: 'Poland, ME' },
  { value: 'Poland_NY', label: 'Poland, NY' },
  { value: 'Poland_IN', label: 'Poland, IN' },
  { value: 'Polaris_MT', label: 'Polaris, MT' },
  { value: 'Polebridge_MT', label: 'Polebridge, MT' },
  { value: 'Polk_PA', label: 'Polk, PA' },
  { value: 'Polk_OH', label: 'Polk, OH' },
  { value: 'Polk_MO', label: 'Polk, MO' },
  { value: 'Polk_NE', label: 'Polk, NE' },
  { value: 'Polk City_FL', label: 'Polk City, FL' },
  { value: 'Polk City_IA', label: 'Polk City, IA' },
  { value: 'Polkton_NC', label: 'Polkton, NC' },
  { value: 'Polkville_NC', label: 'Polkville, NC' },
  { value: 'Pollard_AR', label: 'Pollard, AR' },
  { value: 'Pollock_SD', label: 'Pollock, SD' },
  { value: 'Pollock_MO', label: 'Pollock, MO' },
  { value: 'Pollock_LA', label: 'Pollock, LA' },
  { value: 'Pollock_ID', label: 'Pollock, ID' },
  { value: 'Pollock Pines_CA', label: 'Pollock Pines, CA' },
  { value: 'Pollocksville_NC', label: 'Pollocksville, NC' },
  { value: 'Pollok_TX', label: 'Pollok, TX' },
  { value: 'Polo_IL', label: 'Polo, IL' },
  { value: 'Polo_MO', label: 'Polo, MO' },
  { value: 'Polson_MT', label: 'Polson, MT' },
  { value: 'Polvadera_NM', label: 'Polvadera, NM' },
  { value: 'Pomaria_SC', label: 'Pomaria, SC' },
  { value: 'Pomerene_AZ', label: 'Pomerene, AZ' },
  { value: 'Pomeroy_PA', label: 'Pomeroy, PA' },
  { value: 'Pomeroy_OH', label: 'Pomeroy, OH' },
  { value: 'Pomeroy_IA', label: 'Pomeroy, IA' },
  { value: 'Pomeroy_WA', label: 'Pomeroy, WA' },
  { value: 'Pomfret_CT', label: 'Pomfret, CT' },
  { value: 'Pomfret_MD', label: 'Pomfret, MD' },
  { value: 'Pomfret Center_CT', label: 'Pomfret Center, CT' },
  { value: 'Pomona_NJ', label: 'Pomona, NJ' },
  { value: 'Pomona_NY', label: 'Pomona, NY' },
  { value: 'Pomona_IL', label: 'Pomona, IL' },
  { value: 'Pomona_MO', label: 'Pomona, MO' },
  { value: 'Pomona_KS', label: 'Pomona, KS' },
  { value: 'Pomona_CA', label: 'Pomona, CA' },
  { value: 'Pomona Park_FL', label: 'Pomona Park, FL' },
  { value: 'Pompano Beach_FL', label: 'Pompano Beach, FL' },
  { value: 'Pompeii_MI', label: 'Pompeii, MI' },
  { value: 'Pompey_NY', label: 'Pompey, NY' },
  { value: 'Pompeys Pillar_MT', label: 'Pompeys Pillar, MT' },
  { value: 'Pompton Lakes_NJ', label: 'Pompton Lakes, NJ' },
  { value: 'Pompton Plains_NJ', label: 'Pompton Plains, NJ' },
  { value: 'Ponca_NE', label: 'Ponca, NE' },
  { value: 'Ponca_AR', label: 'Ponca, AR' },
  { value: 'Ponca City_OK', label: 'Ponca City, OK' },
  { value: 'Ponce_PR', label: 'Ponce, PR' },
  { value: 'Ponce De Leon_FL', label: 'Ponce De Leon, FL' },
  { value: 'Ponce De Leon_MO', label: 'Ponce De Leon, MO' },
  { value: 'Poncha Springs_CO', label: 'Poncha Springs, CO' },
  { value: 'Ponchatoula_LA', label: 'Ponchatoula, LA' },
  { value: 'Pond Creek_OK', label: 'Pond Creek, OK' },
  { value: 'Pond Eddy_NY', label: 'Pond Eddy, NY' },
  { value: 'Pond Gap_WV', label: 'Pond Gap, WV' },
  { value: 'Ponder_TX', label: 'Ponder, TX' },
  { value: 'Ponderay_ID', label: 'Ponderay, ID' },
  { value: 'Ponderosa_NM', label: 'Ponderosa, NM' },
  { value: 'Ponemah_MN', label: 'Ponemah, MN' },
  { value: 'Poneto_IN', label: 'Poneto, IN' },
  { value: 'Ponsford_MN', label: 'Ponsford, MN' },
  { value: 'Ponte Vedra_FL', label: 'Ponte Vedra, FL' },
  { value: 'Ponte Vedra Beach_FL', label: 'Ponte Vedra Beach, FL' },
  { value: 'Pontiac_MI', label: 'Pontiac, MI' },
  { value: 'Pontiac_IL', label: 'Pontiac, IL' },
  { value: 'Pontiac_MO', label: 'Pontiac, MO' },
  { value: 'Pontotoc_MS', label: 'Pontotoc, MS' },
  { value: 'Pontotoc_TX', label: 'Pontotoc, TX' },
  { value: 'Pony_MT', label: 'Pony, MT' },
  { value: 'Pool_WV', label: 'Pool, WV' },
  { value: 'Poole_KY', label: 'Poole, KY' },
  { value: 'Pooler_GA', label: 'Pooler, GA' },
  { value: 'Poolesville_MD', label: 'Poolesville, MD' },
  { value: 'Poolville_TX', label: 'Poolville, TX' },
  { value: 'Pope_MS', label: 'Pope, MS' },
  { value: 'Pope Army Airfield_NC', label: 'Pope Army Airfield, NC' },
  { value: 'Pope Valley_CA', label: 'Pope Valley, CA' },
  { value: 'Popejoy_IA', label: 'Popejoy, IA' },
  { value: 'Poplar_WI', label: 'Poplar, WI' },
  { value: 'Poplar_MT', label: 'Poplar, MT' },
  { value: 'Poplar Bluff_MO', label: 'Poplar Bluff, MO' },
  { value: 'Poplar Branch_NC', label: 'Poplar Branch, NC' },
  { value: 'Poplar Grove_IL', label: 'Poplar Grove, IL' },
  { value: 'Poplar Grove_AR', label: 'Poplar Grove, AR' },
  { value: 'Poplarville_MS', label: 'Poplarville, MS' },
  { value: 'Poquoson_VA', label: 'Poquoson, VA' },
  { value: 'Porcupine_SD', label: 'Porcupine, SD' },
  { value: 'Port Alexander_AK', label: 'Port Alexander, AK' },
  { value: 'Port Allegany_PA', label: 'Port Allegany, PA' },
  { value: 'Port Allen_LA', label: 'Port Allen, LA' },
  { value: 'Port Alsworth_AK', label: 'Port Alsworth, AK' },
  { value: 'Port Angeles_WA', label: 'Port Angeles, WA' },
  { value: 'Port Aransas_TX', label: 'Port Aransas, TX' },
  { value: 'Port Arthur_TX', label: 'Port Arthur, TX' },
  { value: 'Port Austin_MI', label: 'Port Austin, MI' },
  { value: 'Port Barre_LA', label: 'Port Barre, LA' },
  { value: 'Port Bolivar_TX', label: 'Port Bolivar, TX' },
  { value: 'Port Byron_NY', label: 'Port Byron, NY' },
  { value: 'Port Byron_IL', label: 'Port Byron, IL' },
  { value: 'Port Carbon_PA', label: 'Port Carbon, PA' },
  { value: 'Port Charlotte_FL', label: 'Port Charlotte, FL' },
  { value: 'Port Chester_NY', label: 'Port Chester, NY' },
  { value: 'Port Clinton_PA', label: 'Port Clinton, PA' },
  { value: 'Port Clinton_OH', label: 'Port Clinton, OH' },
  { value: 'Port Clyde_ME', label: 'Port Clyde, ME' },
  { value: 'Port Costa_CA', label: 'Port Costa, CA' },
  { value: 'Port Crane_NY', label: 'Port Crane, NY' },
  { value: 'Port Deposit_MD', label: 'Port Deposit, MD' },
  { value: 'Port Edwards_WI', label: 'Port Edwards, WI' },
  { value: 'Port Elizabeth_NJ', label: 'Port Elizabeth, NJ' },
  { value: 'Port Ewen_NY', label: 'Port Ewen, NY' },
  { value: 'Port Gamble_WA', label: 'Port Gamble, WA' },
  { value: 'Port Gibson_NY', label: 'Port Gibson, NY' },
  { value: 'Port Gibson_MS', label: 'Port Gibson, MS' },
  { value: 'Port Hadlock_WA', label: 'Port Hadlock, WA' },
  { value: 'Port Haywood_VA', label: 'Port Haywood, VA' },
  { value: 'Port Heiden_AK', label: 'Port Heiden, AK' },
  { value: 'Port Henry_NY', label: 'Port Henry, NY' },
  { value: 'Port Hope_MI', label: 'Port Hope, MI' },
  { value: 'Port Hueneme_CA', label: 'Port Hueneme, CA' },
  { value: 'Port Hueneme Cbc Base_CA', label: 'Port Hueneme Cbc Base, CA' },
  { value: 'Port Huron_MI', label: 'Port Huron, MI' },
  { value: 'Port Isabel_TX', label: 'Port Isabel, TX' },
  { value: 'Port Jefferson_NY', label: 'Port Jefferson, NY' },
  { value: 'Port Jefferson_OH', label: 'Port Jefferson, OH' },
  { value: 'Port Jefferson Station_NY', label: 'Port Jefferson Station, NY' },
  { value: 'Port Jervis_NY', label: 'Port Jervis, NY' },
  { value: 'Port Kent_NY', label: 'Port Kent, NY' },
  { value: 'Port Lavaca_TX', label: 'Port Lavaca, TX' },
  { value: 'Port Leyden_NY', label: 'Port Leyden, NY' },
  { value: 'Port Lions_AK', label: 'Port Lions, AK' },
  { value: 'Port Ludlow_WA', label: 'Port Ludlow, WA' },
  { value: 'Port Mansfield_TX', label: 'Port Mansfield, TX' },
  { value: 'Port Matilda_PA', label: 'Port Matilda, PA' },
  { value: 'Port Monmouth_NJ', label: 'Port Monmouth, NJ' },
  { value: 'Port Murray_NJ', label: 'Port Murray, NJ' },
  { value: 'Port Neches_TX', label: 'Port Neches, TX' },
  { value: 'Port Norris_NJ', label: 'Port Norris, NJ' },
  { value: "Port O'Connor_TX", label: "Port O'Connor, TX" },
  { value: 'Port Orange_FL', label: 'Port Orange, FL' },
  { value: 'Port Orchard_WA', label: 'Port Orchard, WA' },
  { value: 'Port Orford_OR', label: 'Port Orford, OR' },
  { value: 'Port Penn_DE', label: 'Port Penn, DE' },
  { value: 'Port Reading_NJ', label: 'Port Reading, NJ' },
  { value: 'Port Republic_NJ', label: 'Port Republic, NJ' },
  { value: 'Port Republic_MD', label: 'Port Republic, MD' },
  { value: 'Port Republic_VA', label: 'Port Republic, VA' },
  { value: 'Port Richey_FL', label: 'Port Richey, FL' },
  { value: 'Port Royal_PA', label: 'Port Royal, PA' },
  { value: 'Port Royal_VA', label: 'Port Royal, VA' },
  { value: 'Port Royal_SC', label: 'Port Royal, SC' },
  { value: 'Port Royal_KY', label: 'Port Royal, KY' },
  { value: 'Port St Joe_FL', label: 'Port St Joe, FL' },
  { value: 'Port St Lucie_FL', label: 'Port St Lucie, FL' },
  { value: 'Port Sanilac_MI', label: 'Port Sanilac, MI' },
  { value: 'Port Sulphur_LA', label: 'Port Sulphur, LA' },
  { value: 'Port Tobacco_MD', label: 'Port Tobacco, MD' },
  { value: 'Port Townsend_WA', label: 'Port Townsend, WA' },
  { value: 'Port Trevorton_PA', label: 'Port Trevorton, PA' },
  { value: 'Port Washington_NY', label: 'Port Washington, NY' },
  { value: 'Port Washington_OH', label: 'Port Washington, OH' },
  { value: 'Port Washington_WI', label: 'Port Washington, WI' },
  { value: 'Port Wentworth_GA', label: 'Port Wentworth, GA' },
  { value: 'Port William_OH', label: 'Port William, OH' },
  { value: 'Port Wing_WI', label: 'Port Wing, WI' },
  { value: 'Portage_ME', label: 'Portage, ME' },
  { value: 'Portage_PA', label: 'Portage, PA' },
  { value: 'Portage_OH', label: 'Portage, OH' },
  { value: 'Portage_IN', label: 'Portage, IN' },
  { value: 'Portage_MI', label: 'Portage, MI' },
  { value: 'Portage_WI', label: 'Portage, WI' },
  { value: 'Portage_UT', label: 'Portage, UT' },
  { value: 'Portage Des Sioux_MO', label: 'Portage Des Sioux, MO' },
  { value: 'Portageville_NY', label: 'Portageville, NY' },
  { value: 'Portageville_MO', label: 'Portageville, MO' },
  { value: 'Portal_GA', label: 'Portal, GA' },
  { value: 'Portal_ND', label: 'Portal, ND' },
  { value: 'Portales_NM', label: 'Portales, NM' },
  { value: 'Porter_ME', label: 'Porter, ME' },
  { value: 'Porter_MN', label: 'Porter, MN' },
  { value: 'Porter_OK', label: 'Porter, OK' },
  { value: 'Porter_TX', label: 'Porter, TX' },
  { value: 'Porter Corners_NY', label: 'Porter Corners, NY' },
  { value: 'Porter Ranch_CA', label: 'Porter Ranch, CA' },
  { value: 'Porterdale_GA', label: 'Porterdale, GA' },
  { value: 'Porterfield_WI', label: 'Porterfield, WI' },
  { value: 'Porters Falls_WV', label: 'Porters Falls, WV' },
  { value: 'Portersville_PA', label: 'Portersville, PA' },
  { value: 'Porterville_MS', label: 'Porterville, MS' },
  { value: 'Porterville_CA', label: 'Porterville, CA' },
  { value: 'Portia_AR', label: 'Portia, AR' },
  { value: 'Portis_KS', label: 'Portis, KS' },
  { value: 'Portland_ME', label: 'Portland, ME' },
  { value: 'Portland_CT', label: 'Portland, CT' },
  { value: 'Portland_NY', label: 'Portland, NY' },
  { value: 'Portland_PA', label: 'Portland, PA' },
  { value: 'Portland_TN', label: 'Portland, TN' },
  { value: 'Portland_OH', label: 'Portland, OH' },
  { value: 'Portland_IN', label: 'Portland, IN' },
  { value: 'Portland_MI', label: 'Portland, MI' },
  { value: 'Portland_ND', label: 'Portland, ND' },
  { value: 'Portland_MO', label: 'Portland, MO' },
  { value: 'Portland_AR', label: 'Portland, AR' },
  { value: 'Portland_TX', label: 'Portland, TX' },
  { value: 'Portland_OR', label: 'Portland, OR' },
  { value: 'Portlandville_NY', label: 'Portlandville, NY' },
  { value: 'Portola_CA', label: 'Portola, CA' },
  { value: 'Portola Valley_CA', label: 'Portola Valley, CA' },
  { value: 'Portsmouth_RI', label: 'Portsmouth, RI' },
  { value: 'Portsmouth_NH', label: 'Portsmouth, NH' },
  { value: 'Portsmouth_VA', label: 'Portsmouth, VA' },
  { value: 'Portsmouth_OH', label: 'Portsmouth, OH' },
  { value: 'Portsmouth_IA', label: 'Portsmouth, IA' },
  { value: 'Portville_NY', label: 'Portville, NY' },
  { value: 'Porum_OK', label: 'Porum, OK' },
  { value: 'Posen_MI', label: 'Posen, MI' },
  { value: 'Posen_IL', label: 'Posen, IL' },
  { value: 'Posey_CA', label: 'Posey, CA' },
  { value: 'Poseyville_IN', label: 'Poseyville, IN' },
  { value: 'Post_TX', label: 'Post, TX' },
  { value: 'Post_OR', label: 'Post, OR' },
  { value: 'Post Falls_ID', label: 'Post Falls, ID' },
  { value: 'Post Mills_VT', label: 'Post Mills, VT' },
  { value: 'Poston_AZ', label: 'Poston, AZ' },
  { value: 'Postville_IA', label: 'Postville, IA' },
  { value: 'Poteau_OK', label: 'Poteau, OK' },
  { value: 'Poteet_TX', label: 'Poteet, TX' },
  { value: 'Poth_TX', label: 'Poth, TX' },
  { value: 'Potlatch_ID', label: 'Potlatch, ID' },
  { value: 'Potomac_MD', label: 'Potomac, MD' },
  { value: 'Potomac_IL', label: 'Potomac, IL' },
  { value: 'Potosi_WI', label: 'Potosi, WI' },
  { value: 'Potosi_MO', label: 'Potosi, MO' },
  { value: 'Potrero_CA', label: 'Potrero, CA' },
  { value: 'Potsdam_NY', label: 'Potsdam, NY' },
  { value: 'Potsdam_OH', label: 'Potsdam, OH' },
  { value: 'Potter_WI', label: 'Potter, WI' },
  { value: 'Potter_NE', label: 'Potter, NE' },
  { value: 'Potter Valley_CA', label: 'Potter Valley, CA' },
  { value: 'Pottersdale_PA', label: 'Pottersdale, PA' },
  { value: 'Pottersville_NJ', label: 'Pottersville, NJ' },
  { value: 'Pottersville_NY', label: 'Pottersville, NY' },
  { value: 'Pottersville_MO', label: 'Pottersville, MO' },
  { value: 'Potterville_MI', label: 'Potterville, MI' },
  { value: 'Potts Camp_MS', label: 'Potts Camp, MS' },
  { value: 'Potts Grove_PA', label: 'Potts Grove, PA' },
  { value: 'Pottsboro_TX', label: 'Pottsboro, TX' },
  { value: 'Pottstown_PA', label: 'Pottstown, PA' },
  { value: 'Pottsville_PA', label: 'Pottsville, PA' },
  { value: 'Pottsville_AR', label: 'Pottsville, AR' },
  { value: 'Pottsville_TX', label: 'Pottsville, TX' },
  { value: 'Potwin_KS', label: 'Potwin, KS' },
  { value: 'Poughkeepsie_NY', label: 'Poughkeepsie, NY' },
  { value: 'Poughkeepsie_AR', label: 'Poughkeepsie, AR' },
  { value: 'Poughquag_NY', label: 'Poughquag, NY' },
  { value: 'Poulan_GA', label: 'Poulan, GA' },
  { value: 'Poulsbo_WA', label: 'Poulsbo, WA' },
  { value: 'Poultney_VT', label: 'Poultney, VT' },
  { value: 'Pound_VA', label: 'Pound, VA' },
  { value: 'Pound_WI', label: 'Pound, WI' },
  { value: 'Pound Ridge_NY', label: 'Pound Ridge, NY' },
  { value: 'Pounding Mill_VA', label: 'Pounding Mill, VA' },
  { value: 'Poway_CA', label: 'Poway, CA' },
  { value: 'Powder River_WY', label: 'Powder River, WY' },
  { value: 'Powder Springs_GA', label: 'Powder Springs, GA' },
  { value: 'Powder Springs_TN', label: 'Powder Springs, TN' },
  { value: 'Powderhorn_CO', label: 'Powderhorn, CO' },
  { value: 'Powderly_KY', label: 'Powderly, KY' },
  { value: 'Powderly_TX', label: 'Powderly, TX' },
  { value: 'Powderville_MT', label: 'Powderville, MT' },
  { value: 'Powell_TN', label: 'Powell, TN' },
  { value: 'Powell_OH', label: 'Powell, OH' },
  { value: 'Powell_MO', label: 'Powell, MO' },
  { value: 'Powell_TX', label: 'Powell, TX' },
  { value: 'Powell_WY', label: 'Powell, WY' },
  { value: 'Powell Butte_OR', label: 'Powell Butte, OR' },
  { value: 'Powells Point_NC', label: 'Powells Point, NC' },
  { value: 'Powellsville_NC', label: 'Powellsville, NC' },
  { value: 'Powellton_WV', label: 'Powellton, WV' },
  { value: 'Power_MT', label: 'Power, MT' },
  { value: 'Powers_MI', label: 'Powers, MI' },
  { value: 'Powers_OR', label: 'Powers, OR' },
  { value: 'Powers Lake_ND', label: 'Powers Lake, ND' },
  { value: 'Powersite_MO', label: 'Powersite, MO' },
  { value: 'Powersville_MO', label: 'Powersville, MO' },
  { value: 'Powhatan_VA', label: 'Powhatan, VA' },
  { value: 'Powhatan_LA', label: 'Powhatan, LA' },
  { value: 'Powhatan_AR', label: 'Powhatan, AR' },
  { value: 'Powhatan Point_OH', label: 'Powhatan Point, OH' },
  { value: 'Powhattan_KS', label: 'Powhattan, KS' },
  { value: 'Pownal_ME', label: 'Pownal, ME' },
  { value: 'Pownal_VT', label: 'Pownal, VT' },
  { value: 'Poy Sippi_WI', label: 'Poy Sippi, WI' },
  { value: 'Poyen_AR', label: 'Poyen, AR' },
  { value: 'Poynette_WI', label: 'Poynette, WI' },
  { value: 'Poynor_TX', label: 'Poynor, TX' },
  { value: 'Poyntelle_PA', label: 'Poyntelle, PA' },
  { value: 'Prague_NE', label: 'Prague, NE' },
  { value: 'Prague_OK', label: 'Prague, OK' },
  { value: 'Prairie_MS', label: 'Prairie, MS' },
  { value: 'Prairie City_IA', label: 'Prairie City, IA' },
  { value: 'Prairie City_SD', label: 'Prairie City, SD' },
  { value: 'Prairie City_IL', label: 'Prairie City, IL' },
  { value: 'Prairie City_OR', label: 'Prairie City, OR' },
  { value: 'Prairie Creek_IN', label: 'Prairie Creek, IN' },
  { value: 'Prairie Du Chien_WI', label: 'Prairie Du Chien, WI' },
  { value: 'Prairie Du Rocher_IL', label: 'Prairie Du Rocher, IL' },
  { value: 'Prairie Du Sac_WI', label: 'Prairie Du Sac, WI' },
  { value: 'Prairie Farm_WI', label: 'Prairie Farm, WI' },
  { value: 'Prairie Grove_AR', label: 'Prairie Grove, AR' },
  { value: 'Prairie Hill_TX', label: 'Prairie Hill, TX' },
  { value: 'Prairie Home_MO', label: 'Prairie Home, MO' },
  { value: 'Prairie Lea_TX', label: 'Prairie Lea, TX' },
  { value: 'Prairie View_KS', label: 'Prairie View, KS' },
  { value: 'Prairie View_TX', label: 'Prairie View, TX' },
  { value: 'Prairie Village_KS', label: 'Prairie Village, KS' },
  { value: 'Prairieburg_IA', label: 'Prairieburg, IA' },
  { value: 'Prairieton_IN', label: 'Prairieton, IN' },
  { value: 'Prairieville_LA', label: 'Prairieville, LA' },
  { value: 'Prather_CA', label: 'Prather, CA' },
  { value: 'Pratt_WV', label: 'Pratt, WV' },
  { value: 'Pratt_KS', label: 'Pratt, KS' },
  { value: 'Pratts_VA', label: 'Pratts, VA' },
  { value: 'Prattsburgh_NY', label: 'Prattsburgh, NY' },
  { value: 'Prattsville_NY', label: 'Prattsville, NY' },
  { value: 'Prattsville_AR', label: 'Prattsville, AR' },
  { value: 'Prattville_AL', label: 'Prattville, AL' },
  { value: 'Pray_MT', label: 'Pray, MT' },
  { value: 'Preble_NY', label: 'Preble, NY' },
  { value: 'Preemption_IL', label: 'Preemption, IL' },
  { value: 'Premier_WV', label: 'Premier, WV' },
  { value: 'Premium_KY', label: 'Premium, KY' },
  { value: 'Premont_TX', label: 'Premont, TX' },
  { value: 'Prentice_WI', label: 'Prentice, WI' },
  { value: 'Prentiss_MS', label: 'Prentiss, MS' },
  { value: 'Prescott_MI', label: 'Prescott, MI' },
  { value: 'Prescott_IA', label: 'Prescott, IA' },
  { value: 'Prescott_WI', label: 'Prescott, WI' },
  { value: 'Prescott_KS', label: 'Prescott, KS' },
  { value: 'Prescott_AR', label: 'Prescott, AR' },
  { value: 'Prescott_AZ', label: 'Prescott, AZ' },
  { value: 'Prescott_WA', label: 'Prescott, WA' },
  { value: 'Prescott Valley_AZ', label: 'Prescott Valley, AZ' },
  { value: 'Presho_SD', label: 'Presho, SD' },
  { value: 'Presidio_TX', label: 'Presidio, TX' },
  { value: 'Presque Isle_ME', label: 'Presque Isle, ME' },
  { value: 'Presque Isle_MI', label: 'Presque Isle, MI' },
  { value: 'Presque Isle_WI', label: 'Presque Isle, WI' },
  { value: 'Presto_PA', label: 'Presto, PA' },
  { value: 'Preston_CT', label: 'Preston, CT' },
  { value: 'Preston_MD', label: 'Preston, MD' },
  { value: 'Preston_GA', label: 'Preston, GA' },
  { value: 'Preston_MS', label: 'Preston, MS' },
  { value: 'Preston_IA', label: 'Preston, IA' },
  { value: 'Preston_MN', label: 'Preston, MN' },
  { value: 'Preston_MO', label: 'Preston, MO' },
  { value: 'Preston_OK', label: 'Preston, OK' },
  { value: 'Preston_ID', label: 'Preston, ID' },
  { value: 'Preston_WA', label: 'Preston, WA' },
  { value: 'Preston Hollow_NY', label: 'Preston Hollow, NY' },
  { value: 'Preston Park_PA', label: 'Preston Park, PA' },
  { value: 'Prestonsburg_KY', label: 'Prestonsburg, KY' },
  { value: 'Pretty Prairie_KS', label: 'Pretty Prairie, KS' },
  { value: 'Prewitt_NM', label: 'Prewitt, NM' },
  { value: 'Price_TX', label: 'Price, TX' },
  { value: 'Price_UT', label: 'Price, UT' },
  { value: 'Pricedale_PA', label: 'Pricedale, PA' },
  { value: 'Prichard_WV', label: 'Prichard, WV' },
  { value: 'Priddy_TX', label: 'Priddy, TX' },
  { value: 'Pride_LA', label: 'Pride, LA' },
  { value: 'Prides Crossing_MA', label: 'Prides Crossing, MA' },
  { value: 'Priest River_ID', label: 'Priest River, ID' },
  { value: 'Prim_AR', label: 'Prim, AR' },
  { value: 'Primghar_IA', label: 'Primghar, IA' },
  { value: 'Primm Springs_TN', label: 'Primm Springs, TN' },
  { value: 'Primrose_NE', label: 'Primrose, NE' },
  { value: 'Prince_WV', label: 'Prince, WV' },
  { value: 'Prince Frederick_MD', label: 'Prince Frederick, MD' },
  { value: 'Prince George_VA', label: 'Prince George, VA' },
  { value: 'Princess Anne_MD', label: 'Princess Anne, MD' },
  { value: 'Princeton_MA', label: 'Princeton, MA' },
  { value: 'Princeton_ME', label: 'Princeton, ME' },
  { value: 'Princeton_NJ', label: 'Princeton, NJ' },
  { value: 'Princeton_WV', label: 'Princeton, WV' },
  { value: 'Princeton_NC', label: 'Princeton, NC' },
  { value: 'Princeton_AL', label: 'Princeton, AL' },
  { value: 'Princeton_KY', label: 'Princeton, KY' },
  { value: 'Princeton_IN', label: 'Princeton, IN' },
  { value: 'Princeton_IA', label: 'Princeton, IA' },
  { value: 'Princeton_WI', label: 'Princeton, WI' },
  { value: 'Princeton_MN', label: 'Princeton, MN' },
  { value: 'Princeton_IL', label: 'Princeton, IL' },
  { value: 'Princeton_MO', label: 'Princeton, MO' },
  { value: 'Princeton_KS', label: 'Princeton, KS' },
  { value: 'Princeton_LA', label: 'Princeton, LA' },
  { value: 'Princeton_TX', label: 'Princeton, TX' },
  { value: 'Princeton_ID', label: 'Princeton, ID' },
  { value: 'Princeton_CA', label: 'Princeton, CA' },
  { value: 'Princeton_OR', label: 'Princeton, OR' },
  { value: 'Princeton Junction_NJ', label: 'Princeton Junction, NJ' },
  { value: 'Princeville_IL', label: 'Princeville, IL' },
  { value: 'Princeville_HI', label: 'Princeville, HI' },
  { value: 'Princewick_WV', label: 'Princewick, WV' },
  { value: 'Prineville_OR', label: 'Prineville, OR' },
  { value: 'Pringle_SD', label: 'Pringle, SD' },
  { value: 'Prinsburg_MN', label: 'Prinsburg, MN' },
  { value: 'Printer_KY', label: 'Printer, KY' },
  { value: 'Prior Lake_MN', label: 'Prior Lake, MN' },
  { value: 'Pritchett_CO', label: 'Pritchett, CO' },
  { value: 'Proberta_CA', label: 'Proberta, CA' },
  { value: 'Procious_WV', label: 'Procious, WV' },
  { value: 'Proctor_VT', label: 'Proctor, VT' },
  { value: 'Proctor_WV', label: 'Proctor, WV' },
  { value: 'Proctor_MT', label: 'Proctor, MT' },
  { value: 'Proctor_AR', label: 'Proctor, AR' },
  { value: 'Proctor_OK', label: 'Proctor, OK' },
  { value: 'Proctorsville_VT', label: 'Proctorsville, VT' },
  { value: 'Proctorville_NC', label: 'Proctorville, NC' },
  { value: 'Proctorville_OH', label: 'Proctorville, OH' },
  { value: 'Progreso_TX', label: 'Progreso, TX' },
  { value: 'Prole_IA', label: 'Prole, IA' },
  { value: 'Promise City_IA', label: 'Promise City, IA' },
  { value: 'Prompton_PA', label: 'Prompton, PA' },
  { value: 'Prophetstown_IL', label: 'Prophetstown, IL' },
  { value: 'Prospect_CT', label: 'Prospect, CT' },
  { value: 'Prospect_NY', label: 'Prospect, NY' },
  { value: 'Prospect_PA', label: 'Prospect, PA' },
  { value: 'Prospect_VA', label: 'Prospect, VA' },
  { value: 'Prospect_TN', label: 'Prospect, TN' },
  { value: 'Prospect_KY', label: 'Prospect, KY' },
  { value: 'Prospect_OH', label: 'Prospect, OH' },
  { value: 'Prospect_OR', label: 'Prospect, OR' },
  { value: 'Prospect Harbor_ME', label: 'Prospect Harbor, ME' },
  { value: 'Prospect Heights_IL', label: 'Prospect Heights, IL' },
  { value: 'Prospect Hill_NC', label: 'Prospect Hill, NC' },
  { value: 'Prospect Park_PA', label: 'Prospect Park, PA' },
  { value: 'Prosper_TX', label: 'Prosper, TX' },
  { value: 'Prosperity_PA', label: 'Prosperity, PA' },
  { value: 'Prosperity_WV', label: 'Prosperity, WV' },
  { value: 'Prosperity_SC', label: 'Prosperity, SC' },
  { value: 'Prosser_WA', label: 'Prosser, WA' },
  { value: 'Protection_KS', label: 'Protection, KS' },
  { value: 'Protem_MO', label: 'Protem, MO' },
  { value: 'Protivin_IA', label: 'Protivin, IA' },
  { value: 'Provencal_LA', label: 'Provencal, LA' },
  { value: 'Providence_RI', label: 'Providence, RI' },
  { value: 'Providence_NC', label: 'Providence, NC' },
  { value: 'Providence_KY', label: 'Providence, KY' },
  { value: 'Providence_UT', label: 'Providence, UT' },
  { value: 'Providence Forge_VA', label: 'Providence Forge, VA' },
  { value: 'Provincetown_MA', label: 'Provincetown, MA' },
  { value: 'Provo_UT', label: 'Provo, UT' },
  { value: 'Pruden_TN', label: 'Pruden, TN' },
  { value: 'Prudence Island_RI', label: 'Prudence Island, RI' },
  { value: 'Prudenville_MI', label: 'Prudenville, MI' },
  { value: 'Prudhoe Bay_AK', label: 'Prudhoe Bay, AK' },
  { value: 'Prue_OK', label: 'Prue, OK' },
  { value: 'Pryor_MT', label: 'Pryor, MT' },
  { value: 'Pryor_OK', label: 'Pryor, OK' },
  { value: 'Pueblo_CO', label: 'Pueblo, CO' },
  { value: 'Pueblo Of Acoma_NM', label: 'Pueblo Of Acoma, NM' },
  { value: 'Puerto Real_PR', label: 'Puerto Real, PR' },
  { value: 'Pukwana_SD', label: 'Pukwana, SD' },
  { value: 'Pulaski_NY', label: 'Pulaski, NY' },
  { value: 'Pulaski_PA', label: 'Pulaski, PA' },
  { value: 'Pulaski_VA', label: 'Pulaski, VA' },
  { value: 'Pulaski_GA', label: 'Pulaski, GA' },
  { value: 'Pulaski_TN', label: 'Pulaski, TN' },
  { value: 'Pulaski_MS', label: 'Pulaski, MS' },
  { value: 'Pulaski_IA', label: 'Pulaski, IA' },
  { value: 'Pulaski_WI', label: 'Pulaski, WI' },
  { value: 'Pulaski_IL', label: 'Pulaski, IL' },
  { value: 'Pullman_WV', label: 'Pullman, WV' },
  { value: 'Pullman_MI', label: 'Pullman, MI' },
  { value: 'Pullman_WA', label: 'Pullman, WA' },
  { value: 'Pulteney_NY', label: 'Pulteney, NY' },
  { value: 'Pungoteague_VA', label: 'Pungoteague, VA' },
  { value: 'Punta Gorda_FL', label: 'Punta Gorda, FL' },
  { value: 'Punta Santiago_PR', label: 'Punta Santiago, PR' },
  { value: 'Punxsutawney_PA', label: 'Punxsutawney, PA' },
  { value: 'Puposky_MN', label: 'Puposky, MN' },
  { value: 'Purcell_MO', label: 'Purcell, MO' },
  { value: 'Purcell_OK', label: 'Purcell, OK' },
  { value: 'Purcellville_VA', label: 'Purcellville, VA' },
  { value: 'Purchase_NY', label: 'Purchase, NY' },
  { value: 'Purdin_MO', label: 'Purdin, MO' },
  { value: 'Purdon_TX', label: 'Purdon, TX' },
  { value: 'Purdum_NE', label: 'Purdum, NE' },
  { value: 'Purdy_MO', label: 'Purdy, MO' },
  { value: 'Purdys_NY', label: 'Purdys, NY' },
  { value: 'Purgitsville_WV', label: 'Purgitsville, WV' },
  { value: 'Purlear_NC', label: 'Purlear, NC' },
  { value: 'Purling_NY', label: 'Purling, NY' },
  { value: 'Purmela_TX', label: 'Purmela, TX' },
  { value: 'Pursglove_WV', label: 'Pursglove, WV' },
  { value: 'Purvis_MS', label: 'Purvis, MS' },
  { value: 'Puryear_TN', label: 'Puryear, TN' },
  { value: 'Put In Bay_OH', label: 'Put In Bay, OH' },
  { value: 'Putnam_CT', label: 'Putnam, CT' },
  { value: 'Putnam_IL', label: 'Putnam, IL' },
  { value: 'Putnam_OK', label: 'Putnam, OK' },
  { value: 'Putnam_TX', label: 'Putnam, TX' },
  { value: 'Putnam Station_NY', label: 'Putnam Station, NY' },
  { value: 'Putnam Valley_NY', label: 'Putnam Valley, NY' },
  { value: 'Putnamville_IN', label: 'Putnamville, IN' },
  { value: 'Putney_VT', label: 'Putney, VT' },
  { value: 'Putney_KY', label: 'Putney, KY' },
  { value: 'Puxico_MO', label: 'Puxico, MO' },
  { value: 'Puyallup_WA', label: 'Puyallup, WA' },
  { value: 'Pyatt_AR', label: 'Pyatt, AR' },
  { value: 'Pylesville_MD', label: 'Pylesville, MD' },
  { value: 'Pyote_TX', label: 'Pyote, TX' },
  { value: 'Quail_TX', label: 'Quail, TX' },
  { value: 'Quakake_PA', label: 'Quakake, PA' },
  { value: 'Quaker City_OH', label: 'Quaker City, OH' },
  { value: 'Quaker Hill_CT', label: 'Quaker Hill, CT' },
  { value: 'Quakertown_PA', label: 'Quakertown, PA' },
  { value: 'Quanah_TX', label: 'Quanah, TX' },
  { value: 'Quantico_MD', label: 'Quantico, MD' },
  { value: 'Quantico_VA', label: 'Quantico, VA' },
  { value: 'Quapaw_OK', label: 'Quapaw, OK' },
  { value: 'Quarryville_PA', label: 'Quarryville, PA' },
  { value: 'Quartzsite_AZ', label: 'Quartzsite, AZ' },
  { value: 'Quasqueton_IA', label: 'Quasqueton, IA' },
  { value: 'Quebeck_TN', label: 'Quebeck, TN' },
  { value: 'Quebradillas_PR', label: 'Quebradillas, PR' },
  { value: 'Quechee_VT', label: 'Quechee, VT' },
  { value: 'Quecreek_PA', label: 'Quecreek, PA' },
  { value: 'Queen_PA', label: 'Queen, PA' },
  { value: 'Queen Anne_MD', label: 'Queen Anne, MD' },
  { value: 'Queen City_MO', label: 'Queen City, MO' },
  { value: 'Queen City_TX', label: 'Queen City, TX' },
  { value: 'Queen Creek_AZ', label: 'Queen Creek, AZ' },
  { value: 'Queens Village_NY', label: 'Queens Village, NY' },
  { value: 'Queensbury_NY', label: 'Queensbury, NY' },
  { value: 'Queenstown_MD', label: 'Queenstown, MD' },
  { value: 'Quemado_TX', label: 'Quemado, TX' },
  { value: 'Quemado_NM', label: 'Quemado, NM' },
  { value: 'Quenemo_KS', label: 'Quenemo, KS' },
  { value: 'Questa_NM', label: 'Questa, NM' },
  { value: 'Quicksburg_VA', label: 'Quicksburg, VA' },
  { value: 'Quilcene_WA', label: 'Quilcene, WA' },
  { value: 'Quimby_IA', label: 'Quimby, IA' },
  { value: 'Quinault_WA', label: 'Quinault, WA' },
  { value: 'Quinby_VA', label: 'Quinby, VA' },
  { value: 'Quincy_MA', label: 'Quincy, MA' },
  { value: 'Quincy_PA', label: 'Quincy, PA' },
  { value: 'Quincy_FL', label: 'Quincy, FL' },
  { value: 'Quincy_KY', label: 'Quincy, KY' },
  { value: 'Quincy_OH', label: 'Quincy, OH' },
  { value: 'Quincy_IN', label: 'Quincy, IN' },
  { value: 'Quincy_MI', label: 'Quincy, MI' },
  { value: 'Quincy_IL', label: 'Quincy, IL' },
  { value: 'Quincy_MO', label: 'Quincy, MO' },
  { value: 'Quincy_CA', label: 'Quincy, CA' },
  { value: 'Quincy_WA', label: 'Quincy, WA' },
  { value: 'Quinebaug_CT', label: 'Quinebaug, CT' },
  { value: 'Quinhagak_AK', label: 'Quinhagak, AK' },
  { value: 'Quinlan_TX', label: 'Quinlan, TX' },
  { value: 'Quinn_SD', label: 'Quinn, SD' },
  { value: 'Quinnesec_MI', label: 'Quinnesec, MI' },
  { value: 'Quinter_KS', label: 'Quinter, KS' },
  { value: 'Quinton_NJ', label: 'Quinton, NJ' },
  { value: 'Quinton_VA', label: 'Quinton, VA' },
  { value: 'Quinton_AL', label: 'Quinton, AL' },
  { value: 'Quinton_OK', label: 'Quinton, OK' },
  { value: 'Quinwood_WV', label: 'Quinwood, WV' },
  { value: 'Quitaque_TX', label: 'Quitaque, TX' },
  { value: 'Quitman_GA', label: 'Quitman, GA' },
  { value: 'Quitman_MS', label: 'Quitman, MS' },
  { value: 'Quitman_LA', label: 'Quitman, LA' },
  { value: 'Quitman_AR', label: 'Quitman, AR' },
  { value: 'Quitman_TX', label: 'Quitman, TX' },
  { value: 'Qulin_MO', label: 'Qulin, MO' },
  { value: 'Quogue_NY', label: 'Quogue, NY' },
  { value: 'Rabun Gap_GA', label: 'Rabun Gap, GA' },
  { value: 'Raccoon_KY', label: 'Raccoon, KY' },
  { value: 'Raceland_LA', label: 'Raceland, LA' },
  { value: 'Rachel_WV', label: 'Rachel, WV' },
  { value: 'Racine_WV', label: 'Racine, WV' },
  { value: 'Racine_OH', label: 'Racine, OH' },
  { value: 'Racine_WI', label: 'Racine, WI' },
  { value: 'Racine_MN', label: 'Racine, MN' },
  { value: 'Racine_MO', label: 'Racine, MO' },
  { value: 'Rackerby_CA', label: 'Rackerby, CA' },
  { value: 'Radcliff_KY', label: 'Radcliff, KY' },
  { value: 'Radcliffe_IA', label: 'Radcliffe, IA' },
  { value: 'Radford_VA', label: 'Radford, VA' },
  { value: 'Radiant_VA', label: 'Radiant, VA' },
  { value: 'Radisson_WI', label: 'Radisson, WI' },
  { value: 'Radnor_OH', label: 'Radnor, OH' },
  { value: 'Radom_IL', label: 'Radom, IL' },
  { value: 'Raeford_NC', label: 'Raeford, NC' },
  { value: 'Ragland_WV', label: 'Ragland, WV' },
  { value: 'Ragland_AL', label: 'Ragland, AL' },
  { value: 'Ragley_LA', label: 'Ragley, LA' },
  { value: 'Ragsdale_IN', label: 'Ragsdale, IN' },
  { value: 'Rahway_NJ', label: 'Rahway, NJ' },
  { value: 'Raiford_FL', label: 'Raiford, FL' },
  { value: 'Rail Road Flat_CA', label: 'Rail Road Flat, CA' },
  { value: 'Railroad_PA', label: 'Railroad, PA' },
  { value: 'Rainbow_TX', label: 'Rainbow, TX' },
  { value: 'Rainbow City_AL', label: 'Rainbow City, AL' },
  { value: 'Rainbow Lake_NY', label: 'Rainbow Lake, NY' },
  { value: 'Rainelle_WV', label: 'Rainelle, WV' },
  { value: 'Rainier_OR', label: 'Rainier, OR' },
  { value: 'Rainier_WA', label: 'Rainier, WA' },
  { value: 'Rains_SC', label: 'Rains, SC' },
  { value: 'Rainsville_AL', label: 'Rainsville, AL' },
  { value: 'Rainsville_NM', label: 'Rainsville, NM' },
  { value: 'Raisin City_CA', label: 'Raisin City, CA' },
  { value: 'Rake_IA', label: 'Rake, IA' },
  { value: 'Raleigh_WV', label: 'Raleigh, WV' },
  { value: 'Raleigh_NC', label: 'Raleigh, NC' },
  { value: 'Raleigh_MS', label: 'Raleigh, MS' },
  { value: 'Raleigh_ND', label: 'Raleigh, ND' },
  { value: 'Raleigh_IL', label: 'Raleigh, IL' },
  { value: 'Ralls_TX', label: 'Ralls, TX' },
  { value: 'Ralph_AL', label: 'Ralph, AL' },
  { value: 'Ralph_MI', label: 'Ralph, MI' },
  { value: 'Ralph_SD', label: 'Ralph, SD' },
  { value: 'Ralston_PA', label: 'Ralston, PA' },
  { value: 'Ralston_IA', label: 'Ralston, IA' },
  { value: 'Ralston_OK', label: 'Ralston, OK' },
  { value: 'Ralston_WY', label: 'Ralston, WY' },
  { value: 'Ramah_CO', label: 'Ramah, CO' },
  { value: 'Ramah_NM', label: 'Ramah, NM' },
  { value: 'Ramer_AL', label: 'Ramer, AL' },
  { value: 'Ramer_TN', label: 'Ramer, TN' },
  { value: 'Ramey_PA', label: 'Ramey, PA' },
  { value: 'Ramona_SD', label: 'Ramona, SD' },
  { value: 'Ramona_KS', label: 'Ramona, KS' },
  { value: 'Ramona_OK', label: 'Ramona, OK' },
  { value: 'Ramona_CA', label: 'Ramona, CA' },
  { value: 'Rampart_AK', label: 'Rampart, AK' },
  { value: 'Ramsay_MI', label: 'Ramsay, MI' },
  { value: 'Ramsay_MT', label: 'Ramsay, MT' },
  { value: 'Ramseur_NC', label: 'Ramseur, NC' },
  { value: 'Ramsey_NJ', label: 'Ramsey, NJ' },
  { value: 'Ramsey_IN', label: 'Ramsey, IN' },
  { value: 'Ramsey_IL', label: 'Ramsey, IL' },
  { value: 'Ranburne_AL', label: 'Ranburne, AL' },
  { value: 'Ranchester_WY', label: 'Ranchester, WY' },
  { value: 'Ranchita_CA', label: 'Ranchita, CA' },
  { value: 'Rancho Cordova_CA', label: 'Rancho Cordova, CA' },
  { value: 'Rancho Cucamonga_CA', label: 'Rancho Cucamonga, CA' },
  { value: 'Rancho Mirage_CA', label: 'Rancho Mirage, CA' },
  { value: 'Rancho Palos Verdes_CA', label: 'Rancho Palos Verdes, CA' },
  { value: 'Rancho Santa Fe_CA', label: 'Rancho Santa Fe, CA' },
  { value: 'Rancho Santa Margarita_CA', label: 'Rancho Santa Margarita, CA' },
  { value: 'Ranchos De Taos_NM', label: 'Ranchos De Taos, NM' },
  { value: 'Rancocas_NJ', label: 'Rancocas, NJ' },
  { value: 'Rand_CO', label: 'Rand, CO' },
  { value: 'Randalia_IA', label: 'Randalia, IA' },
  { value: 'Randall_IA', label: 'Randall, IA' },
  { value: 'Randall_MN', label: 'Randall, MN' },
  { value: 'Randall_KS', label: 'Randall, KS' },
  { value: 'Randallstown_MD', label: 'Randallstown, MD' },
  { value: 'Randle_WA', label: 'Randle, WA' },
  { value: 'Randleman_NC', label: 'Randleman, NC' },
  { value: 'Randlett_OK', label: 'Randlett, OK' },
  { value: 'Randlett_UT', label: 'Randlett, UT' },
  { value: 'Randolph_MA', label: 'Randolph, MA' },
  { value: 'Randolph_NH', label: 'Randolph, NH' },
  { value: 'Randolph_ME', label: 'Randolph, ME' },
  { value: 'Randolph_VT', label: 'Randolph, VT' },
  { value: 'Randolph_NJ', label: 'Randolph, NJ' },
  { value: 'Randolph_NY', label: 'Randolph, NY' },
  { value: 'Randolph_VA', label: 'Randolph, VA' },
  { value: 'Randolph_AL', label: 'Randolph, AL' },
  { value: 'Randolph_MS', label: 'Randolph, MS' },
  { value: 'Randolph_IA', label: 'Randolph, IA' },
  { value: 'Randolph_WI', label: 'Randolph, WI' },
  { value: 'Randolph_MN', label: 'Randolph, MN' },
  { value: 'Randolph_KS', label: 'Randolph, KS' },
  { value: 'Randolph_NE', label: 'Randolph, NE' },
  { value: 'Randolph_TX', label: 'Randolph, TX' },
  { value: 'Randolph_UT', label: 'Randolph, UT' },
  { value: 'Randolph Center_VT', label: 'Randolph Center, VT' },
  { value: 'Random Lake_WI', label: 'Random Lake, WI' },
  { value: 'Randsburg_CA', label: 'Randsburg, CA' },
  { value: 'Range_AL', label: 'Range, AL' },
  { value: 'Rangeley_ME', label: 'Rangeley, ME' },
  { value: 'Rangely_CO', label: 'Rangely, CO' },
  { value: 'Ranger_WV', label: 'Ranger, WV' },
  { value: 'Ranger_GA', label: 'Ranger, GA' },
  { value: 'Ranger_TX', label: 'Ranger, TX' },
  { value: 'Ranier_MN', label: 'Ranier, MN' },
  { value: 'Rankin_IL', label: 'Rankin, IL' },
  { value: 'Rankin_TX', label: 'Rankin, TX' },
  { value: 'Ransom_KY', label: 'Ransom, KY' },
  { value: 'Ransom_IL', label: 'Ransom, IL' },
  { value: 'Ransom_KS', label: 'Ransom, KS' },
  { value: 'Ransom Canyon_TX', label: 'Ransom Canyon, TX' },
  { value: 'Ransomville_NY', label: 'Ransomville, NY' },
  { value: 'Ranson_WV', label: 'Ranson, WV' },
  { value: 'Rantoul_IL', label: 'Rantoul, IL' },
  { value: 'Rantoul_KS', label: 'Rantoul, KS' },
  { value: 'Rapelje_MT', label: 'Rapelje, MT' },
  { value: 'Raphine_VA', label: 'Raphine, VA' },
  { value: 'Rapid City_MI', label: 'Rapid City, MI' },
  { value: 'Rapid City_SD', label: 'Rapid City, SD' },
  { value: 'Rapid River_MI', label: 'Rapid River, MI' },
  { value: 'Rapidan_VA', label: 'Rapidan, VA' },
  { value: 'Rapids City_IL', label: 'Rapids City, IL' },
  { value: 'Rappahannock Academy_VA', label: 'Rappahannock Academy, VA' },
  { value: 'Raquette Lake_NY', label: 'Raquette Lake, NY' },
  { value: 'Rarden_OH', label: 'Rarden, OH' },
  { value: 'Raritan_NJ', label: 'Raritan, NJ' },
  { value: 'Raritan_IL', label: 'Raritan, IL' },
  { value: 'Ratcliff_AR', label: 'Ratcliff, AR' },
  { value: 'Ratcliff_TX', label: 'Ratcliff, TX' },
  { value: 'Rathdrum_ID', label: 'Rathdrum, ID' },
  { value: 'Ratliff City_OK', label: 'Ratliff City, OK' },
  { value: 'Raton_NM', label: 'Raton, NM' },
  { value: 'Rattan_OK', label: 'Rattan, OK' },
  { value: 'Ravalli_MT', label: 'Ravalli, MT' },
  { value: 'Raven_VA', label: 'Raven, VA' },
  { value: 'Raven_KY', label: 'Raven, KY' },
  { value: 'Ravena_NY', label: 'Ravena, NY' },
  { value: 'Ravencliff_WV', label: 'Ravencliff, WV' },
  { value: 'Ravendale_CA', label: 'Ravendale, CA' },
  { value: 'Ravenden_AR', label: 'Ravenden, AR' },
  { value: 'Ravenden Springs_AR', label: 'Ravenden Springs, AR' },
  { value: 'Ravenel_SC', label: 'Ravenel, SC' },
  { value: 'Ravenna_KY', label: 'Ravenna, KY' },
  { value: 'Ravenna_OH', label: 'Ravenna, OH' },
  { value: 'Ravenna_MI', label: 'Ravenna, MI' },
  { value: 'Ravenna_NE', label: 'Ravenna, NE' },
  { value: 'Ravenna_TX', label: 'Ravenna, TX' },
  { value: 'Ravensdale_WA', label: 'Ravensdale, WA' },
  { value: 'Ravenswood_WV', label: 'Ravenswood, WV' },
  { value: 'Ravenwood_MO', label: 'Ravenwood, MO' },
  { value: 'Ravia_OK', label: 'Ravia, OK' },
  { value: 'Rawlings_MD', label: 'Rawlings, MD' },
  { value: 'Rawlings_VA', label: 'Rawlings, VA' },
  { value: 'Rawlins_WY', label: 'Rawlins, WY' },
  { value: 'Rawson_OH', label: 'Rawson, OH' },
  { value: 'Ray_OH', label: 'Ray, OH' },
  { value: 'Ray_MI', label: 'Ray, MI' },
  { value: 'Ray_ND', label: 'Ray, ND' },
  { value: 'Ray Brook_NY', label: 'Ray Brook, NY' },
  { value: 'Ray City_GA', label: 'Ray City, GA' },
  { value: 'Rayland_OH', label: 'Rayland, OH' },
  { value: 'Rayle_GA', label: 'Rayle, GA' },
  { value: 'Raymond_NH', label: 'Raymond, NH' },
  { value: 'Raymond_ME', label: 'Raymond, ME' },
  { value: 'Raymond_MS', label: 'Raymond, MS' },
  { value: 'Raymond_OH', label: 'Raymond, OH' },
  { value: 'Raymond_IA', label: 'Raymond, IA' },
  { value: 'Raymond_MN', label: 'Raymond, MN' },
  { value: 'Raymond_SD', label: 'Raymond, SD' },
  { value: 'Raymond_MT', label: 'Raymond, MT' },
  { value: 'Raymond_IL', label: 'Raymond, IL' },
  { value: 'Raymond_KS', label: 'Raymond, KS' },
  { value: 'Raymond_NE', label: 'Raymond, NE' },
  { value: 'Raymond_CA', label: 'Raymond, CA' },
  { value: 'Raymond_WA', label: 'Raymond, WA' },
  { value: 'Raymondville_NY', label: 'Raymondville, NY' },
  { value: 'Raymondville_MO', label: 'Raymondville, MO' },
  { value: 'Raymondville_TX', label: 'Raymondville, TX' },
  { value: 'Raymore_MO', label: 'Raymore, MO' },
  { value: 'Rayne_LA', label: 'Rayne, LA' },
  { value: 'Raynesford_MT', label: 'Raynesford, MT' },
  { value: 'Raynham_MA', label: 'Raynham, MA' },
  { value: 'Raysal_WV', label: 'Raysal, WV' },
  { value: 'Rayville_MO', label: 'Rayville, MO' },
  { value: 'Rayville_LA', label: 'Rayville, LA' },
  { value: 'Raywick_KY', label: 'Raywick, KY' },
  { value: 'Raywood_TX', label: 'Raywood, TX' },
  { value: 'Rea_MO', label: 'Rea, MO' },
  { value: 'Reader_WV', label: 'Reader, WV' },
  { value: 'Readfield_ME', label: 'Readfield, ME' },
  { value: 'Reading_MA', label: 'Reading, MA' },
  { value: 'Reading_VT', label: 'Reading, VT' },
  { value: 'Reading_PA', label: 'Reading, PA' },
  { value: 'Reading_MI', label: 'Reading, MI' },
  { value: 'Reading_MN', label: 'Reading, MN' },
  { value: 'Reading_KS', label: 'Reading, KS' },
  { value: 'Readlyn_IA', label: 'Readlyn, IA' },
  { value: 'Reads Landing_MN', label: 'Reads Landing, MN' },
  { value: 'Readsboro_VT', label: 'Readsboro, VT' },
  { value: 'Readstown_WI', label: 'Readstown, WI' },
  { value: 'Readyville_TN', label: 'Readyville, TN' },
  { value: 'Reagan_TN', label: 'Reagan, TN' },
  { value: 'Reagan_TX', label: 'Reagan, TX' },
  { value: 'Realitos_TX', label: 'Realitos, TX' },
  { value: 'Reardan_WA', label: 'Reardan, WA' },
  { value: 'Reasnor_IA', label: 'Reasnor, IA' },
  { value: 'Rebecca_GA', label: 'Rebecca, GA' },
  { value: 'Rebersburg_PA', label: 'Rebersburg, PA' },
  { value: 'Rebuck_PA', label: 'Rebuck, PA' },
  { value: 'Recluse_WY', label: 'Recluse, WY' },
  { value: 'Rector_PA', label: 'Rector, PA' },
  { value: 'Rector_AR', label: 'Rector, AR' },
  { value: 'Red Bank_NJ', label: 'Red Bank, NJ' },
  { value: 'Red Banks_MS', label: 'Red Banks, MS' },
  { value: 'Red Bay_AL', label: 'Red Bay, AL' },
  { value: 'Red Bluff_CA', label: 'Red Bluff, CA' },
  { value: 'Red Boiling Springs_TN', label: 'Red Boiling Springs, TN' },
  { value: 'Red Bud_IL', label: 'Red Bud, IL' },
  { value: 'Red Cliff_CO', label: 'Red Cliff, CO' },
  { value: 'Red Cloud_NE', label: 'Red Cloud, NE' },
  { value: 'Red Creek_NY', label: 'Red Creek, NY' },
  { value: 'Red Creek_WV', label: 'Red Creek, WV' },
  { value: 'Red Devil_AK', label: 'Red Devil, AK' },
  { value: 'Red Feather Lakes_CO', label: 'Red Feather Lakes, CO' },
  { value: 'Red Hill_PA', label: 'Red Hill, PA' },
  { value: 'Red Hook_NY', label: 'Red Hook, NY' },
  { value: 'Red House_VA', label: 'Red House, VA' },
  { value: 'Red House_WV', label: 'Red House, WV' },
  { value: 'Red Jacket_WV', label: 'Red Jacket, WV' },
  { value: 'Red Lake Falls_MN', label: 'Red Lake Falls, MN' },
  { value: 'Red Level_AL', label: 'Red Level, AL' },
  { value: 'Red Lion_PA', label: 'Red Lion, PA' },
  { value: 'Red Lodge_MT', label: 'Red Lodge, MT' },
  { value: 'Red Mountain_CA', label: 'Red Mountain, CA' },
  { value: 'Red Oak_VA', label: 'Red Oak, VA' },
  { value: 'Red Oak_GA', label: 'Red Oak, GA' },
  { value: 'Red Oak_IA', label: 'Red Oak, IA' },
  { value: 'Red Oak_OK', label: 'Red Oak, OK' },
  { value: 'Red Oak_TX', label: 'Red Oak, TX' },
  { value: 'Red River_NM', label: 'Red River, NM' },
  { value: 'Red Rock_OK', label: 'Red Rock, OK' },
  { value: 'Red Rock_TX', label: 'Red Rock, TX' },
  { value: 'Red Rock_AZ', label: 'Red Rock, AZ' },
  { value: 'Red Springs_NC', label: 'Red Springs, NC' },
  { value: 'Red Valley_AZ', label: 'Red Valley, AZ' },
  { value: 'Red Wing_MN', label: 'Red Wing, MN' },
  { value: 'Redbird_OK', label: 'Redbird, OK' },
  { value: 'Redby_MN', label: 'Redby, MN' },
  { value: 'Redcrest_CA', label: 'Redcrest, CA' },
  { value: 'Reddell_LA', label: 'Reddell, LA' },
  { value: 'Reddick_FL', label: 'Reddick, FL' },
  { value: 'Reddick_IL', label: 'Reddick, IL' },
  { value: 'Redding_CT', label: 'Redding, CT' },
  { value: 'Redding_IA', label: 'Redding, IA' },
  { value: 'Redding_CA', label: 'Redding, CA' },
  { value: 'Redfield_NY', label: 'Redfield, NY' },
  { value: 'Redfield_IA', label: 'Redfield, IA' },
  { value: 'Redfield_SD', label: 'Redfield, SD' },
  { value: 'Redfield_KS', label: 'Redfield, KS' },
  { value: 'Redfield_AR', label: 'Redfield, AR' },
  { value: 'Redford_NY', label: 'Redford, NY' },
  { value: 'Redford_MI', label: 'Redford, MI' },
  { value: 'Redford_MO', label: 'Redford, MO' },
  { value: 'Redford_TX', label: 'Redford, TX' },
  { value: 'Redfox_KY', label: 'Redfox, KY' },
  { value: 'Redgranite_WI', label: 'Redgranite, WI' },
  { value: 'Redig_SD', label: 'Redig, SD' },
  { value: 'Redkey_IN', label: 'Redkey, IN' },
  { value: 'Redlake_MN', label: 'Redlake, MN' },
  { value: 'Redlands_CA', label: 'Redlands, CA' },
  { value: 'Redmon_IL', label: 'Redmon, IL' },
  { value: 'Redmond_UT', label: 'Redmond, UT' },
  { value: 'Redmond_OR', label: 'Redmond, OR' },
  { value: 'Redmond_WA', label: 'Redmond, WA' },
  { value: 'Redondo Beach_CA', label: 'Redondo Beach, CA' },
  { value: 'Redrock_NM', label: 'Redrock, NM' },
  { value: 'Redstone_MT', label: 'Redstone, MT' },
  { value: 'Redvale_CO', label: 'Redvale, CO' },
  { value: 'Redwater_TX', label: 'Redwater, TX' },
  { value: 'Redway_CA', label: 'Redway, CA' },
  { value: 'Redwood_NY', label: 'Redwood, NY' },
  { value: 'Redwood_MS', label: 'Redwood, MS' },
  { value: 'Redwood City_CA', label: 'Redwood City, CA' },
  { value: 'Redwood Falls_MN', label: 'Redwood Falls, MN' },
  { value: 'Redwood Valley_CA', label: 'Redwood Valley, CA' },
  { value: 'Ree Heights_SD', label: 'Ree Heights, SD' },
  { value: 'Reed_KY', label: 'Reed, KY' },
  { value: 'Reed City_MI', label: 'Reed City, MI' },
  { value: 'Reed Point_MT', label: 'Reed Point, MT' },
  { value: 'Reeder_ND', label: 'Reeder, ND' },
  { value: 'Reedley_CA', label: 'Reedley, CA' },
  { value: 'Reeds_MO', label: 'Reeds, MO' },
  { value: 'Reeds Spring_MO', label: 'Reeds Spring, MO' },
  { value: 'Reedsburg_WI', label: 'Reedsburg, WI' },
  { value: 'Reedsport_OR', label: 'Reedsport, OR' },
  { value: 'Reedsville_PA', label: 'Reedsville, PA' },
  { value: 'Reedsville_WV', label: 'Reedsville, WV' },
  { value: 'Reedsville_OH', label: 'Reedsville, OH' },
  { value: 'Reedsville_WI', label: 'Reedsville, WI' },
  { value: 'Reedville_VA', label: 'Reedville, VA' },
  { value: 'Reedy_WV', label: 'Reedy, WV' },
  { value: 'Reelsville_IN', label: 'Reelsville, IN' },
  { value: 'Reese_MI', label: 'Reese, MI' },
  { value: 'Reeseville_WI', label: 'Reeseville, WI' },
  { value: 'Reesville_OH', label: 'Reesville, OH' },
  { value: 'Reeves_LA', label: 'Reeves, LA' },
  { value: 'Reevesville_SC', label: 'Reevesville, SC' },
  { value: 'Reform_AL', label: 'Reform, AL' },
  { value: 'Refton_PA', label: 'Refton, PA' },
  { value: 'Refugio_TX', label: 'Refugio, TX' },
  { value: 'Regan_ND', label: 'Regan, ND' },
  { value: 'Regent_ND', label: 'Regent, ND' },
  { value: 'Regina_KY', label: 'Regina, KY' },
  { value: 'Regina_NM', label: 'Regina, NM' },
  { value: 'Register_GA', label: 'Register, GA' },
  { value: 'Rego Park_NY', label: 'Rego Park, NY' },
  { value: 'Rehoboth_MA', label: 'Rehoboth, MA' },
  { value: 'Rehoboth_NM', label: 'Rehoboth, NM' },
  { value: 'Rehoboth Beach_DE', label: 'Rehoboth Beach, DE' },
  { value: 'Rehrersburg_PA', label: 'Rehrersburg, PA' },
  { value: 'Reidsville_NC', label: 'Reidsville, NC' },
  { value: 'Reidsville_GA', label: 'Reidsville, GA' },
  { value: 'Reidville_SC', label: 'Reidville, SC' },
  { value: 'Reinbeck_IA', label: 'Reinbeck, IA' },
  { value: 'Reinholds_PA', label: 'Reinholds, PA' },
  { value: 'Reisterstown_MD', label: 'Reisterstown, MD' },
  { value: 'Reklaw_TX', label: 'Reklaw, TX' },
  { value: 'Reliance_TN', label: 'Reliance, TN' },
  { value: 'Reliance_SD', label: 'Reliance, SD' },
  { value: 'Reliance_WY', label: 'Reliance, WY' },
  { value: 'Rembert_SC', label: 'Rembert, SC' },
  { value: 'Rembrandt_IA', label: 'Rembrandt, IA' },
  { value: 'Remer_MN', label: 'Remer, MN' },
  { value: 'Remington_VA', label: 'Remington, VA' },
  { value: 'Remington_IN', label: 'Remington, IN' },
  { value: 'Remlap_AL', label: 'Remlap, AL' },
  { value: 'Remsen_NY', label: 'Remsen, NY' },
  { value: 'Remsen_IA', label: 'Remsen, IA' },
  { value: 'Remsenburg_NY', label: 'Remsenburg, NY' },
  { value: 'Remus_MI', label: 'Remus, MI' },
  { value: 'Rena Lara_MS', label: 'Rena Lara, MS' },
  { value: 'Renault_IL', label: 'Renault, IL' },
  { value: 'Renfrew_PA', label: 'Renfrew, PA' },
  { value: 'Renick_WV', label: 'Renick, WV' },
  { value: 'Renick_MO', label: 'Renick, MO' },
  { value: 'Renner_SD', label: 'Renner, SD' },
  { value: 'Reno_PA', label: 'Reno, PA' },
  { value: 'Reno_OH', label: 'Reno, OH' },
  { value: 'Reno_NV', label: 'Reno, NV' },
  { value: 'Renovo_PA', label: 'Renovo, PA' },
  { value: 'Rensselaer_NY', label: 'Rensselaer, NY' },
  { value: 'Rensselaer_IN', label: 'Rensselaer, IN' },
  { value: 'Rensselaer Falls_NY', label: 'Rensselaer Falls, NY' },
  { value: 'Rensselaerville_NY', label: 'Rensselaerville, NY' },
  { value: 'Rentiesville_OK', label: 'Rentiesville, OK' },
  { value: 'Renton_WA', label: 'Renton, WA' },
  { value: 'Rentz_GA', label: 'Rentz, GA' },
  { value: 'Renville_MN', label: 'Renville, MN' },
  { value: 'Renwick_IA', label: 'Renwick, IA' },
  { value: 'Represa_CA', label: 'Represa, CA' },
  { value: 'Repton_AL', label: 'Repton, AL' },
  { value: 'Republic_PA', label: 'Republic, PA' },
  { value: 'Republic_OH', label: 'Republic, OH' },
  { value: 'Republic_MI', label: 'Republic, MI' },
  { value: 'Republic_MO', label: 'Republic, MO' },
  { value: 'Republic_KS', label: 'Republic, KS' },
  { value: 'Republic_WA', label: 'Republic, WA' },
  { value: 'Republican City_NE', label: 'Republican City, NE' },
  { value: 'Resaca_GA', label: 'Resaca, GA' },
  { value: 'Rescue_CA', label: 'Rescue, CA' },
  { value: 'Reseda_CA', label: 'Reseda, CA' },
  { value: 'Reserve_MT', label: 'Reserve, MT' },
  { value: 'Reserve_LA', label: 'Reserve, LA' },
  { value: 'Reserve_NM', label: 'Reserve, NM' },
  { value: 'Reston_VA', label: 'Reston, VA' },
  { value: 'Retsof_NY', label: 'Retsof, NY' },
  { value: 'Reubens_ID', label: 'Reubens, ID' },
  { value: 'Reva_VA', label: 'Reva, VA' },
  { value: 'Reva_SD', label: 'Reva, SD' },
  { value: 'Revelo_KY', label: 'Revelo, KY' },
  { value: 'Revere_MA', label: 'Revere, MA' },
  { value: 'Revere_MN', label: 'Revere, MN' },
  { value: 'Revere_MO', label: 'Revere, MO' },
  { value: 'Revillo_SD', label: 'Revillo, SD' },
  { value: 'Revloc_PA', label: 'Revloc, PA' },
  { value: 'Rew_PA', label: 'Rew, PA' },
  { value: 'Rewey_WI', label: 'Rewey, WI' },
  { value: 'Rex_NC', label: 'Rex, NC' },
  { value: 'Rex_GA', label: 'Rex, GA' },
  { value: 'Rexburg_ID', label: 'Rexburg, ID' },
  { value: 'Rexford_NY', label: 'Rexford, NY' },
  { value: 'Rexford_MT', label: 'Rexford, MT' },
  { value: 'Rexford_KS', label: 'Rexford, KS' },
  { value: 'Rexville_NY', label: 'Rexville, NY' },
  { value: 'Reydell_AR', label: 'Reydell, AR' },
  { value: 'Reydon_OK', label: 'Reydon, OK' },
  { value: 'Reyno_AR', label: 'Reyno, AR' },
  { value: 'Reynolds_GA', label: 'Reynolds, GA' },
  { value: 'Reynolds_IN', label: 'Reynolds, IN' },
  { value: 'Reynolds_ND', label: 'Reynolds, ND' },
  { value: 'Reynolds_IL', label: 'Reynolds, IL' },
  { value: 'Reynolds_NE', label: 'Reynolds, NE' },
  { value: 'Reynolds Station_KY', label: 'Reynolds Station, KY' },
  { value: 'Reynoldsburg_OH', label: 'Reynoldsburg, OH' },
  { value: 'Reynoldsville_PA', label: 'Reynoldsville, PA' },
  { value: 'Reynoldsville_WV', label: 'Reynoldsville, WV' },
  { value: 'Rhame_ND', label: 'Rhame, ND' },
  { value: 'Rheems_PA', label: 'Rheems, PA' },
  { value: 'Rhine_GA', label: 'Rhine, GA' },
  { value: 'Rhinebeck_NY', label: 'Rhinebeck, NY' },
  { value: 'Rhinecliff_NY', label: 'Rhinecliff, NY' },
  { value: 'Rhineland_MO', label: 'Rhineland, MO' },
  { value: 'Rhinelander_WI', label: 'Rhinelander, WI' },
  { value: 'Rhoadesville_VA', label: 'Rhoadesville, VA' },
  { value: 'Rhodelia_KY', label: 'Rhodelia, KY' },
  { value: 'Rhodell_WV', label: 'Rhodell, WV' },
  { value: 'Rhodes_MI', label: 'Rhodes, MI' },
  { value: 'Rhodes_IA', label: 'Rhodes, IA' },
  { value: 'Rhodesdale_MD', label: 'Rhodesdale, MD' },
  { value: 'Rhodhiss_NC', label: 'Rhodhiss, NC' },
  { value: 'Rhododendron_OR', label: 'Rhododendron, OR' },
  { value: 'Rhome_TX', label: 'Rhome, TX' },
  { value: 'Rialto_CA', label: 'Rialto, CA' },
  { value: 'Rib Lake_WI', label: 'Rib Lake, WI' },
  { value: 'Ribera_NM', label: 'Ribera, NM' },
  { value: 'Rice_VA', label: 'Rice, VA' },
  { value: 'Rice_MN', label: 'Rice, MN' },
  { value: 'Rice_TX', label: 'Rice, TX' },
  { value: 'Rice_WA', label: 'Rice, WA' },
  { value: 'Rice Lake_WI', label: 'Rice Lake, WI' },
  { value: 'Riceboro_GA', label: 'Riceboro, GA' },
  { value: 'Rices Landing_PA', label: 'Rices Landing, PA' },
  { value: 'Ricetown_KY', label: 'Ricetown, KY' },
  { value: 'Riceville_TN', label: 'Riceville, TN' },
  { value: 'Riceville_IA', label: 'Riceville, IA' },
  { value: 'Rich Creek_VA', label: 'Rich Creek, VA' },
  { value: 'Rich Hill_MO', label: 'Rich Hill, MO' },
  { value: 'Rich Square_NC', label: 'Rich Square, NC' },
  { value: 'Richards_MO', label: 'Richards, MO' },
  { value: 'Richards_TX', label: 'Richards, TX' },
  { value: 'Richardson_TX', label: 'Richardson, TX' },
  { value: 'Richardsville_VA', label: 'Richardsville, VA' },
  { value: 'Richardton_ND', label: 'Richardton, ND' },
  { value: 'Richboro_PA', label: 'Richboro, PA' },
  { value: 'Richburg_NY', label: 'Richburg, NY' },
  { value: 'Richburg_SC', label: 'Richburg, SC' },
  { value: 'Richey_MT', label: 'Richey, MT' },
  { value: 'Richeyville_PA', label: 'Richeyville, PA' },
  { value: 'Richfield_PA', label: 'Richfield, PA' },
  { value: 'Richfield_NC', label: 'Richfield, NC' },
  { value: 'Richfield_OH', label: 'Richfield, OH' },
  { value: 'Richfield_WI', label: 'Richfield, WI' },
  { value: 'Richfield_KS', label: 'Richfield, KS' },
  { value: 'Richfield_ID', label: 'Richfield, ID' },
  { value: 'Richfield_UT', label: 'Richfield, UT' },
  { value: 'Richfield Springs_NY', label: 'Richfield Springs, NY' },
  { value: 'Richford_VT', label: 'Richford, VT' },
  { value: 'Richford_NY', label: 'Richford, NY' },
  { value: 'Richgrove_CA', label: 'Richgrove, CA' },
  { value: 'Richland_NJ', label: 'Richland, NJ' },
  { value: 'Richland_NY', label: 'Richland, NY' },
  { value: 'Richland_PA', label: 'Richland, PA' },
  { value: 'Richland_GA', label: 'Richland, GA' },
  { value: 'Richland_MS', label: 'Richland, MS' },
  { value: 'Richland_IN', label: 'Richland, IN' },
  { value: 'Richland_MI', label: 'Richland, MI' },
  { value: 'Richland_IA', label: 'Richland, IA' },
  { value: 'Richland_MT', label: 'Richland, MT' },
  { value: 'Richland_MO', label: 'Richland, MO' },
  { value: 'Richland_TX', label: 'Richland, TX' },
  { value: 'Richland_OR', label: 'Richland, OR' },
  { value: 'Richland_WA', label: 'Richland, WA' },
  { value: 'Richland Center_WI', label: 'Richland Center, WI' },
  { value: 'Richland Springs_TX', label: 'Richland Springs, TX' },
  { value: 'Richlands_VA', label: 'Richlands, VA' },
  { value: 'Richlands_NC', label: 'Richlands, NC' },
  { value: 'Richlandtown_PA', label: 'Richlandtown, PA' },
  { value: 'Richmond_MA', label: 'Richmond, MA' },
  { value: 'Richmond_ME', label: 'Richmond, ME' },
  { value: 'Richmond_VT', label: 'Richmond, VT' },
  { value: 'Richmond_VA', label: 'Richmond, VA' },
  { value: 'Richmond_KY', label: 'Richmond, KY' },
  { value: 'Richmond_OH', label: 'Richmond, OH' },
  { value: 'Richmond_IN', label: 'Richmond, IN' },
  { value: 'Richmond_MI', label: 'Richmond, MI' },
  { value: 'Richmond_MN', label: 'Richmond, MN' },
  { value: 'Richmond_IL', label: 'Richmond, IL' },
  { value: 'Richmond_MO', label: 'Richmond, MO' },
  { value: 'Richmond_KS', label: 'Richmond, KS' },
  { value: 'Richmond_TX', label: 'Richmond, TX' },
  { value: 'Richmond_UT', label: 'Richmond, UT' },
  { value: 'Richmond_CA', label: 'Richmond, CA' },
  { value: 'Richmond Dale_OH', label: 'Richmond Dale, OH' },
  { value: 'Richmond Hill_NY', label: 'Richmond Hill, NY' },
  { value: 'Richmond Hill_GA', label: 'Richmond Hill, GA' },
  { value: 'Richmondville_NY', label: 'Richmondville, NY' },
  { value: 'Richton_MS', label: 'Richton, MS' },
  { value: 'Richton Park_IL', label: 'Richton Park, IL' },
  { value: 'Richvale_CA', label: 'Richvale, CA' },
  { value: 'Richview_IL', label: 'Richview, IL' },
  { value: 'Richville_NY', label: 'Richville, NY' },
  { value: 'Richville_MN', label: 'Richville, MN' },
  { value: 'Richwood_NJ', label: 'Richwood, NJ' },
  { value: 'Richwood_WV', label: 'Richwood, WV' },
  { value: 'Richwood_OH', label: 'Richwood, OH' },
  { value: 'Richwoods_MO', label: 'Richwoods, MO' },
  { value: 'Ricketts_IA', label: 'Ricketts, IA' },
  { value: 'Rickman_TN', label: 'Rickman, TN' },
  { value: 'Rickreall_OR', label: 'Rickreall, OR' },
  { value: 'Rico_CO', label: 'Rico, CO' },
  { value: 'Riddle_OR', label: 'Riddle, OR' },
  { value: 'Riddlesburg_PA', label: 'Riddlesburg, PA' },
  { value: 'Riddleton_TN', label: 'Riddleton, TN' },
  { value: 'Ridge_NY', label: 'Ridge, NY' },
  { value: 'Ridge_MD', label: 'Ridge, MD' },
  { value: 'Ridge Farm_IL', label: 'Ridge Farm, IL' },
  { value: 'Ridge Spring_SC', label: 'Ridge Spring, SC' },
  { value: 'Ridgecrest_NC', label: 'Ridgecrest, NC' },
  { value: 'Ridgecrest_CA', label: 'Ridgecrest, CA' },
  { value: 'Ridgedale_MO', label: 'Ridgedale, MO' },
  { value: 'Ridgefield_CT', label: 'Ridgefield, CT' },
  { value: 'Ridgefield_NJ', label: 'Ridgefield, NJ' },
  { value: 'Ridgefield_WA', label: 'Ridgefield, WA' },
  { value: 'Ridgefield Park_NJ', label: 'Ridgefield Park, NJ' },
  { value: 'Ridgeland_SC', label: 'Ridgeland, SC' },
  { value: 'Ridgeland_MS', label: 'Ridgeland, MS' },
  { value: 'Ridgeland_WI', label: 'Ridgeland, WI' },
  { value: 'Ridgeley_WV', label: 'Ridgeley, WV' },
  { value: 'Ridgely_MD', label: 'Ridgely, MD' },
  { value: 'Ridgely_TN', label: 'Ridgely, TN' },
  { value: 'Ridgeview_WV', label: 'Ridgeview, WV' },
  { value: 'Ridgeview_SD', label: 'Ridgeview, SD' },
  { value: 'Ridgeville_SC', label: 'Ridgeville, SC' },
  { value: 'Ridgeville_IN', label: 'Ridgeville, IN' },
  { value: 'Ridgeville Corners_OH', label: 'Ridgeville Corners, OH' },
  { value: 'Ridgeway_VA', label: 'Ridgeway, VA' },
  { value: 'Ridgeway_NC', label: 'Ridgeway, NC' },
  { value: 'Ridgeway_SC', label: 'Ridgeway, SC' },
  { value: 'Ridgeway_OH', label: 'Ridgeway, OH' },
  { value: 'Ridgeway_IA', label: 'Ridgeway, IA' },
  { value: 'Ridgeway_WI', label: 'Ridgeway, WI' },
  { value: 'Ridgeway_MO', label: 'Ridgeway, MO' },
  { value: 'Ridgewood_NJ', label: 'Ridgewood, NJ' },
  { value: 'Ridgewood_NY', label: 'Ridgewood, NY' },
  { value: 'Ridgway_PA', label: 'Ridgway, PA' },
  { value: 'Ridgway_IL', label: 'Ridgway, IL' },
  { value: 'Ridgway_CO', label: 'Ridgway, CO' },
  { value: 'Ridley Park_PA', label: 'Ridley Park, PA' },
  { value: 'Ridott_IL', label: 'Ridott, IL' },
  { value: 'Riegelsville_PA', label: 'Riegelsville, PA' },
  { value: 'Riegelwood_NC', label: 'Riegelwood, NC' },
  { value: 'Rienzi_MS', label: 'Rienzi, MS' },
  { value: 'Riesel_TX', label: 'Riesel, TX' },
  { value: 'Rifle_CO', label: 'Rifle, CO' },
  { value: 'Rifton_NY', label: 'Rifton, NY' },
  { value: 'Riga_MI', label: 'Riga, MI' },
  { value: 'Rigby_ID', label: 'Rigby, ID' },
  { value: 'Riggins_ID', label: 'Riggins, ID' },
  { value: 'Riley_IN', label: 'Riley, IN' },
  { value: 'Riley_KS', label: 'Riley, KS' },
  { value: 'Riley_OR', label: 'Riley, OR' },
  { value: 'Rileyville_VA', label: 'Rileyville, VA' },
  { value: 'Rillito_AZ', label: 'Rillito, AZ' },
  { value: 'Rillton_PA', label: 'Rillton, PA' },
  { value: 'Rimersburg_PA', label: 'Rimersburg, PA' },
  { value: 'Rimforest_CA', label: 'Rimforest, CA' },
  { value: 'Rimrock_AZ', label: 'Rimrock, AZ' },
  { value: 'Rinard_IL', label: 'Rinard, IL' },
  { value: 'Rincon_PR', label: 'Rincon, PR' },
  { value: 'Rincon_GA', label: 'Rincon, GA' },
  { value: 'Rincon_NM', label: 'Rincon, NM' },
  { value: 'Rindge_NH', label: 'Rindge, NH' },
  { value: 'Riner_VA', label: 'Riner, VA' },
  { value: 'Rineyville_KY', label: 'Rineyville, KY' },
  { value: 'Ringgold_PA', label: 'Ringgold, PA' },
  { value: 'Ringgold_VA', label: 'Ringgold, VA' },
  { value: 'Ringgold_GA', label: 'Ringgold, GA' },
  { value: 'Ringgold_LA', label: 'Ringgold, LA' },
  { value: 'Ringgold_TX', label: 'Ringgold, TX' },
  { value: 'Ringle_WI', label: 'Ringle, WI' },
  { value: 'Ringling_MT', label: 'Ringling, MT' },
  { value: 'Ringling_OK', label: 'Ringling, OK' },
  { value: 'Ringoes_NJ', label: 'Ringoes, NJ' },
  { value: 'Ringold_OK', label: 'Ringold, OK' },
  { value: 'Ringsted_IA', label: 'Ringsted, IA' },
  { value: 'Ringtown_PA', label: 'Ringtown, PA' },
  { value: 'Ringwood_NJ', label: 'Ringwood, NJ' },
  { value: 'Ringwood_IL', label: 'Ringwood, IL' },
  { value: 'Ringwood_OK', label: 'Ringwood, OK' },
  { value: 'Rio_WV', label: 'Rio, WV' },
  { value: 'Rio_WI', label: 'Rio, WI' },
  { value: 'Rio_IL', label: 'Rio, IL' },
  { value: 'Rio Dell_CA', label: 'Rio Dell, CA' },
  { value: 'Rio Frio_TX', label: 'Rio Frio, TX' },
  { value: 'Rio Grande_PR', label: 'Rio Grande, PR' },
  { value: 'Rio Grande_NJ', label: 'Rio Grande, NJ' },
  { value: 'Rio Grande_OH', label: 'Rio Grande, OH' },
  { value: 'Rio Grande City_TX', label: 'Rio Grande City, TX' },
  { value: 'Rio Hondo_TX', label: 'Rio Hondo, TX' },
  { value: 'Rio Linda_CA', label: 'Rio Linda, CA' },
  { value: 'Rio Medina_TX', label: 'Rio Medina, TX' },
  { value: 'Rio Nido_CA', label: 'Rio Nido, CA' },
  { value: 'Rio Oso_CA', label: 'Rio Oso, CA' },
  { value: 'Rio Rancho_NM', label: 'Rio Rancho, NM' },
  { value: 'Rio Rico_AZ', label: 'Rio Rico, AZ' },
  { value: 'Rio Verde_AZ', label: 'Rio Verde, AZ' },
  { value: 'Rio Vista_TX', label: 'Rio Vista, TX' },
  { value: 'Rio Vista_CA', label: 'Rio Vista, CA' },
  { value: 'Riparius_NY', label: 'Riparius, NY' },
  { value: 'Ripley_NY', label: 'Ripley, NY' },
  { value: 'Ripley_WV', label: 'Ripley, WV' },
  { value: 'Ripley_TN', label: 'Ripley, TN' },
  { value: 'Ripley_MS', label: 'Ripley, MS' },
  { value: 'Ripley_OH', label: 'Ripley, OH' },
  { value: 'Ripley_OK', label: 'Ripley, OK' },
  { value: 'Ripon_WI', label: 'Ripon, WI' },
  { value: 'Ripon_CA', label: 'Ripon, CA' },
  { value: 'Rippey_IA', label: 'Rippey, IA' },
  { value: 'Ripplemead_VA', label: 'Ripplemead, VA' },
  { value: 'Ripton_VT', label: 'Ripton, VT' },
  { value: 'Ririe_ID', label: 'Ririe, ID' },
  { value: 'Risco_MO', label: 'Risco, MO' },
  { value: 'Rising City_NE', label: 'Rising City, NE' },
  { value: 'Rising Fawn_GA', label: 'Rising Fawn, GA' },
  { value: 'Rising Star_TX', label: 'Rising Star, TX' },
  { value: 'Rising Sun_MD', label: 'Rising Sun, MD' },
  { value: 'Rising Sun_IN', label: 'Rising Sun, IN' },
  { value: 'Risingsun_OH', label: 'Risingsun, OH' },
  { value: 'Rison_AR', label: 'Rison, AR' },
  { value: 'Rittman_OH', label: 'Rittman, OH' },
  { value: 'Ritzville_WA', label: 'Ritzville, WA' },
  { value: 'Riva_MD', label: 'Riva, MD' },
  { value: 'River_KY', label: 'River, KY' },
  { value: 'River Edge_NJ', label: 'River Edge, NJ' },
  { value: 'River Falls_AL', label: 'River Falls, AL' },
  { value: 'River Falls_WI', label: 'River Falls, WI' },
  { value: 'River Forest_IL', label: 'River Forest, IL' },
  { value: 'River Grove_IL', label: 'River Grove, IL' },
  { value: 'River Pines_CA', label: 'River Pines, CA' },
  { value: 'River Ranch_FL', label: 'River Ranch, FL' },
  { value: 'River Rouge_MI', label: 'River Rouge, MI' },
  { value: 'Riverbank_CA', label: 'Riverbank, CA' },
  { value: 'Riverdale_NJ', label: 'Riverdale, NJ' },
  { value: 'Riverdale_MD', label: 'Riverdale, MD' },
  { value: 'Riverdale_GA', label: 'Riverdale, GA' },
  { value: 'Riverdale_MI', label: 'Riverdale, MI' },
  { value: 'Riverdale_ND', label: 'Riverdale, ND' },
  { value: 'Riverdale_IL', label: 'Riverdale, IL' },
  { value: 'Riverdale_NE', label: 'Riverdale, NE' },
  { value: 'Riverdale_CA', label: 'Riverdale, CA' },
  { value: 'Riverhead_NY', label: 'Riverhead, NY' },
  { value: 'Riverside_RI', label: 'Riverside, RI' },
  { value: 'Riverside_CT', label: 'Riverside, CT' },
  { value: 'Riverside_NJ', label: 'Riverside, NJ' },
  { value: 'Riverside_PA', label: 'Riverside, PA' },
  { value: 'Riverside_AL', label: 'Riverside, AL' },
  { value: 'Riverside_MI', label: 'Riverside, MI' },
  { value: 'Riverside_IA', label: 'Riverside, IA' },
  { value: 'Riverside_IL', label: 'Riverside, IL' },
  { value: 'Riverside_MO', label: 'Riverside, MO' },
  { value: 'Riverside_TX', label: 'Riverside, TX' },
  { value: 'Riverside_UT', label: 'Riverside, UT' },
  { value: 'Riverside_CA', label: 'Riverside, CA' },
  { value: 'Riverside_OR', label: 'Riverside, OR' },
  { value: 'Riverside_WA', label: 'Riverside, WA' },
  { value: 'Riverton_CT', label: 'Riverton, CT' },
  { value: 'Riverton_NJ', label: 'Riverton, NJ' },
  { value: 'Riverton_WV', label: 'Riverton, WV' },
  { value: 'Riverton_IA', label: 'Riverton, IA' },
  { value: 'Riverton_IL', label: 'Riverton, IL' },
  { value: 'Riverton_KS', label: 'Riverton, KS' },
  { value: 'Riverton_NE', label: 'Riverton, NE' },
  { value: 'Riverton_WY', label: 'Riverton, WY' },
  { value: 'Riverton_UT', label: 'Riverton, UT' },
  { value: 'Rivervale_AR', label: 'Rivervale, AR' },
  { value: 'Riverview_FL', label: 'Riverview, FL' },
  { value: 'Riverview_MI', label: 'Riverview, MI' },
  { value: 'Rives_TN', label: 'Rives, TN' },
  { value: 'Rives Junction_MI', label: 'Rives Junction, MI' },
  { value: 'Rivesville_WV', label: 'Rivesville, WV' },
  { value: 'Riviera_TX', label: 'Riviera, TX' },
  { value: 'Rixeyville_VA', label: 'Rixeyville, VA' },
  { value: 'Rixford_PA', label: 'Rixford, PA' },
  { value: 'Roach_MO', label: 'Roach, MO' },
  { value: 'Roachdale_IN', label: 'Roachdale, IN' },
  { value: 'Roan Mountain_TN', label: 'Roan Mountain, TN' },
  { value: 'Roann_IN', label: 'Roann, IN' },
  { value: 'Roanoke_VA', label: 'Roanoke, VA' },
  { value: 'Roanoke_AL', label: 'Roanoke, AL' },
  { value: 'Roanoke_IN', label: 'Roanoke, IN' },
  { value: 'Roanoke_IL', label: 'Roanoke, IL' },
  { value: 'Roanoke_LA', label: 'Roanoke, LA' },
  { value: 'Roanoke_TX', label: 'Roanoke, TX' },
  { value: 'Roanoke Rapids_NC', label: 'Roanoke Rapids, NC' },
  { value: 'Roans Prairie_TX', label: 'Roans Prairie, TX' },
  { value: 'Roaring Branch_PA', label: 'Roaring Branch, PA' },
  { value: 'Roaring Gap_NC', label: 'Roaring Gap, NC' },
  { value: 'Roaring River_NC', label: 'Roaring River, NC' },
  { value: 'Roaring Spring_PA', label: 'Roaring Spring, PA' },
  { value: 'Roaring Springs_TX', label: 'Roaring Springs, TX' },
  { value: 'Roark_KY', label: 'Roark, KY' },
  { value: 'Robards_KY', label: 'Robards, KY' },
  { value: 'Robbins_NC', label: 'Robbins, NC' },
  { value: 'Robbins_TN', label: 'Robbins, TN' },
  { value: 'Robbins_IL', label: 'Robbins, IL' },
  { value: 'Robbins_CA', label: 'Robbins, CA' },
  { value: 'Robbinston_ME', label: 'Robbinston, ME' },
  { value: 'Robbinsville_NJ', label: 'Robbinsville, NJ' },
  { value: 'Robbinsville_NC', label: 'Robbinsville, NC' },
  { value: 'Robeline_LA', label: 'Robeline, LA' },
  { value: 'Robersonville_NC', label: 'Robersonville, NC' },
  { value: 'Robert_LA', label: 'Robert, LA' },
  { value: 'Robert Lee_TX', label: 'Robert Lee, TX' },
  { value: 'Roberta_GA', label: 'Roberta, GA' },
  { value: 'Roberts_WI', label: 'Roberts, WI' },
  { value: 'Roberts_MT', label: 'Roberts, MT' },
  { value: 'Roberts_IL', label: 'Roberts, IL' },
  { value: 'Roberts_ID', label: 'Roberts, ID' },
  { value: 'Robertsdale_PA', label: 'Robertsdale, PA' },
  { value: 'Robertsdale_AL', label: 'Robertsdale, AL' },
  { value: 'Robertson_WY', label: 'Robertson, WY' },
  { value: 'Robertsville_OH', label: 'Robertsville, OH' },
  { value: 'Robertsville_MO', label: 'Robertsville, MO' },
  { value: 'Robesonia_PA', label: 'Robesonia, PA' },
  { value: 'Robins_IA', label: 'Robins, IA' },
  { value: 'Robinson_PA', label: 'Robinson, PA' },
  { value: 'Robinson_ND', label: 'Robinson, ND' },
  { value: 'Robinson_IL', label: 'Robinson, IL' },
  { value: 'Robinson_KS', label: 'Robinson, KS' },
  { value: 'Robinson Creek_KY', label: 'Robinson Creek, KY' },
  { value: 'Robinsonville_MS', label: 'Robinsonville, MS' },
  { value: 'Robson_WV', label: 'Robson, WV' },
  { value: 'Robstown_TX', label: 'Robstown, TX' },
  { value: 'Roby_MO', label: 'Roby, MO' },
  { value: 'Roby_TX', label: 'Roby, TX' },
  { value: 'Roca_NE', label: 'Roca, NE' },
  { value: 'Rochdale_MA', label: 'Rochdale, MA' },
  { value: 'Rochelle_VA', label: 'Rochelle, VA' },
  { value: 'Rochelle_GA', label: 'Rochelle, GA' },
  { value: 'Rochelle_IL', label: 'Rochelle, IL' },
  { value: 'Rochelle_TX', label: 'Rochelle, TX' },
  { value: 'Rochelle Park_NJ', label: 'Rochelle Park, NJ' },
  { value: 'Rocheport_MO', label: 'Rocheport, MO' },
  { value: 'Rochert_MN', label: 'Rochert, MN' },
  { value: 'Rochester_MA', label: 'Rochester, MA' },
  { value: 'Rochester_NH', label: 'Rochester, NH' },
  { value: 'Rochester_VT', label: 'Rochester, VT' },
  { value: 'Rochester_NY', label: 'Rochester, NY' },
  { value: 'Rochester_PA', label: 'Rochester, PA' },
  { value: 'Rochester_KY', label: 'Rochester, KY' },
  { value: 'Rochester_IN', label: 'Rochester, IN' },
  { value: 'Rochester_MI', label: 'Rochester, MI' },
  { value: 'Rochester_WI', label: 'Rochester, WI' },
  { value: 'Rochester_MN', label: 'Rochester, MN' },
  { value: 'Rochester_IL', label: 'Rochester, IL' },
  { value: 'Rochester_TX', label: 'Rochester, TX' },
  { value: 'Rochester_WA', label: 'Rochester, WA' },
  { value: 'Rochester Mills_PA', label: 'Rochester Mills, PA' },
  { value: 'Rociada_NM', label: 'Rociada, NM' },
  { value: 'Rock_WV', label: 'Rock, WV' },
  { value: 'Rock_MI', label: 'Rock, MI' },
  { value: 'Rock_KS', label: 'Rock, KS' },
  { value: 'Rock Cave_WV', label: 'Rock Cave, WV' },
  { value: 'Rock City_IL', label: 'Rock City, IL' },
  { value: 'Rock City Falls_NY', label: 'Rock City Falls, NY' },
  { value: 'Rock Creek_WV', label: 'Rock Creek, WV' },
  { value: 'Rock Creek_OH', label: 'Rock Creek, OH' },
  { value: 'Rock Falls_IA', label: 'Rock Falls, IA' },
  { value: 'Rock Falls_IL', label: 'Rock Falls, IL' },
  { value: 'Rock Glen_PA', label: 'Rock Glen, PA' },
  { value: 'Rock Hall_MD', label: 'Rock Hall, MD' },
  { value: 'Rock Hill_NY', label: 'Rock Hill, NY' },
  { value: 'Rock Hill_SC', label: 'Rock Hill, SC' },
  { value: 'Rock Island_TN', label: 'Rock Island, TN' },
  { value: 'Rock Island_IL', label: 'Rock Island, IL' },
  { value: 'Rock Island_TX', label: 'Rock Island, TX' },
  { value: 'Rock Island_WA', label: 'Rock Island, WA' },
  { value: 'Rock Point_AZ', label: 'Rock Point, AZ' },
  { value: 'Rock Port_MO', label: 'Rock Port, MO' },
  { value: 'Rock Rapids_IA', label: 'Rock Rapids, IA' },
  { value: 'Rock River_WY', label: 'Rock River, WY' },
  { value: 'Rock Spring_GA', label: 'Rock Spring, GA' },
  { value: 'Rock Springs_WI', label: 'Rock Springs, WI' },
  { value: 'Rock Springs_WY', label: 'Rock Springs, WY' },
  { value: 'Rock Stream_NY', label: 'Rock Stream, NY' },
  { value: 'Rock Tavern_NY', label: 'Rock Tavern, NY' },
  { value: 'Rock Valley_IA', label: 'Rock Valley, IA' },
  { value: 'Rock View_WV', label: 'Rock View, WV' },
  { value: 'Rockaway_NJ', label: 'Rockaway, NJ' },
  { value: 'Rockaway Beach_MO', label: 'Rockaway Beach, MO' },
  { value: 'Rockaway Beach_OR', label: 'Rockaway Beach, OR' },
  { value: 'Rockaway Park_NY', label: 'Rockaway Park, NY' },
  { value: 'Rockbridge_OH', label: 'Rockbridge, OH' },
  { value: 'Rockbridge_IL', label: 'Rockbridge, IL' },
  { value: 'Rockbridge_MO', label: 'Rockbridge, MO' },
  { value: 'Rockbridge Baths_VA', label: 'Rockbridge Baths, VA' },
  { value: 'Rockdale_TX', label: 'Rockdale, TX' },
  { value: 'Rockfall_CT', label: 'Rockfall, CT' },
  { value: 'Rockfield_KY', label: 'Rockfield, KY' },
  { value: 'Rockford_AL', label: 'Rockford, AL' },
  { value: 'Rockford_TN', label: 'Rockford, TN' },
  { value: 'Rockford_OH', label: 'Rockford, OH' },
  { value: 'Rockford_MI', label: 'Rockford, MI' },
  { value: 'Rockford_IA', label: 'Rockford, IA' },
  { value: 'Rockford_MN', label: 'Rockford, MN' },
  { value: 'Rockford_IL', label: 'Rockford, IL' },
  { value: 'Rockford_WA', label: 'Rockford, WA' },
  { value: 'Rockham_SD', label: 'Rockham, SD' },
  { value: 'Rockhill Furnace_PA', label: 'Rockhill Furnace, PA' },
  { value: 'Rockholds_KY', label: 'Rockholds, KY' },
  { value: 'Rockingham_NC', label: 'Rockingham, NC' },
  { value: 'Rocklake_ND', label: 'Rocklake, ND' },
  { value: 'Rockland_MA', label: 'Rockland, MA' },
  { value: 'Rockland_ME', label: 'Rockland, ME' },
  { value: 'Rockland_DE', label: 'Rockland, DE' },
  { value: 'Rockland_MI', label: 'Rockland, MI' },
  { value: 'Rockland_WI', label: 'Rockland, WI' },
  { value: 'Rockland_ID', label: 'Rockland, ID' },
  { value: 'Rockledge_GA', label: 'Rockledge, GA' },
  { value: 'Rockledge_FL', label: 'Rockledge, FL' },
  { value: 'Rocklin_CA', label: 'Rocklin, CA' },
  { value: 'Rockmart_GA', label: 'Rockmart, GA' },
  { value: 'Rockport_MA', label: 'Rockport, MA' },
  { value: 'Rockport_ME', label: 'Rockport, ME' },
  { value: 'Rockport_WV', label: 'Rockport, WV' },
  { value: 'Rockport_KY', label: 'Rockport, KY' },
  { value: 'Rockport_IN', label: 'Rockport, IN' },
  { value: 'Rockport_IL', label: 'Rockport, IL' },
  { value: 'Rockport_TX', label: 'Rockport, TX' },
  { value: 'Rockport_WA', label: 'Rockport, WA' },
  { value: 'Rocksprings_TX', label: 'Rocksprings, TX' },
  { value: 'Rockton_PA', label: 'Rockton, PA' },
  { value: 'Rockton_IL', label: 'Rockton, IL' },
  { value: 'Rockvale_TN', label: 'Rockvale, TN' },
  { value: 'Rockvale_CO', label: 'Rockvale, CO' },
  { value: 'Rockville_RI', label: 'Rockville, RI' },
  { value: 'Rockville_MD', label: 'Rockville, MD' },
  { value: 'Rockville_VA', label: 'Rockville, VA' },
  { value: 'Rockville_IN', label: 'Rockville, IN' },
  { value: 'Rockville_MN', label: 'Rockville, MN' },
  { value: 'Rockville_MO', label: 'Rockville, MO' },
  { value: 'Rockville_NE', label: 'Rockville, NE' },
  { value: 'Rockville_UT', label: 'Rockville, UT' },
  { value: 'Rockville Centre_NY', label: 'Rockville Centre, NY' },
  { value: 'Rockwall_TX', label: 'Rockwall, TX' },
  { value: 'Rockwell_NC', label: 'Rockwell, NC' },
  { value: 'Rockwell_IA', label: 'Rockwell, IA' },
  { value: 'Rockwell City_IA', label: 'Rockwell City, IA' },
  { value: 'Rockwood_ME', label: 'Rockwood, ME' },
  { value: 'Rockwood_PA', label: 'Rockwood, PA' },
  { value: 'Rockwood_TN', label: 'Rockwood, TN' },
  { value: 'Rockwood_MI', label: 'Rockwood, MI' },
  { value: 'Rockwood_IL', label: 'Rockwood, IL' },
  { value: 'Rockwood_TX', label: 'Rockwood, TX' },
  { value: 'Rocky_OK', label: 'Rocky, OK' },
  { value: 'Rocky Comfort_MO', label: 'Rocky Comfort, MO' },
  { value: 'Rocky Face_GA', label: 'Rocky Face, GA' },
  { value: 'Rocky Ford_GA', label: 'Rocky Ford, GA' },
  { value: 'Rocky Ford_CO', label: 'Rocky Ford, CO' },
  { value: 'Rocky Gap_VA', label: 'Rocky Gap, VA' },
  { value: 'Rocky Hill_CT', label: 'Rocky Hill, CT' },
  { value: 'Rocky Hill_NJ', label: 'Rocky Hill, NJ' },
  { value: 'Rocky Hill_KY', label: 'Rocky Hill, KY' },
  { value: 'Rocky Mount_VA', label: 'Rocky Mount, VA' },
  { value: 'Rocky Mount_NC', label: 'Rocky Mount, NC' },
  { value: 'Rocky Mount_MO', label: 'Rocky Mount, MO' },
  { value: 'Rocky Point_NY', label: 'Rocky Point, NY' },
  { value: 'Rocky Point_NC', label: 'Rocky Point, NC' },
  { value: 'Rocky Ridge_MD', label: 'Rocky Ridge, MD' },
  { value: 'Rocky Ridge_OH', label: 'Rocky Ridge, OH' },
  { value: 'Rocky River_OH', label: 'Rocky River, OH' },
  { value: 'Rocky Top_TN', label: 'Rocky Top, TN' },
  { value: 'Rodanthe_NC', label: 'Rodanthe, NC' },
  { value: 'Rodeo_NM', label: 'Rodeo, NM' },
  { value: 'Rodeo_CA', label: 'Rodeo, CA' },
  { value: 'Roderfield_WV', label: 'Roderfield, WV' },
  { value: 'Rodessa_LA', label: 'Rodessa, LA' },
  { value: 'Rodman_NY', label: 'Rodman, NY' },
  { value: 'Rodney_MI', label: 'Rodney, MI' },
  { value: 'Rodney_IA', label: 'Rodney, IA' },
  { value: 'Roduco_NC', label: 'Roduco, NC' },
  { value: 'Roe_AR', label: 'Roe, AR' },
  { value: 'Roebling_NJ', label: 'Roebling, NJ' },
  { value: 'Roebuck_SC', label: 'Roebuck, SC' },
  { value: 'Roff_OK', label: 'Roff, OK' },
  { value: 'Rogers_CT', label: 'Rogers, CT' },
  { value: 'Rogers_KY', label: 'Rogers, KY' },
  { value: 'Rogers_OH', label: 'Rogers, OH' },
  { value: 'Rogers_MN', label: 'Rogers, MN' },
  { value: 'Rogers_ND', label: 'Rogers, ND' },
  { value: 'Rogers_NE', label: 'Rogers, NE' },
  { value: 'Rogers_AR', label: 'Rogers, AR' },
  { value: 'Rogers_TX', label: 'Rogers, TX' },
  { value: 'Rogers_NM', label: 'Rogers, NM' },
  { value: 'Rogers City_MI', label: 'Rogers City, MI' },
  { value: 'Rogerson_ID', label: 'Rogerson, ID' },
  { value: 'Rogersville_PA', label: 'Rogersville, PA' },
  { value: 'Rogersville_AL', label: 'Rogersville, AL' },
  { value: 'Rogersville_TN', label: 'Rogersville, TN' },
  { value: 'Rogersville_MO', label: 'Rogersville, MO' },
  { value: 'Roggen_CO', label: 'Roggen, CO' },
  { value: 'Rogue River_OR', label: 'Rogue River, OR' },
  { value: 'Rohnert Park_CA', label: 'Rohnert Park, CA' },
  { value: 'Rohrersville_MD', label: 'Rohrersville, MD' },
  { value: 'Rohwer_AR', label: 'Rohwer, AR' },
  { value: 'Roland_IA', label: 'Roland, IA' },
  { value: 'Roland_AR', label: 'Roland, AR' },
  { value: 'Roland_OK', label: 'Roland, OK' },
  { value: 'Rolesville_NC', label: 'Rolesville, NC' },
  { value: 'Rolette_ND', label: 'Rolette, ND' },
  { value: 'Rolfe_IA', label: 'Rolfe, IA' },
  { value: 'Roll_AZ', label: 'Roll, AZ' },
  { value: 'Rolla_ND', label: 'Rolla, ND' },
  { value: 'Rolla_MO', label: 'Rolla, MO' },
  { value: 'Rolla_KS', label: 'Rolla, KS' },
  { value: 'Rolling Fork_MS', label: 'Rolling Fork, MS' },
  { value: 'Rolling Meadows_IL', label: 'Rolling Meadows, IL' },
  { value: 'Rolling Prairie_IN', label: 'Rolling Prairie, IN' },
  { value: 'Rollingstone_MN', label: 'Rollingstone, MN' },
  { value: 'Rollins_MT', label: 'Rollins, MT' },
  { value: 'Rollinsford_NH', label: 'Rollinsford, NH' },
  { value: 'Rollinsville_CO', label: 'Rollinsville, CO' },
  { value: 'Roma_TX', label: 'Roma, TX' },
  { value: 'Romance_AR', label: 'Romance, AR' },
  { value: 'Romayor_TX', label: 'Romayor, TX' },
  { value: 'Rombauer_MO', label: 'Rombauer, MO' },
  { value: 'Rome_NY', label: 'Rome, NY' },
  { value: 'Rome_PA', label: 'Rome, PA' },
  { value: 'Rome_GA', label: 'Rome, GA' },
  { value: 'Rome_MS', label: 'Rome, MS' },
  { value: 'Rome_OH', label: 'Rome, OH' },
  { value: 'Rome_IN', label: 'Rome, IN' },
  { value: 'Rome_IL', label: 'Rome, IL' },
  { value: 'Rome City_IN', label: 'Rome City, IN' },
  { value: 'Romeo_MI', label: 'Romeo, MI' },
  { value: 'Romeo_CO', label: 'Romeo, CO' },
  { value: 'Romeoville_IL', label: 'Romeoville, IL' },
  { value: 'Romney_WV', label: 'Romney, WV' },
  { value: 'Romney_IN', label: 'Romney, IN' },
  { value: 'Romulus_NY', label: 'Romulus, NY' },
  { value: 'Romulus_MI', label: 'Romulus, MI' },
  { value: 'Ronald_WA', label: 'Ronald, WA' },
  { value: 'Ronan_MT', label: 'Ronan, MT' },
  { value: 'Ronceverte_WV', label: 'Ronceverte, WV' },
  { value: 'Ronco_PA', label: 'Ronco, PA' },
  { value: 'Ronda_NC', label: 'Ronda, NC' },
  { value: 'Ronkonkoma_NY', label: 'Ronkonkoma, NY' },
  { value: 'Ronks_PA', label: 'Ronks, PA' },
  { value: 'Roodhouse_IL', label: 'Roodhouse, IL' },
  { value: 'Roopville_GA', label: 'Roopville, GA' },
  { value: 'Roosevelt_NJ', label: 'Roosevelt, NJ' },
  { value: 'Roosevelt_NY', label: 'Roosevelt, NY' },
  { value: 'Roosevelt_MN', label: 'Roosevelt, MN' },
  { value: 'Roosevelt_OK', label: 'Roosevelt, OK' },
  { value: 'Roosevelt_TX', label: 'Roosevelt, TX' },
  { value: 'Roosevelt_UT', label: 'Roosevelt, UT' },
  { value: 'Roosevelt_AZ', label: 'Roosevelt, AZ' },
  { value: 'Roosevelt_WA', label: 'Roosevelt, WA' },
  { value: 'Rooseveltown_NY', label: 'Rooseveltown, NY' },
  { value: 'Rootstown_OH', label: 'Rootstown, OH' },
  { value: 'Roper_NC', label: 'Roper, NC' },
  { value: 'Ropesville_TX', label: 'Ropesville, TX' },
  { value: 'Rosalia_KS', label: 'Rosalia, KS' },
  { value: 'Rosalia_WA', label: 'Rosalia, WA' },
  { value: 'Rosalie_NE', label: 'Rosalie, NE' },
  { value: 'Rosamond_IL', label: 'Rosamond, IL' },
  { value: 'Rosamond_CA', label: 'Rosamond, CA' },
  { value: 'Rosanky_TX', label: 'Rosanky, TX' },
  { value: 'Rosario_PR', label: 'Rosario, PR' },
  { value: 'Rosburg_WA', label: 'Rosburg, WA' },
  { value: 'Roscoe_NY', label: 'Roscoe, NY' },
  { value: 'Roscoe_PA', label: 'Roscoe, PA' },
  { value: 'Roscoe_MN', label: 'Roscoe, MN' },
  { value: 'Roscoe_SD', label: 'Roscoe, SD' },
  { value: 'Roscoe_MT', label: 'Roscoe, MT' },
  { value: 'Roscoe_IL', label: 'Roscoe, IL' },
  { value: 'Roscoe_MO', label: 'Roscoe, MO' },
  { value: 'Roscoe_TX', label: 'Roscoe, TX' },
  { value: 'Roscommon_MI', label: 'Roscommon, MI' },
  { value: 'Rose_NY', label: 'Rose, NY' },
  { value: 'Rose_OK', label: 'Rose, OK' },
  { value: 'Rose Bud_AR', label: 'Rose Bud, AR' },
  { value: 'Rose City_MI', label: 'Rose City, MI' },
  { value: 'Rose Creek_MN', label: 'Rose Creek, MN' },
  { value: 'Rose Hill_VA', label: 'Rose Hill, VA' },
  { value: 'Rose Hill_NC', label: 'Rose Hill, NC' },
  { value: 'Rose Hill_MS', label: 'Rose Hill, MS' },
  { value: 'Rose Hill_IA', label: 'Rose Hill, IA' },
  { value: 'Rose Hill_KS', label: 'Rose Hill, KS' },
  { value: 'Roseau_MN', label: 'Roseau, MN' },
  { value: 'Roseboom_NY', label: 'Roseboom, NY' },
  { value: 'Roseboro_NC', label: 'Roseboro, NC' },
  { value: 'Rosebud_SD', label: 'Rosebud, SD' },
  { value: 'Rosebud_MT', label: 'Rosebud, MT' },
  { value: 'Rosebud_MO', label: 'Rosebud, MO' },
  { value: 'Rosebud_TX', label: 'Rosebud, TX' },
  { value: 'Roseburg_OR', label: 'Roseburg, OR' },
  { value: 'Rosebush_MI', label: 'Rosebush, MI' },
  { value: 'Rosedale_NY', label: 'Rosedale, NY' },
  { value: 'Rosedale_MD', label: 'Rosedale, MD' },
  { value: 'Rosedale_VA', label: 'Rosedale, VA' },
  { value: 'Rosedale_WV', label: 'Rosedale, WV' },
  { value: 'Rosedale_MS', label: 'Rosedale, MS' },
  { value: 'Rosedale_IN', label: 'Rosedale, IN' },
  { value: 'Rosedale_LA', label: 'Rosedale, LA' },
  { value: 'Roseglen_ND', label: 'Roseglen, ND' },
  { value: 'Roseland_NJ', label: 'Roseland, NJ' },
  { value: 'Roseland_VA', label: 'Roseland, VA' },
  { value: 'Roseland_NE', label: 'Roseland, NE' },
  { value: 'Roseland_LA', label: 'Roseland, LA' },
  { value: 'Roselle_NJ', label: 'Roselle, NJ' },
  { value: 'Roselle_IL', label: 'Roselle, IL' },
  { value: 'Roselle Park_NJ', label: 'Roselle Park, NJ' },
  { value: 'Rosemead_CA', label: 'Rosemead, CA' },
  { value: 'Rosemont_WV', label: 'Rosemont, WV' },
  { value: 'Rosemount_MN', label: 'Rosemount, MN' },
  { value: 'Rosenberg_TX', label: 'Rosenberg, TX' },
  { value: 'Rosendale_NY', label: 'Rosendale, NY' },
  { value: 'Rosendale_WI', label: 'Rosendale, WI' },
  { value: 'Rosendale_MO', label: 'Rosendale, MO' },
  { value: 'Rosenhayn_NJ', label: 'Rosenhayn, NJ' },
  { value: 'Rosepine_LA', label: 'Rosepine, LA' },
  { value: 'Roseville_OH', label: 'Roseville, OH' },
  { value: 'Roseville_MI', label: 'Roseville, MI' },
  { value: 'Roseville_IL', label: 'Roseville, IL' },
  { value: 'Roseville_CA', label: 'Roseville, CA' },
  { value: 'Rosewood_OH', label: 'Rosewood, OH' },
  { value: 'Rosharon_TX', label: 'Rosharon, TX' },
  { value: 'Rosholt_WI', label: 'Rosholt, WI' },
  { value: 'Rosholt_SD', label: 'Rosholt, SD' },
  { value: 'Rosiclare_IL', label: 'Rosiclare, IL' },
  { value: 'Rosie_AR', label: 'Rosie, AR' },
  { value: 'Rosine_KY', label: 'Rosine, KY' },
  { value: 'Roslindale_MA', label: 'Roslindale, MA' },
  { value: 'Roslyn_NY', label: 'Roslyn, NY' },
  { value: 'Roslyn_SD', label: 'Roslyn, SD' },
  { value: 'Roslyn_WA', label: 'Roslyn, WA' },
  { value: 'Roslyn Heights_NY', label: 'Roslyn Heights, NY' },
  { value: 'Rosman_NC', label: 'Rosman, NC' },
  { value: 'Ross_ND', label: 'Ross, ND' },
  { value: 'Ross_CA', label: 'Ross, CA' },
  { value: 'Rossburg_OH', label: 'Rossburg, OH' },
  { value: 'Rosser_TX', label: 'Rosser, TX' },
  { value: 'Rossford_OH', label: 'Rossford, OH' },
  { value: 'Rossiter_PA', label: 'Rossiter, PA' },
  { value: 'Rosston_AR', label: 'Rosston, AR' },
  { value: 'Rosston_OK', label: 'Rosston, OK' },
  { value: 'Rosston_TX', label: 'Rosston, TX' },
  { value: 'Rossville_GA', label: 'Rossville, GA' },
  { value: 'Rossville_TN', label: 'Rossville, TN' },
  { value: 'Rossville_IN', label: 'Rossville, IN' },
  { value: 'Rossville_IL', label: 'Rossville, IL' },
  { value: 'Rossville_KS', label: 'Rossville, KS' },
  { value: 'Roswell_GA', label: 'Roswell, GA' },
  { value: 'Roswell_NM', label: 'Roswell, NM' },
  { value: 'Rota_MP', label: 'Rota, MP' },
  { value: 'Rotan_TX', label: 'Rotan, TX' },
  { value: 'Rothbury_MI', label: 'Rothbury, MI' },
  { value: 'Rothsay_MN', label: 'Rothsay, MN' },
  { value: 'Rothschild_WI', label: 'Rothschild, WI' },
  { value: 'Rothville_MO', label: 'Rothville, MO' },
  { value: 'Rotonda West_FL', label: 'Rotonda West, FL' },
  { value: 'Rotterdam Junction_NY', label: 'Rotterdam Junction, NY' },
  { value: 'Rougemont_NC', label: 'Rougemont, NC' },
  { value: 'Rough And Ready_CA', label: 'Rough And Ready, CA' },
  { value: 'Rougon_LA', label: 'Rougon, LA' },
  { value: 'Roulette_PA', label: 'Roulette, PA' },
  { value: 'Round Hill_VA', label: 'Round Hill, VA' },
  { value: 'Round Lake_NY', label: 'Round Lake, NY' },
  { value: 'Round Lake_MN', label: 'Round Lake, MN' },
  { value: 'Round Lake_IL', label: 'Round Lake, IL' },
  { value: 'Round Mountain_TX', label: 'Round Mountain, TX' },
  { value: 'Round Mountain_NV', label: 'Round Mountain, NV' },
  { value: 'Round Mountain_CA', label: 'Round Mountain, CA' },
  { value: 'Round O_SC', label: 'Round O, SC' },
  { value: 'Round Pond_ME', label: 'Round Pond, ME' },
  { value: 'Round Rock_TX', label: 'Round Rock, TX' },
  { value: 'Round Rock_AZ', label: 'Round Rock, AZ' },
  { value: 'Round Top_NY', label: 'Round Top, NY' },
  { value: 'Round Top_TX', label: 'Round Top, TX' },
  { value: 'Roundhead_OH', label: 'Roundhead, OH' },
  { value: 'Roundhill_KY', label: 'Roundhill, KY' },
  { value: 'Roundup_MT', label: 'Roundup, MT' },
  { value: 'Rouses Point_NY', label: 'Rouses Point, NY' },
  { value: 'Rouseville_PA', label: 'Rouseville, PA' },
  { value: 'Rousseau_KY', label: 'Rousseau, KY' },
  { value: 'Rouzerville_PA', label: 'Rouzerville, PA' },
  { value: 'Rover_AR', label: 'Rover, AR' },
  { value: 'Rowan_IA', label: 'Rowan, IA' },
  { value: 'Rowdy_KY', label: 'Rowdy, KY' },
  { value: 'Rowe_MA', label: 'Rowe, MA' },
  { value: 'Rowe_VA', label: 'Rowe, VA' },
  { value: 'Rowe_NM', label: 'Rowe, NM' },
  { value: 'Rowena_TX', label: 'Rowena, TX' },
  { value: 'Rowesville_SC', label: 'Rowesville, SC' },
  { value: 'Rowland_PA', label: 'Rowland, PA' },
  { value: 'Rowland_NC', label: 'Rowland, NC' },
  { value: 'Rowland Heights_CA', label: 'Rowland Heights, CA' },
  { value: 'Rowlesburg_WV', label: 'Rowlesburg, WV' },
  { value: 'Rowlett_TX', label: 'Rowlett, TX' },
  { value: 'Rowley_MA', label: 'Rowley, MA' },
  { value: 'Rowley_IA', label: 'Rowley, IA' },
  { value: 'Roxana_KY', label: 'Roxana, KY' },
  { value: 'Roxana_IL', label: 'Roxana, IL' },
  { value: 'Roxboro_NC', label: 'Roxboro, NC' },
  { value: 'Roxbury_MA', label: 'Roxbury, MA' },
  { value: 'Roxbury_ME', label: 'Roxbury, ME' },
  { value: 'Roxbury_VT', label: 'Roxbury, VT' },
  { value: 'Roxbury_CT', label: 'Roxbury, CT' },
  { value: 'Roxbury_NY', label: 'Roxbury, NY' },
  { value: 'Roxbury_PA', label: 'Roxbury, PA' },
  { value: 'Roxbury_KS', label: 'Roxbury, KS' },
  { value: 'Roxbury Crossing_MA', label: 'Roxbury Crossing, MA' },
  { value: 'Roxie_MS', label: 'Roxie, MS' },
  { value: 'Roxobel_NC', label: 'Roxobel, NC' },
  { value: 'Roxton_TX', label: 'Roxton, TX' },
  { value: 'Roy_MT', label: 'Roy, MT' },
  { value: 'Roy_UT', label: 'Roy, UT' },
  { value: 'Roy_NM', label: 'Roy, NM' },
  { value: 'Roy_WA', label: 'Roy, WA' },
  { value: 'Royal_IA', label: 'Royal, IA' },
  { value: 'Royal_IL', label: 'Royal, IL' },
  { value: 'Royal_NE', label: 'Royal, NE' },
  { value: 'Royal_AR', label: 'Royal, AR' },
  { value: 'Royal Center_IN', label: 'Royal Center, IN' },
  { value: 'Royal City_WA', label: 'Royal City, WA' },
  { value: 'Royal Oak_MD', label: 'Royal Oak, MD' },
  { value: 'Royal Oak_MI', label: 'Royal Oak, MI' },
  { value: 'Royalston_MA', label: 'Royalston, MA' },
  { value: 'Royalton_KY', label: 'Royalton, KY' },
  { value: 'Royalton_MN', label: 'Royalton, MN' },
  { value: 'Royalton_IL', label: 'Royalton, IL' },
  { value: 'Royersford_PA', label: 'Royersford, PA' },
  { value: 'Royse City_TX', label: 'Royse City, TX' },
  { value: 'Royston_GA', label: 'Royston, GA' },
  { value: 'Rozel_KS', label: 'Rozel, KS' },
  { value: 'Rozet_WY', label: 'Rozet, WY' },
  { value: 'Rubicon_WI', label: 'Rubicon, WI' },
  { value: 'Ruby_NY', label: 'Ruby, NY' },
  { value: 'Ruby_SC', label: 'Ruby, SC' },
  { value: 'Ruby_AK', label: 'Ruby, AK' },
  { value: 'Ruby Valley_NV', label: 'Ruby Valley, NV' },
  { value: 'Ruckersville_VA', label: 'Ruckersville, VA' },
  { value: 'Rudd_IA', label: 'Rudd, IA' },
  { value: 'Rudolph_OH', label: 'Rudolph, OH' },
  { value: 'Rudolph_WI', label: 'Rudolph, WI' },
  { value: 'Rudy_AR', label: 'Rudy, AR' },
  { value: 'Rudyard_MI', label: 'Rudyard, MI' },
  { value: 'Rudyard_MT', label: 'Rudyard, MT' },
  { value: 'Rueter_MO', label: 'Rueter, MO' },
  { value: 'Rufe_OK', label: 'Rufe, OK' },
  { value: 'Ruffin_NC', label: 'Ruffin, NC' },
  { value: 'Ruffin_SC', label: 'Ruffin, SC' },
  { value: 'Ruffs Dale_PA', label: 'Ruffs Dale, PA' },
  { value: 'Rufus_OR', label: 'Rufus, OR' },
  { value: 'Rugby_TN', label: 'Rugby, TN' },
  { value: 'Rugby_ND', label: 'Rugby, ND' },
  { value: 'Ruidoso_NM', label: 'Ruidoso, NM' },
  { value: 'Ruidoso Downs_NM', label: 'Ruidoso Downs, NM' },
  { value: 'Rule_TX', label: 'Rule, TX' },
  { value: 'Ruleville_MS', label: 'Ruleville, MS' },
  { value: 'Rulo_NE', label: 'Rulo, NE' },
  { value: 'Rumely_MI', label: 'Rumely, MI' },
  { value: 'Rumford_RI', label: 'Rumford, RI' },
  { value: 'Rumford_ME', label: 'Rumford, ME' },
  { value: 'Rumney_NH', label: 'Rumney, NH' },
  { value: 'Rumsey_KY', label: 'Rumsey, KY' },
  { value: 'Rumsey_CA', label: 'Rumsey, CA' },
  { value: 'Rumson_NJ', label: 'Rumson, NJ' },
  { value: 'Runge_TX', label: 'Runge, TX' },
  { value: 'Runnells_IA', label: 'Runnells, IA' },
  { value: 'Runnemede_NJ', label: 'Runnemede, NJ' },
  { value: 'Running Springs_CA', label: 'Running Springs, CA' },
  { value: 'Rupert_VT', label: 'Rupert, VT' },
  { value: 'Rupert_WV', label: 'Rupert, WV' },
  { value: 'Rupert_GA', label: 'Rupert, GA' },
  { value: 'Rupert_ID', label: 'Rupert, ID' },
  { value: 'Rural Hall_NC', label: 'Rural Hall, NC' },
  { value: 'Rural Retreat_VA', label: 'Rural Retreat, VA' },
  { value: 'Rural Ridge_PA', label: 'Rural Ridge, PA' },
  { value: 'Rural Valley_PA', label: 'Rural Valley, PA' },
  { value: 'Rush_NY', label: 'Rush, NY' },
  { value: 'Rush_KY', label: 'Rush, KY' },
  { value: 'Rush_CO', label: 'Rush, CO' },
  { value: 'Rush Center_KS', label: 'Rush Center, KS' },
  { value: 'Rush City_MN', label: 'Rush City, MN' },
  { value: 'Rush Hill_MO', label: 'Rush Hill, MO' },
  { value: 'Rush Springs_OK', label: 'Rush Springs, OK' },
  { value: 'Rush Valley_UT', label: 'Rush Valley, UT' },
  { value: 'Rushford_NY', label: 'Rushford, NY' },
  { value: 'Rushford_MN', label: 'Rushford, MN' },
  { value: 'Rushmore_MN', label: 'Rushmore, MN' },
  { value: 'Rushsylvania_OH', label: 'Rushsylvania, OH' },
  { value: 'Rushville_NY', label: 'Rushville, NY' },
  { value: 'Rushville_OH', label: 'Rushville, OH' },
  { value: 'Rushville_IN', label: 'Rushville, IN' },
  { value: 'Rushville_IL', label: 'Rushville, IL' },
  { value: 'Rushville_MO', label: 'Rushville, MO' },
  { value: 'Rushville_NE', label: 'Rushville, NE' },
  { value: 'Rusk_TX', label: 'Rusk, TX' },
  { value: 'Ruskin_FL', label: 'Ruskin, FL' },
  { value: 'Ruskin_NE', label: 'Ruskin, NE' },
  { value: 'Ruso_ND', label: 'Ruso, ND' },
  { value: 'Russell_MA', label: 'Russell, MA' },
  { value: 'Russell_NY', label: 'Russell, NY' },
  { value: 'Russell_PA', label: 'Russell, PA' },
  { value: 'Russell_KY', label: 'Russell, KY' },
  { value: 'Russell_IA', label: 'Russell, IA' },
  { value: 'Russell_MN', label: 'Russell, MN' },
  { value: 'Russell_IL', label: 'Russell, IL' },
  { value: 'Russell_KS', label: 'Russell, KS' },
  { value: 'Russell_AR', label: 'Russell, AR' },
  { value: 'Russell Springs_KY', label: 'Russell Springs, KY' },
  { value: 'Russells Point_OH', label: 'Russells Point, OH' },
  { value: 'Russellton_PA', label: 'Russellton, PA' },
  { value: 'Russellville_AL', label: 'Russellville, AL' },
  { value: 'Russellville_TN', label: 'Russellville, TN' },
  { value: 'Russellville_KY', label: 'Russellville, KY' },
  { value: 'Russellville_OH', label: 'Russellville, OH' },
  { value: 'Russellville_IN', label: 'Russellville, IN' },
  { value: 'Russellville_MO', label: 'Russellville, MO' },
  { value: 'Russellville_AR', label: 'Russellville, AR' },
  { value: 'Russia_OH', label: 'Russia, OH' },
  { value: 'Russian Mission_AK', label: 'Russian Mission, AK' },
  { value: 'Russiaville_IN', label: 'Russiaville, IN' },
  { value: 'Rustburg_VA', label: 'Rustburg, VA' },
  { value: 'Ruston_LA', label: 'Ruston, LA' },
  { value: 'Ruth_MS', label: 'Ruth, MS' },
  { value: 'Ruth_MI', label: 'Ruth, MI' },
  { value: 'Ruth_NV', label: 'Ruth, NV' },
  { value: 'Ruther Glen_VA', label: 'Ruther Glen, VA' },
  { value: 'Rutherford_NJ', label: 'Rutherford, NJ' },
  { value: 'Rutherford_TN', label: 'Rutherford, TN' },
  { value: 'Rutherford_CA', label: 'Rutherford, CA' },
  { value: 'Rutherford College_NC', label: 'Rutherford College, NC' },
  { value: 'Rutherfordton_NC', label: 'Rutherfordton, NC' },
  { value: 'Ruthton_MN', label: 'Ruthton, MN' },
  { value: 'Ruthven_IA', label: 'Ruthven, IA' },
  { value: 'Ruthville_VA', label: 'Ruthville, VA' },
  { value: 'Rutland_MA', label: 'Rutland, MA' },
  { value: 'Rutland_VT', label: 'Rutland, VT' },
  { value: 'Rutland_OH', label: 'Rutland, OH' },
  { value: 'Rutland_IA', label: 'Rutland, IA' },
  { value: 'Rutland_SD', label: 'Rutland, SD' },
  { value: 'Rutland_ND', label: 'Rutland, ND' },
  { value: 'Rutland_IL', label: 'Rutland, IL' },
  { value: 'Rutledge_GA', label: 'Rutledge, GA' },
  { value: 'Rutledge_AL', label: 'Rutledge, AL' },
  { value: 'Rutledge_TN', label: 'Rutledge, TN' },
  { value: 'Rutledge_MO', label: 'Rutledge, MO' },
  { value: 'Ryan_IA', label: 'Ryan, IA' },
  { value: 'Ryan_OK', label: 'Ryan, OK' },
  { value: 'Rydal_GA', label: 'Rydal, GA' },
  { value: 'Ryde_CA', label: 'Ryde, CA' },
  { value: 'Ryder_ND', label: 'Ryder, ND' },
  { value: 'Ryderwood_WA', label: 'Ryderwood, WA' },
  { value: 'Rye_NH', label: 'Rye, NH' },
  { value: 'Rye_NY', label: 'Rye, NY' },
  { value: 'Rye_TX', label: 'Rye, TX' },
  { value: 'Rye_CO', label: 'Rye, CO' },
  { value: 'Rye Beach_NH', label: 'Rye Beach, NH' },
  { value: 'Ryegate_MT', label: 'Ryegate, MT' },
  { value: 'S Coffeyville_OK', label: 'S Coffeyville, OK' },
  { value: 'Sabael_NY', label: 'Sabael, NY' },
  { value: 'Sabana Grande_PR', label: 'Sabana Grande, PR' },
  { value: 'Sabana Hoyos_PR', label: 'Sabana Hoyos, PR' },
  { value: 'Sabana Seca_PR', label: 'Sabana Seca, PR' },
  { value: 'Sabattus_ME', label: 'Sabattus, ME' },
  { value: 'Sabetha_KS', label: 'Sabetha, KS' },
  { value: 'Sabillasville_MD', label: 'Sabillasville, MD' },
  { value: 'Sabin_MN', label: 'Sabin, MN' },
  { value: 'Sabina_OH', label: 'Sabina, OH' },
  { value: 'Sabinal_TX', label: 'Sabinal, TX' },
  { value: 'Sabine_WV', label: 'Sabine, WV' },
  { value: 'Sabine Pass_TX', label: 'Sabine Pass, TX' },
  { value: 'Sabinsville_PA', label: 'Sabinsville, PA' },
  { value: 'Sabula_IA', label: 'Sabula, IA' },
  { value: 'Sac City_IA', label: 'Sac City, IA' },
  { value: 'Sacaton_AZ', label: 'Sacaton, AZ' },
  { value: 'Sachse_TX', label: 'Sachse, TX' },
  { value: 'Sackets Harbor_NY', label: 'Sackets Harbor, NY' },
  { value: 'Saco_ME', label: 'Saco, ME' },
  { value: 'Saco_MT', label: 'Saco, MT' },
  { value: 'Sacramento_PA', label: 'Sacramento, PA' },
  { value: 'Sacramento_KY', label: 'Sacramento, KY' },
  { value: 'Sacramento_NM', label: 'Sacramento, NM' },
  { value: 'Sacramento_CA', label: 'Sacramento, CA' },
  { value: 'Sacred Heart_MN', label: 'Sacred Heart, MN' },
  { value: 'Sacul_TX', label: 'Sacul, TX' },
  { value: 'Saddle Brook_NJ', label: 'Saddle Brook, NJ' },
  { value: 'Saddle River_NJ', label: 'Saddle River, NJ' },
  { value: 'Sadieville_KY', label: 'Sadieville, KY' },
  { value: 'Sadler_TX', label: 'Sadler, TX' },
  { value: 'Sadorus_IL', label: 'Sadorus, IL' },
  { value: 'Sadsburyville_PA', label: 'Sadsburyville, PA' },
  { value: 'Saegertown_PA', label: 'Saegertown, PA' },
  { value: 'Safety Harbor_FL', label: 'Safety Harbor, FL' },
  { value: 'Saffell_AR', label: 'Saffell, AR' },
  { value: 'Safford_AL', label: 'Safford, AL' },
  { value: 'Safford_AZ', label: 'Safford, AZ' },
  { value: 'Sag Harbor_NY', label: 'Sag Harbor, NY' },
  { value: 'Sagamore_MA', label: 'Sagamore, MA' },
  { value: 'Sagamore_PA', label: 'Sagamore, PA' },
  { value: 'Sagamore Beach_MA', label: 'Sagamore Beach, MA' },
  { value: 'Sagaponack_NY', label: 'Sagaponack, NY' },
  { value: 'Sage_AR', label: 'Sage, AR' },
  { value: 'Saginaw_MI', label: 'Saginaw, MI' },
  { value: 'Saginaw_MN', label: 'Saginaw, MN' },
  { value: 'Sagle_ID', label: 'Sagle, ID' },
  { value: 'Sagola_MI', label: 'Sagola, MI' },
  { value: 'Saguache_CO', label: 'Saguache, CO' },
  { value: 'Sahuarita_AZ', label: 'Sahuarita, AZ' },
  { value: 'Sailor Springs_IL', label: 'Sailor Springs, IL' },
  { value: 'St Agatha_ME', label: 'St Agatha, ME' },
  { value: 'St Albans_ME', label: 'St Albans, ME' },
  { value: 'St Albans_VT', label: 'St Albans, VT' },
  { value: 'St Albans_NY', label: 'St Albans, NY' },
  { value: 'St Albans_WV', label: 'St Albans, WV' },
  { value: 'St Albans_MO', label: 'St Albans, MO' },
  { value: 'St Albans Bay_VT', label: 'St Albans Bay, VT' },
  { value: 'St Amant_LA', label: 'St Amant, LA' },
  { value: 'St Ann_MO', label: 'St Ann, MO' },
  { value: 'St Anne_IL', label: 'St Anne, IL' },
  { value: 'St Ansgar_IA', label: 'St Ansgar, IA' },
  { value: 'St Anthony_IN', label: 'St Anthony, IN' },
  { value: 'St Anthony_IA', label: 'St Anthony, IA' },
  { value: 'St Anthony_ND', label: 'St Anthony, ND' },
  { value: 'St Anthony_ID', label: 'St Anthony, ID' },
  { value: 'St Augustine_FL', label: 'St Augustine, FL' },
  { value: 'St Augustine_IL', label: 'St Augustine, IL' },
  { value: 'St Benedict_PA', label: 'St Benedict, PA' },
  { value: 'St Benedict_LA', label: 'St Benedict, LA' },
  { value: 'St Benedict_OR', label: 'St Benedict, OR' },
  { value: 'St Bernard_LA', label: 'St Bernard, LA' },
  { value: 'St Bernice_IN', label: 'St Bernice, IN' },
  { value: 'St Bonaventure_NY', label: 'St Bonaventure, NY' },
  { value: 'St Boniface_PA', label: 'St Boniface, PA' },
  { value: 'St Bonifacius_MN', label: 'St Bonifacius, MN' },
  { value: 'St Catharine_KY', label: 'St Catharine, KY' },
  { value: 'St Charles_VA', label: 'St Charles, VA' },
  { value: 'St Charles_KY', label: 'St Charles, KY' },
  { value: 'St Charles_MI', label: 'St Charles, MI' },
  { value: 'St Charles_IA', label: 'St Charles, IA' },
  { value: 'St Charles_MN', label: 'St Charles, MN' },
  { value: 'St Charles_SD', label: 'St Charles, SD' },
  { value: 'St Charles_IL', label: 'St Charles, IL' },
  { value: 'St Charles_MO', label: 'St Charles, MO' },
  { value: 'St Charles_AR', label: 'St Charles, AR' },
  { value: 'St Charles_ID', label: 'St Charles, ID' },
  { value: 'St Clair_PA', label: 'St Clair, PA' },
  { value: 'St Clair_MI', label: 'St Clair, MI' },
  { value: 'St Clair_MN', label: 'St Clair, MN' },
  { value: 'St Clair_MO', label: 'St Clair, MO' },
  { value: 'St Clair Shores_MI', label: 'St Clair Shores, MI' },
  { value: 'St Clairsville_OH', label: 'St Clairsville, OH' },
  { value: 'St Cloud_FL', label: 'St Cloud, FL' },
  { value: 'St Cloud_WI', label: 'St Cloud, WI' },
  { value: 'St Cloud_MN', label: 'St Cloud, MN' },
  { value: 'St Croix_IN', label: 'St Croix, IN' },
  { value: 'St Croix Falls_WI', label: 'St Croix Falls, WI' },
  { value: 'St David_ME', label: 'St David, ME' },
  { value: 'St David_IL', label: 'St David, IL' },
  { value: 'St David_AZ', label: 'St David, AZ' },
  { value: 'St Donatus_IA', label: 'St Donatus, IA' },
  { value: 'St Edward_NE', label: 'St Edward, NE' },
  { value: 'St Elizabeth_MO', label: 'St Elizabeth, MO' },
  { value: 'St Elmo_AL', label: 'St Elmo, AL' },
  { value: 'St Elmo_IL', label: 'St Elmo, IL' },
  { value: 'St Francis_ME', label: 'St Francis, ME' },
  { value: 'St Francis_KY', label: 'St Francis, KY' },
  { value: 'St Francis_WI', label: 'St Francis, WI' },
  { value: 'St Francis_MN', label: 'St Francis, MN' },
  { value: 'St Francis_SD', label: 'St Francis, SD' },
  { value: 'St Francis_KS', label: 'St Francis, KS' },
  { value: 'St Francis_AR', label: 'St Francis, AR' },
  { value: 'St Francisville_IL', label: 'St Francisville, IL' },
  { value: 'St Francisville_LA', label: 'St Francisville, LA' },
  { value: 'St Gabriel_LA', label: 'St Gabriel, LA' },
  { value: 'St George_SC', label: 'St George, SC' },
  { value: 'St George_GA', label: 'St George, GA' },
  { value: 'St George_KS', label: 'St George, KS' },
  { value: 'St George_UT', label: 'St George, UT' },
  { value: 'St George Island_AK', label: 'St George Island, AK' },
  { value: 'St Georges_DE', label: 'St Georges, DE' },
  { value: 'St Germain_WI', label: 'St Germain, WI' },
  { value: 'St Hedwig_TX', label: 'St Hedwig, TX' },
  { value: 'St Helen_MI', label: 'St Helen, MI' },
  { value: 'St Helena_NE', label: 'St Helena, NE' },
  { value: 'St Helena_CA', label: 'St Helena, CA' },
  { value: 'St Helena Island_SC', label: 'St Helena Island, SC' },
  { value: 'St Helens_OR', label: 'St Helens, OR' },
  { value: 'St Henry_OH', label: 'St Henry, OH' },
  { value: 'St Hilaire_MN', label: 'St Hilaire, MN' },
  { value: 'St Ignace_MI', label: 'St Ignace, MI' },
  { value: 'St Ignatius_MT', label: 'St Ignatius, MT' },
  { value: 'St Inigoes_MD', label: 'St Inigoes, MD' },
  { value: 'St Jacob_IL', label: 'St Jacob, IL' },
  { value: 'St James_NY', label: 'St James, NY' },
  { value: 'St James_MD', label: 'St James, MD' },
  { value: 'St James_MN', label: 'St James, MN' },
  { value: 'St James_MO', label: 'St James, MO' },
  { value: 'St James_LA', label: 'St James, LA' },
  { value: 'St James City_FL', label: 'St James City, FL' },
  { value: 'St Jo_TX', label: 'St Jo, TX' },
  { value: 'St Joe_IN', label: 'St Joe, IN' },
  { value: 'St Joe_AR', label: 'St Joe, AR' },
  { value: 'St John_IN', label: 'St John, IN' },
  { value: 'St John_ND', label: 'St John, ND' },
  { value: 'St John_WA', label: 'St John, WA' },
  { value: 'St Johns_FL', label: 'St Johns, FL' },
  { value: 'St Johns_OH', label: 'St Johns, OH' },
  { value: 'St Johns_MI', label: 'St Johns, MI' },
  { value: 'St Johns_AZ', label: 'St Johns, AZ' },
  { value: 'St Johnsbury_VT', label: 'St Johnsbury, VT' },
  { value: 'St Johnsville_NY', label: 'St Johnsville, NY' },
  { value: 'St Joseph_TN', label: 'St Joseph, TN' },
  { value: 'St Joseph_MI', label: 'St Joseph, MI' },
  { value: 'St Joseph_MN', label: 'St Joseph, MN' },
  { value: 'St Joseph_IL', label: 'St Joseph, IL' },
  { value: 'St Joseph_MO', label: 'St Joseph, MO' },
  { value: 'St Joseph_LA', label: 'St Joseph, LA' },
  { value: 'St Landry_LA', label: 'St Landry, LA' },
  { value: 'St Lawrence_SD', label: 'St Lawrence, SD' },
  { value: 'St Leo_FL', label: 'St Leo, FL' },
  { value: 'St Leonard_MD', label: 'St Leonard, MD' },
  { value: 'St Libory_IL', label: 'St Libory, IL' },
  { value: 'St Libory_NE', label: 'St Libory, NE' },
  { value: 'St Louis_MI', label: 'St Louis, MI' },
  { value: 'St Louis_MO', label: 'St Louis, MO' },
  { value: 'St Louis_OK', label: 'St Louis, OK' },
  { value: 'St Louisville_OH', label: 'St Louisville, OH' },
  { value: 'St Lucas_IA', label: 'St Lucas, IA' },
  { value: 'St Marie_MT', label: 'St Marie, MT' },
  { value: 'St Maries_ID', label: 'St Maries, ID' },
  { value: 'St Marks_FL', label: 'St Marks, FL' },
  { value: 'St Martin_MN', label: 'St Martin, MN' },
  { value: 'St Martinville_LA', label: 'St Martinville, LA' },
  { value: 'St Mary_KY', label: 'St Mary, KY' },
  { value: 'St Mary_MO', label: 'St Mary, MO' },
  { value: 'St Mary Of The Woods_IN', label: 'St Mary Of The Woods, IN' },
  { value: 'St Marys_PA', label: 'St Marys, PA' },
  { value: 'St Marys_WV', label: 'St Marys, WV' },
  { value: 'St Marys_GA', label: 'St Marys, GA' },
  { value: 'St Marys_OH', label: 'St Marys, OH' },
  { value: 'St Marys_IA', label: 'St Marys, IA' },
  { value: 'St Marys_KS', label: 'St Marys, KS' },
  { value: 'St Marys_AK', label: 'St Marys, AK' },
  { value: 'St Marys City_MD', label: 'St Marys City, MD' },
  { value: 'St Matthews_SC', label: 'St Matthews, SC' },
  { value: 'St Meinrad_IN', label: 'St Meinrad, IN' },
  { value: 'St Michael_PA', label: 'St Michael, PA' },
  { value: 'St Michael_MN', label: 'St Michael, MN' },
  { value: 'St Michael_ND', label: 'St Michael, ND' },
  { value: 'St Michael_AK', label: 'St Michael, AK' },
  { value: 'St Michaels_MD', label: 'St Michaels, MD' },
  { value: 'St Michaels_AZ', label: 'St Michaels, AZ' },
  { value: 'St Nazianz_WI', label: 'St Nazianz, WI' },
  { value: 'St Olaf_IA', label: 'St Olaf, IA' },
  { value: 'St Onge_SD', label: 'St Onge, SD' },
  { value: 'St Paris_OH', label: 'St Paris, OH' },
  { value: 'St Patrick_MO', label: 'St Patrick, MO' },
  { value: 'St Paul_VA', label: 'St Paul, VA' },
  { value: 'St Paul_IN', label: 'St Paul, IN' },
  { value: 'St Paul_IA', label: 'St Paul, IA' },
  { value: 'St Paul_MN', label: 'St Paul, MN' },
  { value: 'St Paul_KS', label: 'St Paul, KS' },
  { value: 'St Paul_NE', label: 'St Paul, NE' },
  { value: 'St Paul_AR', label: 'St Paul, AR' },
  { value: 'St Paul_OR', label: 'St Paul, OR' },
  { value: 'St Paul Island_AK', label: 'St Paul Island, AK' },
  { value: 'St Paul Park_MN', label: 'St Paul Park, MN' },
  { value: 'St Pauls_NC', label: 'St Pauls, NC' },
  { value: 'St Peter_MN', label: 'St Peter, MN' },
  { value: 'St Peter_IL', label: 'St Peter, IL' },
  { value: 'St Peters_MO', label: 'St Peters, MO' },
  { value: 'St Petersburg_PA', label: 'St Petersburg, PA' },
  { value: 'St Petersburg_FL', label: 'St Petersburg, FL' },
  { value: 'St Regis_MT', label: 'St Regis, MT' },
  { value: 'St Regis Falls_NY', label: 'St Regis Falls, NY' },
  { value: 'St Robert_MO', label: 'St Robert, MO' },
  { value: 'St Rose_LA', label: 'St Rose, LA' },
  { value: 'St Simons Island_GA', label: 'St Simons Island, GA' },
  { value: 'St Stephen_SC', label: 'St Stephen, SC' },
  { value: 'St Stephen_MN', label: 'St Stephen, MN' },
  { value: 'St Stephens_AL', label: 'St Stephens, AL' },
  { value: 'St Stephens Church_VA', label: 'St Stephens Church, VA' },
  { value: 'St Thomas_PA', label: 'St Thomas, PA' },
  { value: 'St Thomas_ND', label: 'St Thomas, ND' },
  { value: 'St Thomas_MO', label: 'St Thomas, MO' },
  { value: 'St Vincent_MN', label: 'St Vincent, MN' },
  { value: 'St Xavier_MT', label: 'St Xavier, MT' },
  { value: 'Sainte Genevieve_MO', label: 'Sainte Genevieve, MO' },
  { value: 'Sainte Marie_IL', label: 'Sainte Marie, IL' },
  { value: 'Saipan_MP', label: 'Saipan, MP' },
  { value: 'Salado_TX', label: 'Salado, TX' },
  { value: 'Salamanca_NY', label: 'Salamanca, NY' },
  { value: 'Salamonia_IN', label: 'Salamonia, IN' },
  { value: 'Salcha_AK', label: 'Salcha, AK' },
  { value: 'Sale City_GA', label: 'Sale City, GA' },
  { value: 'Sale Creek_TN', label: 'Sale Creek, TN' },
  { value: 'Salem_MA', label: 'Salem, MA' },
  { value: 'Salem_NH', label: 'Salem, NH' },
  { value: 'Salem_CT', label: 'Salem, CT' },
  { value: 'Salem_NJ', label: 'Salem, NJ' },
  { value: 'Salem_NY', label: 'Salem, NY' },
  { value: 'Salem_VA', label: 'Salem, VA' },
  { value: 'Salem_WV', label: 'Salem, WV' },
  { value: 'Salem_SC', label: 'Salem, SC' },
  { value: 'Salem_FL', label: 'Salem, FL' },
  { value: 'Salem_AL', label: 'Salem, AL' },
  { value: 'Salem_KY', label: 'Salem, KY' },
  { value: 'Salem_OH', label: 'Salem, OH' },
  { value: 'Salem_IN', label: 'Salem, IN' },
  { value: 'Salem_IA', label: 'Salem, IA' },
  { value: 'Salem_WI', label: 'Salem, WI' },
  { value: 'Salem_SD', label: 'Salem, SD' },
  { value: 'Salem_IL', label: 'Salem, IL' },
  { value: 'Salem_MO', label: 'Salem, MO' },
  { value: 'Salem_NE', label: 'Salem, NE' },
  { value: 'Salem_AR', label: 'Salem, AR' },
  { value: 'Salem_UT', label: 'Salem, UT' },
  { value: 'Salem_NM', label: 'Salem, NM' },
  { value: 'Salem_OR', label: 'Salem, OR' },
  { value: 'Salemburg_NC', label: 'Salemburg, NC' },
  { value: 'Salesville_OH', label: 'Salesville, OH' },
  { value: 'Salida_CO', label: 'Salida, CO' },
  { value: 'Salida_CA', label: 'Salida, CA' },
  { value: 'Salina_PA', label: 'Salina, PA' },
  { value: 'Salina_KS', label: 'Salina, KS' },
  { value: 'Salina_OK', label: 'Salina, OK' },
  { value: 'Salina_UT', label: 'Salina, UT' },
  { value: 'Salinas_PR', label: 'Salinas, PR' },
  { value: 'Salinas_CA', label: 'Salinas, CA' },
  { value: 'Saline_MI', label: 'Saline, MI' },
  { value: 'Saline_LA', label: 'Saline, LA' },
  { value: 'Salineno_TX', label: 'Salineno, TX' },
  { value: 'Salineville_OH', label: 'Salineville, OH' },
  { value: 'Salisbury_MA', label: 'Salisbury, MA' },
  { value: 'Salisbury_NH', label: 'Salisbury, NH' },
  { value: 'Salisbury_VT', label: 'Salisbury, VT' },
  { value: 'Salisbury_CT', label: 'Salisbury, CT' },
  { value: 'Salisbury_PA', label: 'Salisbury, PA' },
  { value: 'Salisbury_MD', label: 'Salisbury, MD' },
  { value: 'Salisbury_NC', label: 'Salisbury, NC' },
  { value: 'Salisbury_MO', label: 'Salisbury, MO' },
  { value: 'Salisbury Center_NY', label: 'Salisbury Center, NY' },
  { value: 'Salisbury Mills_NY', label: 'Salisbury Mills, NY' },
  { value: 'Salix_PA', label: 'Salix, PA' },
  { value: 'Salix_IA', label: 'Salix, IA' },
  { value: 'Salkum_WA', label: 'Salkum, WA' },
  { value: 'Salley_SC', label: 'Salley, SC' },
  { value: 'Sallis_MS', label: 'Sallis, MS' },
  { value: 'Sallisaw_OK', label: 'Sallisaw, OK' },
  { value: 'Salmon_ID', label: 'Salmon, ID' },
  { value: 'Salol_MN', label: 'Salol, MN' },
  { value: 'Salome_AZ', label: 'Salome, AZ' },
  { value: 'Salt Flat_TX', label: 'Salt Flat, TX' },
  { value: 'Salt Lake City_UT', label: 'Salt Lake City, UT' },
  { value: 'Salt Lick_KY', label: 'Salt Lick, KY' },
  { value: 'Salt Point_NY', label: 'Salt Point, NY' },
  { value: 'Salt Rock_WV', label: 'Salt Rock, WV' },
  { value: 'Salter Path_NC', label: 'Salter Path, NC' },
  { value: 'Salters_SC', label: 'Salters, SC' },
  { value: 'Saltese_MT', label: 'Saltese, MT' },
  { value: 'Saltillo_PA', label: 'Saltillo, PA' },
  { value: 'Saltillo_TN', label: 'Saltillo, TN' },
  { value: 'Saltillo_MS', label: 'Saltillo, MS' },
  { value: 'Saltillo_TX', label: 'Saltillo, TX' },
  { value: 'Salton City_CA', label: 'Salton City, CA' },
  { value: 'Saltsburg_PA', label: 'Saltsburg, PA' },
  { value: 'Saltville_VA', label: 'Saltville, VA' },
  { value: 'Saluda_VA', label: 'Saluda, VA' },
  { value: 'Saluda_NC', label: 'Saluda, NC' },
  { value: 'Saluda_SC', label: 'Saluda, SC' },
  { value: 'Salvisa_KY', label: 'Salvisa, KY' },
  { value: 'Salvo_NC', label: 'Salvo, NC' },
  { value: 'Salyer_CA', label: 'Salyer, CA' },
  { value: 'Salyersville_KY', label: 'Salyersville, KY' },
  { value: 'Samaria_MI', label: 'Samaria, MI' },
  { value: 'Samburg_TN', label: 'Samburg, TN' },
  { value: 'Sammamish_WA', label: 'Sammamish, WA' },
  { value: 'Samoa_CA', label: 'Samoa, CA' },
  { value: 'Samson_AL', label: 'Samson, AL' },
  { value: 'San Acacia_NM', label: 'San Acacia, NM' },
  { value: 'San Andreas_CA', label: 'San Andreas, CA' },
  { value: 'San Angelo_TX', label: 'San Angelo, TX' },
  { value: 'San Anselmo_CA', label: 'San Anselmo, CA' },
  { value: 'San Antonio_PR', label: 'San Antonio, PR' },
  { value: 'San Antonio_FL', label: 'San Antonio, FL' },
  { value: 'San Antonio_TX', label: 'San Antonio, TX' },
  { value: 'San Antonio_NM', label: 'San Antonio, NM' },
  { value: 'San Ardo_CA', label: 'San Ardo, CA' },
  { value: 'San Augustine_TX', label: 'San Augustine, TX' },
  { value: 'San Benito_TX', label: 'San Benito, TX' },
  { value: 'San Bernardino_CA', label: 'San Bernardino, CA' },
  { value: 'San Bruno_CA', label: 'San Bruno, CA' },
  { value: 'San Carlos_AZ', label: 'San Carlos, AZ' },
  { value: 'San Carlos_CA', label: 'San Carlos, CA' },
  { value: 'San Clemente_CA', label: 'San Clemente, CA' },
  { value: 'San Cristobal_NM', label: 'San Cristobal, NM' },
  { value: 'San Diego_TX', label: 'San Diego, TX' },
  { value: 'San Diego_CA', label: 'San Diego, CA' },
  { value: 'San Dimas_CA', label: 'San Dimas, CA' },
  { value: 'San Elizario_TX', label: 'San Elizario, TX' },
  { value: 'San Felipe_TX', label: 'San Felipe, TX' },
  { value: 'San Fernando_CA', label: 'San Fernando, CA' },
  { value: 'San Fidel_NM', label: 'San Fidel, NM' },
  { value: 'San Francisco_CA', label: 'San Francisco, CA' },
  { value: 'San Gabriel_CA', label: 'San Gabriel, CA' },
  { value: 'San German_PR', label: 'San German, PR' },
  { value: 'San Geronimo_CA', label: 'San Geronimo, CA' },
  { value: 'San Gregorio_CA', label: 'San Gregorio, CA' },
  { value: 'San Isidro_TX', label: 'San Isidro, TX' },
  { value: 'San Jacinto_CA', label: 'San Jacinto, CA' },
  { value: 'San Joaquin_CA', label: 'San Joaquin, CA' },
  { value: 'San Jon_NM', label: 'San Jon, NM' },
  { value: 'San Jose_IL', label: 'San Jose, IL' },
  { value: 'San Jose_NM', label: 'San Jose, NM' },
  { value: 'San Jose_CA', label: 'San Jose, CA' },
  { value: 'San Juan_PR', label: 'San Juan, PR' },
  { value: 'San Juan_TX', label: 'San Juan, TX' },
  { value: 'San Juan Bautista_CA', label: 'San Juan Bautista, CA' },
  { value: 'San Juan Capistrano_CA', label: 'San Juan Capistrano, CA' },
  { value: 'San Leandro_CA', label: 'San Leandro, CA' },
  { value: 'San Lorenzo_PR', label: 'San Lorenzo, PR' },
  { value: 'San Lorenzo_CA', label: 'San Lorenzo, CA' },
  { value: 'San Lucas_CA', label: 'San Lucas, CA' },
  { value: 'San Luis_CO', label: 'San Luis, CO' },
  { value: 'San Luis_AZ', label: 'San Luis, AZ' },
  { value: 'San Luis Obispo_CA', label: 'San Luis Obispo, CA' },
  { value: 'San Manuel_AZ', label: 'San Manuel, AZ' },
  { value: 'San Marcos_TX', label: 'San Marcos, TX' },
  { value: 'San Marcos_CA', label: 'San Marcos, CA' },
  { value: 'San Marino_CA', label: 'San Marino, CA' },
  { value: 'San Martin_CA', label: 'San Martin, CA' },
  { value: 'San Mateo_FL', label: 'San Mateo, FL' },
  { value: 'San Mateo_CA', label: 'San Mateo, CA' },
  { value: 'San Miguel_CA', label: 'San Miguel, CA' },
  { value: 'San Pablo_CA', label: 'San Pablo, CA' },
  { value: 'San Patricio_NM', label: 'San Patricio, NM' },
  { value: 'San Pedro_CA', label: 'San Pedro, CA' },
  { value: 'San Perlita_TX', label: 'San Perlita, TX' },
  { value: 'San Pierre_IN', label: 'San Pierre, IN' },
  { value: 'San Quentin_CA', label: 'San Quentin, CA' },
  { value: 'San Rafael_NM', label: 'San Rafael, NM' },
  { value: 'San Rafael_CA', label: 'San Rafael, CA' },
  { value: 'San Ramon_CA', label: 'San Ramon, CA' },
  { value: 'San Saba_TX', label: 'San Saba, TX' },
  { value: 'San Sebastian_PR', label: 'San Sebastian, PR' },
  { value: 'San Simeon_CA', label: 'San Simeon, CA' },
  { value: 'San Simon_AZ', label: 'San Simon, AZ' },
  { value: 'San Tan Valley_AZ', label: 'San Tan Valley, AZ' },
  { value: 'San Ygnacio_TX', label: 'San Ygnacio, TX' },
  { value: 'San Ysidro_NM', label: 'San Ysidro, NM' },
  { value: 'San Ysidro_CA', label: 'San Ysidro, CA' },
  { value: 'Sanborn_NY', label: 'Sanborn, NY' },
  { value: 'Sanborn_IA', label: 'Sanborn, IA' },
  { value: 'Sanborn_MN', label: 'Sanborn, MN' },
  { value: 'Sanborn_ND', label: 'Sanborn, ND' },
  { value: 'Sanbornton_NH', label: 'Sanbornton, NH' },
  { value: 'Sanbornville_NH', label: 'Sanbornville, NH' },
  { value: 'Sand Coulee_MT', label: 'Sand Coulee, MT' },
  { value: 'Sand Creek_MI', label: 'Sand Creek, MI' },
  { value: 'Sand Fork_WV', label: 'Sand Fork, WV' },
  { value: 'Sand Lake_NY', label: 'Sand Lake, NY' },
  { value: 'Sand Lake_MI', label: 'Sand Lake, MI' },
  { value: 'Sand Point_AK', label: 'Sand Point, AK' },
  { value: 'Sand Springs_MT', label: 'Sand Springs, MT' },
  { value: 'Sand Springs_OK', label: 'Sand Springs, OK' },
  { value: 'Sandborn_IN', label: 'Sandborn, IN' },
  { value: 'Sanders_KY', label: 'Sanders, KY' },
  { value: 'Sanders_MT', label: 'Sanders, MT' },
  { value: 'Sanders_AZ', label: 'Sanders, AZ' },
  { value: 'Sanderson_FL', label: 'Sanderson, FL' },
  { value: 'Sanderson_TX', label: 'Sanderson, TX' },
  { value: 'Sandersville_GA', label: 'Sandersville, GA' },
  { value: 'Sandersville_MS', label: 'Sandersville, MS' },
  { value: 'Sandgap_KY', label: 'Sandgap, KY' },
  { value: 'Sandia_TX', label: 'Sandia, TX' },
  { value: 'Sandia Park_NM', label: 'Sandia Park, NM' },
  { value: 'Sandisfield_MA', label: 'Sandisfield, MA' },
  { value: 'Sandoval_IL', label: 'Sandoval, IL' },
  { value: 'Sandown_NH', label: 'Sandown, NH' },
  { value: 'Sandpoint_ID', label: 'Sandpoint, ID' },
  { value: 'Sandston_VA', label: 'Sandston, VA' },
  { value: 'Sandstone_WV', label: 'Sandstone, WV' },
  { value: 'Sandstone_MN', label: 'Sandstone, MN' },
  { value: 'Sandusky_OH', label: 'Sandusky, OH' },
  { value: 'Sandusky_MI', label: 'Sandusky, MI' },
  { value: 'Sandwich_MA', label: 'Sandwich, MA' },
  { value: 'Sandwich_IL', label: 'Sandwich, IL' },
  { value: 'Sandy_UT', label: 'Sandy, UT' },
  { value: 'Sandy_OR', label: 'Sandy, OR' },
  { value: 'Sandy Creek_NY', label: 'Sandy Creek, NY' },
  { value: 'Sandy Hook_CT', label: 'Sandy Hook, CT' },
  { value: 'Sandy Hook_VA', label: 'Sandy Hook, VA' },
  { value: 'Sandy Hook_MS', label: 'Sandy Hook, MS' },
  { value: 'Sandy Hook_KY', label: 'Sandy Hook, KY' },
  { value: 'Sandy Lake_PA', label: 'Sandy Lake, PA' },
  { value: 'Sandy Level_VA', label: 'Sandy Level, VA' },
  { value: 'Sandy Ridge_PA', label: 'Sandy Ridge, PA' },
  { value: 'Sandy Ridge_NC', label: 'Sandy Ridge, NC' },
  { value: 'Sandy Spring_MD', label: 'Sandy Spring, MD' },
  { value: 'Sandyville_WV', label: 'Sandyville, WV' },
  { value: 'Sandyville_OH', label: 'Sandyville, OH' },
  { value: 'Sanford_ME', label: 'Sanford, ME' },
  { value: 'Sanford_VA', label: 'Sanford, VA' },
  { value: 'Sanford_NC', label: 'Sanford, NC' },
  { value: 'Sanford_FL', label: 'Sanford, FL' },
  { value: 'Sanford_MI', label: 'Sanford, MI' },
  { value: 'Sanford_TX', label: 'Sanford, TX' },
  { value: 'Sanford_CO', label: 'Sanford, CO' },
  { value: 'Sanger_TX', label: 'Sanger, TX' },
  { value: 'Sanger_CA', label: 'Sanger, CA' },
  { value: 'Sangerfield_NY', label: 'Sangerfield, NY' },
  { value: 'Sangerville_ME', label: 'Sangerville, ME' },
  { value: 'Sanibel_FL', label: 'Sanibel, FL' },
  { value: 'Sanostee_NM', label: 'Sanostee, NM' },
  { value: 'Santa_ID', label: 'Santa, ID' },
  { value: 'Santa Ana_CA', label: 'Santa Ana, CA' },
  { value: 'Santa Anna_TX', label: 'Santa Anna, TX' },
  { value: 'Santa Barbara_CA', label: 'Santa Barbara, CA' },
  { value: 'Santa Clara_UT', label: 'Santa Clara, UT' },
  { value: 'Santa Clara_NM', label: 'Santa Clara, NM' },
  { value: 'Santa Clara_CA', label: 'Santa Clara, CA' },
  { value: 'Santa Clarita_CA', label: 'Santa Clarita, CA' },
  { value: 'Santa Claus_IN', label: 'Santa Claus, IN' },
  { value: 'Santa Cruz_NM', label: 'Santa Cruz, NM' },
  { value: 'Santa Cruz_CA', label: 'Santa Cruz, CA' },
  { value: 'Santa Elena_TX', label: 'Santa Elena, TX' },
  { value: 'Santa Fe_TN', label: 'Santa Fe, TN' },
  { value: 'Santa Fe_MO', label: 'Santa Fe, MO' },
  { value: 'Santa Fe_TX', label: 'Santa Fe, TX' },
  { value: 'Santa Fe_NM', label: 'Santa Fe, NM' },
  { value: 'Santa Fe Springs_CA', label: 'Santa Fe Springs, CA' },
  { value: 'Santa Isabel_PR', label: 'Santa Isabel, PR' },
  { value: 'Santa Margarita_CA', label: 'Santa Margarita, CA' },
  { value: 'Santa Maria_TX', label: 'Santa Maria, TX' },
  { value: 'Santa Maria_CA', label: 'Santa Maria, CA' },
  { value: 'Santa Monica_CA', label: 'Santa Monica, CA' },
  { value: 'Santa Paula_CA', label: 'Santa Paula, CA' },
  { value: 'Santa Rita_GU', label: 'Santa Rita, GU' },
  { value: 'Santa Rosa_TX', label: 'Santa Rosa, TX' },
  { value: 'Santa Rosa_NM', label: 'Santa Rosa, NM' },
  { value: 'Santa Rosa_CA', label: 'Santa Rosa, CA' },
  { value: 'Santa Rosa Beach_FL', label: 'Santa Rosa Beach, FL' },
  { value: 'Santa Teresa_NM', label: 'Santa Teresa, NM' },
  { value: 'Santa Ynez_CA', label: 'Santa Ynez, CA' },
  { value: 'Santa Ysabel_CA', label: 'Santa Ysabel, CA' },
  { value: 'Santaquin_UT', label: 'Santaquin, UT' },
  { value: 'Santee_SC', label: 'Santee, SC' },
  { value: 'Santee_CA', label: 'Santee, CA' },
  { value: 'Santo_TX', label: 'Santo, TX' },
  { value: 'Santo Domingo Pueblo_NM', label: 'Santo Domingo Pueblo, NM' },
  { value: 'Sapello_NM', label: 'Sapello, NM' },
  { value: 'Sapelo Island_GA', label: 'Sapelo Island, GA' },
  { value: 'Sapphire_NC', label: 'Sapphire, NC' },
  { value: 'Sapulpa_OK', label: 'Sapulpa, OK' },
  { value: 'Saragosa_TX', label: 'Saragosa, TX' },
  { value: 'Sarah_MS', label: 'Sarah, MS' },
  { value: 'Sarah Ann_WV', label: 'Sarah Ann, WV' },
  { value: 'Sarahsville_OH', label: 'Sarahsville, OH' },
  { value: 'Saraland_AL', label: 'Saraland, AL' },
  { value: 'Saranac_NY', label: 'Saranac, NY' },
  { value: 'Saranac_MI', label: 'Saranac, MI' },
  { value: 'Saranac Lake_NY', label: 'Saranac Lake, NY' },
  { value: 'Sarasota_FL', label: 'Sarasota, FL' },
  { value: 'Saratoga_NC', label: 'Saratoga, NC' },
  { value: 'Saratoga_IN', label: 'Saratoga, IN' },
  { value: 'Saratoga_AR', label: 'Saratoga, AR' },
  { value: 'Saratoga_TX', label: 'Saratoga, TX' },
  { value: 'Saratoga_WY', label: 'Saratoga, WY' },
  { value: 'Saratoga_CA', label: 'Saratoga, CA' },
  { value: 'Saratoga Springs_NY', label: 'Saratoga Springs, NY' },
  { value: 'Saratoga Springs_UT', label: 'Saratoga Springs, UT' },
  { value: 'Sarcoxie_MO', label: 'Sarcoxie, MO' },
  { value: 'Sardinia_NY', label: 'Sardinia, NY' },
  { value: 'Sardinia_OH', label: 'Sardinia, OH' },
  { value: 'Sardis_GA', label: 'Sardis, GA' },
  { value: 'Sardis_AL', label: 'Sardis, AL' },
  { value: 'Sardis_TN', label: 'Sardis, TN' },
  { value: 'Sardis_MS', label: 'Sardis, MS' },
  { value: 'Sardis_OH', label: 'Sardis, OH' },
  { value: 'Sarepta_LA', label: 'Sarepta, LA' },
  { value: 'Sargeant_MN', label: 'Sargeant, MN' },
  { value: 'Sargent_GA', label: 'Sargent, GA' },
  { value: 'Sargent_NE', label: 'Sargent, NE' },
  { value: 'Sargents_CO', label: 'Sargents, CO' },
  { value: 'Sargentville_ME', label: 'Sargentville, ME' },
  { value: 'Sarita_TX', label: 'Sarita, TX' },
  { value: 'Sarles_ND', label: 'Sarles, ND' },
  { value: 'Sarona_WI', label: 'Sarona, WI' },
  { value: 'Saronville_NE', label: 'Saronville, NE' },
  { value: 'Sartell_MN', label: 'Sartell, MN' },
  { value: 'Sarver_PA', label: 'Sarver, PA' },
  { value: 'Sasabe_AZ', label: 'Sasabe, AZ' },
  { value: 'Sasakwa_OK', label: 'Sasakwa, OK' },
  { value: 'Sassafras_KY', label: 'Sassafras, KY' },
  { value: 'Sassamansville_PA', label: 'Sassamansville, PA' },
  { value: 'Sasser_GA', label: 'Sasser, GA' },
  { value: 'Satanta_KS', label: 'Satanta, KS' },
  { value: 'Satartia_MS', label: 'Satartia, MS' },
  { value: 'Satellite Beach_FL', label: 'Satellite Beach, FL' },
  { value: 'Satin_TX', label: 'Satin, TX' },
  { value: 'Satsop_WA', label: 'Satsop, WA' },
  { value: 'Satsuma_FL', label: 'Satsuma, FL' },
  { value: 'Satsuma_AL', label: 'Satsuma, AL' },
  { value: 'Saucier_MS', label: 'Saucier, MS' },
  { value: 'Saugatuck_MI', label: 'Saugatuck, MI' },
  { value: 'Saugerties_NY', label: 'Saugerties, NY' },
  { value: 'Saugus_MA', label: 'Saugus, MA' },
  { value: 'Sauk Centre_MN', label: 'Sauk Centre, MN' },
  { value: 'Sauk City_WI', label: 'Sauk City, WI' },
  { value: 'Sauk Rapids_MN', label: 'Sauk Rapids, MN' },
  { value: 'Saukville_WI', label: 'Saukville, WI' },
  { value: 'Saul_KY', label: 'Saul, KY' },
  { value: 'Saulsbury_TN', label: 'Saulsbury, TN' },
  { value: 'Saulsville_WV', label: 'Saulsville, WV' },
  { value: 'Sault Sainte Marie_MI', label: 'Sault Sainte Marie, MI' },
  { value: 'Saunderstown_RI', label: 'Saunderstown, RI' },
  { value: 'Saunemin_IL', label: 'Saunemin, IL' },
  { value: 'Sauquoit_NY', label: 'Sauquoit, NY' },
  { value: 'Sausalito_CA', label: 'Sausalito, CA' },
  { value: 'Sautee Nacoochee_GA', label: 'Sautee Nacoochee, GA' },
  { value: 'Savage_MD', label: 'Savage, MD' },
  { value: 'Savage_MN', label: 'Savage, MN' },
  { value: 'Savage_MT', label: 'Savage, MT' },
  { value: 'Savanna_IL', label: 'Savanna, IL' },
  { value: 'Savanna_OK', label: 'Savanna, OK' },
  { value: 'Savannah_NY', label: 'Savannah, NY' },
  { value: 'Savannah_GA', label: 'Savannah, GA' },
  { value: 'Savannah_TN', label: 'Savannah, TN' },
  { value: 'Savannah_OH', label: 'Savannah, OH' },
  { value: 'Savannah_MO', label: 'Savannah, MO' },
  { value: 'Saverton_MO', label: 'Saverton, MO' },
  { value: 'Savery_WY', label: 'Savery, WY' },
  { value: 'Savona_NY', label: 'Savona, NY' },
  { value: 'Savonburg_KS', label: 'Savonburg, KS' },
  { value: 'Savoonga_AK', label: 'Savoonga, AK' },
  { value: 'Savoy_MA', label: 'Savoy, MA' },
  { value: 'Savoy_IL', label: 'Savoy, IL' },
  { value: 'Savoy_TX', label: 'Savoy, TX' },
  { value: 'Sawyer_MI', label: 'Sawyer, MI' },
  { value: 'Sawyer_MN', label: 'Sawyer, MN' },
  { value: 'Sawyer_ND', label: 'Sawyer, ND' },
  { value: 'Sawyer_KS', label: 'Sawyer, KS' },
  { value: 'Sawyer_OK', label: 'Sawyer, OK' },
  { value: 'Sawyerville_AL', label: 'Sawyerville, AL' },
  { value: 'Sawyerville_IL', label: 'Sawyerville, IL' },
  { value: 'Saxapahaw_NC', label: 'Saxapahaw, NC' },
  { value: 'Saxe_VA', label: 'Saxe, VA' },
  { value: 'Saxis_VA', label: 'Saxis, VA' },
  { value: 'Saxon_WV', label: 'Saxon, WV' },
  { value: 'Saxon_WI', label: 'Saxon, WI' },
  { value: 'Saxonburg_PA', label: 'Saxonburg, PA' },
  { value: 'Saxton_PA', label: 'Saxton, PA' },
  { value: 'Saxtons River_VT', label: 'Saxtons River, VT' },
  { value: 'Saybrook_IL', label: 'Saybrook, IL' },
  { value: 'Saylorsburg_PA', label: 'Saylorsburg, PA' },
  { value: 'Sayner_WI', label: 'Sayner, WI' },
  { value: 'Sayre_PA', label: 'Sayre, PA' },
  { value: 'Sayre_OK', label: 'Sayre, OK' },
  { value: 'Sayreville_NJ', label: 'Sayreville, NJ' },
  { value: 'Sayville_NY', label: 'Sayville, NY' },
  { value: 'Scales Mound_IL', label: 'Scales Mound, IL' },
  { value: 'Scalf_KY', label: 'Scalf, KY' },
  { value: 'Scaly Mountain_NC', label: 'Scaly Mountain, NC' },
  { value: 'Scammon_KS', label: 'Scammon, KS' },
  { value: 'Scammon Bay_AK', label: 'Scammon Bay, AK' },
  { value: 'Scandia_MN', label: 'Scandia, MN' },
  { value: 'Scandia_KS', label: 'Scandia, KS' },
  { value: 'Scandinavia_WI', label: 'Scandinavia, WI' },
  { value: 'Scappoose_OR', label: 'Scappoose, OR' },
  { value: 'Scarborough_ME', label: 'Scarborough, ME' },
  { value: 'Scarbro_WV', label: 'Scarbro, WV' },
  { value: 'Scarsdale_NY', label: 'Scarsdale, NY' },
  { value: 'Scarville_IA', label: 'Scarville, IA' },
  { value: 'Scenery Hill_PA', label: 'Scenery Hill, PA' },
  { value: 'Scenic_SD', label: 'Scenic, SD' },
  { value: 'Schaefferstown_PA', label: 'Schaefferstown, PA' },
  { value: 'Schaghticoke_NY', label: 'Schaghticoke, NY' },
  { value: 'Schaller_IA', label: 'Schaller, IA' },
  { value: 'Schaumburg_IL', label: 'Schaumburg, IL' },
  { value: 'Schell City_MO', label: 'Schell City, MO' },
  { value: 'Scheller_IL', label: 'Scheller, IL' },
  { value: 'Schellsburg_PA', label: 'Schellsburg, PA' },
  { value: 'Schenectady_NY', label: 'Schenectady, NY' },
  { value: 'Schenevus_NY', label: 'Schenevus, NY' },
  { value: 'Schenley_PA', label: 'Schenley, PA' },
  { value: 'Schererville_IN', label: 'Schererville, IN' },
  { value: 'Schertz_TX', label: 'Schertz, TX' },
  { value: 'Schiller Park_IL', label: 'Schiller Park, IL' },
  { value: 'Schlater_MS', label: 'Schlater, MS' },
  { value: 'Schleswig_IA', label: 'Schleswig, IA' },
  { value: 'Schnecksville_PA', label: 'Schnecksville, PA' },
  { value: 'Schneider_IN', label: 'Schneider, IN' },
  { value: 'Schnellville_IN', label: 'Schnellville, IN' },
  { value: 'Schodack Landing_NY', label: 'Schodack Landing, NY' },
  { value: 'Schoenchen_KS', label: 'Schoenchen, KS' },
  { value: 'Schofield_WI', label: 'Schofield, WI' },
  { value: 'Schofield Barracks_HI', label: 'Schofield Barracks, HI' },
  { value: 'Schoharie_NY', label: 'Schoharie, NY' },
  { value: 'Schoolcraft_MI', label: 'Schoolcraft, MI' },
  { value: 'Schooleys Mountain_NJ', label: 'Schooleys Mountain, NJ' },
  { value: 'Schriever_LA', label: 'Schriever, LA' },
  { value: 'Schroeder_MN', label: 'Schroeder, MN' },
  { value: 'Schroon Lake_NY', label: 'Schroon Lake, NY' },
  { value: 'Schulenburg_TX', label: 'Schulenburg, TX' },
  { value: 'Schulter_OK', label: 'Schulter, OK' },
  { value: 'Schurz_NV', label: 'Schurz, NV' },
  { value: 'Schuyler_VA', label: 'Schuyler, VA' },
  { value: 'Schuyler_NE', label: 'Schuyler, NE' },
  { value: 'Schuyler Falls_NY', label: 'Schuyler Falls, NY' },
  { value: 'Schuyler Lake_NY', label: 'Schuyler Lake, NY' },
  { value: 'Schuylerville_NY', label: 'Schuylerville, NY' },
  { value: 'Schuylkill Haven_PA', label: 'Schuylkill Haven, PA' },
  { value: 'Schwenksville_PA', label: 'Schwenksville, PA' },
  { value: 'Schwertner_TX', label: 'Schwertner, TX' },
  { value: 'Science Hill_KY', label: 'Science Hill, KY' },
  { value: 'Scio_NY', label: 'Scio, NY' },
  { value: 'Scio_OH', label: 'Scio, OH' },
  { value: 'Scio_OR', label: 'Scio, OR' },
  { value: 'Sciota_PA', label: 'Sciota, PA' },
  { value: 'Sciota_IL', label: 'Sciota, IL' },
  { value: 'Scioto Furnace_OH', label: 'Scioto Furnace, OH' },
  { value: 'Scipio_IN', label: 'Scipio, IN' },
  { value: 'Scipio_UT', label: 'Scipio, UT' },
  { value: 'Scipio Center_NY', label: 'Scipio Center, NY' },
  { value: 'Scituate_MA', label: 'Scituate, MA' },
  { value: 'Scobey_MS', label: 'Scobey, MS' },
  { value: 'Scobey_MT', label: 'Scobey, MT' },
  { value: 'Scooba_MS', label: 'Scooba, MS' },
  { value: 'Scotch Plains_NJ', label: 'Scotch Plains, NJ' },
  { value: 'Scotia_SC', label: 'Scotia, SC' },
  { value: 'Scotia_NE', label: 'Scotia, NE' },
  { value: 'Scotia_CA', label: 'Scotia, CA' },
  { value: 'Scotland_CT', label: 'Scotland, CT' },
  { value: 'Scotland_PA', label: 'Scotland, PA' },
  { value: 'Scotland_MD', label: 'Scotland, MD' },
  { value: 'Scotland_GA', label: 'Scotland, GA' },
  { value: 'Scotland_IN', label: 'Scotland, IN' },
  { value: 'Scotland_SD', label: 'Scotland, SD' },
  { value: 'Scotland_AR', label: 'Scotland, AR' },
  { value: 'Scotland_TX', label: 'Scotland, TX' },
  { value: 'Scotland Neck_NC', label: 'Scotland Neck, NC' },
  { value: 'Scotrun_PA', label: 'Scotrun, PA' },
  { value: 'Scott_MS', label: 'Scott, MS' },
  { value: 'Scott_OH', label: 'Scott, OH' },
  { value: 'Scott_LA', label: 'Scott, LA' },
  { value: 'Scott_AR', label: 'Scott, AR' },
  { value: 'Scott Air Force Base_IL', label: 'Scott Air Force Base, IL' },
  { value: 'Scott Bar_CA', label: 'Scott Bar, CA' },
  { value: 'Scott City_MO', label: 'Scott City, MO' },
  { value: 'Scott City_KS', label: 'Scott City, KS' },
  { value: 'Scott Depot_WV', label: 'Scott Depot, WV' },
  { value: 'Scottdale_PA', label: 'Scottdale, PA' },
  { value: 'Scottdale_GA', label: 'Scottdale, GA' },
  { value: 'Scottown_OH', label: 'Scottown, OH' },
  { value: 'Scotts_MI', label: 'Scotts, MI' },
  { value: 'Scotts Hill_TN', label: 'Scotts Hill, TN' },
  { value: 'Scotts Mills_OR', label: 'Scotts Mills, OR' },
  { value: 'Scotts Valley_CA', label: 'Scotts Valley, CA' },
  { value: 'Scottsbluff_NE', label: 'Scottsbluff, NE' },
  { value: 'Scottsboro_AL', label: 'Scottsboro, AL' },
  { value: 'Scottsburg_NY', label: 'Scottsburg, NY' },
  { value: 'Scottsburg_VA', label: 'Scottsburg, VA' },
  { value: 'Scottsburg_IN', label: 'Scottsburg, IN' },
  { value: 'Scottsburg_OR', label: 'Scottsburg, OR' },
  { value: 'Scottsdale_AZ', label: 'Scottsdale, AZ' },
  { value: 'Scottsmoor_FL', label: 'Scottsmoor, FL' },
  { value: 'Scottsville_NY', label: 'Scottsville, NY' },
  { value: 'Scottsville_VA', label: 'Scottsville, VA' },
  { value: 'Scottsville_KY', label: 'Scottsville, KY' },
  { value: 'Scottville_NC', label: 'Scottville, NC' },
  { value: 'Scottville_MI', label: 'Scottville, MI' },
  { value: 'Scranton_PA', label: 'Scranton, PA' },
  { value: 'Scranton_NC', label: 'Scranton, NC' },
  { value: 'Scranton_SC', label: 'Scranton, SC' },
  { value: 'Scranton_IA', label: 'Scranton, IA' },
  { value: 'Scranton_ND', label: 'Scranton, ND' },
  { value: 'Scranton_KS', label: 'Scranton, KS' },
  { value: 'Scranton_AR', label: 'Scranton, AR' },
  { value: 'Screven_GA', label: 'Screven, GA' },
  { value: 'Scribner_NE', label: 'Scribner, NE' },
  { value: 'Scroggins_TX', label: 'Scroggins, TX' },
  { value: 'Scuddy_KY', label: 'Scuddy, KY' },
  { value: 'Scurry_TX', label: 'Scurry, TX' },
  { value: 'Sea Cliff_NY', label: 'Sea Cliff, NY' },
  { value: 'Sea Girt_NJ', label: 'Sea Girt, NJ' },
  { value: 'Sea Island_GA', label: 'Sea Island, GA' },
  { value: 'Sea Isle City_NJ', label: 'Sea Isle City, NJ' },
  { value: 'Seabeck_WA', label: 'Seabeck, WA' },
  { value: 'Seaboard_NC', label: 'Seaboard, NC' },
  { value: 'Seabrook_NH', label: 'Seabrook, NH' },
  { value: 'Seabrook_SC', label: 'Seabrook, SC' },
  { value: 'Seabrook_TX', label: 'Seabrook, TX' },
  { value: 'Seadrift_TX', label: 'Seadrift, TX' },
  { value: 'Seaford_NY', label: 'Seaford, NY' },
  { value: 'Seaford_DE', label: 'Seaford, DE' },
  { value: 'Seaford_VA', label: 'Seaford, VA' },
  { value: 'Seaforth_MN', label: 'Seaforth, MN' },
  { value: 'Seagoville_TX', label: 'Seagoville, TX' },
  { value: 'Seagraves_TX', label: 'Seagraves, TX' },
  { value: 'Seagrove_NC', label: 'Seagrove, NC' },
  { value: 'Seal Beach_CA', label: 'Seal Beach, CA' },
  { value: 'Seal Cove_ME', label: 'Seal Cove, ME' },
  { value: 'Seal Harbor_ME', label: 'Seal Harbor, ME' },
  { value: 'Seal Rock_OR', label: 'Seal Rock, OR' },
  { value: 'Seale_AL', label: 'Seale, AL' },
  { value: 'Sealevel_NC', label: 'Sealevel, NC' },
  { value: 'Sealy_TX', label: 'Sealy, TX' },
  { value: 'Seaman_OH', label: 'Seaman, OH' },
  { value: 'Seanor_PA', label: 'Seanor, PA' },
  { value: 'Searchlight_NV', label: 'Searchlight, NV' },
  { value: 'Searcy_AR', label: 'Searcy, AR' },
  { value: 'Sears_MI', label: 'Sears, MI' },
  { value: 'Searsboro_IA', label: 'Searsboro, IA' },
  { value: 'Searsmont_ME', label: 'Searsmont, ME' },
  { value: 'Searsport_ME', label: 'Searsport, ME' },
  { value: 'Seaside_CA', label: 'Seaside, CA' },
  { value: 'Seaside_OR', label: 'Seaside, OR' },
  { value: 'Seaside Heights_NJ', label: 'Seaside Heights, NJ' },
  { value: 'Seaside Park_NJ', label: 'Seaside Park, NJ' },
  { value: 'Seaton_IL', label: 'Seaton, IL' },
  { value: 'Seatonville_IL', label: 'Seatonville, IL' },
  { value: 'Seattle_WA', label: 'Seattle, WA' },
  { value: 'Seaview_WA', label: 'Seaview, WA' },
  { value: 'Sebago_ME', label: 'Sebago, ME' },
  { value: 'Sebastian_FL', label: 'Sebastian, FL' },
  { value: 'Sebastian_TX', label: 'Sebastian, TX' },
  { value: 'Sebastopol_MS', label: 'Sebastopol, MS' },
  { value: 'Sebastopol_CA', label: 'Sebastopol, CA' },
  { value: 'Sebec_ME', label: 'Sebec, ME' },
  { value: 'Sebeka_MN', label: 'Sebeka, MN' },
  { value: 'Sebewaing_MI', label: 'Sebewaing, MI' },
  { value: 'Sebree_KY', label: 'Sebree, KY' },
  { value: 'Sebring_FL', label: 'Sebring, FL' },
  { value: 'Sebring_OH', label: 'Sebring, OH' },
  { value: 'Secaucus_NJ', label: 'Secaucus, NJ' },
  { value: 'Seco_KY', label: 'Seco, KY' },
  { value: 'Second Mesa_AZ', label: 'Second Mesa, AZ' },
  { value: 'Secondcreek_WV', label: 'Secondcreek, WV' },
  { value: 'Secor_IL', label: 'Secor, IL' },
  { value: 'Secretary_MD', label: 'Secretary, MD' },
  { value: 'Section_AL', label: 'Section, AL' },
  { value: 'Sedalia_NC', label: 'Sedalia, NC' },
  { value: 'Sedalia_KY', label: 'Sedalia, KY' },
  { value: 'Sedalia_OH', label: 'Sedalia, OH' },
  { value: 'Sedalia_MO', label: 'Sedalia, MO' },
  { value: 'Sedalia_CO', label: 'Sedalia, CO' },
  { value: 'Sedan_KS', label: 'Sedan, KS' },
  { value: 'Sedan_NM', label: 'Sedan, NM' },
  { value: 'Sedgewickville_MO', label: 'Sedgewickville, MO' },
  { value: 'Sedgwick_ME', label: 'Sedgwick, ME' },
  { value: 'Sedgwick_KS', label: 'Sedgwick, KS' },
  { value: 'Sedgwick_CO', label: 'Sedgwick, CO' },
  { value: 'Sedley_VA', label: 'Sedley, VA' },
  { value: 'Sedona_AZ', label: 'Sedona, AZ' },
  { value: 'Sedro Woolley_WA', label: 'Sedro Woolley, WA' },
  { value: 'Seekonk_MA', label: 'Seekonk, MA' },
  { value: 'Seeley_CA', label: 'Seeley, CA' },
  { value: 'Seeley Lake_MT', label: 'Seeley Lake, MT' },
  { value: 'Seffner_FL', label: 'Seffner, FL' },
  { value: 'Seguin_TX', label: 'Seguin, TX' },
  { value: 'Seiad Valley_CA', label: 'Seiad Valley, CA' },
  { value: 'Seibert_CO', label: 'Seibert, CO' },
  { value: 'Seiling_OK', label: 'Seiling, OK' },
  { value: 'Sekiu_WA', label: 'Sekiu, WA' },
  { value: 'Selah_WA', label: 'Selah, WA' },
  { value: 'Selawik_AK', label: 'Selawik, AK' },
  { value: 'Selby_SD', label: 'Selby, SD' },
  { value: 'Selbyville_DE', label: 'Selbyville, DE' },
  { value: 'Selbyville_WV', label: 'Selbyville, WV' },
  { value: 'Selden_NY', label: 'Selden, NY' },
  { value: 'Selden_KS', label: 'Selden, KS' },
  { value: 'Seldovia_AK', label: 'Seldovia, AK' },
  { value: 'Selfridge_ND', label: 'Selfridge, ND' },
  { value: 'Seligman_MO', label: 'Seligman, MO' },
  { value: 'Seligman_AZ', label: 'Seligman, AZ' },
  { value: 'Selinsgrove_PA', label: 'Selinsgrove, PA' },
  { value: 'Selkirk_NY', label: 'Selkirk, NY' },
  { value: 'Sellers_SC', label: 'Sellers, SC' },
  { value: 'Sellersburg_IN', label: 'Sellersburg, IN' },
  { value: 'Sellersville_PA', label: 'Sellersville, PA' },
  { value: 'Sells_AZ', label: 'Sells, AZ' },
  { value: 'Selma_VA', label: 'Selma, VA' },
  { value: 'Selma_NC', label: 'Selma, NC' },
  { value: 'Selma_AL', label: 'Selma, AL' },
  { value: 'Selma_IN', label: 'Selma, IN' },
  { value: 'Selma_IA', label: 'Selma, IA' },
  { value: 'Selma_CA', label: 'Selma, CA' },
  { value: 'Selma_OR', label: 'Selma, OR' },
  { value: 'Selman City_TX', label: 'Selman City, TX' },
  { value: 'Selmer_TN', label: 'Selmer, TN' },
  { value: 'Seltzer_PA', label: 'Seltzer, PA' },
  { value: 'Seminary_MS', label: 'Seminary, MS' },
  { value: 'Seminole_PA', label: 'Seminole, PA' },
  { value: 'Seminole_FL', label: 'Seminole, FL' },
  { value: 'Seminole_AL', label: 'Seminole, AL' },
  { value: 'Seminole_OK', label: 'Seminole, OK' },
  { value: 'Seminole_TX', label: 'Seminole, TX' },
  { value: 'Semmes_AL', label: 'Semmes, AL' },
  { value: 'Semora_NC', label: 'Semora, NC' },
  { value: 'Senath_MO', label: 'Senath, MO' },
  { value: 'Senatobia_MS', label: 'Senatobia, MS' },
  { value: 'Seneca_PA', label: 'Seneca, PA' },
  { value: 'Seneca_SC', label: 'Seneca, SC' },
  { value: 'Seneca_WI', label: 'Seneca, WI' },
  { value: 'Seneca_SD', label: 'Seneca, SD' },
  { value: 'Seneca_IL', label: 'Seneca, IL' },
  { value: 'Seneca_MO', label: 'Seneca, MO' },
  { value: 'Seneca_KS', label: 'Seneca, KS' },
  { value: 'Seneca_NE', label: 'Seneca, NE' },
  { value: 'Seneca_OR', label: 'Seneca, OR' },
  { value: 'Seneca Falls_NY', label: 'Seneca Falls, NY' },
  { value: 'Seneca Rocks_WV', label: 'Seneca Rocks, WV' },
  { value: 'Senecaville_OH', label: 'Senecaville, OH' },
  { value: 'Seney_MI', label: 'Seney, MI' },
  { value: 'Senoia_GA', label: 'Senoia, GA' },
  { value: 'Sentinel_OK', label: 'Sentinel, OK' },
  { value: 'Sentinel Butte_ND', label: 'Sentinel Butte, ND' },
  { value: 'Sequatchie_TN', label: 'Sequatchie, TN' },
  { value: 'Sequim_WA', label: 'Sequim, WA' },
  { value: 'Sequoia National Park_CA', label: 'Sequoia National Park, CA' },
  { value: 'Serafina_NM', label: 'Serafina, NM' },
  { value: 'Serena_IL', label: 'Serena, IL' },
  { value: 'Sergeant Bluff_IA', label: 'Sergeant Bluff, IA' },
  { value: 'Servia_IN', label: 'Servia, IN' },
  { value: 'Sesser_IL', label: 'Sesser, IL' },
  { value: 'Seth_WV', label: 'Seth, WV' },
  { value: 'Seven Mile_OH', label: 'Seven Mile, OH' },
  { value: 'Seven Springs_NC', label: 'Seven Springs, NC' },
  { value: 'Seven Valleys_PA', label: 'Seven Valleys, PA' },
  { value: 'Severance_NY', label: 'Severance, NY' },
  { value: 'Severance_CO', label: 'Severance, CO' },
  { value: 'Severn_MD', label: 'Severn, MD' },
  { value: 'Severn_NC', label: 'Severn, NC' },
  { value: 'Severna Park_MD', label: 'Severna Park, MD' },
  { value: 'Severy_KS', label: 'Severy, KS' },
  { value: 'Sevier_UT', label: 'Sevier, UT' },
  { value: 'Sevierville_TN', label: 'Sevierville, TN' },
  { value: 'Seville_GA', label: 'Seville, GA' },
  { value: 'Seville_FL', label: 'Seville, FL' },
  { value: 'Seville_OH', label: 'Seville, OH' },
  { value: 'Sewanee_TN', label: 'Sewanee, TN' },
  { value: 'Seward_PA', label: 'Seward, PA' },
  { value: 'Seward_IL', label: 'Seward, IL' },
  { value: 'Seward_NE', label: 'Seward, NE' },
  { value: 'Seward_AK', label: 'Seward, AK' },
  { value: 'Sewaren_NJ', label: 'Sewaren, NJ' },
  { value: 'Sewell_NJ', label: 'Sewell, NJ' },
  { value: 'Sewickley_PA', label: 'Sewickley, PA' },
  { value: 'Sextons Creek_KY', label: 'Sextons Creek, KY' },
  { value: 'Sextonville_WI', label: 'Sextonville, WI' },
  { value: 'Seymour_CT', label: 'Seymour, CT' },
  { value: 'Seymour_TN', label: 'Seymour, TN' },
  { value: 'Seymour_IN', label: 'Seymour, IN' },
  { value: 'Seymour_IA', label: 'Seymour, IA' },
  { value: 'Seymour_WI', label: 'Seymour, WI' },
  { value: 'Seymour_IL', label: 'Seymour, IL' },
  { value: 'Seymour_MO', label: 'Seymour, MO' },
  { value: 'Seymour_TX', label: 'Seymour, TX' },
  { value: 'Shabbona_IL', label: 'Shabbona, IL' },
  { value: 'Shacklefords_VA', label: 'Shacklefords, VA' },
  { value: 'Shade_OH', label: 'Shade, OH' },
  { value: 'Shade Gap_PA', label: 'Shade Gap, PA' },
  { value: 'Shady Cove_OR', label: 'Shady Cove, OR' },
  { value: 'Shady Dale_GA', label: 'Shady Dale, GA' },
  { value: 'Shady Point_OK', label: 'Shady Point, OK' },
  { value: 'Shady Side_MD', label: 'Shady Side, MD' },
  { value: 'Shady Spring_WV', label: 'Shady Spring, WV' },
  { value: 'Shady Valley_TN', label: 'Shady Valley, TN' },
  { value: 'Shadyside_OH', label: 'Shadyside, OH' },
  { value: 'Shafer_MN', label: 'Shafer, MN' },
  { value: 'Shafter_CA', label: 'Shafter, CA' },
  { value: 'Shaftsbury_VT', label: 'Shaftsbury, VT' },
  { value: 'Shageluk_AK', label: 'Shageluk, AK' },
  { value: 'Shakopee_MN', label: 'Shakopee, MN' },
  { value: 'Shaktoolik_AK', label: 'Shaktoolik, AK' },
  { value: 'Shalimar_FL', label: 'Shalimar, FL' },
  { value: 'Shallotte_NC', label: 'Shallotte, NC' },
  { value: 'Shallowater_TX', label: 'Shallowater, TX' },
  { value: 'Shambaugh_IA', label: 'Shambaugh, IA' },
  { value: 'Shamokin_PA', label: 'Shamokin, PA' },
  { value: 'Shamokin Dam_PA', label: 'Shamokin Dam, PA' },
  { value: 'Shamrock_OK', label: 'Shamrock, OK' },
  { value: 'Shamrock_TX', label: 'Shamrock, TX' },
  { value: 'Shandaken_NY', label: 'Shandaken, NY' },
  { value: 'Shandon_CA', label: 'Shandon, CA' },
  { value: 'Shaniko_OR', label: 'Shaniko, OR' },
  { value: 'Shanks_WV', label: 'Shanks, WV' },
  { value: 'Shanksville_PA', label: 'Shanksville, PA' },
  { value: 'Shannock_RI', label: 'Shannock, RI' },
  { value: 'Shannon_NC', label: 'Shannon, NC' },
  { value: 'Shannon_MS', label: 'Shannon, MS' },
  { value: 'Shannon_IL', label: 'Shannon, IL' },
  { value: 'Shannon City_IA', label: 'Shannon City, IA' },
  { value: 'Shapleigh_ME', label: 'Shapleigh, ME' },
  { value: 'Sharon_MA', label: 'Sharon, MA' },
  { value: 'Sharon_VT', label: 'Sharon, VT' },
  { value: 'Sharon_CT', label: 'Sharon, CT' },
  { value: 'Sharon_PA', label: 'Sharon, PA' },
  { value: 'Sharon_SC', label: 'Sharon, SC' },
  { value: 'Sharon_GA', label: 'Sharon, GA' },
  { value: 'Sharon_TN', label: 'Sharon, TN' },
  { value: 'Sharon_WI', label: 'Sharon, WI' },
  { value: 'Sharon_ND', label: 'Sharon, ND' },
  { value: 'Sharon_KS', label: 'Sharon, KS' },
  { value: 'Sharon_OK', label: 'Sharon, OK' },
  { value: 'Sharon Center_OH', label: 'Sharon Center, OH' },
  { value: 'Sharon Grove_KY', label: 'Sharon Grove, KY' },
  { value: 'Sharon Hill_PA', label: 'Sharon Hill, PA' },
  { value: 'Sharon Springs_NY', label: 'Sharon Springs, NY' },
  { value: 'Sharon Springs_KS', label: 'Sharon Springs, KS' },
  { value: 'Sharpes_FL', label: 'Sharpes, FL' },
  { value: 'Sharples_WV', label: 'Sharples, WV' },
  { value: 'Sharps_VA', label: 'Sharps, VA' },
  { value: 'Sharps Chapel_TN', label: 'Sharps Chapel, TN' },
  { value: 'Sharpsburg_MD', label: 'Sharpsburg, MD' },
  { value: 'Sharpsburg_NC', label: 'Sharpsburg, NC' },
  { value: 'Sharpsburg_GA', label: 'Sharpsburg, GA' },
  { value: 'Sharpsburg_KY', label: 'Sharpsburg, KY' },
  { value: 'Sharpsburg_IA', label: 'Sharpsburg, IA' },
  { value: 'Sharpsville_PA', label: 'Sharpsville, PA' },
  { value: 'Sharpsville_IN', label: 'Sharpsville, IN' },
  { value: 'Sharptown_MD', label: 'Sharptown, MD' },
  { value: 'Shartlesville_PA', label: 'Shartlesville, PA' },
  { value: 'Shasta_CA', label: 'Shasta, CA' },
  { value: 'Shasta Lake_CA', label: 'Shasta Lake, CA' },
  { value: 'Shattuck_OK', label: 'Shattuck, OK' },
  { value: 'Shaver Lake_CA', label: 'Shaver Lake, CA' },
  { value: 'Shavertown_PA', label: 'Shavertown, PA' },
  { value: 'Shaw_MS', label: 'Shaw, MS' },
  { value: 'Shaw Afb_SC', label: 'Shaw Afb, SC' },
  { value: 'Shaw Island_WA', label: 'Shaw Island, WA' },
  { value: 'Shawano_WI', label: 'Shawano, WI' },
  { value: 'Shawboro_NC', label: 'Shawboro, NC' },
  { value: 'Shawmut_ME', label: 'Shawmut, ME' },
  { value: 'Shawmut_MT', label: 'Shawmut, MT' },
  { value: 'Shawnee_OH', label: 'Shawnee, OH' },
  { value: 'Shawnee_KS', label: 'Shawnee, KS' },
  { value: 'Shawnee_OK', label: 'Shawnee, OK' },
  { value: 'Shawnee_CO', label: 'Shawnee, CO' },
  { value: 'Shawnee_WY', label: 'Shawnee, WY' },
  { value: 'Shawnee On Delaware_PA', label: 'Shawnee On Delaware, PA' },
  { value: 'Shawneetown_IL', label: 'Shawneetown, IL' },
  { value: 'Shawsville_VA', label: 'Shawsville, VA' },
  { value: 'Sheakleyville_PA', label: 'Sheakleyville, PA' },
  { value: 'Sheboygan_WI', label: 'Sheboygan, WI' },
  { value: 'Sheboygan Falls_WI', label: 'Sheboygan Falls, WI' },
  { value: 'Shedd_OR', label: 'Shedd, OR' },
  { value: 'Sheep Springs_NM', label: 'Sheep Springs, NM' },
  { value: 'Sheffield_MA', label: 'Sheffield, MA' },
  { value: 'Sheffield_VT', label: 'Sheffield, VT' },
  { value: 'Sheffield_PA', label: 'Sheffield, PA' },
  { value: 'Sheffield_AL', label: 'Sheffield, AL' },
  { value: 'Sheffield_IA', label: 'Sheffield, IA' },
  { value: 'Sheffield_IL', label: 'Sheffield, IL' },
  { value: 'Sheffield_TX', label: 'Sheffield, TX' },
  { value: 'Sheffield Lake_OH', label: 'Sheffield Lake, OH' },
  { value: 'Shelbiana_KY', label: 'Shelbiana, KY' },
  { value: 'Shelbina_MO', label: 'Shelbina, MO' },
  { value: 'Shelburn_IN', label: 'Shelburn, IN' },
  { value: 'Shelburne_VT', label: 'Shelburne, VT' },
  { value: 'Shelburne Falls_MA', label: 'Shelburne Falls, MA' },
  { value: 'Shelby_NC', label: 'Shelby, NC' },
  { value: 'Shelby_AL', label: 'Shelby, AL' },
  { value: 'Shelby_MS', label: 'Shelby, MS' },
  { value: 'Shelby_OH', label: 'Shelby, OH' },
  { value: 'Shelby_IN', label: 'Shelby, IN' },
  { value: 'Shelby_MI', label: 'Shelby, MI' },
  { value: 'Shelby_IA', label: 'Shelby, IA' },
  { value: 'Shelby_MT', label: 'Shelby, MT' },
  { value: 'Shelby_NE', label: 'Shelby, NE' },
  { value: 'Shelby Gap_KY', label: 'Shelby Gap, KY' },
  { value: 'Shelbyville_TN', label: 'Shelbyville, TN' },
  { value: 'Shelbyville_KY', label: 'Shelbyville, KY' },
  { value: 'Shelbyville_IN', label: 'Shelbyville, IN' },
  { value: 'Shelbyville_MI', label: 'Shelbyville, MI' },
  { value: 'Shelbyville_IL', label: 'Shelbyville, IL' },
  { value: 'Shelbyville_MO', label: 'Shelbyville, MO' },
  { value: 'Shelbyville_TX', label: 'Shelbyville, TX' },
  { value: 'Sheldahl_IA', label: 'Sheldahl, IA' },
  { value: 'Sheldon_VT', label: 'Sheldon, VT' },
  { value: 'Sheldon_SC', label: 'Sheldon, SC' },
  { value: 'Sheldon_IA', label: 'Sheldon, IA' },
  { value: 'Sheldon_WI', label: 'Sheldon, WI' },
  { value: 'Sheldon_ND', label: 'Sheldon, ND' },
  { value: 'Sheldon_IL', label: 'Sheldon, IL' },
  { value: 'Sheldon_MO', label: 'Sheldon, MO' },
  { value: 'Sheldon Springs_VT', label: 'Sheldon Springs, VT' },
  { value: 'Shell_WY', label: 'Shell, WY' },
  { value: 'Shell Knob_MO', label: 'Shell Knob, MO' },
  { value: 'Shell Lake_WI', label: 'Shell Lake, WI' },
  { value: 'Shell Rock_IA', label: 'Shell Rock, IA' },
  { value: 'Shelley_ID', label: 'Shelley, ID' },
  { value: 'Shellman_GA', label: 'Shellman, GA' },
  { value: 'Shellsburg_IA', label: 'Shellsburg, IA' },
  { value: 'Shelly_MN', label: 'Shelly, MN' },
  { value: 'Shelocta_PA', label: 'Shelocta, PA' },
  { value: 'Shelter Island_NY', label: 'Shelter Island, NY' },
  { value: 'Shelter Island Heights_NY', label: 'Shelter Island Heights, NY' },
  { value: 'Shelton_CT', label: 'Shelton, CT' },
  { value: 'Shelton_NE', label: 'Shelton, NE' },
  { value: 'Shelton_WA', label: 'Shelton, WA' },
  { value: 'Shenandoah_PA', label: 'Shenandoah, PA' },
  { value: 'Shenandoah_VA', label: 'Shenandoah, VA' },
  { value: 'Shenandoah_IA', label: 'Shenandoah, IA' },
  { value: 'Shenandoah Junction_WV', label: 'Shenandoah Junction, WV' },
  { value: 'Shepardsville_IN', label: 'Shepardsville, IN' },
  { value: 'Shepherd_MI', label: 'Shepherd, MI' },
  { value: 'Shepherd_MT', label: 'Shepherd, MT' },
  { value: 'Shepherd_TX', label: 'Shepherd, TX' },
  { value: 'Shepherdstown_WV', label: 'Shepherdstown, WV' },
  { value: 'Shepherdsville_KY', label: 'Shepherdsville, KY' },
  { value: 'Sheppard Afb_TX', label: 'Sheppard Afb, TX' },
  { value: 'Sheppton_PA', label: 'Sheppton, PA' },
  { value: 'Sherborn_MA', label: 'Sherborn, MA' },
  { value: 'Sherburn_MN', label: 'Sherburn, MN' },
  { value: 'Sherburne_NY', label: 'Sherburne, NY' },
  { value: 'Sheridan_NY', label: 'Sheridan, NY' },
  { value: 'Sheridan_IN', label: 'Sheridan, IN' },
  { value: 'Sheridan_MI', label: 'Sheridan, MI' },
  { value: 'Sheridan_MT', label: 'Sheridan, MT' },
  { value: 'Sheridan_IL', label: 'Sheridan, IL' },
  { value: 'Sheridan_MO', label: 'Sheridan, MO' },
  { value: 'Sheridan_AR', label: 'Sheridan, AR' },
  { value: 'Sheridan_TX', label: 'Sheridan, TX' },
  { value: 'Sheridan_WY', label: 'Sheridan, WY' },
  { value: 'Sheridan_CA', label: 'Sheridan, CA' },
  { value: 'Sheridan_OR', label: 'Sheridan, OR' },
  { value: 'Sheridan Lake_CO', label: 'Sheridan Lake, CO' },
  { value: 'Sherman_ME', label: 'Sherman, ME' },
  { value: 'Sherman_CT', label: 'Sherman, CT' },
  { value: 'Sherman_NY', label: 'Sherman, NY' },
  { value: 'Sherman_MS', label: 'Sherman, MS' },
  { value: 'Sherman_IL', label: 'Sherman, IL' },
  { value: 'Sherman_TX', label: 'Sherman, TX' },
  { value: 'Sherman Oaks_CA', label: 'Sherman Oaks, CA' },
  { value: 'Shermans Dale_PA', label: 'Shermans Dale, PA' },
  { value: 'Sherrard_IL', label: 'Sherrard, IL' },
  { value: 'Sherrill_NY', label: 'Sherrill, NY' },
  { value: 'Sherrill_IA', label: 'Sherrill, IA' },
  { value: 'Sherrill_AR', label: 'Sherrill, AR' },
  { value: 'Sherrills Ford_NC', label: 'Sherrills Ford, NC' },
  { value: 'Sherrodsville_OH', label: 'Sherrodsville, OH' },
  { value: 'Sherwood_MD', label: 'Sherwood, MD' },
  { value: 'Sherwood_TN', label: 'Sherwood, TN' },
  { value: 'Sherwood_OH', label: 'Sherwood, OH' },
  { value: 'Sherwood_MI', label: 'Sherwood, MI' },
  { value: 'Sherwood_WI', label: 'Sherwood, WI' },
  { value: 'Sherwood_ND', label: 'Sherwood, ND' },
  { value: 'Sherwood_AR', label: 'Sherwood, AR' },
  { value: 'Sherwood_OR', label: 'Sherwood, OR' },
  { value: 'Shevlin_MN', label: 'Shevlin, MN' },
  { value: 'Sheyenne_ND', label: 'Sheyenne, ND' },
  { value: 'Shickley_NE', label: 'Shickley, NE' },
  { value: 'Shickshinny_PA', label: 'Shickshinny, PA' },
  { value: 'Shidler_OK', label: 'Shidler, OK' },
  { value: 'Shields_ND', label: 'Shields, ND' },
  { value: 'Shiloh_NJ', label: 'Shiloh, NJ' },
  { value: 'Shiloh_NC', label: 'Shiloh, NC' },
  { value: 'Shiloh_GA', label: 'Shiloh, GA' },
  { value: 'Shiloh_TN', label: 'Shiloh, TN' },
  { value: 'Shiloh_OH', label: 'Shiloh, OH' },
  { value: 'Shiner_TX', label: 'Shiner, TX' },
  { value: 'Shingle Springs_CA', label: 'Shingle Springs, CA' },
  { value: 'Shinglehouse_PA', label: 'Shinglehouse, PA' },
  { value: 'Shingleton_MI', label: 'Shingleton, MI' },
  { value: 'Shingletown_CA', label: 'Shingletown, CA' },
  { value: 'Shinnston_WV', label: 'Shinnston, WV' },
  { value: 'Shiocton_WI', label: 'Shiocton, WI' },
  { value: 'Shipman_VA', label: 'Shipman, VA' },
  { value: 'Shipman_IL', label: 'Shipman, IL' },
  { value: 'Shippensburg_PA', label: 'Shippensburg, PA' },
  { value: 'Shippenville_PA', label: 'Shippenville, PA' },
  { value: 'Shippingport_PA', label: 'Shippingport, PA' },
  { value: 'Shiprock_NM', label: 'Shiprock, NM' },
  { value: 'Shipshewana_IN', label: 'Shipshewana, IN' },
  { value: 'Shirland_IL', label: 'Shirland, IL' },
  { value: 'Shirley_MA', label: 'Shirley, MA' },
  { value: 'Shirley_NY', label: 'Shirley, NY' },
  { value: 'Shirley_IN', label: 'Shirley, IN' },
  { value: 'Shirley_IL', label: 'Shirley, IL' },
  { value: 'Shirley_AR', label: 'Shirley, AR' },
  { value: 'Shirley Mills_ME', label: 'Shirley Mills, ME' },
  { value: 'Shirleysburg_PA', label: 'Shirleysburg, PA' },
  { value: 'Shiro_TX', label: 'Shiro, TX' },
  { value: 'Shishmaref_AK', label: 'Shishmaref, AK' },
  { value: 'Shoals_IN', label: 'Shoals, IN' },
  { value: 'Shobonier_IL', label: 'Shobonier, IL' },
  { value: 'Shock_WV', label: 'Shock, WV' },
  { value: 'Shoemakersville_PA', label: 'Shoemakersville, PA' },
  { value: 'Shohola_PA', label: 'Shohola, PA' },
  { value: 'Shokan_NY', label: 'Shokan, NY' },
  { value: 'Shongaloo_LA', label: 'Shongaloo, LA' },
  { value: 'Shonto_AZ', label: 'Shonto, AZ' },
  { value: 'Shoreham_VT', label: 'Shoreham, VT' },
  { value: 'Shoreham_NY', label: 'Shoreham, NY' },
  { value: 'Shorewood_IL', label: 'Shorewood, IL' },
  { value: 'Short Creek_WV', label: 'Short Creek, WV' },
  { value: 'Short Hills_NJ', label: 'Short Hills, NJ' },
  { value: 'Shorter_AL', label: 'Shorter, AL' },
  { value: 'Shorterville_AL', label: 'Shorterville, AL' },
  { value: 'Shortsville_NY', label: 'Shortsville, NY' },
  { value: 'Shoshone_ID', label: 'Shoshone, ID' },
  { value: 'Shoshone_CA', label: 'Shoshone, CA' },
  { value: 'Shoshoni_WY', label: 'Shoshoni, WY' },
  { value: 'Shoup_ID', label: 'Shoup, ID' },
  { value: 'Show Low_AZ', label: 'Show Low, AZ' },
  { value: 'Showell_MD', label: 'Showell, MD' },
  { value: 'Shreve_OH', label: 'Shreve, OH' },
  { value: 'Shreveport_LA', label: 'Shreveport, LA' },
  { value: 'Shrewsbury_MA', label: 'Shrewsbury, MA' },
  { value: 'Shrewsbury_NJ', label: 'Shrewsbury, NJ' },
  { value: 'Shrewsbury_PA', label: 'Shrewsbury, PA' },
  { value: 'Shrub Oak_NY', label: 'Shrub Oak, NY' },
  { value: 'Shubert_NE', label: 'Shubert, NE' },
  { value: 'Shubuta_MS', label: 'Shubuta, MS' },
  { value: 'Shullsburg_WI', label: 'Shullsburg, WI' },
  { value: 'Shumway_IL', label: 'Shumway, IL' },
  { value: 'Shungnak_AK', label: 'Shungnak, AK' },
  { value: 'Shunk_PA', label: 'Shunk, PA' },
  { value: 'Shuqualak_MS', label: 'Shuqualak, MS' },
  { value: 'Shushan_NY', label: 'Shushan, NY' },
  { value: 'Shutesbury_MA', label: 'Shutesbury, MA' },
  { value: 'Siasconset_MA', label: 'Siasconset, MA' },
  { value: 'Sibley_IA', label: 'Sibley, IA' },
  { value: 'Sibley_IL', label: 'Sibley, IL' },
  { value: 'Sibley_MO', label: 'Sibley, MO' },
  { value: 'Sibley_LA', label: 'Sibley, LA' },
  { value: 'Sicily Island_LA', label: 'Sicily Island, LA' },
  { value: 'Sicklerville_NJ', label: 'Sicklerville, NJ' },
  { value: 'Side Lake_MN', label: 'Side Lake, MN' },
  { value: 'Sidell_IL', label: 'Sidell, IL' },
  { value: 'Sidman_PA', label: 'Sidman, PA' },
  { value: 'Sidnaw_MI', label: 'Sidnaw, MI' },
  { value: 'Sidney_NY', label: 'Sidney, NY' },
  { value: 'Sidney_KY', label: 'Sidney, KY' },
  { value: 'Sidney_OH', label: 'Sidney, OH' },
  { value: 'Sidney_MI', label: 'Sidney, MI' },
  { value: 'Sidney_IA', label: 'Sidney, IA' },
  { value: 'Sidney_MT', label: 'Sidney, MT' },
  { value: 'Sidney_IL', label: 'Sidney, IL' },
  { value: 'Sidney_NE', label: 'Sidney, NE' },
  { value: 'Sidney_AR', label: 'Sidney, AR' },
  { value: 'Sidney_TX', label: 'Sidney, TX' },
  { value: 'Sidney Center_NY', label: 'Sidney Center, NY' },
  { value: 'Sidon_MS', label: 'Sidon, MS' },
  { value: 'Sieper_LA', label: 'Sieper, LA' },
  { value: 'Sierra Blanca_TX', label: 'Sierra Blanca, TX' },
  { value: 'Sierra City_CA', label: 'Sierra City, CA' },
  { value: 'Sierra Madre_CA', label: 'Sierra Madre, CA' },
  { value: 'Sierra Vista_AZ', label: 'Sierra Vista, AZ' },
  { value: 'Sierraville_CA', label: 'Sierraville, CA' },
  { value: 'Sigel_PA', label: 'Sigel, PA' },
  { value: 'Sigel_IL', label: 'Sigel, IL' },
  { value: 'Signal Hill_CA', label: 'Signal Hill, CA' },
  { value: 'Signal Mountain_TN', label: 'Signal Mountain, TN' },
  { value: 'Sigourney_IA', label: 'Sigourney, IA' },
  { value: 'Sigurd_UT', label: 'Sigurd, UT' },
  { value: 'Sikes_LA', label: 'Sikes, LA' },
  { value: 'Sikeston_MO', label: 'Sikeston, MO' },
  { value: 'Silas_AL', label: 'Silas, AL' },
  { value: 'Siler_KY', label: 'Siler, KY' },
  { value: 'Siler City_NC', label: 'Siler City, NC' },
  { value: 'Siletz_OR', label: 'Siletz, OR' },
  { value: 'Silex_MO', label: 'Silex, MO' },
  { value: 'Siloam_NC', label: 'Siloam, NC' },
  { value: 'Siloam_GA', label: 'Siloam, GA' },
  { value: 'Siloam Springs_AR', label: 'Siloam Springs, AR' },
  { value: 'Silsbee_TX', label: 'Silsbee, TX' },
  { value: 'Silt_CO', label: 'Silt, CO' },
  { value: 'Silva_MO', label: 'Silva, MO' },
  { value: 'Silver Bay_NY', label: 'Silver Bay, NY' },
  { value: 'Silver Bay_MN', label: 'Silver Bay, MN' },
  { value: 'Silver City_MS', label: 'Silver City, MS' },
  { value: 'Silver City_IA', label: 'Silver City, IA' },
  { value: 'Silver City_NM', label: 'Silver City, NM' },
  { value: 'Silver City_NV', label: 'Silver City, NV' },
  { value: 'Silver Creek_NY', label: 'Silver Creek, NY' },
  { value: 'Silver Creek_GA', label: 'Silver Creek, GA' },
  { value: 'Silver Creek_MS', label: 'Silver Creek, MS' },
  { value: 'Silver Creek_NE', label: 'Silver Creek, NE' },
  { value: 'Silver Creek_WA', label: 'Silver Creek, WA' },
  { value: 'Silver Gate_MT', label: 'Silver Gate, MT' },
  { value: 'Silver Grove_KY', label: 'Silver Grove, KY' },
  { value: 'Silver Lake_NH', label: 'Silver Lake, NH' },
  { value: 'Silver Lake_NY', label: 'Silver Lake, NY' },
  { value: 'Silver Lake_IN', label: 'Silver Lake, IN' },
  { value: 'Silver Lake_WI', label: 'Silver Lake, WI' },
  { value: 'Silver Lake_MN', label: 'Silver Lake, MN' },
  { value: 'Silver Lake_KS', label: 'Silver Lake, KS' },
  { value: 'Silver Lake_OR', label: 'Silver Lake, OR' },
  { value: 'Silver Plume_CO', label: 'Silver Plume, CO' },
  { value: 'Silver Point_TN', label: 'Silver Point, TN' },
  { value: 'Silver Spring_MD', label: 'Silver Spring, MD' },
  { value: 'Silver Springs_NY', label: 'Silver Springs, NY' },
  { value: 'Silver Springs_FL', label: 'Silver Springs, FL' },
  { value: 'Silver Springs_NV', label: 'Silver Springs, NV' },
  { value: 'Silver Star_MT', label: 'Silver Star, MT' },
  { value: 'Silverado_CA', label: 'Silverado, CA' },
  { value: 'Silverdale_PA', label: 'Silverdale, PA' },
  { value: 'Silverdale_WA', label: 'Silverdale, WA' },
  { value: 'Silverhill_AL', label: 'Silverhill, AL' },
  { value: 'Silverlake_WA', label: 'Silverlake, WA' },
  { value: 'Silverpeak_NV', label: 'Silverpeak, NV' },
  { value: 'Silverstreet_SC', label: 'Silverstreet, SC' },
  { value: 'Silverthorne_CO', label: 'Silverthorne, CO' },
  { value: 'Silverton_TX', label: 'Silverton, TX' },
  { value: 'Silverton_CO', label: 'Silverton, CO' },
  { value: 'Silverton_ID', label: 'Silverton, ID' },
  { value: 'Silverton_OR', label: 'Silverton, OR' },
  { value: 'Silverwood_MI', label: 'Silverwood, MI' },
  { value: 'Silvis_IL', label: 'Silvis, IL' },
  { value: 'Simi Valley_CA', label: 'Simi Valley, CA' },
  { value: 'Simla_CO', label: 'Simla, CO' },
  { value: 'Simmesport_LA', label: 'Simmesport, LA' },
  { value: 'Simms_MT', label: 'Simms, MT' },
  { value: 'Simms_TX', label: 'Simms, TX' },
  { value: 'Simon_WV', label: 'Simon, WV' },
  { value: 'Simonton_TX', label: 'Simonton, TX' },
  { value: 'Simpson_WV', label: 'Simpson, WV' },
  { value: 'Simpson_NC', label: 'Simpson, NC' },
  { value: 'Simpson_IL', label: 'Simpson, IL' },
  { value: 'Simpson_KS', label: 'Simpson, KS' },
  { value: 'Simpson_LA', label: 'Simpson, LA' },
  { value: 'Simpsonville_SC', label: 'Simpsonville, SC' },
  { value: 'Simpsonville_KY', label: 'Simpsonville, KY' },
  { value: 'Sims_NC', label: 'Sims, NC' },
  { value: 'Sims_IL', label: 'Sims, IL' },
  { value: 'Sims_AR', label: 'Sims, AR' },
  { value: 'Simsboro_LA', label: 'Simsboro, LA' },
  { value: 'Simsbury_CT', label: 'Simsbury, CT' },
  { value: 'Sinai_SD', label: 'Sinai, SD' },
  { value: 'Sinclair_ME', label: 'Sinclair, ME' },
  { value: 'Sinclair_WY', label: 'Sinclair, WY' },
  { value: 'Sinclairville_NY', label: 'Sinclairville, NY' },
  { value: 'Singer_LA', label: 'Singer, LA' },
  { value: 'Singers Glen_VA', label: 'Singers Glen, VA' },
  { value: 'Sinking Spring_OH', label: 'Sinking Spring, OH' },
  { value: 'Sinks Grove_WV', label: 'Sinks Grove, WV' },
  { value: 'Sinnamahoning_PA', label: 'Sinnamahoning, PA' },
  { value: 'Sinton_TX', label: 'Sinton, TX' },
  { value: 'Sioux Center_IA', label: 'Sioux Center, IA' },
  { value: 'Sioux City_IA', label: 'Sioux City, IA' },
  { value: 'Sioux Falls_SD', label: 'Sioux Falls, SD' },
  { value: 'Sioux Rapids_IA', label: 'Sioux Rapids, IA' },
  { value: 'Sipesville_PA', label: 'Sipesville, PA' },
  { value: 'Sipsey_AL', label: 'Sipsey, AL' },
  { value: 'Siren_WI', label: 'Siren, WI' },
  { value: 'Sisseton_SD', label: 'Sisseton, SD' },
  { value: 'Sister Bay_WI', label: 'Sister Bay, WI' },
  { value: 'Sisters_OR', label: 'Sisters, OR' },
  { value: 'Sistersville_WV', label: 'Sistersville, WV' },
  { value: 'Sitka_KY', label: 'Sitka, KY' },
  { value: 'Sitka_AK', label: 'Sitka, AK' },
  { value: 'Six Lakes_MI', label: 'Six Lakes, MI' },
  { value: 'Six Mile_SC', label: 'Six Mile, SC' },
  { value: 'Six Mile Run_PA', label: 'Six Mile Run, PA' },
  { value: 'Sixes_OR', label: 'Sixes, OR' },
  { value: 'Sizerock_KY', label: 'Sizerock, KY' },
  { value: 'Skagway_AK', label: 'Skagway, AK' },
  { value: 'Skamokawa_WA', label: 'Skamokawa, WA' },
  { value: 'Skandia_MI', label: 'Skandia, MI' },
  { value: 'Skaneateles_NY', label: 'Skaneateles, NY' },
  { value: 'Skaneateles Falls_NY', label: 'Skaneateles Falls, NY' },
  { value: 'Skanee_MI', label: 'Skanee, MI' },
  { value: 'Skellytown_TX', label: 'Skellytown, TX' },
  { value: 'Skiatook_OK', label: 'Skiatook, OK' },
  { value: 'Skidmore_MO', label: 'Skidmore, MO' },
  { value: 'Skidmore_TX', label: 'Skidmore, TX' },
  { value: 'Skillman_NJ', label: 'Skillman, NJ' },
  { value: 'Skippack_PA', label: 'Skippack, PA' },
  { value: 'Skippers_VA', label: 'Skippers, VA' },
  { value: 'Skipperville_AL', label: 'Skipperville, AL' },
  { value: 'Skipwith_VA', label: 'Skipwith, VA' },
  { value: 'Skokie_IL', label: 'Skokie, IL' },
  { value: 'Skowhegan_ME', label: 'Skowhegan, ME' },
  { value: 'Skull Valley_AZ', label: 'Skull Valley, AZ' },
  { value: 'Skwentna_AK', label: 'Skwentna, AK' },
  { value: 'Skyforest_CA', label: 'Skyforest, CA' },
  { value: 'Skykomish_WA', label: 'Skykomish, WA' },
  { value: 'Skytop_PA', label: 'Skytop, PA' },
  { value: 'Slab Fork_WV', label: 'Slab Fork, WV' },
  { value: 'Slade_KY', label: 'Slade, KY' },
  { value: 'Slagle_LA', label: 'Slagle, LA' },
  { value: 'Slanesville_WV', label: 'Slanesville, WV' },
  { value: 'Slate Hill_NY', label: 'Slate Hill, NY' },
  { value: 'Slate Run_PA', label: 'Slate Run, PA' },
  { value: 'Slatedale_PA', label: 'Slatedale, PA' },
  { value: 'Slater_SC', label: 'Slater, SC' },
  { value: 'Slater_IA', label: 'Slater, IA' },
  { value: 'Slater_MO', label: 'Slater, MO' },
  { value: 'Slater_CO', label: 'Slater, CO' },
  { value: 'Slatersville_RI', label: 'Slatersville, RI' },
  { value: 'Slaterville Springs_NY', label: 'Slaterville Springs, NY' },
  { value: 'Slatington_PA', label: 'Slatington, PA' },
  { value: 'Slaton_TX', label: 'Slaton, TX' },
  { value: 'Slatyfork_WV', label: 'Slatyfork, WV' },
  { value: 'Slaughter_LA', label: 'Slaughter, LA' },
  { value: 'Slaughters_KY', label: 'Slaughters, KY' },
  { value: 'Slayden_TN', label: 'Slayden, TN' },
  { value: 'Slayton_MN', label: 'Slayton, MN' },
  { value: 'Sledge_MS', label: 'Sledge, MS' },
  { value: 'Sleepy Eye_MN', label: 'Sleepy Eye, MN' },
  { value: 'Sleetmute_AK', label: 'Sleetmute, AK' },
  { value: 'Slemp_KY', label: 'Slemp, KY' },
  { value: 'Slick_OK', label: 'Slick, OK' },
  { value: 'Slickville_PA', label: 'Slickville, PA' },
  { value: 'Slidell_LA', label: 'Slidell, LA' },
  { value: 'Slidell_TX', label: 'Slidell, TX' },
  { value: 'Sligo_PA', label: 'Sligo, PA' },
  { value: 'Slinger_WI', label: 'Slinger, WI' },
  { value: 'Slingerlands_NY', label: 'Slingerlands, NY' },
  { value: 'Slippery Rock_PA', label: 'Slippery Rock, PA' },
  { value: 'Sloan_IA', label: 'Sloan, IA' },
  { value: 'Sloan_NV', label: 'Sloan, NV' },
  { value: 'Sloansville_NY', label: 'Sloansville, NY' },
  { value: 'Sloatsburg_NY', label: 'Sloatsburg, NY' },
  { value: 'Slocomb_AL', label: 'Slocomb, AL' },
  { value: 'Sloughhouse_CA', label: 'Sloughhouse, CA' },
  { value: 'Slovan_PA', label: 'Slovan, PA' },
  { value: 'Smackover_AR', label: 'Smackover, AR' },
  { value: 'Smallwood_NY', label: 'Smallwood, NY' },
  { value: 'Smartsville_CA', label: 'Smartsville, CA' },
  { value: 'Smelterville_ID', label: 'Smelterville, ID' },
  { value: 'Smethport_PA', label: 'Smethport, PA' },
  { value: 'Smicksburg_PA', label: 'Smicksburg, PA' },
  { value: 'Smilax_KY', label: 'Smilax, KY' },
  { value: 'Smiley_TX', label: 'Smiley, TX' },
  { value: 'Smith_NV', label: 'Smith, NV' },
  { value: 'Smith Center_KS', label: 'Smith Center, KS' },
  { value: 'Smith Lake_NM', label: 'Smith Lake, NM' },
  { value: 'Smith River_CA', label: 'Smith River, CA' },
  { value: 'Smithboro_IL', label: 'Smithboro, IL' },
  { value: 'Smithburg_WV', label: 'Smithburg, WV' },
  { value: 'Smithdale_MS', label: 'Smithdale, MS' },
  { value: 'Smithers_WV', label: 'Smithers, WV' },
  { value: 'Smithfield_RI', label: 'Smithfield, RI' },
  { value: 'Smithfield_ME', label: 'Smithfield, ME' },
  { value: 'Smithfield_PA', label: 'Smithfield, PA' },
  { value: 'Smithfield_VA', label: 'Smithfield, VA' },
  { value: 'Smithfield_WV', label: 'Smithfield, WV' },
  { value: 'Smithfield_NC', label: 'Smithfield, NC' },
  { value: 'Smithfield_KY', label: 'Smithfield, KY' },
  { value: 'Smithfield_OH', label: 'Smithfield, OH' },
  { value: 'Smithfield_IL', label: 'Smithfield, IL' },
  { value: 'Smithfield_NE', label: 'Smithfield, NE' },
  { value: 'Smithfield_UT', label: 'Smithfield, UT' },
  { value: 'Smithland_KY', label: 'Smithland, KY' },
  { value: 'Smithland_IA', label: 'Smithland, IA' },
  { value: 'Smithmill_PA', label: 'Smithmill, PA' },
  { value: 'Smiths Creek_MI', label: 'Smiths Creek, MI' },
  { value: 'Smiths Grove_KY', label: 'Smiths Grove, KY' },
  { value: 'Smiths Station_AL', label: 'Smiths Station, AL' },
  { value: 'Smithsburg_MD', label: 'Smithsburg, MD' },
  { value: 'Smithshire_IL', label: 'Smithshire, IL' },
  { value: 'Smithton_PA', label: 'Smithton, PA' },
  { value: 'Smithton_IL', label: 'Smithton, IL' },
  { value: 'Smithton_MO', label: 'Smithton, MO' },
  { value: 'Smithtown_NY', label: 'Smithtown, NY' },
  { value: 'Smithville_WV', label: 'Smithville, WV' },
  { value: 'Smithville_GA', label: 'Smithville, GA' },
  { value: 'Smithville_TN', label: 'Smithville, TN' },
  { value: 'Smithville_MS', label: 'Smithville, MS' },
  { value: 'Smithville_OH', label: 'Smithville, OH' },
  { value: 'Smithville_IN', label: 'Smithville, IN' },
  { value: 'Smithville_MO', label: 'Smithville, MO' },
  { value: 'Smithville_AR', label: 'Smithville, AR' },
  { value: 'Smithville_OK', label: 'Smithville, OK' },
  { value: 'Smithville_TX', label: 'Smithville, TX' },
  { value: 'Smithville Flats_NY', label: 'Smithville Flats, NY' },
  { value: 'Smithwick_SD', label: 'Smithwick, SD' },
  { value: 'Smoaks_SC', label: 'Smoaks, SC' },
  { value: 'Smock_PA', label: 'Smock, PA' },
  { value: 'Smoketown_PA', label: 'Smoketown, PA' },
  { value: 'Smoot_WV', label: 'Smoot, WV' },
  { value: 'Smoot_WY', label: 'Smoot, WY' },
  { value: 'Smyer_TX', label: 'Smyer, TX' },
  { value: 'Smyrna_NY', label: 'Smyrna, NY' },
  { value: 'Smyrna_DE', label: 'Smyrna, DE' },
  { value: 'Smyrna_NC', label: 'Smyrna, NC' },
  { value: 'Smyrna_SC', label: 'Smyrna, SC' },
  { value: 'Smyrna_GA', label: 'Smyrna, GA' },
  { value: 'Smyrna_TN', label: 'Smyrna, TN' },
  { value: 'Smyrna Mills_ME', label: 'Smyrna Mills, ME' },
  { value: 'Sneads_FL', label: 'Sneads, FL' },
  { value: 'Sneads Ferry_NC', label: 'Sneads Ferry, NC' },
  { value: 'Sneedville_TN', label: 'Sneedville, TN' },
  { value: 'Snelling_CA', label: 'Snelling, CA' },
  { value: 'Snellville_GA', label: 'Snellville, GA' },
  { value: 'Snohomish_WA', label: 'Snohomish, WA' },
  { value: 'Snook_TX', label: 'Snook, TX' },
  { value: 'Snoqualmie_WA', label: 'Snoqualmie, WA' },
  { value: 'Snoqualmie Pass_WA', label: 'Snoqualmie Pass, WA' },
  { value: 'Snover_MI', label: 'Snover, MI' },
  { value: 'Snow_OK', label: 'Snow, OK' },
  { value: 'Snow Camp_NC', label: 'Snow Camp, NC' },
  { value: 'Snow Hill_MD', label: 'Snow Hill, MD' },
  { value: 'Snow Hill_NC', label: 'Snow Hill, NC' },
  { value: 'Snow Lake_AR', label: 'Snow Lake, AR' },
  { value: 'Snow Shoe_PA', label: 'Snow Shoe, PA' },
  { value: 'Snowflake_AZ', label: 'Snowflake, AZ' },
  { value: 'Snowmass_CO', label: 'Snowmass, CO' },
  { value: 'Snowmass Village_CO', label: 'Snowmass Village, CO' },
  { value: 'Snowshoe_WV', label: 'Snowshoe, WV' },
  { value: 'Snowville_UT', label: 'Snowville, UT' },
  { value: 'Snyder_NE', label: 'Snyder, NE' },
  { value: 'Snyder_OK', label: 'Snyder, OK' },
  { value: 'Snyder_TX', label: 'Snyder, TX' },
  { value: 'Snyder_CO', label: 'Snyder, CO' },
  { value: 'Soap Lake_WA', label: 'Soap Lake, WA' },
  { value: 'Sobieski_WI', label: 'Sobieski, WI' },
  { value: 'Social Circle_GA', label: 'Social Circle, GA' },
  { value: 'Society Hill_SC', label: 'Society Hill, SC' },
  { value: 'Socorro_NM', label: 'Socorro, NM' },
  { value: 'Sod_WV', label: 'Sod, WV' },
  { value: 'Soda Springs_ID', label: 'Soda Springs, ID' },
  { value: 'Soda Springs_CA', label: 'Soda Springs, CA' },
  { value: 'Soddy Daisy_TN', label: 'Soddy Daisy, TN' },
  { value: 'Sodus_NY', label: 'Sodus, NY' },
  { value: 'Sodus_MI', label: 'Sodus, MI' },
  { value: 'Sodus Point_NY', label: 'Sodus Point, NY' },
  { value: 'Solana Beach_CA', label: 'Solana Beach, CA' },
  { value: 'Solano_NM', label: 'Solano, NM' },
  { value: 'Soldier_IA', label: 'Soldier, IA' },
  { value: 'Soldier_KS', label: 'Soldier, KS' },
  { value: 'Soldiers Grove_WI', label: 'Soldiers Grove, WI' },
  { value: 'Soldotna_AK', label: 'Soldotna, AK' },
  { value: 'Soledad_CA', label: 'Soledad, CA' },
  { value: 'Solen_ND', label: 'Solen, ND' },
  { value: 'Solgohachia_AR', label: 'Solgohachia, AR' },
  { value: 'Solo_MO', label: 'Solo, MO' },
  { value: 'Solomon_KS', label: 'Solomon, KS' },
  { value: 'Solomon_AZ', label: 'Solomon, AZ' },
  { value: 'Solomons_MD', label: 'Solomons, MD' },
  { value: 'Solon_ME', label: 'Solon, ME' },
  { value: 'Solon_OH', label: 'Solon, OH' },
  { value: 'Solon_IA', label: 'Solon, IA' },
  { value: 'Solon Springs_WI', label: 'Solon Springs, WI' },
  { value: 'Solsberry_IN', label: 'Solsberry, IN' },
  { value: 'Solvang_CA', label: 'Solvang, CA' },
  { value: 'Solway_MN', label: 'Solway, MN' },
  { value: 'Somerdale_NJ', label: 'Somerdale, NJ' },
  { value: 'Somerdale_OH', label: 'Somerdale, OH' },
  { value: 'Somers_CT', label: 'Somers, CT' },
  { value: 'Somers_NY', label: 'Somers, NY' },
  { value: 'Somers_IA', label: 'Somers, IA' },
  { value: 'Somers_MT', label: 'Somers, MT' },
  { value: 'Somers Point_NJ', label: 'Somers Point, NJ' },
  { value: 'Somerset_MA', label: 'Somerset, MA' },
  { value: 'Somerset_NJ', label: 'Somerset, NJ' },
  { value: 'Somerset_PA', label: 'Somerset, PA' },
  { value: 'Somerset_VA', label: 'Somerset, VA' },
  { value: 'Somerset_KY', label: 'Somerset, KY' },
  { value: 'Somerset_OH', label: 'Somerset, OH' },
  { value: 'Somerset_IN', label: 'Somerset, IN' },
  { value: 'Somerset_WI', label: 'Somerset, WI' },
  { value: 'Somerset_TX', label: 'Somerset, TX' },
  { value: 'Somerset_CO', label: 'Somerset, CO' },
  { value: 'Somerset_CA', label: 'Somerset, CA' },
  { value: 'Somerset Center_MI', label: 'Somerset Center, MI' },
  { value: 'Somersville_CT', label: 'Somersville, CT' },
  { value: 'Somersworth_NH', label: 'Somersworth, NH' },
  { value: 'Somerton_AZ', label: 'Somerton, AZ' },
  { value: 'Somerville_MA', label: 'Somerville, MA' },
  { value: 'Somerville_NJ', label: 'Somerville, NJ' },
  { value: 'Somerville_AL', label: 'Somerville, AL' },
  { value: 'Somerville_TN', label: 'Somerville, TN' },
  { value: 'Somerville_OH', label: 'Somerville, OH' },
  { value: 'Somerville_IN', label: 'Somerville, IN' },
  { value: 'Somerville_TX', label: 'Somerville, TX' },
  { value: 'Somes Bar_CA', label: 'Somes Bar, CA' },
  { value: 'Somis_CA', label: 'Somis, CA' },
  { value: 'Somonauk_IL', label: 'Somonauk, IL' },
  { value: 'Sondheimer_LA', label: 'Sondheimer, LA' },
  { value: 'Sonoita_AZ', label: 'Sonoita, AZ' },
  { value: 'Sonoma_CA', label: 'Sonoma, CA' },
  { value: 'Sonora_KY', label: 'Sonora, KY' },
  { value: 'Sonora_TX', label: 'Sonora, TX' },
  { value: 'Sonora_CA', label: 'Sonora, CA' },
  { value: 'Sontag_MS', label: 'Sontag, MS' },
  { value: 'Sonyea_NY', label: 'Sonyea, NY' },
  { value: 'Sopchoppy_FL', label: 'Sopchoppy, FL' },
  { value: 'Soper_OK', label: 'Soper, OK' },
  { value: 'Soperton_GA', label: 'Soperton, GA' },
  { value: 'Sophia_WV', label: 'Sophia, WV' },
  { value: 'Sophia_NC', label: 'Sophia, NC' },
  { value: 'Soquel_CA', label: 'Soquel, CA' },
  { value: 'Sorento_IL', label: 'Sorento, IL' },
  { value: 'Sorrento_ME', label: 'Sorrento, ME' },
  { value: 'Sorrento_FL', label: 'Sorrento, FL' },
  { value: 'Sorrento_LA', label: 'Sorrento, LA' },
  { value: 'Soso_MS', label: 'Soso, MS' },
  { value: 'Soudan_MN', label: 'Soudan, MN' },
  { value: 'Souderton_PA', label: 'Souderton, PA' },
  { value: 'Soulsbyville_CA', label: 'Soulsbyville, CA' },
  { value: 'Sound Beach_NY', label: 'Sound Beach, NY' },
  { value: 'Sour Lake_TX', label: 'Sour Lake, TX' },
  { value: 'Souris_ND', label: 'Souris, ND' },
  { value: 'South Acworth_NH', label: 'South Acworth, NH' },
  { value: 'South Amana_IA', label: 'South Amana, IA' },
  { value: 'South Amboy_NJ', label: 'South Amboy, NJ' },
  { value: 'South Barre_MA', label: 'South Barre, MA' },
  { value: 'South Barre_VT', label: 'South Barre, VT' },
  { value: 'South Bay_FL', label: 'South Bay, FL' },
  { value: 'South Beach_OR', label: 'South Beach, OR' },
  { value: 'South Beloit_IL', label: 'South Beloit, IL' },
  { value: 'South Bend_IN', label: 'South Bend, IN' },
  { value: 'South Bend_NE', label: 'South Bend, NE' },
  { value: 'South Bend_TX', label: 'South Bend, TX' },
  { value: 'South Bend_WA', label: 'South Bend, WA' },
  { value: 'South Berwick_ME', label: 'South Berwick, ME' },
  { value: 'South Bethlehem_NY', label: 'South Bethlehem, NY' },
  { value: 'South Bloomingville_OH', label: 'South Bloomingville, OH' },
  { value: 'South Boardman_MI', label: 'South Boardman, MI' },
  { value: 'South Boston_MA', label: 'South Boston, MA' },
  { value: 'South Boston_VA', label: 'South Boston, VA' },
  { value: 'South Bound Brook_NJ', label: 'South Bound Brook, NJ' },
  { value: 'South Branch_MI', label: 'South Branch, MI' },
  { value: 'South Bristol_ME', label: 'South Bristol, ME' },
  { value: 'South Burlington_VT', label: 'South Burlington, VT' },
  { value: 'South Butler_NY', label: 'South Butler, NY' },
  { value: 'South Byron_NY', label: 'South Byron, NY' },
  { value: 'South Cairo_NY', label: 'South Cairo, NY' },
  { value: 'South Carrollton_KY', label: 'South Carrollton, KY' },
  { value: 'South Carver_MA', label: 'South Carver, MA' },
  { value: 'South Charleston_WV', label: 'South Charleston, WV' },
  { value: 'South Charleston_OH', label: 'South Charleston, OH' },
  { value: 'South Chatham_MA', label: 'South Chatham, MA' },
  { value: 'South China_ME', label: 'South China, ME' },
  { value: 'South Cle Elum_WA', label: 'South Cle Elum, WA' },
  { value: 'South Colton_NY', label: 'South Colton, NY' },
  { value: 'South Dartmouth_MA', label: 'South Dartmouth, MA' },
  { value: 'South Dayton_NY', label: 'South Dayton, NY' },
  { value: 'South Deerfield_MA', label: 'South Deerfield, MA' },
  { value: 'South Dennis_MA', label: 'South Dennis, MA' },
  { value: 'South Dennis_NJ', label: 'South Dennis, NJ' },
  { value: 'South Dos Palos_CA', label: 'South Dos Palos, CA' },
  { value: 'South Easton_MA', label: 'South Easton, MA' },
  { value: 'South Egremont_MA', label: 'South Egremont, MA' },
  { value: 'South El Monte_CA', label: 'South El Monte, CA' },
  { value: 'South Elgin_IL', label: 'South Elgin, IL' },
  { value: 'South English_IA', label: 'South English, IA' },
  { value: 'South Fallsburg_NY', label: 'South Fallsburg, NY' },
  { value: 'South Fork_PA', label: 'South Fork, PA' },
  { value: 'South Fork_CO', label: 'South Fork, CO' },
  { value: 'South Fulton_TN', label: 'South Fulton, TN' },
  { value: 'South Gardiner_ME', label: 'South Gardiner, ME' },
  { value: 'South Gate_CA', label: 'South Gate, CA' },
  { value: 'South Gibson_PA', label: 'South Gibson, PA' },
  { value: 'South Glastonbury_CT', label: 'South Glastonbury, CT' },
  { value: 'South Glens Falls_NY', label: 'South Glens Falls, NY' },
  { value: 'South Grafton_MA', label: 'South Grafton, MA' },
  { value: 'South Greenfield_MO', label: 'South Greenfield, MO' },
  { value: 'South Hackensack_NJ', label: 'South Hackensack, NJ' },
  { value: 'South Hadley_MA', label: 'South Hadley, MA' },
  { value: 'South Hamilton_MA', label: 'South Hamilton, MA' },
  { value: 'South Haven_MI', label: 'South Haven, MI' },
  { value: 'South Haven_MN', label: 'South Haven, MN' },
  { value: 'South Haven_KS', label: 'South Haven, KS' },
  { value: 'South Heart_ND', label: 'South Heart, ND' },
  { value: 'South Heights_PA', label: 'South Heights, PA' },
  { value: 'South Hero_VT', label: 'South Hero, VT' },
  { value: 'South Hill_VA', label: 'South Hill, VA' },
  { value: 'South Holland_IL', label: 'South Holland, IL' },
  { value: 'South Houston_TX', label: 'South Houston, TX' },
  { value: 'South Hutchinson_KS', label: 'South Hutchinson, KS' },
  { value: 'South Jamesport_NY', label: 'South Jamesport, NY' },
  { value: 'South Jordan_UT', label: 'South Jordan, UT' },
  { value: 'South Kent_CT', label: 'South Kent, CT' },
  { value: 'South Kortright_NY', label: 'South Kortright, NY' },
  { value: 'South Lake Tahoe_CA', label: 'South Lake Tahoe, CA' },
  { value: 'South Lancaster_MA', label: 'South Lancaster, MA' },
  { value: 'South Lebanon_OH', label: 'South Lebanon, OH' },
  { value: 'South Lee_MA', label: 'South Lee, MA' },
  { value: 'South Londonderry_VT', label: 'South Londonderry, VT' },
  { value: 'South Lyme_CT', label: 'South Lyme, CT' },
  { value: 'South Lyon_MI', label: 'South Lyon, MI' },
  { value: 'South Milford_IN', label: 'South Milford, IN' },
  { value: 'South Mills_NC', label: 'South Mills, NC' },
  { value: 'South Milwaukee_WI', label: 'South Milwaukee, WI' },
  { value: 'South Montrose_PA', label: 'South Montrose, PA' },
  { value: 'South Mountain_PA', label: 'South Mountain, PA' },
  { value: 'South Naknek_AK', label: 'South Naknek, AK' },
  { value: 'South New Berlin_NY', label: 'South New Berlin, NY' },
  { value: 'South Newfane_VT', label: 'South Newfane, VT' },
  { value: 'South Orange_NJ', label: 'South Orange, NJ' },
  { value: 'South Otselic_NY', label: 'South Otselic, NY' },
  { value: 'South Ozone Park_NY', label: 'South Ozone Park, NY' },
  { value: 'South Padre Island_TX', label: 'South Padre Island, TX' },
  { value: 'South Paris_ME', label: 'South Paris, ME' },
  { value: 'South Park_PA', label: 'South Park, PA' },
  { value: 'South Pasadena_CA', label: 'South Pasadena, CA' },
  { value: 'South Pekin_IL', label: 'South Pekin, IL' },
  { value: 'South Pittsburg_TN', label: 'South Pittsburg, TN' },
  { value: 'South Plainfield_NJ', label: 'South Plainfield, NJ' },
  { value: 'South Plains_TX', label: 'South Plains, TX' },
  { value: 'South Plymouth_NY', label: 'South Plymouth, NY' },
  { value: 'South Point_OH', label: 'South Point, OH' },
  { value: 'South Pomfret_VT', label: 'South Pomfret, VT' },
  { value: 'South Portland_ME', label: 'South Portland, ME' },
  { value: 'South Portsmouth_KY', label: 'South Portsmouth, KY' },
  { value: 'South Prairie_WA', label: 'South Prairie, WA' },
  { value: 'South Range_MI', label: 'South Range, MI' },
  { value: 'South Range_WI', label: 'South Range, WI' },
  { value: 'South Richmond Hill_NY', label: 'South Richmond Hill, NY' },
  { value: 'South River_NJ', label: 'South River, NJ' },
  { value: 'South Rockwood_MI', label: 'South Rockwood, MI' },
  { value: 'South Roxana_IL', label: 'South Roxana, IL' },
  { value: 'South Royalton_VT', label: 'South Royalton, VT' },
  { value: 'South Ryegate_VT', label: 'South Ryegate, VT' },
  { value: 'South St Paul_MN', label: 'South St Paul, MN' },
  { value: 'South Salem_NY', label: 'South Salem, NY' },
  { value: 'South Salem_OH', label: 'South Salem, OH' },
  { value: 'South San Francisco_CA', label: 'South San Francisco, CA' },
  { value: 'South Seaville_NJ', label: 'South Seaville, NJ' },
  { value: 'South Shore_KY', label: 'South Shore, KY' },
  { value: 'South Shore_SD', label: 'South Shore, SD' },
  { value: 'South Sioux City_NE', label: 'South Sioux City, NE' },
  { value: 'South Solon_OH', label: 'South Solon, OH' },
  { value: 'South Sterling_PA', label: 'South Sterling, PA' },
  { value: 'South Strafford_VT', label: 'South Strafford, VT' },
  { value: 'South Sutton_NH', label: 'South Sutton, NH' },
  { value: 'South Tamworth_NH', label: 'South Tamworth, NH' },
  { value: 'South Thomaston_ME', label: 'South Thomaston, ME' },
  { value: 'South Vienna_OH', label: 'South Vienna, OH' },
  { value: 'South Wales_NY', label: 'South Wales, NY' },
  { value: 'South Walpole_MA', label: 'South Walpole, MA' },
  { value: 'South Wayne_WI', label: 'South Wayne, WI' },
  { value: 'South Webster_OH', label: 'South Webster, OH' },
  { value: 'South Wellfleet_MA', label: 'South Wellfleet, MA' },
  { value: 'South West City_MO', label: 'South West City, MO' },
  { value: 'South Weymouth_MA', label: 'South Weymouth, MA' },
  { value: 'South Whitley_IN', label: 'South Whitley, IN' },
  { value: 'South Williamson_KY', label: 'South Williamson, KY' },
  { value: 'South Wilmington_IL', label: 'South Wilmington, IL' },
  { value: 'South Windham_CT', label: 'South Windham, CT' },
  { value: 'South Windsor_CT', label: 'South Windsor, CT' },
  { value: 'South Woodstock_VT', label: 'South Woodstock, VT' },
  { value: 'South Yarmouth_MA', label: 'South Yarmouth, MA' },
  { value: 'Southampton_MA', label: 'Southampton, MA' },
  { value: 'Southampton_NY', label: 'Southampton, NY' },
  { value: 'Southampton_PA', label: 'Southampton, PA' },
  { value: 'Southard_OK', label: 'Southard, OK' },
  { value: 'Southaven_MS', label: 'Southaven, MS' },
  { value: 'Southborough_MA', label: 'Southborough, MA' },
  { value: 'Southbridge_MA', label: 'Southbridge, MA' },
  { value: 'Southbury_CT', label: 'Southbury, CT' },
  { value: 'Southern Pines_NC', label: 'Southern Pines, NC' },
  { value: 'Southfield_MA', label: 'Southfield, MA' },
  { value: 'Southfield_MI', label: 'Southfield, MI' },
  { value: 'Southfields_NY', label: 'Southfields, NY' },
  { value: 'Southgate_MI', label: 'Southgate, MI' },
  { value: 'Southington_CT', label: 'Southington, CT' },
  { value: 'Southington_OH', label: 'Southington, OH' },
  { value: 'Southlake_TX', label: 'Southlake, TX' },
  { value: 'Southmayd_TX', label: 'Southmayd, TX' },
  { value: 'Southmont_NC', label: 'Southmont, NC' },
  { value: 'Southold_NY', label: 'Southold, NY' },
  { value: 'Southport_ME', label: 'Southport, ME' },
  { value: 'Southport_CT', label: 'Southport, CT' },
  { value: 'Southport_NC', label: 'Southport, NC' },
  { value: 'Southside_WV', label: 'Southside, WV' },
  { value: 'Southside_TN', label: 'Southside, TN' },
  { value: 'Southview_PA', label: 'Southview, PA' },
  { value: 'Southwest Harbor_ME', label: 'Southwest Harbor, ME' },
  { value: 'Southwick_MA', label: 'Southwick, MA' },
  { value: 'Spade_TX', label: 'Spade, TX' },
  { value: 'Spalding_MI', label: 'Spalding, MI' },
  { value: 'Spalding_NE', label: 'Spalding, NE' },
  { value: 'Spanaway_WA', label: 'Spanaway, WA' },
  { value: 'Spangle_WA', label: 'Spangle, WA' },
  { value: 'Spangler_PA', label: 'Spangler, PA' },
  { value: 'Spanish Fork_UT', label: 'Spanish Fork, UT' },
  { value: 'Spanishburg_WV', label: 'Spanishburg, WV' },
  { value: 'Sparkill_NY', label: 'Sparkill, NY' },
  { value: 'Sparkman_AR', label: 'Sparkman, AR' },
  { value: 'Sparks_GA', label: 'Sparks, GA' },
  { value: 'Sparks_NE', label: 'Sparks, NE' },
  { value: 'Sparks_OK', label: 'Sparks, OK' },
  { value: 'Sparks_NV', label: 'Sparks, NV' },
  { value: 'Sparks Glencoe_MD', label: 'Sparks Glencoe, MD' },
  { value: 'Sparland_IL', label: 'Sparland, IL' },
  { value: 'Sparr_FL', label: 'Sparr, FL' },
  { value: 'Sparrow Bush_NY', label: 'Sparrow Bush, NY' },
  { value: 'Sparrows Point_MD', label: 'Sparrows Point, MD' },
  { value: 'Sparta_NJ', label: 'Sparta, NJ' },
  { value: 'Sparta_NC', label: 'Sparta, NC' },
  { value: 'Sparta_GA', label: 'Sparta, GA' },
  { value: 'Sparta_TN', label: 'Sparta, TN' },
  { value: 'Sparta_KY', label: 'Sparta, KY' },
  { value: 'Sparta_OH', label: 'Sparta, OH' },
  { value: 'Sparta_MI', label: 'Sparta, MI' },
  { value: 'Sparta_WI', label: 'Sparta, WI' },
  { value: 'Sparta_IL', label: 'Sparta, IL' },
  { value: 'Sparta_MO', label: 'Sparta, MO' },
  { value: 'Spartanburg_SC', label: 'Spartanburg, SC' },
  { value: 'Spartansburg_PA', label: 'Spartansburg, PA' },
  { value: 'Spavinaw_OK', label: 'Spavinaw, OK' },
  { value: 'Spearfish_SD', label: 'Spearfish, SD' },
  { value: 'Spearman_TX', label: 'Spearman, TX' },
  { value: 'Spearsville_LA', label: 'Spearsville, LA' },
  { value: 'Spearville_KS', label: 'Spearville, KS' },
  { value: 'Speculator_NY', label: 'Speculator, NY' },
  { value: 'Speed_NC', label: 'Speed, NC' },
  { value: 'Speedwell_VA', label: 'Speedwell, VA' },
  { value: 'Speedwell_TN', label: 'Speedwell, TN' },
  { value: 'Speer_IL', label: 'Speer, IL' },
  { value: 'Spelter_WV', label: 'Spelter, WV' },
  { value: 'Spencer_MA', label: 'Spencer, MA' },
  { value: 'Spencer_NY', label: 'Spencer, NY' },
  { value: 'Spencer_VA', label: 'Spencer, VA' },
  { value: 'Spencer_WV', label: 'Spencer, WV' },
  { value: 'Spencer_NC', label: 'Spencer, NC' },
  { value: 'Spencer_TN', label: 'Spencer, TN' },
  { value: 'Spencer_OH', label: 'Spencer, OH' },
  { value: 'Spencer_IN', label: 'Spencer, IN' },
  { value: 'Spencer_IA', label: 'Spencer, IA' },
  { value: 'Spencer_WI', label: 'Spencer, WI' },
  { value: 'Spencer_SD', label: 'Spencer, SD' },
  { value: 'Spencer_NE', label: 'Spencer, NE' },
  { value: 'Spencer_OK', label: 'Spencer, OK' },
  { value: 'Spencer_ID', label: 'Spencer, ID' },
  { value: 'Spencerport_NY', label: 'Spencerport, NY' },
  { value: 'Spencertown_NY', label: 'Spencertown, NY' },
  { value: 'Spencerville_MD', label: 'Spencerville, MD' },
  { value: 'Spencerville_OH', label: 'Spencerville, OH' },
  { value: 'Spencerville_IN', label: 'Spencerville, IN' },
  { value: 'Spencerville_OK', label: 'Spencerville, OK' },
  { value: 'Speonk_NY', label: 'Speonk, NY' },
  { value: 'Sperry_IA', label: 'Sperry, IA' },
  { value: 'Sperry_OK', label: 'Sperry, OK' },
  { value: 'Sperryville_VA', label: 'Sperryville, VA' },
  { value: 'Spiceland_IN', label: 'Spiceland, IN' },
  { value: 'Spicer_MN', label: 'Spicer, MN' },
  { value: 'Spicewood_TX', label: 'Spicewood, TX' },
  { value: 'Spickard_MO', label: 'Spickard, MO' },
  { value: 'Spillville_IA', label: 'Spillville, IA' },
  { value: 'Spindale_NC', label: 'Spindale, NC' },
  { value: 'Spirit Lake_IA', label: 'Spirit Lake, IA' },
  { value: 'Spirit Lake_ID', label: 'Spirit Lake, ID' },
  { value: 'Spiritwood_ND', label: 'Spiritwood, ND' },
  { value: 'Spiro_OK', label: 'Spiro, OK' },
  { value: 'Spivey_KS', label: 'Spivey, KS' },
  { value: 'Splendora_TX', label: 'Splendora, TX' },
  { value: 'Spofford_NH', label: 'Spofford, NH' },
  { value: 'Spokane_MO', label: 'Spokane, MO' },
  { value: 'Spokane_WA', label: 'Spokane, WA' },
  { value: 'Spooner_WI', label: 'Spooner, WI' },
  { value: 'Spotswood_NJ', label: 'Spotswood, NJ' },
  { value: 'Spotsylvania_VA', label: 'Spotsylvania, VA' },
  { value: 'Spottsville_KY', label: 'Spottsville, KY' },
  { value: 'Spout Spring_VA', label: 'Spout Spring, VA' },
  { value: 'Spraggs_PA', label: 'Spraggs, PA' },
  { value: 'Sprague_NE', label: 'Sprague, NE' },
  { value: 'Sprague_WA', label: 'Sprague, WA' },
  { value: 'Sprague River_OR', label: 'Sprague River, OR' },
  { value: 'Spragueville_IA', label: 'Spragueville, IA' },
  { value: 'Sprakers_NY', label: 'Sprakers, NY' },
  { value: 'Sprankle Mills_PA', label: 'Sprankle Mills, PA' },
  { value: 'Spray_OR', label: 'Spray, OR' },
  { value: 'Spreckels_CA', label: 'Spreckels, CA' },
  { value: 'Spring_TX', label: 'Spring, TX' },
  { value: 'Spring Arbor_MI', label: 'Spring Arbor, MI' },
  { value: 'Spring Branch_TX', label: 'Spring Branch, TX' },
  { value: 'Spring Church_PA', label: 'Spring Church, PA' },
  { value: 'Spring City_PA', label: 'Spring City, PA' },
  { value: 'Spring City_TN', label: 'Spring City, TN' },
  { value: 'Spring City_UT', label: 'Spring City, UT' },
  { value: 'Spring Creek_PA', label: 'Spring Creek, PA' },
  { value: 'Spring Creek_NV', label: 'Spring Creek, NV' },
  { value: 'Spring Dale_WV', label: 'Spring Dale, WV' },
  { value: 'Spring Gap_MD', label: 'Spring Gap, MD' },
  { value: 'Spring Glen_NY', label: 'Spring Glen, NY' },
  { value: 'Spring Glen_PA', label: 'Spring Glen, PA' },
  { value: 'Spring Green_WI', label: 'Spring Green, WI' },
  { value: 'Spring Grove_PA', label: 'Spring Grove, PA' },
  { value: 'Spring Grove_VA', label: 'Spring Grove, VA' },
  { value: 'Spring Grove_MN', label: 'Spring Grove, MN' },
  { value: 'Spring Grove_IL', label: 'Spring Grove, IL' },
  { value: 'Spring Hill_FL', label: 'Spring Hill, FL' },
  { value: 'Spring Hill_TN', label: 'Spring Hill, TN' },
  { value: 'Spring Hill_KS', label: 'Spring Hill, KS' },
  { value: 'Spring Hope_NC', label: 'Spring Hope, NC' },
  { value: 'Spring House_PA', label: 'Spring House, PA' },
  { value: 'Spring Lake_NJ', label: 'Spring Lake, NJ' },
  { value: 'Spring Lake_NC', label: 'Spring Lake, NC' },
  { value: 'Spring Lake_MI', label: 'Spring Lake, MI' },
  { value: 'Spring Lake_MN', label: 'Spring Lake, MN' },
  { value: 'Spring Mills_PA', label: 'Spring Mills, PA' },
  { value: 'Spring Park_MN', label: 'Spring Park, MN' },
  { value: 'Spring Run_PA', label: 'Spring Run, PA' },
  { value: 'Spring Valley_NY', label: 'Spring Valley, NY' },
  { value: 'Spring Valley_OH', label: 'Spring Valley, OH' },
  { value: 'Spring Valley_WI', label: 'Spring Valley, WI' },
  { value: 'Spring Valley_MN', label: 'Spring Valley, MN' },
  { value: 'Spring Valley_IL', label: 'Spring Valley, IL' },
  { value: 'Spring Valley_CA', label: 'Spring Valley, CA' },
  { value: 'Springboro_PA', label: 'Springboro, PA' },
  { value: 'Springboro_OH', label: 'Springboro, OH' },
  { value: 'Springbrook_IA', label: 'Springbrook, IA' },
  { value: 'Springbrook_WI', label: 'Springbrook, WI' },
  { value: 'Springdale_PA', label: 'Springdale, PA' },
  { value: 'Springdale_MT', label: 'Springdale, MT' },
  { value: 'Springdale_AR', label: 'Springdale, AR' },
  { value: 'Springdale_UT', label: 'Springdale, UT' },
  { value: 'Springdale_WA', label: 'Springdale, WA' },
  { value: 'Springer_OK', label: 'Springer, OK' },
  { value: 'Springer_NM', label: 'Springer, NM' },
  { value: 'Springerton_IL', label: 'Springerton, IL' },
  { value: 'Springerville_AZ', label: 'Springerville, AZ' },
  { value: 'Springfield_MA', label: 'Springfield, MA' },
  { value: 'Springfield_NH', label: 'Springfield, NH' },
  { value: 'Springfield_ME', label: 'Springfield, ME' },
  { value: 'Springfield_VT', label: 'Springfield, VT' },
  { value: 'Springfield_NJ', label: 'Springfield, NJ' },
  { value: 'Springfield_PA', label: 'Springfield, PA' },
  { value: 'Springfield_VA', label: 'Springfield, VA' },
  { value: 'Springfield_WV', label: 'Springfield, WV' },
  { value: 'Springfield_SC', label: 'Springfield, SC' },
  { value: 'Springfield_GA', label: 'Springfield, GA' },
  { value: 'Springfield_TN', label: 'Springfield, TN' },
  { value: 'Springfield_KY', label: 'Springfield, KY' },
  { value: 'Springfield_OH', label: 'Springfield, OH' },
  { value: 'Springfield_WI', label: 'Springfield, WI' },
  { value: 'Springfield_MN', label: 'Springfield, MN' },
  { value: 'Springfield_SD', label: 'Springfield, SD' },
  { value: 'Springfield_IL', label: 'Springfield, IL' },
  { value: 'Springfield_MO', label: 'Springfield, MO' },
  { value: 'Springfield_NE', label: 'Springfield, NE' },
  { value: 'Springfield_LA', label: 'Springfield, LA' },
  { value: 'Springfield_AR', label: 'Springfield, AR' },
  { value: 'Springfield_CO', label: 'Springfield, CO' },
  { value: 'Springfield_ID', label: 'Springfield, ID' },
  { value: 'Springfield_OR', label: 'Springfield, OR' },
  { value: 'Springfield Center_NY', label: 'Springfield Center, NY' },
  { value: 'Springfield Gardens_NY', label: 'Springfield Gardens, NY' },
  { value: 'Springhill_LA', label: 'Springhill, LA' },
  { value: 'Springlake_TX', label: 'Springlake, TX' },
  { value: 'Springport_IN', label: 'Springport, IN' },
  { value: 'Springport_MI', label: 'Springport, MI' },
  { value: 'Springs_PA', label: 'Springs, PA' },
  { value: 'Springtown_PA', label: 'Springtown, PA' },
  { value: 'Springtown_TX', label: 'Springtown, TX' },
  { value: 'Springvale_ME', label: 'Springvale, ME' },
  { value: 'Springview_NE', label: 'Springview, NE' },
  { value: 'Springville_NY', label: 'Springville, NY' },
  { value: 'Springville_PA', label: 'Springville, PA' },
  { value: 'Springville_AL', label: 'Springville, AL' },
  { value: 'Springville_TN', label: 'Springville, TN' },
  { value: 'Springville_IN', label: 'Springville, IN' },
  { value: 'Springville_IA', label: 'Springville, IA' },
  { value: 'Springville_UT', label: 'Springville, UT' },
  { value: 'Springville_CA', label: 'Springville, CA' },
  { value: 'Springwater_NY', label: 'Springwater, NY' },
  { value: 'Sproul_PA', label: 'Sproul, PA' },
  { value: 'Spruce_MI', label: 'Spruce, MI' },
  { value: 'Spruce Creek_PA', label: 'Spruce Creek, PA' },
  { value: 'Spruce Head_ME', label: 'Spruce Head, ME' },
  { value: 'Spruce Pine_NC', label: 'Spruce Pine, NC' },
  { value: 'Spruce Pine_AL', label: 'Spruce Pine, AL' },
  { value: 'Spur_TX', label: 'Spur, TX' },
  { value: 'Spurgeon_IN', label: 'Spurgeon, IN' },
  { value: 'Spurger_TX', label: 'Spurger, TX' },
  { value: 'Spurlockville_WV', label: 'Spurlockville, WV' },
  { value: 'Squaw Lake_MN', label: 'Squaw Lake, MN' },
  { value: 'Squaw Valley_CA', label: 'Squaw Valley, CA' },
  { value: 'Squire_WV', label: 'Squire, WV' },
  { value: 'Squires_MO', label: 'Squires, MO' },
  { value: 'Squirrel Island_ME', label: 'Squirrel Island, ME' },
  { value: 'St Columbans_NE', label: 'St Columbans, NE' },
  { value: 'St John_VI', label: 'St John, VI' },
  { value: 'St John_KS', label: 'St John, KS' },
  { value: 'St Thomas_VI', label: 'St Thomas, VI' },
  { value: 'Staatsburg_NY', label: 'Staatsburg, NY' },
  { value: 'Stacy_NC', label: 'Stacy, NC' },
  { value: 'Stacy_MN', label: 'Stacy, MN' },
  { value: 'Stacyville_ME', label: 'Stacyville, ME' },
  { value: 'Stacyville_IA', label: 'Stacyville, IA' },
  { value: 'Stafford_NY', label: 'Stafford, NY' },
  { value: 'Stafford_VA', label: 'Stafford, VA' },
  { value: 'Stafford_OH', label: 'Stafford, OH' },
  { value: 'Stafford_KS', label: 'Stafford, KS' },
  { value: 'Stafford_TX', label: 'Stafford, TX' },
  { value: 'Stafford Springs_CT', label: 'Stafford Springs, CT' },
  { value: 'Staffordsville_VA', label: 'Staffordsville, VA' },
  { value: 'Staffordsville_KY', label: 'Staffordsville, KY' },
  { value: 'Stahlstown_PA', label: 'Stahlstown, PA' },
  { value: 'Staley_NC', label: 'Staley, NC' },
  { value: 'Stambaugh_KY', label: 'Stambaugh, KY' },
  { value: 'Stamford_VT', label: 'Stamford, VT' },
  { value: 'Stamford_CT', label: 'Stamford, CT' },
  { value: 'Stamford_NY', label: 'Stamford, NY' },
  { value: 'Stamford_NE', label: 'Stamford, NE' },
  { value: 'Stamford_TX', label: 'Stamford, TX' },
  { value: 'Stamping Ground_KY', label: 'Stamping Ground, KY' },
  { value: 'Stamps_AR', label: 'Stamps, AR' },
  { value: 'Stanardsville_VA', label: 'Stanardsville, VA' },
  { value: 'Stanberry_MO', label: 'Stanberry, MO' },
  { value: 'Stanchfield_MN', label: 'Stanchfield, MN' },
  { value: 'Standard_IL', label: 'Standard, IL' },
  { value: 'Standish_ME', label: 'Standish, ME' },
  { value: 'Standish_MI', label: 'Standish, MI' },
  { value: 'Standish_CA', label: 'Standish, CA' },
  { value: 'Stanfield_NC', label: 'Stanfield, NC' },
  { value: 'Stanfield_AZ', label: 'Stanfield, AZ' },
  { value: 'Stanfield_OR', label: 'Stanfield, OR' },
  { value: 'Stanford_KY', label: 'Stanford, KY' },
  { value: 'Stanford_MT', label: 'Stanford, MT' },
  { value: 'Stanford_IL', label: 'Stanford, IL' },
  { value: 'Stanford_CA', label: 'Stanford, CA' },
  { value: 'Stanfordville_NY', label: 'Stanfordville, NY' },
  { value: 'Stanhope_NJ', label: 'Stanhope, NJ' },
  { value: 'Stanhope_IA', label: 'Stanhope, IA' },
  { value: 'Stanley_NY', label: 'Stanley, NY' },
  { value: 'Stanley_VA', label: 'Stanley, VA' },
  { value: 'Stanley_NC', label: 'Stanley, NC' },
  { value: 'Stanley_IA', label: 'Stanley, IA' },
  { value: 'Stanley_WI', label: 'Stanley, WI' },
  { value: 'Stanley_ND', label: 'Stanley, ND' },
  { value: 'Stanley_ID', label: 'Stanley, ID' },
  { value: 'Stanley_NM', label: 'Stanley, NM' },
  { value: 'Stanleytown_VA', label: 'Stanleytown, VA' },
  { value: 'Stanton_AL', label: 'Stanton, AL' },
  { value: 'Stanton_TN', label: 'Stanton, TN' },
  { value: 'Stanton_KY', label: 'Stanton, KY' },
  { value: 'Stanton_MI', label: 'Stanton, MI' },
  { value: 'Stanton_IA', label: 'Stanton, IA' },
  { value: 'Stanton_ND', label: 'Stanton, ND' },
  { value: 'Stanton_MO', label: 'Stanton, MO' },
  { value: 'Stanton_NE', label: 'Stanton, NE' },
  { value: 'Stanton_TX', label: 'Stanton, TX' },
  { value: 'Stanton_CA', label: 'Stanton, CA' },
  { value: 'Stantonsburg_NC', label: 'Stantonsburg, NC' },
  { value: 'Stantonville_TN', label: 'Stantonville, TN' },
  { value: 'Stanville_KY', label: 'Stanville, KY' },
  { value: 'Stanwood_MI', label: 'Stanwood, MI' },
  { value: 'Stanwood_IA', label: 'Stanwood, IA' },
  { value: 'Stanwood_WA', label: 'Stanwood, WA' },
  { value: 'Staplehurst_NE', label: 'Staplehurst, NE' },
  { value: 'Staples_MN', label: 'Staples, MN' },
  { value: 'Staples_TX', label: 'Staples, TX' },
  { value: 'Stapleton_GA', label: 'Stapleton, GA' },
  { value: 'Stapleton_AL', label: 'Stapleton, AL' },
  { value: 'Stapleton_NE', label: 'Stapleton, NE' },
  { value: 'Star_NC', label: 'Star, NC' },
  { value: 'Star_MS', label: 'Star, MS' },
  { value: 'Star_TX', label: 'Star, TX' },
  { value: 'Star_ID', label: 'Star, ID' },
  { value: 'Star City_IN', label: 'Star City, IN' },
  { value: 'Star City_AR', label: 'Star City, AR' },
  { value: 'Star Junction_PA', label: 'Star Junction, PA' },
  { value: 'Star Lake_NY', label: 'Star Lake, NY' },
  { value: 'Star Lake_WI', label: 'Star Lake, WI' },
  { value: 'Star Prairie_WI', label: 'Star Prairie, WI' },
  { value: 'Star Tannery_VA', label: 'Star Tannery, VA' },
  { value: 'Starbuck_MN', label: 'Starbuck, MN' },
  { value: 'Starbuck_WA', label: 'Starbuck, WA' },
  { value: 'Starford_PA', label: 'Starford, PA' },
  { value: 'Stark_KS', label: 'Stark, KS' },
  { value: 'Stark City_MO', label: 'Stark City, MO' },
  { value: 'Starke_FL', label: 'Starke, FL' },
  { value: 'Starks_LA', label: 'Starks, LA' },
  { value: 'Starksboro_VT', label: 'Starksboro, VT' },
  { value: 'Starkville_MS', label: 'Starkville, MS' },
  { value: 'Starkweather_ND', label: 'Starkweather, ND' },
  { value: 'Starlight_PA', label: 'Starlight, PA' },
  { value: 'Starr_SC', label: 'Starr, SC' },
  { value: 'Starrucca_PA', label: 'Starrucca, PA' },
  { value: 'Start_LA', label: 'Start, LA' },
  { value: 'Startex_SC', label: 'Startex, SC' },
  { value: 'State Center_IA', label: 'State Center, IA' },
  { value: 'State College_PA', label: 'State College, PA' },
  { value: 'State Farm_VA', label: 'State Farm, VA' },
  { value: 'State Line_PA', label: 'State Line, PA' },
  { value: 'State Line_MS', label: 'State Line, MS' },
  { value: 'State Line_IN', label: 'State Line, IN' },
  { value: 'State Road_NC', label: 'State Road, NC' },
  { value: 'State University_AR', label: 'State University, AR' },
  { value: 'Stateline_NV', label: 'Stateline, NV' },
  { value: 'Staten Island_NY', label: 'Staten Island, NY' },
  { value: 'Statenville_GA', label: 'Statenville, GA' },
  { value: 'Statesboro_GA', label: 'Statesboro, GA' },
  { value: 'Statesville_NC', label: 'Statesville, NC' },
  { value: 'Statham_GA', label: 'Statham, GA' },
  { value: 'Staunton_VA', label: 'Staunton, VA' },
  { value: 'Staunton_IN', label: 'Staunton, IN' },
  { value: 'Staunton_IL', label: 'Staunton, IL' },
  { value: 'Stayton_OR', label: 'Stayton, OR' },
  { value: 'Steamboat Rock_IA', label: 'Steamboat Rock, IA' },
  { value: 'Steamboat Springs_CO', label: 'Steamboat Springs, CO' },
  { value: 'Steamburg_NY', label: 'Steamburg, NY' },
  { value: 'Stearns_KY', label: 'Stearns, KY' },
  { value: 'Stebbins_AK', label: 'Stebbins, AK' },
  { value: 'Stedman_NC', label: 'Stedman, NC' },
  { value: 'Steedman_MO', label: 'Steedman, MO' },
  { value: 'Steele_AL', label: 'Steele, AL' },
  { value: 'Steele_KY', label: 'Steele, KY' },
  { value: 'Steele_ND', label: 'Steele, ND' },
  { value: 'Steele_MO', label: 'Steele, MO' },
  { value: 'Steele City_NE', label: 'Steele City, NE' },
  { value: 'Steeles Tavern_VA', label: 'Steeles Tavern, VA' },
  { value: 'Steeleville_IL', label: 'Steeleville, IL' },
  { value: 'Steelville_MO', label: 'Steelville, MO' },
  { value: 'Steen_MN', label: 'Steen, MN' },
  { value: 'Steens_MS', label: 'Steens, MS' },
  { value: 'Steep Falls_ME', label: 'Steep Falls, ME' },
  { value: 'Steger_IL', label: 'Steger, IL' },
  { value: 'Stehekin_WA', label: 'Stehekin, WA' },
  { value: 'Steilacoom_WA', label: 'Steilacoom, WA' },
  { value: 'Steinauer_NE', label: 'Steinauer, NE' },
  { value: 'Steinhatchee_FL', label: 'Steinhatchee, FL' },
  { value: 'Stella_NC', label: 'Stella, NC' },
  { value: 'Stella_MO', label: 'Stella, MO' },
  { value: 'Stella_NE', label: 'Stella, NE' },
  { value: 'Stem_NC', label: 'Stem, NC' },
  { value: 'Stendal_IN', label: 'Stendal, IN' },
  { value: 'Stennis Space Center_MS', label: 'Stennis Space Center, MS' },
  { value: 'Stephan_SD', label: 'Stephan, SD' },
  { value: 'Stephen_MN', label: 'Stephen, MN' },
  { value: 'Stephens_GA', label: 'Stephens, GA' },
  { value: 'Stephens_AR', label: 'Stephens, AR' },
  { value: 'Stephens City_VA', label: 'Stephens City, VA' },
  { value: 'Stephenson_VA', label: 'Stephenson, VA' },
  { value: 'Stephenson_WV', label: 'Stephenson, WV' },
  { value: 'Stephenson_MI', label: 'Stephenson, MI' },
  { value: 'Stephensport_KY', label: 'Stephensport, KY' },
  { value: 'Stephentown_NY', label: 'Stephentown, NY' },
  { value: 'Stephenville_TX', label: 'Stephenville, TX' },
  { value: 'Steptoe_WA', label: 'Steptoe, WA' },
  { value: 'Sterling_MA', label: 'Sterling, MA' },
  { value: 'Sterling_CT', label: 'Sterling, CT' },
  { value: 'Sterling_NY', label: 'Sterling, NY' },
  { value: 'Sterling_PA', label: 'Sterling, PA' },
  { value: 'Sterling_VA', label: 'Sterling, VA' },
  { value: 'Sterling_OH', label: 'Sterling, OH' },
  { value: 'Sterling_MI', label: 'Sterling, MI' },
  { value: 'Sterling_ND', label: 'Sterling, ND' },
  { value: 'Sterling_IL', label: 'Sterling, IL' },
  { value: 'Sterling_KS', label: 'Sterling, KS' },
  { value: 'Sterling_NE', label: 'Sterling, NE' },
  { value: 'Sterling_OK', label: 'Sterling, OK' },
  { value: 'Sterling_CO', label: 'Sterling, CO' },
  { value: 'Sterling_UT', label: 'Sterling, UT' },
  { value: 'Sterling_AK', label: 'Sterling, AK' },
  { value: 'Sterling City_TX', label: 'Sterling City, TX' },
  { value: 'Sterling Forest_NY', label: 'Sterling Forest, NY' },
  { value: 'Sterling Heights_MI', label: 'Sterling Heights, MI' },
  { value: 'Sterlington_LA', label: 'Sterlington, LA' },
  { value: 'Sterrett_AL', label: 'Sterrett, AL' },
  { value: 'Stetson_ME', label: 'Stetson, ME' },
  { value: 'Stetsonville_WI', label: 'Stetsonville, WI' },
  { value: 'Steuben_ME', label: 'Steuben, ME' },
  { value: 'Steuben_WI', label: 'Steuben, WI' },
  { value: 'Steubenville_OH', label: 'Steubenville, OH' },
  { value: 'Stevens_PA', label: 'Stevens, PA' },
  { value: 'Stevens Point_WI', label: 'Stevens Point, WI' },
  { value: 'Stevens Village_AK', label: 'Stevens Village, AK' },
  { value: 'Stevensburg_VA', label: 'Stevensburg, VA' },
  { value: 'Stevenson_MD', label: 'Stevenson, MD' },
  { value: 'Stevenson_AL', label: 'Stevenson, AL' },
  { value: 'Stevenson_WA', label: 'Stevenson, WA' },
  { value: 'Stevenson Ranch_CA', label: 'Stevenson Ranch, CA' },
  { value: 'Stevensville_PA', label: 'Stevensville, PA' },
  { value: 'Stevensville_MD', label: 'Stevensville, MD' },
  { value: 'Stevensville_VA', label: 'Stevensville, VA' },
  { value: 'Stevensville_MI', label: 'Stevensville, MI' },
  { value: 'Stevensville_MT', label: 'Stevensville, MT' },
  { value: 'Stevinson_CA', label: 'Stevinson, CA' },
  { value: 'Steward_IL', label: 'Steward, IL' },
  { value: 'Stewardson_IL', label: 'Stewardson, IL' },
  { value: 'Stewart_TN', label: 'Stewart, TN' },
  { value: 'Stewart_MS', label: 'Stewart, MS' },
  { value: 'Stewart_OH', label: 'Stewart, OH' },
  { value: 'Stewart_MN', label: 'Stewart, MN' },
  { value: 'Stewarts Point_CA', label: 'Stewarts Point, CA' },
  { value: 'Stewartstown_PA', label: 'Stewartstown, PA' },
  { value: 'Stewartsville_NJ', label: 'Stewartsville, NJ' },
  { value: 'Stewartsville_MO', label: 'Stewartsville, MO' },
  { value: 'Stewartville_MN', label: 'Stewartville, MN' },
  { value: 'Stickney_SD', label: 'Stickney, SD' },
  { value: 'Stigler_OK', label: 'Stigler, OK' },
  { value: 'Stilesville_IN', label: 'Stilesville, IN' },
  { value: 'Still Pond_MD', label: 'Still Pond, MD' },
  { value: 'Stillman Valley_IL', label: 'Stillman Valley, IL' },
  { value: 'Stillmore_GA', label: 'Stillmore, GA' },
  { value: 'Stillwater_ME', label: 'Stillwater, ME' },
  { value: 'Stillwater_NY', label: 'Stillwater, NY' },
  { value: 'Stillwater_PA', label: 'Stillwater, PA' },
  { value: 'Stillwater_MN', label: 'Stillwater, MN' },
  { value: 'Stillwater_OK', label: 'Stillwater, OK' },
  { value: 'Stilwell_KS', label: 'Stilwell, KS' },
  { value: 'Stilwell_OK', label: 'Stilwell, OK' },
  { value: 'Stinesville_IN', label: 'Stinesville, IN' },
  { value: 'Stinnett_KY', label: 'Stinnett, KY' },
  { value: 'Stinnett_TX', label: 'Stinnett, TX' },
  { value: 'Stinson Beach_CA', label: 'Stinson Beach, CA' },
  { value: 'Stirling_NJ', label: 'Stirling, NJ' },
  { value: 'Stirling City_CA', label: 'Stirling City, CA' },
  { value: 'Stirum_ND', label: 'Stirum, ND' },
  { value: 'Stites_ID', label: 'Stites, ID' },
  { value: 'Stittville_NY', label: 'Stittville, NY' },
  { value: 'Stitzer_WI', label: 'Stitzer, WI' },
  { value: 'Stockbridge_MA', label: 'Stockbridge, MA' },
  { value: 'Stockbridge_VT', label: 'Stockbridge, VT' },
  { value: 'Stockbridge_GA', label: 'Stockbridge, GA' },
  { value: 'Stockbridge_MI', label: 'Stockbridge, MI' },
  { value: 'Stockbridge_WI', label: 'Stockbridge, WI' },
  { value: 'Stockdale_PA', label: 'Stockdale, PA' },
  { value: 'Stockdale_TX', label: 'Stockdale, TX' },
  { value: 'Stockertown_PA', label: 'Stockertown, PA' },
  { value: 'Stockett_MT', label: 'Stockett, MT' },
  { value: 'Stockholm_ME', label: 'Stockholm, ME' },
  { value: 'Stockholm_NJ', label: 'Stockholm, NJ' },
  { value: 'Stockholm_WI', label: 'Stockholm, WI' },
  { value: 'Stockholm_SD', label: 'Stockholm, SD' },
  { value: 'Stockland_IL', label: 'Stockland, IL' },
  { value: 'Stockport_OH', label: 'Stockport, OH' },
  { value: 'Stockport_IA', label: 'Stockport, IA' },
  { value: 'Stockton_NJ', label: 'Stockton, NJ' },
  { value: 'Stockton_NY', label: 'Stockton, NY' },
  { value: 'Stockton_MD', label: 'Stockton, MD' },
  { value: 'Stockton_GA', label: 'Stockton, GA' },
  { value: 'Stockton_AL', label: 'Stockton, AL' },
  { value: 'Stockton_IA', label: 'Stockton, IA' },
  { value: 'Stockton_IL', label: 'Stockton, IL' },
  { value: 'Stockton_MO', label: 'Stockton, MO' },
  { value: 'Stockton_KS', label: 'Stockton, KS' },
  { value: 'Stockton_UT', label: 'Stockton, UT' },
  { value: 'Stockton_CA', label: 'Stockton, CA' },
  { value: 'Stockton Springs_ME', label: 'Stockton Springs, ME' },
  { value: 'Stockville_NE', label: 'Stockville, NE' },
  { value: 'Stockwell_IN', label: 'Stockwell, IN' },
  { value: 'Stoddard_NH', label: 'Stoddard, NH' },
  { value: 'Stoddard_WI', label: 'Stoddard, WI' },
  { value: 'Stokes_NC', label: 'Stokes, NC' },
  { value: 'Stokesdale_NC', label: 'Stokesdale, NC' },
  { value: 'Stollings_WV', label: 'Stollings, WV' },
  { value: 'Stone_KY', label: 'Stone, KY' },
  { value: 'Stone Creek_OH', label: 'Stone Creek, OH' },
  { value: 'Stone Harbor_NJ', label: 'Stone Harbor, NJ' },
  { value: 'Stone Lake_WI', label: 'Stone Lake, WI' },
  { value: 'Stone Mountain_GA', label: 'Stone Mountain, GA' },
  { value: 'Stone Park_IL', label: 'Stone Park, IL' },
  { value: 'Stone Ridge_NY', label: 'Stone Ridge, NY' },
  { value: 'Stoneboro_PA', label: 'Stoneboro, PA' },
  { value: 'Stonefort_IL', label: 'Stonefort, IL' },
  { value: 'Stoneham_MA', label: 'Stoneham, MA' },
  { value: 'Stoneham_ME', label: 'Stoneham, ME' },
  { value: 'Stoneham_CO', label: 'Stoneham, CO' },
  { value: 'Stoneville_NC', label: 'Stoneville, NC' },
  { value: 'Stoneville_MS', label: 'Stoneville, MS' },
  { value: 'Stonewall_MS', label: 'Stonewall, MS' },
  { value: 'Stonewall_LA', label: 'Stonewall, LA' },
  { value: 'Stonewall_OK', label: 'Stonewall, OK' },
  { value: 'Stonewall_TX', label: 'Stonewall, TX' },
  { value: 'Stoney Fork_KY', label: 'Stoney Fork, KY' },
  { value: 'Stonington_ME', label: 'Stonington, ME' },
  { value: 'Stonington_CT', label: 'Stonington, CT' },
  { value: 'Stonington_IL', label: 'Stonington, IL' },
  { value: 'Stony Brook_NY', label: 'Stony Brook, NY' },
  { value: 'Stony Creek_NY', label: 'Stony Creek, NY' },
  { value: 'Stony Creek_VA', label: 'Stony Creek, VA' },
  { value: 'Stony Point_NY', label: 'Stony Point, NY' },
  { value: 'Stony Point_NC', label: 'Stony Point, NC' },
  { value: 'Stony Ridge_OH', label: 'Stony Ridge, OH' },
  { value: 'Stonyford_CA', label: 'Stonyford, CA' },
  { value: 'Stopover_KY', label: 'Stopover, KY' },
  { value: 'Storden_MN', label: 'Storden, MN' },
  { value: 'Storm Lake_IA', label: 'Storm Lake, IA' },
  { value: 'Stormville_NY', label: 'Stormville, NY' },
  { value: 'Storrs Mansfield_CT', label: 'Storrs Mansfield, CT' },
  { value: 'Story_AR', label: 'Story, AR' },
  { value: 'Story_WY', label: 'Story, WY' },
  { value: 'Story City_IA', label: 'Story City, IA' },
  { value: 'Stotts City_MO', label: 'Stotts City, MO' },
  { value: 'Stottville_NY', label: 'Stottville, NY' },
  { value: 'Stoughton_MA', label: 'Stoughton, MA' },
  { value: 'Stoughton_WI', label: 'Stoughton, WI' },
  { value: 'Stout_OH', label: 'Stout, OH' },
  { value: 'Stout_IA', label: 'Stout, IA' },
  { value: 'Stoutland_MO', label: 'Stoutland, MO' },
  { value: 'Stoutsville_OH', label: 'Stoutsville, OH' },
  { value: 'Stoutsville_MO', label: 'Stoutsville, MO' },
  { value: 'Stovall_NC', label: 'Stovall, NC' },
  { value: 'Stover_MO', label: 'Stover, MO' },
  { value: 'Stow_MA', label: 'Stow, MA' },
  { value: 'Stow_NY', label: 'Stow, NY' },
  { value: 'Stow_OH', label: 'Stow, OH' },
  { value: 'Stowe_VT', label: 'Stowe, VT' },
  { value: 'Stowell_TX', label: 'Stowell, TX' },
  { value: 'Stoy_IL', label: 'Stoy, IL' },
  { value: 'Stoystown_PA', label: 'Stoystown, PA' },
  { value: 'Strabane_PA', label: 'Strabane, PA' },
  { value: 'Strafford_NH', label: 'Strafford, NH' },
  { value: 'Strafford_VT', label: 'Strafford, VT' },
  { value: 'Strafford_MO', label: 'Strafford, MO' },
  { value: 'Strandburg_SD', label: 'Strandburg, SD' },
  { value: 'Strandquist_MN', label: 'Strandquist, MN' },
  { value: 'Strang_NE', label: 'Strang, NE' },
  { value: 'Strang_OK', label: 'Strang, OK' },
  { value: 'Strasburg_PA', label: 'Strasburg, PA' },
  { value: 'Strasburg_VA', label: 'Strasburg, VA' },
  { value: 'Strasburg_OH', label: 'Strasburg, OH' },
  { value: 'Strasburg_ND', label: 'Strasburg, ND' },
  { value: 'Strasburg_IL', label: 'Strasburg, IL' },
  { value: 'Strasburg_MO', label: 'Strasburg, MO' },
  { value: 'Strasburg_CO', label: 'Strasburg, CO' },
  { value: 'Stratford_CT', label: 'Stratford, CT' },
  { value: 'Stratford_NJ', label: 'Stratford, NJ' },
  { value: 'Stratford_NY', label: 'Stratford, NY' },
  { value: 'Stratford_IA', label: 'Stratford, IA' },
  { value: 'Stratford_WI', label: 'Stratford, WI' },
  { value: 'Stratford_SD', label: 'Stratford, SD' },
  { value: 'Stratford_OK', label: 'Stratford, OK' },
  { value: 'Stratford_TX', label: 'Stratford, TX' },
  { value: 'Stratford_CA', label: 'Stratford, CA' },
  { value: 'Stratford_WA', label: 'Stratford, WA' },
  { value: 'Stratham_NH', label: 'Stratham, NH' },
  { value: 'Strathcona_MN', label: 'Strathcona, MN' },
  { value: 'Strathmere_NJ', label: 'Strathmere, NJ' },
  { value: 'Strathmore_CA', label: 'Strathmore, CA' },
  { value: 'Strattanville_PA', label: 'Strattanville, PA' },
  { value: 'Stratton_ME', label: 'Stratton, ME' },
  { value: 'Stratton_OH', label: 'Stratton, OH' },
  { value: 'Stratton_NE', label: 'Stratton, NE' },
  { value: 'Stratton_CO', label: 'Stratton, CO' },
  { value: 'Straughn_IN', label: 'Straughn, IN' },
  { value: 'Strausstown_PA', label: 'Strausstown, PA' },
  { value: 'Strawberry_AR', label: 'Strawberry, AR' },
  { value: 'Strawberry_CA', label: 'Strawberry, CA' },
  { value: 'Strawberry Plains_TN', label: 'Strawberry Plains, TN' },
  { value: 'Strawberry Point_IA', label: 'Strawberry Point, IA' },
  { value: 'Strawberry Valley_CA', label: 'Strawberry Valley, CA' },
  { value: 'Strawn_IL', label: 'Strawn, IL' },
  { value: 'Strawn_TX', label: 'Strawn, TX' },
  { value: 'Streamwood_IL', label: 'Streamwood, IL' },
  { value: 'Streator_IL', label: 'Streator, IL' },
  { value: 'Street_MD', label: 'Street, MD' },
  { value: 'Streeter_ND', label: 'Streeter, ND' },
  { value: 'Streetman_TX', label: 'Streetman, TX' },
  { value: 'Streetsboro_OH', label: 'Streetsboro, OH' },
  { value: 'Stringer_MS', label: 'Stringer, MS' },
  { value: 'Stringtown_OK', label: 'Stringtown, OK' },
  { value: 'Stroh_IN', label: 'Stroh, IN' },
  { value: 'Stromsburg_NE', label: 'Stromsburg, NE' },
  { value: 'Strong_ME', label: 'Strong, ME' },
  { value: 'Strong_AR', label: 'Strong, AR' },
  { value: 'Strong City_KS', label: 'Strong City, KS' },
  { value: 'Stronghurst_IL', label: 'Stronghurst, IL' },
  { value: 'Strongstown_PA', label: 'Strongstown, PA' },
  { value: 'Strongsville_OH', label: 'Strongsville, OH' },
  { value: 'Stroud_OK', label: 'Stroud, OK' },
  { value: 'Stroudsburg_PA', label: 'Stroudsburg, PA' },
  { value: 'Strum_WI', label: 'Strum, WI' },
  { value: 'Strunk_KY', label: 'Strunk, KY' },
  { value: 'Struthers_OH', label: 'Struthers, OH' },
  { value: 'Stryker_OH', label: 'Stryker, OH' },
  { value: 'Stryker_MT', label: 'Stryker, MT' },
  { value: 'Strykersville_NY', label: 'Strykersville, NY' },
  { value: 'Stuart_VA', label: 'Stuart, VA' },
  { value: 'Stuart_FL', label: 'Stuart, FL' },
  { value: 'Stuart_IA', label: 'Stuart, IA' },
  { value: 'Stuart_NE', label: 'Stuart, NE' },
  { value: 'Stuart_OK', label: 'Stuart, OK' },
  { value: 'Stuarts Draft_VA', label: 'Stuarts Draft, VA' },
  { value: 'Studio City_CA', label: 'Studio City, CA' },
  { value: 'Stump Creek_PA', label: 'Stump Creek, PA' },
  { value: 'Stumpy Point_NC', label: 'Stumpy Point, NC' },
  { value: 'Sturbridge_MA', label: 'Sturbridge, MA' },
  { value: 'Sturdivant_MO', label: 'Sturdivant, MO' },
  { value: 'Sturgeon_PA', label: 'Sturgeon, PA' },
  { value: 'Sturgeon_MO', label: 'Sturgeon, MO' },
  { value: 'Sturgeon Bay_WI', label: 'Sturgeon Bay, WI' },
  { value: 'Sturgeon Lake_MN', label: 'Sturgeon Lake, MN' },
  { value: 'Sturgis_MS', label: 'Sturgis, MS' },
  { value: 'Sturgis_KY', label: 'Sturgis, KY' },
  { value: 'Sturgis_MI', label: 'Sturgis, MI' },
  { value: 'Sturgis_SD', label: 'Sturgis, SD' },
  { value: 'Sturkie_AR', label: 'Sturkie, AR' },
  { value: 'Sturtevant_WI', label: 'Sturtevant, WI' },
  { value: 'Stuttgart_AR', label: 'Stuttgart, AR' },
  { value: 'Stuyvesant_NY', label: 'Stuyvesant, NY' },
  { value: 'Stuyvesant Falls_NY', label: 'Stuyvesant Falls, NY' },
  { value: 'Suamico_WI', label: 'Suamico, WI' },
  { value: 'Subiaco_AR', label: 'Subiaco, AR' },
  { value: 'Sublette_IL', label: 'Sublette, IL' },
  { value: 'Sublette_KS', label: 'Sublette, KS' },
  { value: 'Sublime_TX', label: 'Sublime, TX' },
  { value: 'Sublimity_OR', label: 'Sublimity, OR' },
  { value: 'Succasunna_NJ', label: 'Succasunna, NJ' },
  { value: 'Success_MO', label: 'Success, MO' },
  { value: 'Success_AR', label: 'Success, AR' },
  { value: 'Suches_GA', label: 'Suches, GA' },
  { value: 'Sudan_TX', label: 'Sudan, TX' },
  { value: 'Sudbury_MA', label: 'Sudbury, MA' },
  { value: 'Sudlersville_MD', label: 'Sudlersville, MD' },
  { value: 'Suffern_NY', label: 'Suffern, NY' },
  { value: 'Suffield_CT', label: 'Suffield, CT' },
  { value: 'Suffolk_VA', label: 'Suffolk, VA' },
  { value: 'Sugar City_CO', label: 'Sugar City, CO' },
  { value: 'Sugar City_ID', label: 'Sugar City, ID' },
  { value: 'Sugar Grove_PA', label: 'Sugar Grove, PA' },
  { value: 'Sugar Grove_VA', label: 'Sugar Grove, VA' },
  { value: 'Sugar Grove_WV', label: 'Sugar Grove, WV' },
  { value: 'Sugar Grove_NC', label: 'Sugar Grove, NC' },
  { value: 'Sugar Grove_OH', label: 'Sugar Grove, OH' },
  { value: 'Sugar Grove_IL', label: 'Sugar Grove, IL' },
  { value: 'Sugar Hill_NH', label: 'Sugar Hill, NH' },
  { value: 'Sugar Land_TX', label: 'Sugar Land, TX' },
  { value: 'Sugar Run_PA', label: 'Sugar Run, PA' },
  { value: 'Sugar Tree_TN', label: 'Sugar Tree, TN' },
  { value: 'Sugar Valley_GA', label: 'Sugar Valley, GA' },
  { value: 'Sugarcreek_OH', label: 'Sugarcreek, OH' },
  { value: 'Sugarloaf_PA', label: 'Sugarloaf, PA' },
  { value: 'Sugarloaf_CA', label: 'Sugarloaf, CA' },
  { value: 'Sugartown_LA', label: 'Sugartown, LA' },
  { value: 'Suisun City_CA', label: 'Suisun City, CA' },
  { value: 'Suitland_MD', label: 'Suitland, MD' },
  { value: 'Sula_MT', label: 'Sula, MT' },
  { value: 'Sulligent_AL', label: 'Sulligent, AL' },
  { value: 'Sullivan_NH', label: 'Sullivan, NH' },
  { value: 'Sullivan_ME', label: 'Sullivan, ME' },
  { value: 'Sullivan_OH', label: 'Sullivan, OH' },
  { value: 'Sullivan_IN', label: 'Sullivan, IN' },
  { value: 'Sullivan_WI', label: 'Sullivan, WI' },
  { value: 'Sullivan_IL', label: 'Sullivan, IL' },
  { value: 'Sullivan_MO', label: 'Sullivan, MO' },
  { value: 'Sullivan City_TX', label: 'Sullivan City, TX' },
  { value: 'Sullivans Island_SC', label: 'Sullivans Island, SC' },
  { value: 'Sully_IA', label: 'Sully, IA' },
  { value: 'Sulphur_KY', label: 'Sulphur, KY' },
  { value: 'Sulphur_IN', label: 'Sulphur, IN' },
  { value: 'Sulphur_LA', label: 'Sulphur, LA' },
  { value: 'Sulphur_OK', label: 'Sulphur, OK' },
  { value: 'Sulphur Bluff_TX', label: 'Sulphur Bluff, TX' },
  { value: 'Sulphur Rock_AR', label: 'Sulphur Rock, AR' },
  { value: 'Sulphur Springs_OH', label: 'Sulphur Springs, OH' },
  { value: 'Sulphur Springs_IN', label: 'Sulphur Springs, IN' },
  { value: 'Sulphur Springs_AR', label: 'Sulphur Springs, AR' },
  { value: 'Sulphur Springs_TX', label: 'Sulphur Springs, TX' },
  { value: 'Sultan_WA', label: 'Sultan, WA' },
  { value: 'Sultana_CA', label: 'Sultana, CA' },
  { value: 'Sumas_WA', label: 'Sumas, WA' },
  { value: 'Sumatra_MT', label: 'Sumatra, MT' },
  { value: 'Sumava Resorts_IN', label: 'Sumava Resorts, IN' },
  { value: 'Sumerco_WV', label: 'Sumerco, WV' },
  { value: 'Sumerduck_VA', label: 'Sumerduck, VA' },
  { value: 'Sumiton_AL', label: 'Sumiton, AL' },
  { value: 'Summer Lake_OR', label: 'Summer Lake, OR' },
  { value: 'Summer Shade_KY', label: 'Summer Shade, KY' },
  { value: 'Summerdale_PA', label: 'Summerdale, PA' },
  { value: 'Summerdale_AL', label: 'Summerdale, AL' },
  { value: 'Summerfield_NC', label: 'Summerfield, NC' },
  { value: 'Summerfield_FL', label: 'Summerfield, FL' },
  { value: 'Summerfield_OH', label: 'Summerfield, OH' },
  { value: 'Summerfield_IL', label: 'Summerfield, IL' },
  { value: 'Summerfield_KS', label: 'Summerfield, KS' },
  { value: 'Summerfield_LA', label: 'Summerfield, LA' },
  { value: 'Summerfield_TX', label: 'Summerfield, TX' },
  { value: 'Summerhill_PA', label: 'Summerhill, PA' },
  { value: 'Summerland_CA', label: 'Summerland, CA' },
  { value: 'Summerland Key_FL', label: 'Summerland Key, FL' },
  { value: 'Summers_AR', label: 'Summers, AR' },
  { value: 'Summersville_WV', label: 'Summersville, WV' },
  { value: 'Summersville_KY', label: 'Summersville, KY' },
  { value: 'Summersville_MO', label: 'Summersville, MO' },
  { value: 'Summerton_SC', label: 'Summerton, SC' },
  { value: 'Summertown_TN', label: 'Summertown, TN' },
  { value: 'Summerville_PA', label: 'Summerville, PA' },
  { value: 'Summerville_SC', label: 'Summerville, SC' },
  { value: 'Summerville_GA', label: 'Summerville, GA' },
  { value: 'Summerville_OR', label: 'Summerville, OR' },
  { value: 'Summit_NJ', label: 'Summit, NJ' },
  { value: 'Summit_NY', label: 'Summit, NY' },
  { value: 'Summit_MS', label: 'Summit, MS' },
  { value: 'Summit_SD', label: 'Summit, SD' },
  { value: 'Summit_AR', label: 'Summit, AR' },
  { value: 'Summit_UT', label: 'Summit, UT' },
  { value: 'Summit Argo_IL', label: 'Summit Argo, IL' },
  { value: 'Summit Hill_PA', label: 'Summit Hill, PA' },
  { value: 'Summit Lake_WI', label: 'Summit Lake, WI' },
  { value: 'Summit Point_WV', label: 'Summit Point, WV' },
  { value: 'Summit Station_PA', label: 'Summit Station, PA' },
  { value: 'Summitville_NY', label: 'Summitville, NY' },
  { value: 'Summitville_OH', label: 'Summitville, OH' },
  { value: 'Summitville_IN', label: 'Summitville, IN' },
  { value: 'Sumner_ME', label: 'Sumner, ME' },
  { value: 'Sumner_GA', label: 'Sumner, GA' },
  { value: 'Sumner_MS', label: 'Sumner, MS' },
  { value: 'Sumner_MI', label: 'Sumner, MI' },
  { value: 'Sumner_IA', label: 'Sumner, IA' },
  { value: 'Sumner_IL', label: 'Sumner, IL' },
  { value: 'Sumner_MO', label: 'Sumner, MO' },
  { value: 'Sumner_NE', label: 'Sumner, NE' },
  { value: 'Sumner_TX', label: 'Sumner, TX' },
  { value: 'Sumner_WA', label: 'Sumner, WA' },
  { value: 'Sumpter_OR', label: 'Sumpter, OR' },
  { value: 'Sumrall_MS', label: 'Sumrall, MS' },
  { value: 'Sumter_SC', label: 'Sumter, SC' },
  { value: 'Sumterville_FL', label: 'Sumterville, FL' },
  { value: 'Sun_LA', label: 'Sun, LA' },
  { value: 'Sun City_KS', label: 'Sun City, KS' },
  { value: 'Sun City_AZ', label: 'Sun City, AZ' },
  { value: 'Sun City Center_FL', label: 'Sun City Center, FL' },
  { value: 'Sun City West_AZ', label: 'Sun City West, AZ' },
  { value: 'Sun Prairie_WI', label: 'Sun Prairie, WI' },
  { value: 'Sun River_MT', label: 'Sun River, MT' },
  { value: 'Sun Valley_ID', label: 'Sun Valley, ID' },
  { value: 'Sun Valley_AZ', label: 'Sun Valley, AZ' },
  { value: 'Sun Valley_NV', label: 'Sun Valley, NV' },
  { value: 'Sun Valley_CA', label: 'Sun Valley, CA' },
  { value: 'Sunapee_NH', label: 'Sunapee, NH' },
  { value: 'Sunbright_TN', label: 'Sunbright, TN' },
  { value: 'Sunburg_MN', label: 'Sunburg, MN' },
  { value: 'Sunburst_MT', label: 'Sunburst, MT' },
  { value: 'Sunbury_PA', label: 'Sunbury, PA' },
  { value: 'Sunbury_NC', label: 'Sunbury, NC' },
  { value: 'Sunbury_OH', label: 'Sunbury, OH' },
  { value: 'Suncook_NH', label: 'Suncook, NH' },
  { value: 'Sundance_WY', label: 'Sundance, WY' },
  { value: 'Sunderland_MA', label: 'Sunderland, MA' },
  { value: 'Sunderland_MD', label: 'Sunderland, MD' },
  { value: 'Sundown_TX', label: 'Sundown, TX' },
  { value: 'Sunfield_MI', label: 'Sunfield, MI' },
  { value: 'Sunflower_AL', label: 'Sunflower, AL' },
  { value: 'Sunflower_MS', label: 'Sunflower, MS' },
  { value: 'Sunland_CA', label: 'Sunland, CA' },
  { value: 'Sunland Park_NM', label: 'Sunland Park, NM' },
  { value: 'Sunman_IN', label: 'Sunman, IN' },
  { value: 'Sunny Side_GA', label: 'Sunny Side, GA' },
  { value: 'Sunnyside_NY', label: 'Sunnyside, NY' },
  { value: 'Sunnyside_UT', label: 'Sunnyside, UT' },
  { value: 'Sunnyside_WA', label: 'Sunnyside, WA' },
  { value: 'Sunnyvale_TX', label: 'Sunnyvale, TX' },
  { value: 'Sunnyvale_CA', label: 'Sunnyvale, CA' },
  { value: 'Sunol_CA', label: 'Sunol, CA' },
  { value: 'Sunray_TX', label: 'Sunray, TX' },
  { value: 'Sunrise Beach_MO', label: 'Sunrise Beach, MO' },
  { value: 'Sunset_ME', label: 'Sunset, ME' },
  { value: 'Sunset_SC', label: 'Sunset, SC' },
  { value: 'Sunset_LA', label: 'Sunset, LA' },
  { value: 'Sunset_TX', label: 'Sunset, TX' },
  { value: 'Sunset Beach_NC', label: 'Sunset Beach, NC' },
  { value: 'Sunset Beach_CA', label: 'Sunset Beach, CA' },
  { value: 'Sunshine_LA', label: 'Sunshine, LA' },
  { value: 'Sunspot_NM', label: 'Sunspot, NM' },
  { value: 'Supai_AZ', label: 'Supai, AZ' },
  { value: 'Superior_IA', label: 'Superior, IA' },
  { value: 'Superior_WI', label: 'Superior, WI' },
  { value: 'Superior_MT', label: 'Superior, MT' },
  { value: 'Superior_NE', label: 'Superior, NE' },
  { value: 'Superior_WY', label: 'Superior, WY' },
  { value: 'Superior_AZ', label: 'Superior, AZ' },
  { value: 'Supply_NC', label: 'Supply, NC' },
  { value: 'Suquamish_WA', label: 'Suquamish, WA' },
  { value: 'Surfside_CA', label: 'Surfside, CA' },
  { value: 'Surgoinsville_TN', label: 'Surgoinsville, TN' },
  { value: 'Suring_WI', label: 'Suring, WI' },
  { value: 'Surprise_NY', label: 'Surprise, NY' },
  { value: 'Surprise_NE', label: 'Surprise, NE' },
  { value: 'Surprise_AZ', label: 'Surprise, AZ' },
  { value: 'Surrency_GA', label: 'Surrency, GA' },
  { value: 'Surrey_ND', label: 'Surrey, ND' },
  { value: 'Surry_ME', label: 'Surry, ME' },
  { value: 'Surry_VA', label: 'Surry, VA' },
  { value: 'Surveyor_WV', label: 'Surveyor, WV' },
  { value: 'Susan_VA', label: 'Susan, VA' },
  { value: 'Susanville_CA', label: 'Susanville, CA' },
  { value: 'Susquehanna_PA', label: 'Susquehanna, PA' },
  { value: 'Sussex_NJ', label: 'Sussex, NJ' },
  { value: 'Sussex_VA', label: 'Sussex, VA' },
  { value: 'Sussex_WI', label: 'Sussex, WI' },
  { value: 'Sutersville_PA', label: 'Sutersville, PA' },
  { value: 'Sutherland_VA', label: 'Sutherland, VA' },
  { value: 'Sutherland_IA', label: 'Sutherland, IA' },
  { value: 'Sutherland_NE', label: 'Sutherland, NE' },
  { value: 'Sutherland Springs_TX', label: 'Sutherland Springs, TX' },
  { value: 'Sutherlin_VA', label: 'Sutherlin, VA' },
  { value: 'Sutherlin_OR', label: 'Sutherlin, OR' },
  { value: 'Sutter_IL', label: 'Sutter, IL' },
  { value: 'Sutter_CA', label: 'Sutter, CA' },
  { value: 'Sutter Creek_CA', label: 'Sutter Creek, CA' },
  { value: 'Sutton_MA', label: 'Sutton, MA' },
  { value: 'Sutton_VT', label: 'Sutton, VT' },
  { value: 'Sutton_WV', label: 'Sutton, WV' },
  { value: 'Sutton_ND', label: 'Sutton, ND' },
  { value: 'Sutton_NE', label: 'Sutton, NE' },
  { value: 'Sutton_AK', label: 'Sutton, AK' },
  { value: 'Suttons Bay_MI', label: 'Suttons Bay, MI' },
  { value: 'Suwanee_GA', label: 'Suwanee, GA' },
  { value: 'Suwannee_FL', label: 'Suwannee, FL' },
  { value: 'Swain_NY', label: 'Swain, NY' },
  { value: 'Swainsboro_GA', label: 'Swainsboro, GA' },
  { value: 'Swaledale_IA', label: 'Swaledale, IA' },
  { value: 'Swampscott_MA', label: 'Swampscott, MA' },
  { value: 'Swan_IA', label: 'Swan, IA' },
  { value: 'Swan Lake_NY', label: 'Swan Lake, NY' },
  { value: 'Swan River_MN', label: 'Swan River, MN' },
  { value: 'Swan Valley_ID', label: 'Swan Valley, ID' },
  { value: 'Swanlake_ID', label: 'Swanlake, ID' },
  { value: 'Swannanoa_NC', label: 'Swannanoa, NC' },
  { value: 'Swanquarter_NC', label: 'Swanquarter, NC' },
  { value: 'Swans Island_ME', label: 'Swans Island, ME' },
  { value: 'Swansboro_NC', label: 'Swansboro, NC' },
  { value: 'Swansea_MA', label: 'Swansea, MA' },
  { value: 'Swansea_SC', label: 'Swansea, SC' },
  { value: 'Swanton_VT', label: 'Swanton, VT' },
  { value: 'Swanton_MD', label: 'Swanton, MD' },
  { value: 'Swanton_OH', label: 'Swanton, OH' },
  { value: 'Swanton_NE', label: 'Swanton, NE' },
  { value: 'Swanville_MN', label: 'Swanville, MN' },
  { value: 'Swanzey_NH', label: 'Swanzey, NH' },
  { value: 'Swarthmore_PA', label: 'Swarthmore, PA' },
  { value: 'Swartz Creek_MI', label: 'Swartz Creek, MI' },
  { value: 'Swatara_MN', label: 'Swatara, MN' },
  { value: 'Swayzee_IN', label: 'Swayzee, IN' },
  { value: 'Swea City_IA', label: 'Swea City, IA' },
  { value: 'Swedesboro_NJ', label: 'Swedesboro, NJ' },
  { value: 'Swedesburg_IA', label: 'Swedesburg, IA' },
  { value: 'Sweeden_KY', label: 'Sweeden, KY' },
  { value: 'Sweeny_TX', label: 'Sweeny, TX' },
  { value: 'Sweet_ID', label: 'Sweet, ID' },
  { value: 'Sweet Briar_VA', label: 'Sweet Briar, VA' },
  { value: 'Sweet Grass_MT', label: 'Sweet Grass, MT' },
  { value: 'Sweet Home_TX', label: 'Sweet Home, TX' },
  { value: 'Sweet Home_OR', label: 'Sweet Home, OR' },
  { value: 'Sweet Springs_MO', label: 'Sweet Springs, MO' },
  { value: 'Sweet Valley_PA', label: 'Sweet Valley, PA' },
  { value: 'Sweet Water_AL', label: 'Sweet Water, AL' },
  { value: 'Sweetser_IN', label: 'Sweetser, IN' },
  { value: 'Sweetwater_TN', label: 'Sweetwater, TN' },
  { value: 'Sweetwater_OK', label: 'Sweetwater, OK' },
  { value: 'Sweetwater_TX', label: 'Sweetwater, TX' },
  { value: 'Swengel_PA', label: 'Swengel, PA' },
  { value: 'Swifton_AR', label: 'Swifton, AR' },
  { value: 'Swiftwater_PA', label: 'Swiftwater, PA' },
  { value: 'Swink_OK', label: 'Swink, OK' },
  { value: 'Swink_CO', label: 'Swink, CO' },
  { value: 'Swisher_IA', label: 'Swisher, IA' },
  { value: 'Swiss_WV', label: 'Swiss, WV' },
  { value: 'Swisshome_OR', label: 'Swisshome, OR' },
  { value: 'Switchback_WV', label: 'Switchback, WV' },
  { value: 'Switz City_IN', label: 'Switz City, IN' },
  { value: 'Switzer_WV', label: 'Switzer, WV' },
  { value: 'Swoope_VA', label: 'Swoope, VA' },
  { value: 'Swords Creek_VA', label: 'Swords Creek, VA' },
  { value: 'Sycamore_PA', label: 'Sycamore, PA' },
  { value: 'Sycamore_GA', label: 'Sycamore, GA' },
  { value: 'Sycamore_AL', label: 'Sycamore, AL' },
  { value: 'Sycamore_OH', label: 'Sycamore, OH' },
  { value: 'Sycamore_IL', label: 'Sycamore, IL' },
  { value: 'Sycamore_KS', label: 'Sycamore, KS' },
  { value: 'Sykeston_ND', label: 'Sykeston, ND' },
  { value: 'Sykesville_PA', label: 'Sykesville, PA' },
  { value: 'Sykesville_MD', label: 'Sykesville, MD' },
  { value: 'Sylacauga_AL', label: 'Sylacauga, AL' },
  { value: 'Sylmar_CA', label: 'Sylmar, CA' },
  { value: 'Sylva_NC', label: 'Sylva, NC' },
  { value: 'Sylvan Beach_NY', label: 'Sylvan Beach, NY' },
  { value: 'Sylvan Grove_KS', label: 'Sylvan Grove, KS' },
  { value: 'Sylvania_GA', label: 'Sylvania, GA' },
  { value: 'Sylvania_AL', label: 'Sylvania, AL' },
  { value: 'Sylvania_OH', label: 'Sylvania, OH' },
  { value: 'Sylvester_WV', label: 'Sylvester, WV' },
  { value: 'Sylvester_GA', label: 'Sylvester, GA' },
  { value: 'Sylvester_TX', label: 'Sylvester, TX' },
  { value: 'Sylvia_KS', label: 'Sylvia, KS' },
  { value: 'Symsonia_KY', label: 'Symsonia, KY' },
  { value: 'Syosset_NY', label: 'Syosset, NY' },
  { value: 'Syracuse_NY', label: 'Syracuse, NY' },
  { value: 'Syracuse_OH', label: 'Syracuse, OH' },
  { value: 'Syracuse_IN', label: 'Syracuse, IN' },
  { value: 'Syracuse_MO', label: 'Syracuse, MO' },
  { value: 'Syracuse_KS', label: 'Syracuse, KS' },
  { value: 'Syracuse_NE', label: 'Syracuse, NE' },
  { value: 'Syracuse_UT', label: 'Syracuse, UT' },
  { value: 'Syria_VA', label: 'Syria, VA' },
  { value: 'Taberg_NY', label: 'Taberg, NY' },
  { value: 'Tabernash_CO', label: 'Tabernash, CO' },
  { value: 'Tabiona_UT', label: 'Tabiona, UT' },
  { value: 'Table Grove_IL', label: 'Table Grove, IL' },
  { value: 'Table Rock_NE', label: 'Table Rock, NE' },
  { value: 'Tabor_IA', label: 'Tabor, IA' },
  { value: 'Tabor_SD', label: 'Tabor, SD' },
  { value: 'Tabor City_NC', label: 'Tabor City, NC' },
  { value: 'Tacna_AZ', label: 'Tacna, AZ' },
  { value: 'Tacoma_WA', label: 'Tacoma, WA' },
  { value: 'Taconic_CT', label: 'Taconic, CT' },
  { value: 'Taconite_MN', label: 'Taconite, MN' },
  { value: 'Tad_WV', label: 'Tad, WV' },
  { value: 'Taft_TN', label: 'Taft, TN' },
  { value: 'Taft_OK', label: 'Taft, OK' },
  { value: 'Taft_TX', label: 'Taft, TX' },
  { value: 'Taft_CA', label: 'Taft, CA' },
  { value: 'Tafton_PA', label: 'Tafton, PA' },
  { value: 'Taftsville_VT', label: 'Taftsville, VT' },
  { value: 'Taftville_CT', label: 'Taftville, CT' },
  { value: 'Tahlequah_OK', label: 'Tahlequah, OK' },
  { value: 'Tahoe City_CA', label: 'Tahoe City, CA' },
  { value: 'Tahoe Vista_CA', label: 'Tahoe Vista, CA' },
  { value: 'Tahoka_TX', label: 'Tahoka, TX' },
  { value: 'Taholah_WA', label: 'Taholah, WA' },
  { value: 'Tahoma_CA', label: 'Tahoma, CA' },
  { value: 'Tahuya_WA', label: 'Tahuya, WA' },
  { value: 'Taiban_NM', label: 'Taiban, NM' },
  { value: 'Takoma Park_MD', label: 'Takoma Park, MD' },
  { value: 'Takotna_AK', label: 'Takotna, AK' },
  { value: 'Talala_OK', label: 'Talala, OK' },
  { value: 'Talbott_TN', label: 'Talbott, TN' },
  { value: 'Talbotton_GA', label: 'Talbotton, GA' },
  { value: 'Talco_TX', label: 'Talco, TX' },
  { value: 'Talcott_WV', label: 'Talcott, WV' },
  { value: 'Talent_OR', label: 'Talent, OR' },
  { value: 'Talihina_OK', label: 'Talihina, OK' },
  { value: 'Talisheek_LA', label: 'Talisheek, LA' },
  { value: 'Talkeetna_AK', label: 'Talkeetna, AK' },
  { value: 'Talking Rock_GA', label: 'Talking Rock, GA' },
  { value: 'Tall Timbers_MD', label: 'Tall Timbers, MD' },
  { value: 'Talladega_AL', label: 'Talladega, AL' },
  { value: 'Tallahassee_FL', label: 'Tallahassee, FL' },
  { value: 'Tallapoosa_GA', label: 'Tallapoosa, GA' },
  { value: 'Tallapoosa_MO', label: 'Tallapoosa, MO' },
  { value: 'Tallassee_AL', label: 'Tallassee, AL' },
  { value: 'Tallassee_TN', label: 'Tallassee, TN' },
  { value: 'Tallmadge_OH', label: 'Tallmadge, OH' },
  { value: 'Tallmansville_WV', label: 'Tallmansville, WV' },
  { value: 'Tallula_IL', label: 'Tallula, IL' },
  { value: 'Tallulah_LA', label: 'Tallulah, LA' },
  { value: 'Tallulah Falls_GA', label: 'Tallulah Falls, GA' },
  { value: 'Talmage_KS', label: 'Talmage, KS' },
  { value: 'Talmage_NE', label: 'Talmage, NE' },
  { value: 'Talmage_UT', label: 'Talmage, UT' },
  { value: 'Talmo_GA', label: 'Talmo, GA' },
  { value: 'Talmoon_MN', label: 'Talmoon, MN' },
  { value: 'Taloga_OK', label: 'Taloga, OK' },
  { value: 'Talpa_TX', label: 'Talpa, TX' },
  { value: 'Tama_IA', label: 'Tama, IA' },
  { value: 'Tamaqua_PA', label: 'Tamaqua, PA' },
  { value: 'Tamarack_MN', label: 'Tamarack, MN' },
  { value: 'Tamaroa_IL', label: 'Tamaroa, IL' },
  { value: 'Tamassee_SC', label: 'Tamassee, SC' },
  { value: 'Tamiment_PA', label: 'Tamiment, PA' },
  { value: 'Tamms_IL', label: 'Tamms, IL' },
  { value: 'Tampa_FL', label: 'Tampa, FL' },
  { value: 'Tampa_KS', label: 'Tampa, KS' },
  { value: 'Tampico_IL', label: 'Tampico, IL' },
  { value: 'Tamworth_NH', label: 'Tamworth, NH' },
  { value: 'Tanacross_AK', label: 'Tanacross, AK' },
  { value: 'Tanana_AK', label: 'Tanana, AK' },
  { value: 'Taneytown_MD', label: 'Taneytown, MD' },
  { value: 'Taneyville_MO', label: 'Taneyville, MO' },
  { value: 'Tangent_OR', label: 'Tangent, OR' },
  { value: 'Tangier_VA', label: 'Tangier, VA' },
  { value: 'Tangipahoa_LA', label: 'Tangipahoa, LA' },
  { value: 'Tanner_AL', label: 'Tanner, AL' },
  { value: 'Tannersville_NY', label: 'Tannersville, NY' },
  { value: 'Tannersville_PA', label: 'Tannersville, PA' },
  { value: 'Tannersville_VA', label: 'Tannersville, VA' },
  { value: 'Taopi_MN', label: 'Taopi, MN' },
  { value: 'Taos_NM', label: 'Taos, NM' },
  { value: 'Taos Ski Valley_NM', label: 'Taos Ski Valley, NM' },
  { value: 'Tappahannock_VA', label: 'Tappahannock, VA' },
  { value: 'Tappan_NY', label: 'Tappan, NY' },
  { value: 'Tappen_ND', label: 'Tappen, ND' },
  { value: 'Tar Heel_NC', label: 'Tar Heel, NC' },
  { value: 'Tarawa Terrace_NC', label: 'Tarawa Terrace, NC' },
  { value: 'Tarboro_NC', label: 'Tarboro, NC' },
  { value: 'Tarentum_PA', label: 'Tarentum, PA' },
  { value: 'Tariffville_CT', label: 'Tariffville, CT' },
  { value: 'Tarkio_MO', label: 'Tarkio, MO' },
  { value: 'Tarlton_OH', label: 'Tarlton, OH' },
  { value: 'Tarpley_TX', label: 'Tarpley, TX' },
  { value: 'Tarpon Springs_FL', label: 'Tarpon Springs, FL' },
  { value: 'Tarrs_PA', label: 'Tarrs, PA' },
  { value: 'Tarrytown_NY', label: 'Tarrytown, NY' },
  { value: 'Tarrytown_GA', label: 'Tarrytown, GA' },
  { value: 'Tarzan_TX', label: 'Tarzan, TX' },
  { value: 'Tarzana_CA', label: 'Tarzana, CA' },
  { value: 'Tasley_VA', label: 'Tasley, VA' },
  { value: 'Taswell_IN', label: 'Taswell, IN' },
  { value: 'Tatamy_PA', label: 'Tatamy, PA' },
  { value: 'Tate_GA', label: 'Tate, GA' },
  { value: 'Tatitlek_AK', label: 'Tatitlek, AK' },
  { value: 'Tatum_SC', label: 'Tatum, SC' },
  { value: 'Tatum_TX', label: 'Tatum, TX' },
  { value: 'Tatum_NM', label: 'Tatum, NM' },
  { value: 'Tatums_OK', label: 'Tatums, OK' },
  { value: 'Taunton_MA', label: 'Taunton, MA' },
  { value: 'Taunton_MN', label: 'Taunton, MN' },
  { value: 'Tavares_FL', label: 'Tavares, FL' },
  { value: 'Tavernier_FL', label: 'Tavernier, FL' },
  { value: 'Tawas City_MI', label: 'Tawas City, MI' },
  { value: 'Taylor_PA', label: 'Taylor, PA' },
  { value: 'Taylor_MS', label: 'Taylor, MS' },
  { value: 'Taylor_MI', label: 'Taylor, MI' },
  { value: 'Taylor_WI', label: 'Taylor, WI' },
  { value: 'Taylor_ND', label: 'Taylor, ND' },
  { value: 'Taylor_MO', label: 'Taylor, MO' },
  { value: 'Taylor_NE', label: 'Taylor, NE' },
  { value: 'Taylor_AR', label: 'Taylor, AR' },
  { value: 'Taylor_TX', label: 'Taylor, TX' },
  { value: 'Taylor_AZ', label: 'Taylor, AZ' },
  { value: 'Taylor Ridge_IL', label: 'Taylor Ridge, IL' },
  { value: 'Taylor Springs_IL', label: 'Taylor Springs, IL' },
  { value: 'Taylors_SC', label: 'Taylors, SC' },
  { value: 'Taylors Falls_MN', label: 'Taylors Falls, MN' },
  { value: 'Taylors Island_MD', label: 'Taylors Island, MD' },
  { value: 'Taylorstown_PA', label: 'Taylorstown, PA' },
  { value: 'Taylorsville_NC', label: 'Taylorsville, NC' },
  { value: 'Taylorsville_GA', label: 'Taylorsville, GA' },
  { value: 'Taylorsville_MS', label: 'Taylorsville, MS' },
  { value: 'Taylorsville_KY', label: 'Taylorsville, KY' },
  { value: 'Taylorsville_IN', label: 'Taylorsville, IN' },
  { value: 'Taylorsville_CA', label: 'Taylorsville, CA' },
  { value: 'Taylorville_IL', label: 'Taylorville, IL' },
  { value: 'Tazewell_VA', label: 'Tazewell, VA' },
  { value: 'Tazewell_TN', label: 'Tazewell, TN' },
  { value: 'Tchula_MS', label: 'Tchula, MS' },
  { value: 'Tea_SD', label: 'Tea, SD' },
  { value: 'Teaberry_KY', label: 'Teaberry, KY' },
  { value: 'Teachey_NC', label: 'Teachey, NC' },
  { value: 'Teague_TX', label: 'Teague, TX' },
  { value: 'Teaneck_NJ', label: 'Teaneck, NJ' },
  { value: 'Teasdale_UT', label: 'Teasdale, UT' },
  { value: 'Tebbetts_MO', label: 'Tebbetts, MO' },
  { value: 'Tecate_CA', label: 'Tecate, CA' },
  { value: 'Tecopa_CA', label: 'Tecopa, CA' },
  { value: 'Tecumseh_MI', label: 'Tecumseh, MI' },
  { value: 'Tecumseh_MO', label: 'Tecumseh, MO' },
  { value: 'Tecumseh_KS', label: 'Tecumseh, KS' },
  { value: 'Tecumseh_NE', label: 'Tecumseh, NE' },
  { value: 'Tecumseh_OK', label: 'Tecumseh, OK' },
  { value: 'Teec Nos Pos_AZ', label: 'Teec Nos Pos, AZ' },
  { value: 'Teeds Grove_IA', label: 'Teeds Grove, IA' },
  { value: 'Tehachapi_CA', label: 'Tehachapi, CA' },
  { value: 'Tehama_CA', label: 'Tehama, CA' },
  { value: 'Tehuacana_TX', label: 'Tehuacana, TX' },
  { value: 'Tekamah_NE', label: 'Tekamah, NE' },
  { value: 'Tekoa_WA', label: 'Tekoa, WA' },
  { value: 'Tekonsha_MI', label: 'Tekonsha, MI' },
  { value: 'Telephone_TX', label: 'Telephone, TX' },
  { value: 'Telferner_TX', label: 'Telferner, TX' },
  { value: 'Telford_PA', label: 'Telford, PA' },
  { value: 'Telford_TN', label: 'Telford, TN' },
  { value: 'Tell_TX', label: 'Tell, TX' },
  { value: 'Tell City_IN', label: 'Tell City, IN' },
  { value: 'Teller_AK', label: 'Teller, AK' },
  { value: 'Tellico Plains_TN', label: 'Tellico Plains, TN' },
  { value: 'Telluride_CO', label: 'Telluride, CO' },
  { value: 'Telogia_FL', label: 'Telogia, FL' },
  { value: 'Temecula_CA', label: 'Temecula, CA' },
  { value: 'Tempe_AZ', label: 'Tempe, AZ' },
  { value: 'Temperance_MI', label: 'Temperance, MI' },
  { value: 'Temperanceville_VA', label: 'Temperanceville, VA' },
  { value: 'Temple_NH', label: 'Temple, NH' },
  { value: 'Temple_ME', label: 'Temple, ME' },
  { value: 'Temple_PA', label: 'Temple, PA' },
  { value: 'Temple_GA', label: 'Temple, GA' },
  { value: 'Temple_OK', label: 'Temple, OK' },
  { value: 'Temple_TX', label: 'Temple, TX' },
  { value: 'Temple Bar Marina_AZ', label: 'Temple Bar Marina, AZ' },
  { value: 'Temple City_CA', label: 'Temple City, CA' },
  { value: 'Temple Hills_MD', label: 'Temple Hills, MD' },
  { value: 'Templeton_MA', label: 'Templeton, MA' },
  { value: 'Templeton_PA', label: 'Templeton, PA' },
  { value: 'Templeton_IN', label: 'Templeton, IN' },
  { value: 'Templeton_IA', label: 'Templeton, IA' },
  { value: 'Templeton_CA', label: 'Templeton, CA' },
  { value: 'Ten Mile_TN', label: 'Ten Mile, TN' },
  { value: 'Ten Sleep_WY', label: 'Ten Sleep, WY' },
  { value: 'Tenafly_NJ', label: 'Tenafly, NJ' },
  { value: 'Tenaha_TX', label: 'Tenaha, TX' },
  { value: 'Tenakee Springs_AK', label: 'Tenakee Springs, AK' },
  { value: 'Tenants Harbor_ME', label: 'Tenants Harbor, ME' },
  { value: 'Tendoy_ID', label: 'Tendoy, ID' },
  { value: 'Tenino_WA', label: 'Tenino, WA' },
  { value: 'Tenmile_OR', label: 'Tenmile, OR' },
  { value: 'Tennessee_IL', label: 'Tennessee, IL' },
  { value: 'Tennessee Colony_TX', label: 'Tennessee Colony, TX' },
  { value: 'Tennessee Ridge_TN', label: 'Tennessee Ridge, TN' },
  { value: 'Tennga_GA', label: 'Tennga, GA' },
  { value: 'Tennille_GA', label: 'Tennille, GA' },
  { value: 'Tennyson_IN', label: 'Tennyson, IN' },
  { value: 'Tennyson_TX', label: 'Tennyson, TX' },
  { value: 'Tensed_ID', label: 'Tensed, ID' },
  { value: 'Tenstrike_MN', label: 'Tenstrike, MN' },
  { value: 'Tererro_NM', label: 'Tererro, NM' },
  { value: 'Terlingua_TX', label: 'Terlingua, TX' },
  { value: 'Terlton_OK', label: 'Terlton, OK' },
  { value: 'Termo_CA', label: 'Termo, CA' },
  { value: 'Terra Alta_WV', label: 'Terra Alta, WV' },
  { value: 'Terra Bella_CA', label: 'Terra Bella, CA' },
  { value: 'Terra Ceia_FL', label: 'Terra Ceia, FL' },
  { value: 'Terrace Park_OH', label: 'Terrace Park, OH' },
  { value: 'Terral_OK', label: 'Terral, OK' },
  { value: 'Terre Haute_IN', label: 'Terre Haute, IN' },
  { value: 'Terre Hill_PA', label: 'Terre Hill, PA' },
  { value: 'Terrebonne_OR', label: 'Terrebonne, OR' },
  { value: 'Terrell_NC', label: 'Terrell, NC' },
  { value: 'Terrell_TX', label: 'Terrell, TX' },
  { value: 'Terreton_ID', label: 'Terreton, ID' },
  { value: 'Terril_IA', label: 'Terril, IA' },
  { value: 'Terry_MS', label: 'Terry, MS' },
  { value: 'Terry_MT', label: 'Terry, MT' },
  { value: 'Terryville_CT', label: 'Terryville, CT' },
  { value: 'Tescott_KS', label: 'Tescott, KS' },
  { value: 'Teterboro_NJ', label: 'Teterboro, NJ' },
  { value: 'Teton_ID', label: 'Teton, ID' },
  { value: 'Teton Village_WY', label: 'Teton Village, WY' },
  { value: 'Tetonia_ID', label: 'Tetonia, ID' },
  { value: 'Teutopolis_IL', label: 'Teutopolis, IL' },
  { value: 'Tewksbury_MA', label: 'Tewksbury, MA' },
  { value: 'Texarkana_AR', label: 'Texarkana, AR' },
  { value: 'Texarkana_TX', label: 'Texarkana, TX' },
  { value: 'Texas City_TX', label: 'Texas City, TX' },
  { value: 'Texhoma_OK', label: 'Texhoma, OK' },
  { value: 'Texhoma_TX', label: 'Texhoma, TX' },
  { value: 'Texico_IL', label: 'Texico, IL' },
  { value: 'Texico_NM', label: 'Texico, NM' },
  { value: 'Texline_TX', label: 'Texline, TX' },
  { value: 'Texola_OK', label: 'Texola, OK' },
  { value: 'Thackerville_OK', label: 'Thackerville, OK' },
  { value: 'Thatcher_ID', label: 'Thatcher, ID' },
  { value: 'Thatcher_AZ', label: 'Thatcher, AZ' },
  { value: 'Thawville_IL', label: 'Thawville, IL' },
  { value: 'Thaxton_VA', label: 'Thaxton, VA' },
  { value: 'Thaxton_MS', label: 'Thaxton, MS' },
  { value: 'Thayer_IN', label: 'Thayer, IN' },
  { value: 'Thayer_IA', label: 'Thayer, IA' },
  { value: 'Thayer_IL', label: 'Thayer, IL' },
  { value: 'Thayer_MO', label: 'Thayer, MO' },
  { value: 'Thayer_KS', label: 'Thayer, KS' },
  { value: 'Thayne_WY', label: 'Thayne, WY' },
  { value: 'The Colony_TX', label: 'The Colony, TX' },
  { value: 'The Dalles_OR', label: 'The Dalles, OR' },
  { value: 'The Plains_VA', label: 'The Plains, VA' },
  { value: 'The Plains_OH', label: 'The Plains, OH' },
  { value: 'The Rock_GA', label: 'The Rock, GA' },
  { value: 'The Sea Ranch_CA', label: 'The Sea Ranch, CA' },
  { value: 'The Villages_FL', label: 'The Villages, FL' },
  { value: 'Thebes_IL', label: 'Thebes, IL' },
  { value: 'Thedford_NE', label: 'Thedford, NE' },
  { value: 'Thelma_KY', label: 'Thelma, KY' },
  { value: 'Thendara_NY', label: 'Thendara, NY' },
  { value: 'Theodore_AL', label: 'Theodore, AL' },
  { value: 'Theodosia_MO', label: 'Theodosia, MO' },
  { value: 'Theresa_NY', label: 'Theresa, NY' },
  { value: 'Theresa_WI', label: 'Theresa, WI' },
  { value: 'Theriot_LA', label: 'Theriot, LA' },
  { value: 'Thermal_CA', label: 'Thermal, CA' },
  { value: 'Thermopolis_WY', label: 'Thermopolis, WY' },
  { value: 'Thetford Center_VT', label: 'Thetford Center, VT' },
  { value: 'Thibodaux_LA', label: 'Thibodaux, LA' },
  { value: 'Thicket_TX', label: 'Thicket, TX' },
  { value: 'Thida_AR', label: 'Thida, AR' },
  { value: 'Thief River Falls_MN', label: 'Thief River Falls, MN' },
  { value: 'Thiells_NY', label: 'Thiells, NY' },
  { value: 'Thomas_WV', label: 'Thomas, WV' },
  { value: 'Thomas_OK', label: 'Thomas, OK' },
  { value: 'Thomasboro_IL', label: 'Thomasboro, IL' },
  { value: 'Thomaston_ME', label: 'Thomaston, ME' },
  { value: 'Thomaston_CT', label: 'Thomaston, CT' },
  { value: 'Thomaston_GA', label: 'Thomaston, GA' },
  { value: 'Thomaston_AL', label: 'Thomaston, AL' },
  { value: 'Thomasville_PA', label: 'Thomasville, PA' },
  { value: 'Thomasville_NC', label: 'Thomasville, NC' },
  { value: 'Thomasville_GA', label: 'Thomasville, GA' },
  { value: 'Thomasville_AL', label: 'Thomasville, AL' },
  { value: 'Thompson_CT', label: 'Thompson, CT' },
  { value: 'Thompson_PA', label: 'Thompson, PA' },
  { value: 'Thompson_OH', label: 'Thompson, OH' },
  { value: 'Thompson_IA', label: 'Thompson, IA' },
  { value: 'Thompson_ND', label: 'Thompson, ND' },
  { value: 'Thompson_MO', label: 'Thompson, MO' },
  { value: 'Thompson_UT', label: 'Thompson, UT' },
  { value: 'Thompson Falls_MT', label: 'Thompson Falls, MT' },
  { value: 'Thompson Ridge_NY', label: 'Thompson Ridge, NY' },
  { value: 'Thompsons_TX', label: 'Thompsons, TX' },
  { value: 'Thompsons Station_TN', label: 'Thompsons Station, TN' },
  { value: 'Thompsontown_PA', label: 'Thompsontown, PA' },
  { value: 'Thompsonville_NY', label: 'Thompsonville, NY' },
  { value: 'Thompsonville_MI', label: 'Thompsonville, MI' },
  { value: 'Thompsonville_IL', label: 'Thompsonville, IL' },
  { value: 'Thomson_GA', label: 'Thomson, GA' },
  { value: 'Thomson_IL', label: 'Thomson, IL' },
  { value: 'Thonotosassa_FL', label: 'Thonotosassa, FL' },
  { value: 'Thor_IA', label: 'Thor, IA' },
  { value: 'Thoreau_NM', label: 'Thoreau, NM' },
  { value: 'Thorn Hill_TN', label: 'Thorn Hill, TN' },
  { value: 'Thornburg_IA', label: 'Thornburg, IA' },
  { value: 'Thorndale_PA', label: 'Thorndale, PA' },
  { value: 'Thorndale_TX', label: 'Thorndale, TX' },
  { value: 'Thorndike_MA', label: 'Thorndike, MA' },
  { value: 'Thorndike_ME', label: 'Thorndike, ME' },
  { value: 'Thorne Bay_AK', label: 'Thorne Bay, AK' },
  { value: 'Thornfield_MO', label: 'Thornfield, MO' },
  { value: 'Thornton_NH', label: 'Thornton, NH' },
  { value: 'Thornton_PA', label: 'Thornton, PA' },
  { value: 'Thornton_WV', label: 'Thornton, WV' },
  { value: 'Thornton_KY', label: 'Thornton, KY' },
  { value: 'Thornton_IA', label: 'Thornton, IA' },
  { value: 'Thornton_IL', label: 'Thornton, IL' },
  { value: 'Thornton_AR', label: 'Thornton, AR' },
  { value: 'Thornton_TX', label: 'Thornton, TX' },
  { value: 'Thornton_CO', label: 'Thornton, CO' },
  { value: 'Thornton_CA', label: 'Thornton, CA' },
  { value: 'Thornton_WA', label: 'Thornton, WA' },
  { value: 'Thorntown_IN', label: 'Thorntown, IN' },
  { value: 'Thornville_OH', label: 'Thornville, OH' },
  { value: 'Thornwood_NY', label: 'Thornwood, NY' },
  { value: 'Thorofare_NJ', label: 'Thorofare, NJ' },
  { value: 'Thorp_WI', label: 'Thorp, WI' },
  { value: 'Thorp_WA', label: 'Thorp, WA' },
  { value: 'Thorpe_WV', label: 'Thorpe, WV' },
  { value: 'Thorsby_AL', label: 'Thorsby, AL' },
  { value: 'Thousand Island Park_NY', label: 'Thousand Island Park, NY' },
  { value: 'Thousand Oaks_CA', label: 'Thousand Oaks, CA' },
  { value: 'Thousand Palms_CA', label: 'Thousand Palms, CA' },
  { value: 'Thousandsticks_KY', label: 'Thousandsticks, KY' },
  { value: 'Thrall_TX', label: 'Thrall, TX' },
  { value: 'Three Bridges_NJ', label: 'Three Bridges, NJ' },
  { value: 'Three Forks_MT', label: 'Three Forks, MT' },
  { value: 'Three Lakes_WI', label: 'Three Lakes, WI' },
  { value: 'Three Mile Bay_NY', label: 'Three Mile Bay, NY' },
  { value: 'Three Oaks_MI', label: 'Three Oaks, MI' },
  { value: 'Three Rivers_MA', label: 'Three Rivers, MA' },
  { value: 'Three Rivers_MI', label: 'Three Rivers, MI' },
  { value: 'Three Rivers_TX', label: 'Three Rivers, TX' },
  { value: 'Three Rivers_CA', label: 'Three Rivers, CA' },
  { value: 'Three Springs_PA', label: 'Three Springs, PA' },
  { value: 'Throckmorton_TX', label: 'Throckmorton, TX' },
  { value: 'Thurman_OH', label: 'Thurman, OH' },
  { value: 'Thurman_IA', label: 'Thurman, IA' },
  { value: 'Thurmond_WV', label: 'Thurmond, WV' },
  { value: 'Thurmond_NC', label: 'Thurmond, NC' },
  { value: 'Thurmont_MD', label: 'Thurmont, MD' },
  { value: 'Thurston_OH', label: 'Thurston, OH' },
  { value: 'Thurston_NE', label: 'Thurston, NE' },
  { value: 'Tibbie_AL', label: 'Tibbie, AL' },
  { value: 'Tichnor_AR', label: 'Tichnor, AR' },
  { value: 'Tickfaw_LA', label: 'Tickfaw, LA' },
  { value: 'Ticonderoga_NY', label: 'Ticonderoga, NY' },
  { value: 'Tidewater_OR', label: 'Tidewater, OR' },
  { value: 'Tidioute_PA', label: 'Tidioute, PA' },
  { value: 'Tie Siding_WY', label: 'Tie Siding, WY' },
  { value: 'Tierra Amarilla_NM', label: 'Tierra Amarilla, NM' },
  { value: 'Tieton_WA', label: 'Tieton, WA' },
  { value: 'Tiff_MO', label: 'Tiff, MO' },
  { value: 'Tiff City_MO', label: 'Tiff City, MO' },
  { value: 'Tiffin_OH', label: 'Tiffin, OH' },
  { value: 'Tiffin_IA', label: 'Tiffin, IA' },
  { value: 'Tifton_GA', label: 'Tifton, GA' },
  { value: 'Tiger_GA', label: 'Tiger, GA' },
  { value: 'Tigerton_WI', label: 'Tigerton, WI' },
  { value: 'Tignall_GA', label: 'Tignall, GA' },
  { value: 'Tigrett_TN', label: 'Tigrett, TN' },
  { value: 'Tijeras_NM', label: 'Tijeras, NM' },
  { value: 'Tilden_IL', label: 'Tilden, IL' },
  { value: 'Tilden_NE', label: 'Tilden, NE' },
  { value: 'Tilden_TX', label: 'Tilden, TX' },
  { value: 'Tilghman_MD', label: 'Tilghman, MD' },
  { value: 'Tiline_KY', label: 'Tiline, KY' },
  { value: 'Tillamook_OR', label: 'Tillamook, OR' },
  { value: 'Tillar_AR', label: 'Tillar, AR' },
  { value: 'Tillatoba_MS', label: 'Tillatoba, MS' },
  { value: 'Tilleda_WI', label: 'Tilleda, WI' },
  { value: 'Tiller_OR', label: 'Tiller, OR' },
  { value: 'Tillman_SC', label: 'Tillman, SC' },
  { value: 'Tillson_NY', label: 'Tillson, NY' },
  { value: 'Tilly_AR', label: 'Tilly, AR' },
  { value: 'Tilton_NH', label: 'Tilton, NH' },
  { value: 'Tilton_IL', label: 'Tilton, IL' },
  { value: 'Tiltonsville_OH', label: 'Tiltonsville, OH' },
  { value: 'Timber_OR', label: 'Timber, OR' },
  { value: 'Timber Lake_SD', label: 'Timber Lake, SD' },
  { value: 'Timberlake_NC', label: 'Timberlake, NC' },
  { value: 'Timberon_NM', label: 'Timberon, NM' },
  { value: 'Timberville_VA', label: 'Timberville, VA' },
  { value: 'Timblin_PA', label: 'Timblin, PA' },
  { value: 'Timbo_AR', label: 'Timbo, AR' },
  { value: 'Timewell_IL', label: 'Timewell, IL' },
  { value: 'Timmonsville_SC', label: 'Timmonsville, SC' },
  { value: 'Timnath_CO', label: 'Timnath, CO' },
  { value: 'Timpson_TX', label: 'Timpson, TX' },
  { value: 'Tina_MO', label: 'Tina, MO' },
  { value: 'Tingley_IA', label: 'Tingley, IA' },
  { value: 'Tinian_MP', label: 'Tinian, MP' },
  { value: 'Tinley Park_IL', label: 'Tinley Park, IL' },
  { value: 'Tinnie_NM', label: 'Tinnie, NM' },
  { value: 'Tinsley_MS', label: 'Tinsley, MS' },
  { value: 'Tintah_MN', label: 'Tintah, MN' },
  { value: 'Tioga_PA', label: 'Tioga, PA' },
  { value: 'Tioga_WV', label: 'Tioga, WV' },
  { value: 'Tioga_ND', label: 'Tioga, ND' },
  { value: 'Tioga_TX', label: 'Tioga, TX' },
  { value: 'Tiona_PA', label: 'Tiona, PA' },
  { value: 'Tionesta_PA', label: 'Tionesta, PA' },
  { value: 'Tiplersville_MS', label: 'Tiplersville, MS' },
  { value: 'Tipp City_OH', label: 'Tipp City, OH' },
  { value: 'Tippecanoe_OH', label: 'Tippecanoe, OH' },
  { value: 'Tippecanoe_IN', label: 'Tippecanoe, IN' },
  { value: 'Tippo_MS', label: 'Tippo, MS' },
  { value: 'Tipton_PA', label: 'Tipton, PA' },
  { value: 'Tipton_IN', label: 'Tipton, IN' },
  { value: 'Tipton_MI', label: 'Tipton, MI' },
  { value: 'Tipton_IA', label: 'Tipton, IA' },
  { value: 'Tipton_MO', label: 'Tipton, MO' },
  { value: 'Tipton_KS', label: 'Tipton, KS' },
  { value: 'Tipton_OK', label: 'Tipton, OK' },
  { value: 'Tipton_CA', label: 'Tipton, CA' },
  { value: 'Tiptonville_TN', label: 'Tiptonville, TN' },
  { value: 'Tiro_OH', label: 'Tiro, OH' },
  { value: 'Tishomingo_MS', label: 'Tishomingo, MS' },
  { value: 'Tishomingo_OK', label: 'Tishomingo, OK' },
  { value: 'Tiskilwa_IL', label: 'Tiskilwa, IL' },
  { value: 'Titonka_IA', label: 'Titonka, IA' },
  { value: 'Titus_AL', label: 'Titus, AL' },
  { value: 'Titusville_NJ', label: 'Titusville, NJ' },
  { value: 'Titusville_PA', label: 'Titusville, PA' },
  { value: 'Titusville_FL', label: 'Titusville, FL' },
  { value: 'Tiverton_RI', label: 'Tiverton, RI' },
  { value: 'Tivoli_NY', label: 'Tivoli, NY' },
  { value: 'Tivoli_TX', label: 'Tivoli, TX' },
  { value: 'Toa Alta_PR', label: 'Toa Alta, PR' },
  { value: 'Toa Baja_PR', label: 'Toa Baja, PR' },
  { value: 'Toano_VA', label: 'Toano, VA' },
  { value: 'Tobaccoville_NC', label: 'Tobaccoville, NC' },
  { value: 'Tobias_NE', label: 'Tobias, NE' },
  { value: 'Tobyhanna_PA', label: 'Tobyhanna, PA' },
  { value: 'Toccoa_GA', label: 'Toccoa, GA' },
  { value: 'Toccoa Falls_GA', label: 'Toccoa Falls, GA' },
  { value: 'Todd_PA', label: 'Todd, PA' },
  { value: 'Todd_NC', label: 'Todd, NC' },
  { value: 'Toddville_MD', label: 'Toddville, MD' },
  { value: 'Toddville_IA', label: 'Toddville, IA' },
  { value: 'Tofte_MN', label: 'Tofte, MN' },
  { value: 'Togiak_AK', label: 'Togiak, AK' },
  { value: 'Tohatchi_NM', label: 'Tohatchi, NM' },
  { value: 'Toivola_MI', label: 'Toivola, MI' },
  { value: 'Tok_AK', label: 'Tok, AK' },
  { value: 'Tokeland_WA', label: 'Tokeland, WA' },
  { value: 'Tokio_ND', label: 'Tokio, ND' },
  { value: 'Tokio_TX', label: 'Tokio, TX' },
  { value: 'Toksook Bay_AK', label: 'Toksook Bay, AK' },
  { value: 'Tolar_TX', label: 'Tolar, TX' },
  { value: 'Toledo_OH', label: 'Toledo, OH' },
  { value: 'Toledo_IA', label: 'Toledo, IA' },
  { value: 'Toledo_IL', label: 'Toledo, IL' },
  { value: 'Toledo_OR', label: 'Toledo, OR' },
  { value: 'Toledo_WA', label: 'Toledo, WA' },
  { value: 'Tolland_CT', label: 'Tolland, CT' },
  { value: 'Tollesboro_KY', label: 'Tollesboro, KY' },
  { value: 'Tolleson_AZ', label: 'Tolleson, AZ' },
  { value: 'Tolley_ND', label: 'Tolley, ND' },
  { value: 'Tollhouse_CA', label: 'Tollhouse, CA' },
  { value: 'Tolna_ND', label: 'Tolna, ND' },
  { value: 'Tolono_IL', label: 'Tolono, IL' },
  { value: 'Tolovana Park_OR', label: 'Tolovana Park, OR' },
  { value: 'Tolstoy_SD', label: 'Tolstoy, SD' },
  { value: 'Toluca_IL', label: 'Toluca, IL' },
  { value: 'Tom Bean_TX', label: 'Tom Bean, TX' },
  { value: 'Tomah_WI', label: 'Tomah, WI' },
  { value: 'Tomahawk_KY', label: 'Tomahawk, KY' },
  { value: 'Tomahawk_WI', label: 'Tomahawk, WI' },
  { value: 'Tomales_CA', label: 'Tomales, CA' },
  { value: 'Tomball_TX', label: 'Tomball, TX' },
  { value: 'Tombstone_AZ', label: 'Tombstone, AZ' },
  { value: 'Tome_NM', label: 'Tome, NM' },
  { value: 'Tomkins Cove_NY', label: 'Tomkins Cove, NY' },
  { value: 'Tompkinsville_KY', label: 'Tompkinsville, KY' },
  { value: 'Toms Brook_VA', label: 'Toms Brook, VA' },
  { value: 'Toms River_NJ', label: 'Toms River, NJ' },
  { value: 'Tonalea_AZ', label: 'Tonalea, AZ' },
  { value: 'Tonasket_WA', label: 'Tonasket, WA' },
  { value: 'Tonawanda_NY', label: 'Tonawanda, NY' },
  { value: 'Toney_AL', label: 'Toney, AL' },
  { value: 'Tonganoxie_KS', label: 'Tonganoxie, KS' },
  { value: 'Tonica_IL', label: 'Tonica, IL' },
  { value: 'Tonkawa_OK', label: 'Tonkawa, OK' },
  { value: 'Tonopah_AZ', label: 'Tonopah, AZ' },
  { value: 'Tonopah_NV', label: 'Tonopah, NV' },
  { value: 'Tonto Basin_AZ', label: 'Tonto Basin, AZ' },
  { value: 'Tontogany_OH', label: 'Tontogany, OH' },
  { value: 'Tony_WI', label: 'Tony, WI' },
  { value: 'Tooele_UT', label: 'Tooele, UT' },
  { value: 'Toomsboro_GA', label: 'Toomsboro, GA' },
  { value: 'Toomsuba_MS', label: 'Toomsuba, MS' },
  { value: 'Toone_TN', label: 'Toone, TN' },
  { value: 'Topanga_CA', label: 'Topanga, CA' },
  { value: 'Topawa_AZ', label: 'Topawa, AZ' },
  { value: 'Topaz_CA', label: 'Topaz, CA' },
  { value: 'Topeka_IN', label: 'Topeka, IN' },
  { value: 'Topeka_IL', label: 'Topeka, IL' },
  { value: 'Topeka_KS', label: 'Topeka, KS' },
  { value: 'Topinabee_MI', label: 'Topinabee, MI' },
  { value: 'Topmost_KY', label: 'Topmost, KY' },
  { value: 'Topock_AZ', label: 'Topock, AZ' },
  { value: 'Toponas_CO', label: 'Toponas, CO' },
  { value: 'Toppenish_WA', label: 'Toppenish, WA' },
  { value: 'Topping_VA', label: 'Topping, VA' },
  { value: 'Topsfield_MA', label: 'Topsfield, MA' },
  { value: 'Topsfield_ME', label: 'Topsfield, ME' },
  { value: 'Topsham_ME', label: 'Topsham, ME' },
  { value: 'Topsham_VT', label: 'Topsham, VT' },
  { value: 'Topton_PA', label: 'Topton, PA' },
  { value: 'Topton_NC', label: 'Topton, NC' },
  { value: 'Toquerville_UT', label: 'Toquerville, UT' },
  { value: 'Tornado_WV', label: 'Tornado, WV' },
  { value: 'Tornillo_TX', label: 'Tornillo, TX' },
  { value: 'Toronto_OH', label: 'Toronto, OH' },
  { value: 'Toronto_SD', label: 'Toronto, SD' },
  { value: 'Toronto_KS', label: 'Toronto, KS' },
  { value: 'Torrance_PA', label: 'Torrance, PA' },
  { value: 'Torrance_CA', label: 'Torrance, CA' },
  { value: 'Torreon_NM', label: 'Torreon, NM' },
  { value: 'Torrey_UT', label: 'Torrey, UT' },
  { value: 'Torrington_CT', label: 'Torrington, CT' },
  { value: 'Torrington_WY', label: 'Torrington, WY' },
  { value: 'Toston_MT', label: 'Toston, MT' },
  { value: 'Totowa_NJ', label: 'Totowa, NJ' },
  { value: 'Totz_KY', label: 'Totz, KY' },
  { value: 'Touchet_WA', label: 'Touchet, WA' },
  { value: 'Tougaloo_MS', label: 'Tougaloo, MS' },
  { value: 'Toughkenamon_PA', label: 'Toughkenamon, PA' },
  { value: 'Toulon_IL', label: 'Toulon, IL' },
  { value: 'Toutle_WA', label: 'Toutle, WA' },
  { value: 'Tovey_IL', label: 'Tovey, IL' },
  { value: 'Tow_TX', label: 'Tow, TX' },
  { value: 'Towaco_NJ', label: 'Towaco, NJ' },
  { value: 'Towanda_PA', label: 'Towanda, PA' },
  { value: 'Towanda_IL', label: 'Towanda, IL' },
  { value: 'Towanda_KS', label: 'Towanda, KS' },
  { value: 'Towaoc_CO', label: 'Towaoc, CO' },
  { value: 'Tower_MN', label: 'Tower, MN' },
  { value: 'Tower City_PA', label: 'Tower City, PA' },
  { value: 'Tower City_ND', label: 'Tower City, ND' },
  { value: 'Tower Hill_IL', label: 'Tower Hill, IL' },
  { value: 'Town Creek_AL', label: 'Town Creek, AL' },
  { value: 'Towner_ND', label: 'Towner, ND' },
  { value: 'Townley_AL', label: 'Townley, AL' },
  { value: 'Townsend_MA', label: 'Townsend, MA' },
  { value: 'Townsend_DE', label: 'Townsend, DE' },
  { value: 'Townsend_GA', label: 'Townsend, GA' },
  { value: 'Townsend_TN', label: 'Townsend, TN' },
  { value: 'Townsend_WI', label: 'Townsend, WI' },
  { value: 'Townsend_MT', label: 'Townsend, MT' },
  { value: 'Townshend_VT', label: 'Townshend, VT' },
  { value: 'Township Of Washington_NJ', label: 'Township Of Washington, NJ' },
  { value: 'Townsville_NC', label: 'Townsville, NC' },
  { value: 'Townville_PA', label: 'Townville, PA' },
  { value: 'Townville_SC', label: 'Townville, SC' },
  { value: 'Towson_MD', label: 'Towson, MD' },
  { value: 'Toxey_AL', label: 'Toxey, AL' },
  { value: 'Toyah_TX', label: 'Toyah, TX' },
  { value: 'Trabuco Canyon_CA', label: 'Trabuco Canyon, CA' },
  { value: 'Tracy_IA', label: 'Tracy, IA' },
  { value: 'Tracy_MN', label: 'Tracy, MN' },
  { value: 'Tracy_CA', label: 'Tracy, CA' },
  { value: 'Tracy City_TN', label: 'Tracy City, TN' },
  { value: 'Tracys Landing_MD', label: 'Tracys Landing, MD' },
  { value: 'Trade_TN', label: 'Trade, TN' },
  { value: 'Traer_IA', label: 'Traer, IA' },
  { value: 'Trafalgar_IN', label: 'Trafalgar, IN' },
  { value: 'Trafford_PA', label: 'Trafford, PA' },
  { value: 'Trafford_AL', label: 'Trafford, AL' },
  { value: 'Trail_MN', label: 'Trail, MN' },
  { value: 'Trail_OR', label: 'Trail, OR' },
  { value: 'Trail City_SD', label: 'Trail City, SD' },
  { value: 'Tram_KY', label: 'Tram, KY' },
  { value: 'Tranquillity_CA', label: 'Tranquillity, CA' },
  { value: 'Transfer_PA', label: 'Transfer, PA' },
  { value: 'Transylvania_LA', label: 'Transylvania, LA' },
  { value: 'Traphill_NC', label: 'Traphill, NC' },
  { value: 'Trappe_MD', label: 'Trappe, MD' },
  { value: 'Trapper Creek_AK', label: 'Trapper Creek, AK' },
  { value: 'Traskwood_AR', label: 'Traskwood, AR' },
  { value: 'Travelers Rest_SC', label: 'Travelers Rest, SC' },
  { value: 'Traver_CA', label: 'Traver, CA' },
  { value: 'Traverse City_MI', label: 'Traverse City, MI' },
  { value: 'Travis Afb_CA', label: 'Travis Afb, CA' },
  { value: 'Treadwell_NY', label: 'Treadwell, NY' },
  { value: 'Treece_KS', label: 'Treece, KS' },
  { value: 'Trego_WI', label: 'Trego, WI' },
  { value: 'Trego_MT', label: 'Trego, MT' },
  { value: 'Treichlers_PA', label: 'Treichlers, PA' },
  { value: 'Trementina_NM', label: 'Trementina, NM' },
  { value: 'Tremont_PA', label: 'Tremont, PA' },
  { value: 'Tremont_MS', label: 'Tremont, MS' },
  { value: 'Tremont_IL', label: 'Tremont, IL' },
  { value: 'Tremont City_OH', label: 'Tremont City, OH' },
  { value: 'Tremonton_UT', label: 'Tremonton, UT' },
  { value: 'Trempealeau_WI', label: 'Trempealeau, WI' },
  { value: 'Trenary_MI', label: 'Trenary, MI' },
  { value: 'Trent_SD', label: 'Trent, SD' },
  { value: 'Trent_TX', label: 'Trent, TX' },
  { value: 'Trenton_NJ', label: 'Trenton, NJ' },
  { value: 'Trenton_NC', label: 'Trenton, NC' },
  { value: 'Trenton_SC', label: 'Trenton, SC' },
  { value: 'Trenton_GA', label: 'Trenton, GA' },
  { value: 'Trenton_FL', label: 'Trenton, FL' },
  { value: 'Trenton_AL', label: 'Trenton, AL' },
  { value: 'Trenton_TN', label: 'Trenton, TN' },
  { value: 'Trenton_KY', label: 'Trenton, KY' },
  { value: 'Trenton_OH', label: 'Trenton, OH' },
  { value: 'Trenton_MI', label: 'Trenton, MI' },
  { value: 'Trenton_ND', label: 'Trenton, ND' },
  { value: 'Trenton_IL', label: 'Trenton, IL' },
  { value: 'Trenton_MO', label: 'Trenton, MO' },
  { value: 'Trenton_NE', label: 'Trenton, NE' },
  { value: 'Trenton_TX', label: 'Trenton, TX' },
  { value: 'Trenton_UT', label: 'Trenton, UT' },
  { value: 'Tres Piedras_NM', label: 'Tres Piedras, NM' },
  { value: 'Tres Pinos_CA', label: 'Tres Pinos, CA' },
  { value: 'Tresckow_PA', label: 'Tresckow, PA' },
  { value: 'Trevett_ME', label: 'Trevett, ME' },
  { value: 'Trevor_WI', label: 'Trevor, WI' },
  { value: 'Trevorton_PA', label: 'Trevorton, PA' },
  { value: 'Trexlertown_PA', label: 'Trexlertown, PA' },
  { value: 'Treynor_IA', label: 'Treynor, IA' },
  { value: 'Trezevant_TN', label: 'Trezevant, TN' },
  { value: 'Triadelphia_WV', label: 'Triadelphia, WV' },
  { value: 'Triangle_VA', label: 'Triangle, VA' },
  { value: 'Tribes Hill_NY', label: 'Tribes Hill, NY' },
  { value: 'Tribune_KS', label: 'Tribune, KS' },
  { value: 'Tridell_UT', label: 'Tridell, UT' },
  { value: 'Trilla_IL', label: 'Trilla, IL' },
  { value: 'Trimble_TN', label: 'Trimble, TN' },
  { value: 'Trimble_OH', label: 'Trimble, OH' },
  { value: 'Trimble_MO', label: 'Trimble, MO' },
  { value: 'Trimont_MN', label: 'Trimont, MN' },
  { value: 'Trinchera_CO', label: 'Trinchera, CO' },
  { value: 'Trinidad_TX', label: 'Trinidad, TX' },
  { value: 'Trinidad_CO', label: 'Trinidad, CO' },
  { value: 'Trinidad_CA', label: 'Trinidad, CA' },
  { value: 'Trinity_NC', label: 'Trinity, NC' },
  { value: 'Trinity_AL', label: 'Trinity, AL' },
  { value: 'Trinity_TX', label: 'Trinity, TX' },
  { value: 'Trinity Center_CA', label: 'Trinity Center, CA' },
  { value: 'Trinway_OH', label: 'Trinway, OH' },
  { value: 'Trion_GA', label: 'Trion, GA' },
  {
    value: 'Tripler Army Medical Center_HI',
    label: 'Tripler Army Medical Center, HI',
  },
  { value: 'Triplett_MO', label: 'Triplett, MO' },
  { value: 'Tripoli_IA', label: 'Tripoli, IA' },
  { value: 'Tripoli_WI', label: 'Tripoli, WI' },
  { value: 'Tripp_SD', label: 'Tripp, SD' },
  { value: 'Triumph_IL', label: 'Triumph, IL' },
  { value: 'Trivoli_IL', label: 'Trivoli, IL' },
  { value: 'Trona_CA', label: 'Trona, CA' },
  { value: 'Tropic_UT', label: 'Tropic, UT' },
  { value: 'Trosper_KY', label: 'Trosper, KY' },
  { value: 'Troup_TX', label: 'Troup, TX' },
  { value: 'Troupsburg_NY', label: 'Troupsburg, NY' },
  { value: 'Trout_LA', label: 'Trout, LA' },
  { value: 'Trout Creek_NY', label: 'Trout Creek, NY' },
  { value: 'Trout Creek_MI', label: 'Trout Creek, MI' },
  { value: 'Trout Creek_MT', label: 'Trout Creek, MT' },
  { value: 'Trout Lake_MI', label: 'Trout Lake, MI' },
  { value: 'Trout Lake_WA', label: 'Trout Lake, WA' },
  { value: 'Trout Run_PA', label: 'Trout Run, PA' },
  { value: 'Troutdale_VA', label: 'Troutdale, VA' },
  { value: 'Troutdale_OR', label: 'Troutdale, OR' },
  { value: 'Troutman_NC', label: 'Troutman, NC' },
  { value: 'Troutville_PA', label: 'Troutville, PA' },
  { value: 'Troutville_VA', label: 'Troutville, VA' },
  { value: 'Troy_NH', label: 'Troy, NH' },
  { value: 'Troy_ME', label: 'Troy, ME' },
  { value: 'Troy_VT', label: 'Troy, VT' },
  { value: 'Troy_NY', label: 'Troy, NY' },
  { value: 'Troy_PA', label: 'Troy, PA' },
  { value: 'Troy_VA', label: 'Troy, VA' },
  { value: 'Troy_WV', label: 'Troy, WV' },
  { value: 'Troy_NC', label: 'Troy, NC' },
  { value: 'Troy_SC', label: 'Troy, SC' },
  { value: 'Troy_AL', label: 'Troy, AL' },
  { value: 'Troy_TN', label: 'Troy, TN' },
  { value: 'Troy_OH', label: 'Troy, OH' },
  { value: 'Troy_IN', label: 'Troy, IN' },
  { value: 'Troy_MI', label: 'Troy, MI' },
  { value: 'Troy_MT', label: 'Troy, MT' },
  { value: 'Troy_IL', label: 'Troy, IL' },
  { value: 'Troy_MO', label: 'Troy, MO' },
  { value: 'Troy_KS', label: 'Troy, KS' },
  { value: 'Troy_TX', label: 'Troy, TX' },
  { value: 'Troy_ID', label: 'Troy, ID' },
  { value: 'Troy Grove_IL', label: 'Troy Grove, IL' },
  { value: 'Troy Mills_IA', label: 'Troy Mills, IA' },
  { value: 'Truchas_NM', label: 'Truchas, NM' },
  { value: 'Truckee_CA', label: 'Truckee, CA' },
  { value: 'Truesdale_IA', label: 'Truesdale, IA' },
  { value: 'Truesdale_MO', label: 'Truesdale, MO' },
  { value: 'Trufant_MI', label: 'Trufant, MI' },
  { value: 'Trujillo Alto_PR', label: 'Trujillo Alto, PR' },
  { value: 'Truman_MN', label: 'Truman, MN' },
  { value: 'Trumann_AR', label: 'Trumann, AR' },
  { value: 'Trumansburg_NY', label: 'Trumansburg, NY' },
  { value: 'Trumbauersville_PA', label: 'Trumbauersville, PA' },
  { value: 'Trumbull_CT', label: 'Trumbull, CT' },
  { value: 'Trumbull_NE', label: 'Trumbull, NE' },
  { value: 'Truro_MA', label: 'Truro, MA' },
  { value: 'Truro_IA', label: 'Truro, IA' },
  { value: 'Trussville_AL', label: 'Trussville, AL' },
  { value: 'Truth Or Consequences_NM', label: 'Truth Or Consequences, NM' },
  { value: 'Truxton_NY', label: 'Truxton, NY' },
  { value: 'Truxton_MO', label: 'Truxton, MO' },
  { value: 'Tryon_NC', label: 'Tryon, NC' },
  { value: 'Tryon_NE', label: 'Tryon, NE' },
  { value: 'Tryon_OK', label: 'Tryon, OK' },
  { value: 'Tsaile_AZ', label: 'Tsaile, AZ' },
  { value: 'Tualatin_OR', label: 'Tualatin, OR' },
  { value: 'Tuba City_AZ', label: 'Tuba City, AZ' },
  { value: 'Tubac_AZ', label: 'Tubac, AZ' },
  { value: 'Tuckahoe_NY', label: 'Tuckahoe, NY' },
  { value: 'Tuckasegee_NC', label: 'Tuckasegee, NC' },
  { value: 'Tucker_GA', label: 'Tucker, GA' },
  { value: 'Tucker_AR', label: 'Tucker, AR' },
  { value: 'Tuckerman_AR', label: 'Tuckerman, AR' },
  { value: 'Tuckerton_NJ', label: 'Tuckerton, NJ' },
  { value: 'Tucson_AZ', label: 'Tucson, AZ' },
  { value: 'Tucumcari_NM', label: 'Tucumcari, NM' },
  { value: 'Tujunga_CA', label: 'Tujunga, CA' },
  { value: 'Tulare_SD', label: 'Tulare, SD' },
  { value: 'Tulare_CA', label: 'Tulare, CA' },
  { value: 'Tularosa_NM', label: 'Tularosa, NM' },
  { value: 'Tulelake_CA', label: 'Tulelake, CA' },
  { value: 'Tuleta_TX', label: 'Tuleta, TX' },
  { value: 'Tulia_TX', label: 'Tulia, TX' },
  { value: 'Tullahoma_TN', label: 'Tullahoma, TN' },
  { value: 'Tullos_LA', label: 'Tullos, LA' },
  { value: 'Tully_NY', label: 'Tully, NY' },
  { value: 'Tulsa_OK', label: 'Tulsa, OK' },
  { value: 'Tuluksak_AK', label: 'Tuluksak, AK' },
  { value: 'Tumacacori_AZ', label: 'Tumacacori, AZ' },
  { value: 'Tumbling Shoals_AR', label: 'Tumbling Shoals, AR' },
  { value: 'Tumtum_WA', label: 'Tumtum, WA' },
  { value: 'Tunas_MO', label: 'Tunas, MO' },
  { value: 'Tunbridge_VT', label: 'Tunbridge, VT' },
  { value: 'Tunica_MS', label: 'Tunica, MS' },
  { value: 'Tunica_LA', label: 'Tunica, LA' },
  { value: 'Tunkhannock_PA', label: 'Tunkhannock, PA' },
  { value: 'Tunnel Hill_GA', label: 'Tunnel Hill, GA' },
  { value: 'Tunnelton_WV', label: 'Tunnelton, WV' },
  { value: 'Tunnelton_IN', label: 'Tunnelton, IN' },
  { value: 'Tuntutuliak_AK', label: 'Tuntutuliak, AK' },
  { value: 'Tununak_AK', label: 'Tununak, AK' },
  { value: 'Tuolumne_CA', label: 'Tuolumne, CA' },
  { value: 'Tupelo_MS', label: 'Tupelo, MS' },
  { value: 'Tupelo_AR', label: 'Tupelo, AR' },
  { value: 'Tupelo_OK', label: 'Tupelo, OK' },
  { value: 'Tupman_CA', label: 'Tupman, CA' },
  { value: 'Tupper Lake_NY', label: 'Tupper Lake, NY' },
  { value: 'Tuppers Plains_OH', label: 'Tuppers Plains, OH' },
  { value: 'Turbeville_SC', label: 'Turbeville, SC' },
  { value: 'Turbotville_PA', label: 'Turbotville, PA' },
  { value: 'Turin_NY', label: 'Turin, NY' },
  { value: 'Turin_GA', label: 'Turin, GA' },
  { value: 'Turkey_NC', label: 'Turkey, NC' },
  { value: 'Turkey_TX', label: 'Turkey, TX' },
  { value: 'Turkey Creek_LA', label: 'Turkey Creek, LA' },
  { value: 'Turlock_CA', label: 'Turlock, CA' },
  { value: 'Turner_ME', label: 'Turner, ME' },
  { value: 'Turner_MI', label: 'Turner, MI' },
  { value: 'Turner_MT', label: 'Turner, MT' },
  { value: 'Turner_AR', label: 'Turner, AR' },
  { value: 'Turner_OR', label: 'Turner, OR' },
  { value: 'Turners Falls_MA', label: 'Turners Falls, MA' },
  { value: 'Turners Station_KY', label: 'Turners Station, KY' },
  { value: 'Turney_MO', label: 'Turney, MO' },
  { value: 'Turon_KS', label: 'Turon, KS' },
  { value: 'Turpin_OK', label: 'Turpin, OK' },
  { value: 'Turrell_AR', label: 'Turrell, AR' },
  { value: 'Turtle Creek_PA', label: 'Turtle Creek, PA' },
  { value: 'Turtle Creek_WV', label: 'Turtle Creek, WV' },
  { value: 'Turtle Lake_WI', label: 'Turtle Lake, WI' },
  { value: 'Turtle Lake_ND', label: 'Turtle Lake, ND' },
  { value: 'Turtlepoint_PA', label: 'Turtlepoint, PA' },
  { value: 'Turtletown_TN', label: 'Turtletown, TN' },
  { value: 'Turton_SD', label: 'Turton, SD' },
  { value: 'Tuscaloosa_AL', label: 'Tuscaloosa, AL' },
  { value: 'Tuscarawas_OH', label: 'Tuscarawas, OH' },
  { value: 'Tuscarora_PA', label: 'Tuscarora, PA' },
  { value: 'Tuscarora_MD', label: 'Tuscarora, MD' },
  { value: 'Tuscarora_NV', label: 'Tuscarora, NV' },
  { value: 'Tuscola_IL', label: 'Tuscola, IL' },
  { value: 'Tuscola_TX', label: 'Tuscola, TX' },
  { value: 'Tuscumbia_AL', label: 'Tuscumbia, AL' },
  { value: 'Tuscumbia_MO', label: 'Tuscumbia, MO' },
  { value: 'Tuskahoma_OK', label: 'Tuskahoma, OK' },
  { value: 'Tuskegee_AL', label: 'Tuskegee, AL' },
  { value: 'Tuskegee Institute_AL', label: 'Tuskegee Institute, AL' },
  { value: 'Tussy_OK', label: 'Tussy, OK' },
  { value: 'Tustin_MI', label: 'Tustin, MI' },
  { value: 'Tustin_CA', label: 'Tustin, CA' },
  { value: 'Tuthill_SD', label: 'Tuthill, SD' },
  { value: 'Tutor Key_KY', label: 'Tutor Key, KY' },
  { value: 'Tuttle_ND', label: 'Tuttle, ND' },
  { value: 'Tuttle_OK', label: 'Tuttle, OK' },
  { value: 'Tutwiler_MS', label: 'Tutwiler, MS' },
  { value: 'Tuxedo Park_NY', label: 'Tuxedo Park, NY' },
  { value: 'Twain_CA', label: 'Twain, CA' },
  { value: 'Twain Harte_CA', label: 'Twain Harte, CA' },
  { value: 'Twelve Mile_IN', label: 'Twelve Mile, IN' },
  { value: 'Twentynine Palms_CA', label: 'Twentynine Palms, CA' },
  { value: 'Twilight_WV', label: 'Twilight, WV' },
  { value: 'Twin Bridges_MT', label: 'Twin Bridges, MT' },
  { value: 'Twin Bridges_CA', label: 'Twin Bridges, CA' },
  { value: 'Twin Brooks_SD', label: 'Twin Brooks, SD' },
  { value: 'Twin City_GA', label: 'Twin City, GA' },
  { value: 'Twin Falls_ID', label: 'Twin Falls, ID' },
  { value: 'Twin Lake_MI', label: 'Twin Lake, MI' },
  { value: 'Twin Lakes_WI', label: 'Twin Lakes, WI' },
  { value: 'Twin Lakes_MN', label: 'Twin Lakes, MN' },
  { value: 'Twin Lakes_CO', label: 'Twin Lakes, CO' },
  { value: 'Twin Mountain_NH', label: 'Twin Mountain, NH' },
  { value: 'Twin Oaks_OK', label: 'Twin Oaks, OK' },
  { value: 'Twin Peaks_CA', label: 'Twin Peaks, CA' },
  { value: 'Twin Rocks_PA', label: 'Twin Rocks, PA' },
  { value: 'Twin Valley_MN', label: 'Twin Valley, MN' },
  { value: 'Twining_MI', label: 'Twining, MI' },
  { value: 'Twinsburg_OH', label: 'Twinsburg, OH' },
  { value: 'Twisp_WA', label: 'Twisp, WA' },
  { value: 'Two Buttes_CO', label: 'Two Buttes, CO' },
  { value: 'Two Dot_MT', label: 'Two Dot, MT' },
  { value: 'Two Harbors_MN', label: 'Two Harbors, MN' },
  { value: 'Two Rivers_WI', label: 'Two Rivers, WI' },
  { value: 'Ty Ty_GA', label: 'Ty Ty, GA' },
  { value: 'Tyaskin_MD', label: 'Tyaskin, MD' },
  { value: 'Tybee Island_GA', label: 'Tybee Island, GA' },
  { value: 'Tye_TX', label: 'Tye, TX' },
  { value: 'Tygh Valley_OR', label: 'Tygh Valley, OR' },
  { value: 'Tyler_AL', label: 'Tyler, AL' },
  { value: 'Tyler_MN', label: 'Tyler, MN' },
  { value: 'Tyler_TX', label: 'Tyler, TX' },
  { value: 'Tyler Hill_PA', label: 'Tyler Hill, PA' },
  { value: 'Tylersburg_PA', label: 'Tylersburg, PA' },
  { value: 'Tylerton_MD', label: 'Tylerton, MD' },
  { value: 'Tylertown_MS', label: 'Tylertown, MS' },
  { value: 'Tynan_TX', label: 'Tynan, TX' },
  { value: 'Tyndall_SD', label: 'Tyndall, SD' },
  { value: 'Tyner_NC', label: 'Tyner, NC' },
  { value: 'Tyner_KY', label: 'Tyner, KY' },
  { value: 'Tyner_IN', label: 'Tyner, IN' },
  { value: 'Tyngsboro_MA', label: 'Tyngsboro, MA' },
  { value: 'Tyonek_AK', label: 'Tyonek, AK' },
  { value: 'Tyringham_MA', label: 'Tyringham, MA' },
  { value: 'Tyro_VA', label: 'Tyro, VA' },
  { value: 'Tyro_KS', label: 'Tyro, KS' },
  { value: 'Tyrone_PA', label: 'Tyrone, PA' },
  { value: 'Tyrone_GA', label: 'Tyrone, GA' },
  { value: 'Tyrone_OK', label: 'Tyrone, OK' },
  { value: 'Tyrone_NM', label: 'Tyrone, NM' },
  { value: 'Tyronza_AR', label: 'Tyronza, AR' },
  { value: 'Ubly_MI', label: 'Ubly, MI' },
  { value: 'Ucon_ID', label: 'Ucon, ID' },
  { value: 'Udall_MO', label: 'Udall, MO' },
  { value: 'Udall_KS', label: 'Udall, KS' },
  { value: 'Udell_IA', label: 'Udell, IA' },
  { value: 'Uehling_NE', label: 'Uehling, NE' },
  { value: 'Uhrichsville_OH', label: 'Uhrichsville, OH' },
  { value: 'Ukiah_CA', label: 'Ukiah, CA' },
  { value: 'Ukiah_OR', label: 'Ukiah, OR' },
  { value: 'Uledi_PA', label: 'Uledi, PA' },
  { value: 'Ulen_MN', label: 'Ulen, MN' },
  { value: 'Ullin_IL', label: 'Ullin, IL' },
  { value: 'Ulm_MT', label: 'Ulm, MT' },
  { value: 'Ulm_AR', label: 'Ulm, AR' },
  { value: 'Ulman_MO', label: 'Ulman, MO' },
  { value: 'Ulmer_SC', label: 'Ulmer, SC' },
  { value: 'Ulster_PA', label: 'Ulster, PA' },
  { value: 'Ulster Park_NY', label: 'Ulster Park, NY' },
  { value: 'Ulysses_PA', label: 'Ulysses, PA' },
  { value: 'Ulysses_KY', label: 'Ulysses, KY' },
  { value: 'Ulysses_KS', label: 'Ulysses, KS' },
  { value: 'Ulysses_NE', label: 'Ulysses, NE' },
  { value: 'Umatilla_FL', label: 'Umatilla, FL' },
  { value: 'Umatilla_OR', label: 'Umatilla, OR' },
  { value: 'Umbarger_TX', label: 'Umbarger, TX' },
  { value: 'Umpire_AR', label: 'Umpire, AR' },
  { value: 'Umpqua_OR', label: 'Umpqua, OR' },
  { value: 'Una_SC', label: 'Una, SC' },
  { value: 'Unadilla_NY', label: 'Unadilla, NY' },
  { value: 'Unadilla_GA', label: 'Unadilla, GA' },
  { value: 'Unadilla_NE', label: 'Unadilla, NE' },
  { value: 'Unalakleet_AK', label: 'Unalakleet, AK' },
  { value: 'Unalaska_AK', label: 'Unalaska, AK' },
  { value: 'Uncasville_CT', label: 'Uncasville, CT' },
  { value: 'Underhill_VT', label: 'Underhill, VT' },
  { value: 'Underwood_IN', label: 'Underwood, IN' },
  { value: 'Underwood_IA', label: 'Underwood, IA' },
  { value: 'Underwood_MN', label: 'Underwood, MN' },
  { value: 'Underwood_ND', label: 'Underwood, ND' },
  { value: 'Underwood_WA', label: 'Underwood, WA' },
  { value: 'Uneeda_WV', label: 'Uneeda, WV' },
  { value: 'Unicoi_TN', label: 'Unicoi, TN' },
  { value: 'Union_NH', label: 'Union, NH' },
  { value: 'Union_ME', label: 'Union, ME' },
  { value: 'Union_NJ', label: 'Union, NJ' },
  { value: 'Union_WV', label: 'Union, WV' },
  { value: 'Union_SC', label: 'Union, SC' },
  { value: 'Union_MS', label: 'Union, MS' },
  { value: 'Union_KY', label: 'Union, KY' },
  { value: 'Union_MI', label: 'Union, MI' },
  { value: 'Union_IA', label: 'Union, IA' },
  { value: 'Union_IL', label: 'Union, IL' },
  { value: 'Union_MO', label: 'Union, MO' },
  { value: 'Union_NE', label: 'Union, NE' },
  { value: 'Union_OR', label: 'Union, OR' },
  { value: 'Union_WA', label: 'Union, WA' },
  { value: 'Union Bridge_MD', label: 'Union Bridge, MD' },
  { value: 'Union Center_WI', label: 'Union Center, WI' },
  { value: 'Union Center_SD', label: 'Union Center, SD' },
  { value: 'Union Church_MS', label: 'Union Church, MS' },
  { value: 'Union City_NJ', label: 'Union City, NJ' },
  { value: 'Union City_PA', label: 'Union City, PA' },
  { value: 'Union City_GA', label: 'Union City, GA' },
  { value: 'Union City_TN', label: 'Union City, TN' },
  { value: 'Union City_OH', label: 'Union City, OH' },
  { value: 'Union City_IN', label: 'Union City, IN' },
  { value: 'Union City_MI', label: 'Union City, MI' },
  { value: 'Union City_OK', label: 'Union City, OK' },
  { value: 'Union City_CA', label: 'Union City, CA' },
  { value: 'Union Dale_PA', label: 'Union Dale, PA' },
  { value: 'Union Furnace_OH', label: 'Union Furnace, OH' },
  { value: 'Union Grove_NC', label: 'Union Grove, NC' },
  { value: 'Union Grove_AL', label: 'Union Grove, AL' },
  { value: 'Union Grove_WI', label: 'Union Grove, WI' },
  { value: 'Union Hall_VA', label: 'Union Hall, VA' },
  { value: 'Union Hill_IL', label: 'Union Hill, IL' },
  { value: 'Union Mills_NC', label: 'Union Mills, NC' },
  { value: 'Union Mills_IN', label: 'Union Mills, IN' },
  { value: 'Union Pier_MI', label: 'Union Pier, MI' },
  { value: 'Union Point_GA', label: 'Union Point, GA' },
  { value: 'Union Springs_NY', label: 'Union Springs, NY' },
  { value: 'Union Springs_AL', label: 'Union Springs, AL' },
  { value: 'Union Star_KY', label: 'Union Star, KY' },
  { value: 'Union Star_MO', label: 'Union Star, MO' },
  { value: 'Uniondale_NY', label: 'Uniondale, NY' },
  { value: 'Uniondale_IN', label: 'Uniondale, IN' },
  { value: 'Uniontown_PA', label: 'Uniontown, PA' },
  { value: 'Uniontown_AL', label: 'Uniontown, AL' },
  { value: 'Uniontown_KY', label: 'Uniontown, KY' },
  { value: 'Uniontown_OH', label: 'Uniontown, OH' },
  { value: 'Uniontown_MO', label: 'Uniontown, MO' },
  { value: 'Uniontown_KS', label: 'Uniontown, KS' },
  { value: 'Uniontown_AR', label: 'Uniontown, AR' },
  { value: 'Uniontown_WA', label: 'Uniontown, WA' },
  { value: 'Unionville_CT', label: 'Unionville, CT' },
  { value: 'Unionville_NY', label: 'Unionville, NY' },
  { value: 'Unionville_PA', label: 'Unionville, PA' },
  { value: 'Unionville_VA', label: 'Unionville, VA' },
  { value: 'Unionville_TN', label: 'Unionville, TN' },
  { value: 'Unionville_IN', label: 'Unionville, IN' },
  { value: 'Unionville_MI', label: 'Unionville, MI' },
  { value: 'Unionville_IA', label: 'Unionville, IA' },
  { value: 'Unionville_MO', label: 'Unionville, MO' },
  { value: 'Unionville Center_OH', label: 'Unionville Center, OH' },
  { value: 'Uniopolis_OH', label: 'Uniopolis, OH' },
  { value: 'United_PA', label: 'United, PA' },
  { value: 'Unity_ME', label: 'Unity, ME' },
  { value: 'Unity_WI', label: 'Unity, WI' },
  { value: 'Unity_OR', label: 'Unity, OR' },
  { value: 'Unityville_PA', label: 'Unityville, PA' },
  { value: 'Universal_IN', label: 'Universal, IN' },
  { value: 'Universal City_TX', label: 'Universal City, TX' },
  { value: 'Universal City_CA', label: 'Universal City, CA' },
  { value: 'University_MS', label: 'University, MS' },
  { value: 'University Center_MI', label: 'University Center, MI' },
  { value: 'University Park_PA', label: 'University Park, PA' },
  { value: 'University Park_IA', label: 'University Park, IA' },
  { value: 'University Park_IL', label: 'University Park, IL' },
  { value: 'University Place_WA', label: 'University Place, WA' },
  { value: 'Upatoi_GA', label: 'Upatoi, GA' },
  { value: 'Upham_ND', label: 'Upham, ND' },
  { value: 'Upland_IN', label: 'Upland, IN' },
  { value: 'Upland_NE', label: 'Upland, NE' },
  { value: 'Upland_CA', label: 'Upland, CA' },
  { value: 'Upper Black Eddy_PA', label: 'Upper Black Eddy, PA' },
  { value: 'Upper Darby_PA', label: 'Upper Darby, PA' },
  { value: 'Upper Fairmount_MD', label: 'Upper Fairmount, MD' },
  { value: 'Upper Falls_MD', label: 'Upper Falls, MD' },
  { value: 'Upper Jay_NY', label: 'Upper Jay, NY' },
  { value: 'Upper Lake_CA', label: 'Upper Lake, CA' },
  { value: 'Upper Marlboro_MD', label: 'Upper Marlboro, MD' },
  { value: 'Upper Sandusky_OH', label: 'Upper Sandusky, OH' },
  { value: 'Upper Tract_WV', label: 'Upper Tract, WV' },
  { value: 'Upperco_MD', label: 'Upperco, MD' },
  { value: 'Upperglade_WV', label: 'Upperglade, WV' },
  { value: 'Upperstrasburg_PA', label: 'Upperstrasburg, PA' },
  { value: 'Upperville_VA', label: 'Upperville, VA' },
  { value: 'Upsala_MN', label: 'Upsala, MN' },
  { value: 'Upson_WI', label: 'Upson, WI' },
  { value: 'Upton_MA', label: 'Upton, MA' },
  { value: 'Upton_NY', label: 'Upton, NY' },
  { value: 'Upton_KY', label: 'Upton, KY' },
  { value: 'Upton_WY', label: 'Upton, WY' },
  { value: 'Urania_LA', label: 'Urania, LA' },
  { value: 'Urbana_OH', label: 'Urbana, OH' },
  { value: 'Urbana_IN', label: 'Urbana, IN' },
  { value: 'Urbana_IA', label: 'Urbana, IA' },
  { value: 'Urbana_IL', label: 'Urbana, IL' },
  { value: 'Urbana_MO', label: 'Urbana, MO' },
  { value: 'Urbandale_IA', label: 'Urbandale, IA' },
  { value: 'Urbanna_VA', label: 'Urbanna, VA' },
  { value: 'Uriah_AL', label: 'Uriah, AL' },
  { value: 'Urich_MO', label: 'Urich, MO' },
  { value: 'Ursa_IL', label: 'Ursa, IL' },
  { value: 'Usaf Academy_CO', label: 'Usaf Academy, CO' },
  { value: 'Usk_WA', label: 'Usk, WA' },
  { value: 'Ute_IA', label: 'Ute, IA' },
  { value: 'Ute Park_NM', label: 'Ute Park, NM' },
  { value: 'Utica_NY', label: 'Utica, NY' },
  { value: 'Utica_PA', label: 'Utica, PA' },
  { value: 'Utica_MS', label: 'Utica, MS' },
  { value: 'Utica_KY', label: 'Utica, KY' },
  { value: 'Utica_OH', label: 'Utica, OH' },
  { value: 'Utica_MI', label: 'Utica, MI' },
  { value: 'Utica_MN', label: 'Utica, MN' },
  { value: 'Utica_SD', label: 'Utica, SD' },
  { value: 'Utica_IL', label: 'Utica, IL' },
  { value: 'Utica_MO', label: 'Utica, MO' },
  { value: 'Utica_KS', label: 'Utica, KS' },
  { value: 'Utica_NE', label: 'Utica, NE' },
  { value: 'Utopia_TX', label: 'Utopia, TX' },
  { value: 'Utuado_PR', label: 'Utuado, PR' },
  { value: 'Uvalda_GA', label: 'Uvalda, GA' },
  { value: 'Uvalde_TX', label: 'Uvalde, TX' },
  { value: 'Uxbridge_MA', label: 'Uxbridge, MA' },
  { value: 'Vacaville_CA', label: 'Vacaville, CA' },
  { value: 'Vacherie_LA', label: 'Vacherie, LA' },
  { value: 'Vader_WA', label: 'Vader, WA' },
  { value: 'Vadito_NM', label: 'Vadito, NM' },
  { value: 'Vado_NM', label: 'Vado, NM' },
  { value: 'Vaiden_MS', label: 'Vaiden, MS' },
  { value: 'Vail_IA', label: 'Vail, IA' },
  { value: 'Vail_CO', label: 'Vail, CO' },
  { value: 'Vail_AZ', label: 'Vail, AZ' },
  { value: 'Valatie_NY', label: 'Valatie, NY' },
  { value: 'Valders_WI', label: 'Valders, WI' },
  { value: 'Valdese_NC', label: 'Valdese, NC' },
  { value: 'Valdez_NM', label: 'Valdez, NM' },
  { value: 'Valdez_AK', label: 'Valdez, AK' },
  { value: 'Valdosta_GA', label: 'Valdosta, GA' },
  { value: 'Vale_NC', label: 'Vale, NC' },
  { value: 'Vale_SD', label: 'Vale, SD' },
  { value: 'Vale_OR', label: 'Vale, OR' },
  { value: 'Valencia_PA', label: 'Valencia, PA' },
  { value: 'Valencia_CA', label: 'Valencia, CA' },
  { value: 'Valentine_NE', label: 'Valentine, NE' },
  { value: 'Valentine_TX', label: 'Valentine, TX' },
  { value: 'Valentine_AZ', label: 'Valentine, AZ' },
  { value: 'Valentines_VA', label: 'Valentines, VA' },
  { value: 'Valera_TX', label: 'Valera, TX' },
  { value: 'Valhalla_NY', label: 'Valhalla, NY' },
  { value: 'Valhermoso Springs_AL', label: 'Valhermoso Springs, AL' },
  { value: 'Valier_PA', label: 'Valier, PA' },
  { value: 'Valier_MT', label: 'Valier, MT' },
  { value: 'Valier_IL', label: 'Valier, IL' },
  { value: 'Vallecito_CA', label: 'Vallecito, CA' },
  { value: 'Vallecitos_NM', label: 'Vallecitos, NM' },
  { value: 'Vallejo_CA', label: 'Vallejo, CA' },
  { value: 'Valles Mines_MO', label: 'Valles Mines, MO' },
  { value: 'Valley_AL', label: 'Valley, AL' },
  { value: 'Valley_NE', label: 'Valley, NE' },
  { value: 'Valley_WA', label: 'Valley, WA' },
  { value: 'Valley Bend_WV', label: 'Valley Bend, WV' },
  { value: 'Valley Center_KS', label: 'Valley Center, KS' },
  { value: 'Valley Center_CA', label: 'Valley Center, CA' },
  { value: 'Valley City_OH', label: 'Valley City, OH' },
  { value: 'Valley City_ND', label: 'Valley City, ND' },
  { value: 'Valley Cottage_NY', label: 'Valley Cottage, NY' },
  { value: 'Valley Falls_NY', label: 'Valley Falls, NY' },
  { value: 'Valley Falls_KS', label: 'Valley Falls, KS' },
  { value: 'Valley Farms_AZ', label: 'Valley Farms, AZ' },
  { value: 'Valley Ford_CA', label: 'Valley Ford, CA' },
  { value: 'Valley Grove_WV', label: 'Valley Grove, WV' },
  { value: 'Valley Head_WV', label: 'Valley Head, WV' },
  { value: 'Valley Head_AL', label: 'Valley Head, AL' },
  { value: 'Valley Lee_MD', label: 'Valley Lee, MD' },
  { value: 'Valley Mills_TX', label: 'Valley Mills, TX' },
  { value: 'Valley Park_MS', label: 'Valley Park, MS' },
  { value: 'Valley Park_MO', label: 'Valley Park, MO' },
  { value: 'Valley Spring_TX', label: 'Valley Spring, TX' },
  { value: 'Valley Springs_SD', label: 'Valley Springs, SD' },
  { value: 'Valley Springs_AR', label: 'Valley Springs, AR' },
  { value: 'Valley Springs_CA', label: 'Valley Springs, CA' },
  { value: 'Valley Stream_NY', label: 'Valley Stream, NY' },
  { value: 'Valley View_PA', label: 'Valley View, PA' },
  { value: 'Valley View_TX', label: 'Valley View, TX' },
  { value: 'Valley Village_CA', label: 'Valley Village, CA' },
  { value: 'Valleyford_WA', label: 'Valleyford, WA' },
  { value: 'Valliant_OK', label: 'Valliant, OK' },
  { value: 'Vallonia_IN', label: 'Vallonia, IN' },
  { value: 'Valmeyer_IL', label: 'Valmeyer, IL' },
  { value: 'Valmora_NM', label: 'Valmora, NM' },
  { value: 'Valmy_NV', label: 'Valmy, NV' },
  { value: 'Valparaiso_FL', label: 'Valparaiso, FL' },
  { value: 'Valparaiso_IN', label: 'Valparaiso, IN' },
  { value: 'Valparaiso_NE', label: 'Valparaiso, NE' },
  { value: 'Valrico_FL', label: 'Valrico, FL' },
  { value: 'Valyermo_CA', label: 'Valyermo, CA' },
  { value: 'Van_WV', label: 'Van, WV' },
  { value: 'Van_TX', label: 'Van, TX' },
  { value: 'Van Alstyne_TX', label: 'Van Alstyne, TX' },
  { value: 'Van Buren_ME', label: 'Van Buren, ME' },
  { value: 'Van Buren_OH', label: 'Van Buren, OH' },
  { value: 'Van Buren_IN', label: 'Van Buren, IN' },
  { value: 'Van Buren_MO', label: 'Van Buren, MO' },
  { value: 'Van Buren_AR', label: 'Van Buren, AR' },
  { value: 'Van Buren Point_NY', label: 'Van Buren Point, NY' },
  { value: 'Van Dyne_WI', label: 'Van Dyne, WI' },
  { value: 'Van Etten_NY', label: 'Van Etten, NY' },
  { value: 'Van Horn_TX', label: 'Van Horn, TX' },
  { value: 'Van Horne_IA', label: 'Van Horne, IA' },
  { value: 'Van Hornesville_NY', label: 'Van Hornesville, NY' },
  { value: 'Van Lear_KY', label: 'Van Lear, KY' },
  { value: 'Van Meter_IA', label: 'Van Meter, IA' },
  { value: 'Van Nuys_CA', label: 'Van Nuys, CA' },
  { value: 'Van Orin_IL', label: 'Van Orin, IL' },
  { value: 'Van Tassell_WY', label: 'Van Tassell, WY' },
  { value: 'Van Vleck_TX', label: 'Van Vleck, TX' },
  { value: 'Van Voorhis_PA', label: 'Van Voorhis, PA' },
  { value: 'Van Wert_OH', label: 'Van Wert, OH' },
  { value: 'Van Wert_IA', label: 'Van Wert, IA' },
  { value: 'Vance_SC', label: 'Vance, SC' },
  { value: 'Vance_AL', label: 'Vance, AL' },
  { value: 'Vance_MS', label: 'Vance, MS' },
  { value: 'Vanceboro_ME', label: 'Vanceboro, ME' },
  { value: 'Vanceboro_NC', label: 'Vanceboro, NC' },
  { value: 'Vanceburg_KY', label: 'Vanceburg, KY' },
  { value: 'Vancleave_MS', label: 'Vancleave, MS' },
  { value: 'Vancleve_KY', label: 'Vancleve, KY' },
  { value: 'Vancourt_TX', label: 'Vancourt, TX' },
  { value: 'Vancouver_WA', label: 'Vancouver, WA' },
  { value: 'Vandalia_OH', label: 'Vandalia, OH' },
  { value: 'Vandalia_MI', label: 'Vandalia, MI' },
  { value: 'Vandalia_MT', label: 'Vandalia, MT' },
  { value: 'Vandalia_IL', label: 'Vandalia, IL' },
  { value: 'Vandalia_MO', label: 'Vandalia, MO' },
  { value: 'Vandemere_NC', label: 'Vandemere, NC' },
  { value: 'Vanderbilt_PA', label: 'Vanderbilt, PA' },
  { value: 'Vanderbilt_MI', label: 'Vanderbilt, MI' },
  { value: 'Vanderbilt_TX', label: 'Vanderbilt, TX' },
  { value: 'Vandergrift_PA', label: 'Vandergrift, PA' },
  { value: 'Vanderpool_TX', label: 'Vanderpool, TX' },
  { value: 'Vandervoort_AR', label: 'Vandervoort, AR' },
  { value: 'Vanderwagen_NM', label: 'Vanderwagen, NM' },
  { value: 'Vandiver_AL', label: 'Vandiver, AL' },
  { value: 'Vanduser_MO', label: 'Vanduser, MO' },
  { value: 'Vanleer_TN', label: 'Vanleer, TN' },
  { value: 'Vanlue_OH', label: 'Vanlue, OH' },
  { value: 'Vanndale_AR', label: 'Vanndale, AR' },
  { value: 'Vansant_VA', label: 'Vansant, VA' },
  { value: 'Vantage_WA', label: 'Vantage, WA' },
  { value: 'Vanzant_MO', label: 'Vanzant, MO' },
  { value: 'Vardaman_MS', label: 'Vardaman, MS' },
  { value: 'Varina_IA', label: 'Varina, IA' },
  { value: 'Varna_IL', label: 'Varna, IL' },
  { value: 'Varnell_GA', label: 'Varnell, GA' },
  { value: 'Varney_WV', label: 'Varney, WV' },
  { value: 'Varney_KY', label: 'Varney, KY' },
  { value: 'Varnville_SC', label: 'Varnville, SC' },
  { value: 'Varysburg_NY', label: 'Varysburg, NY' },
  { value: 'Vashon_WA', label: 'Vashon, WA' },
  { value: 'Vass_NC', label: 'Vass, NC' },
  { value: 'Vassalboro_ME', label: 'Vassalboro, ME' },
  { value: 'Vassar_MI', label: 'Vassar, MI' },
  { value: 'Vassar_KS', label: 'Vassar, KS' },
  { value: 'Vaughan_MS', label: 'Vaughan, MS' },
  { value: 'Vaughn_MT', label: 'Vaughn, MT' },
  { value: 'Vaughn_NM', label: 'Vaughn, NM' },
  { value: 'Vaughn_WA', label: 'Vaughn, WA' },
  { value: 'Vaughnsville_OH', label: 'Vaughnsville, OH' },
  { value: 'Vauxhall_NJ', label: 'Vauxhall, NJ' },
  { value: 'Veblen_SD', label: 'Veblen, SD' },
  { value: 'Veedersburg_IN', label: 'Veedersburg, IN' },
  { value: 'Vega_TX', label: 'Vega, TX' },
  { value: 'Vega Alta_PR', label: 'Vega Alta, PR' },
  { value: 'Vega Baja_PR', label: 'Vega Baja, PR' },
  { value: 'Veguita_NM', label: 'Veguita, NM' },
  { value: 'Velarde_NM', label: 'Velarde, NM' },
  { value: 'Velma_OK', label: 'Velma, OK' },
  { value: 'Velpen_IN', label: 'Velpen, IN' },
  { value: 'Velva_ND', label: 'Velva, ND' },
  { value: 'Venango_PA', label: 'Venango, PA' },
  { value: 'Venango_NE', label: 'Venango, NE' },
  { value: 'Vendor_AR', label: 'Vendor, AR' },
  { value: 'Venedocia_OH', label: 'Venedocia, OH' },
  { value: 'Veneta_OR', label: 'Veneta, OR' },
  { value: 'Venetia_PA', label: 'Venetia, PA' },
  { value: 'Venetie_AK', label: 'Venetie, AK' },
  { value: 'Venice_FL', label: 'Venice, FL' },
  { value: 'Venice_IL', label: 'Venice, IL' },
  { value: 'Venice_LA', label: 'Venice, LA' },
  { value: 'Venice_CA', label: 'Venice, CA' },
  { value: 'Ventnor City_NJ', label: 'Ventnor City, NJ' },
  { value: 'Ventress_LA', label: 'Ventress, LA' },
  { value: 'Ventura_IA', label: 'Ventura, IA' },
  { value: 'Ventura_CA', label: 'Ventura, CA' },
  { value: 'Venus_PA', label: 'Venus, PA' },
  { value: 'Venus_FL', label: 'Venus, FL' },
  { value: 'Venus_TX', label: 'Venus, TX' },
  { value: 'Vera_OK', label: 'Vera, OK' },
  { value: 'Veradale_WA', label: 'Veradale, WA' },
  { value: 'Verbank_NY', label: 'Verbank, NY' },
  { value: 'Verbena_AL', label: 'Verbena, AL' },
  { value: 'Verden_OK', label: 'Verden, OK' },
  { value: 'Verdi_NV', label: 'Verdi, NV' },
  { value: 'Verdigre_NE', label: 'Verdigre, NE' },
  { value: 'Verdon_NE', label: 'Verdon, NE' },
  { value: 'Verdugo City_CA', label: 'Verdugo City, CA' },
  { value: 'Verdunville_WV', label: 'Verdunville, WV' },
  { value: 'Vergas_MN', label: 'Vergas, MN' },
  { value: 'Vergennes_VT', label: 'Vergennes, VT' },
  { value: 'Vergennes_IL', label: 'Vergennes, IL' },
  { value: 'Vermilion_OH', label: 'Vermilion, OH' },
  { value: 'Vermilion_IL', label: 'Vermilion, IL' },
  { value: 'Vermillion_MN', label: 'Vermillion, MN' },
  { value: 'Vermillion_SD', label: 'Vermillion, SD' },
  { value: 'Vermillion_KS', label: 'Vermillion, KS' },
  { value: 'Vermont_IL', label: 'Vermont, IL' },
  { value: 'Vermontville_NY', label: 'Vermontville, NY' },
  { value: 'Vermontville_MI', label: 'Vermontville, MI' },
  { value: 'Vernal_UT', label: 'Vernal, UT' },
  { value: 'Vernalis_CA', label: 'Vernalis, CA' },
  { value: 'Verndale_MN', label: 'Verndale, MN' },
  { value: 'Verner_WV', label: 'Verner, WV' },
  { value: 'Vernon_VT', label: 'Vernon, VT' },
  { value: 'Vernon_NJ', label: 'Vernon, NJ' },
  { value: 'Vernon_NY', label: 'Vernon, NY' },
  { value: 'Vernon_FL', label: 'Vernon, FL' },
  { value: 'Vernon_AL', label: 'Vernon, AL' },
  { value: 'Vernon_IN', label: 'Vernon, IN' },
  { value: 'Vernon_MI', label: 'Vernon, MI' },
  { value: 'Vernon_IL', label: 'Vernon, IL' },
  { value: 'Vernon_TX', label: 'Vernon, TX' },
  { value: 'Vernon_CO', label: 'Vernon, CO' },
  { value: 'Vernon_UT', label: 'Vernon, UT' },
  { value: 'Vernon_AZ', label: 'Vernon, AZ' },
  { value: 'Vernon Center_NY', label: 'Vernon Center, NY' },
  { value: 'Vernon Center_MN', label: 'Vernon Center, MN' },
  { value: 'Vernon Hill_VA', label: 'Vernon Hill, VA' },
  { value: 'Vernon Hills_IL', label: 'Vernon Hills, IL' },
  { value: 'Vernon Rockville_CT', label: 'Vernon Rockville, CT' },
  { value: 'Vernonia_OR', label: 'Vernonia, OR' },
  { value: 'Vero Beach_FL', label: 'Vero Beach, FL' },
  { value: 'Verona_NJ', label: 'Verona, NJ' },
  { value: 'Verona_NY', label: 'Verona, NY' },
  { value: 'Verona_PA', label: 'Verona, PA' },
  { value: 'Verona_VA', label: 'Verona, VA' },
  { value: 'Verona_MS', label: 'Verona, MS' },
  { value: 'Verona_KY', label: 'Verona, KY' },
  { value: 'Verona_OH', label: 'Verona, OH' },
  { value: 'Verona_WI', label: 'Verona, WI' },
  { value: 'Verona_ND', label: 'Verona, ND' },
  { value: 'Verona_IL', label: 'Verona, IL' },
  { value: 'Verona_MO', label: 'Verona, MO' },
  { value: 'Verona Beach_NY', label: 'Verona Beach, NY' },
  { value: 'Verplanck_NY', label: 'Verplanck, NY' },
  { value: 'Versailles_NY', label: 'Versailles, NY' },
  { value: 'Versailles_KY', label: 'Versailles, KY' },
  { value: 'Versailles_OH', label: 'Versailles, OH' },
  { value: 'Versailles_IN', label: 'Versailles, IN' },
  { value: 'Versailles_IL', label: 'Versailles, IL' },
  { value: 'Versailles_MO', label: 'Versailles, MO' },
  { value: 'Vershire_VT', label: 'Vershire, VT' },
  { value: 'Vesper_WI', label: 'Vesper, WI' },
  { value: 'Vest_KY', label: 'Vest, KY' },
  { value: 'Vesta_MN', label: 'Vesta, MN' },
  { value: 'Vestaburg_PA', label: 'Vestaburg, PA' },
  { value: 'Vestaburg_MI', label: 'Vestaburg, MI' },
  { value: 'Vestal_NY', label: 'Vestal, NY' },
  { value: 'Vesuvius_VA', label: 'Vesuvius, VA' },
  { value: 'Veteran_WY', label: 'Veteran, WY' },
  { value: 'Vevay_IN', label: 'Vevay, IN' },
  { value: 'Veyo_UT', label: 'Veyo, UT' },
  { value: 'Vian_OK', label: 'Vian, OK' },
  { value: 'Viborg_SD', label: 'Viborg, SD' },
  { value: 'Viburnum_MO', label: 'Viburnum, MO' },
  { value: 'Vicco_KY', label: 'Vicco, KY' },
  { value: 'Vichy_MO', label: 'Vichy, MO' },
  { value: 'Vici_OK', label: 'Vici, OK' },
  { value: 'Vickery_OH', label: 'Vickery, OH' },
  { value: 'Vicksburg_MS', label: 'Vicksburg, MS' },
  { value: 'Vicksburg_MI', label: 'Vicksburg, MI' },
  { value: 'Victor_NY', label: 'Victor, NY' },
  { value: 'Victor_WV', label: 'Victor, WV' },
  { value: 'Victor_IA', label: 'Victor, IA' },
  { value: 'Victor_MT', label: 'Victor, MT' },
  { value: 'Victor_CO', label: 'Victor, CO' },
  { value: 'Victor_ID', label: 'Victor, ID' },
  { value: 'Victor_CA', label: 'Victor, CA' },
  { value: 'Victoria_VA', label: 'Victoria, VA' },
  { value: 'Victoria_MN', label: 'Victoria, MN' },
  { value: 'Victoria_IL', label: 'Victoria, IL' },
  { value: 'Victoria_KS', label: 'Victoria, KS' },
  { value: 'Victoria_TX', label: 'Victoria, TX' },
  { value: 'Victorville_CA', label: 'Victorville, CA' },
  { value: 'Victory Mills_NY', label: 'Victory Mills, NY' },
  { value: 'Vida_MT', label: 'Vida, MT' },
  { value: 'Vida_OR', label: 'Vida, OR' },
  { value: 'Vidal_CA', label: 'Vidal, CA' },
  { value: 'Vidalia_GA', label: 'Vidalia, GA' },
  { value: 'Vidalia_LA', label: 'Vidalia, LA' },
  { value: 'Vidor_TX', label: 'Vidor, TX' },
  { value: 'Vienna_ME', label: 'Vienna, ME' },
  { value: 'Vienna_NJ', label: 'Vienna, NJ' },
  { value: 'Vienna_MD', label: 'Vienna, MD' },
  { value: 'Vienna_VA', label: 'Vienna, VA' },
  { value: 'Vienna_WV', label: 'Vienna, WV' },
  { value: 'Vienna_GA', label: 'Vienna, GA' },
  { value: 'Vienna_OH', label: 'Vienna, OH' },
  { value: 'Vienna_SD', label: 'Vienna, SD' },
  { value: 'Vienna_IL', label: 'Vienna, IL' },
  { value: 'Vienna_MO', label: 'Vienna, MO' },
  { value: 'Vieques_PR', label: 'Vieques, PR' },
  { value: 'Viewtown_VA', label: 'Viewtown, VA' },
  { value: 'Viking_MN', label: 'Viking, MN' },
  { value: 'Vilas_NC', label: 'Vilas, NC' },
  { value: 'Vilas_CO', label: 'Vilas, CO' },
  { value: 'Villa Grande_CA', label: 'Villa Grande, CA' },
  { value: 'Villa Grove_IL', label: 'Villa Grove, IL' },
  { value: 'Villa Grove_CO', label: 'Villa Grove, CO' },
  { value: 'Villa Maria_PA', label: 'Villa Maria, PA' },
  { value: 'Villa Park_IL', label: 'Villa Park, IL' },
  { value: 'Villa Park_CA', label: 'Villa Park, CA' },
  { value: 'Villa Rica_GA', label: 'Villa Rica, GA' },
  { value: 'Villa Ridge_IL', label: 'Villa Ridge, IL' },
  { value: 'Villa Ridge_MO', label: 'Villa Ridge, MO' },
  { value: 'Village Mills_TX', label: 'Village Mills, TX' },
  { value: 'Villalba_PR', label: 'Villalba, PR' },
  { value: 'Villanova_PA', label: 'Villanova, PA' },
  { value: 'Villanueva_NM', label: 'Villanueva, NM' },
  { value: 'Villard_MN', label: 'Villard, MN' },
  { value: 'Villas_NJ', label: 'Villas, NJ' },
  { value: 'Ville Platte_LA', label: 'Ville Platte, LA' },
  { value: 'Villisca_IA', label: 'Villisca, IA' },
  { value: 'Vilonia_AR', label: 'Vilonia, AR' },
  { value: 'Vina_AL', label: 'Vina, AL' },
  { value: 'Vina_CA', label: 'Vina, CA' },
  { value: 'Vinalhaven_ME', label: 'Vinalhaven, ME' },
  { value: 'Vincennes_IN', label: 'Vincennes, IN' },
  { value: 'Vincent_AL', label: 'Vincent, AL' },
  { value: 'Vincent_KY', label: 'Vincent, KY' },
  { value: 'Vincent_OH', label: 'Vincent, OH' },
  { value: 'Vincent_IA', label: 'Vincent, IA' },
  { value: 'Vincentown_NJ', label: 'Vincentown, NJ' },
  { value: 'Vine Grove_KY', label: 'Vine Grove, KY' },
  { value: 'Vinegar Bend_AL', label: 'Vinegar Bend, AL' },
  { value: 'Vineland_NJ', label: 'Vineland, NJ' },
  { value: 'Vinemont_AL', label: 'Vinemont, AL' },
  { value: 'Vineyard_UT', label: 'Vineyard, UT' },
  { value: 'Vineyard Haven_MA', label: 'Vineyard Haven, MA' },
  { value: 'Vining_IA', label: 'Vining, IA' },
  { value: 'Vining_MN', label: 'Vining, MN' },
  { value: 'Vinita_OK', label: 'Vinita, OK' },
  { value: 'Vinson_OK', label: 'Vinson, OK' },
  { value: 'Vinton_VA', label: 'Vinton, VA' },
  { value: 'Vinton_OH', label: 'Vinton, OH' },
  { value: 'Vinton_IA', label: 'Vinton, IA' },
  { value: 'Vinton_LA', label: 'Vinton, LA' },
  { value: 'Vinton_CA', label: 'Vinton, CA' },
  { value: 'Vintondale_PA', label: 'Vintondale, PA' },
  { value: 'Viola_DE', label: 'Viola, DE' },
  { value: 'Viola_TN', label: 'Viola, TN' },
  { value: 'Viola_WI', label: 'Viola, WI' },
  { value: 'Viola_IL', label: 'Viola, IL' },
  { value: 'Viola_KS', label: 'Viola, KS' },
  { value: 'Viola_AR', label: 'Viola, AR' },
  { value: 'Viola_ID', label: 'Viola, ID' },
  { value: 'Violet_LA', label: 'Violet, LA' },
  { value: 'Violet Hill_AR', label: 'Violet Hill, AR' },
  { value: 'Viper_KY', label: 'Viper, KY' },
  { value: 'Virden_IL', label: 'Virden, IL' },
  { value: 'Virgie_KY', label: 'Virgie, KY' },
  { value: 'Virgil_SD', label: 'Virgil, SD' },
  { value: 'Virgil_KS', label: 'Virgil, KS' },
  { value: 'Virgilina_VA', label: 'Virgilina, VA' },
  { value: 'Virgin_UT', label: 'Virgin, UT' },
  { value: 'Virginia_MN', label: 'Virginia, MN' },
  { value: 'Virginia_IL', label: 'Virginia, IL' },
  { value: 'Virginia_NE', label: 'Virginia, NE' },
  { value: 'Virginia Beach_VA', label: 'Virginia Beach, VA' },
  { value: 'Virginia City_MT', label: 'Virginia City, MT' },
  { value: 'Virginia City_NV', label: 'Virginia City, NV' },
  { value: 'Virginville_PA', label: 'Virginville, PA' },
  { value: 'Viroqua_WI', label: 'Viroqua, WI' },
  { value: 'Visalia_CA', label: 'Visalia, CA' },
  { value: 'Vista_CA', label: 'Vista, CA' },
  { value: 'Vivian_SD', label: 'Vivian, SD' },
  { value: 'Vivian_LA', label: 'Vivian, LA' },
  { value: 'Voca_TX', label: 'Voca, TX' },
  { value: 'Volant_PA', label: 'Volant, PA' },
  { value: 'Volborg_MT', label: 'Volborg, MT' },
  { value: 'Volcano_CA', label: 'Volcano, CA' },
  { value: 'Volcano_HI', label: 'Volcano, HI' },
  { value: 'Volga_WV', label: 'Volga, WV' },
  { value: 'Volga_IA', label: 'Volga, IA' },
  { value: 'Volga_SD', label: 'Volga, SD' },
  { value: 'Volin_SD', label: 'Volin, SD' },
  { value: 'Voltaire_ND', label: 'Voltaire, ND' },
  { value: 'Voluntown_CT', label: 'Voluntown, CT' },
  { value: 'Von Ormy_TX', label: 'Von Ormy, TX' },
  { value: 'Vona_CO', label: 'Vona, CO' },
  { value: 'Vonore_TN', label: 'Vonore, TN' },
  { value: 'Voorhees_NJ', label: 'Voorhees, NJ' },
  { value: 'Voorheesville_NY', label: 'Voorheesville, NY' },
  { value: 'Voss_TX', label: 'Voss, TX' },
  { value: 'Vossburg_MS', label: 'Vossburg, MS' },
  { value: 'Votaw_TX', label: 'Votaw, TX' },
  { value: 'Vowinckel_PA', label: 'Vowinckel, PA' },
  { value: 'Vredenburgh_AL', label: 'Vredenburgh, AL' },
  { value: 'Vulcan_MI', label: 'Vulcan, MI' },
  { value: 'Vulcan_MO', label: 'Vulcan, MO' },
  { value: 'Waban_MA', label: 'Waban, MA' },
  { value: 'Wabash_IN', label: 'Wabash, IN' },
  { value: 'Wabash_AR', label: 'Wabash, AR' },
  { value: 'Wabasha_MN', label: 'Wabasha, MN' },
  { value: 'Wabasso_FL', label: 'Wabasso, FL' },
  { value: 'Wabasso_MN', label: 'Wabasso, MN' },
  { value: 'Wabbaseka_AR', label: 'Wabbaseka, AR' },
  { value: 'Wabeno_WI', label: 'Wabeno, WI' },
  { value: 'Waccabuc_NY', label: 'Waccabuc, NY' },
  { value: 'Wachapreague_VA', label: 'Wachapreague, VA' },
  { value: 'Wacissa_FL', label: 'Wacissa, FL' },
  { value: 'Waco_NC', label: 'Waco, NC' },
  { value: 'Waco_GA', label: 'Waco, GA' },
  { value: 'Waco_KY', label: 'Waco, KY' },
  { value: 'Waco_NE', label: 'Waco, NE' },
  { value: 'Waco_TX', label: 'Waco, TX' },
  { value: 'Waconia_MN', label: 'Waconia, MN' },
  { value: 'Waddell_AZ', label: 'Waddell, AZ' },
  { value: 'Waddington_NY', label: 'Waddington, NY' },
  { value: 'Waddy_KY', label: 'Waddy, KY' },
  { value: 'Wade_NC', label: 'Wade, NC' },
  { value: 'Wadena_IA', label: 'Wadena, IA' },
  { value: 'Wadena_MN', label: 'Wadena, MN' },
  { value: 'Wadesboro_NC', label: 'Wadesboro, NC' },
  { value: 'Wadesville_IN', label: 'Wadesville, IN' },
  { value: 'Wading River_NY', label: 'Wading River, NY' },
  { value: 'Wadley_GA', label: 'Wadley, GA' },
  { value: 'Wadley_AL', label: 'Wadley, AL' },
  { value: 'Wadmalaw Island_SC', label: 'Wadmalaw Island, SC' },
  { value: 'Wadsworth_OH', label: 'Wadsworth, OH' },
  { value: 'Wadsworth_IL', label: 'Wadsworth, IL' },
  { value: 'Wadsworth_TX', label: 'Wadsworth, TX' },
  { value: 'Wadsworth_NV', label: 'Wadsworth, NV' },
  { value: 'Waelder_TX', label: 'Waelder, TX' },
  { value: 'Wagarville_AL', label: 'Wagarville, AL' },
  { value: 'Wagener_SC', label: 'Wagener, SC' },
  { value: 'Waggoner_IL', label: 'Waggoner, IL' },
  { value: 'Wagner_SD', label: 'Wagner, SD' },
  { value: 'Wagon Mound_NM', label: 'Wagon Mound, NM' },
  { value: 'Wagoner_OK', label: 'Wagoner, OK' },
  { value: 'Wagram_NC', label: 'Wagram, NC' },
  { value: 'Wahiawa_HI', label: 'Wahiawa, HI' },
  { value: 'Wahkiacus_WA', label: 'Wahkiacus, WA' },
  { value: 'Wahkon_MN', label: 'Wahkon, MN' },
  { value: 'Wahoo_NE', label: 'Wahoo, NE' },
  { value: 'Wahpeton_ND', label: 'Wahpeton, ND' },
  { value: 'Waialua_HI', label: 'Waialua, HI' },
  { value: 'Waianae_HI', label: 'Waianae, HI' },
  { value: 'Waikoloa_HI', label: 'Waikoloa, HI' },
  { value: 'Wailuku_HI', label: 'Wailuku, HI' },
  { value: 'Waimanalo_HI', label: 'Waimanalo, HI' },
  { value: 'Waimea_HI', label: 'Waimea, HI' },
  { value: 'Wainscott_NY', label: 'Wainscott, NY' },
  { value: 'Wainwright_OK', label: 'Wainwright, OK' },
  { value: 'Wainwright_AK', label: 'Wainwright, AK' },
  { value: 'Waipahu_HI', label: 'Waipahu, HI' },
  { value: 'Waite_ME', label: 'Waite, ME' },
  { value: 'Waite Park_MN', label: 'Waite Park, MN' },
  { value: 'Waiteville_WV', label: 'Waiteville, WV' },
  { value: 'Waitsburg_WA', label: 'Waitsburg, WA' },
  { value: 'Waitsfield_VT', label: 'Waitsfield, VT' },
  { value: 'Waka_TX', label: 'Waka, TX' },
  { value: 'Wakarusa_IN', label: 'Wakarusa, IN' },
  { value: 'Wakarusa_KS', label: 'Wakarusa, KS' },
  { value: 'Wake_VA', label: 'Wake, VA' },
  { value: 'Wake Forest_NC', label: 'Wake Forest, NC' },
  { value: 'Wakeeney_KS', label: 'Wakeeney, KS' },
  { value: 'Wakefield_MA', label: 'Wakefield, MA' },
  { value: 'Wakefield_RI', label: 'Wakefield, RI' },
  { value: 'Wakefield_VA', label: 'Wakefield, VA' },
  { value: 'Wakefield_MI', label: 'Wakefield, MI' },
  { value: 'Wakefield_KS', label: 'Wakefield, KS' },
  { value: 'Wakefield_NE', label: 'Wakefield, NE' },
  { value: 'Wakeman_OH', label: 'Wakeman, OH' },
  { value: 'Wakita_OK', label: 'Wakita, OK' },
  { value: 'Wakonda_SD', label: 'Wakonda, SD' },
  { value: 'Wakpala_SD', label: 'Wakpala, SD' },
  { value: 'Walbridge_OH', label: 'Walbridge, OH' },
  { value: 'Walcott_IA', label: 'Walcott, IA' },
  { value: 'Walcott_ND', label: 'Walcott, ND' },
  { value: 'Walcott_WY', label: 'Walcott, WY' },
  { value: 'Walden_NY', label: 'Walden, NY' },
  { value: 'Walden_CO', label: 'Walden, CO' },
  { value: 'Waldo_FL', label: 'Waldo, FL' },
  { value: 'Waldo_OH', label: 'Waldo, OH' },
  { value: 'Waldo_WI', label: 'Waldo, WI' },
  { value: 'Waldo_KS', label: 'Waldo, KS' },
  { value: 'Waldo_AR', label: 'Waldo, AR' },
  { value: 'Waldoboro_ME', label: 'Waldoboro, ME' },
  { value: 'Waldorf_MD', label: 'Waldorf, MD' },
  { value: 'Waldorf_MN', label: 'Waldorf, MN' },
  { value: 'Waldport_OR', label: 'Waldport, OR' },
  { value: 'Waldron_IN', label: 'Waldron, IN' },
  { value: 'Waldron_MI', label: 'Waldron, MI' },
  { value: 'Waldron_MO', label: 'Waldron, MO' },
  { value: 'Waldron_KS', label: 'Waldron, KS' },
  { value: 'Waldron_AR', label: 'Waldron, AR' },
  { value: 'Waldron_WA', label: 'Waldron, WA' },
  { value: 'Waldwick_NJ', label: 'Waldwick, NJ' },
  { value: 'Wales_MA', label: 'Wales, MA' },
  { value: 'Wales_WI', label: 'Wales, WI' },
  { value: 'Wales_ND', label: 'Wales, ND' },
  { value: 'Wales_UT', label: 'Wales, UT' },
  { value: 'Wales_AK', label: 'Wales, AK' },
  { value: 'Wales Center_NY', label: 'Wales Center, NY' },
  { value: 'Waleska_GA', label: 'Waleska, GA' },
  { value: 'Walford_IA', label: 'Walford, IA' },
  { value: 'Walhalla_SC', label: 'Walhalla, SC' },
  { value: 'Walhalla_MI', label: 'Walhalla, MI' },
  { value: 'Walhalla_ND', label: 'Walhalla, ND' },
  { value: 'Walhonding_OH', label: 'Walhonding, OH' },
  { value: 'Walker_WV', label: 'Walker, WV' },
  { value: 'Walker_KY', label: 'Walker, KY' },
  { value: 'Walker_IA', label: 'Walker, IA' },
  { value: 'Walker_MN', label: 'Walker, MN' },
  { value: 'Walker_SD', label: 'Walker, SD' },
  { value: 'Walker_MO', label: 'Walker, MO' },
  { value: 'Walker_KS', label: 'Walker, KS' },
  { value: 'Walker_LA', label: 'Walker, LA' },
  { value: 'Walkersville_MD', label: 'Walkersville, MD' },
  { value: 'Walkersville_WV', label: 'Walkersville, WV' },
  { value: 'Walkerton_VA', label: 'Walkerton, VA' },
  { value: 'Walkerton_IN', label: 'Walkerton, IN' },
  { value: 'Walkertown_NC', label: 'Walkertown, NC' },
  { value: 'Walkerville_MI', label: 'Walkerville, MI' },
  { value: 'Wall_SD', label: 'Wall, SD' },
  { value: 'Wall_TX', label: 'Wall, TX' },
  { value: 'Wall Lake_IA', label: 'Wall Lake, IA' },
  { value: 'Walla Walla_WA', label: 'Walla Walla, WA' },
  { value: 'Wallace_WV', label: 'Wallace, WV' },
  { value: 'Wallace_NC', label: 'Wallace, NC' },
  { value: 'Wallace_SC', label: 'Wallace, SC' },
  { value: 'Wallace_MI', label: 'Wallace, MI' },
  { value: 'Wallace_SD', label: 'Wallace, SD' },
  { value: 'Wallace_KS', label: 'Wallace, KS' },
  { value: 'Wallace_NE', label: 'Wallace, NE' },
  { value: 'Wallace_ID', label: 'Wallace, ID' },
  { value: 'Wallace_CA', label: 'Wallace, CA' },
  { value: 'Wallaceton_PA', label: 'Wallaceton, PA' },
  { value: 'Wallagrass_ME', label: 'Wallagrass, ME' },
  { value: 'Walland_TN', label: 'Walland, TN' },
  { value: 'Wallback_WV', label: 'Wallback, WV' },
  { value: 'Walled Lake_MI', label: 'Walled Lake, MI' },
  { value: 'Waller_TX', label: 'Waller, TX' },
  { value: 'Walling_TN', label: 'Walling, TN' },
  { value: 'Wallingford_VT', label: 'Wallingford, VT' },
  { value: 'Wallingford_CT', label: 'Wallingford, CT' },
  { value: 'Wallingford_PA', label: 'Wallingford, PA' },
  { value: 'Wallingford_KY', label: 'Wallingford, KY' },
  { value: 'Wallingford_IA', label: 'Wallingford, IA' },
  { value: 'Wallington_NJ', label: 'Wallington, NJ' },
  { value: 'Wallins Creek_KY', label: 'Wallins Creek, KY' },
  { value: 'Wallis_TX', label: 'Wallis, TX' },
  { value: 'Wallisville_TX', label: 'Wallisville, TX' },
  { value: 'Wallkill_NY', label: 'Wallkill, NY' },
  { value: 'Walloon Lake_MI', label: 'Walloon Lake, MI' },
  { value: 'Wallops Island_VA', label: 'Wallops Island, VA' },
  { value: 'Wallowa_OR', label: 'Wallowa, OR' },
  { value: 'Wallpack Center_NJ', label: 'Wallpack Center, NJ' },
  { value: 'Walls_MS', label: 'Walls, MS' },
  { value: 'Wallsburg_UT', label: 'Wallsburg, UT' },
  { value: 'Wallula_WA', label: 'Wallula, WA' },
  { value: 'Walnut_MS', label: 'Walnut, MS' },
  { value: 'Walnut_IA', label: 'Walnut, IA' },
  { value: 'Walnut_IL', label: 'Walnut, IL' },
  { value: 'Walnut_KS', label: 'Walnut, KS' },
  { value: 'Walnut_CA', label: 'Walnut, CA' },
  { value: 'Walnut Bottom_PA', label: 'Walnut Bottom, PA' },
  { value: 'Walnut Cove_NC', label: 'Walnut Cove, NC' },
  { value: 'Walnut Creek_OH', label: 'Walnut Creek, OH' },
  { value: 'Walnut Creek_CA', label: 'Walnut Creek, CA' },
  { value: 'Walnut Grove_AL', label: 'Walnut Grove, AL' },
  { value: 'Walnut Grove_MS', label: 'Walnut Grove, MS' },
  { value: 'Walnut Grove_MN', label: 'Walnut Grove, MN' },
  { value: 'Walnut Grove_MO', label: 'Walnut Grove, MO' },
  { value: 'Walnut Grove_CA', label: 'Walnut Grove, CA' },
  { value: 'Walnut Hill_IL', label: 'Walnut Hill, IL' },
  { value: 'Walnut Ridge_AR', label: 'Walnut Ridge, AR' },
  { value: 'Walnut Shade_MO', label: 'Walnut Shade, MO' },
  { value: 'Walnut Springs_TX', label: 'Walnut Springs, TX' },
  { value: 'Walnutport_PA', label: 'Walnutport, PA' },
  { value: 'Walpole_MA', label: 'Walpole, MA' },
  { value: 'Walpole_NH', label: 'Walpole, NH' },
  { value: 'Walpole_ME', label: 'Walpole, ME' },
  { value: 'Walsenburg_CO', label: 'Walsenburg, CO' },
  { value: 'Walsh_IL', label: 'Walsh, IL' },
  { value: 'Walsh_CO', label: 'Walsh, CO' },
  { value: 'Walshville_IL', label: 'Walshville, IL' },
  { value: 'Walston_PA', label: 'Walston, PA' },
  { value: 'Walstonburg_NC', label: 'Walstonburg, NC' },
  { value: 'Walterboro_SC', label: 'Walterboro, SC' },
  { value: 'Walters_OK', label: 'Walters, OK' },
  { value: 'Waltersburg_PA', label: 'Waltersburg, PA' },
  { value: 'Walterville_OR', label: 'Walterville, OR' },
  { value: 'Walthall_MS', label: 'Walthall, MS' },
  { value: 'Waltham_MA', label: 'Waltham, MA' },
  { value: 'Waltham_MN', label: 'Waltham, MN' },
  { value: 'Walthill_NE', label: 'Walthill, NE' },
  { value: 'Walthourville_GA', label: 'Walthourville, GA' },
  { value: 'Walton_NY', label: 'Walton, NY' },
  { value: 'Walton_WV', label: 'Walton, WV' },
  { value: 'Walton_KY', label: 'Walton, KY' },
  { value: 'Walton_IN', label: 'Walton, IN' },
  { value: 'Walton_KS', label: 'Walton, KS' },
  { value: 'Walton_NE', label: 'Walton, NE' },
  { value: 'Walton_OR', label: 'Walton, OR' },
  { value: 'Waltonville_IL', label: 'Waltonville, IL' },
  { value: 'Walworth_NY', label: 'Walworth, NY' },
  { value: 'Walworth_WI', label: 'Walworth, WI' },
  { value: 'Wamego_KS', label: 'Wamego, KS' },
  { value: 'Wampsville_NY', label: 'Wampsville, NY' },
  { value: 'Wampum_PA', label: 'Wampum, PA' },
  { value: 'Wamsutter_WY', label: 'Wamsutter, WY' },
  { value: 'Wana_WV', label: 'Wana, WV' },
  { value: 'Wanakena_NY', label: 'Wanakena, NY' },
  { value: 'Wanamingo_MN', label: 'Wanamingo, MN' },
  { value: 'Wanaque_NJ', label: 'Wanaque, NJ' },
  { value: 'Wanatah_IN', label: 'Wanatah, IN' },
  { value: 'Wanblee_SD', label: 'Wanblee, SD' },
  { value: 'Wanchese_NC', label: 'Wanchese, NC' },
  { value: 'Wanda_MN', label: 'Wanda, MN' },
  { value: 'Wanette_OK', label: 'Wanette, OK' },
  { value: 'Wann_OK', label: 'Wann, OK' },
  { value: 'Wannaska_MN', label: 'Wannaska, MN' },
  { value: 'Wantagh_NY', label: 'Wantagh, NY' },
  { value: 'Wapakoneta_OH', label: 'Wapakoneta, OH' },
  { value: 'Wapanucka_OK', label: 'Wapanucka, OK' },
  { value: 'Wapato_WA', label: 'Wapato, WA' },
  { value: 'Wapella_IL', label: 'Wapella, IL' },
  { value: 'Wapello_IA', label: 'Wapello, IA' },
  { value: 'Wapiti_WY', label: 'Wapiti, WY' },
  { value: 'Wappapello_MO', label: 'Wappapello, MO' },
  { value: 'Wappingers Falls_NY', label: 'Wappingers Falls, NY' },
  { value: 'Wapwallopen_PA', label: 'Wapwallopen, PA' },
  { value: 'War_WV', label: 'War, WV' },
  { value: 'Warba_MN', label: 'Warba, MN' },
  { value: 'Warbranch_KY', label: 'Warbranch, KY' },
  { value: 'Ward_SC', label: 'Ward, SC' },
  { value: 'Ward_AL', label: 'Ward, AL' },
  { value: 'Ward_AR', label: 'Ward, AR' },
  { value: 'Ward_CO', label: 'Ward, CO' },
  { value: 'Wardell_MO', label: 'Wardell, MO' },
  { value: 'Warden_WA', label: 'Warden, WA' },
  { value: 'Wardensville_WV', label: 'Wardensville, WV' },
  { value: 'Wardsboro_VT', label: 'Wardsboro, VT' },
  { value: 'Wardville_OK', label: 'Wardville, OK' },
  { value: 'Ware_MA', label: 'Ware, MA' },
  { value: 'Ware Shoals_SC', label: 'Ware Shoals, SC' },
  { value: 'Wareham_MA', label: 'Wareham, MA' },
  { value: 'Waresboro_GA', label: 'Waresboro, GA' },
  { value: 'Waretown_NJ', label: 'Waretown, NJ' },
  { value: 'Warfield_VA', label: 'Warfield, VA' },
  { value: 'Warfield_KY', label: 'Warfield, KY' },
  { value: 'Warfordsburg_PA', label: 'Warfordsburg, PA' },
  { value: 'Waring_TX', label: 'Waring, TX' },
  { value: 'Warm Springs_VA', label: 'Warm Springs, VA' },
  { value: 'Warm Springs_GA', label: 'Warm Springs, GA' },
  { value: 'Warm Springs_MT', label: 'Warm Springs, MT' },
  { value: 'Warm Springs_AR', label: 'Warm Springs, AR' },
  { value: 'Warm Springs_OR', label: 'Warm Springs, OR' },
  { value: 'Warminster_PA', label: 'Warminster, PA' },
  { value: 'Warne_NC', label: 'Warne, NC' },
  { value: 'Warner_NH', label: 'Warner, NH' },
  { value: 'Warner_SD', label: 'Warner, SD' },
  { value: 'Warner_OK', label: 'Warner, OK' },
  { value: 'Warner Robins_GA', label: 'Warner Robins, GA' },
  { value: 'Warner Springs_CA', label: 'Warner Springs, CA' },
  { value: 'Warners_NY', label: 'Warners, NY' },
  { value: 'Warnerville_NY', label: 'Warnerville, NY' },
  { value: 'Warnock_OH', label: 'Warnock, OH' },
  { value: 'Warren_MA', label: 'Warren, MA' },
  { value: 'Warren_RI', label: 'Warren, RI' },
  { value: 'Warren_NH', label: 'Warren, NH' },
  { value: 'Warren_ME', label: 'Warren, ME' },
  { value: 'Warren_VT', label: 'Warren, VT' },
  { value: 'Warren_NJ', label: 'Warren, NJ' },
  { value: 'Warren_PA', label: 'Warren, PA' },
  { value: 'Warren_OH', label: 'Warren, OH' },
  { value: 'Warren_IN', label: 'Warren, IN' },
  { value: 'Warren_MI', label: 'Warren, MI' },
  { value: 'Warren_MN', label: 'Warren, MN' },
  { value: 'Warren_IL', label: 'Warren, IL' },
  { value: 'Warren_AR', label: 'Warren, AR' },
  { value: 'Warren_TX', label: 'Warren, TX' },
  { value: 'Warren_OR', label: 'Warren, OR' },
  { value: 'Warren Center_PA', label: 'Warren Center, PA' },
  { value: 'Warrendale_PA', label: 'Warrendale, PA' },
  { value: 'Warrens_WI', label: 'Warrens, WI' },
  { value: 'Warrensburg_NY', label: 'Warrensburg, NY' },
  { value: 'Warrensburg_IL', label: 'Warrensburg, IL' },
  { value: 'Warrensburg_MO', label: 'Warrensburg, MO' },
  { value: 'Warrensville_NC', label: 'Warrensville, NC' },
  { value: 'Warrenton_VA', label: 'Warrenton, VA' },
  { value: 'Warrenton_NC', label: 'Warrenton, NC' },
  { value: 'Warrenton_GA', label: 'Warrenton, GA' },
  { value: 'Warrenton_MO', label: 'Warrenton, MO' },
  { value: 'Warrenton_OR', label: 'Warrenton, OR' },
  { value: 'Warrenville_SC', label: 'Warrenville, SC' },
  { value: 'Warrenville_IL', label: 'Warrenville, IL' },
  { value: 'Warrington_PA', label: 'Warrington, PA' },
  { value: 'Warrior_AL', label: 'Warrior, AL' },
  { value: 'Warriormine_WV', label: 'Warriormine, WV' },
  { value: 'Warriors Mark_PA', label: 'Warriors Mark, PA' },
  { value: 'Warroad_MN', label: 'Warroad, MN' },
  { value: 'Warsaw_NY', label: 'Warsaw, NY' },
  { value: 'Warsaw_VA', label: 'Warsaw, VA' },
  { value: 'Warsaw_NC', label: 'Warsaw, NC' },
  { value: 'Warsaw_KY', label: 'Warsaw, KY' },
  { value: 'Warsaw_OH', label: 'Warsaw, OH' },
  { value: 'Warsaw_IN', label: 'Warsaw, IN' },
  { value: 'Warsaw_MN', label: 'Warsaw, MN' },
  { value: 'Warsaw_IL', label: 'Warsaw, IL' },
  { value: 'Warsaw_MO', label: 'Warsaw, MO' },
  { value: 'Wartburg_TN', label: 'Wartburg, TN' },
  { value: 'Warthen_GA', label: 'Warthen, GA' },
  { value: 'Wartrace_TN', label: 'Wartrace, TN' },
  { value: 'Warwick_MA', label: 'Warwick, MA' },
  { value: 'Warwick_RI', label: 'Warwick, RI' },
  { value: 'Warwick_NY', label: 'Warwick, NY' },
  { value: 'Warwick_MD', label: 'Warwick, MD' },
  { value: 'Warwick_GA', label: 'Warwick, GA' },
  { value: 'Warwick_ND', label: 'Warwick, ND' },
  { value: 'Wasco_IL', label: 'Wasco, IL' },
  { value: 'Wasco_CA', label: 'Wasco, CA' },
  { value: 'Wasco_OR', label: 'Wasco, OR' },
  { value: 'Waseca_MN', label: 'Waseca, MN' },
  { value: 'Washburn_ME', label: 'Washburn, ME' },
  { value: 'Washburn_TN', label: 'Washburn, TN' },
  { value: 'Washburn_WI', label: 'Washburn, WI' },
  { value: 'Washburn_ND', label: 'Washburn, ND' },
  { value: 'Washburn_IL', label: 'Washburn, IL' },
  { value: 'Washburn_MO', label: 'Washburn, MO' },
  { value: 'Washington_NH', label: 'Washington, NH' },
  { value: 'Washington_ME', label: 'Washington, ME' },
  { value: 'Washington_VT', label: 'Washington, VT' },
  { value: 'Washington_CT', label: 'Washington, CT' },
  { value: 'Washington_NJ', label: 'Washington, NJ' },
  { value: 'Washington_PA', label: 'Washington, PA' },
  { value: 'Washington_DC', label: 'Washington, DC' },
  { value: 'Washington_VA', label: 'Washington, VA' },
  { value: 'Washington_WV', label: 'Washington, WV' },
  { value: 'Washington_NC', label: 'Washington, NC' },
  { value: 'Washington_GA', label: 'Washington, GA' },
  { value: 'Washington_IN', label: 'Washington, IN' },
  { value: 'Washington_MI', label: 'Washington, MI' },
  { value: 'Washington_IA', label: 'Washington, IA' },
  { value: 'Washington_IL', label: 'Washington, IL' },
  { value: 'Washington_MO', label: 'Washington, MO' },
  { value: 'Washington_KS', label: 'Washington, KS' },
  { value: 'Washington_NE', label: 'Washington, NE' },
  { value: 'Washington_LA', label: 'Washington, LA' },
  { value: 'Washington_AR', label: 'Washington, AR' },
  { value: 'Washington_OK', label: 'Washington, OK' },
  { value: 'Washington_TX', label: 'Washington, TX' },
  { value: 'Washington_UT', label: 'Washington, UT' },
  { value: 'Washington_CA', label: 'Washington, CA' },
  { value: 'Washington Boro_PA', label: 'Washington Boro, PA' },
  { value: 'Washington Court House_OH', label: 'Washington Court House, OH' },
  { value: 'Washington Crossing_PA', label: 'Washington Crossing, PA' },
  { value: 'Washington Depot_CT', label: 'Washington Depot, CT' },
  { value: 'Washington Grove_MD', label: 'Washington Grove, MD' },
  { value: 'Washington Island_WI', label: 'Washington Island, WI' },
  { value: 'Washington Navy Yard_DC', label: 'Washington Navy Yard, DC' },
  { value: 'Washingtonville_NY', label: 'Washingtonville, NY' },
  { value: 'Washingtonville_PA', label: 'Washingtonville, PA' },
  { value: 'Washingtonville_OH', label: 'Washingtonville, OH' },
  { value: 'Washoe Valley_NV', label: 'Washoe Valley, NV' },
  { value: 'Washougal_WA', label: 'Washougal, WA' },
  { value: 'Washta_IA', label: 'Washta, IA' },
  { value: 'Washtucna_WA', label: 'Washtucna, WA' },
  { value: 'Wasilla_AK', label: 'Wasilla, AK' },
  { value: 'Waskish_MN', label: 'Waskish, MN' },
  { value: 'Waskom_TX', label: 'Waskom, TX' },
  { value: 'Wasola_MO', label: 'Wasola, MO' },
  { value: 'Wassaic_NY', label: 'Wassaic, NY' },
  { value: 'Wasta_SD', label: 'Wasta, SD' },
  { value: 'Wataga_IL', label: 'Wataga, IL' },
  { value: 'Watauga_TN', label: 'Watauga, TN' },
  { value: 'Watauga_SD', label: 'Watauga, SD' },
  { value: 'Watchung_NJ', label: 'Watchung, NJ' },
  { value: 'Water Mill_NY', label: 'Water Mill, NY' },
  { value: 'Water Valley_MS', label: 'Water Valley, MS' },
  { value: 'Water Valley_KY', label: 'Water Valley, KY' },
  { value: 'Water Valley_TX', label: 'Water Valley, TX' },
  { value: 'Water View_VA', label: 'Water View, VA' },
  { value: 'Waterboro_ME', label: 'Waterboro, ME' },
  { value: 'Waterbury_VT', label: 'Waterbury, VT' },
  { value: 'Waterbury_CT', label: 'Waterbury, CT' },
  { value: 'Waterbury_NE', label: 'Waterbury, NE' },
  { value: 'Waterbury Center_VT', label: 'Waterbury Center, VT' },
  { value: 'Waterfall_PA', label: 'Waterfall, PA' },
  { value: 'Waterflow_NM', label: 'Waterflow, NM' },
  { value: 'Waterford_ME', label: 'Waterford, ME' },
  { value: 'Waterford_CT', label: 'Waterford, CT' },
  { value: 'Waterford_NY', label: 'Waterford, NY' },
  { value: 'Waterford_PA', label: 'Waterford, PA' },
  { value: 'Waterford_VA', label: 'Waterford, VA' },
  { value: 'Waterford_MS', label: 'Waterford, MS' },
  { value: 'Waterford_OH', label: 'Waterford, OH' },
  { value: 'Waterford_MI', label: 'Waterford, MI' },
  { value: 'Waterford_WI', label: 'Waterford, WI' },
  { value: 'Waterford_CA', label: 'Waterford, CA' },
  { value: 'Waterford Works_NJ', label: 'Waterford Works, NJ' },
  { value: 'Waterloo_NY', label: 'Waterloo, NY' },
  { value: 'Waterloo_SC', label: 'Waterloo, SC' },
  { value: 'Waterloo_AL', label: 'Waterloo, AL' },
  { value: 'Waterloo_OH', label: 'Waterloo, OH' },
  { value: 'Waterloo_IN', label: 'Waterloo, IN' },
  { value: 'Waterloo_IA', label: 'Waterloo, IA' },
  { value: 'Waterloo_WI', label: 'Waterloo, WI' },
  { value: 'Waterloo_IL', label: 'Waterloo, IL' },
  { value: 'Waterloo_NE', label: 'Waterloo, NE' },
  { value: 'Waterman_IL', label: 'Waterman, IL' },
  { value: 'Waterport_NY', label: 'Waterport, NY' },
  { value: 'Waterproof_LA', label: 'Waterproof, LA' },
  { value: 'Watersmeet_MI', label: 'Watersmeet, MI' },
  { value: 'Watertown_MA', label: 'Watertown, MA' },
  { value: 'Watertown_CT', label: 'Watertown, CT' },
  { value: 'Watertown_NY', label: 'Watertown, NY' },
  { value: 'Watertown_TN', label: 'Watertown, TN' },
  { value: 'Watertown_WI', label: 'Watertown, WI' },
  { value: 'Watertown_MN', label: 'Watertown, MN' },
  { value: 'Watertown_SD', label: 'Watertown, SD' },
  { value: 'Waterville_ME', label: 'Waterville, ME' },
  { value: 'Waterville_VT', label: 'Waterville, VT' },
  { value: 'Waterville_NY', label: 'Waterville, NY' },
  { value: 'Waterville_PA', label: 'Waterville, PA' },
  { value: 'Waterville_OH', label: 'Waterville, OH' },
  { value: 'Waterville_IA', label: 'Waterville, IA' },
  { value: 'Waterville_MN', label: 'Waterville, MN' },
  { value: 'Waterville_KS', label: 'Waterville, KS' },
  { value: 'Waterville_WA', label: 'Waterville, WA' },
  { value: 'Waterville Valley_NH', label: 'Waterville Valley, NH' },
  { value: 'Watervliet_NY', label: 'Watervliet, NY' },
  { value: 'Watervliet_MI', label: 'Watervliet, MI' },
  { value: 'Watford City_ND', label: 'Watford City, ND' },
  { value: 'Wathena_KS', label: 'Wathena, KS' },
  { value: 'Watkins_IA', label: 'Watkins, IA' },
  { value: 'Watkins_MN', label: 'Watkins, MN' },
  { value: 'Watkins_CO', label: 'Watkins, CO' },
  { value: 'Watkins Glen_NY', label: 'Watkins Glen, NY' },
  { value: 'Watkinsville_GA', label: 'Watkinsville, GA' },
  { value: 'Watonga_OK', label: 'Watonga, OK' },
  { value: 'Watrous_NM', label: 'Watrous, NM' },
  { value: 'Watseka_IL', label: 'Watseka, IL' },
  { value: 'Watson_AL', label: 'Watson, AL' },
  { value: 'Watson_MN', label: 'Watson, MN' },
  { value: 'Watson_IL', label: 'Watson, IL' },
  { value: 'Watson_MO', label: 'Watson, MO' },
  { value: 'Watson_LA', label: 'Watson, LA' },
  { value: 'Watson_AR', label: 'Watson, AR' },
  { value: 'Watson_OK', label: 'Watson, OK' },
  { value: 'Watsontown_PA', label: 'Watsontown, PA' },
  { value: 'Watsonville_CA', label: 'Watsonville, CA' },
  { value: 'Watton_MI', label: 'Watton, MI' },
  { value: 'Watts_OK', label: 'Watts, OK' },
  { value: 'Wattsburg_PA', label: 'Wattsburg, PA' },
  { value: 'Waubay_SD', label: 'Waubay, SD' },
  { value: 'Waubun_MN', label: 'Waubun, MN' },
  { value: 'Wauchula_FL', label: 'Wauchula, FL' },
  { value: 'Waucoma_IA', label: 'Waucoma, IA' },
  { value: 'Wauconda_IL', label: 'Wauconda, IL' },
  { value: 'Wauconda_WA', label: 'Wauconda, WA' },
  { value: 'Waukau_WI', label: 'Waukau, WI' },
  { value: 'Waukee_IA', label: 'Waukee, IA' },
  { value: 'Waukegan_IL', label: 'Waukegan, IL' },
  { value: 'Waukesha_WI', label: 'Waukesha, WI' },
  { value: 'Waukomis_OK', label: 'Waukomis, OK' },
  { value: 'Waukon_IA', label: 'Waukon, IA' },
  { value: 'Waunakee_WI', label: 'Waunakee, WI' },
  { value: 'Wauneta_NE', label: 'Wauneta, NE' },
  { value: 'Waupaca_WI', label: 'Waupaca, WI' },
  { value: 'Waupun_WI', label: 'Waupun, WI' },
  { value: 'Wauregan_CT', label: 'Wauregan, CT' },
  { value: 'Waurika_OK', label: 'Waurika, OK' },
  { value: 'Wausa_NE', label: 'Wausa, NE' },
  { value: 'Wausau_FL', label: 'Wausau, FL' },
  { value: 'Wausau_WI', label: 'Wausau, WI' },
  { value: 'Wausaukee_WI', label: 'Wausaukee, WI' },
  { value: 'Wauseon_OH', label: 'Wauseon, OH' },
  { value: 'Wautoma_WI', label: 'Wautoma, WI' },
  { value: 'Wauzeka_WI', label: 'Wauzeka, WI' },
  { value: 'Waveland_MS', label: 'Waveland, MS' },
  { value: 'Waveland_IN', label: 'Waveland, IN' },
  { value: 'Waverly_NY', label: 'Waverly, NY' },
  { value: 'Waverly_PA', label: 'Waverly, PA' },
  { value: 'Waverly_VA', label: 'Waverly, VA' },
  { value: 'Waverly_WV', label: 'Waverly, WV' },
  { value: 'Waverly_GA', label: 'Waverly, GA' },
  { value: 'Waverly_FL', label: 'Waverly, FL' },
  { value: 'Waverly_AL', label: 'Waverly, AL' },
  { value: 'Waverly_TN', label: 'Waverly, TN' },
  { value: 'Waverly_KY', label: 'Waverly, KY' },
  { value: 'Waverly_OH', label: 'Waverly, OH' },
  { value: 'Waverly_IA', label: 'Waverly, IA' },
  { value: 'Waverly_MN', label: 'Waverly, MN' },
  { value: 'Waverly_IL', label: 'Waverly, IL' },
  { value: 'Waverly_MO', label: 'Waverly, MO' },
  { value: 'Waverly_KS', label: 'Waverly, KS' },
  { value: 'Waverly_NE', label: 'Waverly, NE' },
  { value: 'Waverly_WA', label: 'Waverly, WA' },
  { value: 'Waverly Hall_GA', label: 'Waverly Hall, GA' },
  { value: 'Waves_NC', label: 'Waves, NC' },
  { value: 'Wawaka_IN', label: 'Wawaka, IN' },
  { value: 'Wawarsing_NY', label: 'Wawarsing, NY' },
  { value: 'Waxahachie_TX', label: 'Waxahachie, TX' },
  { value: 'Waxhaw_NC', label: 'Waxhaw, NC' },
  { value: 'Wayan_ID', label: 'Wayan, ID' },
  { value: 'Waycross_GA', label: 'Waycross, GA' },
  { value: 'Wayland_MA', label: 'Wayland, MA' },
  { value: 'Wayland_NY', label: 'Wayland, NY' },
  { value: 'Wayland_KY', label: 'Wayland, KY' },
  { value: 'Wayland_OH', label: 'Wayland, OH' },
  { value: 'Wayland_MI', label: 'Wayland, MI' },
  { value: 'Wayland_IA', label: 'Wayland, IA' },
  { value: 'Wayland_MO', label: 'Wayland, MO' },
  { value: 'Waymart_PA', label: 'Waymart, PA' },
  { value: 'Wayne_ME', label: 'Wayne, ME' },
  { value: 'Wayne_NJ', label: 'Wayne, NJ' },
  { value: 'Wayne_NY', label: 'Wayne, NY' },
  { value: 'Wayne_PA', label: 'Wayne, PA' },
  { value: 'Wayne_WV', label: 'Wayne, WV' },
  { value: 'Wayne_OH', label: 'Wayne, OH' },
  { value: 'Wayne_MI', label: 'Wayne, MI' },
  { value: 'Wayne_IL', label: 'Wayne, IL' },
  { value: 'Wayne_NE', label: 'Wayne, NE' },
  { value: 'Wayne_OK', label: 'Wayne, OK' },
  { value: 'Wayne City_IL', label: 'Wayne City, IL' },
  { value: 'Waynesboro_PA', label: 'Waynesboro, PA' },
  { value: 'Waynesboro_VA', label: 'Waynesboro, VA' },
  { value: 'Waynesboro_GA', label: 'Waynesboro, GA' },
  { value: 'Waynesboro_TN', label: 'Waynesboro, TN' },
  { value: 'Waynesboro_MS', label: 'Waynesboro, MS' },
  { value: 'Waynesburg_PA', label: 'Waynesburg, PA' },
  { value: 'Waynesburg_KY', label: 'Waynesburg, KY' },
  { value: 'Waynesburg_OH', label: 'Waynesburg, OH' },
  { value: 'Waynesfield_OH', label: 'Waynesfield, OH' },
  { value: 'Waynesville_NC', label: 'Waynesville, NC' },
  { value: 'Waynesville_GA', label: 'Waynesville, GA' },
  { value: 'Waynesville_OH', label: 'Waynesville, OH' },
  { value: 'Waynesville_IL', label: 'Waynesville, IL' },
  { value: 'Waynesville_MO', label: 'Waynesville, MO' },
  { value: 'Waynetown_IN', label: 'Waynetown, IN' },
  { value: 'Waynoka_OK', label: 'Waynoka, OK' },
  { value: 'Wayside_WV', label: 'Wayside, WV' },
  { value: 'Wayside_TX', label: 'Wayside, TX' },
  { value: 'Wayzata_MN', label: 'Wayzata, MN' },
  { value: 'Weare_NH', label: 'Weare, NH' },
  { value: 'Weatherby_MO', label: 'Weatherby, MO' },
  { value: 'Weatherford_OK', label: 'Weatherford, OK' },
  { value: 'Weatherford_TX', label: 'Weatherford, TX' },
  { value: 'Weatherly_PA', label: 'Weatherly, PA' },
  { value: 'Weatogue_CT', label: 'Weatogue, CT' },
  { value: 'Weaubleau_MO', label: 'Weaubleau, MO' },
  { value: 'Weaver_AL', label: 'Weaver, AL' },
  { value: 'Weaverville_NC', label: 'Weaverville, NC' },
  { value: 'Weaverville_CA', label: 'Weaverville, CA' },
  { value: 'Webb_AL', label: 'Webb, AL' },
  { value: 'Webb_MS', label: 'Webb, MS' },
  { value: 'Webb_IA', label: 'Webb, IA' },
  { value: 'Webb City_MO', label: 'Webb City, MO' },
  { value: 'Webber_KS', label: 'Webber, KS' },
  { value: 'Webbers Falls_OK', label: 'Webbers Falls, OK' },
  { value: 'Webberville_MI', label: 'Webberville, MI' },
  { value: 'Webbville_KY', label: 'Webbville, KY' },
  { value: 'Weber City_VA', label: 'Weber City, VA' },
  { value: 'Webster_MA', label: 'Webster, MA' },
  { value: 'Webster_NY', label: 'Webster, NY' },
  { value: 'Webster_PA', label: 'Webster, PA' },
  { value: 'Webster_NC', label: 'Webster, NC' },
  { value: 'Webster_FL', label: 'Webster, FL' },
  { value: 'Webster_KY', label: 'Webster, KY' },
  { value: 'Webster_IN', label: 'Webster, IN' },
  { value: 'Webster_IA', label: 'Webster, IA' },
  { value: 'Webster_WI', label: 'Webster, WI' },
  { value: 'Webster_MN', label: 'Webster, MN' },
  { value: 'Webster_SD', label: 'Webster, SD' },
  { value: 'Webster_ND', label: 'Webster, ND' },
  { value: 'Webster_TX', label: 'Webster, TX' },
  { value: 'Webster City_IA', label: 'Webster City, IA' },
  { value: 'Webster Springs_WV', label: 'Webster Springs, WV' },
  { value: 'Websterville_VT', label: 'Websterville, VT' },
  { value: 'Wedgefield_SC', label: 'Wedgefield, SC' },
  { value: 'Wedowee_AL', label: 'Wedowee, AL' },
  { value: 'Wedron_IL', label: 'Wedron, IL' },
  { value: 'Weed_NM', label: 'Weed, NM' },
  { value: 'Weed_CA', label: 'Weed, CA' },
  { value: 'Weedsport_NY', label: 'Weedsport, NY' },
  { value: 'Weedville_PA', label: 'Weedville, PA' },
  { value: 'Weehawken_NJ', label: 'Weehawken, NJ' },
  { value: 'Weeksbury_KY', label: 'Weeksbury, KY' },
  { value: 'Weems_VA', label: 'Weems, VA' },
  { value: 'Weeping Water_NE', label: 'Weeping Water, NE' },
  { value: 'Weesatche_TX', label: 'Weesatche, TX' },
  { value: 'Weidman_MI', label: 'Weidman, MI' },
  { value: 'Weikert_PA', label: 'Weikert, PA' },
  { value: 'Weimar_TX', label: 'Weimar, TX' },
  { value: 'Weimar_CA', label: 'Weimar, CA' },
  { value: 'Weiner_AR', label: 'Weiner, AR' },
  { value: 'Weinert_TX', label: 'Weinert, TX' },
  { value: 'Weippe_ID', label: 'Weippe, ID' },
  { value: 'Weir_MS', label: 'Weir, MS' },
  { value: 'Weir_KS', label: 'Weir, KS' },
  { value: 'Weir_TX', label: 'Weir, TX' },
  { value: 'Weirsdale_FL', label: 'Weirsdale, FL' },
  { value: 'Weirton_WV', label: 'Weirton, WV' },
  { value: 'Weiser_ID', label: 'Weiser, ID' },
  { value: 'Welaka_FL', label: 'Welaka, FL' },
  { value: 'Welch_WV', label: 'Welch, WV' },
  { value: 'Welch_MN', label: 'Welch, MN' },
  { value: 'Welch_OK', label: 'Welch, OK' },
  { value: 'Welch_TX', label: 'Welch, TX' },
  { value: 'Welches_OR', label: 'Welches, OR' },
  { value: 'Welcome_MD', label: 'Welcome, MD' },
  { value: 'Welcome_NC', label: 'Welcome, NC' },
  { value: 'Welcome_MN', label: 'Welcome, MN' },
  { value: 'Weld_ME', label: 'Weld, ME' },
  { value: 'Welda_KS', label: 'Welda, KS' },
  { value: 'Weldon_NC', label: 'Weldon, NC' },
  { value: 'Weldon_IA', label: 'Weldon, IA' },
  { value: 'Weldon_IL', label: 'Weldon, IL' },
  { value: 'Weldon_CA', label: 'Weldon, CA' },
  { value: 'Weldona_CO', label: 'Weldona, CO' },
  { value: 'Weleetka_OK', label: 'Weleetka, OK' },
  { value: 'Wellborn_FL', label: 'Wellborn, FL' },
  { value: 'Wellersburg_PA', label: 'Wellersburg, PA' },
  { value: 'Wellesley_MA', label: 'Wellesley, MA' },
  { value: 'Wellesley Hills_MA', label: 'Wellesley Hills, MA' },
  { value: 'Wellesley Island_NY', label: 'Wellesley Island, NY' },
  { value: 'Wellfleet_MA', label: 'Wellfleet, MA' },
  { value: 'Wellfleet_NE', label: 'Wellfleet, NE' },
  { value: 'Wellford_SC', label: 'Wellford, SC' },
  { value: 'Welling_OK', label: 'Welling, OK' },
  { value: 'Wellington_FL', label: 'Wellington, FL' },
  { value: 'Wellington_AL', label: 'Wellington, AL' },
  { value: 'Wellington_KY', label: 'Wellington, KY' },
  { value: 'Wellington_OH', label: 'Wellington, OH' },
  { value: 'Wellington_IL', label: 'Wellington, IL' },
  { value: 'Wellington_MO', label: 'Wellington, MO' },
  { value: 'Wellington_KS', label: 'Wellington, KS' },
  { value: 'Wellington_TX', label: 'Wellington, TX' },
  { value: 'Wellington_CO', label: 'Wellington, CO' },
  { value: 'Wellington_UT', label: 'Wellington, UT' },
  { value: 'Wellington_NV', label: 'Wellington, NV' },
  { value: 'Wellman_IA', label: 'Wellman, IA' },
  { value: 'Wellman_TX', label: 'Wellman, TX' },
  { value: 'Wellpinit_WA', label: 'Wellpinit, WA' },
  { value: 'Wells_ME', label: 'Wells, ME' },
  { value: 'Wells_VT', label: 'Wells, VT' },
  { value: 'Wells_NY', label: 'Wells, NY' },
  { value: 'Wells_MI', label: 'Wells, MI' },
  { value: 'Wells_MN', label: 'Wells, MN' },
  { value: 'Wells_TX', label: 'Wells, TX' },
  { value: 'Wells_NV', label: 'Wells, NV' },
  { value: 'Wells Bridge_NY', label: 'Wells Bridge, NY' },
  { value: 'Wells River_VT', label: 'Wells River, VT' },
  { value: 'Wells Tannery_PA', label: 'Wells Tannery, PA' },
  { value: 'Wellsboro_PA', label: 'Wellsboro, PA' },
  { value: 'Wellsburg_NY', label: 'Wellsburg, NY' },
  { value: 'Wellsburg_WV', label: 'Wellsburg, WV' },
  { value: 'Wellsburg_IA', label: 'Wellsburg, IA' },
  { value: 'Wellston_OH', label: 'Wellston, OH' },
  { value: 'Wellston_MI', label: 'Wellston, MI' },
  { value: 'Wellston_OK', label: 'Wellston, OK' },
  { value: 'Wellsville_NY', label: 'Wellsville, NY' },
  { value: 'Wellsville_PA', label: 'Wellsville, PA' },
  { value: 'Wellsville_OH', label: 'Wellsville, OH' },
  { value: 'Wellsville_MO', label: 'Wellsville, MO' },
  { value: 'Wellsville_KS', label: 'Wellsville, KS' },
  { value: 'Wellsville_UT', label: 'Wellsville, UT' },
  { value: 'Wellton_AZ', label: 'Wellton, AZ' },
  { value: 'Welsh_LA', label: 'Welsh, LA' },
  { value: 'Welton_IA', label: 'Welton, IA' },
  { value: 'Wenatchee_WA', label: 'Wenatchee, WA' },
  { value: 'Wendel_PA', label: 'Wendel, PA' },
  { value: 'Wendel_CA', label: 'Wendel, CA' },
  { value: 'Wendell_MA', label: 'Wendell, MA' },
  { value: 'Wendell_NC', label: 'Wendell, NC' },
  { value: 'Wendell_MN', label: 'Wendell, MN' },
  { value: 'Wendell_ID', label: 'Wendell, ID' },
  { value: 'Wendell Depot_MA', label: 'Wendell Depot, MA' },
  { value: 'Wenden_AZ', label: 'Wenden, AZ' },
  { value: 'Wendover_KY', label: 'Wendover, KY' },
  { value: 'Wendover_UT', label: 'Wendover, UT' },
  { value: 'Wenham_MA', label: 'Wenham, MA' },
  { value: 'Wenona_IL', label: 'Wenona, IL' },
  { value: 'Wenonah_NJ', label: 'Wenonah, NJ' },
  { value: 'Wentworth_NH', label: 'Wentworth, NH' },
  { value: 'Wentworth_SD', label: 'Wentworth, SD' },
  { value: 'Wentworth_MO', label: 'Wentworth, MO' },
  { value: 'Wentzville_MO', label: 'Wentzville, MO' },
  { value: 'Weogufka_AL', label: 'Weogufka, AL' },
  { value: 'Weott_CA', label: 'Weott, CA' },
  { value: 'Wernersville_PA', label: 'Wernersville, PA' },
  { value: 'Wesco_MO', label: 'Wesco, MO' },
  { value: 'Weskan_KS', label: 'Weskan, KS' },
  { value: 'Weslaco_TX', label: 'Weslaco, TX' },
  { value: 'Wesley_ME', label: 'Wesley, ME' },
  { value: 'Wesley_IA', label: 'Wesley, IA' },
  { value: 'Wesley_AR', label: 'Wesley, AR' },
  { value: 'Wesley Chapel_FL', label: 'Wesley Chapel, FL' },
  { value: 'Wessington_SD', label: 'Wessington, SD' },
  { value: 'Wessington Springs_SD', label: 'Wessington Springs, SD' },
  { value: 'Wesson_MS', label: 'Wesson, MS' },
  { value: 'West_MS', label: 'West, MS' },
  { value: 'West_TX', label: 'West, TX' },
  { value: 'West Alexander_PA', label: 'West Alexander, PA' },
  { value: 'West Alexandria_OH', label: 'West Alexandria, OH' },
  { value: 'West Alton_MO', label: 'West Alton, MO' },
  { value: 'West Augusta_VA', label: 'West Augusta, VA' },
  { value: 'West Babylon_NY', label: 'West Babylon, NY' },
  { value: 'West Baden Springs_IN', label: 'West Baden Springs, IN' },
  { value: 'West Baldwin_ME', label: 'West Baldwin, ME' },
  { value: 'West Barnstable_MA', label: 'West Barnstable, MA' },
  { value: 'West Bend_IA', label: 'West Bend, IA' },
  { value: 'West Bend_WI', label: 'West Bend, WI' },
  { value: 'West Berlin_NJ', label: 'West Berlin, NJ' },
  { value: 'West Bethel_ME', label: 'West Bethel, ME' },
  { value: 'West Blocton_AL', label: 'West Blocton, AL' },
  { value: 'West Bloomfield_NY', label: 'West Bloomfield, NY' },
  { value: 'West Bloomfield_MI', label: 'West Bloomfield, MI' },
  { value: 'West Boothbay Harbor_ME', label: 'West Boothbay Harbor, ME' },
  { value: 'West Boylston_MA', label: 'West Boylston, MA' },
  { value: 'West Branch_MI', label: 'West Branch, MI' },
  { value: 'West Branch_IA', label: 'West Branch, IA' },
  { value: 'West Bridgewater_MA', label: 'West Bridgewater, MA' },
  { value: 'West Brookfield_MA', label: 'West Brookfield, MA' },
  { value: 'West Brooklyn_IL', label: 'West Brooklyn, IL' },
  { value: 'West Burke_VT', label: 'West Burke, VT' },
  { value: 'West Burlington_NY', label: 'West Burlington, NY' },
  { value: 'West Burlington_IA', label: 'West Burlington, IA' },
  { value: 'West Camp_NY', label: 'West Camp, NY' },
  { value: 'West Charleston_VT', label: 'West Charleston, VT' },
  { value: 'West Chazy_NY', label: 'West Chazy, NY' },
  { value: 'West Chester_PA', label: 'West Chester, PA' },
  { value: 'West Chester_OH', label: 'West Chester, OH' },
  { value: 'West Chester_IA', label: 'West Chester, IA' },
  { value: 'West Chesterfield_MA', label: 'West Chesterfield, MA' },
  { value: 'West Chesterfield_NH', label: 'West Chesterfield, NH' },
  { value: 'West Chicago_IL', label: 'West Chicago, IL' },
  { value: 'West College Corner_IN', label: 'West College Corner, IN' },
  { value: 'West Columbia_WV', label: 'West Columbia, WV' },
  { value: 'West Columbia_SC', label: 'West Columbia, SC' },
  { value: 'West Columbia_TX', label: 'West Columbia, TX' },
  { value: 'West Concord_MN', label: 'West Concord, MN' },
  { value: 'West Cornwall_CT', label: 'West Cornwall, CT' },
  { value: 'West Covina_CA', label: 'West Covina, CA' },
  { value: 'West Coxsackie_NY', label: 'West Coxsackie, NY' },
  { value: 'West Creek_NJ', label: 'West Creek, NJ' },
  { value: 'West Danville_VT', label: 'West Danville, VT' },
  { value: 'West Davenport_NY', label: 'West Davenport, NY' },
  { value: 'West Decatur_PA', label: 'West Decatur, PA' },
  { value: 'West Dennis_MA', label: 'West Dennis, MA' },
  { value: 'West Des Moines_IA', label: 'West Des Moines, IA' },
  { value: 'West Dover_VT', label: 'West Dover, VT' },
  { value: 'West Eaton_NY', label: 'West Eaton, NY' },
  { value: 'West Edmeston_NY', label: 'West Edmeston, NY' },
  { value: 'West Elizabeth_PA', label: 'West Elizabeth, PA' },
  { value: 'West Elkton_OH', label: 'West Elkton, OH' },
  { value: 'West End_NC', label: 'West End, NC' },
  { value: 'West Enfield_ME', label: 'West Enfield, ME' },
  { value: 'West Fairlee_VT', label: 'West Fairlee, VT' },
  { value: 'West Falls_NY', label: 'West Falls, NY' },
  { value: 'West Fargo_ND', label: 'West Fargo, ND' },
  { value: 'West Farmington_ME', label: 'West Farmington, ME' },
  { value: 'West Farmington_OH', label: 'West Farmington, OH' },
  { value: 'West Finley_PA', label: 'West Finley, PA' },
  { value: 'West Fork_AR', label: 'West Fork, AR' },
  { value: 'West Forks_ME', label: 'West Forks, ME' },
  { value: 'West Frankfort_IL', label: 'West Frankfort, IL' },
  { value: 'West Friendship_MD', label: 'West Friendship, MD' },
  { value: 'West Fulton_NY', label: 'West Fulton, NY' },
  { value: 'West Glacier_MT', label: 'West Glacier, MT' },
  { value: 'West Granby_CT', label: 'West Granby, CT' },
  { value: 'West Green_GA', label: 'West Green, GA' },
  { value: 'West Greene_AL', label: 'West Greene, AL' },
  { value: 'West Greenwich_RI', label: 'West Greenwich, RI' },
  { value: 'West Grove_PA', label: 'West Grove, PA' },
  { value: 'West Halifax_VT', label: 'West Halifax, VT' },
  { value: 'West Hamlin_WV', label: 'West Hamlin, WV' },
  { value: 'West Harrison_NY', label: 'West Harrison, NY' },
  { value: 'West Harrison_IN', label: 'West Harrison, IN' },
  { value: 'West Hartford_VT', label: 'West Hartford, VT' },
  { value: 'West Hartford_CT', label: 'West Hartford, CT' },
  { value: 'West Hartland_CT', label: 'West Hartland, CT' },
  { value: 'West Harwich_MA', label: 'West Harwich, MA' },
  { value: 'West Hatfield_MA', label: 'West Hatfield, MA' },
  { value: 'West Haven_CT', label: 'West Haven, CT' },
  { value: 'West Haverstraw_NY', label: 'West Haverstraw, NY' },
  { value: 'West Helena_AR', label: 'West Helena, AR' },
  { value: 'West Hempstead_NY', label: 'West Hempstead, NY' },
  { value: 'West Henrietta_NY', label: 'West Henrietta, NY' },
  { value: 'West Hickory_PA', label: 'West Hickory, PA' },
  { value: 'West Hills_CA', label: 'West Hills, CA' },
  { value: 'West Hollywood_CA', label: 'West Hollywood, CA' },
  { value: 'West Hurley_NY', label: 'West Hurley, NY' },
  { value: 'West Hyannisport_MA', label: 'West Hyannisport, MA' },
  { value: 'West Islip_NY', label: 'West Islip, NY' },
  { value: 'West Jefferson_NC', label: 'West Jefferson, NC' },
  { value: 'West Jefferson_OH', label: 'West Jefferson, OH' },
  { value: 'West Jordan_UT', label: 'West Jordan, UT' },
  { value: 'West Kill_NY', label: 'West Kill, NY' },
  { value: 'West Kingston_RI', label: 'West Kingston, RI' },
  { value: 'West Lafayette_OH', label: 'West Lafayette, OH' },
  { value: 'West Lafayette_IN', label: 'West Lafayette, IN' },
  { value: 'West Lebanon_NH', label: 'West Lebanon, NH' },
  { value: 'West Lebanon_NY', label: 'West Lebanon, NY' },
  { value: 'West Lebanon_PA', label: 'West Lebanon, PA' },
  { value: 'West Lebanon_IN', label: 'West Lebanon, IN' },
  { value: 'West Leisenring_PA', label: 'West Leisenring, PA' },
  { value: 'West Leyden_NY', label: 'West Leyden, NY' },
  { value: 'West Liberty_WV', label: 'West Liberty, WV' },
  { value: 'West Liberty_KY', label: 'West Liberty, KY' },
  { value: 'West Liberty_OH', label: 'West Liberty, OH' },
  { value: 'West Liberty_IA', label: 'West Liberty, IA' },
  { value: 'West Liberty_IL', label: 'West Liberty, IL' },
  { value: 'West Linn_OR', label: 'West Linn, OR' },
  { value: 'West Long Branch_NJ', label: 'West Long Branch, NJ' },
  { value: 'West Manchester_OH', label: 'West Manchester, OH' },
  { value: 'West Mansfield_OH', label: 'West Mansfield, OH' },
  { value: 'West Memphis_AR', label: 'West Memphis, AR' },
  { value: 'West Middlesex_PA', label: 'West Middlesex, PA' },
  { value: 'West Middletown_PA', label: 'West Middletown, PA' },
  { value: 'West Mifflin_PA', label: 'West Mifflin, PA' },
  { value: 'West Milford_NJ', label: 'West Milford, NJ' },
  { value: 'West Milford_WV', label: 'West Milford, WV' },
  { value: 'West Millgrove_OH', label: 'West Millgrove, OH' },
  { value: 'West Milton_PA', label: 'West Milton, PA' },
  { value: 'West Milton_OH', label: 'West Milton, OH' },
  { value: 'West Mineral_KS', label: 'West Mineral, KS' },
  { value: 'West Monroe_NY', label: 'West Monroe, NY' },
  { value: 'West Monroe_LA', label: 'West Monroe, LA' },
  { value: 'West New York_NJ', label: 'West New York, NJ' },
  { value: 'West Newbury_MA', label: 'West Newbury, MA' },
  { value: 'West Newfield_ME', label: 'West Newfield, ME' },
  { value: 'West Newton_MA', label: 'West Newton, MA' },
  { value: 'West Newton_PA', label: 'West Newton, PA' },
  { value: 'West Newton_IN', label: 'West Newton, IN' },
  { value: 'West Nottingham_NH', label: 'West Nottingham, NH' },
  { value: 'West Nyack_NY', label: 'West Nyack, NY' },
  { value: 'West Olive_MI', label: 'West Olive, MI' },
  { value: 'West Oneonta_NY', label: 'West Oneonta, NY' },
  { value: 'West Orange_NJ', label: 'West Orange, NJ' },
  { value: 'West Ossipee_NH', label: 'West Ossipee, NH' },
  { value: 'West Paducah_KY', label: 'West Paducah, KY' },
  { value: 'West Palm Beach_FL', label: 'West Palm Beach, FL' },
  { value: 'West Paris_ME', label: 'West Paris, ME' },
  { value: 'West Park_NY', label: 'West Park, NY' },
  { value: 'West Pawlet_VT', label: 'West Pawlet, VT' },
  { value: 'West Pittsburg_PA', label: 'West Pittsburg, PA' },
  { value: 'West Plains_MO', label: 'West Plains, MO' },
  { value: 'West Point_NY', label: 'West Point, NY' },
  { value: 'West Point_VA', label: 'West Point, VA' },
  { value: 'West Point_GA', label: 'West Point, GA' },
  { value: 'West Point_MS', label: 'West Point, MS' },
  { value: 'West Point_KY', label: 'West Point, KY' },
  { value: 'West Point_IA', label: 'West Point, IA' },
  { value: 'West Point_IL', label: 'West Point, IL' },
  { value: 'West Point_NE', label: 'West Point, NE' },
  { value: 'West Point_AR', label: 'West Point, AR' },
  { value: 'West Point_TX', label: 'West Point, TX' },
  { value: 'West Point_CA', label: 'West Point, CA' },
  { value: 'West Portsmouth_OH', label: 'West Portsmouth, OH' },
  { value: 'West Richland_WA', label: 'West Richland, WA' },
  { value: 'West Ridge_AR', label: 'West Ridge, AR' },
  { value: 'West River_MD', label: 'West River, MD' },
  { value: 'West Roxbury_MA', label: 'West Roxbury, MA' },
  { value: 'West Rupert_VT', label: 'West Rupert, VT' },
  { value: 'West Rutland_VT', label: 'West Rutland, VT' },
  { value: 'West Sacramento_CA', label: 'West Sacramento, CA' },
  { value: 'West Salem_OH', label: 'West Salem, OH' },
  { value: 'West Salem_WI', label: 'West Salem, WI' },
  { value: 'West Salem_IL', label: 'West Salem, IL' },
  { value: 'West Salisbury_PA', label: 'West Salisbury, PA' },
  { value: 'West Sand Lake_NY', label: 'West Sand Lake, NY' },
  { value: 'West Sayville_NY', label: 'West Sayville, NY' },
  { value: 'West Shokan_NY', label: 'West Shokan, NY' },
  { value: 'West Simsbury_CT', label: 'West Simsbury, CT' },
  { value: 'West Springfield_MA', label: 'West Springfield, MA' },
  { value: 'West Springfield_PA', label: 'West Springfield, PA' },
  { value: 'West Stewartstown_NH', label: 'West Stewartstown, NH' },
  { value: 'West Stockbridge_MA', label: 'West Stockbridge, MA' },
  { value: 'West Stockholm_NY', label: 'West Stockholm, NY' },
  { value: 'West Suffield_CT', label: 'West Suffield, CT' },
  { value: 'West Sunbury_PA', label: 'West Sunbury, PA' },
  { value: 'West Terre Haute_IN', label: 'West Terre Haute, IN' },
  { value: 'West Tisbury_MA', label: 'West Tisbury, MA' },
  { value: 'West Topsham_VT', label: 'West Topsham, VT' },
  { value: 'West Townsend_MA', label: 'West Townsend, MA' },
  { value: 'West Townshend_VT', label: 'West Townshend, VT' },
  { value: 'West Union_WV', label: 'West Union, WV' },
  { value: 'West Union_SC', label: 'West Union, SC' },
  { value: 'West Union_OH', label: 'West Union, OH' },
  { value: 'West Union_IA', label: 'West Union, IA' },
  { value: 'West Union_MN', label: 'West Union, MN' },
  { value: 'West Union_IL', label: 'West Union, IL' },
  { value: 'West Unity_OH', label: 'West Unity, OH' },
  { value: 'West Valley_NY', label: 'West Valley, NY' },
  { value: 'West Valley City_UT', label: 'West Valley City, UT' },
  { value: 'West Van Lear_KY', label: 'West Van Lear, KY' },
  { value: 'West Wardsboro_VT', label: 'West Wardsboro, VT' },
  { value: 'West Wareham_MA', label: 'West Wareham, MA' },
  { value: 'West Warren_MA', label: 'West Warren, MA' },
  { value: 'West Warwick_RI', label: 'West Warwick, RI' },
  { value: 'West Wendover_NV', label: 'West Wendover, NV' },
  { value: 'West Winfield_NY', label: 'West Winfield, NY' },
  { value: 'West Yarmouth_MA', label: 'West Yarmouth, MA' },
  { value: 'West Yellowstone_MT', label: 'West Yellowstone, MT' },
  { value: 'West York_IL', label: 'West York, IL' },
  { value: 'Westboro_WI', label: 'Westboro, WI' },
  { value: 'Westboro_MO', label: 'Westboro, MO' },
  { value: 'Westborough_MA', label: 'Westborough, MA' },
  { value: 'Westbrook_ME', label: 'Westbrook, ME' },
  { value: 'Westbrook_CT', label: 'Westbrook, CT' },
  { value: 'Westbrook_MN', label: 'Westbrook, MN' },
  { value: 'Westbrook_TX', label: 'Westbrook, TX' },
  { value: 'Westbrookville_NY', label: 'Westbrookville, NY' },
  { value: 'Westbury_NY', label: 'Westbury, NY' },
  { value: 'Westby_WI', label: 'Westby, WI' },
  { value: 'Westby_MT', label: 'Westby, MT' },
  { value: 'Westchester_IL', label: 'Westchester, IL' },
  { value: 'Westcliffe_CO', label: 'Westcliffe, CO' },
  { value: 'Westdale_NY', label: 'Westdale, NY' },
  { value: 'Westerlo_NY', label: 'Westerlo, NY' },
  { value: 'Westerly_RI', label: 'Westerly, RI' },
  { value: 'Western_NE', label: 'Western, NE' },
  { value: 'Western Grove_AR', label: 'Western Grove, AR' },
  { value: 'Western Springs_IL', label: 'Western Springs, IL' },
  { value: 'Westernport_MD', label: 'Westernport, MD' },
  { value: 'Westernville_NY', label: 'Westernville, NY' },
  { value: 'Westerville_OH', label: 'Westerville, OH' },
  { value: 'Westerville_NE', label: 'Westerville, NE' },
  { value: 'Westfall_OR', label: 'Westfall, OR' },
  { value: 'Westfield_MA', label: 'Westfield, MA' },
  { value: 'Westfield_ME', label: 'Westfield, ME' },
  { value: 'Westfield_VT', label: 'Westfield, VT' },
  { value: 'Westfield_NJ', label: 'Westfield, NJ' },
  { value: 'Westfield_NY', label: 'Westfield, NY' },
  { value: 'Westfield_PA', label: 'Westfield, PA' },
  { value: 'Westfield_NC', label: 'Westfield, NC' },
  { value: 'Westfield_IN', label: 'Westfield, IN' },
  { value: 'Westfield_IA', label: 'Westfield, IA' },
  { value: 'Westfield_WI', label: 'Westfield, WI' },
  { value: 'Westfield_IL', label: 'Westfield, IL' },
  { value: 'Westfield Center_OH', label: 'Westfield Center, OH' },
  { value: 'Westfir_OR', label: 'Westfir, OR' },
  { value: 'Westford_MA', label: 'Westford, MA' },
  { value: 'Westford_VT', label: 'Westford, VT' },
  { value: 'Westford_NY', label: 'Westford, NY' },
  { value: 'Westgate_IA', label: 'Westgate, IA' },
  { value: 'Westhampton_NY', label: 'Westhampton, NY' },
  { value: 'Westhampton Beach_NY', label: 'Westhampton Beach, NY' },
  { value: 'Westhoff_TX', label: 'Westhoff, TX' },
  { value: 'Westhope_ND', label: 'Westhope, ND' },
  { value: 'Westlake_OH', label: 'Westlake, OH' },
  { value: 'Westlake_LA', label: 'Westlake, LA' },
  { value: 'Westlake_OR', label: 'Westlake, OR' },
  { value: 'Westlake Village_CA', label: 'Westlake Village, CA' },
  { value: 'Westland_PA', label: 'Westland, PA' },
  { value: 'Westland_MI', label: 'Westland, MI' },
  { value: 'Westley_CA', label: 'Westley, CA' },
  { value: 'Westminster_MA', label: 'Westminster, MA' },
  { value: 'Westminster_VT', label: 'Westminster, VT' },
  { value: 'Westminster_MD', label: 'Westminster, MD' },
  { value: 'Westminster_SC', label: 'Westminster, SC' },
  { value: 'Westminster_CO', label: 'Westminster, CO' },
  { value: 'Westminster_CA', label: 'Westminster, CA' },
  { value: 'Westmont_IL', label: 'Westmont, IL' },
  { value: 'Westmoreland_NH', label: 'Westmoreland, NH' },
  { value: 'Westmoreland_NY', label: 'Westmoreland, NY' },
  { value: 'Westmoreland_TN', label: 'Westmoreland, TN' },
  { value: 'Westmoreland_KS', label: 'Westmoreland, KS' },
  { value: 'Westmoreland City_PA', label: 'Westmoreland City, PA' },
  { value: 'Westmorland_CA', label: 'Westmorland, CA' },
  { value: 'Weston_MA', label: 'Weston, MA' },
  { value: 'Weston_VT', label: 'Weston, VT' },
  { value: 'Weston_CT', label: 'Weston, CT' },
  { value: 'Weston_PA', label: 'Weston, PA' },
  { value: 'Weston_WV', label: 'Weston, WV' },
  { value: 'Weston_GA', label: 'Weston, GA' },
  { value: 'Weston_OH', label: 'Weston, OH' },
  { value: 'Weston_MI', label: 'Weston, MI' },
  { value: 'Weston_MO', label: 'Weston, MO' },
  { value: 'Weston_NE', label: 'Weston, NE' },
  { value: 'Weston_CO', label: 'Weston, CO' },
  { value: 'Weston_WY', label: 'Weston, WY' },
  { value: 'Weston_ID', label: 'Weston, ID' },
  { value: 'Weston_OR', label: 'Weston, OR' },
  { value: 'Westons Mills_NY', label: 'Westons Mills, NY' },
  { value: 'Westover_PA', label: 'Westover, PA' },
  { value: 'Westover_MD', label: 'Westover, MD' },
  { value: 'Westover_AL', label: 'Westover, AL' },
  { value: 'Westphalia_IN', label: 'Westphalia, IN' },
  { value: 'Westphalia_MI', label: 'Westphalia, MI' },
  { value: 'Westphalia_IA', label: 'Westphalia, IA' },
  { value: 'Westphalia_MO', label: 'Westphalia, MO' },
  { value: 'Westphalia_KS', label: 'Westphalia, KS' },
  { value: 'Westpoint_TN', label: 'Westpoint, TN' },
  { value: 'Westpoint_IN', label: 'Westpoint, IN' },
  { value: 'Westport_MA', label: 'Westport, MA' },
  { value: 'Westport_CT', label: 'Westport, CT' },
  { value: 'Westport_NY', label: 'Westport, NY' },
  { value: 'Westport_PA', label: 'Westport, PA' },
  { value: 'Westport_TN', label: 'Westport, TN' },
  { value: 'Westport_KY', label: 'Westport, KY' },
  { value: 'Westport_IN', label: 'Westport, IN' },
  { value: 'Westport_SD', label: 'Westport, SD' },
  { value: 'Westport_CA', label: 'Westport, CA' },
  { value: 'Westport_WA', label: 'Westport, WA' },
  { value: 'Westport Point_MA', label: 'Westport Point, MA' },
  { value: 'Westside_IA', label: 'Westside, IA' },
  { value: 'Westtown_NY', label: 'Westtown, NY' },
  { value: 'Westview_KY', label: 'Westview, KY' },
  { value: 'Westville_NJ', label: 'Westville, NJ' },
  { value: 'Westville_SC', label: 'Westville, SC' },
  { value: 'Westville_FL', label: 'Westville, FL' },
  { value: 'Westville_IN', label: 'Westville, IN' },
  { value: 'Westville_IL', label: 'Westville, IL' },
  { value: 'Westville_OK', label: 'Westville, OK' },
  { value: 'Westwego_LA', label: 'Westwego, LA' },
  { value: 'Westwood_MA', label: 'Westwood, MA' },
  { value: 'Westwood_NJ', label: 'Westwood, NJ' },
  { value: 'Westwood_CA', label: 'Westwood, CA' },
  { value: 'Wethersfield_CT', label: 'Wethersfield, CT' },
  { value: 'Wetmore_MI', label: 'Wetmore, MI' },
  { value: 'Wetmore_KS', label: 'Wetmore, KS' },
  { value: 'Wetmore_CO', label: 'Wetmore, CO' },
  { value: 'Wetumka_OK', label: 'Wetumka, OK' },
  { value: 'Wetumpka_AL', label: 'Wetumpka, AL' },
  { value: 'Wever_IA', label: 'Wever, IA' },
  { value: 'Wevertown_NY', label: 'Wevertown, NY' },
  { value: 'Wewahitchka_FL', label: 'Wewahitchka, FL' },
  { value: 'Wewoka_OK', label: 'Wewoka, OK' },
  { value: 'Wexford_PA', label: 'Wexford, PA' },
  { value: 'Weyanoke_LA', label: 'Weyanoke, LA' },
  { value: 'Weyauwega_WI', label: 'Weyauwega, WI' },
  { value: 'Weyerhaeuser_WI', label: 'Weyerhaeuser, WI' },
  { value: 'Weyers Cave_VA', label: 'Weyers Cave, VA' },
  { value: 'Weymouth_MA', label: 'Weymouth, MA' },
  { value: 'Whaleyville_MD', label: 'Whaleyville, MD' },
  { value: 'Wharncliffe_WV', label: 'Wharncliffe, WV' },
  { value: 'Wharton_NJ', label: 'Wharton, NJ' },
  { value: 'Wharton_WV', label: 'Wharton, WV' },
  { value: 'Wharton_OH', label: 'Wharton, OH' },
  { value: 'Wharton_TX', label: 'Wharton, TX' },
  { value: 'What Cheer_IA', label: 'What Cheer, IA' },
  { value: 'Whately_MA', label: 'Whately, MA' },
  { value: 'Whatley_AL', label: 'Whatley, AL' },
  { value: 'Wheat Ridge_CO', label: 'Wheat Ridge, CO' },
  { value: 'Wheatcroft_KY', label: 'Wheatcroft, KY' },
  { value: 'Wheatfield_IN', label: 'Wheatfield, IN' },
  { value: 'Wheatland_PA', label: 'Wheatland, PA' },
  { value: 'Wheatland_IN', label: 'Wheatland, IN' },
  { value: 'Wheatland_IA', label: 'Wheatland, IA' },
  { value: 'Wheatland_ND', label: 'Wheatland, ND' },
  { value: 'Wheatland_MO', label: 'Wheatland, MO' },
  { value: 'Wheatland_OK', label: 'Wheatland, OK' },
  { value: 'Wheatland_WY', label: 'Wheatland, WY' },
  { value: 'Wheatland_CA', label: 'Wheatland, CA' },
  { value: 'Wheatley_AR', label: 'Wheatley, AR' },
  { value: 'Wheaton_MN', label: 'Wheaton, MN' },
  { value: 'Wheaton_IL', label: 'Wheaton, IL' },
  { value: 'Wheaton_MO', label: 'Wheaton, MO' },
  { value: 'Wheeler_IN', label: 'Wheeler, IN' },
  { value: 'Wheeler_MI', label: 'Wheeler, MI' },
  { value: 'Wheeler_WI', label: 'Wheeler, WI' },
  { value: 'Wheeler_IL', label: 'Wheeler, IL' },
  { value: 'Wheeler_TX', label: 'Wheeler, TX' },
  { value: 'Wheeler_OR', label: 'Wheeler, OR' },
  { value: 'Wheelersburg_OH', label: 'Wheelersburg, OH' },
  { value: 'Wheeling_WV', label: 'Wheeling, WV' },
  { value: 'Wheeling_IL', label: 'Wheeling, IL' },
  { value: 'Wheeling_MO', label: 'Wheeling, MO' },
  { value: 'Wheelock_TX', label: 'Wheelock, TX' },
  { value: 'Wheelwright_MA', label: 'Wheelwright, MA' },
  { value: 'Wheelwright_KY', label: 'Wheelwright, KY' },
  { value: 'Whelen Springs_AR', label: 'Whelen Springs, AR' },
  { value: 'Whick_KY', label: 'Whick, KY' },
  { value: 'Whigham_GA', label: 'Whigham, GA' },
  { value: 'Whippany_NJ', label: 'Whippany, NJ' },
  { value: 'Whipple_OH', label: 'Whipple, OH' },
  { value: 'Whitakers_NC', label: 'Whitakers, NC' },
  { value: 'White_PA', label: 'White, PA' },
  { value: 'White_GA', label: 'White, GA' },
  { value: 'White_SD', label: 'White, SD' },
  { value: 'White Bird_ID', label: 'White Bird, ID' },
  { value: 'White Bluff_TN', label: 'White Bluff, TN' },
  { value: 'White Castle_LA', label: 'White Castle, LA' },
  { value: 'White City_KS', label: 'White City, KS' },
  { value: 'White City_OR', label: 'White City, OR' },
  { value: 'White Cloud_MI', label: 'White Cloud, MI' },
  { value: 'White Cloud_KS', label: 'White Cloud, KS' },
  { value: 'White Cottage_OH', label: 'White Cottage, OH' },
  { value: 'White Deer_PA', label: 'White Deer, PA' },
  { value: 'White Deer_TX', label: 'White Deer, TX' },
  { value: 'White Earth_MN', label: 'White Earth, MN' },
  { value: 'White Earth_ND', label: 'White Earth, ND' },
  { value: 'White Hall_MD', label: 'White Hall, MD' },
  { value: 'White Hall_IL', label: 'White Hall, IL' },
  { value: 'White Hall_AR', label: 'White Hall, AR' },
  { value: 'White Haven_PA', label: 'White Haven, PA' },
  { value: 'White Heath_IL', label: 'White Heath, IL' },
  { value: 'White House_TN', label: 'White House, TN' },
  { value: 'White Lake_NY', label: 'White Lake, NY' },
  { value: 'White Lake_MI', label: 'White Lake, MI' },
  { value: 'White Lake_WI', label: 'White Lake, WI' },
  { value: 'White Lake_SD', label: 'White Lake, SD' },
  { value: 'White Marsh_MD', label: 'White Marsh, MD' },
  { value: 'White Mills_PA', label: 'White Mills, PA' },
  { value: 'White Mills_KY', label: 'White Mills, KY' },
  { value: 'White Mountain_AK', label: 'White Mountain, AK' },
  { value: 'White Mountain Lake_AZ', label: 'White Mountain Lake, AZ' },
  { value: 'White Oak_WV', label: 'White Oak, WV' },
  { value: 'White Oak_NC', label: 'White Oak, NC' },
  { value: 'White Oak_GA', label: 'White Oak, GA' },
  { value: 'White Oak_TX', label: 'White Oak, TX' },
  { value: 'White Owl_SD', label: 'White Owl, SD' },
  { value: 'White Pigeon_MI', label: 'White Pigeon, MI' },
  { value: 'White Pine_TN', label: 'White Pine, TN' },
  { value: 'White Pine_MI', label: 'White Pine, MI' },
  { value: 'White Plains_NY', label: 'White Plains, NY' },
  { value: 'White Plains_MD', label: 'White Plains, MD' },
  { value: 'White Plains_VA', label: 'White Plains, VA' },
  { value: 'White Plains_GA', label: 'White Plains, GA' },
  { value: 'White Plains_KY', label: 'White Plains, KY' },
  { value: 'White Post_VA', label: 'White Post, VA' },
  { value: 'White River_SD', label: 'White River, SD' },
  { value: 'White River Junction_VT', label: 'White River Junction, VT' },
  { value: 'White Rock_SC', label: 'White Rock, SC' },
  { value: 'White Rock_NM', label: 'White Rock, NM' },
  { value: 'White Salmon_WA', label: 'White Salmon, WA' },
  {
    value: 'White Sands Missile Range_NM',
    label: 'White Sands Missile Range, NM',
  },
  { value: 'White Springs_FL', label: 'White Springs, FL' },
  { value: 'White Stone_VA', label: 'White Stone, VA' },
  { value: 'White Sulphur Springs_NY', label: 'White Sulphur Springs, NY' },
  { value: 'White Sulphur Springs_WV', label: 'White Sulphur Springs, WV' },
  { value: 'White Sulphur Springs_MT', label: 'White Sulphur Springs, MT' },
  { value: 'White Swan_WA', label: 'White Swan, WA' },
  { value: 'Whiteclay_NE', label: 'Whiteclay, NE' },
  { value: 'Whiteface_TX', label: 'Whiteface, TX' },
  { value: 'Whitefield_NH', label: 'Whitefield, NH' },
  { value: 'Whitefield_ME', label: 'Whitefield, ME' },
  { value: 'Whitefield_OK', label: 'Whitefield, OK' },
  { value: 'Whitefish_MT', label: 'Whitefish, MT' },
  { value: 'Whiteford_MD', label: 'Whiteford, MD' },
  { value: 'Whitehall_NY', label: 'Whitehall, NY' },
  { value: 'Whitehall_PA', label: 'Whitehall, PA' },
  { value: 'Whitehall_MI', label: 'Whitehall, MI' },
  { value: 'Whitehall_WI', label: 'Whitehall, WI' },
  { value: 'Whitehall_MT', label: 'Whitehall, MT' },
  { value: 'Whitehorse_SD', label: 'Whitehorse, SD' },
  { value: 'Whitehouse_OH', label: 'Whitehouse, OH' },
  { value: 'Whitehouse_TX', label: 'Whitehouse, TX' },
  { value: 'Whitehouse Station_NJ', label: 'Whitehouse Station, NJ' },
  { value: 'Whiteland_IN', label: 'Whiteland, IN' },
  { value: 'Whitelaw_WI', label: 'Whitelaw, WI' },
  { value: 'Whiteman Air Force Base_MO', label: 'Whiteman Air Force Base, MO' },
  { value: 'Whiteoak_MO', label: 'Whiteoak, MO' },
  { value: 'Whiteriver_AZ', label: 'Whiteriver, AZ' },
  { value: 'Whiterocks_UT', label: 'Whiterocks, UT' },
  { value: 'Whites City_NM', label: 'Whites City, NM' },
  { value: 'Whites Creek_TN', label: 'Whites Creek, TN' },
  { value: 'Whitesboro_NY', label: 'Whitesboro, NY' },
  { value: 'Whitesboro_OK', label: 'Whitesboro, OK' },
  { value: 'Whitesboro_TX', label: 'Whitesboro, TX' },
  { value: 'Whitesburg_GA', label: 'Whitesburg, GA' },
  { value: 'Whitesburg_TN', label: 'Whitesburg, TN' },
  { value: 'Whitesburg_KY', label: 'Whitesburg, KY' },
  { value: 'Whiteside_TN', label: 'Whiteside, TN' },
  { value: 'Whiteside_MO', label: 'Whiteside, MO' },
  { value: 'Whitestone_NY', label: 'Whitestone, NY' },
  { value: 'Whitestown_IN', label: 'Whitestown, IN' },
  { value: 'Whitesville_NY', label: 'Whitesville, NY' },
  { value: 'Whitesville_WV', label: 'Whitesville, WV' },
  { value: 'Whitesville_KY', label: 'Whitesville, KY' },
  { value: 'Whitetail_MT', label: 'Whitetail, MT' },
  { value: 'Whitethorn_CA', label: 'Whitethorn, CA' },
  { value: 'Whitetop_VA', label: 'Whitetop, VA' },
  { value: 'Whiteville_NC', label: 'Whiteville, NC' },
  { value: 'Whiteville_TN', label: 'Whiteville, TN' },
  { value: 'Whitewater_WI', label: 'Whitewater, WI' },
  { value: 'Whitewater_MT', label: 'Whitewater, MT' },
  { value: 'Whitewater_MO', label: 'Whitewater, MO' },
  { value: 'Whitewater_KS', label: 'Whitewater, KS' },
  { value: 'Whitewater_CO', label: 'Whitewater, CO' },
  { value: 'Whitewater_CA', label: 'Whitewater, CA' },
  { value: 'Whitewood_VA', label: 'Whitewood, VA' },
  { value: 'Whitewood_SD', label: 'Whitewood, SD' },
  { value: 'Whitewright_TX', label: 'Whitewright, TX' },
  { value: 'Whitfield_MS', label: 'Whitfield, MS' },
  { value: 'Whitharral_TX', label: 'Whitharral, TX' },
  { value: 'Whiting_ME', label: 'Whiting, ME' },
  { value: 'Whiting_VT', label: 'Whiting, VT' },
  { value: 'Whiting_IN', label: 'Whiting, IN' },
  { value: 'Whiting_IA', label: 'Whiting, IA' },
  { value: 'Whiting_KS', label: 'Whiting, KS' },
  { value: 'Whitingham_VT', label: 'Whitingham, VT' },
  { value: 'Whitinsville_MA', label: 'Whitinsville, MA' },
  { value: 'Whitlash_MT', label: 'Whitlash, MT' },
  { value: 'Whitley City_KY', label: 'Whitley City, KY' },
  { value: 'Whitleyville_TN', label: 'Whitleyville, TN' },
  { value: 'Whitman_MA', label: 'Whitman, MA' },
  { value: 'Whitman_WV', label: 'Whitman, WV' },
  { value: 'Whitman_NE', label: 'Whitman, NE' },
  { value: 'Whitmer_WV', label: 'Whitmer, WV' },
  { value: 'Whitmire_SC', label: 'Whitmire, SC' },
  { value: 'Whitmore_CA', label: 'Whitmore, CA' },
  { value: 'Whitmore Lake_MI', label: 'Whitmore Lake, MI' },
  { value: 'Whitney_PA', label: 'Whitney, PA' },
  { value: 'Whitney_NE', label: 'Whitney, NE' },
  { value: 'Whitney_TX', label: 'Whitney, TX' },
  { value: 'Whitney Point_NY', label: 'Whitney Point, NY' },
  { value: 'Whitsett_NC', label: 'Whitsett, NC' },
  { value: 'Whitsett_TX', label: 'Whitsett, TX' },
  { value: 'Whitt_TX', label: 'Whitt, TX' },
  { value: 'Whittaker_MI', label: 'Whittaker, MI' },
  { value: 'Whittemore_MI', label: 'Whittemore, MI' },
  { value: 'Whittemore_IA', label: 'Whittemore, IA' },
  { value: 'Whitten_IA', label: 'Whitten, IA' },
  { value: 'Whittier_NC', label: 'Whittier, NC' },
  { value: 'Whittier_CA', label: 'Whittier, CA' },
  { value: 'Whittier_AK', label: 'Whittier, AK' },
  { value: 'Whittington_IL', label: 'Whittington, IL' },
  { value: 'Whitwell_TN', label: 'Whitwell, TN' },
  { value: 'Wibaux_MT', label: 'Wibaux, MT' },
  { value: 'Wichita_KS', label: 'Wichita, KS' },
  { value: 'Wichita Falls_TX', label: 'Wichita Falls, TX' },
  { value: 'Wickenburg_AZ', label: 'Wickenburg, AZ' },
  { value: 'Wickes_AR', label: 'Wickes, AR' },
  { value: 'Wickett_TX', label: 'Wickett, TX' },
  { value: 'Wickhaven_PA', label: 'Wickhaven, PA' },
  { value: 'Wickliffe_KY', label: 'Wickliffe, KY' },
  { value: 'Wickliffe_OH', label: 'Wickliffe, OH' },
  { value: 'Wiconisco_PA', label: 'Wiconisco, PA' },
  { value: 'Wideman_AR', label: 'Wideman, AR' },
  { value: 'Widen_WV', label: 'Widen, WV' },
  { value: 'Widener_AR', label: 'Widener, AR' },
  { value: 'Wiergate_TX', label: 'Wiergate, TX' },
  { value: 'Wiggins_MS', label: 'Wiggins, MS' },
  { value: 'Wiggins_CO', label: 'Wiggins, CO' },
  { value: 'Wikieup_AZ', label: 'Wikieup, AZ' },
  { value: 'Wilber_NE', label: 'Wilber, NE' },
  { value: 'Wilberforce_OH', label: 'Wilberforce, OH' },
  { value: 'Wilbraham_MA', label: 'Wilbraham, MA' },
  { value: 'Wilbur_OR', label: 'Wilbur, OR' },
  { value: 'Wilbur_WA', label: 'Wilbur, WA' },
  { value: 'Wilburn_AR', label: 'Wilburn, AR' },
  { value: 'Wilburton_PA', label: 'Wilburton, PA' },
  { value: 'Wilburton_OK', label: 'Wilburton, OK' },
  { value: 'Wilcoe_WV', label: 'Wilcoe, WV' },
  { value: 'Wilcox_PA', label: 'Wilcox, PA' },
  { value: 'Wilcox_NE', label: 'Wilcox, NE' },
  { value: 'Wild Horse_CO', label: 'Wild Horse, CO' },
  { value: 'Wild Rose_WI', label: 'Wild Rose, WI' },
  { value: 'Wilder_VT', label: 'Wilder, VT' },
  { value: 'Wilder_TN', label: 'Wilder, TN' },
  { value: 'Wilder_ID', label: 'Wilder, ID' },
  { value: 'Wildersville_TN', label: 'Wildersville, TN' },
  { value: 'Wilderville_OR', label: 'Wilderville, OR' },
  { value: 'Wildomar_CA', label: 'Wildomar, CA' },
  { value: 'Wildorado_TX', label: 'Wildorado, TX' },
  { value: 'Wildrose_ND', label: 'Wildrose, ND' },
  { value: 'Wildwood_NJ', label: 'Wildwood, NJ' },
  { value: 'Wildwood_GA', label: 'Wildwood, GA' },
  { value: 'Wildwood_FL', label: 'Wildwood, FL' },
  { value: 'Wildwood_MO', label: 'Wildwood, MO' },
  { value: 'Wiley_GA', label: 'Wiley, GA' },
  { value: 'Wiley_CO', label: 'Wiley, CO' },
  { value: 'Wiley Ford_WV', label: 'Wiley Ford, WV' },
  { value: 'Wilkes Barre_PA', label: 'Wilkes Barre, PA' },
  { value: 'Wilkesboro_NC', label: 'Wilkesboro, NC' },
  { value: 'Wilkeson_WA', label: 'Wilkeson, WA' },
  { value: 'Wilkesville_OH', label: 'Wilkesville, OH' },
  { value: 'Wilkinson_WV', label: 'Wilkinson, WV' },
  { value: 'Wilkinson_IN', label: 'Wilkinson, IN' },
  { value: 'Willacoochee_GA', label: 'Willacoochee, GA' },
  { value: 'Willamina_OR', label: 'Willamina, OR' },
  { value: 'Willard_NY', label: 'Willard, NY' },
  { value: 'Willard_NC', label: 'Willard, NC' },
  { value: 'Willard_KY', label: 'Willard, KY' },
  { value: 'Willard_OH', label: 'Willard, OH' },
  { value: 'Willard_WI', label: 'Willard, WI' },
  { value: 'Willard_MT', label: 'Willard, MT' },
  { value: 'Willard_MO', label: 'Willard, MO' },
  { value: 'Willard_UT', label: 'Willard, UT' },
  { value: 'Willard_NM', label: 'Willard, NM' },
  { value: 'Willards_MD', label: 'Willards, MD' },
  { value: 'Willcox_AZ', label: 'Willcox, AZ' },
  { value: 'Willernie_MN', label: 'Willernie, MN' },
  { value: 'Willet_NY', label: 'Willet, NY' },
  { value: 'Williams_SC', label: 'Williams, SC' },
  { value: 'Williams_IN', label: 'Williams, IN' },
  { value: 'Williams_IA', label: 'Williams, IA' },
  { value: 'Williams_MN', label: 'Williams, MN' },
  { value: 'Williams_AZ', label: 'Williams, AZ' },
  { value: 'Williams_CA', label: 'Williams, CA' },
  { value: 'Williams_OR', label: 'Williams, OR' },
  { value: 'Williams Bay_WI', label: 'Williams Bay, WI' },
  { value: 'Williamsburg_MA', label: 'Williamsburg, MA' },
  { value: 'Williamsburg_PA', label: 'Williamsburg, PA' },
  { value: 'Williamsburg_VA', label: 'Williamsburg, VA' },
  { value: 'Williamsburg_WV', label: 'Williamsburg, WV' },
  { value: 'Williamsburg_KY', label: 'Williamsburg, KY' },
  { value: 'Williamsburg_OH', label: 'Williamsburg, OH' },
  { value: 'Williamsburg_IN', label: 'Williamsburg, IN' },
  { value: 'Williamsburg_MI', label: 'Williamsburg, MI' },
  { value: 'Williamsburg_IA', label: 'Williamsburg, IA' },
  { value: 'Williamsburg_MO', label: 'Williamsburg, MO' },
  { value: 'Williamsburg_KS', label: 'Williamsburg, KS' },
  { value: 'Williamsburg_NM', label: 'Williamsburg, NM' },
  { value: 'Williamsfield_OH', label: 'Williamsfield, OH' },
  { value: 'Williamsfield_IL', label: 'Williamsfield, IL' },
  { value: 'Williamson_NY', label: 'Williamson, NY' },
  { value: 'Williamson_WV', label: 'Williamson, WV' },
  { value: 'Williamson_GA', label: 'Williamson, GA' },
  { value: 'Williamson_IA', label: 'Williamson, IA' },
  { value: 'Williamsport_PA', label: 'Williamsport, PA' },
  { value: 'Williamsport_MD', label: 'Williamsport, MD' },
  { value: 'Williamsport_TN', label: 'Williamsport, TN' },
  { value: 'Williamsport_KY', label: 'Williamsport, KY' },
  { value: 'Williamsport_OH', label: 'Williamsport, OH' },
  { value: 'Williamsport_IN', label: 'Williamsport, IN' },
  { value: 'Williamston_NC', label: 'Williamston, NC' },
  { value: 'Williamston_SC', label: 'Williamston, SC' },
  { value: 'Williamston_MI', label: 'Williamston, MI' },
  { value: 'Williamstown_MA', label: 'Williamstown, MA' },
  { value: 'Williamstown_VT', label: 'Williamstown, VT' },
  { value: 'Williamstown_NJ', label: 'Williamstown, NJ' },
  { value: 'Williamstown_NY', label: 'Williamstown, NY' },
  { value: 'Williamstown_PA', label: 'Williamstown, PA' },
  { value: 'Williamstown_WV', label: 'Williamstown, WV' },
  { value: 'Williamstown_KY', label: 'Williamstown, KY' },
  { value: 'Williamstown_OH', label: 'Williamstown, OH' },
  { value: 'Williamstown_MO', label: 'Williamstown, MO' },
  { value: 'Williamsville_VT', label: 'Williamsville, VT' },
  { value: 'Williamsville_VA', label: 'Williamsville, VA' },
  { value: 'Williamsville_IL', label: 'Williamsville, IL' },
  { value: 'Williamsville_MO', label: 'Williamsville, MO' },
  { value: 'Williford_AR', label: 'Williford, AR' },
  { value: 'Willimantic_CT', label: 'Willimantic, CT' },
  { value: 'Willingboro_NJ', label: 'Willingboro, NJ' },
  { value: 'Willington_CT', label: 'Willington, CT' },
  { value: 'Willis_VA', label: 'Willis, VA' },
  { value: 'Willis_MI', label: 'Willis, MI' },
  { value: 'Willis_TX', label: 'Willis, TX' },
  { value: 'Willis Wharf_VA', label: 'Willis Wharf, VA' },
  { value: 'Willisburg_KY', label: 'Willisburg, KY' },
  { value: 'Williston_VT', label: 'Williston, VT' },
  { value: 'Williston_NC', label: 'Williston, NC' },
  { value: 'Williston_SC', label: 'Williston, SC' },
  { value: 'Williston_FL', label: 'Williston, FL' },
  { value: 'Williston_TN', label: 'Williston, TN' },
  { value: 'Williston_OH', label: 'Williston, OH' },
  { value: 'Williston_ND', label: 'Williston, ND' },
  { value: 'Williston Park_NY', label: 'Williston Park, NY' },
  { value: 'Willisville_IL', label: 'Willisville, IL' },
  { value: 'Willisville_AR', label: 'Willisville, AR' },
  { value: 'Willits_CA', label: 'Willits, CA' },
  { value: 'Willmar_MN', label: 'Willmar, MN' },
  { value: 'Willoughby_OH', label: 'Willoughby, OH' },
  { value: 'Willow_NY', label: 'Willow, NY' },
  { value: 'Willow_OK', label: 'Willow, OK' },
  { value: 'Willow_AK', label: 'Willow, AK' },
  { value: 'Willow Beach_AZ', label: 'Willow Beach, AZ' },
  { value: 'Willow City_ND', label: 'Willow City, ND' },
  { value: 'Willow City_TX', label: 'Willow City, TX' },
  { value: 'Willow Creek_MT', label: 'Willow Creek, MT' },
  { value: 'Willow Creek_CA', label: 'Willow Creek, CA' },
  { value: 'Willow Grove_PA', label: 'Willow Grove, PA' },
  { value: 'Willow Hill_PA', label: 'Willow Hill, PA' },
  { value: 'Willow Hill_IL', label: 'Willow Hill, IL' },
  { value: 'Willow Island_NE', label: 'Willow Island, NE' },
  { value: 'Willow Lake_SD', label: 'Willow Lake, SD' },
  { value: 'Willow River_MN', label: 'Willow River, MN' },
  { value: 'Willow Spring_NC', label: 'Willow Spring, NC' },
  { value: 'Willow Springs_IL', label: 'Willow Springs, IL' },
  { value: 'Willow Springs_MO', label: 'Willow Springs, MO' },
  { value: 'Willow Street_PA', label: 'Willow Street, PA' },
  { value: 'Willow Wood_OH', label: 'Willow Wood, OH' },
  { value: 'Willowbrook_IL', label: 'Willowbrook, IL' },
  { value: 'Willows_CA', label: 'Willows, CA' },
  { value: 'Wills Point_TX', label: 'Wills Point, TX' },
  { value: 'Willsboro_NY', label: 'Willsboro, NY' },
  { value: 'Willseyville_NY', label: 'Willseyville, NY' },
  { value: 'Willshire_OH', label: 'Willshire, OH' },
  { value: 'Wilmar_AR', label: 'Wilmar, AR' },
  { value: 'Wilmer_AL', label: 'Wilmer, AL' },
  { value: 'Wilmer_TX', label: 'Wilmer, TX' },
  { value: 'Wilmerding_PA', label: 'Wilmerding, PA' },
  { value: 'Wilmette_IL', label: 'Wilmette, IL' },
  { value: 'Wilmington_MA', label: 'Wilmington, MA' },
  { value: 'Wilmington_VT', label: 'Wilmington, VT' },
  { value: 'Wilmington_NY', label: 'Wilmington, NY' },
  { value: 'Wilmington_DE', label: 'Wilmington, DE' },
  { value: 'Wilmington_NC', label: 'Wilmington, NC' },
  { value: 'Wilmington_OH', label: 'Wilmington, OH' },
  { value: 'Wilmington_IL', label: 'Wilmington, IL' },
  { value: 'Wilmington_CA', label: 'Wilmington, CA' },
  { value: 'Wilmont_MN', label: 'Wilmont, MN' },
  { value: 'Wilmore_PA', label: 'Wilmore, PA' },
  { value: 'Wilmore_KY', label: 'Wilmore, KY' },
  { value: 'Wilmore_KS', label: 'Wilmore, KS' },
  { value: 'Wilmot_NH', label: 'Wilmot, NH' },
  { value: 'Wilmot_OH', label: 'Wilmot, OH' },
  { value: 'Wilmot_WI', label: 'Wilmot, WI' },
  { value: 'Wilmot_SD', label: 'Wilmot, SD' },
  { value: 'Wilmot_AR', label: 'Wilmot, AR' },
  { value: 'Wilsall_MT', label: 'Wilsall, MT' },
  { value: 'Wilsey_KS', label: 'Wilsey, KS' },
  { value: 'Wilseyville_CA', label: 'Wilseyville, CA' },
  { value: 'Wilson_NY', label: 'Wilson, NY' },
  { value: 'Wilson_NC', label: 'Wilson, NC' },
  { value: 'Wilson_MI', label: 'Wilson, MI' },
  { value: 'Wilson_WI', label: 'Wilson, WI' },
  { value: 'Wilson_KS', label: 'Wilson, KS' },
  { value: 'Wilson_LA', label: 'Wilson, LA' },
  { value: 'Wilson_AR', label: 'Wilson, AR' },
  { value: 'Wilson_OK', label: 'Wilson, OK' },
  { value: 'Wilson_TX', label: 'Wilson, TX' },
  { value: 'Wilson_WY', label: 'Wilson, WY' },
  { value: 'Wilson Creek_WA', label: 'Wilson Creek, WA' },
  { value: 'Wilsondale_WV', label: 'Wilsondale, WV' },
  { value: 'Wilsons_VA', label: 'Wilsons, VA' },
  { value: 'Wilsons Mills_NC', label: 'Wilsons Mills, NC' },
  { value: 'Wilsonville_AL', label: 'Wilsonville, AL' },
  { value: 'Wilsonville_IL', label: 'Wilsonville, IL' },
  { value: 'Wilsonville_NE', label: 'Wilsonville, NE' },
  { value: 'Wilsonville_OR', label: 'Wilsonville, OR' },
  { value: 'Wilton_NH', label: 'Wilton, NH' },
  { value: 'Wilton_ME', label: 'Wilton, ME' },
  { value: 'Wilton_CT', label: 'Wilton, CT' },
  { value: 'Wilton_IA', label: 'Wilton, IA' },
  { value: 'Wilton_WI', label: 'Wilton, WI' },
  { value: 'Wilton_ND', label: 'Wilton, ND' },
  { value: 'Wilton_AR', label: 'Wilton, AR' },
  { value: 'Wilton_CA', label: 'Wilton, CA' },
  { value: 'Wimauma_FL', label: 'Wimauma, FL' },
  { value: 'Wimberley_TX', label: 'Wimberley, TX' },
  { value: 'Wimbledon_ND', label: 'Wimbledon, ND' },
  { value: 'Winamac_IN', label: 'Winamac, IN' },
  { value: 'Winburne_PA', label: 'Winburne, PA' },
  { value: 'Winchendon_MA', label: 'Winchendon, MA' },
  { value: 'Winchester_MA', label: 'Winchester, MA' },
  { value: 'Winchester_NH', label: 'Winchester, NH' },
  { value: 'Winchester_VA', label: 'Winchester, VA' },
  { value: 'Winchester_TN', label: 'Winchester, TN' },
  { value: 'Winchester_KY', label: 'Winchester, KY' },
  { value: 'Winchester_OH', label: 'Winchester, OH' },
  { value: 'Winchester_IN', label: 'Winchester, IN' },
  { value: 'Winchester_IL', label: 'Winchester, IL' },
  { value: 'Winchester_KS', label: 'Winchester, KS' },
  { value: 'Winchester_AR', label: 'Winchester, AR' },
  { value: 'Winchester_ID', label: 'Winchester, ID' },
  { value: 'Winchester_CA', label: 'Winchester, CA' },
  { value: 'Winchester_OR', label: 'Winchester, OR' },
  { value: 'Wind Gap_PA', label: 'Wind Gap, PA' },
  { value: 'Wind Ridge_PA', label: 'Wind Ridge, PA' },
  { value: 'Windber_PA', label: 'Windber, PA' },
  { value: 'Winder_GA', label: 'Winder, GA' },
  { value: 'Windermere_FL', label: 'Windermere, FL' },
  { value: 'Windfall_IN', label: 'Windfall, IN' },
  { value: 'Windham_NH', label: 'Windham, NH' },
  { value: 'Windham_ME', label: 'Windham, ME' },
  { value: 'Windham_CT', label: 'Windham, CT' },
  { value: 'Windham_NY', label: 'Windham, NY' },
  { value: 'Windham_OH', label: 'Windham, OH' },
  { value: 'Windom_MN', label: 'Windom, MN' },
  { value: 'Windom_KS', label: 'Windom, KS' },
  { value: 'Windom_TX', label: 'Windom, TX' },
  { value: 'Window Rock_AZ', label: 'Window Rock, AZ' },
  { value: 'Windsor_MA', label: 'Windsor, MA' },
  { value: 'Windsor_ME', label: 'Windsor, ME' },
  { value: 'Windsor_VT', label: 'Windsor, VT' },
  { value: 'Windsor_CT', label: 'Windsor, CT' },
  { value: 'Windsor_NJ', label: 'Windsor, NJ' },
  { value: 'Windsor_NY', label: 'Windsor, NY' },
  { value: 'Windsor_PA', label: 'Windsor, PA' },
  { value: 'Windsor_VA', label: 'Windsor, VA' },
  { value: 'Windsor_NC', label: 'Windsor, NC' },
  { value: 'Windsor_SC', label: 'Windsor, SC' },
  { value: 'Windsor_KY', label: 'Windsor, KY' },
  { value: 'Windsor_OH', label: 'Windsor, OH' },
  { value: 'Windsor_WI', label: 'Windsor, WI' },
  { value: 'Windsor_IL', label: 'Windsor, IL' },
  { value: 'Windsor_MO', label: 'Windsor, MO' },
  { value: 'Windsor_CO', label: 'Windsor, CO' },
  { value: 'Windsor_CA', label: 'Windsor, CA' },
  { value: 'Windsor Heights_WV', label: 'Windsor Heights, WV' },
  { value: 'Windsor Heights_IA', label: 'Windsor Heights, IA' },
  { value: 'Windsor Locks_CT', label: 'Windsor Locks, CT' },
  { value: 'Windsor Mill_MD', label: 'Windsor Mill, MD' },
  { value: 'Windthorst_TX', label: 'Windthorst, TX' },
  { value: 'Windyville_MO', label: 'Windyville, MO' },
  { value: 'Winesburg_OH', label: 'Winesburg, OH' },
  { value: 'Winfall_NC', label: 'Winfall, NC' },
  { value: 'Winfield_PA', label: 'Winfield, PA' },
  { value: 'Winfield_WV', label: 'Winfield, WV' },
  { value: 'Winfield_AL', label: 'Winfield, AL' },
  { value: 'Winfield_TN', label: 'Winfield, TN' },
  { value: 'Winfield_IA', label: 'Winfield, IA' },
  { value: 'Winfield_IL', label: 'Winfield, IL' },
  { value: 'Winfield_MO', label: 'Winfield, MO' },
  { value: 'Winfield_KS', label: 'Winfield, KS' },
  { value: 'Winfield_TX', label: 'Winfield, TX' },
  { value: 'Winfred_SD', label: 'Winfred, SD' },
  { value: 'Wing_AL', label: 'Wing, AL' },
  { value: 'Wing_ND', label: 'Wing, ND' },
  { value: 'Wingate_MD', label: 'Wingate, MD' },
  { value: 'Wingate_NC', label: 'Wingate, NC' },
  { value: 'Wingate_IN', label: 'Wingate, IN' },
  { value: 'Wingate_TX', label: 'Wingate, TX' },
  { value: 'Wingdale_NY', label: 'Wingdale, NY' },
  { value: 'Winger_MN', label: 'Winger, MN' },
  { value: 'Wingett Run_OH', label: 'Wingett Run, OH' },
  { value: 'Wingina_VA', label: 'Wingina, VA' },
  { value: 'Wingo_KY', label: 'Wingo, KY' },
  { value: 'Winifred_MT', label: 'Winifred, MT' },
  { value: 'Winifrede_WV', label: 'Winifrede, WV' },
  { value: 'Winigan_MO', label: 'Winigan, MO' },
  { value: 'Wink_TX', label: 'Wink, TX' },
  { value: 'Winkelman_AZ', label: 'Winkelman, AZ' },
  { value: 'Winlock_WA', label: 'Winlock, WA' },
  { value: 'Winn_ME', label: 'Winn, ME' },
  { value: 'Winn_MI', label: 'Winn, MI' },
  { value: 'Winnabow_NC', label: 'Winnabow, NC' },
  { value: 'Winnebago_WI', label: 'Winnebago, WI' },
  { value: 'Winnebago_MN', label: 'Winnebago, MN' },
  { value: 'Winnebago_IL', label: 'Winnebago, IL' },
  { value: 'Winnebago_NE', label: 'Winnebago, NE' },
  { value: 'Winneconne_WI', label: 'Winneconne, WI' },
  { value: 'Winnemucca_NV', label: 'Winnemucca, NV' },
  { value: 'Winner_SD', label: 'Winner, SD' },
  { value: 'Winnetka_IL', label: 'Winnetka, IL' },
  { value: 'Winnetka_CA', label: 'Winnetka, CA' },
  { value: 'Winnetoon_NE', label: 'Winnetoon, NE' },
  { value: 'Winnett_MT', label: 'Winnett, MT' },
  { value: 'Winnfield_LA', label: 'Winnfield, LA' },
  { value: 'Winnie_TX', label: 'Winnie, TX' },
  { value: 'Winnsboro_SC', label: 'Winnsboro, SC' },
  { value: 'Winnsboro_LA', label: 'Winnsboro, LA' },
  { value: 'Winnsboro_TX', label: 'Winnsboro, TX' },
  { value: 'Winona_WV', label: 'Winona, WV' },
  { value: 'Winona_MS', label: 'Winona, MS' },
  { value: 'Winona_OH', label: 'Winona, OH' },
  { value: 'Winona_MN', label: 'Winona, MN' },
  { value: 'Winona_MO', label: 'Winona, MO' },
  { value: 'Winona_KS', label: 'Winona, KS' },
  { value: 'Winona_TX', label: 'Winona, TX' },
  { value: 'Winona Lake_IN', label: 'Winona Lake, IN' },
  { value: 'Winooski_VT', label: 'Winooski, VT' },
  { value: 'Winside_NE', label: 'Winside, NE' },
  { value: 'Winslow_NJ', label: 'Winslow, NJ' },
  { value: 'Winslow_IN', label: 'Winslow, IN' },
  { value: 'Winslow_IL', label: 'Winslow, IL' },
  { value: 'Winslow_NE', label: 'Winslow, NE' },
  { value: 'Winslow_AR', label: 'Winslow, AR' },
  { value: 'Winslow_AZ', label: 'Winslow, AZ' },
  { value: 'Winsted_CT', label: 'Winsted, CT' },
  { value: 'Winsted_MN', label: 'Winsted, MN' },
  { value: 'Winston_GA', label: 'Winston, GA' },
  { value: 'Winston_MT', label: 'Winston, MT' },
  { value: 'Winston_MO', label: 'Winston, MO' },
  { value: 'Winston_NM', label: 'Winston, NM' },
  { value: 'Winston_OR', label: 'Winston, OR' },
  { value: 'Winston Salem_NC', label: 'Winston Salem, NC' },
  { value: 'Winstonville_MS', label: 'Winstonville, MS' },
  { value: 'Winter_WI', label: 'Winter, WI' },
  { value: 'Winter Garden_FL', label: 'Winter Garden, FL' },
  { value: 'Winter Harbor_ME', label: 'Winter Harbor, ME' },
  { value: 'Winter Haven_FL', label: 'Winter Haven, FL' },
  { value: 'Winter Park_FL', label: 'Winter Park, FL' },
  { value: 'Winter Park_CO', label: 'Winter Park, CO' },
  { value: 'Winter Springs_FL', label: 'Winter Springs, FL' },
  { value: 'Winterhaven_CA', label: 'Winterhaven, CA' },
  { value: 'Winterport_ME', label: 'Winterport, ME' },
  { value: 'Winters_TX', label: 'Winters, TX' },
  { value: 'Winters_CA', label: 'Winters, CA' },
  { value: 'Winterset_IA', label: 'Winterset, IA' },
  { value: 'Winterthur_DE', label: 'Winterthur, DE' },
  { value: 'Winterville_NC', label: 'Winterville, NC' },
  { value: 'Winterville_GA', label: 'Winterville, GA' },
  { value: 'Winthrop_MA', label: 'Winthrop, MA' },
  { value: 'Winthrop_ME', label: 'Winthrop, ME' },
  { value: 'Winthrop_NY', label: 'Winthrop, NY' },
  { value: 'Winthrop_IA', label: 'Winthrop, IA' },
  { value: 'Winthrop_MN', label: 'Winthrop, MN' },
  { value: 'Winthrop_AR', label: 'Winthrop, AR' },
  { value: 'Winthrop_WA', label: 'Winthrop, WA' },
  { value: 'Winthrop Harbor_IL', label: 'Winthrop Harbor, IL' },
  { value: 'Winton_NC', label: 'Winton, NC' },
  { value: 'Winton_MN', label: 'Winton, MN' },
  { value: 'Winton_CA', label: 'Winton, CA' },
  { value: 'Wiota_IA', label: 'Wiota, IA' },
  { value: 'Wirt_MN', label: 'Wirt, MN' },
  { value: 'Wirtz_VA', label: 'Wirtz, VA' },
  { value: 'Wiscasset_ME', label: 'Wiscasset, ME' },
  { value: 'Wisconsin Dells_WI', label: 'Wisconsin Dells, WI' },
  { value: 'Wisconsin Rapids_WI', label: 'Wisconsin Rapids, WI' },
  { value: 'Wisdom_MT', label: 'Wisdom, MT' },
  { value: 'Wise_VA', label: 'Wise, VA' },
  { value: 'Wise_NC', label: 'Wise, NC' },
  { value: 'Wise River_MT', label: 'Wise River, MT' },
  { value: 'Wiseman_AR', label: 'Wiseman, AR' },
  { value: 'Wishek_ND', label: 'Wishek, ND' },
  { value: 'Wishon_CA', label: 'Wishon, CA' },
  { value: 'Wishram_WA', label: 'Wishram, WA' },
  { value: 'Wisner_NE', label: 'Wisner, NE' },
  { value: 'Wisner_LA', label: 'Wisner, LA' },
  { value: 'Wister_OK', label: 'Wister, OK' },
  { value: 'Withams_VA', label: 'Withams, VA' },
  { value: 'Withee_WI', label: 'Withee, WI' },
  { value: 'Witherbee_NY', label: 'Witherbee, NY' },
  { value: 'Witt_IL', label: 'Witt, IL' },
  { value: 'Witten_SD', label: 'Witten, SD' },
  { value: 'Wittenberg_WI', label: 'Wittenberg, WI' },
  { value: 'Wittensville_KY', label: 'Wittensville, KY' },
  { value: 'Witter_AR', label: 'Witter, AR' },
  { value: 'Witter Springs_CA', label: 'Witter Springs, CA' },
  { value: 'Wittman_MD', label: 'Wittman, MD' },
  { value: 'Wittmann_AZ', label: 'Wittmann, AZ' },
  { value: 'Witts Springs_AR', label: 'Witts Springs, AR' },
  { value: 'Wixom_MI', label: 'Wixom, MI' },
  { value: 'Woburn_MA', label: 'Woburn, MA' },
  { value: 'Woden_IA', label: 'Woden, IA' },
  { value: 'Wofford Heights_CA', label: 'Wofford Heights, CA' },
  { value: 'Wolbach_NE', label: 'Wolbach, NE' },
  { value: 'Wolcott_VT', label: 'Wolcott, VT' },
  { value: 'Wolcott_CT', label: 'Wolcott, CT' },
  { value: 'Wolcott_NY', label: 'Wolcott, NY' },
  { value: 'Wolcott_IN', label: 'Wolcott, IN' },
  { value: 'Wolcott_CO', label: 'Wolcott, CO' },
  { value: 'Wolcottville_IN', label: 'Wolcottville, IN' },
  { value: 'Wolf_WY', label: 'Wolf, WY' },
  { value: 'Wolf Creek_MT', label: 'Wolf Creek, MT' },
  { value: 'Wolf Creek_OR', label: 'Wolf Creek, OR' },
  { value: 'Wolf Lake_IL', label: 'Wolf Lake, IL' },
  { value: 'Wolf Point_MT', label: 'Wolf Point, MT' },
  { value: 'Wolfcreek_WV', label: 'Wolfcreek, WV' },
  { value: 'Wolfe City_TX', label: 'Wolfe City, TX' },
  { value: 'Wolfeboro_NH', label: 'Wolfeboro, NH' },
  { value: 'Wolfforth_TX', label: 'Wolfforth, TX' },
  { value: 'Wolflake_IN', label: 'Wolflake, IN' },
  { value: 'Wolford_ND', label: 'Wolford, ND' },
  { value: 'Wolsey_SD', label: 'Wolsey, SD' },
  { value: 'Wolverine_MI', label: 'Wolverine, MI' },
  { value: 'Wolverton_MN', label: 'Wolverton, MN' },
  { value: 'Womelsdorf_PA', label: 'Womelsdorf, PA' },
  { value: 'Wonalancet_NH', label: 'Wonalancet, NH' },
  { value: 'Wonder Lake_IL', label: 'Wonder Lake, IL' },
  { value: 'Wonewoc_WI', label: 'Wonewoc, WI' },
  { value: 'Wood_PA', label: 'Wood, PA' },
  { value: 'Wood_SD', label: 'Wood, SD' },
  { value: 'Wood Dale_IL', label: 'Wood Dale, IL' },
  { value: 'Wood Lake_MN', label: 'Wood Lake, MN' },
  { value: 'Wood Lake_NE', label: 'Wood Lake, NE' },
  { value: 'Wood Ridge_NJ', label: 'Wood Ridge, NJ' },
  { value: 'Wood River_IL', label: 'Wood River, IL' },
  { value: 'Wood River_NE', label: 'Wood River, NE' },
  { value: 'Wood River Junction_RI', label: 'Wood River Junction, RI' },
  { value: 'Woodacre_CA', label: 'Woodacre, CA' },
  { value: 'Woodberry Forest_VA', label: 'Woodberry Forest, VA' },
  { value: 'Woodbine_NJ', label: 'Woodbine, NJ' },
  { value: 'Woodbine_MD', label: 'Woodbine, MD' },
  { value: 'Woodbine_GA', label: 'Woodbine, GA' },
  { value: 'Woodbine_KY', label: 'Woodbine, KY' },
  { value: 'Woodbine_IA', label: 'Woodbine, IA' },
  { value: 'Woodbine_KS', label: 'Woodbine, KS' },
  { value: 'Woodbourne_NY', label: 'Woodbourne, NY' },
  { value: 'Woodbridge_CT', label: 'Woodbridge, CT' },
  { value: 'Woodbridge_NJ', label: 'Woodbridge, NJ' },
  { value: 'Woodbridge_VA', label: 'Woodbridge, VA' },
  { value: 'Woodbridge_CA', label: 'Woodbridge, CA' },
  { value: 'Woodburn_KY', label: 'Woodburn, KY' },
  { value: 'Woodburn_IN', label: 'Woodburn, IN' },
  { value: 'Woodburn_IA', label: 'Woodburn, IA' },
  { value: 'Woodburn_OR', label: 'Woodburn, OR' },
  { value: 'Woodbury_VT', label: 'Woodbury, VT' },
  { value: 'Woodbury_CT', label: 'Woodbury, CT' },
  { value: 'Woodbury_NJ', label: 'Woodbury, NJ' },
  { value: 'Woodbury_NY', label: 'Woodbury, NY' },
  { value: 'Woodbury_PA', label: 'Woodbury, PA' },
  { value: 'Woodbury_GA', label: 'Woodbury, GA' },
  { value: 'Woodbury_TN', label: 'Woodbury, TN' },
  { value: 'Woodbury Heights_NJ', label: 'Woodbury Heights, NJ' },
  { value: 'Woodcliff Lake_NJ', label: 'Woodcliff Lake, NJ' },
  { value: 'Woodford_VA', label: 'Woodford, VA' },
  { value: 'Woodford_WI', label: 'Woodford, WI' },
  { value: 'Woodgate_NY', label: 'Woodgate, NY' },
  { value: 'Woodhaven_NY', label: 'Woodhaven, NY' },
  { value: 'Woodhull_NY', label: 'Woodhull, NY' },
  { value: 'Woodhull_IL', label: 'Woodhull, IL' },
  { value: 'Woodinville_WA', label: 'Woodinville, WA' },
  { value: 'Woodlake_TX', label: 'Woodlake, TX' },
  { value: 'Woodlake_CA', label: 'Woodlake, CA' },
  { value: 'Woodland_PA', label: 'Woodland, PA' },
  { value: 'Woodland_NC', label: 'Woodland, NC' },
  { value: 'Woodland_GA', label: 'Woodland, GA' },
  { value: 'Woodland_AL', label: 'Woodland, AL' },
  { value: 'Woodland_MS', label: 'Woodland, MS' },
  { value: 'Woodland_MI', label: 'Woodland, MI' },
  { value: 'Woodland_WI', label: 'Woodland, WI' },
  { value: 'Woodland_IL', label: 'Woodland, IL' },
  { value: 'Woodland_CA', label: 'Woodland, CA' },
  { value: 'Woodland_WA', label: 'Woodland, WA' },
  { value: 'Woodland Hills_CA', label: 'Woodland Hills, CA' },
  { value: 'Woodland Park_CO', label: 'Woodland Park, CO' },
  { value: 'Woodlawn_VA', label: 'Woodlawn, VA' },
  { value: 'Woodlawn_TN', label: 'Woodlawn, TN' },
  { value: 'Woodlawn_IL', label: 'Woodlawn, IL' },
  { value: 'Woodlawn_TX', label: 'Woodlawn, TX' },
  { value: 'Woodleaf_NC', label: 'Woodleaf, NC' },
  { value: 'Woodlyn_PA', label: 'Woodlyn, PA' },
  { value: 'Woodman_WI', label: 'Woodman, WI' },
  { value: 'Woodmere_NY', label: 'Woodmere, NY' },
  { value: 'Woodridge_NY', label: 'Woodridge, NY' },
  { value: 'Woodridge_IL', label: 'Woodridge, IL' },
  { value: 'Woodrow_CO', label: 'Woodrow, CO' },
  { value: 'Woodruff_SC', label: 'Woodruff, SC' },
  { value: 'Woodruff_WI', label: 'Woodruff, WI' },
  { value: 'Woodruff_UT', label: 'Woodruff, UT' },
  { value: 'Woodruff_AZ', label: 'Woodruff, AZ' },
  { value: 'Woods Cross_UT', label: 'Woods Cross, UT' },
  { value: 'Woods Hole_MA', label: 'Woods Hole, MA' },
  { value: 'Woodsboro_MD', label: 'Woodsboro, MD' },
  { value: 'Woodsboro_TX', label: 'Woodsboro, TX' },
  { value: 'Woodsfield_OH', label: 'Woodsfield, OH' },
  { value: 'Woodside_NY', label: 'Woodside, NY' },
  { value: 'Woodside_DE', label: 'Woodside, DE' },
  { value: 'Woodson_IL', label: 'Woodson, IL' },
  { value: 'Woodson_AR', label: 'Woodson, AR' },
  { value: 'Woodson_TX', label: 'Woodson, TX' },
  { value: 'Woodstock_NH', label: 'Woodstock, NH' },
  { value: 'Woodstock_VT', label: 'Woodstock, VT' },
  { value: 'Woodstock_CT', label: 'Woodstock, CT' },
  { value: 'Woodstock_NY', label: 'Woodstock, NY' },
  { value: 'Woodstock_MD', label: 'Woodstock, MD' },
  { value: 'Woodstock_VA', label: 'Woodstock, VA' },
  { value: 'Woodstock_GA', label: 'Woodstock, GA' },
  { value: 'Woodstock_AL', label: 'Woodstock, AL' },
  { value: 'Woodstock_OH', label: 'Woodstock, OH' },
  { value: 'Woodstock_MN', label: 'Woodstock, MN' },
  { value: 'Woodstock_IL', label: 'Woodstock, IL' },
  { value: 'Woodstock Valley_CT', label: 'Woodstock Valley, CT' },
  { value: 'Woodston_KS', label: 'Woodston, KS' },
  { value: 'Woodstown_NJ', label: 'Woodstown, NJ' },
  { value: 'Woodsville_NH', label: 'Woodsville, NH' },
  { value: 'Woodville_VA', label: 'Woodville, VA' },
  { value: 'Woodville_AL', label: 'Woodville, AL' },
  { value: 'Woodville_MS', label: 'Woodville, MS' },
  { value: 'Woodville_OH', label: 'Woodville, OH' },
  { value: 'Woodville_WI', label: 'Woodville, WI' },
  { value: 'Woodville_TX', label: 'Woodville, TX' },
  { value: 'Woodward_PA', label: 'Woodward, PA' },
  { value: 'Woodward_IA', label: 'Woodward, IA' },
  { value: 'Woodward_OK', label: 'Woodward, OK' },
  { value: 'Woodway_TX', label: 'Woodway, TX' },
  { value: 'Woodworth_ND', label: 'Woodworth, ND' },
  { value: 'Woodworth_LA', label: 'Woodworth, LA' },
  { value: 'Woody_CA', label: 'Woody, CA' },
  { value: 'Woody Creek_CO', label: 'Woody Creek, CO' },
  { value: 'Wooldridge_MO', label: 'Wooldridge, MO' },
  { value: 'Woolford_MD', label: 'Woolford, MD' },
  { value: 'Woolrich_PA', label: 'Woolrich, PA' },
  { value: 'Woolstock_IA', label: 'Woolstock, IA' },
  { value: 'Woolwich_ME', label: 'Woolwich, ME' },
  { value: 'Woolwine_VA', label: 'Woolwine, VA' },
  { value: 'Woonsocket_RI', label: 'Woonsocket, RI' },
  { value: 'Woonsocket_SD', label: 'Woonsocket, SD' },
  { value: 'Wooster_OH', label: 'Wooster, OH' },
  { value: 'Wooster_AR', label: 'Wooster, AR' },
  { value: 'Woosung_IL', label: 'Woosung, IL' },
  { value: 'Wooton_KY', label: 'Wooton, KY' },
  { value: 'Worcester_MA', label: 'Worcester, MA' },
  { value: 'Worcester_VT', label: 'Worcester, VT' },
  { value: 'Worcester_NY', label: 'Worcester, NY' },
  { value: 'Worcester_PA', label: 'Worcester, PA' },
  { value: 'Worden_MT', label: 'Worden, MT' },
  { value: 'Worden_IL', label: 'Worden, IL' },
  { value: 'Worland_WY', label: 'Worland, WY' },
  { value: 'Worley_ID', label: 'Worley, ID' },
  { value: 'Woronoco_MA', label: 'Woronoco, MA' },
  { value: 'Worth_IL', label: 'Worth, IL' },
  { value: 'Worth_MO', label: 'Worth, MO' },
  { value: 'Wortham_TX', label: 'Wortham, TX' },
  { value: 'Worthing_SD', label: 'Worthing, SD' },
  { value: 'Worthington_MA', label: 'Worthington, MA' },
  { value: 'Worthington_PA', label: 'Worthington, PA' },
  { value: 'Worthington_WV', label: 'Worthington, WV' },
  { value: 'Worthington_KY', label: 'Worthington, KY' },
  { value: 'Worthington_IN', label: 'Worthington, IN' },
  { value: 'Worthington_IA', label: 'Worthington, IA' },
  { value: 'Worthington_MN', label: 'Worthington, MN' },
  { value: 'Worthington_MO', label: 'Worthington, MO' },
  { value: 'Worthington Springs_FL', label: 'Worthington Springs, FL' },
  { value: 'Worthville_PA', label: 'Worthville, PA' },
  { value: 'Worthville_KY', label: 'Worthville, KY' },
  { value: 'Worton_MD', label: 'Worton, MD' },
  { value: 'Wounded Knee_SD', label: 'Wounded Knee, SD' },
  { value: 'Wrangell_AK', label: 'Wrangell, AK' },
  { value: 'Wray_GA', label: 'Wray, GA' },
  { value: 'Wray_CO', label: 'Wray, CO' },
  { value: 'Wren_OH', label: 'Wren, OH' },
  { value: 'Wrens_GA', label: 'Wrens, GA' },
  { value: 'Wrenshall_MN', label: 'Wrenshall, MN' },
  { value: 'Wrentham_MA', label: 'Wrentham, MA' },
  { value: 'Wright_MN', label: 'Wright, MN' },
  { value: 'Wright_KS', label: 'Wright, KS' },
  { value: 'Wright_AR', label: 'Wright, AR' },
  { value: 'Wright_WY', label: 'Wright, WY' },
  { value: 'Wright City_MO', label: 'Wright City, MO' },
  { value: 'Wright City_OK', label: 'Wright City, OK' },
  { value: 'Wrights_IL', label: 'Wrights, IL' },
  { value: 'Wrightsboro_TX', label: 'Wrightsboro, TX' },
  { value: 'Wrightstown_NJ', label: 'Wrightstown, NJ' },
  { value: 'Wrightstown_WI', label: 'Wrightstown, WI' },
  { value: 'Wrightsville_PA', label: 'Wrightsville, PA' },
  { value: 'Wrightsville_GA', label: 'Wrightsville, GA' },
  { value: 'Wrightsville_AR', label: 'Wrightsville, AR' },
  { value: 'Wrightsville Beach_NC', label: 'Wrightsville Beach, NC' },
  { value: 'Wrightwood_CA', label: 'Wrightwood, CA' },
  { value: 'Wurtsboro_NY', label: 'Wurtsboro, NY' },
  { value: 'Wyaconda_MO', label: 'Wyaconda, MO' },
  { value: 'Wyalusing_PA', label: 'Wyalusing, PA' },
  { value: 'Wyandanch_NY', label: 'Wyandanch, NY' },
  { value: 'Wyandotte_MI', label: 'Wyandotte, MI' },
  { value: 'Wyandotte_OK', label: 'Wyandotte, OK' },
  { value: 'Wyanet_IL', label: 'Wyanet, IL' },
  { value: 'Wyano_PA', label: 'Wyano, PA' },
  { value: 'Wyarno_WY', label: 'Wyarno, WY' },
  { value: 'Wyatt_WV', label: 'Wyatt, WV' },
  { value: 'Wyatt_IN', label: 'Wyatt, IN' },
  { value: 'Wyatt_MO', label: 'Wyatt, MO' },
  { value: 'Wyckoff_NJ', label: 'Wyckoff, NJ' },
  { value: 'Wyco_WV', label: 'Wyco, WV' },
  { value: 'Wycombe_PA', label: 'Wycombe, PA' },
  { value: 'Wye Mills_MD', label: 'Wye Mills, MD' },
  { value: 'Wykoff_MN', label: 'Wykoff, MN' },
  { value: 'Wylie_TX', label: 'Wylie, TX' },
  { value: 'Wylliesburg_VA', label: 'Wylliesburg, VA' },
  { value: 'Wymore_NE', label: 'Wymore, NE' },
  { value: 'Wynantskill_NY', label: 'Wynantskill, NY' },
  { value: 'Wyncote_PA', label: 'Wyncote, PA' },
  { value: 'Wyndmere_ND', label: 'Wyndmere, ND' },
  { value: 'Wynnburg_TN', label: 'Wynnburg, TN' },
  { value: 'Wynne_AR', label: 'Wynne, AR' },
  { value: 'Wynnewood_PA', label: 'Wynnewood, PA' },
  { value: 'Wynnewood_OK', label: 'Wynnewood, OK' },
  { value: 'Wynona_OK', label: 'Wynona, OK' },
  { value: 'Wynot_NE', label: 'Wynot, NE' },
  { value: 'Wyocena_WI', label: 'Wyocena, WI' },
  { value: 'Wyola_MT', label: 'Wyola, MT' },
  { value: 'Wyoming_RI', label: 'Wyoming, RI' },
  { value: 'Wyoming_NY', label: 'Wyoming, NY' },
  { value: 'Wyoming_PA', label: 'Wyoming, PA' },
  { value: 'Wyoming_WV', label: 'Wyoming, WV' },
  { value: 'Wyoming_MI', label: 'Wyoming, MI' },
  { value: 'Wyoming_IA', label: 'Wyoming, IA' },
  { value: 'Wyoming_MN', label: 'Wyoming, MN' },
  { value: 'Wyoming_IL', label: 'Wyoming, IL' },
  { value: 'Wysox_PA', label: 'Wysox, PA' },
  { value: 'Wytheville_VA', label: 'Wytheville, VA' },
  { value: 'Wytopitlock_ME', label: 'Wytopitlock, ME' },
  { value: 'Xenia_OH', label: 'Xenia, OH' },
  { value: 'Xenia_IL', label: 'Xenia, IL' },
  { value: 'Yabucoa_PR', label: 'Yabucoa, PR' },
  { value: 'Yachats_OR', label: 'Yachats, OR' },
  { value: 'Yacolt_WA', label: 'Yacolt, WA' },
  { value: 'Yadkinville_NC', label: 'Yadkinville, NC' },
  { value: 'Yakima_WA', label: 'Yakima, WA' },
  { value: 'Yakutat_AK', label: 'Yakutat, AK' },
  { value: 'Yalaha_FL', label: 'Yalaha, FL' },
  { value: 'Yale_VA', label: 'Yale, VA' },
  { value: 'Yale_MI', label: 'Yale, MI' },
  { value: 'Yale_IA', label: 'Yale, IA' },
  { value: 'Yale_SD', label: 'Yale, SD' },
  { value: 'Yale_IL', label: 'Yale, IL' },
  { value: 'Yale_OK', label: 'Yale, OK' },
  { value: 'Yamhill_OR', label: 'Yamhill, OR' },
  { value: 'Yampa_CO', label: 'Yampa, CO' },
  { value: 'Yancey_TX', label: 'Yancey, TX' },
  { value: 'Yanceyville_NC', label: 'Yanceyville, NC' },
  { value: 'Yankeetown_FL', label: 'Yankeetown, FL' },
  { value: 'Yankton_SD', label: 'Yankton, SD' },
  { value: 'Yantic_CT', label: 'Yantic, CT' },
  { value: 'Yantis_TX', label: 'Yantis, TX' },
  { value: 'Yaphank_NY', label: 'Yaphank, NY' },
  { value: 'Yarmouth_ME', label: 'Yarmouth, ME' },
  { value: 'Yarmouth_IA', label: 'Yarmouth, IA' },
  { value: 'Yarmouth Port_MA', label: 'Yarmouth Port, MA' },
  { value: 'Yarnell_AZ', label: 'Yarnell, AZ' },
  { value: 'Yatahey_NM', label: 'Yatahey, NM' },
  { value: 'Yates Center_KS', label: 'Yates Center, KS' },
  { value: 'Yates City_IL', label: 'Yates City, IL' },
  { value: 'Yatesboro_PA', label: 'Yatesboro, PA' },
  { value: 'Yatesville_GA', label: 'Yatesville, GA' },
  { value: 'Yauco_PR', label: 'Yauco, PR' },
  { value: 'Yawkey_WV', label: 'Yawkey, WV' },
  { value: 'Yazoo City_MS', label: 'Yazoo City, MS' },
  { value: 'Yeaddiss_KY', label: 'Yeaddiss, KY' },
  { value: 'Yeagertown_PA', label: 'Yeagertown, PA' },
  { value: 'Yellow Jacket_CO', label: 'Yellow Jacket, CO' },
  { value: 'Yellow Pine_ID', label: 'Yellow Pine, ID' },
  { value: 'Yellow Spring_WV', label: 'Yellow Spring, WV' },
  { value: 'Yellow Springs_OH', label: 'Yellow Springs, OH' },
  {
    value: 'Yellowstone National Park_WY',
    label: 'Yellowstone National Park, WY',
  },
  { value: 'Yellville_AR', label: 'Yellville, AR' },
  { value: 'Yelm_WA', label: 'Yelm, WA' },
  { value: 'Yemassee_SC', label: 'Yemassee, SC' },
  { value: 'Yeoman_IN', label: 'Yeoman, IN' },
  { value: 'Yerington_NV', label: 'Yerington, NV' },
  { value: 'Yerkes_KY', label: 'Yerkes, KY' },
  { value: 'Yermo_CA', label: 'Yermo, CA' },
  { value: 'Yeso_NM', label: 'Yeso, NM' },
  { value: 'Yettem_CA', label: 'Yettem, CA' },
  { value: 'Yigo_GU', label: 'Yigo, GU' },
  { value: 'Yoakum_TX', label: 'Yoakum, TX' },
  { value: 'Yoder_IN', label: 'Yoder, IN' },
  { value: 'Yoder_CO', label: 'Yoder, CO' },
  { value: 'Yoder_WY', label: 'Yoder, WY' },
  { value: 'Yolo_CA', label: 'Yolo, CA' },
  { value: 'Yolyn_WV', label: 'Yolyn, WV' },
  { value: 'Yoncalla_OR', label: 'Yoncalla, OR' },
  { value: 'Yonkers_NY', label: 'Yonkers, NY' },
  { value: 'Yorba Linda_CA', label: 'Yorba Linda, CA' },
  { value: 'York_ME', label: 'York, ME' },
  { value: 'York_NY', label: 'York, NY' },
  { value: 'York_PA', label: 'York, PA' },
  { value: 'York_SC', label: 'York, SC' },
  { value: 'York_AL', label: 'York, AL' },
  { value: 'York_ND', label: 'York, ND' },
  { value: 'York_NE', label: 'York, NE' },
  { value: 'York Beach_ME', label: 'York Beach, ME' },
  { value: 'York Harbor_ME', label: 'York Harbor, ME' },
  { value: 'York Haven_PA', label: 'York Haven, PA' },
  { value: 'York New Salem_PA', label: 'York New Salem, PA' },
  { value: 'York Springs_PA', label: 'York Springs, PA' },
  { value: 'Yorklyn_DE', label: 'Yorklyn, DE' },
  { value: 'Yorkshire_NY', label: 'Yorkshire, NY' },
  { value: 'Yorkshire_OH', label: 'Yorkshire, OH' },
  { value: 'Yorktown_VA', label: 'Yorktown, VA' },
  { value: 'Yorktown_IN', label: 'Yorktown, IN' },
  { value: 'Yorktown_IA', label: 'Yorktown, IA' },
  { value: 'Yorktown_AR', label: 'Yorktown, AR' },
  { value: 'Yorktown_TX', label: 'Yorktown, TX' },
  { value: 'Yorktown Heights_NY', label: 'Yorktown Heights, NY' },
  { value: 'Yorkville_NY', label: 'Yorkville, NY' },
  { value: 'Yorkville_TN', label: 'Yorkville, TN' },
  { value: 'Yorkville_OH', label: 'Yorkville, OH' },
  { value: 'Yorkville_IL', label: 'Yorkville, IL' },
  { value: 'Yorkville_CA', label: 'Yorkville, CA' },
  { value: 'Yosemite_KY', label: 'Yosemite, KY' },
  { value: 'Yosemite National Park_CA', label: 'Yosemite National Park, CA' },
  { value: 'Young_AZ', label: 'Young, AZ' },
  { value: 'Young America_IN', label: 'Young America, IN' },
  { value: 'Young America_MN', label: 'Young America, MN' },
  { value: 'Young Harris_GA', label: 'Young Harris, GA' },
  { value: 'Youngstown_NY', label: 'Youngstown, NY' },
  { value: 'Youngstown_PA', label: 'Youngstown, PA' },
  { value: 'Youngstown_FL', label: 'Youngstown, FL' },
  { value: 'Youngstown_OH', label: 'Youngstown, OH' },
  { value: 'Youngsville_NY', label: 'Youngsville, NY' },
  { value: 'Youngsville_PA', label: 'Youngsville, PA' },
  { value: 'Youngsville_NC', label: 'Youngsville, NC' },
  { value: 'Youngsville_LA', label: 'Youngsville, LA' },
  { value: 'Youngsville_NM', label: 'Youngsville, NM' },
  { value: 'Youngtown_AZ', label: 'Youngtown, AZ' },
  { value: 'Youngwood_PA', label: 'Youngwood, PA' },
  { value: 'Yountville_CA', label: 'Yountville, CA' },
  { value: 'Ypsilanti_MI', label: 'Ypsilanti, MI' },
  { value: 'Ypsilanti_ND', label: 'Ypsilanti, ND' },
  { value: 'Yreka_CA', label: 'Yreka, CA' },
  { value: 'Yuba City_CA', label: 'Yuba City, CA' },
  { value: 'Yucaipa_CA', label: 'Yucaipa, CA' },
  { value: 'Yucca_AZ', label: 'Yucca, AZ' },
  { value: 'Yucca Valley_CA', label: 'Yucca Valley, CA' },
  { value: 'Yukon_PA', label: 'Yukon, PA' },
  { value: 'Yukon_MO', label: 'Yukon, MO' },
  { value: 'Yukon_OK', label: 'Yukon, OK' },
  { value: 'Yulan_NY', label: 'Yulan, NY' },
  { value: 'Yulee_FL', label: 'Yulee, FL' },
  { value: 'Yuma_TN', label: 'Yuma, TN' },
  { value: 'Yuma_CO', label: 'Yuma, CO' },
  { value: 'Yuma_AZ', label: 'Yuma, AZ' },
  { value: 'Yutan_NE', label: 'Yutan, NE' },
  { value: 'Zachary_LA', label: 'Zachary, LA' },
  { value: 'Zahl_ND', label: 'Zahl, ND' },
  { value: 'Zaleski_OH', label: 'Zaleski, OH' },
  { value: 'Zalma_MO', label: 'Zalma, MO' },
  { value: 'Zamora_CA', label: 'Zamora, CA' },
  { value: 'Zanesfield_OH', label: 'Zanesfield, OH' },
  { value: 'Zanesville_OH', label: 'Zanesville, OH' },
  { value: 'Zanesville_IN', label: 'Zanesville, IN' },
  { value: 'Zanoni_MO', label: 'Zanoni, MO' },
  { value: 'Zap_ND', label: 'Zap, ND' },
  { value: 'Zapata_TX', label: 'Zapata, TX' },
  { value: 'Zarephath_NJ', label: 'Zarephath, NJ' },
  { value: 'Zavalla_TX', label: 'Zavalla, TX' },
  { value: 'Zearing_IA', label: 'Zearing, IA' },
  { value: 'Zebulon_NC', label: 'Zebulon, NC' },
  { value: 'Zebulon_GA', label: 'Zebulon, GA' },
  { value: 'Zeeland_MI', label: 'Zeeland, MI' },
  { value: 'Zeeland_ND', label: 'Zeeland, ND' },
  { value: 'Zeigler_IL', label: 'Zeigler, IL' },
  { value: 'Zelienople_PA', label: 'Zelienople, PA' },
  { value: 'Zellwood_FL', label: 'Zellwood, FL' },
  { value: 'Zenda_WI', label: 'Zenda, WI' },
  { value: 'Zenda_KS', label: 'Zenda, KS' },
  { value: 'Zenia_CA', label: 'Zenia, CA' },
  { value: 'Zephyr_TX', label: 'Zephyr, TX' },
  { value: 'Zephyr Cove_NV', label: 'Zephyr Cove, NV' },
  { value: 'Zephyrhills_FL', label: 'Zephyrhills, FL' },
  { value: 'Zieglerville_PA', label: 'Zieglerville, PA' },
  { value: 'Zillah_WA', label: 'Zillah, WA' },
  { value: 'Zimmerman_MN', label: 'Zimmerman, MN' },
  { value: 'Zion_IL', label: 'Zion, IL' },
  { value: 'Zion Grove_PA', label: 'Zion Grove, PA' },
  { value: 'Zionsville_PA', label: 'Zionsville, PA' },
  { value: 'Zionsville_IN', label: 'Zionsville, IN' },
  { value: 'Zionville_NC', label: 'Zionville, NC' },
  { value: 'Zirconia_NC', label: 'Zirconia, NC' },
  { value: 'Zoar_OH', label: 'Zoar, OH' },
  { value: 'Zoe_KY', label: 'Zoe, KY' },
  { value: 'Zolfo Springs_FL', label: 'Zolfo Springs, FL' },
  { value: 'Zortman_MT', label: 'Zortman, MT' },
  { value: 'Zumbro Falls_MN', label: 'Zumbro Falls, MN' },
  { value: 'Zumbrota_MN', label: 'Zumbrota, MN' },
  { value: 'Zuni_VA', label: 'Zuni, VA' },
  { value: 'Zuni_NM', label: 'Zuni, NM' },
  { value: 'Zurich_MT', label: 'Zurich, MT' },
  { value: 'Zwingle_IA', label: 'Zwingle, IA' },
  { value: 'Zwolle_LA', label: 'Zwolle, LA' },
  { value: 'city_state_id', label: 'city, state_id' },
]
