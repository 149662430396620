import { useMemo } from 'react'
import apiService from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Category,
  CompanyCategorySlug,
  CompanyTypeSlug,
  SubCategory,
} from 'models/companies'

export type OpportunityDropdownItemValue = {
  category: CompanyCategorySlug
  subCategory: CompanyTypeSlug
}

export const useOpportunitiesTabs = () => {
  const api = apiService()
  const { data: companyCatAndSub, isLoading } =
    api.useGetCompanyCategoriesAndSub()

  const params = useParams() as {
    category?: CompanyCategorySlug
    sub_category?: CompanyTypeSlug
  }

  const navigate = useNavigate()
  const categorySelected = params?.category ?? 'commercial'
  const subcategorySelected = params?.sub_category ?? 'restaurants-bars'

  const mainTabs = useMemo(
    () =>
      companyCatAndSub?.map((c) => ({
        key: c.slug,
        title: c.name,
        dropdownItems:
          c.sub_categories.map((sub) => ({
            title: sub.name,
            key: sub.slug,
            value: {
              category: c.slug,
              subCategory: sub.slug,
            } as OpportunityDropdownItemValue,
          })) || [],
      })) ?? [],
    [companyCatAndSub]
  )

  const categoryActive = companyCatAndSub?.find(
    (category: Category) => category.slug === categorySelected
  )

  const subTabs = useMemo(() => {
    return (
      categoryActive?.sub_categories?.map((c: SubCategory) => ({
        key: c.slug,
        title: c.name,
      })) ?? []
    )
  }, [categorySelected, companyCatAndSub])

  let subCategoryActive = categoryActive?.sub_categories?.find(
    (s: SubCategory) => s.slug === subcategorySelected
  )

  // If the active subcategory is not found and there are subcategories available
  if (!subCategoryActive && categoryActive?.sub_categories?.length) {
    // Set the active subcategory to the default subcategory (first subcategory)

    subCategoryActive = categoryActive.sub_categories[0]
    // Since a default subcategory is selected if the subcategory is not found,
    // We update the path to reflect the selected default subcategory
    navigate(`/opportunities/${categorySelected}/${subCategoryActive.slug}`)
  }

  const restaurantsTabsSelected = useMemo(
    () =>
      subcategorySelected === 'restaurants-bars' &&
      categorySelected === 'commercial',
    [subcategorySelected, categorySelected]
  )

  const universitiesTabSelected = useMemo(
    () =>
      subcategorySelected === 'education-cu' &&
      categorySelected === 'non-commercial',
    [subcategorySelected, categorySelected]
  )

  const k12selected = useMemo(
    () =>
      subcategorySelected === 'education-k-12' &&
      categorySelected === 'non-commercial',
    [subcategorySelected, categorySelected]
  )

  return {
    mainTabs,
    subTabs,
    restaurantsTabsSelected,
    universitiesTabSelected,
    subCategoryActive,
    categoryActive,
    k12selected,
    isLoading,
  }
}
