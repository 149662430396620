import { IContactCompany } from 'models/contact_companies'
import { CompanyFormShape, CompanyModal } from './CompanyModal'
import { useMemo } from 'react'
import apiService from 'services/api'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export function EditCompanyModal({
  show,
  setShow,
  company,
}: {
  show: boolean
  setShow: (show: boolean) => void
  company: IContactCompany
}) {
  const api = apiService()
  const queryClient = useQueryClient()

  const apiAction = async (values: CompanyFormShape) => {
    delete values.taglist
    delete values.distributors
    const payload = {
      ...values,
      name: undefined, // name is not editable
      country: values.country || 'US',
      state: values.state || '',
      city: values.city || '',
      domain: values.domain?.toLowerCase() || '',
    }
    const response = await api
      .updateCompany(company.id, payload)
      .then((response) => {
        toast.success('Company updated successfully')
        return response
      })
    await queryClient.invalidateQueries({
      queryKey: ['company', company.id.toString()],
    })
    return response
  }

  const defaultValues: CompanyFormShape = useMemo(() => {
    return {
      ...company,
      category: company.category_id,
      sub_category: company.sub_category_id,
      distributors: company.distributors?.map((d) => d.id),
      taglist: company.taglist?.map((tag) => tag.id),
      unique_domain: true,
    }
  }, [company])

  return (
    <CompanyModal
      show={show}
      setShow={setShow}
      disableFields={['name']}
      hideKnownDistributorsAndTags
      hideCategoryAndType
      defaultValues={defaultValues}
      apiAction={apiAction}
      title="Edit Company"
      description="Add or modify information about this company."
      acceptButtonText="Apply Changes"
    />
  )
}
