import Select from 'components/FormUtils/Select'
import { FilterIdentifier } from 'models/saved_view'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import apiService from 'services/api'
import { FilterStore } from '../FilterStore'

type SelectSavedFilterProps = {
  identifier: FilterIdentifier
  filterStore: FilterStore
}

export default function SelectSavedFilter({
  identifier,
  filterStore,
}: SelectSavedFilterProps) {
  const api = apiService()

  const { clearAllFilters, loadSavedFilter } = filterStore()

  const { data: savedViewsOptions } = api.useGetSavedFiltersOptions(identifier)

  const { control, watch } = useForm({
    defaultValues: {
      selectedFilter: null,
    },
  })

  const selectedFilter = watch('selectedFilter')

  useEffect(() => {
    if (selectedFilter) {
      clearAllFilters()
      const savedFilter = savedViewsOptions?.find(
        (option) => option.value === selectedFilter
      )?.original?.payload
      if (savedFilter) {
        loadSavedFilter(savedFilter)
      }
    }
  }, [selectedFilter])

  return (
    <Select
      style={{ flex: 1 }}
      control={control}
      name="selectedFilter"
      label="Saved Filters"
      placeholder="Select a saved filter"
      options={savedViewsOptions}
    />
  )
}
