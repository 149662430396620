import BaseStep from './BaseStep'
import styled from 'styled-components/macro'
import apiService from 'services/api'
import to from 'await-to-js'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'
import { CompanyAutocomplete } from 'components/CompanyAutocomplete'
import { useMemo, useState } from 'react'

type CompanyAssignment = {
  companyName: string
  matchedCompany?: {
    id?: number
    chain?: number
  }
  exclude: boolean
}

const schema = z.object({
  companies: z.array(
    z
      .object({
        companyName: z.string(),
        matchedCompany: z
          .object({
            id: z.number().optional(),
            chain: z.number().optional(),
          })
          .optional()
          .nullable(),
        exclude: z.boolean(),
      })
      .refine(
        (v) => {
          return v.matchedCompany || v.exclude
        },
        {
          message: 'Must match company or exclude',
          path: ['matchedCompany'],
        }
      )
  ),
})

export function MatchCompanyStep() {
  const {
    methods: {
      handleClose,
      prevStep,
      nextStep,
      setPreviewData,
      setMatchedCompanies,
    },
    state: { unmatchedCompanies, file, columnAssignment },
  } = useImportContactContext()

  const data: CompanyAssignment[] = useMemo(() => {
    return unmatchedCompanies
      ? unmatchedCompanies.map((companyName) => {
          return {
            companyName,
            exclude: false,
          }
        })
      : []
  }, [unmatchedCompanies])

  const [isCompanyFetched, setIsCompanyFetched] = useState<boolean[]>(
    data.map(() => false)
  )

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { companies: data },
  })

  if (!file) return null

  const api = apiService()
  const onSubmit = handleSubmit(
    async (data) => {
      const hasCompanyMatch = data.companies.find(
        (company) => company.matchedCompany !== undefined
      )
      if (!hasCompanyMatch) {
        // Just skip this step if no companies are matched
        nextStep()
        return
      }

      const matchedCompanies = data.companies
        .filter((company) => !company.exclude)
        .map((company) => ({
          company_name: company.companyName,
          matched_company: company.matchedCompany,
        }))

      setMatchedCompanies(matchedCompanies)

      const [err, response] = await to(
        api.previewContactsImport(file, columnAssignment, matchedCompanies)
      )
      if (err) {
        console.error(err)
        return
      }

      setPreviewData(response)
      nextStep()
    },
    (errors) => console.error(errors)
  )

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      title="Update New Contacts - Match Companies"
      backButtonText="Back"
      onContinue={onSubmit}
      isContinueLoading={isSubmitting}
    >
      <S.Content>
        <S.ContentTitle>
          {data.length ? (
            <>
              Match each company to an existing company in First Bite. If a
              company does not exist in First Bite, you can add it manually.
            </>
          ) : (
            <>No companies to match. Click continue to skip this step.</>
          )}
        </S.ContentTitle>
        {data.length > 0 && (
          <form onSubmit={onSubmit}>
            <Table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Company Name</th>
                  <th style={{ flex: 1 }}>Matched Company</th>
                  {/* Max size of exclude is 50px */}
                  <th style={{ width: 60, textAlign: 'center' }}>Exclude</th>
                </tr>
              </thead>
              <tbody>
                {data.map((company, index) => (
                  <tr key={company.companyName}>
                    <td>
                      <div className={'flex items-center h-full'}>
                        {company.companyName}
                      </div>
                    </td>
                    <td>
                      <CompanyAutocomplete
                        className={'mt-0'}
                        key={company.companyName}
                        control={control}
                        name={`companies.${index}.matchedCompany`}
                        label=""
                        placeholder="Select a company"
                        initialSearch={company.companyName}
                        menuPosition="fixed"
                        disableCreate
                        onFetchDone={() => {
                          setIsCompanyFetched((prev) => {
                            const newIsCompanyFetched = [...prev]
                            newIsCompanyFetched[index] = true
                            return newIsCompanyFetched
                          })
                        }}
                        autoSelectFirstOption
                        readOnly={!isCompanyFetched[index]}
                      />
                    </td>
                    <td>
                      <div className={'flex items-center h-full'}>
                        <Checkbox
                          style={{ justifyContent: 'center' }}
                          control={control}
                          name={`companies.${index}.exclude`}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </form>
        )}
      </S.Content>
    </BaseStep>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  td,
  th {
    padding: 4px;
  }
`
