import { AuthenticatedNav } from '../../../components/Layout/Navbar'
import { useAuth } from 'context/authentication/useAuth'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import Footer from 'components/Layout/Footer/Footer'

export function AppLayout() {
  const { isAuthenticated } = useAuth()
  return (
    <LayoutRoot>
      {isAuthenticated && <AuthenticatedNav />}
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </LayoutRoot>
  )
}

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
