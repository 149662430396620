import React, { Fragment } from 'react'
import apiService from '../../services/api'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'
import { ClientDataTable } from '../ClientDataTable/ClientDataTable'
import { formatInteger } from '../../utils/formatting'

interface IChainMenuCardProps {
  chainId?: number
}

export function ChainMenuSection(props: IChainMenuCardProps) {
  const api = apiService()
  const { data: menu, isLoading: isLoadingMenu } = api.useGetChainMenuList(
    props.chainId
  )

  const columns = [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Item Name',
      size: 150,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: 'Description',
      size: 200,
    },
    {
      id: 'price',
      accessorKey: 'price',
      header: 'Price',
      size: 100,
    },
  ]

  if (isLoadingMenu || !menu) {
    return null
  }

  return (
    <CollapsibleSection
      forceClose={menu.length < 1}
      summary={
        <>
          Menu{' '}
          <span className={'font-thin'}>({formatInteger(menu.length)})</span>
        </>
      }
    >
      <ClientDataTable
        data={menu}
        columns={columns}
        enableSorting={true}
        enableGlobalSearch={true}
        searchPlaceholder={'Search menu by keywords...'}
      />
    </CollapsibleSection>
  )
}
