import { formatInteger, formatPercentage, formatUsd } from 'utils/formatting'
import * as S from './ZipCodeDemographics.styles'
import { AddressInfo } from '../../models/restaurant'

export function ZipCodeDemographics(props: { addressInfo?: AddressInfo }) {
  if (!props.addressInfo) {
    return null
  }
  return (
    <S.PopIncomeInfo>
      <S.CardTitle>ZIP Code Demographics</S.CardTitle>
      <S.Label>Median Household Income</S.Label>
      <S.ValueBig>
        {formatUsd(props.addressInfo.income_medianhouseholdincome_hh_cnt)}
      </S.ValueBig>
      <S.Label>Population Density</S.Label>
      <S.ValueBig>
        {formatInteger(props.addressInfo.pop_sqmi)} people / mile&sup2;
      </S.ValueBig>
      <S.Row style={{ gap: 10 }}>
        <div>
          <S.Label>HH Income $100k+</S.Label>
          <S.ValueBig>
            {formatPercentage(
              (props.addressInfo.income_100000to149999_hh_pct +
                props.addressInfo.income_150000to199999_hh_pct +
                props.addressInfo.income_200000ormore_hh_pct) *
                100
            )}
            %
          </S.ValueBig>
        </div>
        <div>
          <S.Label>Unemployment Rate</S.Label>
          <S.ValueBig>
            {formatInteger(
              props.addressInfo
                .employment_unemployed_rate_county_level_only_pct * 100
            )}
            %
          </S.ValueBig>
        </div>
      </S.Row>
    </S.PopIncomeInfo>
  )
}
