import { ColumnDef } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { Activity } from 'models/activity'
import CellEventFormatter from './CellEvent/CellEventFormatter'
import { MdPersonOutline } from 'react-icons/md'
import { getCompanyUrl } from 'utils/getCompanyUrl'

const contentTypeMap: Record<string, any> = {
  contact: 'Contact',
  contactcompany: 'Company',
  note: 'Note',
  deal: 'Deal',
  product: 'Product',
  savedview: 'Saved Filter',
}

const tryToGetCompanyFromChanges = (changes: any) => {
  if (!changes) return '-'
  const companyValues = changes.contact_company?.objects
  if (!companyValues) return '-'
  const company = companyValues.find((v: string) => v !== 'None')
  if (!company) return '-'
  // Get the last number between parentheses (there could be multiple parentheses)
  // Since user can override name, he can add parentheses to the name as well, last parentheses will allways be the id
  // E.g.: "Company Name (123)" -> 123, "Company Name (User Overriden) (456)" -> 456
  const companyId = company
    .match(/\(([^)]+)\)/g)
    .pop()
    .replace(/\D/g, '')
  const companyName = company.split(' - ')[0]
  return <FbLink to={`/company/${companyId}`}>{companyName}</FbLink>
}

export const useActivityTableColumns = (): ColumnDef<Activity, any>[] => {
  return [
    {
      id: 'actor',
      accessorKey: 'actor',
      header: 'Team Member',
      cell: ({ row }) => {
        const actor = row.original.actor
        return (
          <div className="d-flex align-items-center gap-1">
            <MdPersonOutline size={20} />
            <div>
              {actor.first_name} {actor.last_name}
            </div>
          </div>
        )
      },
    },
    {
      id: 'event',
      accessorKey: 'changes',
      header: 'Event',
      size: 300,
      cell: ({ row }) => {
        return CellEventFormatter(row.original)
      },
    },
    {
      id: 'content_type',
      accessorKey: 'content_type',
      header: 'Type',
      cell: ({ row }) => {
        return contentTypeMap[row.original.content_type]
      },
    },
    {
      id: 'company',
      accessorKey: 'contact_company.name',
      header: 'Company',
      cell: ({ row }) => {
        const company = row.original.contact_company
        if (!company) return tryToGetCompanyFromChanges(row.original.changes)
        return <FbLink to={getCompanyUrl(company)}>{company.name}</FbLink>
      },
    },
    {
      id: 'timestamp',
      accessorKey: 'timestamp',
      header: 'Date',
      cell: ({ row }) => {
        const date = new Date(row.original.timestamp)
        // Date needs to be formatted like this: May 16, 2023,  4:14 PM
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      },
    },
  ]
}
