import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

export const extensionZodValidator = z
  .string()
  .max(6, 'Extensions are limited to 6 numbers')
  .optional()
  .refine((v) => {
    if (!v) return true
    return /^\d+$/.test(v)
  }, 'Extension must be a number')
export const phoneNumberZodValidor = z
  .string()
  .optional()
  .refine(
    (v) => {
      if (!v) return true
      return isValidPhoneNumber(v)
    },
    {
      message: 'Enter phone number as: +1 ### ### ####',
    }
  )

export const ifExtensionThenPhoneNumberZodValidator = z
