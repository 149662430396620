import styled from 'styled-components/macro'
import { Link, useParams } from 'react-router-dom'
import { handleFormError } from '../../../utils/handleFormError'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { useForm } from 'react-hook-form'
import { useAuth } from 'context/authentication/useAuth'
import to from 'await-to-js'
import { useState } from 'react'
import { Text } from '../../../components/FbUI/Text'
import FbButton from '../../../components/FbUI/FbButton'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const resetPasswordConfirmFormShape = z.object({
  email: z.string().email(),
  password1: z.string().min(8),
  password2: z.string().min(8),
})

function ResetPasswordConfirm() {
  const { resetPasswordConfirm } = useAuth()
  const [successMsg, setSuccessMsg] = useState('')
  const urlParams = useParams()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      non_field_errors: '',
      password1: '',
      password2: '',
    },
    resolver: zodResolver(resetPasswordConfirmFormShape),
  })

  const onSubmit = handleSubmit(
    async (values) => {
      if (!urlParams?.uid || !urlParams?.token)
        return setError('non_field_errors', { message: 'Invalid URL' })

      const [err, res] = await to(
        resetPasswordConfirm(
          values.email,
          urlParams?.uid,
          urlParams?.token,
          values?.password1,
          values?.password2
        )
      )
      if (err) {
        handleFormError(err, setError)
        return
      }

      if (res) setSuccessMsg(res.detail)
    },
    (errors) => console.warn(errors)
  )

  return (
    <div style={{ width: '400px', flexShrink: 0 }}>
      <Title>Reset Password</Title>
      {successMsg && (
        <Text>
          {successMsg} <Link to="/login">Click here</Link> to return to Login
          page
        </Text>
      )}
      <TextInput control={control} name="email" label="Email" />
      <TextInput
        type="password"
        control={control}
        name="password1"
        label="New Password"
      />
      <TextInput
        type="password"
        control={control}
        name="password2"
        label="Confirm New Password"
      />
      <ButtonContainer>
        <FbButton type="submit" onClick={onSubmit} loading={isSubmitting}>
          Reset
        </FbButton>
      </ButtonContainer>
    </div>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
`

export default ResetPasswordConfirm
