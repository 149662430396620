import React from 'react'
import { Tabs } from '../../../components/FbUI/Tabs'
import styled from 'styled-components/macro'
import { Page } from 'components/FbUI/Page'
import useDocumentTitle from 'components/useDocumentTitle'
import { ActivityTableV2 } from 'components/Tables/ActivityTableV2/ActivityTableV2'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavbarStore } from '../../../components/Layout/navbar-store'

export function ActivityPageV2() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'all'
  const store = useNavbarStore()

  const [totalActivity, setTotalActivity] = React.useState<number | null>(null)
  const [totalMyActivity, setTotalMyActivity] = React.useState<number | null>(
    null
  )

  useDocumentTitle(
    `${
      selectedTab === 'my-activity' ? 'My' : 'Team'
    } Activity - Activity - First Bite`
  )

  return (
    <Page>
      <Header>
        <TitleRow>
          <Title>Team Activity</Title>
        </TitleRow>

        <TabsAndStats>
          <Tabs
            tabs={[
              {
                key: 'all',
                title: (
                  <div>
                    <b>Team activity</b>{' '}
                    {totalActivity ? `(${totalActivity})` : ''}
                  </div>
                ),
              },
              {
                key: 'my',
                title: (
                  <div>
                    <b>My activity</b>{' '}
                    {totalMyActivity ? `(${totalMyActivity})` : ''}
                  </div>
                ),
              },
            ]}
            selectedTab={selectedTab}
            setSelectedTab={(value) => {
              const url = `/activity/${value}/`
              navigate(url)
              store.setLastActivityVisited(url)
            }}
            tabStyle={{ width: '180px' }}
          />
        </TabsAndStats>
      </Header>

      <TableContainer show={selectedTab === 'all'}>
        {selectedTab === 'all' && (
          <ActivityTableV2
            tableKey={'all-activity-table-v2'}
            setTotalRowsCount={setTotalActivity}
          />
        )}
      </TableContainer>

      <TableContainer show={selectedTab === 'my'}>
        {selectedTab === 'my' && (
          <ActivityTableV2
            tableKey={'my-activity-table-v2'}
            setTotalRowsCount={setTotalMyActivity}
            myActivityOnly
          />
        )}
      </TableContainer>
    </Page>
  )
}

const Header = styled.div`
  padding: 16px 25px 0 25px;
`

const TabsAndStats = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 100px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 12px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`

// table container height based on
// footer height + navbar height + table header height
const TableContainer = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
  height: calc(100vh - 260px);
`
