import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'

export default function ContactCompanyExportModal(
  props: ExportModalProps & {
    subCategoryName: string
  }
) {
  const api = apiService()

  const exportContactCompanies = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportContactCompanies(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Opportunites - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Opportunities Attributes',
      elements: [
        ['name', 'Name'],
        ['domain', 'Domain'],
        ['address', 'Address'],
        ['city', 'City'],
        ['state', 'State'],
        ['zipcode', 'ZIP Code'],
        ['country', 'Country'],
        ['created', 'Date Created'],
        ['category', 'Category'],
        ['type', 'Type'],
      ],
    },
    {
      title: 'Account Details',
      elements: [
        ['sales_stage', 'Sales Stages'],
        ['tags', 'Tags'],
        ['distributors', 'Known Distributors'],
        ['account_owners', 'Account Owners'],
        ['deal_count', 'Deals Count'],
        ['note_count', 'Note Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title={`Select Columns for Unmatched ${props.subCategoryName} Download`}
      subTitle="Download up to 10,000 opportunites at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Companies"
      columns={columns}
      exportAPIAction={exportContactCompanies}
      {...props}
    />
  )
}
