import styled from 'styled-components/macro'

export const DataTablePaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
`

export const PageButton = styled.button<{
  isActive: boolean
}>`
  border: none;

  width: 50px;
  height: 40px;

  background-color: ${({ isActive }) => (isActive ? '#cee3d9' : 'transparent')};
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: ${({ isActive }) => (isActive ? '#087443' : '#667085')};

  &:hover {
    background-color: #cee3d9;
    color: #087443;
  }
`

export const DataTablePaginationDots = styled.div`
  margin: 0 0.5rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`

export const DataTablePaginationButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
  margin: 0px 8px 0px 8px;
`
