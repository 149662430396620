import styled from 'styled-components/macro'

export function BarChart({
  data,
}: {
  data: {
    percentage: number
    color: string
  }[]
}) {
  return (
    <Wrapper>
      <Bar>
        {data.map((item, index) => {
          if (!item.percentage) return null
          return (
            <BarSplice
              key={index}
              percentage={item.percentage * 100}
              color={item.color}
            />
          )
        })}
      </Bar>
      <Row>
        <Text>0%</Text>
        <Text>100%</Text>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 258px;
  margin-bottom: 12px;
`

const Bar = styled.div`
  display: flex;
  height: 17px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

const BarSplice = styled.div<{
  percentage: number
  color: string
}>`
  flex: ${(props) => props.percentage / 100};
  height: 100%;
  background-color: ${(props) => props.color};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #475467;
`
