import Checkbox from 'components/Checkbox/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/UI/BaseDropdown/dropdown-base'
import React, { useEffect, useRef } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import styled from 'styled-components/macro'
import { formatInteger } from 'utils/formatting'

interface DataTableSelectAllDropdownProps {
  isAllRowsSelected: boolean
  totalSelected: number
  listCount: number
  pageCount: number
  selectAllText?: string
  onSelectAllInList: () => void
  onSelectAllInPage: () => void
  onSelectNone: () => void
}

export const DataTableSelectAllDropdown = ({
  isAllRowsSelected,
  totalSelected,
  listCount,
  pageCount,
  selectAllText,
  onSelectAllInList,
  onSelectAllInPage,
  onSelectNone,
}: DataTableSelectAllDropdownProps) => {
  const handleCheckboxChange = () => {
    if (totalSelected > 0) {
      onSelectNone()
    } else {
      onSelectAllInPage()
    }
  }

  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = !isAllRowsSelected && totalSelected > 0
    }
  }, [totalSelected, isAllRowsSelected])

  const handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation()
  }

  const items = [
    {
      label: `All in list (${formatInteger(listCount)})`,
      callback: onSelectAllInList,
    },
    {
      label: `All on page (${formatInteger(pageCount)})`,
      callback: onSelectAllInPage,
    },
    {
      label: 'None',
      callback: onSelectNone,
    },
  ]

  return (
    <div className="w-[200px]">
      <DropdownMenu>
        <div className="justify-start px-[12px]">
          <div className="flex">
            <div className="relative z-10 flex items-center">
              <Checkbox
                checked={isAllRowsSelected}
                onChange={handleCheckboxChange}
                ref={ref}
                onClick={handleCheckboxClick}
              />
            </div>
            <DropdownMenuTrigger asChild>
              <div className="w-full flex items-center justify-between gap-1 ml-4">
                <span className="inline-flex flex-col text-sm leading-none">
                  {totalSelected > 0 ? (
                    <>
                      <span className="font-light text-xs leading-none">{`(${formatInteger(totalSelected)})`}</span>
                      <span className="mt-1">Selected</span>
                    </>
                  ) : (
                    <SelectAllText>{selectAllText || 'All'}</SelectAllText>
                  )}
                </span>
                <BsChevronDown />
              </div>
            </DropdownMenuTrigger>
          </div>
        </div>
        <DropdownMenuContent>
          <DropdownMenuGroup>
            {items.map((item) => (
              <DropdownMenuItem onClick={item.callback} key={item.label}>
                {item.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const SelectAllText = styled.span`
  font-weight: 500;
  color: rgb(102, 112, 133);
`
