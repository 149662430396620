import { CellEventFormatterProps } from '../../utils'
import { useMemo } from 'react'
import { DefaultAddOrRemoveEvent } from './DefaultAddOrRemoveEvent'
import apiService from 'services/api'
import { Tag } from 'models/tags'

export const ContactAddOrRemoveEvent = ({ event }: CellEventFormatterProps) => {
  const actionType = event.action_type === 'ADD' ? 'added' : 'removed'
  if (event.field_name === 'contact_tags') {
    return (
      <ContactAddOrRemoveContactTagsEvent
        event={event}
        actionType={actionType}
      />
    )
  }
}

const ContactAddOrRemoveContactTagsEvent = ({
  event,
  actionType,
}: CellEventFormatterProps & { actionType: string }) => {
  const api = apiService()
  const { data: tags } = api.useGetTagsOptions(false, 'contacts')

  const tagsMap = useMemo(() => {
    if (!tags) return {}

    return tags.reduce((acc: Record<string, Tag>, curr) => {
      if (!curr.original) return acc
      acc[curr.original.id] = curr.original
      return acc
    }, {})
  }, [tags])

  const getTags = (tagsIds: number[]) => {
    if (!Object.keys(tagsMap).length) return ''
    return tagsIds.map((tagId) => tagsMap[tagId].tag).join(', ')
  }

  return (
    <DefaultAddOrRemoveEvent
      action={actionType}
      fieldName="contact labels"
      values={getTags(event.value)}
    />
  )
}
