import { restaurantsFilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
  QuickFilterGroup,
} from 'components/Filters/components/FilterModal'
import {
  QuickRangeFilter,
  QuickValueFilter,
} from 'components/Filters/fields/QuickFilter'
import {
  AvgRatingRangeFilter,
  BrandLTVRangeFilter,
  ChainSizeRangeFilter,
  DealCountRangeFilter,
  InstagramFollowersRangeFilter,
  LTVTotalRangeFilter,
  MedianHouseholdIncomeRangeFilter,
  PopDensityRangeFilter,
  PoundsPerYearRangeFilter,
  RangeFilter,
  RangeFilterWithMinMax,
  RevenueLTVRangeFilter,
  ReviewCountRangeFilter,
  TaboRangeFilter,
  TaroPlusTaboRangeFilter,
  TaroRangeFilter,
  UnemploymentRateRangeFilter,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { DELIVERY_PLATFORMS_OPTIONS } from 'models/delivery_platforms'
import { DMA_OPTIONS } from 'models/dma_options'
import { REPUTATION_PLATFORMS_OPTIONS } from 'models/reputation_platforms'
import { VelocityGroupOptions } from 'models/velocity_groups'
import { useEffect, useState } from 'react'
import apiService from 'services/api'
import { ALL_COUNTRIES } from 'utils/countries'
import { featureFlagService } from 'utils/featureFlagService'
import { US_CITIES } from 'utils/us_cities'
import { Cuisine50Options } from '../../../models/cuisine_50'
import { menu_ingredients } from '../../../models/menu_ingredients'
import { US_STATES } from '../../../utils/us_states'

import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

const IDENTIFIER: FilterIdentifier = 'DOORS_TABLE'

export function DoorsFilterset() {
  const featureFlags = featureFlagService()
  const api = apiService()
  const featureFlag = featureFlagService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)

  const { pendingValueFilters, setPendingValueFilters } =
    restaurantsFilterStore()

  const [usCities, setUsCities] = useState<{ value: string; label: string }[]>()

  useEffect(() => {
    const selectedStates = pendingValueFilters.state?.map((f) => f.value)
    if (!selectedStates?.length) {
      setUsCities(US_CITIES)
      return
    }

    const availableCities = US_CITIES.filter((c) =>
      selectedStates.some((s) => s === c.value.split('_')[1])
    )

    const selectedCities = pendingValueFilters?.city?.map((f) => f.value)

    if (selectedCities) {
      const citiesToRemove: string[] = []
      for (const city of selectedCities) {
        if (!availableCities.some((c) => c.value === city)) {
          citiesToRemove.push(city as string)
        }
      }
      if (citiesToRemove.length) {
        setPendingValueFilters(
          'city',
          pendingValueFilters.city.filter(
            (f) => !citiesToRemove.some((c) => c === f.value)
          )
        )
      }
    }

    setUsCities(availableCities)
  }, [pendingValueFilters])

  return (
    <FilterModal identifier={IDENTIFIER} store={restaurantsFilterStore}>
      <QuickFilterGroup>
        <QuickRangeFilter
          filterKey="one_year_total_value"
          title={FILTER_TITLES[IDENTIFIER].one_year_total_value}
          valueLabel="≥1"
          predefinedValue={{
            min: 1,
          }}
          filterStore={restaurantsFilterStore}
        />
        <QuickRangeFilter
          filterKey="chain_count"
          title={FILTER_TITLES[IDENTIFIER].chain_count}
          valueLabel="2+"
          predefinedValue={{
            min: 2,
          }}
          filterStore={restaurantsFilterStore}
        />
        <QuickValueFilter
          filterKey="sales_stage"
          title={FILTER_TITLES[IDENTIFIER].sales_stage}
          valueLabel="Empty"
          predefinedValue={[
            {
              value: '-1',
              label: 'Empty',
            },
          ]}
          filterStore={restaurantsFilterStore}
        />
        <QuickRangeFilter
          filterKey="contact_count"
          title={FILTER_TITLES[IDENTIFIER].contact_count}
          valueLabel="≤0"
          predefinedValue={{
            max: 0,
          }}
          filterStore={restaurantsFilterStore}
        />
      </QuickFilterGroup>
      <FilterGroup title="Lifetime Values">
        <LTVTotalRangeFilter store={restaurantsFilterStore} />
        <BrandLTVRangeFilter store={restaurantsFilterStore} />
        <RevenueLTVRangeFilter store={restaurantsFilterStore} />
      </FilterGroup>
      <FilterGroup title="Predictive Annual Values">
        <TaroPlusTaboRangeFilter store={restaurantsFilterStore} />
        <TaroRangeFilter store={restaurantsFilterStore} />
        <TaboRangeFilter store={restaurantsFilterStore} />
      </FilterGroup>
      <FilterGroup title="Menu">
        <SelectFilter
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].cuisine_50}
          filterKey="cuisine_50"
          options={Cuisine50Options}
        />
        <PoundsPerYearRangeFilter store={restaurantsFilterStore} />
        <SelectFilter
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].menu_ingredients}
          filterKey="menu_ingredients"
          options={menu_ingredients}
        />
        {featureFlag.shouldShowMenuSize && (
          <RangeFilter
            filterKey="menu_size"
            title={FILTER_TITLES[IDENTIFIER].menu_size}
            store={restaurantsFilterStore}
          />
        )}
        <RangeFilter
          filterKey="menu_matches"
          title={FILTER_TITLES[IDENTIFIER].menu_matches}
          store={restaurantsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Features">
        <ChainSizeRangeFilter store={restaurantsFilterStore} />
        <SelectFilter
          filterStore={restaurantsFilterStore}
          filterKey="delivery_platforms"
          title={FILTER_TITLES[IDENTIFIER].delivery_platforms}
          options={DELIVERY_PLATFORMS_OPTIONS}
        />
        <SelectFilter
          filterStore={restaurantsFilterStore}
          filterKey="expense_category"
          title={FILTER_TITLES[IDENTIFIER].expense_category}
          options={[
            { label: '$', value: '1' },
            { label: '$$', value: '2' },
            { label: '$$$', value: '3' },
            { label: '$$$$', value: '4' },
          ]}
        />
        {featureFlag.shouldShowReputationData && (
          <>
            <SelectFilter
              title={FILTER_TITLES[IDENTIFIER].reputation_data}
              filterKey="reputation_data"
              options={REPUTATION_PLATFORMS_OPTIONS}
              filterStore={restaurantsFilterStore}
            />
            <RangeFilterWithMinMax
              filterKey="michelin_stars"
              title={FILTER_TITLES[IDENTIFIER].michelin_stars}
              store={restaurantsFilterStore}
              min={0}
              max={3}
            />
          </>
        )}
        <AvgRatingRangeFilter store={restaurantsFilterStore} />
        <ReviewCountRangeFilter store={restaurantsFilterStore} />
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].velocity_group}
          filterKey="velocity_group"
          filterStore={restaurantsFilterStore}
          options={VelocityGroupOptions}
        />
        {featureFlag.enableInstagremData && (
          <InstagramFollowersRangeFilter store={restaurantsFilterStore} />
        )}
      </FilterGroup>
      <FilterGroup title="Location">
        {featureFlags.enableRestaurantsCountryFilter && (
          <SelectFilter
            title={FILTER_TITLES[IDENTIFIER].country}
            filterStore={restaurantsFilterStore}
            filterKey={'country'}
            options={ALL_COUNTRIES}
          />
        )}
        <SelectFilter
          filterKey="state"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].state}
          options={US_STATES}
        />
        <SelectFilter
          filterKey="dmanamecbsa"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].dmanamecbsa}
          options={DMA_OPTIONS}
        />
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].city}
          filterStore={restaurantsFilterStore}
          filterKey={'city'}
          options={usCities}
        />
        <ZipcodeFilter filterStore={restaurantsFilterStore} />
      </FilterGroup>
      <FilterGroup title="Account">
        <SelectFilter
          filterKey="sales_stage"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].sales_stage}
          options={stageOptions as any}
        />
        <SelectFilter
          filterKey="account_owner"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].account_owner}
          options={userOptions as any}
        />
        <SelectFilter
          filterKey="tags"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].tags}
          options={tagsOptions as any}
        />
        <SelectFilter
          options={distributorOptions as any}
          filterKey="distributors"
          filterStore={restaurantsFilterStore}
          title={FILTER_TITLES[IDENTIFIER].distributors}
        />
        <DealCountRangeFilter store={restaurantsFilterStore} />
        <RangeFilter
          store={restaurantsFilterStore}
          filterKey="contact_count"
          title={FILTER_TITLES[IDENTIFIER].contact_count}
        />
      </FilterGroup>
      <FilterGroup title="ZIP Code Demographics">
        <MedianHouseholdIncomeRangeFilter store={restaurantsFilterStore} />
        <PopDensityRangeFilter store={restaurantsFilterStore} />
        <UnemploymentRateRangeFilter store={restaurantsFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
