import { FaPlus } from 'react-icons/fa'
import React, { useState } from 'react'
import { NotesTable } from 'components/Tables/NotesTable/NotesTable'
import NotesModal from '../../Modals/NotesModal'
import { CollapsibleSection } from '../../UI/CollapsibleSection/CollapsibleSection'
import FbButton from '../../FbUI/FbButton'
import { formatInteger } from '../../../utils/formatting'
import { queryClient } from '../../../services/queryClient'

export default function NotesSection({
  pagination,
  chainId,
  contactCompanyId,
}: {
  chainId?: number | undefined
  contactCompanyId?: number | undefined
  pagination?: boolean
}) {
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(0)

  const TABLE_KEY = `notes-section-${contactCompanyId}`

  return (
    <>
      <CollapsibleSection
        forceClose={count < 1}
        summary={
          <div className={'flex justify-between items-center w-full'}>
            <div>
              Notes{' '}
              <span className={'font-thin'}>({formatInteger(count)})</span>
            </div>
            <FbButton onClick={() => setShowModal(true)}>
              <FaPlus size={14} /> Add Note
            </FbButton>
          </div>
        }
      >
        <NotesModal
          chainId={chainId}
          contactCompanyId={contactCompanyId}
          show={showModal}
          handleClose={() => {
            setShowModal(false)
            queryClient.refetchQueries({ queryKey: [TABLE_KEY] })
          }}
        />

        <NotesTable
          tableKey={TABLE_KEY}
          contactCompanyId={contactCompanyId}
          chainId={chainId}
          setTotalRowsCount={setCount}
          enablePagination={pagination}
        />
      </CollapsibleSection>
    </>
  )
}
