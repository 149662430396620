import s from 'styled-components'

export const ColumnLabel = s.span`
  color: var(--gray-700, #344054);
  font-weight: 500;
  line-height: 20px;
`

export const ColumnItemContainer = s.div<{ checked?: boolean }>`
  display: flex;
  padding: 8px 14px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  gap: 8px;
  margin: 10px 0px;
  cursor: pointer;
  &:hover{
    background-color: #efefef;
  }
  transition: 0.05s ease-out;
  &:last-child:{
    margin-bottom: 0px;
  }
  ${({ checked }) =>
    !checked &&
    `
    color: #D0D5DD;
  `}
`
