import { BsSortDownAlt, BsSortUpAlt } from 'react-icons/bs'
import styled from 'styled-components/macro'

export const DownArrow = styled(BsSortDownAlt)`
  height: 1rem;
  width: 1rem;
  margin-left: 10px;
  flex-shrink: 0;
`

export const UpArrow = styled(BsSortUpAlt)`
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  margin-left: 10px;
`
