import { useMemo } from 'react'
import * as S from './NotificationItem.styles'
import Markdown from 'react-markdown'
import moment from 'moment'
import apiService from 'services/api'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { User } from 'models/user'
import notificationAvatar from 'assets/notification-avatar.png'

export type NotificationItemProps = {
  isUnread?: boolean
  id: number
  message: string
  time: Date
  fromUser?: User
  notificationLink?: string | null
  onClick?: () => void
}

const NotificationAvatar = ({ fromUser }: { fromUser?: User }) => {
  if (fromUser) {
    const userInitials = useMemo(() => {
      const { first_name: firstName, last_name: lastName } = fromUser
      return `${firstName[0]}${lastName[0]}`.toLocaleUpperCase()
    }, [fromUser])

    return <S.NotificationAvatar>{userInitials}</S.NotificationAvatar>
  }
  return (
    <S.NotificationAvatar>
      <img src={notificationAvatar} alt="notification-avatar" />
    </S.NotificationAvatar>
  )
}

export const NotificationItem = ({
  isUnread = false,
  id,
  message,
  time,
  fromUser,
  notificationLink,
  onClick,
}: NotificationItemProps) => {
  const userName = useMemo(() => {
    if (fromUser) {
      const { first_name: firstName, last_name: lastName } = fromUser
      return `${firstName} ${lastName}`
    }
    return ''
  }, [fromUser])

  const api = apiService()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleClick = () => {
    if (isUnread) {
      api.markNotificationAsRead([id]).then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] })
        queryClient.invalidateQueries({ queryKey: ['unread-notifications'] })
      })
    }
    if (notificationLink) {
      navigate(notificationLink)
    }
    if (onClick) {
      onClick()
    }
  }

  return (
    <S.NotificationItem
      isUnread={isUnread}
      onClick={handleClick}
      hasLink={!!notificationLink}
    >
      <S.NotificationLeft>
        {isUnread && <S.UnreadDot />}
        <NotificationAvatar fromUser={fromUser} />
      </S.NotificationLeft>
      <S.NotificationRight>
        <S.NotificationText>
          {/* Dont add the p around markdown */}
          <Markdown
            components={{
              p: ({ children }) => <>{children}</>,
            }}
          >
            {`${userName} ${message}`}
          </Markdown>
        </S.NotificationText>
        <S.NotificationTime>{moment(time).fromNow()}</S.NotificationTime>
      </S.NotificationRight>
    </S.NotificationItem>
  )
}
