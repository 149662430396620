import { useEffect, useMemo, useState } from 'react'
import { SelectFilterOption } from 'components/Filters/fields/SelectFilter'
import { FilterStore } from 'components/Filters/FilterStore'
import apiService from 'services/api'

interface CategoryOptionsHookParams {
  filterStore: FilterStore
}

export function useCategorySubcategoryOptions({
  filterStore,
}: CategoryOptionsHookParams) {
  const api = apiService()
  const { pendingValueFilters, setPendingValueFilters } = filterStore()

  const { data: _categoriesOptions } = api.useGetCompanyCategoriesAndSub()
  const [subCategoriesOptions, setSubcategoriesOptions] = useState<
    SelectFilterOption[]
  >([])

  const categoriesOptions = useMemo(() => {
    if (!_categoriesOptions) {
      return []
    }

    return _categoriesOptions.map((o) => {
      return {
        label: o.name,
        value: o.id.toString(),
      }
    })
  }, [_categoriesOptions])

  useEffect(() => {
    const subcategories: SelectFilterOption[] = []
    _categoriesOptions?.forEach((c) => {
      const categoriesSelected = pendingValueFilters?.['category']?.map(
        (s) => s.value
      )
      if (
        categoriesSelected?.length &&
        !categoriesSelected.includes(c.id.toString())
      ) {
        return
      }
      c.sub_categories.forEach((s) => {
        subcategories.push({
          label: s.name,
          value: s.id.toString(),
        })
      })
    })
    setSubcategoriesOptions(subcategories)

    if (pendingValueFilters['sub_category']) {
      const allowedSubcategories = subcategories.map((s) => s.value)
      const newSubs = pendingValueFilters?.['sub_category'].filter((s) =>
        allowedSubcategories.includes(s.value)
      )
      if (newSubs.length !== pendingValueFilters['sub_category'].length) {
        setPendingValueFilters('sub_category', newSubs)
      }
    }
  }, [pendingValueFilters, _categoriesOptions])

  return { categoriesOptions, subCategoriesOptions }
}
