import { Form } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { IoMdCloseCircle } from 'react-icons/io'
import styled from 'styled-components/macro'

interface IRadioInput<T extends FieldValues> {
  options: { label: string; value: any }[]

  formControl: Control<T, any>
  fieldName: Path<T>
  horizontal?: boolean
}

export function RadioInput<T extends FieldValues>(props: IRadioInput<T>) {
  const { options, fieldName, formControl } = props
  const {
    field: { ref, onChange, value, onBlur },
    fieldState: { error },
  } = useController({ name: fieldName, control: formControl })

  return (
    <>
      <SelectRadioDiv horizontal={props.horizontal}>
        {options.map((opt, index) => {
          return (
            <RadioData
              onChange={() => onChange(opt.value)}
              onBlur={onBlur}
              ref={ref}
              id={fieldName + index}
              key={fieldName + index}
              checked={value === opt.value}
              className={error?.message && 'text-danger'}
              label={opt.label}
              type="radio"
            />
          )
        })}
      </SelectRadioDiv>

      {error?.message && (
        <ErrorMessage>
          <ErrorIcon /> {error?.message}
        </ErrorMessage>
      )}
    </>
  )
}

const SelectRadioDiv = styled.div<{
  horizontal?: boolean
}>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  gap: ${({ horizontal }) => (horizontal ? '1rem' : 'unset')};
  margin-bottom: 1rem;
`

const RadioData = styled(Form.Check)`
  font-size: 14px;

  &:last-child {
    margin-right: 0px;
  }
`
export const ErrorMessage = styled.span`
  color: var(--bs-gray-600);
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 2px;
`
export const ErrorIcon = styled(IoMdCloseCircle)`
  color: #c73128;
  font-size: 12px;
  margin-right: 5px;
`
