import { CellEventFormatterProps } from '../../utils'
import React from 'react'
import { Editor } from '../../../../Editor/Editor'
import styled from 'styled-components/macro'

export const NoteUpdateEvent = ({ event }: CellEventFormatterProps) => {
  return (
    <div className={'flex justify-center flex-col pt-3'}>
      <span>
        changed <GreenBold>Note</GreenBold> to:{' '}
      </span>
      <Editor
        value={event.value}
        readOnly={true}
        disableToolbar={true}
        noBorder
      />
    </div>
  )
}

export const GreenBold = styled.b`
  color: #087443;
`
