import React, { ReactNode } from 'react'

import { Modal } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import s from 'styled-components'
import FbButton from '../../FbUI/FbButton'

interface IModalForm {
  show: boolean
  title: string
  subtitle?: string | ReactNode
  onHide: () => void
  loading: boolean
  handleSubmit: () => void
  children?: ReactNode
  allowSubmit: boolean
}

const ModalForm = ({
  show,
  title,
  onHide,
  loading,
  handleSubmit,
  children,
  allowSubmit,
  subtitle,
}: IModalForm) => {
  return (
    <ModalCont size="lg" show={show} backdrop="static" onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <ModalHeader>
          <Modal.Title>{title}</Modal.Title>
          {!loading && (
            <CloseIcon
              style={{ fontSize: 24 }}
              className="text-muted"
              onClick={onHide}
            />
          )}
        </ModalHeader>

        {subtitle && (
          <ModalSubtitleCont>
            <ModalSubtitle>{subtitle}</ModalSubtitle>
          </ModalSubtitleCont>
        )}
        <Modal.Body>{children}</Modal.Body>

        <Modal.Footer>
          <FbButton disabled={loading} onClick={onHide} variant="secondary">
            Cancel
          </FbButton>

          <FbButton
            loading={loading}
            disabled={!allowSubmit}
            onClick={handleSubmit}
          >
            Submit
          </FbButton>
        </Modal.Footer>
      </form>
    </ModalCont>
  )
}

const ModalCont = s(Modal)`

`

const ModalHeader = s.div`
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

`

const ModalSubtitleCont = s.div`
 padding: 0 16px;
`

const ModalSubtitle = s.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;
  font-feature-settings: 'salt' on;

  /* New-Gray / 700 */

  color: #374151;
`

const CloseIcon = s(MdClose)`
  color: #aaa;
  font-size: 18px;
  margin: auto 0;
  cursor: pointer;
  margin-top: -4px;
  margin-right: -4px;
  transition: all 0.14s ease-in-out;
  &:hover {
    color: #444 !important;
  }
  &:focus {
    color: #000 !important;
  }
  &:active {
    color: #000 !important;
  }
`

export default ModalForm
