import { ColumnDef } from '@tanstack/react-table'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import apiService from 'services/api'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'
import { Switch } from '../../../components/UI/Switch/Switch'
import { MISC_SETTINGS } from '../../../models/misc_settings'
import { MiscSetting } from '../../../models/preferences'
import { queryClient } from '../../../services/queryClient'
import { notifySuccess } from '../../../services/toast'
import * as S from './styles'

const description = ``

export function MiscSettingsTable() {
  const api = apiService()
  const mutation = api.useChangeMiscSettings()
  const { data, isLoading } = api.useGetPreferences()

  const [isApplyingTempClosed, setIsApplyingTempClosed] = useState(false)

  async function handleChangeTempClosed(val: boolean) {
    setIsApplyingTempClosed(true)
    await mutation.mutateAsync({
      key: 'show_temp_closed',
      value: val ? '1' : '0',
    })
    setIsApplyingTempClosed(false)
    notifySuccess('Setting Changed Successfully')
    queryClient.clear()
  }

  const columns = useMemo<ColumnDef<MiscSetting, any>[]>(
    () => [
      {
        accessorKey: 'key',
        header: 'Setting',
        cell: (info) =>
          MISC_SETTINGS[info.getValue() as keyof typeof MISC_SETTINGS].title,
        size: 150,
      },
      {
        id: 'description',
        accessorKey: 'key',
        header: 'Description',
        cell: (info) =>
          MISC_SETTINGS[info.getValue() as keyof typeof MISC_SETTINGS]
            .description,
        size: 150,
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 100,
        meta: { rightAlign: true },
        cell: ({ row }) => {
          if (row.original.key === 'show_temp_closed') {
            if (isApplyingTempClosed) {
              return <Spinner animation="border" />
            }
            return (
              <div className={'justify-end flex'}>
                <Switch
                  onChange={(e) => handleChangeTempClosed(e.target.checked)}
                  defaultChecked={!!parseInt(row.original.value)}
                />
              </div>
            )
          }
          return null
        },
      },
    ],
    [data, isApplyingTempClosed]
  )

  // hotfix: exclude items which do not have a description in MISC_SETTINGS
  const filteredData = useMemo(() => {
    return (data?.misc_settings || []).filter(
      (setting) => MISC_SETTINGS[setting.key as keyof typeof MISC_SETTINGS]
    )
  }, [data])

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <S.SettingsTableHeaderContainer>
        <S.TableDescriptionText>
          <OverflownTextTooltip
            tooltipText={description}
            maxLines={4}
            placement="bottom"
          >
            {description}
          </OverflownTextTooltip>
        </S.TableDescriptionText>
      </S.SettingsTableHeaderContainer>
      <S.SettingsTableContainer
        style={{
          height: 'calc(100vh - 310px)',
        }}
      >
        <ClientDataTable
          stickyLastColumn={true}
          data={filteredData}
          columns={columns}
        />
      </S.SettingsTableContainer>
    </>
  )
}
