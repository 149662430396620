import * as S from './styles'

export function FbLinkPill({
  url,
  children,
}: {
  url: string
  children: React.ReactNode
}) {
  return (
    <S.LinkPill href={url} target="_blank">
      <S.LinkIcon />
      {children}
    </S.LinkPill>
  )
}
