import styled from 'styled-components/macro'

export const CardTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  /* identical to box height, or 173% */

  /* Gray/900 */

  color: #101828;
`
export const ColoredDot = styled.div<{
  color: string
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
`

export const CardSubtitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #475467;
`

export const CardSubtitlePercent = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`
