import { BsCheck } from 'react-icons/bs'
import React from 'react'

export type ColorpickerColor = { background: string; text: string }

export function ColorPicker(props: {
  label: string
  colors: ColorpickerColor[]
  value?: ColorpickerColor
  onChange: (color: ColorpickerColor) => void
  noMargin?: boolean
  optional?: boolean
}) {
  return (
    <div className={`flex flex-col w-full ${props.noMargin ? 'mt-0' : 'mt-3'}`}>
      <div className={props.noMargin ? 'mt-0' : 'mb-1'}>
        {props.label && (
          <label className="text-base font-medium text-gray-800 select-none">
            {props.label}
            {props.optional && (
              <span className="italic text-sm font-normal"> - optional</span>
            )}
          </label>
        )}
      </div>
      <div className={'flex flex-row items-center justify-between w-full'}>
        {props.colors.map((color) => (
          <div
            className={
              'h-8 w-8  rounded-full flex items-center justify-center cursor-pointer'
            }
            style={{ backgroundColor: color.background }}
            onClick={() => props.onChange(color)}
            key={'color-picker-item-' + color.background}
          >
            {props?.value?.background === color.background ? (
              <BsCheck color={color.text} />
            ) : (
              <span style={{ color: color.text }}>a</span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
