import { DealsTable } from '../../../components/Tables/DealsTable/DealsTable'
import styled from 'styled-components/macro'
import React from 'react'
import { Page } from '../../../components/FbUI/Page'
import { StatsCard, StatsContainer } from '../../../styled/Stats'
import { formatInteger } from '../../../utils/formatting'
import { DEAL_TABLE_KEY } from 'constants/tableQueryKeys'

export function DealsPage() {
  const [totalDeals, setTotalDeals] = React.useState<number>()

  const placesStats = [
    {
      name: 'Total Deals',
      value: formatInteger(totalDeals),
      isLoading: !totalDeals,
    },
  ]

  return (
    <Page>
      <Header>
        <TitleRow>
          <Title>Deals</Title>
        </TitleRow>
        <StatsContainer>
          {placesStats.map((stat) => (
            <StatsCard key={stat.name}>
              <h1> {stat.name} </h1>
              {!stat.isLoading ? (
                <span> {stat.value} </span>
              ) : (
                <span style={{ fontSize: 12, fontWeight: 400 }}>
                  Calculating...
                </span>
              )}
            </StatsCard>
          ))}
        </StatsContainer>
      </Header>
      <DealsTable
        tableKey={DEAL_TABLE_KEY}
        defaultColumnVisibility={{
          predicted_annual_revenue: false,
          predicted_monthly_revenue: false,
          monthly_revenue_override: false,
          company_size: false,
        }}
        showCompanyColumn
        setTotalRowsCount={setTotalDeals}
        containerStyle={{ height: 'calc(100vh - 236px)' }}
      />
    </Page>
  )
}

const Header = styled.div`
  padding: 16px 25px 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`
