import styled from 'styled-components'

export const ErrorMessage = styled.div``

export const ErrorLabel = styled.div`
  display: flex;
  gap: 4px;
  color: #da0000;
  padding-left: 2px;
  padding-top: 4px;
`
