import { TextInput } from '../../../components/FormUtils/TextInput'
import { useAuth } from 'context/authentication/useAuth'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import to from 'await-to-js'
import { handleFormError } from '../../../utils/handleFormError'
import { ErrorText } from '../../../components/FormUtils/ErrorText'
import styled from 'styled-components/macro'
import FbButton from '../../../components/FbUI/FbButton'
import { FbLink } from 'components/FbUI/FbLink'

const loginFormShape = z.object({
  email: z.string().email(),
  password: z.string(),
  non_field_errors: z.string().optional(),
})

function Login() {
  const { login, resendVerify } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      non_field_errors: '',
    },
    resolver: zodResolver(loginFormShape),
  })

  const onSubmit = handleSubmit(
    async (values) => {
      const [err] = await to(login(values.email, values.password))
      handleFormError(err, setError)
    },
    (errors) => console.warn(errors)
  )

  const resendVerification = async () => {
    const [err] = await to(resendVerify(getValues()?.email))
    handleFormError(err, setError)
  }

  return (
    <Form onSubmit={onSubmit}>
      <Title>Log in to your account</Title>
      <TextInput control={control} name="email" label="Email" />
      <TextInput
        type="password"
        control={control}
        name="password"
        label="Password"
      />
      <ButtonContainer>
        <FbButton type="submit" loading={isSubmitting}>
          Log In
        </FbButton>
        <FbLink to="/reset-password">Forgot Password?</FbLink>
      </ButtonContainer>
      <ErrorText
        resendVerification={resendVerification}
        control={control}
        name={'non_field_errors'}
      />
    </Form>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

const Form = styled.form`
  width: 400px;
  flexshrink: 0;

  @media (max-width: 400px) {
    width: 100%;
    margin: 0 16px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
`

export default Login
