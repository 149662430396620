import { useQuery } from '@tanstack/react-query'
import to from 'await-to-js'
import { FilterStore, useFilterParams } from 'components/Filters/FilterStore'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { useEffect, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import apiService from 'services/api'
import styled from 'styled-components'
import { ClientDataTable } from '../../ClientDataTable/ClientDataTable'
import { FilterChipSearch } from '../../Filters/components/FilterChips/FilterChipSearch'
import { RouterLink } from '../../UI/Anchor'
import { Modal } from '../../UI/Modal/Modal'

type MenuModalProps = {
  show: boolean
  handleClose: () => void
  chainId: number
  chainName: string
  menuLength?: number
  filterStore: FilterStore
}

export default function MenuModal({
  show,
  handleClose,
  chainId,
  chainName,
  filterStore,
}: MenuModalProps) {
  const api = apiService()
  const { search } = filterStore()
  const { preferences } = usePreferences()
  const filtersAsParams = useFilterParams(filterStore)

  const TABLE_QUERY_KEY = ['chain-menu', chainId, []]
  const { data, isFetching, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const [err, res] = await to(
        api.getChainMenuList(
          chainId,
          {
            ...filtersAsParams,
          },
          signal
        )
      )

      if (err) throw err

      return res
    },
    enabled: show,
  })

  useEffect(() => {
    if (show) {
      refetch()
    }
  }, [show, refetch])

  const menuElasticSearch = useMemo(() => {
    return search['menu']
  }, [search])

  const columns = [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Item Name',
      size: 150,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: 'Description',
      size: 200,
    },
    {
      id: 'price',
      accessorKey: 'price',
      header: 'Price',
      size: 100,
    },
  ]

  return (
    <Modal
      size={'lg'}
      title={
        <span>
          Menu:{' '}
          <RouterLink
            to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${chainId}`}
          >
            {chainName}
          </RouterLink>
        </span>
      }
      open={show}
      onOpenChange={(open) => !open && handleClose?.()}
      cancelButtonText={'Close'}
    >
      <div className="d-flex mb-1">
        {menuElasticSearch && (
          <FilterChipSearch
            search={menuElasticSearch.value}
            searchLabel={menuElasticSearch.label}
          />
        )}
      </div>
      <TableContainer className="d-flex position-relative">
        <ClientDataTable data={data ?? []} columns={columns} />
        <LoadingOverlay show={isFetching}>
          <Spinner />
        </LoadingOverlay>
      </TableContainer>
    </Modal>
  )
}

const TableContainer = styled.div`
  max-height: 600px;
`

const LoadingOverlay = styled.div<{
  show: boolean
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 30;
`
