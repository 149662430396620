export const CARNEGIE_CLASSIFICATION_SIZE_OPTIONS_OLD = [
  {
    value: -2,
    label: 'Not applicable',
  },
  {
    value: 0,
    label: '(Not classified)',
  },
  {
    value: 1,
    label: 'Two-year, very small',
  },
  {
    value: 2,
    label: 'Two-year, small',
  },
  {
    value: 3,
    label: 'Two-year, medium',
  },
  {
    value: 4,
    label: 'Two-year, large',
  },
  {
    value: 5,
    label: 'Two-year, very large',
  },
  {
    value: 6,
    label: 'Four-year, very small, primarily non residential',
  },
  {
    value: 7,
    label: 'Four-year, very small, primarily residential',
  },
  {
    value: 8,
    label: 'Four-year, very small, highly residential',
  },
  {
    value: 9,
    label: 'Four-year, small, primarily non residential',
  },
  {
    value: 10,
    label: 'Four-year, small, primarily residential',
  },
  {
    value: 11,
    label: 'Four-year, small, highly residential',
  },
  {
    value: 12,
    label: 'Four-year, medium, primarily non residential',
  },
  {
    value: 13,
    label: 'Four-year, medium, primarily residential',
  },
  {
    value: 14,
    label: 'Four-year, medium, highly residential',
  },
  {
    value: 15,
    label: 'Four-year, large, primarily non residential',
  },
  {
    value: 16,
    label: 'Four-year, large, primarily residential',
  },
  {
    value: 17,
    label: 'Four-year, large, highly residential',
  },
  {
    value: 18,
    label: 'Exclusively graduate/professional',
  },
]

export const CARNEGIE_CLASSIFICATION_SIZE_OPTIONS = [
  {
    value: -1,
    label: 'Not available',
  },
  {
    value: 'Very Small',
    label: 'Very Small',
  },
  {
    value: 'Small',
    label: 'Small',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'Large',
    label: 'Large',
  },
  {
    value: 'Very Large',
    label: 'Very Large',
  },
]

export const CARNEGIE_CLASSIFICATION_YEAR_OPTIONS = [
  {
    value: -1,
    label: 'Not available',
  },
  {
    value: 'Two-year',
    label: 'Two-year',
  },
  {
    value: 'Four-year',
    label: 'Four-year',
  },
]

export const CARNEGIE_CLASSIFICATION_STUDENT_COMPOSITION_OPTIONS = [
  {
    value: -1,
    label: 'Not available',
  },
  {
    value: 'Exclusively Graduate/Professional',
    label: 'Exclusively Graduate/Professional',
  },
  {
    value: 'Highly Residential',
    label: 'Highly Residential',
  },
  {
    value: 'Primarily Non-Residential',
    label: 'Primarily Non-Residential',
  },
  {
    value: 'Primarily Residential',
    label: 'Primarily Residential',
  },
]
