import React, { useMemo, useState } from 'react'
import apiService from '../../services/api'
import dialogConfirm from '../../components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import FbButton from '../../components/FbUI/FbButton'
import { ActionButtons } from '../../components/Buttons/ActionButtons'
import { OverflownText } from '../../components/OverflownText'
import { Campaign } from '../../models/campaign'
import { CreateEditCampaignModal } from './CreateEditCampaignModal'
import { CampaignsCell } from '../../components/FbUI/StagePill'
import { ClientDataTable } from '../../components/ClientDataTable/ClientDataTable'

export function CampaignsTable(props: {
  onOpenCampaign?: (campaignId: Campaign) => void
}) {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()
  const { data: campaignData, isFetching } = api.useGetCampaigns()
  const deleteCampaignMutation = api.useDeleteCampaign()

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    setSelectedCampaign(undefined)
  }

  async function handleRowDelete(index: number) {
    const id = campaignData?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this campaign?',
        'Delete Campaign'
      )
    ) {
      await deleteCampaignMutation.mutateAsync(id)
    }
  }

  function handleRowEdit(campaign: Campaign) {
    setShowModal(true)
    setSelectedCampaign(campaign)
  }

  const columns = useMemo<ColumnDef<Campaign, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Campaign Name',
        size: 150,
        cell: (info) => {
          return <CampaignsCell campaigns={[info.row.original]} />
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
      },
      {
        accessorKey: 'type.name',
        header: 'Type',
        size: 150,
      },
      {
        accessorKey: 'contact_company_count',
        header: 'Opps Assigned',
        size: 100,
      },
      {
        accessorKey: 'deal_count',
        header: 'Deals Count',
        size: 100,
      },
      {
        accessorKey: 'contact_count',
        header: 'Contacts Count',
        size: 100,
      },
      {
        accessorKey: 'product.name',
        header: 'Product',
        size: 300,
        cell: (info) => {
          const value = info.getValue()

          return value ? (
            <OverflownText maxLines={2}>{value}</OverflownText>
          ) : (
            'N/A'
          )
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 130,
        meta: { rightAlign: true },
        cell: ({ row }) => (
          <ActionButtons
            onDelete={() => handleRowDelete(row.index)}
            onEdit={() => handleRowEdit(row.original)}
            onSee={
              props.onOpenCampaign
                ? () => props?.onOpenCampaign?.(row.original)
                : undefined
            }
          />
        ),
      },
    ],
    [campaignData]
  )
  return (
    <div className={'flex-1 h-100'}>
      <div className={'flex items-center justify-between pt-3 px-4 mb-4'}>
        <h1 className={'text-2xl font-semibold'}>Campaigns Overview</h1>
        <FbButton style={{ width: 200 }} onClick={() => openModal()}>
          Create Campaign
        </FbButton>
      </div>
      <ClientDataTable
        loading={isFetching}
        height={'calc(100% - 50px)'}
        stickyLastColumn={true}
        data={campaignData ?? []}
        columns={columns}
      />
      <CreateEditCampaignModal
        isOpen={showModal}
        onClose={hideModal}
        editingCampaign={selectedCampaign}
      />
    </div>
  )
}
