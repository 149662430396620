import styled from 'styled-components/macro'
import { BsStarFill } from 'react-icons/bs'

export const Ratings = styled.div`
  display: flex;

  gap: 30px;
`

export const RatingsBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const RatingsBar = styled.div`
  background: #f1f3f4;
  border-radius: 39px;
  width: 174px;
  display: flex;
  height: 12px;
`

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ValueBig = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #101828;
`
export const RatingsBarFill = styled.div<{
  width: number
}>`
  background: #fcbc05;
  border-radius: 37px;
  width: ${(props) => props.width}%;
`

export const RatingsBarText = styled.span`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
`

export const StarIcon = styled(BsStarFill)`
  width: 16px;
  height: 16px;
  color: #fcbc05;
`

export const RatingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const RatingsValueRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
