import { useQueryClient } from '@tanstack/react-query'
import to from 'await-to-js'
import CreatableSelect from 'components/FormUtils/CreatableSelect'
import { Control, FieldValues, Path, UseFormSetError } from 'react-hook-form'
import apiService from 'services/api'
import { handleFormError } from 'utils/handleFormError'
import { toast } from 'react-toastify'

export function CreatableTagSelector<T extends FieldValues>({
  name,
  control,
  options,
  setError,
  label,
  placeholder,
  tagParent = 'products',
  className,
  optional,
}: {
  name: Path<T>

  control: Control<T, any>

  options: any
  setError: UseFormSetError<T>
  label?: string
  placeholder?: string
  tagParent?: 'products' | 'contacts'
  className?: string
  optional?: boolean
}) {
  const api = apiService()
  const queryClient = useQueryClient()

  const onCreateOption = async (value: string) => {
    if (value.length > 50) {
      toast.error('Tag length limited to 50 characters.')
      return null
    }
    const [err, res] = await to(
      api.postTag(
        {
          tag: value,
        },
        tagParent
      )
    )

    if (err) handleFormError(err, setError)
    else {
      // Invalidate tags query
      queryClient.invalidateQueries({ queryKey: ['tags', tagParent] })
      return {
        label: res?.tag,
        value: res?.id,
      }
    }

    return null
  }

  return (
    <CreatableSelect
      name={name}
      isMulti
      control={control}
      type="text"
      label={label}
      placeholder={placeholder}
      options={options}
      onCreateOption={onCreateOption}
      className={className}
      optional={optional}
    />
  )
}
