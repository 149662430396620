/**
 * Determines if the provided filter and sort parameters contain any account-specific filters.
 *
 * @param {Record<string, any>} filters - A dictionary of filter parameters.
 * @returns {boolean} True if any account-specific filter keys are present in the parameters; otherwise, false.
 */
export const paramsContainAccountFilters = (
  filters: Record<string, any> | undefined
): boolean => {
  if (!filters) return false

  const account_filter_keys = new Set([
    'account_owner',
    'contact_count_greater',
    'contact_count_less',
    'deal_count_greater',
    'deal_count_less',
    'distributors',
    'sales_stage',
    'tags',
  ])

  return Object.keys(filters).some((key) => account_filter_keys.has(key))
}
