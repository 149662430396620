import { NavMenu } from '../NavMenu'
import * as S from './styles'

export function UnauthenticatedNav() {
  return (
    <S.Wrapper>
      <S.TopBarTitle>First Bite</S.TopBarTitle>
      <NavMenu
        items={[
          {
            label: 'Login',
            to: '/login',
          },
          {
            label: 'Get Started',
            to: '/signup',
          },
        ]}
      />
    </S.Wrapper>
  )
}
