import { ColumnDef } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { ActivityV2 } from 'models/activityV2'
import CellEventFormatterV2 from './CellEvent/CellEventFormatterV2'
import { MdPersonOutline } from 'react-icons/md'
import { formatInteger } from 'utils/formatting'
import { contentTypeMap } from './utils'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { getCompanyUrl } from 'utils/getCompanyUrl'
import { Fragment } from 'react'

export const useActivityTableColumnsV2 = (): ColumnDef<ActivityV2, any>[] => {
  const { preferences } = usePreferences()
  return [
    {
      id: 'user',
      accessorKey: 'user',
      header: 'Team Member',
      cell: ({ row }) => {
        const user = row.original.user
        return (
          <div className="d-flex align-items-center gap-1">
            <MdPersonOutline size={20} />
            <div>
              {user.first_name} {user.last_name}
            </div>
          </div>
        )
      },
    },
    {
      id: 'event',
      accessorKey: 'changes',
      header: 'Event',
      size: 300,
      cell: ({ row }) => {
        return CellEventFormatterV2({ event: row.original })
      },
    },
    {
      id: 'bulk_size',
      accessorKey: 'bulk_size',
      header: 'Bulk Size',
      size: 100,
      cell: ({ getValue }) => {
        const value = getValue()
        return formatInteger(value)
      },
    },
    {
      id: 'content_type',
      accessorKey: 'content_type',
      header: 'Type',
      cell: ({ row }) => {
        return contentTypeMap[row.original.content_type.model]
      },
    },
    {
      id: 'company',
      header: 'Company',
      cell: ({ row }) => {
        if (row.original.bulk_size === 1) {
          const company = row.original.changes[0]?.contact_company
          if (!company) return null
          return (
            <FbLink
              to={`${getCompanyUrl(company)}`}
              target="_blank"
              rel="noreferrer"
            >
              {company.name}
            </FbLink>
          )
        }
        const contact_companies = row.original.changes.map(
          (change) => change.contact_company
        )
        const addElipsis =
          row.original.bulk_size > contact_companies.length &&
          contact_companies.length === 3
        return (
          <>
            {contact_companies.map((company, index) => (
              <Fragment
                key={'contact-companies-link-activity-table' + company?.id}
              >
                <FbLink
                  to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${company!.chain}`}
                >
                  {company!.name}
                </FbLink>
                {index < contact_companies.length - 1 && ', '}
              </Fragment>
            ))}
            {addElipsis && '...'}
          </>
        )
      },
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      header: 'Date',
      cell: ({ row }) => {
        const date = new Date(row.original.created_at)
        // Date needs to be formatted like this: May 16, 2023,  4:14 PM
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      },
    },
  ]
}
