import React, { forwardRef } from 'react'
import './checkbox.styles.css'

const Checkbox = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    boxSize?: 'sm' | 'md' | 'lg'
    wrapperClassName?: string
  }
>(
  (
    props: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & {
      boxSize?: 'sm' | 'md' | 'lg'
      wrapperClassName?: string
    },
    ref
  ) => {
    const { wrapperClassName, boxSize, ...rest } = props
    return (
      <label className={`custom-checkbox cursor-pointer ${wrapperClassName}`}>
        <input type="checkbox" {...rest} ref={ref} />
        <span className={`checkmark checkmark-${boxSize ?? 'md'} `}></span>
      </label>
    )
  }
)
Checkbox.displayName = 'Checkbox'

export default Checkbox
