import FbButton from 'components/FbUI/FbButton'
import styled from 'styled-components'
import { CreateCompanyModal } from './CreateCompanyModal'
import { FaPlus } from 'react-icons/fa'
import { useMemo, useState } from 'react'
import apiService from '../../../services/api'
import { CompanyTypeSlug } from '../../../models/companies'

export default function CreateCompanyButton(props: {
  companyTypeSlug: CompanyTypeSlug
}) {
  const [show, setShow] = useState(false)

  const api = apiService()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()

  const cat = useMemo(() => {
    let cat: { category_id: number; sub_category_id: number } | null = null
    outer: for (const c of categories ?? []) {
      for (const s of c.sub_categories) {
        if (s.slug === props.companyTypeSlug) {
          cat = {
            category_id: c.id,
            sub_category_id: s.id,
          }
          break outer
        }
      }
    }
    return cat
  }, [categories, props.companyTypeSlug])

  if (!cat) {
    return null
  }

  return (
    <>
      <IconMiniButton variant="secondary" onClick={() => setShow(true)}>
        <FaPlus size={14} />
      </IconMiniButton>
      <CreateCompanyModal
        show={show}
        setShow={setShow}
        defaultValues={{
          category: cat.category_id,
          sub_category: cat.sub_category_id,
        }}
      />
    </>
  )
}

const IconMiniButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
