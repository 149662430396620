import { GreenBold } from 'components/Tables/ActivityTableV2/CellEvent/CellEventFormatter'
import { CellEventFormatterProps, contentTypeMap } from '../../utils'

export const DefaultCreateEvent = ({ event }: CellEventFormatterProps) => {
  const contentTypeName = contentTypeMap[event.content_type.model]
  return (
    <>
      created new <GreenBold>{contentTypeName}</GreenBold>
    </>
  )
}
