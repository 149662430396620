import UploadStep from './UploadStep'
import { useState } from 'react'
import { ColumnsStep } from './ColumnsStep'
import { PreviewSuccessfulStep } from './PreviewSuccessfulStep'
import { PreviewFailedStep } from './PreviewFailedStep'
import { MatchCompanyStep } from './MatchCompanyStep'
import { ImportContactProvider } from './ImportContactContext'
import { AddTagsStep } from './AddTagsStep'
import { Dialog, DialogContent } from 'components/UI/Modal/ModalPrimitive'

export default function ImportContactModal({
  show,
  handleClose,
  onContactsImported,
}: {
  show: boolean
  handleClose: () => void
  onContactsImported?: () => void
}) {
  const [step, setStep] = useState(0)

  return (
    <Dialog open={show} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent id={'fb-modal-content'} size="lg">
        <ImportContactProvider
          handleClose={handleClose}
          onStepChange={setStep}
          onContactsImported={onContactsImported}
        >
          <div className={step === 0 ? '' : 'd-none'}>
            <UploadStep />
          </div>
          {step === 1 && <ColumnsStep />}
          {step === 2 && <MatchCompanyStep />}
          {step === 3 && <PreviewSuccessfulStep />}
          {step === 4 && <PreviewFailedStep />}
          {step === 5 && <AddTagsStep />}
        </ImportContactProvider>
      </DialogContent>
    </Dialog>
  )
}
