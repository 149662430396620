import { CompanyFormShape, CompanyModal } from './CompanyModal'
import { toast } from 'react-toastify'
import { IDoorProxy } from '../../../models/contact_companies'
import apiService from '../../../services/api'

export function EditDoorProxyModal(props: {
  doorProxy: IDoorProxy | null
  show: boolean
  setShow: (show: boolean) => void
}) {
  const api = apiService()
  const { refetch } = api.useDoorProxiesOfChainProxy(
    props?.doorProxy?.chain_proxy
  )

  const apiAction = async (values: CompanyFormShape) => {
    if (!props.doorProxy) {
      return
    }

    const payload = {
      ...values,
      zipcode: values.zipcode || '',
      address: values.address || '',
      country: values.country || 'US',
      state: values.state || '',
      city: values.city || '',
      domain: values.domain?.toLowerCase() || '',
      name: values.name?.trim() || '',
    }
    const response = await api.editDoorProxy(payload, props?.doorProxy?.id)
    await refetch()
    toast.success('Door updated successfully')
    return response
  }

  if (!props.doorProxy) {
    return
  }

  return (
    <CompanyModal
      hideCategoryAndType
      hideKnownDistributorsAndTags
      defaultValues={{
        ...props.doorProxy,
        category: 0,
        sub_category: 0,
      }}
      apiAction={apiAction}
      show={props.show}
      setShow={props.setShow}
      title="Edit Door"
      description="Add or modify information about this company."
      acceptButtonText="Edit Door"
    />
  )
}
