import { CiCircleList } from 'react-icons/ci'
import TableButton from './TableButton'
import apiService from 'services/api'
import { useMemo } from 'react'

export default function TableButtonProduct({
  selectedProducts,
  ...props
}: {
  selectedProducts: number[]
} & Partial<React.ComponentProps<typeof TableButton>>) {
  const api = apiService()
  const { data } = api.useProducts()
  const selectedProductsNames = useMemo(() => {
    return data
      ?.filter((product) => selectedProducts.includes(product.id))
      .map((product) => product.name)
  }, [data, selectedProducts])
  const activeValue = useMemo(() => {
    if (!selectedProductsNames || !selectedProductsNames.length) return null
    if (selectedProductsNames.length === 1) return selectedProductsNames[0]
    return selectedProductsNames.length.toString()
  }, [selectedProductsNames])

  return (
    <TableButton
      {...props}
      text="Products"
      icon={CiCircleList}
      activeValue={activeValue}
    />
  )
}
