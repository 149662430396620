import React from 'react'
import { BiArrowToRight, BiTrash } from 'react-icons/bi'
import { FiEdit2 } from 'react-icons/fi'
import { TbListDetails } from 'react-icons/tb'
import { cn } from '../UI/cn'

interface ActionIconProps {
  style?: React.CSSProperties
  onClick: () => void
}

export const SeeIcon = (props: ActionIconProps) => {
  return (
    <BiArrowToRight
      size={20}
      color="#667085"
      style={{ cursor: 'pointer', ...props.style }}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick()
      }}
    />
  )
}
export const DeleteIcon = (props: ActionIconProps) => {
  return (
    <BiTrash
      size={20}
      color="#667085"
      style={{ cursor: 'pointer', ...props.style }}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick()
      }}
    />
  )
}

export const EditIcon = (props: ActionIconProps) => {
  return (
    <FiEdit2
      size={20}
      color="#667085"
      style={{ cursor: 'pointer', ...props.style }}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick()
      }}
    />
  )
}

export const ViewIcon = (props: ActionIconProps) => {
  return (
    <TbListDetails
      size={20}
      color="#667085"
      style={{ cursor: 'pointer', ...props.style }}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick()
      }}
    />
  )
}

type ActionButtonsProps = {
  onEdit?: () => void
  onDelete?: () => void
  onSee?: () => void
  className?: string
}

export function ActionButtons(props: ActionButtonsProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-end pr-5 gap-2 flex-1',
        props.className
      )}
    >
      {props.onEdit && <EditIcon onClick={props.onEdit} />}
      {props.onDelete && <DeleteIcon onClick={props.onDelete} />}
      {props.onSee && <SeeIcon onClick={props.onSee} />}
    </div>
  )
}

type JobActionButonProps = {
  onView: () => void
  className?: string
}

export function JobActionButtons(props: JobActionButonProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-center pr-5 gap-2 flex-1',
        props.className
      )}
    >
      <ViewIcon onClick={props.onView} />
    </div>
  )
}
