import * as S from './LTVChartsCard.styles'
import { formatUsd } from '../../../utils/formatting'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

export function LTVChartsCard({
  ltv,
}: {
  ltv: {
    total_ltv: number
    revenue_ltv: number
    brand_ltv: number
  }
}) {
  return (
    <S.LTVInfo>
      <S.Value style={{ marginLeft: 20 }}>
        <S.Label>Total Lifetime Value</S.Label>
        <S.ValueBig>{formatUsd(ltv?.total_ltv)}</S.ValueBig>
      </S.Value>

      <S.LTVChart>
        <S.ChartWrapper>
          <Doughnut
            data={{
              datasets: [
                {
                  data: [ltv?.revenue_ltv, ltv?.brand_ltv],
                  backgroundColor: ['#218100', '#E5CB96'],
                  borderColor: ['#218100', '#E5CB96'],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                datalabels: {
                  display: false,
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
            }}
          />
        </S.ChartWrapper>

        <S.LTVChartSubtitles>
          <S.Value>
            <S.Label>
              <S.ColoredDot color={'#218100'} />
              Revenue LTV
            </S.Label>
            <S.ValueBig>{formatUsd(ltv?.revenue_ltv)}</S.ValueBig>
          </S.Value>
          <S.Value>
            <S.Label>
              <S.ColoredDot color={'#E5CB96'} />
              Lifetime Brand Value
            </S.Label>
            <S.ValueBig>{formatUsd(ltv?.brand_ltv)}</S.ValueBig>
          </S.Value>
        </S.LTVChartSubtitles>
      </S.LTVChart>
    </S.LTVInfo>
  )
}
