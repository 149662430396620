import getJobStatus from 'utils/getJobStatusColor'
import { TextPill } from './TextPill'

export function JobStatusPill({
  statusValue,
  statusLabel,
}: {
  statusValue: number
  statusLabel: string
}) {
  const colors = getJobStatus(statusValue)

  return (
    <TextPill
      style={{
        marginBottom: 2,
      }}
      textColor={colors[1]}
      backgroundColor={colors[0]}
    >
      {statusLabel}
    </TextPill>
  )
}
