import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { ISelectProps, Select } from './Select'
import React from 'react'

export interface IFormSelectProps<T extends FieldValues, K>
  extends ISelectProps<K> {
  control: Control<T, any>
  name: Path<T>
}

export function FormSelect<T extends FieldValues, K>(
  props: IFormSelectProps<T, K>
) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  return (
    <Select
      {...props}
      buttonVariant={'secondary'}
      selected={value}
      onSelect={(item) => onChange(item)}
      error={error?.message}
    />
  )
}
