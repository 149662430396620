import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import styles from 'styled-components'

const NUM_CHARS = 450

const StyledEllipsis = styles.div`
  color: var(--gray-600, #475467);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

function Ellipsis({ description }: { description: string }) {
  const [isTruncated, setIsTruncated] = useState(true)

  if (!description) return null

  if (description.length <= NUM_CHARS) {
    return <>{description}</>
  }

  if (description.length > NUM_CHARS && isTruncated) {
    return (
      <StyledEllipsis
        style={{ cursor: 'pointer' }}
        onClick={() => setIsTruncated(false)}
      >
        {description.slice(0, NUM_CHARS)}... <BsChevronDown />
      </StyledEllipsis>
    )
  }

  return (
    <StyledEllipsis
      style={{ cursor: 'pointer' }}
      onClick={() => setIsTruncated(true)}
    >
      {description}
      <BsChevronUp style={{ marginLeft: 8 }} />
    </StyledEllipsis>
  )
}

export default Ellipsis
