import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Tabs } from '../../components/FbUI/Tabs'
import { ChainsTable } from '../../components/Tables/ChainsTable/ChainsTable'
import { CompaniesTable } from '../../components/Tables/CompaniesTable/CompaniesTable'
import { ContactsTable } from '../../components/Tables/ContactsTable/ContactsTable'
import { DealsTable } from '../../components/Tables/DealsTable/DealsTable'
import { K12Table } from '../../components/Tables/K12Table/K12Table'
import { UniversitiesTable } from '../../components/Tables/UniversitiesTable/UniversitiesTable'
import { cn } from '../../components/UI/cn'
import { Combobox } from '../../components/UI/combobox/combobox'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  CONTACT_REQUEST_JOB_TABLE_KEY,
  CONTACTS_TABLE_KEY,
  DEAL_TABLE_KEY,
  getCampaignTableKey,
  getCompanyTableKey,
  K12_TABLE_KEY,
} from '../../constants/tableQueryKeys'
import { useCampaignsStore } from '../../features/campaigns/campaigns-store'
import { Campaign } from '../../models/campaign'
import { Category, CompanyTypeSlug, SubCategory } from '../../models/companies'
import { FilterIdentifier } from '../../models/saved_view'
import apiService from '../../services/api'
import { CampaignsTable } from '../../features/campaigns/CampaignsTable'
import FbButton from '../../components/FbUI/FbButton'
import { BiChevronLeft } from 'react-icons/bi'
import SimpleBar from 'simplebar-react'
import { ContactRequestsTable } from '../../components/Tables/ContactRequestsTable/ContactRequestsTable'
import { CreateContactRequesJobFromCampaign } from '../../components/Modals/CreateContactRequestJobFromCampaignModal'

export function CampaignsPage() {
  const api = apiService()
  const campaignsStore = useCampaignsStore()
  const { data: campaigns, isFetching: campaignsLoading } =
    api.useGetCampaigns()
  const { data: categories, isFetching: categoriesLoading } =
    api.useGetCompanyCategoriesAndSub()

  const campaignTypes = useMemo(() => {
    const types: {
      slug: string
      sub_category: SubCategory
      category: Category
    }[] = []
    categories?.forEach((c) => {
      c.sub_categories.forEach((s) => {
        types.push({
          slug: s.slug,
          sub_category: s,
          category: c,
        })
      })
    })
    return types
  }, [categories])

  const campaignOptions = useMemo(
    () =>
      campaigns
        ?.filter(
          (c) => !campaignsStore.campaignsOpen.some((_c) => _c.id === c.id)
        )
        ?.map((c) => ({
          label: c.name,
          value: c.id,
          icon: (
            <div
              className={`h-3 w-3 rounded-full mr-2 flex-shrink-0`}
              style={{
                backgroundColor: c.color,
              }}
            />
          ),
        })),
    [campaigns, campaignsStore.campaignsOpen]
  )

  function handleOpenCampaign(campaignId: number | string) {
    const selected = campaigns?.find((it) => it.id == campaignId)
    if (selected) {
      campaignsStore.openCampaign(selected)
    }
  }

  const tablesForCampaignsOpen = useMemo(() => {
    return campaignsStore.campaignsOpen?.map((c) => {
      const type = campaignTypes.find((it) => it.slug === c.type.slug)
      if (!type) {
        return
      }

      switch (c.type.slug) {
        case 'restaurants-bars':
          return (
            <TableContainer
              campaign={c}
              key={`campaign-${c.id}`}
              oppsTable={
                <ChainsTable
                  filterIdentifierModifier={
                    `_CAMPAIGN_${c.id}` as FilterIdentifier
                  }
                  defaultColumnVisibility={{
                    chain: true,
                    count: true,
                    menu: false,
                    menu_length: false,
                    menu_matches: false,
                    total_ltv: false,
                    brand_ltv: false,
                    revenue_ltv: false,
                    one_year_total_value: false,
                    taro: false,
                    tabo: false,
                    pounds_per_year: false,
                    note_count: true,
                    contact_count: true,
                    deal_count: true,
                    sales_stages: true,
                    account_owners: true,
                    tags: true,
                    distributors: true,
                    cuisine_50: false,
                    expense_category: false,
                    rating: false,
                    reviews_count: false,
                    velocity_group: false,
                    domain: false,
                    instagram_url: false,
                    instagram_followers: false,
                    instagram_following: false,
                    instagram_posts: false,
                    ingredients_matrix: false,
                    country_summary: false,
                    state_summary: false,
                    city_summary: false,
                    zip_summary: false,
                    median_hhi: false,
                    pop_density: false,
                    hh_gt100k: false,
                    reputation_data: false,
                    michelin_stars_count: false,
                  }}
                  tableKey={getCampaignTableKey(CHAINS_TABLE_KEY, c.id)}
                  baseFilters={{ campaign: c.id }}
                />
              }
            />
          )
        case 'education-cu':
          return (
            <TableContainer
              campaign={c}
              key={`campaign-${c.id}`}
              oppsTable={
                <UniversitiesTable
                  defaultColumnVisibility={{
                    institution_name: true,
                    address: false,
                    city: false,
                    state_abbr: false,
                    zipcode: false,
                    domain: false,
                    dormitory_capacity: false,
                    meal_plan_provided: false,
                    total_enrollment: false,
                    total_men_enrollment: false,
                    total_women_enrollment: false,
                    food_management_company: false,
                    food_management_company_source: false,
                    food_management_company_parent: false,
                    estimated_meals_per_day: false,
                    carnegie_classification_2021_size_setting_size: false,
                    carnegie_classification_2021_size_setting_year: false,
                    carnegie_classification_2021_size_setting_student_composition:
                      false,
                    campaigns: false,
                    deal_count: true,
                    contact_count: true,
                    note_count: true,
                    sales_stages: true,
                    account_owner: true,
                    taglist: true,
                    distributors: true,
                    google_place_url: false,
                  }}
                  filterIdentifierModifier={
                    `_CAMPAIGN_${c.id}` as FilterIdentifier
                  }
                  tableKey={getCampaignTableKey(
                    COLLEGE_AND_UNIVERSITY_TABLE_KEY,
                    c.id
                  )}
                  baseFilters={{ campaign: c.id }}
                />
              }
            />
          )
        case 'education-k-12':
          return (
            <TableContainer
              key={`campaign-${c.id}`}
              campaign={c}
              oppsTable={
                <K12Table
                  filterIdentifierModifier={
                    `_CAMPAIGN_${c.id}` as FilterIdentifier
                  }
                  tableKey={getCampaignTableKey(K12_TABLE_KEY, c.id)}
                  baseFilters={{ campaign: c.id }}
                  defaultColumnVisibility={{
                    k12district__lea_name: true,
                    k12district__address: false,
                    k12district__zipcode: false,
                    k12district__lcity: false,
                    k12district__lstate: false,
                    k12district__county: false,
                    k12district__locale: false,
                    k12district__operational_schools: false,
                    k12district__gslo: false,
                    k12district__gshi: false,
                    k12district__student_count: false,
                    k12district__teachers_count: false,
                    k12district__phone: false,
                    campaigns: false,
                    sales_stages: true,
                    deals__account_owner: true,
                    taglist: true,
                    contact_count: true,
                    note_count: true,
                    distributors: true,
                    deal_count: true,
                    k12district__revenue: false,
                    k12district__reduced_lunch: false,
                    k12district__free_lunch: false,
                    k12district__estimated_meals_per_day: false,
                    k12district__food_service_expenditures: false,
                    k12district__total_supplemented_lunch: false,
                    k12district__total_supplemented_lunch_perc: false,
                    k12district__domain: false,
                    k12district__google_place_url: false,
                    k12district__uncategorized_lunch: false,
                    k12district__free_lunch_perc: false,
                    k12district__reduced_lunch_perc: false,
                    k12district__uncategorized_lunch_perc: false,
                  }}
                />
              }
            />
          )
        default:
          return (
            <TableContainer
              campaign={c}
              key={`campaign-${c.id}`}
              oppsTable={
                <CompaniesTable
                  subCategory={type.sub_category}
                  category={type.category}
                  filterIdentifierModifier={
                    `_CAMPAIGN_${c.id}` as FilterIdentifier
                  }
                  tableKey={getCampaignTableKey(
                    getCompanyTableKey(type.slug as CompanyTypeSlug),
                    c.id
                  )}
                  baseFilters={{ campaign: c.id }}
                />
              }
            />
          )
      }
    })
  }, [campaigns, campaignsStore.campaignsOpen])

  return (
    <div
      className={'p-4 w-full flex flex-row gap-x-3'}
      style={{
        height: 'calc(100vh - 90px)',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div className={'w-full h-full flex-5 bg-white rounded'}>
        {campaignsStore.overviewShown ? (
          <TableContainerDiv show={true}>
            <CampaignsTable
              onOpenCampaign={(campaign) =>
                campaignsStore.openCampaign(campaign)
              }
            />
          </TableContainerDiv>
        ) : (
          tablesForCampaignsOpen
        )}
      </div>
      {campaignsStore.campaignsOpen.length ? (
        <div
          className={'h-full flex-2 bg-white rounded flex-shrink-0 w-[250px]'}
        >
          <div className={'h-full w-full flex flex-col gap-x-2 p-2 gap-y-2'}>
            {campaignsStore.overviewShown ? (
              <div className={'flex items-center justify-center p-1.5'}>
                <h4 className={'text-lg font-bold'}>Opened campaigns</h4>
              </div>
            ) : (
              <FbButton
                onClick={() => campaignsStore.showOverview()}
                variant={'warning'}
                disabled={campaignsStore.overviewShown}
              >
                <div className={'w-full flex items-center'}>
                  <BiChevronLeft size={25} />
                  <span className={'w-full'}>Back To Overview</span>
                </div>
              </FbButton>
            )}
            <Combobox
              loading={campaignsLoading || categoriesLoading}
              disabled={campaignOptions?.length === 0}
              options={campaignOptions ?? []}
              searchPlaceholder="Search Campaigns..."
              placeholder="Open Campaign"
              onSelectAltAction={handleOpenCampaign}
              buttonClassName={'w-full max-w-full mb-1'}
            />
            <hr />
            <SimpleBar style={{ maxHeight: 'calc(100% - 100px)' }}>
              {campaignsStore.campaignsOpen?.map((camp) => {
                return <CampaignTab key={camp.id} campaign={camp} />
              })}
            </SimpleBar>
          </div>
        </div>
      ) : null}
    </div>
  )
}

function CampaignTabButton(props: {
  onClick: () => void
  active: boolean
  title: string
  subtitle?: string
  color?: string
  onClose?: () => void
}) {
  return (
    <div
      onClick={props.onClick}
      className={cn(
        'group rounded cursor-pointer border-2 border-solid flex flex-row items-center relative hover:bg-gray-100 justify-between min-h-16 overflow-hidden w-full mb-2',
        {
          'border-fb-green-800 bg-slate-100': props.active,
        }
      )}
    >
      <div className={'w-full flex flex-row'}>
        <div className="flex-1 flex p-2">
          <span className="truncate w-full ">
            <span className={'flex items-center gap-1'}>
              <div
                className={'w-3 h-3 rounded-full'}
                style={{ backgroundColor: props.color }}
              ></div>
              <b className="block truncate">{props.title}</b>
            </span>
            {props.subtitle && (
              <span className="block truncate text-sm">({props.subtitle})</span>
            )}
          </span>
        </div>
        {props.onClose && (
          <div
            className="hidden group-hover:flex text-gray-300 hover:text-black rounded items-center justify-center pr-3"
            onClick={(e) => {
              e.stopPropagation()
              props.onClose?.()
            }}
          >
            &#x2715;
          </div>
        )}
      </div>
    </div>
  )
}

function CampaignTab({ campaign }: { campaign: Campaign }) {
  const campaignsStore = useCampaignsStore()
  return (
    <CampaignTabButton
      onClick={() => campaignsStore.showCampaign(campaign)}
      active={campaignsStore.campaignShown?.id === campaign.id}
      title={campaign.name}
      subtitle={campaign.type.name}
      color={campaign.color}
      onClose={() => {
        return campaignsStore.closeCampaign(campaign.id)
      }}
    />
  )
}

function TableContainer(props: {
  oppsTable: React.JSX.Element
  campaign: Campaign
}) {
  const [tab, setTab] = useState<
    'opps' | 'deals' | 'contacts' | 'contact_requests'
  >('opps')
  const campaignShown = useCampaignsStore((s) => s.campaignShown)

  const hasContactRequests = useMemo(
    () =>
      ['restaurants-bars', 'education-cu', 'education-k12'].includes(
        props.campaign.type.slug
      ),
    [props.campaign]
  )

  const tabs = useMemo(() => {
    const tabs = [
      { title: 'Opportunities', key: 'opps' },
      { title: 'Deals', key: 'deals' },
      { title: 'Contacts', key: 'contacts' },
    ]
    if (hasContactRequests) {
      tabs.push({ title: 'Contact Requests', key: 'contact_requests' })
    }
    return tabs
  }, [hasContactRequests])

  return (
    <TableContainerDiv show={props.campaign.id === campaignShown?.id}>
      <div className={'pt-3 px-4'}>
        <div className={'w-full flex justify-between'}>
          <h1 className={'text-2xl mb-2 font-bold'}>{props.campaign.name}</h1>
          {hasContactRequests && (
            <CreateContactRequesJobFromCampaign
              campaignId={props.campaign.id}
            />
          )}
        </div>
        <Tabs
          tabs={tabs as any}
          selectedTab={tab}
          setSelectedTab={setTab}
          style={{
            borderBottom: 'none',
          }}
          tabStyle={{ width: '140px' }}
        />
      </div>
      <TabDiv show={tab === 'opps'}>{props.oppsTable}</TabDiv>
      <TabDiv show={tab === 'deals'}>
        <DealsTable
          showCompanyColumn
          disableUrlPagination
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
          tableKey={getCampaignTableKey(DEAL_TABLE_KEY, props.campaign.id)}
        />
      </TabDiv>
      <TabDiv show={tab === 'contacts'}>
        <ContactsTable
          defaultColumnVisibility={{
            requested_contact__campaign__name: false,
          }}
          tableKey={getCampaignTableKey(CONTACTS_TABLE_KEY, props.campaign.id)}
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
        />
      </TabDiv>
      {hasContactRequests && (
        <TabDiv show={tab === 'contact_requests'}>
          <ContactRequestsTable
            tableKey={getCampaignTableKey(
              CONTACT_REQUEST_JOB_TABLE_KEY,
              props.campaign.id
            )}
            campaignId={props.campaign.id}
          />
        </TabDiv>
      )}
    </TableContainerDiv>
  )
}

const TableContainerDiv = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  flex-grow: 1;
  //overflow: hidden;
  height: calc(100vh - 130px);
`

const TabDiv = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  height: 100%;
`
