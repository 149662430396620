import { toast, ToastOptions } from 'react-toastify'

const toastConfig = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light',
} satisfies ToastOptions
export const notifyError = (message: string) => {
  toast.error(message, toastConfig)
}
export const notifySuccess = (message: string) => {
  toast.success(message, toastConfig)
}
export const notifyInfo = (message: string) => {
  toast.info(message, toastConfig)
}
