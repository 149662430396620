import { cn } from '../UI/cn'
import ReactCreatableSelect from 'react-select/creatable'
import React, { forwardRef } from 'react'

export const ReactCreatableSelectWrapper = forwardRef<any, any>(
  (props, ref) => {
    return (
      <ReactCreatableSelect
        menuPortalTarget={document.body}
        {...props}
        styles={{
          ...props.styles,
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 100,
            pointerEvents: 'all',
          }),
          control: (provided, state) => ({
            // ...provided,
            ...props.styles?.control?.(provided, state),
            display: 'flex',
            height: props?.height || '33.5px',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            ...props.styles?.placeholder?.(provided, state),
            color: undefined,
          }),
        }}
        classNames={{
          ...props.classNames,
          control: (provided) => {
            return cn(
              'form-control-select',
              {
                'form-control-select-focus': provided.isFocused,
                'opacity-50': props.isDisabled,
              },
              props.classNames?.control?.(provided) ?? ''
            )
          },
          placeholder: (provided) =>
            'form-control-select-placeholder' +
            (props.classNames?.placeholder?.(provided) ?? ''),
          option: (provided) =>
            `${provided.isFocused ? 'hover-menu-item' : ''} ${
              provided.isSelected ? 'selected-menu-item' : ''
            }` + (props.classNames?.option?.(provided) ?? ''),
        }}
        ref={ref}
      />
    )
  }
)

ReactCreatableSelectWrapper.displayName = 'ReactCreatableSelectWrapper'
