import { formatPhoneNumberIntl } from 'react-phone-number-input'

export const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const decimalUsd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const formatUsd = (num?: number): string => {
  if (num === null || num === undefined) {
    return ''
  }
  return usd.format(num)
}

export const formatUsdDecimal = (num: number): string => {
  if (num === null || num === undefined) {
    return ''
  }
  return decimalUsd.format(num)
}

export const twoDecimalNum = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const integerNum = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const percentageNum = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export const formatPercentage = (num: number): string => {
  if (num === null || num === undefined) {
    return ''
  }
  return percentageNum.format(num)
}

export const formatInteger = (num?: number): string => {
  if (num === null || num === undefined) {
    return ''
  }

  return integerNum.format(num)
}

export const formatTwoDecimal = (num: number): string => {
  if (num === null || num === undefined) {
    return ''
  }
  return twoDecimalNum.format(num)
}

export const formatPhoneNumber = ({
  phoneNumber,
  extension,
}: {
  phoneNumber: string
  extension?: string
}): string => {
  const phoneNumberWithPlus = phoneNumber.startsWith('+')
    ? phoneNumber
    : `+${phoneNumber}`
  let formattedPhoneNumber =
    formatPhoneNumberIntl(phoneNumberWithPlus) || phoneNumberWithPlus
  // This will return +1 213 373 4253
  // I want +1 (213) 373-4253
  const [countryCode, areaCode, firstThree, lastFour] =
    formattedPhoneNumber.split(' ')
  if (countryCode && areaCode && firstThree && lastFour) {
    formattedPhoneNumber = `${countryCode} (${areaCode}) ${firstThree}-${lastFour}`
  }
  return extension
    ? `${formattedPhoneNumber}, ext. ${extension}`
    : formattedPhoneNumber
}
