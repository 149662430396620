import { INote } from '../../models/contact_companies'
import { useState } from 'react'
import apiService from '../../services/api'
import { Modal } from '../UI/Modal/Modal'
import { useForm } from 'react-hook-form'
import { NoteManager } from 'components/Notes/NoteManager'

export default function NotesModal(props: {
  noteToEdit?: INote
  show: boolean
  handleClose: () => void
  chainId: number | undefined
  contactCompanyId: number | undefined
}) {
  const api = apiService()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      note: props.noteToEdit ? props.noteToEdit.content : '',
      attachments: [],
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true)
    if (props.noteToEdit) {
      await api.editNote(props.noteToEdit.id, values.note)
      if (values.attachments.length > 0) {
        await api.addAttachmentsToNote(props.noteToEdit.id, values.attachments)
      }
    } else {
      await api.createNote(
        props.chainId,
        props.contactCompanyId,
        values.note,
        values.attachments
      )
    }
    setIsSubmitting(false)
    props.handleClose()
  })

  return (
    <Modal
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      title={props.noteToEdit ? 'Edit this Note' : 'Add a Note'}
      onAccept={onSubmit}
      acceptButtonText={props.noteToEdit ? 'Edit' : 'Add'}
      loading={isSubmitting}
    >
      <NoteManager
        control={control}
        noteName="note"
        attachmentsName="attachments"
        label=""
        existingNote={props.noteToEdit}
      />
    </Modal>
  )
}
