import apiService from 'services/api'
import FilterModal, { FilterGroup } from '../../Filters/components/FilterModal'
import { SelectFilter } from '../../Filters/fields/SelectFilter'
import {
  ContactCountRangeFilter,
  DealCountRangeFilter,
  NoteCountRangeFilter,
  RangeFilter,
} from '../../Filters/fields/RangeFilter'
import { US_STATES } from '../../../utils/us_states'
import { US_CITIES } from 'utils/us_cities'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { k12LocaleOptions } from '../../../utils/k12locales'
import { k12Grades } from '../../../utils/k12grades'
import { SingleSelectFilter } from 'components/Filters/fields/SingleSelectFilter'
import { useEffect, useState } from 'react'

import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from '../../Filters/FilterStore'

export function K12Filterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const k12FilterStore = filterStoreRepo.getStore(filterIdentifier)
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)
  const { data: k12CountiesOptions } = api.useK12CountiesOptions()
  const { pendingValueFilters: pending } = k12FilterStore()

  const [filteredLowestGrades, setFilteredLowestGrades] = useState(k12Grades)
  const [filteredHighestGrades, setFilteredHighestGrades] = useState(k12Grades)

  // This effect ensures that the available options for lowest and highest grade
  // are updated when the selected value changes for gslo or gshi
  useEffect(() => {
    const lowestGrade = pending['gslo']?.[0] ?? null
    const highestGrade = pending['gshi']?.[0] ?? null

    let lowestGradeRank: number | undefined
    let highestGradeRank: number | undefined

    if (lowestGrade) {
      lowestGradeRank = k12Grades.find(
        (grade) => grade.value === lowestGrade.value
      )?.rank
    }

    if (highestGrade) {
      highestGradeRank = k12Grades.find(
        (grade) => grade.value === highestGrade.value
      )?.rank
    }

    setFilteredHighestGrades(
      lowestGrade
        ? k12Grades.filter((grade) => grade.rank >= (lowestGradeRank as number))
        : k12Grades
    )

    setFilteredLowestGrades(
      highestGrade
        ? k12Grades.filter(
            (grade) => grade.rank <= (highestGradeRank as number)
          )
        : k12Grades
    )
  }, [pending])

  return (
    <FilterModal identifier={filterIdentifier} store={k12FilterStore}>
      <FilterGroup title="Account">
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].sales_stage}
          filterKey={'sales_stage'}
          options={stageOptions as any}
          filterStore={k12FilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].account_owner}
          filterKey={'account_owner'}
          options={userOptions as any}
          filterStore={k12FilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].tags}
          filterKey={'tags'}
          options={tagsOptions as any}
          filterStore={k12FilterStore}
        />
        <DealCountRangeFilter store={k12FilterStore} />
        <NoteCountRangeFilter store={k12FilterStore} />
        <ContactCountRangeFilter store={k12FilterStore} />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].distributors}
          filterKey={'distributors'}
          filterStore={k12FilterStore}
          options={distributorOptions as any}
        />
      </FilterGroup>
      <FilterGroup title="Location">
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].city}
          options={US_CITIES}
          filterStore={k12FilterStore}
          filterKey={'city'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].state}
          options={US_STATES}
          filterStore={k12FilterStore}
          filterKey={'state'}
        />
        <ZipcodeFilter filterStore={k12FilterStore} />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].locale}
          options={k12LocaleOptions}
          filterStore={k12FilterStore}
          filterKey={'locale'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].county}
          options={k12CountiesOptions}
          filterStore={k12FilterStore}
          filterKey={'county'}
        />
      </FilterGroup>
      <FilterGroup title="District Info">
        <SingleSelectFilter
          filterKey={'gslo'}
          title={FILTER_TITLES[filterIdentifier].gslo}
          options={filteredLowestGrades}
          filterStore={k12FilterStore}
        />
        <SingleSelectFilter
          filterKey={'gshi'}
          title={FILTER_TITLES[filterIdentifier].gshi}
          options={filteredHighestGrades}
          filterStore={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].student_count}
          filterKey={'student_count'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].teachers_count}
          filterKey={'teachers_count'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].operational_schools}
          filterKey={'operational_schools'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].revenue}
          filterKey={'revenue'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].food_service_expenditures}
          filterKey={'food_service_expenditures'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].free_lunch}
          filterKey={'free_lunch'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].reduced_lunch}
          filterKey={'reduced_lunch'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].total_supplemented_lunch}
          filterKey={'total_supplemented_lunch'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].uncategorized_lunch}
          filterKey={'uncategorized_lunch'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].free_lunch_perc}
          filterKey={'free_lunch_perc'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].reduced_lunch_perc}
          filterKey={'reduced_lunch_perc'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].total_supplemented_lunch_perc}
          filterKey={'total_supplemented_lunch_perc'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].uncategorized_lunch_perc}
          filterKey={'uncategorized_lunch_perc'}
          store={k12FilterStore}
        />
        <RangeFilter
          minAllowNegative={false}
          maxAllowNegative={false}
          title={FILTER_TITLES[filterIdentifier].estimated_meals_per_day}
          filterKey={'estimated_meals_per_day'}
          store={k12FilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
