import FbButton from 'components/FbUI/FbButton'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { ImportContactStepProps } from './types'
import { BiError } from 'react-icons/bi'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/UI/Modal/ModalPrimitive'

export default function BaseStep({
  onContinue,
  onBack,
  title,
  subtitle,
  children,
  canContinue = true,
  isContinueLoading = false,
  backButtonText = 'Cancel',
  continueButtonText = 'Continue',
  errorText,
}: ImportContactStepProps & {
  title: string
  subtitle?: string | React.ReactNode
  children: React.ReactNode
  canContinue?: boolean
  isContinueLoading?: boolean
  backButtonText?: string
  continueButtonText?: string
  errorText?: string | null
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        {subtitle && <DialogDescription>{subtitle}</DialogDescription>}
      </DialogHeader>
      <div
        className={
          'max-h-[70vh] overflow-y-auto overflow-x-hidden max-w-full w-full my-2'
        }
      >
        <ModalBodyContent>{children}</ModalBodyContent>
      </div>
      {errorText && (
        <ErrorContainer>
          <ErrorIconWrapper>
            <BiError color="red" />
          </ErrorIconWrapper>
          <ErrorText>{errorText}</ErrorText>
        </ErrorContainer>
      )}
      <FilterModalFooter>
        <FbButtonModal onClick={onBack} variant="secondary">
          {backButtonText}
        </FbButtonModal>
        <FbButtonModal
          onClick={canContinue ? onContinue : undefined}
          disabled={!canContinue}
          loading={isContinueLoading}
        >
          {continueButtonText}
        </FbButtonModal>
      </FilterModalFooter>
    </>
  )
}

const FilterModalFooter = styled(Modal.Footer)`
  display: flex;
  gap: 2px;
`

const FbButtonModal = styled(FbButton)`
  flex: 1;
`

const ModalBodyContent = styled.div`
  border: 1px dashed #a8b9ca;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0 10px;
  padding: 20px 0;
`
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 14px 10px;
`

const ErrorIconWrapper = styled.div`
  margin-right: 8px;
  font-size: 26px;
`

const ErrorText = styled.p`
  color: #b03a2e;
`
