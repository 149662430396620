import { useAuth } from 'context/authentication/useAuth'
import { Navigate, Outlet } from 'react-router-dom'
import * as S from './styles'
import LoadingScreen from '../../../components/Layout/LoadingScreen'
import { UnauthenticatedNav } from 'components/Layout/Navbar'
import Footer from 'components/Layout/Footer/Footer'

export function AuthLayout() {
  const { isAuthenticated, isCheckingAuth } = useAuth()

  if (isCheckingAuth) return <LoadingScreen />

  if (isAuthenticated) return <Navigate to="/" />

  return (
    <S.Container>
      <UnauthenticatedNav />
      <S.Content>
        <Outlet />
      </S.Content>
      <Footer />
    </S.Container>
  )
}
