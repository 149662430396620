import { NotificationButton } from 'components/Notification/NotificationButton'
import { useAuth } from 'context/authentication/useAuth'
import { useMemo } from 'react'
import logo from '../../../assets/logo.svg'
import { featureFlagService } from '../../../utils/featureFlagService'
import { NavMenu } from '../NavMenu'
import * as S from './styles'
import { useNavbarStore } from '../navbar-store'

export function AuthenticatedNav() {
  const { logout, user } = useAuth()
  const store = useNavbarStore()
  const featureFlag = featureFlagService()

  const items = useMemo(() => {
    let out = [
      { label: 'Dashboard', to: '/dashboard' },
      { label: 'Activity', to: store.lastActivityVisited },
      { label: 'Contacts', to: store.lastContactVisited },
      { label: 'Opportunities', to: store.lastOppVisited },
      { label: 'Deals', to: '/deals' },
    ]

    if (featureFlag.enableCampaigns) {
      out.push({ label: 'Campaigns', to: '/campaigns' })
    }

    out = out.concat([
      { label: 'Product', to: '/product' },
      { label: 'Settings', to: store.lastSettingsVisited },
    ])
    return out
  }, [featureFlag.enableCampaigns, store])

  return (
    <S.Wrapper>
      <S.LogoImg src={logo} alt="logo" />
      <NavMenu items={items} />
      <NotificationButton />
      <S.CompanyTitle>@{user?.email.split('@')[1]}</S.CompanyTitle>
      <S.LogoutIcon onClick={logout} style={{ marginRight: 20 }} />
    </S.Wrapper>
  )
}
