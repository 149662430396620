import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import { Modal } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components/macro'
import FbButton, { FbButtonVariants } from './FbUI/FbButton'

interface Options {
  confirmLabel?: string
  buttonVariant?: FbButtonVariants
}

interface IDialogConfirm {
  show: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  proceed: Function
  confirmation: React.ReactNode | string
  options?: Options
  redMessage?: string
  title: string
  enableEscape?: boolean
}

const DialogConfirm = (props: IDialogConfirm) => {
  const { show, proceed, confirmation, title, redMessage, enableEscape } = props
  return (
    <Modal
      centered
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : 'static'}
      show={show}
    >
      <Modal.Header className="flex justify-content-between align-items-center">
        <Modal.Title>{title}</Modal.Title>
        <CloseIcon className="text-muted" onClick={() => proceed(false)} />
      </Modal.Header>
      <Modal.Body>
        <span>{confirmation}</span>
        {redMessage && (
          <>
            <br />{' '}
            <ExtraMessageRed className="text-danger">
              {redMessage}
            </ExtraMessageRed>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className={'d-flex'}>
        <FbButton
          style={{ flex: 1 }}
          variant="secondary"
          onClick={() => proceed(false)}
        >
          Cancel
        </FbButton>
        <FbButton
          variant={props.options?.buttonVariant || 'primary'}
          style={{ flex: 1 }}
          onClick={() => proceed(true)}
          data-testid="button-confirm-delete"
        >
          {props.options?.confirmLabel || 'OK'}
        </FbButton>
      </Modal.Footer>
    </Modal>
  )
}

export default function confirm(
  confirmation: React.ReactNode | string,
  title: string,
  redMessage = '',
  options: Options = { confirmLabel: 'OK', buttonVariant: 'primary' }
) {
  return createConfirmation(confirmable(DialogConfirm))({
    confirmation,
    title,
    redMessage,
    options,
  })
}

const CloseIcon = styled(MdClose)`
  color: #aaa;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.14s ease-in-out;

  &:hover {
    color: #444 !important;
  }

  &:focus {
    color: #000 !important;
  }

  &:active {
    color: #000 !important;
  }
`
const ExtraMessageRed = styled.span``
