import { Link, LinkProps } from 'react-router-dom'
import { cn } from './cn'

export function Anchor(
  props: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
) {
  return (
    <a
      className={cn(
        'text-[#087443] hover:text-[#83B9A1] visited:text-[#002414] active:font-medium underline',
        props.className
      )}
      {...props}
    />
  )
}

export function RouterLink(props: LinkProps) {
  return (
    <Link
      className={cn(
        'text-[#087443] hover:text-[#83B9A1] visited:text-[#002414] active:font-medium underline',
        props.className
      )}
      {...props}
    />
  )
}
