import { Control, FieldValues, Path, useController } from 'react-hook-form'
import React, { useEffect, useMemo } from 'react'
import { ErrorLabel } from '../ErrorLabel'
import lodash from 'lodash'
import { ReactCreatableSelectWrapper } from '../ReactCreatableSelectWrapper'
import { cn } from '../../UI/cn'

export interface SelectOption {
  label: string
  value: unknown
}

export interface ICreatableSelectProps<T extends FieldValues, U> {
  control: Control<T, any>
  name: Path<T>
  label?: string | null
  placeholder?: string
  type?: string
  options?: U[]
  isMulti?: boolean
  disableFiltering?: boolean
  isLoading?: boolean
  allowCreateWhileLoading?: boolean
  onCreateOption: (inputValue: string) => Promise<U | null>
  onSearchChange?: (search: string) => void
  disableCreate?: boolean
  style?: React.CSSProperties
  initialSearch?: string
  menuPosition?: 'fixed' | 'absolute'
  clearSearchOnSelect?: boolean
  disabled?: boolean
  readOnly?: boolean
  className?: string
  optional?: boolean
  height?: string
}

export default function CreatableSelect<
  T extends FieldValues,
  U extends FieldValues,
>(props: ICreatableSelectProps<T, U>) {
  const [internalIsLoading, setInternalIsLoading] = React.useState(false)
  const [createdOptions, setCreatedOptions] = React.useState<U[]>([])
  const [search, setSearch] = React.useState(props.initialSearch || '')

  const allOptions = useMemo(
    () => props.options?.concat(createdOptions),
    [props.options, createdOptions]
  )

  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  const compareObjects = (o1: any, o2: any) => {
    return lodash.isEqual(o1, o2)
  }

  const _value = useMemo(() => {
    if (!value) return null
    if (props.isMulti) {
      return value?.map((v: SelectOption) =>
        allOptions?.find((option: any) => compareObjects(option.value, v))
      )
    } else {
      return allOptions?.find((option: any) =>
        compareObjects(option.value, value)
      )
    }
  }, [value, allOptions])

  useEffect(() => {
    if (props.clearSearchOnSelect && _value) {
      setSearch('')
    }
  }, [_value])

  const handleCreate = (inputValue: string) => {
    setInternalIsLoading(true)
    props
      .onCreateOption(inputValue)
      .then((option) => {
        if (!option) return

        setCreatedOptions([...createdOptions, option])
        if (props.isMulti) {
          onChange([...value, option.value])
        } else {
          onChange(option.value)
        }
      })
      .finally(() => {
        setInternalIsLoading(false)
      })
  }

  const handleInputChange = (inputValue: string) => {
    if (props.readOnly) return
    setSearch(inputValue)
    props.onSearchChange?.(inputValue)
  }

  return (
    <div className={cn('mt-3', props.className)} style={props.style}>
      {props.label && (
        <label
          className="text-base font-medium text-gray-800 select-none mb-1"
          htmlFor={props.name.toString()}
        >
          {props.label}
          {props.optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}
        </label>
      )}

      <ReactCreatableSelectWrapper
        onChange={(newValue: any) => {
          if (props.isMulti) {
            onChange(newValue.map((v: any) => v.value))
          } else {
            onChange(newValue?.value)
          }
          if (!newValue) {
            onChange(props.isMulti ? [] : null)
          }
        }}
        isClearable={true}
        name={props.name}
        options={allOptions}
        value={_value}
        ref={ref}
        isMulti={props.isMulti}
        isDisabled={props.disabled || internalIsLoading}
        isLoading={internalIsLoading || props.isLoading}
        allowCreateWhileLoading={props.allowCreateWhileLoading}
        onCreateOption={handleCreate}
        onInputChange={handleInputChange}
        isValidNewOption={(inputValue: any) =>
          props.disableCreate ? false : inputValue.length > 0
        }
        inputValue={search}
        placeholder={props.placeholder}
        filterOption={props.disableFiltering ? () => true : undefined}
        height={props.height}
      />

      {error?.message && <ErrorLabel message={error?.message} />}
    </div>
  )
}
