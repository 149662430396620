import { CellContext } from '@tanstack/react-table'

export const DateCell = <T, U extends string>(info: CellContext<T, U>) => {
  const date = new Date(info.getValue())
  // Format date as "Jan 1, 2021 "
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
    hour12: true,
  })
}
