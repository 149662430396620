import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { CommonEditorProps, Editor } from './Editor'

interface EditorRHFProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
}

export function EditorRHF<T extends FieldValues>(
  props: EditorRHFProps<T> & CommonEditorProps
) {
  const { control, name, ...rest } = props
  const {
    field: { onChange, value },
  } = useController({ control, name })
  return <Editor {...rest} onChange={onChange} value={value} />
}
