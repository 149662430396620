import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  /* display: flex; */

  /* justify-content: space-between; */
  width: 100%;
  align-items: stretch;
`

export const Left = styled.div`
  flex: 0.4;
`

export const Right = styled.div`
  display: flex;
  flex: 0.4;
  justify-content: stretch;
  align-items: flex-end;
`

export const Row = styled.div`
  display: flex;
  gap: 20px;
`
