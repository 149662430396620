/**
 * Extracts the ID with a given prefix from a string.
 * @param str The string containing the prefix and ID.
 * @param prefix The prefix to match at the beginning of the string.
 * @returns The extracted ID as an integer, or undefined if the string does not match the pattern.
 */
export const getIdWithPrefix = (
  str: string,
  prefix: string | undefined
): number | undefined => {
  if (!prefix) return undefined
  // Check if the string starts with the given prefix
  if (str.startsWith(prefix)) {
    // Extract the numeric part of the string after the prefix
    const id: number = parseInt(str.slice(prefix.length))
    // Check if the extracted part is a valid integer
    if (!isNaN(id)) {
      return id
    }
  }
  // If the string does not match the pattern, return undefined
  return undefined
}
