import { FbLink } from 'components/FbUI/FbLink'
import * as S from './styles'

function Privacy() {
  const supportEmail = 'support@firstbite.io'
  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  const emailLink = () => (
    <FbLink to="#" onClick={handleEmailClick}>
      {supportEmail}
    </FbLink>
  )

  return (
    <S.Page>
      <S.Container>
        <S.Header>
          <S.Title>Privacy Policy</S.Title>
          <S.Subtitle>
            Date of last revision: <b>March 20, 2023</b>
          </S.Subtitle>
          <S.Hr />
        </S.Header>
        <S.Content>
          <h2 style={{ marginTop: 0 }}>Who Are We?</h2>
          <p>
            The name of our company is First Bite, Inc., and we’re a company
            located in California, and registered as a corporation in Delaware,
            both in the United States. Our company provides valuable real-time
            data with respect to restaurants, other food service locations,
            retail locations, brokers, distributors and food manufactures and
            their sales information.
          </p>

          <h2>What Is This?</h2>
          <p>
            This is a privacy policy and the reason we have it is to tell you
            how we collect, manage, store, and use your information.
          </p>
          <p>
            Just so we’re clear, whenever we say “we,” “us,” “our,” or
            “ourselves”, we’re talking about First Bite, Inc. and whenever we
            say “you” or “your,” we’re talking about the person or business who
            has decided to use our services. When we talk about our services, we
            mean any of our platforms, websites, or apps; or any features,
            products, graphics, text, images, photos, audio, video, or similar
            things we use.
          </p>

          <h2>Why Are We Showing You This?</h2>
          <p>
            We value and respect your privacy. That is why we strive to only use
            your information when we think that doing so improves your
            experience in using our Services. If you feel that we could improve
            in this mission in any way, or if you have a complaint or concern,
            please let us know by sending us your feedback to the following
            email address: {emailLink()}.
          </p>
          <p>
            Our goal is to be as transparent and open about our use of
            information and data as possible, so that our users can benefit from
            both the way they provide information and how we use it.
          </p>
          <p>
            This privacy policy should be read along with our Terms of Use,
            posted at{' '}
            <FbLink to="/terms-of-use">app.firstbite.io/terms-of-use</FbLink>.
            That’s another big part of what we do, so please review it and
            follow its process for questions or concerns about what it says
            there.
          </p>
          <p>
            Any capitalized terms not defined here are defined in the Terms of
            Use.
          </p>

          <h2>Information Collection and Use</h2>
          <p className="with-margin">
            In using the services, you may be asked to provide us a variety of
            information— some of which can personally identify you and some that
            cannot. We may collect, store, and share this personal information
            with third parties, but only in the ways we explain in this policy.
            Here’s how we do it and why we do it.
          </p>
          <p>
            <b>Personally Identifiable Information: How we collect it.</b>
            <br />
            Personally identifiable information is data that can be used to
            contact or identify a single person. Examples include your name,
            your phone number, your email, your address, and your IP address. We
            collect this information in the following ways:
          </p>
          <p className="with-margin">
            <ul>
              <li>
                Information you provide us via email, through our Services or
                website, including:
                <ul>
                  <li>
                    Your company’s name, telephone number, and email address;
                  </li>
                  <li>
                    As an employee or representative of your company, your name,
                    your email, your other personal information, and your title;
                  </li>
                  <li>Your company’s account information;</li>
                  <li>Your account information;</li>
                  <li>
                    Any other information you provide through our Services
                    (which, based upon what you provide there, may contain
                    personally identifiable information);
                  </li>
                  <li>your IP address;</li>
                </ul>
              </li>
              <li>
                Information we obtain from analytics tools we have. We may use
                cookies or other information through Google Analytics.
              </li>
            </ul>
          </p>

          <p>
            <b>Personally Identifiable Information: How we use it.</b>
            <br />
            We use your personal information in the following ways:
          </p>

          <p className="with-margin">
            <ul>
              <li>To provide you our Services;</li>
              <li>
                To contact you via email to inform you of service issues, new
                features, updates, offers, and billing issues;
              </li>
              <li>To improve our website performance;</li>
              <li>
                To tailor our services to your needs and the way you use our
                Services;
              </li>
              <li>
                To provide you with customized and personalized content and
                services that may be of interest to you;
              </li>
              <li>To determine your geographic location;</li>
              <li>
                To help us quickly and efficiently respond to inquiries and
                requests;
              </li>
              <li>
                To secure our systems, prevent fraud, and help us protect the
                security of our member accounts;
              </li>
              <li>
                To prevent, detect, and investigate potentially illegal conduct,
                including fraud, and to enforce our agreements;
              </li>
              <li>
                To analyze and understand our audience, improve our service
                (including our user interface experiences and service
                performance) and optimize content;
              </li>
              <li>To track how our users are using the Services;</li>
              <li>
                To improve our algorithms and the delivery of our Services;
              </li>
            </ul>
          </p>

          <p>
            <b>Who We Share Your Information With and Why</b>
            <br />
            We only share your information with third parties in the following
            ways and for the following purposes:
          </p>

          <p className="with-margin">
            <ol>
              <li>
                Quickbooks
                <ol type="a">
                  <li>
                    We share your business name, credit cardholder name, and
                    card number and related information to run the initial and
                    subsequent payments for our services. This information is
                    sent directly to Stripe through a plugin on our website.
                    Your credit card information is stored with Stripe for
                    subsequently billing; we do not retain your credit card
                    information internally.
                  </li>
                </ol>
              </li>
              <li>
                Stripe, Inc.
                <ol type="a">
                  <li>
                    We share your business name, credit cardholder name, and
                    card number and related information to run the initial and
                    subsequent payments for our services. This information is
                    sent directly to Stripe through a plugin on our website.
                    Your credit card information is stored with Stripe for
                    subsequently billing; we do not retain your credit card
                    information internally.
                  </li>
                </ol>
              </li>
              <li>
                We share your name and email address with an email processor
                Twilio SendGrid to distribute our mailing list.
              </li>
              <li>
                We will share all collected information to the extent necessary
                and as required by law or to comply with any legal obligations,
                including defense of our company.
              </li>
            </ol>
          </p>

          <p>
            <b>Your Choices in What Information You Share</b>
            <br />
            For users who do not register for our services or a business
            account, we will not collect that users’ personally identifying
            information—unless that personally identifiable information is
            information of a customer of one of our business clients, which is
            shared by that business through permission obtained by the business
            directly from that customer.
          </p>

          <p className="with-margin">
            Our registration process related to your personal information begins
            when you register for the site. We will request your consent to use
            your data at that time. We require your personal data as part of
            your contract with us so we can provide you those Services.
          </p>

          <p className="with-margin">
            <b>How Long We Keep Your Information</b>
            <br />
            We will retain your personally identifiable information for as long
            as you use our services and for approximately 5 years after
            termination of our services, or until you request in writing that we
            delete or otherwise remove your personally identifiable information.
          </p>

          <p>
            <b>Where We Keep and Transfer Your Information</b>
            <br />
            Our business is operated in the United States and, as far as we are
            aware, third parties with whom we share your information are as
            well. Our servers are located in the United States and this is where
            your data and information will be stored. Due to the nature of
            internet communications, however, such data could pass through other
            countries as part of the transmission process; this is also true for
            our clients outside the United States.
          </p>
          <p>
            We do not target our services to customers outside the United
            States. Please be aware if you are a citizen of another country, and
            if you live in Europe in particular, that your information will be
            transferred out of your home country and into the United States. The
            United States might not have the same level of data protection as
            your country provides.
          </p>
          <p>
            Our processing of personal data from individuals is not targeted to
            reveal race; ethnicity; political, religious, or philosophical
            beliefs; trade union memberships; health; sexual activity; or,
            sexual orientation.
          </p>
          <p className="with-margin">
            If you would like more information about this, please email us at{' '}
            {emailLink()}.
          </p>

          <p>
            <b>California Residents</b>
            <br />
            The California Consumer Privacy Act (“CCPA”) provides California
            residents specific rights to restrict, access, and delete their
            collected information. All requests under this section should be
            provided to {emailLink()}. Subject to the requirements and
            limitations under the CCPA, these rights include:
          </p>

          <p>
            <ul>
              <li>
                Upon your written request, up to 2 times during a 12 month
                period, we will provide you a summary of the personal
                information we have for you for your review.
              </li>
              <li>
                We currently do not sell your personal information to other
                persons or entities. However, if we were to do so in the future,
                and after amending this privacy policy, you would have the right
                to opt out of such sales.
              </li>
              <li>
                Upon your written request, and absent a statutorily-recognized
                need under the CCPA to retain such information, we will delete
                your personal information we have collected.
              </li>
            </ul>
          </p>

          <p className="with-margin">
            We may be required to make further inquiry to verify the identity of
            the individual requesting any action above to confirm that person’s
            identity prior to processing that request.
          </p>

          <h2>Authorization of Customer Information Use</h2>
          <p>
            For your business to use our Services and our CRM system in general,
            you will provide us with personally identifiable information of your
            customers and some prospective customers. You are required and
            solely responsible for obtaining the express and explicit consent of
            those customers for our services to message and contact those
            customers. Each time you provide us a customer’s information, you
            are asserting and confirming that you have obtained such consent
            from that customer. You here confirm and agree that you will not use
            our services to message any customer or other individual or entity
            from whom you have not first obtained that consent and that that
            consent has not been revoked or otherwise expired (under an
            agreement, law, or otherwise) at the time the message is sent.
          </p>

          <h2>Protecting Your Information</h2>
          <p>
            <b>Keeping it Safe</b>
            <br />
            We make reasonable and commercially feasible efforts to keep your
            information safe. Though we are a small business, we have
            appropriate security measures in place to prevent your information
            from being accidentally lost, used, or accessed in an unauthorized
            way. We restrict access to your personal information to those who
            need to know it, are subject to contractual confidentiality
            obligations in the case of internal personnel and third-party
            providers, and may be disciplined or terminated if they fail to meet
            these obligations in terms of contractors and internal personnel.
            Those processing your information are tasked to do so in an
            authorized manner and are subject to a duty of confidentiality.
          </p>
          <p>Additionally, we provide the following protections:</p>
          <p>
            <ul>
              <li>All data is stored in an encrypted database.</li>
              <li>All client-server communications are encrypted via SSL.</li>
              <li>
                All passwords are encrypted and are not stored as plain text.
              </li>
              <li>
                We use OpenSSL to issue certificates, as managed by our servicer
                hosting provider.
              </li>
              <li>
                We regularly review our processes to consider if there are any
                additional improvements we can make to our systems.
              </li>
            </ul>
          </p>

          <p className="with-margin">
            That said, no organization or business can guarantee 100% data
            protection. With that in mind, we also have procedures in place to
            deal with any suspected data security breach. We will inform both
            you and any applicable authorities of a suspected data security
            breach, as and when required by law.
          </p>

          <p>
            <b>Third-Party Providers</b>
            <br />
            As articulated in this privacy policy, our services utilize
            third-party providers, as well as providing integration with
            companies such as Google. These third parties have their own privacy
            policies and others’ terms of use.
          </p>

          <p className="with-margin">
            We do not control those policies and terms. You should visit those
            providers to acquaint yourself with their policies and terms. If you
            have any issue or concern with those terms or policies, you should
            address those concerns with that third-party provider.
          </p>

          <p className="with-margin">
            <b>Do Not Track Signals/Cookies</b>
            <br />
            Some technologies, such as web browsers or mobile devices, provide a
            setting that when turned on sends a Do Not Track (DNT) signal when
            browsing a website or app. There is currently no common standard for
            responding to DNT Signals or even in the DNT signal itself. We do
            not recognize or utilize DNT signals. We utilize cookies through
            Google Analytics.
          </p>

          <p>
            <b>No Use by Minors</b>
            <br />
            This website, its content, and our services are not intended for
            anyone under the age of 18. We do not knowingly collect information
            from those under the age of 18. If we learn we have accidentally
            collected such information, it will be deleted.
          </p>
          <p className="with-margin">
            If you are a parent and your minor child has provided us personal
            information, you have the right to contact us and require that we:
            (a) permit you to review the personal information provided, and (b)
            remove and delete the personal information provided. To do so, upon
            you contacting us, we must take reasonable steps to confirm you are
            the parent. You may contact us for such a request at any time at{' '}
            {emailLink()}.
          </p>

          <p>
            <b>Compliance with Regulators</b>
            <br />
            We regularly review our privacy policy to do our best to ensure it
            complies with any applicable laws. Ours is a small business, but
            when we receive formal written complaints, we will contact the
            person who made the complaint to follow up as soon as practicable.
            We will work with relevant regulatory authorities to resolve any
            complaints or concerns that we cannot resolve with our users
            directly.
          </p>
          <p className="with-margin">
            You also have the right to file a complaint with the supervisory
            authority of your home country, where available, relating to the
            processing of any personal data you feel may have violated local
            regulations.
          </p>

          <h2>General Information</h2>
          <p className="with-margin">
            <b>No Unsolicited Personal Information Requests</b>
            <br />
            We will never ask you for your personal information in an
            unsolicited letter, call, or email. If you contact us, we will only
            use your personal information if necessary to fulfill your request.
          </p>
          <p className="with-margin">
            <b>Changes</b>
            <br />
            Our business and the services we provide are constantly evolving. We
            may change our privacy policy at any time. If we change our policy,
            we will affirmatively request that you consent to the new policy or
            email you for consent at that time. We will not reduce your rights
            under this policy without your consent.
          </p>
          <p className="with-margin">
            <b>Complaints</b>
            <br />
            We respect the rights of all of our users, regardless of location or
            citizenship. If you have any questions or concerns about any of
            these rights, or if you would like to assert any of these rights at
            any time, please contact {emailLink()}.
          </p>
          <p className="with-margin">
            <b>Questions about Policy</b>
            <br />
            If you have any questions about this privacy policy, contact us at:{' '}
            {emailLink()}. By accessing any of our services or content, you are
            affirming that you understand and agree with the terms of our
            privacy policy.
          </p>
        </S.Content>
      </S.Container>
    </S.Page>
  )
}

export default Privacy
