import { activityFilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import apiService from 'services/api'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

const IDENTIFIER: FilterIdentifier = 'ACTIVITY_TABLE'

export default function ActivityFilterset() {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions()

  return (
    <FilterModal store={activityFilterStore} identifier={IDENTIFIER}>
      <FilterGroup title="Activity">
        <SelectFilter
          filterKey="actor"
          filterStore={activityFilterStore}
          title={FILTER_TITLES[IDENTIFIER].actor}
          options={userOptions as any}
        />
        <DateRangeFilter
          title={FILTER_TITLES[IDENTIFIER].timestamp}
          filterKey="timestamp"
          filterStore={activityFilterStore}
        />
        <SelectFilter
          filterKey="event_type"
          title={FILTER_TITLES[IDENTIFIER].event_type}
          options={[
            { value: 'company', label: 'Company' },
            { value: 'contact', label: 'Contact' },
            { value: 'deal', label: 'Deal' },
            { value: 'product', label: 'Product' },
          ]}
          filterStore={activityFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
