import { BiTrash } from 'react-icons/bi'
import styled from 'styled-components'

interface FileContainerProps {
  file: { name: string }
  handleRemoveFile: () => void
  LeftIcon?: React.ComponentType<{ size: number; color: string }>
  RemoveFileIcon?: React.ComponentType<{
    size: number
    color: string
    className?: string
  }>
  noOutline?: boolean
}

export default function FileContainer(props: FileContainerProps) {
  const { file, handleRemoveFile, LeftIcon, RemoveFileIcon, noOutline } = {
    RemoveFileIcon: BiTrash,
    ...props,
  }
  return (
    <FileMainContainer noOutline={noOutline}>
      {!!LeftIcon && <LeftIcon size={22} color="#449C0A" />}
      <FileContent leftBorder={!!LeftIcon}>
        {file.name}
        <RemoveFileIcon
          size={22}
          color="#5D6C87"
          onClick={handleRemoveFile}
          className="cursor-pointer"
        />
      </FileContent>
    </FileMainContainer>
  )
}

const FileMainContainer = styled.div<{ noOutline?: boolean }>`
  padding: 0 13px;
  display: flex;
  align-items: center;
  ${({ noOutline }) =>
    !noOutline &&
    `
    border: 1px solid #d3dfe7;
    border-radius: 8px;
    `}
`

const FileContent = styled.div<{ leftBorder?: boolean }>`
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: #2e3748;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
  ${({ leftBorder }) =>
    leftBorder &&
    `
    padding-left: 13px;
    margin-left: 13px;
    border-left: 1px solid #d3dfe7;
    `}
`
