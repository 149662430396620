import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import apiService from 'services/api'
import {
  CARNEGIE_CLASSIFICATION_SIZE_OPTIONS,
  CARNEGIE_CLASSIFICATION_STUDENT_COMPOSITION_OPTIONS,
  CARNEGIE_CLASSIFICATION_YEAR_OPTIONS,
} from 'utils/carnegieClassificationSize'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from '../../../utils/us_states'
import { filterStoreRepo } from '../../Filters/FilterStore'
import FilterModal, { FilterGroup } from '../../Filters/components/FilterModal'
import {
  ContactCountRangeFilter,
  DealCountRangeFilter,
  NoteCountRangeFilter,
  RangeFilter,
} from '../../Filters/fields/RangeFilter'
import { SelectFilter } from '../../Filters/fields/SelectFilter'

import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

export function UniversitiesFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)
  // NOTE: disabling 'with none' option for now for food management companies.
  const { data: foodCompanyManagementOptions } =
    api.useGetUniversityFoodManagementCompanyOptions(false)

  const universitiesFilterStore = filterStoreRepo.getStore(filterIdentifier)

  return (
    <FilterModal identifier={filterIdentifier} store={universitiesFilterStore}>
      <FilterGroup title="Account">
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].sales_stage}
          filterKey={'sales_stage'}
          options={stageOptions as any}
          filterStore={universitiesFilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].account_owner}
          filterKey={'account_owner'}
          options={userOptions as any}
          filterStore={universitiesFilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].tags}
          filterKey={'tags'}
          options={tagsOptions as any}
          filterStore={universitiesFilterStore}
        />
        <DealCountRangeFilter store={universitiesFilterStore} />
        <NoteCountRangeFilter store={universitiesFilterStore} />
        <ContactCountRangeFilter store={universitiesFilterStore} />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].distributors}
          filterKey={'distributors'}
          filterStore={universitiesFilterStore}
          options={distributorOptions as any}
        />
      </FilterGroup>
      <FilterGroup title="Campus Details">
        <RangeFilter
          filterKey="dormitory_capacity"
          title={FILTER_TITLES[filterIdentifier].dormitory_capacity}
          store={universitiesFilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].meal_plan_provided}
          options={[
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ]}
          filterStore={universitiesFilterStore}
          filterKey="meal_plan_provided"
        />
        <RangeFilter
          filterKey="estimated_meals_per_day"
          title={FILTER_TITLES[filterIdentifier].estimated_meals_per_day}
          store={universitiesFilterStore}
        />
        <div className="grid grid-cols-2 gap-2 col-span-3">
          <SelectFilter
            title={FILTER_TITLES[filterIdentifier].food_management_company}
            options={
              foodCompanyManagementOptions?.food_management_companies as any
            }
            filterStore={universitiesFilterStore}
            filterKey={'food_management_company'}
          />
          <SelectFilter
            title={
              FILTER_TITLES[filterIdentifier].food_management_company_source
            }
            options={[
              { label: 'Direct', value: 'direct' },
              { label: 'Indirect', value: 'indirect' },
            ]}
            filterStore={universitiesFilterStore}
            filterKey={'food_management_company_source'}
          />
          <SelectFilter
            title={
              FILTER_TITLES[filterIdentifier].food_management_company_parent
            }
            options={
              foodCompanyManagementOptions?.parent_food_management_companies as any
            }
            filterStore={universitiesFilterStore}
            filterKey={'food_management_company_parent'}
          />
        </div>
      </FilterGroup>
      <FilterGroup title="Carnegie Classification">
        <SelectFilter
          title={
            FILTER_TITLES[filterIdentifier]
              .carnegie_classification_2021_size_setting_size
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_SIZE_OPTIONS as any}
          filterStore={universitiesFilterStore}
          filterKey={'carnegie_classification_2021_size_setting_size'}
        />
        <SelectFilter
          title={
            FILTER_TITLES[filterIdentifier]
              .carnegie_classification_2021_size_setting_year
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_YEAR_OPTIONS as any}
          filterStore={universitiesFilterStore}
          filterKey={'carnegie_classification_2021_size_setting_year'}
        />
        <SelectFilter
          title={
            FILTER_TITLES[filterIdentifier]
              .carnegie_classification_2021_size_setting_student_composition
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_STUDENT_COMPOSITION_OPTIONS as any}
          filterStore={universitiesFilterStore}
          filterKey={
            'carnegie_classification_2021_size_setting_student_composition'
          }
        />
      </FilterGroup>
      <FilterGroup title="Location">
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].city}
          options={US_CITIES}
          filterStore={universitiesFilterStore}
          filterKey={'city'}
        />
        <SelectFilter
          title={FILTER_TITLES[filterIdentifier].state_abbr}
          options={US_STATES}
          filterStore={universitiesFilterStore}
          filterKey={'state_abbr'}
        />
        <ZipcodeFilter filterStore={universitiesFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
