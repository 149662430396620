import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { Cuisine50Options } from 'models/cuisine_50'
import { useMemo } from 'react'
import apiService from 'services/api'
import { CONTACT_TITLES } from 'utils/constants'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

export default function ContactsFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: contactLabelsOption } = api.useGetTagsOptions(true, 'contacts')
  const { data: companyTagsOption } = api.useGetTagsOptions(true, 'products')
  const { data: categories } = api.useGetCompanyCategories()
  const contactTypeOptions = useMemo(() => {
    if (!categories) return []
    return categories.contact_types.map((type) => ({
      label: type.name,
      value: type.id,
      original: type,
    }))
  }, [categories])

  const contactsFilterStore = filterStoreRepo.getStore(
    filterIdentifier as FilterIdentifier
  )

  return (
    <FilterModal store={contactsFilterStore} identifier={filterIdentifier}>
      <FilterGroup title="Contact Details">
        <SelectFilter
          filterStore={contactsFilterStore}
          filterKey="title"
          title={FILTER_TITLES[filterIdentifier].title}
          options={[
            {
              label: 'No Title',
              value: '-1',
            },
            ...CONTACT_TITLES.map((title) => ({
              label: title,
              value: title,
            })),
          ]}
        />
        <SelectFilter
          filterStore={contactsFilterStore}
          filterKey="contact_type"
          title={FILTER_TITLES[filterIdentifier].contact_type}
          options={[
            {
              label: 'No Type',
              value: '-1',
            },
            ...contactTypeOptions.map((type) => ({
              label: type.label,
              value: type.value.toString(),
            })),
          ]}
        />
        <SelectFilter
          filterKey="contact_labels"
          title={FILTER_TITLES[filterIdentifier].contact_labels}
          options={contactLabelsOption as any}
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Company Details">
        <RangeFilter
          filterKey="company_size"
          title={FILTER_TITLES[filterIdentifier].company_size}
          store={contactsFilterStore}
        />
        <SelectFilter
          filterStore={contactsFilterStore}
          filterKey={'company_cuisine_50'}
          title={FILTER_TITLES[filterIdentifier].company_cuisine_50}
          options={Cuisine50Options}
        />
      </FilterGroup>
      <FilterGroup title="Account">
        <SelectFilter
          filterStore={contactsFilterStore}
          filterKey="sales_stage"
          title={FILTER_TITLES[filterIdentifier].sales_stage}
          options={stageOptions as any}
        />
        <SelectFilter
          filterKey="account_owner"
          title={FILTER_TITLES[filterIdentifier].account_owner}
          options={userOptions as any}
          filterStore={contactsFilterStore}
        />
        <SelectFilter
          filterKey="company_tags"
          title={FILTER_TITLES[filterIdentifier].company_tags}
          options={companyTagsOption as any}
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="User Activity">
        <DateRangeFilter
          title={FILTER_TITLES[filterIdentifier].modified}
          filterKey="modified"
          filterStore={contactsFilterStore}
        />
        <DateRangeFilter
          title={FILTER_TITLES[filterIdentifier].added}
          filterKey="added"
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
