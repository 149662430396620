import { activityV2FilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import apiService from 'services/api'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'

const IDENTIFIER: FilterIdentifier = 'ACTIVITY_TABLE_V2'

export default function ActivityV2Filterset() {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions()

  return (
    <FilterModal identifier={IDENTIFIER} store={activityV2FilterStore}>
      <FilterGroup title="Activity">
        <SelectFilter
          filterKey="user"
          title={FILTER_TITLES[IDENTIFIER].user}
          filterStore={activityV2FilterStore}
          options={userOptions as any}
        />
        <DateRangeFilter
          title={FILTER_TITLES[IDENTIFIER].created_at}
          filterKey="created_at"
          filterStore={activityV2FilterStore}
        />
        <SelectFilter
          filterKey="event_type"
          filterStore={activityV2FilterStore}
          title={FILTER_TITLES[IDENTIFIER].event_type}
          options={[
            { value: 'company', label: 'Company' },
            { value: 'contact', label: 'Contact' },
            { value: 'deal', label: 'Deal' },
          ]}
        />
      </FilterGroup>
    </FilterModal>
  )
}
