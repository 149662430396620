import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'

export default function DealExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportDeals = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportDeals(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Deals - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Company Attributes',
      elements: [
        ['company', 'Company'],
        ['category', 'Category'],
        ['type', 'Type'],
        ['size', 'Size'],
        ['tags', 'Tags'],
        ['contact_tags', 'Contact Labels'],
        ['distributors', 'Distributors'],
        ['note_count', 'Notes Count'],
        ['contact_count', 'Contact Count'],
        ['days_since_last_modified', 'Days Since Last Update'],
      ],
    },
    {
      title: 'Deal Attributes',
      elements: [
        ['product', 'Product'],
        ['account_owner', 'Account Owner'],
        ['sales_stage', 'Sales Stage'],
        ['last_datetime_stage_changed', 'Days In Stage'],
        ['expected_close_date', 'Expected Close Date'],
        ['close_date_override', 'Close Date Override'],
        ['modified', 'Last Modified'],
        ['created', 'Date Created'],
      ],
    },
    {
      title: 'Forecast',
      elements: [
        ['predicted_monthly_volume', 'Predicted Monthly Volume'],
        ['monthly_volume_override', 'Monthly Volume Override'],
        ['predicted_monthly_revenue', 'Predicted Monthly Revenue'],
        ['monthly_revenue_override', 'Monthly Revenue Override'],
        ['predicted_annual_volume', '1yr Volume (lbs)'],
        ['predicted_annual_revenue', '1yr Revenue Value'],
        ['predicted_annual_brand_opp', '1yr Brand Value'],
        ['predicted_annual_total', '1yr Total Value'],
        ['annual_revenue_opportunity', 'Annual Revenue Opportunity'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Deals Download"
      subTitle="Download up to 10,000 deals at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Deals"
      columns={columns}
      exportAPIAction={exportDeals}
      {...props}
    />
  )
}
