import styled from 'styled-components/macro'

export const Container = styled.div`
  background-color: white;
  padding: 0px 100px;
`
export const TableDescriptionText = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  max-width: 70%;
`

export const TableTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`

export const SettingsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const SettingsPageContent = styled.div`
  width: 80%;
  background-color: transparent;
`

export const SettingsTableHeaderContainer = styled.div`
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const SettingsTableContainer = styled.div`
  padding: 0px 25px 80px 25px;
  box-sizing: border-box;
`
