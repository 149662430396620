import { Spinner } from 'react-bootstrap'

export default function LoadingScreen() {
  return (
    <div
      className={
        'flex flex-col items-center justify-center h-screen w-screen bg-[#f5f5f5]'
      }
    >
      <Spinner animation="border" />
    </div>
  )
}
