import React, { Fragment, ReactNode } from 'react'

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: ReactNode) => React.JSX.Element
  children: ReactNode
}

export function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): React.JSX.Element {
  return condition ? wrapper(children) : <Fragment>{children}</Fragment>
}
