import { FbLink } from 'components/FbUI/FbLink'
import * as S from './styles'

function Terms() {
  const supportEmail = 'support@firstbite.io'
  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  const emailLink = () => (
    <FbLink to="#" onClick={handleEmailClick}>
      {supportEmail}
    </FbLink>
  )

  return (
    <S.Page>
      <S.Container>
        <S.Header>
          <S.Title>Terms of Use Agreement</S.Title>
          <S.Subtitle>
            Date of last revision: <b>March 13, 2023</b>
          </S.Subtitle>
          <S.Hr />
        </S.Header>
        <S.Content>
          <p>
            This terms of use agreement (“Agreement”) is between First Bite,
            Inc., a Delaware Corporation (“First Bite,” “we,” “us,” “our,” or
            “ourselves”) and the person or entity (“you” or “your”) that has
            decided to use our services; any of our websites or apps; or any
            features, products, graphics, text, images, photos, audio, video,
            location data, computer code, and all other forms of data and
            communications (collectively, “Services”).
          </p>
          <p>
            YOU MUST CONSENT TO THIS AGREEMENT TO USE OUR SERVICE. If you do not
            accept and agree to be bound by all of the terms of this Agreement,
            including the Privacy Policy, posted at{' '}
            <FbLink to="/privacy-policy">
              app.firstbite.io/privacy-policy
            </FbLink>{' '}
            and incorporated by reference herein, you cannot use Services.
          </p>
          <p>
            If we update this Agreement, we will provide you notice and an
            opportunity to review and decide whether you would like to continue
            to use the Services.
          </p>

          <h2>Description of the Services</h2>
          <p>
            We provide a database of every restaurant in the United States that
            can predict what and how much these restaurants will buy, along with
            certain customer relationship management functionality.
          </p>

          <h2>Accessing the Services</h2>
          <p>
            We reserve the right to withdraw or amend the Services and any
            material we provide in the Services, in our sole discretion without
            notice. We will not be liable if for any reason all or any part of
            the Services is unavailable at any time or for any period.
          </p>

          <h2>Log-in Information</h2>
          <p>
            If you choose, or are provided with, a username, password, or any
            other piece of information as part of our security procedures, you
            must treat such information as confidential, and you must not
            disclose it to any other person or entity. You agree not to provide
            any other person with access to this Service or portions of it using
            your username, password, or other security information. You agree to
            notify us immediately of any unauthorized access to or use of your
            username or password or any other breach of security.
          </p>

          <h2>Intellectual Property</h2>
          <p>
            First Bite respects the intellectual property of others and expects
            those who use the Services to do the same. It is our policy, in
            appropriate circumstances and at our discretion, to disable and/or
            terminate the accounts of individuals who may infringe or repeatedly
            infringe the copyrights or other intellectual property rights of
            First Bite or others.
          </p>

          <h2>Your Use of the Services</h2>
          <p className="with-margin">
            <b>Your Representations and Eligibility to Use Services</b>
            <br />
            By registering and using the Services, you represent and warrant
            you: (i) have the authority and capacity to enter this Agreement;
            (ii) are at least 18 years old, or 13 years or older and have the
            express permission of your parent or guardian to use the Services;
            and, (iii) are not precluded or restricted in any way from using the
            Services, either by law or due to previous suspension from the
            Services.
          </p>

          <p className="with-margin">
            <b>Truthfulness of Information</b>
            <br />
            You represent and warrant that all information you submit when using
            the Services is complete, accurate, and truthful. You are
            responsible for maintaining the completeness, accuracy, and
            truthfulness of such information.
          </p>

          <p>
            <b>Limited Use of Services</b>
            <br />
            The Services are only for the uses specified in this Agreement. You
            agree that you will not use our proprietary information or materials
            in any way whatsoever except for use of the Services in compliance
            with this Agreement. We reserve the right to investigate and take
            legal action in response to illegal and/or unauthorized uses of the
            Services.
          </p>

          <p className="with-margin">
            <ol>
              <li>
                You agree that our Services contain proprietary information and
                material that we own and is protected by applicable intellectual
                property and other laws, including but not limited to trademark,
                copyright, patent, and trade secret laws.
              </li>
              <li>
                Our ownership extends to all content, features, and
                functionality on the website or app, and in our communications,
                that is protected or protectable under the law.
              </li>
              <li>
                You agree that you will not use our proprietary information or
                materials in any way whatsoever except for use of the Services
                in compliance with this Agreement.
              </li>
              <li>
                In no way should your use of the Services be construed to
                diminish our intellectual property rights or be construed as a
                license or the ability to use the Services in any context other
                than as expressly permitted under this Agreement.
              </li>
            </ol>
          </p>

          <p>
            <b>Prohibited Activities</b>
            <br />
            You shall{' '}
            <u>
              <b>not</b>
            </u>{' '}
            engage in the following activities:
          </p>
          <p>
            <ol>
              <li>
                Use the Services for any commercial purposes excet as expressly
                authorized by First Bite;
              </li>
              <li>
                Reproduce any portion of the Services in any form or by any
                means, except as expressly peritted in this Agreement or
                otherwise in writing by our authorized agent;
              </li>
              <li>
                Modify, rent, lease, loan, sell, distribute, or create
                derivative works based on the Services in any manner, and you
                shall not exploit the Services in any unauthorized way;
              </li>
              <li>
                Use the Services for any unlawful activities or in violation of
                any laws, regulations, or contractual provisions, or to induce
                others to do or engage in the same;
              </li>
              <li>
                Use the Services to promote violence, degradation, subjugation,
                discrimination or hatred against individuals or groups based on
                race, ethnic origin, religion, disability, gender, age, veteran
                status, sexual orientation, or gender identity;
              </li>
              <li>
                Access another&apos;s account without permission of us or that
                person;
              </li>
              <li>
                Publish or allow to be published malicious code intended to
                damage any mobile device, browser, computer, server, or network
                hardware;
              </li>
              <li>
                Spam any comments section with offers of goods and services, or
                inappropriate messages;
              </li>
              <li>
                Use manual or automated software, devices, or other processes to
                “crawl,” “scrape,” or “spider” any portion of the Services;
              </li>
              <li>
                Decompile, reverse engineer, or otherwise attempt to obtain the
                source code of the Services; and
              </li>
              <li>
                Solicit passwords or personal identifying information for
                commercial or unlawful purposes from others or disseminate
                another person&apos;s personal information without that
                person&apos;s permission.
              </li>
            </ol>
          </p>

          <h2>Payments</h2>
          <p>
            <b>Third-Party Payment Services</b>
            <br />
            We utilize third-party payment services (currently, Quickbooks and
            Stripe) to handle payment services. If you have any issue with
            charges, those issues need to be addressed between you and the
            third-party payment service. We are not responsible for the payments
            or any related disputes.
          </p>

          <p>
            <b>No Refunds</b>
            <br />
            We do not provide refunds for any Services or corresponding
            subscriptions for any reason. If you cancel a subscription prior to
            the subscription period ending, you will not receive a pro-rated
            refund for any remaining time left in the subscription.
          </p>

          <h2>Disclaimers, Waivers, and Indemnification</h2>
          <p className="with-margin">
            <b>No Guarantees, Endorsements, or Investigation</b>
            <br />
            We do not provide any guarantees or endorsements of any third-party
            or user, or its content or links, provided through the Services. We
            do not investigate or otherwise review any user or third-party or
            its content. You accept all risks associated with any third-party,
            and its content, links, or related information. You agree not to
            hold us liable for any conduct or content of third parties or other
            user.
          </p>

          <p className="with-margin">
            <b>Disclaimer of Warranties</b>
            <br />
            All information and services are provided on an “as is” basis
            without warranty of any kind, either express or implied, including,
            but not limited to, the implied warranties of merchantability,
            fitness for a particular purpose, and non-infringement. In addition,
            we do not make any warranty that access to the Services will be
            uninterrupted, secure, complete, error free, or meet your particular
            requirements.
          </p>

          <p className="with-margin">
            <b>Limitation of Liability</b>
            <br />
            To the maximum extent permitted by law, in no event shall we or our
            agents be liable to you or any other person or entity for any
            direct, punitive, incidental, special, consequential, or exemplary
            damages. In no event shall our liability under this Agreement exceed
            the total amount of money paid to us by you under any subscription
            or fees for our Services.
          </p>
          <p>
            <b>Waiver of Liability</b>
            <br />
            You waive any liability of or claims against us for any injuries or
            damages (including compensatory, punitive, special, or consequential
            damages) you sustain as a result of or associated with using the
            Services. You waive any claim or liability stemming from our
            negligence.
          </p>
          <p className="with-margin">
            Where our Services incorporate or utilize any information, software,
            or content of a third party, you waive any liability or claim
            against us based upon that information, software, or
            content—including based upon the negligence of that third party.
          </p>
          <p className="with-margin">
            <b>Scope of Waiver</b>
            <br />
            You understand and agree the above waiver extends to any claim of
            any nature or kind, known or unknown, suspected or unsuspected,
            regardless of when the claim first existed.
          </p>
          <p>
            <b>California-Specific Waiver and Notices</b>
            <br />
            You understand and agree the above waiver includes waiver of a claim
            of the type identified under California Civil Code, Section 1542,
            which provides: “A general release does not extend to claims which
            the creditor does not know or suspect to exist in his or her favor
            at the time of executing the release, which if known by him or her
            must have materially affected his or her settlement with the
            debtor.”
          </p>
          <p className="with-margin">
            The following notice is for California users: Pursuant to California
            Civil Code Section 1789.3: If you have a question or complaint about
            us, our products, or our Services please contact us at {emailLink()}
            . You may also contact the Complaint Assistance Unit of the Division
            of Consumer Services of the California Department of Consumer
            affairs by telephone at (800) 952-5210 or by mail at the Department
            of Consumer Affairs, Consumer Information Division, 1625 North
            Market Blvd., Suite N 112, Sacramento, CA 95834.
          </p>
          <p className="with-margin">
            <b>Indemnification</b>
            <br />
            If we are subject to a complaint, a demand, mediation, arbitration,
            litigation, or other pursuit based upon your conduct relating to or
            from use of our Services, you agree to defend, indemnify, and hold
            us harmless for all costs, expenses, and fees (including attorneys’
            fees by the attorneys of our choice) we expend in defending
            ourselves against or otherwise addressing such pursuit. Such conduct
            or use includes, but is not limited to, your violation of this
            Agreement; your use of the Services and any consequence that
            produces; your use of intellectual property on or through the
            Services; and, any such actions performed by your agent or
            representative, or at your direction. You agree to indemnify us as
            soon as we incur the costs, expenses, and fees, whether those are
            billed by event, or in week, month, or some other interval.
          </p>

          <h2>Limitation of Services and Termination</h2>
          <p className="with-margin">
            <b>Right to Remove Content</b>
            <br />
            We reserve an unrestricted right to remove content at any time
            without advanced notice. Nonetheless, we are not responsible for any
            third-party content and make no commitment or assurances that we
            will remove, monitor, or assess any specific third-party content,
            regardless of its content or character.
          </p>
          <p>
            <b>Right to Terminate Access</b>
            <br />
            To protect us and our users, we reserve an unrestricted right to
            refuse, terminate, block, or cancel your application to, account
            with, or access to the Services at any time, with or without cause.
            You acknowledge here that you have no right: to use of the Services
            should we terminate or suspend your account. Primary reasons
            warranting termination include (and primary reasons leading to
            suspension pending investigation of claims or evidence of the
            following):
          </p>
          <p>
            <ol>
              <li>You violate any of the provisions of this Agreement;</li>
              <li>
                You hinder or interfering with us in providing our Services;
              </li>
              <li>
                You make misrepresentations or otherwise deceive First Bite;
                and,
              </li>
              <li>
                You use the Services in violation of: any international,
                federal, state, or local law; or applicable regulation, rule, or
                order by any regulatory, governing, or private authority, or a
                court of competent jurisdiction.
              </li>
              <li>No Right to Services or Content</li>
            </ol>
          </p>
          <p className="with-margin">
            You neither possess nor retain any ownership of or rights to the
            Services, regardless of who generated the content. Upon termination,
            you have no right to access, retain, or have returned any such
            information.
          </p>
          <p className="with-margin">
            <b>Grant of License to User-Generated Content</b>
            <br />
            You hereby grant to us a worldwide, irrevocable, non-exclusive,
            royalty-free license to use, reproduce, modify, publish, translate
            and distribute any content that you submit in any form to our
            Services in any existing or future media. You also grant to us the
            right to sub-license these rights, and the right to bring an action
            for infringement of these rights.
          </p>
          <p className="with-margin">
            <b>Survival</b>
            <br />
            After termination, we retain all rights to content as specified in
            this Agreement. Sections II—VI of this Agreement survive after
            termination.
          </p>

          <h2>General Provisions</h2>
          <p>
            <b>DMCA Violations</b>
            <br />
            If you believe any of our content infringes on your copyright, you
            may request removal by contacting the following address:{' '}
            {emailLink()}. We will respond to all requests promptly and to the
            best of our ability.
          </p>
          <p>
            <b>Successors and Assignees</b>
            <br />
            We may assign this Agreement to an affiliate or in connection with a
            merger or sale of all or substantially all of our corresponding
            assets. You may not assign this Agreement.
          </p>
          <p className="with-margin">
            You agree that any waiver or protections afforded to us are also
            provided to our affiliates, directors, officers, principals,
            employees, agents, and successors in their roles and relationship
            with us. You also acknowledge that all waivers and agreements bind
            not only you, but any successors, heirs, agents, and other
            representatives.
          </p>
          <p className="with-margin">
            <b>Arbitration</b>
            <br />
            <b>
              The exclusive means of resolving any dispute or claim arising out
              of or relating to this Agreement (including any alleged breach
              thereof) or the Service shall be binding arbitration administered
              by the American Arbitration Association. You may not under any
              circumstances commence or maintain against us any class action,
              class arbitration, or other representative action or proceeding.
              You understand and accept that, by agreeing to arbitration, you
              are foregoing your access to a trial by jury.
            </b>
          </p>
          <p className="with-margin">
            <b>Governing Law, Forum Selection, and Venue</b>
            <br />
            This Agreement will be governed by and construed in accordance with
            the laws of the State of California. The venue for any disputes
            including mediation, arbitration, or litigation shall be San Mateo
            County, California. You agree to waive the following defenses to any
            action brought in California: forum non conveniens and lack of
            personal jurisdiction.
          </p>
          <p className="with-margin">
            <b>Waiver</b>
            <br />
            If one party waives any term or provision of this Agreement at any
            time, that waiver will only be effective for the specific instance
            and specific purpose for which the waiver was given. If either party
            fails to exercise or delays exercising any of its rights or remedies
            under this Agreement, that party retains the right to enforce that
            term or provision at a later time.
          </p>
          <p className="with-margin">
            <b>Severability</b>
            <br />
            If any provision of this Agreement is invalid or unenforceable,
            whether by the decision of an arbitrator or court, by passage of a
            new law, or otherwise, the remainder of this Agreement will remain
            in effort and be construed and enforced consistent with the purpose
            of this Agreement, to the fullest extent permitted by law.
            Furthermore, if a provision is deemed invalid or unenforceable, you
            agree that provision should be enforced to the fullest extent
            permitted under the law, consistent with its purpose.
          </p>
          <p className="with-margin">
            <b>Understanding of Agreement</b>
            <br />
            You acknowledge that you understand the terms and conditions of this
            Agreement. You also acknowledge that you could discuss these
            provisions with a lawyer at your own expense prior to entering into
            this Agreement and have either done so or chosen not to do so in
            entering this Agreement. Regardless of your choice, you intend to be
            fully bound by this Agreement.
          </p>
          <p className="with-margin">
            <b>Entire Agreement</b>
            <br />
            This Agreement, together with the Privacy Policy, constitutes the
            entire agreement between us, and supersedes all prior agreements,
            representations, and understandings, oral or written, between us. If
            you subscribe to the Services, you will also be required to agree to
            a Subscription Agreement and related documents. In the event of a
            conflict between this Agreement and the Subscription Agreement with
            you, your Subscription Agreement would govern.
          </p>
        </S.Content>
      </S.Container>
    </S.Page>
  )
}

export default Terms
