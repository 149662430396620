import { AiOutlineWarning } from 'react-icons/ai'
import * as S from './styles'

export function ErrorLabel({ message }: { message: string }) {
  return (
    <S.ErrorLabel>
      <AiOutlineWarning size={20} />
      <S.ErrorMessage>{message}</S.ErrorMessage>
    </S.ErrorLabel>
  )
}
