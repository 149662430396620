import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { AiOutlineWarning } from 'react-icons/ai'

interface WarningBannerProps {
  text: string
}

const WarningBanner: React.FC<WarningBannerProps> = ({ text }) => {
  return (
    <Alert variant="warning" className="mb-0 py-[8px]">
      <div className="flex items-center gap-2 text-xl">
        <AiOutlineWarning size={14} color="#FCBD00" />
        <i className="text-sm">
          <strong>Note:</strong> {text}
        </i>
      </div>
    </Alert>
  )
}

export default WarningBanner
