import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'

export const featureFlagService = () => {
  const { preferences, isLoading } = usePreferences()

  const getFeatureFlag = (flagName: string, defaultValue = false) =>
    useMemo(
      () => preferences?.feature_flags?.[flagName]?.enabled ?? defaultValue,
      [preferences]
    )

  const shouldShowMenuSize = getFeatureFlag('show_menu_size')
  const enableInstagremData = getFeatureFlag('enable_instagram_data')
  const shouldShowMenu = getFeatureFlag('show_menu_column')
  const shouldShowReputationData = getFeatureFlag('reputation_data')
  const shouldShowDoorMenu = getFeatureFlag('show_door_menu_column')
  const enableCnUTable = getFeatureFlag('enable_cnu_table')
  const enableK12Table = getFeatureFlag('enable_k12_table')
  const enableActivityV2 = getFeatureFlag('enable_activity_v2')
  const enableRestaurantsCountryFilter = getFeatureFlag(
    'enable_restaurants_country_filter'
  )
  const enableContactRequests = getFeatureFlag('enable_contact_requests')
  const enableChainsListV2 = getFeatureFlag('chains_list_v2')
  const enableTagsSort = getFeatureFlag('enable_tags_sort')
  const enableCampaigns = getFeatureFlag('enable_campaigns')

  return {
    shouldShowMenuSize,
    enableInstagremData,
    shouldShowMenu,
    shouldShowReputationData,
    shouldShowDoorMenu,
    enableCnUTable,
    enableK12Table,
    enableActivityV2,
    enableRestaurantsCountryFilter,
    enableContactRequests,
    enableChainsListV2,
    enableTagsSort,
    enableCampaigns,
    isLoading,
  }
}
