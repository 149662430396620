import apiService from 'services/api'
import { CompanyFormShape, CompanyModal } from './CompanyModal'
import { toast } from 'react-toastify'

export function CreateCompanyModal({
  show,
  setShow,
  defaultValues,
}: {
  show: boolean
  setShow: (show: boolean) => void
  defaultValues?: Partial<CompanyFormShape>
}) {
  const api = apiService()

  const apiAction = async (values: CompanyFormShape) => {
    const payload = {
      ...values,
      country: values.country || 'US',
      state: values.state || '',
      city: values.city || '',
      domain: values.domain?.toLowerCase() || '',
      name: values.name?.trim() || '',
    }
    const response = await api.createCompany(payload)
    toast.success('Company created successfully')
    return response
  }

  return (
    <CompanyModal
      defaultValues={defaultValues}
      show={show}
      setShow={setShow}
      apiAction={apiAction}
      title="New Company"
      description="Add a company to First Bite to track additional deals and opportunities."
      acceptButtonText="Create Company"
      checkUniqueDomain={true}
    />
  )
}
