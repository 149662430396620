import { useMemo } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

interface EditorProps {
  value: string
  onChange?: (value: string | undefined) => void
}
export interface CommonEditorProps {
  theme?: 'snow' | 'bubble'
  placeholder?: string
  label?: string
  optional?: boolean
  additionalToolbarButtons?: React.ReactNode[]
  readOnly?: boolean
  disableToolbar?: boolean
  noBorder?: boolean
  className?: string
}

export function Editor(props: EditorProps & CommonEditorProps) {
  const {
    value,
    onChange,
    theme,
    placeholder,
    label,
    optional,
    readOnly,
    disableToolbar,
    noBorder,
    className,
  } = {
    theme: 'snow',
    onChange: () => {},
    ...props,
  }

  // We need to generate a unique ID for the toolbar container since multiple can coexist on the same page
  const uniqueId = useMemo(() => Math.random().toString(36).substring(7), [])

  const onChangeHandler = (value: string) => {
    let newValue: string = value
    if (newValue === '<p><br></p>') {
      // empty string on the WYSIWYG editor
      newValue = ''
    }
    onChange(newValue)
  }

  return (
    <div className="mt-2">
      {label && (
        <label className="text-base font-medium text-gray-800 select-none mb-1">
          {label}
          {optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}
        </label>
      )}
      <div>
        <ReactQuillStyled
          id={'editor-' + uniqueId}
          tabIndex={0}
          className={className}
          theme={theme}
          value={value}
          noBorder={noBorder}
          onChange={onChangeHandler}
          placeholder={placeholder}
          modules={{
            toolbar: disableToolbar
              ? false
              : {
                  container: `#toolbar-${uniqueId}`,
                },
          }}
          readOnly={readOnly}
          formats={[
            'bold',
            'italic',
            'underline',
            'strike',
            'list',
            'bullet',
            'ordered',
          ]}
        />
        <ToolbarParent noBorder={noBorder}>
          <div id={'toolbar-' + uniqueId}>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-list" value="ordered"></button>
            {/* Custom attachment button */}
            {props.additionalToolbarButtons && props.additionalToolbarButtons}
          </div>
        </ToolbarParent>
      </div>
    </div>
  )
}

const ReactQuillStyled = styled(ReactQuill)<{ noBorder?: boolean }>`
  .ql-container {
    border-bottom: 0;
    border-radius: 0.375rem;
    ${({ noBorder }) => noBorder && 'border: none;'}
    order: 0;
  }
`
const ToolbarParent = styled.div<{ noBorder?: boolean }>`
  .ql-toolbar {
    border-top: 0;
    border-radius: 0.375rem;
    ${({ noBorder }) => noBorder && 'border: none;'}


    button:hover, button.ql-active, button:focus {
      .ql-stroke {
        fill: none;
        stroke: #1E734C !important;
      }

      .ql-fill {
        fill: #1E734C !important;
        stroke: none;
      }
      .ql-picker {
        color: #1E734C !important;
      }
    }
  }
}`
