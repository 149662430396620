import { useEffect, useState } from 'react'

export default function useCachedState<T>(
  initialState: T,
  localStorageKey: string,
  typeGuard?: (obj: unknown) => obj is T
) {
  const [state, setState] = useState<T>(initialState)

  useEffect(() => {
    const cachedState = localStorage.getItem(localStorageKey)

    if (cachedState != null && cachedState !== 'undefined') {
      const cachedStateObj = JSON.parse(cachedState)
      if (typeGuard) {
        if (typeGuard(cachedStateObj)) {
          setState(cachedStateObj)
        }
      } else {
        setState(cachedStateObj)
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state))
  }, [state])

  return [state, setState] as const
}
