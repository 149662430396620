import { GreenBold } from 'components/Tables/ActivityTableV2/CellEvent/CellEventFormatter'
import { CellEventFormatterProps, contentTypeMap } from '../../utils'

export const DefaultDeleteEvent = ({ event }: CellEventFormatterProps) => {
  const contentTypeName = contentTypeMap[event.content_type.model]
  return (
    <>
      deleted a <GreenBold>{contentTypeName}</GreenBold>
    </>
  )
}
