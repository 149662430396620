import { cn } from 'components/UI/cn'
import { IoIosClose } from 'react-icons/io'

export function FilterCloseButton(props: {
  isActive: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <button
      type={'button'}
      className={cn(
        'absolute top-1 right-1 w-8 h-[80%] flex items-center justify-center rounded cursor-default hover:bg-gray-200 transition-colors',
        {
          hidden: !props.isActive,
        }
      )}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick?.(e)
      }}
    >
      <IoIosClose
        style={{ width: '32px', height: 'auto' }}
        className={'text-red-700'}
      />
    </button>
  )
}
