import { FbLink } from 'components/FbUI/FbLink'
import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import styled from 'styled-components/macro'
import BaseStep from './BaseStep'
import { useImportContactContext } from './ImportContactContext'
import {
  FileType,
  isValidFileSize,
  readAndParseFileContents,
} from 'utils/fileValidation'
import FileContainer from 'components/FileContainer/FileContainer'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

const MAX_ROW_LENGTH = 200

export default function UploadStep() {
  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [fileError, setFileError] = useState<string | null>(null)

  const {
    methods: { nextStep, prevStep, setFile, handleClose },
    state: { file },
  } = useImportContactContext()

  const handleDrag = (
    e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files)
    }
  }

  const handleFiles = async (files: FileList): Promise<void> => {
    const isValid = await isFileValid(files[0])
    if (isValid) {
      setFile(files[0])
    }
  }

  /**
   * Checks if a given file is a valid CSV file.
   * @param file The file to be checked.
   * @returns Promise<boolean> indicating if the file is valid or not.
   */
  const isFileValid = async (file: File): Promise<boolean> => {
    setFileError(null)
    try {
      // Check file type
      if (file.type !== FileType.CSV) {
        throw new Error('Invalid file type. Please upload a .csv file.')
      }

      // Check file size
      if (!isValidFileSize(file, 1)) {
        throw new Error('File size exceeds 1MB. Please upload a smaller file.')
      }

      // Read and parse file
      const parsedData = await readAndParseFileContents(file)

      // Check row count
      if (parsedData.length > MAX_ROW_LENGTH) {
        throw new Error(
          `File size too large. Uploads are limited to ${MAX_ROW_LENGTH} contacts at a time.`
        )
      }

      return true
    } catch (error: any) {
      setFileError(`${error}`)
      return false
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click()
  }

  useEffect(() => {
    if (!file) {
      if (inputRef.current) {
        inputRef.current!.value = ''
      }
    }
  }, [file])

  return (
    <BaseStep
      handleClose={handleClose}
      onContinue={() => nextStep()}
      onBack={() => prevStep()}
      canContinue={!!file}
      errorText={fileError}
      title="Upload New Contacts - Add File"
      subtitle={
        <>
          First row will be used as column headers. See sample upload{' '}
          <Link
            href="https://docs.google.com/spreadsheets/d/1GD9VFtPcQ-4vlxSu3dVWjyLW9SoCinVBZPoCegfjh7I/edit#gid=290823366"
            target="_blank"
          >
            here
          </Link>
          .
        </>
      }
    >
      <Form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <ModalBodyContent
          htmlFor="input-file-upload"
          dragActive={dragActive}
          onClick={(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) =>
            e.preventDefault()
          }
        >
          <FiUpload size={40} color="#798AA3" />
          {file ? (
            <WithFiles file={file} setFile={setFile} />
          ) : (
            <NoFiles onButtonClick={onButtonClick} />
          )}
        </ModalBodyContent>
        {dragActive && (
          <DragOverlay
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </Form>
    </BaseStep>
  )
}

function NoFiles({ onButtonClick }: { onButtonClick: () => void }) {
  return (
    <>
      <ModalBodyContentTitle>Drag & drop file here</ModalBodyContentTitle>
      <ModalBodyContentSubtitle>
        or{' '}
        <FbLink to="#" onClick={onButtonClick}>
          browse
        </FbLink>{' '}
        to choose a file
      </ModalBodyContentSubtitle>
      <ModalBodyContentLegend>
        (supports .csv file type up to 1MB each, up to {MAX_ROW_LENGTH} contacts
        per file)
      </ModalBodyContentLegend>
    </>
  )
}

function WithFiles({
  file,
  setFile,
}: {
  file: File
  setFile: (file: File | null) => void
}) {
  const handleRemoveFile = () => {
    setFile(null)
  }

  return (
    <>
      <ModalBodyContentTitle>Upload - Success!</ModalBodyContentTitle>
      <FileContainer
        LeftIcon={IoMdCheckmarkCircleOutline}
        file={file}
        handleRemoveFile={handleRemoveFile}
      />
    </>
  )
}

const ModalBodyContent = styled.label<{
  dragActive?: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  border-radius: 8px;

  ${(props) =>
    props.dragActive &&
    `
    background: #F0F4F8;
  `}
`

const ModalBodyContentTitle = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #1d212d;
`

const ModalBodyContentSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #1d212d;
`

const ModalBodyContentLegend = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 10px;
  color: #5d6c87;
`

const Form = styled.form`
  position: relative;
`

const DragOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const Link = styled.a`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #087443;

  &:hover,
  &:active,
  &:focus {
    color: #11b269;
  }
`
