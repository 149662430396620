import { CellEventFormatterProps } from '../utils'
import { DefaultUpdateEvent } from './UpdateEvent/DefaultUpdateEvent'
import { DealUpdateEvent } from './UpdateEvent/DealUpdateEvent'
import { DefaultDeleteEvent } from './DeleteEvent/DefaultDeleteEvent'
import { CompanyAddOrRemoveEvent } from './AddOrRemoveEvent/CompanyAddOrRemoveEvent'
import { ContactAddOrRemoveEvent } from './AddOrRemoveEvent/ContactAddOrRemoveEvent'
import { DefaultCreateEvent } from './CreateEvent/DefaultCreateEvent'
import { NoteUpdateEvent } from './UpdateEvent/NoteUpdateEvent'

export default function CellEventFormatterV2({
  event,
}: CellEventFormatterProps) {
  if (!event) return null

  switch (event.action_type) {
    case 'CREATE':
      switch (event.content_type.model) {
        default:
          return <DefaultCreateEvent event={event} />
      }

    case 'UPDATE':
      switch (event.content_type.model) {
        case 'deal':
          return <DealUpdateEvent event={event} />
        case 'note':
          return <NoteUpdateEvent event={event} />
        default:
          return <DefaultUpdateEvent event={event} />
      }

    case 'DELETE':
      return <DefaultDeleteEvent event={event} />

    case 'ADD':
    case 'REMOVE':
      switch (event.content_type.model) {
        case 'contactcompany':
          return <CompanyAddOrRemoveEvent event={event} />
        case 'contact':
          return <ContactAddOrRemoveEvent event={event} />
        default:
          return null
      }

    default:
      return null
  }
}
