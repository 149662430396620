import { useQuery } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import apiService from 'services/api'
import * as S from '../CommonTable.styles'
import { TableSearch } from '../../Filters/TableSearch'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import styled from 'styled-components'
import { ContactResponse } from 'models/contacts'
import { useActivityTableColumns } from './useActivityTableColumns'
import ActivityFilterset from './ActivityFilterset'
import { BsDownload } from 'react-icons/bs'
import { IconMiniButton } from 'components/FbUI/FbButton'
import { downloadFile } from 'utils/csvDownloader'
import { toast } from 'react-toastify'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import {
  activityFilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { IColumnVisibility } from '../../DataTable/types'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'actor',
  'content_type',
  'timestamp',
  'company',
]

const PAGE_SIZE = 100

interface ActivityTableProps {
  setTotalRowsCount?: (count: number) => void
  myActivityOnly?: boolean
  tableKey: string
}

function ActivityTableComponent(props: ActivityTableProps) {
  const [isDownloading, setIsDownloading] = useState(false)
  const {
    state: { sorting },
    methods: { clearSelectedRows },
  } = useDataTableContext()

  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)
  const api = apiService()

  const filterParams = useFilterParams(activityFilterStore)

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      if (props.myActivityOnly) {
        return await api.getMyActivityList(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      } else {
        return await api.getActivityList(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      }
    },
  })
  // END DATA FETCHING

  const columns = useMemo(() => {
    return [...useActivityTableColumns()]
  }, [])

  const downloadCsv = async () => {
    const params = {
      ...filterAndSortParams,
    }

    try {
      setIsDownloading(true)
      if (props.myActivityOnly) {
        const res = await api.downloadMyActivityExport(params)
        downloadFile(
          res,
          `First Bite Export: My Activity - ${new Date().toLocaleDateString()}.csv`
        )
      } else {
        const res = await api.downloadActivityExport(params)
        downloadFile(
          res,
          `First Bite Export: Activity - ${new Date().toLocaleDateString()}.csv`
        )
      }
      toast.success('CSV downloaded successfully')
    } catch (e) {
      toast.error('Error downloading CSV')
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    props.setTotalRowsCount?.(data?.count ?? 0)
  }, [data?.count])

  const tooltip = (
    <Tooltip id="tooltip">Download limited to 10,000 rows</Tooltip>
  )

  return (
    <TableContainer>
      <S.SearchContainer>
        <S.SearchContainerFilters>
          <S.TableSearchContainer>
            <TableSearch
              filterStore={activityFilterStore}
              searchPlaceholder="Search by company or email address"
            />
          </S.TableSearchContainer>
          <S.TableButtonsContainer>
            <ActivityFilterset />
            <ColumnSelectorRecipient tableKey={props.tableKey} />
          </S.TableButtonsContainer>
        </S.SearchContainerFilters>
        <FilterChips
          identifier={'ACTIVITY_TABLE'}
          store={activityFilterStore}
          clearAllButton
          showActive
        />
      </S.SearchContainer>
      <DataTable
        autoLayout
        key={props.tableKey}
        loading={isFetching}
        tableKey={props.tableKey}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        isPaginationEnabled={true}
        stickyLastColumn
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        footerControls={
          <OverlayTrigger placement="top" overlay={tooltip}>
            <div>
              <IconMiniButton
                onClick={() => downloadCsv()}
                loading={isDownloading}
              >
                <BsDownload size={18} />
              </IconMiniButton>
            </div>
          </OverlayTrigger>
        }
      />
    </TableContainer>
  )
}

ActivityTableComponent.displayName = 'ActivityTable'

export function ActivityTable(
  props: ActivityTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={props.tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={tableKey}>
        <ActivityTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
