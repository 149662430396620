const DMA_OPTIONS_STR = [
  'Moberly, MO',
  'Monroe, LA',
  'Tulsa, OK',
  'Saginaw, MI',
  'Aguadilla-Isabela, PR',
  'Albany-Lebanon, OR',
  'Klamath Falls, OR',
  'Panama City, FL',
  'Las Cruces, NM',
  'Longview, TX',
  'Rock Springs, WY',
  'Stevens Point, WI',
  'Ogdensburg-Massena, NY',
  'Cullowhee, NC',
  'St. Cloud, MN',
  'Salt Lake City, UT',
  'Keene, NH',
  'Lima, OH',
  'Glenwood Springs, CO',
  'Winchester, VA-WV',
  'Ukiah, CA',
  'St. George, UT',
  'Carson City, NV',
  'Fresno, CA',
  'Odessa, TX',
  'Centralia, IL',
  'Jasper, IN',
  'Sweetwater, TX',
  'Defiance, OH',
  'Crossville, TN',
  'Scranton--Wilkes-Barre, PA',
  'Wabash, IN',
  'Rexburg, ID',
  'Ocean City, NJ',
  'Jackson, OH',
  'Jacksonville, TX',
  'Lewisburg, TN',
  'Toledo, OH',
  'Brookings, SD',
  'Gainesville, FL',
  'Palm Bay-Melbourne-Titusville, FL',
  'Jasper, AL',
  'Grenada, MS',
  'Thomaston, GA',
  'Myrtle Beach-Conway-North Myrtle Beach, SC-NC',
  'Cortland, NY',
  'Kahului-Wailuku-Lahaina, HI',
  'Muncie, IN',
  'Spearfish, SD',
  'Mount Gay-Shamrock, WV',
  'Omaha-Council Bluffs, NE-IA',
  'Bogalusa, LA',
  'Winona, MN',
  'Enterprise, AL',
  'Seymour, IN',
  'Scottsboro, AL',
  'Pahrump, NV',
  'Evanston, WY',
  'Chicago-Naperville-Elgin, IL-IN-WI',
  'Fayetteville-Springdale-Rogers, AR',
  'Shelby, NC',
  'Marshall, MN',
  'Macomb, IL',
  'Jesup, GA',
  'Boston-Cambridge-Newton, MA-NH',
  'Lewisburg, PA',
  'Laconia, NH',
  'Fairfield, IA',
  'Waterloo-Cedar Falls, IA',
  'Rome, GA',
  'Corsicana, TX',
  'Searcy, AR',
  'Wisconsin Rapids-Marshfield, WI',
  'Levelland, TX',
  'Dothan, AL',
  'Santa Isabel, PR',
  'Union, SC',
  'Spokane-Spokane Valley, WA',
  'Frankfort, KY',
  'Duluth, MN-WI',
  'Grand Rapids-Kentwood, MI',
  'Greenville, OH',
  'Batesville, AR',
  'Salisbury, MD-DE',
  'Summerville, GA',
  'McComb, MS',
  'Lexington-Fayette, KY',
  'Minden, LA',
  'Madison, WI',
  'Lake City, FL',
  'Rutland, VT',
  'College Station-Bryan, TX',
  'Springfield, MO',
  'Jacksonville, NC',
  'Durham-Chapel Hill, NC',
  'Cumberland, MD-WV',
  'Eureka-Arcata, CA',
  'Chillicothe, OH',
  'Muscatine, IA',
  'Pine Bluff, AR',
  'Lafayette, LA',
  'Farmington, NM',
  'Trenton-Princeton, NJ',
  'Connersville, IN',
  'Statesboro, GA',
  'Alice, TX',
  'DeRidder, LA',
  'Charleston-Mattoon, IL',
  'Crawfordsville, IN',
  'Idaho Falls, ID',
  'Jackson, MI',
  'Missoula, MT',
  'Opelousas, LA',
  'Ithaca, NY',
  'Greenville-Anderson, SC',
  'Steamboat Springs, CO',
  'Hot Springs, AR',
  'Batavia, NY',
  'Dodge City, KS',
  'New Castle, IN',
  'Bellefontaine, OH',
  'Sioux Falls, SD',
  'Parsons, KS',
  'West Plains, MO',
  'Española, NM',
  'Shelton, WA',
  'Greeley, CO',
  'Brownsville, TN',
  'Kingston, NY',
  'Albany, GA',
  'Tupelo, MS',
  'Newport, OR',
  'Tahlequah, OK',
  'Bartlesville, OK',
  'Scottsbluff, NE',
  'Russellville, AR',
  'Worcester, MA-CT',
  'Bradford, PA',
  'Bloomington, IL',
  'Decatur, IL',
  'Sidney, OH',
  'Logansport, IN',
  'Fort Smith, AR-OK',
  'Jamestown-Dunkirk-Fredonia, NY',
  'Oxnard-Thousand Oaks-Ventura, CA',
  'Faribault-Northfield, MN',
  'McAllen-Edinburg-Mission, TX',
  'Hope, AR',
  'Tampa-St. Petersburg-Clearwater, FL',
  'Lamesa, TX',
  'Shawano, WI',
  'Vernal, UT',
  'Show Low, AZ',
  'Augusta-Richmond County, GA-SC',
  'Georgetown, SC',
  'Burlington, IA-IL',
  'Fort Wayne, IN',
  'Green Bay, WI',
  'Glens Falls, NY',
  'Easton, MD',
  'Barnstable Town, MA',
  'Huntingdon, PA',
  'Natchez, MS-LA',
  'Clarksburg, WV',
  'Sikeston, MO',
  'Magnolia, AR',
  'Sebastian-Vero Beach, FL',
  'Ashtabula, OH',
  'Beaumont-Port Arthur, TX',
  'Newport, TN',
  'Bloomsburg-Berwick, PA',
  'Crescent City, CA',
  'Juneau, AK',
  'Midland, MI',
  'Mountain Home, ID',
  'Sevierville, TN',
  'Terre Haute, IN',
  'North Port-Sarasota-Bradenton, FL',
  'Watertown-Fort Atkinson, WI',
  'Crestview-Fort Walton Beach-Destin, FL',
  'Buffalo-Cheektowaga, NY',
  'North Vernon, IN',
  'Mount Pleasant, MI',
  'Dyersburg, TN',
  'Jackson, WY-ID',
  'Yankton, SD',
  'Mitchell, SD',
  'Ashland, OH',
  'Florence, SC',
  'Holland, MI',
  'Midland, TX',
  'Vineland-Bridgeton, NJ',
  'Janesville-Beloit, WI',
  'Amsterdam, NY',
  'Gaffney, SC',
  'Burlington-South Burlington, VT',
  'Albert Lea, MN',
  'Johnstown, PA',
  'Eau Claire, WI',
  'Beatrice, NE',
  'Lumberton, NC',
  'Paducah, KY-IL',
  'Wheeling, WV-OH',
  'Farmington, MO',
  'Bennington, VT',
  'Parkersburg-Vienna, WV',
  'Michigan City-La Porte, IN',
  'Roswell, NM',
  'Kearney, NE',
  'Harrisburg-Carlisle, PA',
  'Lynchburg, VA',
  'Brunswick, GA',
  'Vermillion, SD',
  'South Bend-Mishawaka, IN-MI',
  'El Campo, TX',
  'Tiffin, OH',
  'Merced, CA',
  'Pittsburg, KS',
  'Port St. Lucie, FL',
  'Louisville/Jefferson County, KY-IN',
  'Grand Rapids, MN',
  'Memphis, TN-MS-AR',
  'Arcadia, FL',
  'Payson, AZ',
  'Salem, OH',
  'Waycross, GA',
  'Palatka, FL',
  'Wahpeton, ND-MN',
  'Cheyenne, WY',
  'Blackfoot, ID',
  'Yakima, WA',
  'Greenwood, MS',
  'Appleton, WI',
  'Pottsville, PA',
  'Mount Vernon, OH',
  'McMinnville, TN',
  'Sault Ste. Marie, MI',
  'Eugene-Springfield, OR',
  'Selma, AL',
  'Fort Madison-Keokuk, IA-IL-MO',
  'San Jose-Sunnyvale-Santa Clara, CA',
  'Daphne-Fairhope-Foley, AL',
  'Gettysburg, PA',
  'Billings, MT',
  'Kendallville, IN',
  'Lake Charles, LA',
  'Butte-Silver Bow, MT',
  'Middlesborough, KY',
  'Riverton, WY',
  'Santa Cruz-Watsonville, CA',
  'Coshocton, OH',
  'Atlanta-Sandy Springs-Alpharetta, GA',
  'Grand Junction, CO',
  'Warren, PA',
  'Dublin, GA',
  'Ponce, PR',
  'Anniston-Oxford, AL',
  'Fort Dodge, IA',
  'Richmond, IN',
  'Medford, OR',
  'Davenport-Moline-Rock Island, IA-IL',
  'Marion, IN',
  'Pensacola-Ferry Pass-Brent, FL',
  'Othello, WA',
  'Flint, MI',
  'Sandusky, OH',
  'Pocatello, ID',
  'Aberdeen, SD',
  'Binghamton, NY',
  'Alexandria, LA',
  'Lake Havasu City-Kingman, AZ',
  'Pella, IA',
  'El Paso, TX',
  'Hilo, HI',
  'Rapid City, SD',
  'Portland-Vancouver-Hillsboro, OR-WA',
  'Ruidoso, NM',
  'Kokomo, IN',
  'Bay City, TX',
  'Athens-Clarke County, GA',
  'Auburn, NY',
  'Washington Court House, OH',
  'Henderson, NC',
  'Bainbridge, GA',
  'Peru, IN',
  'Columbus, MS',
  'Tuscaloosa, AL',
  'Ottumwa, IA',
  'Mountain Home, AR',
  'Olean, NY',
  'Mount Vernon-Anacortes, WA',
  'Lubbock, TX',
  'Gardnerville Ranchos, NV',
  'Huron, SD',
  'Kansas City, MO-KS',
  'Deming, NM',
  'Pampa, TX',
  'Plainview, TX',
  'Athens, TN',
  'Lexington, NE',
  'Brainerd, MN',
  'Susanville, CA',
  'Dayton, TN',
  'Cookeville, TN',
  'St. Louis, MO-IL',
  'New Castle, PA',
  'Kinston, NC',
  'Alexander City, AL',
  'Oil City, PA',
  'Virginia Beach-Norfolk-Newport News, VA-NC',
  'Elko, NV',
  'Pullman, WA',
  'Snyder, TX',
  'Johnson City, TN',
  'Canton-Massillon, OH',
  'Mayagüez, PR',
  'Owensboro, KY',
  'Centralia, WA',
  'Fremont, OH',
  'Orangeburg, SC',
  'Victoria, TX',
  'Zapata, TX',
  'Stillwater, OK',
  'Tucson, AZ',
  "Coeur d'Alene, ID",
  'Mobile, AL',
  'Bay City, MI',
  'Spartanburg, SC',
  'Hudson, NY',
  'Utica-Rome, NY',
  'Coos Bay, OR',
  'Fargo, ND-MN',
  'Gainesville, TX',
  'Lewistown, PA',
  'Sanford, NC',
  'Zanesville, OH',
  'Americus, GA',
  'Washington, NC',
  'San Angelo, TX',
  'Wilmington, OH',
  'Rochelle, IL',
  'Stephenville, TX',
  'Fort Morgan, CO',
  'Roseburg, OR',
  'Cape Girardeau, MO-IL',
  'Vincennes, IN',
  'Altoona, PA',
  'Cedar Rapids, IA',
  'Albuquerque, NM',
  'Phoenix-Mesa-Chandler, AZ',
  'Sumter, SC',
  'Bend, OR',
  'Greeneville, TN',
  'Ludington, MI',
  'Moscow, ID',
  'Eagle Pass, TX',
  'Cedar City, UT',
  'Cape Coral-Fort Myers, FL',
  'Pueblo, CO',
  'Elmira, NY',
  'Elkhart-Goshen, IN',
  'Fayetteville, NC',
  'Plattsburgh, NY',
  'Thomasville, GA',
  'Clovis, NM',
  'Grand Island, NE',
  'Talladega-Sylacauga, AL',
  'Yuba City, CA',
  'Pittsfield, MA',
  'Blytheville, AR',
  'Springfield, IL',
  'Meadville, PA',
  'San Luis Obispo-Paso Robles, CA',
  'Great Bend, KS',
  'Goldsboro, NC',
  'Cambridge, OH',
  'Boise City, ID',
  'Vidalia, GA',
  'Laurinburg, NC',
  'Brownsville-Harlingen, TX',
  'Muskogee, OK',
  'Iowa City, IA',
  'Newberry, SC',
  'St. Marys, PA',
  'Laramie, WY',
  'Mount Pleasant, TX',
  'Enid, OK',
  'Truckee-Grass Valley, CA',
  'Ada, OK',
  'Clewiston, FL',
  'Eufaula, AL-GA',
  'Tallahassee, FL',
  'Richmond, VA',
  'Des Moines-West Des Moines, IA',
  'Houma-Thibodaux, LA',
  'McAlester, OK',
  'Jacksonville, IL',
  'Kalispell, MT',
  'Seneca Falls, NY',
  'Punta Gorda, FL',
  'Taylorville, IL',
  'Dalton, GA',
  'Hagerstown-Martinsburg, MD-WV',
  'Andrews, TX',
  'Alma, MI',
  'Akron, OH',
  'Central City, KY',
  'Hattiesburg, MS',
  'Okeechobee, FL',
  'Washington-Arlington-Alexandria, DC-VA-MD-WV',
  'Mineral Wells, TX',
  'Albemarle, NC',
  'Rolla, MO',
  'Lawrence, KS',
  'Athens, OH',
  'Orlando-Kissimmee-Sanford, FL',
  'Charleston, WV',
  'Winfield, KS',
  'Oxford, MS',
  'Shawnee, OK',
  'Bellingham, WA',
  'Forest City, NC',
  'Huntsville, AL',
  'Morehead City, NC',
  'Bloomington, IN',
  'Edwards, CO',
  'Salinas, CA',
  'Sunbury, PA',
  'Decatur, AL',
  'Mount Sterling, KY',
  'Lafayette-West Lafayette, IN',
  'Murray, KY',
  'Hobbs, NM',
  'Battle Creek, MI',
  'Atmore, AL',
  'Fort Leonard Wood, MO',
  'Baraboo, WI',
  'Douglas, GA',
  'Laredo, TX',
  'New Haven-Milford, CT',
  'Jefferson City, MO',
  'Malvern, AR',
  'Milwaukee-Waukesha, WI',
  'York-Hanover, PA',
  'Hays, KS',
  'Minneapolis-St. Paul-Bloomington, MN-WI',
  'San Antonio-New Braunfels, TX',
  'Carbondale-Marion, IL',
  'Fort Polk South, LA',
  'Kingsville, TX',
  'Pittsburgh, PA',
  'Somerset, PA',
  'Hickory-Lenoir-Morganton, NC',
  'Guayama, PR',
  'Marietta, OH',
  'St. Marys, GA',
  'Roanoke, VA',
  'Washington, IN',
  'Yuma, AZ',
  'Pierre, SD',
  'Bremerton-Silverdale-Port Orchard, WA',
  'Paragould, AR',
  'Coffeyville, KS',
  'Ellensburg, WA',
  'Moultrie, GA',
  'Danville, IL',
  'Greenwood, SC',
  'Palestine, TX',
  'Montgomery, AL',
  'Bakersfield, CA',
  'Carroll, IA',
  'Fitzgerald, GA',
  'Longview, WA',
  'Houston-The Woodlands-Sugar Land, TX',
  'Salem, OR',
  'Gillette, WY',
  'Williamsport, PA',
  'Mansfield, OH',
  'Jacksonville, FL',
  'Poplar Bluff, MO',
  'Norwich-New London, CT',
  'Nashville-Davidson--Murfreesboro--Franklin, TN',
  'Coco, PR',
  'Key West, FL',
  'Brookhaven, MS',
  'Provo-Orem, UT',
  'Redding, CA',
  'Arkadelphia, AR',
  'Freeport, IL',
  'Danville, KY',
  'Camden, AR',
  'Lawton, OK',
  'Valdosta, GA',
  'Houghton, MI',
  'Greenville, MS',
  'Marshalltown, IA',
  'Baton Rouge, LA',
  'Somerset, KY',
  'Uvalde, TX',
  'Mankato, MN',
  'Sayre, PA',
  'London, KY',
  'Grants, NM',
  'Spencer, IA',
  'Lufkin, TX',
  'Oak Harbor, WA',
  'East Stroudsburg, PA',
  'Sterling, IL',
  'Traverse City, MI',
  'Williston, ND',
  'NaN',
  'Malone, NY',
  'Lewiston, ID-WA',
  'Campbellsville, KY',
  'Cordele, GA',
  'La Grande, OR',
  'New York-Newark-Jersey City, NY-NJ-PA',
  'Cedartown, GA',
  'Pinehurst-Southern Pines, NC',
  'Troy, AL',
  'Fort Collins, CO',
  'Casper, WY',
  'Fredericksburg, TX',
  'Poughkeepsie-Newburgh-Middletown, NY',
  'Seattle-Tacoma-Bellevue, WA',
  'Greensburg, IN',
  'Champaign-Urbana, IL',
  'Oneonta, NY',
  'Visalia, CA',
  'Lebanon, MO',
  'Ozark, AL',
  'Sherman-Denison, TX',
  'Quincy, IL-MO',
  'Kennett, MO',
  'Raleigh-Cary, NC',
  'Huntington, IN',
  'Wauchula, FL',
  'Warrensburg, MO',
  'Stockton, CA',
  'Lancaster, PA',
  'Concord, NH',
  'Worthington, MN',
  'New Philadelphia-Dover, OH',
  'Picayune, MS',
  'Dubuque, IA',
  'Menomonie, WI',
  'Columbus, NE',
  'Fond du Lac, WI',
  'Pontiac, IL',
  'Wichita, KS',
  'North Platte, NE',
  'Lock Haven, PA',
  'La Crosse-Onalaska, WI-MN',
  'Kill Devil Hills, NC',
  'Duncan, OK',
  'Safford, AZ',
  'Ottawa, KS',
  'Fort Payne, AL',
  'Charlotte-Concord-Gastonia, NC-SC',
  'Sebring-Avon Park, FL',
  'Kapaa, HI',
  'Owatonna, MN',
  'Reno, NV',
  'Hastings, NE',
  'Huntsville, TX',
  'Jackson, MS',
  'Dallas-Fort Worth-Arlington, TX',
  'Hutchinson, KS',
  'Liberal, KS',
  'Clinton, IA',
  'Rockport, TX',
  'Oshkosh-Neenah, WI',
  'Detroit-Warren-Dearborn, MI',
  'Albertville, AL',
  'Heber, UT',
  'Columbus, GA-AL',
  'Peoria, IL',
  'Effingham, IL',
  'Durant, OK',
  'Hinesville, GA',
  'Marquette, MI',
  'Guymon, OK',
  'Mason City, IA',
  'Florence-Muscle Shoals, AL',
  'Fremont, NE',
  'Cambridge, MD',
  'Fergus Falls, MN',
  'The Dalles, OR',
  'Hammond, LA',
  'McPherson, KS',
  'Meridian, MS',
  'Borger, TX',
  'Deltona-Daytona Beach-Ormond Beach, FL',
  'Cleveland, TN',
  'Bonham, TX',
  'Kankakee, IL',
  'West Point, MS',
  'Sulphur Springs, TX',
  'North Wilkesboro, NC',
  'Nogales, AZ',
  'Alpena, MI',
  'Ann Arbor, MI',
  'Winston-Salem, NC',
  'Columbus, OH',
  'DuBois, PA',
  'Sierra Vista-Douglas, AZ',
  'Dickinson, ND',
  'Helena-West Helena, AR',
  'Wenatchee, WA',
  'Warsaw, IN',
  'Albany-Schenectady-Troy, NY',
  'Brenham, TX',
  'Sterling, CO',
  'Hilton Head Island-Bluffton, SC',
  'Mayfield, KY',
  'Greensboro-High Point, NC',
  'Astoria, OR',
  'Arecibo, PR',
  'Kingsport-Bristol, TN-VA',
  'Portland-South Portland, ME',
  'Clearlake, CA',
  'Oskaloosa, IA',
  'Torrington, CT',
  'Topeka, KS',
  'Vicksburg, MS',
  'Taos, NM',
  'Indiana, PA',
  'El Dorado, AR',
  'Big Rapids, MI',
  'Spirit Lake, IA',
  'Wapakoneta, OH',
  'Elk City, OK',
  'Lebanon, PA',
  'Columbia, SC',
  'Reading, PA',
  'Bozeman, MT',
  'Silver City, NM',
  'Montrose, CO',
  'Seneca, SC',
  'Sacramento-Roseville-Folsom, CA',
  'Van Wert, OH',
  'Jayuya, PR',
  'Tifton, GA',
  'Adrian, MI',
  'Los Alamos, NM',
  'Bowling Green, KY',
  'Barre, VT',
  'Tyler, TX',
  'Big Stone Gap, VA',
  'Port Angeles, WA',
  'Springfield, MA',
  'Mount Vernon, IL',
  'Staunton, VA',
  'Hutchinson, MN',
  'Auburn-Opelika, AL',
  'Scottsburg, IN',
  'Charleston-North Charleston, SC',
  'Beaver Dam, WI',
  'Portales, NM',
  'Helena, MT',
  'Kirksville, MO',
  'Bardstown, KY',
  'Syracuse, NY',
  'Pearsall, TX',
  'Escanaba, MI',
  'Harrisonburg, VA',
  'Racine, WI',
  'Del Rio, TX',
  'Fallon, NV',
  'Las Vegas-Henderson-Paradise, NV',
  'Clarksdale, MS',
  'Richmond-Berea, KY',
  'Evansville, IN-KY',
  'Maysville, KY',
  'Granbury, TX',
  'Manchester-Nashua, NH',
  'Tullahoma-Manchester, TN',
  'Columbia, MO',
  'Lewiston-Auburn, ME',
  'Napa, CA',
  'Jamestown, ND',
  'Homosassa Springs, FL',
  'Olympia-Lacey-Tumwater, WA',
  'Ottawa, IL',
  'El Centro, CA',
  'Las Vegas, NM',
  'Bismarck, ND',
  'Santa Rosa-Petaluma, CA',
  'Fairmont, MN',
  'Bridgeport-Stamford-Norwalk, CT',
  'Vernon, TX',
  'Brookings, OR',
  'Point Pleasant, WV-OH',
  'Pecos, TX',
  'Aberdeen, WA',
  'Blacksburg-Christiansburg, VA',
  'Norfolk, NE',
  'Watertown, SD',
  'Denver-Aurora-Lakewood, CO',
  'Gainesville, GA',
  'Columbus, IN',
  'Cincinnati, OH-KY-IN',
  'Chattanooga, TN-GA',
  'Carlsbad-Artesia, NM',
  'Anchorage, AK',
  'Gloversville, NY',
  'Corvallis, OR',
  'Ardmore, OK',
  'Walla Walla, WA',
  'Rochester, MN',
  'Wichita Falls, TX',
  'Asheville, NC',
  'State College, PA',
  'Waco, TX',
  'Laurel, MS',
  'Garden City, KS',
  'Jonesboro, AR',
  'Wilson, NC',
  'Marshall, MO',
  'Abilene, TX',
  'Wilmington, NC',
  'Flagstaff, AZ',
  'Athens, TX',
  'Little Rock-North Little Rock-Conway, AR',
  'Natchitoches, LA',
  'Willmar, MN',
  'Sheboygan, WI',
  'Ames, IA',
  'San Germán, PR',
  'Ogden-Clearfield, UT',
  'Prineville, OR',
  'Los Angeles-Long Beach-Anaheim, CA',
  'Beckley, WV',
  'Jackson, TN',
  'Sonora, CA',
  'San Juan-Bayamón-Caguas, PR',
  'Craig, CO',
  'Brevard, NC',
  'Wausau-Weston, WI',
  'Miami-Fort Lauderdale-Pompano Beach, FL',
  'Hailey, ID',
  'California-Lexington Park, MD',
  'Burley, ID',
  'Miami, OK',
  'Corning, NY',
  'Watertown-Fort Drum, NY',
  'Monroe, MI',
  'Portsmouth, OH',
  'Red Wing, MN',
  'San Francisco-Oakland-Berkeley, CA',
  'Sandpoint, ID',
  'Elizabethtown-Fort Knox, KY',
  'Weirton-Steubenville, WV-OH',
  'Atlantic City-Hammonton, NJ',
  'Marinette, WI-MI',
  'Boulder, CO',
  'Branson, MO',
  'Cleveland-Elyria, OH',
  'Clarksville, TN-KY',
  'Maryville, MO',
  'Martin, TN',
  'Manhattan, KS',
  'Providence-Warwick, RI-MA',
  'Red Bluff, CA',
  'Elkins, WV',
  'Shreveport-Bossier City, LA',
  'Kerrville, TX',
  'Modesto, CA',
  'Bangor, ME',
  'Grants Pass, OR',
  'Santa Maria-Santa Barbara, CA',
  'Ruston, LA',
  'Beeville, TX',
  'Morgan City, LA',
  'Whitewater, WI',
  'Iron Mountain, MI-WI',
  'Platteville, WI',
  'Calhoun, GA',
  'Logan, UT-ID',
  'Paris, TX',
  'Knoxville, TN',
  'Birmingham-Hoover, AL',
  'Urban Honolulu, HI',
  'Texarkana, TX-AR',
  'Lincoln, IL',
  'Greenville, NC',
  'Chambersburg-Waynesboro, PA',
  'Gallup, NM',
  'Savannah, GA',
  'Vineyard Haven, MA',
  'Celina, OH',
  'Muskegon, MI',
  'Warner Robins, GA',
  'Prescott Valley-Prescott, AZ',
  'Boone, NC',
  'Hereford, TX',
  'Winnemucca, NV',
  'Lebanon, NH-VT',
  'Hartford-East Hartford-Middletown, CT',
  'Cadillac, MI',
  'Kalamazoo-Portage, MI',
  'Cleveland, MS',
  'Great Falls, MT',
  'Hillsdale, MI',
  'Ocala, FL',
  'Ponca City, OK',
  'Sioux City, IA-NE-SD',
  'Fernley, NV',
  'Lansing-East Lansing, MI',
  'Alexandria, MN',
  'Brownwood, TX',
  'Madison, IN',
  'Frankfort, IN',
  'Milledgeville, GA',
  'Corinth, MS',
  'Joplin, MO',
  'Lakeland-Winter Haven, FL',
  'Marion, OH',
  'Minot, ND',
  'Alamogordo, NM',
  'Niles, MI',
  'Selinsgrove, PA',
  'Dayton-Kettering, OH',
  'The Villages, FL',
  'Killeen-Temple, TX',
  'Hanford-Corcoran, CA',
  'Ketchikan, AK',
  'Auburn, IN',
  'Morgantown, WV',
  'Oklahoma City, OK',
  'Sheridan, WY',
  'Urbana, OH',
  'Hood River, OR',
  'Fairbanks, AK',
  'Mount Airy, NC',
  'Big Spring, TX',
  'Dover, DE',
  'Hermiston-Pendleton, OR',
  'Youngstown-Warren-Boardman, OH-PA',
  'Twin Falls, ID',
  'Berlin, NH',
  'Coldwater, MI',
  'Rocky Mount, NC',
  'Corpus Christi, TX',
  'Coamo, PR',
  'Kennewick-Richland, WA',
  'Danville, VA',
  'New Bern, NC',
  'Woodward, OK',
  'Dixon, IL',
  'Baltimore-Columbia-Towson, MD',
  'Cornelia, GA',
  'Morristown, TN',
  'Austin, MN',
  'Burlington, NC',
  'Bluefield, WV-VA',
  'Chico, CA',
  'Jefferson, GA',
  'Yauco, PR',
  'Marion, NC',
  'Decatur, IN',
  'Madisonville, KY',
  'Fairmont, WV',
  'Bucyrus-Galion, OH',
  'Santa Fe, NM',
  'Moses Lake, WA',
  'Lawrenceburg, TN',
  'Galesburg, IL',
  'Springfield, OH',
  'Riverside-San Bernardino-Ontario, CA',
  'Jennings, LA',
  'Indianola, MS',
  'Toccoa, GA',
  'Rochester, NY',
  'Weatherford, OK',
  'Durango, CO',
  'Macon-Bibb County, GA',
  'Emporia, KS',
  'Grand Forks, ND-MN',
  'Allentown-Bethlehem-Easton, PA-NJ',
  'Roanoke Rapids, NC',
  'Gadsden, AL',
  'Union City, TN',
  'Sedalia, MO',
  'Amarillo, TX',
  'Madera, CA',
  'Hannibal, MO',
  'Dumas, TX',
  'Breckenridge, CO',
  'Rio Grande City-Roma, TX',
  'LaGrange, GA-AL',
  'Starkville, MS',
  'Austin-Round Rock-Georgetown, TX',
  'Plymouth, IN',
  'Vallejo, CA',
  'Angola, IN',
  'Altus, OK',
  'Huntington-Ashland, WV-KY-OH',
  'Storm Lake, IA',
  'Rockford, IL',
  'Forrest City, AR',
  'New Ulm, MN',
  'Ontario, OR-ID',
  'Sturgis, MI',
  'Augusta-Waterville, ME',
  'Mexico, MO',
  'Bennettsville, SC',
  'Port Lavaca, TX',
  'Lincoln, NE',
  'Naples-Marco Island, FL',
  'New Orleans-Metairie, LA',
  'Colorado Springs, CO',
  'Harrison, AR',
  'St. Joseph, MO-KS',
  'Price, UT',
  'Nacogdoches, TX',
  'Findlay, OH',
  'Bedford, IN',
  'Bemidji, MN',
  'Glasgow, KY',
  'San Diego-Chula Vista-Carlsbad, CA',
  'Elizabeth City, NC',
  'Cañon City, CO',
  'Cullman, AL',
  'Raymondville, TX',
  'Wooster, OH',
  'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
  'Charlottesville, VA',
  'Manitowoc, WI',
  'Salina, KS',
  'Rockingham, NC',
  'Indianapolis-Carmel-Anderson, IN',
  'Gulfport-Biloxi, MS',
  'Paris, TN',
  'Erie, PA',
  'Shelbyville, TN',
  'Martinsville, VA',
  'Atchison, KS',
  'Norwalk, OH',
]

const DMA_OPTIONS = DMA_OPTIONS_STR.map((d) => ({ value: d, label: d }))

export { DMA_OPTIONS }
