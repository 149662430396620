export const k12LocaleOptions = [
  { value: 'City – Large', label: 'City – Large' },
  { value: 'City – Midsize', label: 'City – Midsize' },
  { value: 'City – Small', label: 'City – Small' },
  { value: 'Suburban – Large', label: 'Suburban – Large' },
  { value: 'Suburban – Midsize', label: 'Suburban – Midsize' },
  { value: 'Suburban – Small', label: 'Suburban – Small' },
  { value: 'Town – Fringe', label: 'Town – Fringe' },
  { value: 'Town – Distant', label: 'Town – Distant' },
  { value: 'Town – Remote', label: 'Town – Remote' },
  { value: 'Rural – Fringe', label: 'Rural – Fringe' },
  { value: 'Rural – Distant', label: 'Rural – Distant' },
  { value: 'Rural – Remote', label: 'Rural – Remote' },
]
