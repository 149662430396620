import Papa from 'papaparse'

export enum FileType {
  CSV = 'text/csv',
  JSON = 'application/json',
}

/**
 * Checks if the file size is valid.
 * @param file The file to be checked.
 * @param maxFileSizeInMB The maximum file size allowed in megabytes.
 * @returns Boolean indicating if the file size is valid.
 */
export const isValidFileSize = (
  file: File,
  maxFileSizeInMB: number
): boolean => {
  const maxSizeInBytes = maxFileSizeInMB * 1024 * 1024
  return file.size <= maxSizeInBytes
}

/**
 * Reads and parses the file contents using FileReader and Papa Parse asynchronously.
 * @param file The file to be read and parsed.
 * @returns Promise containing the parsed data or null if an error occurs.
 */

export const readAndParseFileContents = (file: File): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const parsedData = Papa.parse(event.target.result as string, {
          skipEmptyLines: true,
          header: true,
        }).data
        resolve(parsedData)
      } else {
        reject(new Error('Error parsing file.'))
      }
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsText(file)
  })
}
