import { Page } from 'components/FbUI/Page'
import styled from 'styled-components/macro'
import { Tabs } from '../../../components/FbUI/Tabs'
import { DistributorTable } from './DistributorTable'
import { MiscSettingsTable } from './MiscSettingsTable'
import { SalesStageTable } from './SalesStageTable'
import { SavedFiltersTable } from './SavedFiltersTable'
import { TagTable } from './TagTable'
import { UserTable } from './UserTable'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavbarStore } from '../../../components/Layout/navbar-store'

export function Settings() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'users'
  const store = useNavbarStore()

  const tabs = [
    { key: 'users', title: 'Users' },
    { key: 'sales-stages', title: 'Sales Stages' },
    { key: 'filters', title: 'Filters' },
    { key: 'distributors', title: 'Distributors' },
    { key: 'product-tags', title: 'Tags' },
    { key: 'contact-tags', title: 'Contact Labels' },
    { key: 'misc', title: 'Misc. Settings' },
  ]

  return (
    <Page>
      <SettingsContainer>
        <Header>
          <TitleRow>
            <Title>Settings</Title>
          </TitleRow>

          <TabsAndStats>
            <Tabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={(value) => {
                const url = `/settings/${value}/`
                navigate(url)
                store.setLastSettingsVisited(url)
              }}
              tabStyle={{ width: '125px' }}
            />
          </TabsAndStats>
        </Header>

        <TableContainer show={selectedTab === 'users'}>
          <UserTable />
        </TableContainer>
        <TableContainer show={selectedTab === 'sales-stages'}>
          <SalesStageTable />
        </TableContainer>
        <TableContainer show={selectedTab === 'filters'}>
          <SavedFiltersTable />
        </TableContainer>
        <TableContainer show={selectedTab === 'distributors'}>
          <DistributorTable />
        </TableContainer>
        <TableContainer show={selectedTab === 'product-tags'}>
          <TagTable tagParent="products" />
        </TableContainer>
        <TableContainer show={selectedTab === 'contact-tags'}>
          <TagTable tagParent="contacts" />
        </TableContainer>
        <TableContainer show={selectedTab === 'misc'}>
          <MiscSettingsTable />
        </TableContainer>
      </SettingsContainer>
    </Page>
  )
}

const Header = styled.div`
  padding: 33px 25px 0 25px;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`

const TabsAndStats = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  margin-bottom: 10px;
`

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: calc(100vh - 140px);
`

const TableContainer = styled.div<{
  show?: boolean
}>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: relative;
`
