import { Campaign } from './campaign'
import { User } from './user'

export enum ContactRequestJobStatus {
  SUBMITTED = 0,
  STARTED = 10,
  PENDING_APPROVAL = 20,
  ACCEPTED = 30,
  REJECTED = 40,
}

export interface ContactRequestJobResponse {
  id: number
  company: number
  user: User
  max_contacts: number
  status: { id: number; label: string }
  created: string
  modified: string
  found_active_count: number
  found_companies_count: number
  sub_category: {
    id: number
    name: string
    description: string
  }
  contact_companies: Array<{
    id: number
    name: string
  }>
  campaign: Campaign
}

export interface RequestedContactResponse {
  id: number
  chain_proxy: {
    id: number
    name: string
  }
  status: {
    id: number
    label: string
  }
  contact: string
  contact_type: string
  pdl_id: string
  title: string
  seniority?: string
  department?: string
  email_validity?: string
  first_name_found: boolean
  last_name_found: boolean
  phone_number_found: boolean
  email_found: boolean
  linkedin_url_found: boolean
  email_category?: string
  metadata: any
}
