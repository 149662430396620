import { useDataTableContext } from '../../components/DataTable/DataTableContext'
import apiService from '../../services/api'
import lodash from 'lodash'
import { useMemo, useState } from 'react'
import confirm from '../../components/dialogConfirm'
import { CompanyTypeSlug } from '../../models/companies'
import { Combobox } from '../../components/UI/combobox/combobox'
import { CreateEditCampaignModal } from './CreateEditCampaignModal'

export interface AssignToCampaignModalProps {
  accessorKey: string
  tableQueryKey: string[]
  requestParams: Record<string, any>
  companyTypeSlug: CompanyTypeSlug
  data: any[]
  buttonLess?: boolean
}

export function AssignToCampaignModal(props: AssignToCampaignModalProps) {
  const api = apiService()
  const { data: campaigns, isLoading } = api.useGetCampaigns()

  const mutation = api.useAssignToCampaignMutation(
    props.tableQueryKey,
    props.accessorKey,
    props.companyTypeSlug
  )
  const {
    state: { rowSelection, isAllRowsSelected },
  } = useDataTableContext()

  const [openNewCampaign, setOpenNewCampaign] = useState(false)

  const campaignOptions = useMemo(
    () =>
      campaigns
        ?.filter((c) => c.type.slug === props.companyTypeSlug)
        .map((c) => ({
          label: c.name,
          value: c.id,
          icon: (
            <div
              className={`h-3 w-3 rounded-full mr-2 flex-shrink-0`}
              style={{
                backgroundColor: c.color,
              }}
            />
          ),
        })) ?? [],
    [campaigns]
  )

  const selectedRows = props.data?.filter((_, i) => {
    return Boolean(rowSelection?.[i])
  })

  const unselectedRows = props.data?.filter((_, i) => {
    return !rowSelection?.[i]
  })

  const selectedChainProxyIds = selectedRows?.map((r: any) =>
    lodash.get(r, props.accessorKey)
  ) as number[]

  const unselectedChainProxyIds = unselectedRows?.map((r: any) =>
    lodash.get(r, props.accessorKey)
  ) as number[]

  async function handleSubmit(campaignId: number | string) {
    const confirmed = await confirm(
      'Confirm assigning to campaign?',
      'Assign to Campaign'
    )

    if (!confirmed) {
      return
    }

    const selected = campaigns?.find((it) => it.id == campaignId)

    await mutation.mutateAsync({
      campaign_name: selected?.name ?? '',
      campaign_color: selected?.color ?? '',
      campaign_id: parseInt(campaignId.toString()),
      chain_proxy_ids: isAllRowsSelected ? undefined : selectedChainProxyIds,
      exclude_chain_proxy_ids: unselectedChainProxyIds,
      params: props.requestParams,
    })
  }

  return (
    <>
      <Combobox
        options={campaignOptions}
        searchPlaceholder="Search Campaigns..."
        placeholder="Push to Campaign"
        onSelectAltAction={handleSubmit}
        onAddNew={() => setOpenNewCampaign(true)}
        loading={isLoading || mutation.isPending}
        buttonLess={props.buttonLess}
      />
      <CreateEditCampaignModal
        isOpen={openNewCampaign}
        onClose={() => setOpenNewCampaign(false)}
        companyTypeSlug={props.companyTypeSlug}
        pushToCampaignOptions={{
          data: props.data,
          accessorKey: props.accessorKey,
          requestParams: props.requestParams,
          tableQueryKey: props.tableQueryKey,
        }}
      />
    </>
  )
}
